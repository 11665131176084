const n3VocabList = [
  {
    vocabulario: "あいにく",
    romaji: "ainiku",
    hiragana: "あいにく",
    traducao: "infelizmente",
    exemplosFrases: [
      {
        exemplo: "あいにく、明日は忙しいです。",
        hiragana: "あいにく、あしたはいそがしいです。",
        romaji: "Ainiku, ashita wa isogashii desu.",
        traducao: "Infelizmente, estarei ocupado amanhã.",
      },
      {
        exemplo: "あいにくの天気ですね。",
        hiragana: "あいにくのてんきですね。",
        romaji: "Ainiku no tenki desu ne.",
        traducao: "Infelizmente, o tempo está ruim, não é?",
      },
      {
        exemplo: "あいにくながら、お断りせざるを得ません。",
        hiragana: "あいにくながら、おことわりせざるをえません。",
        romaji: "Ainiku nagara, okotowari sezaru wo emasen.",
        traducao: "Infelizmente, não posso recusar.",
      },
    ],
  },
  {
    vocabulario: "あんまり",
    romaji: "anmari",
    hiragana: "あんまり",
    traducao: "não muito",
    exemplosFrases: [
      {
        exemplo: "あんまり好きじゃないです。",
        hiragana: "あんまりすきじゃないです。",
        romaji: "Anmari suki janai desu.",
        traducao: "Eu não gosto muito.",
      },
      {
        exemplo: "あんまり上手じゃありません。",
        hiragana: "あんまりじょうずじゃありません。",
        romaji: "Anmari jōzu ja arimasen.",
        traducao: "Eu não sou muito bom nisso.",
      },
      {
        exemplo: "あんまり忙しくないです。",
        hiragana: "あんまりいそがしくないです。",
        romaji: "Anmari isogashikunai desu.",
        traducao: "Não estou muito ocupado.",
      },
    ],
  },
  {
    vocabulario: "いずれ",
    romaji: "izure",
    hiragana: "いずれ",
    traducao: "em breve",
    exemplosFrases: [
      {
        exemplo: "いずれ会いましょう。",
        hiragana: "いずれあいましょう。",
        romaji: "Izure aimashō.",
        traducao: "Vamos nos encontrar em breve.",
      },
      {
        exemplo: "いずれ解決されるでしょう。",
        hiragana: "いずれかいけつされるでしょう。",
        romaji: "Izure kaiketsu sareru deshō.",
        traducao: "Será resolvido em breve.",
      },
      {
        exemplo: "いずれ日本に行きたいです。",
        hiragana: "いずれにほんにいきたいです。",
        romaji: "Izure Nihon ni ikitai desu.",
        traducao: "Eu quero ir ao Japão em breve.",
      },
    ],
  },
  {
    vocabulario: "おそらく",
    romaji: "osoraku",
    hiragana: "おそらく",
    traducao: "provavelmente",
    exemplosFrases: [
      {
        exemplo: "おそらく明日は忙しいです。",
        hiragana: "おそらくあしたはいそがしいです。",
        romaji: "Osoraku ashita wa isogashii desu.",
        traducao: "Provavelmente estarei ocupado amanhã.",
      },
      {
        exemplo: "おそらく雨が降るでしょう。",
        hiragana: "おそらくあめがふるでしょう。",
        romaji: "Osoraku ame ga furu deshō.",
        traducao: "Provavelmente vai chover.",
      },
      {
        exemplo: "おそらく彼は遅れます。",
        hiragana: "おそらくかれはおくれます。",
        romaji: "Osoraku kare wa okuremasu.",
        traducao: "Provavelmente ele vai se atrasar.",
      },
    ],
  },
  {
    vocabulario: "およそ",
    romaji: "oyoso",
    hiragana: "およそ",
    traducao: "aproximadamente",
    exemplosFrases: [
      {
        exemplo: "およそ10分かかります。",
        hiragana: "およそ10ふんかかります。",
        romaji: "Oyoso juppun kakarimasu.",
        traducao: "Vai levar aproximadamente 10 minutos.",
      },
      {
        exemplo: "およそ100人が参加しました。",
        hiragana: "およそ100にんがさんかしました。",
        romaji: "Oyoso hyakunin ga sanka shimashita.",
        traducao: "Aproximadamente 100 pessoas participaram.",
      },
      {
        exemplo: "およそ1年前に日本に来ました。",
        hiragana: "およそ1ねんまえににほんにきました。",
        romaji: "Oyoso ichinen mae ni Nihon ni kimashita.",
        traducao: "Eu cheguei ao Japão aproximadamente há um ano.",
      },
    ],
  },
  {
    vocabulario: "がっかり",
    romaji: "gakkari",
    hiragana: "がっかり",
    traducao: "ficar desapontado",
    exemplosFrases: [
      {
        exemplo: "試験の結果が悪くてがっかりしました。",
        hiragana: "しけんのけっかがわるくてがっかりしました。",
        romaji: "Shiken no kekka ga warukute gakkari shimashita.",
        traducao: "Fiquei desapontado com o resultado do exame.",
      },
      {
        exemplo: "約束を破られてがっかりした。",
        hiragana: "やくそくをやぶられてがっかりした。",
        romaji: "Yakusoku o yaburarete gakkari shita.",
        traducao: "Fiquei desapontado quando a promessa foi quebrada.",
      },
      {
        exemplo: "この映画は期待外れでがっかりした。",
        hiragana: "このえいがはきたいはずれでがっかりした。",
        romaji: "Kono eiga wa kitai hazure de gakkari shita.",
        traducao: "Este filme foi uma decepção, fiquei desapontado.",
      },
    ],
  },
  {
    vocabulario: "かなり",
    romaji: "kanari",
    hiragana: "かなり",
    traducao: "bastante, consideravelmente",
    exemplosFrases: [
      {
        exemplo: "彼はかなり背が高いです。",
        hiragana: "かれはかなりせがたかいです。",
        romaji: "Kare wa kanari sega takai desu.",
        traducao: "Ele é bastante alto.",
      },
      {
        exemplo: "昨夜はかなり遅くまで起きていた。",
        hiragana: "ゆうべはかなりおそくまでおきていた。",
        romaji: "Yūbe wa kanari osoku made okiteita.",
        traducao: "Eu fiquei acordado até bastante tarde ontem à noite.",
      },
      {
        exemplo: "このレストランはかなり人気があります。",
        hiragana: "このれすとらんはかなりにんきがあります。",
        romaji: "Kono resutoran wa kanari ninki ga arimasu.",
        traducao: "Este restaurante é bastante popular.",
      },
    ],
  },
  {
    vocabulario: "きちんと",
    romaji: "kichinto",
    hiragana: "きちんと",
    traducao: "corretamente, adequadamente",
    exemplosFrases: [
      {
        exemplo: "授業にはきちんと参加しましょう。",
        hiragana: "じゅぎょうにはきちんとさんかしましょう。",
        romaji: "Jugyō ni wa kichinto sanka shimashō.",
        traducao: "Participe das aulas adequadamente.",
      },
      {
        exemplo: "会議の前に資料をきちんと準備しておきました。",
        hiragana: "かいぎのまえにしりょうをきちんとじゅんびしておきました。",
        romaji: "Kaigi no mae ni shiryō o kichinto junbi shite okimashita.",
        traducao: "Eu preparei os documentos adequadamente antes da reunião.",
      },
      {
        exemplo: "大切なことはきちんと伝えることです。",
        hiragana: "たいせつなことはきちんとつたえることです。",
        romaji: "Taisetsu na koto wa kichinto tsutaeru koto desu.",
        traducao: "A coisa importante é transmitir corretamente.",
      },
    ],
  },
  {
    vocabulario: "ぐつぐつ",
    romaji: "gutsugutsu",
    hiragana: "ぐつぐつ",
    traducao: "fervendo, borbulhando",
    exemplosFrases: [
      {
        exemplo: "スープがぐつぐつ煮えています。",
        hiragana: "すーぷがぐつぐつにえています。",
        romaji: "Sūpu ga gutsugutsu niete imasu.",
        traducao: "A sopa está fervendo.",
      },
      {
        exemplo: "鍋の中でぐつぐつ音がしています。",
        hiragana: "なべのなかでぐつぐつおとがしています。",
        romaji: "Nabe no naka de gutsugutsu oto ga shite imasu.",
        traducao: "Há um som de borbulhas na panela.",
      },
      {
        exemplo: "お風呂がぐつぐつ温かいです。",
        hiragana: "おふろがぐつぐつあたたかいです。",
        romaji: "Ofuro ga gutsugutsu atatakai desu.",
        traducao: "A banheira está fervendo e quente.",
      },
    ],
  },
  {
    vocabulario: "ぐっすり",
    romaji: "gussuri",
    hiragana: "ぐっすり",
    traducao: "profundamente, tranquilamente",
    exemplosFrases: [
      {
        exemplo: "昨夜はぐっすり眠れました。",
        hiragana: "ゆうべはぐっすりねむれました。",
        romaji: "Yūbe wa gussuri nemuremashita.",
        traducao: "Eu dormi profundamente na noite passada.",
      },
      {
        exemplo: "休みの日はぐっすり寝たいです。",
        hiragana: "やすみのひはぐっすりねたいです。",
        romaji: "Yasumi no hi wa gussuri netai desu.",
        traducao: "Eu quero dormir tranquilamente nos dias de folga.",
      },
      {
        exemplo: "彼はぐっすりと熟睡しています。",
        hiragana: "かれはぐっすりとじゅくすいしています。",
        romaji: "Kare wa gussuri to jukusui shite imasu.",
        traducao: "Ele está dormindo profundamente.",
      },
    ],
  },
  {
    vocabulario: "ざっ",
    romaji: "za'",
    hiragana: "ざっ",
    traducao: "de repente",
    exemplosFrases: [
      {
        exemplo: "彼はざっと本を読んでしまった。",
        hiragana: "かれはざっとほんをよんでしまった。",
        romaji: "Kare wa zatto hon o yonde shimatta.",
        traducao: "Ele leu o livro rapidamente.",
      },
      {
        exemplo: "電車が突然に停止して、ざっとした。",
        hiragana: "でんしゃがとつぜんにていしして、ざっとした。",
        romaji: "Densha ga totsuzen ni teishi shite, zatto shita.",
        traducao: "O trem parou de repente.",
      },
      {
        exemplo: "会議の内容をざっとチェックしました。",
        hiragana: "かいぎのないようをざっとちぇっくしました。",
        romaji: "Kaigi no naiyō o zatto chekku shimashita.",
        traducao: "Verifiquei rapidamente o conteúdo da reunião.",
      },
    ],
  },
  {
    vocabulario: "さっぱり",
    romaji: "sappari",
    hiragana: "さっぱり",
    traducao: "completamente, nada",
    exemplosFrases: [
      {
        exemplo: "この問題はさっぱり分かりません。",
        hiragana: "このもんだいはさっぱりわかりません。",
        romaji: "Kono mondai wa sappari wakarimasen.",
        traducao: "Eu não entendo nada deste problema.",
      },
      {
        exemplo: "さっぱりわからない話でした。",
        hiragana: "さっぱりわからないはなしでした。",
        romaji: "Sappari wakaranai hanashi deshita.",
        traducao: "Foi uma conversa que eu não entendi nada.",
      },
      {
        exemplo: "食欲がなくて、さっぱり食べられませんでした。",
        hiragana: "しょくよくがなくて、さっぱりたべられませんでした。",
        romaji: "Shokuyoku ga nakute, sappari taberaremasen deshita.",
        traducao: "Sem apetite, eu não consegui comer nada.",
      },
    ],
  },
  {
    vocabulario: "しきり",
    romaji: "shikiri",
    hiragana: "しきり",
    traducao: "repetidamente, com frequência",
    exemplosFrases: [
      {
        exemplo: "彼はしきりに質問をします。",
        hiragana: "かれはしきりにしつもんをします。",
        romaji: "Kare wa shikiri ni shitsumon o shimasu.",
        traducao: "Ele faz perguntas repetidamente.",
      },
      {
        exemplo: "電話がしきりに鳴り続けています。",
        hiragana: "でんわがしきりになりつづけています。",
        romaji: "Denwa ga shikiri ni nari tsuzukete imasu.",
        traducao: "O telefone continua tocando repetidamente.",
      },
      {
        exemplo: "彼はしきりに頭を振っていました。",
        hiragana: "かれはしきりにあたまをふっていました。",
        romaji: "Kare wa shikiri ni atama o futte imashita.",
        traducao: "Ele balançou a cabeça repetidamente.",
      },
    ],
  },
  {
    vocabulario: "じっと",
    romaji: "jitto",
    hiragana: "じっと",
    traducao: "fixamente, imóvel",
    exemplosFrases: [
      {
        exemplo: "彼はじっと私を見つめていた。",
        hiragana: "かれはじっとわたしをみつめていた。",
        romaji: "Kare wa jitto watashi o mitsumete ita.",
        traducao: "Ele olhava fixamente para mim.",
      },
      {
        exemplo: "子供たちはじっと耳を傾けていました。",
        hiragana: "こどもたちはじっとみみをかたむけていました。",
        romaji: "Kodomotachi wa jitto mimi o katamukete imashita.",
        traducao: "As crianças estavam ouvindo atentamente.",
      },
      {
        exemplo: "彼はじっと動かずに座っていました。",
        hiragana: "かれはじっとうごかずにすわっていました。",
        romaji: "Kare wa jitto ugokazu ni suwatte imashita.",
        traducao: "Ele ficou imóvel sentado.",
      },
    ],
  },
  {
    vocabulario: "しばしば",
    romaji: "shibashiba",
    hiragana: "しばしば",
    traducao: "frequentemente, muitas vezes",
    exemplosFrases: [
      {
        exemplo: "彼はしばしば遅刻します。",
        hiragana: "かれはしばしばちこくします。",
        romaji: "Kare wa shibashiba chikoku shimasu.",
        traducao: "Ele frequentemente se atrasa.",
      },
      {
        exemplo: "この地域では地震がしばしば起こります。",
        hiragana: "このちいきではじしんがしばしばおこります。",
        romaji: "Kono chiiki de wa jishin ga shibashiba okorimasu.",
        traducao: "Nesta região, os terremotos ocorrem frequentemente.",
      },
      {
        exemplo: "彼はしばしば外国へ旅行に行きます。",
        hiragana: "かれはしばしばがいこくへりょこうにいきます。",
        romaji: "Kare wa shibashiba gaikoku e ryokō ni ikimasu.",
        traducao: "Ele viaja frequentemente para o exterior.",
      },
    ],
  },
  {
    vocabulario: "すう",
    romaji: "sū",
    hiragana: "すう",
    traducao: "muitos, vários",
    exemplosFrases: [
      {
        exemplo: "友達がすう人来ました。",
        hiragana: "ともだちがすうにんきました。",
        romaji: "Tomodachi ga sū nin kimashita.",
        traducao: "Muitos amigos vieram.",
      },
      {
        exemplo: "すう曲を演奏しました。",
        hiragana: "すうきょくをえんそうしました。",
        romaji: "Sū kyoku o ensō shimashita.",
        traducao: "Toquei várias músicas.",
      },
      {
        exemplo: "すう回繰り返しました。",
        hiragana: "すうかいくりかえしました。",
        romaji: "Sū kai kurikaeshimashita.",
        traducao: "Repeti várias vezes.",
      },
    ],
  },
  {
    vocabulario: "せいぜい",
    romaji: "seizei",
    hiragana: "せいぜい",
    traducao: "no máximo, o mais",
    exemplosFrases: [
      {
        exemplo: "せいぜい三時間しか寝られませんでした。",
        hiragana: "せいぜいさんじかんしかねられませんでした。",
        romaji: "Seizei san-jikan shika neraremasen deshita.",
        traducao: "Eu só pude dormir no máximo três horas.",
      },
      {
        exemplo: "せいぜい一週間で終わると思います。",
        hiragana: "せいぜいいっしゅうかんでおわるとおもいます。",
        romaji: "Seizei isshūkan de owaru to omoimasu.",
        traducao: "Acredito que termine em no máximo uma semana.",
      },
      {
        exemplo: "この店ではせいぜい500円まで値引きしてくれます。",
        hiragana: "このみせではせいぜいごひゃくえんまでねびきしてくれます。",
        romaji:
          "Kono mise de wa seizei go-hyaku en made nebiki shite kuremasu.",
        traducao:
          "Nesta loja, eles oferecem um desconto de no máximo 500 ienes.",
      },
    ],
  },
  {
    vocabulario: "そっくり",
    romaji: "sokkuri",
    hiragana: "そっくり",
    traducao: "parecido, semelhante",
    exemplosFrases: [
      {
        exemplo: "彼は父親にそっくりです。",
        hiragana: "かれはちちおやにそっくりです。",
        romaji: "Kare wa chichioya ni sokkuri desu.",
        traducao: "Ele se parece muito com o pai.",
      },
      {
        exemplo: "この写真は彼女にそっくりです。",
        hiragana: "このしゃしんはかのじょにそっくりです。",
        romaji: "Kono shashin wa kanojo ni sokkuri desu.",
        traducao: "Esta foto é muito parecida com ela.",
      },
      {
        exemplo: "この二つの建物はそっくりです。",
        hiragana: "このふたつのたてものはそっくりです。",
        romaji: "Kono futatsu no tatemono wa sokkuri desu.",
        traducao: "Esses dois prédios são muito parecidos.",
      },
    ],
  },
  {
    vocabulario: "そっと",
    romaji: "sotto",
    hiragana: "そっと",
    traducao: "silenciosamente, delicadamente",
    exemplosFrases: [
      {
        exemplo: "彼はそっとドアを開けました。",
        hiragana: "かれはそっとどあをあけました。",
        romaji: "Kare wa sotto doa o akemashita.",
        traducao: "Ele abriu a porta silenciosamente.",
      },
      {
        exemplo: "彼女はそっと手を握りました。",
        hiragana: "かのじょはそっとてをにぎりました。",
        romaji: "Kanojo wa sotto te o nigirimashita.",
        traducao: "Ela segurou delicadamente a mão.",
      },
      {
        exemplo: "彼はそっと話しかけました。",
        hiragana: "かれはそっとはなしかけました。",
        romaji: "Kare wa sotto hanashikakemashita.",
        traducao: "Ele sussurrou suavemente.",
      },
    ],
  },
  {
    vocabulario: "たっぷり",
    romaji: "tappuri",
    hiragana: "たっぷり",
    traducao: "abundante, cheio",
    exemplosFrases: [
      {
        exemplo: "たっぷり睡眠をとることが大切です。",
        hiragana: "たっぷりすいみんをとることがたいせつです。",
        romaji: "Tappuri suimin o toru koto ga taisetsu desu.",
        traducao: "É importante ter um sono abundante.",
      },
      {
        exemplo: "冷蔵庫にはたっぷりの食材があります。",
        hiragana: "れいぞうこにはたっぷりのしょくざいがあります。",
        romaji: "Reizōko ni wa tappuri no shokuzai ga arimasu.",
        traducao: "A geladeira está cheia de ingredientes.",
      },
      {
        exemplo: "たっぷり時間があるのでゆっくり考えましょう。",
        hiragana: "たっぷりじかんがあるのでゆっくりかんがえましょう。",
        romaji: "Tappuri jikan ga aru node yukkuri kangaemashō.",
        traducao: "Como temos bastante tempo, vamos pensar com calma.",
      },
    ],
  },
  {
    vocabulario: "たとえ",
    romaji: "tatoe",
    hiragana: "たとえ",
    traducao: "mesmo que, embora",
    exemplosFrases: [
      {
        exemplo: "たとえ雨が降っても、行きます。",
        hiragana: "たとえあめがふっても、いきます。",
        romaji: "Tatoe ame ga futte mo, ikimasu.",
        traducao: "Mesmo que esteja chovendo, eu irei.",
      },
      {
        exemplo: "たとえ時間がなくても、やり遂げます。",
        hiragana: "たとえじかんがなくても、やりとげます。",
        romaji: "Tatoe jikan ga nakute mo, yaritogemasu.",
        traducao: "Mesmo que não tenha tempo, eu vou conseguir.",
      },
      {
        exemplo: "たとえ上手くできなくても、頑張ります。",
        hiragana: "たとえうまくできなくても、がんばります。",
        romaji: "Tatoe umaku dekinakute mo, ganbarimasu.",
        traducao: "Mesmo que não consiga fazer bem, vou me esforçar.",
      },
    ],
  },
  {
    vocabulario: "たびたび",
    romaji: "tabitabi",
    hiragana: "たびたび",
    traducao: "repetidamente, frequentemente",
    exemplosFrases: [
      {
        exemplo: "彼はたびたび旅行に行きます。",
        hiragana: "かれはたびたびりょこうにいきます。",
        romaji: "Kare wa tabitabi ryokō ni ikimasu.",
        traducao: "Ele viaja frequentemente.",
      },
      {
        exemplo: "この問題はたびたび出題されます。",
        hiragana: "このもんだいはたびたびしゅつだいされます。",
        romaji: "Kono mondai wa tabitabi shutsudai saremasu.",
        traducao: "Este problema é frequentemente apresentado.",
      },
      {
        exemplo: "彼女はたびたび新しいアイデアを出します。",
        hiragana: "かのじょはたびたびあたらしいあいであをだします。",
        romaji: "Kanojo wa tabitabi atarashii aidea o dashimasu.",
        traducao: "Ela frequentemente apresenta novas ideias.",
      },
    ],
  },
  {
    vocabulario: "たまたま",
    romaji: "tamatama",
    hiragana: "たまたま",
    traducao: "por acaso, coincidentemente",
    exemplosFrases: [
      {
        exemplo: "たまたま友達と街で会いました。",
        hiragana: "たまたまともだちとまちであいました。",
        romaji: "Tamatama tomodachi to machi de aimashita.",
        traducao: "Por acaso, encontrei um amigo na cidade.",
      },
      {
        exemplo: "たまたま新しいレストランを見つけました。",
        hiragana: "たまたまあたらしいれすとらんをみつけました。",
        romaji: "Tamatama atarashii resutoran o mitsukemashita.",
        traducao: "Por acaso, encontrei um novo restaurante.",
      },
      {
        exemplo: "たまたまその映画をテレビで見ました。",
        hiragana: "たまたまそのえいがをてれびでみました。",
        romaji: "Tamatama sono eiga o terebi de mimashita.",
        traducao: "Por acaso, assisti a esse filme na TV.",
      },
    ],
  },
  {
    vocabulario: "ちゃんと",
    romaji: "chanto",
    hiragana: "ちゃんと",
    traducao: "adequadamente, corretamente",
    exemplosFrases: [
      {
        exemplo: "授業にちゃんと参加しましょう。",
        hiragana: "じゅぎょうにちゃんとさんかしましょう。",
        romaji: "Jugyō ni chanto sanka shimashō.",
        traducao: "Vamos participar das aulas corretamente.",
      },
      {
        exemplo: "ちゃんと予定を確認しましょう。",
        hiragana: "ちゃんとよていをかくにんしましょう。",
        romaji: "Chanto yotei o kakunin shimashō.",
        traducao: "Vamos verificar adequadamente o cronograma.",
      },
      {
        exemplo: "ちゃんと目標を設定しましょう。",
        hiragana: "ちゃんともくひょうをせっていしましょう。",
        romaji: "Chanto mokuhyō o settei shimashō.",
        traducao: "Vamos estabelecer corretamente os objetivos.",
      },
    ],
  },
  {
    vocabulario: "つまり",
    romaji: "tsumari",
    hiragana: "つまり",
    traducao: "em resumo, ou seja",
    exemplosFrases: [
      {
        exemplo: "彼は頭がいいです。つまり、勉強が得意です。",
        hiragana: "かれはあたまがいいです。つまり、べんきょうがとくいです。",
        romaji: "Kare wa atama ga ii desu. Tsumari, benkyō ga tokui desu.",
        traducao: "Ele é inteligente. Em resumo, é bom em estudos.",
      },
      {
        exemplo: "彼は忙しいです。つまり、時間がありません。",
        hiragana: "かれはいそがしいです。つまり、じかんがありません。",
        romaji: "Kare wa isogashii desu. Tsumari, jikan ga arimasen.",
        traducao: "Ele está ocupado. Em resumo, não tem tempo.",
      },
      {
        exemplo: "私たちは友達です。つまり、仲が良いです。",
        hiragana: "わたしたちはともだちです。つまり、なかがいいです。",
        romaji: "Watashitachi wa tomodachi desu. Tsumari, naka ga ii desu.",
        traducao: "Nós somos amigos. Em resumo, temos um bom relacionamento.",
      },
    ],
  },
  {
    vocabulario: "どう",
    romaji: "dō",
    hiragana: "どう",
    traducao: "como, de que maneira",
    exemplosFrases: [
      {
        exemplo: "これはどう使いますか？",
        hiragana: "これはどうつかいますか？",
        romaji: "Kore wa dō tsukaimasu ka?",
        traducao: "Como isso é usado?",
      },
      {
        exemplo: "どうしたらいいですか？",
        hiragana: "どうしたらいいですか？",
        romaji: "Dō shitara ii desu ka?",
        traducao: "O que devo fazer?",
      },
      {
        exemplo: "どう思いますか？",
        hiragana: "どうおもいますか？",
        romaji: "Dō omoimasu ka?",
        traducao: "O que você acha?",
      },
    ],
  },
  {
    vocabulario: "とにかく",
    romaji: "tonikaku",
    hiragana: "とにかく",
    traducao: "de qualquer maneira, de todo modo",
    exemplosFrases: [
      {
        exemplo: "忙しいけど、とにかくやってみます。",
        hiragana: "いそがしいけど、とにかくやってみます。",
        romaji: "Isogashii kedo, tonikaku yatte mimasu.",
        traducao: "Estou ocupado, mas de qualquer maneira vou tentar.",
      },
      {
        exemplo: "わからないけど、とにかく頑張ります。",
        hiragana: "わからないけど、とにかくがんばります。",
        romaji: "Wakaranai kedo, tonikaku ganbarimasu.",
        traducao: "Não entendo, mas de todo modo vou me esforçar.",
      },
      {
        exemplo: "うまくいかないけど、とにかくやってみよう。",
        hiragana: "うまくいかないけど、とにかくやってみよう。",
        romaji: "Umaku ikanai kedo, tonikaku yatte miyō.",
        traducao: "Não está indo bem, mas de qualquer modo, vamos tentar.",
      },
    ],
  },
  {
    vocabulario: "とんでも",
    romaji: "tondemo",
    hiragana: "とんでも",
    traducao: "impossível, inacreditável",
    exemplosFrases: [
      {
        exemplo: "とんでもないことが起きました。",
        hiragana: "とんでもないことがおきました。",
        romaji: "Tondemo nai koto ga okimashita.",
        traducao: "Aconteceu algo inacreditável.",
      },
      {
        exemplo: "とんでもない値段です。",
        hiragana: "とんでもないねだんです。",
        romaji: "Tondemo nai nedan desu.",
        traducao: "O preço é incrível.",
      },
      {
        exemplo: "とんでもない才能を持っています。",
        hiragana: "とんでもないさいのうをもっています。",
        romaji: "Tondemo nai sainō o motteimasu.",
        traducao: "Ele possui um talento incrível.",
      },
    ],
  },
  {
    vocabulario: "なぜ",
    romaji: "naze",
    hiragana: "なぜ",
    traducao: "por que, por qual motivo",
    exemplosFrases: [
      {
        exemplo: "なぜそんなことを言ったのですか？",
        hiragana: "なぜそんなことをいったのですか？",
        romaji: "Naze sonna koto o itta no desu ka?",
        traducao: "Por que você disse uma coisa dessas?",
      },
      {
        exemplo: "なぜ彼は遅刻したのですか？",
        hiragana: "なぜかれはちこくしたのですか？",
        romaji: "Naze kare wa chikoku shita no desu ka?",
        traducao: "Por que ele se atrasou?",
      },
      {
        exemplo: "なぜそれを選んだのですか？",
        hiragana: "なぜそれをえらんだのですか？",
        romaji: "Naze sore o eranda no desu ka?",
        traducao: "Por que você escolheu isso?",
      },
    ],
  },
  {
    vocabulario: "にっこり",
    romaji: "nikkori",
    hiragana: "にっこり",
    traducao: "sorridente, com um sorriso",
    exemplosFrases: [
      {
        exemplo: "彼女はにっこり笑って挨拶しました。",
        hiragana: "かのじょはにっこりわらってあいさつしました。",
        romaji: "Kanojo wa nikkori waratte aisatsu shimashita.",
        traducao: "Ela sorriu e cumprimentou.",
      },
      {
        exemplo: "彼はにっこりと微笑みました。",
        hiragana: "かれはにっこりとほほえみました。",
        romaji: "Kare wa nikkori to hohoemimashita.",
        traducao: "Ele sorriu levemente.",
      },
      {
        exemplo: "子供たちはにっこりと笑顔を見せました。",
        hiragana: "こどもたちはにっこりとえがおをみせました。",
        romaji: "Kodomotachi wa nikkori to egao o misemashita.",
        traducao: "As crianças mostraram sorrisos radiantes.",
      },
    ],
  },
  {
    vocabulario: "のんびり",
    romaji: "nonbiri",
    hiragana: "のんびり",
    traducao: "relaxado, despreocupado",
    exemplosFrases: [
      {
        exemplo: "休日はのんびり過ごしたいです。",
        hiragana: "きゅうじつはのんびりすごしたいです。",
        romaji: "Kyūjitsu wa nonbiri sugoshitai desu.",
        traducao: "Quero passar os dias de folga relaxado.",
      },
      {
        exemplo: "彼はのんびりとした性格です。",
        hiragana: "かれはのんびりとしたせいかくです。",
        romaji: "Kare wa nonbiri to shita seikaku desu.",
        traducao: "Ele tem uma personalidade relaxada.",
      },
      {
        exemplo: "のんびりした気分で散歩しました。",
        hiragana: "のんびりしたきぶんでさんぽしました。",
        romaji: "Nonbiri shita kibun de sanpo shimashita.",
        traducao: "Fiz uma caminhada despreocupada.",
      },
    ],
  },
  {
    vocabulario: "ぴったり",
    romaji: "pittari",
    hiragana: "ぴったり",
    traducao: "perfeitamente, exatamente",
    exemplosFrases: [
      {
        exemplo: "この靴はぴったり合っています。",
        hiragana: "このくつはぴったりあっています。",
        romaji: "Kono kutsu wa pittari atteimasu.",
        traducao: "Estes sapatos se encaixam perfeitamente.",
      },
      {
        exemplo: "彼女の提案はぴったりだと思います。",
        hiragana: "かのじょのていあんはぴったりだとおもいます。",
        romaji: "Kanojo no teian wa pittari da to omoimasu.",
        traducao: "Acho que a proposta dela é perfeita.",
      },
      {
        exemplo: "時間にぴったり間に合いました。",
        hiragana: "じかんにぴったりまにあいました。",
        romaji: "Jikan ni pittari manaimashita.",
        traducao: "Cheguei exatamente a tempo.",
      },
    ],
  },
  {
    vocabulario: "ふと",
    romaji: "futo",
    hiragana: "ふと",
    traducao: "de repente, por acaso",
    exemplosFrases: [
      {
        exemplo: "ふと窓の外を見たら、きれいな景色が広がっていました。",
        hiragana:
          "ふとまどのそとをみたら、きれいなけしきがひろがっていました。",
        romaji:
          "Futo mado no soto o mitara, kirei na keshiki ga hirogatte imashita.",
        traducao:
          "De repente, quando olhei para fora da janela, havia uma bela paisagem se estendendo.",
      },
      {
        exemplo: "ふと昔の友達のことを思い出しました。",
        hiragana: "ふとむかしのともだちのことをおもいだしました。",
        romaji: "Futo mukashi no tomodachi no koto o omoidashimashita.",
        traducao: "Por acaso, me lembrei de um antigo amigo.",
      },
      {
        exemplo: "ふと疑問に思ったことがあります。",
        hiragana: "ふとぎもんにおもったことがあります。",
        romaji: "Futo gimon ni omotta koto ga arimasu.",
        traducao: "De repente, tive uma dúvida.",
      },
    ],
  },
  {
    vocabulario: "ほぼ",
    romaji: "hobo",
    hiragana: "ほぼ",
    traducao: "quase, praticamente",
    exemplosFrases: [
      {
        exemplo: "ほぼ毎日日本語を勉強しています。",
        hiragana: "ほぼまいにちにほんごをべんきょうしています。",
        romaji: "Hobo mainichi nihongo o benkyō shiteimasu.",
        traducao: "Estudo japonês quase todos os dias.",
      },
      {
        exemplo: "その映画はほぼ3時間続きます。",
        hiragana: "そのえいがはほぼ3じかんつづきます。",
        romaji: "Sono eiga wa hobo san-jikan tsuzukimasu.",
        traducao: "Aquele filme dura quase 3 horas.",
      },
      {
        exemplo: "ほぼ全員が参加しました。",
        hiragana: "ほぼぜんいんがさんかしました。",
        romaji: "Hobo zen'in ga sanka shimashita.",
        traducao: "Quase todos participaram.",
      },
    ],
  },
  {
    vocabulario: "ぼんやり",
    romaji: "bonyari",
    hiragana: "ぼんやり",
    traducao: "distraído, vago",
    exemplosFrases: [
      {
        exemplo: "ぼんやりと空を見つめていた。",
        hiragana: "ぼんやりとそらをみつめていた。",
        romaji: "Bonyari to sora o mitsumete ita.",
        traducao: "Estava olhando distraído para o céu.",
      },
      {
        exemplo: "会議中、ぼんやりと考え事をしていた。",
        hiragana: "かいぎちゅう、ぼんやりとかんがえごとをしていた。",
        romaji: "Kaigi-chū, bonyari to kangae goto o shite ita.",
        traducao: "Durante a reunião, estava pensando em algo vagamente.",
      },
      {
        exemplo: "彼はぼんやりした表情をしていた。",
        hiragana: "かれはぼんやりしたひょうじょうをしていた。",
        romaji: "Kare wa bonyari shita hyōjō o shite ita.",
        traducao: "Ele tinha uma expressão vaga no rosto.",
      },
    ],
  },
  {
    vocabulario: "まさか",
    romaji: "masaka",
    hiragana: "まさか",
    traducao: "de jeito nenhum, impossível",
    exemplosFrases: [
      {
        exemplo: "まさか彼がそんなことをするなんて思わなかった。",
        hiragana: "まさかかれがそんなことをするなんておもわなかった。",
        romaji: "Masaka kare ga sonna koto o suru nante omowanakatta.",
        traducao: "Nunca imaginei que ele faria algo assim.",
      },
      {
        exemplo: "まさかあんなに早く到着するとは思わなかった。",
        hiragana: "まさかあんなにはやくとうちゃくするとはおもわなかった。",
        romaji: "Masaka anna ni hayaku tōchaku suru to wa omowanakatta.",
        traducao: "Nunca pensei que chegaria tão cedo assim.",
      },
      {
        exemplo: "まさか彼女がそんなことを知っているとは信じられない。",
        hiragana: "まさかかのじょがそんなことをしっているとはしんじられない。",
        romaji: "Masaka kanojo ga sonna koto o shitte iru to wa shinjirarenai.",
        traducao: "É impossível acreditar que ela saiba algo assim.",
      },
    ],
  },
  {
    vocabulario: "まさに",
    romaji: "masani",
    hiragana: "まさに",
    traducao: "exatamente, precisamente",
    exemplosFrases: [
      {
        exemplo: "その絵はまさに芸術だ。",
        hiragana: "そのえはまさにげいじゅつだ。",
        romaji: "Sono e wa masani geijutsu da.",
        traducao: "Essa pintura é exatamente uma obra de arte.",
      },
      {
        exemplo: "彼女の言葉はまさに私の心に響いた。",
        hiragana: "かのじょのことばはまさにわたしのこころにひびいた。",
        romaji: "Kanojo no kotoba wa masani watashi no kokoro ni hibiita.",
        traducao: "Suas palavras ressoaram exatamente em meu coração.",
      },
      {
        exemplo: "彼はまさに英雄と言える人物だ。",
        hiragana: "かれはまさにえいゆうといえるじんぶつだ。",
        romaji: "Kare wa masani eiyū to ieru jinbutsu da.",
        traducao: "Ele é exatamente uma figura heroica.",
      },
    ],
  },
  {
    vocabulario: "ますます",
    romaji: "masumasu",
    hiragana: "ますます",
    traducao: "cada vez mais",
    exemplosFrases: [
      {
        exemplo: "日本の人気がますます高まっている。",
        hiragana: "にほんのにんきがますますたかまっている。",
        romaji: "Nihon no ninki ga masumasu takamatte iru.",
        traducao: "A popularidade do Japão está cada vez maior.",
      },
      {
        exemplo: "勉強すればするほど、ますます分からなくなる。",
        hiragana: "べんきょうすればするほど、ますますわからなくなる。",
        romaji: "Benkyō sureba suru hodo, masumasu wakaranaku naru.",
        traducao: "Quanto mais estudo, menos entendo.",
      },
      {
        exemplo: "彼はますます頼もしくなっている。",
        hiragana: "かれはますますたのもしくなっている。",
        romaji: "Kare wa masumasu tayoroshiku natte iru.",
        traducao: "Ele está se tornando cada vez mais confiável.",
      },
    ],
  },
  {
    vocabulario: "まるで",
    romaji: "marude",
    hiragana: "まるで",
    traducao: "como, exatamente como",
    exemplosFrases: [
      {
        exemplo: "彼女の声はまるで天使のようだ。",
        hiragana: "かのじょのこえはまるでてんしのようだ。",
        romaji: "Kanojo no koe wa marude tenshi no yō da.",
        traducao: "A voz dela é como a de um anjo.",
      },
      {
        exemplo: "この本はまるで映画のような展開だ。",
        hiragana: "このほんはまるでえいがのようなてんかいだ。",
        romaji: "Kono hon wa marude eiga no yō na tenkai da.",
        traducao: "Este livro tem um enredo exatamente como o de um filme.",
      },
      {
        exemplo: "彼のダンスはまるでプロのようだ。",
        hiragana: "かれのだんすはまるでぷろのようだ。",
        romaji: "Kare no dansu wa marude puro no yō da.",
        traducao: "A dança dele é exatamente como a de um profissional.",
      },
    ],
  },
  {
    vocabulario: "むしろ",
    romaji: "mushiro",
    hiragana: "むしろ",
    traducao: "ao contrário, antes",
    exemplosFrases: [
      {
        exemplo: "彼の提案はむしろ良いと思います。",
        hiragana: "かれのていあんはむしろいいとおもいます。",
        romaji: "Kare no teian wa mushiro ii to omoimasu.",
        traducao: "Eu acho que a proposta dele é boa, ao contrário.",
      },
      {
        exemplo: "彼女はむしろ怒るより笑うことが多い。",
        hiragana: "かのじょはむしろおこるよりわらうことがおおい。",
        romaji: "Kanojo wa mushiro okoru yori warau koto ga ōi.",
        traducao: "Ela ri muito mais do que fica brava.",
      },
      {
        exemplo: "むしろ遅れてきて良かったと思います。",
        hiragana: "むしろおくれてきてよかったとおもいます。",
        romaji: "Mushiro okurete kite yokatta to omoimasu.",
        traducao: "Acho que foi bom ter chegado atrasado, na verdade.",
      },
    ],
  },
  {
    vocabulario: "もし",
    romaji: "moshi",
    hiragana: "もし",
    traducao: "se, caso",
    exemplosFrases: [
      {
        exemplo: "もし彼が来なかったら、私たちは行けません。",
        hiragana: "もしかれがこなかったら、わたしたちはいけません。",
        romaji: "Moshi kare ga konakattara, watashitachi wa ikemasen.",
        traducao: "Se ele não vier, não podemos ir.",
      },
      {
        exemplo: "もし時間があれば、映画を見に行きたいです。",
        hiragana: "もしじかんがあれば、えいがをみにいきたいです。",
        romaji: "Moshi jikan ga areba, eiga o mini ikitai desu.",
        traducao: "Se tiver tempo, gostaria de ir assistir a um filme.",
      },
      {
        exemplo: "もし明日雨が降ったら、ピクニックは中止です。",
        hiragana: "もしあしたあめがふったら、ぴくにっくはちゅうしです。",
        romaji: "Moshi ashita ame ga futtara, pikunikku wa chūshi desu.",
        traducao: "Se chover amanhã, o picnic será cancelado.",
      },
    ],
  },
  {
    vocabulario: "やがて",
    romaji: "yagate",
    hiragana: "やがて",
    traducao: "em breve, eventualmente",
    exemplosFrases: [
      {
        exemplo: "やがて夏が終わり、秋がやってくる。",
        hiragana: "やがてなつがおわり、あきがやってくる。",
        romaji: "Yagate natsu ga owari, aki ga yatte kuru.",
        traducao: "Em breve, o verão terminará e o outono chegará.",
      },
      {
        exemplo: "やがて彼は成功を収めた。",
        hiragana: "やがてかれはせいこうをおさめた。",
        romaji: "Yagate kare wa seikō o osameta.",
        traducao: "Eventualmente, ele alcançou o sucesso.",
      },
      {
        exemplo: "やがて彼女の努力が報われるだろう。",
        hiragana: "やがてかのじょのどりょくがむくわれるだろう。",
        romaji: "Yagate kanojo no doryoku ga mukuwareru darō.",
        traducao: "Em breve, os esforços dela serão recompensados.",
      },
    ],
  },
  {
    vocabulario: "やや",
    romaji: "yaya",
    hiragana: "やや",
    traducao: "um pouco, ligeiramente",
    exemplosFrases: [
      {
        exemplo: "彼の反応はやや冷たかった。",
        hiragana: "かれのはんのうはややつめたかった。",
        romaji: "Kare no hannō wa yaya tsumetakatta.",
        traducao: "A reação dele foi um pouco fria.",
      },
      {
        exemplo: "昨日よりやや涼しい気温です。",
        hiragana: "きのうよりややすずしいきおんです。",
        romaji: "Kinō yori yaya suzushii kion desu.",
        traducao: "A temperatura está um pouco mais fresca do que ontem.",
      },
      {
        exemplo: "この料理はやや辛めです。",
        hiragana: "このりょうりはややからめです。",
        romaji: "Kono ryōri wa yaya karame desu.",
        traducao: "Este prato está um pouco picante.",
      },
    ],
  },
  {
    vocabulario: "ゆっくり",
    romaji: "yukkuri",
    hiragana: "ゆっくり",
    traducao: "devagar, calmamente",
    exemplosFrases: [
      {
        exemplo: "ゆっくり話してください。",
        hiragana: "ゆっくりはなしてください。",
        romaji: "Yukkuri hanashite kudasai.",
        traducao: "Por favor, fale devagar.",
      },
      {
        exemplo: "ゆっくり歩いて公園まで行きましょう。",
        hiragana: "ゆっくりあるいてこうえんまでいきましょう。",
        romaji: "Yukkuri aruite kōen made ikimashō.",
        traducao: "Vamos caminhar calmamente até o parque.",
      },
      {
        exemplo: "ゆっくりとお茶を楽しんでください。",
        hiragana: "ゆっくりとおちゃをたのしんでください。",
        romaji: "Yukkuri to ocha o tanoshinde kudasai.",
        traducao: "Aproveite o chá calmamente.",
      },
    ],
  },
  {
    vocabulario: "ワイワイ",
    romaji: "waiwai",
    hiragana: "わいわい",
    traducao: "animado, barulhento (som de pessoas conversando)",
    exemplosFrases: [
      {
        exemplo: "パーティーでみんなワイワイしていた。",
        hiragana: "ぱーてぃーでみんなわいわいしていた。",
        romaji: "Pātī de minna waiwai shite ita.",
        traducao: "Todos estavam animados na festa.",
      },
      {
        exemplo: "公園で子供たちがワイワイ遊んでいる。",
        hiragana: "こうえんでこどもたちがわいわいあそんでいる。",
        romaji: "Kōen de kodomotachi ga waiwai asonde iru.",
        traducao: "As crianças estão brincando animadamente no parque.",
      },
      {
        exemplo: "カフェで友達とワイワイおしゃべりした。",
        hiragana: "かふぇでともだちとわいわいおしゃべりした。",
        romaji: "Kafe de tomodachi to waiwai oshaberi shita.",
        traducao: "Conversei animadamente com meus amigos em um café.",
      },
    ],
  },
  {
    vocabulario: "わざと",
    romaji: "wazato",
    hiragana: "わざと",
    traducao: "de propósito, intencionalmente",
    exemplosFrases: [
      {
        exemplo: "彼はわざと遅れてきた。",
        hiragana: "かれはわざとおくれてきた。",
        romaji: "Kare wa wazato okurete kita.",
        traducao: "Ele veio de propósito atrasado.",
      },
      {
        exemplo: "彼女はわざと私を無視した。",
        hiragana: "かのじょはわざとわたしをむしした。",
        romaji: "Kanojo wa wazato watashi o mushi shita.",
        traducao: "Ela me ignorou de propósito.",
      },
      {
        exemplo: "わざとらしく笑ってみせた。",
        hiragana: "わざとらしくわらってみせた。",
        romaji: "Wazato rashiku waratte miseta.",
        traducao: "Eu ri intencionalmente, de forma forçada.",
      },
    ],
  },
  {
    vocabulario: "わざわざ",
    romaji: "wazawaza",
    hiragana: "わざわざ",
    traducao: "de propósito, especialmente (fazer algo com esforço adicional)",
    exemplosFrases: [
      {
        exemplo: "わざわざありがとうと言ってくれた。",
        hiragana: "わざわざありがとうといってくれた。",
        romaji: "Wazawaza arigatō to itte kureta.",
        traducao: "Ele(a) fez questão de agradecer.",
      },
      {
        exemplo: "彼はわざわざプレゼントを買ってきてくれた。",
        hiragana: "かれはわざわざぷれぜんとをかってきてくれた。",
        romaji: "Kare wa wazawaza purezento o katte kite kureta.",
        traducao: "Ele(a) fez questão de comprar um presente para mim.",
      },
      {
        exemplo: "わざわざ遠くから来てくれた。",
        hiragana: "わざわざとおくからきてくれた。",
        romaji: "Wazawaza tōku kara kite kureta.",
        traducao: "Ele(a) fez questão de vir de longe.",
      },
    ],
  },
  {
    vocabulario: "わずか",
    romaji: "wazuka",
    hiragana: "わずか",
    traducao: "apenas, somente, mero",
    exemplosFrases: [
      {
        exemplo: "わずかな時間で終わりました。",
        hiragana: "わずかなじかんでおわりました。",
        romaji: "Wazuka na jikan de owarimashita.",
        traducao: "Foi concluído em apenas um curto período de tempo.",
      },
      {
        exemplo: "彼はわずかな経験しか持っていない。",
        hiragana: "かれはわずかなけいけんしかもっていない。",
        romaji: "Kare wa wazuka na keiken shika motte inai.",
        traducao: "Ele tem apenas pouca experiência.",
      },
      {
        exemplo: "彼女はわずかなお金で生活している。",
        hiragana: "かのじょはわずかなおかねでせいかつしている。",
        romaji: "Kanojo wa wazuka na okane de seikatsu shite iru.",
        traducao: "Ela está vivendo com apenas um pouco de dinheiro.",
      },
    ],
  },
  {
    vocabulario: "一層",
    romaji: "issō",
    hiragana: "いっそう",
    traducao: "ainda mais, de forma ainda mais",
    exemplosFrases: [
      {
        exemplo: "一層頑張ってください。",
        hiragana: "いっそうがんばってください。",
        romaji: "Issō ganbatte kudasai.",
        traducao: "Por favor, faça ainda mais esforço.",
      },
      {
        exemplo: "彼女は一層美しくなった。",
        hiragana: "かのじょはいっそううつくしくなった。",
        romaji: "Kanojo wa issō utsukushiku natta.",
        traducao: "Ela ficou ainda mais bonita.",
      },
      {
        exemplo: "一層注意が必要です。",
        hiragana: "いっそうちゅういがひつようです。",
        romaji: "Issō chūi ga hitsuyō desu.",
        traducao: "É necessário ter ainda mais cuidado.",
      },
    ],
  },
  {
    vocabulario: "主に",
    romaji: "omoni",
    hiragana: "おもに",
    traducao: "principalmente, principalmente",
    exemplosFrases: [
      {
        exemplo: "この本は主に子供向けです。",
        hiragana: "このほんはおもにこどもむけです。",
        romaji: "Kono hon wa omoni kodomo-muke desu.",
        traducao: "Este livro é principalmente para crianças.",
      },
      {
        exemplo: "彼は主に仕事に集中している。",
        hiragana: "かれはおもにしごとにしゅうちゅうしている。",
        romaji: "Kare wa omoni shigoto ni shūchū shite iru.",
        traducao: "Ele está principalmente focado no trabalho.",
      },
      {
        exemplo: "この地域は主に農業が盛んだ。",
        hiragana: "このちいきはおもにのうぎょうがさかんだ。",
        romaji: "Kono chiiki wa omoni nōgyō ga sakan da.",
        traducao: "Nesta região, a agricultura é principalmente próspera.",
      },
    ],
  },
  {
    vocabulario: "余り",
    romaji: "amari",
    hiragana: "あまり",
    traducao: "não muito, não frequentemente, sobra",
    exemplosFrases: [
      {
        exemplo: "彼はあまり勉強しません。",
        hiragana: "かれはあまりべんきょうしません。",
        romaji: "Kare wa amari benkyō shimasen.",
        traducao: "Ele não estuda muito.",
      },
      {
        exemplo: "私はあまり野菜が好きではありません。",
        hiragana: "わたしはあまりやさいがすきではありません。",
        romaji: "Watashi wa amari yasai ga suki de wa arimasen.",
        traducao: "Eu não gosto muito de legumes.",
      },
      {
        exemplo: "彼はあまり旅行に行かない。",
        hiragana: "かれはあまりりょこうにいかない。",
        romaji: "Kare wa amari ryokō ni ikanai.",
        traducao: "Ele não viaja muito frequentemente.",
      },
    ],
  },
  {
    vocabulario: "全く",
    romaji: "mattaku",
    hiragana: "まったく",
    traducao: "completamente, de todo, absolutamente",
    exemplosFrases: [
      {
        exemplo: "彼の言うことは全く正しくない。",
        hiragana: "かれのいうことはまったくただしくない。",
        romaji: "Kare no iu koto wa mattaku tadashikunai.",
        traducao: "O que ele diz está completamente errado.",
      },
      {
        exemplo: "その映画は全く面白くなかった。",
        hiragana: "そのえいがはまったくおもしろくなかった。",
        romaji: "Sono eiga wa mattaku omoshirokunakatta.",
        traducao: "Aquele filme não foi absolutamente interessante.",
      },
      {
        exemplo: "彼女は全く知りませんでした。",
        hiragana: "かのじょはまったくしりませんでした。",
        romaji: "Kanojo wa mattaku shirimasen deshita.",
        traducao: "Ela não sabia absolutamente nada sobre isso.",
      },
    ],
  },
  {
    vocabulario: "再び",
    romaji: "futatabi",
    hiragana: "ふたたび",
    traducao: "novamente, mais uma vez",
    exemplosFrases: [
      {
        exemplo: "再び会えてうれしいです。",
        hiragana: "ふたたびあえてうれしいです。",
        romaji: "Futatabi aete ureshii desu.",
        traducao: "Estou feliz em te ver novamente.",
      },
      {
        exemplo: "再び同じミスを繰り返さないように注意してください。",
        hiragana:
          "ふたたびおなじみすをくりかえさないようにちゅういしてください。",
        romaji: "Futatabi onaji misu o kurikaesanaigoyō ni chūi shite kudasai.",
        traducao:
          "Por favor, tome cuidado para não cometer o mesmo erro novamente.",
      },
      {
        exemplo: "再びこの場所に戻ってきました。",
        hiragana: "ふたたびこのばしょにもどってきました。",
        romaji: "Futatabi kono basho ni modotte kimashita.",
        traducao: "Voltei novamente para este lugar.",
      },
    ],
  },
  {
    vocabulario: "単に",
    romaji: "tan'ni",
    hiragana: "たんに",
    traducao: "simplesmente, apenas, meramente",
    exemplosFrases: [
      {
        exemplo: "彼は単に疲れていただけです。",
        hiragana: "かれはたんにつかれていただけです。",
        romaji: "Kare wa tan'ni tsukarete ita dake desu.",
        traducao: "Ele estava simplesmente cansado.",
      },
      {
        exemplo: "彼の言葉は単に冗談でした。",
        hiragana: "かれのことばはたんにじょうだんでした。",
        romaji: "Kare no kotoba wa tan'ni jōdan deshita.",
        traducao: "As palavras dele eram apenas uma brincadeira.",
      },
      {
        exemplo: "この問題は単に計算ミスです。",
        hiragana: "このもんだいはたんにけいさんみすです。",
        romaji: "Kono mondai wa tan'ni keisan misu desu.",
        traducao: "Este problema é simplesmente um erro de cálculo.",
      },
    ],
  },
  {
    vocabulario: "大いに",
    romaji: "ōini",
    hiragana: "おおいに",
    traducao: "muito, grandemente",
    exemplosFrases: [
      {
        exemplo: "彼は大いに喜んだ。",
        hiragana: "かれはおおいによろこんだ。",
        romaji: "Kare wa ōini yorokonda.",
        traducao: "Ele ficou muito feliz.",
      },
      {
        exemplo: "大いに努力して成功した。",
        hiragana: "おおいにどりょくしてせいこうした。",
        romaji: "Ōini doryoku shite seikō shita.",
        traducao: "Ele se esforçou muito e teve sucesso.",
      },
      {
        exemplo: "この映画は大いに感動させられた。",
        hiragana: "このえいがはおおいにかんどうさせられた。",
        romaji: "Kono eiga wa ōini kandō saserareta.",
        traducao: "Este filme me emocionou muito.",
      },
    ],
  },
  {
    vocabulario: "少し",
    romaji: "sukoshi",
    hiragana: "すこし",
    traducao: "um pouco",
    exemplosFrases: [
      {
        exemplo: "少し待ってください。",
        hiragana: "すこしまってください。",
        romaji: "Sukoshi matte kudasai.",
        traducao: "Por favor, espere um pouco.",
      },
      {
        exemplo: "少し時間があります。",
        hiragana: "すこしじかんがあります。",
        romaji: "Sukoshi jikan ga arimasu.",
        traducao: "Tenho um pouco de tempo.",
      },
      {
        exemplo: "少し休憩しましょう。",
        hiragana: "すこしきゅうけいしましょう。",
        romaji: "Sukoshi kyūkei shimashō.",
        traducao: "Vamos descansar um pouco.",
      },
    ],
  },
  {
    vocabulario: "幸い",
    romaji: "saiwai",
    hiragana: "さいわい",
    traducao: "felizmente, por sorte",
    exemplosFrases: [
      {
        exemplo: "幸いなことに、事故はありませんでした。",
        hiragana: "さいわいなことに、じこはありませんでした。",
        romaji: "Saiwai na koto ni, jiko wa arimasen deshita.",
        traducao: "Felizmente, não houve acidentes.",
      },
      {
        exemplo: "幸いなことに、問題は解決しました。",
        hiragana: "さいわいなことに、もんだいはかいけつしました。",
        romaji: "Saiwai na koto ni, mondai wa kaiketsu shimashita.",
        traducao: "Felizmente, o problema foi resolvido.",
      },
      {
        exemplo: "幸いなことに、天気がよくなりました。",
        hiragana: "さいわいなことに、てんきがよくなりました。",
        romaji: "Saiwai na koto ni, tenki ga yokunarimashita.",
        traducao: "Felizmente, o tempo melhorou.",
      },
    ],
  },
  {
    vocabulario: "当然",
    romaji: "tōzen",
    hiragana: "とうぜん",
    traducao: "naturalmente, evidentemente, obviamente",
    exemplosFrases: [
      {
        exemplo: "彼は当然のことを言った。",
        hiragana: "かれはとうぜんのことをいった。",
        romaji: "Kare wa tōzen no koto o itta.",
        traducao: "Ele disse algo óbvio.",
      },
      {
        exemplo: "当然の結果が出た。",
        hiragana: "とうぜんのけっかがでた。",
        romaji: "Tōzen no kekka ga deta.",
        traducao: "O resultado era esperado/natural.",
      },
      {
        exemplo: "その仕事は当然の義務です。",
        hiragana: "そのしごとはとうぜんのぎむです。",
        romaji: "Sono shigoto wa tōzen no gimu desu.",
        traducao: "Este trabalho é uma obrigação óbvia.",
      },
    ],
  },
  {
    vocabulario: "必ず",
    romaji: "kanarazu",
    hiragana: "かならず",
    traducao: "com certeza, sem falta",
    exemplosFrases: [
      {
        exemplo: "今晩、必ず電話します。",
        hiragana: "こんばん、かならずでんわします。",
        romaji: "Konban, kanarazu denwa shimasu.",
        traducao: "Vou ligar com certeza esta noite.",
      },
      {
        exemplo: "明日、必ず会いましょう。",
        hiragana: "あした、かならずあいましょう。",
        romaji: "Ashita, kanarazu aimashō.",
        traducao: "Vamos nos encontrar sem falta amanhã.",
      },
      {
        exemplo: "試験の準備は必ずしておきましょう。",
        hiragana: "しけんのじゅんびはかならずしておきましょう。",
        romaji: "Shiken no junbi wa kanarazu shite okimashō.",
        traducao: "Vamos definitivamente fazer os preparativos para o exame.",
      },
    ],
  },
  {
    vocabulario: "既に",
    romaji: "sudeni",
    hiragana: "すでに",
    traducao: "já, agora, desde já",
    exemplosFrases: [
      {
        exemplo: "彼は既に出発しました。",
        hiragana: "かれはすでにしゅっぱつしました。",
        romaji: "Kare wa sudeni shuppatsu shimashita.",
        traducao: "Ele já partiu.",
      },
      {
        exemplo: "その本は既に読みました。",
        hiragana: "そのほんはすでによみました。",
        romaji: "Sono hon wa sudeni yomimashita.",
        traducao: "Eu já li aquele livro.",
      },
      {
        exemplo: "既に決めてある計画があります。",
        hiragana: "すでにきめてあるけいかくがあります。",
        romaji: "Sudeni kimete aru keikaku ga arimasu.",
        traducao: "Já temos um plano decidido.",
      },
    ],
  },
  {
    vocabulario: "次々",
    romaji: "tsugitsugi",
    hiragana: "つぎつぎ",
    traducao: "um após o outro, sucessivamente",
    exemplosFrases: [
      {
        exemplo: "彼らは次々に部屋を出て行った。",
        hiragana: "かれらはつぎつぎにへやをでていった。",
        romaji: "Karera wa tsugitsugi ni heya o dete itta.",
        traducao: "Eles saíram dos quartos um após o outro.",
      },
      {
        exemplo: "新しい商品が次々と発売される。",
        hiragana: "あたらしいしょうひんがつぎつぎとはつばいされる。",
        romaji: "Atarashii shōhin ga tsugitsugi to hatsubai sareru.",
        traducao: "Novos produtos são lançados sucessivamente.",
      },
      {
        exemplo: "彼女は次々と仕事をこなしていく。",
        hiragana: "かのじょはつぎつぎとしごとをこなしていく。",
        romaji: "Kanojo wa tsugitsugi to shigoto o konashite iku.",
        traducao: "Ela vai realizando o trabalho um após o outro.",
      },
    ],
  },
  {
    vocabulario: "正直",
    romaji: "shōjiki",
    hiragana: "しょうじき",
    traducao: "honestamente, sinceramente",
    exemplosFrases: [
      {
        exemplo: "正直に言って、あまり好きではありません。",
        hiragana: "しょうじきにいって、あまりすきではありません。",
        romaji: "Shōjiki ni itte, amari suki de wa arimasen.",
        traducao: "Honestamente falando, eu não gosto muito.",
      },
      {
        exemplo: "正直な意見を言ってください。",
        hiragana: "しょうじきないけんをいってください。",
        romaji: "Shōjiki na iken o itte kudasai.",
        traducao: "Por favor, dê sua opinião sincera.",
      },
      {
        exemplo: "正直に謝罪しました。",
        hiragana: "しょうじきにしゃざいしました。",
        romaji: "Shōjiki ni shazai shimashita.",
        traducao: "Eu me desculpei sinceramente.",
      },
    ],
  },
  {
    vocabulario: "特に",
    romaji: "toku ni",
    hiragana: "とくに",
    traducao: "especialmente, particularmente",
    exemplosFrases: [
      {
        exemplo: "この店の料理は特においしいです。",
        hiragana: "このみせのりょうりはとくにおいしいです。",
        romaji: "Kono mise no ryōri wa toku ni oishii desu.",
        traducao: "A comida deste restaurante é especialmente deliciosa.",
      },
      {
        exemplo: "特に問題はありません。",
        hiragana: "とくにもんだいはありません。",
        romaji: "Toku ni mondai wa arimasen.",
        traducao: "Não há problemas em particular.",
      },
      {
        exemplo: "私は特に興味がありません。",
        hiragana: "わたしはとくにきょうみがありません。",
        romaji: "Watashi wa toku ni kyōmi ga arimasen.",
        traducao: "Eu não tenho interesse em particular.",
      },
    ],
  },
  {
    vocabulario: "直ちに",
    romaji: "tadachi ni",
    hiragana: "ただちに",
    traducao: "imediatamente",
    exemplosFrases: [
      {
        exemplo: "問題が発生したら、直ちに報告してください。",
        hiragana: "もんだいがはっせいしたら、ただちにほうこくしてください。",
        romaji: "Mondai ga hassei shitara, tadachi ni hōkoku shite kudasai.",
        traducao: "Se ocorrer algum problema, informe imediatamente.",
      },
      {
        exemplo: "直ちに対応する必要があります。",
        hiragana: "ただちにたいおうするひつようがあります。",
        romaji: "Tadachi ni taiō suru hitsuyō ga arimasu.",
        traducao: "Precisamos responder imediatamente.",
      },
      {
        exemplo: "直ちに出発しましょう。",
        hiragana: "ただちにしゅっぱつしましょう。",
        romaji: "Tadachi ni shuppatsu shimashō.",
        traducao: "Vamos partir imediatamente.",
      },
    ],
  },
  {
    vocabulario: "真っ直ぐ",
    romaji: "massugu",
    hiragana: "まっすぐ",
    traducao: "direto, em linha reta",
    exemplosFrases: [
      {
        exemplo: "まっすぐ前に進んでください。",
        hiragana: "まっすぐまえにすすんでください。",
        romaji: "Massugu mae ni susunde kudasai.",
        traducao: "Por favor, siga em frente.",
      },
      {
        exemplo: "彼はまっすぐに目的地に向かった。",
        hiragana: "かれはまっすぐにもくてきちにむかった。",
        romaji: "Kare wa massugu ni mokutekichi ni mukatta.",
        traducao: "Ele foi direto para o destino.",
      },
      {
        exemplo: "この道をまっすぐ進んでください。",
        hiragana: "このみちをまっすぐすすんでください。",
        romaji: "Kono michi o massugu susunde kudasai.",
        traducao: "Por favor, siga em linha reta nesta estrada.",
      },
    ],
  },
  {
    vocabulario: "突然",
    romaji: "totsuzen",
    hiragana: "とつぜん",
    traducao: "de repente, abruptamente",
    exemplosFrases: [
      {
        exemplo: "突然雨が降り始めた。",
        hiragana: "とつぜんあめがふりはじめた。",
        romaji: "Totsuzen ame ga furi hajimeta.",
        traducao: "Começou a chover de repente.",
      },
      {
        exemplo: "彼は突然立ち上がり、去っていった。",
        hiragana: "かれはとつぜんたちあがり、さっていった。",
        romaji: "Kare wa totsuzen tachiagari, satte itta.",
        traducao: "Ele se levantou de repente e saiu.",
      },
      {
        exemplo: "突然気分が悪くなりました。",
        hiragana: "とつぜんきぶんがわるくなりました。",
        romaji: "Totsuzen kibun ga waruku narimashita.",
        traducao: "De repente, me senti mal.",
      },
    ],
  },
  {
    vocabulario: "言わば",
    romaji: "iwaba",
    hiragana: "いわば",
    traducao: "pode-se dizer que, por assim dizer",
    exemplosFrases: [
      {
        exemplo: "彼は言わば天才だ。",
        hiragana: "かれはいわばてんさいだ。",
        romaji: "Kare wa iwaba tensai da.",
        traducao: "Ele é, por assim dizer, um gênio.",
      },
      {
        exemplo: "それは言わば一生の思い出だ。",
        hiragana: "それはいわばいっしょうのおもいでだ。",
        romaji: "Sore wa iwaba isshō no omoide da.",
        traducao: "Isso é, por assim dizer, uma memória para toda a vida.",
      },
      {
        exemplo: "この展覧会は言わば芸術の祭典です。",
        hiragana: "このてんらんかいはいわばげいじゅつのさいてんです。",
        romaji: "Kono tenrankai wa iwaba geijutsu no saiten desu.",
        traducao: "Esta exposição é, por assim dizer, um festival de arte.",
      },
    ],
  },
  {
    vocabulario: "遂に",
    romaji: "tsui ni",
    hiragana: "ついに",
    traducao: "finalmente, enfim",
    exemplosFrases: [
      {
        exemplo: "ついに目標を達成した。",
        hiragana: "ついにもくひょうをたっせいした。",
        romaji: "Tsui ni mokuhyō o tassei shita.",
        traducao: "Finalmente, alcancei meu objetivo.",
      },
      {
        exemplo: "ついに念願の夢を叶えた。",
        hiragana: "ついにねんがんのゆめをかなえた。",
        romaji: "Tsui ni nengan no yume o kanaeta.",
        traducao: "Finalmente, realizei meu sonho de longa data.",
      },
      {
        exemplo: "ついに春がやってきた。",
        hiragana: "ついにはるがやってきた。",
        romaji: "Tsui ni haru ga yattekita.",
        traducao: "Finalmente, a primavera chegou.",
      },
    ],
  },
  {
    vocabulario: "随分",
    romaji: "zuibun",
    hiragana: "ずいぶん",
    traducao: "bastante, consideravelmente",
    exemplosFrases: [
      {
        exemplo: "彼女は随分上手になった。",
        hiragana: "かのじょはずいぶんじょうずになった。",
        romaji: "Kanojo wa zuibun jōzu ni natta.",
        traducao: "Ela melhorou bastante.",
      },
      {
        exemplo: "随分長い時間待たされました。",
        hiragana: "ずいぶんながいじかんまたされました。",
        romaji: "Zuibun nagai jikan mata saremashita.",
        traducao: "Fui obrigado a esperar por um longo tempo.",
      },
      {
        exemplo: "随分楽しいパーティーだった。",
        hiragana: "ずいぶんたのしいぱーてぃーだった。",
        romaji: "Zuibun tanoshii pātī datta.",
        traducao: "A festa foi bastante divertida.",
      },
    ],
  },
  {
    vocabulario: "あんな",
    romaji: "anna",
    hiragana: "あんな",
    traducao: "daquele tipo, assim",
    exemplosFrases: [
      {
        exemplo: "あんな人とは一緒に仕事したくない。",
        hiragana: "あんなひととはいっしょにしごとしたくない。",
        romaji: "Anna hito to wa issho ni shigoto shitakunai.",
        traducao: "Não quero trabalhar com alguém assim.",
      },
      {
        exemplo: "あんな場所に行きたくありません。",
        hiragana: "あんなばしょにいきたくありません。",
        romaji: "Anna basho ni ikitaku arimasen.",
        traducao: "Não quero ir para um lugar assim.",
      },
      {
        exemplo: "あんなに美しい景色を見たことがありません。",
        hiragana: "あんなにうつくしいけしきをみたことがありません。",
        romaji: "Anna ni utsukushii keshiki o mita koto ga arimasen.",
        traducao: "Nunca vi uma paisagem tão bonita assim.",
      },
    ],
  },
  {
    vocabulario: "かわいそう",
    romaji: "kawaisō",
    hiragana: "かわいそう",
    traducao: "coitado(a), com pena",
    exemplosFrases: [
      {
        exemplo: "かわいそうな犬が道で迷っている。",
        hiragana: "かわいそうないぬがみちでまよっている。",
        romaji: "Kawaisō na inu ga michi de mayotte iru.",
        traducao: "Um pobre cachorro está perdido na rua.",
      },
      {
        exemplo: "彼はかわいそうな境遇に置かれている。",
        hiragana: "かれはかわいそうなきょうぐうにおかれている。",
        romaji: "Kare wa kawaisō na kyōguu ni okarete iru.",
        traducao: "Ele está em uma situação lamentável.",
      },
      {
        exemplo: "かわいそうな子供たちにおもちゃをプレゼントしました。",
        hiragana: "かわいそうなこどもたちにおもちゃをぷれぜんとしました。",
        romaji: "Kawaisō na kodomo-tachi ni omocha o purezento shimashita.",
        traducao: "Presentei as pobres crianças com brinquedos.",
      },
    ],
  },
  {
    vocabulario: "グローバル",
    romaji: "gurōbaru",
    hiragana: "ぐろーばる",
    traducao: "global",
    exemplosFrases: [
      {
        exemplo: "グローバルな市場で競争する。",
        hiragana: "ぐろーばるなしじょうできょうそうする。",
        romaji: "Gurōbaru na shijō de kyōsō suru.",
        traducao: "Competir no mercado global.",
      },
      {
        exemplo: "グローバルな視点から問題を考える。",
        hiragana: "ぐろーばるなしてんからもんだいをかんがえる。",
        romaji: "Gurōbaru na shiten kara mondai o kangaeru.",
        traducao: "Pensar nos problemas de uma perspectiva global.",
      },
      {
        exemplo: "グローバル企業との取引を拡大する。",
        hiragana: "ぐろーばるきぎょうとのとりひきをかくだいする。",
        romaji: "Gurōbaru kigyō to no torihiki o kakudai suru.",
        traducao: "Expandir o negócio com empresas globais.",
      },
    ],
  },
  {
    vocabulario: "こんな",
    romaji: "konna",
    hiragana: "こんな",
    traducao: "assim, deste tipo",
    exemplosFrases: [
      {
        exemplo: "こんなに美しい景色を見たことがない。",
        hiragana: "こんなにうつくしいけしきをみたことがない。",
        romaji: "Konna ni utsukushii keshiki o mita koto ga nai.",
        traducao: "Nunca vi uma paisagem tão bonita assim.",
      },
      {
        exemplo: "こんな美味しい料理は初めて食べた。",
        hiragana: "こんなおいしいりょうりははじめてたべた。",
        romaji: "Konna oishii ryōri wa hajimete tabeta.",
        traducao: "Foi a primeira vez que comi uma comida tão deliciosa assim.",
      },
      {
        exemplo: "こんな問題は簡単に解けるはずだ。",
        hiragana: "こんなもんだいはかんたんにとけるはずだ。",
        romaji: "Konna mondai wa kantan ni tokeru hazu da.",
        traducao: "Este tipo de problema deveria ser fácil de resolver.",
      },
    ],
  },
  {
    vocabulario: "ドロドロ",
    romaji: "dorodoro",
    hiragana: "どろどろ",
    traducao: "pegajoso, viscoso",
    exemplosFrases: [
      {
        exemplo: "雨で道がドロドロになった。",
        hiragana: "あめでみちがどろどろになった。",
        romaji: "Ame de michi ga dorodoro ni natta.",
        traducao: "A estrada ficou lamacenta devido à chuva.",
      },
      {
        exemplo: "ドロドロの泥に足を取られた。",
        hiragana: "どろどろのどろにあしをとられた。",
        romaji: "Dorodoro no doro ni ashi o torareta.",
        traducao: "Meus pés ficaram presos na lama viscosa.",
      },
      {
        exemplo: "ドロドロした液体をかき混ぜた。",
        hiragana: "どろどろしたえきたいをかきまぜた。",
        romaji: "Dorodoro shita ekitai o kakimazeta.",
        traducao: "Mexi um líquido espesso e viscoso.",
      },
    ],
  },
  {
    vocabulario: "どんな",
    romaji: "donna",
    hiragana: "どんな",
    traducao: "que tipo de, que tipo de",
    exemplosFrases: [
      {
        exemplo: "どんな本を読みますか？",
        hiragana: "どんなほんをよみますか？",
        romaji: "Donna hon o yomimasu ka?",
        traducao: "Que tipo de livro você lê?",
      },
      {
        exemplo: "どんな映画が好きですか？",
        hiragana: "どんなえいががすきですか？",
        romaji: "Donna eiga ga suki desu ka?",
        traducao: "Que tipo de filme você gosta?",
      },
      {
        exemplo: "どんな食べ物が好きですか？",
        hiragana: "どんなたべものがすきですか？",
        romaji: "Donna tabemono ga suki desu ka?",
        traducao: "Que tipo de comida você gosta?",
      },
    ],
  },
  {
    vocabulario: "ばらばら",
    romaji: "barabara",
    hiragana: "ばらばら",
    traducao: "separado, disperso",
    exemplosFrases: [
      {
        exemplo: "書類がばらばらになってしまった。",
        hiragana: "しょるいがばらばらになってしまった。",
        romaji: "Shorui ga barabara ni natte shimatta.",
        traducao: "Os documentos ficaram espalhados/dispersos.",
      },
      {
        exemplo: "彼らはばらばらになって各々の道を歩んだ。",
        hiragana: "かれらはばらばらになっておのおののみちをあるんだ。",
        romaji: "Karera wa barabara ni natte onoono no michi o ayunda.",
        traducao: "Eles se separaram e seguiram caminhos diferentes.",
      },
      {
        exemplo: "仲間たちはばらばらに散りました。",
        hiragana: "なかまたちはばらばらにちりました。",
        romaji: "Nakama-tachi wa barabara ni chirimashita.",
        traducao: "Os membros do grupo se dispersaram.",
      },
    ],
  },
  {
    vocabulario: "めった",
    romaji: "metta",
    hiragana: "めった",
    traducao: "frequente, constante",
    exemplosFrases: [
      {
        exemplo: "彼はめったに遅刻しない。",
        hiragana: "かれはめったにちこくしない。",
        romaji: "Kare wa metta ni chikoku shinai.",
        traducao: "Ele raramente se atrasa.",
      },
      {
        exemplo: "めったに外食しない。",
        hiragana: "めったにがいしょくしない。",
        romaji: "Metta ni gaishoku shinai.",
        traducao: "Raramente como fora de casa.",
      },
      {
        exemplo: "彼女はめったに笑わない。",
        hiragana: "かのじょはめったにわらない。",
        romaji: "Kanojo wa metta ni waranai.",
        traducao: "Ela raramente ri.",
      },
    ],
  },
  {
    vocabulario: "不要",
    romaji: "fuyō",
    hiragana: "ふよう",
    traducao: "desnecessário, não é preciso",
    exemplosFrases: [
      {
        exemplo: "これはもう不要です。",
        hiragana: "これはもうふようです。",
        romaji: "Kore wa mō fuyō desu.",
        traducao: "Isso não é mais necessário.",
      },
      {
        exemplo: "その情報は不要です。",
        hiragana: "そのじょうほうはふようです。",
        romaji: "Sono jōhō wa fuyō desu.",
        traducao: "Essa informação não é necessária.",
      },
      {
        exemplo: "不要な荷物を持っている。",
        hiragana: "ふようなにもつをもっている。",
        romaji: "Fuyō na nimotsu o motte iru.",
        traducao: "Tenho bagagem desnecessária.",
      },
    ],
  },
  {
    vocabulario: "主要",
    romaji: "shuyō",
    hiragana: "しゅよう",
    traducao: "principal, principal",
    exemplosFrases: [
      {
        exemplo: "これが主要なポイントです。",
        hiragana: "これがしゅようなぽいんとです。",
        romaji: "Kore ga shuyō na pointo desu.",
        traducao: "Este é o ponto principal.",
      },
      {
        exemplo: "主要な問題に取り組む。",
        hiragana: "しゅようなもんだいにとりくむ。",
        romaji: "Shuyō na mondai ni torikumu.",
        traducao: "Lidar com questões principais.",
      },
      {
        exemplo: "その会議は主要メンバーのみ参加です。",
        hiragana: "そのかいぎはしゅようめんばーのみさんかです。",
        romaji: "Sono kaigi wa shuyō menbā nomi sanka desu.",
        traducao: "A reunião é apenas para membros principais.",
      },
    ],
  },
  {
    vocabulario: "偉大",
    romaji: "idai",
    hiragana: "いだい",
    traducao: "grande, magnífico",
    exemplosFrases: [
      {
        exemplo: "彼は偉大なリーダーです。",
        hiragana: "かれはいだいなりーだーです。",
        romaji: "Kare wa idai na rīdā desu.",
        traducao: "Ele é um grande líder.",
      },
      {
        exemplo: "偉大な功績を残した。",
        hiragana: "いだいなこうせきをのこした。",
        romaji: "Idai na kōseki o nokoshita.",
        traducao: "Ele deixou um grande legado.",
      },
      {
        exemplo: "偉大な芸術家として認められた。",
        hiragana: "いだいなげいじゅつかとしてみとめられた。",
        romaji: "Idai na geijutsuka toshite mitomerareta.",
        traducao: "Ele foi reconhecido como um grande artista.",
      },
    ],
  },
  {
    vocabulario: "優秀",
    romaji: "yūshū",
    hiragana: "ゆうしゅう",
    traducao: "excelente, talentoso",
    exemplosFrases: [
      {
        exemplo: "彼は非常に優秀な学生です。",
        hiragana: "かれはひじょうにゆうしゅうながくせいです。",
        romaji: "Kare wa hijō ni yūshū na gakusei desu.",
        traducao: "Ele é um estudante muito talentoso.",
      },
      {
        exemplo: "優秀な成績を収める。",
        hiragana: "ゆうしゅうなせいせきをおさめる。",
        romaji: "Yūshū na seiseki o osameru.",
        traducao: "Alcançar excelentes resultados acadêmicos.",
      },
      {
        exemplo: "彼女は優秀なリーダーシップを持っている。",
        hiragana: "かのじょはゆうしゅうなりーだーしっぷをもっている。",
        romaji: "Kanojo wa yūshū na rīdāshippu o motte iru.",
        traducao: "Ela possui excelentes habilidades de liderança.",
      },
    ],
  },
  {
    vocabulario: "利口",
    romaji: "rikō",
    hiragana: "りこう",
    traducao: "inteligente, esperto",
    exemplosFrases: [
      {
        exemplo: "彼は利口な決断をした。",
        hiragana: "かれはりこうなけつだんをした。",
        romaji: "Kare wa rikō na ketsudan o shita.",
        traducao: "Ele tomou uma decisão inteligente.",
      },
      {
        exemplo: "利口な策略を考える。",
        hiragana: "りこうなさくりゃくをかんがえる。",
        romaji: "Rikō na sakuryaku o kangaeru.",
        traducao: "Pensar em estratégias inteligentes.",
      },
      {
        exemplo: "彼女は利口で面白い。",
        hiragana: "かのじょはりこうでおもしろい。",
        romaji: "Kanojo wa rikō de omoshiroi.",
        traducao: "Ela é inteligente e engraçada.",
      },
    ],
  },
  {
    vocabulario: "単純",
    romaji: "tanjun",
    hiragana: "たんじゅん",
    traducao: "simples, fácil",
    exemplosFrases: [
      {
        exemplo: "この問題は単純です。",
        hiragana: "このもんだいはたんじゅんです。",
        romaji: "Kono mondai wa tanjun desu.",
        traducao: "Este problema é simples.",
      },
      {
        exemplo: "単純な手順に従ってください。",
        hiragana: "たんじゅんなてじゅんにしたがってください。",
        romaji: "Tanjun na tejun ni shitagatte kudasai.",
        traducao: "Siga as instruções simples.",
      },
      {
        exemplo: "単純な誤解が生じました。",
        hiragana: "たんじゅんなごかいがしょうじました。",
        romaji: "Tanjun na gokai ga shōjimashita.",
        traducao: "Ocorreu um simples mal-entendido.",
      },
    ],
  },
  {
    vocabulario: "可能",
    romaji: "kanō",
    hiragana: "かのう",
    traducao: "possível, capaz",
    exemplosFrases: [
      {
        exemplo: "彼は不可能を可能にした。",
        hiragana: "かれはふかのうをかのうにした。",
        romaji: "Kare wa fukanō o kanō ni shita.",
        traducao: "Ele tornou o impossível possível.",
      },
      {
        exemplo: "それは可能性がある。",
        hiragana: "それはかのうせいがある。",
        romaji: "Sore wa kanōsei ga aru.",
        traducao: "Isso é possível.",
      },
      {
        exemplo: "可能な限り努力しよう。",
        hiragana: "かのうなかぎりどりょくしよう。",
        romaji: "Kanōna kagiri doryoku shiyō.",
        traducao: "Vamos nos esforçar o máximo possível.",
      },
    ],
  },
  {
    vocabulario: "同一",
    romaji: "dōitsu",
    hiragana: "どういつ",
    traducao: "o mesmo, idêntico",
    exemplosFrases: [
      {
        exemplo: "彼らは同一の意見を持っている。",
        hiragana: "かれらはどういつのいけんをもっている。",
        romaji: "Karera wa dōitsu no iken o motte iru.",
        traducao: "Eles têm a mesma opinião.",
      },
      {
        exemplo: "同一の問題が再発生した。",
        hiragana: "どういつのもんだいがさいはっせいした。",
        romaji: "Dōitsu no mondai ga saihassei shita.",
        traducao: "O mesmo problema ocorreu novamente.",
      },
      {
        exemplo: "この二つの言葉は意味が同一だ。",
        hiragana: "このふたつのことばはいみがどういつだ。",
        romaji: "Kono futatsu no kotoba wa imi ga dōitsu da.",
        traducao: "Essas duas palavras têm o mesmo significado.",
      },
    ],
  },
  {
    vocabulario: "同様",
    romaji: "dōyō",
    hiragana: "どうよう",
    traducao: "similar, semelhante",
    exemplosFrases: [
      {
        exemplo: "彼は父親と同様に優しい。",
        hiragana: "かれはちちおやとどうようにやさしい。",
        romaji: "Kare wa chichioya to dōyō ni yasashii.",
        traducao: "Ele é gentil assim como o pai.",
      },
      {
        exemplo: "彼女の意見は私と同様だ。",
        hiragana: "かのじょのいけんはわたしとどうようだ。",
        romaji: "Kanojo no iken wa watashi to dōyō da.",
        traducao: "A opinião dela é semelhante à minha.",
      },
      {
        exemplo: "私たちは同様の目標を持っています。",
        hiragana: "わたしたちはどうようのもくひょうをもっています。",
        romaji: "Watashitachi wa dōyō no mokuhyō o motte imasu.",
        traducao: "Nós temos objetivos semelhantes.",
      },
    ],
  },
  {
    vocabulario: "器用",
    romaji: "kiyō",
    hiragana: "きよう",
    traducao: "habilidoso, ágil",
    exemplosFrases: [
      {
        exemplo: "彼は手先が器用だ。",
        hiragana: "かれはてさきがきようだ。",
        romaji: "Kare wa tesaki ga kiyō da.",
        traducao: "Ele é habilidoso com as mãos.",
      },
      {
        exemplo: "彼女は料理がとても器用だ。",
        hiragana: "かのじょはりょうりがとてもきようだ。",
        romaji: "Kanojo wa ryōri ga totemo kiyō da.",
        traducao: "Ela é muito habilidosa na cozinha.",
      },
      {
        exemplo: "彼はスポーツがとても器用だ。",
        hiragana: "かれはすぽーつがとてもきようだ。",
        romaji: "Kare wa supōtsu ga totemo kiyō da.",
        traducao: "Ele é muito ágil nos esportes.",
      },
    ],
  },
  {
    vocabulario: "夢中",
    romaji: "muchū",
    hiragana: "むちゅう",
    traducao: "absorvido, obcecado",
    exemplosFrases: [
      {
        exemplo: "彼はゲームに夢中になっている。",
        hiragana: "かれはげーむにむちゅうになっている。",
        romaji: "Kare wa gēmu ni muchū ni natte iru.",
        traducao: "Ele está obcecado por jogos.",
      },
      {
        exemplo: "彼女は読書に夢中だ。",
        hiragana: "かのじょはどくしょにむちゅうだ。",
        romaji: "Kanojo wa dokusho ni muchū da.",
        traducao: "Ela está absorvida na leitura.",
      },
      {
        exemplo: "彼は音楽に夢中になっている。",
        hiragana: "かれはおんがくにむちゅうになっている。",
        romaji: "Kare wa ongaku ni muchū ni natte iru.",
        traducao: "Ele está obcecado por música.",
      },
    ],
  },
  {
    vocabulario: "奇妙",
    romaji: "kimyō",
    hiragana: "きみょう",
    traducao: "estranho, peculiar",
    exemplosFrases: [
      {
        exemplo: "この映画は奇妙なストーリーを持っている。",
        hiragana: "このえいがはきみょうなすとーりーをもっている。",
        romaji: "Kono eiga wa kimyō na sutōrī o motte iru.",
        traducao: "Este filme tem uma história estranha.",
      },
      {
        exemplo: "彼は奇妙な習慣を持っている。",
        hiragana: "かれはきみょうなしゅうかんをもっている。",
        romaji: "Kare wa kimyō na shūkan o motte iru.",
        traducao: "Ele tem um hábito estranho.",
      },
      {
        exemplo: "奇妙な音が聞こえた。",
        hiragana: "きみょうなおとがきこえた。",
        romaji: "Kimyō na oto ga kikoeta.",
        traducao: "Ouvi um som estranho.",
      },
    ],
  },
  {
    vocabulario: "嫌",
    romaji: "iya",
    hiragana: "いや",
    traducao: "desagradável, odiar",
    exemplosFrases: [
      {
        exemplo: "彼女は嫌な人だ。",
        hiragana: "かのじょはいやなひとだ。",
        romaji: "Kanojo wa iya na hito da.",
        traducao: "Ela é uma pessoa desagradável.",
      },
      {
        exemplo: "この匂いが嫌だ。",
        hiragana: "このにおいがいやだ。",
        romaji: "Kono nioi ga iya da.",
        traducao: "Eu não gosto desse cheiro.",
      },
      {
        exemplo: "彼は嫌なことを言った。",
        hiragana: "かれはいやなことをいった。",
        romaji: "Kare wa iya na koto o itta.",
        traducao: "Ele disse algo desagradável.",
      },
    ],
  },
  {
    vocabulario: "完全",
    romaji: "kanzen",
    hiragana: "かんぜん",
    traducao: "completo, perfeito",
    exemplosFrases: [
      {
        exemplo: "彼は完全に間違っている。",
        hiragana: "かれはかんぜんにまちがっている。",
        romaji: "Kare wa kanzen ni machigatte iru.",
        traducao: "Ele está completamente errado.",
      },
      {
        exemplo: "これは完全な解決策だ。",
        hiragana: "これはかんぜんなかいけつさくだ。",
        romaji: "Kore wa kanzen na kaiketsusaku da.",
        traducao: "Esta é uma solução completa.",
      },
      {
        exemplo: "彼女の演技は完全だ。",
        hiragana: "かのじょのえんぎはかんぜんだ。",
        romaji: "Kanojo no engi wa kanzen da.",
        traducao: "Sua atuação é perfeita.",
      },
    ],
  },
  {
    vocabulario: "容易",
    romaji: "yōi",
    hiragana: "ようい",
    traducao: "fácil, simples",
    exemplosFrases: [
      {
        exemplo: "この問題は容易に解ける。",
        hiragana: "このもんだいはよういにとける。",
        romaji: "Kono mondai wa yōi ni tokeru.",
        traducao: "Este problema é facilmente resolvível.",
      },
      {
        exemplo: "容易な質問に答える。",
        hiragana: "よういなしつもんにこたえる。",
        romaji: "Yōi na shitsumon ni kotaeru.",
        traducao: "Responder a perguntas simples.",
      },
      {
        exemplo: "彼は容易に成功した。",
        hiragana: "かれはよういにせいこうした。",
        romaji: "Kare wa yōi ni seikō shita.",
        traducao: "Ele obteve sucesso facilmente.",
      },
    ],
  },
  {
    vocabulario: "巨大",
    romaji: "kyodai",
    hiragana: "きょだい",
    traducao: "enorme, gigante",
    exemplosFrases: [
      {
        exemplo: "巨大な建物がそびえ立っている。",
        hiragana: "きょだいなたてものがそびえたっている。",
        romaji: "Kyodai na tatemono ga sobietatte iru.",
        traducao: "Um prédio enorme se ergue.",
      },
      {
        exemplo: "巨大な力を持っている。",
        hiragana: "きょだいなちからをもっている。",
        romaji: "Kyodai na chikara o motte iru.",
        traducao: "Ele possui um poder gigantesco.",
      },
      {
        exemplo: "巨大な影響を与える。",
        hiragana: "きょだいなえいきょうをあたえる。",
        romaji: "Kyodai na eikyō o ataeru.",
        traducao: "Exercer uma influência enorme.",
      },
    ],
  },
  {
    vocabulario: "強力",
    romaji: "kyōryoku",
    hiragana: "きょうりょく",
    traducao: "poderoso, forte",
    exemplosFrases: [
      {
        exemplo: "彼は強力なリーダーシップを持っている。",
        hiragana: "かれはきょうりょくなりーだーしっぷをもっている。",
        romaji: "Kare wa kyōryoku na rīdāshippu o motte iru.",
        traducao: "Ele possui uma liderança poderosa.",
      },
      {
        exemplo: "強力な支援を受ける。",
        hiragana: "きょうりょくなしえんをうける。",
        romaji: "Kyōryoku na shien o ukeru.",
        traducao: "Receber um apoio forte.",
      },
      {
        exemplo: "彼は強力な競争相手だ。",
        hiragana: "かれはきょうりょくなきょうそうあいてだ。",
        romaji: "Kare wa kyōryoku na kyōsō aite da.",
        traducao: "Ele é um concorrente poderoso.",
      },
    ],
  },
  {
    vocabulario: "微妙",
    romaji: "bimyō",
    hiragana: "びみょう",
    traducao: "sutil, delicado",
    exemplosFrases: [
      {
        exemplo: "彼の反応は微妙だった。",
        hiragana: "かれのはんのうはびみょうだった。",
        romaji: "Kare no hannō wa bimyō datta.",
        traducao: "A reação dele foi sutil.",
      },
      {
        exemplo: "微妙なニュアンスを理解する。",
        hiragana: "びみょうなにゅあんすをりかいする。",
        romaji: "Bimyō na nyuansu o rikai suru.",
        traducao: "Compreender nuances sutis.",
      },
      {
        exemplo: "彼女の表情は微妙だ。",
        hiragana: "かのじょのひょうじょうはびみょうだ。",
        romaji: "Kanojo no hyōjō wa bimyō da.",
        traducao: "A expressão dela é delicada.",
      },
    ],
  },
  {
    vocabulario: "必死",
    romaji: "hisshi",
    hiragana: "ひっし",
    traducao: "desesperado, empenhado",
    exemplosFrases: [
      {
        exemplo: "彼は必死に勉強した。",
        hiragana: "かれはひっしにべんきょうした。",
        romaji: "Kare wa hisshi ni benkyō shita.",
        traducao: "Ele estudou desesperadamente.",
      },
      {
        exemplo: "必死で逃げた。",
        hiragana: "ひっしでにげた。",
        romaji: "Hisshi de nigeta.",
        traducao: "Ele fugiu desesperadamente.",
      },
      {
        exemplo: "彼女は必死に抵抗した。",
        hiragana: "かのじょはひっしにていこうした。",
        romaji: "Kanojo wa hisshi ni teikō shita.",
        traducao: "Ela resistiu desesperadamente.",
      },
    ],
  },
  {
    vocabulario: "急激",
    romaji: "kyūgeki",
    hiragana: "きゅうげき",
    traducao: "rápido, drástico",
    exemplosFrases: [
      {
        exemplo: "状況は急激に変化した。",
        hiragana: "じょうきょうはきゅうげきにへんかした。",
        romaji: "Jōkyō wa kyūgeki ni henka shita.",
        traducao: "A situação mudou drasticamente.",
      },
      {
        exemplo: "急激な成長を遂げる。",
        hiragana: "きゅうげきなせいちょうをとげる。",
        romaji: "Kyūgeki na seichō o togeru.",
        traducao: "Experimentar um crescimento rápido.",
      },
      {
        exemplo: "彼の態度は急激に変わった。",
        hiragana: "かれのたいどはきゅうげきにかわった。",
        romaji: "Kare no taido wa kyūgeki ni kawatta.",
        traducao: "A atitude dele mudou drasticamente.",
      },
    ],
  },
  {
    vocabulario: "急速",
    romaji: "kyūsoku",
    hiragana: "きゅうそく",
    traducao: "rápido, veloz",
    exemplosFrases: [
      {
        exemplo: "その列車は急速に走っている。",
        hiragana: "そのれっしゃはきゅうそくにはしっている。",
        romaji: "Sono ressha wa kyūsoku ni hashitte iru.",
        traducao: "O trem está correndo rapidamente.",
      },
      {
        exemplo: "急速な変化が起こった。",
        hiragana: "きゅうそくなへんかがおこった。",
        romaji: "Kyūsoku na henka ga okotta.",
        traducao: "Uma mudança rápida ocorreu.",
      },
      {
        exemplo: "彼の成績は急速に向上した。",
        hiragana: "かれのせいせきはきゅうそくにこうじょうした。",
        romaji: "Kare no seiseki wa kyūsoku ni kōjō shita.",
        traducao: "As notas dele melhoraram rapidamente.",
      },
    ],
  },
  {
    vocabulario: "意外",
    romaji: "igai",
    hiragana: "いがい",
    traducao: "surpreendente, inesperado",
    exemplosFrases: [
      {
        exemplo: "彼の反応は意外だった。",
        hiragana: "かれのはんのうはいがいだった。",
        romaji: "Kare no hannō wa igai datta.",
        traducao: "A reação dele foi surpreendente.",
      },
      {
        exemplo: "意外な結果が出た。",
        hiragana: "いがいなけっかがでた。",
        romaji: "Igai na kekka ga deta.",
        traducao: "Um resultado inesperado ocorreu.",
      },
      {
        exemplo: "彼女の行動は意外だった。",
        hiragana: "かのじょのこうどうはいがいだった。",
        romaji: "Kanojo no kōdō wa igai datta.",
        traducao: "As ações dela foram surpreendentes.",
      },
    ],
  },
  {
    vocabulario: "抜群",
    romaji: "bakugun",
    hiragana: "ばくぐん",
    traducao: "excelente, excepcional",
    exemplosFrases: [
      {
        exemplo: "彼の才能は抜群だ。",
        hiragana: "かれのさいのうはばくぐんだ。",
        romaji: "Kare no sainō wa bakugun da.",
        traducao: "O talento dele é excelente.",
      },
      {
        exemplo: "彼女は抜群の成績を収めた。",
        hiragana: "かのじょはばくぐんのせいせきをおさめた。",
        romaji: "Kanojo wa bakugun no seiseki o osameta.",
        traducao: "Ela obteve resultados excepcionais.",
      },
      {
        exemplo: "その店のサービスは抜群だ。",
        hiragana: "そのみせのさーびすはばくぐんだ。",
        romaji: "Sono mise no sābisu wa bakugun da.",
        traducao: "O serviço daquela loja é excelente.",
      },
    ],
  },
  {
    vocabulario: "新た",
    romaji: "arata",
    hiragana: "あらた",
    traducao: "novo, renovado",
    exemplosFrases: [
      {
        exemplo: "新たな計画を立てる。",
        hiragana: "あらたなけいかくをたてる。",
        romaji: "Arata na keikaku o tateru.",
        traducao: "Fazer um novo plano.",
      },
      {
        exemplo: "新たな挑戦をする。",
        hiragana: "あらたなちょうせんをする。",
        romaji: "Arata na chōsen o suru.",
        traducao: "Fazer uma nova tentativa.",
      },
      {
        exemplo: "新たな目標を設定する。",
        hiragana: "あらたなもくひょうをせっていする。",
        romaji: "Arata na mokuhyō o settei suru.",
        traducao: "Definir novas metas.",
      },
    ],
  },
  {
    vocabulario: "新鮮",
    romaji: "shinsen",
    hiragana: "しんせん",
    traducao: "fresco, novo",
    exemplosFrases: [
      {
        exemplo: "新鮮な野菜を食べる。",
        hiragana: "しんせんなやさいをたべる。",
        romaji: "Shinsen na yasai o taberu.",
        traducao: "Comer vegetais frescos.",
      },
      {
        exemplo: "新鮮な空気を吸う。",
        hiragana: "しんせんなくうきをすう。",
        romaji: "Shinsen na kūki o suu.",
        traducao: "Respirar ar fresco.",
      },
      {
        exemplo: "新鮮な情報を手に入れる。",
        hiragana: "しんせんなじょうほうをてにいれる。",
        romaji: "Shinsen na jōhō o te ni ireru.",
        traducao: "Obter informações novas.",
      },
    ],
  },
  {
    vocabulario: "明らか",
    romaji: "akiraka",
    hiragana: "あきらか",
    traducao: "óbvio, claro",
    exemplosFrases: [
      {
        exemplo: "彼女の意図は明らかだった。",
        hiragana: "かのじょのいとはあきらかだった。",
        romaji: "Kanojo no ito wa akiraka datta.",
        traducao: "A intenção dela estava clara.",
      },
      {
        exemplo: "問題の解決方法は明らかではない。",
        hiragana: "もんだいのかいけつほうほうはあきらかではない。",
        romaji: "Mondai no kaiketsu hōhō wa akiraka dewa nai.",
        traducao: "O método de resolução do problema não está claro.",
      },
      {
        exemplo: "彼の嘘は明らかだ。",
        hiragana: "かれのうそはあきらかだ。",
        romaji: "Kare no uso wa akiraka da.",
        traducao: "A mentira dele é óbvia.",
      },
    ],
  },
  {
    vocabulario: "明確",
    romaji: "meikaku",
    hiragana: "めいかく",
    traducao: "claro, definido",
    exemplosFrases: [
      {
        exemplo: "問題の答えは明確だ。",
        hiragana: "もんだいのこたえはめいかくだ。",
        romaji: "Mondai no kotae wa meikaku da.",
        traducao: "A resposta do problema é clara.",
      },
      {
        exemplo: "方針を明確にする。",
        hiragana: "ほうしんをめいかくにする。",
        romaji: "Hōshin o meikaku ni suru.",
        traducao: "Tornar a diretriz clara.",
      },
      {
        exemplo: "彼の意図は明確ではない。",
        hiragana: "かれのいとはめいかくではない。",
        romaji: "Kare no ito wa meikaku dewa nai.",
        traducao: "A intenção dele não está clara.",
      },
    ],
  },
  {
    vocabulario: "有利",
    romaji: "yūri",
    hiragana: "ゆうり",
    traducao: "vantajoso, favorável",
    exemplosFrases: [
      {
        exemplo: "彼は有利な立場にいる。",
        hiragana: "かれはゆうりなたちばにいる。",
        romaji: "Kare wa yūri na tachiba ni iru.",
        traducao: "Ele está em uma posição vantajosa.",
      },
      {
        exemplo: "有利な条件を得る。",
        hiragana: "ゆうりなじょうけんをえる。",
        romaji: "Yūri na jōken o eru.",
        traducao: "Obter condições favoráveis.",
      },
      {
        exemplo: "競争で有利になる。",
        hiragana: "きょうそうでゆうりになる。",
        romaji: "Kyōsō de yūri ni naru.",
        traducao: "Ter vantagem na competição.",
      },
    ],
  },
  {
    vocabulario: "有効",
    romaji: "yūkō",
    hiragana: "ゆうこう",
    traducao: "efetivo, válido",
    exemplosFrases: [
      {
        exemplo: "有効な戦略を立てる。",
        hiragana: "ゆうこうなせんりゃくをたてる。",
        romaji: "Yūkō na senryaku o tateru.",
        traducao: "Elaborar uma estratégia efetiva.",
      },
      {
        exemplo: "有効な手段を見つける。",
        hiragana: "ゆうこうなしゅだんをみつける。",
        romaji: "Yūkō na shudan o mitsukeru.",
        traducao: "Encontrar um meio válido.",
      },
      {
        exemplo: "彼女の提案は有効だった。",
        hiragana: "かのじょのていあんはゆうこうだった。",
        romaji: "Kanojo no teian wa yūkō datta.",
        traducao: "A proposta dela foi efetiva.",
      },
    ],
  },
  {
    vocabulario: "様々",
    romaji: "samazama",
    hiragana: "さまざま",
    traducao: "diversos, variados",
    exemplosFrases: [
      {
        exemplo: "彼は様々な経験を持っている。",
        hiragana: "かれはさまざまなけいけんをもっている。",
        romaji: "Kare wa samazama na keiken o motte iru.",
        traducao: "Ele tem diversas experiências.",
      },
      {
        exemplo: "様々な選択肢がある。",
        hiragana: "さまざまなせんたくしゃがある。",
        romaji: "Samazama na sentakushiga aru.",
        traducao: "Existem diversas opções.",
      },
      {
        exemplo: "彼は様々な才能を持っている。",
        hiragana: "かれはさまざまなさいのうをもっている。",
        romaji: "Kare wa samazama na sainō o motte iru.",
        traducao: "Ele possui diversos talentos.",
      },
    ],
  },
  {
    vocabulario: "正式",
    romaji: "seishiki",
    hiragana: "せいしき",
    traducao: "formal, oficial",
    exemplosFrases: [
      {
        exemplo: "正式な手続きを行う。",
        hiragana: "せいしきなてつづきをおこなう。",
        romaji: "Seishiki na tetsuzuki o okonau.",
        traducao: "Realizar procedimentos formais.",
      },
      {
        exemplo: "正式な許可を得る。",
        hiragana: "せいしきなきょかをえる。",
        romaji: "Seishiki na kyoka o eru.",
        traducao: "Obter permissão oficial.",
      },
      {
        exemplo: "正式な書類を提出する。",
        hiragana: "せいしきなしょるいをていしゅつする。",
        romaji: "Seishiki na shorui o teishutsu suru.",
        traducao: "Apresentar documentos oficiais.",
      },
    ],
  },
  {
    vocabulario: "気の毒",
    romaji: "kinodoku",
    hiragana: "きのどく",
    traducao: "pena, lamentável",
    exemplosFrases: [
      {
        exemplo: "彼の境遇は気の毒だ。",
        hiragana: "かれのきょうぐうはきのどくだ。",
        romaji: "Kare no kyōgū wa kinodoku da.",
        traducao: "A situação dele é lamentável.",
      },
      {
        exemplo: "彼女は気の毒な経験をした。",
        hiragana: "かのじょはきのどくなけいけんをした。",
        romaji: "Kanojo wa kinodoku na keiken o shita.",
        traducao: "Ela teve uma experiência lamentável.",
      },
      {
        exemplo: "彼の失敗は気の毒だった。",
        hiragana: "かれのしっぱいはきのどくだった。",
        romaji: "Kare no shippai wa kinodoku datta.",
        traducao: "A falha dele foi lamentável.",
      },
    ],
  },
  {
    vocabulario: "深刻",
    romaji: "shinkoku",
    hiragana: "しんこく",
    traducao: "grave, sério",
    exemplosFrases: [
      {
        exemplo: "事態は深刻だ。",
        hiragana: "じたいはしんこくだ。",
        romaji: "Jitai wa shinkoku da.",
        traducao: "A situação é grave.",
      },
      {
        exemplo: "深刻な問題を解決する。",
        hiragana: "しんこくなもんだいをかいけつする。",
        romaji: "Shinkoku na mondai o kaiketsu suru.",
        traducao: "Resolver um problema sério.",
      },
      {
        exemplo: "彼の病状は深刻だ。",
        hiragana: "かれのびょうじょうはしんこくだ。",
        romaji: "Kare no byōjō wa shinkoku da.",
        traducao: "O estado de saúde dele é grave.",
      },
    ],
  },
  {
    vocabulario: "温暖",
    romaji: "ondan",
    hiragana: "おんだん",
    traducao: "temperado, ameno",
    exemplosFrases: [
      {
        exemplo: "この地域は温暖な気候だ。",
        hiragana: "このちいきはおんだんなきこうだ。",
        romaji: "Kono chiiki wa ondan na kikō da.",
        traducao: "Esta região tem um clima temperado.",
      },
      {
        exemplo: "温暖な地方に住む。",
        hiragana: "おんだんなちほうにすむ。",
        romaji: "Ondan na chihō ni sumu.",
        traducao: "Morar em uma região amena.",
      },
      {
        exemplo: "温暖な天候が続く。",
        hiragana: "おんだんなてんこうがつづく。",
        romaji: "Ondan na tenkō ga tsuzuku.",
        traducao: "O clima ameno continua.",
      },
    ],
  },
  {
    vocabulario: "独特",
    romaji: "dokutoku",
    hiragana: "どくとく",
    traducao: "único, peculiar",
    exemplosFrases: [
      {
        exemplo: "彼女は独特なセンスを持っている。",
        hiragana: "かのじょはどくとくなせんすをもっている。",
        romaji: "Kanojo wa dokutoku na sensu o motte iru.",
        traducao: "Ela tem um senso único.",
      },
      {
        exemplo: "その建物は独特なデザインだ。",
        hiragana: "そのたてものはどくとくなでざいんだ。",
        romaji: "Sono tatemono wa dokutoku na dezain da.",
        traducao: "Aquele prédio tem um design único.",
      },
      {
        exemplo: "彼のアイデアは独特だ。",
        hiragana: "かれのあいであはどくとくだ。",
        romaji: "Kare no aidea wa dokutoku da.",
        traducao: "A ideia dele é única.",
      },
    ],
  },
  {
    vocabulario: "直",
    romaji: "choku",
    hiragana: "ちょく",
    traducao: "direto, reto",
    exemplosFrases: [
      {
        exemplo: "彼女は直に話す。",
        hiragana: "かのじょはちょくにはなす。",
        romaji: "Kanojo wa choku ni hanasu.",
        traducao: "Ela fala diretamente.",
      },
      {
        exemplo: "直に彼に会う。",
        hiragana: "ちょくにかれにあう。",
        romaji: "Choku ni kare ni au.",
        traducao: "Encontrar-se com ele pessoalmente.",
      },
      {
        exemplo: "問題を直に解決する。",
        hiragana: "もんだいをちょくにかいけつする。",
        romaji: "Mondai o choku ni kaiketsu suru.",
        traducao: "Resolver o problema diretamente.",
      },
    ],
  },
  {
    vocabulario: "真っ赤",
    romaji: "makka",
    hiragana: "まっか",
    traducao: "vermelho intenso",
    exemplosFrases: [
      {
        exemplo: "彼女のドレスは真っ赤だった。",
        hiragana: "かのじょのどれすはまっかだった。",
        romaji: "Kanojo no doresu wa makka datta.",
        traducao: "O vestido dela era vermelho intenso.",
      },
      {
        exemplo: "真っ赤なバラが咲く。",
        hiragana: "まっかなばらがさく。",
        romaji: "Makka na bara ga saku.",
        traducao: "Rosas vermelhas intensas estão florescendo.",
      },
      {
        exemplo: "彼の顔は真っ赤になった。",
        hiragana: "かれのかおはまっかになった。",
        romaji: "Kare no kao wa makka ni natta.",
        traducao: "O rosto dele ficou vermelho intenso.",
      },
    ],
  },
  {
    vocabulario: "確実",
    romaji: "kakujitsu",
    hiragana: "かくじつ",
    traducao: "certo, seguro",
    exemplosFrases: [
      {
        exemplo: "勝利は確実だ。",
        hiragana: "しょうりはかくじつだ。",
        romaji: "Shōri wa kakujitsu da.",
        traducao: "A vitória é certa.",
      },
      {
        exemplo: "彼の予測は確実だった。",
        hiragana: "かれのよそくはかくじつだった。",
        romaji: "Kare no yosoku wa kakujitsu datta.",
        traducao: "A previsão dele estava correta.",
      },
      {
        exemplo: "確実な方法を選ぶ。",
        hiragana: "かくじつなほうほうをえらぶ。",
        romaji: "Kakujitsu na hōhō o erabu.",
        traducao: "Escolher um método seguro.",
      },
    ],
  },
  {
    vocabulario: "穏やか",
    romaji: "odayaka",
    hiragana: "おだやか",
    traducao: "calmo, tranquilo",
    exemplosFrases: [
      {
        exemplo: "彼は穏やかな性格だ。",
        hiragana: "かれはおだやかなせいかくだ。",
        romaji: "Kare wa odayaka na seikaku da.",
        traducao: "Ele tem uma personalidade calma.",
      },
      {
        exemplo: "穏やかな海が広がっている。",
        hiragana: "おだやかなうみがひろがっている。",
        romaji: "Odayaka na umi ga hirogatte iru.",
        traducao: "Um mar tranquilo se estende.",
      },
      {
        exemplo: "彼女の声は穏やかだ。",
        hiragana: "かのじょのこえはおだやかだ。",
        romaji: "Kanojo no koe wa odayaka da.",
        traducao: "A voz dela é calma.",
      },
    ],
  },
  {
    vocabulario: "粗末",
    romaji: "somatsu",
    hiragana: "そまつ",
    traducao: "simples, grosseiro",
    exemplosFrases: [
      {
        exemplo: "粗末な食事をする。",
        hiragana: "そまつなしょくじをする。",
        romaji: "Somatsu na shokuji o suru.",
        traducao: "Fazer uma refeição simples.",
      },
      {
        exemplo: "粗末な部屋に泊まる。",
        hiragana: "そまつなへやにとまる。",
        romaji: "Somatsu na heya ni tomaru.",
        traducao: "Ficar em um quarto simples.",
      },
      {
        exemplo: "粗末な言葉で謝る。",
        hiragana: "そまつなことばであやまる。",
        romaji: "Somatsu na kotoba de ayamaru.",
        traducao: "Pedir desculpas com palavras grosseiras.",
      },
    ],
  },
  {
    vocabulario: "素敵",
    romaji: "suteki",
    hiragana: "すてき",
    traducao: "maravilhoso, adorável",
    exemplosFrases: [
      {
        exemplo: "素敵な景色を見る。",
        hiragana: "すてきなけしきをみる。",
        romaji: "Suteki na keshiki o miru.",
        traducao: "Ver uma paisagem maravilhosa.",
      },
      {
        exemplo: "彼女は素敵な笑顔を持っている。",
        hiragana: "かのじょはすてきなえがおをもっている。",
        romaji: "Kanojo wa suteki na egao o motte iru.",
        traducao: "Ela tem um sorriso adorável.",
      },
      {
        exemplo: "素敵なプレゼントをもらう。",
        hiragana: "すてきなぷれぜんとをもらう。",
        romaji: "Suteki na purezento o morau.",
        traducao: "Receber um presente maravilhoso.",
      },
    ],
  },
  {
    vocabulario: "見事",
    romaji: "migoto",
    hiragana: "みごと",
    traducao: "esplêndido, magnífico",
    exemplosFrases: [
      {
        exemplo: "見事な演技をする。",
        hiragana: "みごとなえんぎをする。",
        romaji: "Migoto na engi o suru.",
        traducao: "Realizar uma atuação esplêndida.",
      },
      {
        exemplo: "見事な勝利を収める。",
        hiragana: "みごとなしょうりをおさめる。",
        romaji: "Migoto na shōri o osameru.",
        traducao: "Alcançar uma vitória magnífica.",
      },
      {
        exemplo: "彼の作品は見事だ。",
        hiragana: "かれのさくひんはみごとだ。",
        romaji: "Kare no sakuhin wa migoto da.",
        traducao: "A obra dele é esplêndida.",
      },
    ],
  },
  {
    vocabulario: "豊か",
    romaji: "yutaka",
    hiragana: "ゆたか",
    traducao: "rico, abundante",
    exemplosFrases: [
      {
        exemplo: "豊かな自然を楽しむ。",
        hiragana: "ゆたかなしぜんをたのしむ。",
        romaji: "Yutaka na shizen o tanoshimu.",
        traducao: "Desfrutar da natureza abundante.",
      },
      {
        exemplo: "彼は豊かな経験を持っている。",
        hiragana: "かれはゆたかなけいけんをもっている。",
        romaji: "Kare wa yutaka na keiken o motte iru.",
        traducao: "Ele tem experiências ricas.",
      },
      {
        exemplo: "豊かな想像力を持つ。",
        hiragana: "ゆたかなそうぞうりょくをもつ。",
        romaji: "Yutaka na sōzōryoku o motsu.",
        traducao: "Ter uma imaginação abundante.",
      },
    ],
  },
  {
    vocabulario: "豊富",
    romaji: "hōfu",
    hiragana: "ほうふ",
    traducao: "abundante, rico",
    exemplosFrases: [
      {
        exemplo: "彼は豊富な知識を持っている。",
        hiragana: "かれはほうふなちしきをもっている。",
        romaji: "Kare wa hōfu na chishiki o motte iru.",
        traducao: "Ele possui um conhecimento abundante.",
      },
      {
        exemplo: "豊富な経験を積む。",
        hiragana: "ほうふなけいけんをつむ。",
        romaji: "Hōfu na keiken o tsumu.",
        traducao: "Acumular experiências abundantes.",
      },
      {
        exemplo: "豊富な資源を活用する。",
        hiragana: "ほうふなしげんをかつようする。",
        romaji: "Hōfu na shigen o katsuyō suru.",
        traducao: "Utilizar recursos abundantes.",
      },
    ],
  },
  {
    vocabulario: "貴重",
    romaji: "kichō",
    hiragana: "きちょう",
    traducao: "valioso, precioso",
    exemplosFrases: [
      {
        exemplo: "貴重な体験をする。",
        hiragana: "きちょうなたいけんをする。",
        romaji: "Kichō na taiken o suru.",
        traducao: "Ter uma experiência valiosa.",
      },
      {
        exemplo: "貴重な情報を入手する。",
        hiragana: "きちょうなじょうほうをにゅうしゅする。",
        romaji: "Kichō na jōhō o nyūshu suru.",
        traducao: "Obter informações valiosas.",
      },
      {
        exemplo: "貴重な時間を大切にする。",
        hiragana: "きちょうなじかんをたいせつにする。",
        romaji: "Kichō na jikan o taisetsu ni suru.",
        traducao: "Valorizar o tempo precioso.",
      },
    ],
  },
  {
    vocabulario: "適切",
    romaji: "tekisetsu",
    hiragana: "てきせつ",
    traducao: "adequado, apropriado",
    exemplosFrases: [
      {
        exemplo: "適切な対策を取る。",
        hiragana: "てきせつなたいさくをとる。",
        romaji: "Tekisetsu na taisaku o toru.",
        traducao: "Tomar medidas adequadas.",
      },
      {
        exemplo: "適切な判断をする。",
        hiragana: "てきせつなはんだんをする。",
        romaji: "Tekisetsu na handan o suru.",
        traducao: "Fazer uma decisão apropriada.",
      },
      {
        exemplo: "適切な言葉で伝える。",
        hiragana: "てきせつなことばでつたえる。",
        romaji: "Tekisetsu na kotoba de tsutaeru.",
        traducao: "Comunicar com palavras adequadas.",
      },
    ],
  },
  {
    vocabulario: "適度",
    romaji: "tekido",
    hiragana: "てきど",
    traducao: "moderado, adequado",
    exemplosFrases: [
      {
        exemplo: "適度な運動をする。",
        hiragana: "てきどなうんどうをする。",
        romaji: "Tekido na undō o suru.",
        traducao: "Fazer exercícios moderados.",
      },
      {
        exemplo: "適度な睡眠をとる。",
        hiragana: "てきどなすいみんをとる。",
        romaji: "Tekido na suimin o toru.",
        traducao: "Ter um sono adequado.",
      },
      {
        exemplo: "適度な食事を摂る。",
        hiragana: "てきどなしょくじをとる。",
        romaji: "Tekido na shokuji o toru.",
        traducao: "Fazer uma alimentação moderada.",
      },
    ],
  },
  {
    vocabulario: "重大",
    romaji: "jūdai",
    hiragana: "じゅうだい",
    traducao: "grave, sério",
    exemplosFrases: [
      {
        exemplo: "重大な問題が発生した。",
        hiragana: "じゅうだいなもんだいがはっせいした。",
        romaji: "Jūdai na mondai ga hassei shita.",
        traducao: "Ocorreu um problema grave.",
      },
      {
        exemplo: "重大な決定を下す。",
        hiragana: "じゅうだいなけっていをくだす。",
        romaji: "Jūdai na kettei o kudasu.",
        traducao: "Tomar uma decisão séria.",
      },
      {
        exemplo: "重大な事実が明らかになる。",
        hiragana: "じゅうだいなじじつがあきらかになる。",
        romaji: "Jūdai na jijitsu ga akiraka ni naru.",
        traducao: "Um fato grave se torna evidente.",
      },
    ],
  },
  {
    vocabulario: "重要",
    romaji: "jūyō",
    hiragana: "じゅうよう",
    traducao: "importante",
    exemplosFrases: [
      {
        exemplo: "重要な役割を果たす。",
        hiragana: "じゅうようなやくわりをはたす。",
        romaji: "Jūyō na yakuwari o hatasu.",
        traducao: "Desempenhar um papel importante.",
      },
      {
        exemplo: "重要なポイントを押さえる。",
        hiragana: "じゅうようなぽいんとをおさえる。",
        romaji: "Jūyō na pointo o osaeru.",
        traducao: "Captar os pontos importantes.",
      },
      {
        exemplo: "重要な会議に参加する。",
        hiragana: "じゅうようなかいぎにさんかする。",
        romaji: "Jūyō na kaigi ni sanka suru.",
        traducao: "Participar de uma reunião importante.",
      },
    ],
  },
  {
    vocabulario: "非常",
    romaji: "hijō",
    hiragana: "ひじょう",
    traducao: "extremo, extraordinário",
    exemplosFrases: [
      {
        exemplo: "非常な事態が発生した。",
        hiragana: "ひじょうなじたいがはっせいした。",
        romaji: "Hijō na jitai ga hassei shita.",
        traducao: "Ocorreu uma situação extrema.",
      },
      {
        exemplo: "非常に重要な情報を入手する。",
        hiragana: "ひじょうにじゅうようなじょうほうをにゅうしゅする。",
        romaji: "Hijō ni jūyō na jōhō o nyūshu suru.",
        traducao: "Obter informações extremamente importantes.",
      },
      {
        exemplo: "非常な努力をして目標を達成する。",
        hiragana: "ひじょうなどりょくをしてもくひょうをたっせいする。",
        romaji: "Hijō na doryoku o shite mokuhyō o tassei suru.",
        traducao: "Alcançar metas com esforço extraordinário.",
      },
    ],
  },
  {
    vocabulario: "順調",
    romaji: "junchō",
    hiragana: "じゅんちょう",
    traducao: "em boa ordem, bem-sucedido",
    exemplosFrases: [
      {
        exemplo: "プロジェクトは順調に進行している。",
        hiragana: "ぷろじぇくとはじゅんちょうにしんこうしている。",
        romaji: "Purojekuto wa junchō ni shinkō shite iru.",
        traducao: "O projeto está progredindo bem.",
      },
      {
        exemplo: "勉強は順調だ。",
        hiragana: "べんきょうはじゅんちょうだ。",
        romaji: "Benkyō wa junchō da.",
        traducao: "Os estudos estão indo bem.",
      },
      {
        exemplo: "順調に成長する。",
        hiragana: "じゅんちょうにせいちょうする。",
        romaji: "Junchō ni seichō suru.",
        traducao: "Crescer em boa ordem.",
      },
    ],
  },
  {
    vocabulario: "いい",
    romaji: "ii",
    hiragana: "いい",
    traducao: "bom, bom",
    exemplosFrases: [
      {
        exemplo: "この本はとてもいいです。",
        hiragana: "このほんはとてもいいです。",
        romaji: "Kono hon wa totemo ii desu.",
        traducao: "Este livro é muito bom.",
      },
      {
        exemplo: "いい天気ですね。",
        hiragana: "いいてんきですね。",
        romaji: "Ii tenki desu ne.",
        traducao: "Está um bom tempo, não é?",
      },
      {
        exemplo: "いい結果が出ました。",
        hiragana: "いいけっかがでました。",
        romaji: "Ii kekka ga demashita.",
        traducao: "Obtivemos um bom resultado.",
      },
    ],
  },
  {
    vocabulario: "かゆい",
    romaji: "kayui",
    hiragana: "かゆい",
    traducao: "coçando, comichão",
    exemplosFrases: [
      {
        exemplo: "蚊に刺されてかゆいです。",
        hiragana: "かにさされてかゆいです。",
        romaji: "Ka ni sasarete kayui desu.",
        traducao: "Estou com coceira por causa de uma picada de mosquito.",
      },
      {
        exemplo: "アレルギーで肌がかゆい。",
        hiragana: "あれるぎーではだがかゆい。",
        romaji: "Arerugī de hada ga kayui.",
        traducao: "Tenho coceira na pele devido a uma alergia.",
      },
      {
        exemplo: "かゆいところに手が届く。",
        hiragana: "かゆいところにてがとどく。",
        romaji: "Kayui tokoro ni te ga todoku.",
        traducao: "Alcançar o local coçando.",
      },
    ],
  },
  {
    vocabulario: "かわいらしい",
    romaji: "kawairashii",
    hiragana: "かわいらしい",
    traducao: "adorável, bonitinho",
    exemplosFrases: [
      {
        exemplo: "赤ちゃんがかわいらしい。",
        hiragana: "あかちゃんがかわいらしい。",
        romaji: "Akachan ga kawairashii.",
        traducao: "O bebê é adorável.",
      },
      {
        exemplo: "かわいらしい猫がいます。",
        hiragana: "かわいらしいねこがいます。",
        romaji: "Kawairashii neko ga imasu.",
        traducao: "Há um gatinho bonitinho.",
      },
      {
        exemplo: "かわいらしい笑顔で挨拶する。",
        hiragana: "かわいらしいえがおであいさつする。",
        romaji: "Kawairashii egao de aisatsu suru.",
        traducao: "Cumprimentar com um sorriso adorável.",
      },
    ],
  },
  {
    vocabulario: "きつい",
    romaji: "kitsui",
    hiragana: "きつい",
    traducao: "difícil, rigoroso",
    exemplosFrases: [
      {
        exemplo: "この仕事はきついです。",
        hiragana: "このしごとはきついです。",
        romaji: "Kono shigoto wa kitsui desu.",
        traducao: "Este trabalho é difícil.",
      },
      {
        exemplo: "きついトレーニングをする。",
        hiragana: "きついとれーにんぐをする。",
        romaji: "Kitsui torēningu o suru.",
        traducao: "Realizar um treinamento rigoroso.",
      },
      {
        exemplo: "きつい試験に合格する。",
        hiragana: "きついしけんにごうかくする。",
        romaji: "Kitsui shiken ni gōkaku suru.",
        traducao: "Passar em um exame difícil.",
      },
    ],
  },
  {
    vocabulario: "くやしい",
    romaji: "kuyashii",
    hiragana: "くやしい",
    traducao: "frustrante, irritante",
    exemplosFrases: [
      {
        exemplo: "失敗してくやしい。",
        hiragana: "しっぱいしてくやしい。",
        romaji: "Shippai shite kuyashii.",
        traducao: "É frustrante cometer um erro.",
      },
      {
        exemplo: "くやしい思いをする。",
        hiragana: "くやしいおもいをする。",
        romaji: "Kuyashii omoi o suru.",
        traducao: "Sentir-se frustrado.",
      },
      {
        exemplo: "くやしい結果になる。",
        hiragana: "くやしいけっかになる。",
        romaji: "Kuyashii kekka ni naru.",
        traducao: "Ter um resultado frustrante.",
      },
    ],
  },
  {
    vocabulario: "しかたない",
    romaji: "shikatanai",
    hiragana: "しかたない",
    traducao: "não pode ser ajudado, inevitável",
    exemplosFrases: [
      {
        exemplo: "雨が降ってしまったので、しかたない。",
        hiragana: "あめがふってしまったので、しかたない。",
        romaji: "Ame ga futte shimatta node, shikatanai.",
        traducao: "Não pode ser ajudado que choveu.",
      },
      {
        exemplo: "遅れてしまったけど、しかたない。",
        hiragana: "おくれてしまったけど、しかたない。",
        romaji: "Okurete shimatta kedo, shikatanai.",
        traducao: "Não pode ser ajudado que me atrasei.",
      },
      {
        exemplo: "昨日の試合で負けてしまったけど、しかたない。",
        hiragana: "きのうのしあいでまけてしまったけど、しかたない。",
        romaji: "Kinō no shiai de makete shimatta kedo, shikatanai.",
        traducao: "Não pode ser ajudado que perdemos o jogo de ontem.",
      },
    ],
  },
  {
    vocabulario: "つらい",
    romaji: "tsurai",
    hiragana: "つらい",
    traducao: "difícil, doloroso",
    exemplosFrases: [
      {
        exemplo: "失恋はつらい。",
        hiragana: "しつれんはつらい。",
        romaji: "Shitsuren wa tsurai.",
        traducao: "O desgosto amoroso é doloroso.",
      },
      {
        exemplo: "つらい状況に立ち向かう。",
        hiragana: "つらいじょうきょうにたちむかう。",
        romaji: "Tsurai jōkyō ni tachimukau.",
        traducao: "Enfrentar uma situação difícil.",
      },
      {
        exemplo: "つらい選択をする。",
        hiragana: "つらいせんたくをする。",
        romaji: "Tsurai sentaku o suru.",
        traducao: "Fazer uma escolha difícil.",
      },
    ],
  },
  {
    vocabulario: "ない",
    romaji: "nai",
    hiragana: "ない",
    traducao: "não ter, não existir",
    exemplosFrases: [
      {
        exemplo: "財布がない。",
        hiragana: "さいふがない。",
        romaji: "Saifu ga nai.",
        traducao: "Não tenho carteira.",
      },
      {
        exemplo: "予定がない。",
        hiragana: "よていがない。",
        romaji: "Yotei ga nai.",
        traducao: "Não tenho planos.",
      },
      {
        exemplo: "時間がない。",
        hiragana: "じかんがない。",
        romaji: "Jikan ga nai.",
        traducao: "Não tenho tempo.",
      },
    ],
  },
  {
    vocabulario: "めでとう",
    romaji: "medetō",
    hiragana: "めでとう",
    traducao: "parabéns",
    exemplosFrases: [
      {
        exemplo: "結婚おめでとう！",
        hiragana: "けっこんおめでとう！",
        romaji: "Kekkon omedetō!",
        traducao: "Parabéns pelo casamento!",
      },
      {
        exemplo: "卒業おめでとうございます。",
        hiragana: "そつぎょうおめでとうございます。",
        romaji: "Sotsugyō omedetō gozaimasu.",
        traducao: "Parabéns pela formatura.",
      },
      {
        exemplo: "お誕生日おめでとう！",
        hiragana: "おたんじょうびおめでとう！",
        romaji: "Otanjōbi omedetō!",
        traducao: "Parabéns pelo seu aniversário!",
      },
    ],
  },
  {
    vocabulario: "幼い",
    romaji: "osanai",
    hiragana: "おさない",
    traducao: "jovem, criança",
    exemplosFrases: [
      {
        exemplo: "彼は幼い頃からピアノを弾いている。",
        hiragana: "かれはおさないころからぴあのをひいている。",
        romaji: "Kare wa osanai koro kara piano o hiite iru.",
        traducao: "Ele toca piano desde criança.",
      },
      {
        exemplo: "彼女は幼い顔をしている。",
        hiragana: "かのじょはおさないかおをしている。",
        romaji: "Kanojo wa osanai kao o shite iru.",
        traducao: "Ela tem um rosto jovem.",
      },
      {
        exemplo: "幼い子供たちは元気に遊んでいる。",
        hiragana: "おさないこどもたちはげんきにあそんでいる。",
        romaji: "Osanai kodomo-tachi wa genki ni asonde iru.",
        traducao: "As crianças pequenas estão brincando animadamente.",
      },
    ],
  },
  {
    vocabulario: "恐ろしい",
    romaji: "osoroshii",
    hiragana: "おそろしい",
    traducao: "aterrorizante, assustador",
    exemplosFrases: [
      {
        exemplo: "彼女は恐ろしい夢を見た。",
        hiragana: "かのじょはおそろしいゆめをみた。",
        romaji: "Kanojo wa osoroshii yume o mita.",
        traducao: "Ela teve um sonho assustador.",
      },
      {
        exemplo: "恐ろしい事件が起こった。",
        hiragana: "おそろしいじけんがおこった。",
        romaji: "Osoroshii jiken ga okotta.",
        traducao: "Aconteceu um evento aterrorizante.",
      },
      {
        exemplo: "恐ろしい噂が広まっている。",
        hiragana: "おそろしいうわさがひろまっている。",
        romaji: "Osoroshii uwasa ga hiromatte iru.",
        traducao: "Há rumores assustadores se espalhando.",
      },
    ],
  },
  {
    vocabulario: "激しい",
    romaji: "hageshii",
    hiragana: "はげしい",
    traducao: "intenso, violento",
    exemplosFrases: [
      {
        exemplo: "激しい雨が降っている。",
        hiragana: "はげしいあめがふっている。",
        romaji: "Hageshii ame ga futte iru.",
        traducao: "Está chovendo intensamente.",
      },
      {
        exemplo: "彼は激しい競争に参加している。",
        hiragana: "かれははげしいきょうそうにさんかしている。",
        romaji: "Kare wa hageshii kyōsō ni sankashite iru.",
        traducao: "Ele está participando de uma competição intensa.",
      },
      {
        exemplo: "激しい痛みを感じる。",
        hiragana: "はげしいいたみをかんじる。",
        romaji: "Hageshii itami o kanjiru.",
        traducao: "Sentir uma dor intensa.",
      },
    ],
  },
  {
    vocabulario: "濃い",
    romaji: "koi",
    hiragana: "こい",
    traducao: "escuro, denso",
    exemplosFrases: [
      {
        exemplo: "濃い色の服を着ている。",
        hiragana: "こいいろのふくをきている。",
        romaji: "Koi iro no fuku o kite iru.",
        traducao: "Estou usando roupas de cor escura.",
      },
      {
        exemplo: "濃い味のスープを飲む。",
        hiragana: "こいあじのすーぷをのむ。",
        romaji: "Koi aji no sūpu o nomu.",
        traducao: "Beber uma sopa de sabor intenso.",
      },
      {
        exemplo: "濃い霧が立ち込めている。",
        hiragana: "こいきりがたちこめている。",
        romaji: "Koi kiri ga tachikomete iru.",
        traducao: "Uma névoa densa está se formando.",
      },
    ],
  },
  {
    vocabulario: "等しい",
    romaji: "hitoshii",
    hiragana: "ひとしい",
    traducao: "igual, mesmo",
    exemplosFrases: [
      {
        exemplo: "私たちの能力は等しい。",
        hiragana: "わたしたちののうりょくはひとしい。",
        romaji: "Watashitachi no nōryoku wa hitoshii.",
        traducao: "Nossas habilidades são iguais.",
      },
      {
        exemplo: "二つの町の距離は等しい。",
        hiragana: "ふたつのまちのきょりはひとしい。",
        romaji: "Futatsu no machi no kyori wa hitoshii.",
        traducao: "A distância entre as duas cidades é a mesma.",
      },
      {
        exemplo: "時間の使い方は等しい重要性がある。",
        hiragana: "じかんのつかいかたはひとしいじゅうようせいがある。",
        romaji: "Jikan no tsukaikata wa hitoshii jūyōsei ga aru.",
        traducao: "A forma como usamos o tempo tem a mesma importância.",
      },
    ],
  },
  {
    vocabulario: "粗い",
    romaji: "arai",
    hiragana: "あらい",
    traducao: "áspero, grosseiro",
    exemplosFrases: [
      {
        exemplo: "この布は粗い質感がある。",
        hiragana: "このぬのはあらいしつかんがある。",
        romaji: "Kono nuno wa arai shitsukan ga aru.",
        traducao: "Este tecido tem uma textura áspera.",
      },
      {
        exemplo: "粗い言葉を使うな。",
        hiragana: "あらいことばをつかうな。",
        romaji: "Arai kotoba o tsukau na.",
        traducao: "Não use palavras grosseiras.",
      },
      {
        exemplo: "粗い仕事をする。",
        hiragana: "あらいしごとをする。",
        romaji: "Arai shigoto o suru.",
        traducao: "Fazer um trabalho grosseiro.",
      },
    ],
  },
  {
    vocabulario: "良い",
    romaji: "yoi",
    hiragana: "よい",
    traducao: "bom",
    exemplosFrases: [
      {
        exemplo: "彼はとても良い人だ。",
        hiragana: "かれはとてもよいひとだ。",
        romaji: "Kare wa totemo yoi hito da.",
        traducao: "Ele é uma pessoa muito boa.",
      },
      {
        exemplo: "この本はとても良いです。",
        hiragana: "このほんはとてもよいです。",
        romaji: "Kono hon wa totemo yoi desu.",
        traducao: "Este livro é muito bom.",
      },
      {
        exemplo: "良い結果が出ました。",
        hiragana: "よいけっかがでました。",
        romaji: "Yoi kekka ga demashita.",
        traducao: "Obtivemos um bom resultado.",
      },
    ],
  },
  {
    vocabulario: "苦しい",
    romaji: "kurushii",
    hiragana: "くるしい",
    traducao: "difícil, doloroso",
    exemplosFrases: [
      {
        exemplo: "試験が苦しいです。",
        hiragana: "しけんがくるしいです。",
        romaji: "Shiken ga kurushii desu.",
        traducao: "Os exames são difíceis.",
      },
      {
        exemplo: "苦しい経験から多くを学ぶ。",
        hiragana: "くるしいけいけんからおおくをまなぶ。",
        romaji: "Kurushii keiken kara ōku o manabu.",
        traducao: "Aprender muito com experiências difíceis.",
      },
      {
        exemplo: "苦しい状況に立ち向かう。",
        hiragana: "くるしいじょうきょうにたちむかう。",
        romaji: "Kurushii jōkyō ni tachimukau.",
        traducao: "Enfrentar situações difíceis.",
      },
    ],
  },
  {
    vocabulario: "親しい",
    romaji: "shitashii",
    hiragana: "したしい",
    traducao: "próximo, íntimo",
    exemplosFrases: [
      {
        exemplo: "彼は私にとても親しい。",
        hiragana: "かれはわたしにとてもしたしい。",
        romaji: "Kare wa watashi ni totemo shitashii.",
        traducao: "Ele é muito próximo de mim.",
      },
      {
        exemplo: "親しい友人と出かける。",
        hiragana: "したしいゆうじんとでかける。",
        romaji: "Shitashii yūjin to dekakeru.",
        traducao: "Sair com amigos próximos.",
      },
      {
        exemplo: "親しい人に相談する。",
        hiragana: "したしいひとにそうだんする。",
        romaji: "Shitashii hito ni sōdan suru.",
        traducao: "Consultar uma pessoa próxima.",
      },
    ],
  },
  {
    vocabulario: "詳しい",
    romaji: "kuwashii",
    hiragana: "くわしい",
    traducao: "detalhado, minucioso",
    exemplosFrases: [
      {
        exemplo: "その本は詳しい説明をしている。",
        hiragana: "そのほんはくわしいせつめいをしている。",
        romaji: "Sono hon wa kuwashii setsumei o shite iru.",
        traducao: "Este livro fornece uma explicação detalhada.",
      },
      {
        exemplo: "詳しい情報を提供する。",
        hiragana: "くわしいじょうほうをていきょうする。",
        romaji: "Kuwashii jōhō o teikyō suru.",
        traducao: "Fornecer informações detalhadas.",
      },
      {
        exemplo: "詳しい計画を立てる。",
        hiragana: "くわしいけいかくをたてる。",
        romaji: "Kuwashii keikaku o tateru.",
        traducao: "Elaborar um plano detalhado.",
      },
    ],
  },
  {
    vocabulario: "貧しい",
    romaji: "mazushii",
    hiragana: "まずしい",
    traducao: "pobre",
    exemplosFrases: [
      {
        exemplo: "その地域は貧しい。",
        hiragana: "そのちいきはまずしい。",
        romaji: "Sono chiiki wa mazushii.",
        traducao: "Essa região é pobre.",
      },
      {
        exemplo: "貧しい家族を支援する。",
        hiragana: "まずしいかぞくをしえんする。",
        romaji: "Mazushii kazoku o shien suru.",
        traducao: "Apoiar famílias pobres.",
      },
      {
        exemplo: "貧しい生活を送る。",
        hiragana: "まずしいせいかつをおくる。",
        romaji: "Mazushii seikatsu o okuru.",
        traducao: "Levar uma vida pobre.",
      },
    ],
  },
  {
    vocabulario: "賢い",
    romaji: "kashikoi",
    hiragana: "かしこい",
    traducao: "inteligente, sábio",
    exemplosFrases: [
      {
        exemplo: "彼は賢い意見を持っている。",
        hiragana: "かれはかしこいいけんをもっている。",
        romaji: "Kare wa kashikoi iken o motte iru.",
        traducao: "Ele tem opiniões inteligentes.",
      },
      {
        exemplo: "賢い決断をする。",
        hiragana: "かしこいけつだんをする。",
        romaji: "Kashikoi ketsudan o suru.",
        traducao: "Tomar decisões sábias.",
      },
      {
        exemplo: "賢いリーダーシップを示す。",
        hiragana: "かしこいリーダーシップをしめす。",
        romaji: "Kashikoi rīdāshippu o shimesu.",
        traducao: "Demonstrar liderança inteligente.",
      },
    ],
  },
  {
    vocabulario: "鋭い",
    romaji: "surudoi",
    hiragana: "するどい",
    traducao: "afiado, perspicaz",
    exemplosFrases: [
      {
        exemplo: "彼は鋭い視点を持っている。",
        hiragana: "かれはするどいしてんをもっている。",
        romaji: "Kare wa surudoi shiten o motte iru.",
        traducao: "Ele tem uma perspectiva perspicaz.",
      },
      {
        exemplo: "鋭いナイフで野菜を切る。",
        hiragana: "するどいないふでやさいをきる。",
        romaji: "Surudoi naifu de yasai o kiru.",
        traducao: "Cortar vegetais com uma faca afiada.",
      },
      {
        exemplo: "鋭い意見を述べる。",
        hiragana: "するどいいけんをのべる。",
        romaji: "Surudoi iken o noberu.",
        traducao: "Expressar uma opinião perspicaz.",
      },
    ],
  },
  {
    vocabulario: "あらゆる",
    romaji: "arayuru",
    hiragana: "あらゆる",
    traducao: "todo, cada",
    exemplosFrases: [
      {
        exemplo: "あらゆる可能性を追求する。",
        hiragana: "あらゆるかのうせいをついきゅうする。",
        romaji: "Arayuru kanōsei o tsuikyū suru.",
        traducao: "Pursue every possibility.",
      },
      {
        exemplo: "あらゆる角度から考える。",
        hiragana: "あらゆるかくどからかんがえる。",
        romaji: "Arayuru kakudo kara kangaeru.",
        traducao: "Consider from every angle.",
      },
      {
        exemplo: "あらゆる情報を集める。",
        hiragana: "あらゆるじょうほうをあつめる。",
        romaji: "Arayuru jōhō o atsumeru.",
        traducao: "Gather every piece of information.",
      },
    ],
  },
  {
    vocabulario: "いわゆる",
    romaji: "iwayuru",
    hiragana: "いわゆる",
    traducao: "o chamado, assim chamado",
    exemplosFrases: [
      {
        exemplo: "彼はいわゆる天才だ。",
        hiragana: "かれはいわゆるてんさいだ。",
        romaji: "Kare wa iwayuru tensai da.",
        traducao: "He is what you would call a genius.",
      },
      {
        exemplo: "この建物はいわゆる文化財だ。",
        hiragana: "このたてものはいわゆるぶんかざいだ。",
        romaji: "Kono tatemono wa iwayuru bunkazai da.",
        traducao: "This building is what is known as a cultural asset.",
      },
      {
        exemplo: "彼はいわゆる大物だ。",
        hiragana: "かれはいわゆるおおものだ。",
        romaji: "Kare wa iwayuru ōmono da.",
        traducao: "He is what is called a big shot.",
      },
    ],
  },
  {
    vocabulario: "この",
    romaji: "kono",
    hiragana: "この",
    traducao: "este, esta",
    exemplosFrases: [
      {
        exemplo: "この本は面白い。",
        hiragana: "このほんはおもしろい。",
        romaji: "Kono hon wa omoshiroi.",
        traducao: "This book is interesting.",
      },
      {
        exemplo: "この場所で待ってください。",
        hiragana: "このばしょでまってください。",
        romaji: "Kono basho de matte kudasai.",
        traducao: "Please wait at this place.",
      },
      {
        exemplo: "この問題を解決する方法はありますか？",
        hiragana: "このもんだいをかいけつするほうほうはありますか？",
        romaji: "Kono mondai o kaiketsu suru hōhō wa arimasu ka?",
        traducao: "Is there a way to solve this problem?",
      },
    ],
  },
  {
    vocabulario: "その",
    romaji: "sono",
    hiragana: "その",
    traducao: "aquele, aquela",
    exemplosFrases: [
      {
        exemplo: "その車は高い。",
        hiragana: "そのくるまはたかい。",
        romaji: "Sono kuruma wa takai.",
        traducao: "That car is expensive.",
      },
      {
        exemplo: "その建物は古いです。",
        hiragana: "そのたてものはふるいです。",
        romaji: "Sono tatemono wa furui desu.",
        traducao: "That building is old.",
      },
      {
        exemplo: "その人は私の友達です。",
        hiragana: "そのひとはわたしのともだちです。",
        romaji: "Sono hito wa watashi no tomodachi desu.",
        traducao: "That person is my friend.",
      },
    ],
  },
  {
    vocabulario: "単なる",
    romaji: "tan'naru",
    hiragana: "たんなる",
    traducao: "mero, simplesmente",
    exemplosFrases: [
      {
        exemplo: "それは単なる噂にすぎない。",
        hiragana: "それはたんなるうわさにすぎない。",
        romaji: "Sore wa tan'naru uwasa ni suginai.",
        traducao: "It's nothing more than a mere rumor.",
      },
      {
        exemplo: "彼は単なるアマチュアだ。",
        hiragana: "かれはたんなるあまちゅあだ。",
        romaji: "Kare wa tan'naru amachua da.",
        traducao: "He's just a mere amateur.",
      },
      {
        exemplo: "それは単なる運の巡り合わせだ。",
        hiragana: "それはたんなるうんのめぐりあわせだ。",
        romaji: "Sore wa tan'naru un no meguriawase da.",
        traducao: "It's simply a matter of luck.",
      },
    ],
  },
  {
    vocabulario: "大した",
    romaji: "taishita",
    hiragana: "たいした",
    traducao: "grande, considerável",
    exemplosFrases: [
      {
        exemplo: "彼は大した選手だ。",
        hiragana: "かれはたいしたせんしゅだ。",
        romaji: "Kare wa taishita senshu da.",
        traducao: "He is a great player.",
      },
      {
        exemplo: "大した成果を得る。",
        hiragana: "たいしたせいかをえる。",
        romaji: "Taishita seika o eru.",
        traducao: "Achieve significant results.",
      },
      {
        exemplo: "その本は大した内容ではない。",
        hiragana: "そのほんはたいしたないようではない。",
        romaji: "Sono hon wa taishita naiyō dewa nai.",
        traducao: "That book is not of great content.",
      },
    ],
  },
  {
    vocabulario: "我が",
    romaji: "waga",
    hiragana: "わが",
    traducao: "meu, nossa",
    exemplosFrases: [
      {
        exemplo: "我が国",
        hiragana: "わがくに",
        romaji: "Waga kuni",
        traducao: "Meu país",
      },
      {
        exemplo: "我が家",
        hiragana: "わがいえ",
        romaji: "Waga ie",
        traducao: "Minha casa",
      },
      {
        exemplo: "我が社",
        hiragana: "わがしゃ",
        romaji: "Waga sha",
        traducao: "Nossa empresa",
      },
    ],
  },
  {
    vocabulario: "あるいは",
    romaji: "aruiwa",
    hiragana: "あるいは",
    traducao: "ou, talvez",
    exemplosFrases: [
      {
        exemplo: "彼は医者あるいは弁護士になりたい。",
        hiragana: "かれはいしゃあるいはべんごしになりたい。",
        romaji: "Kare wa isha aruiwa bengoshi ni naritai.",
        traducao: "Ele quer ser médico ou advogado.",
      },
      {
        exemplo: "明日は晴れる、あるいは雨が降るかもしれません。",
        hiragana: "あしたははれる、あるいはあめがふるかもしれません。",
        romaji: "Ashita wa hareru, aruiwa ame ga furu kamoshiremasen.",
        traducao: "Amanhã pode estar ensolarado ou chover.",
      },
      {
        exemplo: "彼女は大学に行くか、あるいは就職する予定です。",
        hiragana:
          "かのじょはだいがくにいくか、あるいはしゅうしょくするよていです。",
        romaji: "Kanojo wa daigaku ni iku ka, aruiwa shūshoku suru yotei desu.",
        traducao: "Ela planeja ir para a universidade ou conseguir um emprego.",
      },
    ],
  },
  {
    vocabulario: "さて",
    romaji: "sate",
    hiragana: "さて",
    traducao: "bem, então",
    exemplosFrases: [
      {
        exemplo: "さて、どこから始めましょうか。",
        hiragana: "さて、どこからはじめましょうか。",
        romaji: "Sate, doko kara hajimemashou ka.",
        traducao: "Bem, por onde devemos começar?",
      },
      {
        exemplo: "さて、次の話題は何ですか。",
        hiragana: "さて、つぎのわだいはなんですか。",
        romaji: "Sate, tsugi no wadai wa nan desu ka.",
        traducao: "Então, qual é o próximo tópico?",
      },
      {
        exemplo: "さて、これからどうしましょうか。",
        hiragana: "さて、これからどうしましょうか。",
        romaji: "Sate, kore kara dou shimashou ka.",
        traducao: "Bem, o que devemos fazer a partir de agora?",
      },
    ],
  },
  {
    vocabulario: "さらに",
    romaji: "sarani",
    hiragana: "さらに",
    traducao: "além disso, adicionalmente",
    exemplosFrases: [
      {
        exemplo: "新しい機能をさらに追加しました。",
        hiragana: "あたらしいきのうをさらについかしました。",
        romaji: "Atarashii kinō o sarani tsuikashimashita.",
        traducao: "Adicionamos novos recursos além disso.",
      },
      {
        exemplo: "さらに詳細な情報が必要です。",
        hiragana: "さらにしょうさいなじょうほうがひつようです。",
        romaji: "Sarani shōsai na jōhō ga hitsuyō desu.",
        traducao: "Precisamos de informações mais detalhadas também.",
      },
      {
        exemplo: "さらに時間がかかるかもしれません。",
        hiragana: "さらにじかんがかかるかもしれません。",
        romaji: "Sarani jikan ga kakaru kamoshiremasen.",
        traducao: "Pode levar mais tempo também.",
      },
    ],
  },
  {
    vocabulario: "しかも",
    romaji: "shikamo",
    hiragana: "しかも",
    traducao: "além disso, ainda mais",
    exemplosFrases: [
      {
        exemplo: "今日は忙しかった、しかも疲れた。",
        hiragana: "きょうはいそがしかった、しかもつかれた。",
        romaji: "Kyō wa isogashikatta, shikamo tsukareta.",
        traducao: "Hoje foi agitado, além disso, estou cansado.",
      },
      {
        exemplo: "彼は優秀な学生で、しかもスポーツも得意だ。",
        hiragana: "かれはゆうしゅうながくせいで、しかもスポーツもとくいだ。",
        romaji: "Kare wa yūshūna gakusei de, shikamo supōtsu mo tokui da.",
        traducao:
          "Ele é um estudante excelente e, além disso, é bom em esportes.",
      },
      {
        exemplo: "この商品は高品質で、しかも手頃な価格だ。",
        hiragana: "このしょうひんはこうひんしつで、しかもてごろなかかくだ。",
        romaji: "Kono shōhin wa kōhinshitsu de, shikamo tegorona kakaku da.",
        traducao:
          "Este produto é de alta qualidade e, além disso, tem um preço acessível.",
      },
    ],
  },
  {
    vocabulario: "すなわち",
    romaji: "sunawachi",
    hiragana: "すなわち",
    traducao: "ou seja, isto é",
    exemplosFrases: [
      {
        exemplo: "私たちはほかの言葉でいうと、すなわち友達です。",
        hiragana: "わたしたちはほかのことばでいうと、すなわちともだちです。",
        romaji:
          "Watashitachi wa hoka no kotoba de iu to, sunawachi tomodachi desu.",
        traducao: "Em outras palavras, somos amigos.",
      },
      {
        exemplo: "彼は日本の俳優、すなわち映画スターです。",
        hiragana: "かれはにほんのはいゆう、すなわちえいがすたーです。",
        romaji: "Kare wa Nihon no haiyū, sunawachi eigasutā desu.",
        traducao: "Ele é um ator japonês, ou seja, uma estrela de cinema.",
      },
      {
        exemplo: "彼女はパーティーで踊るのが好き、すなわちダンスが得意です。",
        hiragana:
          "かのじょはパーティーでおどるのがすき、すなわちだんすがとくいです。",
        romaji:
          "Kanojo wa pātī de odoru no ga suki, sunawachi dansu ga tokui desu.",
        traducao: "Ela gosta de dançar em festas, ou seja, ela é boa em dança.",
      },
    ],
  },
  {
    vocabulario: "ただ",
    romaji: "tada",
    hiragana: "ただ",
    traducao: "apenas, somente",
    exemplosFrases: [
      {
        exemplo: "ただ一つの問題",
        hiragana: "ただひとつのもんだい",
        romaji: "Tada hitotsu no mondai",
        traducao: "Apenas um problema",
      },
      {
        exemplo: "ただ勉強する",
        hiragana: "ただべんきょうする",
        romaji: "Tada benkyō suru",
        traducao: "Apenas estudar",
      },
      {
        exemplo: "ただ待つ",
        hiragana: "ただまつ",
        romaji: "Tada matsu",
        traducao: "Apenas esperar",
      },
    ],
  },
  {
    vocabulario: "なお",
    romaji: "nao",
    hiragana: "なお",
    traducao: "além disso, também",
    exemplosFrases: [
      {
        exemplo: "仕事が終わった、なお、予定がある。",
        hiragana: "しごとがおわった、なお、よていがある。",
        romaji: "Shigoto ga owatta, nao, yotei ga aru.",
        traducao: "Terminei o trabalho, além disso, tenho planos.",
      },
      {
        exemplo: "この町は美しい、なお、食べ物もおいしい。",
        hiragana: "このまちはうつくしい、なお、たべものもおいしい。",
        romaji: "Kono machi wa utsukushii, nao, tabemono mo oishii.",
        traducao:
          "Esta cidade é bonita, além disso, a comida também é deliciosa.",
      },
      {
        exemplo: "映画を見た、なお、本も読む予定だ。",
        hiragana: "えいがをみた、なお、ほんもよむよていだ。",
        romaji: "Eiga o mita, nao, hon mo yomu yotei da.",
        traducao:
          "Assisti a um filme, além disso, também tenho planos de ler um livro.",
      },
    ],
  },
  {
    vocabulario: "一方",
    romaji: "ippō",
    hiragana: "いっぽう",
    traducao: "por outro lado, enquanto",
    exemplosFrases: [
      {
        exemplo: "彼は頭がいい、一方で運動は苦手だ。",
        hiragana: "かれはあたまがいい、いっぽうでうんどうはにがてだ。",
        romaji: "Kare wa atama ga ii, ippō de undō wa nigate da.",
        traducao: "Ele é inteligente, por outro lado, é ruim em esportes.",
      },
      {
        exemplo: "田中さんは美しい、一方で料理も上手だ。",
        hiragana: "たなかさんはうつくしい、いっぽうでりょうりもじょうずだ。",
        romaji: "Tanaka-san wa utsukushii, ippō de ryōri mo jōzu da.",
        traducao:
          "A Sra. Tanaka é bonita, por outro lado, também é habilidosa na culinária.",
      },
      {
        exemplo: "時間がない、一方でやる気はある。",
        hiragana: "じかんがない、いっぽうでやるきはある。",
        romaji: "Jikan ga nai, ippō de yaruki wa aru.",
        traducao: "Não tenho tempo, por outro lado, tenho vontade de fazer.",
      },
    ],
  },
  {
    vocabulario: "又",
    romaji: "mata",
    hiragana: "また",
    traducao: "além disso, também",
    exemplosFrases: [
      {
        exemplo: "今日は忙しい、又、明日も忙しい。",
        hiragana: "きょうはいそがしい、また、あしたもいそがしい。",
        romaji: "Kyō wa isogashii, mata, ashita mo isogashii.",
        traducao:
          "Hoje estou ocupado, além disso, também estarei ocupado amanhã.",
      },
      {
        exemplo: "彼は優秀な学生、又、スポーツも得意だ。",
        hiragana: "かれはゆうしゅうながくせい、また、スポーツもとくいだ。",
        romaji: "Kare wa yūshūna gakusei, mata, supōtsu mo tokui da.",
        traducao:
          "Ele é um estudante excelente, além disso, também é bom em esportes.",
      },
      {
        exemplo: "この商品は高品質、又、手頃な価格だ。",
        hiragana: "このしょうひんはこうひんしつ、また、てごろなかかくだ。",
        romaji: "Kono shōhin wa kōhinshitsu, mata, tegorona kakaku da.",
        traducao:
          "Este produto é de alta qualidade, além disso, tem um preço acessível.",
      },
    ],
  },
  {
    vocabulario: "更に",
    romaji: "sara ni",
    hiragana: "さらに",
    traducao: "ainda mais, adicionalmente",
    exemplosFrases: [
      {
        exemplo: "新しい機能を更に追加しました。",
        hiragana: "あたらしいきのうをさらについかしました。",
        romaji: "Atarashii kinō o sara ni tsuikashimashita.",
        traducao: "Adicionamos ainda mais novos recursos.",
      },
      {
        exemplo: "さらに詳細な情報が必要です。",
        hiragana: "さらにしょうさいなじょうほうがひつようです。",
        romaji: "Sara ni shōsai na jōhō ga hitsuyō desu.",
        traducao: "Precisamos de informações ainda mais detalhadas.",
      },
      {
        exemplo: "さらに時間がかかるかもしれません。",
        hiragana: "さらにじかんがかかるかもしれません。",
        romaji: "Sara ni jikan ga kakaru kamoshiremasen.",
        traducao: "Pode levar ainda mais tempo.",
      },
    ],
  },
  {
    vocabulario: "下記",
    romaji: "kakki",
    hiragana: "かき",
    traducao: "abaixo mencionado, a seguir",
    exemplosFrases: [
      {
        exemplo: "下記の手順に従って操作してください。",
        hiragana: "かきのてじゅんにしたがってそうさしてください。",
        romaji: "Kakki no tejun ni shitagatte sōsa shite kudasai.",
        traducao:
          "Por favor, siga as instruções abaixo mencionadas para operar.",
      },
      {
        exemplo: "下記の表を参考にしてください。",
        hiragana: "かきのひょうをさんこうにしてください。",
        romaji: "Kakki no hyō o sankō ni shite kudasai.",
        traducao: "Por favor, consulte a tabela a seguir para referência.",
      },
      {
        exemplo: "下記のメールをご確認ください。",
        hiragana: "かきのメールをごかくにんください。",
        romaji: "Kakki no mēru o go-kakunin kudasai.",
        traducao: "Por favor, verifique o e-mail abaixo mencionado.",
      },
    ],
  },
  {
    vocabulario: "オープン",
    romaji: "ōpun",
    hiragana: "おーぷん",
    traducao: "aberto, abrir",
    exemplosFrases: [
      {
        exemplo: "この店は朝から夜までオープンしています。",
        hiragana: "このみせはあさからよるまでおーぷんしています。",
        romaji: "Kono mise wa asa kara yoru made ōpun shiteimasu.",
        traducao: "Esta loja está aberta desde a manhã até a noite.",
      },
      {
        exemplo: "新しいレストランがオープンしました。",
        hiragana: "あたらしいれすとらんがおーぷんしました。",
        romaji: "Atarashii resutoran ga ōpun shimashita.",
        traducao: "Um novo restaurante abriu.",
      },
      {
        exemplo: "来週、新しいスーパーマーケットがオープンします。",
        hiragana: "らいしゅう、あたらしいすーぱーまーけっとがおーぷんします。",
        romaji: "Raishū, atarashii sūpāmāketto ga ōpun shimasu.",
        traducao: "Na próxima semana, abrirá um novo supermercado.",
      },
    ],
  },
  {
    vocabulario: "悪口",
    romaji: "warukuchi",
    hiragana: "わるくち",
    traducao: "falar mal, difamação",
    exemplosFrases: [
      {
        exemplo: "他の人の悪口を言うのはやめましょう。",
        hiragana: "ほかのひとのわるくちをいうのはやめましょう。",
        romaji: "Hoka no hito no warukuchi o iu no wa yamemashō.",
        traducao: "Vamos parar de falar mal das outras pessoas.",
      },
      {
        exemplo: "悪口を言われても、反応しないようにしましょう。",
        hiragana: "わるくちをいわれても、はんのうしないようにしましょう。",
        romaji: "Warukuchi o iwarete mo, hannō shinai yō ni shimashō.",
        traducao: "Vamos tentar não reagir quando falarem mal de nós.",
      },
      {
        exemplo: "人を傷つける悪口は避けましょう。",
        hiragana: "ひとをきずつけるわるくちはさけましょう。",
        romaji: "Hito o kizutsukeru warukuchi wa sakemashō.",
        traducao: "Vamos evitar difamar e ferir as pessoas.",
      },
    ],
  },
  {
    vocabulario: "キャンプ",
    romaji: "kyanpu",
    hiragana: "きゃんぷ",
    traducao: "acampamento",
    exemplosFrases: [
      {
        exemplo: "夏休みにキャンプに行く予定です。",
        hiragana: "なつやすみにきゃんぷにいくよていです。",
        romaji: "Natsuyasumi ni kyanpu ni iku yotei desu.",
        traducao: "Tenho planos de ir acampar nas férias de verão.",
      },
      {
        exemplo: "友達と一緒にキャンプファイヤーを楽しみました。",
        hiragana: "ともだちといっしょにきゃんぷふぁいやーをたのしみました。",
        romaji: "Tomodachi to issho ni kyanpufaiyā o tanoshimimashita.",
        traducao:
          "Diverti-me com meus amigos ao redor da fogueira no acampamento.",
      },
      {
        exemplo: "キャンプ場でテントを張りました。",
        hiragana: "きゃんぷじょうでてんとをはりました。",
        romaji: "Kyanpujō de tento o harimashita.",
        traducao: "Montamos uma barraca no acampamento.",
      },
    ],
  },
  {
    vocabulario: "ヒット",
    romaji: "hitto",
    hiragana: "ひっと",
    traducao: "sucesso, hit",
    exemplosFrases: [
      {
        exemplo: "新しい映画は大ヒットしました。",
        hiragana: "あたらしいえいがはだいひっとしました。",
        romaji: "Atarashii eiga wa daihitto shimashita.",
        traducao: "O novo filme foi um grande sucesso.",
      },
      {
        exemplo: "彼女の最新曲がヒットしています。",
        hiragana: "かのじょのさいしんきょくがひっとしています。",
        romaji: "Kanojo no saishin kyoku ga hitto shiteimasu.",
        traducao: "A última música dela está fazendo sucesso.",
      },
      {
        exemplo: "この商品は市場でヒットしています。",
        hiragana: "このしょうひんはいちばでひっとしています。",
        romaji: "Kono shōhin wa ichiba de hitto shiteimasu.",
        traducao: "Este produto está sendo um sucesso no mercado.",
      },
    ],
  },
  {
    vocabulario: "延期",
    romaji: "enki",
    hiragana: "えんき",
    traducao: "adiar, postergar",
    exemplosFrases: [
      {
        exemplo: "イベントは来月に延期されました。",
        hiragana: "いべんとはらいげつにえんきされました。",
        romaji: "Ibento wa raigetsu ni enki saremashita.",
        traducao: "O evento foi adiado para o próximo mês.",
      },
      {
        exemplo: "会議は急な事情で延期になりました。",
        hiragana: "かいぎはきゅうなじじょうでえんきになりました。",
        romaji: "Kaigi wa kyūna jijō de enki ni narimashita.",
        traducao: "A reunião foi adiada devido a circunstâncias imprevistas.",
      },
      {
        exemplo: "新製品の発売が延期されました。",
        hiragana: "しんせいひんのはつばいがえんきされました。",
        romaji: "Shinseihin no hatsubai ga enki saremashita.",
        traducao: "O lançamento do novo produto foi adiado.",
      },
    ],
  },
  {
    vocabulario: "快適",
    romaji: "kaiteki",
    hiragana: "かいてき",
    traducao: "confortável, conforto",
    exemplosFrases: [
      {
        exemplo: "このホテルは快適な部屋があります。",
        hiragana: "このほてるはかいてきなへやがあります。",
        romaji: "Kono hoteru wa kaiteki na heya ga arimasu.",
        traducao: "Este hotel possui quartos confortáveis.",
      },
      {
        exemplo: "新しいソファはとても快適です。",
        hiragana: "あたらしいそふぁはとてもかいてきです。",
        romaji: "Atarashii sofa wa totemo kaiteki desu.",
        traducao: "O novo sofá é muito confortável.",
      },
      {
        exemplo: "快適な環境で仕事ができる。",
        hiragana: "かいてきなかんきょうでしごとができる。",
        romaji: "Kaiteki na kankyō de shigoto ga dekiru.",
        traducao: "É possível trabalhar em um ambiente confortável.",
      },
    ],
  },
  {
    vocabulario: "せい",
    romaji: "sei",
    hiragana: "せい",
    traducao: "culpa, responsabilidade",
    exemplosFrases: [
      {
        exemplo: "失敗は自分のせいだ。",
        hiragana: "しっぱいはじぶんのせいだ。",
        romaji: "Shippai wa jibun no sei da.",
        traducao: "A falha é culpa minha.",
      },
      {
        exemplo: "彼にはこの問題のせいではない。",
        hiragana: "かれにはこのもんだいのせいではない。",
        romaji: "Kare ni wa kono mondai no sei dewa nai.",
        traducao: "Não é responsabilidade dele por esse problema.",
      },
      {
        exemplo: "成功も自分の努力のせいだ。",
        hiragana: "せいこうもじぶんのどりょくのせいだ。",
        romaji: "Seikō mo jibun no doryoku no sei da.",
        traducao: "O sucesso também é resultado do meu próprio esforço.",
      },
    ],
  },
  {
    vocabulario: "加減",
    romaji: "kagen",
    hiragana: "かげん",
    traducao: "grau, quantidade, medida",
    exemplosFrases: [
      {
        exemplo: "お塩の加減を調整してください。",
        hiragana: "おしおのかげんをちょうせいしてください。",
        romaji: "O-shio no kagen o chōsei shite kudasai.",
        traducao: "Por favor, ajuste a quantidade de sal.",
      },
      {
        exemplo: "お酒の飲みすぎには注意しましょう。加減が大事です。",
        hiragana:
          "おさけののみすぎにはちゅういしましょう。かげんがだいじです。",
        romaji: "Osake no nomisugi ni wa chūi shimashō. Kagen ga daiji desu.",
        traducao:
          "Vamos ter cuidado com o consumo excessivo de álcool. A medida é importante.",
      },
      {
        exemplo: "お菓子の甘さの加減がちょうどいい。",
        hiragana: "おかしのあまさのかげんがちょうどいい。",
        romaji: "Okashi no amasa no kagen ga chōdo ii.",
        traducao: "O doce está na medida certa de doçura.",
      },
    ],
  },
  {
    vocabulario: "アレルギー",
    romaji: "arerugī",
    hiragana: "あれるぎー",
    traducao: "alergia",
    exemplosFrases: [
      {
        exemplo: "私は花粉のアレルギーがあります。",
        hiragana: "わたしはかふんのあれるぎーがあります。",
        romaji: "Watashi wa kafun no arerugī ga arimasu.",
        traducao: "Eu tenho alergia ao pólen.",
      },
      {
        exemplo: "アレルギーを持っている人には気をつけましょう。",
        hiragana: "あれるぎーをもっているひとにはきをつけましょう。",
        romaji: "Arerugī o motte iru hito ni wa ki o tsukemashō.",
        traducao: "Vamos ter cuidado com pessoas que têm alergias.",
      },
      {
        exemplo: "アレルギー反応が出る場合は医師に相談してください。",
        hiragana:
          "あれるぎーはんのうがでるばあいはいしにそうだんしてください。",
        romaji: "Arerugī hannō ga deru baai wa ishi ni sōdan shite kudasai.",
        traducao: "Em caso de reação alérgica, consulte um médico.",
      },
    ],
  },
  {
    vocabulario: "餌",
    romaji: "esa",
    hiragana: "えさ",
    traducao: "alimento, ração",
    exemplosFrases: [
      {
        exemplo: "飼い猫に毎日餌を与えています。",
        hiragana: "かいねこにまいにちえさをあたえています。",
        romaji: "Kaineko ni mainichi esa o ataeteimasu.",
        traducao: "Alimento meu gato todos os dias.",
      },
      {
        exemplo: "魚には小さな餌を使ってください。",
        hiragana: "さかなにはちいさなえさをつかってください。",
        romaji: "Sakana ni wa chiisana esa o tsukatte kudasai.",
        traducao: "Por favor, use uma ração pequena para os peixes.",
      },
      {
        exemplo: "餌をあげると野鳥が集まってきます。",
        hiragana: "えさをあげるとやちょうがあつまってきます。",
        romaji: "Esa o ageru to yachō ga atsumatte kimasu.",
        traducao: "Os pássaros selvagens se reúnem quando eu dou comida.",
      },
    ],
  },
  {
    vocabulario: "家賃",
    romaji: "yachin",
    hiragana: "やちん",
    traducao: "aluguel",
    exemplosFrases: [
      {
        exemplo: "毎月の家賃は銀行振込で支払っています。",
        hiragana: "まいつきのやちんはぎんこうふりこみでしはらっています。",
        romaji: "Maitsuki no yachin wa ginkō furikomi de shiharatteimasu.",
        traducao: "Eu pago o aluguel mensalmente por transferência bancária.",
      },
      {
        exemplo: "家賃が高くて生活が苦しい。",
        hiragana: "やちんがたかくてせいかつがくるしい。",
        romaji: "Yachin ga takakute seikatsu ga kurushii.",
        traducao: "O aluguel é alto e a vida está difícil.",
      },
      {
        exemplo: "新しいアパートの家賃は手頃です。",
        hiragana: "あたらしいあぱーとのやちんはてごろです。",
        romaji: "Atarashii apāto no yachin wa tegoro desu.",
        traducao: "O aluguel do novo apartamento é acessível.",
      },
    ],
  },
  {
    vocabulario: "レンタル",
    romaji: "rentaru",
    hiragana: "れんたる",
    traducao: "aluguel, locação",
    exemplosFrases: [
      {
        exemplo: "映画をレンタルして家で観ました。",
        hiragana: "えいがをれんたるしていえでみました。",
        romaji: "Eiga o rentaru shite ie de mimashita.",
        traducao: "Aluguei um filme e assisti em casa.",
      },
      {
        exemplo: "自転車をレンタルして街を散策しました。",
        hiragana: "じてんしゃをれんたるしてまちをさんさくしました。",
        romaji: "Jitensha o rentaru shite machi o sansaku shimashita.",
        traducao: "Aluguei uma bicicleta e explorei a cidade.",
      },
      {
        exemplo: "レンタルスーツを着てパーティーに参加しました。",
        hiragana: "れんたるすーつをきてぱーてぃーにさんかしました。",
        romaji: "Rentaru sūtsu o kite pātī ni sankashimashita.",
        traducao: "Vesti um terno alugado e participei da festa.",
      },
    ],
  },
  {
    vocabulario: "愛情",
    romaji: "aijō",
    hiragana: "あいじょう",
    traducao: "amor, afeto",
    exemplosFrases: [
      {
        exemplo: "彼女は動物たちにたくさんの愛情を注いでいます。",
        hiragana:
          "かのじょはどうぶつたちにたくさんのあいじょうをそそいでいます。",
        romaji: "Kanojo wa dōbutsu-tachi ni takusan no aijō o sosoideteimasu.",
        traducao: "Ela dá muito amor aos animais.",
      },
      {
        exemplo: "愛情ある言葉をかけることが大切です。",
        hiragana: "あいじょうあることばをかけることがたいせつです。",
        romaji: "Aijō aru kotoba o kakeru koto ga taisetsu desu.",
        traducao: "É importante expressar palavras amorosas.",
      },
      {
        exemplo: "家族への愛情を大切にしましょう。",
        hiragana: "かぞくへのあいじょうをたいせつにしましょう。",
        romaji: "Kazoku e no aijō o taisetsu ni shimashō.",
        traducao: "Vamos valorizar o amor pela família.",
      },
    ],
  },
  {
    vocabulario: "愛",
    romaji: "ai",
    hiragana: "あい",
    traducao: "amor",
    exemplosFrases: [
      {
        exemplo: "彼は家族に深い愛を持っています。",
        hiragana: "かれはかぞくにふかいあいをもっています。",
        romaji: "Kare wa kazoku ni fukai ai o motteimasu.",
        traducao: "Ele tem um amor profundo pela família.",
      },
      {
        exemplo: "愛する人の幸せを願います。",
        hiragana: "あいするひとのしあわせをねがいます。",
        romaji: "Ai suru hito no shiawase o negaimasu.",
        traducao: "Desejo a felicidade da pessoa amada.",
      },
      {
        exemplo: "愛は人々を結びつける力です。",
        hiragana: "あいはひとびとをむすびつけるちからです。",
        romaji: "Ai wa hitobito o musubitsukeru chikara desu.",
        traducao: "O amor é uma força que une as pessoas.",
      },
    ],
  },
  {
    vocabulario: "アニメ",
    romaji: "anime",
    hiragana: "あにめ",
    traducao: "anime",
    exemplosFrases: [
      {
        exemplo: "最新のアニメを見るのが好きです。",
        hiragana: "さいしんのあにめをみるのがすきです。",
        romaji: "Saishin no anime o miru no ga suki desu.",
        traducao: "Gosto de assistir aos animes mais recentes.",
      },
      {
        exemplo: "アニメキャラクターのグッズを集めています。",
        hiragana: "あにめきゃらくたーのぐっずをあつめています。",
        romaji: "Anime kyarakutā no guzzu o atsumeteimasu.",
        traducao:
          "Estou colecionando produtos relacionados aos personagens de anime.",
      },
      {
        exemplo: "アニメのストーリーに夢中になりました。",
        hiragana: "あにめのすとーりーにむちゅうになりました。",
        romaji: "Anime no sutōrī ni muchū ni narimashita.",
        traducao: "Fiquei obcecado com a história do anime.",
      },
    ],
  },
  {
    vocabulario: "握手",
    romaji: "akushu",
    hiragana: "あくしゅ",
    traducao: "aperto de mãos",
    exemplosFrases: [
      {
        exemplo: "挨拶の時に握手をするのが普通です。",
        hiragana: "あいさつのときにあくしゅをするのがふつうです。",
        romaji: "Aisatsu no toki ni akushu o suru no ga futsū desu.",
        traducao: "É comum apertar as mãos ao cumprimentar.",
      },
      {
        exemplo: "イベントで有名な人と握手する機会がありました。",
        hiragana: "いべんとでゆうめいなひととあくしゅするきかいがありました。",
        romaji: "Ibento de yūmei na hito to akushu suru kikai ga arimashita.",
        traducao:
          "Tive a oportunidade de apertar as mãos com uma pessoa famosa no evento.",
      },
      {
        exemplo: "握手をすることで感謝の気持ちを伝えました。",
        hiragana: "あくしゅをすることでかんしゃのきもちをつたえました。",
        romaji: "Akushu o suru koto de kansha no kimochi o tsutaemashita.",
        traducao: "Expresso meus sentimentos de gratidão apertando as mãos.",
      },
    ],
  },
  {
    vocabulario: "サポート",
    romaji: "sapōto",
    hiragana: "さぽーと",
    traducao: "apoio, suporte",
    exemplosFrases: [
      {
        exemplo: "新しいプロジェクトにサポートを提供しています。",
        hiragana: "あたらしいぷろじぇくとにさぽーとをていきょうしています。",
        romaji: "Atarashii purojekuto ni sapōto o teikyō shiteimasu.",
        traducao: "Estou oferecendo suporte ao novo projeto.",
      },
      {
        exemplo: "彼はいつも私たちをサポートしてくれます。",
        hiragana: "かれはいつもわたしたちをさぽーとしてくれます。",
        romaji: "Kare wa itsumo watashitachi o sapōto shite kuremasu.",
        traducao: "Ele sempre nos apoia.",
      },
      {
        exemplo: "サポートが必要な場合はいつでも連絡してください。",
        hiragana: "さぽーとがひつようなばあいはいつでもれんらくしてください。",
        romaji: "Sapōto ga hitsuyō na baai wa itsudemo renraku shite kudasai.",
        traducao: "Entre em contato conosco sempre que precisar de suporte.",
      },
    ],
  },
  {
    vocabulario: "アルバム",
    romaji: "arubamu",
    hiragana: "あるばむ",
    traducao: "álbum",
    exemplosFrases: [
      {
        exemplo: "最新の写真をアルバムに入れました。",
        hiragana: "さいしんのしゃしんをあるばむにいれました。",
        romaji: "Saishin no shashin o arubamu ni iremashita.",
        traducao: "Coloquei as fotos mais recentes no álbum.",
      },
      {
        exemplo: "このアルバムは大切な思い出が詰まっています。",
        hiragana: "このあるばむはたいせつなおもいでがつまっています。",
        romaji: "Kono arubamu wa taisetsu na omoide ga tsumatteimasu.",
        traducao: "Este álbum está cheio de memórias preciosas.",
      },
      {
        exemplo: "アルバムを開いて過去の思い出を振り返りました。",
        hiragana: "あるばむをひらいてかこのおもいでをふりかえりました。",
        romaji: "Arubamu o hiraite kako no omoide o furikaerimashita.",
        traducao: "Abri o álbum e relembrei as memórias do passado.",
      },
    ],
  },
  {
    vocabulario: "おかげ",
    romaji: "okage",
    hiragana: "おかげ",
    traducao: "graças a, devido a",
    exemplosFrases: [
      {
        exemplo: "皆さんのおかげで、イベントは成功しました。",
        hiragana: "みなさんのおかげで、いべんとはせいこうしました。",
        romaji: "Minasan no okage de, ibento wa seikō shimashita.",
        traducao: "Graças a todos, o evento foi um sucesso.",
      },
      {
        exemplo: "おかげさまで、無事に目的地に到着しました。",
        hiragana: "おかげさまで、ぶじにもくてきちにとうちゃくしました。",
        romaji: "Okagesama de, buji ni mokutekichi ni tōchaku shimashita.",
        traducao: "Graças a Deus, chegamos ao nosso destino em segurança.",
      },
      {
        exemplo: "おかげさまで、仕事が順調に進んでいます。",
        hiragana: "おかげさまで、しごとがじゅんちょうにすすんでいます。",
        romaji: "Okagesama de, shigoto ga junchō ni susundeimasu.",
        traducao: "Graças a você, o trabalho está progredindo bem.",
      },
    ],
  },
  {
    vocabulario: "プレゼン",
    romaji: "purezen",
    hiragana: "ぷれぜん",
    traducao: "apresentação",
    exemplosFrases: [
      {
        exemplo: "明日の会議でプレゼンをする予定です。",
        hiragana: "あしたのかいぎでぷれぜんをするよていです。",
        romaji: "Ashita no kaigi de purezen o suru yotei desu.",
        traducao:
          "Estou planejando fazer uma apresentação na reunião de amanhã.",
      },
      {
        exemplo: "プレゼンの準備に時間をかけました。",
        hiragana: "ぷれぜんのじゅんびにじかんをかけました。",
        romaji: "Purezen no junbi ni jikan o kakemashita.",
        traducao: "Dediquei tempo para me preparar para a apresentação.",
      },
      {
        exemplo: "プレゼン中に質問がありました。",
        hiragana: "ぷれぜんちゅうにしつもんがありました。",
        romaji: "Purezen-chū ni shitsumon ga arimashita.",
        traducao: "Houve perguntas durante a apresentação.",
      },
    ],
  },
  {
    vocabulario: "プレゼンテーション",
    romaji: "purezentai-shon",
    hiragana: "ぷれぜんてーしょん",
    traducao: "apresentação",
    exemplosFrases: [
      {
        exemplo: "新商品のプレゼンテーションを行いました。",
        hiragana: "しんしょうひんのぷれぜんてーしょんをおこないました。",
        romaji: "Shinshōhin no purezentai-shon o okonaimashita.",
        traducao: "Realizamos uma apresentação do novo produto.",
      },
      {
        exemplo: "プレゼンテーションのスライドを作成しました。",
        hiragana: "ぷれぜんてーしょんのすらいどをさくせいしました。",
        romaji: "Purezentai-shon no suraido o sakusei shimashita.",
        traducao: "Criei os slides da apresentação.",
      },
      {
        exemplo: "プレゼンテーションの準備が整いました。",
        hiragana: "ぷれぜんてーしょんのじゅんびがととのいました。",
        romaji: "Purezentai-shon no junbi ga totonoimashita.",
        traducao: "A preparação para a apresentação está pronta.",
      },
    ],
  },
  {
    vocabulario: "ファイル",
    romaji: "fairu",
    hiragana: "ふぁいる",
    traducao: "arquivo",
    exemplosFrases: [
      {
        exemplo: "大事な文書をファイルに保存しました。",
        hiragana: "だいじなぶんしょをふぁいるにほぞんしました。",
        romaji: "Daijina bunsho o fairu ni hozon shimashita.",
        traducao: "Salvei o documento importante no arquivo.",
      },
      {
        exemplo: "ファイルを開いてデータを確認しました。",
        hiragana: "ふぁいるをひらいてでーたをかくにんしました。",
        romaji: "Fairu o hiraite dēta o kakunin shimashita.",
        traducao: "Abri o arquivo e verifiquei os dados.",
      },
      {
        exemplo: "ファイルのコピーを作成しました。",
        hiragana: "ふぁいるのこぴーをさくせいしました。",
        romaji: "Fairu no kopī o sakusei shimashita.",
        traducao: "Criei uma cópia do arquivo.",
      },
    ],
  },
  {
    vocabulario: "稲",
    romaji: "ine",
    hiragana: "いね",
    traducao: "arroz",
    exemplosFrases: [
      {
        exemplo: "この地域では稲の栽培が盛んです。",
        hiragana: "このちいきではいねのさいばいがさかんです。",
        romaji: "Kono chiiki de wa ine no saibai ga sakan desu.",
        traducao: "O cultivo de arroz é próspero nesta região.",
      },
      {
        exemplo: "稲を収穫する季節がやってきました。",
        hiragana: "いねをしゅうかくするきせつがやってきました。",
        romaji: "Ine o shūkaku suru kisetsu ga yattekimashita.",
        traducao: "Chegou a temporada de colheita do arroz.",
      },
      {
        exemplo: "美味しい稲を使っておにぎりを作りました。",
        hiragana: "おいしいいねをつかっておにぎりをつくりました。",
        romaji: "Oishii ine o tsukatte onigiri o tsukurimashita.",
        traducao: "Fiz onigiri usando arroz delicioso.",
      },
    ],
  },
  {
    vocabulario: "解決",
    romaji: "kaiketsu",
    hiragana: "かいけつ",
    traducao: "resolução, solução",
    exemplosFrases: [
      {
        exemplo: "問題の解決方法を探しています。",
        hiragana: "もんだいのかいけつほうほうをさがしています。",
        romaji: "Mondai no kaiketsu hōhō o sagashiteimasu.",
        traducao: "Estou procurando maneiras de resolver o problema.",
      },
      {
        exemplo: "話し合いによって問題を解決しました。",
        hiragana: "はなしあいによってもんだいをかいけつしました。",
        romaji: "Hanashiai ni yotte mondai o kaiketsu shimashita.",
        traducao: "Resolvemos o problema por meio de discussões.",
      },
      {
        exemplo: "全員の協力により、問題が解決されました。",
        hiragana:
          "ぜんいんのきょうりょくにより、もんだいがかいけつされました。",
        romaji: "Zen'in no kyōryoku ni yori, mondai ga kaiketsu sa remashita.",
        traducao: "Com a cooperação de todos, o problema foi resolvido.",
      },
    ],
  },
  {
    vocabulario: "援助",
    romaji: "enjo",
    hiragana: "えんじょ",
    traducao: "assistência, auxílio",
    exemplosFrases: [
      {
        exemplo: "困っている人々に援助を提供します。",
        hiragana: "こまっているひとびとにえんじょをていきょうします。",
        romaji: "Komatteiru hitobito ni enjo o teikyō shimasu.",
        traducao: "Vamos fornecer assistência às pessoas em dificuldades.",
      },
      {
        exemplo: "被災地に援助物資を届けました。",
        hiragana: "ひさいちにえんじょぶっしをとどけました。",
        romaji: "Hisaichi ni enjo busshi o todokemashita.",
        traducao: "Entregamos suprimentos de assistência às áreas afetadas.",
      },
      {
        exemplo: "援助を必要とする人々をサポートしています。",
        hiragana: "えんじょをひつようとするひとびとをさぽーとしています。",
        romaji: "Enjo o hitsuyō to suru hitobito o sapōto shiteimasu.",
        traducao: "Estamos apoiando as pessoas que precisam de assistência.",
      },
    ],
  },
  {
    vocabulario: "以前",
    romaji: "izen",
    hiragana: "いぜん",
    traducao: "anteriormente, antes",
    exemplosFrases: [
      {
        exemplo: "以前、東京に住んでいました。",
        hiragana: "いぜん、とうきょうにすんでいました。",
        romaji: "Izen, Tōkyō ni sundeimashita.",
        traducao: "Anteriormente, eu morava em Tóquio.",
      },
      {
        exemplo: "以前の仕事はとても忙しかったです。",
        hiragana: "いぜんのしごとはとてもいそがしかったです。",
        romaji: "Izen no shigoto wa totemo isogashikatta desu.",
        traducao: "Meu trabalho anterior era muito ocupado.",
      },
      {
        exemplo: "以前の経験が役立ちました。",
        hiragana: "いぜんのけいけんがやくだちました。",
        romaji: "Izen no keiken ga yakudachimashita.",
        traducao: "Minha experiência anterior foi útil.",
      },
    ],
  },
  {
    vocabulario: "演技",
    romaji: "engi",
    hiragana: "えんぎ",
    traducao: "atuação, performance",
    exemplosFrases: [
      {
        exemplo: "彼は優れた演技力を持っています。",
        hiragana: "かれはすぐれたえんぎりょくをもっています。",
        romaji: "Kare wa sugureta engi-ryoku o motteimasu.",
        traducao: "Ele tem excelentes habilidades de atuação.",
      },
      {
        exemplo: "映画の演技に感動しました。",
        hiragana: "えいがのえんぎにかんどうしました。",
        romaji: "Eiga no engi ni kandō shimashita.",
        traducao: "Fiquei emocionado com a atuação no filme.",
      },
      {
        exemplo: "彼女の演技は自然で魅力的でした。",
        hiragana: "かのじょのえんぎはしぜんでみりょくてきでした。",
        romaji: "Kanojo no engi wa shizen de miryokuteki deshita.",
        traducao: "A atuação dela foi natural e cativante.",
      },
    ],
  },
  {
    vocabulario: "サイン",
    romaji: "sain",
    hiragana: "さいん",
    traducao: "assinatura",
    exemplosFrases: [
      {
        exemplo: "有名な俳優のサインをもらいました。",
        hiragana: "ゆうめいなはいゆうのさいんをもらいました。",
        romaji: "Yūmei na haiyū no sain o moraimashita.",
        traducao: "Recebi a assinatura de um ator famoso.",
      },
      {
        exemplo: "契約書にサインをしてください。",
        hiragana: "けいやくしょにさいんをしてください。",
        romaji: "Keiyakusho ni sain o shite kudasai.",
        traducao: "Por favor, assine o contrato.",
      },
      {
        exemplo: "サインをもらうために並びました。",
        hiragana: "さいんをもらうためにならびました。",
        romaji: "Sain o morau tame ni narabimashita.",
        traducao: "Esperei na fila para conseguir uma assinatura.",
      },
    ],
  },
  {
    vocabulario: "バドミントン",
    romaji: "badominton",
    hiragana: "ばどみんとん",
    traducao: "badminton",
    exemplosFrases: [
      {
        exemplo: "週末に友達とバドミントンをします。",
        hiragana: "しゅうまつにともだちとばどみんとんをします。",
        romaji: "Shūmatsu ni tomodachi to badominton o shimasu.",
        traducao: "Jogo badminton com meus amigos nos finais de semana.",
      },
      {
        exemplo: "バドミントンの試合に出場しました。",
        hiragana: "ばどみんとんのしあいにしゅつじょうしました。",
        romaji: "Badominton no shiai ni shutsujō shimashita.",
        traducao: "Participei de um torneio de badminton.",
      },
      {
        exemplo: "バドミントンのスキルを向上させたいです。",
        hiragana: "ばどみんとんのすきるをこうじょうさせたいです。",
        romaji: "Badominton no sukiru o kōjō sasetai desu.",
        traducao: "Quero melhorar minhas habilidades no badminton.",
      },
    ],
  },
  {
    vocabulario: "荷物",
    romaji: "nimotsu",
    hiragana: "にもつ",
    traducao: "bagagem, pacote",
    exemplosFrases: [
      {
        exemplo: "空港で荷物を受け取りました。",
        hiragana: "くうこうでにもつをうけとりました。",
        romaji: "Kūkō de nimotsu o uketorimashita.",
        traducao: "Peguei minha bagagem no aeroporto.",
      },
      {
        exemplo: "荷物が重すぎて持てません。",
        hiragana: "にもつがおもすぎてもてません。",
        romaji: "Nimotsu ga omosugite motemasen.",
        traducao: "A bagagem está muito pesada e não consigo carregá-la.",
      },
      {
        exemplo: "荷物を整理してスーツケースに詰めました。",
        hiragana: "にもつをせいりしてすーつけーすにつめました。",
        romaji: "Nimotsu o seiri shite sūtsukēsu ni tsumemashita.",
        traducao: "Organizei as bagagens e as coloquei na mala.",
      },
    ],
  },
  {
    vocabulario: "ベンチ",
    romaji: "bench",
    hiragana: "べんち",
    traducao: "banco (assento)",
    exemplosFrases: [
      {
        exemplo: "公園のベンチで休憩しました。",
        hiragana: "こうえんのべんちできゅうけいしました。",
        romaji: "Kōen no benchi de kyūkei shimashita.",
        traducao: "Descansei no banco do parque.",
      },
      {
        exemplo: "ベンチで本を読んでいる人を見かけました。",
        hiragana: "べんちでほんをよんでいるひとをみかけました。",
        romaji: "Benchi de hon o yonde iru hito o mikakemashita.",
        traducao: "Avistei alguém lendo um livro no banco.",
      },
      {
        exemplo: "ベンチに座って景色を楽しみました。",
        hiragana: "べんちにすわってけしきをたのしみました。",
        romaji: "Benchi ni suwatte keshiki o tanoshimimashita.",
        traducao: "Sentei no banco e apreciei a paisagem.",
      },
    ],
  },
  {
    vocabulario: "バンド",
    romaji: "bando",
    hiragana: "ばんど",
    traducao: "banda (música)",
    exemplosFrases: [
      {
        exemplo: "友達と一緒にバンドを組んでいます。",
        hiragana: "ともだちといっしょにばんどをくんでいます。",
        romaji: "Tomodachi to issho ni bando o kundeimasu.",
        traducao: "Estou montando uma banda com meus amigos.",
      },
      {
        exemplo: "バンドのライブに行きたいです。",
        hiragana: "ばんどのらいぶにいきたいです。",
        romaji: "Bando no raibu ni ikitai desu.",
        traducao: "Quero ir a um show da banda.",
      },
      {
        exemplo: "バンドのメンバーと新曲を作りました。",
        hiragana: "ばんどのめんばーとしんきょくをつくりました。",
        romaji: "Bando no menbā to shinkyoku o tsukurimashita.",
        traducao: "Criei uma nova música com os membros da banda.",
      },
    ],
  },
  {
    vocabulario: "トイレット",
    romaji: "toiretto",
    hiragana: "といれっと",
    traducao: "toilet, banheiro",
    exemplosFrases: [
      {
        exemplo: "駅のトイレットを利用しました。",
        hiragana: "えきのといれっとをりようしました。",
        romaji: "Eki no toiretto o riyō shimashita.",
        traducao: "Usei o banheiro da estação.",
      },
      {
        exemplo: "トイレットに入っている人がいました。",
        hiragana: "といれっとにはいっているひとがいました。",
        romaji: "Toiretto ni haitte iru hito ga imashita.",
        traducao: "Havia alguém usando o banheiro.",
      },
      {
        exemplo: "トイレットが混んでいて待たなければなりませんでした。",
        hiragana: "といれっとがこんでいてまたなければなりませんでした。",
        romaji: "Toiretto ga konde ite matanakereba narimasen deshita.",
        traducao: "O banheiro estava cheio e tive que esperar.",
      },
    ],
  },
  {
    vocabulario: "ボート",
    romaji: "bōto",
    hiragana: "ぼーと",
    traducao: "barco, boat",
    exemplosFrases: [
      {
        exemplo: "湖でボートに乗りました。",
        hiragana: "みずうみでぼーとにのりました。",
        romaji: "Mizūmi de bōto ni norimashita.",
        traducao: "Andei de barco no lago.",
      },
      {
        exemplo: "ボートで川を下りました。",
        hiragana: "ぼーとでかわをくだりました。",
        romaji: "Bōto de kawa o kudarimashita.",
        traducao: "Desci o rio de barco.",
      },
      {
        exemplo: "友達とボートに乗って楽しい時間を過ごしました。",
        hiragana: "ともだちとぼーとにのってたのしいじかんをすごしました。",
        romaji: "Tomodachi to bōto ni notte tanoshii jikan o sugoshimashita.",
        traducao: "Passei um tempo divertido andando de barco com meus amigos.",
      },
    ],
  },
  {
    vocabulario: "テント",
    romaji: "tento",
    hiragana: "てんと",
    traducao: "tenda",
    exemplosFrases: [
      {
        exemplo: "キャンプでテントを設置しました。",
        hiragana: "きゃんぷでてんとをせっちしました。",
        romaji: "Kyanpu de tento o secchi shimashita.",
        traducao: "Montei uma tenda no acampamento.",
      },
      {
        exemplo: "テントの中で寝るのは気持ちがいいです。",
        hiragana: "てんとのなかでねるのはきもちがいいです。",
        romaji: "Tento no naka de neru no wa kimochi ga ii desu.",
        traducao: "É agradável dormir dentro da tenda.",
      },
      {
        exemplo: "テントをたたんでキャンプ場を出ました。",
        hiragana: "てんとをたたんできゃんぷじょうをでました。",
        romaji: "Tento o tatande kyanpujō o demashita.",
        traducao: "Dobramos a tenda e saímos do acampamento.",
      },
    ],
  },
  {
    vocabulario: "ノック",
    romaji: "nokku",
    hiragana: "のっく",
    traducao: "tocar na porta, bater",
    exemplosFrases: [
      {
        exemplo: "ドアをノックして入室しました。",
        hiragana: "どあをのっくしてにゅうしつしました。",
        romaji: "Doa o nokku shite nyūshitsu shimashita.",
        traducao: "Bati na porta e entrei na sala.",
      },
      {
        exemplo: "ノックの音が聞こえました。",
        hiragana: "のっくのおとがきこえました。",
        romaji: "Nokku no oto ga kikoemashita.",
        traducao: "Ouvi o som da batida na porta.",
      },
      {
        exemplo: "ノックしても返事がなかった。",
        hiragana: "のっくしてもへんじがなかった。",
        romaji: "Nokku shite mo henji ga nakatta.",
        traducao: "Bati na porta, mas não recebi resposta.",
      },
    ],
  },
  {
    vocabulario: "ドリンク",
    romaji: "dorinku",
    hiragana: "どりんく",
    traducao: "bebida",
    exemplosFrases: [
      {
        exemplo: "カフェでドリンクを注文しました。",
        hiragana: "かふぇでどりんくをちゅうもんしました。",
        romaji: "Kafe de dorinku o chūmon shimashita.",
        traducao: "Eu pedi uma bebida no café.",
      },
      {
        exemplo: "コンビニでドリンクを買いました。",
        hiragana: "こんびにでどりんくをかいました。",
        romaji: "Konbini de dorinku o kaimashita.",
        traducao: "Comprei uma bebida na loja de conveniência.",
      },
      {
        exemplo: "パーティーでさまざまなドリンクを楽しんだ。",
        hiragana: "ぱーてぃーでさまざまなどりんくをたのしんだ。",
        romaji: "Pātī de samazama na dorinku o tanoshinda.",
        traducao: "Desfrutei de várias bebidas na festa.",
      },
    ],
  },
  {
    vocabulario: "一気",
    romaji: "ikki",
    hiragana: "いっき",
    traducao: "de uma vez, de um gole",
    exemplosFrases: [
      {
        exemplo: "ビールを一気に飲み干した。",
        hiragana: "びーるをいっきにのみほした。",
        romaji: "Bīru o ikki ni nomihoshita.",
        traducao: "Tomei a cerveja de uma vez só.",
      },
      {
        exemplo: "小説を一気に読み終えた。",
        hiragana: "しょうせつをいっきによみおえた。",
        romaji: "Shōsetsu o ikki ni yomi oeta.",
        traducao: "Terminei de ler o romance de uma vez só.",
      },
      {
        exemplo: "コーヒーを一気に飲んで目が覚めた。",
        hiragana: "こーひーをいっきにのんでめがさめた。",
        romaji: "Kōhī o ikki ni nonde me ga sameta.",
        traducao: "Acordei tomando o café de uma vez só.",
      },
    ],
  },
  {
    vocabulario: "なす",
    romaji: "nasu",
    hiragana: "なす",
    traducao: "berinjela",
    exemplosFrases: [
      {
        exemplo: "スーパーでなすを買いました。",
        hiragana: "すーぱーでなすをかいました。",
        romaji: "Sūpā de nasu o kaimashita.",
        traducao: "Comprei berinjelas no supermercado.",
      },
      {
        exemplo: "なす料理が好きです。",
        hiragana: "なすりょうりがすきです。",
        romaji: "Nasu ryōri ga suki desu.",
        traducao: "Gosto de pratos com berinjela.",
      },
      {
        exemplo: "なすを使ってラタトゥイユを作りました。",
        hiragana: "なすをつかってらたとうゆをつくりました。",
        romaji: "Nasu o tsukatte ratatouille o tsukurimashita.",
        traducao: "Fiz ratatouille usando berinjela.",
      },
    ],
  },
  {
    vocabulario: "バイク",
    romaji: "baiku",
    hiragana: "ばいく",
    traducao: "motocicleta, moto",
    exemplosFrases: [
      {
        exemplo: "友達とバイクでドライブに行きました。",
        hiragana: "ともだちとばいくでどらいぶにいきました。",
        romaji: "Tomodachi to baiku de doraibu ni ikimashita.",
        traducao: "Fui fazer um passeio de moto com meu amigo.",
      },
      {
        exemplo: "バイクの免許を取る予定です。",
        hiragana: "ばいくのめんきょをとるよていです。",
        romaji: "Baiku no menkyo o toru yotei desu.",
        traducao: "Estou planejando tirar a licença de moto.",
      },
      {
        exemplo: "バイクで通勤しています。",
        hiragana: "ばいくでつうきんしています。",
        romaji: "Baiku de tsūkin shiteimasu.",
        traducao: "Estou indo para o trabalho de moto.",
      },
    ],
  },
  {
    vocabulario: "クッキー",
    romaji: "kukkī",
    hiragana: "くっきー",
    traducao: "biscoito, cookie",
    exemplosFrases: [
      {
        exemplo: "クッキーを焼くのが好きです。",
        hiragana: "くっきーをやくのがすきです。",
        romaji: "Kukkī o yaku no ga suki desu.",
        traducao: "Gosto de assar biscoitos.",
      },
      {
        exemplo: "コーヒーとクッキーの組み合わせが最高です。",
        hiragana: "こーひーとくっきーのくみあわせがさいこうです。",
        romaji: "Kōhī to kukkī no kumiawase ga saikō desu.",
        traducao: "A combinação de café e biscoito é ótima.",
      },
      {
        exemplo: "手作りのクッキーをプレゼントしました。",
        hiragana: "てづくりのくっきーをぷれぜんとしました。",
        romaji: "Tezukuri no kukkī o purezento shimashita.",
        traducao: "Presenteei com biscoitos feitos à mão.",
      },
    ],
  },
  {
    vocabulario: "あくび",
    romaji: "akubi",
    hiragana: "あくび",
    traducao: "bocejo",
    exemplosFrases: [
      {
        exemplo: "眠い時にはよくあくびをします。",
        hiragana: "ねむいときにはよくあくびをします。",
        romaji: "Nemui toki ni wa yoku akubi o shimasu.",
        traducao: "Costumo bocejar quando estou com sono.",
      },
      {
        exemplo: "会議中にあくびを我慢しました。",
        hiragana: "かいぎちゅうにあくびをがまんしました。",
        romaji: "Kaigi-chū ni akubi o gaman shimashita.",
        traducao: "Segurei o bocejo durante a reunião.",
      },
      {
        exemplo: "あくびをすると目が覚めることがあります。",
        hiragana: "あくびをするとめがさめることがあります。",
        romaji: "Akubi o suru to me ga sameru koto ga arimasu.",
        traducao: "Às vezes, bocejar me faz acordar.",
      },
    ],
  },
  {
    vocabulario: "ボール",
    romaji: "bōru",
    hiragana: "ぼーる",
    traducao: "bola",
    exemplosFrases: [
      {
        exemplo: "公園でボールを投げました。",
        hiragana: "こうえんでぼーるをなげました。",
        romaji: "Kōen de bōru o nagemashita.",
        traducao: "Joguei bola no parque.",
      },
      {
        exemplo: "サッカーボールを蹴りました。",
        hiragana: "さっかーぼーるをけりました。",
        romaji: "Sakkā bōru o kerimashita.",
        traducao: "Chutei a bola de futebol.",
      },
      {
        exemplo: "友達とボールを使って遊びました。",
        hiragana: "ともだちとぼーるをつかってあそびました。",
        romaji: "Tomodachi to bōru o tsukatte asobimashita.",
        traducao: "Brinquei com meus amigos usando a bola.",
      },
    ],
  },
  {
    vocabulario: "ボウリング",
    romaji: "bōringu",
    hiragana: "ぼうりんぐ",
    traducao: "boliche",
    exemplosFrases: [
      {
        exemplo: "週末に友達とボウリングに行きました。",
        hiragana: "しゅうまつにともだちとぼうりんぐにいきました。",
        romaji: "Shūmatsu ni tomodachi to bōringu ni ikimashita.",
        traducao: "Fui ao boliche com meus amigos no fim de semana.",
      },
      {
        exemplo: "ボウリング大会に参加しました。",
        hiragana: "ぼうりんぐたいかいにさんかしました。",
        romaji: "Bōringu taikai ni sanka shimashita.",
        traducao: "Participei de um torneio de boliche.",
      },
      {
        exemplo: "ボウリングのスコアを競いました。",
        hiragana: "ぼうりんぐのすこあをきそいました。",
        romaji: "Bōringu no sukoa o kisoi mashita.",
        traducao: "Competi pela pontuação no boliche.",
      },
    ],
  },
  {
    vocabulario: "バッグ",
    romaji: "baggu",
    hiragana: "ばっぐ",
    traducao: "bolsa, sacola",
    exemplosFrases: [
      {
        exemplo: "デパートで新しいバッグを買いました。",
        hiragana: "でぱーとであたらしいばっぐをかいました。",
        romaji: "Depāto de atarashii baggu o kaimashita.",
        traducao: "Comprei uma bolsa nova na loja de departamento.",
      },
      {
        exemplo: "バッグに必要なものを詰めました。",
        hiragana: "ばっぐにひつようなものをつめました。",
        romaji: "Baggu ni hitsuyōna mono o tsumemashita.",
        traducao: "Coloquei as coisas necessárias na bolsa.",
      },
      {
        exemplo: "旅行には大きなバッグが便利です。",
        hiragana: "りょこうにはおおきなばっぐがべんりです。",
        romaji: "Ryokō ni wa ōkina baggu ga benri desu.",
        traducao: "Uma bolsa grande é conveniente para viagens.",
      },
    ],
  },
  {
    vocabulario: "ピアス",
    romaji: "piasu",
    hiragana: "ぴあす",
    traducao: "brinco (piercing)",
    exemplosFrases: [
      {
        exemplo: "新しいピアスを付けました。",
        hiragana: "あたらしいぴあすをつけました。",
        romaji: "Atarashii piasu o tsukemashita.",
        traducao: "Coloquei um novo brinco.",
      },
      {
        exemplo: "ピアスを買いにジュエリーショップへ行きました。",
        hiragana: "ぴあすをかいにじゅえりーしょっぷへいきました。",
        romaji: "Piasu o kai ni juerī shoppu e ikimashita.",
        traducao: "Fui a uma joalheria comprar brincos.",
      },
      {
        exemplo: "ピアスを外すのが苦手です。",
        hiragana: "ぴあすをはずすのがにがてです。",
        romaji: "Piasu o hazusu no ga nigate desu.",
        traducao: "Tenho dificuldade em tirar os brincos.",
      },
    ],
  },
  {
    vocabulario: "芽",
    romaji: "me",
    hiragana: "め",
    traducao: "broto",
    exemplosFrases: [
      {
        exemplo: "春になると芽が出てくる。",
        hiragana: "はるになるとめがでてくる。",
        romaji: "Haru ni naru to me ga dete kuru.",
        traducao: "Na primavera, os brotos começam a brotar.",
      },
      {
        exemplo: "野菜の芽を摘む。",
        hiragana: "やさいのめをつむ。",
        romaji: "Yasai no me o tsumu.",
        traducao: "Colher brotos de vegetais.",
      },
      {
        exemplo: "芽が育って成長している。",
        hiragana: "めがそだってせいちょうしている。",
        romaji: "Me ga sodatte seichō shite iru.",
        traducao: "Os brotos estão crescendo e se desenvolvendo.",
      },
    ],
  },
  {
    vocabulario: "それぞれ",
    romaji: "sorezore",
    hiragana: "それぞれ",
    traducao: "cada um, respectivamente",
    exemplosFrases: [
      {
        exemplo: "参加者はそれぞれの意見を述べた。",
        hiragana: "さんかしゃはそれぞれのいけんをのべた。",
        romaji: "Sankasha wa sorezore no iken o nobeta.",
        traducao: "Cada participante expressou sua própria opinião.",
      },
      {
        exemplo: "メンバーはそれぞれの役割を果たした。",
        hiragana: "めんばーはそれぞれのやくわりをはたした。",
        romaji: "Menbā wa sorezore no yakuwari o hatashita.",
        traducao: "Cada membro desempenhou seu papel respectivo.",
      },
      {
        exemplo: "彼らはそれぞれ違う経験をしてきた。",
        hiragana: "かれらはそれぞれちがうけいけんをしてきた。",
        romaji: "Karera wa sorezore chigau keiken o shite kita.",
        traducao: "Eles tiveram experiências diferentes cada um.",
      },
    ],
  },
  {
    vocabulario: "カフェ",
    romaji: "kafue",
    hiragana: "かふぇ",
    traducao: "café",
    exemplosFrases: [
      {
        exemplo: "友達とカフェでお茶をしました。",
        hiragana: "ともだちとかふぇでおちゃをしました。",
        romaji: "Tomodachi to kafue de ocha o shimashita.",
        traducao: "Tomei chá com meu amigo em um café.",
      },
      {
        exemplo: "カフェで読書をするのが好きです。",
        hiragana: "かふぇでどくしょをするのがすきです。",
        romaji: "Kafue de dokusho o suru no ga suki desu.",
        traducao: "Gosto de ler em um café.",
      },
      {
        exemplo: "カフェでケーキを食べました。",
        hiragana: "かふぇでけーきをたべました。",
        romaji: "Kafue de kēki o tabemashita.",
        traducao: "Comi bolo em um café.",
      },
    ],
  },
  {
    vocabulario: "カロリー",
    romaji: "karorī",
    hiragana: "かろりー",
    traducao: "caloria",
    exemplosFrases: [
      {
        exemplo: "食事のカロリーを気にする。",
        hiragana: "しょくじのかろりーをきにする。",
        romaji: "Shokuji no karorī o ki ni suru.",
        traducao: "Preocupar-se com as calorias da refeição.",
      },
      {
        exemplo: "カロリーの高い食品を控える。",
        hiragana: "かろりーのたかいしょくひんをひかえる。",
        romaji: "Karorī no takai shokuhin o hikaeru.",
        traducao: "Evitar alimentos com alto teor de calorias.",
      },
      {
        exemplo: "一日のカロリー摂取量を管理する。",
        hiragana: "いちにちのかろりーせっしゅりょうをかんりする。",
        romaji: "Ichinichi no karorī sesshuryō o kanri suru.",
        traducao: "Gerenciar a ingestão diária de calorias.",
      },
    ],
  },
  {
    vocabulario: "デジカメ",
    romaji: "dejikame",
    hiragana: "でじかめ",
    traducao: "câmera digital",
    exemplosFrases: [
      {
        exemplo: "デジカメで写真を撮る。",
        hiragana: "でじかめでしゃしんをとる。",
        romaji: "Dejikame de shashin o toru.",
        traducao: "Tirar fotos com uma câmera digital.",
      },
      {
        exemplo: "旅行の記念にデジカメを購入した。",
        hiragana: "りょこうのきねんにでじかめをこうにゅうした。",
        romaji: "Ryokō no kinen ni dejikame o kōnyū shita.",
        traducao: "Comprei uma câmera digital como lembrança da viagem.",
      },
      {
        exemplo: "デジカメで撮った写真をSNSに投稿する。",
        hiragana: "でじかめでとったしゃしんをえすえぬえすにとうこうする。",
        romaji: "Dejikame de totta shashin o esuensu ni tōkō suru.",
        traducao:
          "Postar fotos tiradas com uma câmera digital nas redes sociais.",
      },
    ],
  },
  {
    vocabulario: "ハイキング",
    romaji: "haikingu",
    hiragana: "はいきんぐ",
    traducao: "caminhada, hiking",
    exemplosFrases: [
      {
        exemplo: "週末に友達とハイキングに行きました。",
        hiragana: "しゅうまつにともだちとはいきんぐにいきました。",
        romaji: "Shūmatsu ni tomodachi to haikingu ni ikimashita.",
        traducao: "Fui fazer uma caminhada com meus amigos no fim de semana.",
      },
      {
        exemplo: "山でハイキングを楽しんだ。",
        hiragana: "やまではいきんぐをたのしんだ。",
        romaji: "Yama de haikingu o tanoshinda.",
        traducao: "Diverti-me fazendo uma caminhada na montanha.",
      },
      {
        exemplo: "ハイキングコースを歩く。",
        hiragana: "はいきんぐこーすをあるく。",
        romaji: "Haikingu kōsu o aruku.",
        traducao: "Caminhar em uma trilha de caminhada.",
      },
    ],
  },
  {
    vocabulario: "トラック",
    romaji: "torakku",
    hiragana: "とらっく",
    traducao: "caminhão",
    exemplosFrases: [
      {
        exemplo: "大きなトラックが道を通っていた。",
        hiragana: "おおきなとらっくがみちをとおっていた。",
        romaji: "Ōkina torakku ga michi o tōtte ita.",
        traducao: "Um caminhão grande estava passando pela estrada.",
      },
      {
        exemplo: "トラック運転手の仕事は大変だ。",
        hiragana: "とらっくうんてんしゅのしごとはたいへんだ。",
        romaji: "Torakku untenshu no shigoto wa taihen da.",
        traducao: "O trabalho de motorista de caminhão é difícil.",
      },
      {
        exemplo: "トラックに荷物を積み込む。",
        hiragana: "とらっくににもつをつみこむ。",
        romaji: "Torakku ni nimotsu o tsumikomu.",
        traducao: "Carregar mercadorias em um caminhão.",
      },
    ],
  },
  {
    vocabulario: "パス",
    romaji: "pasu",
    hiragana: "ぱす",
    traducao: "passe, cartão de acesso",
    exemplosFrases: [
      {
        exemplo: "公園の入り口でパスを見せる。",
        hiragana: "こうえんのいりぐちでぱすをみせる。",
        romaji: "Kōen no iriguchi de pasu o miseru.",
        traducao: "Mostrar o passe na entrada do parque.",
      },
      {
        exemplo: "ビルのエレベーターにはパスが必要です。",
        hiragana: "びるのえれべーたーにはぱすがひつようです。",
        romaji: "Biru no erebētā ni wa pasu ga hitsuyō desu.",
        traducao: "É necessário um cartão de acesso para o elevador do prédio.",
      },
      {
        exemplo: "パスを使ってイベントに入場する。",
        hiragana: "ぱすをつかっていべんとににゅうじょうする。",
        romaji: "Pasu o tsukatte ibento ni nyūjō suru.",
        traducao: "Entrar no evento usando um passe.",
      },
    ],
  },
  {
    vocabulario: "しょう",
    romaji: "shō",
    hiragana: "しょう",
    traducao: "pequeno",
    exemplosFrases: [
      {
        exemplo: "この町はしょうな規模だ。",
        hiragana: "このまちはしょうなきぼだ。",
        romaji: "Kono machi wa shōna kibo da.",
        traducao: "Esta cidade é de pequena escala.",
      },
      {
        exemplo: "しょうな問題を解決する。",
        hiragana: "しょうなもんだいをかいけつする。",
        romaji: "Shōna mondai o kaiketsu suru.",
        traducao: "Resolver um problema pequeno.",
      },
      {
        exemplo: "しょうな変更を加える。",
        hiragana: "しょうなへんこうをくわえる。",
        romaji: "Shōna henkō o kuwaeru.",
        traducao: "Fazer uma pequena alteração.",
      },
    ],
  },
  {
    vocabulario: "キャンセル",
    romaji: "kyanseru",
    hiragana: "きゃんせる",
    traducao: "cancelamento",
    exemplosFrases: [
      {
        exemplo: "予定が変更になってキャンセルした。",
        hiragana: "よていがへんこうになってきゃんせるした。",
        romaji: "Yotei ga henkō ni natte kyanseru shita.",
        traducao: "Cancelado devido a uma mudança de planos.",
      },
      {
        exemplo: "イベントは天候不良のためキャンセルされた。",
        hiragana: "いべんとはてんこうふりょうのためきゃんせるされた。",
        romaji: "Ibento wa tenkō furyō no tame kyanseru sareta.",
        traducao:
          "O evento foi cancelado devido às condições climáticas ruins.",
      },
      {
        exemplo: "予約をキャンセルする。",
        hiragana: "よやくをきゃんせるする。",
        romaji: "Yoyaku o kyanseru suru.",
        traducao: "Cancelar uma reserva.",
      },
    ],
  },
  {
    vocabulario: "歌手",
    romaji: "kashu",
    hiragana: "かしゅ",
    traducao: "cantor(a)",
    exemplosFrases: [
      {
        exemplo: "彼は有名な歌手です。",
        hiragana: "かれはゆうめいなかしゅです。",
        romaji: "Kare wa yūmei na kashu desu.",
        traducao: "Ele é um cantor famoso.",
      },
      {
        exemplo: "私は将来歌手になりたいです。",
        hiragana: "わたしはしょうらいかしゅになりたいです。",
        romaji: "Watashi wa shōrai kashu ni naritai desu.",
        traducao: "Eu quero ser cantora no futuro.",
      },
      {
        exemplo: "最新のヒット曲を歌う歌手が好きです。",
        hiragana: "さいしんのひっときょくをうたうかしゅがすきです。",
        romaji: "Saishin no hitto kyoku o utau kashu ga suki desu.",
        traducao:
          "Eu gosto de cantores que cantam as últimas músicas de sucesso.",
      },
    ],
  },
  {
    vocabulario: "カード",
    romaji: "kādo",
    hiragana: "かーど",
    traducao: "cartão",
    exemplosFrases: [
      {
        exemplo: "誕生日にカードを贈りました。",
        hiragana: "たんじょうびにかーどをおくりました。",
        romaji: "Tanjōbi ni kādo o okurimashita.",
        traducao: "Eu enviei um cartão de aniversário.",
      },
      {
        exemplo: "カードを使って支払いをしました。",
        hiragana: "かーどをつかってしはらいをしました。",
        romaji: "Kādo o tsukatte shiharai o shimashita.",
        traducao: "Eu fiz o pagamento usando o cartão.",
      },
      {
        exemplo: "友達から素敵なカードをもらいました。",
        hiragana: "ともだちからすてきなかーどをもらいました。",
        romaji: "Tomodachi kara suteki na kādo o moraimashita.",
        traducao: "Recebi um cartão bonito de um amigo.",
      },
    ],
  },
  {
    vocabulario: "トランプ",
    romaji: "toranpu",
    hiragana: "とらんぷ",
    traducao: "baralho",
    exemplosFrases: [
      {
        exemplo: "友達とトランプで遊びました。",
        hiragana: "ともだちととらんぷであそびました。",
        romaji: "Tomodachi to toranpu de asobimashita.",
        traducao: "Eu joguei cartas com meus amigos.",
      },
      {
        exemplo: "トランプのゲームを覚えるのは難しいです。",
        hiragana: "とらんぷのげーむをおぼえるのはむずかしいです。",
        romaji: "Toranpu no gēmu o oboeru no wa muzukashii desu.",
        traducao: "Aprender jogos de cartas é difícil.",
      },
      {
        exemplo: "トランプでトリックを決める。",
        hiragana: "とらんぷでとりっくをきめる。",
        romaji: "Toranpu de torikku o kimeru.",
        traducao: "Definir truques com cartas.",
      },
    ],
  },
  {
    vocabulario: "ケース",
    romaji: "kēsu",
    hiragana: "けーす",
    traducao: "estojo, case",
    exemplosFrases: [
      {
        exemplo: "眼鏡をケースに入れる。",
        hiragana: "めがねをけーすにいれる。",
        romaji: "Megane o kēsu ni ireru.",
        traducao: "Colocar os óculos no estojo.",
      },
      {
        exemplo: "スマートフォンをケースから取り出す。",
        hiragana: "すまーとふぉんをけーすからとりだす。",
        romaji: "Sumātofon o kēsu kara toridasu.",
        traducao: "Retirar o smartphone do estojo.",
      },
      {
        exemplo: "楽器をケースに入れて持ち運ぶ。",
        hiragana: "がっきをけーすにいれてもちはこぶ。",
        romaji: "Gakki o kēsu ni irete mochihakobu.",
        traducao: "Colocar o instrumento musical no estojo e levá-lo consigo.",
      },
    ],
  },
  {
    vocabulario: "絵画",
    romaji: "kaiga",
    hiragana: "かいが",
    traducao: "pintura",
    exemplosFrases: [
      {
        exemplo: "美術館で素晴らしい絵画を見た。",
        hiragana: "びじゅつかんですばらしいかいがをみた。",
        romaji: "Bijutsukan de subarashii kaiga o mita.",
        traducao: "Vi pinturas maravilhosas no museu de arte.",
      },
      {
        exemplo: "絵画の展示会に参加する。",
        hiragana: "かいがのてんじかいにさんかする。",
        romaji: "Kaiga no tenjikai ni sanka suru.",
        traducao: "Participar de uma exposição de pintura.",
      },
      {
        exemplo: "絵画を描くのは楽しいです。",
        hiragana: "かいがをかくのはたのしいです。",
        romaji: "Kaiga o kaku no wa tanoshii desu.",
        traducao: "Desenhar pinturas é divertido.",
      },
    ],
  },
  {
    vocabulario: "にんじん",
    romaji: "ninjin",
    hiragana: "にんじん",
    traducao: "cenoura",
    exemplosFrases: [
      {
        exemplo: "サラダににんじんを入れる。",
        hiragana: "さらだににんじんをいれる。",
        romaji: "Sarada ni ninjin o ireru.",
        traducao: "Colocar cenouras na salada.",
      },
      {
        exemplo: "にんじんジュースを飲んだことがありますか？",
        hiragana: "にんじんじゅーすをのんだことがありますか？",
        romaji: "Ninjin jūsu o nonda koto ga arimasu ka?",
        traducao: "Você já experimentou suco de cenoura?",
      },
      {
        exemplo: "にんじんを使った料理を作る。",
        hiragana: "にんじんをつかったりょうりをつくる。",
        romaji: "Ninjin o tsukatta ryōri o tsukuru.",
        traducao: "Preparar uma receita usando cenouras.",
      },
    ],
  },
  {
    vocabulario: "センター",
    romaji: "sentā",
    hiragana: "せんたー",
    traducao: "centro",
    exemplosFrases: [
      {
        exemplo: "市のセンターでイベントが開催されました。",
        hiragana: "しのせんたーでいべんとがかいさいされました。",
        romaji: "Shi no sentā de ibento ga kaisai saremashita.",
        traducao: "O evento foi realizado no centro da cidade.",
      },
      {
        exemplo: "センターで買い物をする。",
        hiragana: "せんたーでかいものをする。",
        romaji: "Sentā de kaimono o suru.",
        traducao: "Fazer compras no centro.",
      },
      {
        exemplo: "図書館は市のセンターにあります。",
        hiragana: "としょかんはしのせんたーにあります。",
        romaji: "Toshokan wa shi no sentā ni arimasu.",
        traducao: "A biblioteca está localizada no centro da cidade.",
      },
    ],
  },
  {
    vocabulario: "ビール",
    romaji: "bīru",
    hiragana: "びーる",
    traducao: "cerveja",
    exemplosFrases: [
      {
        exemplo: "夏に冷たいビールが美味しい。",
        hiragana: "なつにつめたいびーるがおいしい。",
        romaji: "Natsu ni tsumetai bīru ga oishii.",
        traducao: "Uma cerveja gelada é deliciosa no verão.",
      },
      {
        exemplo: "友達とビールを飲みながら話す。",
        hiragana: "ともだちとびーるをのみながらはなす。",
        romaji: "Tomodachi to bīru o nominagara hanasu.",
        traducao: "Conversar com os amigos enquanto toma cerveja.",
      },
      {
        exemplo: "ビールの銘柄にはいくつか種類があります。",
        hiragana: "びーるのめいがらにはいくつかしゅるいがあります。",
        romaji: "Bīru no meigara ni wa ikutsuka shurui ga arimasu.",
        traducao: "Existem vários tipos de marcas de cerveja.",
      },
    ],
  },
  {
    vocabulario: "かご",
    romaji: "kago",
    hiragana: "かご",
    traducao: "cesta",
    exemplosFrases: [
      {
        exemplo: "買い物かごに食材を入れる。",
        hiragana: "かいものかごにしょくざいをいれる。",
        romaji: "Kaimono kago ni shokuzai o ireru.",
        traducao: "Colocar os ingredientes na cesta de compras.",
      },
      {
        exemplo: "ピクニックにかごを持って行く。",
        hiragana: "ぴくにっくにかごをもっていく。",
        romaji: "Pikunikku ni kago o motte iku.",
        traducao: "Levar uma cesta para o piquenique.",
      },
      {
        exemplo: "かごに花を飾る。",
        hiragana: "かごにはなをかざる。",
        romaji: "Kago ni hana o kazaru.",
        traducao: "Decorar a cesta com flores.",
      },
    ],
  },
  {
    vocabulario: "炎",
    romaji: "honō",
    hiragana: "ほのお",
    traducao: "chama",
    exemplosFrases: [
      {
        exemplo: "キャンプファイヤーの炎が明るく輝いていた。",
        hiragana: "きゃんぷふぁいやーのほのおがあかるくかがやいていた。",
        romaji: "Kyanpu faiyā no honō ga akaruku kagayaite ita.",
        traducao: "As chamas da fogueira do acampamento estavam brilhantes.",
      },
      {
        exemplo: "ろうそくの炎がゆらゆらと揺れている。",
        hiragana: "ろうそくのほのおがゆらゆらとゆれている。",
        romaji: "Rōsoku no honō ga yurayura to yurete iru.",
        traducao: "A chama da vela está tremulando suavemente.",
      },
      {
        exemplo: "炎の中から勇者が現れた。",
        hiragana: "ほのおのなかからゆうしゃがあらわれた。",
        romaji: "Honō no naka kara yūsha ga arawareta.",
        traducao: "Um herói apareceu de dentro das chamas.",
      },
    ],
  },
  {
    vocabulario: "チャンス",
    romaji: "chansu",
    hiragana: "ちゃんす",
    traducao: "oportunidade, chance",
    exemplosFrases: [
      {
        exemplo: "一生に一度のチャンスを逃すな。",
        hiragana: "いっしょうにいちどのちゃんすをのがすな。",
        romaji: "Isshō ni ichido no chansu o nogasu na.",
        traducao: "Não perca a chance de uma vez na vida.",
      },
      {
        exemplo: "大きなチャンスが訪れた。",
        hiragana: "おおきなちゃんすがおとずれた。",
        romaji: "Ōkina chansu ga otozureta.",
        traducao: "Uma grande oportunidade surgiu.",
      },
      {
        exemplo: "頑張ってチャンスを掴む。",
        hiragana: "がんばってちゃんすをつかむ。",
        romaji: "Ganbatte chansu o tsukamu.",
        traducao: "Esforce-se para agarrar a chance.",
      },
    ],
  },
  {
    vocabulario: "バーベキュー",
    romaji: "bābekyū",
    hiragana: "ばーべきゅー",
    traducao: "churrasco",
    exemplosFrases: [
      {
        exemplo: "夏に友達とバーベキューを楽しむ。",
        hiragana: "なつにともだちとばーべきゅーをたのしむ。",
        romaji: "Natsu ni tomodachi to bābekyū o tanoshimu.",
        traducao: "Desfrutar de um churrasco com os amigos no verão.",
      },
      {
        exemplo: "バーベキューで美味しい肉を食べる。",
        hiragana: "ばーべきゅーでおいしいにくをたべる。",
        romaji: "Bābekyū de oishii niku o taberu.",
        traducao: "Comer carne deliciosa no churrasco.",
      },
      {
        exemplo: "バーベキュー用のグリルを準備する。",
        hiragana: "ばーべきゅーようのぐりるをじゅんびする。",
        romaji: "Bābekyū-yō no guriru o junbi suru.",
        traducao: "Preparar a grelha para o churrasco.",
      },
    ],
  },
  {
    vocabulario: "街",
    romaji: "machi",
    hiragana: "まち",
    traducao: "cidade",
    exemplosFrases: [
      {
        exemplo: "夜の街は賑やかです。",
        hiragana: "よるのまちはにぎやかです。",
        romaji: "Yoru no machi wa nigiyaka desu.",
        traducao: "A cidade à noite é animada.",
      },
      {
        exemplo: "街を散歩するのが好きです。",
        hiragana: "まちをさんぽするのがすきです。",
        romaji: "Machi o sanpo suru no ga suki desu.",
        traducao: "Eu gosto de passear pela cidade.",
      },
      {
        exemplo: "この街には美味しいレストランがたくさんあります。",
        hiragana: "このまちにはおいしいれすとらんがたくさんあります。",
        romaji: "Kono machi ni wa oishii resutoran ga takusan arimasu.",
        traducao: "Esta cidade tem muitos restaurantes deliciosos.",
      },
    ],
  },
  {
    vocabulario: "ベルト",
    romaji: "beruto",
    hiragana: "べると",
    traducao: "cinto",
    exemplosFrases: [
      {
        exemplo: "このベルトは合っていますか？",
        hiragana: "このべるとはあっていますか？",
        romaji: "Kono beruto wa atte imasu ka?",
        traducao: "Este cinto está do tamanho certo?",
      },
      {
        exemplo: "新しいベルトを買いました。",
        hiragana: "あたらしいべるとをかいました。",
        romaji: "Atarashii beruto o kaimashita.",
        traducao: "Comprei um cinto novo.",
      },
      {
        exemplo: "ベルトを締めるのを忘れないでください。",
        hiragana: "べるとをしめるのをわすれないでください。",
        romaji: "Beruto o shimeru no o wasurenaide kudasai.",
        traducao: "Não se esqueça de apertar o cinto.",
      },
    ],
  },
  {
    vocabulario: "灰",
    romaji: "hai",
    hiragana: "はい",
    traducao: "cinza (cor)",
    exemplosFrases: [
      {
        exemplo: "この車は灰色です。",
        hiragana: "このくるまははいいろです。",
        romaji: "Kono kuruma wa haiiro desu.",
        traducao: "Este carro é cinza.",
      },
      {
        exemplo: "灰色の壁が落ち着いた雰囲気を作ります。",
        hiragana: "はいいろのかべがおちついたふんいきをつくります。",
        romaji: "Haiiro no kabe ga ochitsuita fun'iki o tsukurimasu.",
        traducao: "A parede cinza cria uma atmosfera tranquila.",
      },
      {
        exemplo: "灰色のスーツが彼によく似合っています。",
        hiragana: "はいいろのすーつがかれによくにあっています。",
        romaji: "Haiiro no sūtsu ga kare ni yoku niatte imasu.",
        traducao: "O terno cinza fica muito bem nele.",
      },
    ],
  },
  {
    vocabulario: "グレー",
    romaji: "gurē",
    hiragana: "ぐれー",
    traducao: "cinza (cor)",
    exemplosFrases: [
      {
        exemplo: "このセーターはグレーです。",
        hiragana: "このせーたーはぐれーです。",
        romaji: "Kono sētā wa gurē desu.",
        traducao: "Este suéter é cinza.",
      },
      {
        exemplo: "グレーのスカートがとてもおしゃれですね。",
        hiragana: "ぐれーのすかーとがとてもおしゃれですね。",
        romaji: "Gurē no sukāto ga totemo oshare desu ne.",
        traducao: "A saia cinza é muito elegante.",
      },
      {
        exemplo: "グレーの雲が広がっています。",
        hiragana: "ぐれーのくもがひろがっています。",
        romaji: "Gurē no kumo ga hirogatte imasu.",
        traducao: "Nuvens cinzas estão se espalhando.",
      },
    ],
  },
  {
    vocabulario: "サークル",
    romaji: "sākuru",
    hiragana: "さーくる",
    traducao: "círculo, clube",
    exemplosFrases: [
      {
        exemplo: "大学のサークルに入りました。",
        hiragana: "だいがくのさーくるにはいりました。",
        romaji: "Daigaku no sākuru ni hairimashita.",
        traducao: "Eu entrei para um clube da universidade.",
      },
      {
        exemplo: "サークル活動に参加する。",
        hiragana: "さーくるかつどうにさんかする。",
        romaji: "Sākuru katsudō ni sanka suru.",
        traducao: "Participar das atividades do clube.",
      },
      {
        exemplo: "サークルのメンバーと一緒にイベントを開催する。",
        hiragana: "さーくるのめんばーといっしょにいべんとをかいさいする。",
        romaji: "Sākuru no menbā to issho ni ibento o kaisai suru.",
        traducao: "Realizar um evento junto com os membros do clube.",
      },
    ],
  },
  {
    vocabulario: "クラシック",
    romaji: "kurashikku",
    hiragana: "くらしっく",
    traducao: "clássico",
    exemplosFrases: [
      {
        exemplo: "クラシック音楽が好きです。",
        hiragana: "くらしっくおんがくがすきです。",
        romaji: "Kurashikku ongaku ga suki desu.",
        traducao: "Eu gosto de música clássica.",
      },
      {
        exemplo: "クラシックの曲を演奏する。",
        hiragana: "くらしっくのきょくをえんそうする。",
        romaji: "Kurashikku no kyoku o ensō suru.",
        traducao: "Tocar uma música clássica.",
      },
      {
        exemplo: "クラシックコンサートに行きたい。",
        hiragana: "くらしっくこんさーとにいきたい。",
        romaji: "Kurashikku konsāto ni ikitai.",
        traducao: "Quero ir a um concerto clássico.",
      },
    ],
  },
  {
    vocabulario: "クラブ",
    romaji: "kurabu",
    hiragana: "くらぶ",
    traducao: "clube",
    exemplosFrases: [
      {
        exemplo: "スポーツクラブに入会しました。",
        hiragana: "すぽーつくらぶににゅうかいしました。",
        romaji: "Supōtsu kurabu ni nyūkai shimashita.",
        traducao: "Me juntei a um clube esportivo.",
      },
      {
        exemplo: "クラブで友達を作る。",
        hiragana: "くらぶでともだちをつくる。",
        romaji: "Kurabu de tomodachi o tsukuru.",
        traducao: "Fazer amigos no clube.",
      },
      {
        exemplo: "クラブの活動に参加する。",
        hiragana: "くらぶのかつどうにさんかする。",
        romaji: "Kurabu no katsudō ni sanka suru.",
        traducao: "Participar das atividades do clube.",
      },
    ],
  },
  {
    vocabulario: "コアラ",
    romaji: "koara",
    hiragana: "こあら",
    traducao: "coala",
    exemplosFrases: [
      {
        exemplo: "コアラはオーストラリアの象徴的な動物です。",
        hiragana: "こあらはおーすとらりあのしょうちょうてきなどうぶつです。",
        romaji: "Koara wa ōsutoraria no shōchō-teki na dōbutsu desu.",
        traducao: "O coala é um animal simbólico da Austrália.",
      },
      {
        exemplo: "コアラの写真を見る。",
        hiragana: "こあらのしゃしんをみる。",
        romaji: "Koara no shashin o miru.",
        traducao: "Ver fotos de coalas.",
      },
      {
        exemplo: "コアラはユーカリの葉を食べます。",
        hiragana: "こあらはゆーかりのはをたべます。",
        romaji: "Koara wa yūkari no ha o tabemasu.",
        traducao: "Os coalas comem folhas de eucalipto.",
      },
    ],
  },
  {
    vocabulario: "屋根",
    romaji: "yane",
    hiragana: "やね",
    traducao: "telhado",
    exemplosFrases: [
      {
        exemplo: "雨が降ってきたので屋根の下に入った。",
        hiragana: "あめがふってきたのでやねのしたにはいった。",
        romaji: "Ame ga futte kita node yane no shita ni haitta.",
        traducao: "Como estava chovendo, entrei embaixo do telhado.",
      },
      {
        exemplo: "家の屋根が修理されています。",
        hiragana: "いえのやねがしゅうりされています。",
        romaji: "Ie no yane ga shūri sarete imasu.",
        traducao: "O telhado da casa está sendo reparado.",
      },
      {
        exemplo: "屋根から雪が滑り落ちた。",
        hiragana: "やねからゆきがすべりおちた。",
        romaji: "Yane kara yuki ga suberi ochita.",
        traducao: "A neve escorregou do telhado.",
      },
    ],
  },
  {
    vocabulario: "コード",
    romaji: "kōdo",
    hiragana: "こーど",
    traducao: "código",
    exemplosFrases: [
      {
        exemplo: "パスワードを入力するためのコードを教えてください。",
        hiragana:
          "ぱすわーどをにゅうりょくするためのこーどをおしえてください。",
        romaji: "Pasuwādo o nyūryoku suru tame no kōdo o oshiete kudasai.",
        traducao: "Por favor, me informe o código para digitar a senha.",
      },
      {
        exemplo: "このアプリケーションのコードはオープンソースです。",
        hiragana: "このあぷりけーしょんのこーどはおーぷんそーすです。",
        romaji: "Kono apurikēshon no kōdo wa ōpun sōsu desu.",
        traducao: "O código deste aplicativo é de código aberto.",
      },
      {
        exemplo: "新しいコードを書くのは難しいです。",
        hiragana: "あたらしいこーどをかくのはむずかしいです。",
        romaji: "Atarashii kōdo o kaku no wa muzukashii desu.",
        traducao: "É difícil escrever um novo código.",
      },
    ],
  },
  {
    vocabulario: "うさぎ",
    romaji: "usagi",
    hiragana: "うさぎ",
    traducao: "coelho",
    exemplosFrases: [
      {
        exemplo: "公園でかわいいうさぎを見た。",
        hiragana: "こうえんでかわいいうさぎをみた。",
        romaji: "Kōen de kawaii usagi o mita.",
        traducao: "Eu vi um coelho fofo no parque.",
      },
      {
        exemplo: "うさぎの耳は長いです。",
        hiragana: "うさぎのみみはながいです。",
        romaji: "Usagi no mimi wa nagai desu.",
        traducao: "As orelhas dos coelhos são longas.",
      },
      {
        exemplo: "うさぎはジャンプが得意です。",
        hiragana: "うさぎはじゃんぷがとくいです。",
        romaji: "Usagi wa janpu ga tokui desu.",
        traducao: "Os coelhos são bons em pular.",
      },
    ],
  },
  {
    vocabulario: "一致",
    romaji: "itchi",
    hiragana: "いっち",
    traducao: "concordância, acordo",
    exemplosFrases: [
      {
        exemplo: "意見が一致しました。",
        hiragana: "いけんがいっちしました。",
        romaji: "Iken ga itchi shimashita.",
        traducao: "Chegamos a um acordo na opinião.",
      },
      {
        exemplo: "彼らの話は一致していました。",
        hiragana: "かれらのはなしはいっちしていました。",
        romaji: "Karera no hanashi wa itchi shite imashita.",
        traducao: "As histórias deles estavam em acordo.",
      },
      {
        exemplo: "結果が一致しなかった。",
        hiragana: "けっかがいっちしなかった。",
        romaji: "Kekka ga itchi shinakatta.",
        traducao: "Os resultados não foram compatíveis.",
      },
    ],
  },
  {
    vocabulario: "クラスメイト",
    romaji: "kurasumeito",
    hiragana: "くらすめいと",
    traducao: "colega de classe",
    exemplosFrases: [
      {
        exemplo: "私のクラスメイトは親切です。",
        hiragana: "わたしのくらすめいとはしんせつです。",
        romaji: "Watashi no kurasumeito wa shinsetsu desu.",
        traducao: "Meus colegas de classe são gentis.",
      },
      {
        exemplo: "クラスメイトと一緒に勉強する。",
        hiragana: "くらすめいとといっしょにべんきょうする。",
        romaji: "Kurasumeito to issho ni benkyō suru.",
        traducao: "Estudar junto com os colegas de classe.",
      },
      {
        exemplo: "クラスメイトとランチをする。",
        hiragana: "くらすめいととらんちをする。",
        romaji: "Kurasumeito to ranchi o suru.",
        traducao: "Almoçar com os colegas de classe.",
      },
    ],
  },
  {
    vocabulario: "開始",
    romaji: "kaishi",
    hiragana: "かいし",
    traducao: "início, começo",
    exemplosFrases: [
      {
        exemplo: "授業の開始時間は何時ですか？",
        hiragana: "じゅぎょうのかいしじかんはなんじですか？",
        romaji: "Jugyō no kaishi jikan wa nanji desu ka?",
        traducao: "A que horas começa a aula?",
      },
      {
        exemplo: "イベントの開始が遅れました。",
        hiragana: "いべんとのかいしがおくれました。",
        romaji: "Ibento no kaishi ga okuremashita.",
        traducao: "O início do evento atrasou.",
      },
      {
        exemplo: "プロジェクトの開始日が決まりました。",
        hiragana: "ぷろじぇくとのかいしびがきまりました。",
        romaji: "Purojekuto no kaishi-bi ga kimarimashita.",
        traducao: "A data de início do projeto foi definida.",
      },
    ],
  },
  {
    vocabulario: "コメディー",
    romaji: "komedī",
    hiragana: "こめでぃー",
    traducao: "comédia",
    exemplosFrases: [
      {
        exemplo: "映画館でコメディーを見る。",
        hiragana: "えいがかんでこめでぃーをみる。",
        romaji: "Eigakan de komedī o miru.",
        traducao: "Assistir a uma comédia no cinema.",
      },
      {
        exemplo: "コメディー映画は笑えて楽しい。",
        hiragana: "こめでぃーえいがはわらえてたのしい。",
        romaji: "Komedī eiga wa waraete tanoshii.",
        traducao: "Filmes de comédia são engraçados e divertidos.",
      },
      {
        exemplo: "コメディー番組がお気に入りです。",
        hiragana: "こめでぃーばんぐみがおきにいりです。",
        romaji: "Komedī bangumi ga okiniiri desu.",
        traducao: "Programas de comédia são os meus favoritos.",
      },
    ],
  },
  {
    vocabulario: "フード",
    romaji: "fūdo",
    hiragana: "ふーど",
    traducao: "comida, alimento",
    exemplosFrases: [
      {
        exemplo: "スーパーマーケットで新鮮なフードを買う。",
        hiragana: "すーぱーまーけっとでしんせんなふーどをかう。",
        romaji: "Sūpāmāketto de shinsen na fūdo o kau.",
        traducao: "Comprar alimentos frescos no supermercado.",
      },
      {
        exemplo: "美味しいフードがたくさんあります。",
        hiragana: "おいしいふーどがたくさんあります。",
        romaji: "Oishii fūdo ga takusan arimasu.",
        traducao: "Há muita comida gostosa.",
      },
      {
        exemplo: "健康的なフードを選ぶ。",
        hiragana: "けんこうてきなふーどをえらぶ。",
        romaji: "Kenkō-teki na fūdo o erabu.",
        traducao: "Escolher alimentos saudáveis.",
      },
    ],
  },
  {
    vocabulario: "まま",
    romaji: "mama",
    hiragana: "まま",
    traducao: "como está, do jeito que está",
    exemplosFrases: [
      {
        exemplo: "部屋を出るときは片付けたままにしてください。",
        hiragana: "へやをでるときはかたづけたままにしてください。",
        romaji: "Heya o deru toki wa katazuketa mama ni shite kudasai.",
        traducao: "Ao sair do quarto, por favor, deixe-o arrumado.",
      },
      {
        exemplo: "彼はいつも怒ったままです。",
        hiragana: "かれはいつもおこったままです。",
        romaji: "Kare wa itsumo okotta mama desu.",
        traducao: "Ele está sempre zangado.",
      },
      {
        exemplo: "昨夜のままのメイクで出かけた。",
        hiragana: "さくやのままのめいくででかけた。",
        romaji: "Sakuya no mama no meiku de dekaketa.",
        traducao: "Saí de casa com a maquiagem da noite passada.",
      },
    ],
  },
  {
    vocabulario: "ショッピング",
    romaji: "shoppingu",
    hiragana: "しょっぴんぐ",
    traducao: "compras, fazer compras",
    exemplosFrases: [
      {
        exemplo: "週末にショッピングモールに行く。",
        hiragana: "しゅうまつにしょっぴんぐもーるにいく。",
        romaji: "Shūmatsu ni shoppingu mōru ni iku.",
        traducao: "Ir ao shopping no fim de semana.",
      },
      {
        exemplo: "新しい洋服をショッピングで買いました。",
        hiragana: "あたらしいようふくをしょっぴんぐでかいました。",
        romaji: "Atarashii yōfuku o shoppingu de kaimashita.",
        traducao: "Comprei roupas novas durante as compras.",
      },
      {
        exemplo: "ショッピングが好きです。",
        hiragana: "しょっぴんぐがすきです。",
        romaji: "Shoppingu ga suki desu.",
        traducao: "Eu gosto de fazer compras.",
      },
    ],
  },
  {
    vocabulario: "コミュニケーション",
    romaji: "komyunikēshon",
    hiragana: "こみゅにけーしょん",
    traducao: "comunicação",
    exemplosFrases: [
      {
        exemplo: "良いコミュニケーションを築く。",
        hiragana: "いいこみゅにけーしょんをきずく。",
        romaji: "Ii komyunikēshon o kizuku.",
        traducao: "Construir uma boa comunicação.",
      },
      {
        exemplo: "コミュニケーション能力を向上させる。",
        hiragana: "こみゅにけーしょんのうりょくをこうじょうさせる。",
        romaji: "Komyunikēshon nōryoku o kōjō saseru.",
        traducao: "Melhorar as habilidades de comunicação.",
      },
      {
        exemplo: "言葉以外のコミュニケーションも重要です。",
        hiragana: "ことばいがいのこみゅにけーしょんもじゅうようです。",
        romaji: "Kotoba igai no komyunikēshon mo jūyō desu.",
        traducao: "A comunicação além das palavras também é importante.",
      },
    ],
  },
  {
    vocabulario: "セット",
    romaji: "setto",
    hiragana: "せっと",
    traducao: "conjunto, set",
    exemplosFrases: [
      {
        exemplo: "このレストランのランチセットはお得です。",
        hiragana: "このれすとらんのらんちせっとはおとくです。",
        romaji: "Kono resutoran no ranchi setto wa otoku desu.",
        traducao: "O almoço em conjunto deste restaurante é uma boa oferta.",
      },
      {
        exemplo: "セットメニューを注文する。",
        hiragana: "せっとめにゅーをちゅうもんする。",
        romaji: "Setto menyū o chūmon suru.",
        traducao: "Pedir um menu em conjunto.",
      },
      {
        exemplo: "セットアップを準備する。",
        hiragana: "せっとあっぷをじゅんびする。",
        romaji: "Settoappu o junbi suru.",
        traducao: "Preparar a configuração em conjunto.",
      },
    ],
  },
  {
    vocabulario: "意識",
    romaji: "ishiki",
    hiragana: "いしき",
    traducao: "consciência",
    exemplosFrases: [
      {
        exemplo: "環境保護の意識を高める。",
        hiragana: "かんきょうほごのいしきをたかめる。",
        romaji: "Kankyō hogo no ishiki o takameru.",
        traducao: "Aumentar a consciência de proteção ambiental.",
      },
      {
        exemplo: "安全意識を持つ。",
        hiragana: "あんぜんいしきをもつ。",
        romaji: "Anzen ishiki o motsu.",
        traducao: "Ter consciência de segurança.",
      },
      {
        exemplo: "健康意識を向上させる。",
        hiragana: "けんこういしきをこうじょうさせる。",
        romaji: "Kenkō ishiki o kōjō saseru.",
        traducao: "Melhorar a consciência de saúde.",
      },
    ],
  },
  {
    vocabulario: "アドバイス",
    romaji: "adobaisu",
    hiragana: "あどばいす",
    traducao: "conselho, orientação",
    exemplosFrases: [
      {
        exemplo: "友達からアドバイスをもらう。",
        hiragana: "ともだちからあどばいすをもらう。",
        romaji: "Tomodachi kara adobaisu o morau.",
        traducao: "Receber conselhos de um amigo.",
      },
      {
        exemplo: "アドバイスを提供する。",
        hiragana: "あどばいすをていきょうする。",
        romaji: "Adobaisu o teikyō suru.",
        traducao: "Fornecer orientação.",
      },
      {
        exemplo: "アドバイスに従って行動する。",
        hiragana: "あどばいすにしたがってこうどうする。",
        romaji: "Adobaisu ni shitagatte kōdō suru.",
        traducao: "Agir de acordo com o conselho.",
      },
    ],
  },
  {
    vocabulario: "会計",
    romaji: "kaikei",
    hiragana: "かいけい",
    traducao: "contabilidade, faturamento",
    exemplosFrases: [
      {
        exemplo: "会計の仕事に就く。",
        hiragana: "かいけいのしごとにつく。",
        romaji: "Kaikei no shigoto ni tsuku.",
        traducao: "Trabalhar na área de contabilidade.",
      },
      {
        exemplo: "会計処理を行う。",
        hiragana: "かいけいしょりをおこなう。",
        romaji: "Kaikei shori o okonau.",
        traducao: "Realizar o processamento contábil.",
      },
      {
        exemplo: "会計報告書を作成する。",
        hiragana: "かいけいほうこくしょをさくせいする。",
        romaji: "Kaikei hōkokusho o sakusei suru.",
        traducao: "Criar um relatório de contabilidade.",
      },
    ],
  },
  {
    vocabulario: "コンタクト",
    romaji: "kontakuto",
    hiragana: "こんたくと",
    traducao: "contato, lente de contato",
    exemplosFrases: [
      {
        exemplo: "新しいコンタクトを買う。",
        hiragana: "あたらしいこんたくとをかう。",
        romaji: "Atarashii kontakuto o kau.",
        traducao: "Comprar novas lentes de contato.",
      },
      {
        exemplo: "コンタクトで視力を補正する。",
        hiragana: "こんたくとでしりょくをほせいする。",
        romaji: "Kontakuto de shiryoku o hosei suru.",
        traducao: "Corrigir a visão com lentes de contato.",
      },
      {
        exemplo: "コンタクトを付けるのが苦手だ。",
        hiragana: "こんたくとをつけるのがにがてだ。",
        romaji: "Kontakuto o tsukeru no ga nigate da.",
        traducao: "Tenho dificuldade em colocar lentes de contato.",
      },
    ],
  },
  {
    vocabulario: "しゃべり",
    romaji: "shaberi",
    hiragana: "しゃべり",
    traducao: "conversa, fala",
    exemplosFrases: [
      {
        exemplo: "友達と楽しくしゃべる。",
        hiragana: "ともだちとたのしくしゃべる。",
        romaji: "Tomodachi to tanoshiku shaberu.",
        traducao: "Conversar animadamente com os amigos.",
      },
      {
        exemplo: "おしゃべりが得意です。",
        hiragana: "おしゃべりがとくいです。",
        romaji: "Oshaberi ga tokui desu.",
        traducao: "Sou bom em conversar.",
      },
      {
        exemplo: "楽しいしゃべりをする。",
        hiragana: "たのしいしゃべりをする。",
        romaji: "Tanoshii shaberi o suru.",
        traducao: "Ter uma conversa divertida.",
      },
    ],
  },
  {
    vocabulario: "ランニング",
    romaji: "ranningu",
    hiragana: "らんにんぐ",
    traducao: "corrida, jogging",
    exemplosFrases: [
      {
        exemplo: "毎朝ランニングをする。",
        hiragana: "まいあさらんにんぐをする。",
        romaji: "Maiasa ranningu o suru.",
        traducao: "Fazer corrida todas as manhãs.",
      },
      {
        exemplo: "ランニングイベントに参加する。",
        hiragana: "らんにんぐいべんとにさんかする。",
        romaji: "Ranningu ibento ni sanka suru.",
        traducao: "Participar de um evento de corrida.",
      },
      {
        exemplo: "健康のためにランニングを始める。",
        hiragana: "けんこうのためにらんにんぐをはじめる。",
        romaji: "Kenkō no tame ni ranningu o hajimeru.",
        traducao: "Começar a correr pela saúde.",
      },
    ],
  },
  {
    vocabulario: "カット",
    romaji: "katto",
    hiragana: "かっと",
    traducao: "corte",
    exemplosFrases: [
      {
        exemplo: "髪をカットする。",
        hiragana: "かみをかっとする。",
        romaji: "Kami o katto suru.",
        traducao: "Cortar o cabelo.",
      },
      {
        exemplo: "美容院でカットをお願いする。",
        hiragana: "びよういんでかっとをおねがいする。",
        romaji: "Biyōin de katto o onegai suru.",
        traducao: "Pedir um corte no salão de beleza.",
      },
      {
        exemplo: "カットスタイルを変える。",
        hiragana: "かっとすたいるをかえる。",
        romaji: "Katto sutairu o kaeru.",
        traducao: "Mudar o estilo do corte.",
      },
    ],
  },
  {
    vocabulario: "引用",
    romaji: "in'yō",
    hiragana: "いんよう",
    traducao: "citação, referência",
    exemplosFrases: [
      {
        exemplo: "論文に引用を追加する。",
        hiragana: "ろんぶんにいんようをついかする。",
        romaji: "Ronbun ni in'yō o tsuika suru.",
        traducao: "Adicionar uma citação ao artigo.",
      },
      {
        exemplo: "資料の引用元を明記する。",
        hiragana: "しりょうのいんようげんをめいきする。",
        romaji: "Shiryō no in'yōgen o meiki suru.",
        traducao: "Indicar claramente a fonte da citação no documento.",
      },
      {
        exemplo: "引用符で文を囲む。",
        hiragana: "いんようふでぶんをかこむ。",
        romaji: "In'yōfu de bun o kakomu.",
        traducao: "Colocar aspas ao redor da frase citada.",
      },
    ],
  },
  {
    vocabulario: "ひび",
    romaji: "hibi",
    hiragana: "ひび",
    traducao: "rachadura, fissura",
    exemplosFrases: [
      {
        exemplo: "乾燥した手のひびが痛い。",
        hiragana: "かんそうしたてのひびがいたい。",
        romaji: "Kansō shita te no hibi ga itai.",
        traducao: "As rachaduras nas mãos secas doem.",
      },
      {
        exemplo: "ひび割れた道路",
        hiragana: "ひびわれたどうろ",
        romaji: "Hibiwareta dōro",
        traducao: "Uma estrada rachada.",
      },
      {
        exemplo: "ひびの入った壁",
        hiragana: "ひびのはいったかべ",
        romaji: "Hibi no haitta kabe",
        traducao: "Uma parede com uma fissura.",
      },
    ],
  },
  {
    vocabulario: "クリーム",
    romaji: "kurīmu",
    hiragana: "くりーむ",
    traducao: "creme",
    exemplosFrases: [
      {
        exemplo: "手にハンドクリームを塗る。",
        hiragana: "てにはんどくりーむをぬる。",
        romaji: "Te ni hando kurīmu o nuru.",
        traducao: "Passar creme nas mãos.",
      },
      {
        exemplo: "ホイップクリームをかける。",
        hiragana: "ほいっぷくりーむをかける。",
        romaji: "Hoippu kurīmu o kakeru.",
        traducao: "Colocar chantilly.",
      },
      {
        exemplo: "アイスクリームを食べる。",
        hiragana: "あいすくりーむをたべる。",
        romaji: "Aisu kurīmu o taberu.",
        traducao: "Comer sorvete.",
      },
    ],
  },
  {
    vocabulario: "横断",
    romaji: "ōdan",
    hiragana: "おうだん",
    traducao: "travessia, cruzamento",
    exemplosFrases: [
      {
        exemplo: "道路を横断する。",
        hiragana: "どうろをおうだんする。",
        romaji: "Dōro o ōdan suru.",
        traducao: "Atravessar a estrada.",
      },
      {
        exemplo: "歩行者用の横断歩道",
        hiragana: "ほこうしゃようのおうだんほどう",
        romaji: "Hokōsha-yō no ōdan hodō",
        traducao: "Ponte de pedestres.",
      },
      {
        exemplo: "横断禁止の看板",
        hiragana: "おうだんきんしのかんばん",
        romaji: "Ōdan kinshi no kanban",
        traducao: "Placa de proibido atravessar.",
      },
    ],
  },
  {
    vocabulario: "医療",
    romaji: "iryō",
    hiragana: "いりょう",
    traducao: "atendimento médico, assistência médica",
    exemplosFrases: [
      {
        exemplo: "医療費を支払う。",
        hiragana: "いりょうひをしはらう。",
        romaji: "Iryō-hi o shiharau.",
        traducao: "Pagar despesas médicas.",
      },
      {
        exemplo: "高度な医療技術",
        hiragana: "こうどないりょうぎじゅつ",
        romaji: "Kōdo na iryō gijutsu",
        traducao: "Tecnologia médica avançada.",
      },
      {
        exemplo: "医療制度の改革",
        hiragana: "いりょうせいどのかいかく",
        romaji: "Iryō seido no kaikaku",
        traducao: "Reforma do sistema de saúde.",
      },
    ],
  },
  {
    vocabulario: "クーポン",
    romaji: "kūpon",
    hiragana: "くーぽん",
    traducao: "cupom, vale",
    exemplosFrases: [
      {
        exemplo: "クーポンを使って割引を受ける。",
        hiragana: "くーぽんをつかってわりびきをうける。",
        romaji: "Kūpon o tsukatte waribiki o ukeru.",
        traducao: "Receber um desconto ao usar um cupom.",
      },
      {
        exemplo: "レストランのクーポンを手に入れる。",
        hiragana: "れすとらんのくーぽんをてにいれる。",
        romaji: "Resutoran no kūpon o te ni ireru.",
        traducao: "Obter um cupom de restaurante.",
      },
      {
        exemplo: "オンラインでクーポンをダウンロードする。",
        hiragana: "おんらいんでくーぽんをだうんろーどする。",
        romaji: "Onrain de kūpon o daunrōdo suru.",
        traducao: "Baixar um cupom online.",
      },
    ],
  },
  {
    vocabulario: "ダンス",
    romaji: "dansu",
    hiragana: "だんす",
    traducao: "dança",
    exemplosFrases: [
      {
        exemplo: "ダンスのレッスンを受ける。",
        hiragana: "だんすのれっすんをうける。",
        romaji: "Dansu no ressun o ukeru.",
        traducao: "Fazer aula de dança.",
      },
      {
        exemplo: "パーティーで踊るダンス",
        hiragana: "ぱーてぃーでおどるだんす",
        romaji: "Pātī de odoru dansu",
        traducao: "Dançar em uma festa.",
      },
      {
        exemplo: "ダンスパフォーマンスを披露する。",
        hiragana: "だんすぱふぉーまんすをひろうする。",
        romaji: "Dansu pafōmansu o hirō suru.",
        traducao: "Apresentar uma performance de dança.",
      },
    ],
  },
  {
    vocabulario: "下り",
    romaji: "kudari",
    hiragana: "くだり",
    traducao: "descida, descendo",
    exemplosFrases: [
      {
        exemplo: "坂を下りる。",
        hiragana: "さかをくだりる。",
        romaji: "Saka o kudariru.",
        traducao: "Descer a colina.",
      },
      {
        exemplo: "階段を下りる。",
        hiragana: "かいだんをくだりる。",
        romaji: "Kaidan o kudariru.",
        traducao: "Descer as escadas.",
      },
      {
        exemplo: "エスカレーターで下りる。",
        hiragana: "えすかれーたーでくだりる。",
        romaji: "Esukarētā de kudariru.",
        traducao: "Descer pela escada rolante.",
      },
    ],
  },
  {
    vocabulario: "以来",
    romaji: "irai",
    hiragana: "いらい",
    traducao: "desde, a partir de então",
    exemplosFrases: [
      {
        exemplo: "この事件以来、彼女は変わった。",
        hiragana: "このじけんいらい、かのじょはかわった。",
        romaji: "Kono jiken irai, kanojo wa kawatta.",
        traducao: "Desde esse incidente, ela mudou.",
      },
      {
        exemplo: "引っ越し以来、新しい友達ができた。",
        hiragana: "ひっこしいらい、あたらしいともだちができた。",
        romaji: "Hikkoshi irai, atarashii tomodachi ga dekita.",
        traducao: "Desde a mudança, fiz novos amigos.",
      },
      {
        exemplo: "卒業以来、久しぶりに会うことになった。",
        hiragana: "そつぎょういらい、ひさしぶりにあうことになった。",
        romaji: "Sotsugyō irai, hisashiburi ni au koto ni natta.",
        traducao:
          "Desde a formatura, tive a oportunidade de reencontrá-lo depois de muito tempo.",
      },
    ],
  },
  {
    vocabulario: "縁",
    romaji: "en",
    hiragana: "えん",
    traducao: "relação, conexão",
    exemplosFrases: [
      {
        exemplo: "彼とは昔からの縁がある。",
        hiragana: "かれとはむかしからのえんがある。",
        romaji: "Kare to wa mukashi kara no en ga aru.",
        traducao: "Tenho uma conexão com ele desde há muito tempo.",
      },
      {
        exemplo: "偶然の縁で彼に会った。",
        hiragana: "ぐうぜんのえんでかれにあった。",
        romaji: "Gūzen no en de kare ni atta.",
        traducao: "Conheci ele por acaso.",
      },
      {
        exemplo: "彼女との縁を大切にしたい。",
        hiragana: "かのじょとのえんをたいせつにしたい。",
        romaji: "Kanojo to no en o taisetsu ni shitai.",
        traducao: "Quero valorizar o nosso relacionamento.",
      },
    ],
  },
  {
    vocabulario: "悪魔",
    romaji: "akuma",
    hiragana: "あくま",
    traducao: "demônio",
    exemplosFrases: [
      {
        exemplo: "彼は悪魔のように笑った。",
        hiragana: "かれはあくまのようにわらった。",
        romaji: "Kare wa akuma no yō ni waratta.",
        traducao: "Ele riu como um demônio.",
      },
      {
        exemplo: "悪魔の力を持つ",
        hiragana: "あくまのちからをもつ",
        romaji: "Akuma no chikara o motsu",
        traducao: "Possuir o poder do demônio.",
      },
      {
        exemplo: "悪魔の誘惑に負ける",
        hiragana: "あくまのゆうわくにまける",
        romaji: "Akuma no yūwaku ni makeru",
        traducao: "Ceder à tentação do demônio.",
      },
    ],
  },
  {
    vocabulario: "ダイヤ",
    romaji: "daiya",
    hiragana: "だいや",
    traducao: "diamante",
    exemplosFrases: [
      {
        exemplo: "ダイヤの指輪を贈る",
        hiragana: "だいやのゆびわをおくる",
        romaji: "Daiya no yubiwa o okuru",
        traducao: "Presentear com um anel de diamante.",
      },
      {
        exemplo: "ダイヤモンドの輝き",
        hiragana: "だいやもんどのかがやき",
        romaji: "Daiyamondo no kagayaki",
        traducao: "O brilho do diamante.",
      },
      {
        exemplo: "ダイヤのネックレスを身につける",
        hiragana: "だいやのねっくれすをみにつける",
        romaji: "Daiya no nekkuresu o mi ni tsukeru",
        traducao: "Usar um colar de diamante.",
      },
    ],
  },
  {
    vocabulario: "ダイエット",
    romaji: "daietto",
    hiragana: "だいえっと",
    traducao: "dieta",
    exemplosFrases: [
      {
        exemplo: "ダイエットを始める",
        hiragana: "だいえっとをはじめる",
        romaji: "Daietto o hajimeru",
        traducao: "Começar uma dieta.",
      },
      {
        exemplo: "ダイエット中の食事制限",
        hiragana: "だいえっとちゅうのしょくじせいげん",
        romaji: "Daietto-chū no shokuji seigen",
        traducao: "Restrições alimentares durante a dieta.",
      },
      {
        exemplo: "ダイエットに成功する",
        hiragana: "だいえっとにせいこうする",
        romaji: "Daietto ni seikō suru",
        traducao: "Ter sucesso na dieta.",
      },
    ],
  },
  {
    vocabulario: "違い",
    romaji: "chigai",
    hiragana: "ちがい",
    traducao: "diferença",
    exemplosFrases: [
      {
        exemplo: "二つの商品の違い",
        hiragana: "ふたつのしょうひんのちがい",
        romaji: "Futatsu no shōhin no chigai",
        traducao: "A diferença entre dois produtos.",
      },
      {
        exemplo: "価格に違いがある",
        hiragana: "かかくにちがいがある",
        romaji: "Kakaku ni chigai ga aru",
        traducao: "Há diferença de preço.",
      },
      {
        exemplo: "見た目の違い",
        hiragana: "みためのちがい",
        romaji: "Mitame no chigai",
        traducao: "Diferença na aparência.",
      },
    ],
  },
  {
    vocabulario: "外交",
    romaji: "gaikō",
    hiragana: "がいこう",
    traducao: "diplomacia",
    exemplosFrases: [
      {
        exemplo: "外交交渉を行う",
        hiragana: "がいこうこうしょうをおこなう",
        romaji: "Gaikō kōshō o okonau",
        traducao: "Realizar negociações diplomáticas.",
      },
      {
        exemplo: "外交政策を立てる",
        hiragana: "がいこうせいさくをたてる",
        romaji: "Gaikō seisaku o tateru",
        traducao: "Formular uma política externa.",
      },
      {
        exemplo: "外交官として働く",
        hiragana: "がいこうかんとしてはたらく",
        romaji: "Gaikōkan to shite hataraku",
        traducao: "Trabalhar como diplomata.",
      },
    ],
  },
  {
    vocabulario: "ドライブ",
    romaji: "doraibu",
    hiragana: "どらいぶ",
    traducao: "passeio de carro, dirigir",
    exemplosFrases: [
      {
        exemplo: "ドライブに出かける",
        hiragana: "どらいぶにでかける",
        romaji: "Doraibu ni dekakeru",
        traducao: "Sair para um passeio de carro.",
      },
      {
        exemplo: "ドライブ中の風景",
        hiragana: "どらいぶちゅうのふうけい",
        romaji: "Doraibu-chū no fūkei",
        traducao: "Paisagens durante um passeio de carro.",
      },
      {
        exemplo: "友達とドライブに行く",
        hiragana: "ともだちとどらいぶにいく",
        romaji: "Tomodachi to doraibu ni iku",
        traducao: "Ir para um passeio de carro com os amigos.",
      },
    ],
  },
  {
    vocabulario: "演説",
    romaji: "enzetsu",
    hiragana: "えんぜつ",
    traducao: "discurso",
    exemplosFrases: [
      {
        exemplo: "大統領の演説",
        hiragana: "だいとうりょうのえんぜつ",
        romaji: "Daitōryō no enzetsu",
        traducao: "Discurso do presidente.",
      },
      {
        exemplo: "感動的な演説",
        hiragana: "かんどうてきなえんぜつ",
        romaji: "Kandō-teki na enzetsu",
        traducao: "Discurso emocionante.",
      },
      {
        exemplo: "演説を行う場所",
        hiragana: "えんぜつをおこなうばしょ",
        romaji: "Enzetsu o okonau basho",
        traducao: "Local para realizar um discurso.",
      },
    ],
  },
  {
    vocabulario: "スピーチ",
    romaji: "supīchi",
    hiragana: "すぴーち",
    traducao: "discurso, fala",
    exemplosFrases: [
      {
        exemplo: "公開スピーチをする",
        hiragana: "こうかいすぴーちをする",
        romaji: "Kōkai supīchi o suru",
        traducao: "Fazer um discurso público.",
      },
      {
        exemplo: "スピーチコンテストに参加する",
        hiragana: "すぴーちこんてすとにさんかする",
        romaji: "Supīchi kontesuto ni sanka suru",
        traducao: "Participar de um concurso de discursos.",
      },
      {
        exemplo: "感謝のスピーチを述べる",
        hiragana: "かんしゃのすぴーちをのべる",
        romaji: "Kansha no supīchi o noberu",
        traducao: "Expressar um discurso de agradecimento.",
      },
    ],
  },
  {
    vocabulario: "ディスカッション",
    romaji: "disukasshon",
    hiragana: "でぃすかっしょん",
    traducao: "discussão",
    exemplosFrases: [
      {
        exemplo: "グループでディスカッションをする",
        hiragana: "ぐるーぷででぃすかっしょんをする",
        romaji: "Gurūpu de disukasshon o suru",
        traducao: "Realizar uma discussão em grupo.",
      },
      {
        exemplo: "意見を交換するディスカッション",
        hiragana: "いけんをこうかんするでぃすかっしょん",
        romaji: "Iken o kōkan suru disukasshon",
        traducao: "Uma discussão para troca de opiniões.",
      },
      {
        exemplo: "ディスカッションをリードする",
        hiragana: "でぃすかっしょんをりーどする",
        romaji: "Disukasshon o rīdo suru",
        traducao: "Liderar a discussão.",
      },
    ],
  },
  {
    vocabulario: "ドラマ",
    romaji: "dorama",
    hiragana: "どらま",
    traducao: "drama (série de TV)",
    exemplosFrases: [
      {
        exemplo: "人気ドラマを観る",
        hiragana: "にんきどらまをみる",
        romaji: "Ninki dorama o miru",
        traducao: "Assistir a um drama popular.",
      },
      {
        exemplo: "ドラマの主役を演じる",
        hiragana: "どらまのしゅやくをえんじる",
        romaji: "Dorama no shuyaku o enjiru",
        traducao: "Atuar como protagonista em um drama.",
      },
      {
        exemplo: "感動的なドラマのシーン",
        hiragana: "かんどうてきなどらまのしーん",
        romaji: "Kandō-teki na dorama no shīn",
        traducao: "Cena emocionante em um drama.",
      },
    ],
  },
  {
    vocabulario: "画面",
    romaji: "gamen",
    hiragana: "がめん",
    traducao: "tela, tela de exibição",
    exemplosFrases: [
      {
        exemplo: "画面の明るさを調整する",
        hiragana: "がめんのあかるさをちょうせいする",
        romaji: "Gamen no akarusa o chōsei suru",
        traducao: "Ajustar o brilho da tela.",
      },
      {
        exemplo: "画面を拡大する",
        hiragana: "がめんをかくだいする",
        romaji: "Gamen o kakudai suru",
        traducao: "Ampliar a tela.",
      },
      {
        exemplo: "画面に表示されたメッセージ",
        hiragana: "がめんにひょうじされためっせーじ",
        romaji: "Gamen ni hyōji sareta messēji",
        traducao: "Mensagem exibida na tela.",
      },
    ],
  },
  {
    vocabulario: "回り",
    romaji: "mawari",
    hiragana: "まわり",
    traducao: "ao redor, arredores",
    exemplosFrases: [
      {
        exemplo: "部屋の周りを掃除する",
        hiragana: "へやのまわりをそうじする",
        romaji: "Heya no mawari o sōji suru",
        traducao: "Limpar ao redor do quarto.",
      },
      {
        exemplo: "周りの人たちと話す",
        hiragana: "まわりのひとたちとはなす",
        romaji: "Mawari no hitotachi to hanasu",
        traducao: "Conversar com as pessoas ao redor.",
      },
      {
        exemplo: "自然の美しさに囲まれた周り",
        hiragana: "しぜんのうつくしさにかこまれたまわり",
        romaji: "Shizen no utsukushisa ni kakomareta mawari",
        traducao: "Arredores cercados pela beleza da natureza.",
      },
    ],
  },
  {
    vocabulario: "デート",
    romaji: "dēto",
    hiragana: "でーと",
    traducao: "encontro, sair com alguém",
    exemplosFrases: [
      {
        exemplo: "彼とデートする",
        hiragana: "かれとでーとする",
        romaji: "Kare to dēto suru",
        traducao: "Sair em um encontro com ele.",
      },
      {
        exemplo: "デートプランを立てる",
        hiragana: "でーとぷらんをたてる",
        romaji: "Dēto puran o tateru",
        traducao: "Planejar um encontro.",
      },
      {
        exemplo: "デート中の楽しい時間",
        hiragana: "でーとちゅうのたのしいじかん",
        romaji: "Dēto-chū no tanoshii jikan",
        traducao: "Tempo divertido durante o encontro.",
      },
    ],
  },
  {
    vocabulario: "会合",
    romaji: "kaigō",
    hiragana: "かいごう",
    traducao: "reunião, encontro",
    exemplosFrases: [
      {
        exemplo: "会合に参加する",
        hiragana: "かいごうにさんかする",
        romaji: "Kaigō ni sanka suru",
        traducao: "Participar de uma reunião.",
      },
      {
        exemplo: "会合の日程を調整する",
        hiragana: "かいごうのにっていをちょうせいする",
        romaji: "Kaigō no nitei o chōsei suru",
        traducao: "Ajustar a agenda da reunião.",
      },
      {
        exemplo: "会合の場所を決める",
        hiragana: "かいごうのばしょをきめる",
        romaji: "Kaigō no basho o kimeru",
        traducao: "Decidir o local da reunião.",
      },
    ],
  },
  {
    vocabulario: "宛先",
    romaji: "atesaki",
    hiragana: "あてさき",
    traducao: "destinatário, endereço de destino",
    exemplosFrases: [
      {
        exemplo: "手紙の宛先を書く",
        hiragana: "てがみのあてさきをかく",
        romaji: "Tegami no atesaki o kaku",
        traducao: "Escrever o endereço do destinatário na carta.",
      },
      {
        exemplo: "宛先を間違える",
        hiragana: "あてさきをまちがえる",
        romaji: "Atesaki o machigaeru",
        traducao: "Errar o endereço de destino.",
      },
      {
        exemplo: "宛先不明の手紙",
        hiragana: "あてさきふめいのてがみ",
        romaji: "Atesaki fumei no tegami",
        traducao: "Carta com destino desconhecido.",
      },
    ],
  },
  {
    vocabulario: "エネルギー",
    romaji: "enerugī",
    hiragana: "えねるぎー",
    traducao: "energia",
    exemplosFrases: [
      {
        exemplo: "エネルギーを消費する",
        hiragana: "えねるぎーをしょうひする",
        romaji: "Enerugī o shōhi suru",
        traducao: "Consumir energia.",
      },
      {
        exemplo: "再生可能エネルギー",
        hiragana: "さいせいかのうえねるぎー",
        romaji: "Saisei kanō enerugī",
        traducao: "Energia renovável.",
      },
      {
        exemplo: "エネルギー効率の改善",
        hiragana: "えねるぎーこうりつのかいぜん",
        romaji: "Enerugī kōritsu no kaizen",
        traducao: "Melhoria na eficiência energética.",
      },
    ],
  },
  {
    vocabulario: "うなぎ",
    romaji: "unagi",
    hiragana: "うなぎ",
    traducao: "enguia",
    exemplosFrases: [
      {
        exemplo: "うなぎの蒲焼きを食べる",
        hiragana: "うなぎのかばやきをたべる",
        romaji: "Unagi no kabayaki o taberu",
        traducao: "Comer unagi grelhada.",
      },
      {
        exemplo: "うなぎの料理が人気",
        hiragana: "うなぎのりょうりがにんき",
        romaji: "Unagi no ryōri ga ninki",
        traducao: "A culinária de unagi é popular.",
      },
      {
        exemplo: "うなぎの味を楽しむ",
        hiragana: "うなぎのあじをたのしむ",
        romaji: "Unagi no aji o tanoshimu",
        traducao: "Desfrutar do sabor da unagi.",
      },
    ],
  },
  {
    vocabulario: "バランス",
    romaji: "baransu",
    hiragana: "ばらんす",
    traducao: "equilíbrio, balanço",
    exemplosFrases: [
      {
        exemplo: "バランスのとれた食事",
        hiragana: "ばらんすのとれたしょくじ",
        romaji: "Baransu no toteta shokuji",
        traducao: "Refeição balanceada.",
      },
      {
        exemplo: "バランスを保つ",
        hiragana: "ばらんすをたもつ",
        romaji: "Baransu o tamotsu",
        traducao: "Manter o equilíbrio.",
      },
      {
        exemplo: "体のバランスを取る",
        hiragana: "からだのばらんすをとる",
        romaji: "Karada no baransu o toru",
        traducao: "Equilibrar o corpo.",
      },
    ],
  },
  {
    vocabulario: "チーム",
    romaji: "chīmu",
    hiragana: "ちーむ",
    traducao: "time, equipe",
    exemplosFrases: [
      {
        exemplo: "サッカーチームに入る",
        hiragana: "さっかーちーむにはいる",
        romaji: "Sakkā chīmu ni hairu",
        traducao: "Entrar para um time de futebol.",
      },
      {
        exemplo: "チームメンバーと協力する",
        hiragana: "ちーむめんばーときょうりょくする",
        romaji: "Chīmu menbā to kyōryoku suru",
        traducao: "Colaborar com os membros da equipe.",
      },
      {
        exemplo: "チームの勝利を祝う",
        hiragana: "ちーむのしょうりをいわう",
        romaji: "Chīmu no shōri o iwau",
        traducao: "Comemorar a vitória da equipe.",
      },
    ],
  },
  {
    vocabulario: "ミス",
    romaji: "misu",
    hiragana: "みす",
    traducao: "erro, engano",
    exemplosFrases: [
      {
        exemplo: "ミスを犯す",
        hiragana: "みすをおかす",
        romaji: "Misu o okasu",
        traducao: "Cometer um erro.",
      },
      {
        exemplo: "ミスを修正する",
        hiragana: "みすをしゅうせいする",
        romaji: "Misu o shūsei suru",
        traducao: "Corrigir um erro.",
      },
      {
        exemplo: "ミスを防ぐ",
        hiragana: "みすをふせぐ",
        romaji: "Misu o fusegu",
        traducao: "Prevenir um erro.",
      },
    ],
  },
  {
    vocabulario: "オフィス",
    romaji: "ofisu",
    hiragana: "おふぃす",
    traducao: "escritório",
    exemplosFrases: [
      {
        exemplo: "オフィスで仕事をする",
        hiragana: "おふぃすでしごとをする",
        romaji: "Ofisu de shigoto o suru",
        traducao: "Trabalhar no escritório.",
      },
      {
        exemplo: "オフィスの雰囲気",
        hiragana: "おふぃすのふんいき",
        romaji: "Ofisu no fun'iki",
        traducao: "Ambiente do escritório.",
      },
      {
        exemplo: "オフィス家具",
        hiragana: "おふぃすかぐ",
        romaji: "Ofisu kagu",
        traducao: "Móveis de escritório.",
      },
    ],
  },
  {
    vocabulario: "一種",
    romaji: "isshu",
    hiragana: "いっしゅ",
    traducao: "um tipo, uma espécie",
    exemplosFrases: [
      {
        exemplo: "一種の芸術",
        hiragana: "いっしゅのげいじゅつ",
        romaji: "Isshu no geijutsu",
        traducao: "Uma forma de arte.",
      },
      {
        exemplo: "一種のスポーツ",
        hiragana: "いっしゅのすぽーつ",
        romaji: "Isshu no supōtsu",
        traducao: "Um tipo de esporte.",
      },
      {
        exemplo: "一種の感情",
        hiragana: "いっしゅのかんじょう",
        romaji: "Isshu no kanjō",
        traducao: "Um tipo de emoção.",
      },
    ],
  },
  {
    vocabulario: "スポーツ",
    romaji: "supōtsu",
    hiragana: "すぽーつ",
    traducao: "esporte",
    exemplosFrases: [
      {
        exemplo: "スポーツをする",
        hiragana: "すぽーつをする",
        romaji: "Supōtsu o suru",
        traducao: "Praticar esportes.",
      },
      {
        exemplo: "スポーツイベントに参加する",
        hiragana: "すぽーついべんとにさんかする",
        romaji: "Supōtsu ibento ni sanka suru",
        traducao: "Participar de um evento esportivo.",
      },
      {
        exemplo: "スポーツマンシップを守る",
        hiragana: "すぽーつまんしっぷをまもる",
        romaji: "Supōtsu manshippu o mamoru",
        traducao: "Manter o espírito esportivo.",
      },
    ],
  },
  {
    vocabulario: "嫁",
    romaji: "yome",
    hiragana: "よめ",
    traducao: "esposa (de alguém)",
    exemplosFrases: [
      {
        exemplo: "彼の嫁",
        hiragana: "かれのよめ",
        romaji: "Kare no yome",
        traducao: "A esposa dele.",
      },
      {
        exemplo: "新婦は嫁ぐ",
        hiragana: "しんぷはよめぐ",
        romaji: "Shinpu wa yomegu",
        traducao: "A noiva se casa.",
      },
      {
        exemplo: "嫁姑の関係",
        hiragana: "よめしゅうとのかんけい",
        romaji: "Yome shūto no kankei",
        traducao: "Relação entre esposa e sogra.",
      },
    ],
  },
  {
    vocabulario: "スキー",
    romaji: "skī",
    hiragana: "すきー",
    traducao: "esqui",
    exemplosFrases: [
      {
        exemplo: "スキー場で滑る",
        hiragana: "すきーじょうですべる",
        romaji: "Skī-jō de suberu",
        traducao: "Esquiar na estação de esqui.",
      },
      {
        exemplo: "スキーのレッスンを受ける",
        hiragana: "すきーのれっすんをうける",
        romaji: "Skī no ressun o ukeru",
        traducao: "Fazer aulas de esqui.",
      },
      {
        exemplo: "スキー旅行に行く",
        hiragana: "すきーりょこうにいく",
        romaji: "Skī ryokō ni iku",
        traducao: "Fazer uma viagem de esqui.",
      },
    ],
  },
  {
    vocabulario: "安定",
    romaji: "antei",
    hiragana: "あんてい",
    traducao: "estabilidade",
    exemplosFrases: [
      {
        exemplo: "安定した収入",
        hiragana: "あんていしたしゅうにゅう",
        romaji: "Antei shita shūnyū",
        traducao: "Renda estável.",
      },
      {
        exemplo: "安定した状態を保つ",
        hiragana: "あんていしたじょうたいをたもつ",
        romaji: "Antei shita jōtai o tamotsu",
        traducao: "Manter um estado estável.",
      },
      {
        exemplo: "安定した雇用",
        hiragana: "あんていしたこよう",
        romaji: "Antei shita koyō",
        traducao: "Emprego estável.",
      },
    ],
  },
  {
    vocabulario: "インターンシップ",
    romaji: "intānshippu",
    hiragana: "いんたーんしっぷ",
    traducao: "estágio",
    exemplosFrases: [
      {
        exemplo: "インターンシップに参加する",
        hiragana: "いんたーんしっぷにさんかする",
        romaji: "Intānshippu ni sanka suru",
        traducao: "Participar de um estágio.",
      },
      {
        exemplo: "インターンシップの経験",
        hiragana: "いんたーんしっぷのけいけん",
        romaji: "Intānshippu no keiken",
        traducao: "Experiência de estágio.",
      },
      {
        exemplo: "インターンシッププログラム",
        hiragana: "いんたーんしっぷぷろぐらむ",
        romaji: "Intānshippu puroguramu",
        traducao: "Programa de estágio.",
      },
    ],
  },
  {
    vocabulario: "スタイル",
    romaji: "sutairu",
    hiragana: "すたいる",
    traducao: "estilo",
    exemplosFrases: [
      {
        exemplo: "ファッションスタイル",
        hiragana: "ふぁっしょんすたいる",
        romaji: "Fasshon sutairu",
        traducao: "Estilo de moda.",
      },
      {
        exemplo: "スタイルを変える",
        hiragana: "すたいるをかえる",
        romaji: "Sutairu o kaeru",
        traducao: "Mudar o estilo.",
      },
      {
        exemplo: "個人のスタイル",
        hiragana: "こじんのすたいる",
        romaji: "Kojin no sutairu",
        traducao: "Estilo pessoal.",
      },
    ],
  },
  {
    vocabulario: "胃",
    romaji: "i",
    hiragana: "い",
    traducao: "estômago",
    exemplosFrases: [
      {
        exemplo: "胃の痛み",
        hiragana: "いのいたみ",
        romaji: "I no itami",
        traducao: "Dor de estômago.",
      },
      {
        exemplo: "胃腸の調子が悪い",
        hiragana: "いちょうのちょうしにわるい",
        romaji: "Ichō no chōshi ga warui",
        traducao: "Má digestão.",
      },
      {
        exemplo: "胃を休める",
        hiragana: "いをやすめる",
        romaji: "I o yasumeru",
        traducao: "Descansar o estômago.",
      },
    ],
  },
  {
    vocabulario: "海外",
    romaji: "kaigai",
    hiragana: "かいがい",
    traducao: "exterior, estrangeiro",
    exemplosFrases: [
      {
        exemplo: "海外旅行",
        hiragana: "かいがいりょこう",
        romaji: "Kaigai ryokō",
        traducao: "Viagem ao exterior.",
      },
      {
        exemplo: "海外生活",
        hiragana: "かいがいせいかつ",
        romaji: "Kaigai seikatsu",
        traducao: "Vida no exterior.",
      },
      {
        exemplo: "海外で働く",
        hiragana: "かいがいではたらく",
        romaji: "Kaigai de hataraku",
        traducao: "Trabalhar no exterior.",
      },
    ],
  },
  {
    vocabulario: "異常",
    romaji: "ijō",
    hiragana: "いじょう",
    traducao: "anormalidade, anormal",
    exemplosFrases: [
      {
        exemplo: "体温が異常",
        hiragana: "たいおんがいじょう",
        romaji: "Taion ga ijō",
        traducao: "Temperatura corporal anormal.",
      },
      {
        exemplo: "異常な行動",
        hiragana: "いじょうなこうどう",
        romaji: "Ijōna kōdō",
        traducao: "Comportamento anormal.",
      },
      {
        exemplo: "異常気象",
        hiragana: "いじょうきしょう",
        romaji: "Ijō kishō",
        traducao: "Clima anormal.",
      },
    ],
  },
  {
    vocabulario: "スター",
    romaji: "sutā",
    hiragana: "すたー",
    traducao: "estrela (famosa)",
    exemplosFrases: [
      {
        exemplo: "映画スター",
        hiragana: "えいがすたー",
        romaji: "Eiga sutā",
        traducao: "Estrela de cinema.",
      },
      {
        exemplo: "アイドルスター",
        hiragana: "あいどるすたー",
        romaji: "Aidoru sutā",
        traducao: "Estrela idol.",
      },
      {
        exemplo: "人気スター",
        hiragana: "にんきすたー",
        romaji: "Ninki sutā",
        traducao: "Estrela popular.",
      },
    ],
  },
  {
    vocabulario: "ストレス",
    romaji: "sutoresu",
    hiragana: "すとれす",
    traducao: "estresse",
    exemplosFrases: [
      {
        exemplo: "ストレスが溜まる",
        hiragana: "すとれすがたまる",
        romaji: "Sutoresu ga tamaru",
        traducao: "Acumular estresse.",
      },
      {
        exemplo: "ストレスを解消する",
        hiragana: "すとれすをかいしょうする",
        romaji: "Sutoresu o kaishō suru",
        traducao: "Aliviar o estresse.",
      },
      {
        exemplo: "ストレスフルな生活",
        hiragana: "すとれすふるなせいかつ",
        romaji: "Sutoresu furu na seikatsu",
        traducao: "Estilo de vida estressante.",
      },
    ],
  },
  {
    vocabulario: "永遠",
    romaji: "eien",
    hiragana: "えいえん",
    traducao: "eternidade",
    exemplosFrases: [
      {
        exemplo: "愛は永遠",
        hiragana: "あいはえいえん",
        romaji: "Ai wa eien",
        traducao: "O amor é eterno.",
      },
      {
        exemplo: "永遠の命",
        hiragana: "えいえんのいのち",
        romaji: "Eien no inochi",
        traducao: "Vida eterna.",
      },
      {
        exemplo: "永遠に続く",
        hiragana: "えいえんにつづく",
        romaji: "Eien ni tsuzuku",
        traducao: "Continuar para sempre.",
      },
    ],
  },
  {
    vocabulario: "永久",
    romaji: "eikyū",
    hiragana: "えいきゅう",
    traducao: "eternidade, permanente",
    exemplosFrases: [
      {
        exemplo: "永久保存",
        hiragana: "えいきゅうほぞん",
        romaji: "Eikyū hozon",
        traducao: "Armazenamento permanente.",
      },
      {
        exemplo: "永久に忘れない",
        hiragana: "えいきゅうにわすれない",
        romaji: "Eikyū ni wasurenai",
        traducao: "Nunca esquecer.",
      },
      {
        exemplo: "永久就職",
        hiragana: "えいきゅうしゅうしょく",
        romaji: "Eikyū shūshoku",
        traducao: "Emprego permanente.",
      },
    ],
  },
  {
    vocabulario: "ヨーロッパ",
    romaji: "yōroppa",
    hiragana: "よーろっぱ",
    traducao: "Europa",
    exemplosFrases: [
      {
        exemplo: "ヨーロッパ旅行",
        hiragana: "よーろっぱりょこう",
        romaji: "Yōroppa ryokō",
        traducao: "Viagem pela Europa.",
      },
      {
        exemplo: "ヨーロッパの文化",
        hiragana: "よーろっぱのぶんか",
        romaji: "Yōroppa no bunka",
        traducao: "Cultura europeia.",
      },
      {
        exemplo: "ヨーロッパ諸国",
        hiragana: "よーろっぱしょこく",
        romaji: "Yōroppa shokoku",
        traducao: "Países europeus.",
      },
    ],
  },
  {
    vocabulario: "イベント",
    romaji: "ibento",
    hiragana: "いべんと",
    traducao: "evento",
    exemplosFrases: [
      {
        exemplo: "イベントに参加する",
        hiragana: "いべんとにさんかする",
        romaji: "Ibento ni sanka suru",
        traducao: "Participar de um evento.",
      },
      {
        exemplo: "イベントを開催する",
        hiragana: "いべんとをかいさいする",
        romaji: "Ibento o kaisai suru",
        traducao: "Realizar um evento.",
      },
      {
        exemplo: "イベントの準備をする",
        hiragana: "いべんとのじゅんびをする",
        romaji: "Ibento no junbi o suru",
        traducao: "Preparar-se para um evento.",
      },
    ],
  },
  {
    vocabulario: "拡大",
    romaji: "kakudai",
    hiragana: "かくだい",
    traducao: "expansão, ampliação",
    exemplosFrases: [
      {
        exemplo: "市場を拡大する",
        hiragana: "しじょうをかくだいする",
        romaji: "Shijō o kakudai suru",
        traducao: "Expandir o mercado.",
      },
      {
        exemplo: "拡大版",
        hiragana: "かくだいばん",
        romaji: "Kakudai-ban",
        traducao: "Edição ampliada.",
      },
      {
        exemplo: "拡大写真",
        hiragana: "かくだいしゃしん",
        romaji: "Kakudai shashin",
        traducao: "Foto ampliada.",
      },
    ],
  },
  {
    vocabulario: "解釈",
    romaji: "kaishaku",
    hiragana: "かいしゃく",
    traducao: "interpretação",
    exemplosFrases: [
      {
        exemplo: "解釈の違い",
        hiragana: "かいしゃくのちがい",
        romaji: "Kaishaku no chigai",
        traducao: "Diferença de interpretação.",
      },
      {
        exemplo: "法律の解釈",
        hiragana: "ほうりつのかいしゃく",
        romaji: "Hōritsu no kaishaku",
        traducao: "Interpretação da lei.",
      },
      {
        exemplo: "解釈を誤る",
        hiragana: "かいしゃくをあやまる",
        romaji: "Kaishaku o ayamaru",
        traducao: "Interpretar erroneamente.",
      },
    ],
  },
  {
    vocabulario: "アイロン",
    romaji: "airon",
    hiragana: "あいろん",
    traducao: "ferro de passar roupa",
    exemplosFrases: [
      {
        exemplo: "アイロンをかける",
        hiragana: "あいろんをかける",
        romaji: "Airon o kakeru",
        traducao: "Passar roupa com ferro.",
      },
      {
        exemplo: "アイロン台",
        hiragana: "あいろんだい",
        romaji: "Airon-dai",
        traducao: "Tábua de passar roupa.",
      },
      {
        exemplo: "アイロンの温度",
        hiragana: "あいろんのおんど",
        romaji: "Airon no ondo",
        traducao: "Temperatura do ferro de passar.",
      },
    ],
  },
  {
    vocabulario: "スタンド",
    romaji: "sutando",
    hiragana: "すたんど",
    traducao: "suporte, pedestal",
    exemplosFrases: [
      {
        exemplo: "マイクスタンド",
        hiragana: "まいくすたんど",
        romaji: "Maiku sutando",
        traducao: "Suporte para microfone.",
      },
      {
        exemplo: "スタンドライト",
        hiragana: "すたんどらいと",
        romaji: "Sutando raito",
        traducao: "Luminária de suporte.",
      },
      {
        exemplo: "スタンドマイク",
        hiragana: "すたんどまいく",
        romaji: "Sutando maiku",
        traducao: "Microfone de suporte.",
      },
    ],
  },
  {
    vocabulario: "フィンランド",
    romaji: "Finrando",
    hiragana: "ふぃんらんど",
    traducao: "Finlândia",
    exemplosFrases: [
      {
        exemplo: "フィンランド語",
        hiragana: "ふぃんらんどご",
        romaji: "Finrando-go",
        traducao: "Língua finlandesa.",
      },
      {
        exemplo: "フィンランド文化",
        hiragana: "ふぃんらんどぶんか",
        romaji: "Finrando bunka",
        traducao: "Cultura finlandesa.",
      },
      {
        exemplo: "フィンランド人",
        hiragana: "ふぃんらんどじん",
        romaji: "Finrando-jin",
        traducao: "Finlandês.",
      },
    ],
  },
  {
    vocabulario: "印",
    romaji: "shirushi",
    hiragana: "しるし",
    traducao: "sinal, marca",
    exemplosFrases: [
      {
        exemplo: "印をつける",
        hiragana: "しるしをつける",
        romaji: "Shirushi o tsukeru",
        traducao: "Marcar, assinalar.",
      },
      {
        exemplo: "証拠の印",
        hiragana: "しょうこのしるし",
        romaji: "Shōko no shirushi",
        traducao: "Marca de evidência.",
      },
      {
        exemplo: "特徴の印",
        hiragana: "とくちょうのしるし",
        romaji: "Tokuchō no shirushi",
        traducao: "Sinal característico.",
      },
    ],
  },
  {
    vocabulario: "火災",
    romaji: "kasai",
    hiragana: "かさい",
    traducao: "incêndio",
    exemplosFrases: [
      {
        exemplo: "火災報知器",
        hiragana: "かさいほうちき",
        romaji: "Kasai hōchiki",
        traducao: "Detector de incêndio.",
      },
      {
        exemplo: "火災の原因",
        hiragana: "かさいのげんいん",
        romaji: "Kasai no gen'in",
        traducao: "Causa do incêndio.",
      },
      {
        exemplo: "火災警報",
        hiragana: "かさいけいほう",
        romaji: "Kasai keihō",
        traducao: "Alarme de incêndio.",
      },
    ],
  },
  {
    vocabulario: "花火",
    romaji: "hanabi",
    hiragana: "はなび",
    traducao: "fogos de artifício",
    exemplosFrases: [
      {
        exemplo: "花火大会",
        hiragana: "はなびたいかい",
        romaji: "Hanabi taikai",
        traducao: "Festival de fogos de artifício.",
      },
      {
        exemplo: "花火を打ち上げる",
        hiragana: "はなびをうちあげる",
        romaji: "Hanabi o uchiageru",
        traducao: "Lançar fogos de artifício.",
      },
      {
        exemplo: "花火の音",
        hiragana: "はなびのおと",
        romaji: "Hanabi no oto",
        traducao: "Som dos fogos de artifício.",
      },
    ],
  },
  {
    vocabulario: "ヘッドホン",
    romaji: "heddohon",
    hiragana: "へっどほん",
    traducao: "fones de ouvido",
    exemplosFrases: [
      {
        exemplo: "ヘッドホンをつける",
        hiragana: "へっどほんをつける",
        romaji: "Heddohon o tsukeru",
        traducao: "Colocar os fones de ouvido.",
      },
      {
        exemplo: "ワイヤレスヘッドホン",
        hiragana: "わいあれすへっどほん",
        romaji: "Waiaresu heddohon",
        traducao: "Fones de ouvido sem fio.",
      },
      {
        exemplo: "ヘッドホンジャック",
        hiragana: "へっどほんじゃっく",
        romaji: "Heddohon jakku",
        traducao: "Entrada do fone de ouvido.",
      },
    ],
  },
  {
    vocabulario: "アウト",
    romaji: "auto",
    hiragana: "あうと",
    traducao: "fora, erro",
    exemplosFrases: [
      {
        exemplo: "アウトになる",
        hiragana: "あうとになる",
        romaji: "Auto ni naru",
        traducao: "Ser eliminado, ser considerado fora.",
      },
      {
        exemplo: "アウトする判定",
        hiragana: "あうとするはんてい",
        romaji: "Auto suru hantei",
        traducao: "Decisão de ser considerado fora.",
      },
      {
        exemplo: "アウトの合図",
        hiragana: "あうとのあいず",
        romaji: "Auto no aizu",
        traducao: "Sinal de fora.",
      },
    ],
  },
  {
    vocabulario: "かっこ",
    romaji: "kakko",
    hiragana: "かっこ",
    traducao: "elegante, legal",
    exemplosFrases: [
      {
        exemplo: "かっこいい車",
        hiragana: "かっこいいくるま",
        romaji: "Kakkoii kuruma",
        traducao: "Carro legal.",
      },
      {
        exemplo: "かっこいいスタイル",
        hiragana: "かっこいいすたいる",
        romaji: "Kakkoii sutairu",
        traducao: "Estilo elegante.",
      },
      {
        exemplo: "かっこいいアクション",
        hiragana: "かっこいいあくしょん",
        romaji: "Kakkoii akushon",
        traducao: "Ação legal.",
      },
    ],
  },
  {
    vocabulario: "運",
    romaji: "un",
    hiragana: "うん",
    traducao: "sorte, destino",
    exemplosFrases: [
      {
        exemplo: "運がいい",
        hiragana: "うんがいい",
        romaji: "Un ga ii",
        traducao: "Ter sorte.",
      },
      {
        exemplo: "運を味方にする",
        hiragana: "うんをみかたにする",
        romaji: "Un o mikata ni suru",
        traducao: "Ter a sorte a seu favor.",
      },
      {
        exemplo: "運が悪い日",
        hiragana: "うんがわるいひ",
        romaji: "Un ga warui hi",
        traducao: "Dia de azar.",
      },
    ],
  },
  {
    vocabulario: "ブレーキ",
    romaji: "burēki",
    hiragana: "ぶれーき",
    traducao: "freio",
    exemplosFrases: [
      {
        exemplo: "ブレーキを踏む",
        hiragana: "ぶれーきをふむ",
        romaji: "Burēki o fumu",
        traducao: "Pisar no freio.",
      },
      {
        exemplo: "ブレーキの効きが悪い",
        hiragana: "ぶれーきのききがわるい",
        romaji: "Burēki no kiki ga warui",
        traducao: "Freio com mau funcionamento.",
      },
      {
        exemplo: "ブレーキパッド",
        hiragana: "ぶれーきぱっど",
        romaji: "Burēki paddo",
        traducao: "Pastilha de freio.",
      },
    ],
  },
  {
    vocabulario: "フルーツ",
    romaji: "furūtsu",
    hiragana: "ふるーつ",
    traducao: "frutas",
    exemplosFrases: [
      {
        exemplo: "フルーツのバスケット",
        hiragana: "ふるーつのばすけっと",
        romaji: "Furūtsu no basuketto",
        traducao: "Cesta de frutas.",
      },
      {
        exemplo: "フルーツジュース",
        hiragana: "ふるーつじゅーす",
        romaji: "Furūtsu jūsu",
        traducao: "Suco de frutas.",
      },
      {
        exemplo: "フルーツの味",
        hiragana: "ふるーつのあじ",
        romaji: "Furūtsu no aji",
        traducao: "Sabor das frutas.",
      },
    ],
  },
  {
    vocabulario: "煙",
    romaji: "kemuri",
    hiragana: "けむり",
    traducao: "fumaça",
    exemplosFrases: [
      {
        exemplo: "煙が出る",
        hiragana: "けむりがでる",
        romaji: "Kemuri ga deru",
        traducao: "Sair fumaça.",
      },
      {
        exemplo: "煙突",
        hiragana: "えんとつ",
        romaji: "Entotsu",
        traducao: "Chaminé.",
      },
      {
        exemplo: "煙草を吸う",
        hiragana: "たばこをすう",
        romaji: "Tabako o suu",
        traducao: "Fumar um cigarro.",
      },
    ],
  },
  {
    vocabulario: "スタッフ",
    romaji: "sutaffu",
    hiragana: "すたっふ",
    traducao: "equipe, staff",
    exemplosFrases: [
      {
        exemplo: "スタッフ一同",
        hiragana: "すたっふいちどう",
        romaji: "Sutaffu ichidō",
        traducao: "Todos os membros da equipe.",
      },
      {
        exemplo: "スタッフメンバー",
        hiragana: "すたっふめんばー",
        romaji: "Sutaffu menbā",
        traducao: "Membro da equipe.",
      },
      {
        exemplo: "スタッフ募集中",
        hiragana: "すたっふぼしゅうちゅう",
        romaji: "Sutaffu boshū chū",
        traducao: "Recrutamento de equipe em andamento.",
      },
    ],
  },
  {
    vocabulario: "うがい",
    romaji: "ugai",
    hiragana: "うがい",
    traducao: "enxágue bucal, gargarejo",
    exemplosFrases: [
      {
        exemplo: "うがいをする",
        hiragana: "うがいをする",
        romaji: "Ugai o suru",
        traducao: "Fazer gargarejo.",
      },
      {
        exemplo: "うがい薬",
        hiragana: "うがいぐすり",
        romaji: "Ugai gusuri",
        traducao: "Enxaguante bucal.",
      },
      {
        exemplo: "うがいの効果",
        hiragana: "うがいのこうか",
        romaji: "Ugai no kōka",
        traducao: "Efeito do gargarejo.",
      },
    ],
  },
  {
    vocabulario: "アイス",
    romaji: "aisu",
    hiragana: "あいす",
    traducao: "sorvete",
    exemplosFrases: [
      {
        exemplo: "アイスクリーム",
        hiragana: "あいすくりーむ",
        romaji: "Aisu kurīmu",
        traducao: "Sorvete",
      },
      {
        exemplo: "アイスコーヒー",
        hiragana: "あいすこーひー",
        romaji: "Aisu kōhī",
        traducao: "Café gelado",
      },
      {
        exemplo: "アイスキャンディー",
        hiragana: "あいすきゃんでぃー",
        romaji: "Aisu kyandī",
        traducao: "Picolé",
      },
    ],
  },
  {
    vocabulario: "一般",
    romaji: "ippan",
    hiragana: "いっぱん",
    traducao: "geral, comum",
    exemplosFrases: [
      {
        exemplo: "一般的な質問",
        hiragana: "いっぱんてきなしつもん",
        romaji: "Ippanteki na shitsumon",
        traducao: "Pergunta comum",
      },
      {
        exemplo: "一般人",
        hiragana: "いっぱんじん",
        romaji: "Ippanjin",
        traducao: "Pessoa comum",
      },
      {
        exemplo: "一般教養",
        hiragana: "いっぱんきょうよう",
        romaji: "Ippan kyōyō",
        traducao: "Educação geral",
      },
    ],
  },
  {
    vocabulario: "だいたい",
    romaji: "daitai",
    hiragana: "だいたい",
    traducao: "em geral, aproximadamente",
    exemplosFrases: [
      {
        exemplo: "だいたいの時間",
        hiragana: "だいたいのじかん",
        romaji: "Daitai no jikan",
        traducao: "Tempo aproximado",
      },
      {
        exemplo: "だいたいの予想",
        hiragana: "だいたいのよそう",
        romaji: "Daitai no yosō",
        traducao: "Previsão geral",
      },
      {
        exemplo: "だいたいの量",
        hiragana: "だいたいのりょう",
        romaji: "Daitai no ryō",
        traducao: "Quantidade aproximada",
      },
    ],
  },
  {
    vocabulario: "ゴルフ",
    romaji: "gorufu",
    hiragana: "ごるふ",
    traducao: "golfe",
    exemplosFrases: [
      {
        exemplo: "ゴルフをする",
        hiragana: "ごるふをする",
        romaji: "Gorufu o suru",
        traducao: "Jogar golfe",
      },
      {
        exemplo: "ゴルフ場",
        hiragana: "ごるふじょう",
        romaji: "Gorufu jō",
        traducao: "Campo de golfe",
      },
      {
        exemplo: "ゴルフクラブ",
        hiragana: "ごるふくらぶ",
        romaji: "Gorufu kurabu",
        traducao: "Clube de golfe",
      },
    ],
  },
  {
    vocabulario: "グルメ",
    romaji: "gurume",
    hiragana: "ぐるめ",
    traducao: "gastronomia, gourmet",
    exemplosFrases: [
      {
        exemplo: "グルメな料理",
        hiragana: "ぐるめなりょうり",
        romaji: "Gurume na ryōri",
        traducao: "Prato gourmet",
      },
      {
        exemplo: "グルメ情報",
        hiragana: "ぐるめじょうほう",
        romaji: "Gurume jōhō",
        traducao: "Informação gastronômica",
      },
      {
        exemplo: "グルメツアー",
        hiragana: "ぐるめつあー",
        romaji: "Gurume tsuā",
        traducao: "Tour gastronômico",
      },
    ],
  },
  {
    vocabulario: "ほど",
    romaji: "hodo",
    hiragana: "ほど",
    traducao: "cerca de, aproximadamente",
    exemplosFrases: [
      {
        exemplo: "ほど遠い場所",
        hiragana: "ほどとおいばしょ",
        romaji: "Hodo tōi basho",
        traducao: "Local aproximadamente distante",
      },
      {
        exemplo: "ほど大きな建物",
        hiragana: "ほどおおきなたてもの",
        romaji: "Hodo ōkina tatemono",
        traducao: "Edifício aproximadamente grande",
      },
      {
        exemplo: "ほど早く到着する",
        hiragana: "ほどはやくとうちゃくする",
        romaji: "Hodo hayaku tōchaku suru",
        traducao: "Chegar aproximadamente cedo",
      },
    ],
  },
  {
    vocabulario: "グループ",
    romaji: "gurūpu",
    hiragana: "ぐるーぷ",
    traducao: "grupo",
    exemplosFrases: [
      {
        exemplo: "グループ活動",
        hiragana: "ぐるーぷかつどう",
        romaji: "Gurūpu katsudō",
        traducao: "Atividade em grupo",
      },
      {
        exemplo: "グループメンバー",
        hiragana: "ぐるーぷめんばー",
        romaji: "Gurūpu menbā",
        traducao: "Membro do grupo",
      },
      {
        exemplo: "グループディスカッション",
        hiragana: "ぐるーぷでぃすかっしょん",
        romaji: "Gurūpu disukasshon",
        traducao: "Discussão em grupo",
      },
    ],
  },
  {
    vocabulario: "ハンバーガー",
    romaji: "hanbāgā",
    hiragana: "はんばーがー",
    traducao: "hambúrguer",
    exemplosFrases: [
      {
        exemplo: "ハンバーガーセット",
        hiragana: "はんばーがーせっと",
        romaji: "Hanbāgā setto",
        traducao: "Combo de hambúrguer",
      },
      {
        exemplo: "ハンバーガーレストラン",
        hiragana: "はんばーがーれすとらん",
        romaji: "Hanbāgā resutoran",
        traducao: "Restaurante de hambúrguer",
      },
      {
        exemplo: "ハンバーガーを食べる",
        hiragana: "はんばーがーをたべる",
        romaji: "Hanbāgā o taberu",
        traducao: "Comer hambúrguer",
      },
    ],
  },
  {
    vocabulario: "ホームステイ",
    romaji: "hōmusutei",
    hiragana: "ほーむすてい",
    traducao: "homestay, estadia em família",
    exemplosFrases: [
      {
        exemplo: "ホームステイ先",
        hiragana: "ほーむすていさき",
        romaji: "Hōmusutei saki",
        traducao: "Família anfitriã",
      },
      {
        exemplo: "ホームステイの経験",
        hiragana: "ほーむすていのけいけん",
        romaji: "Hōmusutei no keiken",
        traducao: "Experiência de estadia em família",
      },
      {
        exemplo: "ホームステイプログラム",
        hiragana: "ほーむすていぷろぐらむ",
        romaji: "Hōmusutei puroguramu",
        traducao: "Programa de homestay",
      },
    ],
  },
  {
    vocabulario: "スズメバチ",
    romaji: "suzumebachi",
    hiragana: "すずめばち",
    traducao: "vespa",
    exemplosFrases: [
      {
        exemplo: "スズメバチの巣",
        hiragana: "すずめばちのす",
        romaji: "Suzumebachi no su",
        traducao: "Ninho de vespas",
      },
      {
        exemplo: "スズメバチに刺される",
        hiragana: "すずめばちにさされる",
        romaji: "Suzumebachi ni sasareru",
        traducao: "Ser picado por uma vespa",
      },
      {
        exemplo: "スズメバチの攻撃",
        hiragana: "すずめばちのこうげき",
        romaji: "Suzumebachi no kōgeki",
        traducao: "Ataque de vespas",
      },
    ],
  },
  {
    vocabulario: "ユーモア",
    romaji: "yūmoa",
    hiragana: "ゆーもあ",
    traducao: "humor",
    exemplosFrases: [
      {
        exemplo: "ユーモアのセンス",
        hiragana: "ゆーもあのせんす",
        romaji: "Yūmoa no sensu",
        traducao: "Senso de humor",
      },
      {
        exemplo: "ユーモアなジョーク",
        hiragana: "ゆーもあなじょーく",
        romaji: "Yūmoa na jōku",
        traducao: "Piada engraçada",
      },
      {
        exemplo: "ユーモアのある人",
        hiragana: "ゆーもあのあるひと",
        romaji: "Yūmoa no aru hito",
        traducao: "Pessoa engraçada",
      },
    ],
  },
  {
    vocabulario: "ヨット",
    romaji: "yotto",
    hiragana: "よっと",
    traducao: "iate, veleiro",
    exemplosFrases: [
      {
        exemplo: "ヨットに乗る",
        hiragana: "よっとにのる",
        romaji: "Yotto ni noru",
        traducao: "Andar de iate",
      },
      {
        exemplo: "ヨット競技",
        hiragana: "よっときょうぎ",
        romaji: "Yotto kyōgi",
        traducao: "Competição de iatismo",
      },
      {
        exemplo: "ヨットクラブ",
        hiragana: "よっとくらぶ",
        romaji: "Yotto kurabu",
        traducao: "Clube de iatismo",
      },
    ],
  },
  {
    vocabulario: "アイディア",
    romaji: "aidia",
    hiragana: "あいでぃあ",
    traducao: "ideia",
    exemplosFrases: [
      {
        exemplo: "アイディアを出す",
        hiragana: "あいでぃあをだす",
        romaji: "Aidia o dasu",
        traducao: "Dar ideias",
      },
      {
        exemplo: "アイディアが浮かぶ",
        hiragana: "あいでぃあがうかぶ",
        romaji: "Aidia ga ukabu",
        traducao: "Ter uma ideia",
      },
      {
        exemplo: "アイディアを活かす",
        hiragana: "あいでぃあをいかす",
        romaji: "Aidia o ikasu",
        traducao: "Aproveitar as ideias",
      },
    ],
  },
  {
    vocabulario: "案",
    romaji: "an",
    hiragana: "あん",
    traducao: "ideia, proposta",
    exemplosFrases: [
      {
        exemplo: "案を考える",
        hiragana: "あんをかんがえる",
        romaji: "An o kangaeru",
        traducao: "Pensar em uma ideia",
      },
      {
        exemplo: "案を出す",
        hiragana: "あんをだす",
        romaji: "An o dasu",
        traducao: "Propor uma ideia",
      },
      {
        exemplo: "案が通る",
        hiragana: "あんがとおる",
        romaji: "An ga tōru",
        traducao: "A ideia é aceita",
      },
    ],
  },
  {
    vocabulario: "バカ",
    romaji: "baka",
    hiragana: "ばか",
    traducao: "idiota, tolo",
    exemplosFrases: [
      {
        exemplo: "バカなことをする",
        hiragana: "ばかなことをする",
        romaji: "Baka na koto o suru",
        traducao: "Fazer coisas idiotas",
      },
      {
        exemplo: "バカにされる",
        hiragana: "ばかにされる",
        romaji: "Baka ni sareru",
        traducao: "Ser ridicularizado",
      },
      {
        exemplo: "バカな冗談",
        hiragana: "ばかなじょうだん",
        romaji: "Baka na jōdan",
        traducao: "Piada tola",
      },
    ],
  },
  {
    vocabulario: "画像",
    romaji: "gazō",
    hiragana: "がぞう",
    traducao: "imagem",
    exemplosFrases: [
      {
        exemplo: "画像を表示する",
        hiragana: "がぞうをひょうじする",
        romaji: "Gazō o hyōji suru",
        traducao: "Mostrar uma imagem",
      },
      {
        exemplo: "画像処理",
        hiragana: "がぞうしょり",
        romaji: "Gazō shori",
        traducao: "Processamento de imagem",
      },
      {
        exemplo: "画像ファイル",
        hiragana: "がぞうふぁいる",
        romaji: "Gazō fairu",
        traducao: "Arquivo de imagem",
      },
    ],
  },
  {
    vocabulario: "印刷",
    romaji: "insatsu",
    hiragana: "いんさつ",
    traducao: "impressão",
    exemplosFrases: [
      {
        exemplo: "印刷する",
        hiragana: "いんさつする",
        romaji: "Insatsu suru",
        traducao: "Imprimir",
      },
      {
        exemplo: "印刷物",
        hiragana: "いんさつぶつ",
        romaji: "Insatsu butsu",
        traducao: "Material impresso",
      },
      {
        exemplo: "印刷会社",
        hiragana: "いんさつがいしゃ",
        romaji: "Insatsu gaisha",
        traducao: "Empresa de impressão",
      },
    ],
  },
  {
    vocabulario: "印象",
    romaji: "inshō",
    hiragana: "いんしょう",
    traducao: "impressão",
    exemplosFrases: [
      {
        exemplo: "印象に残る",
        hiragana: "いんしょうにのこる",
        romaji: "Inshō ni nokoru",
        traducao: "Permanecer na memória",
      },
      {
        exemplo: "印象的な光景",
        hiragana: "いんしょうてきなこうけい",
        romaji: "Inshōteki na kōkei",
        traducao: "Cena impressionante",
      },
      {
        exemplo: "印象を与える",
        hiragana: "いんしょうをあたえる",
        romaji: "Inshō o ataeru",
        traducao: "Causar uma impressão",
      },
    ],
  },
  {
    vocabulario: "影響",
    romaji: "eikyou",
    hiragana: "えいきょう",
    traducao: "influência",
    exemplosFrases: [
      {
        exemplo: "影響を与える",
        hiragana: "えいきょうをあたえる",
        romaji: "Eikyou o ataeru",
        traducao: "Exercer influência",
      },
      {
        exemplo: "影響を受ける",
        hiragana: "えいきょうをうける",
        romaji: "Eikyou o ukeru",
        traducao: "Ser influenciado",
      },
      {
        exemplo: "影響力がある",
        hiragana: "えいきょうりょくがある",
        romaji: "Eikyouryoku ga aru",
        traducao: "Ter influência",
      },
    ],
  },
  {
    vocabulario: "意思",
    romaji: "ishi",
    hiragana: "いし",
    traducao: "intenção, vontade",
    exemplosFrases: [
      {
        exemplo: "意思表示",
        hiragana: "いしひょうじ",
        romaji: "Ishi hyōji",
        traducao: "Expressão de intenção",
      },
      {
        exemplo: "意思決定",
        hiragana: "いしけってい",
        romaji: "Ishi kettei",
        traducao: "Tomada de decisão",
      },
      {
        exemplo: "意思疎通",
        hiragana: "いしそつう",
        romaji: "Ishi sotsū",
        traducao: "Comunicação de intenções",
      },
    ],
  },
  {
    vocabulario: "奥",
    romaji: "oku",
    hiragana: "おく",
    traducao: "fundo, interior",
    exemplosFrases: [
      {
        exemplo: "奥にある",
        hiragana: "おくにある",
        romaji: "Oku ni aru",
        traducao: "Estar no fundo",
      },
      {
        exemplo: "奥の部屋",
        hiragana: "おくのへや",
        romaji: "Oku no heya",
        traducao: "Quarto interno",
      },
      {
        exemplo: "奥の深さ",
        hiragana: "おくのふかさ",
        romaji: "Oku no fukasa",
        traducao: "Profundidade",
      },
    ],
  },
  {
    vocabulario: "スイッチ",
    romaji: "suicchi",
    hiragana: "すいっち",
    traducao: "interruptor",
    exemplosFrases: [
      {
        exemplo: "スイッチを入れる",
        hiragana: "すいっちをいれる",
        romaji: "Suicchi o ireru",
        traducao: "Ligar o interruptor",
      },
      {
        exemplo: "スイッチを切る",
        hiragana: "すいっちをきる",
        romaji: "Suicchi o kiru",
        traducao: "Desligar o interruptor",
      },
      {
        exemplo: "スイッチの位置",
        hiragana: "すいっちのいち",
        romaji: "Suicchi no ichi",
        traducao: "Posição do interruptor",
      },
    ],
  },
  {
    vocabulario: "ヨーグルト",
    romaji: "yōguruto",
    hiragana: "よーぐると",
    traducao: "iogurte",
    exemplosFrases: [
      {
        exemplo: "ヨーグルトを食べる",
        hiragana: "よーぐるとをたべる",
        romaji: "Yōguruto o taberu",
        traducao: "Comer iogurte",
      },
      {
        exemplo: "ヨーグルトの味",
        hiragana: "よーぐるとのあじ",
        romaji: "Yōguruto no aji",
        traducao: "Sabor do iogurte",
      },
      {
        exemplo: "ヨーグルトの種類",
        hiragana: "よーぐるとのしゅるい",
        romaji: "Yōguruto no shurui",
        traducao: "Tipos de iogurte",
      },
    ],
  },
  {
    vocabulario: "いらいら",
    romaji: "iraira",
    hiragana: "いらいら",
    traducao: "irritação, impaciência",
    exemplosFrases: [
      {
        exemplo: "いらいらする",
        hiragana: "いらいらする",
        romaji: "Iraira suru",
        traducao: "Sentir-se irritado",
      },
      {
        exemplo: "いらいらさせる",
        hiragana: "いらいらさせる",
        romaji: "Iraira saseru",
        traducao: "Fazer alguém se irritar",
      },
      {
        exemplo: "いらいらした気分",
        hiragana: "いらいらしたきぶん",
        romaji: "Iraira shita kibun",
        traducao: "Sentimento de irritação",
      },
    ],
  },
  {
    vocabulario: "ディナー",
    romaji: "dinā",
    hiragana: "でぃなー",
    traducao: "jantar",
    exemplosFrases: [
      {
        exemplo: "ディナーを食べる",
        hiragana: "でぃなーをたべる",
        romaji: "Dinā o taberu",
        traducao: "Jantar",
      },
      {
        exemplo: "ディナーの予約",
        hiragana: "でぃなーのよやく",
        romaji: "Dinā no yoyaku",
        traducao: "Reserva de jantar",
      },
      {
        exemplo: "ディナーのメニュー",
        hiragana: "でぃなーのめにゅー",
        romaji: "Dinā no menyū",
        traducao: "Cardápio do jantar",
      },
    ],
  },
  {
    vocabulario: "ガーデニング",
    romaji: "gādeningu",
    hiragana: "がーでにんぐ",
    traducao: "jardinagem",
    exemplosFrases: [
      {
        exemplo: "ガーデニングをする",
        hiragana: "がーでにんぐをする",
        romaji: "Gādeningu o suru",
        traducao: "Fazer jardinagem",
      },
      {
        exemplo: "ガーデニング用具",
        hiragana: "がーでにんぐようぐ",
        romaji: "Gādeningu yōgu",
        traducao: "Ferramentas de jardinagem",
      },
      {
        exemplo: "ガーデニングの楽しみ",
        hiragana: "がーでにんぐのたのしみ",
        romaji: "Gādeningu no tanoshimi",
        traducao: "Alegria da jardinagem",
      },
    ],
  },
  {
    vocabulario: "ピッチャー",
    romaji: "pitchā",
    hiragana: "ぴっちゃー",
    traducao: "jarro, pitcher",
    exemplosFrases: [
      {
        exemplo: "ピッチャーに水を注ぐ",
        hiragana: "ぴっちゃーにみずをそそぐ",
        romaji: "Pitchā ni mizu o sosogu",
        traducao: "Despejar água no jarro",
      },
      {
        exemplo: "ピッチャーが投げる",
        hiragana: "ぴっちゃーがなげる",
        romaji: "Pitchā ga nageru",
        traducao: "O pitcher lança a bola",
      },
      {
        exemplo: "ピッチャーの役割",
        hiragana: "ぴっちゃーのやくわり",
        romaji: "Pitchā no yakuwari",
        traducao: "Papel do pitcher",
      },
    ],
  },
  {
    vocabulario: "ジーンズ",
    romaji: "jīnzu",
    hiragana: "じーんず",
    traducao: "jeans",
    exemplosFrases: [
      {
        exemplo: "ジーンズを履く",
        hiragana: "じーんずをはく",
        romaji: "Jīnzu o haku",
        traducao: "Vestir jeans",
      },
      {
        exemplo: "ジーンズの色",
        hiragana: "じーんずのいろ",
        romaji: "Jīnzu no iro",
        traducao: "Cor dos jeans",
      },
      {
        exemplo: "ジーンズのブランド",
        hiragana: "じーんずのぶらんど",
        romaji: "Jīnzu no burando",
        traducao: "Marca de jeans",
      },
    ],
  },
  {
    vocabulario: "演奏",
    romaji: "ensou",
    hiragana: "えんそう",
    traducao: "performance, execução",
    exemplosFrases: [
      {
        exemplo: "ピアノを演奏する",
        hiragana: "ぴあのをえんそうする",
        romaji: "Piano o ensou suru",
        traducao: "Tocar piano",
      },
      {
        exemplo: "演奏会を開く",
        hiragana: "えんそうかいをひらく",
        romaji: "Ensoukai o hiraku",
        traducao: "Realizar um concerto",
      },
      {
        exemplo: "楽器の演奏",
        hiragana: "がっきのえんそう",
        romaji: "Gakki no ensou",
        traducao: "Execução de instrumento musical",
      },
    ],
  },
  {
    vocabulario: "ゲーム",
    romaji: "gēmu",
    hiragana: "げーむ",
    traducao: "jogo",
    exemplosFrases: [
      {
        exemplo: "ゲームをする",
        hiragana: "げーむをする",
        romaji: "Gēmu o suru",
        traducao: "Jogar um jogo",
      },
      {
        exemplo: "ビデオゲーム",
        hiragana: "びでおげーむ",
        romaji: "Bideo gēmu",
        traducao: "Jogo de vídeo",
      },
      {
        exemplo: "ゲームセンター",
        hiragana: "げーむせんたー",
        romaji: "Gēmu sentā",
        traducao: "Centro de jogos",
      },
    ],
  },
  {
    vocabulario: "ランチ",
    romaji: "ranchi",
    hiragana: "らんち",
    traducao: "almoço",
    exemplosFrases: [
      {
        exemplo: "ランチを食べる",
        hiragana: "らんちをたべる",
        romaji: "Ranchi o taberu",
        traducao: "Almoçar",
      },
      {
        exemplo: "ランチメニュー",
        hiragana: "らんちめにゅー",
        romaji: "Ranchi menyū",
        traducao: "Cardápio de almoço",
      },
      {
        exemplo: "ランチタイム",
        hiragana: "らんちたいむ",
        romaji: "Ranchi taimu",
        traducao: "Hora do almoço",
      },
    ],
  },
  {
    vocabulario: "ミルク",
    romaji: "miruku",
    hiragana: "みるく",
    traducao: "leite",
    exemplosFrases: [
      {
        exemplo: "ミルクを飲む",
        hiragana: "みるくをのむ",
        romaji: "Miruku o nomu",
        traducao: "Beber leite",
      },
      {
        exemplo: "ミルクティー",
        hiragana: "みるくてぃー",
        romaji: "Miruku tī",
        traducao: "Chá com leite",
      },
      {
        exemplo: "ミルクの味",
        hiragana: "みるくのあじ",
        romaji: "Miruku no aji",
        traducao: "Sabor do leite",
      },
    ],
  },
  {
    vocabulario: "レンズ",
    romaji: "renzu",
    hiragana: "れんず",
    traducao: "lente",
    exemplosFrases: [
      {
        exemplo: "カメラのレンズ",
        hiragana: "かめらのれんず",
        romaji: "Kamera no renzu",
        traducao: "Lente da câmera",
      },
      {
        exemplo: "メガネのレンズ",
        hiragana: "めがねのれんず",
        romaji: "Megane no renzu",
        traducao: "Lente dos óculos",
      },
      {
        exemplo: "コンタクトレンズ",
        hiragana: "こんたくとれんず",
        romaji: "Kontakuto renzu",
        traducao: "Lente de contato",
      },
    ],
  },
  {
    vocabulario: "課",
    romaji: "ka",
    hiragana: "か",
    traducao: "departamento, lição",
    exemplosFrases: [
      {
        exemplo: "課の仕事",
        hiragana: "かのしごと",
        romaji: "Ka no shigoto",
        traducao: "Trabalho do departamento",
      },
      {
        exemplo: "課のメンバー",
        hiragana: "かのめんばー",
        romaji: "Ka no menbā",
        traducao: "Membros do departamento",
      },
      {
        exemplo: "課題を解く",
        hiragana: "かだいをとく",
        romaji: "Kadai o toku",
        traducao: "Resolver uma lição",
      },
    ],
  },
  {
    vocabulario: "ネット",
    romaji: "netto",
    hiragana: "ねっと",
    traducao: "internet",
    exemplosFrases: [
      {
        exemplo: "ネットを使う",
        hiragana: "ねっとをつかう",
        romaji: "Netto o tsukau",
        traducao: "Usar a internet",
      },
      {
        exemplo: "ネットサーフィン",
        hiragana: "ねっとさーふぃん",
        romaji: "Netto sāfin",
        traducao: "Navegar na internet",
      },
      {
        exemplo: "ネットワーク",
        hiragana: "ねっとわーく",
        romaji: "Nettowāku",
        traducao: "Rede de internet",
      },
    ],
  },
  {
    vocabulario: "リットル",
    romaji: "rittoru",
    hiragana: "りっとる",
    traducao: "litro",
    exemplosFrases: [
      {
        exemplo: "1リットルの水",
        hiragana: "1りっとるのみず",
        romaji: "Ichi rittoru no mizu",
        traducao: "1 litro de água",
      },
      {
        exemplo: "リットルボトル",
        hiragana: "りっとるぼとる",
        romaji: "Rittoru botoru",
        traducao: "Garrafa de litro",
      },
      {
        exemplo: "リットルの牛乳",
        hiragana: "りっとるのぎゅうにゅう",
        romaji: "Rittoru no gyuunyuu",
        traducao: "Leite em litro",
      },
    ],
  },
  {
    vocabulario: "位置",
    romaji: "ichi",
    hiragana: "いち",
    traducao: "posição",
    exemplosFrases: [
      {
        exemplo: "その建物の位置を確認してください。",
        hiragana: "そのたてもののいちをかくにんしてください。",
        romaji: "Sono tatemono no ichi o kakunin shite kudasai.",
        traducao: "Por favor, verifique a posição do prédio.",
      },
      {
        exemplo: "位置情報を設定する",
        hiragana: "いちじょうほうをせっていする",
        romaji: "Ichi jōhō o settei suru",
        traducao: "Definir informações de localização",
      },
      {
        exemplo: "地図で位置を確認する",
        hiragana: "ちずでいちをかくにんする",
        romaji: "Chizu de ichi o kakunin suru",
        traducao: "Verificar a posição no mapa",
      },
    ],
  },
  {
    vocabulario: "猿",
    romaji: "saru",
    hiragana: "さる",
    traducao: "macaco",
    exemplosFrases: [
      {
        exemplo: "猿が木に登っています。",
        hiragana: "さるがきにのぼっています。",
        romaji: "Saru ga ki ni nobotteimasu.",
        traducao: "O macaco está subindo na árvore.",
      },
      {
        exemplo: "猿の赤ちゃん",
        hiragana: "さるのあかちゃん",
        romaji: "Saru no akachan",
        traducao: "Filhote de macaco",
      },
      {
        exemplo: "猿たちが遊んでいます。",
        hiragana: "さるたちがあそんでいます。",
        romaji: "Saru-tachi ga asondeimasu.",
        traducao: "Os macacos estão brincando.",
      },
    ],
  },
  {
    vocabulario: "ライター",
    romaji: "raitā",
    hiragana: "らいたー",
    traducao: "isqueiro",
    exemplosFrases: [
      {
        exemplo: "ライターで火をつける",
        hiragana: "らいたーでひをつける",
        romaji: "Raitā de hi o tsukeru",
        traducao: "Acender com um isqueiro",
      },
      {
        exemplo: "ライターを持っていく",
        hiragana: "らいたーをもっていく",
        romaji: "Raitā o motte iku",
        traducao: "Levar um isqueiro",
      },
      {
        exemplo: "ライターのガスを補充する",
        hiragana: "らいたーのがすをほじゅうする",
        romaji: "Raitā no gasu o hojū suru",
        traducao: "Reabastecer o gás do isqueiro",
      },
    ],
  },
  {
    vocabulario: "プラス",
    romaji: "purasu",
    hiragana: "ぷらす",
    traducao: "mais, positivo",
    exemplosFrases: [
      {
        exemplo: "プラスの効果",
        hiragana: "ぷらすのこうか",
        romaji: "Purasu no kōka",
        traducao: "Efeito positivo",
      },
      {
        exemplo: "プラスとマイナス",
        hiragana: "ぷらすとまいなす",
        romaji: "Purasu to mainasu",
        traducao: "Mais e menos",
      },
      {
        exemplo: "プラスの要素",
        hiragana: "ぷらすのようそ",
        romaji: "Purasu no yōso",
        traducao: "Elemento positivo",
      },
    ],
  },
  {
    vocabulario: "ママ",
    romaji: "mama",
    hiragana: "まま",
    traducao: "mamãe, mãe",
    exemplosFrases: [
      {
        exemplo: "ママとお買い物に行く",
        hiragana: "ままとおかいものにいく",
        romaji: "Mama to o-kaimono ni iku",
        traducao: "Ir fazer compras com a mamãe",
      },
      {
        exemplo: "ママの手料理",
        hiragana: "ままのてりょうり",
        romaji: "Mama no te-ryōri",
        traducao: "Comida caseira da mamãe",
      },
      {
        exemplo: "ママ友とランチする",
        hiragana: "ままともとらんちする",
        romaji: "Mama-tomo to ranchi suru",
        traducao: "Almoçar com as amigas mães",
      },
    ],
  },
  {
    vocabulario: "マンション",
    romaji: "manshon",
    hiragana: "まんしょん",
    traducao: "apartamento",
    exemplosFrases: [
      {
        exemplo: "マンションに住む",
        hiragana: "まんしょんにすむ",
        romaji: "Manshon ni sumu",
        traducao: "Morar em um apartamento",
      },
      {
        exemplo: "高級マンション",
        hiragana: "こうきゅうまんしょん",
        romaji: "Kōkyū manshon",
        traducao: "Apartamento de luxo",
      },
      {
        exemplo: "マンションの管理人",
        hiragana: "まんしょんのかんりにん",
        romaji: "Manshon no kanrinin",
        traducao: "Zelador do prédio",
      },
    ],
  },
  {
    vocabulario: "維持",
    romaji: "iji",
    hiragana: "いじ",
    traducao: "manutenção, sustentação",
    exemplosFrases: [
      {
        exemplo: "健康を維持する",
        hiragana: "けんこうをいじする",
        romaji: "Kenkō o iji suru",
        traducao: "Manter a saúde",
      },
      {
        exemplo: "維持費",
        hiragana: "いじひ",
        romaji: "Ijihi",
        traducao: "Custos de manutenção",
      },
      {
        exemplo: "体重を維持する",
        hiragana: "たいじゅうをいじする",
        romaji: "Taijū o iji suru",
        traducao: "Manter o peso",
      },
    ],
  },
  {
    vocabulario: "化粧",
    romaji: "keshō",
    hiragana: "けしょう",
    traducao: "maquiagem",
    exemplosFrases: [
      {
        exemplo: "化粧をする",
        hiragana: "けしょうをする",
        romaji: "Keshō o suru",
        traducao: "Fazer maquiagem",
      },
      {
        exemplo: "化粧品",
        hiragana: "けしょうひん",
        romaji: "Keshōhin",
        traducao: "Cosméticos",
      },
      {
        exemplo: "化粧のテクニック",
        hiragana: "けしょうのてくにっく",
        romaji: "Keshō no tekunikku",
        traducao: "Técnicas de maquiagem",
      },
    ],
  },
  {
    vocabulario: "沖",
    romaji: "oki",
    hiragana: "おき",
    traducao: "mar aberto",
    exemplosFrases: [
      {
        exemplo: "船が沖に出る",
        hiragana: "ふねがおきにでる",
        romaji: "Fune ga oki ni deru",
        traducao: "O barco parte para o mar aberto",
      },
      {
        exemplo: "沖で釣りをする",
        hiragana: "おきでつりをする",
        romaji: "Oki de tsuri o suru",
        traducao: "Pescar no mar aberto",
      },
      {
        exemplo: "沖に浮かぶ島",
        hiragana: "おきにうかぶしま",
        romaji: "Oki ni ukabu shima",
        traducao: "Uma ilha flutuante no mar aberto",
      },
    ],
  },
  {
    vocabulario: "マスク",
    romaji: "masuku",
    hiragana: "ますく",
    traducao: "máscara",
    exemplosFrases: [
      {
        exemplo: "マスクを着用する",
        hiragana: "ますくをちゃくようする",
        romaji: "Masuku o chakuyō suru",
        traducao: "Usar máscara",
      },
      {
        exemplo: "マスクを手作りする",
        hiragana: "ますくをてづくりする",
        romaji: "Masuku o tezukuri suru",
        traducao: "Fazer máscara em casa",
      },
      {
        exemplo: "マスクの需要が高まる",
        hiragana: "ますくのじゅようがたかまる",
        romaji: "Masuku no juyō ga takamaru",
        traducao: "A demanda por máscaras está aumentando",
      },
    ],
  },
  {
    vocabulario: "パスタ",
    romaji: "pasuta",
    hiragana: "ぱすた",
    traducao: "massa (culinária)",
    exemplosFrases: [
      {
        exemplo: "パスタを茹でる",
        hiragana: "ぱすたをゆでる",
        romaji: "Pasuta o yuderu",
        traducao: "Cozinhar massa",
      },
      {
        exemplo: "パスタソースを作る",
        hiragana: "ぱすたそーすをつくる",
        romaji: "Pasuta sōsu o tsukuru",
        traducao: "Preparar molho de massa",
      },
      {
        exemplo: "パスタの種類",
        hiragana: "ぱすたのしゅるい",
        romaji: "Pasuta no shurui",
        traducao: "Tipos de massa",
      },
    ],
  },
  {
    vocabulario: "メダル",
    romaji: "medaru",
    hiragana: "めだる",
    traducao: "medalha",
    exemplosFrases: [
      {
        exemplo: "金メダルを獲得する",
        hiragana: "きんめだるをかくとくする",
        romaji: "Kin medaru o kakutoku suru",
        traducao: "Ganhar uma medalha de ouro",
      },
      {
        exemplo: "メダル授与式",
        hiragana: "めだるじゅよしき",
        romaji: "Medaru juyōshiki",
        traducao: "Cerimônia de entrega de medalhas",
      },
      {
        exemplo: "オリンピックのメダル",
        hiragana: "おりんぴっくのめだる",
        romaji: "Orinpikku no medaru",
        traducao: "Medalha olímpica",
      },
    ],
  },
  {
    vocabulario: "ロケット",
    romaji: "roketto",
    hiragana: "ろけっと",
    traducao: "foguete",
    exemplosFrases: [
      {
        exemplo: "ロケット打ち上げ",
        hiragana: "ろけっとうちあげ",
        romaji: "Roketto uchiage",
        traducao: "Lançamento de foguete",
      },
      {
        exemplo: "宇宙ロケット",
        hiragana: "うちゅうろけっと",
        romaji: "Uchū roketto",
        traducao: "Foguete espacial",
      },
      {
        exemplo: "ロケットの噴射",
        hiragana: "ろけっとのふんしゃ",
        romaji: "Roketto no funsha",
        traducao: "Propulsão do foguete",
      },
    ],
  },
  {
    vocabulario: "医師",
    romaji: "ishi",
    hiragana: "いし",
    traducao: "médico",
    exemplosFrases: [
      {
        exemplo: "医師の診断",
        hiragana: "いしのしんだん",
        romaji: "Ishi no shindan",
        traducao: "Diagnóstico médico",
      },
      {
        exemplo: "医師に相談する",
        hiragana: "いしにそうだんする",
        romaji: "Ishi ni sōdan suru",
        traducao: "Consultar um médico",
      },
      {
        exemplo: "医師の勉強",
        hiragana: "いしのべんきょう",
        romaji: "Ishi no benkyō",
        traducao: "Estudo de medicina",
      },
    ],
  },
  {
    vocabulario: "改善",
    romaji: "kaizen",
    hiragana: "かいぜん",
    traducao: "melhorar, aprimorar",
    exemplosFrases: [
      {
        exemplo: "生活を改善する",
        hiragana: "せいかつをかいぜんする",
        romaji: "Seikatsu o kaizen suru",
        traducao: "Melhorar a qualidade de vida",
      },
      {
        exemplo: "システムを改善する",
        hiragana: "しすてむをかいぜんする",
        romaji: "Shisutemu o kaizen suru",
        traducao: "Aprimorar o sistema",
      },
      {
        exemplo: "改善策を考える",
        hiragana: "かいぜんさくをかんがえる",
        romaji: "Kaizen-saku o kangaeru",
        traducao: "Pensar em soluções de melhoria",
      },
    ],
  },
  {
    vocabulario: "会員",
    romaji: "kaiin",
    hiragana: "かいいん",
    traducao: "membro",
    exemplosFrases: [
      {
        exemplo: "スポーツクラブの会員",
        hiragana: "すぽーつくらぶのかいいん",
        romaji: "Supōtsu kurabu no kaiin",
        traducao: "Membro de um clube esportivo",
      },
      {
        exemplo: "図書館の会員証",
        hiragana: "としょかんのかいいんしょう",
        romaji: "Toshokan no kaiinshō",
        traducao: "Cartão de membro da biblioteca",
      },
      {
        exemplo: "会員特典",
        hiragana: "かいいんとくてん",
        romaji: "Kaiin tokuten",
        traducao: "Benefícios para membros",
      },
    ],
  },
  {
    vocabulario: "委員",
    romaji: "iin",
    hiragana: "いいん",
    traducao: "membro de comitê",
    exemplosFrases: [
      {
        exemplo: "学校の委員会",
        hiragana: "がっこうのいいんかい",
        romaji: "Gakkō no iinkai",
        traducao: "Comitê da escola",
      },
      {
        exemplo: "委員に選ばれる",
        hiragana: "いいんにえらばれる",
        romaji: "Iin ni erabareru",
        traducao: "Ser escolhido para o comitê",
      },
      {
        exemplo: "委員の任務",
        hiragana: "いいんのにんむ",
        romaji: "Iin no ninmu",
        traducao: "Funções do comitê",
      },
    ],
  },
  {
    vocabulario: "メンバー",
    romaji: "menbā",
    hiragana: "めんばー",
    traducao: "membro",
    exemplosFrases: [
      {
        exemplo: "サッカーチームのメンバー",
        hiragana: "さっかーちーむのめんばー",
        romaji: "Sakkā chīmu no menbā",
        traducao: "Membro do time de futebol",
      },
      {
        exemplo: "バンドのメンバー",
        hiragana: "ばんどのめんばー",
        romaji: "Bando no menbā",
        traducao: "Membro da banda",
      },
      {
        exemplo: "クラブのメンバー",
        hiragana: "くらぶのめんばー",
        romaji: "Kurabu no menbā",
        traducao: "Membro do clube",
      },
    ],
  },
  {
    vocabulario: "暗記",
    romaji: "anki",
    hiragana: "あんき",
    traducao: "memorização",
    exemplosFrases: [
      {
        exemplo: "単語を暗記する",
        hiragana: "たんごをあんきする",
        romaji: "Tango o anki suru",
        traducao: "Memorizar palavras",
      },
      {
        exemplo: "詩を暗記する",
        hiragana: "しをあんきする",
        romaji: "Shi o anki suru",
        traducao: "Memorizar poemas",
      },
      {
        exemplo: "数字を暗記する",
        hiragana: "すうじをあんきする",
        romaji: "Sūji o anki suru",
        traducao: "Memorizar números",
      },
    ],
  },
  {
    vocabulario: "嘘",
    romaji: "uso",
    hiragana: "うそ",
    traducao: "mentira",
    exemplosFrases: [
      {
        exemplo: "嘘をつく",
        hiragana: "うそをつく",
        romaji: "Uso o tsuku",
        traducao: "Contar uma mentira",
      },
      {
        exemplo: "嘘を見破る",
        hiragana: "うそをみやぶる",
        romaji: "Uso o miyaburu",
        traducao: "Detectar uma mentira",
      },
      {
        exemplo: "嘘を許す",
        hiragana: "うそをゆるす",
        romaji: "Uso o yurusu",
        traducao: "Perdoar uma mentira",
      },
    ],
  },
  {
    vocabulario: "マーケット",
    romaji: "māketto",
    hiragana: "まーけっと",
    traducao: "mercado",
    exemplosFrases: [
      {
        exemplo: "農産物市場",
        hiragana: "のうさんぶつしじょう",
        romaji: "Nōsanbutsu shijō",
        traducao: "Mercado de produtos agrícolas",
      },
      {
        exemplo: "株式市場",
        hiragana: "かぶしきしじょう",
        romaji: "Kabushiki shijō",
        traducao: "Mercado de ações",
      },
      {
        exemplo: "市場調査",
        hiragana: "しじょうちょうさ",
        romaji: "Shijō chōsa",
        traducao: "Pesquisa de mercado",
      },
    ],
  },
  {
    vocabulario: "ケチ",
    romaji: "kechi",
    hiragana: "けち",
    traducao: "mão de vaca, avarento",
    exemplosFrases: [
      {
        exemplo: "ケチな人",
        hiragana: "けちなひと",
        romaji: "Kechi na hito",
        traducao: "Pessoa avarenta",
      },
      {
        exemplo: "ケチな財布",
        hiragana: "けちなさいふ",
        romaji: "Kechi na saifu",
        traducao: "Carteira de mão de vaca",
      },
      {
        exemplo: "ケチに値段を交渉する",
        hiragana: "けちにねだんをこうしょうする",
        romaji: "Kechi ni nedan o kōshō suru",
        traducao: "Negociar o preço como um avarento",
      },
    ],
  },
  {
    vocabulario: "マイク",
    romaji: "maiku",
    hiragana: "まいく",
    traducao: "microfone",
    exemplosFrases: [
      {
        exemplo: "ステージでマイクを使う",
        hiragana: "すてーじでまいくをつかう",
        romaji: "Sutēji de maiku o tsukau",
        traducao: "Usar o microfone no palco",
      },
      {
        exemplo: "マイクを近づける",
        hiragana: "まいくをちかづける",
        romaji: "Maiku o chikazukeru",
        traducao: "Aproximar o microfone",
      },
      {
        exemplo: "マイクを握る",
        hiragana: "まいくをにぎる",
        romaji: "Maiku o nigiru",
        traducao: "Segurar o microfone",
      },
    ],
  },
  {
    vocabulario: "家具",
    romaji: "kagu",
    hiragana: "かぐ",
    traducao: "móveis",
    exemplosFrases: [
      {
        exemplo: "家具を配置する",
        hiragana: "かぐをはいちする",
        romaji: "Kagu o haichi suru",
        traducao: "Dispor os móveis",
      },
      {
        exemplo: "新しい家具を買う",
        hiragana: "あたらしいかぐをかう",
        romaji: "Atarashii kagu o kau",
        traducao: "Comprar móveis novos",
      },
      {
        exemplo: "家具屋でショッピングする",
        hiragana: "かぐやでしょっぴんぐする",
        romaji: "Kagu-ya de shoppingu suru",
        traducao: "Fazer compras em uma loja de móveis",
      },
    ],
  },
  {
    vocabulario: "ファッション",
    romaji: "fasshon",
    hiragana: "ふぁっしょん",
    traducao: "moda",
    exemplosFrases: [
      {
        exemplo: "最新のファッション",
        hiragana: "さいしんのふぁっしょん",
        romaji: "Saishin no fasshon",
        traducao: "Moda mais recente",
      },
      {
        exemplo: "ファッションショー",
        hiragana: "ふぁっしょんしょー",
        romaji: "Fasshon shō",
        traducao: "Desfile de moda",
      },
      {
        exemplo: "ファッション雑誌",
        hiragana: "ふぁっしょんざっし",
        romaji: "Fasshon zasshi",
        traducao: "Revista de moda",
      },
    ],
  },
  {
    vocabulario: "モデル",
    romaji: "moderu",
    hiragana: "もでる",
    traducao: "modelo",
    exemplosFrases: [
      {
        exemplo: "ファッションモデル",
        hiragana: "ふぁっしょんもでる",
        romaji: "Fasshon moderu",
        traducao: "Modelo de moda",
      },
      {
        exemplo: "カタログモデル",
        hiragana: "かたろぐもでる",
        romaji: "Katarogu moderu",
        traducao: "Modelo de catálogo",
      },
      {
        exemplo: "モデルとして働く",
        hiragana: "もでるとしてはたらく",
        romaji: "Moderu toshite hataraku",
        traducao: "Trabalhar como modelo",
      },
    ],
  },
  {
    vocabulario: "カビ",
    romaji: "kabi",
    hiragana: "かび",
    traducao: "mofo",
    exemplosFrases: [
      {
        exemplo: "壁にカビが生える",
        hiragana: "かべにかびがはえる",
        romaji: "Kabe ni kabi ga haeru",
        traducao: "Mofo crescendo na parede",
      },
      {
        exemplo: "カビの臭い",
        hiragana: "かびのにおい",
        romaji: "Kabi no nioi",
        traducao: "Cheiro de mofo",
      },
      {
        exemplo: "カビ取りスプレー",
        hiragana: "かびとりすぷれー",
        romaji: "Kabi-tori supurē",
        traducao: "Spray antimofo",
      },
    ],
  },
  {
    vocabulario: "一瞬",
    romaji: "isshun",
    hiragana: "いっしゅん",
    traducao: "um instante",
    exemplosFrases: [
      {
        exemplo: "一瞬待ってください",
        hiragana: "いっしゅんまってください",
        romaji: "Isshun matte kudasai",
        traducao: "Aguarde um instante, por favor",
      },
      {
        exemplo: "一瞬で終わる",
        hiragana: "いっしゅんでおわる",
        romaji: "Isshun de owaru",
        traducao: "Acabar em um instante",
      },
      {
        exemplo: "一瞬で気づく",
        hiragana: "いっしゅんできづく",
        romaji: "Isshun de kidzuku",
        traducao: "Perceber em um instante",
      },
    ],
  },
  {
    vocabulario: "蚊",
    romaji: "ka",
    hiragana: "か",
    traducao: "mosquito",
    exemplosFrases: [
      {
        exemplo: "蚊に刺される",
        hiragana: "かにさされる",
        romaji: "Ka ni sasareru",
        traducao: "Ser picado por um mosquito",
      },
      {
        exemplo: "蚊に対策を取る",
        hiragana: "かにたいさくをとる",
        romaji: "Ka ni taisaku o toru",
        traducao: "Tomar medidas contra mosquitos",
      },
      {
        exemplo: "蚊の鳴き声",
        hiragana: "かのなきごえ",
        romaji: "Ka no nakigoe",
        traducao: "Som do mosquito",
      },
    ],
  },
  {
    vocabulario: "エンジン",
    romaji: "enjin",
    hiragana: "えんじん",
    traducao: "motor, engine",
    exemplosFrases: [
      {
        exemplo: "車のエンジン",
        hiragana: "くるまのえんじん",
        romaji: "Kuruma no enjin",
        traducao: "Motor do carro",
      },
      {
        exemplo: "エンジン音",
        hiragana: "えんじんおと",
        romaji: "Enjin on",
        traducao: "Som do motor",
      },
      {
        exemplo: "エンジンの故障",
        hiragana: "えんじんのこしょう",
        romaji: "Enjin no koshō",
        traducao: "Falha no motor",
      },
    ],
  },
  {
    vocabulario: "引っ越し",
    romaji: "hikkoshi",
    hiragana: "ひっこし",
    traducao: "mudança, se mudar",
    exemplosFrases: [
      {
        exemplo: "引っ越しの手続き",
        hiragana: "ひっこしのてつづき",
        romaji: "Hikkoshi no tetsuzuki",
        traducao: "Procedimentos de mudança",
      },
      {
        exemplo: "新しい家に引っ越す",
        hiragana: "あたらしいいえにひっこす",
        romaji: "Atarashii ie ni hikkosu",
        traducao: "Se mudar para uma nova casa",
      },
      {
        exemplo: "引っ越し業者",
        hiragana: "ひっこしぎょうしゃ",
        romaji: "Hikkoshi gyōsha",
        traducao: "Empresa de mudanças",
      },
    ],
  },
  {
    vocabulario: "音楽",
    romaji: "ongaku",
    hiragana: "おんがく",
    traducao: "música",
    exemplosFrases: [
      {
        exemplo: "音楽を聴く",
        hiragana: "おんがくをきく",
        romaji: "Ongaku o kiku",
        traducao: "Ouvir música",
      },
      {
        exemplo: "音楽の才能",
        hiragana: "おんがくのさいのう",
        romaji: "Ongaku no sainō",
        traducao: "Talento musical",
      },
      {
        exemplo: "音楽祭",
        hiragana: "おんがくさい",
        romaji: "Ongaku-sai",
        traducao: "Festival de música",
      },
    ],
  },
  {
    vocabulario: "バレンタイン",
    romaji: "barentain",
    hiragana: "ばれんたいん",
    traducao: "Dia dos Namorados (Valentine's Day)",
    exemplosFrases: [
      {
        exemplo: "バレンタインデーのプレゼント",
        hiragana: "ばれんたいんでーのぷれぜんと",
        romaji: "Barentain dē no purezento",
        traducao: "Presente do Dia dos Namorados",
      },
      {
        exemplo: "バレンタインチョコレート",
        hiragana: "ばれんたいんちょこれーと",
        romaji: "Barentain chokorēto",
        traducao: "Chocolate do Dia dos Namorados",
      },
      {
        exemplo: "バレンタインデート",
        hiragana: "ばれんたいんでーと",
        romaji: "Barentain dēto",
        traducao: "Encontro do Dia dos Namorados",
      },
    ],
  },
  {
    vocabulario: "泳ぎ",
    romaji: "oyogi",
    hiragana: "およぎ",
    traducao: "natação",
    exemplosFrases: [
      {
        exemplo: "泳ぎが上手です。",
        hiragana: "およぎがじょうずです。",
        romaji: "Oyogi ga jōzu desu.",
        traducao: "Eu sou bom em natação.",
      },
      {
        exemplo: "プールで泳ぎます。",
        hiragana: "ぷーるでおよぎます。",
        romaji: "Pūru de oyogimasu.",
        traducao: "Eu nado na piscina.",
      },
      {
        exemplo: "海で泳ぐのが好きです。",
        hiragana: "うみでおよぐのがすきです。",
        romaji: "Umi de oyogu no ga suki desu.",
        traducao: "Eu gosto de nadar no mar.",
      },
    ],
  },
  {
    vocabulario: "クリスマス",
    romaji: "kurisumasu",
    hiragana: "くりすます",
    traducao: "Natal",
    exemplosFrases: [
      {
        exemplo: "クリスマスプレゼント",
        hiragana: "くりすますぷれぜんと",
        romaji: "Kurisumasu purezento",
        traducao: "Presente de Natal",
      },
      {
        exemplo: "クリスマスケーキ",
        hiragana: "くりすますけーき",
        romaji: "Kurisumasu kēki",
        traducao: "Bolo de Natal",
      },
      {
        exemplo: "クリスマスイルミネーション",
        hiragana: "くりすますいるみねーしょん",
        romaji: "Kurisumasu iruminēshon",
        traducao: "Iluminação de Natal",
      },
    ],
  },
  {
    vocabulario: "ニーズ",
    romaji: "nīzu",
    hiragana: "にーず",
    traducao: "necessidades, demandas",
    exemplosFrases: [
      {
        exemplo: "市場のニーズに応える",
        hiragana: "しじょうのにーずにこたえる",
        romaji: "Shijō no nīzu ni kotaeru",
        traducao: "Atender às necessidades do mercado",
      },
      {
        exemplo: "顧客のニーズを理解する",
        hiragana: "こきゃくのにーずをりかいする",
        romaji: "Kokyaku no nīzu o rikai suru",
        traducao: "Compreender as necessidades dos clientes",
      },
      {
        exemplo: "ニーズに合った商品を提供する",
        hiragana: "にーずにあったしょうひんをていきょうする",
        romaji: "Nīzu ni atta shōhin o teikyō suru",
        traducao: "Oferecer produtos que atendam às necessidades",
      },
    ],
  },
  {
    vocabulario: "メモ",
    romaji: "memo",
    hiragana: "めも",
    traducao: "anotação, nota",
    exemplosFrases: [
      {
        exemplo: "重要なことをメモする",
        hiragana: "じゅうようなことをめもする",
        romaji: "Jūyōna koto o memo suru",
        traducao: "Fazer anotações sobre coisas importantes",
      },
      {
        exemplo: "メモを取る習慣がある",
        hiragana: "めもをとるしゅうかんがある",
        romaji: "Memo o toru shūkan ga aru",
        traducao: "Tenho o hábito de fazer anotações",
      },
      {
        exemplo: "会議のメモを整理する",
        hiragana: "かいぎのめもをせいりする",
        romaji: "Kaigi no memo o seiri suru",
        traducao: "Organizar as anotações da reunião",
      },
    ],
  },
  {
    vocabulario: "栄養",
    romaji: "eiyō",
    hiragana: "えいよう",
    traducao: "nutrição",
    exemplosFrases: [
      {
        exemplo: "バランスのとれた栄養",
        hiragana: "ばらんすのとれたえいよう",
        romaji: "Baransu no totta eiyō",
        traducao: "Nutrição balanceada",
      },
      {
        exemplo: "栄養価の高い食品",
        hiragana: "えいようかのたかいしょくひん",
        romaji: "Eiyō-ka no takai shokuhin",
        traducao: "Alimentos com alto valor nutricional",
      },
      {
        exemplo: "栄養のある食事を摂る",
        hiragana: "えいようのあるしょくじをとる",
        romaji: "Eiyō no aru shokuji o toru",
        traducao: "Ter uma refeição nutritiva",
      },
    ],
  },
  {
    vocabulario: "メール",
    romaji: "mēru",
    hiragana: "めーる",
    traducao: "e-mail",
    exemplosFrases: [
      {
        exemplo: "メールを送る",
        hiragana: "めーるをおくる",
        romaji: "Mēru o okuru",
        traducao: "Enviar um e-mail",
      },
      {
        exemplo: "メールをチェックする",
        hiragana: "めーるをちぇっくする",
        romaji: "Mēru o chekku suru",
        traducao: "Verificar o e-mail",
      },
      {
        exemplo: "メールの件名",
        hiragana: "めーるのけんめい",
        romaji: "Mēru no kenmei",
        traducao: "Assunto do e-mail",
      },
    ],
  },
  {
    vocabulario: "営業",
    romaji: "eigyō",
    hiragana: "えいぎょう",
    traducao: "vendas, negócio",
    exemplosFrases: [
      {
        exemplo: "営業時間",
        hiragana: "えいぎょうじかん",
        romaji: "Eigyō jikan",
        traducao: "Horário de funcionamento",
      },
      {
        exemplo: "新商品の営業活動",
        hiragana: "しんしょうひんのえいぎょうかつどう",
        romaji: "Shinshōhin no eigyō katsudō",
        traducao: "Atividades de venda de novos produtos",
      },
      {
        exemplo: "営業成績を上げる",
        hiragana: "えいぎょうせいせきをあげる",
        romaji: "Eigyō seiseki o ageru",
        traducao: "Melhorar o desempenho nas vendas",
      },
    ],
  },
  {
    vocabulario: "過去",
    romaji: "kako",
    hiragana: "かこ",
    traducao: "passado",
    exemplosFrases: [
      {
        exemplo: "過去の経験",
        hiragana: "かこのけいけん",
        romaji: "Kako no keiken",
        traducao: "Experiência passada",
      },
      {
        exemplo: "過去の出来事",
        hiragana: "かこのできごと",
        romaji: "Kako no dekigoto",
        traducao: "Acontecimento passado",
      },
      {
        exemplo: "過去の失敗から学ぶ",
        hiragana: "かこのしっぱいからまなぶ",
        romaji: "Kako no shippai kara manabu",
        traducao: "Aprender com os erros do passado",
      },
    ],
  },
  {
    vocabulario: "ゴール",
    romaji: "gōru",
    hiragana: "ごーる",
    traducao: "meta, objetivo",
    exemplosFrases: [
      {
        exemplo: "ゴールを達成する",
        hiragana: "ごーるをたっせいする",
        romaji: "Gōru o tassei suru",
        traducao: "Alcançar a meta",
      },
      {
        exemplo: "ゴールまで頑張る",
        hiragana: "ごーるまでがんばる",
        romaji: "Gōru made ganbaru",
        traducao: "Persistir até a meta",
      },
      {
        exemplo: "ゴールが見える",
        hiragana: "ごーるがみえる",
        romaji: "Gōru ga mieru",
        traducao: "Ver a meta",
      },
    ],
  },
  {
    vocabulario: "サングラス",
    romaji: "sangurasu",
    hiragana: "さんぐらす",
    traducao: "óculos de sol",
    exemplosFrases: [
      {
        exemplo: "サングラスをかける",
        hiragana: "さんぐらすをかける",
        romaji: "Sangurasu o kakeru",
        traducao: "Usar óculos de sol",
      },
      {
        exemplo: "サングラスで目を守る",
        hiragana: "さんぐらすでめをまもる",
        romaji: "Sangurasu de me o mamoru",
        traducao: "Proteger os olhos com óculos de sol",
      },
      {
        exemplo: "サングラスを忘れる",
        hiragana: "さんぐらすをわすれる",
        romaji: "Sangurasu o wasureru",
        traducao: "Esquecer os óculos de sol",
      },
    ],
  },
  {
    vocabulario: "オリンピック",
    romaji: "orinpikku",
    hiragana: "おりんぴっく",
    traducao: "Olimpíadas",
    exemplosFrases: [
      {
        exemplo: "オリンピックに参加する",
        hiragana: "おりんぴっくにさんかする",
        romaji: "Orinpikku ni sanka suru",
        traducao: "Participar das Olimpíadas",
      },
      {
        exemplo: "オリンピックのメダル",
        hiragana: "おりんぴっくのめだる",
        romaji: "Orinpikku no medaru",
        traducao: "Medalha olímpica",
      },
      {
        exemplo: "オリンピックの開会式",
        hiragana: "おりんぴっくのかいかいしき",
        romaji: "Orinpikku no kaikaishiki",
        traducao: "Cerimônia de abertura das Olimpíadas",
      },
    ],
  },
  {
    vocabulario: "我慢",
    romaji: "gaman",
    hiragana: "がまん",
    traducao: "paciência, resistência",
    exemplosFrases: [
      {
        exemplo: "我慢する力が必要だ",
        hiragana: "がまんするちからがひつようだ",
        romaji: "Gaman suru chikara ga hitsuyō da",
        traducao: "É necessário ter a capacidade de resistência",
      },
      {
        exemplo: "辛い時でも我慢する",
        hiragana: "つらいときでもがまんする",
        romaji: "Tsurai toki demo gaman suru",
        traducao: "Ter paciência mesmo em tempos difíceis",
      },
      {
        exemplo: "我慢できる限り頑張る",
        hiragana: "がまんできるかぎりがんばる",
        romaji: "Gaman dekiru kagiri ganbaru",
        traducao: "Dar o melhor enquanto se mantém paciente",
      },
    ],
  },
  {
    vocabulario: "ページ",
    romaji: "pēji",
    hiragana: "ぺーじ",
    traducao: "página",
    exemplosFrases: [
      {
        exemplo: "本のページをめくる",
        hiragana: "ほんのぺーじをめくる",
        romaji: "Hon no pēji o mekuru",
        traducao: "Virar as páginas de um livro",
      },
      {
        exemplo: "ページを開く",
        hiragana: "ぺーじをひらく",
        romaji: "Pēji o hiraku",
        traducao: "Abrir uma página",
      },
      {
        exemplo: "ページを保存する",
        hiragana: "ぺーじをほぞんする",
        romaji: "Pēji o hozon suru",
        traducao: "Salvar uma página",
      },
    ],
  },
  {
    vocabulario: "サンタクロース",
    romaji: "santakurōsu",
    hiragana: "さんたくろーす",
    traducao: "Papai Noel",
    exemplosFrases: [
      {
        exemplo: "サンタクロースの格好をする",
        hiragana: "さんたくろーすのかっこうをする",
        romaji: "Santakurōsu no kakkō o suru",
        traducao: "Vestir-se como o Papai Noel",
      },
      {
        exemplo: "サンタクロースに手紙を書く",
        hiragana: "さんたくろーすにてがみをかく",
        romaji: "Santakurōsu ni tegami o kaku",
        traducao: "Escrever uma carta para o Papai Noel",
      },
      {
        exemplo: "サンタクロースがプレゼントを配る",
        hiragana: "さんたくろーすがぷれぜんとをくばる",
        romaji: "Santakurōsu ga purezento o kubaru",
        traducao: "O Papai Noel distribui presentes",
      },
    ],
  },
  {
    vocabulario: "ペーパー",
    romaji: "pēpā",
    hiragana: "ぺーぱー",
    traducao: "trabalho escrito, paper",
    exemplosFrases: [
      {
        exemplo: "大学のペーパーを書く",
        hiragana: "だいがくのぺーぱーをかく",
        romaji: "Daigaku no pēpā o kaku",
        traducao: "Escrever um trabalho escrito para a universidade",
      },
      {
        exemplo: "ペーパーのテーマを選ぶ",
        hiragana: "ぺーぱーのてーまをえらぶ",
        romaji: "Pēpā no tēma o erabu",
        traducao: "Escolher o tema do paper",
      },
      {
        exemplo: "ペーパーの要点をまとめる",
        hiragana: "ぺーぱーのようてんをまとめる",
        romaji: "Pēpā no yōten o matomeru",
        traducao: "Resumir os pontos principais do paper",
      },
    ],
  },
  {
    vocabulario: "パリ",
    romaji: "Pari",
    hiragana: "ぱり",
    traducao: "Paris",
    exemplosFrases: [
      {
        exemplo: "パリに行く",
        hiragana: "ぱりにいく",
        romaji: "Pari ni iku",
        traducao: "Ir a Paris",
      },
      {
        exemplo: "パリの観光名所",
        hiragana: "ぱりのかんこうめいしょ",
        romaji: "Pari no kankō meisho",
        traducao: "Pontos turísticos de Paris",
      },
      {
        exemplo: "パリのカフェでコーヒーを飲む",
        hiragana: "ぱりのかふぇでこーひーをのむ",
        romaji: "Pari no kafe de kōhī o nomu",
        traducao: "Tomar café em um café de Paris",
      },
    ],
  },
  {
    vocabulario: "パスポート",
    romaji: "pasupōto",
    hiragana: "ぱすぽーと",
    traducao: "passaporte",
    exemplosFrases: [
      {
        exemplo: "パスポートを持っていく",
        hiragana: "ぱすぽーとをもっていく",
        romaji: "Pasupōto o motte iku",
        traducao: "Levar o passaporte",
      },
      {
        exemplo: "パスポートを更新する",
        hiragana: "ぱすぽーとをこうしんする",
        romaji: "Pasupōto o kōshin suru",
        traducao: "Renovar o passaporte",
      },
      {
        exemplo: "パスポートを紛失する",
        hiragana: "ぱすぽーとをふんしつする",
        romaji: "Pasupōto o funshitsu suru",
        traducao: "Perder o passaporte",
      },
    ],
  },
  {
    vocabulario: "外出",
    romaji: "gaishutsu",
    hiragana: "がいしゅつ",
    traducao: "sair de casa, saída",
    exemplosFrases: [
      {
        exemplo: "外出する準備をする",
        hiragana: "がいしゅつするじゅんびをする",
        romaji: "Gaishutsu suru junbi o suru",
        traducao: "Preparar-se para sair de casa",
      },
      {
        exemplo: "外出先で友達に会う",
        hiragana: "がいしゅつさきでともだちにあう",
        romaji: "Gaishutsu-saki de tomodachi ni au",
        traducao: "Encontrar-se com um amigo em algum lugar fora de casa",
      },
      {
        exemplo: "今日は外出しない",
        hiragana: "きょうはがいしゅつしない",
        romaji: "Kyō wa gaishutsu shinai",
        traducao: "Não vou sair de casa hoje",
      },
    ],
  },
  {
    vocabulario: "菓子",
    romaji: "kashi",
    hiragana: "かし",
    traducao: "doce, guloseima",
    exemplosFrases: [
      {
        exemplo: "お菓子を食べる",
        hiragana: "おかしをたべる",
        romaji: "Okashi o taberu",
        traducao: "Comer um doce",
      },
      {
        exemplo: "お菓子を買う",
        hiragana: "おかしをかう",
        romaji: "Okashi o kau",
        traducao: "Comprar um doce",
      },
      {
        exemplo: "お菓子を作る",
        hiragana: "おかしをつくる",
        romaji: "Okashi o tsukuru",
        traducao: "Fazer um doce",
      },
    ],
  },
  {
    vocabulario: "スケート",
    romaji: "sukēto",
    hiragana: "すけーと",
    traducao: "patins, patinação",
    exemplosFrases: [
      {
        exemplo: "スケートをする",
        hiragana: "すけーとをする",
        romaji: "Sukēto o suru",
        traducao: "Andar de patins, patinar",
      },
      {
        exemplo: "スケートリンクで遊ぶ",
        hiragana: "すけーとりんくであそぶ",
        romaji: "Sukēto rinku de asobu",
        traducao: "Brincar na pista de patinação",
      },
      {
        exemplo: "スケートの技を練習する",
        hiragana: "すけーとのわざをれんしゅうする",
        romaji: "Sukēto no waza o renshū suru",
        traducao: "Praticar truques de patinação",
      },
    ],
  },
  {
    vocabulario: "依頼",
    romaji: "irai",
    hiragana: "いらい",
    traducao: "pedido, solicitação",
    exemplosFrases: [
      {
        exemplo: "仕事の依頼を受ける",
        hiragana: "しごとのいらいをうける",
        romaji: "Shigoto no irai o ukeru",
        traducao: "Aceitar um pedido de trabalho",
      },
      {
        exemplo: "依頼を断る",
        hiragana: "いらいをことわる",
        romaji: "Irai o kotowaru",
        traducao: "Recusar uma solicitação",
      },
      {
        exemplo: "依頼に応じる",
        hiragana: "いらいにおうじる",
        romaji: "Irai ni ōjiru",
        traducao: "Atender a uma solicitação",
      },
    ],
  },
  {
    vocabulario: "羽",
    romaji: "hane",
    hiragana: "はね",
    traducao: "pena, asa",
    exemplosFrases: [
      {
        exemplo: "鳥の羽を見る",
        hiragana: "とりのはねをみる",
        romaji: "Tori no hane o miru",
        traducao: "Ver as penas de um pássaro",
      },
      {
        exemplo: "羽を持つ昆虫",
        hiragana: "はねをもつこんちゅう",
        romaji: "Hane o motsu konchū",
        traducao: "Inseto com asas",
      },
      {
        exemplo: "羽が抜ける",
        hiragana: "はねがぬける",
        romaji: "Hane ga nukeru",
        traducao: "As penas caem",
      },
    ],
  },
  {
    vocabulario: "哀れ",
    romaji: "aware",
    hiragana: "あわれ",
    traducao: "lamentável, digno de pena",
    exemplosFrases: [
      {
        exemplo: "哀れな状況",
        hiragana: "あわれなじょうきょう",
        romaji: "Aware na jōkyō",
        traducao: "Situação lamentável",
      },
      {
        exemplo: "哀れな目をする",
        hiragana: "あわれなめをする",
        romaji: "Aware na me o suru",
        traducao: "Fazer um olhar digno de pena",
      },
      {
        exemplo: "哀れに思う",
        hiragana: "あわれにおもう",
        romaji: "Aware ni omou",
        traducao: "Sentir pena",
      },
    ],
  },
  {
    vocabulario: "キュウリ",
    romaji: "kyūri",
    hiragana: "きゅうり",
    traducao: "pepino",
    exemplosFrases: [
      {
        exemplo: "キュウリを切る",
        hiragana: "きゅうりをきる",
        romaji: "Kyūri o kiru",
        traducao: "Cortar pepino",
      },
      {
        exemplo: "キュウリのサラダ",
        hiragana: "きゅうりのさらだ",
        romaji: "Kyūri no sarada",
        traducao: "Salada de pepino",
      },
      {
        exemplo: "キュウリの漬物",
        hiragana: "きゅうりのつけもの",
        romaji: "Kyūri no tsukemono",
        traducao: "Picles de pepino",
      },
    ],
  },
  {
    vocabulario: "パイロット",
    romaji: "pairotto",
    hiragana: "ぱいろっと",
    traducao: "piloto",
    exemplosFrases: [
      {
        exemplo: "パイロットになる",
        hiragana: "ぱいろっとになる",
        romaji: "Pairotto ni naru",
        traducao: "Tornar-se um piloto",
      },
      {
        exemplo: "パイロットの制服",
        hiragana: "ぱいろっとのせいふく",
        romaji: "Pairotto no seifuku",
        traducao: "Uniforme de piloto",
      },
      {
        exemplo: "パイロットの技術",
        hiragana: "ぱいろっとのぎじゅつ",
        romaji: "Pairotto no gijutsu",
        traducao: "Habilidades de piloto",
      },
    ],
  },
  {
    vocabulario: "こしょう",
    romaji: "koshō",
    hiragana: "こしょう",
    traducao: "pimenta",
    exemplosFrases: [
      {
        exemplo: "こしょうを加える",
        hiragana: "こしょうをくわえる",
        romaji: "Koshō o kuwaeru",
        traducao: "Adicionar pimenta",
      },
      {
        exemplo: "こしょうの辛さ",
        hiragana: "こしょうのからさ",
        romaji: "Koshō no karasa",
        traducao: "Picância da pimenta",
      },
      {
        exemplo: "こしょうを振る",
        hiragana: "こしょうをふる",
        romaji: "Koshō o furu",
        traducao: "Polvilhar pimenta",
      },
    ],
  },
  {
    vocabulario: "ピン",
    romaji: "pin",
    hiragana: "ぴん",
    traducao: "alfinete, pino",
    exemplosFrases: [
      {
        exemplo: "紙にピンを刺す",
        hiragana: "かみにぴんをさす",
        romaji: "Kami ni pin o sasu",
        traducao: "Espetar um alfinete no papel",
      },
      {
        exemplo: "ピンで髪を留める",
        hiragana: "ぴんでかみをとめる",
        romaji: "Pin de kami o tomeru",
        traducao: "Prender o cabelo com um grampo",
      },
      {
        exemplo: "ピンが曲がる",
        hiragana: "ぴんがまがる",
        romaji: "Pin ga magaru",
        traducao: "O pino está torto",
      },
    ],
  },
  {
    vocabulario: "画家",
    romaji: "gaka",
    hiragana: "がか",
    traducao: "pintor(a), artista",
    exemplosFrases: [
      {
        exemplo: "有名な画家",
        hiragana: "ゆうめいながか",
        romaji: "Yūmei na gaka",
        traducao: "Pintor(a) famoso(a)",
      },
      {
        exemplo: "画家の作品",
        hiragana: "がかのさくひん",
        romaji: "Gaka no sakuhin",
        traducao: "Obra de arte de um(a) pintor(a)",
      },
      {
        exemplo: "画家として活動する",
        hiragana: "がかとしてかつどうする",
        romaji: "Gaka toshite katsudō suru",
        traducao: "Atuar como pintor(a), trabalhar como artista",
      },
    ],
  },
  {
    vocabulario: "ピクニック",
    romaji: "pikunikku",
    hiragana: "ぴくにっく",
    traducao: "piquenique",
    exemplosFrases: [
      {
        exemplo: "ピクニックに行く",
        hiragana: "ぴくにっくにいく",
        romaji: "Pikunikku ni iku",
        traducao: "Ir a um piquenique",
      },
      {
        exemplo: "公園でピクニックをする",
        hiragana: "こうえんでぴくにっくをする",
        romaji: "Kōen de pikunikku o suru",
        traducao: "Fazer um piquenique no parque",
      },
      {
        exemplo: "食べ物を持ってピクニックに参加する",
        hiragana: "たべものをもってぴくにっくにさんかする",
        romaji: "Tabemono o motte pikunikku ni sanka suru",
        traducao: "Participar de um piquenique levando comida",
      },
    ],
  },
  {
    vocabulario: "ピザ",
    romaji: "piza",
    hiragana: "ぴざ",
    traducao: "pizza",
    exemplosFrases: [
      {
        exemplo: "ピザを注文する",
        hiragana: "ぴざをちゅうもんする",
        romaji: "Piza o chūmon suru",
        traducao: "Fazer um pedido de pizza",
      },
      {
        exemplo: "ピザを食べる",
        hiragana: "ぴざをたべる",
        romaji: "Piza o taberu",
        traducao: "Comer pizza",
      },
      {
        exemplo: "ピザのトッピングを選ぶ",
        hiragana: "ぴざのとっぴんぐをえらぶ",
        romaji: "Piza no toppingu o erabu",
        traducao: "Escolher as coberturas da pizza",
      },
    ],
  },
  {
    vocabulario: "プラン",
    romaji: "puran",
    hiragana: "ぷらん",
    traducao: "plano, programa",
    exemplosFrases: [
      {
        exemplo: "旅行のプランを立てる",
        hiragana: "りょこうのぷらんをたてる",
        romaji: "Ryokō no puran o tateru",
        traducao: "Criar um plano de viagem",
      },
      {
        exemplo: "新しいビジネスのプラン",
        hiragana: "あたらしいびじねすのぷらん",
        romaji: "Atarashii bijinesu no puran",
        traducao: "Plano de negócios novo",
      },
      {
        exemplo: "プラン通りに行動する",
        hiragana: "ぷらんどおりにこうどうする",
        romaji: "Puran dōri ni kōdō suru",
        traducao: "Agir conforme o plano",
      },
    ],
  },
  {
    vocabulario: "ホーム",
    romaji: "hōmu",
    hiragana: "ほーむ",
    traducao: "casa, lar",
    exemplosFrases: [
      {
        exemplo: "新しいホームを探す",
        hiragana: "あたらしいほーむをさがす",
        romaji: "Atarashii hōmu o sagasu",
        traducao: "Procurar uma nova casa",
      },
      {
        exemplo: "ホームシックになる",
        hiragana: "ほーむしっくになる",
        romaji: "Hōmu shikku ni naru",
        traducao: "Sentir saudades de casa",
      },
      {
        exemplo: "快適なホームで過ごす",
        hiragana: "かいてきなほーむですごす",
        romaji: "Kaiteki na hōmu de sugosu",
        traducao: "Passar o tempo em uma casa confortável",
      },
    ],
  },
  {
    vocabulario: "プラグ",
    romaji: "puragu",
    hiragana: "ぷらぐ",
    traducao: "plugue, tomada",
    exemplosFrases: [
      {
        exemplo: "プラグをコンセントに差す",
        hiragana: "ぷらぐをこんせんとにさす",
        romaji: "Puragu o konsento ni sasu",
        traducao: "Inserir o plugue na tomada",
      },
      {
        exemplo: "電源プラグを抜く",
        hiragana: "でんげんぷらぐをぬく",
        romaji: "Dengen puragu o nuku",
        traducao: "Desconectar o plugue de energia",
      },
      {
        exemplo: "プラグの形を確認する",
        hiragana: "ぷらぐのかたちをかくにんする",
        romaji: "Puragu no katachi o kakunin suru",
        traducao: "Verificar o formato do plugue",
      },
    ],
  },
  {
    vocabulario: "汚染",
    romaji: "osen",
    hiragana: "おせん",
    traducao: "poluição, contaminação",
    exemplosFrases: [
      {
        exemplo: "環境の汚染",
        hiragana: "かんきょうのおせん",
        romaji: "Kankyō no osen",
        traducao: "Poluição ambiental",
      },
      {
        exemplo: "水の汚染",
        hiragana: "みずのおせん",
        romaji: "Mizu no osen",
        traducao: "Contaminação da água",
      },
      {
        exemplo: "大気の汚染",
        hiragana: "たいきのおせん",
        romaji: "Taiki no osen",
        traducao: "Poluição do ar",
      },
    ],
  },
  {
    vocabulario: "パーセント",
    romaji: "pāsento",
    hiragana: "ぱーせんと",
    traducao: "porcentagem",
    exemplosFrases: [
      {
        exemplo: "80パーセントの確率",
        hiragana: "80ぱーせんとのかくりつ",
        romaji: "80 pāsento no kakuritsu",
        traducao: "Probabilidade de 80%",
      },
      {
        exemplo: "価格が10パーセント下がる",
        hiragana: "かかくが10ぱーせんとさがる",
        romaji: "Kakaku ga 10 pāsento sagaru",
        traducao: "O preço cai 10%",
      },
      {
        exemplo: "調査結果の15パーセント",
        hiragana: "ちょうさけっかの15ぱーせんと",
        romaji: "Chōsa kekka no 15 pāsento",
        traducao: "15% dos resultados da pesquisa",
      },
    ],
  },
  {
    vocabulario: "価格",
    romaji: "kakaku",
    hiragana: "かかく",
    traducao: "preço",
    exemplosFrases: [
      {
        exemplo: "商品の価格",
        hiragana: "しょうひんのかかく",
        romaji: "Shōhin no kakaku",
        traducao: "Preço do produto",
      },
      {
        exemplo: "価格を比較する",
        hiragana: "かかくをひかくする",
        romaji: "Kakaku o hikaku suru",
        traducao: "Comparar preços",
      },
      {
        exemplo: "価格が上がる",
        hiragana: "かかくがあがる",
        romaji: "Kakaku ga agaru",
        traducao: "O preço sobe",
      },
    ],
  },
  {
    vocabulario: "怪我",
    romaji: "kega",
    hiragana: "けが",
    traducao: "lesão, ferimento",
    exemplosFrases: [
      {
        exemplo: "スポーツで怪我する",
        hiragana: "すぽーつでけがする",
        romaji: "Supōtsu de kega suru",
        traducao: "Se machucar durante o esporte",
      },
      {
        exemplo: "怪我の治療",
        hiragana: "けがのちりょう",
        romaji: "Kega no chiryō",
        traducao: "Tratamento de lesões",
      },
      {
        exemplo: "怪我を予防する",
        hiragana: "けがをよぼうする",
        romaji: "Kega o yobō suru",
        traducao: "Prevenir lesões",
      },
    ],
  },
  {
    vocabulario: "ハム",
    romaji: "hamu",
    hiragana: "はむ",
    traducao: "presunto",
    exemplosFrases: [
      {
        exemplo: "ハムサンドイッチ",
        hiragana: "はむさんどいっち",
        romaji: "Hamu sandoitchi",
        traducao: "Sanduíche de presunto",
      },
      {
        exemplo: "ハムを切る",
        hiragana: "はむをきる",
        romaji: "Hamu o kiru",
        traducao: "Cortar presunto",
      },
      {
        exemplo: "ハムの味",
        hiragana: "はむのあじ",
        romaji: "Hamu no aji",
        traducao: "Sabor do presunto",
      },
    ],
  },
  {
    vocabulario: "ファスト",
    romaji: "fasuto",
    hiragana: "ふぁすと",
    traducao: "rápido, rápido(a) (em relação a comida ou serviço)",
    exemplosFrases: [
      {
        exemplo: "ファストフード",
        hiragana: "ふぁすとふーど",
        romaji: "Fasuto fūdo",
        traducao: "Comida rápida, fast food",
      },
      {
        exemplo: "ファストフード店",
        hiragana: "ふぁすとふーどてん",
        romaji: "Fasuto fūdo ten",
        traducao: "Restaurante de fast food",
      },
      {
        exemplo: "ファストフードの注文",
        hiragana: "ふぁすとふーどのちゅうもん",
        romaji: "Fasuto fūdo no chūmon",
        traducao: "Pedido de fast food",
      },
    ],
  },
  {
    vocabulario: "王子",
    romaji: "ōji",
    hiragana: "おうじ",
    traducao: "príncipe",
    exemplosFrases: [
      {
        exemplo: "王子様のような笑顔",
        hiragana: "おうじさまのようなえがお",
        romaji: "Ōji-sama no yōna egao",
        traducao: "Sorriso como o de um príncipe",
      },
      {
        exemplo: "王子様になる夢",
        hiragana: "おうじさまになるゆめ",
        romaji: "Ōji-sama ni naru yume",
        traducao: "Sonho de se tornar um príncipe",
      },
      {
        exemplo: "王子様の行方を追う",
        hiragana: "おうじさまのゆくえをおう",
        romaji: "Ōji-sama no yukue o ou",
        traducao: "Seguir o paradeiro do príncipe",
      },
    ],
  },
  {
    vocabulario: "プライベート",
    romaji: "puraibēto",
    hiragana: "ぷらいべーと",
    traducao: "privado",
    exemplosFrases: [
      {
        exemplo: "プライベートな時間",
        hiragana: "ぷらいべーとなじかん",
        romaji: "Puraibēto na jikan",
        traducao: "Tempo privado",
      },
      {
        exemplo: "プライベートな情報",
        hiragana: "ぷらいべーとなじょうほう",
        romaji: "Puraibēto na jōhō",
        traducao: "Informação privada",
      },
      {
        exemplo: "プライベートな空間",
        hiragana: "ぷらいべーとなくうかん",
        romaji: "Puraibēto na kūkan",
        traducao: "Espaço privado",
      },
    ],
  },
  {
    vocabulario: "すえ",
    romaji: "sue",
    hiragana: "すえ",
    traducao: "depois, mais tarde",
    exemplosFrases: [
      {
        exemplo: "後でやること",
        hiragana: "あとでやること",
        romaji: "Ato de yaru koto",
        traducao: "Coisas para fazer mais tarde",
      },
      {
        exemplo: "後で話そう",
        hiragana: "あとではなそう",
        romaji: "Ato de hanasou",
        traducao: "Vamos conversar mais tarde",
      },
      {
        exemplo: "後で連絡する",
        hiragana: "あとでれんらくする",
        romaji: "Ato de renraku suru",
        traducao: "Entrar em contato mais tarde",
      },
    ],
  },
  {
    vocabulario: "プロ",
    romaji: "puro",
    hiragana: "ぷろ",
    traducao: "profissional",
    exemplosFrases: [
      {
        exemplo: "プロのアスリート",
        hiragana: "ぷろのあすりーと",
        romaji: "Puro no asurīto",
        traducao: "Atleta profissional",
      },
      {
        exemplo: "プロの意見",
        hiragana: "ぷろのいけん",
        romaji: "Puro no iken",
        traducao: "Opinião profissional",
      },
      {
        exemplo: "プロの技術",
        hiragana: "ぷろのぎじゅつ",
        romaji: "Puro no gijutsu",
        traducao: "Técnica profissional",
      },
    ],
  },
  {
    vocabulario: "プロジェクト",
    romaji: "purojekuto",
    hiragana: "ぷろじぇくと",
    traducao: "projeto",
    exemplosFrases: [
      {
        exemplo: "新しいプロジェクトを始める予定です。",
        hiragana: "あたらしいぷろじぇくとをはじめるよていです。",
        romaji: "Atarashii purojekuto o hajimeru yotei desu.",
        traducao: "Estou planejando começar um novo projeto.",
      },
      {
        exemplo: "このプロジェクトはチームで進められます。",
        hiragana: "このぷろじぇくとはちーむですすめられます。",
        romaji: "Kono purojekuto wa chīmu de susumeraremasu.",
        traducao: "Este projeto está sendo conduzido em equipe.",
      },
      {
        exemplo: "プロジェクトの目標を達成しました。",
        hiragana: "ぷろじぇくとのもくひょうをたっせいしました。",
        romaji: "Purojekuto no mokuhyō o tassei shimashita.",
        traducao: "Alcançamos os objetivos do projeto.",
      },
    ],
  },
  {
    vocabulario: "プロジェクター",
    romaji: "purojekutā",
    hiragana: "ぷろじぇくたー",
    traducao: "projetor",
    exemplosFrases: [
      {
        exemplo: "会議でプロジェクターを使用しました。",
        hiragana: "かいぎでぷろじぇくたーをしようしました。",
        romaji: "Kaigi de purojekutā o shiyō shimashita.",
        traducao: "Utilizamos um projetor na reunião.",
      },
      {
        exemplo: "プロジェクターの映像がクリアでした。",
        hiragana: "ぷろじぇくたーのえいぞうがくりあでした。",
        romaji: "Purojekutā no eizō ga kuria deshita.",
        traducao: "A projeção do projetor estava clara.",
      },
      {
        exemplo: "プロジェクターの設置に時間がかかりました。",
        hiragana: "ぷろじぇくたーのせっちにじかんがかかりました。",
        romaji: "Purojekutā no secchi ni jikan ga kakarimashita.",
        traducao: "Levou tempo para configurar o projetor.",
      },
    ],
  },
  {
    vocabulario: "いたずら",
    romaji: "itazura",
    hiragana: "いたずら",
    traducao: "brincadeira, travessura",
    exemplosFrases: [
      {
        exemplo: "子供たちがいたずらをして笑っていました。",
        hiragana: "こどもたちがいたずらをしてわらっていました。",
        romaji: "Kodomo-tachi ga itazura o shite waratte imashita.",
        traducao: "As crianças estavam rindo enquanto faziam travessuras.",
      },
      {
        exemplo: "彼はいたずら好きな性格です。",
        hiragana: "かれはいたずらずきなせいかくです。",
        romaji: "Kare wa itazura-zuki na seikaku desu.",
        traducao: "Ele tem uma personalidade brincalhona.",
      },
      {
        exemplo: "いたずらは友達との絆を深めることもあります。",
        hiragana: "いたずらはともだちとのきずなをふかめることもあります。",
        romaji: "Itazura wa tomodachi to no kizuna o fukameru koto mo arimasu.",
        traducao:
          "As brincadeiras também podem fortalecer os laços de amizade.",
      },
    ],
  },
  {
    vocabulario: "チーズ",
    romaji: "chīzu",
    hiragana: "ちーず",
    traducao: "queijo",
    exemplosFrases: [
      {
        exemplo: "ピザにたくさんのチーズを乗せました。",
        hiragana: "ぴざにたくさんのちーずをのせました。",
        romaji: "Piza ni takusan no chīzu o nosemashita.",
        traducao: "Coloquei muito queijo na pizza.",
      },
      {
        exemplo: "チーズが好きで、毎日食べています。",
        hiragana: "ちーずがすきで、まいにちたべています。",
        romaji: "Chīzu ga suki de, mainichi tabete imasu.",
        traducao: "Eu gosto de queijo e como todos os dias.",
      },
      {
        exemplo: "この店の特製チーズケーキがおすすめです。",
        hiragana: "このみせのとくせいちーずけーきがおすすめです。",
        romaji: "Kono mise no tokusei chīzu kēki ga osusume desu.",
        traducao: "O cheesecake especial desta loja é altamente recomendado.",
      },
    ],
  },
  {
    vocabulario: "キロ",
    romaji: "kiro",
    hiragana: "きろ",
    traducao: "quilograma",
    exemplosFrases: [
      {
        exemplo: "この荷物は５キロあります。",
        hiragana: "このにもつは５きろあります。",
        romaji: "Kono nimotsu wa go kiro arimasu.",
        traducao: "Esta bagagem pesa 5 quilogramas.",
      },
      {
        exemplo: "体重をキロ単位で測りました。",
        hiragana: "たいじゅうをきろたんいではかりました。",
        romaji: "Taijū o kiro tan'i de hakarimashita.",
        traducao: "Pesei meu peso em quilogramas.",
      },
      {
        exemplo: "商品の値段はキログラムあたりで表示されています。",
        hiragana:
          "しょうひんのねだんはきろぐらむあたりでひょうじされています。",
        romaji: "Shōhin no nedan wa kiroguramu atari de hyōji sarete imasu.",
        traducao: "O preço dos produtos é exibido por quilograma.",
      },
    ],
  },
  {
    vocabulario: "化学",
    romaji: "kagaku",
    hiragana: "かがく",
    traducao: "química",
    exemplosFrases: [
      {
        exemplo: "高校で化学を勉強しています。",
        hiragana: "こうこうでかがくをべんきょうしています。",
        romaji: "Kōkō de kagaku o benkyō shite imasu.",
        traducao: "Estou estudando química no ensino médio.",
      },
      {
        exemplo: "化学実験を行う前に手順を確認しました。",
        hiragana: "かがくじっけんをおこなうまえにてじゅんをかくにんしました。",
        romaji:
          "Kagaku jikkendekin o okonau mae ni tejun o kakunin shimashita.",
        traducao:
          "Confirmei os procedimentos antes de realizar o experimento de química.",
      },
      {
        exemplo: "化学の知識が役立ちました。",
        hiragana: "かがくのちしきがやくだちました。",
        romaji: "Kagaku no chishiki ga yakudachimashita.",
        traducao: "Meu conhecimento de química foi útil.",
      },
    ],
  },
  {
    vocabulario: "エビ",
    romaji: "ebi",
    hiragana: "えび",
    traducao: "camarão",
    exemplosFrases: [
      {
        exemplo: "エビフライが大好きです。",
        hiragana: "えびふらいがだいすきです。",
        romaji: "Ebi furai ga daisuki desu.",
        traducao: "Eu adoro camarão frito.",
      },
      {
        exemplo: "海老寿司を注文しました。",
        hiragana: "えびすしをちゅうもんしました。",
        romaji: "Ebi sushi o chūmon shimashita.",
        traducao: "Eu pedi sushi de camarão.",
      },
      {
        exemplo: "海老の天ぷらはサクサクしています。",
        hiragana: "えびのてんぷらはさくさくしています。",
        romaji: "Ebi no tempura wa saku saku shite imasu.",
        traducao: "O tempurá de camarão é crocante.",
      },
    ],
  },
  {
    vocabulario: "しっぽ",
    romaji: "shippo",
    hiragana: "しっぽ",
    traducao: "rabo",
    exemplosFrases: [
      {
        exemplo: "犬のしっぽが振れていました。",
        hiragana: "いぬのしっぽがふれていました。",
        romaji: "Inu no shippo ga furete imashita.",
        traducao: "O rabo do cachorro estava abanando.",
      },
      {
        exemplo: "猫のしっぽを触るのは気をつけてください。",
        hiragana: "ねこのしっぽをさわるのはきをつけてください。",
        romaji: "Neko no shippo o sawaru no wa ki o tsukete kudasai.",
        traducao: "Por favor, tenha cuidado ao tocar no rabo do gato.",
      },
      {
        exemplo: "キツネのしっぽが長くてふさふさしています。",
        hiragana: "きつねのしっぽがながくてふさふさしています。",
        romaji: "Kitsune no shippo ga nagakute fusafusa shite imasu.",
        traducao: "O rabo da raposa é longo e fofo.",
      },
    ],
  },
  {
    vocabulario: "スピード",
    romaji: "supīdo",
    hiragana: "すぴーど",
    traducao: "velocidade",
    exemplosFrases: [
      {
        exemplo: "この車はスピードが速いです。",
        hiragana: "このくるまはすぴーどがはやいです。",
        romaji: "Kono kuruma wa supīdo ga hayai desu.",
        traducao: "Este carro é rápido.",
      },
      {
        exemplo: "マラソンでスピードを上げるためにトレーニングをしました。",
        hiragana: "まらそんですぴーどをあげるためにとれーにんぐをしました。",
        romaji: "Marason de supīdo o ageru tame ni torēningu o shimashita.",
        traducao: "Treinei para aumentar minha velocidade na maratona.",
      },
      {
        exemplo: "スピード違反は法律で禁止されています。",
        hiragana: "すぴーどいはんはほうりつできんしされています。",
        romaji: "Supīdo ihan wa hōritsu de kinshi sareteimasu.",
        traducao: "Exceder a velocidade é proibido por lei.",
      },
    ],
  },
  {
    vocabulario: "ラケット",
    romaji: "raketto",
    hiragana: "らけっと",
    traducao: "raquete",
    exemplosFrases: [
      {
        exemplo: "テニスのラケットを買いました。",
        hiragana: "てにすのらけっとをかいました。",
        romaji: "Tenisu no raketto o kaimashita.",
        traducao: "Comprei uma raquete de tênis.",
      },
      {
        exemplo: "ラケットスポーツは運動量が多いです。",
        hiragana: "らけっとすぽーつはうんどうりょうがおおいです。",
        romaji: "Raketto supōtsu wa undōryō ga ōi desu.",
        traducao: "Os esportes de raquete exigem muita atividade física.",
      },
      {
        exemplo: "ラケットのグリップを握り直しました。",
        hiragana: "らけっとのぐりっぷをにぎりなおしました。",
        romaji: "Raketto no gurippu o nigiri naoshimashita.",
        traducao: "Eu reajustei o aperto da raquete.",
      },
    ],
  },
  {
    vocabulario: "レシピ",
    romaji: "reshipi",
    hiragana: "れしぴ",
    traducao: "receita",
    exemplosFrases: [
      {
        exemplo: "この料理のレシピを教えてください。",
        hiragana: "このりょうりのれしぴをおしえてください。",
        romaji: "Kono ryōri no reshipi o oshiete kudasai.",
        traducao: "Por favor, me ensine a receita deste prato.",
      },
      {
        exemplo: "新しいレシピを試してみました。",
        hiragana: "あたらしいれしぴをためしてみました。",
        romaji: "Atarashii reshipi o tameshite mimashita.",
        traducao: "Eu experimentei uma nova receita.",
      },
      {
        exemplo: "母の作る料理はいつもレシピ通りに美味しいです。",
        hiragana: "ははのつくるりょうりはいつもれしぴどおりにおいしいです。",
        romaji: "Haha no tsukuru ryōri wa itsumo reshipi dōri ni oishii desu.",
        traducao:
          "A comida que minha mãe faz sempre fica deliciosa, seguindo a receita.",
      },
    ],
  },
  {
    vocabulario: "回復",
    romaji: "kaifuku",
    hiragana: "かいふく",
    traducao: "recuperação",
    exemplosFrases: [
      {
        exemplo: "ケガから回復するには時間がかかります。",
        hiragana: "けがからかいふくするにはじかんがかかります。",
        romaji: "Kega kara kaifuku suru ni wa jikan ga kakarimasu.",
        traducao: "Leva tempo para se recuperar de uma lesão.",
      },
      {
        exemplo: "風邪を引いたけど、もう回復しました。",
        hiragana: "かぜをひいたけど、もうかいふくしました。",
        romaji: "Kaze o hiita kedo, mō kaifuku shimashita.",
        traducao: "Peguei um resfriado, mas já me recuperei.",
      },
      {
        exemplo: "病気の後、体力を回復するために食事に気をつけています。",
        hiragana:
          "びょうきのあと、たいりょくをかいふくするためにしょくじにきをつけています。",
        romaji:
          "Byōki no ato, tairyoku o kaifuku suru tame ni shokuji ni ki o tsuketeimasu.",
        traducao:
          "Após a doença, estou cuidando da minha alimentação para recuperar minha energia.",
      },
    ],
  },
  {
    vocabulario: "王",
    romaji: "ō",
    hiragana: "おう",
    traducao: "rei",
    exemplosFrases: [
      {
        exemplo: "昔々、王様がいました。",
        hiragana: "むかしむかし、おうさまがいました。",
        romaji: "Mukashi mukashi, ōsama ga imashita.",
        traducao: "Havia um rei antigamente.",
      },
      {
        exemplo: "チェスの目的は敵の王を倒すことです。",
        hiragana: "ちぇすのもくてきはてきのおうをたおすことです。",
        romaji: "Chesu no mokuteki wa teki no ō o taosu koto desu.",
        traducao: "O objetivo do xadrez é derrubar o rei do adversário.",
      },
      {
        exemplo: "王の娘が美しい国で暮らしていました。",
        hiragana: "おうのむすめがうつくしいくにでくらしていました。",
        romaji: "Ō no musume ga utsukushii kuni de kurasite imashita.",
        traducao: "A filha do rei vivia em um reino belo.",
      },
    ],
  },
  {
    vocabulario: "移動",
    romaji: "idō",
    hiragana: "いどう",
    traducao: "movimento",
    exemplosFrases: [
      {
        exemplo: "電車での移動は便利です。",
        hiragana: "でんしゃでのいどうはべんりです。",
        romaji: "Densha de no idō wa benri desu.",
        traducao: "A viagem de trem é conveniente.",
      },
      {
        exemplo: "外国への移動はパスポートが必要です。",
        hiragana: "がいこくへのいどうはぱすぽーとがひつようです。",
        romaji: "Gaikoku e no idō wa pasupōto ga hitsuyō desu.",
        traducao: "A viagem para o exterior requer um passaporte.",
      },
      {
        exemplo: "大都市への移動は交通手段が豊富です。",
        hiragana: "だいとしへのいどうはこうつうしゅだんがほうふです。",
        romaji: "Daitoshi e no idō wa kōtsū shudan ga hōfu desu.",
        traducao:
          "Há muitas opções de transporte para se deslocar para grandes cidades.",
      },
    ],
  },
  {
    vocabulario: "ライバル",
    romaji: "raibaru",
    hiragana: "らいばる",
    traducao: "rival",
    exemplosFrases: [
      {
        exemplo: "彼は私のライバルです。",
        hiragana: "かれはわたしのらいばるです。",
        romaji: "Kare wa watashi no raibaru desu.",
        traducao: "Ele é meu rival.",
      },
      {
        exemplo: "競技のレベルが上がり、ライバルとして切磋琢磨しています。",
        hiragana:
          "きょうぎのれべるがあがり、らいばるとしてせっさたくましています。",
        romaji:
          "Kyōgi no reberu ga agari, raibaru toshite sessatakumashiteimasu.",
        traducao:
          "O nível da competição está aumentando, e estamos nos esforçando para melhorar como rivais.",
      },
      {
        exemplo: "ライバルとの競争が刺激的です。",
        hiragana: "らいばるとのきょうそうがしげきてきです。",
        romaji: "Raibaru to no kyōsō ga shigekiteki desu.",
        traducao: "A competição com um rival é emocionante.",
      },
    ],
  },
  {
    vocabulario: "衣服",
    romaji: "ifuku",
    hiragana: "いふく",
    traducao: "roupa",
    exemplosFrases: [
      {
        exemplo: "洗濯機で衣服を洗いました。",
        hiragana: "せんたくきでいふくをあらいました。",
        romaji: "Sentakuki de ifuku o araimashita.",
        traducao: "Lavei as roupas na máquina de lavar.",
      },
      {
        exemplo: "新しい衣服を買いたいです。",
        hiragana: "あたらしいいふくをかいたいです。",
        romaji: "Atarashii ifuku o kaitai desu.",
        traducao: "Quero comprar roupas novas.",
      },
      {
        exemplo: "着たい衣服がたくさんあります。",
        hiragana: "きたいいふくがたくさんあります。",
        romaji: "Kitai ifuku ga takusan arimasu.",
        traducao: "Tenho muitas roupas que quero usar.",
      },
    ],
  },
  {
    vocabulario: "しわ",
    romaji: "shiwa",
    hiragana: "しわ",
    traducao: "ruga",
    exemplosFrases: [
      {
        exemplo: "アイロンでシワを伸ばしました。",
        hiragana: "あいろんでしわをのばしました。",
        romaji: "Airon de shiwa o nobashimashita.",
        traducao: "Passei as rugas com o ferro de passar.",
      },
      {
        exemplo: "このシャツはしわになりにくい素材です。",
        hiragana: "このしゃつはしわになりにくいそざいです。",
        romaji: "Kono shatsu wa shiwa ni narinikui sozai desu.",
        traducao:
          "Esta camisa é feita de um material que não amassa facilmente.",
      },
      {
        exemplo: "衣服を畳むとシワができないようにします。",
        hiragana: "いふくをたたむとしわができないようにします。",
        romaji: "Ifuku o tatamu to shiwa ga dekinai yō ni shimasu.",
        traducao: "Dobro as roupas de forma a evitar rugas.",
      },
    ],
  },
  {
    vocabulario: "噂",
    romaji: "uwasa",
    hiragana: "うわさ",
    traducao: "boato",
    exemplosFrases: [
      {
        exemplo: "噂によると、彼は結婚するそうです。",
        hiragana: "うわさによると、かれはけっこんするそうです。",
        romaji: "Uwasa ni yoru to, kare wa kekkon suru sō desu.",
        traducao: "Segundo boatos, ele vai se casar.",
      },
      {
        exemplo: "噂に振り回されるのは良くありません。",
        hiragana: "うわさにふりまわされるのはよくありません。",
        romaji: "Uwasa ni furimawasareru no wa yoku arimasen.",
        traducao: "Não é bom ser influenciado por boatos.",
      },
      {
        exemplo: "噂では彼女は有名な歌手だと言われています。",
        hiragana: "うわさではかのじょはゆうめいなかしゅだといわれています。",
        romaji: "Uwasa de wa kanojo wa yūmei na kashu da to iwareteimasu.",
        traducao:
          "Dizem que ela é uma cantora famosa, de acordo com os boatos.",
      },
    ],
  },
  {
    vocabulario: "ルーム",
    romaji: "rūmu",
    hiragana: "るーむ",
    traducao: "quarto",
    exemplosFrases: [
      {
        exemplo: "ホテルのルームサービスを利用しました。",
        hiragana: "ほてるのるーむさーびすをりようしました。",
        romaji: "Hoteru no rūmu sābisu o riyō shimashita.",
        traducao: "Usei o serviço de quarto do hotel.",
      },
      {
        exemplo: "新しいアパートに引っ越して、広いルームを持っています。",
        hiragana:
          "あたらしいあぱーとにひっこして、ひろいるーむをもっています。",
        romaji: "Atarashii apāto ni hikkoshite, hiroi rūmu o motteimasu.",
        traducao: "Mudei para um novo apartamento e tenho um quarto espaçoso.",
      },
      {
        exemplo: "ルームメイトと家賃を分担しています。",
        hiragana: "るーむめいととやちんをぶんたんしています。",
        romaji: "Rūmu meito to yachin o buntan shiteimasu.",
        traducao: "Divido o aluguel com meu colega de quarto.",
      },
    ],
  },
  {
    vocabulario: "衛星",
    romaji: "eisei",
    hiragana: "えいせい",
    traducao: "satélite",
    exemplosFrases: [
      {
        exemplo: "宇宙からの衛星写真を見ました。",
        hiragana: "うちゅうからのえいせいしゃしんをみました。",
        romaji: "Uchū kara no eisei shashin o mimashita.",
        traducao: "Vi fotos de satélite do espaço.",
      },
      {
        exemplo: "通信衛星は情報の伝送に重要な役割を果たしています。",
        hiragana:
          "つうしんえいせいはじょうほうのでんそうにじゅうようなやくわりをはたしています。",
        romaji:
          "Tsūshin eisei wa jōhō no densō ni jūyō na yakuwari o hatashiteimasu.",
        traducao:
          "Os satélites de comunicação desempenham um papel importante na transmissão de informações.",
      },
      {
        exemplo: "地球の衛星として月があります。",
        hiragana: "ちきゅうのえいせいとしてつきがあります。",
        romaji: "Chikyū no eisei toshite tsuki ga arimasu.",
        traducao: "A Lua é um satélite da Terra.",
      },
    ],
  },
  {
    vocabulario: "引退",
    romaji: "intai",
    hiragana: "いんたい",
    traducao: "aposentadoria",
    exemplosFrases: [
      {
        exemplo: "彼は長いキャリアの後、引退しました。",
        hiragana: "かれはながいきゃりあのあと、いんたいしました。",
        romaji: "Kare wa nagai kyaria no ato, intai shimashita.",
        traducao: "Ele se aposentou depois de uma longa carreira.",
      },
      {
        exemplo: "引退後はのんびりと過ごしたいです。",
        hiragana: "いんたいごはのんびりとすごしたいです。",
        romaji: "Intai-go wa nonbiri to sugoshitai desu.",
        traducao:
          "Quero aproveitar a vida tranquilamente após a aposentadoria.",
      },
      {
        exemplo: "引退を決めるのは難しい決断です。",
        hiragana: "いんたいをきめるのはむずかしいけつだんです。",
        romaji: "Intai o kimeru no wa muzukashii ketsudan desu.",
        traducao: "Decidir se aposentar é uma decisão difícil.",
      },
    ],
  },
  {
    vocabulario: "安全",
    romaji: "anzen",
    hiragana: "あんぜん",
    traducao: "segurança",
    exemplosFrases: [
      {
        exemplo: "交通安全に気をつけましょう。",
        hiragana: "こうつうあんぜんにきをつけましょう。",
        romaji: "Kōtsū anzen ni ki o tsukemashō.",
        traducao: "Vamos ter cuidado com a segurança no trânsito.",
      },
      {
        exemplo: "火災から身を守るために安全訓練を受けました。",
        hiragana: "かさいからみをまもるためにあんぜんくんれんをうけました。",
        romaji: "Kasai kara mi o mamoru tame ni anzen kunren o ukemashita.",
        traducao:
          "Recebi treinamento de segurança para me proteger de incêndios.",
      },
      {
        exemplo: "旅行先での安全について調べることが重要です。",
        hiragana:
          "りょこうさきでのあんぜんについてしらべることがじゅうようです。",
        romaji: "Ryokō-saki de no anzen ni tsuite shiraberu koto ga jūyō desu.",
        traducao:
          "É importante pesquisar sobre a segurança no destino da viagem.",
      },
    ],
  },
  {
    vocabulario: "サービス",
    romaji: "sābisu",
    hiragana: "さーびす",
    traducao: "serviço",
    exemplosFrases: [
      {
        exemplo: "このレストランのサービスは素晴らしいです。",
        hiragana: "このれすとらんのさーびすはすばらしいです。",
        romaji: "Kono resutoran no sābisu wa subarashii desu.",
        traducao: "O serviço neste restaurante é excelente.",
      },
      {
        exemplo: "ホテルで無料の朝食サービスがあります。",
        hiragana: "ほてるでむりょうのちょうしょくさーびすがあります。",
        romaji: "Hoteru de muryō no chōshoku sābisu ga arimasu.",
        traducao: "O hotel oferece serviço de café da manhã gratuito.",
      },
      {
        exemplo: "通信速度を上げるためにインターネットサービスを変更しました。",
        hiragana:
          "つうしんそくどをあげるためにいんたーねっとさーびすをへんこうしました。",
        romaji:
          "Tsūshin sokudo o ageru tame ni intānetto sābisu o henkō shimashita.",
        traducao:
          "Mudei o provedor de internet para aumentar a velocidade de conexão.",
      },
    ],
  },
  {
    vocabulario: "ごめん",
    romaji: "gomen",
    hiragana: "ごめん",
    traducao: "desculpa",
    exemplosFrases: [
      {
        exemplo: "ごめんなさい、遅れてしまいました。",
        hiragana: "ごめんなさい、おくれてしまいました。",
        romaji: "Gomen nasai, okurete shimaimashita.",
        traducao: "Desculpe, estou atrasado(a).",
      },
      {
        exemplo: "ごめんなさい、間違えました。",
        hiragana: "ごめんなさい、まちがえました。",
        romaji: "Gomen nasai, machigaemashita.",
        traducao: "Desculpe, cometi um erro.",
      },
      {
        exemplo: "ごめんなさい、気をつけます。",
        hiragana: "ごめんなさい、きをつけます。",
        romaji: "Gomen nasai, ki o tsukemasu.",
        traducao: "Desculpe, vou ter cuidado.",
      },
    ],
  },
  {
    vocabulario: "システム",
    romaji: "shisutemu",
    hiragana: "しすてむ",
    traducao: "sistema",
    exemplosFrases: [
      {
        exemplo: "このシステムは便利です。",
        hiragana: "このしすてむはべんりです。",
        romaji: "Kono shisutemu wa benri desu.",
        traducao: "Este sistema é conveniente.",
      },
      {
        exemplo: "システムをアップデートしました。",
        hiragana: "しすてむをあっぷでーとしました。",
        romaji: "Shisutemu o appudēto shimashita.",
        traducao: "Atualizei o sistema.",
      },
      {
        exemplo: "このシステムの仕組みを理解しましょう。",
        hiragana: "このしすてむのしくみをりかいしましょう。",
        romaji: "Kono shisutemu no shikumi o rikai shimashō.",
        traducao: "Vamos entender a estrutura deste sistema.",
      },
    ],
  },
  {
    vocabulario: "ソファー",
    romaji: "sofā",
    hiragana: "そふぁー",
    traducao: "sofá",
    exemplosFrases: [
      {
        exemplo: "ソファーでくつろいでいます。",
        hiragana: "そふぁーでくつろいでいます。",
        romaji: "Sofā de kutsuroideimasu.",
        traducao: "Estou relaxando no sofá.",
      },
      {
        exemplo: "新しいソファーを買いました。",
        hiragana: "あたらしいそふぁーをかいました。",
        romaji: "Atarashii sofā o kaimashita.",
        traducao: "Comprei um sofá novo.",
      },
      {
        exemplo: "ソファーに座ってテレビを見ています。",
        hiragana: "そふぁーにすわっててれびをみています。",
        romaji: "Sofā ni suwatte terebi o miteimasu.",
        traducao: "Estou sentado(a) no sofá assistindo TV.",
      },
    ],
  },
  {
    vocabulario: "影",
    romaji: "kage",
    hiragana: "かげ",
    traducao: "sombra",
    exemplosFrases: [
      {
        exemplo: "木漏れ日の中に影が揺れている。",
        hiragana: "こもれびのなかにかげがゆれている。",
        romaji: "Komorebi no naka ni kage ga yureteiru.",
        traducao:
          "Uma sombra está balançando dentro do raio de sol filtrado pelas árvores.",
      },
      {
        exemplo: "影が延びていく様子が美しいです。",
        hiragana: "かげがのびていくようすがうつくしいです。",
        romaji: "Kage ga nobiteiku yōsu ga utsukushii desu.",
        traducao: "A forma como a sombra se estende é bonita.",
      },
      {
        exemplo: "夕日が建物の影に隠れていく。",
        hiragana: "ゆうひがたてもののかげにかくれていく。",
        romaji: "Yūhi ga tatemono no kage ni kakureteiku.",
        traducao:
          "O sol se escondendo atrás das sombras dos prédios ao entardecer.",
      },
    ],
  },
  {
    vocabulario: "スープ",
    romaji: "sūpu",
    hiragana: "すーぷ",
    traducao: "sopa",
    exemplosFrases: [
      {
        exemplo: "温かいスープが飲みたいです。",
        hiragana: "あたたかいすーぷがのみたいです。",
        romaji: "Atatakai sūpu ga nomitai desu.",
        traducao: "Quero beber uma sopa quente.",
      },
      {
        exemplo: "自家製の野菜スープを作りました。",
        hiragana: "じかせいのやさいすーぷをつくりました。",
        romaji: "Jikasei no yasai sūpu o tsukurimashita.",
        traducao: "Fiz uma sopa de legumes caseira.",
      },
      {
        exemplo: "冷たいスープは夏にぴったりです。",
        hiragana: "つめたいすーぷはなつにぴったりです。",
        romaji: "Tsumetai sūpu wa natsu ni pittari desu.",
        traducao: "Sopa fria é perfeita para o verão.",
      },
    ],
  },
  {
    vocabulario: "ソフト",
    romaji: "sofuto",
    hiragana: "そふと",
    traducao: "software",
    exemplosFrases: [
      {
        exemplo: "このソフトは使いやすいです。",
        hiragana: "このそふとはつかいやすいです。",
        romaji: "Kono sofuto wa tsukaiyasui desu.",
        traducao: "Este software é fácil de usar.",
      },
      {
        exemplo: "ソフトをインストールする手順を教えてください。",
        hiragana: "そふとをいんすとーるするてじゅんをおしえてください。",
        romaji: "Sofuto o insutōru suru tejun o oshiete kudasai.",
        traducao: "Por favor, me ensine os passos para instalar o software.",
      },
      {
        exemplo: "新しいソフトを開発しています。",
        hiragana: "あたらしいそふとをかいはつしています。",
        romaji: "Atarashii sofuto o kaihatsu shiteimasu.",
        traducao: "Estou desenvolvendo um novo software.",
      },
    ],
  },
  {
    vocabulario: "ジュース",
    romaji: "jūsu",
    hiragana: "じゅーす",
    traducao: "suco",
    exemplosFrases: [
      {
        exemplo: "オレンジジュースを飲みたいです。",
        hiragana: "おれんじじゅーすをのみたいです。",
        romaji: "Orenji jūsu o nomitai desu.",
        traducao: "Quero beber suco de laranja.",
      },
      {
        exemplo: "人気のあるフルーツジュースを注文しました。",
        hiragana: "にんきのあるふるーつじゅーすをちゅうもんしました。",
        romaji: "Ninki no aru furūtsu jūsu o chūmon shimashita.",
        traducao: "Pedi um suco de frutas popular.",
      },
      {
        exemplo: "食事には健康的なジュースを飲むようにしています。",
        hiragana: "しょくじにはけんこうてきなじゅーすをのむようにしています。",
        romaji: "Shokuji ni wa kenkōteki na jūsu o nomu yō ni shiteimasu.",
        traducao: "Tento beber sucos saudáveis durante as refeições.",
      },
    ],
  },
  {
    vocabulario: "科目",
    romaji: "kamoku",
    hiragana: "かもく",
    traducao: "matéria (escolar)",
    exemplosFrases: [
      {
        exemplo: "学校で何科目を勉強していますか？",
        hiragana: "がっこうでなんかもくをべんきょうしていますか？",
        romaji: "Gakkō de nanka moku o benkyō shiteimasu ka?",
        traducao: "Quais matérias você estuda na escola?",
      },
      {
        exemplo: "得意な科目は数学です。",
        hiragana: "とくいなかもくはすうがくです。",
        romaji: "Tokui na kamoku wa sūgaku desu.",
        traducao: "Minha matéria favorita é matemática.",
      },
      {
        exemplo: "科目ごとに勉強時間を設けています。",
        hiragana: "かもくごとにべんきょうじかんをもうけています。",
        romaji: "Kamoku goto ni benkyō jikan o motsuketeimasu.",
        traducao: "Estabeleço um tempo de estudo para cada matéria.",
      },
    ],
  },
  {
    vocabulario: "おもて",
    romaji: "omote",
    hiragana: "おもて",
    traducao: "frente",
    exemplosFrases: [
      {
        exemplo: "建物のおもてに花壇があります。",
        hiragana: "たてもののおもてにかだんがあります。",
        romaji: "Tatemono no omote ni kadan ga arimasu.",
        traducao: "Há um canteiro de flores na frente do prédio.",
      },
      {
        exemplo: "おもてのドアを開けてください。",
        hiragana: "おもてのどあをあけてください。",
        romaji: "Omote no doa o akete kudasai.",
        traducao: "Por favor, abra a porta da frente.",
      },
      {
        exemplo: "建物のおもてに広場があります。",
        hiragana: "たてもののおもてにひろばがあります。",
        romaji: "Tatemono no omote ni hiroba ga arimasu.",
        traducao: "Há uma praça na frente do prédio.",
      },
    ],
  },
  {
    vocabulario: "サプリ",
    romaji: "sapuri",
    hiragana: "さぷり",
    traducao: "suplemento",
    exemplosFrases: [
      {
        exemplo: "毎日サプリを飲んで健康に気をつけています。",
        hiragana: "まいにちさぷりをのんでけんこうにきをつけています。",
        romaji: "Mainichi sapuri o nonde kenkō ni ki o tsuketeimasu.",
        traducao: "Tomo suplementos todos os dias e cuido da minha saúde.",
      },
      {
        exemplo: "ビタミンCのサプリメントを摂取しています。",
        hiragana: "びたみんCのさぷりめんとをせっしゅしています。",
        romaji: "Bitamin C no sapurimento o sesshoku shiteimasu.",
        traducao: "Estou tomando suplemento de vitamina C.",
      },
      {
        exemplo: "サプリを飲むことで栄養を補っています。",
        hiragana: "さぷりをのむことでえいようをおっています。",
        romaji: "Sapuri o nomu koto de eiyō o otteimasu.",
        traducao: "Estou complementando a minha nutrição tomando suplementos.",
      },
    ],
  },
  {
    vocabulario: "サプリメント",
    romaji: "sapurimento",
    hiragana: "さぷりめんと",
    traducao: "suplemento",
    exemplosFrases: [
      {
        exemplo: "健康のためにサプリメントを摂取しています。",
        hiragana: "けんこうのためにさぷりめんとをせっしゅしています。",
        romaji: "Kenkō no tame ni sapurimento o sesshoku shiteimasu.",
        traducao: "Estou tomando suplementos para a saúde.",
      },
      {
        exemplo: "ビタミンCのサプリメントを飲んで免疫力を高めましょう。",
        hiragana:
          "びたみんCのさぷりめんとをのんでめんえきりょくをたかめましょう。",
        romaji: "Bitamin C no sapurimento o nonde men'eki-ryoku o takamemashō.",
        traducao:
          "Vamos aumentar a imunidade tomando suplementos de vitamina C.",
      },
      {
        exemplo: "サプリメントは健康維持に役立ちます。",
        hiragana: "さぷりめんとはけんこういじにやくだちます。",
        romaji: "Sapurimento wa kenkō iji ni yakudachimasu.",
        traducao: "Os suplementos são úteis para a manutenção da saúde.",
      },
    ],
  },
  {
    vocabulario: "蓋",
    romaji: "futa",
    hiragana: "ふた",
    traducao: "tampa",
    exemplosFrases: [
      {
        exemplo: "ビンの蓋をしっかり閉めてください。",
        hiragana: "びんのふたをしっかりとじてください。",
        romaji: "Bin no futa o shikkari tojite kudasai.",
        traducao: "Por favor, feche bem a tampa da garrafa.",
      },
      {
        exemplo: "この容器には蓋がついています。",
        hiragana: "このようきにはふたがついています。",
        romaji: "Kono yōki ni wa futa ga tsuiteimasu.",
        traducao: "Este recipiente tem uma tampa.",
      },
      {
        exemplo: "蓋を開けると中に美味しい匂いが広がります。",
        hiragana: "ふたをあけるとなかにおいしいにおいがひろがります。",
        romaji: "Futa o akeru to naka ni oishii nioi ga hirogarimasu.",
        traducao:
          "Quando abrimos a tampa, um delicioso cheiro se espalha de dentro.",
      },
    ],
  },
  {
    vocabulario: "ゆうべ",
    romaji: "yūbe",
    hiragana: "ゆうべ",
    traducao: "ontem à noite",
    exemplosFrases: [
      {
        exemplo: "ゆうべは友達と映画を見に行きました。",
        hiragana: "ゆうべはともだちとえいがをみにいきました。",
        romaji: "Yūbe wa tomodachi to eiga o mi ni ikimashita.",
        traducao: "Ontem à noite fui ao cinema com meus amigos.",
      },
      {
        exemplo: "ゆうべは早く寝たほうがいいです。",
        hiragana: "ゆうべははやくねたほうがいいです。",
        romaji: "Yūbe wa hayaku neta hō ga ii desu.",
        traducao: "É melhor dormir cedo ontem à noite.",
      },
      {
        exemplo: "ゆうべはおいしいディナーを食べました。",
        hiragana: "ゆうべはおいしいでぃなーをたべました。",
        romaji: "Yūbe wa oishii dinā o tabemashita.",
        traducao: "Ontem à noite, comi um jantar delicioso.",
      },
    ],
  },
  {
    vocabulario: "家事",
    romaji: "kaji",
    hiragana: "かじ",
    traducao: "tarefas domésticas",
    exemplosFrases: [
      {
        exemplo: "家事を手伝うことは大切です。",
        hiragana: "かじをてつだうことはたいせつです。",
        romaji: "Kaji o tetsudau koto wa taisetsu desu.",
        traducao: "É importante ajudar nas tarefas domésticas.",
      },
      {
        exemplo: "毎日の家事を効率的にこなすためのコツを教えてください。",
        hiragana:
          "まいにちのかじをこうりつてきにこなすためのこつをおしえてください。",
        romaji:
          "Mainichi no kaji o kōritsuteki ni konasu tame no kotsu o oshiete kudasai.",
        traducao:
          "Por favor, me ensine dicas para realizar as tarefas domésticas diárias de forma eficiente.",
      },
      {
        exemplo: "家事を分担することで負担を減らすことができます。",
        hiragana: "かじをぶんたんすることでふたんをへらすことができます。",
        romaji: "Kaji o buntan suru koto de futan o herasu koto ga dekimasu.",
        traducao: "Podemos reduzir a carga ao dividir as tarefas domésticas.",
      },
    ],
  },
  {
    vocabulario: "テレビ",
    romaji: "terebi",
    hiragana: "てれび",
    traducao: "televisão",
    exemplosFrases: [
      {
        exemplo: "テレビを見る時間を減らして勉強に集中しましょう。",
        hiragana:
          "てれびをみるじかんをへらしてべんきょうにしゅうちゅうしましょう。",
        romaji: "Terebi o miru jikan o herashite benkyō ni shūchū shimashō.",
        traducao:
          "Vamos reduzir o tempo de assistir televisão e nos concentrar nos estudos.",
      },
      {
        exemplo: "最近のテレビ番組は面白くないです。",
        hiragana: "さいきんのてれびばんぐみはおもしろくないです。",
        romaji: "Saikin no terebi bangumi wa omoshirokunai desu.",
        traducao: "Os programas de televisão recentes não são interessantes.",
      },
      {
        exemplo: "このテレビは画面が大きくて綺麗です。",
        hiragana: "このてれびはがめんがおおきくてきれいです。",
        romaji: "Kono terebi wa gamen ga ōkikute kirei desu.",
        traducao: "Esta televisão tem uma tela grande e bonita.",
      },
    ],
  },
  {
    vocabulario: "温度",
    romaji: "ondo",
    hiragana: "おんど",
    traducao: "temperatura",
    exemplosFrases: [
      {
        exemplo: "室内の温度が高すぎると快適ではありません。",
        hiragana: "しつないのおんどがたかすぎるとかいてきではありません。",
        romaji: "Shitsunai no ondo ga takasugiru to kaiteki de wa arimasen.",
        traducao:
          "Não é confortável quando a temperatura ambiente está muito alta.",
      },
      {
        exemplo: "外の温度が低いので暖かい服を着て出かけましょう。",
        hiragana:
          "そとのおんどがひくいのであたたかいふくをきてでかけましょう。",
        romaji: "Soto no ondo ga hikui node atatakai fuku o kite dekakemashō.",
        traducao:
          "Como a temperatura externa está baixa, vamos vestir roupas quentes ao sair.",
      },
      {
        exemplo: "体の温度を測るために体温計を使用します。",
        hiragana: "からだのおんどをはかるためにたいおんけいをしようします。",
        romaji: "Karada no ondo o hakaru tame ni taionkei o shiyō shimasu.",
        traducao: "Vamos usar um termômetro para medir a temperatura corporal.",
      },
    ],
  },
  {
    vocabulario: "とき",
    romaji: "toki",
    hiragana: "とき",
    traducao: "momento",
    exemplosFrases: [
      {
        exemplo: "適切なときに行動することが大切です。",
        hiragana: "てきせつなときにこうどうすることがたいせつです。",
        romaji: "Tekisetsu na toki ni kōdō suru koto ga taisetsu desu.",
        traducao: "É importante agir no momento adequado.",
      },
      {
        exemplo: "旅行のときはたくさん写真を撮ります。",
        hiragana: "りょこうのときはたくさんしゃしんをとります。",
        romaji: "Ryokō no toki wa takusan shashin o torimasu.",
        traducao: "Tiro muitas fotos durante as viagens.",
      },
      {
        exemplo: "大切な人と過ごす時間は宝です。",
        hiragana: "たいせつなひととすごすじかんはたからです。",
        romaji: "Taisetsu na hito to sugosu jikan wa takara desu.",
        traducao: "O tempo gasto com pessoas importantes é um tesouro.",
      },
    ],
  },
  {
    vocabulario: "火曜",
    romaji: "kayō",
    hiragana: "かよう",
    traducao: "terça-feira",
    exemplosFrases: [
      {
        exemplo: "火曜日は日本語の授業があります。",
        hiragana: "かようびはにほんごのじゅぎょうがあります。",
        romaji: "Kayōbi wa nihongo no jugyō ga arimasu.",
        traducao: "Na terça-feira, tenho aula de japonês.",
      },
      {
        exemplo: "火曜日は友達とランチに行く予定です。",
        hiragana: "かようびはともだちとらんちにいくよていです。",
        romaji: "Kayōbi wa tomodachi to ranchi ni iku yotei desu.",
        traducao:
          "Tenho planos de sair para almoçar com meus amigos na terça-feira.",
      },
      {
        exemplo: "火曜日はスポーツクラブに通っています。",
        hiragana: "かようびはすぽーつくらぶにかよっています。",
        romaji: "Kayōbi wa supōtsu kurabu ni kayotteimasu.",
        traducao: "Na terça-feira, vou à academia de esportes.",
      },
    ],
  },
  {
    vocabulario: "グラウンド",
    romaji: "guraundo",
    hiragana: "ぐらうんど",
    traducao: "campo",
    exemplosFrases: [
      {
        exemplo: "サッカーの試合はグラウンドで行われます。",
        hiragana: "さっかーのしあいはぐらうんどでおこなわれます。",
        romaji: "Sakkā no shiai wa guraundo de okonawaremasu.",
        traducao: "As partidas de futebol acontecem no campo.",
      },
      {
        exemplo: "グラウンドでピクニックを楽しみましょう。",
        hiragana: "ぐらうんどでぴくにっくをたのしみましょう。",
        romaji: "Guraundo de pikunikku o tanoshimimashō.",
        traducao: "Vamos aproveitar um piquenique no campo.",
      },
      {
        exemplo: "子供たちはグラウンドで元気に遊んでいます。",
        hiragana: "こどもたちはぐらうんどでげんきにあそんでいます。",
        romaji: "Kodomo-tachi wa guraundo de genki ni asondeimasu.",
        traducao: "As crianças estão brincando animadamente no campo.",
      },
    ],
  },
  {
    vocabulario: "ボウル",
    romaji: "bōru",
    hiragana: "ぼうる",
    traducao: "tigela",
    exemplosFrases: [
      {
        exemplo: "ボウルにスープを入れます。",
        hiragana: "ぼうるにすーぷをいれます。",
        romaji: "Bōru ni sūpu o iremasu.",
        traducao: "Eu coloco sopa na tigela.",
      },
      {
        exemplo: "ボウルを使ってサラダを混ぜます。",
        hiragana: "ぼうるをつかってさらだをまぜます。",
        romaji: "Bōru o tsukatte sarada o mazemasu.",
        traducao: "Eu misturo a salada usando uma tigela.",
      },
      {
        exemplo: "ボウルが割れてしまいました。",
        hiragana: "ぼうるがわれてしまいました。",
        romaji: "Bōru ga warete shimaimashita.",
        traducao: "A tigela quebrou.",
      },
    ],
  },
  {
    vocabulario: "インク",
    romaji: "inku",
    hiragana: "いんく",
    traducao: "tinta",
    exemplosFrases: [
      {
        exemplo: "インクが切れたので買いに行きます。",
        hiragana: "いんくがきれたのでかいにいきます。",
        romaji: "Inku ga kireta node kai ni ikimasu.",
        traducao: "A tinta acabou, então vou comprar mais.",
      },
      {
        exemplo: "このインクは乾きが早いです。",
        hiragana: "このいんくはかわきがはやいです。",
        romaji: "Kono inku wa kawaki ga hayai desu.",
        traducao: "Esta tinta seca rapidamente.",
      },
      {
        exemplo: "インクが服についてしまいました。",
        hiragana: "いんくがふくについてしまいました。",
        romaji: "Inku ga fuku ni tsuite shimaimashita.",
        traducao: "A tinta manchou minhas roupas.",
      },
    ],
  },
  {
    vocabulario: "おじ",
    romaji: "oji",
    hiragana: "おじ",
    traducao: "tio (irmão mais velho do pai ou da mãe)",
    exemplosFrases: [
      {
        exemplo: "おじの家に遊びに行きます。",
        hiragana: "おじのいえにあそびにいきます。",
        romaji: "Oji no ie ni asobi ni ikimasu.",
        traducao: "Vou visitar a casa do meu tio.",
      },
      {
        exemplo: "おじの話はいつも面白いです。",
        hiragana: "おじのはなしはいつもおもしろいです。",
        romaji: "Oji no hanashi wa itsumo omoshiroi desu.",
        traducao: "As histórias do meu tio são sempre interessantes.",
      },
      {
        exemplo: "おじは料理が上手です。",
        hiragana: "おじはりょうりがじょうずです。",
        romaji: "Oji wa ryōri ga jōzu desu.",
        traducao: "Meu tio é bom em cozinhar.",
      },
    ],
  },
  {
    vocabulario: "タイトル",
    romaji: "taitoru",
    hiragana: "たいとる",
    traducao: "título",
    exemplosFrases: [
      {
        exemplo: "この本のタイトルは「冒険の旅」というです。",
        hiragana: "このほんのたいとるは「ぼうけんのたび」というです。",
        romaji: "Kono hon no taitoru wa 'bōken no tabi' to iu desu.",
        traducao: "O título deste livro é 'A Viagem da Aventura'.",
      },
      {
        exemplo: "映画のタイトルを忘れてしまいました。",
        hiragana: "えいがのたいとるをわすれてしまいました。",
        romaji: "Eiga no taitoru o wasurete shimaimashita.",
        traducao: "Esqueci o título do filme.",
      },
      {
        exemplo: "この記事のタイトルは興味を引きます。",
        hiragana: "このきじのたいとるはきょうみをひきます。",
        romaji: "Kono kiji no taitoru wa kyōmi o hikimasu.",
        traducao: "O título deste artigo desperta interesse.",
      },
    ],
  },
  {
    vocabulario: "タオル",
    romaji: "taoru",
    hiragana: "たおる",
    traducao: "toalha",
    exemplosFrases: [
      {
        exemplo: "シャワーを浴びた後、タオルで体を拭きます。",
        hiragana: "しゃわーをあびたあと、たおるでからだをふきます。",
        romaji: "Shawā o abita ato, taoru de karada o fukimasu.",
        traducao: "Após tomar banho, seque o corpo com uma toalha.",
      },
      {
        exemplo: "プールでタオルを使って頭を乾かします。",
        hiragana: "ぷーるでたおるをつかってあたまをかわかします。",
        romaji: "Pūru de taoru o tsukatte atama o kawakashimasu.",
        traducao: "Na piscina, seque a cabeça com uma toalha.",
      },
      {
        exemplo: "旅行に行くときは必ずタオルを持っています。",
        hiragana: "りょこうにいくときはかならずたおるをもっています。",
        romaji: "Ryokō ni iku toki wa kanarazu taoru o motteimasu.",
        traducao: "Sempre levo uma toalha quando viajo.",
      },
    ],
  },
  {
    vocabulario: "トマト",
    romaji: "tomato",
    hiragana: "とまと",
    traducao: "tomate",
    exemplosFrases: [
      {
        exemplo: "サラダに新鮮なトマトを加えます。",
        hiragana: "さらだにしんせんなとまとをくわえます。",
        romaji: "Sarada ni shinsen na tomato o kuwaemasu.",
        traducao: "Adiciono tomate fresco à salada.",
      },
      {
        exemplo: "トマトはビタミンが豊富です。",
        hiragana: "とまとはびたみんがほうふです。",
        romaji: "Tomato wa bitamin ga hōfu desu.",
        traducao: "O tomate é rico em vitamina.",
      },
      {
        exemplo: "夏になるとトマトがおいしい季節です。",
        hiragana: "なつになるととまとがおいしいきせつです。",
        romaji: "Natsu ni naru to tomato ga oishii kisetsu desu.",
        traducao: "No verão, é a estação em que os tomates são deliciosos.",
      },
    ],
  },
  {
    vocabulario: "テーマ",
    romaji: "tēma",
    hiragana: "てーま",
    traducao: "tema",
    exemplosFrases: [
      {
        exemplo: "プレゼンテーションのテーマを決めます。",
        hiragana: "ぷれぜんてーしょんのてーまをきめます。",
        romaji: "Purezentēshon no tēma o kimemasu.",
        traducao: "Decidimos o tema da apresentação.",
      },
      {
        exemplo: "この本のテーマは友情です。",
        hiragana: "このほんのてーまはゆうじょうです。",
        romaji: "Kono hon no tēma wa yūjō desu.",
        traducao: "O tema deste livro é amizade.",
      },
      {
        exemplo: "テーマパークで楽しい時間を過ごしました。",
        hiragana: "てーまぱーくでたのしいじかんをすごしました。",
        romaji: "Tēma pāku de tanoshii jikan o sugoshimashita.",
        traducao: "Passei um tempo divertido no parque temático.",
      },
    ],
  },
  {
    vocabulario: "トップ",
    romaji: "toppu",
    hiragana: "とっぷ",
    traducao: "topo",
    exemplosFrases: [
      {
        exemplo: "山のトップに登りたいです。",
        hiragana: "やまのとっぷにのぼりたいです。",
        romaji: "Yama no toppu ni noboritai desu.",
        traducao: "Eu quero subir até o topo da montanha.",
      },
      {
        exemplo: "試合でトップの成績を収めました。",
        hiragana: "しあいでとっぷのせいせきをおさめました。",
        romaji: "Shiai de toppu no seiseki o osamemashita.",
        traducao: "Obtive o melhor resultado no jogo.",
      },
      {
        exemplo: "新製品が市場のトップに立ちました。",
        hiragana: "しんせいひんがしじょうのとっぷにたちました。",
        romaji: "Shinseihin ga shijō no toppu ni tachimashita.",
        traducao: "O novo produto se destacou no topo do mercado.",
      },
    ],
  },
  {
    vocabulario: "咳",
    romaji: "seki",
    hiragana: "せき",
    traducao: "tosse",
    exemplosFrases: [
      {
        exemplo: "咳が出るので、お医者さんに診てもらいました。",
        hiragana: "せきがでるので、おいしゃさんにみてもらいました。",
        romaji: "Seki ga deru node, oishasan ni mite moraimashita.",
        traducao: "Como eu estava tossindo, fui ao médico para ser examinado.",
      },
      {
        exemplo: "咳をするときは、口を手で覆ってください。",
        hiragana: "せきをするときは、くちをてでおおってください。",
        romaji: "Seki o suru toki wa, kuchi o te de ōtte kudasai.",
        traducao: "Ao tossir, cubra a boca com a mão.",
      },
      {
        exemplo: "咳が治まったので、安心しました。",
        hiragana: "せきがおさまったので、あんしんしました。",
        romaji: "Seki ga osamatta node, anshin shimashita.",
        traducao: "Como a tosse parou, fiquei aliviado.",
      },
    ],
  },
  {
    vocabulario: "サラリーマン",
    romaji: "sararīman",
    hiragana: "さらりーまん",
    traducao: "funcionário de escritório",
    exemplosFrases: [
      {
        exemplo: "父はサラリーマンで忙しいです。",
        hiragana: "ちちはさらりーまんでいそがしいです。",
        romaji: "Chichi wa sararīman de isogashii desu.",
        traducao: "Meu pai é um funcionário de escritório e está ocupado.",
      },
      {
        exemplo: "サラリーマンとして働くことに慣れてきました。",
        hiragana: "さらりーまんとしてはたらくことになれてきました。",
        romaji: "Sararīman toshite hataraku koto ni narete kimashita.",
        traducao:
          "Estou me acostumando a trabalhar como funcionário de escritório.",
      },
      {
        exemplo: "サラリーマンは通勤時間が長いことが多いです。",
        hiragana: "さらりーまんはつうきんじかんがながいことがおおいです。",
        romaji: "Sararīman wa tsūkin jikan ga nagai koto ga ōi desu.",
        traducao:
          "Os funcionários de escritório geralmente têm longos tempos de deslocamento.",
      },
    ],
  },
  {
    vocabulario: "チームワーク",
    romaji: "chīmuwāku",
    hiragana: "ちーむわーく",
    traducao: "trabalho em equipe",
    exemplosFrases: [
      {
        exemplo: "チームワークが重要です。",
        hiragana: "ちーむわーくがじゅうようです。",
        romaji: "Chīmuwāku ga jūyō desu.",
        traducao: "O trabalho em equipe é importante.",
      },
      {
        exemplo: "このプロジェクトでは、良いチームワークが求められます。",
        hiragana: "このぷろじぇくとでは、よいちーむわーくがもとめられます。",
        romaji: "Kono purojekuto de wa, yoi chīmuwāku ga motomeraremasu.",
        traducao:
          "Neste projeto, é necessário ter uma boa colaboração em equipe.",
      },
      {
        exemplo: "チームワークを高めるためにコミュニケーションが重要です。",
        hiragana:
          "ちーむわーくをたかめるためにこみゅにけーしょんがじゅうようです。",
        romaji: "Chīmuwāku o takameru tame ni komyunikēshon ga jūyō desu.",
        traducao:
          "A comunicação é importante para melhorar o trabalho em equipe.",
      },
    ],
  },
  {
    vocabulario: "コーチ",
    romaji: "kōchi",
    hiragana: "こーち",
    traducao: "treinador",
    exemplosFrases: [
      {
        exemplo: "サッカーチームのコーチは厳しいですが、優れた指導者です。",
        hiragana:
          "さっかーちーむのこーちはきびしいですが、すぐれたしどうしゃです。",
        romaji:
          "Sakkā chīmu no kōchi wa kibishii desu ga, sugureta shidōsha desu.",
        traducao:
          "O treinador da equipe de futebol é rigoroso, mas um excelente líder.",
      },
      {
        exemplo: "コーチのアドバイスに従って練習しました。",
        hiragana: "こーちのあどばいすにしたがってれんしゅうしました。",
        romaji: "Kōchi no adobaisu ni shitagatte renshū shimashita.",
        traducao: "Pratiquei seguindo os conselhos do treinador.",
      },
      {
        exemplo: "私のコーチは私を励ましてくれます。",
        hiragana: "わたしのこーちはわたしをはげましてくれます。",
        romaji: "Watashi no kōchi wa watashi o hagemashite kuremasu.",
        traducao: "Meu treinador me encoraja.",
      },
    ],
  },
  {
    vocabulario: "トレーニング",
    romaji: "torēningu",
    hiragana: "とれーにんぐ",
    traducao: "treinamento",
    exemplosFrases: [
      {
        exemplo: "毎日のトレーニングで体力を向上させます。",
        hiragana: "まいにちのとれーにんぐでたいりょくをこうじょうさせます。",
        romaji: "Mainichi no torēningu de tairyoku o kōjō sasemasu.",
        traducao: "Melhoro minha resistência física com o treinamento diário.",
      },
      {
        exemplo: "トレーニングの一環としてストレッチを行います。",
        hiragana: "とれーにんぐのいっかんとしてすとれっちをおこないます。",
        romaji: "Torēningu no ikkan toshite sutoretchi o okonaimasu.",
        traducao: "Realizamos alongamentos como parte do treinamento.",
      },
      {
        exemplo: "トレーニング中には水分補給を忘れないようにしましょう。",
        hiragana:
          "とれーにんぐちゅうにはすいぶんほきゅうをわすれないようにしましょう。",
        romaji: "Torēningu chū ni wa suibun hokyū o wasurenai yō ni shimashō.",
        traducao:
          "Certifique-se de não se esquecer de se hidratar durante o treinamento.",
      },
    ],
  },
  {
    vocabulario: "パイプ",
    romaji: "paipu",
    hiragana: "ぱいぷ",
    traducao: "contato, conexão",
    exemplosFrases: [
      {
        exemplo: "彼はそのプロジェクトのパイプを持っています。",
        hiragana: "かれはそのぷろじぇくとのぱいぷをもっています。",
        romaji: "Kare wa sono purojekuto no paipu o motteimasu.",
        traducao: "Ele tem contatos para aquele projeto.",
      },
      {
        exemplo: "新しい仕事を見つけるためにパイプを活用しました。",
        hiragana: "あたらしいしごとをみつけるためにぱいぷをかつようしました。",
        romaji:
          "Atarashii shigoto o mitsukeru tame ni paipu o katsuyō shimashita.",
        traducao: "Utilizei minhas conexões para encontrar um novo emprego.",
      },
      {
        exemplo: "パイプを使って情報を入手しました。",
        hiragana: "ぱいぷをつかってじょうほうをにゅうしゅしました。",
        romaji: "Paipu o tsukatte jōhō o nyūshu shimashita.",
        traducao: "Obtive informações usando meus contatos.",
      },
    ],
  },
  {
    vocabulario: "いっせい",
    romaji: "issei",
    hiragana: "いっせい",
    traducao: "simultaneamente, ao mesmo tempo",
    exemplosFrases: [
      {
        exemplo: "複数のタスクをいっせいにこなすことができます。",
        hiragana: "ふくすうのたすくをいっせいにこなすことができます。",
        romaji: "Fukusū no tasuku o issei ni konasu koto ga dekimasu.",
        traducao: "Posso lidar com várias tarefas simultaneamente.",
      },
      {
        exemplo: "音楽を聴きながら勉強することができますか？",
        hiragana: "おんがくをききながらべんきょうすることができますか？",
        romaji: "Ongaku o kikinagara benkyō suru koto ga dekimasu ka?",
        traducao: "Você consegue estudar enquanto ouve música?",
      },
      {
        exemplo: "プレゼンテーションの準備をしながら資料を作成しました。",
        hiragana:
          "ぷれぜんてーしょんのじゅんびをしながらしりょうをさくせいしました。",
        romaji:
          "Purezentēshon no junbi o shinagara shiryō o sakusei shimashita.",
        traducao:
          "Criei os materiais enquanto me preparava para a apresentação.",
      },
    ],
  },
  {
    vocabulario: "トンネル",
    romaji: "tonneru",
    hiragana: "とんねる",
    traducao: "túnel",
    exemplosFrases: [
      {
        exemplo: "長いトンネルを通って山に入りました。",
        hiragana: "ながいとんねるをとおってやまにはいりました。",
        romaji: "Nagai tonneru o tōtte yama ni hairimashita.",
        traducao: "Passamos por um longo túnel e entramos nas montanhas.",
      },
      {
        exemplo: "このトンネルは地下鉄と道路の下を通っています。",
        hiragana: "このとんねるはちかてつとどうろのしたをとおっています。",
        romaji: "Kono tonneru wa chikatetsu to dōro no shita o tōtteimasu.",
        traducao: "Este túnel passa sob o metrô e a estrada.",
      },
      {
        exemplo: "トンネルの中は暗くて怖かったです。",
        hiragana: "とんねるのなかはくらくてこわかったです。",
        romaji: "Tonneru no naka wa kurakute kowakatta desu.",
        traducao: "Dentro do túnel estava escuro e assustador.",
      },
    ],
  },
  {
    vocabulario: "うどん",
    romaji: "udon",
    hiragana: "うどん",
    traducao: "macarrão udon",
    exemplosFrases: [
      {
        exemplo: "讃岐うどんは有名です。",
        hiragana: "さぬきうどんはゆうめいです。",
        romaji: "Sanuki udon wa yūmei desu.",
        traducao: "O macarrão udon de Sanuki é famoso.",
      },
      {
        exemplo: "冷たいうどんが夏にぴったりです。",
        hiragana: "つめたいうどんがなつにぴったりです。",
        romaji: "Tsumetai udon ga natsu ni pittari desu.",
        traducao: "O macarrão udon gelado é perfeito para o verão.",
      },
      {
        exemplo: "うどん屋さんで美味しいうどんを食べました。",
        hiragana: "うどんやさんでおいしいうどんをたべました。",
        romaji: "Udon-ya san de oishii udon o tabemashita.",
        traducao: "Comi um delicioso macarrão udon em um restaurante de udon.",
      },
    ],
  },
  {
    vocabulario: "ウイスキー",
    romaji: "uisukī",
    hiragana: "ういすきー",
    traducao: "uísque",
    exemplosFrases: [
      {
        exemplo: "ウイスキーのボトルをプレゼントされました。",
        hiragana: "ういすきーのぼとるをぷれぜんとされました。",
        romaji: "Uisukī no botoru o purezento sa remashita.",
        traducao: "Recebi uma garrafa de uísque como presente.",
      },
      {
        exemplo: "バーでウイスキーを飲みました。",
        hiragana: "ばーでういすきーをのみました。",
        romaji: "Bā de uisukī o nomimashita.",
        traducao: "Bebi uísque em um bar.",
      },
      {
        exemplo: "ウイスキーはお酒の一種です。",
        hiragana: "ういすきーはおさけのいっしゅです。",
        romaji: "Uisukī wa osake no isshu desu.",
        traducao: "O uísque é um tipo de bebida alcoólica.",
      },
    ],
  },
  {
    vocabulario: "一家",
    romaji: "ikka",
    hiragana: "いっか",
    traducao: "família",
    exemplosFrases: [
      {
        exemplo: "彼は一家の大黒柱です。",
        hiragana: "かれはいっかのだいこくちゅうです。",
        romaji: "Kare wa ikka no daikokuchū desu.",
        traducao: "Ele é o pilar da família.",
      },
      {
        exemplo: "一家団欒の時間を大切にしましょう。",
        hiragana: "いっかだんらんのじかんをたいせつにしましょう。",
        romaji: "Ikka danran no jikan o taisetsu ni shimashō.",
        traducao: "Vamos valorizar o tempo em família.",
      },
      {
        exemplo: "彼女は一家の経済を支えています。",
        hiragana: "かのじょはいっかのけいざいをささえています。",
        romaji: "Kanojo wa ikka no keizai o sasaete imasu.",
        traducao: "Ela sustenta a economia da família.",
      },
    ],
  },
  {
    vocabulario: "一時",
    romaji: "ichiji",
    hiragana: "いちじ",
    traducao: "momento, hora",
    exemplosFrases: [
      {
        exemplo: "一時的な解決策ではなく、長期的な視野で考えましょう。",
        hiragana:
          "いちじてきなかいけつさくではなく、ちょうきてきなしやでかんがえましょう。",
        romaji:
          "Ichijiteki na kaiketsusaku de wa naku, chōkiteki na shiya de kangaemashō.",
        traducao:
          "Vamos pensar a longo prazo em vez de buscar soluções temporárias.",
      },
      {
        exemplo: "一時の辛さに耐えることで将来の幸せが待っています。",
        hiragana:
          "いちじのつらさにたえることでしょうらいのしあわせがまっています。",
        romaji:
          "Ichiji no tsurasa ni taeru koto de shōrai no shiawase ga matteimasu.",
        traducao:
          "Superar as dificuldades momentâneas nos aguarda uma felicidade futura.",
      },
      {
        exemplo: "一時的な困難に立ち向かう勇気を持ちましょう。",
        hiragana: "いちじてきなこんなんにたちむかうゆうきをもちましょう。",
        romaji: "Ichijiteki na konnan ni tachimukau yūki o mochimashō.",
        traducao:
          "Vamos ter coragem para enfrentar as dificuldades temporárias.",
      },
    ],
  },
  {
    vocabulario: "一言",
    romaji: "hitokoto",
    hiragana: "ひとこと",
    traducao: "uma palavra, breve comentário",
    exemplosFrases: [
      {
        exemplo: "彼から一言励まされました。",
        hiragana: "かれからひとことはげまされました。",
        romaji: "Kare kara hitokoto hagemasa remashita.",
        traducao: "Recebi palavras de encorajamento dele.",
      },
      {
        exemplo: "彼女の一言で気持ちが変わりました。",
        hiragana: "かのじょのひとことできもちがかわりました。",
        romaji: "Kanojo no hitokoto de kimochi ga kawarimashita.",
        traducao: "Meus sentimentos mudaram com uma palavra dela.",
      },
      {
        exemplo: "大切なことは一言で表すことができます。",
        hiragana: "たいせつなことはひとことであらわすことができます。",
        romaji: "Taisetsu na koto wa hitokoto de arawasu koto ga dekimasu.",
        traducao: "Podemos expressar coisas importantes em poucas palavras.",
      },
    ],
  },
  {
    vocabulario: "ユニフォーム",
    romaji: "yunifōmu",
    hiragana: "ゆにふぉーむ",
    traducao: "uniforme",
    exemplosFrases: [
      {
        exemplo: "スポーツクラブのユニフォームを着用してください。",
        hiragana: "すぽーつくらぶのゆにふぉーむをちゃくようしてください。",
        romaji: "Supōtsu kurabu no yunifōmu o chakuyō shite kudasai.",
        traducao: "Por favor, use o uniforme do clube esportivo.",
      },
      {
        exemplo: "学校のユニフォームは制服です。",
        hiragana: "がっこうのゆにふぉーむはせいふくです。",
        romaji: "Gakkō no yunifōmu wa seifuku desu.",
        traducao: "O uniforme da escola é um uniforme escolar.",
      },
      {
        exemplo: "ユニフォームの色は青と白です。",
        hiragana: "ゆにふぉーむのいろはあおとしろです。",
        romaji: "Yunifōmu no iro wa ao to shiro desu.",
        traducao: "As cores do uniforme são azul e branco.",
      },
    ],
  },
  {
    vocabulario: "宇宙",
    romaji: "uchū",
    hiragana: "うちゅう",
    traducao: "universo, espaço",
    exemplosFrases: [
      {
        exemplo: "宇宙の謎を解明するために研究を行っています。",
        hiragana:
          "うちゅうのなぞをかいめいするためにけんきゅうをおこなっています。",
        romaji: "Uchū no nazo o kaimei suru tame ni kenkyū o okonatte imasu.",
        traducao:
          "Estamos conduzindo pesquisas para desvendar os mistérios do universo.",
      },
      {
        exemplo: "宇宙旅行に興味があります。",
        hiragana: "うちゅうりょこうにきょうみがあります。",
        romaji: "Uchū ryokō ni kyōmi ga arimasu.",
        traducao: "Tenho interesse em viagens espaciais.",
      },
      {
        exemplo: "宇宙の広さには驚かされます。",
        hiragana: "うちゅうのひろさにはおどろかされます。",
        romaji: "Uchū no hiro-sa ni wa odorokasa remasu.",
        traducao: "Fico impressionado com a vastidão do espaço.",
      },
    ],
  },
  {
    vocabulario: "意志",
    romaji: "ishi",
    hiragana: "いし",
    traducao: "vontade",
    exemplosFrases: [
      {
        exemplo: "彼は強い意志を持って目標に向かっています。",
        hiragana: "かれはつよいいしをもってもくひょうにむかっています。",
        romaji: "Kare wa tsuyoi ishi o motte mokuhyō ni mukatte imasu.",
        traducao:
          "Ele está seguindo em direção aos seus objetivos com determinação.",
      },
      {
        exemplo: "意志の力は人を変えることができます。",
        hiragana: "いしのちからはひとをかえることができます。",
        romaji: "Ishi no chikara wa hito o kaeru koto ga dekimasu.",
        traducao: "O poder da vontade pode mudar as pessoas.",
      },
      {
        exemplo: "彼女は強い意志を持つリーダーです。",
        hiragana: "かのじょはつよいいしをもつりーだーです。",
        romaji: "Kanojo wa tsuyoi ishi o motsu rīdā desu.",
        traducao: "Ela é uma líder com uma forte vontade.",
      },
    ],
  },
  {
    vocabulario: "一生",
    romaji: "isshō",
    hiragana: "いっしょう",
    traducao: "a vida toda, para sempre",
    exemplosFrases: [
      {
        exemplo: "彼とは一生の友達です。",
        hiragana: "かれとはいっしょうのともだちです。",
        romaji: "Kare to wa isshō no tomodachi desu.",
        traducao: "Ele é meu amigo para a vida toda.",
      },
      {
        exemplo: "一生に一度の経験をしましょう。",
        hiragana: "いっしょうにいちどのけいけんをしましょう。",
        romaji: "Isshō ni ichido no keiken o shimashō.",
        traducao: "Vamos ter uma experiência única na vida.",
      },
      {
        exemplo: "夢を追い続けることが一生の目標です。",
        hiragana: "ゆめをおいつづけることがいっしょうのもくひょうです。",
        romaji: "Yume o oitsuzukeru koto ga isshō no mokuhyō desu.",
        traducao: "Perseguir os sonhos é o objetivo da vida toda.",
      },
    ],
  },
  {
    vocabulario: "ビデオ",
    romaji: "bideo",
    hiragana: "びでお",
    traducao: "vídeo",
    exemplosFrases: [
      {
        exemplo: "映画をビデオで観ました。",
        hiragana: "えいがをびでおでみました。",
        romaji: "Eiga o bideo de mimashita.",
        traducao: "Assisti ao filme em vídeo.",
      },
      {
        exemplo: "ビデオを録画して後で見る予定です。",
        hiragana: "びでおをろくがしてあとでみるよていです。",
        romaji: "Bideo o rokuga shite ato de miru yotei desu.",
        traducao: "Vou gravar o vídeo e assistir mais tarde.",
      },
      {
        exemplo: "このビデオは面白いです。",
        hiragana: "このびでおはおもしろいです。",
        romaji: "Kono bideo wa omoshiroi desu.",
        traducao: "Este vídeo é interessante.",
      },
    ],
  },
  {
    vocabulario: "グラス",
    romaji: "gurasu",
    hiragana: "ぐらす",
    traducao: "copo, taça",
    exemplosFrases: [
      {
        exemplo: "水をグラスに注ぎます。",
        hiragana: "みずをぐらすにそそぎます。",
        romaji: "Mizu o gurasu ni sosogimasu.",
        traducao: "Despejo água no copo.",
      },
      {
        exemplo: "ワイングラスを使って乾杯しましょう。",
        hiragana: "わいんぐらすをつかってかんぱいしましょう。",
        romaji: "Wain gurasu o tsukatte kanpai shimashō.",
        traducao: "Vamos brindar usando taças de vinho.",
      },
      {
        exemplo: "グラスを割ってしまいました。",
        hiragana: "ぐらすをわってしまいました。",
        romaji: "Gurasu o watte shimaimashita.",
        traducao: "Quebrei o copo por acidente.",
      },
    ],
  },
  {
    vocabulario: "ワイン",
    romaji: "wain",
    hiragana: "わいん",
    traducao: "vinho",
    exemplosFrases: [
      {
        exemplo: "ディナーにワインを飲みましょう。",
        hiragana: "でぃなーにわいんをのみましょう。",
        romaji: "Dinā ni wain o nomimashō.",
        traducao: "Vamos beber vinho no jantar.",
      },
      {
        exemplo: "ワインの味はどうですか？",
        hiragana: "わいんのあじはどうですか？",
        romaji: "Wain no aji wa dō desu ka?",
        traducao: "Como está o sabor do vinho?",
      },
      {
        exemplo: "ワインは赤と白があります。",
        hiragana: "わいんはあかとしろがあります。",
        romaji: "Wain wa aka to shiro ga arimasu.",
        traducao: "Existem vinhos tinto e branco.",
      },
    ],
  },
  {
    vocabulario: "ビニール",
    romaji: "binīru",
    hiragana: "びにーる",
    traducao: "vinil, plástico",
    exemplosFrases: [
      {
        exemplo: "この袋はビニールでできています。",
        hiragana: "このふくろはびにーるでできています。",
        romaji: "Kono fukuro wa binīru de dekite imasu.",
        traducao: "Este saco é feito de plástico.",
      },
      {
        exemplo: "ビニール傘を持って外出しましょう。",
        hiragana: "びにーるかさをもってがいしゅつしましょう。",
        romaji: "Binīru kasa o motte gaishutsu shimashō.",
        traducao: "Vamos sair com um guarda-chuva de plástico.",
      },
      {
        exemplo: "ビニール袋を使ってごみを捨ててください。",
        hiragana: "びにーるふくろをつかってごみをすててください。",
        romaji: "Binīru fukuro o tsukatte gomi o sutete kudasai.",
        traducao: "Por favor, jogue o lixo em sacos de plástico.",
      },
    ],
  },
  {
    vocabulario: "違反",
    romaji: "ihaan",
    hiragana: "いはん",
    traducao: "violação, infração",
    exemplosFrases: [
      {
        exemplo: "ルール違反は許されません。",
        hiragana: "るーるいはんはゆるされません。",
        romaji: "Rūru ihan wa yurusaremasen.",
        traducao: "As violações das regras não são permitidas.",
      },
      {
        exemplo: "交通違反には罰金が科されます。",
        hiragana: "こうつういはんにはばっきんがかされます。",
        romaji: "Kōtsū ihan ni wa bakkin ga kasarematsu.",
        traducao: "As infrações de trânsito são punidas com multa.",
      },
      {
        exemplo: "ルールを守ることは違反を避けるために重要です。",
        hiragana: "るーるをまもることはいはんをさけるためにじゅうようです。",
        romaji: "Rūru o mamoru koto wa ihan o sakeru tame ni jūyō desu.",
        traducao: "É importante seguir as regras para evitar violações.",
      },
    ],
  },
  {
    vocabulario: "バイオリン",
    romaji: "baiorin",
    hiragana: "ばいおりん",
    traducao: "violino",
    exemplosFrases: [
      {
        exemplo: "彼女はバイオリンを演奏します。",
        hiragana: "かのじょはばいおりんをえんそうします。",
        romaji: "Kanojo wa baiorin o ensō shimasu.",
        traducao: "Ela toca violino.",
      },
      {
        exemplo: "バイオリンの音色は美しいです。",
        hiragana: "ばいおりんのねいろはうつくしいです。",
        romaji: "Baiorin no neiro wa utsukushii desu.",
        traducao: "O som do violino é bonito.",
      },
      {
        exemplo: "バイオリンのレッスンを受けています。",
        hiragana: "ばいおりんのれっすんをうけています。",
        romaji: "Baiorin no ressun o ukete imasu.",
        traducao: "Estou tendo aulas de violino.",
      },
    ],
  },
  {
    vocabulario: "ウイルス",
    romaji: "uirusu",
    hiragana: "ういるす",
    traducao: "vírus",
    exemplosFrases: [
      {
        exemplo: "コンピューターウイルスに注意してください。",
        hiragana: "こんぴゅーたーういるすにちゅういしてください。",
        romaji: "Konpyūtā uirusu ni chūi shite kudasai.",
        traducao: "Por favor, tenha cuidado com os vírus de computador.",
      },
      {
        exemplo: "ウイルス感染を予防するために手洗いをしましょう。",
        hiragana: "ういるすかんせんをよぼうするためにてあらいをしましょう。",
        romaji: "Uirusu kansen o yobō suru tame ni tearai o shimashō.",
        traducao: "Vamos lavar as mãos para prevenir a infecção por vírus.",
      },
      {
        exemplo: "新型ウイルスに対するワクチンが開発されました。",
        hiragana: "しんがたういるすにたいするわくちんがかいはつされました。",
        romaji: "Shingata uirusu ni taisuru wakuchin ga kaihatsu saremashita.",
        traducao: "Uma vacina foi desenvolvida para o novo vírus.",
      },
    ],
  },
  {
    vocabulario: "やる気",
    romaji: "yaruki",
    hiragana: "やるき",
    traducao: "motivação, vontade de fazer algo",
    exemplosFrases: [
      {
        exemplo: "勉強するやる気がありません。",
        hiragana: "べんきょうするやるきがありません。",
        romaji: "Benkyō suru yaruki ga arimasen.",
        traducao: "Não tenho motivação para estudar.",
      },
      {
        exemplo: "仕事にやる気を持って取り組んでいます。",
        hiragana: "しごとにやるきをもってとりくんでいます。",
        romaji: "Shigoto ni yaruki o motte torikunde imasu.",
        traducao: "Estou abordando o trabalho com motivação.",
      },
      {
        exemplo: "やる気が出ない時は小休憩を取ることも大切です。",
        hiragana:
          "やるきがでないときはしょうきゅうけいをとることもたいせつです。",
        romaji:
          "Yaruki ga denai toki wa shōkyūkei o toru koto mo taisetsu desu.",
        traducao:
          "Quando você não está motivado, é importante fazer pequenas pausas.",
      },
    ],
  },
  {
    vocabulario: "陰",
    romaji: "kage",
    hiragana: "かげ",
    traducao: "sombra",
    exemplosFrases: [
      {
        exemplo: "木の陰で涼んでいます。",
        hiragana: "きのかげですずんでいます。",
        romaji: "Ki no kage de sunde imasu.",
        traducao: "Estou me refrescando na sombra da árvore.",
      },
      {
        exemplo: "陽と陰は対照的な存在です。",
        hiragana: "ようとかげはたいしょうてきなそんざいです。",
        romaji: "Yō to kage wa taishōteki na sonzai desu.",
        traducao: "O sol e a sombra são existências contrastantes.",
      },
      {
        exemplo: "太陽が雲の陰に隠れました。",
        hiragana: "たいようがくものかげにかくれました。",
        romaji: "Taiyō ga kumo no kage ni kakuremashita.",
        traducao: "O sol se escondeu atrás das nuvens.",
      },
    ],
  },
];

export default n3VocabList;
