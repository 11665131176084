import PageJLPTDetails from "../components/PageJLPTDetails";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function AboutJLPT() {

  return (
    <>
    <Header />
      <PageJLPTDetails />
      <Footer />
    </>
  )
};
