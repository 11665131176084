const n3KanjiList = [
  {
    kanji: "政",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "まつりごと", romaji: "matsurigoto" }],
    significado: "política",
    exemplos: [
      {
        kanji: "政府",
        furigana: "せいふ",
        romaji: "seifu",
        traducao: "governo",
      },
      {
        kanji: "政治",
        furigana: "せいじ",
        romaji: "seiji",
        traducao: "política",
      },
      {
        kanji: "政策",
        furigana: "せいさく",
        romaji: "seisaku",
        traducao: "política (medidas)",
      },
    ],
  },
  {
    kanji: "議",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [{ hiragana: "いと", romaji: "ito" }],
    significado: "discussão",
    exemplos: [
      {
        kanji: "議論",
        furigana: "ぎろん",
        romaji: "giron",
        traducao: "discussão",
      },
      {
        kanji: "議会",
        furigana: "ぎかい",
        romaji: "gikai",
        traducao: "assembleia",
      },
      {
        kanji: "討議",
        furigana: "とうぎ",
        romaji: "togi",
        traducao: "deliberação",
      },
    ],
  },
  {
    kanji: "民",
    onYomi: [{ katakana: "ミン", romaji: "min" }],
    kunYomi: [{ hiragana: "たみ", romaji: "tami" }],
    significado: "povo",
    exemplos: [
      {
        kanji: "民族",
        furigana: "みんぞく",
        romaji: "minzoku",
        traducao: "etnia",
      },
      {
        kanji: "民間",
        furigana: "みんかん",
        romaji: "minkan",
        traducao: "privado",
      },
      {
        kanji: "国民",
        furigana: "こくみん",
        romaji: "kokumin",
        traducao: "cidadão",
      },
    ],
  },
  {
    kanji: "連",
    onYomi: [{ katakana: "レン", romaji: "ren" }],
    kunYomi: [{ hiragana: "つらなる", romaji: "tsuranaru" }],
    significado: "conexão",
    exemplos: [
      {
        kanji: "連絡",
        furigana: "れんらく",
        romaji: "renraku",
        traducao: "contato",
      },
      {
        kanji: "連休",
        furigana: "れんきゅう",
        romaji: "renkyuu",
        traducao: "feriado contínuo",
      },
      {
        kanji: "関連",
        furigana: "かんれん",
        romaji: "kanren",
        traducao: "relacionado",
      },
    ],
  },
  {
    kanji: "対",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "あいて", romaji: "aite" }],
    significado: "oposição",
    exemplos: [
      {
        kanji: "対話",
        furigana: "たいわ",
        romaji: "taiwa",
        traducao: "diálogo",
      },
      {
        kanji: "対策",
        furigana: "たいさく",
        romaji: "taisaku",
        traducao: "medida",
      },
      {
        kanji: "対立",
        furigana: "たいりつ",
        romaji: "tairitsu",
        traducao: "conflito",
      },
    ],
  },
  {
    kanji: "部",
    onYomi: [{ katakana: "ブ", romaji: "bu" }],
    kunYomi: [{ hiragana: "へ", romaji: "he" }],
    significado: "departamento",
    exemplos: [
      {
        kanji: "部長",
        furigana: "ぶちょう",
        romaji: "buchou",
        traducao: "chefe de departamento",
      },
      {
        kanji: "部屋",
        furigana: "へや",
        romaji: "heya",
        traducao: "quarto",
      },
      {
        kanji: "部分",
        furigana: "ぶぶん",
        romaji: "bubun",
        traducao: "parte",
      },
    ],
  },
  {
    kanji: "合",
    onYomi: [{ katakana: "ゴウ", romaji: "gou" }],
    kunYomi: [{ hiragana: "あ", romaji: "a" }],
    significado: "juntar",
    exemplos: [
      {
        kanji: "合格",
        furigana: "ごうかく",
        romaji: "goukaku",
        traducao: "aprovação",
      },
      {
        kanji: "合図",
        furigana: "あいず",
        romaji: "aizu",
        traducao: "sinal",
      },
      {
        kanji: "合計",
        furigana: "ごうけい",
        romaji: "goukei",
        traducao: "total",
      },
    ],
  },
  {
    kanji: "市",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "いち", romaji: "ichi" }],
    significado: "cidade",
    exemplos: [
      {
        kanji: "市民",
        furigana: "しみん",
        romaji: "shimin",
        traducao: "cidadão",
      },
      {
        kanji: "市場",
        furigana: "いちば",
        romaji: "ichiba",
        traducao: "mercado",
      },
      {
        kanji: "市内",
        furigana: "しない",
        romaji: "shinai",
        traducao: "dentro da cidade",
      },
    ],
  },
  {
    kanji: "内",
    onYomi: [{ katakana: "ナイ", romaji: "nai" }],
    kunYomi: [{ hiragana: "うち", romaji: "uchi" }],
    significado: "dentro",
    exemplos: [
      {
        kanji: "内緒",
        furigana: "ないしょ",
        romaji: "naisho",
        traducao: "segredo",
      },
      {
        kanji: "内部",
        furigana: "ないぶ",
        romaji: "naibu",
        traducao: "interno",
      },
      {
        kanji: "内科",
        furigana: "ないか",
        romaji: "naika",
        traducao: "clínica médica",
      },
    ],
  },
  {
    kanji: "相",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "あい", romaji: "ai" }],
    significado: "relativo",
    exemplos: [
      {
        kanji: "相手",
        furigana: "あいて",
        romaji: "aite",
        traducao: "parceiro",
      },
      {
        kanji: "相談",
        furigana: "そうだん",
        romaji: "soudan",
        traducao: "consultar",
      },
      {
        kanji: "相変わらず",
        furigana: "あいかわらず",
        romaji: "aikawarazu",
        traducao: "ainda do mesmo jeito",
      },
    ],
  },
  {
    kanji: "定",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "さだ", romaji: "sada" }],
    significado: "fixo",
    exemplos: [
      {
        kanji: "定期",
        furigana: "ていき",
        romaji: "teiki",
        traducao: "regular",
      },
      {
        kanji: "定休日",
        furigana: "ていきゅうび",
        romaji: "teikyuuhi",
        traducao: "dia de folga fixo",
      },
      {
        kanji: "一定",
        furigana: "いってい",
        romaji: "ittei",
        traducao: "constante",
      },
    ],
  },
  {
    kanji: "回",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "まわ", romaji: "mawa" }],
    significado: "vez",
    exemplos: [
      {
        kanji: "回答",
        furigana: "かいとう",
        romaji: "kaitou",
        traducao: "resposta",
      },
      {
        kanji: "回復",
        furigana: "かいふく",
        romaji: "kaifuku",
        traducao: "recuperação",
      },
      {
        kanji: "一回",
        furigana: "いっかい",
        romaji: "ikkai",
        traducao: "uma vez",
      },
    ],
  },
  {
    kanji: "選",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "えら", romaji: "era" }],
    significado: "escolher",
    exemplos: [
      {
        kanji: "選択",
        furigana: "せんたく",
        romaji: "sentaku",
        traducao: "seleção",
      },
      {
        kanji: "選手",
        furigana: "せんしゅ",
        romaji: "senshu",
        traducao: "atleta",
      },
      {
        kanji: "選挙",
        furigana: "せんきょ",
        romaji: "senkyo",
        traducao: "eleição",
      },
    ],
  },
  {
    kanji: "米",
    onYomi: [{ katakana: "ベイ", romaji: "bei" }],
    kunYomi: [{ hiragana: "こめ", romaji: "kome" }],
    significado: "arroz",
    exemplos: [
      {
        kanji: "米国",
        furigana: "べいこく",
        romaji: "beikoku",
        traducao: "Estados Unidos",
      },
      {
        kanji: "白米",
        furigana: "はくまい",
        romaji: "hakumai",
        traducao: "arroz branco",
      },
      {
        kanji: "米飯",
        furigana: "まいはん",
        romaji: "maihan",
        traducao: "refeição de arroz",
      },
    ],
  },
  {
    kanji: "実",
    onYomi: [
      { katakana: "ジツ", romaji: "jitsu" },
      { katakana: "シツ", romaji: "shitsu" },
    ],
    kunYomi: [
      { hiragana: "み", romaji: "mi" },
      { hiragana: "まこと", romaji: "makoto" },
    ],
    significado: "realidade",
    exemplos: [
      {
        kanji: "実力",
        furigana: "じつりょく",
        romaji: "jitsuryoku",
        traducao: "habilidade real",
      },
      {
        kanji: "実験",
        furigana: "じっけん",
        romaji: "jikken",
        traducao: "experimento",
      },
      {
        kanji: "実際",
        furigana: "じっさい",
        romaji: "jissai",
        traducao: "realidade",
      },
    ],
  },
  {
    kanji: "関",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "せき", romaji: "seki" }],
    significado: "relação",
    exemplos: [
      {
        kanji: "関係",
        furigana: "かんけい",
        romaji: "kankei",
        traducao: "relação",
      },
      {
        kanji: "関心",
        furigana: "かんしん",
        romaji: "kanshin",
        traducao: "interesse",
      },
      {
        kanji: "関わる",
        furigana: "かかわる",
        romaji: "kakawaru",
        traducao: "estar envolvido",
      },
    ],
  },
  {
    kanji: "決",
    onYomi: [{ katakana: "ケツ", romaji: "ketsu" }],
    kunYomi: [{ hiragana: "き", romaji: "ki" }],
    significado: "decidir",
    exemplos: [
      {
        kanji: "決定",
        furigana: "けってい",
        romaji: "kettei",
        traducao: "decisão",
      },
      {
        kanji: "決心",
        furigana: "けっしん",
        romaji: "kesshin",
        traducao: "determinação",
      },
      {
        kanji: "決める",
        furigana: "きめる",
        romaji: "kimeru",
        traducao: "decidir",
      },
    ],
  },
  {
    kanji: "全",
    onYomi: [{ katakana: "ゼン", romaji: "zen" }],
    kunYomi: [{ hiragana: "まった", romaji: "matta" }],
    significado: "todo",
    exemplos: [
      {
        kanji: "全部",
        furigana: "ぜんぶ",
        romaji: "zenbu",
        traducao: "todos",
      },
      {
        kanji: "全国",
        furigana: "ぜんこく",
        romaji: "zenkoku",
        traducao: "todo o país",
      },
      {
        kanji: "全然",
        furigana: "ぜんぜん",
        romaji: "zenzen",
        traducao: "de jeito nenhum",
      },
    ],
  },
  {
    kanji: "表",
    onYomi: [{ katakana: "ヒョウ", romaji: "hyou" }],
    kunYomi: [{ hiragana: "おもて", romaji: "omote" }],
    significado: "superfície",
    exemplos: [
      {
        kanji: "表面",
        furigana: "ひょうめん",
        romaji: "hyoumen",
        traducao: "superfície",
      },
      {
        kanji: "表現",
        furigana: "ひょうげん",
        romaji: "hyougen",
        traducao: "expressão",
      },
      {
        kanji: "表す",
        furigana: "あらわす",
        romaji: "arawasu",
        traducao: "representar",
      },
    ],
  },
  {
    kanji: "戦",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "いくさ", romaji: "ikusa" }],
    significado: "guerra",
    exemplos: [
      {
        kanji: "戦争",
        furigana: "せんそう",
        romaji: "sensou",
        traducao: "guerra",
      },
      {
        kanji: "戦士",
        furigana: "せんし",
        romaji: "senshi",
        traducao: "guerreiro",
      },
      {
        kanji: "戦う",
        furigana: "たたかう",
        romaji: "tatakau",
        traducao: "lutar",
      },
    ],
  },
  {
    kanji: "経",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "へ", romaji: "he" }],
    significado: "passar por",
    exemplos: [
      {
        kanji: "経験",
        furigana: "けいけん",
        romaji: "keiken",
        traducao: "experiência",
      },
      {
        kanji: "経済",
        furigana: "けいざい",
        romaji: "keizai",
        traducao: "economia",
      },
      {
        kanji: "経路",
        furigana: "けいろ",
        romaji: "keiro",
        traducao: "rota",
      },
    ],
  },
  {
    kanji: "最",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "もっと", romaji: "motto" }],
    significado: "mais",
    exemplos: [
      {
        kanji: "最後",
        furigana: "さいご",
        romaji: "saigo",
        traducao: "último",
      },
      {
        kanji: "最高",
        furigana: "さいこう",
        romaji: "saikou",
        traducao: "melhor",
      },
      {
        kanji: "最初",
        furigana: "さいしょ",
        romaji: "saisho",
        traducao: "primeiro",
      },
    ],
  },
  {
    kanji: "現",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [{ hiragana: "あらわ", romaji: "arawa" }],
    significado: "presente",
    exemplos: [
      {
        kanji: "現在",
        furigana: "げんざい",
        romaji: "genzai",
        traducao: "atualmente",
      },
      {
        kanji: "現金",
        furigana: "げんきん",
        romaji: "genkin",
        traducao: "dinheiro",
      },
      {
        kanji: "現地",
        furigana: "げんち",
        romaji: "genchi",
        traducao: "local",
      },
    ],
  },
  {
    kanji: "調",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "しら", romaji: "shira" }],
    significado: "ajustar",
    exemplos: [
      {
        kanji: "調査",
        furigana: "ちょうさ",
        romaji: "chousa",
        traducao: "investigação",
      },
      {
        kanji: "調理",
        furigana: "ちょうり",
        romaji: "chouri",
        traducao: "cozinha",
      },
      {
        kanji: "調整",
        furigana: "ちょうせい",
        romaji: "chousei",
        traducao: "ajuste",
      },
    ],
  },
  {
    kanji: "化",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "ば", romaji: "ba" }],
    significado: "mudar",
    exemplos: [
      {
        kanji: "化学",
        furigana: "かがく",
        romaji: "kagaku",
        traducao: "química",
      },
      {
        kanji: "化粧",
        furigana: "けしょう",
        romaji: "keshou",
        traducao: "maquiagem",
      },
      {
        kanji: "変化",
        furigana: "へんか",
        romaji: "henka",
        traducao: "mudança",
      },
    ],
  },
  {
    kanji: "当",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [
      { hiragana: "あ", romaji: "a" },
      { hiragana: "まさ", romaji: "masa" },
    ],
    significado: "quando",
    exemplos: [
      {
        kanji: "当然",
        furigana: "とうぜん",
        romaji: "touzen",
        traducao: "naturalmente",
      },
      {
        kanji: "当たる",
        furigana: "あたる",
        romaji: "ataru",
        traducao: "acertar",
      },
      {
        kanji: "当時",
        furigana: "とうじ",
        romaji: "touji",
        traducao: "naquela época",
      },
    ],
  },
  {
    kanji: "約",
    onYomi: [{ katakana: "ヤク", romaji: "yaku" }],
    kunYomi: [{ hiragana: "つづ", romaji: "tsuzu" }],
    significado: "cerca de",
    exemplos: [
      {
        kanji: "約束",
        furigana: "やくそく",
        romaji: "yakusoku",
        traducao: "promessa",
      },
      {
        kanji: "約10分",
        furigana: "やくじゅっぷん",
        romaji: "yakujuuppun",
        traducao: "cerca de 10 minutos",
      },
      {
        kanji: "約300人",
        furigana: "やくさんびゃくにん",
        romaji: "yakusanbyakunin",
        traducao: "cerca de 300 pessoas",
      },
    ],
  },
  {
    kanji: "首",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [{ hiragana: "くび", romaji: "kubi" }],
    significado: "pescoço",
    exemplos: [
      {
        kanji: "首都",
        furigana: "しゅと",
        romaji: "shuto",
        traducao: "capital",
      },
      {
        kanji: "首相",
        furigana: "しゅしょう",
        romaji: "shushou",
        traducao: "primeiro-ministro",
      },
      {
        kanji: "首都圏",
        furigana: "しゅとけん",
        romaji: "shutoken",
        traducao: "região metropolitana",
      },
    ],
  },
  {
    kanji: "法",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "のり", romaji: "nori" }],
    significado: "lei",
    exemplos: [
      {
        kanji: "法律",
        furigana: "ほうりつ",
        romaji: "houritsu",
        traducao: "lei",
      },
      {
        kanji: "法学",
        furigana: "ほうがく",
        romaji: "hougaku",
        traducao: "estudo jurídico",
      },
      {
        kanji: "刑法",
        furigana: "けいほう",
        romaji: "keihou",
        traducao: "direito penal",
      },
    ],
  },
  {
    kanji: "性",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "さが", romaji: "saga" }],
    significado: "natureza",
    exemplos: [
      {
        kanji: "性格",
        furigana: "せいかく",
        romaji: "seikaku",
        traducao: "personalidade",
      },
      {
        kanji: "性別",
        furigana: "せいべつ",
        romaji: "seibetsu",
        traducao: "sexo",
      },
      {
        kanji: "性質",
        furigana: "せいしつ",
        romaji: "seishitsu",
        traducao: "caráter",
      },
    ],
  },
  {
    kanji: "的",
    onYomi: [{ katakana: "テキ", romaji: "teki" }],
    kunYomi: [{ hiragana: "まと", romaji: "mato" }],
    significado: "alvo",
    exemplos: [
      {
        kanji: "目的",
        furigana: "もくてき",
        romaji: "mokuteki",
        traducao: "objetivo",
      },
      {
        kanji: "具体的",
        furigana: "ぐたいてき",
        romaji: "gutaiteki",
        traducao: "concreto",
      },
      {
        kanji: "象徴的",
        furigana: "しょうちょうてき",
        romaji: "shouchouteki",
        traducao: "simbólico",
      },
    ],
  },
  {
    kanji: "要",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [
      { hiragana: "い", romaji: "i" },
      { hiragana: "かなめ", romaji: "kaname" },
    ],
    significado: "essencial",
    exemplos: [
      {
        kanji: "必要",
        furigana: "ひつよう",
        romaji: "hitsuyou",
        traducao: "necessário",
      },
      {
        kanji: "要点",
        furigana: "ようてん",
        romaji: "youten",
        traducao: "ponto principal",
      },
      {
        kanji: "重要",
        furigana: "じゅうよう",
        romaji: "juuyou",
        traducao: "importante",
      },
    ],
  },
  {
    kanji: "制",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "sistema",
    exemplos: [
      {
        kanji: "制度",
        furigana: "せいど",
        romaji: "seido",
        traducao: "sistema",
      },
      {
        kanji: "製造",
        furigana: "せいぞう",
        romaji: "seizou",
        traducao: "fabricação",
      },
      {
        kanji: "制限",
        furigana: "せいげん",
        romaji: "seigen",
        traducao: "restrição",
      },
    ],
  },
  {
    kanji: "治",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [
      { hiragana: "おさ", romaji: "osa" },
      { hiragana: "なお", romaji: "nao" },
    ],
    significado: "governar",
    exemplos: [
      {
        kanji: "政治",
        furigana: "せいじ",
        romaji: "seiji",
        traducao: "política",
      },
      {
        kanji: "治療",
        furigana: "ちりょう",
        romaji: "chiryou",
        traducao: "tratamento médico",
      },
      {
        kanji: "経済を治す",
        furigana: "けいざいをなおす",
        romaji: "keizai o naosu",
        traducao: "consertar a economia",
      },
    ],
  },
  {
    kanji: "務",
    onYomi: [{ katakana: "ム", romaji: "mu" }],
    kunYomi: [{ hiragana: "つと", romaji: "tsuto" }],
    significado: "dever",
    exemplos: [
      {
        kanji: "業務",
        furigana: "ぎょうむ",
        romaji: "gyoumu",
        traducao: "tarefa",
      },
      {
        kanji: "職務",
        furigana: "しょくむ",
        romaji: "shokumu",
        traducao: "dever",
      },
      {
        kanji: "務める",
        furigana: "つとめる",
        romaji: "tsutomeru",
        traducao: "desempenhar",
      },
    ],
  },
  {
    kanji: "成",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [
      { hiragana: "な", romaji: "na" },
      { hiragana: "なる", romaji: "naru" },
    ],
    significado: "tornar-se",
    exemplos: [
      {
        kanji: "成功",
        furigana: "せいこう",
        romaji: "seikou",
        traducao: "sucesso",
      },
      {
        kanji: "成績",
        furigana: "せいせき",
        romaji: "seiseki",
        traducao: "resultado",
      },
      {
        kanji: "成長",
        furigana: "せいちょう",
        romaji: "seichou",
        traducao: "crescimento",
      },
    ],
  },
  {
    kanji: "期",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "ご", romaji: "go" }],
    significado: "período",
    exemplos: [
      {
        kanji: "期限",
        furigana: "きげん",
        romaji: "kigen",
        traducao: "prazo",
      },
      {
        kanji: "時期",
        furigana: "じき",
        romaji: "jiki",
        traducao: "tempo",
      },
      {
        kanji: "期待",
        furigana: "きたい",
        romaji: "kitai",
        traducao: "expectativa",
      },
    ],
  },
  {
    kanji: "取",
    onYomi: [
      { katakana: "シュ", romaji: "shu" },
      { katakana: "ト", romaji: "to" },
    ],
    kunYomi: [
      { hiragana: "と", romaji: "to" },
      { hiragana: "とり", romaji: "tori" },
    ],
    significado: "pegar",
    exemplos: [
      {
        kanji: "取得",
        furigana: "しゅとく",
        romaji: "shutoku",
        traducao: "obtenção",
      },
      {
        kanji: "取引",
        furigana: "とりひき",
        romaji: "torihiki",
        traducao: "negócio",
      },
      {
        kanji: "取る",
        furigana: "とる",
        romaji: "toru",
        traducao: "pegar",
      },
    ],
  },
  {
    kanji: "都",
    onYomi: [{ katakana: "ト", romaji: "to" }],
    kunYomi: [{ hiragana: "みやこ", romaji: "miyako" }],
    significado: "capital",
    exemplos: [
      {
        kanji: "東京都",
        furigana: "とうきょうと",
        romaji: "Toukyou-to",
        traducao: "Tóquio",
      },
      {
        kanji: "都市",
        furigana: "とし",
        romaji: "toshi",
        traducao: "cidade",
      },
      {
        kanji: "首都",
        furigana: "しゅと",
        romaji: "shuto",
        traducao: "capital",
      },
    ],
  },
  {
    kanji: "和",
    onYomi: [{ katakana: "ワ", romaji: "wa" }],
    kunYomi: [
      { hiragana: "なご", romaji: "nago" },
      { hiragana: "やわ", romaji: "yawa" },
    ],
    significado: "harmonia",
    exemplos: [
      {
        kanji: "和食",
        furigana: "わしょく",
        romaji: "washoku",
        traducao: "culinária japonesa",
      },
      {
        kanji: "平和",
        furigana: "へいわ",
        romaji: "heiwa",
        traducao: "paz",
      },
      {
        kanji: "和風",
        furigana: "わふう",
        romaji: "wafuu",
        traducao: "estilo japonês",
      },
    ],
  },
  {
    kanji: "機",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "はた", romaji: "hata" }],
    significado: "máquina",
    exemplos: [
      {
        kanji: "機会",
        furigana: "きかい",
        romaji: "kikai",
        traducao: "oportunidade",
      },
      {
        kanji: "機械",
        furigana: "きかい",
        romaji: "kikai",
        traducao: "máquina",
      },
      {
        kanji: "機能",
        furigana: "きのう",
        romaji: "kinou",
        traducao: "função",
      },
    ],
  },
  {
    kanji: "平",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [
      { hiragana: "たい", romaji: "tai" },
      { hiragana: "ひら", romaji: "hira" },
    ],
    significado: "plano",
    exemplos: [
      {
        kanji: "平均",
        furigana: "へいきん",
        romaji: "heikin",
        traducao: "média",
      },
      {
        kanji: "平和",
        furigana: "へいわ",
        romaji: "heiwa",
        traducao: "paz",
      },
      {
        kanji: "平等",
        furigana: "びょうどう",
        romaji: "byoudou",
        traducao: "igualdade",
      },
    ],
  },
  {
    kanji: "加",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [
      { hiragana: "くわ", romaji: "kuwa" },
      { hiragana: "くわえ", romaji: "kuwae" },
    ],
    significado: "adicionar",
    exemplos: [
      {
        kanji: "追加",
        furigana: "ついか",
        romaji: "tsuika",
        traducao: "adição",
      },
      {
        kanji: "増加",
        furigana: "ぞうか",
        romaji: "zouka",
        traducao: "aumento",
      },
      {
        kanji: "加速",
        furigana: "かそく",
        romaji: "kasoku",
        traducao: "aceleração",
      },
    ],
  },
  {
    kanji: "受",
    onYomi: [{ katakana: "ジュ", romaji: "ju" }],
    kunYomi: [
      { hiragana: "う", romaji: "u" },
      { hiragana: "うけ", romaji: "uke" },
    ],
    significado: "receber",
    exemplos: [
      {
        kanji: "受験",
        furigana: "じゅけん",
        romaji: "juken",
        traducao: "exame",
      },
      {
        kanji: "受付",
        furigana: "うけつけ",
        romaji: "uketsuke",
        traducao: "recepção",
      },
      {
        kanji: "受け入れる",
        furigana: "うけいれる",
        romaji: "ukeireru",
        traducao: "aceitar",
      },
    ],
  },
  {
    kanji: "続",
    onYomi: [{ katakana: "ゾク", romaji: "zoku" }],
    kunYomi: [{ hiragana: "つづ", romaji: "tsuzu" }],
    significado: "continuação",
    exemplos: [
      {
        kanji: "続く",
        furigana: "つづく",
        romaji: "tsuzuku",
        traducao: "continuar",
      },
      {
        kanji: "継続",
        furigana: "けいぞく",
        romaji: "keizoku",
        traducao: "continuidade",
      },
      {
        kanji: "続ける",
        furigana: "つづける",
        romaji: "tsuzukeru",
        traducao: "manter",
      },
    ],
  },
  {
    kanji: "進",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "すす", romaji: "susu" }],
    significado: "avançar",
    exemplos: [
      {
        kanji: "進む",
        furigana: "すすむ",
        romaji: "susumu",
        traducao: "avançar",
      },
      {
        kanji: "進化",
        furigana: "しんか",
        romaji: "shinka",
        traducao: "evolução",
      },
      {
        kanji: "進学",
        furigana: "しんがく",
        romaji: "shingaku",
        traducao: "prosseguir os estudos",
      },
    ],
  },
  {
    kanji: "数",
    onYomi: [{ katakana: "スウ", romaji: "suu" }],
    kunYomi: [{ hiragana: "かず", romaji: "kazu" }],
    significado: "número",
    exemplos: [
      {
        kanji: "数字",
        furigana: "すうじ",
        romaji: "suuji",
        traducao: "número",
      },
      {
        kanji: "数学",
        furigana: "すうがく",
        romaji: "suugaku",
        traducao: "matemática",
      },
      {
        kanji: "多数",
        furigana: "たすう",
        romaji: "tasuu",
        traducao: "grande número",
      },
    ],
  },
  {
    kanji: "記",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "しる", romaji: "shiru" }],
    significado: "anotação",
    exemplos: [
      {
        kanji: "記録",
        furigana: "きろく",
        romaji: "kiroku",
        traducao: "registro",
      },
      {
        kanji: "日記",
        furigana: "にっき",
        romaji: "nikki",
        traducao: "diário",
      },
      {
        kanji: "記憶",
        furigana: "きおく",
        romaji: "kioku",
        traducao: "memória",
      },
    ],
  },
  {
    kanji: "初",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [{ hiragana: "はじ", romaji: "haji" }],
    significado: "primeiro",
    exemplos: [
      {
        kanji: "初めて",
        furigana: "はじめて",
        romaji: "hajimete",
        traducao: "pela primeira vez",
      },
      {
        kanji: "初心者",
        furigana: "しょしんしゃ",
        romaji: "shoshinsha",
        traducao: "iniciante",
      },
      {
        kanji: "初日",
        furigana: "はつか",
        romaji: "hatsuka",
        traducao: "primeiro dia",
      },
    ],
  },
  {
    kanji: "指",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "ゆび", romaji: "yubi" }],
    significado: "dedo",
    exemplos: [
      {
        kanji: "指示",
        furigana: "しじ",
        romaji: "shiji",
        traducao: "instrução",
      },
      {
        kanji: "指輪",
        furigana: "ゆびわ",
        romaji: "yubiwa",
        traducao: "anel",
      },
      {
        kanji: "指導",
        furigana: "しどう",
        romaji: "shidou",
        traducao: "orientação",
      },
    ],
  },
  {
    kanji: "権",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "direito",
    exemplos: [
      {
        kanji: "権利",
        furigana: "けんり",
        romaji: "kenri",
        traducao: "direito",
      },
      {
        kanji: "権力",
        furigana: "けんりょく",
        romaji: "kenryoku",
        traducao: "poder",
      },
      {
        kanji: "権威",
        furigana: "けんい",
        romaji: "ken'i",
        traducao: "autoridade",
      },
    ],
  },
  {
    kanji: "支",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "ささ", romaji: "sasa" }],
    significado: "suporte",
    exemplos: [
      {
        kanji: "支払う",
        furigana: "しはらう",
        romaji: "shiharau",
        traducao: "pagar",
      },
      {
        kanji: "支店",
        furigana: "してん",
        romaji: "shiten",
        traducao: "filial",
      },
      {
        kanji: "支持",
        furigana: "しじ",
        romaji: "shiji",
        traducao: "apoio",
      },
    ],
  },
  {
    kanji: "産",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [{ hiragana: "う", romaji: "u" }],
    significado: "produzir",
    exemplos: [
      {
        kanji: "生産",
        furigana: "せいさん",
        romaji: "seisan",
        traducao: "produção",
      },
      {
        kanji: "産業",
        furigana: "さんぎょう",
        romaji: "sangyou",
        traducao: "indústria",
      },
      {
        kanji: "産地",
        furigana: "さんち",
        romaji: "sanchi",
        traducao: "local de produção",
      },
    ],
  },
  {
    kanji: "点",
    onYomi: [{ katakana: "テン", romaji: "ten" }],
    kunYomi: [{ hiragana: "つ", romaji: "tsu" }],
    significado: "ponto",
    exemplos: [
      {
        kanji: "点数",
        furigana: "てんすう",
        romaji: "tensuu",
        traducao: "pontuação",
      },
      {
        kanji: "点検",
        furigana: "てんけん",
        romaji: "tenken",
        traducao: "inspeção",
      },
      {
        kanji: "目の点",
        furigana: "めのてん",
        romaji: "me no ten",
        traducao: "ponto de vista",
      },
    ],
  },
  {
    kanji: "報",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "むく", romaji: "muku" }],
    significado: "relatório",
    exemplos: [
      {
        kanji: "報告",
        furigana: "ほうこく",
        romaji: "houkoku",
        traducao: "relatório",
      },
      {
        kanji: "新聞報道",
        furigana: "しんぶんほうどう",
        romaji: "shinbun houdou",
        traducao: "notícias de jornal",
      },
      {
        kanji: "報酬",
        furigana: "ほうしゅう",
        romaji: "houshuu",
        traducao: "recompensa",
      },
    ],
  },
  {
    kanji: "済",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "す", romaji: "su" }],
    significado: "concluir",
    exemplos: [
      {
        kanji: "終了済み",
        furigana: "しゅうりょうずみ",
        romaji: "shuuryouzumi",
        traducao: "concluído",
      },
      {
        kanji: "了解済み",
        furigana: "りょうかいずみ",
        romaji: "ryoukaizumi",
        traducao: "entendido",
      },
      {
        kanji: "手続き済み",
        furigana: "てつづきずみ",
        romaji: "tetsuzuki zumi",
        traducao: "processado",
      },
    ],
  },
  {
    kanji: "活",
    onYomi: [{ katakana: "カツ", romaji: "katsu" }],
    kunYomi: [{ hiragana: "い", romaji: "i" }],
    significado: "atividade",
    exemplos: [
      {
        kanji: "活動",
        furigana: "かつどう",
        romaji: "katsudou",
        traducao: "atividade",
      },
      {
        kanji: "生活",
        furigana: "せいかつ",
        romaji: "seikatsu",
        traducao: "vida diária",
      },
      {
        kanji: "活気",
        furigana: "かっき",
        romaji: "kakki",
        traducao: "animado",
      },
    ],
  },
  {
    kanji: "原",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [{ hiragana: "はら", romaji: "hara" }],
    significado: "original",
    exemplos: [
      {
        kanji: "原因",
        furigana: "げんいん",
        romaji: "gen'in",
        traducao: "causa",
      },
      {
        kanji: "原稿",
        furigana: "げんこう",
        romaji: "genkou",
        traducao: "manuscrito",
      },
      {
        kanji: "原始的",
        furigana: "げんしてき",
        romaji: "genshiteki",
        traducao: "primitivo",
      },
    ],
  },
  {
    kanji: "共",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "とも", romaji: "tomo" }],
    significado: "junto",
    exemplos: [
      {
        kanji: "共同",
        furigana: "きょうどう",
        romaji: "kyoudou",
        traducao: "colaboração",
      },
      {
        kanji: "共通",
        furigana: "きょうつう",
        romaji: "kyoutsuu",
        traducao: "comum",
      },
      {
        kanji: "共有",
        furigana: "きょうゆう",
        romaji: "kyouyuu",
        traducao: "compartilhar",
      },
    ],
  },
  {
    kanji: "得",
    onYomi: [{ katakana: "トク", romaji: "toku" }],
    kunYomi: [{ hiragana: "え", romaji: "e" }],
    significado: "obter",
    exemplos: [
      {
        kanji: "得意",
        furigana: "とくい",
        romaji: "tokui",
        traducao: "habilidoso",
      },
      {
        kanji: "利得",
        furigana: "りとく",
        romaji: "ritoku",
        traducao: "lucro",
      },
      {
        kanji: "入手する",
        furigana: "にゅうしゅする",
        romaji: "nyuushu suru",
        traducao: "obter",
      },
    ],
  },
  {
    kanji: "解",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [
      { hiragana: "と", romaji: "to" },
      { hiragana: "ほど", romaji: "hodo" },
    ],
    significado: "resolver",
    exemplos: [
      {
        kanji: "解決",
        furigana: "かいけつ",
        romaji: "kaiketsu",
        traducao: "resolução",
      },
      {
        kanji: "解放",
        furigana: "かいほう",
        romaji: "kaihou",
        traducao: "libertação",
      },
      {
        kanji: "解釈",
        furigana: "かいしゃく",
        romaji: "kaishaku",
        traducao: "interpretação",
      },
    ],
  },
  {
    kanji: "交",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [
      { hiragana: "まじ", romaji: "maji" },
      { hiragana: "まじわ", romaji: "majiwa" },
    ],
    significado: "interação",
    exemplos: [
      {
        kanji: "交流",
        furigana: "こうりゅう",
        romaji: "kouryuu",
        traducao: "intercâmbio",
      },
      {
        kanji: "交通",
        furigana: "こうつう",
        romaji: "koutsuu",
        traducao: "transporte",
      },
      {
        kanji: "交換",
        furigana: "こうかん",
        romaji: "koukan",
        traducao: "troca",
      },
    ],
  },
  {
    kanji: "資",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "recursos",
    exemplos: [
      {
        kanji: "資料",
        furigana: "しりょう",
        romaji: "shiryou",
        traducao: "material/informação",
      },
      {
        kanji: "資格",
        furigana: "しかく",
        romaji: "shikaku",
        traducao: "qualificação",
      },
      {
        kanji: "資源",
        furigana: "しげん",
        romaji: "shigen",
        traducao: "recursos naturais",
      },
    ],
  },
  {
    kanji: "予",
    onYomi: [{ katakana: "ヨ", romaji: "yo" }],
    kunYomi: [{ hiragana: "あらかじ", romaji: "arakaji" }],
    significado: "antecipação",
    exemplos: [
      {
        kanji: "予約",
        furigana: "よやく",
        romaji: "yoyaku",
        traducao: "reserva",
      },
      {
        kanji: "予測",
        furigana: "よそく",
        romaji: "yosoku",
        traducao: "previsão",
      },
      {
        kanji: "予備",
        furigana: "よび",
        romaji: "yobi",
        traducao: "preparação",
      },
    ],
  },
  {
    kanji: "向",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [
      { hiragana: "む", romaji: "mu" },
      { hiragana: "むける", romaji: "mukeru" },
    ],
    significado: "direção",
    exemplos: [
      {
        kanji: "向かう",
        furigana: "むかう",
        romaji: "mukau",
        traducao: "dirigir-se a",
      },
      {
        kanji: "向上",
        furigana: "こうじょう",
        romaji: "koujou",
        traducao: "melhoria",
      },
      {
        kanji: "方向",
        furigana: "ほうこう",
        romaji: "houkou",
        traducao: "direção",
      },
    ],
  },
  {
    kanji: "際",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "きわ", romaji: "kiwa" }],
    significado: "ocasião",
    exemplos: [
      {
        kanji: "機会の際",
        furigana: "きかいのさい",
        romaji: "kikai no sai",
        traducao: "oportunidade",
      },
      {
        kanji: "この際",
        furigana: "このさい",
        romaji: "kono sai",
        traducao: "nesta ocasião",
      },
      {
        kanji: "国際",
        furigana: "こくさい",
        romaji: "kokusai",
        traducao: "internacional",
      },
    ],
  },
  {
    kanji: "勝",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [
      { hiragana: "か", romaji: "ka" },
      { hiragana: "まさ", romaji: "masa" },
    ],
    significado: "vitória",
    exemplos: [
      {
        kanji: "勝利",
        furigana: "しょうり",
        romaji: "shouri",
        traducao: "vitória",
      },
      {
        kanji: "勝負",
        furigana: "しょうぶ",
        romaji: "shoubu",
        traducao: "competição",
      },
      {
        kanji: "勝手",
        furigana: "かって",
        romaji: "katte",
        traducao: "por conta própria",
      },
    ],
  },
  {
    kanji: "面",
    onYomi: [{ katakana: "メン", romaji: "men" }],
    kunYomi: [{ hiragana: "おも", romaji: "omo" }],
    significado: "rosto",
    exemplos: [
      {
        kanji: "面接",
        furigana: "めんせつ",
        romaji: "mensetsu",
        traducao: "entrevista",
      },
      {
        kanji: "表面",
        furigana: "ひょうめん",
        romaji: "hyoumen",
        traducao: "superfície",
      },
      {
        kanji: "笑顔",
        furigana: "えがお",
        romaji: "egao",
        traducao: "sorriso",
      },
    ],
  },
  {
    kanji: "告",
    onYomi: [{ katakana: "コク", romaji: "koku" }],
    kunYomi: [{ hiragana: "つ", romaji: "tsu" }],
    significado: "anunciar",
    exemplos: [
      {
        kanji: "告白",
        furigana: "こくはく",
        romaji: "kokuhaku",
        traducao: "confissão",
      },
      {
        kanji: "告知",
        furigana: "こくち",
        romaji: "kokuchi",
        traducao: "anúncio",
      },
      {
        kanji: "告げる",
        furigana: "つげる",
        romaji: "tsugeru",
        traducao: "informar",
      },
    ],
  },
  {
    kanji: "反",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [
      { hiragana: "そ", romaji: "so" },
      { hiragana: "かえ", romaji: "kae" },
    ],
    significado: "contrário",
    exemplos: [
      {
        kanji: "反対",
        furigana: "はんたい",
        romaji: "hantai",
        traducao: "oposição",
      },
      {
        kanji: "反応",
        furigana: "はんのう",
        romaji: "hannou",
        traducao: "reação",
      },
      {
        kanji: "反省",
        furigana: "はんせい",
        romaji: "hansei",
        traducao: "reflexão",
      },
    ],
  },
  {
    kanji: "判",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "わか", romaji: "waka" }],
    significado: "julgamento",
    exemplos: [
      {
        kanji: "判定",
        furigana: "はんてい",
        romaji: "hantei",
        traducao: "decisão/julgamento",
      },
      {
        kanji: "判断",
        furigana: "はんだん",
        romaji: "handan",
        traducao: "avaliação",
      },
      {
        kanji: "裁判",
        furigana: "さいばん",
        romaji: "saiban",
        traducao: "tribunal/julgamento",
      },
    ],
  },
  {
    kanji: "認",
    onYomi: [{ katakana: "ニン", romaji: "nin" }],
    kunYomi: [{ hiragana: "みと", romaji: "mito" }],
    significado: "reconhecer",
    exemplos: [
      {
        kanji: "認識",
        furigana: "にんしき",
        romaji: "ninshiki",
        traducao: "reconhecimento",
      },
      {
        kanji: "認める",
        furigana: "みとめる",
        romaji: "mitomeru",
        traducao: "reconhecer/aceitar",
      },
      {
        kanji: "承認",
        furigana: "しょうにん",
        romaji: "shounin",
        traducao: "aprovação",
      },
    ],
  },
  {
    kanji: "参",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [
      { hiragana: "まい", romaji: "mai" },
      { hiragana: "まじわ", romaji: "majiwari" },
    ],
    significado: "participar",
    exemplos: [
      {
        kanji: "参加",
        furigana: "さんか",
        romaji: "sanka",
        traducao: "participação",
      },
      {
        kanji: "参考",
        furigana: "さんこう",
        romaji: "sankou",
        traducao: "referência",
      },
      {
        kanji: "参考書",
        furigana: "さんこうしょ",
        romaji: "sankou sho",
        traducao: "livro de referência",
      },
    ],
  },
  {
    kanji: "利",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [
      { hiragana: "き", romaji: "ki" },
      { hiragana: "と", romaji: "to" },
    ],
    significado: "benefício",
    exemplos: [
      {
        kanji: "利益",
        furigana: "りえき",
        romaji: "rieki",
        traducao: "lucro",
      },
      {
        kanji: "利用",
        furigana: "りよう",
        romaji: "riyou",
        traducao: "utilização",
      },
      {
        kanji: "利点",
        furigana: "りてん",
        romaji: "riten",
        traducao: "vantagem",
      },
    ],
  },
  {
    kanji: "組",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [{ hiragana: "く", romaji: "ku" }],
    significado: "grupo",
    exemplos: [
      {
        kanji: "組織",
        furigana: "そしき",
        romaji: "soshiki",
        traducao: "organização",
      },
      {
        kanji: "組合",
        furigana: "くみあい",
        romaji: "kumiai",
        traducao: "sindicato",
      },
      {
        kanji: "組む",
        furigana: "くむ",
        romaji: "kumu",
        traducao: "juntar",
      },
    ],
  },
  {
    kanji: "信",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "しん", romaji: "shin" }],
    significado: "confiança",
    exemplos: [
      {
        kanji: "信頼",
        furigana: "しんらい",
        romaji: "shinrai",
        traducao: "confiança",
      },
      {
        kanji: "信号",
        furigana: "しんごう",
        romaji: "shingou",
        traducao: "sinal",
      },
      {
        kanji: "信じる",
        furigana: "しんじる",
        romaji: "shinjiru",
        traducao: "acreditar",
      },
    ],
  },
  {
    kanji: "在",
    onYomi: [{ katakana: "ザイ", romaji: "zai" }],
    kunYomi: [{ hiragana: "あ", romaji: "a" }],
    significado: "estar presente",
    exemplos: [
      {
        kanji: "在学",
        furigana: "ざいがく",
        romaji: "zaigaku",
        traducao: "estudante",
      },
      {
        kanji: "在庫",
        furigana: "ざいこ",
        romaji: "zaiko",
        traducao: "estoque",
      },
      {
        kanji: "在留",
        furigana: "ざいりゅう",
        romaji: "zairyuu",
        traducao: "permanência",
      },
    ],
  },
  {
    kanji: "件",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "assunto",
    exemplos: [
      {
        kanji: "問題",
        furigana: "もんだい",
        romaji: "mondai",
        traducao: "problema",
      },
      {
        kanji: "事件",
        furigana: "じけん",
        romaji: "jiken",
        traducao: "caso",
      },
      {
        kanji: "件名",
        furigana: "けんめい",
        romaji: "kenmei",
        traducao: "assunto",
      },
    ],
  },
  {
    kanji: "側",
    onYomi: [{ katakana: "ソク", romaji: "soku" }],
    kunYomi: [{ hiragana: "がわ", romaji: "gawa" }],
    significado: "lado",
    exemplos: [
      {
        kanji: "近く側",
        furigana: "ちかくがわ",
        romaji: "chikaku gawa",
        traducao: "perto",
      },
      {
        kanji: "他側",
        furigana: "たがわ",
        romaji: "tagawa",
        traducao: "outro lado",
      },
      {
        kanji: "左側",
        furigana: "ひだりがわ",
        romaji: "hidari gawa",
        traducao: "lado esquerdo",
      },
    ],
  },
  {
    kanji: "任",
    onYomi: [{ katakana: "ニン", romaji: "nin" }],
    kunYomi: [{ hiragana: "まか", romaji: "maka" }],
    significado: "responsabilidade",
    exemplos: [
      {
        kanji: "責任",
        furigana: "せきにん",
        romaji: "sekinin",
        traducao: "responsabilidade",
      },
      {
        kanji: "任務",
        furigana: "にんむ",
        romaji: "ninmu",
        traducao: "tarefa",
      },
      {
        kanji: "信任",
        furigana: "しんにん",
        romaji: "shinnin",
        traducao: "confiança",
      },
    ],
  },
  {
    kanji: "官",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "governo",
    exemplos: [
      {
        kanji: "官庁",
        furigana: "かんちょう",
        romaji: "kanchou",
        traducao: "agência governamental",
      },
      {
        kanji: "官僚",
        furigana: "かんりょう",
        romaji: "kanryou",
        traducao: "funcionário público",
      },
      {
        kanji: "官房",
        furigana: "かんぼう",
        romaji: "kanbou",
        traducao: "gabinete",
      },
    ],
  },
  {
    kanji: "増",
    onYomi: [{ katakana: "ゾウ", romaji: "zou" }],
    kunYomi: [
      { hiragana: "ま", romaji: "ma" },
      { hiragana: "ふ.える", romaji: "fu.eru" },
    ],
    significado: "aumentar",
    exemplos: [
      {
        kanji: "増加",
        furigana: "ぞうか",
        romaji: "zouka",
        traducao: "aumento",
      },
      {
        kanji: "増える",
        furigana: "ふえる",
        romaji: "fueru",
        traducao: "aumentar",
      },
      {
        kanji: "増大",
        furigana: "ぞうだい",
        romaji: "zoudai",
        traducao: "crescimento",
      },
    ],
  },
  {
    kanji: "係",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "かか.る", romaji: "kaka.ru" }],
    significado: "responsável",
    exemplos: [
      {
        kanji: "担当",
        furigana: "たんとう",
        romaji: "tantou",
        traducao: "responsável",
      },
      {
        kanji: "係員",
        furigana: "かかりいん",
        romaji: "kakariin",
        traducao: "atendente",
      },
      {
        kanji: "関係",
        furigana: "かんけい",
        romaji: "kankei",
        traducao: "relacionamento",
      },
    ],
  },
  {
    kanji: "感",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "sentimento",
    exemplos: [
      {
        kanji: "感謝",
        furigana: "かんしゃ",
        romaji: "kansha",
        traducao: "gratidão",
      },
      {
        kanji: "感動",
        furigana: "かんどう",
        romaji: "kandou",
        traducao: "emoção",
      },
      {
        kanji: "感じる",
        furigana: "かんじる",
        romaji: "kanjiru",
        traducao: "sentir",
      },
    ],
  },
  {
    kanji: "情",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "emoção",
    exemplos: [
      {
        kanji: "情報",
        furigana: "じょうほう",
        romaji: "jouhou",
        traducao: "informação",
      },
      {
        kanji: "感情",
        furigana: "かんじょう",
        romaji: "kanjou",
        traducao: "emoção",
      },
      {
        kanji: "情熱",
        furigana: "じょうねつ",
        romaji: "jounetsu",
        traducao: "paixão",
      },
    ],
  },
  {
    kanji: "投",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [
      { hiragana: "な", romaji: "na" },
      { hiragana: "な.げる", romaji: "na.ge.ru" },
    ],
    significado: "lançar",
    exemplos: [
      {
        kanji: "投票",
        furigana: "とうひょう",
        romaji: "touhyou",
        traducao: "votação",
      },
      {
        kanji: "投げる",
        furigana: "なげる",
        romaji: "nageru",
        traducao: "lançar",
      },
      {
        kanji: "投資",
        furigana: "とうし",
        romaji: "toushi",
        traducao: "investimento",
      },
    ],
  },
  {
    kanji: "示",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [{ hiragana: "しめ.す", romaji: "shime.su" }],
    significado: "mostrar",
    exemplos: [
      {
        kanji: "示す",
        furigana: "しめす",
        romaji: "shimesu",
        traducao: "mostrar",
      },
      {
        kanji: "指示",
        furigana: "しじ",
        romaji: "shiji",
        traducao: "instrução",
      },
      {
        kanji: "示唆",
        furigana: "しさ",
        romaji: "shisa",
        traducao: "sugestão",
      },
    ],
  },
  {
    kanji: "変",
    onYomi: [{ katakana: "ヘン", romaji: "hen" }],
    kunYomi: [
      { hiragana: "か.わる", romaji: "ka.waru" },
      { hiragana: "か.わり", romaji: "ka.wari" },
    ],
    significado: "mudar",
    exemplos: [
      {
        kanji: "変化",
        furigana: "へんか",
        romaji: "henka",
        traducao: "mudança",
      },
      {
        kanji: "変更",
        furigana: "へんこう",
        romaji: "henkou",
        traducao: "alteração",
      },
      {
        kanji: "変わる",
        furigana: "かわる",
        romaji: "kawaru",
        traducao: "mudar",
      },
    ],
  },
  {
    kanji: "打",
    onYomi: [{ katakana: "ダ", romaji: "da" }],
    kunYomi: [{ hiragana: "う.つ", romaji: "u.tsu" }],
    significado: "bater",
    exemplos: [
      {
        kanji: "打つ",
        furigana: "うつ",
        romaji: "utsu",
        traducao: "bater",
      },
      {
        kanji: "打撃",
        furigana: "だげき",
        romaji: "dageki",
        traducao: "golpe",
      },
      {
        kanji: "打者",
        furigana: "だしゃ",
        romaji: "dasha",
        traducao: "rebatedor",
      },
    ],
  },
  {
    kanji: "直",
    onYomi: [
      { katakana: "チョク", romaji: "choku" },
      { katakana: "ジキ", romaji: "jiki" },
    ],
    kunYomi: [
      { hiragana: "ただ.ちに", romaji: "tada.chi.ni" },
      { hiragana: "なお.す", romaji: "nao.su" },
    ],
    significado: "direto",
    exemplos: [
      {
        kanji: "直接",
        furigana: "ちょくせつ",
        romaji: "chokusetsu",
        traducao: "direto",
      },
      {
        kanji: "直前",
        furigana: "ちょくぜん",
        romaji: "chokuzen",
        traducao: "imediatamente antes",
      },
      {
        kanji: "直す",
        furigana: "なおす",
        romaji: "naosu",
        traducao: "corrigir",
      },
    ],
  },
  {
    kanji: "両",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [{ hiragana: "もろ", romaji: "moro" }],
    significado: "ambos",
    exemplos: [
      {
        kanji: "両方",
        furigana: "りょうほう",
        romaji: "ryouhou",
        traducao: "ambos os lados",
      },
      {
        kanji: "両手",
        furigana: "りょうて",
        romaji: "ryoute",
        traducao: "ambas as mãos",
      },
      {
        kanji: "両親",
        furigana: "りょうしん",
        romaji: "ryoushin",
        traducao: "pais",
      },
    ],
  },
  {
    kanji: "式",
    onYomi: [{ katakana: "シキ", romaji: "shiki" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "cerimônia",
    exemplos: [
      {
        kanji: "式",
        furigana: "しき",
        romaji: "shiki",
        traducao: "cerimônia",
      },
      {
        kanji: "式場",
        furigana: "しきじょう",
        romaji: "shikijou",
        traducao: "local da cerimônia",
      },
      {
        kanji: "結婚式",
        furigana: "けっこんしき",
        romaji: "kekkonshiki",
        traducao: "cerimônia de casamento",
      },
    ],
  },
  {
    kanji: "確",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "たし.か", romaji: "tashi.ka" }],
    significado: "confirmar",
    exemplos: [
      {
        kanji: "確認",
        furigana: "かくにん",
        romaji: "kakunin",
        traducao: "confirmação",
      },
      {
        kanji: "確か",
        furigana: "たしか",
        romaji: "tashika",
        traducao: "certo",
      },
      {
        kanji: "確保",
        furigana: "かくほ",
        romaji: "kakuho",
        traducao: "garantir",
      },
    ],
  },
  {
    kanji: "果",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [
      { hiragana: "は.たす", romaji: "ha.tasu" },
      { hiragana: "は.てる", romaji: "ha.teru" },
    ],
    significado: "resultado",
    exemplos: [
      {
        kanji: "結果",
        furigana: "けっか",
        romaji: "kekka",
        traducao: "resultado",
      },
      {
        kanji: "果物",
        furigana: "くだもの",
        romaji: "kudamono",
        traducao: "fruta",
      },
      {
        kanji: "果たす",
        furigana: "はたす",
        romaji: "hatasu",
        traducao: "cumprir",
      },
    ],
  },
  {
    kanji: "容",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "aparência",
    exemplos: [
      {
        kanji: "容器",
        furigana: "ようき",
        romaji: "youki",
        traducao: "recipiente",
      },
      {
        kanji: "容疑",
        furigana: "ようぎ",
        romaji: "yougi",
        traducao: "suspeita",
      },
      {
        kanji: "容姿",
        furigana: "ようし",
        romaji: "youshi",
        traducao: "aparência",
      },
    ],
  },
  {
    kanji: "必",
    onYomi: [{ katakana: "ヒツ", romaji: "hitsu" }],
    kunYomi: [{ hiragana: "かなら.ず", romaji: "kanara.zu" }],
    significado: "necessário",
    exemplos: [
      {
        kanji: "必要",
        furigana: "ひつよう",
        romaji: "hitsuyou",
        traducao: "necessário",
      },
      {
        kanji: "必ず",
        furigana: "かならず",
        romaji: "kanarazu",
        traducao: "certamente",
      },
      {
        kanji: "必勝",
        furigana: "ひっしょう",
        romaji: "hisshou",
        traducao: "vitória garantida",
      },
    ],
  },
  {
    kanji: "演",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "atuação",
    exemplos: [
      {
        kanji: "演技",
        furigana: "えんぎ",
        romaji: "engi",
        traducao: "atuação",
      },
      {
        kanji: "演奏",
        furigana: "えんそう",
        romaji: "ensou",
        traducao: "performance musical",
      },
      {
        kanji: "演劇",
        furigana: "えんげき",
        romaji: "engeki",
        traducao: "teatro",
      },
    ],
  },
  {
    kanji: "歳",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "とし", romaji: "toshi" }],
    significado: "idade",
    exemplos: [
      {
        kanji: "歳",
        furigana: "とし",
        romaji: "toshi",
        traducao: "idade",
      },
      {
        kanji: "十歳",
        furigana: "じゅっさい",
        romaji: "jussai",
        traducao: "dez anos de idade",
      },
      {
        kanji: "歳末",
        furigana: "さいまつ",
        romaji: "saimatsu",
        traducao: "final do ano",
      },
    ],
  },
  {
    kanji: "争",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "あらそ.う", romaji: "arasou" }],
    significado: "conflito",
    exemplos: [
      {
        kanji: "争い",
        furigana: "あらそい",
        romaji: "arasoi",
        traducao: "conflito",
      },
      {
        kanji: "競争",
        furigana: "きょうそう",
        romaji: "kyousou",
        traducao: "competição",
      },
      {
        kanji: "争う",
        furigana: "あらそう",
        romaji: "arasou",
        traducao: "lutar",
      },
    ],
  },
  {
    kanji: "談",
    onYomi: [{ katakana: "ダン", romaji: "dan" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "discussão",
    exemplos: [
      {
        kanji: "話談",
        furigana: "わだん",
        romaji: "wadan",
        traducao: "conversa",
      },
      {
        kanji: "交談",
        furigana: "こうだん",
        romaji: "koudan",
        traducao: "conversação",
      },
      {
        kanji: "談話",
        furigana: "だんわ",
        romaji: "danwa",
        traducao: "discussão",
      },
    ],
  },
  {
    kanji: "能",
    onYomi: [{ katakana: "ノウ", romaji: "nou" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "habilidade",
    exemplos: [
      {
        kanji: "能力",
        furigana: "のうりょく",
        romaji: "nouryoku",
        traducao: "habilidade",
      },
      {
        kanji: "可能",
        furigana: "かのう",
        romaji: "kanou",
        traducao: "possível",
      },
      {
        kanji: "能動的",
        furigana: "のうどうてき",
        romaji: "noudouteki",
        traducao: "ativo",
      },
    ],
  },
  {
    kanji: "位",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "くらい", romaji: "kurai" }],
    significado: "posição",
    exemplos: [
      {
        kanji: "位置",
        furigana: "いち",
        romaji: "ichi",
        traducao: "posição",
      },
      {
        kanji: "位",
        furigana: "くらい",
        romaji: "kurai",
        traducao: "grau",
      },
      {
        kanji: "位取り",
        furigana: "くらいどり",
        romaji: "kuraidori",
        traducao: "classificação",
      },
    ],
  },
  {
    kanji: "置",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [{ hiragana: "お.く", romaji: "o.ku" }],
    significado: "colocar",
    exemplos: [
      {
        kanji: "置く",
        furigana: "おく",
        romaji: "oku",
        traducao: "colocar",
      },
      {
        kanji: "位置",
        furigana: "いち",
        romaji: "ichi",
        traducao: "posição",
      },
      {
        kanji: "配置",
        furigana: "はいち",
        romaji: "haichi",
        traducao: "disposição",
      },
    ],
  },
  {
    kanji: "流",
    onYomi: [{ katakana: "リュウ", romaji: "ryuu" }],
    kunYomi: [{ hiragana: "なが.れる", romaji: "naga.reru" }],
    significado: "fluxo",
    exemplos: [
      {
        kanji: "流行",
        furigana: "りゅうこう",
        romaji: "ryuukou",
        traducao: "moda",
      },
      {
        kanji: "流れる",
        furigana: "ながれる",
        romaji: "nagareru",
        traducao: "fluir",
      },
      {
        kanji: "流域",
        furigana: "りゅういき",
        romaji: "ryuuiki",
        traducao: "bacia hidrográfica",
      },
    ],
  },
  {
    kanji: "格",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "status",
    exemplos: [
      {
        kanji: "格差",
        furigana: "かくさ",
        romaji: "kakusa",
        traducao: "desigualdade",
      },
      {
        kanji: "身分格差",
        furigana: "みぶんかくさ",
        romaji: "mibunkakusa",
        traducao: "disparidade social",
      },
      {
        kanji: "身体格闘技",
        furigana: "しんたいかくとうぎ",
        romaji: "shintai kakutougi",
        traducao: "artes marciais",
      },
    ],
  },
  {
    kanji: "疑",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [{ hiragana: "うたが.う", romaji: "utaga.u" }],
    significado: "duvidar",
    exemplos: [
      {
        kanji: "疑問",
        furigana: "ぎもん",
        romaji: "gimon",
        traducao: "pergunta",
      },
      {
        kanji: "疑う",
        furigana: "うたがう",
        romaji: "utagau",
        traducao: "duvidar",
      },
      {
        kanji: "疑い",
        furigana: "うたがい",
        romaji: "utagai",
        traducao: "suspeita",
      },
    ],
  },
  {
    kanji: "過",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [
      { hiragana: "す.ぎる", romaji: "su.giru" },
      { hiragana: "す.ごす", romaji: "su.gosu" },
    ],
    significado: "passar",
    exemplos: [
      {
        kanji: "過去",
        furigana: "かこ",
        romaji: "kako",
        traducao: "passado",
      },
      {
        kanji: "過ぎる",
        furigana: "すぎる",
        romaji: "sugiru",
        traducao: "passar",
      },
      {
        kanji: "過ごす",
        furigana: "すごす",
        romaji: "sugosu",
        traducao: "passar (tempo)",
      },
    ],
  },
  {
    kanji: "局",
    onYomi: [{ katakana: "キョク", romaji: "kyoku" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "departamento",
    exemplos: [
      {
        kanji: "警察局",
        furigana: "けいさつきょく",
        romaji: "keisatsu kyoku",
        traducao: "delegacia de polícia",
      },
      {
        kanji: "局面",
        furigana: "きょくめん",
        romaji: "kyokumen",
        traducao: "situação",
      },
      {
        kanji: "局所",
        furigana: "きょくしょ",
        romaji: "kyokusho",
        traducao: "localizado",
      },
    ],
  },
  {
    kanji: "放",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "はな.す", romaji: "hana.su" }],
    significado: "liberar",
    exemplos: [
      {
        kanji: "放送",
        furigana: "ほうそう",
        romaji: "housou",
        traducao: "transmissão",
      },
      {
        kanji: "解放",
        furigana: "かいほう",
        romaji: "kaihou",
        traducao: "libertação",
      },
      {
        kanji: "放す",
        furigana: "はなす",
        romaji: "hanasu",
        traducao: "liberar",
      },
    ],
  },
  {
    kanji: "常",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "つね", romaji: "tsune" }],
    significado: "comum",
    exemplos: [
      {
        kanji: "常識",
        furigana: "じょうしき",
        romaji: "joushiki",
        traducao: "senso comum",
      },
      {
        kanji: "常に",
        furigana: "つねに",
        romaji: "tsune ni",
        traducao: "sempre",
      },
      {
        kanji: "常用",
        furigana: "じょうよう",
        romaji: "jouyou",
        traducao: "uso comum",
      },
    ],
  },
  {
    kanji: "状",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "estado",
    exemplos: [
      {
        kanji: "状況",
        furigana: "じょうきょう",
        romaji: "joukyou",
        traducao: "situação",
      },
      {
        kanji: "状態",
        furigana: "じょうたい",
        romaji: "joutai",
        traducao: "estado",
      },
      {
        kanji: "事情",
        furigana: "じじょう",
        romaji: "jijou",
        traducao: "circunstância",
      },
    ],
  },
  {
    kanji: "球",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "たま", romaji: "tama" }],
    significado: "bola",
    exemplos: [
      {
        kanji: "野球",
        furigana: "やきゅう",
        romaji: "yakyuu",
        traducao: "beisebol",
      },
      {
        kanji: "球場",
        furigana: "きゅうじょう",
        romaji: "kyuujou",
        traducao: "estádio",
      },
      {
        kanji: "水球",
        furigana: "すいきゅう",
        romaji: "suikyuu",
        traducao: "polo aquático",
      },
    ],
  },
  {
    kanji: "職",
    onYomi: [{ katakana: "ショク", romaji: "shoku" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "ocupação",
    exemplos: [
      {
        kanji: "職業",
        furigana: "しょくぎょう",
        romaji: "shokugyou",
        traducao: "profissão",
      },
      {
        kanji: "職場",
        furigana: "しょくば",
        romaji: "shokuba",
        traducao: "local de trabalho",
      },
      {
        kanji: "就職",
        furigana: "しゅうしょく",
        romaji: "shuushoku",
        traducao: "emprego",
      },
    ],
  },
  {
    kanji: "与",
    onYomi: [{ katakana: "ヨ", romaji: "yo" }],
    kunYomi: [{ hiragana: "あた.える", romaji: "ata.eru" }],
    significado: "dar",
    exemplos: [
      {
        kanji: "提供",
        furigana: "ていきょう",
        romaji: "teikyou",
        traducao: "fornecer",
      },
      {
        kanji: "関与",
        furigana: "かんよ",
        romaji: "kan'yo",
        traducao: "envolvimento",
      },
      {
        kanji: "共与",
        furigana: "きょうよ",
        romaji: "kyouyo",
        traducao: "participação",
      },
    ],
  },
  {
    kanji: "供",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "そな.える", romaji: "sona.eru" }],
    significado: "fornecer",
    exemplos: [
      {
        kanji: "提供",
        furigana: "ていきょう",
        romaji: "teikyou",
        traducao: "fornecer",
      },
      {
        kanji: "供給",
        furigana: "きょうきゅう",
        romaji: "kyoukyuu",
        traducao: "fornecimento",
      },
      {
        kanji: "供える",
        furigana: "そなえる",
        romaji: "sonaeru",
        traducao: "oferecer",
      },
    ],
  },
  {
    kanji: "役",
    onYomi: [{ katakana: "ヤク", romaji: "yaku" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "papel",
    exemplos: [
      {
        kanji: "役割",
        furigana: "やくわり",
        romaji: "yakuwari",
        traducao: "papel",
      },
      {
        kanji: "主役",
        furigana: "しゅやく",
        romaji: "shuyaku",
        traducao: "protagonista",
      },
      {
        kanji: "公務役",
        furigana: "こうむやく",
        romaji: "koumu yaku",
        traducao: "funcionário público",
      },
    ],
  },
  {
    kanji: "構",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "かま.える", romaji: "kama.eru" }],
    significado: "construir",
    exemplos: [
      {
        kanji: "構造",
        furigana: "こうぞう",
        romaji: "kouzou",
        traducao: "estrutura",
      },
      {
        kanji: "構築",
        furigana: "こうちく",
        romaji: "kouchiku",
        traducao: "construção",
      },
      {
        kanji: "構う",
        furigana: "かまう",
        romaji: "kamau",
        traducao: "preocupar-se",
      },
    ],
  },
  {
    kanji: "割",
    onYomi: [{ katakana: "カツ", romaji: "katsu" }],
    kunYomi: [
      { hiragana: "わ.る", romaji: "wa.ru" },
      { hiragana: "わり", romaji: "wari" },
    ],
    significado: "dividir",
    exemplos: [
      {
        kanji: "割合",
        furigana: "わりあい",
        romaji: "wariai",
        traducao: "proporção",
      },
      {
        kanji: "割る",
        furigana: "わる",
        romaji: "waru",
        traducao: "dividir",
      },
      {
        kanji: "割引",
        furigana: "わりびき",
        romaji: "waribiki",
        traducao: "desconto",
      },
    ],
  },
  {
    kanji: "身",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "み", romaji: "mi" }],
    significado: "corpo",
    exemplos: [
      {
        kanji: "身体",
        furigana: "しんたい",
        romaji: "shintai",
        traducao: "corpo",
      },
      {
        kanji: "身長",
        furigana: "しんちょう",
        romaji: "shinchou",
        traducao: "altura",
      },
      {
        kanji: "身分",
        furigana: "みぶん",
        romaji: "mibun",
        traducao: "status",
      },
    ],
  },
  {
    kanji: "費",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "つい.やす", romaji: "tsui.yasu" }],
    significado: "despesa",
    exemplos: [
      {
        kanji: "費用",
        furigana: "ひよう",
        romaji: "hiyou",
        traducao: "custo",
      },
      {
        kanji: "消費",
        furigana: "しょうひ",
        romaji: "shouhi",
        traducao: "consumo",
      },
      {
        kanji: "経費",
        furigana: "けいひ",
        romaji: "keihi",
        traducao: "despesas",
      },
    ],
  },
  {
    kanji: "付",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "つ.ける", romaji: "tsu.keru" }],
    significado: "anexar",
    exemplos: [
      {
        kanji: "付近",
        furigana: "ふきん",
        romaji: "fukin",
        traducao: "vizinhança",
      },
      {
        kanji: "付ける",
        furigana: "つける",
        romaji: "tsukeru",
        traducao: "anexar",
      },
      {
        kanji: "関連付ける",
        furigana: "かんれんつける",
        romaji: "kanren tsukeru",
        traducao: "relacionar",
      },
    ],
  },
  {
    kanji: "由",
    onYomi: [{ katakana: "ユ", romaji: "yu" }],
    kunYomi: [{ hiragana: "よし", romaji: "yoshi" }],
    significado: "causa",
    exemplos: [
      {
        kanji: "理由",
        furigana: "りゆう",
        romaji: "riyuu",
        traducao: "motivo",
      },
      {
        kanji: "原因",
        furigana: "げんいん",
        romaji: "genin",
        traducao: "causa",
      },
      {
        kanji: "由来",
        furigana: "ゆらい",
        romaji: "yurai",
        traducao: "origem",
      },
    ],
  },
  {
    kanji: "説",
    onYomi: [{ katakana: "セツ", romaji: "setsu" }],
    kunYomi: [{ hiragana: "と.く", romaji: "to.ku" }],
    significado: "explicar",
    exemplos: [
      {
        kanji: "説明",
        furigana: "せつめい",
        romaji: "setsumei",
        traducao: "explicação",
      },
      {
        kanji: "説得",
        furigana: "せっとく",
        romaji: "settoku",
        traducao: "persuasão",
      },
      {
        kanji: "解説",
        furigana: "かいせつ",
        romaji: "kaisetsu",
        traducao: "comentário",
      },
    ],
  },
  {
    kanji: "難",
    onYomi: [{ katakana: "ナン", romaji: "nan" }],
    kunYomi: [{ hiragana: "むずか.しい", romaji: "muzuka.shii" }],
    significado: "difícil",
    exemplos: [
      {
        kanji: "難しい",
        furigana: "むずかしい",
        romaji: "muzukashii",
        traducao: "difícil",
      },
      {
        kanji: "困難",
        furigana: "こんなん",
        romaji: "konnan",
        traducao: "desafiador",
      },
      {
        kanji: "難関",
        furigana: "なんかん",
        romaji: "nankan",
        traducao: "desafio",
      },
    ],
  },
  {
    kanji: "優",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [
      { hiragana: "やさ.しい", romaji: "yasa.shii" },
      { hiragana: "すぐ.れる", romaji: "sugu.reru" },
    ],
    significado: "excelente",
    exemplos: [
      {
        kanji: "優れる",
        furigana: "すぐれる",
        romaji: "sugureru",
        traducao: "ser excelente",
      },
      {
        kanji: "優先",
        furigana: "ゆうせん",
        romaji: "yuusen",
        traducao: "prioridade",
      },
      {
        kanji: "優勝",
        furigana: "ゆうしょう",
        romaji: "yuushou",
        traducao: "campeão",
      },
    ],
  },
  {
    kanji: "夫",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "おっと", romaji: "otto" }],
    significado: "marido",
    exemplos: [
      {
        kanji: "夫婦",
        furigana: "ふうふ",
        romaji: "fuufu",
        traducao: "casal",
      },
      {
        kanji: "夫人",
        furigana: "ふじん",
        romaji: "fujin",
        traducao: "senhora",
      },
      {
        kanji: "夫妻",
        furigana: "ふさい",
        romaji: "fusai",
        traducao: "marido e esposa",
      },
    ],
  },
  {
    kanji: "収",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [{ hiragana: "おさ.める", romaji: "osa.meru" }],
    significado: "receber",
    exemplos: [
      {
        kanji: "収入",
        furigana: "しゅうにゅう",
        romaji: "shuunyuu",
        traducao: "renda",
      },
      {
        kanji: "収集",
        furigana: "しゅうしゅう",
        romaji: "shuushuu",
        traducao: "coleta",
      },
      {
        kanji: "収める",
        furigana: "おさめる",
        romaji: "osameru",
        traducao: "receber",
      },
    ],
  },
  {
    kanji: "断",
    onYomi: [{ katakana: "ダン", romaji: "dan" }],
    kunYomi: [{ hiragana: "た.つ", romaji: "ta.tsu" }],
    significado: "negar",
    exemplos: [
      {
        kanji: "拒否",
        furigana: "きょひ",
        romaji: "kyohi",
        traducao: "recusa",
      },
      {
        kanji: "中断",
        furigana: "ちゅうだん",
        romaji: "chuudan",
        traducao: "interrupção",
      },
      {
        kanji: "断る",
        furigana: "ことわる",
        romaji: "kotowaru",
        traducao: "recusar",
      },
    ],
  },
  {
    kanji: "石",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "いし", romaji: "ishi" }],
    significado: "pedra",
    exemplos: [
      {
        kanji: "石",
        furigana: "いし",
        romaji: "ishi",
        traducao: "pedra",
      },
      {
        kanji: "宝石",
        furigana: "ほうせき",
        romaji: "houseki",
        traducao: "joia",
      },
      {
        kanji: "石油",
        furigana: "せきゆ",
        romaji: "sekiyu",
        traducao: "petróleo",
      },
    ],
  },
  {
    kanji: "違",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "ちが.う", romaji: "chiga.u" }],
    significado: "diferente",
    exemplos: [
      {
        kanji: "違い",
        furigana: "ちがい",
        romaji: "chigai",
        traducao: "diferença",
      },
      {
        kanji: "違反",
        furigana: "いはん",
        romaji: "ihan",
        traducao: "violação",
      },
      {
        kanji: "間違い",
        furigana: "まちがい",
        romaji: "machigai",
        traducao: "erro",
      },
    ],
  },
  {
    kanji: "消",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "け.す", romaji: "ke.su" }],
    significado: "apagar",
    exemplos: [
      {
        kanji: "消化",
        furigana: "しょうか",
        romaji: "shouka",
        traducao: "digestão",
      },
      {
        kanji: "消防",
        furigana: "しょうぼう",
        romaji: "shoubou",
        traducao: "bombeiro",
      },
      {
        kanji: "消す",
        furigana: "けす",
        romaji: "kesu",
        traducao: "apagar",
      },
    ],
  },
  {
    kanji: "神",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "かみ", romaji: "kami" }],
    significado: "deus",
    exemplos: [
      {
        kanji: "神社",
        furigana: "じんじゃ",
        romaji: "jinja",
        traducao: "santuário",
      },
      {
        kanji: "神様",
        furigana: "かみさま",
        romaji: "kamisama",
        traducao: "divindade",
      },
      {
        kanji: "神秘的",
        furigana: "しんぴてき",
        romaji: "shinpiteki",
        traducao: "misterioso",
      },
    ],
  },
  {
    kanji: "番",
    onYomi: [{ katakana: "バン", romaji: "ban" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "número",
    exemplos: [
      {
        kanji: "番号",
        furigana: "ばんごう",
        romaji: "bangou",
        traducao: "número",
      },
      {
        kanji: "番組",
        furigana: "ばんぐみ",
        romaji: "bangumi",
        traducao: "programa de TV",
      },
      {
        kanji: "順番",
        furigana: "じゅんばん",
        romaji: "junban",
        traducao: "ordem",
      },
    ],
  },
  {
    kanji: "規",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "regra",
    exemplos: [
      {
        kanji: "規則",
        furigana: "きそく",
        romaji: "kisoku",
        traducao: "regra",
      },
      {
        kanji: "規模",
        furigana: "きぼ",
        romaji: "kibo",
        traducao: "escala",
      },
      {
        kanji: "基準",
        furigana: "きじゅん",
        romaji: "kijun",
        traducao: "critério",
      },
    ],
  },
  {
    kanji: "術",
    onYomi: [{ katakana: "ジュツ", romaji: "jutsu" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "técnica",
    exemplos: [
      {
        kanji: "技術",
        furigana: "ぎじゅつ",
        romaji: "gijutsu",
        traducao: "tecnologia",
      },
      {
        kanji: "手術",
        furigana: "しゅじゅつ",
        romaji: "shujutsu",
        traducao: "cirurgia",
      },
      {
        kanji: "魔術",
        furigana: "まじゅつ",
        romaji: "majutsu",
        traducao: "magia",
      },
    ],
  },
  {
    kanji: "備",
    onYomi: [{ katakana: "ビ", romaji: "bi" }],
    kunYomi: [{ hiragana: "そな.える", romaji: "sona.eru" }],
    significado: "preparar",
    exemplos: [
      {
        kanji: "準備",
        furigana: "じゅんび",
        romaji: "junbi",
        traducao: "preparação",
      },
      {
        kanji: "装備",
        furigana: "そうび",
        romaji: "soubi",
        traducao: "equipamento",
      },
      {
        kanji: "備える",
        furigana: "そなえる",
        romaji: "sonaeru",
        traducao: "preparar",
      },
    ],
  },
  {
    kanji: "宅",
    onYomi: [{ katakana: "タク", romaji: "taku" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "residência",
    exemplos: [
      {
        kanji: "自宅",
        furigana: "じたく",
        romaji: "jitaku",
        traducao: "casa própria",
      },
      {
        kanji: "宅配",
        furigana: "たくはい",
        romaji: "takuhai",
        traducao: "entrega em domicílio",
      },
      {
        kanji: "宅急便",
        furigana: "たっきゅうびん",
        romaji: "takkyuubin",
        traducao: "entrega expressa",
      },
    ],
  },
  {
    kanji: "害",
    onYomi: [{ katakana: "ガイ", romaji: "gai" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "dano",
    exemplos: [
      {
        kanji: "被害",
        furigana: "ひがい",
        romaji: "higai",
        traducao: "prejuízo",
      },
      {
        kanji: "害虫",
        furigana: "がいちゅう",
        romaji: "gaichuu",
        traducao: "inseto nocivo",
      },
      {
        kanji: "害する",
        furigana: "がいする",
        romaji: "gaisuru",
        traducao: "prejudicar",
      },
    ],
  },
  {
    kanji: "配",
    onYomi: [{ katakana: "ハイ", romaji: "hai" }],
    kunYomi: [{ hiragana: "くば.る", romaji: "kuba.ru" }],
    significado: "distribuir",
    exemplos: [
      {
        kanji: "配達",
        furigana: "はいたつ",
        romaji: "haitatsu",
        traducao: "entrega",
      },
      {
        kanji: "配慮",
        furigana: "はいりょ",
        romaji: "hairyo",
        traducao: "consideração",
      },
      {
        kanji: "配る",
        furigana: "くばる",
        romaji: "kubaru",
        traducao: "distribuir",
      },
    ],
  },
  {
    kanji: "警",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "polícia",
    exemplos: [
      {
        kanji: "警察",
        furigana: "けいさつ",
        romaji: "keisatsu",
        traducao: "polícia",
      },
      {
        kanji: "警告",
        furigana: "けいこく",
        romaji: "keikoku",
        traducao: "advertência",
      },
      {
        kanji: "警報",
        furigana: "けいほう",
        romaji: "keihou",
        traducao: "alarme",
      },
    ],
  },
  {
    kanji: "育",
    onYomi: [{ katakana: "イク", romaji: "iku" }],
    kunYomi: [{ hiragana: "そだ.つ", romaji: "soda.tsu" }],
    significado: "crescer",
    exemplos: [
      {
        kanji: "教育",
        furigana: "きょういく",
        romaji: "kyouiku",
        traducao: "educação",
      },
      {
        kanji: "成育",
        furigana: "せいいく",
        romaji: "seiiiku",
        traducao: "cuidado infantil",
      },
      {
        kanji: "育つ",
        furigana: "そだつ",
        romaji: "sodatsu",
        traducao: "crescer",
      },
    ],
  },
  {
    kanji: "席",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "assento",
    exemplos: [
      {
        kanji: "座席",
        furigana: "ざせき",
        romaji: "zaseki",
        traducao: "assento",
      },
      {
        kanji: "席",
        furigana: "せき",
        romaji: "seki",
        traducao: "lugar",
      },
      {
        kanji: "空席",
        furigana: "くうせき",
        romaji: "kuuseki",
        traducao: "assento vazio",
      },
    ],
  },
  {
    kanji: "訪",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "おとず.れる", romaji: "otozu.reru" }],
    significado: "visitar",
    exemplos: [
      {
        kanji: "訪問",
        furigana: "ほうもん",
        romaji: "houmon",
        traducao: "visita",
      },
      {
        kanji: "訪れる",
        furigana: "おとずれる",
        romaji: "otozureru",
        traducao: "visitar",
      },
      {
        kanji: "訪ねる",
        furigana: "たずねる",
        romaji: "tazuneru",
        traducao: "fazer uma visita",
      },
    ],
  },
  {
    kanji: "乗",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "の.る", romaji: "no.ru" }],
    significado: "montar",
    exemplos: [
      {
        kanji: "乗車",
        furigana: "じょうしゃ",
        romaji: "jousha",
        traducao: "entrar (em um veículo)",
      },
      {
        kanji: "乗り物",
        furigana: "のりもの",
        romaji: "norimono",
        traducao: "veículo",
      },
      {
        kanji: "乗る",
        furigana: "のる",
        romaji: "noru",
        traducao: "montar",
      },
    ],
  },
  {
    kanji: "残",
    onYomi: [{ katakana: "ザン", romaji: "zan" }],
    kunYomi: [{ hiragana: "のこ.る", romaji: "noko.ru" }],
    significado: "restar",
    exemplos: [
      {
        kanji: "残念",
        furigana: "ざんねん",
        romaji: "zannen",
        traducao: "lamentável",
      },
      {
        kanji: "残り",
        furigana: "のこり",
        romaji: "nokori",
        traducao: "restante",
      },
      {
        kanji: "残る",
        furigana: "のこる",
        romaji: "nokoru",
        traducao: "restar",
      },
    ],
  },
  {
    kanji: "想",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "imaginar",
    exemplos: [
      {
        kanji: "思想",
        furigana: "しそう",
        romaji: "shisou",
        traducao: "pensamento",
      },
      {
        kanji: "想像",
        furigana: "そうぞう",
        romaji: "souzou",
        traducao: "imaginação",
      },
      {
        kanji: "予想",
        furigana: "よそう",
        romaji: "yosou",
        traducao: "previsão",
      },
    ],
  },
  {
    kanji: "声",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "こえ", romaji: "koe" }],
    significado: "voz",
    exemplos: [
      {
        kanji: "音声",
        furigana: "おんせい",
        romaji: "onsei",
        traducao: "som",
      },
      {
        kanji: "声",
        furigana: "こえ",
        romaji: "koe",
        traducao: "voz",
      },
      {
        kanji: "発声",
        furigana: "はっせい",
        romaji: "hasssei",
        traducao: "pronúncia",
      },
    ],
  },
  {
    kanji: "助",
    onYomi: [{ katakana: "ジョ", romaji: "jo" }],
    kunYomi: [{ hiragana: "たす.ける", romaji: "tasu.keru" }],
    significado: "ajudar",
    exemplos: [
      {
        kanji: "助言",
        furigana: "じょげん",
        romaji: "jogen",
        traducao: "conselho",
      },
      {
        kanji: "助手",
        furigana: "じょしゅ",
        romaji: "joshu",
        traducao: "assistente",
      },
      {
        kanji: "助ける",
        furigana: "たすける",
        romaji: "tasukeru",
        traducao: "ajudar",
      },
    ],
  },
  {
    kanji: "労",
    onYomi: [{ katakana: "ロウ", romaji: "rou" }],
    kunYomi: [{ hiragana: "ろう", romaji: "rou" }],
    significado: "trabalho",
    exemplos: [
      {
        kanji: "労働",
        furigana: "ろうどう",
        romaji: "roudou",
        traducao: "trabalho",
      },
      {
        kanji: "労力",
        furigana: "ろうりょく",
        romaji: "rouryoku",
        traducao: "esforço",
      },
      {
        kanji: "労わる",
        furigana: "いたわる",
        romaji: "itawaru",
        traducao: "cuidar de alguém",
      },
    ],
  },
  {
    kanji: "例",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [{ hiragana: "たと.える", romaji: "tato.eru" }],
    significado: "exemplo",
    exemplos: [
      {
        kanji: "例文",
        furigana: "れいぶん",
        romaji: "reibun",
        traducao: "frase de exemplo",
      },
      {
        kanji: "例外",
        furigana: "れいがい",
        romaji: "reigai",
        traducao: "exceção",
      },
      {
        kanji: "例える",
        furigana: "たとえる",
        romaji: "tatoeru",
        traducao: "exemplificar",
      },
    ],
  },
  {
    kanji: "然",
    onYomi: [{ katakana: "ゼン", romaji: "zen" }],
    kunYomi: [{ hiragana: "しか", romaji: "shika" }],
    significado: "assim",
    exemplos: [
      {
        kanji: "自然",
        furigana: "しぜん",
        romaji: "shizen",
        traducao: "natureza",
      },
      {
        kanji: "当然",
        furigana: "とうぜん",
        romaji: "touzen",
        traducao: "natural",
      },
      {
        kanji: "しかるべき",
        furigana: "しかるべき",
        romaji: "shikarubeki",
        traducao: "devido, apropriado",
      },
    ],
  },
  {
    kanji: "限",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [{ hiragana: "かぎ.る", romaji: "kagi.ru" }],
    significado: "limite",
    exemplos: [
      {
        kanji: "制限",
        furigana: "せいげん",
        romaji: "seigen",
        traducao: "restrição",
      },
      {
        kanji: "期限",
        furigana: "きげん",
        romaji: "kigen",
        traducao: "prazo",
      },
      {
        kanji: "限る",
        furigana: "かぎる",
        romaji: "kagiru",
        traducao: "limitar",
      },
    ],
  },
  {
    kanji: "追",
    onYomi: [{ katakana: "ツイ", romaji: "tsui" }],
    kunYomi: [{ hiragana: "お.う", romaji: "o.u" }],
    significado: "perseguir",
    exemplos: [
      {
        kanji: "追求",
        furigana: "ついきゅう",
        romaji: "tsuikyuu",
        traducao: "perseguir",
      },
      {
        kanji: "追加",
        furigana: "ついか",
        romaji: "tsuika",
        traducao: "adição",
      },
      {
        kanji: "追う",
        furigana: "おう",
        romaji: "ou",
        traducao: "perseguir",
      },
    ],
  },
  {
    kanji: "商",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "あきな.う", romaji: "akina.u" }],
    significado: "comércio",
    exemplos: [
      {
        kanji: "商業",
        furigana: "しょうぎょう",
        romaji: "shougyou",
        traducao: "comércio",
      },
      {
        kanji: "商売",
        furigana: "しょうばい",
        romaji: "shoubai",
        traducao: "negócio",
      },
      {
        kanji: "商う",
        furigana: "あきなう",
        romaji: "akinau",
        traducao: "negociar",
      },
    ],
  },
  {
    kanji: "葉",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "は", romaji: "ha" }],
    significado: "folha",
    exemplos: [
      {
        kanji: "葉書",
        furigana: "はがき",
        romaji: "hagaki",
        traducao: "cartão postal",
      },
      {
        kanji: "葉っぱ",
        furigana: "はっぱ",
        romaji: "happa",
        traducao: "folha",
      },
      {
        kanji: "落ち葉",
        furigana: "おちば",
        romaji: "ochiba",
        traducao: "folha caída",
      },
    ],
  },
  {
    kanji: "伝",
    onYomi: [{ katakana: "デン", romaji: "den" }],
    kunYomi: [{ hiragana: "つた.わる", romaji: "tsuta.waru" }],
    significado: "transmitir",
    exemplos: [
      {
        kanji: "伝言",
        furigana: "でんごん",
        romaji: "dengon",
        traducao: "recado",
      },
      {
        kanji: "伝える",
        furigana: "つたえる",
        romaji: "tsutaeru",
        traducao: "transmitir",
      },
      {
        kanji: "伝わる",
        furigana: "つたわる",
        romaji: "tsutawaru",
        traducao: "ser transmitido",
      },
    ],
  },
  {
    kanji: "働",
    onYomi: [{ katakana: "ドウ", romaji: "dou" }],
    kunYomi: [{ hiragana: "はたら.く", romaji: "hataraku" }],
    significado: "trabalhar",
    exemplos: [
      {
        kanji: "労働",
        furigana: "ろうどう",
        romaji: "roudou",
        traducao: "trabalho",
      },
      {
        kanji: "働く",
        furigana: "はたらく",
        romaji: "hataraku",
        traducao: "trabalhar",
      },
      {
        kanji: "働き者",
        furigana: "はたらきもの",
        romaji: "hatarakimono",
        traducao: "trabalhador",
      },
    ],
  },
  {
    kanji: "形",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "かたち", romaji: "katachi" }],
    significado: "forma",
    exemplos: [
      {
        kanji: "形",
        furigana: "かたち",
        romaji: "katachi",
        traducao: "forma",
      },
      {
        kanji: "形成",
        furigana: "けいせい",
        romaji: "keisei",
        traducao: "formação",
      },
      {
        kanji: "形容詞",
        furigana: "けいようし",
        romaji: "keiyoushi",
        traducao: "adjetivo",
      },
    ],
  },
  {
    kanji: "景",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "", romaji: "" }],
    significado: "paisagem",
    exemplos: [
      {
        kanji: "景色",
        furigana: "けしき",
        romaji: "keshiki",
        traducao: "paisagem",
      },
      {
        kanji: "風景",
        furigana: "ふうけい",
        romaji: "fuukei",
        traducao: "cenário",
      },
      {
        kanji: "夜景",
        furigana: "やけい",
        romaji: "yakei",
        traducao: "paisagem noturna",
      },
    ],
  },
  {
    kanji: "落",
    onYomi: [{ katakana: "ラク", romaji: "raku" }],
    kunYomi: [{ hiragana: "お.ちる", romaji: "o.chiru" }],
    significado: "cair",
    exemplos: [
      {
        kanji: "落ちる",
        furigana: "おちる",
        romaji: "ochiru",
        traducao: "cair",
      },
      {
        kanji: "落とす",
        furigana: "おとす",
        romaji: "otosu",
        traducao: "deixar cair",
      },
      {
        kanji: "落ち着く",
        furigana: "おちつく",
        romaji: "ochitsuku",
        traducao: "acalmar-se",
      },
    ],
  },
  {
    kanji: "好",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "この.む", romaji: "kono.mu" }],
    significado: "gostar",
    exemplos: [
      {
        kanji: "好き",
        furigana: "すき",
        romaji: "suki",
        traducao: "gostar",
      },
      {
        kanji: "好意",
        furigana: "こうい",
        romaji: "koui",
        traducao: "bondade",
      },
      {
        kanji: "好む",
        furigana: "このむ",
        romaji: "konomu",
        traducao: "gostar de",
      },
    ],
  },
  {
    kanji: "退",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "しりぞ.く", romaji: "shirizo.ku" }],
    significado: "retirar-se",
    exemplos: [
      {
        kanji: "退社",
        furigana: "たいしゃ",
        romaji: "taisha",
        traducao: "deixar o trabalho",
      },
      {
        kanji: "退場",
        furigana: "たいじょう",
        romaji: "taijou",
        traducao: "sair do palco",
      },
      {
        kanji: "退く",
        furigana: "しりぞく",
        romaji: "shirizoku",
        traducao: "retirar-se",
      },
    ],
  },
  {
    kanji: "頭",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "あたま", romaji: "atama" }],
    significado: "cabeça",
    exemplos: [
      {
        kanji: "頭痛",
        furigana: "ずつう",
        romaji: "zutsuu",
        traducao: "dor de cabeça",
      },
      {
        kanji: "頭文字",
        furigana: "かしらもじ",
        romaji: "kashiramoji",
        traducao: "letra inicial",
      },
      {
        kanji: "頭",
        furigana: "あたま",
        romaji: "atama",
        traducao: "cabeça",
      },
    ],
  },
  {
    kanji: "負",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "ま.ける", romaji: "ma.keru" }],
    significado: "perder",
    exemplos: [
      {
        kanji: "負担",
        furigana: "ふたん",
        romaji: "futan",
        traducao: "carga",
      },
      {
        kanji: "負ける",
        furigana: "まける",
        romaji: "makeru",
        traducao: "perder",
      },
      {
        kanji: "負う",
        furigana: "おう",
        romaji: "ou",
        traducao: "assumir",
      },
    ],
  },
  {
    kanji: "渡",
    onYomi: [{ katakana: "ト", romaji: "to" }],
    kunYomi: [{ hiragana: "わた.る", romaji: "wata.ru" }],
    significado: "atravessar",
    exemplos: [
      {
        kanji: "渡航",
        furigana: "とこう",
        romaji: "tokou",
        traducao: "viagem",
      },
      {
        kanji: "渡す",
        furigana: "わたす",
        romaji: "watasu",
        traducao: "entregar",
      },
      {
        kanji: "渡る",
        furigana: "わたる",
        romaji: "wataru",
        traducao: "atravessar",
      },
    ],
  },
  {
    kanji: "失",
    onYomi: [{ katakana: "シツ", romaji: "shitsu" }],
    kunYomi: [{ hiragana: "うしな.う", romaji: "ushina.u" }],
    significado: "perder",
    exemplos: [
      {
        kanji: "失敗",
        furigana: "しっぱい",
        romaji: "shippai",
        traducao: "fracasso",
      },
      {
        kanji: "失望",
        furigana: "しつぼう",
        romaji: "shitsubou",
        traducao: "desapontamento",
      },
      {
        kanji: "失う",
        furigana: "うしなう",
        romaji: "ushinau",
        traducao: "perder",
      },
    ],
  },
  {
    kanji: "差",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [{ hiragana: "さ.す", romaji: "sa.su" }],
    significado: "diferença",
    exemplos: [
      {
        kanji: "差別",
        furigana: "さべつ",
        romaji: "sabetsu",
        traducao: "discriminação",
      },
      {
        kanji: "差し支える",
        furigana: "さしつかえる",
        romaji: "sashitsukaeru",
        traducao: "incomodar",
      },
      {
        kanji: "差す",
        furigana: "さす",
        romaji: "sasu",
        traducao: "apontar",
      },
    ],
  },
  {
    kanji: "末",
    onYomi: [
      { katakana: "マツ", romaji: "matsu" },
      { katakana: "バツ", romaji: "batsu" },
    ],
    kunYomi: [{ hiragana: "すえ", romaji: "sue" }],
    significado: "fim",
    exemplos: [
      {
        kanji: "末尾",
        furigana: "まつび",
        romaji: "matsubi",
        traducao: "final",
      },
      {
        kanji: "先月末",
        furigana: "せんげつすえ",
        romaji: "sengetsusue",
        traducao: "final do mês passado",
      },
      {
        kanji: "年末",
        furigana: "ねんまつ",
        romaji: "nenmatsu",
        traducao: "final do ano",
      },
    ],
  },
  {
    kanji: "守",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [{ hiragana: "まも.る", romaji: "mamo.ru" }],
    significado: "proteger",
    exemplos: [
      {
        kanji: "守護",
        furigana: "しゅご",
        romaji: "shugo",
        traducao: "proteção",
      },
      {
        kanji: "守備",
        furigana: "しゅび",
        romaji: "shubi",
        traducao: "defesa",
      },
      {
        kanji: "守る",
        furigana: "まもる",
        romaji: "mamoru",
        traducao: "proteger",
      },
    ],
  },
  {
    kanji: "若",
    onYomi: [{ katakana: "ジャク", romaji: "jaku" }],
    kunYomi: [{ hiragana: "わか.い", romaji: "waka.i" }],
    significado: "jovem",
    exemplos: [
      {
        kanji: "若者",
        furigana: "わかもの",
        romaji: "wakamono",
        traducao: "jovem",
      },
      {
        kanji: "若干",
        furigana: "じゃっかん",
        romaji: "jyakkan",
        traducao: "um pouco",
      },
      {
        kanji: "若い",
        furigana: "わかい",
        romaji: "wakai",
        traducao: "jovem",
      },
    ],
  },
  {
    kanji: "種",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [{ hiragana: "たね", romaji: "tane" }],
    significado: "tipo",
    exemplos: [
      {
        kanji: "種類",
        furigana: "しゅるい",
        romaji: "shurui",
        traducao: "tipo",
      },
      {
        kanji: "品種",
        furigana: "ひんしゅ",
        romaji: "hinshu",
        traducao: "variedade",
      },
      {
        kanji: "種",
        furigana: "たね",
        romaji: "tane",
        traducao: "semente",
      },
    ],
  },
  {
    kanji: "美",
    onYomi: [{ katakana: "ビ", romaji: "bi" }],
    kunYomi: [{ hiragana: "うつく.しい", romaji: "utsuku.shii" }],
    significado: "beleza",
    exemplos: [
      {
        kanji: "美術",
        furigana: "びじゅつ",
        romaji: "bijutsu",
        traducao: "arte",
      },
      {
        kanji: "美しい",
        furigana: "うつくしい",
        romaji: "utsukushii",
        traducao: "bonito",
      },
      {
        kanji: "美人",
        furigana: "びじん",
        romaji: "bijin",
        traducao: "pessoa bonita",
      },
    ],
  },
  {
    kanji: "命",
    onYomi: [{ katakana: "メイ", romaji: "mei" }],
    kunYomi: [{ hiragana: "いのち", romaji: "inochi" }],
    significado: "vida",
    exemplos: [
      {
        kanji: "命令",
        furigana: "めいれい",
        romaji: "meirei",
        traducao: "ordem",
      },
      {
        kanji: "生命",
        furigana: "せいめい",
        romaji: "seimei",
        traducao: "vida",
      },
      {
        kanji: "命",
        furigana: "いのち",
        romaji: "inochi",
        traducao: "vida",
      },
    ],
  },
  {
    kanji: "福",
    onYomi: [{ katakana: "フク", romaji: "fuku" }],
    kunYomi: [{ hiragana: "さいわ.い", romaji: "saiwa.i" }],
    significado: "sorte",
    exemplos: [
      {
        kanji: "幸福",
        furigana: "こうふく",
        romaji: "koufuku",
        traducao: "felicidade",
      },
      {
        kanji: "福祉",
        furigana: "ふくし",
        romaji: "fukushi",
        traducao: "bem-estar",
      },
      {
        kanji: "福",
        furigana: "ふく",
        romaji: "fuku",
        traducao: "sorte",
      },
    ],
  },
  {
    kanji: "望",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "のぞ.む", romaji: "nozo.mu" }],
    significado: "esperança",
    exemplos: [
      {
        kanji: "希望",
        furigana: "きぼう",
        romaji: "kibou",
        traducao: "esperança",
      },
      {
        kanji: "望む",
        furigana: "のぞむ",
        romaji: "nozomu",
        traducao: "esperar",
      },
      {
        kanji: "願望",
        furigana: "がんぼう",
        romaji: "ganbou",
        traducao: "desejo",
      },
    ],
  },
  {
    kanji: "非",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "あら.ず", romaji: "ara.zu" }],
    significado: "não",
    exemplos: [
      {
        kanji: "非常",
        furigana: "ひじょう",
        romaji: "hijou",
        traducao: "emergência",
      },
      {
        kanji: "非常口",
        furigana: "ひじょうぐち",
        romaji: "hijouguchi",
        traducao: "saída de emergência",
      },
      {
        kanji: "非",
        furigana: "あら",
        romaji: "ara",
        traducao: "não",
      },
    ],
  },
  {
    kanji: "観",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "み.る", romaji: "mi.ru" }],
    significado: "observar",
    exemplos: [
      {
        kanji: "観光",
        furigana: "かんこう",
        romaji: "kankou",
        traducao: "turismo",
      },
      {
        kanji: "観察",
        furigana: "かんさつ",
        romaji: "kansatsu",
        traducao: "observação",
      },
      {
        kanji: "観る",
        furigana: "みる",
        romaji: "miru",
        traducao: "ver, observar",
      },
    ],
  },
  {
    kanji: "察",
    onYomi: [{ katakana: "サツ", romaji: "satsu" }],
    kunYomi: [],
    significado: "perceber",
    exemplos: [
      {
        kanji: "注意",
        furigana: "ちゅうい",
        romaji: "chui",
        traducao: "atenção",
      },
      {
        kanji: "察知",
        furigana: "さっち",
        romaji: "satchi",
        traducao: "percepção",
      },
      {
        kanji: "察する",
        furigana: "さっする",
        romaji: "satsusuru",
        traducao: "perceber",
      },
    ],
  },
  {
    kanji: "段",
    onYomi: [{ katakana: "ダン", romaji: "dan" }],
    kunYomi: [],
    significado: "etapa",
    exemplos: [
      {
        kanji: "階段",
        furigana: "かいだん",
        romaji: "kaidan",
        traducao: "escada",
      },
      {
        kanji: "一段と",
        furigana: "いちだんと",
        romaji: "ichidanto",
        traducao: "ainda mais",
      },
      {
        kanji: "段",
        furigana: "だん",
        romaji: "dan",
        traducao: "etapa",
      },
    ],
  },
  {
    kanji: "横",
    onYomi: [{ katakana: "オウ", romaji: "ou" }],
    kunYomi: [{ hiragana: "よこ", romaji: "yoko" }],
    significado: "horizontal",
    exemplos: [
      {
        kanji: "横断",
        furigana: "おうだん",
        romaji: "oudan",
        traducao: "travessia",
      },
      {
        kanji: "横顔",
        furigana: "よこがお",
        romaji: "yokogao",
        traducao: "perfil",
      },
      {
        kanji: "横",
        furigana: "よこ",
        romaji: "yoko",
        traducao: "horizontal",
      },
    ],
  },
  {
    kanji: "深",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "ふか.い", romaji: "fuka.i" }],
    significado: "profundo",
    exemplos: [
      {
        kanji: "深刻",
        furigana: "しんこく",
        romaji: "shinkoku",
        traducao: "grave",
      },
      {
        kanji: "深夜",
        furigana: "しんや",
        romaji: "shinya",
        traducao: "alta noite",
      },
      {
        kanji: "深い",
        furigana: "ふかい",
        romaji: "fukai",
        traducao: "profundo",
      },
    ],
  },
  {
    kanji: "申",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "もう.す", romaji: "mou.su" }],
    significado: "afirmar",
    exemplos: [
      {
        kanji: "申告",
        furigana: "しんこく",
        romaji: "shinkoku",
        traducao: "declaração",
      },
      {
        kanji: "申し込む",
        furigana: "もうしこむ",
        romaji: "moushikomu",
        traducao: "solicitar",
      },
      {
        kanji: "申す",
        furigana: "もうす",
        romaji: "mousu",
        traducao: "afirmar",
      },
    ],
  },
  {
    kanji: "様",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "さま", romaji: "sama" }],
    significado: "modo",
    exemplos: [
      {
        kanji: "方法",
        furigana: "ほうほう",
        romaji: "houhou",
        traducao: "método",
      },
      {
        kanji: "様子",
        furigana: "ようす",
        romaji: "yousu",
        traducao: "aparência",
      },
      {
        kanji: "様々",
        furigana: "さまざま",
        romaji: "samazama",
        traducao: "vários",
      },
    ],
  },
  {
    kanji: "財",
    onYomi: [{ katakana: "ザイ", romaji: "zai" }],
    kunYomi: [{ hiragana: "たから", romaji: "takara" }],
    significado: "riqueza",
    exemplos: [
      {
        kanji: "財布",
        furigana: "さいふ",
        romaji: "saifu",
        traducao: "carteira",
      },
      {
        kanji: "財産",
        furigana: "ざいさん",
        romaji: "zaisan",
        traducao: "propriedade",
      },
      {
        kanji: "財",
        furigana: "たから",
        romaji: "takara",
        traducao: "riqueza",
      },
    ],
  },
  {
    kanji: "港",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "みなと", romaji: "minato" }],
    significado: "porto",
    exemplos: [
      {
        kanji: "港町",
        furigana: "みなとまち",
        romaji: "minatomachi",
        traducao: "cidade portuária",
      },
      {
        kanji: "港",
        furigana: "みなと",
        romaji: "minato",
        traducao: "porto",
      },
      {
        kanji: "港湾",
        furigana: "こうわん",
        romaji: "kouwan",
        traducao: "porto marítimo",
      },
    ],
  },
  {
    kanji: "識",
    onYomi: [{ katakana: "シキ", romaji: "shiki" }],
    kunYomi: [{ hiragana: "しる", romaji: "shiru" }],
    significado: "consciência",
    exemplos: [
      {
        kanji: "認識",
        furigana: "にんしき",
        romaji: "ninshiki",
        traducao: "reconhecimento",
      },
      {
        kanji: "意識",
        furigana: "いしき",
        romaji: "ishiki",
        traducao: "consciência",
      },
      {
        kanji: "識る",
        furigana: "しる",
        romaji: "shiru",
        traducao: "conhecer",
      },
    ],
  },
  {
    kanji: "呼",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "よ.ぶ", romaji: "yo.bu" }],
    significado: "chamar",
    exemplos: [
      {
        kanji: "呼吸",
        furigana: "こきゅう",
        romaji: "kokyuu",
        traducao: "respiração",
      },
      {
        kanji: "招待",
        furigana: "しょうたい",
        romaji: "shoutai",
        traducao: "convite",
      },
      {
        kanji: "呼ぶ",
        furigana: "よぶ",
        romaji: "yobu",
        traducao: "chamar",
      },
    ],
  },
  {
    kanji: "達",
    onYomi: [{ katakana: "タツ", romaji: "tatsu" }],
    kunYomi: [],
    significado: "atingir",
    exemplos: [
      {
        kanji: "到達",
        furigana: "とうたつ",
        romaji: "toutatsu",
        traducao: "chegada",
      },
      {
        kanji: "達成",
        furigana: "たっせい",
        romaji: "tassei",
        traducao: "realização",
      },
      {
        kanji: "達する",
        furigana: "たっする",
        romaji: "tassuru",
        traducao: "atingir",
      },
    ],
  },
  {
    kanji: "良",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [{ hiragana: "よ.い", romaji: "yo.i" }],
    significado: "bom",
    exemplos: [
      {
        kanji: "優良",
        furigana: "ゆうりょう",
        romaji: "yuuryou",
        traducao: "excelente",
      },
      {
        kanji: "良好",
        furigana: "りょうこう",
        romaji: "ryoukou",
        traducao: "bom",
      },
      {
        kanji: "良い",
        furigana: "よい",
        romaji: "yoi",
        traducao: "bom",
      },
    ],
  },
  {
    kanji: "阪",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [],
    significado: "colina",
    exemplos: [
      {
        kanji: "大阪",
        furigana: "おおさか",
        romaji: "oosaka",
        traducao: "Osaka",
      },
      {
        kanji: "阪神",
        furigana: "はんしん",
        romaji: "hanshin",
        traducao: "Hanshin",
      },
      {
        kanji: "阪",
        furigana: "さか",
        romaji: "saka",
        traducao: "colina",
      },
    ],
  },
  {
    kanji: "候",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "そうろう", romaji: "sourou" }],
    significado: "condição",
    exemplos: [
      {
        kanji: "天候",
        furigana: "てんこう",
        romaji: "tenkou",
        traducao: "condição do tempo",
      },
      {
        kanji: "候補",
        furigana: "こうほ",
        romaji: "kouho",
        traducao: "candidato",
      },
      {
        kanji: "候",
        furigana: "そうろう",
        romaji: "sourou",
        traducao: "condição",
      },
    ],
  },
  {
    kanji: "程",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "ほど", romaji: "hodo" }],
    significado: "grau",
    exemplos: [
      {
        kanji: "程度",
        furigana: "ていど",
        romaji: "teido",
        traducao: "nível, grau",
      },
      {
        kanji: "限度",
        furigana: "げんど",
        romaji: "gendo",
        traducao: "limite",
      },
      {
        kanji: "程",
        furigana: "ほど",
        romaji: "hodo",
        traducao: "grau",
      },
    ],
  },
  {
    kanji: "満",
    onYomi: [{ katakana: "マン", romaji: "man" }],
    kunYomi: [{ hiragana: "み.ちる", romaji: "mi.chiru" }],
    significado: "cheio",
    exemplos: [
      {
        kanji: "満足",
        furigana: "まんぞく",
        romaji: "manzoku",
        traducao: "satisfação",
      },
      {
        kanji: "満員",
        furigana: "まんいん",
        romaji: "man'in",
        traducao: "lotado",
      },
      {
        kanji: "満たす",
        furigana: "みたす",
        romaji: "mitasu",
        traducao: "preencher",
      },
    ],
  },
  {
    kanji: "敗",
    onYomi: [{ katakana: "ハイ", romaji: "hai" }],
    kunYomi: [{ hiragana: "やぶ.れる", romaji: "yabu.reru" }],
    significado: "derrota",
    exemplos: [
      {
        kanji: "敗北",
        furigana: "はいぼく",
        romaji: "haiboku",
        traducao: "derrota",
      },
      {
        kanji: "失敗",
        furigana: "しっぱい",
        romaji: "shippai",
        traducao: "fracasso",
      },
      {
        kanji: "敗れる",
        furigana: "やぶれる",
        romaji: "yabureru",
        traducao: "ser derrotado",
      },
    ],
  },
  {
    kanji: "値",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [{ hiragana: "ね", romaji: "ne" }],
    significado: "valor",
    exemplos: [
      {
        kanji: "価値",
        furigana: "かち",
        romaji: "kachi",
        traducao: "valor",
      },
      {
        kanji: "値段",
        furigana: "ねだん",
        romaji: "nedan",
        traducao: "preço",
      },
      {
        kanji: "値",
        furigana: "ね",
        romaji: "ne",
        traducao: "valor",
      },
    ],
  },
  {
    kanji: "突",
    onYomi: [{ katakana: "トツ", romaji: "totsu" }],
    kunYomi: [{ hiragana: "つ.く", romaji: "tsu.ku" }],
    significado: "atacar",
    exemplos: [
      {
        kanji: "突然",
        furigana: "とつぜん",
        romaji: "totsuzen",
        traducao: "repentinamente",
      },
      {
        kanji: "突破",
        furigana: "とっぱ",
        romaji: "toppa",
        traducao: "rompimento",
      },
      {
        kanji: "突く",
        furigana: "つく",
        romaji: "tsuku",
        traducao: "atacar",
      },
    ],
  },
  {
    kanji: "光",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "ひかり", romaji: "hikari" }],
    significado: "luz",
    exemplos: [
      {
        kanji: "光線",
        furigana: "こうせん",
        romaji: "kousen",
        traducao: "raio de luz",
      },
      {
        kanji: "光る",
        furigana: "ひかる",
        romaji: "hikaru",
        traducao: "brilhar",
      },
      {
        kanji: "光",
        furigana: "ひかり",
        romaji: "hikari",
        traducao: "luz",
      },
    ],
  },
  {
    kanji: "路",
    onYomi: [{ katakana: "ロ", romaji: "ro" }],
    kunYomi: [{ hiragana: "みち", romaji: "michi" }],
    significado: "caminho",
    exemplos: [
      {
        kanji: "道路",
        furigana: "どうろ",
        romaji: "douro",
        traducao: "estrada",
      },
      {
        kanji: "歩道",
        furigana: "ほどう",
        romaji: "hodou",
        traducao: "calçada",
      },
      {
        kanji: "路",
        furigana: "みち",
        romaji: "michi",
        traducao: "caminho",
      },
    ],
  },
  {
    kanji: "科",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [],
    significado: "ciência",
    exemplos: [
      {
        kanji: "科学",
        furigana: "かがく",
        romaji: "kagaku",
        traducao: "ciência",
      },
      {
        kanji: "生物科",
        furigana: "せいぶつか",
        romaji: "seibutsuka",
        traducao: "biologia",
      },
      {
        kanji: "科",
        furigana: "か",
        romaji: "ka",
        traducao: "ciência",
      },
    ],
  },
  {
    kanji: "積",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "つ.む", romaji: "tsu.mu" }],
    significado: "acumular",
    exemplos: [
      {
        kanji: "経験積み",
        furigana: "けいけんづみ",
        romaji: "keikendzumi",
        traducao: "experiência acumulada",
      },
      {
        kanji: "積極的",
        furigana: "せっきょくてき",
        romaji: "sekkyokuteki",
        traducao: "ativo, positivo",
      },
      {
        kanji: "積む",
        furigana: "つむ",
        romaji: "tsumu",
        traducao: "acumular",
      },
    ],
  },
  {
    kanji: "他",
    onYomi: [{ katakana: "タ", romaji: "ta" }],
    kunYomi: [{ hiragana: "ほか", romaji: "hoka" }],
    significado: "outro",
    exemplos: [
      {
        kanji: "他人",
        furigana: "たにん",
        romaji: "tanin",
        traducao: "outra pessoa",
      },
      {
        kanji: "他国",
        furigana: "たこく",
        romaji: "takoku",
        traducao: "outro país",
      },
      {
        kanji: "他",
        furigana: "ほか",
        romaji: "hoka",
        traducao: "outro",
      },
    ],
  },
  {
    kanji: "処",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [{ hiragana: "ところ", romaji: "tokoro" }],
    significado: "local",
    exemplos: [
      {
        kanji: "場所",
        furigana: "ばしょ",
        romaji: "basho",
        traducao: "lugar",
      },
      {
        kanji: "処理",
        furigana: "しょり",
        romaji: "shori",
        traducao: "processamento",
      },
      {
        kanji: "処",
        furigana: "ところ",
        romaji: "tokoro",
        traducao: "local",
      },
    ],
  },
  {
    kanji: "太",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "ふと.い", romaji: "futo.i" }],
    significado: "grosso",
    exemplos: [
      {
        kanji: "太陽",
        furigana: "たいよう",
        romaji: "taiyou",
        traducao: "sol",
      },
      {
        kanji: "太い",
        furigana: "ふとい",
        romaji: "futoi",
        traducao: "grosso",
      },
      {
        kanji: "太",
        furigana: "たい",
        romaji: "tai",
        traducao: "grosso",
      },
    ],
  },
  {
    kanji: "客",
    onYomi: [{ katakana: "キャク", romaji: "kyaku" }],
    kunYomi: [{ hiragana: "きゃく", romaji: "kyaku" }],
    significado: "convidado",
    exemplos: [
      {
        kanji: "観客",
        furigana: "かんきゃく",
        romaji: "kankyaku",
        traducao: "espectador",
      },
      {
        kanji: "客室",
        furigana: "きゃくしつ",
        romaji: "kyakushitsu",
        traducao: "quarto de hóspedes",
      },
      {
        kanji: "客",
        furigana: "きゃく",
        romaji: "kyaku",
        traducao: "convidado",
      },
    ],
  },
  {
    kanji: "否",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "いな", romaji: "ina" }],
    significado: "não",
    exemplos: [
      {
        kanji: "承否",
        furigana: "しょうひ",
        romaji: "shouhi",
        traducao: "sim ou não",
      },
      {
        kanji: "否定",
        furigana: "ひてい",
        romaji: "hitei",
        traducao: "negação",
      },
      {
        kanji: "否",
        furigana: "いな",
        romaji: "ina",
        traducao: "não",
      },
    ],
  },
  {
    kanji: "師",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "なら.う", romaji: "nara.u" }],
    significado: "professor",
    exemplos: [
      {
        kanji: "教師",
        furigana: "きょうし",
        romaji: "kyoushi",
        traducao: "professor",
      },
      {
        kanji: "師匠",
        furigana: "ししょう",
        romaji: "shishou",
        traducao: "mestre",
      },
      {
        kanji: "師",
        furigana: "ならう",
        romaji: "nara.u",
        traducao: "professor",
      },
    ],
  },
  {
    kanji: "登",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "のぼ.る", romaji: "nobo.ru" }],
    significado: "subir",
    exemplos: [
      {
        kanji: "登山",
        furigana: "とざん",
        romaji: "tozan",
        traducao: "montanhismo",
      },
      {
        kanji: "登校",
        furigana: "とうこう",
        romaji: "toukou",
        traducao: "ir para a escola",
      },
      {
        kanji: "登る",
        furigana: "のぼる",
        romaji: "nobo.ru",
        traducao: "subir",
      },
    ],
  },
  {
    kanji: "易",
    onYomi: [{ katakana: "エキ", romaji: "eki" }],
    kunYomi: [{ hiragana: "やさ.しい", romaji: "yasa.shii" }],
    significado: "fácil",
    exemplos: [
      {
        kanji: "容易",
        furigana: "ようい",
        romaji: "youi",
        traducao: "fácil",
      },
      {
        kanji: "易しい",
        furigana: "やさしい",
        romaji: "yasashii",
        traducao: "fácil",
      },
      {
        kanji: "易",
        furigana: "やさしい",
        romaji: "yasa.shii",
        traducao: "fácil",
      },
    ],
  },
  {
    kanji: "速",
    onYomi: [{ katakana: "ソク", romaji: "soku" }],
    kunYomi: [{ hiragana: "はや.い", romaji: "haya.i" }],
    significado: "rápido",
    exemplos: [
      {
        kanji: "高速",
        furigana: "こうそく",
        romaji: "kousoku",
        traducao: "alta velocidade",
      },
      {
        kanji: "速度",
        furigana: "そくど",
        romaji: "sokudo",
        traducao: "velocidade",
      },
      {
        kanji: "速い",
        furigana: "はやい",
        romaji: "hayai",
        traducao: "rápido",
      },
    ],
  },
  {
    kanji: "存",
    onYomi: [{ katakana: "ソン", romaji: "son" }],
    kunYomi: [{ hiragana: "ざい", romaji: "zai" }],
    significado: "existência",
    exemplos: [
      {
        kanji: "保存",
        furigana: "ほぞん",
        romaji: "hozon",
        traducao: "manter, preservar",
      },
      {
        kanji: "存知",
        furigana: "ぞんじ",
        romaji: "zonji",
        traducao: "conhecimento",
      },
      {
        kanji: "存在",
        furigana: "そんざい",
        romaji: "sonzai",
        traducao: "existência",
      },
    ],
  },
  {
    kanji: "飛",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "と.ぶ", romaji: "to.bu" }],
    significado: "voar",
    exemplos: [
      {
        kanji: "飛行機",
        furigana: "ひこうき",
        romaji: "hikouki",
        traducao: "avião",
      },
      {
        kanji: "飛ぶ",
        furigana: "とぶ",
        romaji: "tobu",
        traducao: "voar",
      },
      {
        kanji: "飛躍",
        furigana: "ひやく",
        romaji: "hiyaku",
        traducao: "salto",
      },
    ],
  },
  {
    kanji: "殺",
    onYomi: [{ katakana: "サツ", romaji: "satsu" }],
    kunYomi: [{ hiragana: "ころ.す", romaji: "koro.su" }],
    significado: "matar",
    exemplos: [
      {
        kanji: "殺人",
        furigana: "さつじん",
        romaji: "satsujin",
        traducao: "assassinato",
      },
      {
        kanji: "殺す",
        furigana: "ころす",
        romaji: "korosu",
        traducao: "matar",
      },
      {
        kanji: "自殺",
        furigana: "じさつ",
        romaji: "jisatsu",
        traducao: "suicídio",
      },
    ],
  },
  {
    kanji: "号",
    onYomi: [{ katakana: "ゴウ", romaji: "gou" }],
    kunYomi: [],
    significado: "número",
    exemplos: [
      {
        kanji: "電話番号",
        furigana: "でんわばんごう",
        romaji: "denwabangou",
        traducao: "número de telefone",
      },
      {
        kanji: "号室",
        furigana: "ごうしつ",
        romaji: "goushitsu",
        traducao: "número do quarto",
      },
      {
        kanji: "号",
        furigana: "ごう",
        romaji: "gou",
        traducao: "número",
      },
    ],
  },
  {
    kanji: "単",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "ひとえ", romaji: "hitoe" }],
    significado: "simples",
    exemplos: [
      {
        kanji: "単語",
        furigana: "たんご",
        romaji: "tango",
        traducao: "palavra",
      },
      {
        kanji: "単純",
        furigana: "たんじゅん",
        romaji: "tanjun",
        traducao: "simples",
      },
      {
        kanji: "単一",
        furigana: "たんいつ",
        romaji: "tan'itsu",
        traducao: "único",
      },
    ],
  },
  {
    kanji: "座",
    onYomi: [{ katakana: "ザ", romaji: "za" }],
    kunYomi: [{ hiragana: "すわ.る", romaji: "suwa.ru" }],
    significado: "sentar",
    exemplos: [
      {
        kanji: "座席",
        furigana: "ざせき",
        romaji: "zaseki",
        traducao: "assento",
      },
      {
        kanji: "座る",
        furigana: "すわる",
        romaji: "suwaru",
        traducao: "sentar",
      },
      {
        kanji: "座",
        furigana: "すわ",
        romaji: "suwa",
        traducao: "sentar",
      },
    ],
  },
  {
    kanji: "破",
    onYomi: [{ katakana: "ハ", romaji: "ha" }],
    kunYomi: [{ hiragana: "やぶ.る", romaji: "yabu.ru" }],
    significado: "quebrar",
    exemplos: [
      {
        kanji: "破壊",
        furigana: "はかい",
        romaji: "hakai",
        traducao: "destruição",
      },
      {
        kanji: "破る",
        furigana: "やぶる",
        romaji: "yaburu",
        traducao: "quebrar",
      },
      {
        kanji: "破片",
        furigana: "はへん",
        romaji: "hahen",
        traducao: "fragmento",
      },
    ],
  },
  {
    kanji: "除",
    onYomi: [{ katakana: "ジョ", romaji: "jo" }],
    kunYomi: [{ hiragana: "のぞ.く", romaji: "nozo.ku" }],
    significado: "remover",
    exemplos: [
      {
        kanji: "除外",
        furigana: "じょがい",
        romaji: "jogai",
        traducao: "exclusão",
      },
      {
        kanji: "排除",
        furigana: "はいじょ",
        romaji: "haijo",
        traducao: "eliminação",
      },
      {
        kanji: "除く",
        furigana: "のぞく",
        romaji: "nozoku",
        traducao: "remover",
      },
    ],
  },
  {
    kanji: "完",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "completo",
    exemplos: [
      {
        kanji: "完全",
        furigana: "かんぜん",
        romaji: "kanzen",
        traducao: "completo",
      },
      {
        kanji: "完了",
        furigana: "かんりょう",
        romaji: "kanryou",
        traducao: "conclusão",
      },
      {
        kanji: "完",
        furigana: "かん",
        romaji: "kan",
        traducao: "completo",
      },
    ],
  },
  {
    kanji: "降",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "お.りる", romaji: "o.riru" }],
    significado: "descer",
    exemplos: [
      {
        kanji: "降雨",
        furigana: "こうう",
        romaji: "kouu",
        traducao: "chuva",
      },
      {
        kanji: "降りる",
        furigana: "おりる",
        romaji: "oriru",
        traducao: "descer",
      },
      {
        kanji: "降下",
        furigana: "こうか",
        romaji: "kouka",
        traducao: "descida",
      },
    ],
  },
  {
    kanji: "責",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "せ.める", romaji: "se.meru" }],
    significado: "responsabilizar",
    exemplos: [
      {
        kanji: "責任",
        furigana: "せきにん",
        romaji: "sekinin",
        traducao: "responsabilidade",
      },
      {
        kanji: "非難",
        furigana: "ひなん",
        romaji: "hinan",
        traducao: "culpar",
      },
      {
        kanji: "責める",
        furigana: "せめる",
        romaji: "semeru",
        traducao: "responsabilizar",
      },
    ],
  },
  {
    kanji: "捕",
    onYomi: [{ katakana: "ホ", romaji: "ho" }],
    kunYomi: [{ hiragana: "と.る", romaji: "to.ru" }],
    significado: "capturar",
    exemplos: [
      {
        kanji: "捕獲",
        furigana: "ほかく",
        romaji: "hokaku",
        traducao: "captura",
      },
      {
        kanji: "捕まえる",
        furigana: "つかまえる",
        romaji: "tsukamaeru",
        traducao: "capturar",
      },
      {
        kanji: "捕る",
        furigana: "とる",
        romaji: "toru",
        traducao: "capturar",
      },
    ],
  },
  {
    kanji: "危",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "あぶ.ない", romaji: "abu.nai" }],
    significado: "perigo",
    exemplos: [
      {
        kanji: "危険",
        furigana: "きけん",
        romaji: "kiken",
        traducao: "perigo",
      },
      {
        kanji: "危ない",
        furigana: "あぶない",
        romaji: "abunai",
        traducao: "perigoso",
      },
      {
        kanji: "危機",
        furigana: "きき",
        romaji: "kiki",
        traducao: "crise",
      },
    ],
  },
  {
    kanji: "給",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "たま.う", romaji: "tama.u" }],
    significado: "fornecer",
    exemplos: [
      {
        kanji: "給料",
        furigana: "きゅうりょう",
        romaji: "kyuuryou",
        traducao: "salário",
      },
      {
        kanji: "給食",
        furigana: "きゅうしょく",
        romaji: "kyuushoku",
        traducao: "refeição escolar",
      },
      {
        kanji: "給う",
        furigana: "たまう",
        romaji: "tama.u",
        traducao: "fornecer",
      },
    ],
  },
  {
    kanji: "苦",
    onYomi: [{ katakana: "ク", romaji: "ku" }],
    kunYomi: [{ hiragana: "くる.しい", romaji: "kuru.shii" }],
    significado: "sofrimento",
    exemplos: [
      {
        kanji: "苦労",
        furigana: "くろう",
        romaji: "kurou",
        traducao: "dificuldade",
      },
      {
        kanji: "苦しい",
        furigana: "くるしい",
        romaji: "kurushii",
        traducao: "difícil",
      },
      {
        kanji: "苦手",
        furigana: "にがて",
        romaji: "nigate",
        traducao: "inabilidade",
      },
    ],
  },
  {
    kanji: "迎",
    onYomi: [{ katakana: "ゲイ", romaji: "gei" }],
    kunYomi: [{ hiragana: "むか.える", romaji: "muka.eru" }],
    significado: "receber",
    exemplos: [
      {
        kanji: "歓迎",
        furigana: "かんげい",
        romaji: "kangei",
        traducao: "boas-vindas",
      },
      {
        kanji: "迎える",
        furigana: "むかえる",
        romaji: "mukaeru",
        traducao: "receber",
      },
      {
        kanji: "迎",
        furigana: "むかえ",
        romaji: "mukae",
        traducao: "receber",
      },
    ],
  },
  {
    kanji: "園",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [],
    significado: "jardim",
    exemplos: [
      {
        kanji: "公園",
        furigana: "こうえん",
        romaji: "kouen",
        traducao: "parque",
      },
      {
        kanji: "動物園",
        furigana: "どうぶつえん",
        romaji: "doubutsuen",
        traducao: "zoológico",
      },
      {
        kanji: "園",
        furigana: "その",
        romaji: "sono",
        traducao: "jardim",
      },
    ],
  },
  {
    kanji: "具",
    onYomi: [{ katakana: "グ", romaji: "gu" }],
    kunYomi: [],
    significado: "ferramenta",
    exemplos: [
      {
        kanji: "具体的",
        furigana: "ぐたいてき",
        romaji: "gutaiteki",
        traducao: "específico",
      },
      {
        kanji: "具備",
        furigana: "ぐび",
        romaji: "gubi",
        traducao: "equipado",
      },
      {
        kanji: "具",
        furigana: "ぐ",
        romaji: "gu",
        traducao: "ferramenta",
      },
    ],
  },
  {
    kanji: "辞",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [{ hiragana: "や.める", romaji: "ya.meru" }],
    significado: "despedir",
    exemplos: [
      {
        kanji: "辞書",
        furigana: "じしょ",
        romaji: "jisho",
        traducao: "dicionário",
      },
      {
        kanji: "辞退",
        furigana: "じたい",
        romaji: "jitai",
        traducao: "recusar",
      },
      {
        kanji: "辞める",
        furigana: "やめる",
        romaji: "yameru",
        traducao: "despedir",
      },
    ],
  },
  {
    kanji: "因",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [{ hiragana: "よ.る", romaji: "yo.ru" }],
    significado: "causa",
    exemplos: [
      {
        kanji: "原因",
        furigana: "げんいん",
        romaji: "gen'in",
        traducao: "causa",
      },
      {
        kanji: "因果関係",
        furigana: "いんがかんけい",
        romaji: "ingakankei",
        traducao: "relação de causa e efeito",
      },
      {
        kanji: "因る",
        furigana: "よる",
        romaji: "yoru",
        traducao: "causar",
      },
    ],
  },
  {
    kanji: "馬",
    onYomi: [{ katakana: "バ", romaji: "ba" }],
    kunYomi: [{ hiragana: "うま", romaji: "uma" }],
    significado: "cavalo",
    exemplos: [
      {
        kanji: "馬鹿",
        furigana: "ばか",
        romaji: "baka",
        traducao: "idiota",
      },
      {
        kanji: "競馬",
        furigana: "けいば",
        romaji: "keiba",
        traducao: "corrida de cavalos",
      },
      {
        kanji: "馬",
        furigana: "うま",
        romaji: "uma",
        traducao: "cavalo",
      },
    ],
  },
  {
    kanji: "愛",
    onYomi: [{ katakana: "アイ", romaji: "ai" }],
    kunYomi: [{ hiragana: "いと.しい", romaji: "ito.shii" }],
    significado: "amor",
    exemplos: [
      {
        kanji: "愛情",
        furigana: "あいじょう",
        romaji: "aijou",
        traducao: "afeto",
      },
      {
        kanji: "愛する",
        furigana: "あいする",
        romaji: "aisuru",
        traducao: "amar",
      },
      {
        kanji: "愛",
        furigana: "いとしい",
        romaji: "ito.shii",
        traducao: "amor",
      },
    ],
  },
  {
    kanji: "富",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "と.む", romaji: "to.mu" }],
    significado: "riqueza",
    exemplos: [
      {
        kanji: "富裕",
        furigana: "ふゆう",
        romaji: "fuyuu",
        traducao: "riqueza",
      },
      {
        kanji: "富む",
        furigana: "とむ",
        romaji: "tomu",
        traducao: "ser rico",
      },
      {
        kanji: "富",
        furigana: "とむ",
        romaji: "to.mu",
        traducao: "riqueza",
      },
    ],
  },
  {
    kanji: "彼",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "かれ", romaji: "kare" }],
    significado: "ele",
    exemplos: [
      {
        kanji: "彼女",
        furigana: "かのじょ",
        romaji: "kanojo",
        traducao: "ela",
      },
      {
        kanji: "彼氏",
        furigana: "かれし",
        romaji: "kareshi",
        traducao: "namorado",
      },
      {
        kanji: "彼",
        furigana: "かれ",
        romaji: "kare",
        traducao: "ele",
      },
    ],
  },
  {
    kanji: "未",
    onYomi: [{ katakana: "ミ", romaji: "mi" }],
    kunYomi: [],
    significado: "não",
    exemplos: [
      {
        kanji: "未来",
        furigana: "みらい",
        romaji: "mirai",
        traducao: "futuro",
      },
      {
        kanji: "未知",
        furigana: "みち",
        romaji: "michi",
        traducao: "desconhecido",
      },
      {
        kanji: "未",
        furigana: "み",
        romaji: "mi",
        traducao: "não",
      },
    ],
  },
  {
    kanji: "舞",
    onYomi: [{ katakana: "ブ", romaji: "bu" }],
    kunYomi: [{ hiragana: "ま.う", romaji: "ma.u" }],
    significado: "dançar",
    exemplos: [
      {
        kanji: "舞台",
        furigana: "ぶたい",
        romaji: "butai",
        traducao: "palco",
      },
      {
        kanji: "舞う",
        furigana: "まう",
        romaji: "mau",
        traducao: "dançar",
      },
      {
        kanji: "舞踏",
        furigana: "ぶとう",
        romaji: "butou",
        traducao: "balé",
      },
    ],
  },
  {
    kanji: "亡",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "な.い", romaji: "na.i" }],
    significado: "morte",
    exemplos: [
      {
        kanji: "亡くなる",
        furigana: "なくなる",
        romaji: "nakunaru",
        traducao: "falecer",
      },
      {
        kanji: "亡者",
        furigana: "もうじゃ",
        romaji: "mouja",
        traducao: "falecido",
      },
      {
        kanji: "亡",
        furigana: "な",
        romaji: "na",
        traducao: "morte",
      },
    ],
  },
  {
    kanji: "冷",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [{ hiragana: "つめ.たい", romaji: "tsume.tai" }],
    significado: "frio",
    exemplos: [
      {
        kanji: "冷蔵庫",
        furigana: "れいぞうこ",
        romaji: "reizouko",
        traducao: "geladeira",
      },
      {
        kanji: "冷たい",
        furigana: "つめたい",
        romaji: "tsumetai",
        traducao: "frio",
      },
      {
        kanji: "冷",
        furigana: "つめ",
        romaji: "tsume",
        traducao: "frio",
      },
    ],
  },
  {
    kanji: "適",
    onYomi: [{ katakana: "テキ", romaji: "teki" }],
    kunYomi: [],
    significado: "adequado",
    exemplos: [
      {
        kanji: "適切",
        furigana: "てきせつ",
        romaji: "tekisetsu",
        traducao: "adequado",
      },
      {
        kanji: "適度",
        furigana: "てきど",
        romaji: "tekido",
        traducao: "moderado",
      },
      {
        kanji: "適",
        furigana: "てき",
        romaji: "teki",
        traducao: "adequado",
      },
    ],
  },
  {
    kanji: "婦",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [],
    significado: "mulher",
    exemplos: [
      {
        kanji: "主婦",
        furigana: "しゅふ",
        romaji: "shufu",
        traducao: "dona de casa",
      },
      {
        kanji: "婦人",
        furigana: "ふじん",
        romaji: "fujin",
        traducao: "senhora",
      },
      {
        kanji: "婦",
        furigana: "ふ",
        romaji: "fu",
        traducao: "mulher",
      },
    ],
  },
  {
    kanji: "寄",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "よ.る", romaji: "yo.ru" }],
    significado: "aproximar-se",
    exemplos: [
      {
        kanji: "寄付",
        furigana: "きふ",
        romaji: "kihu",
        traducao: "doação",
      },
      {
        kanji: "寄る",
        furigana: "よる",
        romaji: "yoru",
        traducao: "aproximar-se",
      },
      {
        kanji: "寄",
        furigana: "よ",
        romaji: "yo",
        traducao: "aproximar-se",
      },
    ],
  },
  {
    kanji: "込",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "こ.む", romaji: "ko.mu" }],
    significado: "cheio",
    exemplos: [
      {
        kanji: "混雑",
        furigana: "こんざつ",
        romaji: "konzatsu",
        traducao: "lotação",
      },
      {
        kanji: "込む",
        furigana: "こむ",
        romaji: "komu",
        traducao: "ficar cheio",
      },
      {
        kanji: "込",
        furigana: "こめ",
        romaji: "kome",
        traducao: "cheio",
      },
    ],
  },
  {
    kanji: "顔",
    onYomi: [{ katakana: "ガン", romaji: "gan" }],
    kunYomi: [{ hiragana: "かお", romaji: "kao" }],
    significado: "rosto",
    exemplos: [
      {
        kanji: "顔色",
        furigana: "かおいろ",
        romaji: "kaoiro",
        traducao: "cor da pele",
      },
      {
        kanji: "顔",
        furigana: "かお",
        romaji: "kao",
        traducao: "rosto",
      },
      {
        kanji: "顔面",
        furigana: "がんめん",
        romaji: "ganmen",
        traducao: "face",
      },
    ],
  },
  {
    kanji: "類",
    onYomi: [{ katakana: "ルイ", romaji: "rui" }],
    kunYomi: [],
    significado: "tipo",
    exemplos: [
      {
        kanji: "種類",
        furigana: "しゅるい",
        romaji: "shurui",
        traducao: "tipo",
      },
      {
        kanji: "類似",
        furigana: "るいじ",
        romaji: "ruiji",
        traducao: "similaridade",
      },
      {
        kanji: "類",
        furigana: "るい",
        romaji: "rui",
        traducao: "tipo",
      },
    ],
  },
  {
    kanji: "余",
    onYomi: [{ katakana: "ヨ", romaji: "yo" }],
    kunYomi: [{ hiragana: "あま.る", romaji: "ama.ru" }],
    significado: "excedente",
    exemplos: [
      {
        kanji: "余裕",
        furigana: "よゆう",
        romaji: "yoyuu",
        traducao: "folga",
      },
      {
        kanji: "余る",
        furigana: "あまる",
        romaji: "amaru",
        traducao: "sobrar",
      },
      {
        kanji: "余",
        furigana: "よ",
        romaji: "yo",
        traducao: "excedente",
      },
    ],
  },
  {
    kanji: "王",
    onYomi: [{ katakana: "オウ", romaji: "ou" }],
    kunYomi: [],
    significado: "rei",
    exemplos: [
      {
        kanji: "王国",
        furigana: "おうこく",
        romaji: "oukoku",
        traducao: "reino",
      },
      {
        kanji: "女王",
        furigana: "じょおう",
        romaji: "joou",
        traducao: "rainha",
      },
      {
        kanji: "王",
        furigana: "おう",
        romaji: "ou",
        traducao: "rei",
      },
    ],
  },
  {
    kanji: "返",
    onYomi: [{ katakana: "ヘン", romaji: "hen" }],
    kunYomi: [{ hiragana: "かえ.す", romaji: "kae.su" }],
    significado: "retornar",
    exemplos: [
      {
        kanji: "返事",
        furigana: "へんじ",
        romaji: "henji",
        traducao: "resposta",
      },
      {
        kanji: "返す",
        furigana: "かえす",
        romaji: "kaesu",
        traducao: "retornar",
      },
      {
        kanji: "返",
        furigana: "かえ",
        romaji: "kae",
        traducao: "retornar",
      },
    ],
  },
  {
    kanji: "妻",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "つま", romaji: "tsuma" }],
    significado: "esposa",
    exemplos: [
      {
        kanji: "妻子",
        furigana: "さいし",
        romaji: "saishi",
        traducao: "esposa e filhos",
      },
      {
        kanji: "夫妻",
        furigana: "ふさい",
        romaji: "fusai",
        traducao: "casal",
      },
      {
        kanji: "妻",
        furigana: "つま",
        romaji: "tsuma",
        traducao: "esposa",
      },
    ],
  },
  {
    kanji: "背",
    onYomi: [{ katakana: "ハイ", romaji: "hai" }],
    kunYomi: [{ hiragana: "せ", romaji: "se" }],
    significado: "costas",
    exemplos: [
      {
        kanji: "背中",
        furigana: "せなか",
        romaji: "senaka",
        traducao: "costas",
      },
      {
        kanji: "背高",
        furigana: "せだか",
        romaji: "sedaka",
        traducao: "alto",
      },
      {
        kanji: "背",
        furigana: "せ",
        romaji: "se",
        traducao: "costas",
      },
    ],
  },
  {
    kanji: "熱",
    onYomi: [{ katakana: "ネツ", romaji: "netsu" }],
    kunYomi: [{ hiragana: "あつ.い", romaji: "atsu.i" }],
    significado: "febre",
    exemplos: [
      {
        kanji: "熱心",
        furigana: "ねっしん",
        romaji: "nesshin",
        traducao: "entusiasmado",
      },
      {
        kanji: "熱",
        furigana: "ねつ",
        romaji: "netsu",
        traducao: "febre",
      },
      {
        kanji: "熱い",
        furigana: "あつい",
        romaji: "atsui",
        traducao: "quente",
      },
    ],
  },
  {
    kanji: "宿",
    onYomi: [{ katakana: "シュク", romaji: "shuku" }],
    kunYomi: [{ hiragana: "やど", romaji: "yado" }],
    significado: "alojamento",
    exemplos: [
      {
        kanji: "宿泊",
        furigana: "しゅくはく",
        romaji: "shukuhaku",
        traducao: "hospedagem",
      },
      {
        kanji: "宿",
        furigana: "やど",
        romaji: "yado",
        traducao: "alojamento",
      },
      {
        kanji: "宿題",
        furigana: "しゅくだい",
        romaji: "shukudai",
        traducao: "lição de casa",
      },
    ],
  },
  {
    kanji: "薬",
    onYomi: [{ katakana: "ヤク", romaji: "yaku" }],
    kunYomi: [{ hiragana: "くすり", romaji: "kusuri" }],
    significado: "remédio",
    exemplos: [
      {
        kanji: "薬局",
        furigana: "やっきょく",
        romaji: "yakkyoku",
        traducao: "farmácia",
      },
      {
        kanji: "薬",
        furigana: "くすり",
        romaji: "kusuri",
        traducao: "remédio",
      },
      {
        kanji: "薬指",
        furigana: "くすりゆび",
        romaji: "kusuriyubi",
        traducao: "dedo anelar",
      },
    ],
  },
  {
    kanji: "険",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "けわ.しい", romaji: "kewa.shii" }],
    significado: "perigoso",
    exemplos: [
      {
        kanji: "険しい",
        furigana: "けわしい",
        romaji: "kewashii",
        traducao: "perigoso",
      },
      {
        kanji: "危険",
        furigana: "きけん",
        romaji: "kiken",
        traducao: "perigoso",
      },
      {
        kanji: "険",
        furigana: "けわ.しい",
        romaji: "kewa.shii",
        traducao: "perigoso",
      },
    ],
  },
  {
    kanji: "頼",
    onYomi: [{ katakana: "ライ", romaji: "rai" }],
    kunYomi: [{ hiragana: "たの.む", romaji: "tano.mu" }],
    significado: "confiar",
    exemplos: [
      {
        kanji: "頼む",
        furigana: "たのむ",
        romaji: "tanomu",
        traducao: "confiar",
      },
      {
        kanji: "依頼",
        furigana: "いらい",
        romaji: "irai",
        traducao: "pedido",
      },
      {
        kanji: "頼り",
        furigana: "たより",
        romaji: "tayori",
        traducao: "confiável",
      },
    ],
  },
  {
    kanji: "覚",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "おぼ.える", romaji: "obo.eru" }],
    significado: "lembrar",
    exemplos: [
      {
        kanji: "覚える",
        furigana: "おぼえる",
        romaji: "oboeru",
        traducao: "lembrar",
      },
      {
        kanji: "記憶",
        furigana: "きおく",
        romaji: "kioku",
        traducao: "memória",
      },
      {
        kanji: "覚え",
        furigana: "おぼえ",
        romaji: "oboe",
        traducao: "lembrança",
      },
    ],
  },
  {
    kanji: "船",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "ふね", romaji: "fune" }],
    significado: "barco",
    exemplos: [
      {
        kanji: "船",
        furigana: "ふね",
        romaji: "fune",
        traducao: "barco",
      },
      {
        kanji: "汽船",
        furigana: "きせん",
        romaji: "kisen",
        traducao: "barco a vapor",
      },
      {
        kanji: "船員",
        furigana: "せんいん",
        romaji: "sen'in",
        traducao: "tripulante",
      },
    ],
  },
  {
    kanji: "途",
    onYomi: [{ katakana: "ト", romaji: "to" }],
    kunYomi: [],
    significado: "caminho",
    exemplos: [
      {
        kanji: "途中",
        furigana: "とちゅう",
        romaji: "tochuu",
        traducao: "no caminho",
      },
      {
        kanji: "道路",
        furigana: "どうろ",
        romaji: "douro",
        traducao: "estrada",
      },
      {
        kanji: "途",
        furigana: "と",
        romaji: "to",
        traducao: "caminho",
      },
    ],
  },
  {
    kanji: "許",
    onYomi: [{ katakana: "キョ", romaji: "kyo" }],
    kunYomi: [{ hiragana: "ゆる.す", romaji: "yuru.su" }],
    significado: "permitir",
    exemplos: [
      {
        kanji: "許可",
        furigana: "きょか",
        romaji: "kyoka",
        traducao: "permissão",
      },
      {
        kanji: "許す",
        furigana: "ゆるす",
        romaji: "yurusu",
        traducao: "permitir",
      },
      {
        kanji: "許",
        furigana: "きょ",
        romaji: "kyo",
        traducao: "permitir",
      },
    ],
  },
  {
    kanji: "抜",
    onYomi: [{ katakana: "バツ", romaji: "batsu" }],
    kunYomi: [{ hiragana: "ぬ.く", romaji: "nu.ku" }],
    significado: "retirar",
    exemplos: [
      {
        kanji: "抜く",
        furigana: "ぬく",
        romaji: "nuku",
        traducao: "retirar",
      },
      {
        kanji: "抜ける",
        furigana: "ぬける",
        romaji: "nukeru",
        traducao: "escapar",
      },
      {
        kanji: "抜",
        furigana: "ぬ",
        romaji: "nu",
        traducao: "retirar",
      },
    ],
  },
  {
    kanji: "便",
    onYomi: [{ katakana: "ベン", romaji: "ben" }],
    kunYomi: [{ hiragana: "たよ.り", romaji: "tayo.ri" }],
    significado: "conveniente",
    exemplos: [
      {
        kanji: "便利",
        furigana: "べんり",
        romaji: "benri",
        traducao: "conveniente",
      },
      {
        kanji: "便",
        furigana: "たより",
        romaji: "tayori",
        traducao: "informação",
      },
      {
        kanji: "便",
        furigana: "たよ.り",
        romaji: "tayo.ri",
        traducao: "conveniente",
      },
    ],
  },
  {
    kanji: "留",
    onYomi: [{ katakana: "リュウ", romaji: "ryuu" }],
    kunYomi: [{ hiragana: "と.める", romaji: "to.meru" }],
    significado: "parar",
    exemplos: [
      {
        kanji: "留学",
        furigana: "りゅうがく",
        romaji: "ryuugaku",
        traducao: "estudar no exterior",
      },
      {
        kanji: "留める",
        furigana: "とめる",
        romaji: "tomeru",
        traducao: "parar",
      },
      {
        kanji: "留",
        furigana: "と",
        romaji: "to",
        traducao: "parar",
      },
    ],
  },
  {
    kanji: "罪",
    onYomi: [{ katakana: "ザイ", romaji: "zai" }],
    kunYomi: [{ hiragana: "つみ", romaji: "tsumi" }],
    significado: "crime",
    exemplos: [
      {
        kanji: "罪悪感",
        furigana: "ざいあくかん",
        romaji: "zaiakukan",
        traducao: "sentimento de culpa",
      },
      {
        kanji: "犯罪",
        furigana: "はんざい",
        romaji: "hanzai",
        traducao: "crime",
      },
      {
        kanji: "罪",
        furigana: "つみ",
        romaji: "tsumi",
        traducao: "crime",
      },
    ],
  },
  {
    kanji: "努",
    onYomi: [{ katakana: "ド", romaji: "do" }],
    kunYomi: [{ hiragana: "つと.める", romaji: "tsuto.meru" }],
    significado: "esforço",
    exemplos: [
      {
        kanji: "努力",
        furigana: "どりょく",
        romaji: "doryoku",
        traducao: "esforço",
      },
      {
        kanji: "努める",
        furigana: "つとめる",
        romaji: "tsutomeru",
        traducao: "esforçar-se",
      },
      {
        kanji: "努",
        furigana: "つと.める",
        romaji: "tsuto.meru",
        traducao: "esforço",
      },
    ],
  },
  {
    kanji: "精",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "よ.い", romaji: "yo.i" }],
    significado: "espírito",
    exemplos: [
      {
        kanji: "精神",
        furigana: "せいしん",
        romaji: "seishin",
        traducao: "mente",
      },
      {
        kanji: "精",
        furigana: "よ",
        romaji: "yo",
        traducao: "espírito",
      },
      {
        kanji: "精度",
        furigana: "せいど",
        romaji: "seido",
        traducao: "precisão",
      },
    ],
  },
  {
    kanji: "散",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [{ hiragana: "ち.る", romaji: "chi.ru" }],
    significado: "espalhar",
    exemplos: [
      {
        kanji: "散歩",
        furigana: "さんぽ",
        romaji: "sanpo",
        traducao: "caminhada",
      },
      {
        kanji: "散らかる",
        furigana: "ちらかる",
        romaji: "chirakaru",
        traducao: "ficar bagunçado",
      },
      {
        kanji: "散",
        furigana: "ち",
        romaji: "chi",
        traducao: "espalhar",
      },
    ],
  },
  {
    kanji: "静",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "しず.か", romaji: "shizu.ka" }],
    significado: "quieto",
    exemplos: [
      {
        kanji: "静か",
        furigana: "しずか",
        romaji: "shizuka",
        traducao: "quieto",
      },
      {
        kanji: "静止",
        furigana: "せいし",
        romaji: "seishi",
        traducao: "parar",
      },
      {
        kanji: "静",
        furigana: "しず.か",
        romaji: "shizu.ka",
        traducao: "quieto",
      },
    ],
  },
  {
    kanji: "婚",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [],
    significado: "casamento",
    exemplos: [
      {
        kanji: "結婚",
        furigana: "けっこん",
        romaji: "kekkon",
        traducao: "casamento",
      },
      {
        kanji: "婚約",
        furigana: "こんやく",
        romaji: "kon'yaku",
        traducao: "noivado",
      },
      {
        kanji: "婚",
        furigana: "こん",
        romaji: "kon",
        traducao: "casamento",
      },
    ],
  },
  {
    kanji: "喜",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "よろこ.ぶ", romaji: "yoroko.bu" }],
    significado: "alegrar-se",
    exemplos: [
      {
        kanji: "喜ぶ",
        furigana: "よろこぶ",
        romaji: "yorokobu",
        traducao: "alegrar-se",
      },
      {
        kanji: "喜び",
        furigana: "よろこび",
        romaji: "yorokobi",
        traducao: "alegria",
      },
      {
        kanji: "喜",
        furigana: "よろこ.ぶ",
        romaji: "yoroko.bu",
        traducao: "alegrar-se",
      },
    ],
  },
  {
    kanji: "浮",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "う.く", romaji: "u.ku" }],
    significado: "flutuar",
    exemplos: [
      {
        kanji: "浮く",
        furigana: "うく",
        romaji: "uku",
        traducao: "flutuar",
      },
      {
        kanji: "浮気",
        furigana: "うわき",
        romaji: "uwaki",
        traducao: "infidelidade",
      },
      {
        kanji: "浮",
        furigana: "う.く",
        romaji: "u.ku",
        traducao: "flutuar",
      },
    ],
  },
  {
    kanji: "絶",
    onYomi: [{ katakana: "ゼツ", romaji: "zetsu" }],
    kunYomi: [{ hiragana: "た.える", romaji: "ta.eru" }],
    significado: "interromper",
    exemplos: [
      {
        kanji: "絶対",
        furigana: "ぜったい",
        romaji: "zettai",
        traducao: "absoluto",
      },
      {
        kanji: "絶える",
        furigana: "たえる",
        romaji: "taeru",
        traducao: "interromper",
      },
      {
        kanji: "絶",
        furigana: "た.える",
        romaji: "ta.eru",
        traducao: "interromper",
      },
    ],
  },
  {
    kanji: "幸",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "しあわ.せ", romaji: "shiawa.se" }],
    significado: "felicidade",
    exemplos: [
      {
        kanji: "幸せ",
        furigana: "しあわせ",
        romaji: "shiawase",
        traducao: "felicidade",
      },
      {
        kanji: "幸運",
        furigana: "こううん",
        romaji: "kouun",
        traducao: "sorte",
      },
      {
        kanji: "幸",
        furigana: "しあわ.せ",
        romaji: "shiawa.se",
        traducao: "felicidade",
      },
    ],
  },
  {
    kanji: "押",
    onYomi: [{ katakana: "オウ", romaji: "ou" }],
    kunYomi: [{ hiragana: "お.す", romaji: "o.su" }],
    significado: "empurrar",
    exemplos: [
      {
        kanji: "押す",
        furigana: "おす",
        romaji: "osu",
        traducao: "empurrar",
      },
      {
        kanji: "押し入れ",
        furigana: "おしいれ",
        romaji: "oshiire",
        traducao: "guarda-roupa embutido",
      },
      {
        kanji: "押",
        furigana: "お.す",
        romaji: "o.su",
        traducao: "empurrar",
      },
    ],
  },
  {
    kanji: "倒",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "たお.れる", romaji: "tao.reru" }],
    significado: "derrubar",
    exemplos: [
      {
        kanji: "倒れる",
        furigana: "たおれる",
        romaji: "taoreru",
        traducao: "cair",
      },
      {
        kanji: "倒産",
        furigana: "とうさん",
        romaji: "tousan",
        traducao: "falência",
      },
      {
        kanji: "倒",
        furigana: "たお.れる",
        romaji: "tao.reru",
        traducao: "derrubar",
      },
    ],
  },
  {
    kanji: "等",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "ひと.しい", romaji: "hito.shii" }],
    significado: "igual",
    exemplos: [
      {
        kanji: "等しい",
        furigana: "ひとしい",
        romaji: "hitoshii",
        traducao: "igual",
      },
      {
        kanji: "等級",
        furigana: "とうきゅう",
        romaji: "toukyuu",
        traducao: "classe",
      },
      {
        kanji: "等",
        furigana: "ひと.しい",
        romaji: "hito.shii",
        traducao: "igual",
      },
    ],
  },
  {
    kanji: "老",
    onYomi: [{ katakana: "ロウ", romaji: "rou" }],
    kunYomi: [{ hiragana: "お.いる", romaji: "o.iru" }],
    significado: "velho",
    exemplos: [
      {
        kanji: "老人",
        furigana: "ろうじん",
        romaji: "roujin",
        traducao: "idoso",
      },
      {
        kanji: "老化",
        furigana: "ろうか",
        romaji: "rouka",
        traducao: "envelhecimento",
      },
      {
        kanji: "老",
        furigana: "お.いる",
        romaji: "o.iru",
        traducao: "velho",
      },
    ],
  },
  {
    kanji: "曲",
    onYomi: [{ katakana: "キョク", romaji: "kyoku" }],
    kunYomi: [{ hiragana: "ま.がる", romaji: "ma.garu" }],
    significado: "curvar",
    exemplos: [
      {
        kanji: "曲がる",
        furigana: "まがる",
        romaji: "magaru",
        traducao: "curvar",
      },
      {
        kanji: "曲線",
        furigana: "きょくせん",
        romaji: "kyokusen",
        traducao: "curva",
      },
      {
        kanji: "曲",
        furigana: "ま.がる",
        romaji: "ma.garu",
        traducao: "curvar",
      },
    ],
  },
  {
    kanji: "払",
    onYomi: [{ katakana: "フツ", romaji: "futsu" }],
    kunYomi: [{ hiragana: "はら.う", romaji: "hara.u" }],
    significado: "pagar",
    exemplos: [
      {
        kanji: "払う",
        furigana: "はらう",
        romaji: "harau",
        traducao: "pagar",
      },
      {
        kanji: "払い",
        furigana: "はらい",
        romaji: "harai",
        traducao: "pagamento",
      },
      {
        kanji: "払",
        furigana: "はら.う",
        romaji: "hara.u",
        traducao: "pagar",
      },
    ],
  },
  {
    kanji: "庭",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "にわ", romaji: "niwa" }],
    significado: "jardim",
    exemplos: [
      {
        kanji: "庭",
        furigana: "にわ",
        romaji: "niwa",
        traducao: "jardim",
      },
      {
        kanji: "庭園",
        furigana: "ていえん",
        romaji: "teien",
        traducao: "jardim",
      },
      {
        kanji: "庭",
        furigana: "にわ",
        romaji: "niwa",
        traducao: "jardim",
      },
    ],
  },
  {
    kanji: "徒",
    onYomi: [{ katakana: "ト", romaji: "to" }],
    kunYomi: [{ hiragana: "いたずら", romaji: "itazura" }],
    significado: "a pé",
    exemplos: [
      {
        kanji: "徒歩",
        furigana: "とほ",
        romaji: "toho",
        traducao: "a pé",
      },
      {
        kanji: "徒労",
        furigana: "とろう",
        romaji: "torou",
        traducao: "trabalho em vão",
      },
      {
        kanji: "徒",
        furigana: "いたずら",
        romaji: "itazura",
        traducao: "a pé",
      },
    ],
  },
  {
    kanji: "勤",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [{ hiragana: "つと.める", romaji: "tsuto.meru" }],
    significado: "trabalhar",
    exemplos: [
      {
        kanji: "勤務",
        furigana: "きんむ",
        romaji: "kinmu",
        traducao: "serviço",
      },
      {
        kanji: "勤める",
        furigana: "つとめる",
        romaji: "tsutomeru",
        traducao: "trabalhar",
      },
      {
        kanji: "勤",
        furigana: "つと.める",
        romaji: "tsuto.meru",
        traducao: "trabalhar",
      },
    ],
  },
  {
    kanji: "遅",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [{ hiragana: "おく.れる", romaji: "oku.reru" }],
    significado: "atraso",
    exemplos: [
      {
        kanji: "遅れる",
        furigana: "おくれる",
        romaji: "okureru",
        traducao: "atrasar",
      },
      {
        kanji: "遅刻",
        furigana: "ちこく",
        romaji: "chikoku",
        traducao: "atraso",
      },
      {
        kanji: "遅",
        furigana: "おく.れる",
        romaji: "oku.reru",
        traducao: "atraso",
      },
    ],
  },
  {
    kanji: "居",
    onYomi: [{ katakana: "キョ", romaji: "kyo" }],
    kunYomi: [{ hiragana: "い.る", romaji: "i.ru" }],
    significado: "estar",
    exemplos: [
      {
        kanji: "居る",
        furigana: "いる",
        romaji: "iru",
        traducao: "estar",
      },
      {
        kanji: "居酒屋",
        furigana: "いざかや",
        romaji: "izakaya",
        traducao: "bar",
      },
      {
        kanji: "居",
        furigana: "い.る",
        romaji: "i.ru",
        traducao: "estar",
      },
    ],
  },
  {
    kanji: "雑",
    onYomi: [{ katakana: "ザツ", romaji: "zatsu" }],
    kunYomi: [{ hiragana: "まじ.る", romaji: "maji.ru" }],
    significado: "variado",
    exemplos: [
      {
        kanji: "雑誌",
        furigana: "ざっし",
        romaji: "zasshi",
        traducao: "revista",
      },
      {
        kanji: "雑音",
        furigana: "ざつおん",
        romaji: "zatsuon",
        traducao: "ruído",
      },
      {
        kanji: "雑",
        furigana: "まじ.る",
        romaji: "maji.ru",
        traducao: "variado",
      },
    ],
  },
  {
    kanji: "招",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "まね.く", romaji: "mane.ku" }],
    significado: "convidar",
    exemplos: [
      {
        kanji: "招く",
        furigana: "まねく",
        romaji: "maneku",
        traducao: "convidar",
      },
      {
        kanji: "招待",
        furigana: "しょうたい",
        romaji: "shoutai",
        traducao: "convite",
      },
      {
        kanji: "招",
        furigana: "まね.く",
        romaji: "mane.ku",
        traducao: "convidar",
      },
    ],
  },
  {
    kanji: "困",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [{ hiragana: "こま.る", romaji: "koma.ru" }],
    significado: "estar em apuros",
    exemplos: [
      {
        kanji: "困る",
        furigana: "こまる",
        romaji: "komaru",
        traducao: "estar em apuros",
      },
      {
        kanji: "困難",
        furigana: "こんなん",
        romaji: "konnan",
        traducao: "dificuldade",
      },
      {
        kanji: "困",
        furigana: "こま.る",
        romaji: "koma.ru",
        traducao: "estar em apuros",
      },
    ],
  },
  {
    kanji: "欠",
    onYomi: [{ katakana: "ケツ", romaji: "ketsu" }],
    kunYomi: [
      { hiragana: "か.ける", romaji: "ka.keru" },
      { hiragana: "か.く", romaji: "ka.ku" },
    ],
    significado: "falta",
    exemplos: [
      {
        kanji: "欠席",
        furigana: "けっせき",
        romaji: "kessseki",
        traducao: "ausência",
      },
      {
        kanji: "不足",
        furigana: "ふそく",
        romaji: "fusoku",
        traducao: "insuficiente",
      },
      {
        kanji: "欠",
        furigana: "か.ける",
        romaji: "ka.keru",
        traducao: "falta",
      },
    ],
  },
  {
    kanji: "更",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [
      { hiragana: "さら.に", romaji: "sara.ni" },
      { hiragana: "ふ.ける", romaji: "fu.keru" },
    ],
    significado: "mais",
    exemplos: [
      {
        kanji: "更に",
        furigana: "さらに",
        romaji: "sarani",
        traducao: "além disso",
      },
      {
        kanji: "更新",
        furigana: "こうしん",
        romaji: "koushin",
        traducao: "renovar",
      },
      {
        kanji: "更",
        furigana: "さら.に",
        romaji: "sara.ni",
        traducao: "mais",
      },
    ],
  },
  {
    kanji: "刻",
    onYomi: [{ katakana: "コク", romaji: "koku" }],
    kunYomi: [{ hiragana: "きざ.む", romaji: "kiza.mu" }],
    significado: "gravar",
    exemplos: [
      {
        kanji: "刻む",
        furigana: "きざむ",
        romaji: "kizamu",
        traducao: "gravar",
      },
      {
        kanji: "時刻",
        furigana: "じこく",
        romaji: "jikoku",
        traducao: "horário",
      },
      {
        kanji: "刻",
        furigana: "きざ.む",
        romaji: "kiza.mu",
        traducao: "gravar",
      },
    ],
  },
  {
    kanji: "賛",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [],
    significado: "aprovar",
    exemplos: [
      {
        kanji: "賛成",
        furigana: "さんせい",
        romaji: "sansei",
        traducao: "aprovação",
      },
      {
        kanji: "賛否",
        furigana: "さんぴ",
        romaji: "sanpi",
        traducao: "aprovação ou reprovação",
      },
      {
        kanji: "賛",
        furigana: "さん",
        romaji: "san",
        traducao: "aprovar",
      },
    ],
  },
  {
    kanji: "抱",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "だ.く", romaji: "da.ku" }],
    significado: "abraçar",
    exemplos: [
      {
        kanji: "抱く",
        furigana: "だく",
        romaji: "daku",
        traducao: "abraçar",
      },
      {
        kanji: "抱負",
        furigana: "ほうふ",
        romaji: "houfu",
        traducao: "aspiração",
      },
      {
        kanji: "抱",
        furigana: "だ.く",
        romaji: "da.ku",
        traducao: "abraçar",
      },
    ],
  },
  {
    kanji: "犯",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "おか.す", romaji: "oka.su" }],
    significado: "cometer",
    exemplos: [
      {
        kanji: "犯罪",
        furigana: "はんざい",
        romaji: "hanzai",
        traducao: "crime",
      },
      {
        kanji: "犯人",
        furigana: "はんにん",
        romaji: "hannin",
        traducao: "criminoso",
      },
      {
        kanji: "犯",
        furigana: "おか.す",
        romaji: "oka.su",
        traducao: "cometer",
      },
    ],
  },
  {
    kanji: "恐",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "おそ.れる", romaji: "oso.reru" }],
    significado: "temer",
    exemplos: [
      {
        kanji: "恐怖",
        furigana: "きょうふ",
        romaji: "kyoufu",
        traducao: "medo",
      },
      {
        kanji: "恐れる",
        furigana: "おそれる",
        romaji: "osoreru",
        traducao: "temer",
      },
      {
        kanji: "恐",
        furigana: "おそ.れる",
        romaji: "oso.reru",
        traducao: "temer",
      },
    ],
  },
  {
    kanji: "息",
    onYomi: [{ katakana: "ソク", romaji: "soku" }],
    kunYomi: [{ hiragana: "いき", romaji: "iki" }],
    significado: "respiração",
    exemplos: [
      {
        kanji: "息",
        furigana: "いき",
        romaji: "iki",
        traducao: "respiração",
      },
      {
        kanji: "息子",
        furigana: "むすこ",
        romaji: "musuko",
        traducao: "filho",
      },
      {
        kanji: "息",
        furigana: "いき",
        romaji: "iki",
        traducao: "respiração",
      },
    ],
  },
  {
    kanji: "遠",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "とお.い", romaji: "too.i" }],
    significado: "distante",
    exemplos: [
      {
        kanji: "遠い",
        furigana: "とおい",
        romaji: "tooi",
        traducao: "distante",
      },
      {
        kanji: "遠足",
        furigana: "えんそく",
        romaji: "ensoku",
        traducao: "excursão",
      },
      {
        kanji: "遠",
        furigana: "とお.い",
        romaji: "too.i",
        traducao: "distante",
      },
    ],
  },
  {
    kanji: "戻",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [{ hiragana: "もど.す", romaji: "modo.su" }],
    significado: "voltar",
    exemplos: [
      {
        kanji: "戻る",
        furigana: "もどる",
        romaji: "modoru",
        traducao: "voltar",
      },
      {
        kanji: "戻す",
        furigana: "もどす",
        romaji: "modosu",
        traducao: "devolver",
      },
      {
        kanji: "戻",
        furigana: "もど.す",
        romaji: "modo.su",
        traducao: "voltar",
      },
    ],
  },
  {
    kanji: "願",
    onYomi: [{ katakana: "ガン", romaji: "gan" }],
    kunYomi: [{ hiragana: "ねが.う", romaji: "nega.u" }],
    significado: "desejar",
    exemplos: [
      {
        kanji: "願い",
        furigana: "ねがい",
        romaji: "negai",
        traducao: "desejo",
      },
      {
        kanji: "願望",
        furigana: "がんぼう",
        romaji: "ganbou",
        traducao: "aspiração",
      },
      {
        kanji: "願",
        furigana: "ねが.う",
        romaji: "nega.u",
        traducao: "desejar",
      },
    ],
  },
  {
    kanji: "絵",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [],
    significado: "pintura",
    exemplos: [
      {
        kanji: "絵",
        furigana: "え",
        romaji: "e",
        traducao: "pintura",
      },
      {
        kanji: "絵画",
        furigana: "かいが",
        romaji: "kaiga",
        traducao: "pintura",
      },
      {
        kanji: "絵",
        furigana: "え",
        romaji: "e",
        traducao: "pintura",
      },
    ],
  },
  {
    kanji: "越",
    onYomi: [{ katakana: "エツ", romaji: "etsu" }],
    kunYomi: [{ hiragana: "こ.す", romaji: "ko.su" }],
    significado: "ultrapassar",
    exemplos: [
      {
        kanji: "越える",
        furigana: "こえる",
        romaji: "koeru",
        traducao: "ultrapassar",
      },
      {
        kanji: "越境",
        furigana: "えっきょう",
        romaji: "ekkyou",
        traducao: "transfronteiriço",
      },
      {
        kanji: "越",
        furigana: "こ.す",
        romaji: "ko.su",
        traducao: "ultrapassar",
      },
    ],
  },
  {
    kanji: "欲",
    onYomi: [{ katakana: "ヨク", romaji: "yoku" }],
    kunYomi: [{ hiragana: "ほっ.する", romaji: "ho.suru" }],
    significado: "desejo",
    exemplos: [
      {
        kanji: "欲望",
        furigana: "よくぼう",
        romaji: "yokubou",
        traducao: "desejo",
      },
      {
        kanji: "欲しい",
        furigana: "ほしい",
        romaji: "hoshii",
        traducao: "querer",
      },
      {
        kanji: "欲",
        furigana: "ほっ.する",
        romaji: "ho.suru",
        traducao: "desejo",
      },
    ],
  },
  {
    kanji: "痛",
    onYomi: [{ katakana: "ツウ", romaji: "tsuu" }],
    kunYomi: [{ hiragana: "いた.い", romaji: "ita.i" }],
    significado: "dor",
    exemplos: [
      {
        kanji: "痛み",
        furigana: "いたみ",
        romaji: "itami",
        traducao: "dor",
      },
      {
        kanji: "痛い",
        furigana: "いたい",
        romaji: "itai",
        traducao: "dolorido",
      },
      {
        kanji: "痛",
        furigana: "いた.い",
        romaji: "ita.i",
        traducao: "dor",
      },
    ],
  },
  {
    kanji: "笑",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "わら.う", romaji: "wara.u" }],
    significado: "rir",
    exemplos: [
      {
        kanji: "笑顔",
        furigana: "えがお",
        romaji: "egao",
        traducao: "sorriso",
      },
      {
        kanji: "笑う",
        furigana: "わらう",
        romaji: "warau",
        traducao: "rir",
      },
      {
        kanji: "笑",
        furigana: "わら.う",
        romaji: "wara.u",
        traducao: "rir",
      },
    ],
  },
  {
    kanji: "互",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [{ hiragana: "たが.い", romaji: "taga.i" }],
    significado: "mutuamente",
    exemplos: [
      {
        kanji: "互い",
        furigana: "たがい",
        romaji: "tagai",
        traducao: "mutuamente",
      },
      {
        kanji: "相互",
        furigana: "そうご",
        romaji: "sougo",
        traducao: "recíproco",
      },
      {
        kanji: "互",
        furigana: "たが.い",
        romaji: "taga.i",
        traducao: "mutuamente",
      },
    ],
  },
  {
    kanji: "束",
    onYomi: [{ katakana: "ソク", romaji: "soku" }],
    kunYomi: [{ hiragana: "たば", romaji: "taba" }],
    significado: "feixe",
    exemplos: [
      {
        kanji: "束",
        furigana: "たば",
        romaji: "taba",
        traducao: "feixe",
      },
      {
        kanji: "束縛",
        furigana: "そくばく",
        romaji: "sokubaku",
        traducao: "restrição",
      },
      {
        kanji: "束",
        furigana: "たば",
        romaji: "taba",
        traducao: "feixe",
      },
    ],
  },
  {
    kanji: "似",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [{ hiragana: "に.る", romaji: "ni.ru" }],
    significado: "semelhante",
    exemplos: [
      {
        kanji: "似る",
        furigana: "にる",
        romaji: "niru",
        traducao: "ser semelhante",
      },
      {
        kanji: "類似",
        furigana: "るいじ",
        romaji: "ruiji",
        traducao: "semelhante",
      },
      {
        kanji: "似",
        furigana: "に.る",
        romaji: "ni.ru",
        traducao: "semelhante",
      },
    ],
  },
  {
    kanji: "列",
    onYomi: [{ katakana: "レツ", romaji: "retsu" }],
    kunYomi: [{ hiragana: "れつ", romaji: "retsu" }],
    significado: "fila",
    exemplos: [
      {
        kanji: "列車",
        furigana: "れっしゃ",
        romaji: "ressha",
        traducao: "trem",
      },
      {
        kanji: "行列",
        furigana: "ぎょうれつ",
        romaji: "gyouretsu",
        traducao: "fila",
      },
      {
        kanji: "列",
        furigana: "れつ",
        romaji: "retsu",
        traducao: "fila",
      },
    ],
  },
  {
    kanji: "探",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "さぐ.る", romaji: "sagu.ru" }],
    significado: "procurar",
    exemplos: [
      {
        kanji: "探す",
        furigana: "さがす",
        romaji: "sagasu",
        traducao: "procurar",
      },
      {
        kanji: "探偵",
        furigana: "たんてい",
        romaji: "tantei",
        traducao: "detetive",
      },
      {
        kanji: "探",
        furigana: "さぐ.る",
        romaji: "sagu.ru",
        traducao: "procurar",
      },
    ],
  },
  {
    kanji: "逃",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "に.げる", romaji: "ni.geru" }],
    significado: "fugir",
    exemplos: [
      {
        kanji: "逃げる",
        furigana: "にげる",
        romaji: "nigeru",
        traducao: "fugir",
      },
      {
        kanji: "逃亡",
        furigana: "とうぼう",
        romaji: "toubou",
        traducao: "fuga",
      },
      {
        kanji: "逃",
        furigana: "に.げる",
        romaji: "ni.geru",
        traducao: "fugir",
      },
    ],
  },
  {
    kanji: "遊",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [{ hiragana: "あそ.ぶ", romaji: "aso.bu" }],
    significado: "brincar",
    exemplos: [
      {
        kanji: "遊ぶ",
        furigana: "あそぶ",
        romaji: "asobu",
        traducao: "brincar",
      },
      {
        kanji: "遊園地",
        furigana: "ゆうえんち",
        romaji: "yuuenchi",
        traducao: "parque de diversões",
      },
      {
        kanji: "遊",
        furigana: "あそ.ぶ",
        romaji: "aso.bu",
        traducao: "brincar",
      },
    ],
  },
  {
    kanji: "迷",
    onYomi: [{ katakana: "メイ", romaji: "mei" }],
    kunYomi: [{ hiragana: "まよ.う", romaji: "mayo.u" }],
    significado: "se perder",
    exemplos: [
      {
        kanji: "迷う",
        furigana: "まよう",
        romaji: "mayou",
        traducao: "se perder",
      },
      {
        kanji: "迷子",
        furigana: "まいご",
        romaji: "maigo",
        traducao: "criança perdida",
      },
      {
        kanji: "迷",
        furigana: "まよ.う",
        romaji: "mayo.u",
        traducao: "se perder",
      },
    ],
  },
  {
    kanji: "夢",
    onYomi: [{ katakana: "ム", romaji: "mu" }],
    kunYomi: [{ hiragana: "ゆめ", romaji: "yume" }],
    significado: "sonho",
    exemplos: [
      {
        kanji: "夢",
        furigana: "ゆめ",
        romaji: "yume",
        traducao: "sonho",
      },
      {
        kanji: "夢中",
        furigana: "むちゅう",
        romaji: "muchuu",
        traducao: "obcecado",
      },
      {
        kanji: "夢",
        furigana: "ゆめ",
        romaji: "yume",
        traducao: "sonho",
      },
    ],
  },
  {
    kanji: "君",
    onYomi: [{ katakana: "クン", romaji: "kun" }],
    kunYomi: [{ hiragana: "きみ", romaji: "kimi" }],
    significado: "você",
    exemplos: [
      {
        kanji: "君",
        furigana: "きみ",
        romaji: "kimi",
        traducao: "você",
      },
      {
        kanji: "先輩",
        furigana: "せんぱい",
        romaji: "senpai",
        traducao: "veterano",
      },
      {
        kanji: "君",
        furigana: "きみ",
        romaji: "kimi",
        traducao: "você",
      },
    ],
  },
  {
    kanji: "閉",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [{ hiragana: "し.める", romaji: "shi.meru" }],
    significado: "fechar",
    exemplos: [
      {
        kanji: "閉める",
        furigana: "しめる",
        romaji: "shimeru",
        traducao: "fechar",
      },
      {
        kanji: "閉鎖",
        furigana: "へいさ",
        romaji: "heisa",
        traducao: "fechamento",
      },
      {
        kanji: "閉",
        furigana: "し.める",
        romaji: "shi.meru",
        traducao: "fechar",
      },
    ],
  },
  {
    kanji: "緒",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [{ hiragana: "お", romaji: "o" }],
    significado: "início",
    exemplos: [
      {
        kanji: "緒",
        furigana: "お",
        romaji: "o",
        traducao: "início",
      },
      {
        kanji: "心の緒",
        furigana: "こころのお",
        romaji: "kokoro no o",
        traducao: "tranquilidade de espírito",
      },
      {
        kanji: "緒",
        furigana: "お",
        romaji: "o",
        traducao: "início",
      },
    ],
  },
  {
    kanji: "折",
    onYomi: [{ katakana: "セツ", romaji: "setsu" }],
    kunYomi: [{ hiragana: "お.る", romaji: "o.ru" }],
    significado: "dobrar",
    exemplos: [
      {
        kanji: "折る",
        furigana: "おる",
        romaji: "oru",
        traducao: "dobrar",
      },
      {
        kanji: "折り紙",
        furigana: "おりがみ",
        romaji: "origami",
        traducao: "origami",
      },
      {
        kanji: "折",
        furigana: "お.る",
        romaji: "o.ru",
        traducao: "dobrar",
      },
    ],
  },
  {
    kanji: "草",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "くさ", romaji: "kusa" }],
    significado: "grama",
    exemplos: [
      {
        kanji: "草",
        furigana: "くさ",
        romaji: "kusa",
        traducao: "grama",
      },
      {
        kanji: "草原",
        furigana: "くさはら",
        romaji: "kusahara",
        traducao: "pradaria",
      },
      {
        kanji: "草",
        furigana: "くさ",
        romaji: "kusa",
        traducao: "grama",
      },
    ],
  },
  {
    kanji: "暮",
    onYomi: [{ katakana: "ボ", romaji: "bo" }],
    kunYomi: [{ hiragana: "く.れる", romaji: "ku.reru" }],
    significado: "anoitecer",
    exemplos: [
      {
        kanji: "暮れる",
        furigana: "くれる",
        romaji: "kureru",
        traducao: "anoitecer",
      },
      {
        kanji: "暮らす",
        furigana: "くらす",
        romaji: "kurasu",
        traducao: "viver",
      },
      {
        kanji: "暮",
        furigana: "く.れる",
        romaji: "ku.reru",
        traducao: "anoitecer",
      },
    ],
  },
  {
    kanji: "酒",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [{ hiragana: "さけ", romaji: "sake" }],
    significado: "bebida alcoólica",
    exemplos: [
      {
        kanji: "日本酒",
        furigana: "にほんしゅ",
        romaji: "nihonshu",
        traducao: "saquê",
      },
      {
        kanji: "酒場",
        furigana: "さかば",
        romaji: "sakaba",
        traducao: "bar",
      },
      {
        kanji: "酒",
        furigana: "さけ",
        romaji: "sake",
        traducao: "bebida alcoólica",
      },
    ],
  },
  {
    kanji: "悲",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "かな.しい", romaji: "kana.shii" }],
    significado: "triste",
    exemplos: [
      {
        kanji: "悲しい",
        furigana: "かなしい",
        romaji: "kanashii",
        traducao: "triste",
      },
      {
        kanji: "悲劇",
        furigana: "ひげき",
        romaji: "higeki",
        traducao: "tragédia",
      },
      {
        kanji: "悲",
        furigana: "かな.しい",
        romaji: "kana.shii",
        traducao: "triste",
      },
    ],
  },
  {
    kanji: "晴",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "は.れる", romaji: "ha.reru" }],
    significado: "claro",
    exemplos: [
      {
        kanji: "晴れる",
        furigana: "はれる",
        romaji: "hareru",
        traducao: "ficar claro",
      },
      {
        kanji: "晴天",
        furigana: "せいてん",
        romaji: "seiten",
        traducao: "céu claro",
      },
      {
        kanji: "晴",
        furigana: "は.れる",
        romaji: "ha.reru",
        traducao: "claro",
      },
    ],
  },
  {
    kanji: "掛",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [
      { hiragana: "か.ける", romaji: "ka.keru" },
      { hiragana: "か.かる", romaji: "ka.karu" },
    ],
    significado: "pendurar",
    exemplos: [
      {
        kanji: "掛ける",
        furigana: "かける",
        romaji: "kakeru",
        traducao: "pendurar",
      },
      {
        kanji: "掛け布団",
        furigana: "かけぶとん",
        romaji: "kakebuton",
        traducao: "edredom",
      },
      {
        kanji: "掛",
        furigana: "か.ける",
        romaji: "ka.keru",
        traducao: "pendurar",
      },
    ],
  },
  {
    kanji: "到",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "いた.る", romaji: "ita.ru" }],
    significado: "chegar",
    exemplos: [
      {
        kanji: "到着",
        furigana: "とうちゃく",
        romaji: "touchaku",
        traducao: "chegada",
      },
      {
        kanji: "到達",
        furigana: "とうたつ",
        romaji: "toutatsu",
        traducao: "atingir",
      },
      {
        kanji: "到",
        furigana: "いた.る",
        romaji: "ita.ru",
        traducao: "chegar",
      },
    ],
  },
  {
    kanji: "寝",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "ね.る", romaji: "ne.ru" }],
    significado: "dormir",
    exemplos: [
      {
        kanji: "寝る",
        furigana: "ねる",
        romaji: "neru",
        traducao: "dormir",
      },
      {
        kanji: "寝室",
        furigana: "しんしつ",
        romaji: "shinshitsu",
        traducao: "quarto",
      },
      {
        kanji: "寝",
        furigana: "ね.る",
        romaji: "ne.ru",
        traducao: "dormir",
      },
    ],
  },
  {
    kanji: "暗",
    onYomi: [{ katakana: "アン", romaji: "an" }],
    kunYomi: [{ hiragana: "くら.い", romaji: "kura.i" }],
    significado: "escuro",
    exemplos: [
      {
        kanji: "暗い",
        furigana: "くらい",
        romaji: "kurai",
        traducao: "escuro",
      },
      {
        kanji: "暗記",
        furigana: "あんき",
        romaji: "anki",
        traducao: "memorização",
      },
      {
        kanji: "暗",
        furigana: "くら.い",
        romaji: "kura.i",
        traducao: "escuro",
      },
    ],
  },
  {
    kanji: "盗",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "ぬす.む", romaji: "nusu.mu" }],
    significado: "roubar",
    exemplos: [
      {
        kanji: "盗む",
        furigana: "ぬすむ",
        romaji: "nusumu",
        traducao: "roubar",
      },
      {
        kanji: "盗難",
        furigana: "とうなん",
        romaji: "tounan",
        traducao: "roubo",
      },
      {
        kanji: "盗",
        furigana: "ぬす.む",
        romaji: "nusu.mu",
        traducao: "roubar",
      },
    ],
  },
  {
    kanji: "吸",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "す.う", romaji: "su.u" }],
    significado: "sugar",
    exemplos: [
      {
        kanji: "吸う",
        furigana: "すう",
        romaji: "suu",
        traducao: "sugar",
      },
      {
        kanji: "吸血鬼",
        furigana: "きゅうけつき",
        romaji: "kyuuketsuki",
        traducao: "vampiro",
      },
      {
        kanji: "吸",
        furigana: "す.う",
        romaji: "su.u",
        traducao: "sugar",
      },
    ],
  },
  {
    kanji: "陽",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "ひ", romaji: "hi" }],
    significado: "sol",
    exemplos: [
      {
        kanji: "陽",
        furigana: "ひ",
        romaji: "hi",
        traducao: "sol",
      },
      {
        kanji: "陽気",
        furigana: "ようき",
        romaji: "youki",
        traducao: "alegre",
      },
      {
        kanji: "陽",
        furigana: "ひ",
        romaji: "hi",
        traducao: "sol",
      },
    ],
  },
  {
    kanji: "御",
    onYomi: [
      { katakana: "ギョ", romaji: "gyo" },
      { katakana: "ゴ", romaji: "go" },
    ],
    kunYomi: [
      { hiragana: "おん-", romaji: "on-" },
      { hiragana: "お-", romaji: "o-" },
    ],
    significado: "honroso",
    exemplos: [
      {
        kanji: "御飯",
        furigana: "ごはん",
        romaji: "gohan",
        traducao: "arroz cozido",
      },
      {
        kanji: "御客様",
        furigana: "おきゃくさま",
        romaji: "okyakusama",
        traducao: "convidado(a)",
      },
      {
        kanji: "御",
        furigana: "ご",
        romaji: "go",
        traducao: "honroso",
      },
    ],
  },
  {
    kanji: "歯",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "は", romaji: "ha" }],
    significado: "dente",
    exemplos: [
      {
        kanji: "歯",
        furigana: "は",
        romaji: "ha",
        traducao: "dente",
      },
      {
        kanji: "歯医者",
        furigana: "はいしゃ",
        romaji: "haisha",
        traducao: "dentista",
      },
      {
        kanji: "歯",
        furigana: "は",
        romaji: "ha",
        traducao: "dente",
      },
    ],
  },
  {
    kanji: "忘",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "わす.れる", romaji: "wasu.reru" }],
    significado: "esquecer",
    exemplos: [
      {
        kanji: "忘れる",
        furigana: "わすれる",
        romaji: "wasureru",
        traducao: "esquecer",
      },
      {
        kanji: "忘年会",
        furigana: "ぼうねんかい",
        romaji: "bounenkai",
        traducao: "festa de fim de ano",
      },
      {
        kanji: "忘",
        furigana: "わす.れる",
        romaji: "wasu.reru",
        traducao: "esquecer",
      },
    ],
  },
  {
    kanji: "雪",
    onYomi: [{ katakana: "セツ", romaji: "setsu" }],
    kunYomi: [{ hiragana: "ゆき", romaji: "yuki" }],
    significado: "neve",
    exemplos: [
      {
        kanji: "雪",
        furigana: "ゆき",
        romaji: "yuki",
        traducao: "neve",
      },
      {
        kanji: "雪国",
        furigana: "ゆきぐに",
        romaji: "yukiguni",
        traducao: "país das neves",
      },
      {
        kanji: "雪",
        furigana: "ゆき",
        romaji: "yuki",
        traducao: "neve",
      },
    ],
  },
  {
    kanji: "吹",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "ふ.く", romaji: "fu.ku" }],
    significado: "soprar",
    exemplos: [
      {
        kanji: "吹く",
        furigana: "ふく",
        romaji: "fuku",
        traducao: "soprar",
      },
      {
        kanji: "吹雪",
        furigana: "ふぶき",
        romaji: "fubuki",
        traducao: "nevasca",
      },
      {
        kanji: "吹",
        furigana: "ふ.く",
        romaji: "fu.ku",
        traducao: "soprar",
      },
    ],
  },
  {
    kanji: "娘",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "むすめ", romaji: "musume" }],
    significado: "filha",
    exemplos: [
      {
        kanji: "娘",
        furigana: "むすめ",
        romaji: "musume",
        traducao: "filha",
      },
      {
        kanji: "長女",
        furigana: "ちょうじょ",
        romaji: "choujo",
        traducao: "filha mais velha",
      },
      {
        kanji: "娘",
        furigana: "むすめ",
        romaji: "musume",
        traducao: "filha",
      },
    ],
  },
  {
    kanji: "誤",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [{ hiragana: "あやま.る", romaji: "ayama.ru" }],
    significado: "erro",
    exemplos: [
      {
        kanji: "誤解",
        furigana: "ごかい",
        romaji: "gokai",
        traducao: "mal-entendido",
      },
      {
        kanji: "誤報",
        furigana: "ごほう",
        romaji: "gohou",
        traducao: "informação errada",
      },
      {
        kanji: "誤",
        furigana: "あやま.る",
        romaji: "ayama.ru",
        traducao: "erro",
      },
    ],
  },
  {
    kanji: "洗",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "あら.う", romaji: "ara.u" }],
    significado: "lavar",
    exemplos: [
      {
        kanji: "洗濯",
        furigana: "せんたく",
        romaji: "sentaku",
        traducao: "lavar roupa",
      },
      {
        kanji: "洗面",
        furigana: "せんめん",
        romaji: "senmen",
        traducao: "lavar o rosto",
      },
      {
        kanji: "洗",
        furigana: "あら.う",
        romaji: "ara.u",
        traducao: "lavar",
      },
    ],
  },
  {
    kanji: "慣",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "な.れる", romaji: "na.reru" }],
    significado: "acostumar-se",
    exemplos: [
      {
        kanji: "慣れる",
        furigana: "なれる",
        romaji: "nareru",
        traducao: "acostumar-se",
      },
      {
        kanji: "習慣",
        furigana: "しゅうかん",
        romaji: "shuukan",
        traducao: "hábito",
      },
      {
        kanji: "慣",
        furigana: "な.れる",
        romaji: "na.reru",
        traducao: "acostumar-se",
      },
    ],
  },
  {
    kanji: "礼",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [
      { hiragana: "あや", romaji: "aya" },
      { hiragana: "いただ.く", romaji: "itada.ku" },
    ],
    significado: "gratidão",
    exemplos: [
      {
        kanji: "お礼",
        furigana: "おれい",
        romaji: "orei",
        traducao: "agradecimento",
      },
      {
        kanji: "礼儀",
        furigana: "れいぎ",
        romaji: "reigi",
        traducao: "etiqueta",
      },
      {
        kanji: "礼",
        furigana: "あや",
        romaji: "aya",
        traducao: "gratidão",
      },
    ],
  },
  {
    kanji: "窓",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "まど", romaji: "mado" }],
    significado: "janela",
    exemplos: [
      {
        kanji: "窓",
        furigana: "まど",
        romaji: "mado",
        traducao: "janela",
      },
      {
        kanji: "窓口",
        furigana: "まどぐち",
        romaji: "madoguchi",
        traducao: "guichê",
      },
      {
        kanji: "窓",
        furigana: "まど",
        romaji: "mado",
        traducao: "janela",
      },
    ],
  },
  {
    kanji: "昔",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "むかし", romaji: "mukashi" }],
    significado: "passado",
    exemplos: [
      {
        kanji: "昔話",
        furigana: "むかしばなし",
        romaji: "mukashi banashi",
        traducao: "conto de fadas",
      },
      {
        kanji: "昔",
        furigana: "むかし",
        romaji: "mukashi",
        traducao: "passado",
      },
    ],
  },
  {
    kanji: "貧",
    onYomi: [{ katakana: "ヒン", romaji: "hin" }],
    kunYomi: [{ hiragana: "まず.しい", romaji: "mazu.shii" }],
    significado: "pobre",
    exemplos: [
      {
        kanji: "貧乏",
        furigana: "びんぼう",
        romaji: "binbou",
        traducao: "pobreza",
      },
      {
        kanji: "貧困",
        furigana: "ひんこん",
        romaji: "hinkon",
        traducao: "pobreza",
      },
      {
        kanji: "貧",
        furigana: "まず.しい",
        romaji: "mazu.shii",
        traducao: "pobre",
      },
    ],
  },
  {
    kanji: "怒",
    onYomi: [{ katakana: "ド", romaji: "do" }],
    kunYomi: [{ hiragana: "いか.る", romaji: "ika.ru" }],
    significado: "raiva",
    exemplos: [
      {
        kanji: "怒り",
        furigana: "いかり",
        romaji: "ikari",
        traducao: "raiva",
      },
      {
        kanji: "怒る",
        furigana: "いかる",
        romaji: "ikaru",
        traducao: "ficar com raiva",
      },
      {
        kanji: "怒",
        furigana: "いか.る",
        romaji: "ika.ru",
        traducao: "raiva",
      },
    ],
  },
  {
    kanji: "泳",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [{ hiragana: "およ.ぐ", romaji: "oyo.gu" }],
    significado: "nadar",
    exemplos: [
      {
        kanji: "泳ぐ",
        furigana: "およぐ",
        romaji: "oyogu",
        traducao: "nadar",
      },
      {
        kanji: "水泳",
        furigana: "すいえい",
        romaji: "suiei",
        traducao: "natação",
      },
      {
        kanji: "泳",
        furigana: "およ.ぐ",
        romaji: "oyo.gu",
        traducao: "nadar",
      },
    ],
  },
  {
    kanji: "祖",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [{ hiragana: "もと", romaji: "moto" }],
    significado: "ancestral",
    exemplos: [
      {
        kanji: "祖先",
        furigana: "そせん",
        romaji: "sosen",
        traducao: "ancestral",
      },
      {
        kanji: "祖母",
        furigana: "そぼ",
        romaji: "sobo",
        traducao: "avó",
      },
      {
        kanji: "祖",
        furigana: "もと",
        romaji: "moto",
        traducao: "ancestral",
      },
    ],
  },
  {
    kanji: "杯",
    onYomi: [{ katakana: "ハイ", romaji: "hai" }],
    kunYomi: [{ hiragana: "さかずき", romaji: "sakazuki" }],
    significado: "taça",
    exemplos: [
      {
        kanji: "一杯",
        furigana: "いっぱい",
        romaji: "ippai",
        traducao: "cheio",
      },
      {
        kanji: "乾杯",
        furigana: "かんぱい",
        romaji: "kanpai",
        traducao: "brinde",
      },
      {
        kanji: "杯",
        furigana: "さかずき",
        romaji: "sakazuki",
        traducao: "taça",
      },
    ],
  },
  {
    kanji: "疲",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "つか.れる", romaji: "tsuka.reru" }],
    significado: "cansado",
    exemplos: [
      {
        kanji: "疲れる",
        furigana: "つかれる",
        romaji: "tsukareru",
        traducao: "cansar",
      },
      {
        kanji: "疲労",
        furigana: "ひろう",
        romaji: "hirou",
        traducao: "fadiga",
      },
      {
        kanji: "疲",
        furigana: "つか.れる",
        romaji: "tsuka.reru",
        traducao: "cansado",
      },
    ],
  },
  {
    kanji: "皆",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "みな", romaji: "mina" }],
    significado: "todos",
    exemplos: [
      {
        kanji: "皆さん",
        furigana: "みなさん",
        romaji: "minasan",
        traducao: "todos",
      },
      {
        kanji: "皆無",
        furigana: "かいむ",
        romaji: "kaimu",
        traducao: "nada",
      },
      {
        kanji: "皆",
        furigana: "みな",
        romaji: "mina",
        traducao: "todos",
      },
    ],
  },
  {
    kanji: "鳴",
    onYomi: [{ katakana: "メイ", romaji: "mei" }],
    kunYomi: [{ hiragana: "な.く", romaji: "na.ku" }],
    significado: "chirrar",
    exemplos: [
      {
        kanji: "鳴る",
        furigana: "なる",
        romaji: "naru",
        traducao: "fazer barulho",
      },
      {
        kanji: "鳴動",
        furigana: "めいどう",
        romaji: "meidou",
        traducao: "barulho",
      },
      {
        kanji: "鳴",
        furigana: "な.く",
        romaji: "na.ku",
        traducao: "chirrar",
      },
    ],
  },
  {
    kanji: "腹",
    onYomi: [{ katakana: "フク", romaji: "fuku" }],
    kunYomi: [{ hiragana: "はら", romaji: "hara" }],
    significado: "abdômen",
    exemplos: [
      {
        kanji: "腹",
        furigana: "はら",
        romaji: "hara",
        traducao: "abdômen",
      },
      {
        kanji: "腹痛",
        furigana: "ふくつう",
        romaji: "fukutsuu",
        traducao: "dor de estômago",
      },
      {
        kanji: "腹",
        furigana: "はら",
        romaji: "hara",
        traducao: "abdômen",
      },
    ],
  },
  {
    kanji: "煙",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "けむ.る", romaji: "kemu.ru" }],
    significado: "fumaça",
    exemplos: [
      {
        kanji: "煙",
        furigana: "けむり",
        romaji: "kemuri",
        traducao: "fumaça",
      },
      {
        kanji: "煙草",
        furigana: "たばこ",
        romaji: "tabako",
        traducao: "cigarro",
      },
      {
        kanji: "煙",
        furigana: "けむ.る",
        romaji: "kemu.ru",
        traducao: "fumaça",
      },
    ],
  },
  {
    kanji: "眠",
    onYomi: [{ katakana: "ミン", romaji: "min" }],
    kunYomi: [{ hiragana: "ねむ.る", romaji: "ne.mu.ru" }],
    significado: "sono",
    exemplos: [
      {
        kanji: "眠る",
        furigana: "ねむる",
        romaji: "nemuru",
        traducao: "dormir",
      },
      {
        kanji: "眠気",
        furigana: "ねむけ",
        romaji: "nemuke",
        traducao: "sonolência",
      },
      {
        kanji: "眠",
        furigana: "ねむ.る",
        romaji: "ne.mu.ru",
        traducao: "sono",
      },
    ],
  },
  {
    kanji: "怖",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "こわ.い", romaji: "kowa.i" }],
    significado: "medo",
    exemplos: [
      {
        kanji: "怖い",
        furigana: "こわい",
        romaji: "kowai",
        traducao: "assustador",
      },
      {
        kanji: "恐怖",
        furigana: "きょうふ",
        romaji: "kyoufu",
        traducao: "terror",
      },
      {
        kanji: "怖",
        furigana: "こわ.い",
        romaji: "kowa.i",
        traducao: "medo",
      },
    ],
  },
  {
    kanji: "耳",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [{ hiragana: "みみ", romaji: "mimi" }],
    significado: "orelha",
    exemplos: [
      {
        kanji: "耳",
        furigana: "みみ",
        romaji: "mimi",
        traducao: "orelha",
      },
      {
        kanji: "耳鳴り",
        furigana: "みみなり",
        romaji: "miminari",
        traducao: "zumbido no ouvido",
      },
      {
        kanji: "耳",
        furigana: "みみ",
        romaji: "mimi",
        traducao: "orelha",
      },
    ],
  },
  {
    kanji: "頂",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "いただ.く", romaji: "itada.ku" }],
    significado: "topo",
    exemplos: [
      {
        kanji: "頂く",
        furigana: "いただく",
        romaji: "itadaku",
        traducao: "receber",
      },
      {
        kanji: "山頂",
        furigana: "さんちょう",
        romaji: "sanchou",
        traducao: "pico da montanha",
      },
      {
        kanji: "頂",
        furigana: "いただ.く",
        romaji: "itada.ku",
        traducao: "topo",
      },
    ],
  },
  {
    kanji: "箱",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "はこ", romaji: "hako" }],
    significado: "caixa",
    exemplos: [
      {
        kanji: "箱",
        furigana: "はこ",
        romaji: "hako",
        traducao: "caixa",
      },
      {
        kanji: "荷箱",
        furigana: "にばこ",
        romaji: "nibako",
        traducao: "caixa de carga",
      },
      {
        kanji: "箱",
        furigana: "はこ",
        romaji: "hako",
        traducao: "caixa",
      },
    ],
  },
  {
    kanji: "晩",
    onYomi: [{ katakana: "バン", romaji: "ban" }],
    kunYomi: [{ hiragana: "ばん", romaji: "ban" }],
    significado: "noite",
    exemplos: [
      {
        kanji: "晩",
        furigana: "ばん",
        romaji: "ban",
        traducao: "noite",
      },
      {
        kanji: "晩御飯",
        furigana: "ばんごはん",
        romaji: "bangohan",
        traducao: "jantar",
      },
      {
        kanji: "晩",
        furigana: "ばん",
        romaji: "ban",
        traducao: "noite",
      },
    ],
  },
  {
    kanji: "寒",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "さむ.い", romaji: "samu.i" }],
    significado: "frio",
    exemplos: [
      {
        kanji: "寒い",
        furigana: "さむい",
        romaji: "samui",
        traducao: "frio",
      },
      {
        kanji: "寒冷",
        furigana: "かんれい",
        romaji: "kanrei",
        traducao: "clima frio",
      },
      {
        kanji: "寒",
        furigana: "さむ.い",
        romaji: "samu.i",
        traducao: "frio",
      },
    ],
  },
  {
    kanji: "髪",
    onYomi: [{ katakana: "ハツ", romaji: "hatsu" }],
    kunYomi: [{ hiragana: "かみ", romaji: "kami" }],
    significado: "cabelo",
    exemplos: [
      {
        kanji: "髪",
        furigana: "かみ",
        romaji: "kami",
        traducao: "cabelo",
      },
      {
        kanji: "長髪",
        furigana: "ちょうはつ",
        romaji: "chouhatsu",
        traducao: "cabelo longo",
      },
      {
        kanji: "髪",
        furigana: "かみ",
        romaji: "kami",
        traducao: "cabelo",
      },
    ],
  },
  {
    kanji: "忙",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "いそが.しい", romaji: "isoga.shii" }],
    significado: "ocupado",
    exemplos: [
      {
        kanji: "忙しい",
        furigana: "いそがしい",
        romaji: "isogashii",
        traducao: "ocupado",
      },
      {
        kanji: "多忙",
        furigana: "たぼう",
        romaji: "tabou",
        traducao: "muito ocupado",
      },
      {
        kanji: "忙",
        furigana: "いそが.しい",
        romaji: "isoga.shii",
        traducao: "ocupado",
      },
    ],
  },
  {
    kanji: "才",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [],
    significado: "talento",
    exemplos: [
      {
        kanji: "天才",
        furigana: "てんさい",
        romaji: "tensai",
        traducao: "gênio",
      },
      {
        kanji: "才能",
        furigana: "さいのう",
        romaji: "sainou",
        traducao: "talento",
      },
      {
        kanji: "才",
        furigana: "サイ",
        romaji: "sai",
        traducao: "talento",
      },
    ],
  },
  {
    kanji: "靴",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "くつ", romaji: "kutsu" }],
    significado: "sapato",
    exemplos: [
      {
        kanji: "靴",
        furigana: "くつ",
        romaji: "kutsu",
        traducao: "sapato",
      },
      {
        kanji: "靴下",
        furigana: "くつした",
        romaji: "kutsushita",
        traducao: "meia",
      },
      {
        kanji: "靴",
        furigana: "くつ",
        romaji: "kutsu",
        traducao: "sapato",
      },
    ],
  },
  {
    kanji: "恥",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [{ hiragana: "は.じる", romaji: "ha.jiru" }],
    significado: "vergonha",
    exemplos: [
      {
        kanji: "恥ずかしい",
        furigana: "はずかしい",
        romaji: "hazukashii",
        traducao: "vergonhoso",
      },
      {
        kanji: "恥",
        furigana: "は.じる",
        romaji: "ha.jiru",
        traducao: "vergonha",
      },
    ],
  },
  {
    kanji: "偶",
    onYomi: [{ katakana: "グウ", romaji: "gū" }],
    kunYomi: [{ hiragana: "たま", romaji: "tama" }],
    significado: "acaso",
    exemplos: [
      {
        kanji: "偶然",
        furigana: "ぐうぜん",
        romaji: "gūzen",
        traducao: "acaso",
      },
      {
        kanji: "偶像",
        furigana: "ぐうぞう",
        romaji: "gūzō",
        traducao: "ídolo",
      },
      {
        kanji: "偶",
        furigana: "たま",
        romaji: "tama",
        traducao: "acaso",
      },
    ],
  },
  {
    kanji: "偉",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "えら.い", romaji: "era.i" }],
    significado: "grande",
    exemplos: [
      {
        kanji: "偉大",
        furigana: "いだい",
        romaji: "idai",
        traducao: "grande",
      },
      {
        kanji: "偉人",
        furigana: "いじん",
        romaji: "ijin",
        traducao: "pessoa ilustre",
      },
      {
        kanji: "偉",
        furigana: "えら.い",
        romaji: "era.i",
        traducao: "grande",
      },
    ],
  },
  {
    kanji: "猫",
    onYomi: [{ katakana: "ビョウ", romaji: "byou" }],
    kunYomi: [{ hiragana: "ねこ", romaji: "neko" }],
    significado: "gato",
    exemplos: [
      {
        kanji: "猫",
        furigana: "ねこ",
        romaji: "neko",
        traducao: "gato",
      },
      {
        kanji: "猫舌",
        furigana: "ねこじた",
        romaji: "nekojita",
        traducao: "sensibilidade ao calor",
      },
      {
        kanji: "猫",
        furigana: "ねこ",
        romaji: "neko",
        traducao: "gato",
      },
    ],
  },
  {
    kanji: "幾",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "いく", romaji: "iku" }],
    significado: "quantos",
    exemplos: [
      {
        kanji: "幾つ",
        furigana: "いくつ",
        romaji: "ikutsu",
        traducao: "quantos",
      },
      {
        kanji: "幾分",
        furigana: "いくぶん",
        romaji: "ikubun",
        traducao: "em que medida",
      },
      {
        kanji: "幾",
        furigana: "いく",
        romaji: "iku",
        traducao: "quantos",
      },
    ],
  },
  {
    kanji: "誰",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "だれ", romaji: "dare" }],
    significado: "quem",
    exemplos: [
      {
        kanji: "誰",
        furigana: "だれ",
        romaji: "dare",
        traducao: "quem",
      },
      {
        kanji: "誰でも",
        furigana: "だれでも",
        romaji: "dare demo",
        traducao: "qualquer pessoa",
      },
      {
        kanji: "誰",
        furigana: "だれ",
        romaji: "dare",
        traducao: "quem",
      },
    ],
  },
];

export default n3KanjiList;