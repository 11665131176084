const n1KanjiList = [
  {
    kanji: "氏",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "うじ", romaji: "uji" }],
    significado: "sobrenome; Sr.",
    exemplos: [
      {
        kanji: "氏名",
        furigana: "しめい",
        romaji: "shimei",
        traducao: "nome completo",
      },
      {
        kanji: "氏族",
        furigana: "しぞく",
        romaji: "shizoku",
        traducao: "clã; família",
      },
      {
        kanji: "氏文",
        furigana: "しぶん",
        romaji: "shibun",
        traducao: "nome completo ",
      },
    ],
  },
  {
    kanji: "統",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "す.べる", romaji: "suberu" }],
    significado: "unificar; governar",
    exemplos: [
      {
        kanji: "統一",
        furigana: "とういつ",
        romaji: "touitsu",
        traducao: "unificação",
      },
      {
        kanji: "統計",
        furigana: "とうけい",
        romaji: "toukei",
        traducao: "estatística",
      },
      {
        kanji: "統治",
        furigana: "とうじ",
        romaji: "touji",
        traducao: "governar; governança",
      },
    ],
  },
  {
    kanji: "保",
    onYomi: [{ katakana: "ホ", romaji: "ho" }],
    kunYomi: [{ hiragana: "たも.つ", romaji: "tamotsu" }],
    significado: "proteger; manter",
    exemplos: [
      {
        kanji: "保護",
        furigana: "ほご",
        romaji: "hogo",
        traducao: "proteção",
      },
      {
        kanji: "保証",
        furigana: "ほしょう",
        romaji: "hoshou",
        traducao: "garantia",
      },
      {
        kanji: "保育",
        furigana: "ほいく",
        romaji: "hoiku",
        traducao: "cuidado de crianças",
      },
    ],
  },
  {
    kanji: "第",
    onYomi: [{ katakana: "ダイ", romaji: "dai" }],
    kunYomi: [],
    significado: "ordinal; número",
    exemplos: [
      {
        kanji: "第一",
        furigana: "だいいち",
        romaji: "daiichi",
        traducao: "primeiro",
      },
      {
        kanji: "第二",
        furigana: "だいに",
        romaji: "daini",
        traducao: "segundo",
      },
      {
        kanji: "第三",
        furigana: "だいさん",
        romaji: "daisan",
        traducao: "terceiro",
      },
    ],
  },
  {
    kanji: "結",
    onYomi: [{ katakana: "ケツ", romaji: "ketsu" }],
    kunYomi: [{ hiragana: "むす.ぶ", romaji: "musubu" }],
    significado: "unir; juntar",
    exemplos: [
      {
        kanji: "結婚",
        furigana: "けっこん",
        romaji: "kekkon",
        traducao: "casamento",
      },
      {
        kanji: "結果",
        furigana: "けっか",
        romaji: "kekka",
        traducao: "resultado",
      },
      {
        kanji: "結論",
        furigana: "けつろん",
        romaji: "ketsuron",
        traducao: "conclusão",
      },
    ],
  },
  {
    kanji: "派",
    onYomi: [{ katakana: "ハ", romaji: "ha" }],
    kunYomi: [],
    significado: "grupo; facção",
    exemplos: [
      {
        kanji: "派手",
        furigana: "はで",
        romaji: "hade",
        traducao: "chamativo",
      },
      {
        kanji: "派遣",
        furigana: "はけん",
        romaji: "haken",
        traducao: "envio (de pessoas)",
      },
      {
        kanji: "派閥",
        furigana: "はばつ",
        romaji: "habatsu",
        traducao: "facção; clã",
      },
    ],
  },
  {
    kanji: "案",
    onYomi: [{ katakana: "アン", romaji: "an" }],
    kunYomi: [{ hiragana: "あん", romaji: "an" }],
    significado: "ideia; proposta",
    exemplos: [
      {
        kanji: "案内",
        furigana: "あんない",
        romaji: "annai",
        traducao: "guia; orientação",
      },
      {
        kanji: "案件",
        furigana: "あんけん",
        romaji: "anken",
        traducao: "assunto; caso",
      },
      {
        kanji: "案内人",
        furigana: "あんないにん",
        romaji: "annainin",
        traducao: "guia; acompanhante",
      },
    ],
  },
  {
    kanji: "策",
    onYomi: [{ katakana: "サク", romaji: "saku" }],
    kunYomi: [],
    significado: "estratégia; plano",
    exemplos: [
      {
        kanji: "政策",
        furigana: "せいさく",
        romaji: "seisaku",
        traducao: "política (governo)",
      },
      {
        kanji: "解策",
        furigana: "かいさく",
        romaji: "kaisaku",
        traducao: "solução; resolução",
      },
      {
        kanji: "方策",
        furigana: "ほうさく",
        romaji: "housaku",
        traducao: "medida; estratégia",
      },
    ],
  },
  {
    kanji: "基",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "もと", romaji: "moto" }],
    significado: "base; fundação",
    exemplos: [
      {
        kanji: "基本",
        furigana: "きほん",
        romaji: "kihon",
        traducao: "fundamento; básico",
      },
      {
        kanji: "基礎",
        furigana: "きそ",
        romaji: "kiso",
        traducao: "base; fundamentos",
      },
      {
        kanji: "基準",
        furigana: "きじゅん",
        romaji: "kijun",
        traducao: "padrão; critério",
      },
    ],
  },
  {
    kanji: "価",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "あたい", romaji: "atai" }],
    significado: "valor; preço",
    exemplos: [
      {
        kanji: "価値",
        furigana: "かち",
        romaji: "kachi",
        traducao: "valor",
      },
      {
        kanji: "価格",
        furigana: "かかく",
        romaji: "kakaku",
        traducao: "preço",
      },
      {
        kanji: "評価",
        furigana: "ひょうか",
        romaji: "hyouka",
        traducao: "avaliação",
      },
    ],
  },
  {
    kanji: "提",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "さ.げる", romaji: "sageru" }],
    significado: "apresentar; oferecer",
    exemplos: [
      {
        kanji: "提案",
        furigana: "ていあん",
        romaji: "teian",
        traducao: "proposta",
      },
      {
        kanji: "提供",
        furigana: "ていきょう",
        romaji: "teikyou",
        traducao: "fornecimento; oferta",
      },
      {
        kanji: "提携",
        furigana: "ていけい",
        romaji: "teikei",
        traducao: "cooperação; parceria",
      },
    ],
  },
  {
    kanji: "挙",
    onYomi: [{ katakana: "キョ", romaji: "kyo" }],
    kunYomi: [{ hiragana: "あ.げる", romaji: "ageru" }],
    significado: "levantar; mencionar",
    exemplos: [
      {
        kanji: "選挙",
        furigana: "せんきょ",
        romaji: "senkyo",
        traducao: "eleição",
      },
      {
        kanji: "舉挙",
        furigana: "きょきょ",
        romaji: "kyokyo",
        traducao: "levantamento",
      },
      {
        kanji: "挙手",
        furigana: "きょしゅ",
        romaji: "kyoshu",
        traducao: "levantar a mão",
      },
    ],
  },
  {
    kanji: "応",
    onYomi: [{ katakana: "オウ", romaji: "ou" }],
    kunYomi: [{ hiragana: "こた.える", romaji: "kotae" }],
    significado: "responder; atender",
    exemplos: [
      {
        kanji: "応答",
        furigana: "おうとう",
        romaji: "outou",
        traducao: "resposta",
      },
      {
        kanji: "応募",
        furigana: "おうぼ",
        romaji: "oubo",
        traducao: "inscrição; candidatura",
      },
      {
        kanji: "応援",
        furigana: "おうえん",
        romaji: "ouen",
        traducao: "apoio; torcida",
      },
    ],
  },
  {
    kanji: "企",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "くわだ.てる", romaji: "kuwada" }],
    significado: "planejar; empreender",
    exemplos: [
      {
        kanji: "企画",
        furigana: "きかく",
        romaji: "kikaku",
        traducao: "planejamento",
      },
      {
        kanji: "企業",
        furigana: "きぎょう",
        romaji: "kigyou",
        traducao: "empresa",
      },
      {
        kanji: "企図",
        furigana: "きと",
        romaji: "kito",
        traducao: "plano; projeto",
      },
    ],
  },
  {
    kanji: "検",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "しら.べる", romaji: "shiraberu" }],
    significado: "examinar; investigar",
    exemplos: [
      {
        kanji: "検査",
        furigana: "けんさ",
        romaji: "kensa",
        traducao: "inspeção; exame",
      },
      {
        kanji: "検証",
        furigana: "けんしょう",
        romaji: "kenshou",
        traducao: "verificação; confirmação",
      },
      {
        kanji: "調査",
        furigana: "ちょうさ",
        romaji: "chousa",
        traducao: "investigação",
      },
    ],
  },
  {
    kanji: "藤",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [],
    significado: "glicínia",
    exemplos: [
      {
        kanji: "藤色",
        furigana: "ふじいろ",
        romaji: "fujiiro",
        traducao: "cor lilás; cor de glicínia",
      },
      {
        kanji: "藤",
        furigana: "ふじ",
        romaji: "fuji",
        traducao: "glicínia",
      },
      {
        kanji: "藤の花",
        furigana: "ふじのはな",
        romaji: "fuji no hana",
        traducao: "flor de glicínia",
      },
    ],
  },
  {
    kanji: "沢",
    onYomi: [{ katakana: "タク", romaji: "taku" }],
    kunYomi: [{ hiragana: "さわ", romaji: "sawa" }],
    significado: "pântano; riacho",
    exemplos: [
      {
        kanji: "湖沢",
        furigana: "こさわ",
        romaji: "kosawa",
        traducao: "lago; pântano",
      },
      {
        kanji: "沢山",
        furigana: "たくさん",
        romaji: "takusan",
        traducao: "muitos; abundante",
      },
      {
        kanji: "沢地",
        furigana: "さわち",
        romaji: "sawachi",
        traducao: "terreno pantanoso",
      },
    ],
  },
  {
    kanji: "裁",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "た.つ", romaji: "tatsu" }],
    significado: "julgar; decidir",
    exemplos: [
      {
        kanji: "裁判",
        furigana: "さいばん",
        romaji: "saiban",
        traducao: "julgamento; tribunal",
      },
      {
        kanji: "裁縫",
        furigana: "さいほう",
        romaji: "saihou",
        traducao: "costura",
      },
      {
        kanji: "判裁",
        furigana: "はんさい",
        romaji: "hansai",
        traducao: "julgamento",
      },
    ],
  },
  {
    kanji: "証",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "あかし", romaji: "akashi" }],
    significado: "prova; testemunho",
    exemplos: [
      {
        kanji: "証明",
        furigana: "しょうめい",
        romaji: "shoumei",
        traducao: "prova; comprovação",
      },
      {
        kanji: "証言",
        furigana: "しょうげん",
        romaji: "shougen",
        traducao: "testemunho",
      },
      {
        kanji: "証拠",
        furigana: "しょうこ",
        romaji: "shouko",
        traducao: "evidência; prova",
      },
    ],
  },
  {
    kanji: "援",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [],
    significado: "apoio; assistência",
    exemplos: [
      {
        kanji: "援助",
        furigana: "えんじょ",
        romaji: "enjo",
        traducao: "ajuda; assistência",
      },
      {
        kanji: "応援",
        furigana: "おうえん",
        romaji: "ouen",
        traducao: "apoio; torcida",
      },
      {
        kanji: "支援",
        furigana: "しえん",
        romaji: "shien",
        traducao: "apoio; suporte",
      },
    ],
  },
  {
    kanji: "施",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "ほどこ.す", romaji: "hodokosu" }],
    significado: "oferecer; aplicar",
    exemplos: [
      {
        kanji: "施設",
        furigana: "しせつ",
        romaji: "shisetsu",
        traducao: "instalação; equipamento",
      },
      {
        kanji: "施工",
        furigana: "しこう",
        romaji: "shikou",
        traducao: "construção; obra",
      },
      {
        kanji: "施策",
        furigana: "しさく",
        romaji: "shisaku",
        traducao: "medida; política",
      },
    ],
  },
  {
    kanji: "井",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "い", romaji: "i" }],
    significado: "poço",
    exemplos: [
      {
        kanji: "井戸",
        furigana: "いど",
        romaji: "ido",
        traducao: "poço",
      },
      {
        kanji: "井上",
        furigana: "いのうえ",
        romaji: "inoue",
        traducao: "Inoue (sobrenome)",
      },
      {
        kanji: "井",
        furigana: "い",
        romaji: "i",
        traducao: "poço",
      },
    ],
  },
  {
    kanji: "護",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [],
    significado: "proteger; defender",
    exemplos: [
      {
        kanji: "保護",
        furigana: "ほご",
        romaji: "hogo",
        traducao: "proteção",
      },
      {
        kanji: "護衛",
        furigana: "ごえい",
        romaji: "goei",
        traducao: "escolta; guarda-costas",
      },
      {
        kanji: "自衛",
        furigana: "じえい",
        romaji: "jie",
        traducao: "autodefesa",
      },
    ],
  },
  {
    kanji: "展",
    onYomi: [{ katakana: "テン", romaji: "ten" }],
    kunYomi: [],
    significado: "exposição; exibição",
    exemplos: [
      {
        kanji: "展示",
        furigana: "てんじ",
        romaji: "tenji",
        traducao: "exposição",
      },
      {
        kanji: "発展",
        furigana: "はってん",
        romaji: "hatten",
        traducao: "desenvolvimento",
      },
      {
        kanji: "展開",
        furigana: "てんかい",
        romaji: "tenkai",
        traducao: "desenvolvimento; expansão",
      },
    ],
  },
  {
    kanji: "態",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [],
    significado: "condição; estado",
    exemplos: [
      {
        kanji: "状態",
        furigana: "じょうたい",
        romaji: "joutai",
        traducao: "condição; estado",
      },
      {
        kanji: "態度",
        furigana: "たいど",
        romaji: "taido",
        traducao: "atitude",
      },
      {
        kanji: "行動",
        furigana: "こうどう",
        romaji: "koudou",
        traducao: "ação; comportamento",
      },
    ],
  },
  {
    kanji: "鮮",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "あざ.やか", romaji: "azayaka" }],
    significado: "vibrante; fresco",
    exemplos: [
      {
        kanji: "鮮明",
        furigana: "せんめい",
        romaji: "senmei",
        traducao: "vívido; claro",
      },
      {
        kanji: "新鮮",
        furigana: "しんせん",
        romaji: "shinsen",
        traducao: "fresco; novo",
      },
      {
        kanji: "鮮血",
        furigana: "せんけつ",
        romaji: "senketsu",
        traducao: "sangue fresco",
      },
    ],
  },
  {
    kanji: "視",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "ver; observar",
    exemplos: [
      {
        kanji: "視点",
        furigana: "してん",
        romaji: "shiten",
        traducao: "perspectiva",
      },
      {
        kanji: "視野",
        furigana: "しや",
        romaji: "shiya",
        traducao: "campo de visão",
      },
      {
        kanji: "監視",
        furigana: "かんし",
        romaji: "kanshi",
        traducao: "vigilância",
      },
    ],
  },
  {
    kanji: "条",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [],
    significado: "cláusula; artigo",
    exemplos: [
      {
        kanji: "条件",
        furigana: "じょうけん",
        romaji: "jouken",
        traducao: "condição",
      },
      {
        kanji: "条約",
        furigana: "じょうやく",
        romaji: "jouyaku",
        traducao: "tratado; acordo",
      },
      {
        kanji: "規条",
        furigana: "きじょう",
        romaji: "kijou",
        traducao: "cláusula",
      },
    ],
  },
  {
    kanji: "幹",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "tronco; núcleo",
    exemplos: [
      {
        kanji: "主幹",
        furigana: "しゅかん",
        romaji: "shukan",
        traducao: "núcleo; elemento principal",
      },
      {
        kanji: "幹事",
        furigana: "かんじ",
        romaji: "kanji",
        traducao: "secretário",
      },
      {
        kanji: "幹線",
        furigana: "かんせん",
        romaji: "kansen",
        traducao: "linha principal",
      },
    ],
  },
  {
    kanji: "独",
    onYomi: [{ katakana: "ドク", romaji: "doku" }],
    kunYomi: [],
    significado: "sozinho; independente",
    exemplos: [
      {
        kanji: "独立",
        furigana: "どくりつ",
        romaji: "dokuritsu",
        traducao: "independência",
      },
      {
        kanji: "独自",
        furigana: "どくじ",
        romaji: "dokuji",
        traducao: "próprio; original",
      },
      {
        kanji: "独占",
        furigana: "どくせん",
        romaji: "dokusen",
        traducao: "monopólio",
      },
    ],
  },
  {
    kanji: "宮",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "みや", romaji: "miya" }],
    significado: "palácio; santuário",
    exemplos: [
      {
        kanji: "宮殿",
        furigana: "きゅうでん",
        romaji: "kyuuden",
        traducao: "palácio",
      },
      {
        kanji: "神宮",
        furigana: "じんぐう",
        romaji: "jinguu",
        traducao: "santuário",
      },
      {
        kanji: "宮廷",
        furigana: "きゅうてい",
        romaji: "kyuutei",
        traducao: "corte imperial",
      },
    ],
  },
  {
    kanji: "率",
    onYomi: [{ katakana: "ソツ", romaji: "sotsu" }],
    kunYomi: [{ hiragana: "ひき.いる", romaji: "hikii" }],
    significado: "taxa; proporção",
    exemplos: [
      {
        kanji: "割合",
        furigana: "わりあい",
        romaji: "wariai",
        traducao: "proporção",
      },
      {
        kanji: "率直",
        furigana: "そっちょく",
        romaji: "socchoku",
        traducao: "franqueza; sinceridade",
      },
      {
        kanji: "成功率",
        furigana: "せいこうりつ",
        romaji: "seikouritsu",
        traducao: "taxa de sucesso",
      },
    ],
  },
  {
    kanji: "衛",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [],
    significado: "defesa; guarda",
    exemplos: [
      {
        kanji: "自衛",
        furigana: "じえい",
        romaji: "jie",
        traducao: "autodefesa",
      },
      {
        kanji: "防衛",
        furigana: "ぼうえい",
        romaji: "bouei",
        traducao: "defesa",
      },
      {
        kanji: "警衛",
        furigana: "けいえい",
        romaji: "keiei",
        traducao: "segurança; guarda",
      },
    ],
  },
  {
    kanji: "張",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "は.る", romaji: "haru" }],
    significado: "esticar; estender",
    exemplos: [
      {
        kanji: "拡張",
        furigana: "かくちょう",
        romaji: "kakuchou",
        traducao: "expansão",
      },
      {
        kanji: "張る",
        furigana: "はる",
        romaji: "haru",
        traducao: "esticar; estender",
      },
      {
        kanji: "張り紙",
        furigana: "はりがみ",
        romaji: "harigami",
        traducao: "cartaz; aviso",
      },
    ],
  },
  {
    kanji: "監",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "supervisão; controle",
    exemplos: [
      {
        kanji: "監督",
        furigana: "かんとく",
        romaji: "kantoku",
        traducao: "diretor; supervisor",
      },
      {
        kanji: "監視",
        furigana: "かんし",
        romaji: "kanshi",
        traducao: "vigilância",
      },
      {
        kanji: "管理",
        furigana: "かんり",
        romaji: "kanri",
        traducao: "gerenciamento",
      },
    ],
  },
  {
    kanji: "環",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "anel; ambiente",
    exemplos: [
      {
        kanji: "環境",
        furigana: "かんきょう",
        romaji: "kankyou",
        traducao: "ambiente",
      },
      {
        kanji: "環状",
        furigana: "かんじょう",
        romaji: "kanjou",
        traducao: "anular; circular",
      },
      {
        kanji: "環境保護",
        furigana: "かんきょうほご",
        romaji: "kankyouhogo",
        traducao: "preservação ambiental",
      },
    ],
  },
  {
    kanji: "審",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "julgar; revisar",
    exemplos: [
      {
        kanji: "審査",
        furigana: "しんさ",
        romaji: "shinsa",
        traducao: "avaliação; revisão",
      },
      {
        kanji: "審判",
        furigana: "しんぱん",
        romaji: "shinpan",
        traducao: "julgamento; arbitragem",
      },
      {
        kanji: "審議",
        furigana: "しんぎ",
        romaji: "shingi",
        traducao: "deliberação",
      },
    ],
  },
  {
    kanji: "義",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [],
    significado: "justiça; moral",
    exemplos: [
      {
        kanji: "義務",
        furigana: "ぎむ",
        romaji: "gimu",
        traducao: "obrigação; dever",
      },
      {
        kanji: "正義",
        furigana: "せいぎ",
        romaji: "seigi",
        traducao: "justiça",
      },
      {
        kanji: "道義",
        furigana: "どうぎ",
        romaji: "dougi",
        traducao: "moral; ética",
      },
    ],
  },
  {
    kanji: "訴",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [{ hiragana: "うった.える", romaji: "uttaeru" }],
    significado: "apelar; processar",
    exemplos: [
      {
        kanji: "訴訟",
        furigana: "そしょう",
        romaji: "soshou",
        traducao: "processo judicial",
      },
      {
        kanji: "告訴",
        furigana: "こくそ",
        romaji: "kokuso",
        traducao: "denúncia; acusação",
      },
      {
        kanji: "訴求",
        furigana: "そきゅう",
        romaji: "sokyuu",
        traducao: "apelar; reivindicar",
      },
    ],
  },
  {
    kanji: "株",
    onYomi: [{ katakana: "カブ", romaji: "kabu" }],
    kunYomi: [],
    significado: "ação; ação de empresa",
    exemplos: [
      {
        kanji: "株式",
        furigana: "かぶしき",
        romaji: "kabushiki",
        traducao: "ação (título)",
      },
      {
        kanji: "株価",
        furigana: "かぶか",
        romaji: "kabuka",
        traducao: "preço da ação",
      },
      {
        kanji: "株式市場",
        furigana: "かぶしきしじょう",
        romaji: "kabushikishijou",
        traducao: "mercado de ações",
      },
    ],
  },
  {
    kanji: "姿",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "すがた", romaji: "sugata" }],
    significado: "aparência; forma",
    exemplos: [
      {
        kanji: "姿勢",
        furigana: "しせい",
        romaji: "shisei",
        traducao: "postura",
      },
      {
        kanji: "外見",
        furigana: "がいけん",
        romaji: "gaiken",
        traducao: "aparência",
      },
      {
        kanji: "容姿",
        furigana: "ようし",
        romaji: "youshi",
        traducao: "aparência física",
      },
    ],
  },
  {
    kanji: "閣",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [],
    significado: "gabinete; torre",
    exemplos: [
      {
        kanji: "内閣",
        furigana: "ないかく",
        romaji: "naikaku",
        traducao: "gabinete",
      },
      {
        kanji: "閣議",
        furigana: "かくぎ",
        romaji: "kakugi",
        traducao: "conselho de ministros",
      },
      {
        kanji: "閣僚",
        furigana: "かくりょう",
        romaji: "kakuryou",
        traducao: "ministro",
      },
    ],
  },
  {
    kanji: "衆",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [],
    significado: "multidão; povo",
    exemplos: [
      {
        kanji: "衆議院",
        furigana: "しゅうぎいん",
        romaji: "shuugiin",
        traducao: "Câmara dos Representantes",
      },
      {
        kanji: "市衆",
        furigana: "いちしゅう",
        romaji: "ichishuu",
        traducao: "cidadãos; povo",
      },
      {
        kanji: "大衆",
        furigana: "たいしゅう",
        romaji: "taishuu",
        traducao: "massa; público",
      },
    ],
  },
  {
    kanji: "評",
    onYomi: [{ katakana: "ヒョウ", romaji: "hyou" }],
    kunYomi: [],
    significado: "avaliação; crítica",
    exemplos: [
      {
        kanji: "評価",
        furigana: "ひょうか",
        romaji: "hyouka",
        traducao: "avaliação",
      },
      {
        kanji: "批評",
        furigana: "ひひょう",
        romaji: "hihyou",
        traducao: "crítica",
      },
      {
        kanji: "評判",
        furigana: "ひょうばん",
        romaji: "hyouban",
        traducao: "reputação",
      },
    ],
  },
  {
    kanji: "影",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [{ hiragana: "かげ", romaji: "kage" }],
    significado: "sombra; influência",
    exemplos: [
      {
        kanji: "影響",
        furigana: "えいきょう",
        romaji: "eikyou",
        traducao: "influência",
      },
      {
        kanji: "日影",
        furigana: "ひかげ",
        romaji: "hikage",
        traducao: "sombra",
      },
      {
        kanji: "映像",
        furigana: "えいぞう",
        romaji: "eizou",
        traducao: "imagem; filme",
      },
    ],
  },
  {
    kanji: "松",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "まつ", romaji: "matsu" }],
    significado: "pinheiro",
    exemplos: [
      {
        kanji: "松林",
        furigana: "まつばやし",
        romaji: "matsubayashi",
        traducao: "floresta de pinheiros",
      },
      {
        kanji: "松竹梅",
        furigana: "しょうちくばい",
        romaji: "shouchikubai",
        traducao: "os três símbolos japoneses (pinheiro, bambu e ameixeira)",
      },
      {
        kanji: "松",
        furigana: "まつ",
        romaji: "matsu",
        traducao: "pinheiro",
      },
    ],
  },
  {
    kanji: "撃",
    onYomi: [{ katakana: "ゲキ", romaji: "geki" }],
    kunYomi: [{ hiragana: "う.つ", romaji: "utsu" }],
    significado: "atacar; disparar",
    exemplos: [
      {
        kanji: "攻撃",
        furigana: "こうげき",
        romaji: "kougeki",
        traducao: "ataque",
      },
      {
        kanji: "撃退",
        furigana: "げきたい",
        romaji: "gekitai",
        traducao: "repelir",
      },
      {
        kanji: "打撃",
        furigana: "だげき",
        romaji: "dageki",
        traducao: "golpe",
      },
    ],
  },
  {
    kanji: "佐",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [],
    significado: "ajudante; assistente",
    exemplos: [
      {
        kanji: "佐官",
        furigana: "さかん",
        romaji: "sakan",
        traducao: "oficial assistente",
      },
      {
        kanji: "佐藤",
        furigana: "さとう",
        romaji: "satou",
        traducao: "Sato (sobrenome)",
      },
      {
        kanji: "参佐",
        furigana: "さんさ",
        romaji: "sansa",
        traducao: "ajudante",
      },
    ],
  },
  {
    kanji: "核",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [],
    significado: "núcleo; essência",
    exemplos: [
      {
        kanji: "原子核",
        furigana: "げんしかく",
        romaji: "genshikaku",
        traducao: "núcleo atômico",
      },
      {
        kanji: "核兵器",
        furigana: "かくへいき",
        romaji: "kakuheiki",
        traducao: "arma nuclear",
      },
      {
        kanji: "核心",
        furigana: "かくしん",
        romaji: "kakushin",
        traducao: "essência",
      },
    ],
  },
  {
    kanji: "整",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "ととの.える", romaji: "totonoeru" }],
    significado: "organizar; arrumar",
    exemplos: [
      {
        kanji: "整理",
        furigana: "せいり",
        romaji: "seiri",
        traducao: "organização",
      },
      {
        kanji: "整備",
        furigana: "せいび",
        romaji: "seibi",
        traducao: "manutenção; preparação",
      },
      {
        kanji: "調整",
        furigana: "ちょうせい",
        romaji: "chousei",
        traducao: "ajuste",
      },
    ],
  },
  {
    kanji: "融",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [{ hiragana: "と.ける", romaji: "to.keru" }],
    significado: "fundir; derreter",
    exemplos: [
      {
        kanji: "融資",
        furigana: "ゆうし",
        romaji: "yuushi",
        traducao: "financiamento",
      },
      {
        kanji: "溶融",
        furigana: "ようゆう",
        romaji: "yōyū",
        traducao: "fusão",
      },
      {
        kanji: "融通",
        furigana: "ゆうずう",
        romaji: "yuuzuu",
        traducao: "flexibilidade",
      },
    ],
  },
  {
    kanji: "製",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [],
    significado: "produzir; fabricar",
    exemplos: [
      {
        kanji: "製造",
        furigana: "せいぞう",
        romaji: "seizou",
        traducao: "fabricação",
      },
      {
        kanji: "製品",
        furigana: "せいひん",
        romaji: "seihin",
        traducao: "produto",
      },
      {
        kanji: "製作",
        furigana: "せいさく",
        romaji: "seisaku",
        traducao: "produção",
      },
    ],
  },
  {
    kanji: "票",
    onYomi: [{ katakana: "ヒョウ", romaji: "hyou" }],
    kunYomi: [],
    significado: "voto; bilhete",
    exemplos: [
      {
        kanji: "投票",
        furigana: "とうひょう",
        romaji: "touhyou",
        traducao: "votação",
      },
      {
        kanji: "得票",
        furigana: "とくひょう",
        romaji: "tokuhyou",
        traducao: "votos obtidos",
      },
      {
        kanji: "一票",
        furigana: "いっぴょう",
        romaji: "ippyou",
        traducao: "um voto",
      },
    ],
  },
  {
    kanji: "渉",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "わた.る", romaji: "wataru" }],
    significado: "atravessar; envolver",
    exemplos: [
      {
        kanji: "関渉",
        furigana: "かんしょう",
        romaji: "kanshou",
        traducao: "envolvimento",
      },
      {
        kanji: "渉外",
        furigana: "しょうがい",
        romaji: "shougai",
        traducao: "relações exteriores",
      },
      {
        kanji: "渡る",
        furigana: "わたる",
        romaji: "wataru",
        traducao: "atravessar",
      },
    ],
  },
  {
    kanji: "響",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "ひび.く", romaji: "hibiku" }],
    significado: "ressoar; ecoar",
    exemplos: [
      {
        kanji: "響音",
        furigana: "きょうおん",
        romaji: "kyouon",
        traducao: "som ressonante",
      },
      {
        kanji: "影響",
        furigana: "えいきょう",
        romaji: "eikyou",
        traducao: "influência",
      },
      {
        kanji: "響く",
        furigana: "ひびく",
        romaji: "hibiku",
        traducao: "ressoar",
      },
    ],
  },
  {
    kanji: "推",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "お.す", romaji: "osu" }],
    significado: "recomendar; deduzir",
    exemplos: [
      {
        kanji: "推理",
        furigana: "すいり",
        romaji: "suiri",
        traducao: "raciocínio; dedução",
      },
      {
        kanji: "推論",
        furigana: "すいろん",
        romaji: "suiron",
        traducao: "inferência; lógica",
      },
      {
        kanji: "推測",
        furigana: "すいそく",
        romaji: "suisoku",
        traducao: "especulação",
      },
    ],
  },
  {
    kanji: "請",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "こ.う", romaji: "ko.u" }],
    significado: "pedir; solicitar",
    exemplos: [
      {
        kanji: "請求",
        furigana: "せいきゅう",
        romaji: "seikyuu",
        traducao: "solicitação; cobrança",
      },
      {
        kanji: "請願",
        furigana: "せいがん",
        romaji: "seigan",
        traducao: "petição",
      },
      {
        kanji: "謝請",
        furigana: "しゃせい",
        romaji: "shasei",
        traducao: "pedido de desculpas",
      },
    ],
  },
  {
    kanji: "器",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "うつわ", romaji: "utsuwa" }],
    significado: "recipiente; utensílio",
    exemplos: [
      {
        kanji: "容器",
        furigana: "ようき",
        romaji: "youki",
        traducao: "recipiente",
      },
      {
        kanji: "器具",
        furigana: "きぐ",
        romaji: "kigu",
        traducao: "utensílio",
      },
      {
        kanji: "楽器",
        furigana: "がっき",
        romaji: "gakki",
        traducao: "instrumento musical",
      },
    ],
  },
  {
    kanji: "士",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "cavaleiro; erudito",
    exemplos: [
      {
        kanji: "武士",
        furigana: "ぶし",
        romaji: "bushi",
        traducao: "samurai; guerreiro",
      },
      {
        kanji: "学士",
        furigana: "がくし",
        romaji: "gakushi",
        traducao: "bacharel",
      },
      {
        kanji: "士気",
        furigana: "しかい",
        romaji: "shikai",
        traducao: "moral; espírito",
      },
    ],
  },
  {
    kanji: "討",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "う.つ", romaji: "utsu" }],
    significado: "atacar; lutar contra",
    exemplos: [
      {
        kanji: "討論",
        furigana: "とうろん",
        romaji: "touron",
        traducao: "discussão",
      },
      {
        kanji: "討議",
        furigana: "とうぎ",
        romaji: "tougi",
        traducao: "deliberação",
      },
      {
        kanji: "討伐",
        furigana: "とうばつ",
        romaji: "toubatsu",
        traducao: "subjugação",
      },
    ],
  },
  {
    kanji: "攻",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "atacar; ataque",
    exemplos: [
      {
        kanji: "攻撃",
        furigana: "こうげき",
        romaji: "kougeki",
        traducao: "ataque",
      },
      {
        kanji: "攻略",
        furigana: "こうりゃく",
        romaji: "kouryaku",
        traducao: "estratégia; conquista",
      },
      {
        kanji: "攻勢",
        furigana: "こうせい",
        romaji: "kousei",
        traducao: "ofensiva",
      },
    ],
  },
  {
    kanji: "崎",
    onYomi: [],
    kunYomi: [{ hiragana: "さき", romaji: "saki" }],
    significado: "cabo; promontório",
    exemplos: [
      {
        kanji: "函館崎",
        furigana: "はこだてざき",
        romaji: "Hakodatezaki",
        traducao: "Promontório de Hakodate",
      },
      {
        kanji: "釜石崎",
        furigana: "かまいしざき",
        romaji: "Kamaishizaki",
        traducao: "Promontório de Kamaishi",
      },
      {
        kanji: "高崎",
        furigana: "たかさき",
        romaji: "Takasaki",
        traducao: "Takasaki (cidade no Japão)",
      },
    ],
  },
  {
    kanji: "督",
    onYomi: [{ katakana: "トク", romaji: "toku" }],
    kunYomi: [],
    significado: "supervisor; monitor",
    exemplos: [
      {
        kanji: "監督",
        furigana: "かんとく",
        romaji: "kantoku",
        traducao: "diretor; supervisor",
      },
      {
        kanji: "指導",
        furigana: "しどう",
        romaji: "shidou",
        traducao: "orientação; guia",
      },
      {
        kanji: "督促",
        furigana: "とくそく",
        romaji: "tokusoku",
        traducao: "lembrete; notificação",
      },
    ],
  },
  {
    kanji: "授",
    onYomi: [{ katakana: "ジュ", romaji: "ju" }],
    kunYomi: [{ hiragana: "さず.ける", romaji: "sazu.keru" }],
    significado: "conceder; ensinar",
    exemplos: [
      {
        kanji: "授業",
        furigana: "じゅぎょう",
        romaji: "jugyou",
        traducao: "aula",
      },
      {
        kanji: "授与",
        furigana: "じゅよ",
        romaji: "juyo",
        traducao: "concessão; outorga",
      },
      {
        kanji: "指授",
        furigana: "しじゅ",
        romaji: "shiju",
        traducao: "iniciação; orientação",
      },
    ],
  },
  {
    kanji: "催",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "もよう.す", romaji: "moyou.su" }],
    significado: "realizar; promover",
    exemplos: [
      {
        kanji: "催促",
        furigana: "さいそく",
        romaji: "saisoku",
        traducao: "lembrete; solicitação",
      },
      {
        kanji: "開催",
        furigana: "かいさい",
        romaji: "kaisai",
        traducao: "realização; realização de um evento",
      },
      {
        kanji: "催眠",
        furigana: "さいみん",
        romaji: "saimin",
        traducao: "hipnose",
      },
    ],
  },
  {
    kanji: "及",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "およ.ぶ", romaji: "oyo.bu" }],
    significado: "alcançar; atingir",
    exemplos: [
      {
        kanji: "及第",
        furigana: "きゅうだい",
        romaji: "kyuudai",
        traducao: "passar; ser aprovado",
      },
      {
        kanji: "及ぶ",
        furigana: "およぶ",
        romaji: "oyobu",
        traducao: "alcançar; abranger",
      },
      {
        kanji: "及時",
        furigana: "きゅうじ",
        romaji: "kyuuji",
        traducao: "oportuno; pontual",
      },
    ],
  },
  {
    kanji: "憲",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [],
    significado: "constituição; lei",
    exemplos: [
      {
        kanji: "憲法",
        furigana: "けんぽう",
        romaji: "kenpou",
        traducao: "constituição",
      },
      {
        kanji: "憲章",
        furigana: "けんしょう",
        romaji: "kenshou",
        traducao: "carta; declaração",
      },
      {
        kanji: "立憲",
        furigana: "りっけん",
        romaji: "rikken",
        traducao: "constitucional",
      },
    ],
  },
  {
    kanji: "離",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [{ hiragana: "はな.れる", romaji: "hana.reru" }],
    significado: "separar; afastar",
    exemplos: [
      {
        kanji: "離婚",
        furigana: "りこん",
        romaji: "rikon",
        traducao: "divórcio",
      },
      {
        kanji: "離脱",
        furigana: "りだつ",
        romaji: "ridatsu",
        traducao: "retirada",
      },
      {
        kanji: "分離",
        furigana: "ぶんり",
        romaji: "bunri",
        traducao: "separação",
      },
    ],
  },
  {
    kanji: "激",
    onYomi: [{ katakana: "ゲキ", romaji: "geki" }],
    kunYomi: [],
    significado: "intenso; violento",
    exemplos: [
      {
        kanji: "激しい",
        furigana: "はげしい",
        romaji: "hageshii",
        traducao: "intenso; violento",
      },
      {
        kanji: "激化",
        furigana: "げきか",
        romaji: "gekika",
        traducao: "intensificação",
      },
      {
        kanji: "激戦",
        furigana: "げきせん",
        romaji: "gekisen",
        traducao: "batalha intensa",
      },
    ],
  },
  {
    kanji: "摘",
    onYomi: [{ katakana: "テキ", romaji: "teki" }],
    kunYomi: [{ hiragana: "つ.む", romaji: "tsu.mu" }],
    significado: "colher; arrancar",
    exemplos: [
      {
        kanji: "摘要",
        furigana: "てきよう",
        romaji: "tekiyou",
        traducao: "resumo",
      },
      {
        kanji: "選摘",
        furigana: "せんてき",
        romaji: "senteki",
        traducao: "seleção",
      },
      {
        kanji: "摘発",
        furigana: "てきはつ",
        romaji: "tekihatsu",
        traducao: "revelar; expor",
      },
    ],
  },
  {
    kanji: "系",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [],
    significado: "sistema; linhagem",
    exemplos: [
      {
        kanji: "系統",
        furigana: "けいとう",
        romaji: "keitou",
        traducao: "sistema; linha",
      },
      {
        kanji: "家系",
        furigana: "かけい",
        romaji: "kakei",
        traducao: "linhagem familiar",
      },
      {
        kanji: "系図",
        furigana: "けいず",
        romaji: "keizu",
        traducao: "árvore genealógica",
      },
    ],
  },
  {
    kanji: "批",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [],
    significado: "crítica; comentário",
    exemplos: [
      {
        kanji: "批判",
        furigana: "ひはん",
        romaji: "hihan",
        traducao: "crítica",
      },
      {
        kanji: "批評",
        furigana: "ひひょう",
        romaji: "hihyou",
        traducao: "resenha; crítica",
      },
      {
        kanji: "批准",
        furigana: "ひじゅん",
        romaji: "hijun",
        traducao: "ratificação; aprovação",
      },
    ],
  },
  {
    kanji: "郎",
    onYomi: [{ katakana: "ロウ", romaji: "rou" }],
    kunYomi: [],
    significado: "jovem; rapaz",
    exemplos: [
      {
        kanji: "少年",
        furigana: "しょうねん",
        romaji: "shounen",
        traducao: "menino; jovem",
      },
      {
        kanji: "青年",
        furigana: "せいねん",
        romaji: "seinen",
        traducao: "jovem",
      },
      {
        kanji: "若者",
        furigana: "わかもの",
        romaji: "wakamono",
        traducao: "jovem",
      },
    ],
  },
  {
    kanji: "健",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [],
    significado: "saúde; vigor",
    exemplos: [
      {
        kanji: "健康",
        furigana: "けんこう",
        romaji: "kenkou",
        traducao: "saúde",
      },
      {
        kanji: "健在",
        furigana: "けんざい",
        romaji: "kenzai",
        traducao: "estar bem; estar ativo",
      },
      {
        kanji: "元気",
        furigana: "げんき",
        romaji: "genki",
        traducao: "energia; saúde",
      },
    ],
  },
  {
    kanji: "盟",
    onYomi: [{ katakana: "メイ", romaji: "mei" }],
    kunYomi: [],
    significado: "aliança; pacto",
    exemplos: [
      {
        kanji: "同盟",
        furigana: "どうめい",
        romaji: "doumei",
        traducao: "aliança",
      },
      {
        kanji: "連盟",
        furigana: "れんめい",
        romaji: "renmei",
        traducao: "federação",
      },
      {
        kanji: "盟約",
        furigana: "めいやく",
        romaji: "meiyaku",
        traducao: "pacto; acordo",
      },
    ],
  },
  {
    kanji: "従",
    onYomi: [{ katakana: "ジュウ", romaji: "juu" }],
    kunYomi: [{ hiragana: "したが.う", romaji: "shitaga.u" }],
    significado: "seguir; obedecer",
    exemplos: [
      {
        kanji: "従業",
        furigana: "じゅうぎょう",
        romaji: "juugyou",
        traducao: "emprego; ocupação",
      },
      {
        kanji: "従事",
        furigana: "じゅうじ",
        romaji: "juuji",
        traducao: "se dedicar; se engajar",
      },
      {
        kanji: "従兄弟",
        furigana: "いとこ",
        romaji: "itoko",
        traducao: "primo",
      },
    ],
  },
  {
    kanji: "修",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [{ hiragana: "おさ.める", romaji: "osa.meru" }],
    significado: "estudar; corrigir",
    exemplos: [
      {
        kanji: "修学",
        furigana: "しゅうがく",
        romaji: "shuugaku",
        traducao: "estudo",
      },
      {
        kanji: "修練",
        furigana: "しゅうれん",
        romaji: "shuuren",
        traducao: "treinamento",
      },
      {
        kanji: "修正",
        furigana: "しゅうせい",
        romaji: "shuusei",
        traducao: "correção",
      },
    ],
  },
  {
    kanji: "隊",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [],
    significado: "esquadrão; equipe",
    exemplos: [
      {
        kanji: "部隊",
        furigana: "ぶたい",
        romaji: "butai",
        traducao: "unidade; tropa",
      },
      {
        kanji: "派遣隊",
        furigana: "はけんたい",
        romaji: "hakentai",
        traducao: "equipe de envio",
      },
      {
        kanji: "警察隊",
        furigana: "けいさつたい",
        romaji: "keisatsutai",
        traducao: "equipe da polícia",
      },
    ],
  },
  {
    kanji: "織",
    onYomi: [{ katakana: "ショク", romaji: "shoku" }],
    kunYomi: [{ hiragana: "お.る", romaji: "o.ru" }],
    significado: "tecer; tecido",
    exemplos: [
      {
        kanji: "織物",
        furigana: "おりもの",
        romaji: "orimono",
        traducao: "tecido; material",
      },
      {
        kanji: "織機",
        furigana: "しょっき",
        romaji: "shokki",
        traducao: "tear",
      },
      {
        kanji: "織姫",
        furigana: "おりひめ",
        romaji: "Orihime",
        traducao: "Orihime (personagem mitológica)",
      },
    ],
  },
  {
    kanji: "拡",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "ひろ.がる", romaji: "hiro.garu" }],
    significado: "expandir; ampliar",
    exemplos: [
      {
        kanji: "拡大",
        furigana: "かくだい",
        romaji: "kakudai",
        traducao: "expansão",
      },
      {
        kanji: "拡張",
        furigana: "かくちょう",
        romaji: "kakuchou",
        traducao: "extensão",
      },
      {
        kanji: "拡充",
        furigana: "かくじゅう",
        romaji: "kakujuu",
        traducao: "expansão; fortalecimento",
      },
    ],
  },
  {
    kanji: "故",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "ゆえ", romaji: "yue" }],
    significado: "incidente; razão",
    exemplos: [
      {
        kanji: "事故",
        furigana: "じこ",
        romaji: "jiko",
        traducao: "acidente",
      },
      {
        kanji: "故障",
        furigana: "こしょう",
        romaji: "koshou",
        traducao: "falha; avaria",
      },
      {
        kanji: "故意",
        furigana: "こい",
        romaji: "koi",
        traducao: "intencional",
      },
    ],
  },
  {
    kanji: "振",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "ふ.る", romaji: "fu.ru" }],
    significado: "agitar; balançar",
    exemplos: [
      {
        kanji: "振動",
        furigana: "しんどう",
        romaji: "shindou",
        traducao: "vibração",
      },
      {
        kanji: "振舞う",
        furigana: "ふるまう",
        romaji: "furu.mau",
        traducao: "comportar-se",
      },
      {
        kanji: "振り返る",
        furigana: "ふりかえる",
        romaji: "furi.kaeru",
        traducao: "olhar para trás",
      },
    ],
  },
  {
    kanji: "弁",
    onYomi: [{ katakana: "ベン", romaji: "ben" }],
    kunYomi: [],
    significado: "válvula; discurso",
    exemplos: [
      {
        kanji: "弁護",
        furigana: "べんご",
        romaji: "bengo",
        traducao: "defesa; advocacia",
      },
      {
        kanji: "弁解",
        furigana: "べんかい",
        romaji: "benkai",
        traducao: "explicação; desculpa",
      },
      {
        kanji: "弁明",
        furigana: "べんめい",
        romaji: "benmei",
        traducao: "justificação; esclarecimento",
      },
    ],
  },
  {
    kanji: "就",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [{ hiragana: "つ.く", romaji: "tsu.ku" }],
    significado: "assumir; entrar em",
    exemplos: [
      {
        kanji: "就職",
        furigana: "しゅうしょく",
        romaji: "shuushoku",
        traducao: "emprego",
      },
      {
        kanji: "就学",
        furigana: "しゅうがく",
        romaji: "shuugaku",
        traducao: "frequentar a escola",
      },
      {
        kanji: "就寝",
        furigana: "しゅうしん",
        romaji: "shuushin",
        traducao: "ir para a cama",
      },
    ],
  },
  {
    kanji: "異",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "こと", romaji: "koto" }],
    significado: "diferente; incomum",
    exemplos: [
      {
        kanji: "異常",
        furigana: "いじょう",
        romaji: "ijou",
        traducao: "anormalidade",
      },
      {
        kanji: "異国",
        furigana: "いこく",
        romaji: "ikoku",
        traducao: "país estrangeiro",
      },
      {
        kanji: "異例",
        furigana: "いれい",
        romaji: "irei",
        traducao: "exceção",
      },
    ],
  },
  {
    kanji: "献",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "たてまつ.る", romaji: "tatematsu.ru" }],
    significado: "oferecer; dedicar",
    exemplos: [
      {
        kanji: "献身",
        furigana: "けんしん",
        romaji: "kenshin",
        traducao: "dedicação",
      },
      {
        kanji: "献金",
        furigana: "けんきん",
        romaji: "kenkin",
        traducao: "doação; contribuição",
      },
      {
        kanji: "奉献",
        furigana: "ほうけん",
        romaji: "houken",
        traducao: "dedicação; oferta",
      },
    ],
  },
  {
    kanji: "厳",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [{ hiragana: "きび.しい", romaji: "kibi.shii" }],
    significado: "severo; rigoroso",
    exemplos: [
      {
        kanji: "厳重",
        furigana: "げんじゅう",
        romaji: "genjuu",
        traducao: "rigoroso",
      },
      {
        kanji: "厳格",
        furigana: "げんかく",
        romaji: "genkaku",
        traducao: "estrito; rígido",
      },
      {
        kanji: "厳密",
        furigana: "げんみつ",
        romaji: "genmitsu",
        traducao: "preciso; rigoroso",
      },
    ],
  },
  {
    kanji: "維",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [],
    significado: "manter; preservar",
    exemplos: [
      {
        kanji: "維持",
        furigana: "いじ",
        romaji: "iji",
        traducao: "manutenção; preservação",
      },
      {
        kanji: "維新",
        furigana: "いしん",
        romaji: "ishin",
        traducao: "restauração",
      },
      {
        kanji: "維持する",
        furigana: "いじする",
        romaji: "iji suru",
        traducao: "manter; preservar",
      },
    ],
  },
  {
    kanji: "浜",
    onYomi: [{ katakana: "ヒン", romaji: "hin" }],
    kunYomi: [{ hiragana: "はま", romaji: "hama" }],
    significado: "praia",
    exemplos: [
      {
        kanji: "海浜",
        furigana: "かいひん",
        romaji: "kaihin",
        traducao: "praia; costa",
      },
      {
        kanji: "浜辺",
        furigana: "はまべ",
        romaji: "hamabe",
        traducao: "praia",
      },
      {
        kanji: "湖畔",
        furigana: "こはん",
        romaji: "kohan",
        traducao: "margem do lago",
      },
    ],
  },
  {
    kanji: "遺",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "のこ.す", romaji: "noko.su" }],
    significado: "deixar para trás; legado",
    exemplos: [
      {
        kanji: "遺産",
        furigana: "いさん",
        romaji: "isan",
        traducao: "herança; patrimônio",
      },
      {
        kanji: "遺言",
        furigana: "ゆいごん",
        romaji: "yuigon",
        traducao: "testamento",
      },
      {
        kanji: "遺跡",
        furigana: "いせき",
        romaji: "iseki",
        traducao: "sítio arqueológico",
      },
    ],
  },
  {
    kanji: "塁",
    onYomi: [{ katakana: "ルイ", romaji: "rui" }],
    kunYomi: [],
    significado: "base; trincheira",
    exemplos: [
      {
        kanji: "塁",
        furigana: "るい",
        romaji: "rui",
        traducao: "base (esportes)",
      },
      {
        kanji: "砦",
        furigana: "とりで",
        romaji: "toride",
        traducao: "fortaleza",
      },
      {
        kanji: "救急救命",
        furigana: "きゅうきゅうきゅうめい",
        romaji: "kyuukyuukyuumei",
        traducao: "primeiros socorros",
      },
    ],
  },
  {
    kanji: "邦",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [],
    significado: "nacional; país",
    exemplos: [
      {
        kanji: "日本邦",
        furigana: "にほんほう",
        romaji: "nihonhou",
        traducao: "Japão",
      },
      {
        kanji: "和邦",
        furigana: "わほう",
        romaji: "wahou",
        traducao: "Japão",
      },
      {
        kanji: "本邦",
        furigana: "ほんぽう",
        romaji: "honpou",
        traducao: "Japão",
      },
    ],
  },
  {
    kanji: "素",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [{ hiragana: "もと", romaji: "moto" }],
    significado: "elemento; ingrediente",
    exemplos: [
      {
        kanji: "素材",
        furigana: "そざい",
        romaji: "sozai",
        traducao: "material",
      },
      {
        kanji: "素晴らしい",
        furigana: "すばらしい",
        romaji: "subarashii",
        traducao: "maravilhoso",
      },
      {
        kanji: "基本素質",
        furigana: "きほんそしつ",
        romaji: "kihonsoshitsu",
        traducao: "habilidades básicas",
      },
    ],
  },
  {
    kanji: "遣",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "つか.う", romaji: "tsuka.u" }],
    significado: "enviar; empregar",
    exemplos: [
      {
        kanji: "派遣",
        furigana: "はけん",
        romaji: "haken",
        traducao: "envio; despacho",
      },
      {
        kanji: "使い遣る",
        furigana: "つかいやる",
        romaji: "tsukaiyaru",
        traducao: "usar; empregar",
      },
      {
        kanji: "遣わす",
        furigana: "つかわす",
        romaji: "tsukawasu",
        traducao: "enviar; despachar",
      },
    ],
  },
  {
    kanji: "抗",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "resistir; opor-se",
    exemplos: [
      {
        kanji: "抗議",
        furigana: "こうぎ",
        romaji: "kougi",
        traducao: "protesto; objeção",
      },
      {
        kanji: "抗生物質",
        furigana: "こうせいぶっしつ",
        romaji: "kouseibutsushitsu",
        traducao: "antibiótico",
      },
      {
        kanji: "抗争",
        furigana: "こうそう",
        romaji: "kousou",
        traducao: "conflito; disputa",
      },
    ],
  },
  {
    kanji: "模",
    onYomi: [{ katakana: "モ", romaji: "mo" }],
    kunYomi: [],
    significado: "modelo; imitação",
    exemplos: [
      {
        kanji: "模範",
        furigana: "もはん",
        romaji: "mohan",
        traducao: "modelo; padrão",
      },
      {
        kanji: "模型",
        furigana: "もけい",
        romaji: "mokei",
        traducao: "modelo; maquete",
      },
      {
        kanji: "模様",
        furigana: "もよう",
        romaji: "moyou",
        traducao: "padrão; design",
      },
    ],
  },
  {
    kanji: "雄",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [{ hiragana: "お", romaji: "o" }],
    significado: "macho; herói",
    exemplos: [
      {
        kanji: "雄大",
        furigana: "ゆうだい",
        romaji: "yuudai",
        traducao: "majestoso",
      },
      {
        kanji: "雄飛",
        furigana: "ゆうひ",
        romaji: "yuubi",
        traducao: "bem-sucedido; esplêndido",
      },
      {
        kanji: "雄弁",
        furigana: "ゆうべん",
        romaji: "yuuben",
        traducao: "eloquente",
      },
    ],
  },
  {
    kanji: "益",
    onYomi: [
      { katakana: "エキ", romaji: "eki" },
      { katakana: "ヤク", romaji: "yaku" },
    ],
    kunYomi: [{ hiragana: "ます", romaji: "masu" }],
    significado: "benefício; lucro",
    exemplos: [
      {
        kanji: "利益",
        furigana: "りえき",
        romaji: "rieki",
        traducao: "lucro",
      },
      {
        kanji: "増益",
        furigana: "ぞうえき",
        romaji: "zoueki",
        traducao: "aumento de lucro",
      },
      {
        kanji: "益する",
        furigana: "えきする",
        romaji: "eki suru",
        traducao: "beneficiar; ser útil",
      },
    ],
  },
  {
    kanji: "緊",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [],
    significado: "tensão; aperto",
    exemplos: [
      {
        kanji: "緊急",
        furigana: "きんきゅう",
        romaji: "kinkyuu",
        traducao: "urgente; emergência",
      },
      {
        kanji: "緊迫",
        furigana: "きんぱく",
        romaji: "kinpaku",
        traducao: "tensão; pressão",
      },
      {
        kanji: "緊張",
        furigana: "きんちょう",
        romaji: "kinchou",
        traducao: "tensão; nervosismo",
      },
    ],
  },
  {
    kanji: "標",
    onYomi: [{ katakana: "ヒョウ", romaji: "hyou" }],
    kunYomi: [{ hiragana: "しるべ", romaji: "shirube" }],
    significado: "sinal; marcador",
    exemplos: [
      {
        kanji: "目標",
        furigana: "もくひょう",
        romaji: "mokuhyou",
        traducao: "meta; objetivo",
      },
      {
        kanji: "標識",
        furigana: "ひょうしき",
        romaji: "hyoushiki",
        traducao: "sinal; placa",
      },
      {
        kanji: "基準",
        furigana: "きじゅん",
        romaji: "kijun",
        traducao: "padrão; critério",
      },
    ],
  },
  {
    kanji: "宣",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "のたま.う", romaji: "notama.u" }],
    significado: "proclamar; anunciar",
    exemplos: [
      {
        kanji: "宣言",
        furigana: "せんげん",
        romaji: "sengen",
        traducao: "declaração",
      },
      {
        kanji: "宣伝",
        furigana: "せんでん",
        romaji: "senden",
        traducao: "propaganda",
      },
      {
        kanji: "宣誓",
        furigana: "せんせい",
        romaji: "sensei",
        traducao: "juramento",
      },
    ],
  },
  {
    kanji: "昭",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "brilhante; ilustre",
    exemplos: [
      {
        kanji: "昭和",
        furigana: "しょうわ",
        romaji: "shouwa",
        traducao: "Era Shouwa",
      },
      {
        kanji: "昭明",
        furigana: "しょうめい",
        romaji: "shoumei",
        traducao: "clareza; brilhante",
      },
      {
        kanji: "昭彰",
        furigana: "しょうしょう",
        romaji: "shoushou",
        traducao: "evidente; distinto",
      },
    ],
  },
  {
    kanji: "廃",
    onYomi: [{ katakana: "ハイ", romaji: "hai" }],
    kunYomi: [{ hiragana: "すた.れる", romaji: "suta.reru" }],
    significado: "abandonar; abolir",
    exemplos: [
      {
        kanji: "廃止",
        furigana: "はいし",
        romaji: "haishi",
        traducao: "abolir; abolição",
      },
      {
        kanji: "廃墟",
        furigana: "はいきょ",
        romaji: "haikyo",
        traducao: "ruína",
      },
      {
        kanji: "廃棄",
        furigana: "はいき",
        romaji: "haiki",
        traducao: "descartar; eliminação",
      },
    ],
  },
  {
    kanji: "伊",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [],
    significado: "Itália",
    exemplos: [
      {
        kanji: "伊勢",
        furigana: "いせ",
        romaji: "ise",
        traducao: "Ise (lugar no Japão)",
      },
      {
        kanji: "伊達",
        furigana: "だて",
        romaji: "date",
        traducao: "cool; elegante",
      },
      {
        kanji: "伊藤",
        furigana: "いとう",
        romaji: "itou",
        traducao: "Ito (sobrenome japonês)",
      },
    ],
  },
  {
    kanji: "江",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "え", romaji: "e" }],
    significado: "rio",
    exemplos: [
      {
        kanji: "江戸",
        furigana: "えど",
        romaji: "edo",
        traducao: "Edo (antigo nome de Tóquio)",
      },
      {
        kanji: "河江",
        furigana: "こう",
        romaji: "kou",
        traducao: "Kou (nome próprio)",
      },
      {
        kanji: "三津江",
        furigana: "みつえ",
        romaji: "mitsue",
        traducao: "Mitsue (nome próprio)",
      },
    ],
  },
  {
    kanji: "僚",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [],
    significado: "colega",
    exemplos: [
      {
        kanji: "同僚",
        furigana: "どうりょう",
        romaji: "douryou",
        traducao: "colega de trabalho",
      },
      {
        kanji: "友僚",
        furigana: "ゆうりょう",
        romaji: "yuuryou",
        traducao: "amigo; companheiro",
      },
      {
        kanji: "職僚",
        furigana: "しょくりょう",
        romaji: "shokuryou",
        traducao: "funcionário público",
      },
    ],
  },
  {
    kanji: "吉",
    onYomi: [{ katakana: "キチ", romaji: "kichi" }],
    kunYomi: [{ hiragana: "よし", romaji: "yoshi" }],
    significado: "sorte; bom",
    exemplos: [
      {
        kanji: "吉報",
        furigana: "きっぽう",
        romaji: "kippou",
        traducao: "boas notícias",
      },
      {
        kanji: "吉日",
        furigana: "きちじつ",
        romaji: "kichijitsu",
        traducao: "dia de sorte",
      },
      {
        kanji: "吉兆",
        furigana: "きっちょう",
        romaji: "kicchou",
        traducao: "sinal de boa sorte",
      },
    ],
  },
  {
    kanji: "盛",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "も.る", romaji: "mo.ru" }],
    significado: "crescer; florescer",
    exemplos: [
      {
        kanji: "盛大",
        furigana: "せいだい",
        romaji: "seidai",
        traducao: "grande; esplêndido",
      },
      {
        kanji: "盛況",
        furigana: "せいきょう",
        romaji: "seikyou",
        traducao: "prosperidade; sucesso",
      },
      {
        kanji: "盛り上がる",
        furigana: "もりあがる",
        romaji: "moriagaru",
        traducao: "animar-se; empolgar-se",
      },
    ],
  },
  {
    kanji: "皇",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "imperador",
    exemplos: [
      {
        kanji: "天皇",
        furigana: "てんのう",
        romaji: "tennou",
        traducao: "imperador",
      },
      {
        kanji: "皇后",
        furigana: "こうごう",
        romaji: "kougou",
        traducao: "imperatriz",
      },
      {
        kanji: "皇族",
        furigana: "こうぞく",
        romaji: "kouzoku",
        traducao: "família imperial",
      },
    ],
  },
  {
    kanji: "臨",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [{ hiragana: "のぞ.む", romaji: "nozo.mu" }],
    significado: "encarar; aproximar-se",
    exemplos: [
      {
        kanji: "臨時",
        furigana: "りんじ",
        romaji: "rinji",
        traducao: "temporário",
      },
      {
        kanji: "臨床",
        furigana: "りんしょう",
        romaji: "rinshou",
        traducao: "clínico",
      },
      {
        kanji: "臨機応変",
        furigana: "りんきおうへん",
        romaji: "rinkiouhen",
        traducao: "adaptação às circunstâncias",
      },
    ],
  },
  {
    kanji: "踏",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "ふ.む", romaji: "fu.mu" }],
    significado: "pisar; caminhar",
    exemplos: [
      {
        kanji: "踏む",
        furigana: "ふむ",
        romaji: "fumu",
        traducao: "pisar; caminhar",
      },
      {
        kanji: "踏切",
        furigana: "ふみきり",
        romaji: "fumikiri",
        traducao: "cruzamento ferroviário",
      },
      {
        kanji: "踏み出す",
        furigana: "ふみだす",
        romaji: "fumidasu",
        traducao: "dar um passo adiante",
      },
    ],
  },
  {
    kanji: "壊",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "こわ.す", romaji: "kowa.su" }],
    significado: "quebrar; destruir",
    exemplos: [
      {
        kanji: "破壊",
        furigana: "はかい",
        romaji: "hakai",
        traducao: "destruição",
      },
      {
        kanji: "崩壊",
        furigana: "ほうかい",
        romaji: "houkai",
        traducao: "colapso",
      },
      {
        kanji: "解壊",
        furigana: "かいかい",
        romaji: "kaikai",
        traducao: "desmontar; desfazer",
      },
    ],
  },
  {
    kanji: "債",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [],
    significado: "dívida",
    exemplos: [
      {
        kanji: "債務",
        furigana: "さいむ",
        romaji: "saimu",
        traducao: "dívida; obrigação",
      },
      {
        kanji: "国債",
        furigana: "こくさい",
        romaji: "kokusai",
        traducao: "título do governo",
      },
      {
        kanji: "債権",
        furigana: "さいけん",
        romaji: "saiken",
        traducao: "crédito; direito de crédito",
      },
    ],
  },
  {
    kanji: "興",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "おこ.る", romaji: "oko.ru" }],
    significado: "interesse; animação",
    exemplos: [
      {
        kanji: "興味",
        furigana: "きょうみ",
        romaji: "kyoumi",
        traducao: "interesse",
      },
      {
        kanji: "振興",
        furigana: "しんこう",
        romaji: "shinkou",
        traducao: "promoção; incentivo",
      },
      {
        kanji: "興奮",
        furigana: "こうふん",
        romaji: "koufun",
        traducao: "excitação; agitação",
      },
    ],
  },
  {
    kanji: "源",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [{ hiragana: "みなもと", romaji: "minamoto" }],
    significado: "fonte; origem",
    exemplos: [
      {
        kanji: "水源",
        furigana: "すいげん",
        romaji: "suigen",
        traducao: "fonte de água",
      },
      {
        kanji: "源泉",
        furigana: "げんせん",
        romaji: "gensen",
        traducao: "fonte; manancial",
      },
      {
        kanji: "起源",
        furigana: "きげん",
        romaji: "kigen",
        traducao: "origem",
      },
    ],
  },
  {
    kanji: "儀",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [],
    significado: "cerimônia; ritual",
    exemplos: [
      {
        kanji: "儀式",
        furigana: "ぎしき",
        romaji: "gishiki",
        traducao: "cerimônia",
      },
      {
        kanji: "儀礼",
        furigana: "ぎれい",
        romaji: "girei",
        traducao: "etiqueta; formalidade",
      },
      {
        kanji: "儀式的",
        furigana: "ぎしきてき",
        romaji: "gishikiteki",
        traducao: "cerimonial",
      },
    ],
  },
  {
    kanji: "創",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "つく.る", romaji: "tsuku.ru" }],
    significado: "criar; fundar",
    exemplos: [
      {
        kanji: "創造",
        furigana: "そうぞう",
        romaji: "souzou",
        traducao: "criação; criação artística",
      },
      {
        kanji: "創業",
        furigana: "そうぎょう",
        romaji: "sougyou",
        traducao: "fundação de uma empresa",
      },
      {
        kanji: "創作",
        furigana: "そうさく",
        romaji: "sousaku",
        traducao: "criação; produção artística",
      },
    ],
  },
  {
    kanji: "障",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "さわ.る", romaji: "sawa.ru" }],
    significado: "obstáculo; impedimento",
    exemplos: [
      {
        kanji: "障害",
        furigana: "しょうがい",
        romaji: "shougai",
        traducao: "deficiência; impedimento",
      },
      {
        kanji: "障壁",
        furigana: "しょうへき",
        romaji: "shouheki",
        traducao: "barreira; obstáculo",
      },
      {
        kanji: "障子",
        furigana: "しょうじ",
        romaji: "shouji",
        traducao: "porta de correr; divisória",
      },
    ],
  },
  {
    kanji: "継",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "つ.ぐ", romaji: "tsu.gu" }],
    significado: "herdar; suceder",
    exemplos: [
      {
        kanji: "継続",
        furigana: "けいぞく",
        romaji: "keizoku",
        traducao: "continuação; persistência",
      },
      {
        kanji: "継承",
        furigana: "けいしょう",
        romaji: "keishou",
        traducao: "herança; sucessão",
      },
      {
        kanji: "相継",
        furigana: "あいつぎ",
        romaji: "aitsugi",
        traducao: "sucessivamente",
      },
    ],
  },
  {
    kanji: "筋",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [{ hiragana: "すじ", romaji: "suji" }],
    significado: "músculo; linha",
    exemplos: [
      {
        kanji: "筋肉",
        furigana: "きんにく",
        romaji: "kinniku",
        traducao: "músculo",
      },
      {
        kanji: "筋道",
        furigana: "すじみち",
        romaji: "suji michi",
        traducao: "lógica; coerência",
      },
      {
        kanji: "血筋",
        furigana: "ちすじ",
        romaji: "chisuzuji",
        traducao: "linhagem; ascendência",
      },
    ],
  },
  {
    kanji: "闘",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "たたか.う", romaji: "tataka.u" }],
    significado: "lutar; combater",
    exemplos: [
      {
        kanji: "闘争",
        furigana: "とうそう",
        romaji: "tousou",
        traducao: "luta; conflito",
      },
      {
        kanji: "戦闘",
        furigana: "せんとう",
        romaji: "sentou",
        traducao: "batalha; combate",
      },
      {
        kanji: "闘志",
        furigana: "とうし",
        romaji: "toushi",
        traducao: "espírito de luta",
      },
    ],
  },
  {
    kanji: "葬",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "ほうむ.る", romaji: "houmu.ru" }],
    significado: "funeral; enterrar",
    exemplos: [
      {
        kanji: "葬儀",
        furigana: "そうぎ",
        romaji: "sougi",
        traducao: "funeral",
      },
      {
        kanji: "埋葬",
        furigana: "まいそう",
        romaji: "maisou",
        traducao: "sepultamento",
      },
      {
        kanji: "葬式",
        furigana: "そうしき",
        romaji: "soushiki",
        traducao: "cerimônia fúnebre",
      },
    ],
  },
  {
    kanji: "避",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "さ.ける", romaji: "sa.keru" }],
    significado: "evitar; escapar",
    exemplos: [
      {
        kanji: "回避",
        furigana: "かいひ",
        romaji: "kaihi",
        traducao: "evitar; evitar",
      },
      {
        kanji: "防避",
        furigana: "ぼうひ",
        romaji: "bouhi",
        traducao: "prevenção; evitar",
      },
      {
        kanji: "避難",
        furigana: "ひなん",
        romaji: "hinan",
        traducao: "evacuação; refúgio",
      },
    ],
  },
  {
    kanji: "司",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "juiz; administrador",
    exemplos: [
      {
        kanji: "裁司",
        furigana: "さいし",
        romaji: "saishi",
        traducao: "juiz",
      },
      {
        kanji: "司会",
        furigana: "しかい",
        romaji: "shikai",
        traducao: "moderador; apresentador",
      },
      {
        kanji: "管理司",
        furigana: "かんりし",
        romaji: "kanrishi",
        traducao: "administrador",
      },
    ],
  },
  {
    kanji: "康",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "saúde; paz",
    exemplos: [
      {
        kanji: "健康",
        furigana: "けんこう",
        romaji: "kenkou",
        traducao: "saúde",
      },
      {
        kanji: "安康",
        furigana: "あんこう",
        romaji: "ankou",
        traducao: "saúde e segurança",
      },
      {
        kanji: "康徳",
        furigana: "こうとく",
        romaji: "koutoku",
        traducao: "paz e virtude",
      },
    ],
  },
  {
    kanji: "善",
    onYomi: [{ katakana: "ゼン", romaji: "zen" }],
    kunYomi: [{ hiragana: "よ.い", romaji: "yo.i" }],
    significado: "bom; virtuoso",
    exemplos: [
      {
        kanji: "善行",
        furigana: "ぜんこう",
        romaji: "zenkou",
        traducao: "ação virtuosa",
      },
      {
        kanji: "善意",
        furigana: "ぜんい",
        romaji: "zen'i",
        traducao: "boa vontade",
      },
      {
        kanji: "善人",
        furigana: "ぜんにん",
        romaji: "zennin",
        traducao: "pessoa boa",
      },
    ],
  },
  {
    kanji: "逮",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [],
    significado: "prender; capturar",
    exemplos: [
      {
        kanji: "逮捕",
        furigana: "たいほ",
        romaji: "taiho",
        traducao: "prisão; captura",
      },
      {
        kanji: "逮捕状",
        furigana: "たいほじょう",
        romaji: "taihojou",
        traducao: "mandado de prisão",
      },
      {
        kanji: "逮捕者",
        furigana: "たいほしゃ",
        romaji: "taihosha",
        traducao: "pessoa presa",
      },
    ],
  },
  {
    kanji: "迫",
    onYomi: [{ katakana: "ハク", romaji: "haku" }],
    kunYomi: [{ hiragana: "せま.る", romaji: "sema.ru" }],
    significado: "pressionar; ameaçar",
    exemplos: [
      {
        kanji: "迫力",
        furigana: "はくりょく",
        romaji: "hakuryoku",
        traducao: "impacto; força",
      },
      {
        kanji: "迫害",
        furigana: "はくがい",
        romaji: "hakugai",
        traducao: "perseguição",
      },
      {
        kanji: "迫真",
        furigana: "はくしん",
        romaji: "hakushin",
        traducao: "realismo; autenticidade",
      },
    ],
  },
  {
    kanji: "惑",
    onYomi: [{ katakana: "ワク", romaji: "waku" }],
    kunYomi: [{ hiragana: "まど.う", romaji: "mado.u" }],
    significado: "confuso; perturbar",
    exemplos: [
      {
        kanji: "迷惑",
        furigana: "めいわく",
        romaji: "meiwaku",
        traducao: "inconveniente; aborrecimento",
      },
      {
        kanji: "困惑",
        furigana: "こんわく",
        romaji: "konwaku",
        traducao: "confusão; perplexidade",
      },
      {
        kanji: "混惑",
        furigana: "こんわく",
        romaji: "konwaku",
        traducao: "confusão; perplexidade",
      },
    ],
  },
  {
    kanji: "崩",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "くず.れる", romaji: "kuzu.reru" }],
    significado: "desmoronar; colapsar",
    exemplos: [
      {
        kanji: "崩壊",
        furigana: "ほうかい",
        romaji: "houkai",
        traducao: "colapso; desmoronamento",
      },
      {
        kanji: "崩落",
        furigana: "ほうらく",
        romaji: "houraku",
        traducao: "desmoronamento; queda",
      },
      {
        kanji: "崩壊する",
        furigana: "ほうかいする",
        romaji: "houkaisuru",
        traducao: "colapsar; desmoronar",
      },
    ],
  },
  {
    kanji: "紀",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "era; registro",
    exemplos: [
      {
        kanji: "紀元前",
        furigana: "きげんぜん",
        romaji: "kigenzen",
        traducao: "antes da era cristã",
      },
      {
        kanji: "紀要",
        furigana: "きよう",
        romaji: "kiyou",
        traducao: "resumo; registro",
      },
      {
        kanji: "文献紀要",
        furigana: "ぶんけんきよう",
        romaji: "bunkenkiyou",
        traducao: "resumo de literatura",
      },
    ],
  },
  {
    kanji: "聴",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "き.く", romaji: "ki.ku" }],
    significado: "ouvir; escutar",
    exemplos: [
      {
        kanji: "聴衆",
        furigana: "ちょうしゅう",
        romaji: "choushuu",
        traducao: "audiência; público",
      },
      {
        kanji: "聴力",
        furigana: "ちょうりょく",
        romaji: "chouryoku",
        traducao: "audição; capacidade auditiva",
      },
      {
        kanji: "聴覚",
        furigana: "ちょうかく",
        romaji: "choukaku",
        traducao: "audição",
      },
    ],
  },
  {
    kanji: "脱",
    onYomi: [{ katakana: "ダツ", romaji: "datsu" }],
    kunYomi: [{ hiragana: "ぬ.ぐ", romaji: "nu.gu" }],
    significado: "tirar; livrar-se",
    exemplos: [
      {
        kanji: "脱出",
        furigana: "だっしゅつ",
        romaji: "dasshutsu",
        traducao: "fuga; escapar",
      },
      {
        kanji: "脱衣",
        furigana: "だつい",
        romaji: "datsui",
        traducao: "despir-se",
      },
      {
        kanji: "脱退",
        furigana: "だったい",
        romaji: "dattai",
        traducao: "renúncia; retirada",
      },
    ],
  },
  {
    kanji: "級",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [],
    significado: "classe; grau",
    exemplos: [
      {
        kanji: "学級",
        furigana: "がっきゅう",
        romaji: "gakkyuu",
        traducao: "classe escolar",
      },
      {
        kanji: "上級",
        furigana: "じょうきゅう",
        romaji: "joukyuu",
        traducao: "avançado; superior",
      },
      {
        kanji: "初級",
        furigana: "しょきゅう",
        romaji: "shokyuu",
        traducao: "iniciante; básico",
      },
    ],
  },
  {
    kanji: "博",
    onYomi: [{ katakana: "ハク", romaji: "haku" }],
    kunYomi: [],
    significado: "amplo; profundo",
    exemplos: [
      {
        kanji: "博士",
        furigana: "はくし",
        romaji: "hakushi",
        traducao: "doutor; PhD",
      },
      {
        kanji: "学博",
        furigana: "がくはく",
        romaji: "gakuhaku",
        traducao: "doutorado",
      },
      {
        kanji: "博覧",
        furigana: "はくらん",
        romaji: "hakuran",
        traducao: "exposição; visitar",
      },
    ],
  },
  {
    kanji: "締",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "し.まる", romaji: "shi.maru" }],
    significado: "apertar; amarrar",
    exemplos: [
      {
        kanji: "締結",
        furigana: "ていけつ",
        romaji: "teiketsu",
        traducao: "celebrar um contrato",
      },
      {
        kanji: "締切",
        furigana: "しめきり",
        romaji: "shimekiri",
        traducao: "prazo final",
      },
      {
        kanji: "締め付ける",
        furigana: "しめつける",
        romaji: "shimetsukeru",
        traducao: "apertar",
      },
    ],
  },
  {
    kanji: "救",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "すく.う", romaji: "suku.u" }],
    significado: "salvar; resgatar",
    exemplos: [
      {
        kanji: "救助",
        furigana: "きゅうじょ",
        romaji: "kyuujo",
        traducao: "resgate",
      },
      {
        kanji: "救急",
        furigana: "きゅうきゅう",
        romaji: "kyuukyuu",
        traducao: "primeiros socorros",
      },
      {
        kanji: "救世",
        furigana: "きゅうせい",
        romaji: "kyuusei",
        traducao: "salvação",
      },
    ],
  },
  {
    kanji: "執",
    onYomi: [{ katakana: "シツ", romaji: "shitsu" }],
    kunYomi: [{ hiragana: "と.る", romaji: "to.ru" }],
    significado: "segurar; agarrar",
    exemplos: [
      {
        kanji: "執行",
        furigana: "しっこう",
        romaji: "shikkou",
        traducao: "execução",
      },
      {
        kanji: "執筆",
        furigana: "しっぴつ",
        romaji: "shippitsu",
        traducao: "escrever; redigir",
      },
      {
        kanji: "執念",
        furigana: "しゅうねん",
        romaji: "shuunen",
        traducao: "obstinação; determinação",
      },
    ],
  },
  {
    kanji: "房",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "ふさ", romaji: "fusa" }],
    significado: "quarto; ramo",
    exemplos: [
      {
        kanji: "客房",
        furigana: "きゃくぼう",
        romaji: "kyakubou",
        traducao: "quarto de hóspedes",
      },
      {
        kanji: "翼房",
        furigana: "よくぼう",
        romaji: "yokubou",
        traducao: "sala de estar",
      },
      {
        kanji: "抱房",
        furigana: "ほうぼう",
        romaji: "houbou",
        traducao: "ramo; galho",
      },
    ],
  },
  {
    kanji: "撤",
    onYomi: [{ katakana: "テツ", romaji: "tetsu" }],
    kunYomi: [],
    significado: "retirar; remover",
    exemplos: [
      {
        kanji: "撤退",
        furigana: "てったい",
        romaji: "tettai",
        traducao: "retirada; retirar-se",
      },
      {
        kanji: "撤去",
        furigana: "てっきょ",
        romaji: "tekkyo",
        traducao: "remover; desmontar",
      },
      {
        kanji: "撤回",
        furigana: "てっかい",
        romaji: "tekkai",
        traducao: "retirar; retirada",
      },
    ],
  },
  {
    kanji: "削",
    onYomi: [{ katakana: "サク", romaji: "saku" }],
    kunYomi: [{ hiragana: "けず.る", romaji: "kezu.ru" }],
    significado: "cortar; reduzir",
    exemplos: [
      {
        kanji: "削除",
        furigana: "さくじょ",
        romaji: "sakujo",
        traducao: "excluir; remover",
      },
      {
        kanji: "減削",
        furigana: "げんさく",
        romaji: "gensaku",
        traducao: "redução",
      },
      {
        kanji: "削減",
        furigana: "さくげん",
        romaji: "sakugen",
        traducao: "redução; diminuição",
      },
    ],
  },
  {
    kanji: "密",
    onYomi: [{ katakana: "ミツ", romaji: "mitsu" }],
    kunYomi: [{ hiragana: "ひそ.か", romaji: "hiso.ka" }],
    significado: "secreto; denso",
    exemplos: [
      {
        kanji: "密度",
        furigana: "みつど",
        romaji: "mitsudo",
        traducao: "densidade",
      },
      {
        kanji: "秘密",
        furigana: "ひみつ",
        romaji: "himitsu",
        traducao: "segredo",
      },
      {
        kanji: "密輸",
        furigana: "みつゆ",
        romaji: "mitsuyu",
        traducao: "contrabando",
      },
    ],
  },
  {
    kanji: "措",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [{ hiragana: "お.く", romaji: "o.ku" }],
    significado: "colocar; dispor",
    exemplos: [
      {
        kanji: "措置",
        furigana: "そち",
        romaji: "sochi",
        traducao: "medida; providência",
      },
      {
        kanji: "措辞",
        furigana: "そじ",
        romaji: "soji",
        traducao: "formulação; redação",
      },
      {
        kanji: "配置",
        furigana: "はいち",
        romaji: "haichi",
        traducao: "disposição; arranjo",
      },
    ],
  },
  {
    kanji: "志",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "こころざ.す", romaji: "kokorozasu" }],
    significado: "aspiração; vontade",
    exemplos: [
      {
        kanji: "意志",
        furigana: "いし",
        romaji: "ishi",
        traducao: "vontade",
      },
      {
        kanji: "志望",
        furigana: "しぼう",
        romaji: "shibou",
        traducao: "aspiração; desejo",
      },
      {
        kanji: "志向",
        furigana: "しこう",
        romaji: "shikou",
        traducao: "orientação; tendência",
      },
    ],
  },
  {
    kanji: "載",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "の.せる", romaji: "no.seru" }],
    significado: "incluir; publicar",
    exemplos: [
      {
        kanji: "掲載",
        furigana: "けいさい",
        romaji: "keisai",
        traducao: "publicação; inclusão",
      },
      {
        kanji: "載せる",
        furigana: "のせる",
        romaji: "noseru",
        traducao: "incluir; colocar",
      },
      {
        kanji: "載る",
        furigana: "のる",
        romaji: "noru",
        traducao: "ser publicado; ser incluído",
      },
    ],
  },
  {
    kanji: "陣",
    onYomi: [{ katakana: "ジン", romaji: "jin" }],
    kunYomi: [{ hiragana: "じん", romaji: "jin" }],
    significado: "acampamento; formação",
    exemplos: [
      {
        kanji: "陣営",
        furigana: "じんえい",
        romaji: "jinei",
        traducao: "acampamento; base",
      },
      {
        kanji: "陣地",
        furigana: "じんち",
        romaji: "jinch",
        traducao: "posição; território",
      },
      {
        kanji: "前陣",
        furigana: "ぜんじん",
        romaji: "zenjin",
        traducao: "linha de frente",
      },
    ],
  },
  {
    kanji: "我",
    onYomi: [{ katakana: "ガ", romaji: "ga" }],
    kunYomi: [{ hiragana: "われ", romaji: "ware" }],
    significado: "eu; meu",
    exemplos: [
      {
        kanji: "自我",
        furigana: "じが",
        romaji: "jiga",
        traducao: "ego; autoconsciência",
      },
      {
        kanji: "我慢",
        furigana: "がまん",
        romaji: "gaman",
        traducao: "paciente; tolerância",
      },
      {
        kanji: "我が家",
        furigana: "わがや",
        romaji: "wagaya",
        traducao: "minha casa",
      },
    ],
  },
  {
    kanji: "為",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "ため", romaji: "tame" }],
    significado: "para; causa",
    exemplos: [
      {
        kanji: "目的",
        furigana: "もくてき",
        romaji: "mokuteki",
        traducao: "objetivo",
      },
      {
        kanji: "為替",
        furigana: "かわせ",
        romaji: "kawase",
        traducao: "câmbio",
      },
      {
        kanji: "故為",
        furigana: "こため",
        romaji: "kotame",
        traducao: "causa; motivo",
      },
    ],
  },
  {
    kanji: "抑",
    onYomi: [{ katakana: "ヨク", romaji: "yoku" }],
    kunYomi: [{ hiragana: "おさ.える", romaji: "osa.eru" }],
    significado: "restringir; suprimir",
    exemplos: [
      {
        kanji: "抑制",
        furigana: "よくせい",
        romaji: "yokusei",
        traducao: "inibição; controle",
      },
      {
        kanji: "抑圧",
        furigana: "よくあつ",
        romaji: "yokuatsu",
        traducao: "opressão; repressão",
      },
      {
        kanji: "抑制する",
        furigana: "よくせいする",
        romaji: "yokuseisuru",
        traducao: "restringir; suprimir",
      },
    ],
  },
  {
    kanji: "幕",
    onYomi: [{ katakana: "マク", romaji: "maku" }],
    kunYomi: [],
    significado: "cortina; tela",
    exemplos: [
      {
        kanji: "幕末",
        furigana: "ばくまつ",
        romaji: "bakumatsu",
        traducao: "fim do xogunato",
      },
      {
        kanji: "幕布",
        furigana: "まくふ",
        romaji: "makufu",
        traducao: "cortina; pano de fundo",
      },
      {
        kanji: "舞台幕",
        furigana: "ぶたいまく",
        romaji: "butaimaku",
        traducao: "cenário",
      },
    ],
  },
  {
    kanji: "染",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "そ.める", romaji: "so.meru" }],
    significado: "tingir; colorir",
    exemplos: [
      {
        kanji: "染色",
        furigana: "せんしょく",
        romaji: "senshoku",
        traducao: "coloração",
      },
      {
        kanji: "染料",
        furigana: "せんりょう",
        romaji: "senryou",
        traducao: "corante",
      },
      {
        kanji: "染みる",
        furigana: "しみる",
        romaji: "shimiru",
        traducao: "manchar; infiltrar-se",
      },
    ],
  },
  {
    kanji: "奈",
    onYomi: [{ katakana: "ナ", romaji: "na" }],
    kunYomi: [],
    significado: "caráter fonético",
    exemplos: [
      {
        kanji: "京奈和",
        furigana: "けいなわ",
        romaji: "keinawa",
        traducao: "Kyoto-Nara-Wakayama",
      },
      {
        kanji: "奈良",
        furigana: "なら",
        romaji: "nara",
        traducao: "Nara (cidade)",
      },
      {
        kanji: "奈落",
        furigana: "ならく",
        romaji: "naraku",
        traducao: "inferno",
      },
    ],
  },
  {
    kanji: "傷",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "きず", romaji: "kizu" }],
    significado: "ferida; lesão",
    exemplos: [
      {
        kanji: "負傷",
        furigana: "ふしょう",
        romaji: "fushou",
        traducao: "ferimento",
      },
      {
        kanji: "傷口",
        furigana: "きずぐち",
        romaji: "kizuguchi",
        traducao: "ferida",
      },
      {
        kanji: "心の傷",
        furigana: "こころのきず",
        romaji: "kokoro no kizu",
        traducao: "ferida emocional",
      },
    ],
  },
  {
    kanji: "択",
    onYomi: [{ katakana: "タク", romaji: "taku" }],
    kunYomi: [{ hiragana: "えら.ぶ", romaji: "era.bu" }],
    significado: "escolher; selecionar",
    exemplos: [
      {
        kanji: "選択",
        furigana: "せんたく",
        romaji: "sentaku",
        traducao: "escolha",
      },
      {
        kanji: "多択",
        furigana: "たたく",
        romaji: "tataku",
        traducao: "múltipla escolha",
      },
      {
        kanji: "選択肢",
        furigana: "せんたくし",
        romaji: "sentakushi",
        traducao: "opção; alternativa",
      },
    ],
  },
  {
    kanji: "秀",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [],
    significado: "excelente; talentoso",
    exemplos: [
      {
        kanji: "優秀",
        furigana: "ゆうしゅう",
        romaji: "yuushuu",
        traducao: "excelente",
      },
      {
        kanji: "秀才",
        furigana: "しゅうさい",
        romaji: "shuusai",
        traducao: "gênio",
      },
      {
        kanji: "秀でる",
        furigana: "ひいでる",
        romaji: "hiideru",
        traducao: "se destacar",
      },
    ],
  },
  {
    kanji: "徴",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [],
    significado: "sinal; característica",
    exemplos: [
      {
        kanji: "特徴",
        furigana: "とくちょう",
        romaji: "tokuchou",
        traducao: "característica",
      },
      {
        kanji: "徴収",
        furigana: "ちょうしゅう",
        romaji: "choushuu",
        traducao: "coleta; cobrança",
      },
      {
        kanji: "徴兵",
        furigana: "ちょうへい",
        romaji: "chouhei",
        traducao: "alistamento militar",
      },
    ],
  },
  {
    kanji: "弾",
    onYomi: [{ katakana: "ダン", romaji: "dan" }],
    kunYomi: [{ hiragana: "はず.む", romaji: "hazu.mu" }],
    significado: "bala; projetar",
    exemplos: [
      {
        kanji: "弾丸",
        furigana: "だんがん",
        romaji: "dangan",
        traducao: "bala",
      },
      {
        kanji: "弾道",
        furigana: "だんどう",
        romaji: "dandou",
        traducao: "trajetória",
      },
      {
        kanji: "発射弾",
        furigana: "はっしゃだん",
        romaji: "hasshadan",
        traducao: "munição",
      },
    ],
  },
  {
    kanji: "償",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "つぐな.う", romaji: "tsuguna.u" }],
    significado: "compensar; retribuir",
    exemplos: [
      {
        kanji: "補償",
        furigana: "ほしょう",
        romaji: "hoshou",
        traducao: "compensação",
      },
      {
        kanji: "賠償",
        furigana: "ばいしょう",
        romaji: "baishou",
        traducao: "indenização",
      },
      {
        kanji: "償う",
        furigana: "つぐなう",
        romaji: "tsugunau",
        traducao: "compensar; pagar",
      },
    ],
  },
  {
    kanji: "功",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "realização; mérito",
    exemplos: [
      {
        kanji: "功績",
        furigana: "こうせき",
        romaji: "kouseki",
        traducao: "mérito; conquista",
      },
      {
        kanji: "功労",
        furigana: "こうろう",
        romaji: "kourou",
        traducao: "contribuição; mérito",
      },
      {
        kanji: "成果",
        furigana: "せいか",
        romaji: "seika",
        traducao: "realização; resultado",
      },
    ],
  },
  {
    kanji: "拠",
    onYomi: [{ katakana: "キョ", romaji: "kyo" }],
    kunYomi: [{ hiragana: "よ.る", romaji: "yo.ru" }],
    significado: "base; fundamento",
    exemplos: [
      {
        kanji: "拠点",
        furigana: "きょてん",
        romaji: "kyoten",
        traducao: "base; centro",
      },
      {
        kanji: "根拠",
        furigana: "こんきょ",
        romaji: "konkyo",
        traducao: "fundamento; base",
      },
      {
        kanji: "拠出",
        furigana: "きょしゅつ",
        romaji: "kyoshutsu",
        traducao: "contribuição; alocar",
      },
    ],
  },
  {
    kanji: "秘",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "ひ.める", romaji: "hi.meru" }],
    significado: "secreto; escondido",
    exemplos: [
      {
        kanji: "秘密",
        furigana: "ひみつ",
        romaji: "himitsu",
        traducao: "segredo",
      },
      {
        kanji: "秘書",
        furigana: "ひしょ",
        romaji: "hisho",
        traducao: "secretário",
      },
      {
        kanji: "秘訣",
        furigana: "ひけつ",
        romaji: "hiketsu",
        traducao: "segredo do sucesso",
      },
    ],
  },
  {
    kanji: "拒",
    onYomi: [{ katakana: "キョ", romaji: "kyo" }],
    kunYomi: [{ hiragana: "こば.む", romaji: "koba.mu" }],
    significado: "recusar; rejeitar",
    exemplos: [
      {
        kanji: "拒否",
        furigana: "きょひ",
        romaji: "kyohi",
        traducao: "recusa; rejeição",
      },
      {
        kanji: "排拒",
        furigana: "はいきょ",
        romaji: "haikyo",
        traducao: "exclusão",
      },
      {
        kanji: "抵抗",
        furigana: "ていこう",
        romaji: "teikou",
        traducao: "resistência",
      },
    ],
  },
  {
    kanji: "刑",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [],
    significado: "punição; pena",
    exemplos: [
      {
        kanji: "死刑",
        furigana: "しけい",
        romaji: "shikei",
        traducao: "pena de morte",
      },
      {
        kanji: "刑事",
        furigana: "けいじ",
        romaji: "keiji",
        traducao: "crime; investigador",
      },
      {
        kanji: "刑務所",
        furigana: "けいむしょ",
        romaji: "keimusho",
        traducao: "prisão",
      },
    ],
  },
  {
    kanji: "塚",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "つか", romaji: "tsuka" }],
    significado: "monte; túmulo",
    exemplos: [
      {
        kanji: "墓塚",
        furigana: "はかづか",
        romaji: "hakazuka",
        traducao: "túmulo",
      },
      {
        kanji: "古墳",
        furigana: "こふん",
        romaji: "kofun",
        traducao: "túmulo antigo",
      },
      {
        kanji: "塚地",
        furigana: "つかじ",
        romaji: "tsukaji",
        traducao: "monte; colina",
      },
    ],
  },
  {
    kanji: "致",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [{ hiragana: "いた.す", romaji: "ita.su" }],
    significado: "causar; levar a",
    exemplos: [
      {
        kanji: "致命的",
        furigana: "ちめいてき",
        romaji: "chimeiteki",
        traducao: "fatal",
      },
      {
        kanji: "敬意を致す",
        furigana: "けいいをいたす",
        romaji: "keii o itasu",
        traducao: "mostrar respeito",
      },
      {
        kanji: "申し致す",
        furigana: "もうしいたす",
        romaji: "moushi itasu",
        traducao: "dizer (humilde)",
      },
    ],
  },
  {
    kanji: "繰",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "く.る", romaji: "ku.ru" }],
    significado: "enrolar; girar",
    exemplos: [
      {
        kanji: "繰り返す",
        furigana: "くりかえす",
        romaji: "kurikaesu",
        traducao: "repetir",
      },
      {
        kanji: "繰り上げる",
        furigana: "くりあげる",
        romaji: "kuriageru",
        traducao: "arredondar para cima",
      },
      {
        kanji: "糸を繰る",
        furigana: "いとをくる",
        romaji: "ito o kuru",
        traducao: "enrolar o fio",
      },
    ],
  },
  {
    kanji: "尾",
    onYomi: [{ katakana: "ビ", romaji: "bi" }],
    kunYomi: [{ hiragana: "お", romaji: "o" }],
    significado: "cauda; fim",
    exemplos: [
      {
        kanji: "象の尾",
        furigana: "ぞうのお",
        romaji: "zou no o",
        traducao: "cauda do elefante",
      },
      {
        kanji: "後尾",
        furigana: "こうび",
        romaji: "koubi",
        traducao: "cauda",
      },
      {
        kanji: "尾行",
        furigana: "びこう",
        romaji: "bikou",
        traducao: "seguir; perseguir",
      },
    ],
  },
  {
    kanji: "描",
    onYomi: [{ katakana: "ビョウ", romaji: "byou" }],
    kunYomi: [{ hiragana: "えが.く", romaji: "ega.ku" }],
    significado: "desenhar; retratar",
    exemplos: [
      {
        kanji: "描画",
        furigana: "びょうが",
        romaji: "byouga",
        traducao: "desenho; pintura",
      },
      {
        kanji: "描写",
        furigana: "びょうしゃ",
        romaji: "byousha",
        traducao: "descrição; representação",
      },
      {
        kanji: "描く",
        furigana: "かく",
        romaji: "kaku",
        traducao: "desenhar; retratar",
      },
    ],
  },
  {
    kanji: "鈴",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [{ hiragana: "すず", romaji: "suzu" }],
    significado: "sino",
    exemplos: [
      {
        kanji: "鈴木",
        furigana: "すずき",
        romaji: "Suzuki",
        traducao: "Suzuki (sobrenome)",
      },
      {
        kanji: "風鈴",
        furigana: "ふうりん",
        romaji: "fuurin",
        traducao: "sino de vento",
      },
      {
        kanji: "鈴の音",
        furigana: "すずのおと",
        romaji: "suzu no oto",
        traducao: "som do sino",
      },
    ],
  },
  {
    kanji: "盤",
    onYomi: [{ katakana: "バン", romaji: "ban" }],
    kunYomi: [],
    significado: "tabuleiro; disco",
    exemplos: [
      {
        kanji: "将棋盤",
        furigana: "しょうぎばん",
        romaji: "shougi ban",
        traducao: "tabuleiro de xadrez japonês",
      },
      {
        kanji: "記録盤",
        furigana: "きろくばん",
        romaji: "kiroku ban",
        traducao: "disco de gravação",
      },
      {
        kanji: "地図盤",
        furigana: "ちずばん",
        romaji: "chizu ban",
        traducao: "mapa",
      },
    ],
  },
  {
    kanji: "項",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "item; cláusula",
    exemplos: [
      {
        kanji: "項目",
        furigana: "こうもく",
        romaji: "koumoku",
        traducao: "item; ponto",
      },
      {
        kanji: "条件項",
        furigana: "じょうけんこう",
        romaji: "jouken kou",
        traducao: "cláusula de condição",
      },
      {
        kanji: "学校項",
        furigana: "がっこうこう",
        romaji: "gakkou kou",
        traducao: "item escolar",
      },
    ],
  },
  {
    kanji: "喪",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "も", romaji: "mo" }],
    significado: "luto; perda",
    exemplos: [
      {
        kanji: "喪失",
        furigana: "そうしつ",
        romaji: "soushitsu",
        traducao: "perda",
      },
      {
        kanji: "喪服",
        furigana: "もふく",
        romaji: "mofuku",
        traducao: "roupa de luto",
      },
      {
        kanji: "喪中",
        furigana: "もちゅう",
        romaji: "mochuu",
        traducao: "período de luto",
      },
    ],
  },
  {
    kanji: "伴",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "ともな.う", romaji: "tomo.nau" }],
    significado: "acompanhar; estar junto",
    exemplos: [
      {
        kanji: "伴侶",
        furigana: "はんりょ",
        romaji: "hanryo",
        traducao: "companheiro",
      },
      {
        kanji: "同伴",
        furigana: "どうはん",
        romaji: "douhan",
        traducao: "acompanhamento",
      },
      {
        kanji: "伴走",
        furigana: "ばんそう",
        romaji: "bansou",
        traducao: "correr junto",
      },
    ],
  },
  {
    kanji: "養",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "やしな.う", romaji: "yashina.u" }],
    significado: "criar; nutrir",
    exemplos: [
      {
        kanji: "養育",
        furigana: "よういく",
        romaji: "youiku",
        traducao: "criação; educação",
      },
      {
        kanji: "養子",
        furigana: "ようし",
        romaji: "youshi",
        traducao: "filho adotivo",
      },
      {
        kanji: "養成",
        furigana: "ようせい",
        romaji: "yousei",
        traducao: "formação; treinamento",
      },
    ],
  },
  {
    kanji: "懸",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "か.ける", romaji: "ka.keru" }],
    significado: "suspender; pendurar",
    exemplos: [
      {
        kanji: "懸念",
        furigana: "けねん",
        romaji: "kenen",
        traducao: "preocupação",
      },
      {
        kanji: "懸賞",
        furigana: "けんしょう",
        romaji: "kenshou",
        traducao: "recompensa",
      },
      {
        kanji: "懸命",
        furigana: "けんめい",
        romaji: "kenmei",
        traducao: "empenho; diligência",
      },
    ],
  },
  {
    kanji: "街",
    onYomi: [{ katakana: "ガイ", romaji: "gai" }],
    kunYomi: [{ hiragana: "まち", romaji: "machi" }],
    significado: "cidade; rua",
    exemplos: [
      {
        kanji: "街路",
        furigana: "がいろ",
        romaji: "gairo",
        traducao: "via pública",
      },
      {
        kanji: "商店街",
        furigana: "しょうてんがい",
        romaji: "shoutengai",
        traducao: "rua comercial",
      },
      {
        kanji: "市街地",
        furigana: "しがいち",
        romaji: "shigaichi",
        traducao: "área urbana",
      },
    ],
  },
  {
    kanji: "契",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "ちぎ.る", romaji: "chigi.ru" }],
    significado: "contrato; promessa",
    exemplos: [
      {
        kanji: "契約",
        furigana: "けいやく",
        romaji: "keiyaku",
        traducao: "contrato",
      },
      {
        kanji: "約束",
        furigana: "やくそく",
        romaji: "yakusoku",
        traducao: "promessa",
      },
      {
        kanji: "契り",
        furigana: "ちぎり",
        romaji: "chigiri",
        traducao: "voto; pacto",
      },
    ],
  },
  {
    kanji: "掲",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "かか.げる", romaji: "kaka.geru" }],
    significado: "publicar; afixar",
    exemplos: [
      {
        kanji: "掲示板",
        furigana: "けいじばん",
        romaji: "keijiban",
        traducao: "quadro de avisos",
      },
      {
        kanji: "掲載",
        furigana: "けいさい",
        romaji: "keisai",
        traducao: "publicação",
      },
      {
        kanji: "広告掲示",
        furigana: "こうこくけいじ",
        romaji: "koukoku keiji",
        traducao: "anúncio publicitário",
      },
    ],
  },
  {
    kanji: "躍",
    onYomi: [{ katakana: "ヤク", romaji: "yaku" }],
    kunYomi: [{ hiragana: "おど.る", romaji: "odo.ru" }],
    significado: "saltar; pular",
    exemplos: [
      {
        kanji: "活躍",
        furigana: "かつやく",
        romaji: "katsuyaku",
        traducao: "atividade ativa",
      },
      {
        kanji: "躍進",
        furigana: "やくしん",
        romaji: "yakushin",
        traducao: "avanço; progresso",
      },
      {
        kanji: "跳躍",
        furigana: "ちょうやく",
        romaji: "chouyaku",
        traducao: "salto",
      },
    ],
  },
  {
    kanji: "棄",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "す.てる", romaji: "su.teru" }],
    significado: "descartar; abandonar",
    exemplos: [
      {
        kanji: "放棄",
        furigana: "ほうき",
        romaji: "houki",
        traducao: "abandono",
      },
      {
        kanji: "廃棄",
        furigana: "はいき",
        romaji: "haiki",
        traducao: "descarte",
      },
      {
        kanji: "棄権",
        furigana: "きけん",
        romaji: "kiken",
        traducao: "abstenção",
      },
    ],
  },
  {
    kanji: "邸",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "residência; mansão",
    exemplos: [
      {
        kanji: "邸宅",
        furigana: "ていたく",
        romaji: "teitaku",
        traducao: "residência; mansão",
      },
      {
        kanji: "公邸",
        furigana: "こうてい",
        romaji: "koutei",
        traducao: "residência oficial",
      },
      {
        kanji: "私邸",
        furigana: "してい",
        romaji: "shitei",
        traducao: "residência privada",
      },
    ],
  },
  {
    kanji: "縮",
    onYomi: [{ katakana: "シュク", romaji: "shuku" }],
    kunYomi: [{ hiragana: "ちぢ.む", romaji: "chiji.mu" }],
    significado: "contrair; encolher",
    exemplos: [
      {
        kanji: "縮小",
        furigana: "しゅくしょう",
        romaji: "shukushou",
        traducao: "redução; encolhimento",
      },
      {
        kanji: "縮図",
        furigana: "しゅくず",
        romaji: "shukuze",
        traducao: "esboço; diagrama",
      },
      {
        kanji: "縮める",
        furigana: "ちぢめる",
        romaji: "chidjimeru",
        traducao: "encolher; contrair",
      },
    ],
  },
  {
    kanji: "還",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "かえ.る", romaji: "kae.ru" }],
    significado: "retornar; devolver",
    exemplos: [
      {
        kanji: "帰還",
        furigana: "きかん",
        romaji: "kikan",
        traducao: "retorno",
      },
      {
        kanji: "復還",
        furigana: "ふっかん",
        romaji: "fukkan",
        traducao: "restauração; retorno",
      },
      {
        kanji: "返還",
        furigana: "へんかん",
        romaji: "henkan",
        traducao: "devolução",
      },
    ],
  },
  {
    kanji: "属",
    onYomi: [{ katakana: "ゾク", romaji: "zoku" }],
    kunYomi: [{ hiragana: "さかん", romaji: "sakan" }],
    significado: "pertencer; ser membro de",
    exemplos: [
      {
        kanji: "所属",
        furigana: "しょぞく",
        romaji: "shozoku",
        traducao: "filiação; afiliação",
      },
      {
        kanji: "社属",
        furigana: "しゃぞく",
        romaji: "shazoku",
        traducao: "membro da empresa",
      },
      {
        kanji: "一部屋に属する",
        furigana: "ひとべやにぞくする",
        romaji: "hitobeya ni zoku suru",
        traducao: "pertencer a um quarto",
      },
    ],
  },
  {
    kanji: "慮",
    onYomi: [{ katakana: "リョ", romaji: "ryo" }],
    kunYomi: [{ hiragana: "おもんぱく.る", romaji: "omonpaku.ru" }],
    significado: "considerar; refletir",
    exemplos: [
      {
        kanji: "慎慮",
        furigana: "しんりょ",
        romaji: "shinryo",
        traducao: "prudência; cuidado",
      },
      {
        kanji: "思慮深い",
        furigana: "しりょぶかい",
        romaji: "shiryobukai",
        traducao: "pensativo; ponderado",
      },
      {
        kanji: "検討を慮る",
        furigana: "けんとうをおもんばかる",
        romaji: "kentou o omonbakaru",
        traducao: "considerar; refletir sobre",
      },
    ],
  },
  {
    kanji: "枠",
    onYomi: [{ katakana: "ワク", romaji: "waku" }],
    kunYomi: [],
    significado: "moldura; estrutura",
    exemplos: [
      {
        kanji: "枠組み",
        furigana: "わくぐみ",
        romaji: "wakugumi",
        traducao: "estrutura; esquema",
      },
      {
        kanji: "窓枠",
        furigana: "まどわく",
        romaji: "madowaku",
        traducao: "moldura da janela",
      },
      {
        kanji: "定められた枠",
        furigana: "さだめられたわく",
        romaji: "sadamerareta waku",
        traducao: "estrutura definida",
      },
    ],
  },
  {
    kanji: "恵",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "めぐ.む", romaji: "megu.mu" }],
    significado: "benção; graça",
    exemplos: [
      {
        kanji: "恩恵",
        furigana: "おんけい",
        romaji: "onkei",
        traducao: "benefício; graça",
      },
      {
        kanji: "恵み",
        furigana: "めぐみ",
        romaji: "megumi",
        traducao: "graça; bênção",
      },
      {
        kanji: "天恵",
        furigana: "てんけい",
        romaji: "tenkei",
        traducao: "graça divina",
      },
    ],
  },
  {
    kanji: "露",
    onYomi: [{ katakana: "ロ", romaji: "ro" }],
    kunYomi: [{ hiragana: "つゆ", romaji: "tsuyu" }],
    significado: "orvalho; revelar",
    exemplos: [
      {
        kanji: "露天",
        furigana: "ろてん",
        romaji: "roten",
        traducao: "céu aberto; ao ar livre",
      },
      {
        kanji: "露出",
        furigana: "ろしゅつ",
        romaji: "roshutsu",
        traducao: "exposição; mostrar",
      },
      {
        kanji: "露骨",
        furigana: "ろこつ",
        romaji: "rokotsu",
        traducao: "aberto; evidente",
      },
    ],
  },
  {
    kanji: "沖",
    onYomi: [{ katakana: "チュウ", romaji: "chuu" }],
    kunYomi: [{ hiragana: "おき", romaji: "oki" }],
    significado: "mar alto; afastado da costa",
    exemplos: [
      {
        kanji: "沖縄",
        furigana: "おきなわ",
        romaji: "Okinawa",
        traducao: "Okinawa (cidade)",
      },
      {
        kanji: "沖合",
        furigana: "おきあい",
        romaji: "oki'ai",
        traducao: "alto mar",
      },
      {
        kanji: "沖に浮かぶ",
        furigana: "おきにうかぶ",
        romaji: "oki ni ukabu",
        traducao: "flutuar no mar",
      },
    ],
  },
  {
    kanji: "緩",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "ゆる.い", romaji: "yuru.i" }],
    significado: "lento; frouxo",
    exemplos: [
      {
        kanji: "緩和",
        furigana: "かんわ",
        romaji: "kanwa",
        traducao: "alívio; relaxamento",
      },
      {
        kanji: "緩衝",
        furigana: "かんしょう",
        romaji: "kanshou",
        traducao: "zona tampão",
      },
      {
        kanji: "緩やか",
        furigana: "ゆるやか",
        romaji: "yuruyaka",
        traducao: "suave; leve",
      },
    ],
  },
  {
    kanji: "節",
    onYomi: [{ katakana: "セツ", romaji: "setsu" }],
    kunYomi: [{ hiragana: "ふし", romaji: "fushi" }],
    significado: "ponto; estação",
    exemplos: [
      {
        kanji: "季節",
        furigana: "きせつ",
        romaji: "kisetsu",
        traducao: "estação; temporada",
      },
      {
        kanji: "節約",
        furigana: "せつやく",
        romaji: "setsuyaku",
        traducao: "economia; poupança",
      },
      {
        kanji: "重要な節",
        furigana: "じゅうようなせつ",
        romaji: "juuyou na setsu",
        traducao: "ponto importante",
      },
    ],
  },
  {
    kanji: "需",
    onYomi: [{ katakana: "ジュ", romaji: "ju" }],
    kunYomi: [],
    significado: "demanda; requisito",
    exemplos: [
      {
        kanji: "需要",
        furigana: "じゅよう",
        romaji: "juyou",
        traducao: "demanda; procura",
      },
      {
        kanji: "必需品",
        furigana: "ひつじゅひん",
        romaji: "hitsujuhin",
        traducao: "necessidade essencial",
      },
      {
        kanji: "需要を満たす",
        furigana: "じゅようをみたす",
        romaji: "juyou o mitasu",
        traducao: "atender à demanda",
      },
    ],
  },
  {
    kanji: "射",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "い.る", romaji: "i.ru" }],
    significado: "atirar; disparar",
    exemplos: [
      {
        kanji: "射撃",
        furigana: "しゃげき",
        romaji: "shageki",
        traducao: "tiro; disparo",
      },
      {
        kanji: "矢を射る",
        furigana: "やをいる",
        romaji: "ya o iru",
        traducao: "atirar uma flecha",
      },
      {
        kanji: "射程",
        furigana: "しゃてい",
        romaji: "shatei",
        traducao: "alcance (de tiro)",
      },
    ],
  },
  {
    kanji: "購",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "comprar; adquirir",
    exemplos: [
      {
        kanji: "購入",
        furigana: "こうにゅう",
        romaji: "kounyuu",
        traducao: "compra; aquisição",
      },
      {
        kanji: "購読",
        furigana: "こうどく",
        romaji: "koudoku",
        traducao: "assinatura (de jornal, revista)",
      },
      {
        kanji: "購買力",
        furigana: "こうばいりょく",
        romaji: "koubairyoku",
        traducao: "poder de compra",
      },
    ],
  },
  {
    kanji: "揮",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "ふ.る", romaji: "fu.ru" }],
    significado: "liderar; comandar",
    exemplos: [
      {
        kanji: "指揮",
        furigana: "しき",
        romaji: "shiki",
        traducao: "comando; direção",
      },
      {
        kanji: "振り揮る",
        furigana: "ふりふる",
        romaji: "furifuru",
        traducao: "brandir; agitar",
      },
      {
        kanji: "揮発性",
        furigana: "きはつせい",
        romaji: "kihatsusei",
        traducao: "volatilidade",
      },
    ],
  },
  {
    kanji: "充",
    onYomi: [{ katakana: "ジュウ", romaji: "juu" }],
    kunYomi: [{ hiragana: "あ.てる", romaji: "a.teru" }],
    significado: "preencher; abastecer",
    exemplos: [
      {
        kanji: "充実",
        furigana: "じゅうじつ",
        romaji: "juujitsu",
        traducao: "enriquecimento; aprimoramento",
      },
      {
        kanji: "充填",
        furigana: "じゅうてん",
        romaji: "juuten",
        traducao: "enchimento",
      },
      {
        kanji: "満員に充する",
        furigana: "まんいんにみつする",
        romaji: "man'in ni mitsu suru",
        traducao: "lotar; preencher completamente",
      },
    ],
  },
  {
    kanji: "貢",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "みつ.ぐ", romaji: "mitsu.gu" }],
    significado: "contribuir; oferecer",
    exemplos: [
      {
        kanji: "貢献",
        furigana: "こうけん",
        romaji: "kouken",
        traducao: "contribuição",
      },
      {
        kanji: "貢物",
        furigana: "みつもの",
        romaji: "mitsumono",
        traducao: "tributo; presente",
      },
      {
        kanji: "貢状",
        furigana: "こうじょう",
        romaji: "koujou",
        traducao: "carta de tributo",
      },
    ],
  },
  {
    kanji: "鹿",
    onYomi: [{ katakana: "ロク", romaji: "roku" }],
    kunYomi: [{ hiragana: "しか", romaji: "shika" }],
    significado: "veado",
    exemplos: [
      {
        kanji: "鹿肉",
        furigana: "しかにく",
        romaji: "shikaniku",
        traducao: "carne de veado",
      },
      {
        kanji: "鹿狩り",
        furigana: "しかがり",
        romaji: "shikagari",
        traducao: "caça ao veado",
      },
      {
        kanji: "鹿島",
        furigana: "かしま",
        romaji: "Kashima",
        traducao: "Kashima (nome de lugar)",
      },
    ],
  },
  {
    kanji: "却",
    onYomi: [{ katakana: "キャク", romaji: "kyaku" }],
    kunYomi: [{ hiragana: "かえ.って", romaji: "kae.tte" }],
    significado: "recusar; rejeitar",
    exemplos: [
      {
        kanji: "却下",
        furigana: "きゃっか",
        romaji: "kyakka",
        traducao: "rejeição; recusa",
      },
      {
        kanji: "退却",
        furigana: "たいきゃく",
        romaji: "taikyaku",
        traducao: "retirada; recuo",
      },
      {
        kanji: "返却",
        furigana: "へんきゃく",
        romaji: "henkyaku",
        traducao: "devolução",
      },
    ],
  },
  {
    kanji: "端",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "はし", romaji: "hashi" }],
    significado: "extremidade; ponta",
    exemplos: [
      {
        kanji: "端末",
        furigana: "たんまつ",
        romaji: "tanmatsu",
        traducao: "terminal",
      },
      {
        kanji: "端正",
        furigana: "たんせい",
        romaji: "tansei",
        traducao: "ordem; correção",
      },
      {
        kanji: "一端",
        furigana: "いったん",
        romaji: "ittan",
        traducao: "uma ponta; um lado",
      },
    ],
  },
  {
    kanji: "賃",
    onYomi: [{ katakana: "チン", romaji: "chin" }],
    kunYomi: [{ hiragana: "やちん", romaji: "yachin" }],
    significado: "aluguel; taxa",
    exemplos: [
      {
        kanji: "賃金",
        furigana: "ちんぎん",
        romaji: "chingin",
        traducao: "salário",
      },
      {
        kanji: "賃貸",
        furigana: "ちんたい",
        romaji: "chintai",
        traducao: "aluguel",
      },
      {
        kanji: "月賃",
        furigana: "げっちん",
        romaji: "gecchin",
        traducao: "aluguel mensal",
      },
    ],
  },
  {
    kanji: "獲",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "え.る", romaji: "e.ru" }],
    significado: "obter; adquirir",
    exemplos: [
      {
        kanji: "獲得",
        furigana: "かくとく",
        romaji: "kakutoku",
        traducao: "obtenção; aquisição",
      },
      {
        kanji: "獲物",
        furigana: "えもの",
        romaji: "emono",
        traducao: "presas; captura",
      },
      {
        kanji: "獲得する",
        furigana: "かくとくする",
        romaji: "kakutoku suru",
        traducao: "obter; adquirir",
      },
    ],
  },
  {
    kanji: "郡",
    onYomi: [{ katakana: "グン", romaji: "gun" }],
    kunYomi: [],
    significado: "distrito; condado",
    exemplos: [
      {
        kanji: "市郡",
        furigana: "しぐん",
        romaji: "shigun",
        traducao: "cidade e distrito",
      },
      {
        kanji: "過去郡",
        furigana: "かこぐん",
        romaji: "kakogun",
        traducao: "condado antigo",
      },
      {
        kanji: "県郡",
        furigana: "けんぐん",
        romaji: "kengun",
        traducao: "prefeitura e condado",
      },
    ],
  },
  {
    kanji: "併",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [{ hiragana: "なら.べる", romaji: "nara.beru" }],
    significado: "juntar; combinar",
    exemplos: [
      {
        kanji: "併合",
        furigana: "へいごう",
        romaji: "heigou",
        traducao: "anexação; fusão",
      },
      {
        kanji: "併設",
        furigana: "へいせつ",
        romaji: "heisetsu",
        traducao: "juntamente com",
      },
      {
        kanji: "併用",
        furigana: "へいよう",
        romaji: "heiyou",
        traducao: "uso simultâneo",
      },
    ],
  },
  {
    kanji: "徹",
    onYomi: [{ katakana: "テツ", romaji: "tetsu" }],
    kunYomi: [{ hiragana: "とお.る", romaji: "too.ru" }],
    significado: "penetrar; abranger",
    exemplos: [
      {
        kanji: "徹底",
        furigana: "てってい",
        romaji: "tettei",
        traducao: "completo; minucioso",
      },
      {
        kanji: "徹夜",
        furigana: "てつや",
        romaji: "tetsuya",
        traducao: "passar a noite acordado",
      },
      {
        kanji: "徹底的",
        furigana: "てっていてき",
        romaji: "tetteiteki",
        traducao: "completamente",
      },
    ],
  },
  {
    kanji: "貴",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "とうと.い", romaji: "touto.i" }],
    significado: "nobre; valioso",
    exemplos: [
      {
        kanji: "貴重",
        furigana: "きちょう",
        romaji: "kichou",
        traducao: "valioso; precioso",
      },
      {
        kanji: "貴族",
        furigana: "きぞく",
        romaji: "kizoku",
        traducao: "nobreza; aristocracia",
      },
      {
        kanji: "貴重品",
        furigana: "きちょうひん",
        romaji: "kichouhin",
        traducao: "artigo de valor",
      },
    ],
  },
  {
    kanji: "衝",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "colidir; impactar",
    exemplos: [
      {
        kanji: "衝突",
        furigana: "しょうとつ",
        romaji: "shoutotsu",
        traducao: "colisão; conflito",
      },
      {
        kanji: "衝撃",
        furigana: "しょうげき",
        romaji: "shougeki",
        traducao: "impacto; choque",
      },
      {
        kanji: "衝動",
        furigana: "しょうどう",
        romaji: "shoudou",
        traducao: "impulso; instinto",
      },
    ],
  },
  {
    kanji: "焦",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "こ.げる", romaji: "ko.geru" }],
    significado: "queimar; apressar-se",
    exemplos: [
      {
        kanji: "焦点",
        furigana: "しょうてん",
        romaji: "shouten",
        traducao: "foco; ponto central",
      },
      {
        kanji: "焦がす",
        furigana: "こがす",
        romaji: "kogasu",
        traducao: "queimar",
      },
      {
        kanji: "焦る",
        furigana: "あせる",
        romaji: "aseru",
        traducao: "ficar impaciente; apressar-se",
      },
    ],
  },
  {
    kanji: "奪",
    onYomi: [{ katakana: "ダツ", romaji: "datsu" }],
    kunYomi: [{ hiragana: "うば.う", romaji: "uba.u" }],
    significado: "roubar; tomar",
    exemplos: [
      {
        kanji: "奪取",
        furigana: "だっしゅ",
        romaji: "dasshu",
        traducao: "captura; apreensão",
      },
      {
        kanji: "奪う",
        furigana: "うばう",
        romaji: "ubau",
        traducao: "roubar; tomar",
      },
      {
        kanji: "勝利を奪う",
        furigana: "しょうりをうばう",
        romaji: "shouri o ubau",
        traducao: "conquistar a vitória",
      },
    ],
  },
  {
    kanji: "災",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [],
    significado: "desastre; calamidade",
    exemplos: [
      {
        kanji: "災害",
        furigana: "さいがい",
        romaji: "saigai",
        traducao: "desastre; calamidade",
      },
      {
        kanji: "火災",
        furigana: "かさい",
        romaji: "kasai",
        traducao: "incêndio",
      },
      {
        kanji: "天災",
        furigana: "てんさい",
        romaji: "tensai",
        traducao: "desastre natural",
      },
    ],
  },
  {
    kanji: "浦",
    onYomi: [{ katakana: "ホ", romaji: "ho" }],
    kunYomi: [{ hiragana: "うら", romaji: "ura" }],
    significado: "baía; praia",
    exemplos: [
      {
        kanji: "海浜",
        furigana: "かいひん",
        romaji: "kaihin",
        traducao: "praia; costa marítima",
      },
      {
        kanji: "河浦",
        furigana: "かわうら",
        romaji: "kawaura",
        traducao: "baía de rio",
      },
      {
        kanji: "浦島太郎",
        furigana: "うらしまたろう",
        romaji: "Urashima Tarou",
        traducao: "Urashima Taro (personagem folclórico)",
      },
    ],
  },
  {
    kanji: "析",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [],
    significado: "analisar; decompor",
    exemplos: [
      {
        kanji: "分析",
        furigana: "ぶんせき",
        romaji: "bunseki",
        traducao: "análise",
      },
      {
        kanji: "解析",
        furigana: "かいせき",
        romaji: "kaiseki",
        traducao: "análise; decodificação",
      },
      {
        kanji: "枝分かれ",
        furigana: "えだわかれ",
        romaji: "edawakare",
        traducao: "ramificação",
      },
    ],
  },
  {
    kanji: "譲",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "ゆず.る", romaji: "yuzu.ru" }],
    significado: "ceder; transferir",
    exemplos: [
      {
        kanji: "譲渡",
        furigana: "じょうと",
        romaji: "jouto",
        traducao: "transferência",
      },
      {
        kanji: "譲歩",
        furigana: "じょうほ",
        romaji: "jouho",
        traducao: "concessão; ceder terreno",
      },
      {
        kanji: "譲る",
        furigana: "ゆずる",
        romaji: "yuzuru",
        traducao: "ceder; transferir",
      },
    ],
  },
  {
    kanji: "称",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "たた.える", romaji: "tata.eru" }],
    significado: "elogiar; denominar",
    exemplos: [
      {
        kanji: "称賛",
        furigana: "しょうさん",
        romaji: "shousan",
        traducao: "elogio; admiração",
      },
      {
        kanji: "自称",
        furigana: "じしょう",
        romaji: "jishou",
        traducao: "auto-denominação",
      },
      {
        kanji: "賞賛する",
        furigana: "しょうさんする",
        romaji: "shousan suru",
        traducao: "elogiar; admirar",
      },
    ],
  },
  {
    kanji: "納",
    onYomi: [{ katakana: "ノウ", romaji: "nou" }],
    kunYomi: [{ hiragana: "おさ.める", romaji: "osa.meru" }],
    significado: "receber; aceitar",
    exemplos: [
      {
        kanji: "納入",
        furigana: "のうにゅう",
        romaji: "nou'nyuu",
        traducao: "pagamento; contribuição",
      },
      {
        kanji: "納得",
        furigana: "なっとく",
        romaji: "nattoku",
        traducao: "aceitação; compreensão",
      },
      {
        kanji: "収納",
        furigana: "しゅうのう",
        romaji: "shuunou",
        traducao: "recebimento; coleta",
      },
    ],
  },
  {
    kanji: "樹",
    onYomi: [{ katakana: "ジュ", romaji: "ju" }],
    kunYomi: [{ hiragana: "き", romaji: "ki" }],
    significado: "árvore",
    exemplos: [
      {
        kanji: "木樹",
        furigana: "もくじゅ",
        romaji: "mokuju",
        traducao: "árvore",
      },
      {
        kanji: "果樹",
        furigana: "かじゅ",
        romaji: "kaju",
        traducao: "árvore frutífera",
      },
      {
        kanji: "樹木",
        furigana: "じゅもく",
        romaji: "jumoku",
        traducao: "árvores",
      },
    ],
  },
  {
    kanji: "挑",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "いど.む", romaji: "ido.mu" }],
    significado: "desafiar; enfrentar",
    exemplos: [
      {
        kanji: "挑戦",
        furigana: "ちょうせん",
        romaji: "chousen",
        traducao: "desafio",
      },
      {
        kanji: "挑む",
        furigana: "いどむ",
        romaji: "idomu",
        traducao: "desafiar; enfrentar",
      },
      {
        kanji: "闘志を挑む",
        furigana: "とうしをいどむ",
        romaji: "toushi o idomu",
        traducao: "encarar com espírito de luta",
      },
    ],
  },
  {
    kanji: "誘",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [{ hiragana: "さそ.う", romaji: "saso.u" }],
    significado: "convidar; atrair",
    exemplos: [
      {
        kanji: "誘惑",
        furigana: "ゆうわく",
        romaji: "yuwaku",
        traducao: "tentação; sedução",
      },
      {
        kanji: "誘導",
        furigana: "ゆうどう",
        romaji: "yuudou",
        traducao: "guia; condução",
      },
      {
        kanji: "誘う",
        furigana: "さそう",
        romaji: "sasou",
        traducao: "convidar; atrair",
      },
    ],
  },
  {
    kanji: "紛",
    onYomi: [{ katakana: "フン", romaji: "fun" }],
    kunYomi: [{ hiragana: "まぎ.れる", romaji: "magi.reru" }],
    significado: "confusão; engano",
    exemplos: [
      {
        kanji: "紛失",
        furigana: "ふんしつ",
        romaji: "funshitsu",
        traducao: "perda; extravio",
      },
      {
        kanji: "紛争",
        furigana: "ふんそう",
        romaji: "funsou",
        traducao: "conflito; disputa",
      },
      {
        kanji: "混乱",
        furigana: "こんらん",
        romaji: "konran",
        traducao: "confusão; caos",
      },
    ],
  },
  {
    kanji: "至",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "いた.る", romaji: "ita.ru" }],
    significado: "até; alcançar",
    exemplos: [
      {
        kanji: "至高",
        furigana: "しこう",
        romaji: "shikou",
        traducao: "supremo; máximo",
      },
      {
        kanji: "至急",
        furigana: "しきゅう",
        romaji: "shikyuu",
        traducao: "urgente; imediato",
      },
      {
        kanji: "最高至上",
        furigana: "さいこうしじょう",
        romaji: "saikou shijou",
        traducao: "máximo; supremo",
      },
    ],
  },
  {
    kanji: "宗",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [],
    significado: "religião; seita",
    exemplos: [
      {
        kanji: "宗教",
        furigana: "しゅうきょう",
        romaji: "shuukyou",
        traducao: "religião",
      },
      {
        kanji: "宗派",
        furigana: "しゅうは",
        romaji: "shuuha",
        traducao: "seita; escola religiosa",
      },
      {
        kanji: "仏教宗派",
        furigana: "ぶっきょうしゅうは",
        romaji: "bukkyou shuuha",
        traducao: "escola budista",
      },
    ],
  },
  {
    kanji: "促",
    onYomi: [{ katakana: "ソク", romaji: "soku" }],
    kunYomi: [{ hiragana: "うなが.す", romaji: "unaga.su" }],
    significado: "incentivar; apressar",
    exemplos: [
      {
        kanji: "促進",
        furigana: "そくしん",
        romaji: "sokushin",
        traducao: "promoção; estímulo",
      },
      {
        kanji: "促す",
        furigana: "うながす",
        romaji: "unagasu",
        traducao: "incentivar; apressar",
      },
      {
        kanji: "促使",
        furigana: "そくし",
        romaji: "sokushi",
        traducao: "motivação",
      },
    ],
  },
  {
    kanji: "慎",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "つつし.む", romaji: "tsutsushi.mu" }],
    significado: "prudente; cuidadoso",
    exemplos: [
      {
        kanji: "慎重",
        furigana: "しんちょう",
        romaji: "shinchou",
        traducao: "prudência; cautela",
      },
      {
        kanji: "慎む",
        furigana: "つつしむ",
        romaji: "tsutsushimu",
        traducao: "ser cuidadoso; ser prudente",
      },
      {
        kanji: "自慢しない",
        furigana: "じまんしない",
        romaji: "jiman shinai",
        traducao: "não se gabar",
      },
    ],
  },
  {
    kanji: "控",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "ひか.える", romaji: "hika.eru" }],
    significado: "controlar; restringir",
    exemplos: [
      {
        kanji: "制控",
        furigana: "せいこう",
        romaji: "seikou",
        traducao: "controle; restrição",
      },
      {
        kanji: "控える",
        furigana: "ひかえる",
        romaji: "hikaeru",
        traducao: "abster-se; restringir",
      },
      {
        kanji: "記録を控える",
        furigana: "きろくをひかえる",
        romaji: "kiroku o hikaeru",
        traducao: "fazer anotações",
      },
    ],
  },
  {
    kanji: "智",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [{ hiragana: "さと", romaji: "sato" }],
    significado: "sabedoria; inteligência",
    exemplos: [
      {
        kanji: "知恵",
        furigana: "ちえ",
        romaji: "chie",
        traducao: "sabedoria",
      },
      {
        kanji: "知識",
        furigana: "ちしき",
        romaji: "chishiki",
        traducao: "conhecimento",
      },
      {
        kanji: "知能",
        furigana: "ちのう",
        romaji: "chinou",
        traducao: "inteligência",
      },
    ],
  },
  {
    kanji: "握",
    onYomi: [{ katakana: "アク", romaji: "aku" }],
    kunYomi: [{ hiragana: "にぎ.る", romaji: "nigi.ru" }],
    significado: "segurar; apertar",
    exemplos: [
      {
        kanji: "握手",
        furigana: "あくしゅ",
        romaji: "akushu",
        traducao: "aperto de mão",
      },
      {
        kanji: "握る",
        furigana: "にぎる",
        romaji: "nigiru",
        traducao: "segurar; apertar",
      },
      {
        kanji: "力を握る",
        furigana: "ちからをにぎる",
        romaji: "chikara o nigiru",
        traducao: "ter poder; ter controle",
      },
    ],
  },
  {
    kanji: "宙",
    onYomi: [{ katakana: "チュウ", romaji: "chuu" }],
    kunYomi: [],
    significado: "espaço; ar",
    exemplos: [
      {
        kanji: "宇宙",
        furigana: "うちゅう",
        romaji: "uchuu",
        traducao: "espaço; universo",
      },
      {
        kanji: "空中",
        furigana: "くうちゅう",
        romaji: "kuuchuu",
        traducao: "ar; espaço aéreo",
      },
      {
        kanji: "宙返り",
        furigana: "ちゅうがえり",
        romaji: "chuugaeri",
        traducao: "volta mortal; salto mortal",
      },
    ],
  },
  {
    kanji: "俊",
    onYomi: [{ katakana: "シュン", romaji: "shun" }],
    kunYomi: [],
    significado: "talento; gênio",
    exemplos: [
      {
        kanji: "優俊",
        furigana: "ゆうしゅん",
        romaji: "yuushun",
        traducao: "talento; habilidade",
      },
      {
        kanji: "俊敏",
        furigana: "しゅんびん",
        romaji: "shunbin",
        traducao: "agilidade; esperteza",
      },
      {
        kanji: "俊才",
        furigana: "しゅんさい",
        romaji: "shunsai",
        traducao: "gênio; talento",
      },
    ],
  },
  {
    kanji: "銭",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "ぜに", romaji: "zeni" }],
    significado: "dinheiro; moeda",
    exemplos: [
      {
        kanji: "お金",
        furigana: "おかね",
        romaji: "okane",
        traducao: "dinheiro",
      },
      {
        kanji: "小銭",
        furigana: "こぜに",
        romaji: "kozeni",
        traducao: "troco; moedas",
      },
      {
        kanji: "金銭",
        furigana: "きんせん",
        romaji: "kinsen",
        traducao: "dinheiro",
      },
    ],
  },
  {
    kanji: "渋",
    onYomi: [{ katakana: "ジュウ", romaji: "juu" }],
    kunYomi: [{ hiragana: "しぶ", romaji: "shibu" }],
    significado: "amargo; austeridade",
    exemplos: [
      {
        kanji: "渋い",
        furigana: "しぶい",
        romaji: "shibui",
        traducao: "amargo; sóbrio",
      },
      {
        kanji: "渋滞",
        furigana: "じゅうたい",
        romaji: "juutai",
        traducao: "congestionamento; engarrafamento",
      },
      {
        kanji: "渋面",
        furigana: "しぶつら",
        romaji: "shibutsura",
        traducao: "rosto sério",
      },
    ],
  },
  {
    kanji: "銃",
    onYomi: [{ katakana: "ジュウ", romaji: "juu" }],
    kunYomi: [],
    significado: "arma de fogo",
    exemplos: [
      {
        kanji: "拳銃",
        furigana: "けんじゅう",
        romaji: "kenjuu",
        traducao: "revólver",
      },
      {
        kanji: "銃撃",
        furigana: "じゅうげき",
        romaji: "juugeki",
        traducao: "tiroteio",
      },
      {
        kanji: "ライフル銃",
        furigana: "らいふるじゅう",
        romaji: "raifuru juu",
        traducao: "rifle",
      },
    ],
  },
  {
    kanji: "操",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "あやつ.る", romaji: "ayatsu.ru" }],
    significado: "manipular; controlar",
    exemplos: [
      {
        kanji: "操作",
        furigana: "そうさ",
        romaji: "sousa",
        traducao: "manipulação; controle",
      },
      {
        kanji: "自己操作",
        furigana: "じこそうさ",
        romaji: "jiko sousa",
        traducao: "autocontrole",
      },
      {
        kanji: "操る",
        furigana: "あやつる",
        romaji: "ayatsuru",
        traducao: "manipular; controlar",
      },
    ],
  },
  {
    kanji: "携",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "たずさ.える", romaji: "tazusa.eru" }],
    significado: "carregar; levar consigo",
    exemplos: [
      {
        kanji: "携帯",
        furigana: "けいたい",
        romaji: "keitai",
        traducao: "celular; telefone móvel",
      },
      {
        kanji: "携行",
        furigana: "けいこう",
        romaji: "keikou",
        traducao: "portar; carregar consigo",
      },
      {
        kanji: "持ち物を携える",
        furigana: "もちものをたずさえる",
        romaji: "mochimono o tazusaeru",
        traducao: "carregar objetos consigo",
      },
    ],
  },
  {
    kanji: "診",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "み.る", romaji: "mi.ru" }],
    significado: "examinar; diagnosticar",
    exemplos: [
      {
        kanji: "診断",
        furigana: "しんだん",
        romaji: "shindan",
        traducao: "diagnóstico",
      },
      {
        kanji: "診察",
        furigana: "しんさつ",
        romaji: "shinsatsu",
        traducao: "exame médico",
      },
      {
        kanji: "診る",
        furigana: "みる",
        romaji: "miru",
        traducao: "examinar; diagnosticar",
      },
    ],
  },
  {
    kanji: "託",
    onYomi: [{ katakana: "タク", romaji: "taku" }],
    kunYomi: [{ hiragana: "かこつ.ける", romaji: "kakotsu.keru" }],
    significado: "confiar; delegar",
    exemplos: [
      {
        kanji: "信託",
        furigana: "しんたく",
        romaji: "shintaku",
        traducao: "confiança; fideicomisso",
      },
      {
        kanji: "託す",
        furigana: "かこつける",
        romaji: "kakotsukeru",
        traducao: "confiar; delegar",
      },
      {
        kanji: "依託する",
        furigana: "いたくする",
        romaji: "itaku suru",
        traducao: "confiar em; contar com",
      },
    ],
  },
  {
    kanji: "撮",
    onYomi: [{ katakana: "サツ", romaji: "satsu" }],
    kunYomi: [{ hiragana: "と.る", romaji: "to.ru" }],
    significado: "tirar (foto); gravar (vídeo)",
    exemplos: [
      {
        kanji: "撮影",
        furigana: "さつえい",
        romaji: "satsuei",
        traducao: "fotografia; filmagem",
      },
      {
        kanji: "撮る",
        furigana: "とる",
        romaji: "toru",
        traducao: "tirar (foto); gravar (vídeo)",
      },
      {
        kanji: "撮影する",
        furigana: "さつえいする",
        romaji: "satsuei suru",
        traducao: "fotografar; filmar",
      },
    ],
  },
  {
    kanji: "誕",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "たんじる", romaji: "tanjiru" }],
    significado: "nascimento",
    exemplos: [
      {
        kanji: "誕生",
        furigana: "たんじょう",
        romaji: "tanjou",
        traducao: "nascimento",
      },
      {
        kanji: "生誕",
        furigana: "せいたん",
        romaji: "seitan",
        traducao: "nascimento",
      },
      {
        kanji: "誕生日",
        furigana: "たんじょうび",
        romaji: "tanjoubi",
        traducao: "aniversário",
      },
    ],
  },
  {
    kanji: "侵",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "おか.す", romaji: "oka.su" }],
    significado: "invadir; violar",
    exemplos: [
      {
        kanji: "侵略",
        furigana: "しんりゃく",
        romaji: "shinryaku",
        traducao: "invasão",
      },
      {
        kanji: "侵入",
        furigana: "しんにゅう",
        romaji: "shinnyuu",
        traducao: "invasão; entrada",
      },
      {
        kanji: "侵す",
        furigana: "おかす",
        romaji: "okasu",
        traducao: "invadir; violar",
      },
    ],
  },
  {
    kanji: "括",
    onYomi: [{ katakana: "カツ", romaji: "katsu" }],
    kunYomi: [{ hiragana: "くく.る", romaji: "kuku.ru" }],
    significado: "amarrar; unir",
    exemplos: [
      {
        kanji: "結括",
        furigana: "けっかつ",
        romaji: "kekatsu",
        traducao: "conclusão",
      },
      {
        kanji: "括る",
        furigana: "くくる",
        romaji: "kukuru",
        traducao: "amarrar; unir",
      },
      {
        kanji: "括り付ける",
        furigana: "くくりつける",
        romaji: "kukuritsukeru",
        traducao: "fixar; prender",
      },
    ],
  },
  {
    kanji: "謝",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "あやま.る", romaji: "ayama.ru" }],
    significado: "desculpar-se; agradecer",
    exemplos: [
      {
        kanji: "謝罪",
        furigana: "しゃざい",
        romaji: "shazai",
        traducao: "desculpas",
      },
      {
        kanji: "感謝",
        furigana: "かんしゃ",
        romaji: "kansha",
        traducao: "gratidão",
      },
      {
        kanji: "謝る",
        furigana: "あやまる",
        romaji: "ayamaru",
        traducao: "desculpar-se; agradecer",
      },
    ],
  },
  {
    kanji: "駆",
    onYomi: [{ katakana: "ク", romaji: "ku" }],
    kunYomi: [{ hiragana: "か.ける", romaji: "ka.keru" }],
    significado: "correr; apressar-se",
    exemplos: [
      {
        kanji: "駆ける",
        furigana: "かける",
        romaji: "kakeru",
        traducao: "correr; apressar-se",
      },
      {
        kanji: "追い駆ける",
        furigana: "おいかける",
        romaji: "oikakeru",
        traducao: "perseguir",
      },
      {
        kanji: "駆使する",
        furigana: "くしする",
        romaji: "kushi suru",
        traducao: "fazer uso pleno de",
      },
    ],
  },
  {
    kanji: "透",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "す.く", romaji: "su.ku" }],
    significado: "transparente; claro",
    exemplos: [
      {
        kanji: "透明",
        furigana: "とうめい",
        romaji: "toumei",
        traducao: "transparência",
      },
      {
        kanji: "透視",
        furigana: "とうし",
        romaji: "toushi",
        traducao: "visão transparente",
      },
      {
        kanji: "透かす",
        furigana: "すかす",
        romaji: "sukasu",
        traducao: "tornar transparente; clarificar",
      },
    ],
  },
  {
    kanji: "津",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "porto; local à beira-mar",
    exemplos: [
      {
        kanji: "神戸港",
        furigana: "こうべこう",
        romaji: "Koube kou",
        traducao: "porto de Kobe",
      },
      {
        kanji: "港町",
        furigana: "みなとまち",
        romaji: "minato machi",
        traducao: "cidade portuária",
      },
      {
        kanji: "港湾",
        furigana: "こうわん",
        romaji: "kouwan",
        traducao: "porto",
      },
    ],
  },
  {
    kanji: "壁",
    onYomi: [{ katakana: "ヘキ", romaji: "heki" }],
    kunYomi: [{ hiragana: "かべ", romaji: "kabe" }],
    significado: "parede",
    exemplos: [
      {
        kanji: "壁画",
        furigana: "へきが",
        romaji: "hekiga",
        traducao: "mural",
      },
      {
        kanji: "壁",
        furigana: "かべ",
        romaji: "kabe",
        traducao: "parede",
      },
      {
        kanji: "外壁",
        furigana: "がいへき",
        romaji: "gaiheki",
        traducao: "fachada",
      },
    ],
  },
  {
    kanji: "稲",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "いね", romaji: "ine" }],
    significado: "arrozal",
    exemplos: [
      {
        kanji: "稲作",
        furigana: "いなさく",
        romaji: "inasaku",
        traducao: "cultivo de arroz",
      },
      {
        kanji: "稲田",
        furigana: "いなだ",
        romaji: "inada",
        traducao: "arrozal",
      },
      {
        kanji: "稲穂",
        furigana: "いなほ",
        romaji: "inaho",
        traducao: "espiga de arroz",
      },
    ],
  },
  {
    kanji: "仮",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "かり", romaji: "kari" }],
    significado: "provisório; temporário",
    exemplos: [
      {
        kanji: "仮定",
        furigana: "かてい",
        romaji: "katei",
        traducao: "suposição; hipótese",
      },
      {
        kanji: "仮名",
        furigana: "かな",
        romaji: "kana",
        traducao: "hiragana; katakana",
      },
      {
        kanji: "仮装",
        furigana: "かそう",
        romaji: "kasou",
        traducao: "fantasia; disfarce",
      },
    ],
  },
  {
    kanji: "裂",
    onYomi: [{ katakana: "レツ", romaji: "retsu" }],
    kunYomi: [{ hiragana: "さ.く", romaji: "sa.ku" }],
    significado: "rasgar; dividir",
    exemplos: [
      {
        kanji: "分裂",
        furigana: "ぶんれつ",
        romaji: "bunretsu",
        traducao: "divisão; cisma",
      },
      {
        kanji: "裂く",
        furigana: "さく",
        romaji: "saku",
        traducao: "rasgar; dividir",
      },
      {
        kanji: "裂ける",
        furigana: "さける",
        romaji: "sakeru",
        traducao: "rasgar-se; dividir-se",
      },
    ],
  },
  {
    kanji: "敏",
    onYomi: [{ katakana: "ビン", romaji: "bin" }],
    kunYomi: [{ hiragana: "さとい", romaji: "satoi" }],
    significado: "rápido; ágil",
    exemplos: [
      {
        kanji: "敏速",
        furigana: "びんそく",
        romaji: "binsoku",
        traducao: "rapidez",
      },
      {
        kanji: "敏感",
        furigana: "びんかん",
        romaji: "binkan",
        traducao: "sensível",
      },
      {
        kanji: "敏捷",
        furigana: "びんしょう",
        romaji: "binshou",
        traducao: "agilidade",
      },
    ],
  },
  {
    kanji: "是",
    onYomi: [{ katakana: "ゼ", romaji: "ze" }],
    kunYomi: [],
    significado: "certo; correto",
    exemplos: [
      {
        kanji: "是非",
        furigana: "ぜひ",
        romaji: "zehi",
        traducao: "certamente; com certeza",
      },
      {
        kanji: "正当性",
        furigana: "せいとうせい",
        romaji: "seitousei",
        traducao: "legitimidade",
      },
      {
        kanji: "是正する",
        furigana: "ぜせいする",
        romaji: "zesei suru",
        traducao: "corrigir; retificar",
      },
    ],
  },
  {
    kanji: "排",
    onYomi: [{ katakana: "ハイ", romaji: "hai" }],
    kunYomi: [],
    significado: "excluir; rejeitar",
    exemplos: [
      {
        kanji: "排除",
        furigana: "はいじょ",
        romaji: "haijo",
        traducao: "exclusão; eliminação",
      },
      {
        kanji: "排水",
        furigana: "はいすい",
        romaji: "haisui",
        traducao: "escoamento; drenagem",
      },
      {
        kanji: "排斥する",
        furigana: "はいせきする",
        romaji: "haiseki suru",
        traducao: "rejeitar; repelir",
      },
    ],
  },
  {
    kanji: "裕",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [],
    significado: "abundância; folga",
    exemplos: [
      {
        kanji: "豊裕",
        furigana: "ほうゆう",
        romaji: "houyuu",
        traducao: "abundância",
      },
      {
        kanji: "余裕",
        furigana: "よゆう",
        romaji: "yoyuu",
        traducao: "folga; margem",
      },
      {
        kanji: "富裕",
        furigana: "ふゆう",
        romaji: "fuyuu",
        traducao: "riqueza",
      },
    ],
  },
  {
    kanji: "堅",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "かた.い", romaji: "kata.i" }],
    significado: "firme; duro",
    exemplos: [
      {
        kanji: "堅固",
        furigana: "けんご",
        romaji: "kengo",
        traducao: "firmeza; solidez",
      },
      {
        kanji: "堅実",
        furigana: "けんじつ",
        romaji: "kenjitsu",
        traducao: "sólido; confiável",
      },
      {
        kanji: "固堅",
        furigana: "かたくい",
        romaji: "kataku i",
        traducao: "rigidez",
      },
    ],
  },
  {
    kanji: "訳",
    onYomi: [{ katakana: "ヤク", romaji: "yaku" }],
    kunYomi: [{ hiragana: "わけ", romaji: "wake" }],
    significado: "tradução; razão",
    exemplos: [
      {
        kanji: "翻訳",
        furigana: "ほんやく",
        romaji: "honyaku",
        traducao: "tradução",
      },
      {
        kanji: "原因",
        furigana: "げんいん",
        romaji: "genin",
        traducao: "causa; motivo",
      },
      {
        kanji: "訳する",
        furigana: "わけする",
        romaji: "wake suru",
        traducao: "traduzir; interpretar",
      },
    ],
  },
  {
    kanji: "芝",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "grama; relva",
    exemplos: [
      {
        kanji: "芝生",
        furigana: "しばふ",
        romaji: "shibafu",
        traducao: "gramado",
      },
      {
        kanji: "芝刈り",
        furigana: "しばかり",
        romaji: "shibakari",
        traducao: "cortar a grama",
      },
      {
        kanji: "芝",
        furigana: "しば",
        romaji: "shiba",
        traducao: "grama; relva",
      },
    ],
  },
  {
    kanji: "綱",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "つな", romaji: "tsuna" }],
    significado: "corda; linha principal",
    exemplos: [
      {
        kanji: "綱領",
        furigana: "こうりょう",
        romaji: "kouryou",
        traducao: "princípios; diretrizes",
      },
      {
        kanji: "重要な綱",
        furigana: "じゅうようなつな",
        romaji: "juuyou na tsuna",
        traducao: "linha principal; ponto crucial",
      },
      {
        kanji: "首綱",
        furigana: "くびつな",
        romaji: "kubi tsuna",
        traducao: "corda; linha principal",
      },
    ],
  },
  {
    kanji: "典",
    onYomi: [{ katakana: "テン", romaji: "ten" }],
    kunYomi: [],
    significado: "código; cerimônia",
    exemplos: [
      {
        kanji: "典型",
        furigana: "てんけい",
        romaji: "tenkei",
        traducao: "modelo; exemplo",
      },
      {
        kanji: "辞典",
        furigana: "じてん",
        romaji: "jiten",
        traducao: "dicionário",
      },
      {
        kanji: "典礼",
        furigana: "てんれい",
        romaji: "tenrei",
        traducao: "cerimônia",
      },
    ],
  },
  {
    kanji: "賀",
    onYomi: [{ katakana: "ガ", romaji: "ga" }],
    kunYomi: [{ hiragana: "が", romaji: "ga" }],
    significado: "felicitar",
    exemplos: [
      {
        kanji: "祝賀",
        furigana: "しゅくが",
        romaji: "shukuga",
        traducao: "celebração",
      },
      {
        kanji: "賀状",
        furigana: "がじょう",
        romaji: "gajou",
        traducao: "cartão de felicitações",
      },
      {
        kanji: "賀",
        furigana: "が",
        romaji: "ga",
        traducao: "felicitar",
      },
    ],
  },
  {
    kanji: "扱",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "あつか.う", romaji: "atsuka.u" }],
    significado: "manusear; tratar",
    exemplos: [
      {
        kanji: "取扱",
        furigana: "とりあつか",
        romaji: "toriatsuka",
        traducao: "manuseio",
      },
      {
        kanji: "扱う",
        furigana: "あつかう",
        romaji: "atsukau",
        traducao: "manusear; tratar",
      },
      {
        kanji: "取り扱う",
        furigana: "とりあつかう",
        romaji: "toriatsukau",
        traducao: "lidar com; tratar",
      },
    ],
  },
  {
    kanji: "顧",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "かえり.みる", romaji: "kaeri.miru" }],
    significado: "olhar para trás; preocupar-se",
    exemplos: [
      {
        kanji: "顧客",
        furigana: "こきゃく",
        romaji: "kokyaku",
        traducao: "cliente",
      },
      {
        kanji: "顧問",
        furigana: "こもん",
        romaji: "komon",
        traducao: "consultor; conselheiro",
      },
      {
        kanji: "顧みる",
        furigana: "かえりみる",
        romaji: "kaerimiru",
        traducao: "olhar para trás; preocupar-se",
      },
    ],
  },
  {
    kanji: "弘",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "vasto; amplo",
    exemplos: [
      {
        kanji: "広弘",
        furigana: "こうこう",
        romaji: "koukou",
        traducao: "amplo",
      },
      {
        kanji: "弘大な",
        furigana: "こうだいな",
        romaji: "koudai na",
        traducao: "vasto; amplo",
      },
      {
        kanji: "弘める",
        furigana: "ひろめる",
        romaji: "hiromeru",
        traducao: "divulgar; promover",
      },
    ],
  },
  {
    kanji: "看",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "cuidar; enfermagem",
    exemplos: [
      {
        kanji: "看護",
        furigana: "かんご",
        romaji: "kango",
        traducao: "cuidados de enfermagem",
      },
      {
        kanji: "看板",
        furigana: "かんばん",
        romaji: "kanban",
        traducao: "placa; letreiro",
      },
      {
        kanji: "看護師",
        furigana: "かんごし",
        romaji: "kangoshi",
        traducao: "enfermeiro(a)",
      },
    ],
  },
  {
    kanji: "訟",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "litígio; processo",
    exemplos: [
      {
        kanji: "訴訟",
        furigana: "そしょう",
        romaji: "soshou",
        traducao: "processo judicial",
      },
      {
        kanji: "告訴する",
        furigana: "こくそする",
        romaji: "kokuso suru",
        traducao: "denunciar; processar",
      },
      {
        kanji: "起訴",
        furigana: "きそ",
        romaji: "kiso",
        traducao: "acusação; denúncia",
      },
    ],
  },
  {
    kanji: "戒",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "いまし.める", romaji: "imashi.meru" }],
    significado: "advertir; avisar",
    exemplos: [
      {
        kanji: "警戒",
        furigana: "けいかい",
        romaji: "keikai",
        traducao: "vigilância; precaução",
      },
      {
        kanji: "戒める",
        furigana: "いましめる",
        romaji: "imashimeru",
        traducao: "advertir; avisar",
      },
      {
        kanji: "戒律",
        furigana: "かいりつ",
        romaji: "kairitsu",
        traducao: "regra; disciplina",
      },
    ],
  },
  {
    kanji: "祉",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "bem-estar; felicidade",
    exemplos: [
      {
        kanji: "福祉",
        furigana: "ふくし",
        romaji: "fukushi",
        traducao: "bem-estar; assistência social",
      },
      {
        kanji: "社会福祉",
        furigana: "しゃかいふくし",
        romaji: "shakai fukushi",
        traducao: "bem-estar social",
      },
      {
        kanji: "福祉施設",
        furigana: "ふくししせつ",
        romaji: "fukushi shisetsu",
        traducao: "instituição de assistência social",
      },
    ],
  },
  {
    kanji: "誉",
    onYomi: [{ katakana: "ヨ", romaji: "yo" }],
    kunYomi: [{ hiragana: "ほま.れ", romaji: "homa.re" }],
    significado: "honra; reputação",
    exemplos: [
      {
        kanji: "名誉",
        furigana: "めいよ",
        romaji: "meiyo",
        traducao: "honra; prestígio",
      },
      {
        kanji: "光誉",
        furigana: "こうよ",
        romaji: "kouyo",
        traducao: "glória; esplendor",
      },
      {
        kanji: "名誉な",
        furigana: "めいよな",
        romaji: "meiyo na",
        traducao: "honroso",
      },
    ],
  },
  {
    kanji: "歓",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "よろこ.ぶ", romaji: "yoroko.bu" }],
    significado: "alegria; prazer",
    exemplos: [
      {
        kanji: "歓迎",
        furigana: "かんげい",
        romaji: "kangei",
        traducao: "boas-vindas",
      },
      {
        kanji: "歓喜",
        furigana: "かんき",
        romaji: "kanki",
        traducao: "júbilo; felicidade",
      },
      {
        kanji: "歓声",
        furigana: "かんせい",
        romaji: "kansei",
        traducao: "aplausos; vivas",
      },
    ],
  },
  {
    kanji: "奏",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "かな.でる", romaji: "kana.deru" }],
    significado: "tocar; executar (música)",
    exemplos: [
      {
        kanji: "演奏",
        furigana: "えんそう",
        romaji: "ensou",
        traducao: "performance musical",
      },
      {
        kanji: "合奏",
        furigana: "がっそう",
        romaji: "gassou",
        traducao: "música de conjunto",
      },
      {
        kanji: "奏でる",
        furigana: "かなでる",
        romaji: "kanaderu",
        traducao: "tocar; executar (música)",
      },
    ],
  },
  {
    kanji: "勧",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "すす.める", romaji: "susume.ru" }],
    significado: "recomendar; encorajar",
    exemplos: [
      {
        kanji: "勧誘",
        furigana: "かんゆう",
        romaji: "kanyuu",
        traducao: "convite; recrutamento",
      },
      {
        kanji: "勧告",
        furigana: "かんこく",
        romaji: "kankoku",
        traducao: "recomendação",
      },
      {
        kanji: "勧める",
        furigana: "すすめる",
        romaji: "susumeru",
        traducao: "recomendar; encorajar",
      },
    ],
  },
  {
    kanji: "騒",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "さわ.ぐ", romaji: "sawa.gu" }],
    significado: "fazer barulho; agitar",
    exemplos: [
      {
        kanji: "騒音",
        furigana: "そうおん",
        romaji: "souon",
        traducao: "ruído; barulho",
      },
      {
        kanji: "騒動",
        furigana: "そうどう",
        romaji: "soudou",
        traducao: "confusão; alvoroço",
      },
      {
        kanji: "騒ぐ",
        furigana: "さわぐ",
        romaji: "sawagu",
        traducao: "fazer barulho; agitar",
      },
    ],
  },
  {
    kanji: "閥",
    onYomi: [{ katakana: "バツ", romaji: "batsu" }],
    kunYomi: [],
    significado: "clã; facção",
    exemplos: [
      {
        kanji: "派閥",
        furigana: "はばつ",
        romaji: "habatsu",
        traducao: "facção; grupo",
      },
      {
        kanji: "閥族",
        furigana: "ばつぞく",
        romaji: "batsuzoku",
        traducao: "clã; tribo",
      },
      {
        kanji: "一閥",
        furigana: "いちばつ",
        romaji: "ichibatsu",
        traducao: "uma facção",
      },
    ],
  },
  {
    kanji: "甲",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "primeiro; proteção",
    exemplos: [
      {
        kanji: "甲状腺",
        furigana: "こうじょうせん",
        romaji: "koujousen",
        traducao: "glândula tireoide",
      },
      {
        kanji: "甲高い",
        furigana: "こうだかい",
        romaji: "koudakai",
        traducao: "alto; estridente",
      },
      {
        kanji: "一甲",
        furigana: "いっこう",
        romaji: "ikkou",
        traducao: "primeiro",
      },
    ],
  },
  {
    kanji: "縄",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "なわ", romaji: "nawa" }],
    significado: "corda",
    exemplos: [
      {
        kanji: "縄跳び",
        furigana: "なわとび",
        romaji: "nawatobi",
        traducao: "pular corda",
      },
      {
        kanji: "縄文時代",
        furigana: "じょうもんじだい",
        romaji: "joumon jidai",
        traducao: "período Jomon",
      },
      {
        kanji: "綱縄",
        furigana: "つななわ",
        romaji: "tsuna nawa",
        traducao: "corda",
      },
    ],
  },
  {
    kanji: "郷",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "ごう", romaji: "gou" }],
    significado: "aldeia; cidade natal",
    exemplos: [
      {
        kanji: "郷愁",
        furigana: "きょうしゅう",
        romaji: "kyoushuu",
        traducao: "nostalgia; saudade",
      },
      {
        kanji: "郷土",
        furigana: "きょうど",
        romaji: "kyoudo",
        traducao: "terra natal; região",
      },
      {
        kanji: "故郷",
        furigana: "こきょう",
        romaji: "kokyou",
        traducao: "terra natal",
      },
    ],
  },
  {
    kanji: "揺",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "ゆ.れる", romaji: "yu.reru" }],
    significado: "balançar; sacudir",
    exemplos: [
      {
        kanji: "揺れる",
        furigana: "ゆれる",
        romaji: "yureru",
        traducao: "balançar; sacudir",
      },
      {
        kanji: "不安定",
        furigana: "ふあんてい",
        romaji: "fuantai",
        traducao: "instável; inseguro",
      },
      {
        kanji: "揺さぶる",
        furigana: "ゆさぶる",
        romaji: "yusaburu",
        traducao: "abalado; agitado",
      },
    ],
  },
  {
    kanji: "免",
    onYomi: [{ katakana: "メン", romaji: "men" }],
    kunYomi: [{ hiragana: "まぬか.れる", romaji: "manuka.reru" }],
    significado: "isento; dispensado",
    exemplos: [
      {
        kanji: "免除",
        furigana: "めんじょ",
        romaji: "menjo",
        traducao: "isenção; dispensa",
      },
      {
        kanji: "免許",
        furigana: "めんきょ",
        romaji: "menkyo",
        traducao: "licença; habilitação",
      },
      {
        kanji: "免れる",
        furigana: "まぬかれる",
        romaji: "manukareru",
        traducao: "escapar; ser dispensado",
      },
    ],
  },
  {
    kanji: "既",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "すで.に", romaji: "sude.ni" }],
    significado: "já; anteriormente",
    exemplos: [
      {
        kanji: "既成",
        furigana: "きせい",
        romaji: "kisei",
        traducao: "já existente; estabelecido",
      },
      {
        kanji: "既知",
        furigana: "きち",
        romaji: "kichi",
        traducao: "já conhecido",
      },
      {
        kanji: "既に",
        furigana: "すでに",
        romaji: "sude ni",
        traducao: "já; anteriormente",
      },
    ],
  },
  {
    kanji: "薦",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "すす.める", romaji: "susume.ru" }],
    significado: "recomendar",
    exemplos: [
      {
        kanji: "推薦",
        furigana: "すいせん",
        romaji: "suisen",
        traducao: "recomendação",
      },
      {
        kanji: "推薦状",
        furigana: "すいせんじょう",
        romaji: "suisenjou",
        traducao: "carta de recomendação",
      },
      {
        kanji: "薦める",
        furigana: "すすめる",
        romaji: "susumeru",
        traducao: "recomendar",
      },
    ],
  },
  {
    kanji: "隣",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [{ hiragana: "となり", romaji: "tonari" }],
    significado: "vizinho",
    exemplos: [
      {
        kanji: "隣人",
        furigana: "りんじん",
        romaji: "rinjin",
        traducao: "vizinho",
      },
      {
        kanji: "近隣",
        furigana: "きんりん",
        romaji: "kinrin",
        traducao: "vizinhança",
      },
      {
        kanji: "隣り合う",
        furigana: "となりあう",
        romaji: "tonari au",
        traducao: "ser vizinho de",
      },
    ],
  },
  {
    kanji: "華",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "はな", romaji: "hana" }],
    significado: "flor; elegância",
    exemplos: [
      {
        kanji: "華やかな",
        furigana: "はなやかな",
        romaji: "hanayaka na",
        traducao: "esplêndido; glamouroso",
      },
      {
        kanji: "華族",
        furigana: "かぞく",
        romaji: "kazoku",
        traducao: "nobreza; aristocracia",
      },
      {
        kanji: "華麗な",
        furigana: "かれいな",
        romaji: "karei na",
        traducao: "elegante; magnífico",
      },
    ],
  },
  {
    kanji: "範",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [],
    significado: "modelo; exemplo",
    exemplos: [
      {
        kanji: "模範",
        furigana: "もはん",
        romaji: "mohan",
        traducao: "modelo; exemplar",
      },
      {
        kanji: "範囲",
        furigana: "はんい",
        romaji: "han'i",
        traducao: "âmbito; escopo",
      },
      {
        kanji: "教育の範囲",
        furigana: "きょういくのはんい",
        romaji: "kyouiku no han'i",
        traducao: "âmbito da educação",
      },
    ],
  },
  {
    kanji: "隠",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [{ hiragana: "かく.す", romaji: "kaku.su" }],
    significado: "esconder; ocultar",
    exemplos: [
      {
        kanji: "隠れる",
        furigana: "かくれる",
        romaji: "kakureru",
        traducao: "esconder-se",
      },
      {
        kanji: "秘密に隠す",
        furigana: "ひみつにかくす",
        romaji: "himitsu ni kakusu",
        traducao: "esconder em segredo",
      },
      {
        kanji: "隠し事",
        furigana: "かくしごと",
        romaji: "kakushigoto",
        traducao: "segredo; ocultação",
      },
    ],
  },
  {
    kanji: "徳",
    onYomi: [{ katakana: "トク", romaji: "toku" }],
    kunYomi: [],
    significado: "virtude; mérito",
    exemplos: [
      {
        kanji: "道徳",
        furigana: "どうとく",
        romaji: "doutoku",
        traducao: "ética; moral",
      },
      {
        kanji: "善徳",
        furigana: "ぜんとく",
        romaji: "zentoku",
        traducao: "bondade; virtude",
      },
      {
        kanji: "徳行",
        furigana: "とくこう",
        romaji: "tokukou",
        traducao: "conduta virtuosa",
      },
    ],
  },
  {
    kanji: "哲",
    onYomi: [{ katakana: "テツ", romaji: "tetsu" }],
    kunYomi: [],
    significado: "filosofia; sábio",
    exemplos: [
      {
        kanji: "哲学",
        furigana: "てつがく",
        romaji: "tetsugaku",
        traducao: "filosofia",
      },
      {
        kanji: "哲学者",
        furigana: "てつがくしゃ",
        romaji: "tetsugakusha",
        traducao: "filósofo",
      },
      {
        kanji: "哲人",
        furigana: "てつじん",
        romaji: "tetsujin",
        traducao: "sábio; erudito",
      },
    ],
  },
  {
    kanji: "杉",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [{ hiragana: "すぎ", romaji: "sugi" }],
    significado: "cedro",
    exemplos: [
      {
        kanji: "杉の木",
        furigana: "すぎのき",
        romaji: "sugi no ki",
        traducao: "árvore de cedro",
      },
      {
        kanji: "杉林",
        furigana: "すぎばやし",
        romaji: "sugibayashi",
        traducao: "floresta de cedro",
      },
      {
        kanji: "杉板",
        furigana: "すぎいた",
        romaji: "sugiita",
        traducao: "prancha de cedro",
      },
    ],
  },
  {
    kanji: "釈",
    onYomi: [{ katakana: "シャク", romaji: "shaku" }],
    kunYomi: [],
    significado: "explicar; esclarecer",
    exemplos: [
      {
        kanji: "釈明",
        furigana: "しゃくめい",
        romaji: "shakumei",
        traducao: "explicação; esclarecimento",
      },
      {
        kanji: "解釈",
        furigana: "かいしゃく",
        romaji: "kaishaku",
        traducao: "interpretação",
      },
      {
        kanji: "釈放",
        furigana: "しゃくほう",
        romaji: "shakuhou",
        traducao: "libertação; soltura",
      },
    ],
  },
  {
    kanji: "己",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "おのれ", romaji: "onore" }],
    significado: "eu; a si mesmo",
    exemplos: [
      {
        kanji: "自己",
        furigana: "じこ",
        romaji: "jiko",
        traducao: "eu; a si mesmo",
      },
      {
        kanji: "己の力",
        furigana: "おのれのちから",
        romaji: "onore no chikara",
        traducao: "próprio poder",
      },
      {
        kanji: "己に厳しい",
        furigana: "おのれにきびしい",
        romaji: "onore ni kibishii",
        traducao: "exigente consigo mesmo",
      },
    ],
  },
  {
    kanji: "妥",
    onYomi: [{ katakana: "ダ", romaji: "da" }],
    kunYomi: [],
    significado: "adequado; apropriado",
    exemplos: [
      {
        kanji: "妥当",
        furigana: "だとう",
        romaji: "datou",
        traducao: "adequado; válido",
      },
      {
        kanji: "妥協",
        furigana: "だきょう",
        romaji: "dakyou",
        traducao: "compromisso",
      },
      {
        kanji: "妥結",
        furigana: "だけつ",
        romaji: "daketsu",
        traducao: "acordo; conclusão",
      },
    ],
  },
  {
    kanji: "威",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [],
    significado: "autoridade; prestígio",
    exemplos: [
      {
        kanji: "威力",
        furigana: "いりょく",
        romaji: "iryoku",
        traducao: "poder; força",
      },
      {
        kanji: "威圧",
        furigana: "いあつ",
        romaji: "iatsu",
        traducao: "intimidação; pressão",
      },
      {
        kanji: "威嚇",
        furigana: "いかく",
        romaji: "ikaku",
        traducao: "ameaça; intimidação",
      },
    ],
  },
  {
    kanji: "豪",
    onYomi: [{ katakana: "ゴウ", romaji: "gou" }],
    kunYomi: [],
    significado: "grande; magnífico",
    exemplos: [
      {
        kanji: "豪華",
        furigana: "ごうか",
        romaji: "gouka",
        traducao: "luxo; esplendor",
      },
      {
        kanji: "豪雨",
        furigana: "ごうう",
        romaji: "gouu",
        traducao: "chuva forte",
      },
      {
        kanji: "豪快",
        furigana: "ごうかい",
        romaji: "goukai",
        traducao: "vigoroso; entusiasmado",
      },
    ],
  },
  {
    kanji: "熊",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [{ hiragana: "くま", romaji: "kuma" }],
    significado: "urso",
    exemplos: [
      {
        kanji: "熊",
        furigana: "くま",
        romaji: "kuma",
        traducao: "urso",
      },
      {
        kanji: "熊手",
        furigana: "くまで",
        romaji: "kumade",
        traducao: "garra de urso",
      },
      {
        kanji: "熊本県",
        furigana: "くまもとけん",
        romaji: "Kumamoto-ken",
        traducao: "Prefeitura de Kumamoto",
      },
    ],
  },
  {
    kanji: "滞",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "とどこお.る", romaji: "todoko.o.ru" }],
    significado: "atrasar; estagnar",
    exemplos: [
      {
        kanji: "滞在",
        furigana: "たいざい",
        romaji: "taizai",
        traducao: "estadia; permanência",
      },
      {
        kanji: "滞る",
        furigana: "とどこおる",
        romaji: "todokooru",
        traducao: "atrasar; estagnar",
      },
      {
        kanji: "滞納",
        furigana: "たいのう",
        romaji: "tainou",
        traducao: "inadimplência",
      },
    ],
  },
  {
    kanji: "微",
    onYomi: [{ katakana: "ビ", romaji: "bi" }],
    kunYomi: [],
    significado: "leve; sutil",
    exemplos: [
      {
        kanji: "微妙",
        furigana: "びみょう",
        romaji: "bimyou",
        traducao: "sutil; delicado",
      },
      {
        kanji: "微笑",
        furigana: "びしょう",
        romaji: "bishou",
        traducao: "sorriso",
      },
      {
        kanji: "微細",
        furigana: "びさい",
        romaji: "bisai",
        traducao: "minúcia; detalhe",
      },
    ],
  },
  {
    kanji: "隆",
    onYomi: [{ katakana: "リュウ", romaji: "ryuu" }],
    kunYomi: [],
    significado: "proeminente; alto",
    exemplos: [
      {
        kanji: "隆盛",
        furigana: "りゅうせい",
        romaji: "ryuusei",
        traducao: "prosperidade; florescimento",
      },
      {
        kanji: "隆起",
        furigana: "りゅうき",
        romaji: "ryuuki",
        traducao: "protuberância; elevação",
      },
      {
        kanji: "隆重",
        furigana: "りゅうちょう",
        romaji: "ryuuchou",
        traducao: "solene; grandioso",
      },
    ],
  },
  {
    kanji: "症",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "sintoma; doença",
    exemplos: [
      {
        kanji: "症状",
        furigana: "しょうじょう",
        romaji: "shoujou",
        traducao: "sintoma",
      },
      {
        kanji: "疾病",
        furigana: "しっぺい",
        romaji: "shippai",
        traducao: "doença",
      },
      {
        kanji: "症例",
        furigana: "しょうれい",
        romaji: "shourei",
        traducao: "caso clínico",
      },
    ],
  },
  {
    kanji: "暫",
    onYomi: [{ katakana: "ザン", romaji: "zan" }],
    kunYomi: [{ hiragana: "しばら.く", romaji: "shibara.ku" }],
    significado: "temporário; por enquanto",
    exemplos: [
      {
        kanji: "暫時",
        furigana: "ざんじ",
        romaji: "zanji",
        traducao: "temporário; provisório",
      },
      {
        kanji: "暫く",
        furigana: "しばらく",
        romaji: "shibaraku",
        traducao: "por um tempo; por enquanto",
      },
      {
        kanji: "一時的に暫定する",
        furigana: "いちじてきにざんていする",
        romaji: "ichijiteki ni zantei suru",
        traducao: "provisoriamente estabelecido",
      },
    ],
  },
  {
    kanji: "忠",
    onYomi: [{ katakana: "チュウ", romaji: "chuu" }],
    kunYomi: [{ hiragana: "ただし", romaji: "tadashi" }],
    significado: "lealdade; fidelidade",
    exemplos: [
      {
        kanji: "忠誠心",
        furigana: "ちゅうせいしん",
        romaji: "chuuseishin",
        traducao: "lealdade; devoção",
      },
      {
        kanji: "忠実な",
        furigana: "ちゅうじつな",
        romaji: "chuujitsuna",
        traducao: "fiel; dedicado",
      },
      {
        kanji: "忠告",
        furigana: "ちゅうこく",
        romaji: "chuukoku",
        traducao: "aviso; conselho",
      },
    ],
  },
  {
    kanji: "倉",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "くら", romaji: "kura" }],
    significado: "depósito; celeiro",
    exemplos: [
      {
        kanji: "倉庫",
        furigana: "そうこ",
        romaji: "souko",
        traducao: "armazém; depósito",
      },
      {
        kanji: "穀倉",
        furigana: "こくそう",
        romaji: "kokusou",
        traducao: "celeiro",
      },
      {
        kanji: "倉敷",
        furigana: "くらしき",
        romaji: "Kurashiki",
        traducao: "Kurashiki (nome de uma cidade)",
      },
    ],
  },
  {
    kanji: "彦",
    onYomi: [],
    kunYomi: [{ hiragana: "ひこ", romaji: "hiko" }],
    significado: "rapaz; jovem",
    exemplos: [
      {
        kanji: "美彦",
        furigana: "よしひこ",
        romaji: "Yoshihiko",
        traducao: "Yoshihiko (nome próprio)",
      },
      {
        kanji: "彦星",
        furigana: "ひこぼし",
        romaji: "hikoboshi",
        traducao: "estrela Altair",
      },
      {
        kanji: "彦根市",
        furigana: "ひこねし",
        romaji: "Hikone-shi",
        traducao: "Hikone (nome de uma cidade)",
      },
    ],
  },
  {
    kanji: "肝",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "きも", romaji: "kimo" }],
    significado: "fígado; coragem; essência",
    exemplos: [
      {
        kanji: "肝心",
        furigana: "かんじん",
        romaji: "kanjin",
        traducao: "essencial; crucial",
      },
      {
        kanji: "肝臓",
        furigana: "かんぞう",
        romaji: "kanzou",
        traducao: "fígado",
      },
      {
        kanji: "胆肝",
        furigana: "たんかん",
        romaji: "tankan",
        traducao: "coragem; valentia",
      },
    ],
  },
  {
    kanji: "喚",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "わめ.く", romaji: "wame.ku" }],
    significado: "gritar; clamar",
    exemplos: [
      {
        kanji: "叫喚",
        furigana: "きょうかん",
        romaji: "kyoukan",
        traducao: "grito; clamor",
      },
      {
        kanji: "喚問",
        furigana: "かんもん",
        romaji: "kanmon",
        traducao: "interrogação; questionamento",
      },
      {
        kanji: "声を喚く",
        furigana: "こえをわめく",
        romaji: "koe o wameku",
        traducao: "gritar",
      },
    ],
  },
  {
    kanji: "沿",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "そ.う", romaji: "so.u" }],
    significado: "ao longo de",
    exemplos: [
      {
        kanji: "沿道",
        furigana: "えんどう",
        romaji: "endou",
        traducao: "ao longo do caminho",
      },
      {
        kanji: "沿線",
        furigana: "えんせん",
        romaji: "ensen",
        traducao: "ao longo da linha",
      },
      {
        kanji: "沿岸",
        furigana: "えんがん",
        romaji: "engan",
        traducao: "ao longo da costa",
      },
    ],
  },
  {
    kanji: "妙",
    onYomi: [{ katakana: "ミョウ", romaji: "myou" }],
    kunYomi: [
      { hiragana: "たえ", romaji: "tae" },
      { hiragana: "みょう", romaji: "myou" },
    ],
    significado: "maravilhoso; estranho",
    exemplos: [
      {
        kanji: "奇妙",
        furigana: "きみょう",
        romaji: "kimyou",
        traducao: "estranho; peculiar",
      },
      {
        kanji: "妙案",
        furigana: "みょうあん",
        romaji: "myouan",
        traducao: "ideia brilhante",
      },
      {
        kanji: "妙技",
        furigana: "みょうぎ",
        romaji: "myougi",
        traducao: "habilidade excepcional",
      },
    ],
  },
  {
    kanji: "唱",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "とな.える", romaji: "tona.eru" }],
    significado: "cantar; recitar",
    exemplos: [
      {
        kanji: "唱歌",
        furigana: "しょうか",
        romaji: "shouka",
        traducao: "canção",
      },
      {
        kanji: "合唱",
        furigana: "がっしょう",
        romaji: "gasshou",
        traducao: "coro; canto em conjunto",
      },
      {
        kanji: "唱和",
        furigana: "しょうわ",
        romaji: "shouwa",
        traducao: "recitação em coro",
      },
    ],
  },
  {
    kanji: "阿",
    onYomi: [],
    kunYomi: [],
    significado: "prefixo; inicial",
    exemplos: [
      {
        kanji: "阿弥陀仏",
        furigana: "あみだぶつ",
        romaji: "Amida Butsu",
        traducao: "Buda Amida",
      },
      {
        kanji: "阿蘇山",
        furigana: "あそさん",
        romaji: "Asosan",
        traducao: "Monte Aso",
      },
      {
        kanji: "阿部寛",
        furigana: "あべひろし",
        romaji: "Abe Hiroshi",
        traducao: "Hiroshi Abe (nome próprio)",
      },
    ],
  },
  {
    kanji: "索",
    onYomi: [{ katakana: "サク", romaji: "saku" }],
    kunYomi: [],
    significado: "pesquisa; busca",
    exemplos: [
      {
        kanji: "索引",
        furigana: "さくいん",
        romaji: "sakuin",
        traducao: "índice; referência",
      },
      {
        kanji: "検索",
        furigana: "けんさく",
        romaji: "kensaku",
        traducao: "pesquisar; buscar",
      },
      {
        kanji: "索道",
        furigana: "さくどう",
        romaji: "sakudou",
        traducao: "transporte por cabo",
      },
    ],
  },
  {
    kanji: "誠",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "まこと", romaji: "makoto" }],
    significado: "sinceridade; verdade",
    exemplos: [
      {
        kanji: "誠実",
        furigana: "せいじつ",
        romaji: "seijitsu",
        traducao: "sinceridade; honestidade",
      },
      {
        kanji: "誠意",
        furigana: "せいい",
        romaji: "sei'i",
        traducao: "boa vontade; sinceridade",
      },
      {
        kanji: "真誠",
        furigana: "しんせい",
        romaji: "shinsei",
        traducao: "sinceridade absoluta",
      },
    ],
  },
  {
    kanji: "襲",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [{ hiragana: "おそ.う", romaji: "oso.u" }],
    significado: "atacar; surpreender",
    exemplos: [
      {
        kanji: "襲撃",
        furigana: "しゅうげき",
        romaji: "shuugeki",
        traducao: "ataque; assalto",
      },
      {
        kanji: "襲来",
        furigana: "しゅうらい",
        romaji: "shuurai",
        traducao: "invasão; chegada",
      },
      {
        kanji: "奇襲",
        furigana: "きしゅう",
        romaji: "kishuu",
        traducao: "ataque surpresa",
      },
    ],
  },
  {
    kanji: "懇",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [{ hiragana: "ねんご.ろ", romaji: "nengo.ro" }],
    significado: "solicitar; pedir",
    exemplos: [
      {
        kanji: "懇意",
        furigana: "こんい",
        romaji: "kon'i",
        traducao: "amizade; intimidade",
      },
      {
        kanji: "懇願",
        furigana: "こんがん",
        romaji: "kongan",
        traducao: "súplica; pedido",
      },
      {
        kanji: "懇請",
        furigana: "こんせい",
        romaji: "konsei",
        traducao: "solicitação; apelo",
      },
    ],
  },
  {
    kanji: "俳",
    onYomi: [{ katakana: "ハイ", romaji: "hai" }],
    kunYomi: [],
    significado: "ator; haikai",
    exemplos: [
      {
        kanji: "俳優",
        furigana: "はいゆう",
        romaji: "haiyuu",
        traducao: "ator",
      },
      {
        kanji: "俳句",
        furigana: "はいく",
        romaji: "haiku",
        traducao: "haicai",
      },
      {
        kanji: "俳人",
        furigana: "はいじん",
        romaji: "haijin",
        traducao: "haicaísta",
      },
    ],
  },
  {
    kanji: "柄",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [{ hiragana: "がら", romaji: "gara" }],
    significado: "alça; padrão",
    exemplos: [
      {
        kanji: "人柄",
        furigana: "ひとがら",
        romaji: "hitogara",
        traducao: "personalidade; caráter",
      },
      {
        kanji: "柄織り",
        furigana: "がらおり",
        romaji: "garaori",
        traducao: "tecido estampado",
      },
      {
        kanji: "柄杓",
        furigana: "ひしゃく",
        romaji: "hishaku",
        traducao: "concha (utensílio para água)",
      },
    ],
  },
  {
    kanji: "驚",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "おどろ.く", romaji: "odoro.ku" }],
    significado: "surpreender; espantar",
    exemplos: [
      {
        kanji: "驚愕",
        furigana: "きょうがく",
        romaji: "kyougaku",
        traducao: "choque; surpresa",
      },
      {
        kanji: "驚異",
        furigana: "きょうい",
        romaji: "kyoui",
        traducao: "maravilha; assombro",
      },
      {
        kanji: "驚くべき",
        furigana: "おどろくべき",
        romaji: "odoroku beki",
        traducao: "surpreendente",
      },
    ],
  },
  {
    kanji: "麻",
    onYomi: [{ katakana: "マ", romaji: "ma" }],
    kunYomi: [
      { hiragana: "あさ", romaji: "asa" },
      { hiragana: "あさ.ぐ", romaji: "asa.gu" },
    ],
    significado: "cânhamo; maconha",
    exemplos: [
      {
        kanji: "麻布",
        furigana: "あさぬの",
        romaji: "asanuno",
        traducao: "tecido de linho",
      },
      {
        kanji: "大麻",
        furigana: "たいま",
        romaji: "taima",
        traducao: "maconha",
      },
      {
        kanji: "麻痺",
        furigana: "まひ",
        romaji: "mahi",
        traducao: "paralisia",
      },
    ],
  },
  {
    kanji: "李",
    onYomi: [],
    kunYomi: [],
    significado: "ameixa",
    exemplos: [
      {
        kanji: "李子",
        furigana: "すもも",
        romaji: "sumomo",
        traducao: "ameixa",
      },
      {
        kanji: "梅李",
        furigana: "ばいり",
        romaji: "bairi",
        traducao: "ameixa",
      },
      {
        kanji: "李白",
        furigana: "りはく",
        romaji: "Rihaku",
        traducao: "Li Bai (nome próprio)",
      },
    ],
  },
  {
    kanji: "浩",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "vasto; grande",
    exemplos: [
      {
        kanji: "浩宮",
        furigana: "こうぐう",
        romaji: "kouguu",
        traducao: "palácio imperial",
      },
      {
        kanji: "広浩",
        furigana: "こうひろ",
        romaji: "Kouhiro",
        traducao: "Kouhiro (nome próprio)",
      },
      {
        kanji: "浩大",
        furigana: "こうだい",
        romaji: "koudai",
        traducao: "vasto; imenso",
      },
    ],
  },
  {
    kanji: "剤",
    onYomi: [{ katakana: "ザイ", romaji: "zai" }],
    kunYomi: [],
    significado: "remédio; droga",
    exemplos: [
      {
        kanji: "薬剤",
        furigana: "やくざい",
        romaji: "yakuzai",
        traducao: "medicamento",
      },
      {
        kanji: "毒剤",
        furigana: "どくざい",
        romaji: "dokuzai",
        traducao: "veneno",
      },
      {
        kanji: "剤形",
        furigana: "ざいけい",
        romaji: "zaikei",
        traducao: "forma farmacêutica",
      },
    ],
  },
  {
    kanji: "瀬",
    onYomi: [],
    kunYomi: [{ hiragana: "せ", romaji: "se" }],
    significado: "correnteza; cachoeira",
    exemplos: [
      {
        kanji: "急流瀬",
        furigana: "きゅうりゅうせ",
        romaji: "kyuuryuuse",
        traducao: "correnteza",
      },
      {
        kanji: "急瀬",
        furigana: "きゅうせ",
        romaji: "kyuuse",
        traducao: "correnteza",
      },
      {
        kanji: "渦瀬",
        furigana: "うずせ",
        romaji: "uzuse",
        traducao: "redemoinho",
      },
    ],
  },
  {
    kanji: "趣",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [
      { hiragana: "おもむき", romaji: "omomuki" },
      { hiragana: "おもむ.く", romaji: "omomu.ku" },
    ],
    significado: "interesse; charme",
    exemplos: [
      {
        kanji: "趣味",
        furigana: "しゅみ",
        romaji: "shumi",
        traducao: "hobby; interesse",
      },
      {
        kanji: "趣向",
        furigana: "しゅこう",
        romaji: "shukou",
        traducao: "intenção; propósito",
      },
      {
        kanji: "趣",
        furigana: "おもむき",
        romaji: "omomuki",
        traducao: "charme; estilo",
      },
    ],
  },
  {
    kanji: "陥",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "おちい.る", romaji: "ochii.ru" }],
    significado: "cair em; cair em uma armadilha",
    exemplos: [
      {
        kanji: "陥落",
        furigana: "かんらく",
        romaji: "kanraku",
        traducao: "queda; capitulação",
      },
      {
        kanji: "陥没",
        furigana: "かんぼつ",
        romaji: "kanbotsu",
        traducao: "afundamento; colapso",
      },
      {
        kanji: "陥る",
        furigana: "おちいる",
        romaji: "ochiiru",
        traducao: "cair em; cair em uma armadilha",
      },
    ],
  },
  {
    kanji: "斎",
    onYomi: [],
    kunYomi: [],
    significado: "jejum; ritual; santuário",
    exemplos: [
      {
        kanji: "斎場",
        furigana: "さいじょう",
        romaji: "saijou",
        traducao: "capela; sala de funeral",
      },
      {
        kanji: "斎藤",
        furigana: "さいとう",
        romaji: "Saitou",
        traducao: "Saito (nome próprio)",
      },
      {
        kanji: "斎戒",
        furigana: "さいかい",
        romaji: "saikai",
        traducao: "jejum",
      },
    ],
  },
  {
    kanji: "貫",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "つらぬ.く", romaji: "tsuranu.ku" }],
    significado: "atravessar; penetrar",
    exemplos: [
      {
        kanji: "貫通",
        furigana: "かんつう",
        romaji: "kantsuu",
        traducao: "penetração; perfuração",
      },
      {
        kanji: "貫徹",
        furigana: "かんてつ",
        romaji: "kantetsu",
        traducao: "perseverança; dedicação",
      },
      {
        kanji: "一貫",
        furigana: "いっかん",
        romaji: "ikkan",
        traducao: "coerência; consistência",
      },
    ],
  },
  {
    kanji: "仙",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [],
    significado: "eremita; imortal",
    exemplos: [
      {
        kanji: "仙人",
        furigana: "せんにん",
        romaji: "sennin",
        traducao: "eremita; sábio",
      },
      {
        kanji: "仙台",
        furigana: "せんだい",
        romaji: "Sendai",
        traducao: "Sendai (nome de uma cidade)",
      },
      {
        kanji: "仙境",
        furigana: "せんきょう",
        romaji: "senkyou",
        traducao: "paraíso; reino dos imortais",
      },
    ],
  },
  {
    kanji: "慰",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "なぐさ.める", romaji: "nagusa.meru" }],
    significado: "consolar; confortar",
    exemplos: [
      {
        kanji: "慰安",
        furigana: "いあん",
        romaji: "ian",
        traducao: "conforto; bem-estar",
      },
      {
        kanji: "慰謝",
        furigana: "いしゃ",
        romaji: "isha",
        traducao: "compensação; desculpas",
      },
      {
        kanji: "慰める",
        furigana: "なぐさめる",
        romaji: "nagusameru",
        traducao: "consolar; confortar",
      },
    ],
  },
  {
    kanji: "序",
    onYomi: [{ katakana: "ジョ", romaji: "jo" }],
    kunYomi: [{ hiragana: "ついで", romaji: "tsuide" }],
    significado: "prelúdio; introdução",
    exemplos: [
      {
        kanji: "序曲",
        furigana: "じょきょく",
        romaji: "jokyoku",
        traducao: "abertura (música)",
      },
      {
        kanji: "序列",
        furigana: "じょれつ",
        romaji: "joretsu",
        traducao: "classificação; ordem",
      },
      {
        kanji: "序説",
        furigana: "じょせつ",
        romaji: "josetsu",
        traducao: "introdução; prólogo",
      },
    ],
  },
  {
    kanji: "旬",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [{ hiragana: "しゅん", romaji: "shun" }],
    significado: "década; estação",
    exemplos: [
      {
        kanji: "旬刊",
        furigana: "しゅんかん",
        romaji: "shunkan",
        traducao: "periódico quinzenal",
      },
      {
        kanji: "旬報",
        furigana: "しゅんぽう",
        romaji: "shunpou",
        traducao: "relatório quinzenal",
      },
      {
        kanji: "旬間",
        furigana: "しゅんかん",
        romaji: "shunkan",
        traducao: "dez dias; período de dez dias",
      },
    ],
  },
  {
    kanji: "兼",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [
      { hiragana: "か.ねる", romaji: "ka.neru" },
      { hiragana: "か.ね", romaji: "ka.ne" },
    ],
    significado: "combinar; simultaneamente",
    exemplos: [
      {
        kanji: "兼務",
        furigana: "けんむ",
        romaji: "kenmu",
        traducao: "dupla função; ocupação simultânea",
      },
      {
        kanji: "兼業",
        furigana: "けんぎょう",
        romaji: "kengyou",
        traducao: "emprego secundário",
      },
      {
        kanji: "兼ねる",
        furigana: "かねる",
        romaji: "kaneru",
        traducao: "fazer simultaneamente; combinar",
      },
    ],
  },
  {
    kanji: "聖",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "ひじり", romaji: "hijiri" }],
    significado: "sagrado; santo",
    exemplos: [
      {
        kanji: "聖書",
        furigana: "せいしょ",
        romaji: "seisho",
        traducao: "Bíblia",
      },
      {
        kanji: "聖地",
        furigana: "せいち",
        romaji: "seichi",
        traducao: "local sagrado",
      },
      {
        kanji: "聖人",
        furigana: "せいじん",
        romaji: "seijin",
        traducao: "santo; pessoa santa",
      },
    ],
  },
  {
    kanji: "旨",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "むね", romaji: "mune" }],
    significado: "propósito; intenção",
    exemplos: [
      {
        kanji: "目的の旨",
        furigana: "もくてきのむね",
        romaji: "mokuteki no mune",
        traducao: "propósito; intenção",
      },
      {
        kanji: "旨い",
        furigana: "うまい",
        romaji: "umai",
        traducao: "delicioso; saboroso",
      },
      {
        kanji: "旨味",
        furigana: "うまみ",
        romaji: "umami",
        traducao: "sabor; gosto",
      },
    ],
  },
  {
    kanji: "柳",
    onYomi: [{ katakana: "リュウ", romaji: "ryuu" }],
    kunYomi: [{ hiragana: "やなぎ", romaji: "yanagi" }],
    significado: "salgueiro",
    exemplos: [
      {
        kanji: "柳川",
        furigana: "やながわ",
        romaji: "Yanagawa",
        traducao: "Yanagawa (nome de um lugar)",
      },
      {
        kanji: "柳町",
        furigana: "やなぎちょう",
        romaji: "yanagichou",
        traducao: "bairro de salgueiros",
      },
      {
        kanji: "柳",
        furigana: "やなぎ",
        romaji: "yanagi",
        traducao: "salgueiro",
      },
    ],
  },
  {
    kanji: "舎",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "やど.る", romaji: "yado.ru" }],
    significado: "casa; edifício",
    exemplos: [
      {
        kanji: "寄宿舎",
        furigana: "きしゅくしゃ",
        romaji: "kishukusha",
        traducao: "alojamento; dormitório",
      },
      {
        kanji: "舎弟",
        furigana: "しゃてい",
        romaji: "shatei",
        traducao: "irmão de gangue",
      },
      {
        kanji: "石造舎",
        furigana: "せきぞうしゃ",
        romaji: "sekizousha",
        traducao: "edifício de pedra",
      },
    ],
  },
  {
    kanji: "偽",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [{ hiragana: "いつわ.る", romaji: "itsuwa.ru" }],
    significado: "falso; falsificação",
    exemplos: [
      {
        kanji: "偽名",
        furigana: "ぎめい",
        romaji: "gimei",
        traducao: "nome falso",
      },
      {
        kanji: "偽装",
        furigana: "ぎそう",
        romaji: "gisou",
        traducao: "disfarce; camuflagem",
      },
      {
        kanji: "偽善",
        furigana: "ぎぜん",
        romaji: "gizen",
        traducao: "hipocrisia",
      },
    ],
  },
  {
    kanji: "較",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "くら.べる", romaji: "kura.beru" }],
    significado: "comparar; contrastar",
    exemplos: [
      {
        kanji: "比較",
        furigana: "ひかく",
        romaji: "hikaku",
        traducao: "comparação",
      },
      {
        kanji: "競較",
        furigana: "きょうかく",
        romaji: "kyoukaku",
        traducao: "competição; rivalidade",
      },
      {
        kanji: "較差",
        furigana: "かくさ",
        romaji: "kakusa",
        traducao: "diferença; disparidade",
      },
    ],
  },
  {
    kanji: "覇",
    onYomi: [{ katakana: "ハ", romaji: "ha" }],
    kunYomi: [],
    significado: "dominação; supremacia",
    exemplos: [
      {
        kanji: "覇権",
        furigana: "はけん",
        romaji: "haken",
        traducao: "hegemonia; supremacia",
      },
      {
        kanji: "絶対覇者",
        furigana: "ぜったいはしゃ",
        romaji: "zettaihasha",
        traducao: "vencedor absoluto",
      },
      {
        kanji: "覇者",
        furigana: "はしゃ",
        romaji: "hasha",
        traducao: "vencedor; campeão",
      },
    ],
  },
  {
    kanji: "詳",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "くわ.しい", romaji: "kuwa.shii" }],
    significado: "detalhado; minucioso",
    exemplos: [
      {
        kanji: "詳細",
        furigana: "しょうさい",
        romaji: "shousai",
        traducao: "detalhes; minúcias",
      },
      {
        kanji: "詳報",
        furigana: "しょうほう",
        romaji: "shouhou",
        traducao: "relatório detalhado",
      },
      {
        kanji: "詳述",
        furigana: "しょうじゅつ",
        romaji: "shoujutsu",
        traducao: "descrição detalhada",
      },
    ],
  },
  {
    kanji: "抵",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "resistir; opor-se",
    exemplos: [
      {
        kanji: "抵抗",
        furigana: "ていこう",
        romaji: "teikou",
        traducao: "resistência; oposição",
      },
      {
        kanji: "抵当",
        furigana: "ていとう",
        romaji: "teitou",
        traducao: "penhor; garantia",
      },
      {
        kanji: "抵触",
        furigana: "ていしょく",
        romaji: "teishoku",
        traducao: "violação; infringir",
      },
    ],
  },
  {
    kanji: "脅",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "おびや.かす", romaji: "obiya.kasu" }],
    significado: "ameaçar; intimidar",
    exemplos: [
      {
        kanji: "脅迫",
        furigana: "きょうはく",
        romaji: "kyouhaku",
        traducao: "ameaça; intimidação",
      },
      {
        kanji: "脅威",
        furigana: "きょうい",
        romaji: "kyoui",
        traducao: "ameaça; perigo",
      },
      {
        kanji: "脅す",
        furigana: "おどす",
        romaji: "odosu",
        traducao: "ameaçar; intimidar",
      },
    ],
  },
  {
    kanji: "茂",
    onYomi: [{ katakana: "モ", romaji: "mo" }],
    kunYomi: [{ hiragana: "しげ.る", romaji: "shige.ru" }],
    significado: "crescer exuberantemente; abundante",
    exemplos: [
      {
        kanji: "茂る",
        furigana: "しげる",
        romaji: "shigeru",
        traducao: "crescer exuberantemente; florescer",
      },
      {
        kanji: "茂み",
        furigana: "しげみ",
        romaji: "shigemi",
        traducao: "mata; floresta densa",
      },
      {
        kanji: "茂盛",
        furigana: "ももり",
        romaji: "momori",
        traducao: "prosperar; florescer",
      },
    ],
  },
  {
    kanji: "犠",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [],
    significado: "sacrifício; vítima",
    exemplos: [
      {
        kanji: "犠牲",
        furigana: "ぎせい",
        romaji: "gisei",
        traducao: "sacrifício",
      },
      {
        kanji: "犠打",
        furigana: "ぎだ",
        romaji: "gida",
        traducao: "rebatedor atingido por arremesso",
      },
      {
        kanji: "犠牲者",
        furigana: "ぎせいしゃ",
        romaji: "giseisha",
        traducao: "vítima",
      },
    ],
  },
  {
    kanji: "旗",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "はた", romaji: "hata" }],
    significado: "bandeira",
    exemplos: [
      {
        kanji: "国旗",
        furigana: "こっき",
        romaji: "kokki",
        traducao: "bandeira nacional",
      },
      {
        kanji: "旗手",
        furigana: "はたて",
        romaji: "hatate",
        traducao: "porta-bandeira",
      },
      {
        kanji: "青旗",
        furigana: "あおはた",
        romaji: "aohata",
        traducao: "bandeira azul",
      },
    ],
  },
  {
    kanji: "距",
    onYomi: [{ katakana: "キョ", romaji: "kyo" }],
    kunYomi: [{ hiragana: "ちか.い", romaji: "chika.i" }],
    significado: "distância",
    exemplos: [
      {
        kanji: "距離",
        furigana: "きょり",
        romaji: "kyori",
        traducao: "distância",
      },
      {
        kanji: "近距離",
        furigana: "きんきょり",
        romaji: "kinkyori",
        traducao: "curta distância",
      },
      {
        kanji: "長距離",
        furigana: "ちょうきょり",
        romaji: "choukyori",
        traducao: "longa distância",
      },
    ],
  },
  {
    kanji: "雅",
    onYomi: [{ katakana: "ガ", romaji: "ga" }],
    kunYomi: [{ hiragana: "みや.び", romaji: "miya.bi" }],
    significado: "elegância; graciosidade",
    exemplos: [
      {
        kanji: "風雅",
        furigana: "ふうが",
        romaji: "fuuga",
        traducao: "elegância; requinte",
      },
      {
        kanji: "雅号",
        furigana: "がごう",
        romaji: "gagou",
        traducao: "nome artístico; pseudônimo",
      },
      {
        kanji: "雅",
        furigana: "みやび",
        romaji: "miyabi",
        traducao: "elegância; graciosidade",
      },
    ],
  },
  {
    kanji: "飾",
    onYomi: [{ katakana: "ショク", romaji: "shoku" }],
    kunYomi: [{ hiragana: "かざ.る", romaji: "kaza.ru" }],
    significado: "decorar; ornamentar",
    exemplos: [
      {
        kanji: "飾り",
        furigana: "かざり",
        romaji: "kazari",
        traducao: "decoração; enfeite",
      },
      {
        kanji: "飾る",
        furigana: "かざる",
        romaji: "kazaru",
        traducao: "decorar; ornamentar",
      },
      {
        kanji: "飾磨",
        furigana: "かざりま",
        romaji: "kazarima",
        traducao: "Kazarima (nome de um lugar)",
      },
    ],
  },
  {
    kanji: "網",
    onYomi: [{ katakana: "モウ", romaji: "mou" }],
    kunYomi: [{ hiragana: "あみ", romaji: "ami" }],
    significado: "rede",
    exemplos: [
      {
        kanji: "インターネット",
        furigana: "いんたーねっと",
        romaji: "intaanetto",
        traducao: "Internet",
      },
      {
        kanji: "網戸",
        furigana: "あみど",
        romaji: "amido",
        traducao: "tela; tela mosquiteira",
      },
      {
        kanji: "魚網",
        furigana: "うおあみ",
        romaji: "uoami",
        traducao: "rede de pesca",
      },
    ],
  },
  {
    kanji: "竜",
    onYomi: [{ katakana: "リュウ", romaji: "ryuu" }],
    kunYomi: [{ hiragana: "たつ", romaji: "tatsu" }],
    significado: "dragão",
    exemplos: [
      {
        kanji: "竜巻",
        furigana: "たつまき",
        romaji: "tatsumaki",
        traducao: "tornado",
      },
      {
        kanji: "金竜",
        furigana: "きんりゅう",
        romaji: "kinryuu",
        traducao: "dragão dourado",
      },
      {
        kanji: "竜",
        furigana: "りゅう",
        romaji: "ryuu",
        traducao: "dragão",
      },
    ],
  },
  {
    kanji: "詩",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "うた", romaji: "uta" }],
    significado: "poesia; poema",
    exemplos: [
      {
        kanji: "詩人",
        furigana: "しじん",
        romaji: "shijin",
        traducao: "poeta",
      },
      {
        kanji: "詩集",
        furigana: "ししゅう",
        romaji: "shishuu",
        traducao: "coleção de poemas",
      },
      {
        kanji: "詩",
        furigana: "し",
        romaji: "shi",
        traducao: "poesia; poema",
      },
    ],
  },
  {
    kanji: "繁",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "しげ.る", romaji: "shige.ru" }],
    significado: "próspero; florescente",
    exemplos: [
      {
        kanji: "繁忙",
        furigana: "はんぼう",
        romaji: "hanbou",
        traducao: "ocupado; movimentado",
      },
      {
        kanji: "繁盛",
        furigana: "はんじょう",
        romaji: "hanjou",
        traducao: "prosperidade; florescimento",
      },
      {
        kanji: "繁栄",
        furigana: "はんえい",
        romaji: "han'ei",
        traducao: "prosperidade; sucesso",
      },
    ],
  },
  {
    kanji: "翼",
    onYomi: [{ katakana: "ヨク", romaji: "yoku" }],
    kunYomi: [{ hiragana: "つばさ", romaji: "tsubasa" }],
    significado: "asa",
    exemplos: [
      {
        kanji: "羽翼",
        furigana: "うよく",
        romaji: "uyoku",
        traducao: "asas; plumagem",
      },
      {
        kanji: "翼賛",
        furigana: "よくさん",
        romaji: "yokusen",
        traducao: "apoio; adesão",
      },
      {
        kanji: "翼",
        furigana: "つばさ",
        romaji: "tsubasa",
        traducao: "asa",
      },
    ],
  },
  {
    kanji: "潟",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "かた", romaji: "kata" }],
    significado: "lagoa; baía",
    exemplos: [
      {
        kanji: "石潟",
        furigana: "いしがた",
        romaji: "ishigata",
        traducao: "baía de pedras",
      },
      {
        kanji: "潟湖",
        furigana: "かたこ",
        romaji: "katako",
        traducao: "lagoa",
      },
      {
        kanji: "津潟",
        furigana: "つがた",
        romaji: "tsugata",
        traducao: "baía",
      },
    ],
  },
  {
    kanji: "敵",
    onYomi: [{ katakana: "テキ", romaji: "teki" }],
    kunYomi: [{ hiragana: "かたき", romaji: "kataki" }],
    significado: "inimigo; adversário",
    exemplos: [
      {
        kanji: "敵対",
        furigana: "てきたい",
        romaji: "tekitai",
        traducao: "hostilidade; oposição",
      },
      {
        kanji: "敵国",
        furigana: "てきこく",
        romaji: "tekikoku",
        traducao: "país inimigo",
      },
      {
        kanji: "敵",
        furigana: "かたき",
        romaji: "kataki",
        traducao: "inimigo",
      },
    ],
  },
  {
    kanji: "魅",
    onYomi: [{ katakana: "ミ", romaji: "mi" }],
    kunYomi: [{ hiragana: "み.せる", romaji: "mi.seru" }],
    significado: "atraente; fascinante",
    exemplos: [
      {
        kanji: "魅力",
        furigana: "みりょく",
        romaji: "miryoku",
        traducao: "encanto; atração",
      },
      {
        kanji: "魅惑",
        furigana: "みわく",
        romaji: "miwaku",
        traducao: "fascinação; encanto",
      },
      {
        kanji: "魅力的",
        furigana: "みりょくてき",
        romaji: "miryokuteki",
        traducao: "atraente; cativante",
      },
    ],
  },
  {
    kanji: "嫌",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "きら.い", romaji: "kira.i" }],
    significado: "odiar; detestar",
    exemplos: [
      {
        kanji: "嫌悪",
        furigana: "けんお",
        romaji: "ken'o",
        traducao: "repugnância; aversão",
      },
      {
        kanji: "嫌悪感",
        furigana: "けんおかん",
        romaji: "ken'okan",
        traducao: "sentimento de repugnância",
      },
      {
        kanji: "嫌",
        furigana: "きらい",
        romaji: "kirai",
        traducao: "ódio; antipatia",
      },
    ],
  },
  {
    kanji: "斉",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [],
    significado: "igual; uniforme",
    exemplos: [
      {
        kanji: "一斉",
        furigana: "いっせい",
        romaji: "issei",
        traducao: "uníssono; simultâneo",
      },
      {
        kanji: "統一斉",
        furigana: "とういつせい",
        romaji: "touitsusei",
        traducao: "uniformidade; consistência",
      },
      {
        kanji: "斉行",
        furigana: "せいこう",
        romaji: "seikou",
        traducao: "prática comum; realização conjunta",
      },
    ],
  },
  {
    kanji: "敷",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "し.く", romaji: "shi.ku" }],
    significado: "espalhar; colocar",
    exemplos: [
      {
        kanji: "敷地",
        furigana: "しきち",
        romaji: "shikichi",
        traducao: "terreno; propriedade",
      },
      {
        kanji: "敷布団",
        furigana: "しきぶとん",
        romaji: "shikibuton",
        traducao: "colchão de futon",
      },
      {
        kanji: "敷く",
        furigana: "しく",
        romaji: "shiku",
        traducao: "espalhar; colocar",
      },
    ],
  },
  {
    kanji: "擁",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "よう.する", romaji: "you.suru" }],
    significado: "abrigar; proteger",
    exemplos: [
      {
        kanji: "擁護",
        furigana: "ようご",
        romaji: "yougo",
        traducao: "apoio; proteção",
      },
      {
        kanji: "擁立",
        furigana: "ようりつ",
        romaji: "youritsu",
        traducao: "elevar ao poder",
      },
      {
        kanji: "擁する",
        furigana: "ようする",
        romaji: "yousuru",
        traducao: "possuir; abrigar",
      },
    ],
  },
  {
    kanji: "圏",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [],
    significado: "zona; área",
    exemplos: [
      {
        kanji: "地域圏",
        furigana: "ちいきけん",
        romaji: "chiikiken",
        traducao: "região; área",
      },
      {
        kanji: "周辺圏",
        furigana: "しゅうへんけん",
        romaji: "shuuhenken",
        traducao: "área circundante",
      },
      {
        kanji: "圏内",
        furigana: "けんない",
        romaji: "kennai",
        traducao: "dentro da área",
      },
    ],
  },
  {
    kanji: "酸",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [{ hiragana: "す.い", romaji: "su.i" }],
    significado: "ácido",
    exemplos: [
      {
        kanji: "酸性",
        furigana: "さんせい",
        romaji: "sansei",
        traducao: "acidez",
      },
      {
        kanji: "酸味",
        furigana: "さんみ",
        romaji: "sanmi",
        traducao: "acidez; sabor ácido",
      },
      {
        kanji: "酸",
        furigana: "すい",
        romaji: "sui",
        traducao: "ácido",
      },
    ],
  },
  {
    kanji: "罰",
    onYomi: [{ katakana: "バツ", romaji: "batsu" }],
    kunYomi: [{ hiragana: "ばつ", romaji: "batsu" }],
    significado: "punição; castigo",
    exemplos: [
      {
        kanji: "罰金",
        furigana: "ばっきん",
        romaji: "bakkinn",
        traducao: "multa",
      },
      {
        kanji: "罰則",
        furigana: "ばっそく",
        romaji: "bassoku",
        traducao: "penalidade; sanção",
      },
      {
        kanji: "罰",
        furigana: "ばつ",
        romaji: "batsu",
        traducao: "punição; castigo",
      },
    ],
  },
  {
    kanji: "滅",
    onYomi: [{ katakana: "メツ", romaji: "metsu" }],
    kunYomi: [{ hiragana: "ほろ.びる", romaji: "horo.biru" }],
    significado: "destruir; extinguir",
    exemplos: [
      {
        kanji: "滅亡",
        furigana: "めつぼう",
        romaji: "metsubou",
        traducao: "colapso; ruína",
      },
      {
        kanji: "滅多",
        furigana: "めった",
        romaji: "metta",
        traducao: "raramente; dificilmente",
      },
      {
        kanji: "滅ぼす",
        furigana: "ほろぼす",
        romaji: "horobosu",
        traducao: "destruir; arruinar",
      },
    ],
  },
  {
    kanji: "礎",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [{ hiragana: "いしずえ", romaji: "ishizue" }],
    significado: "fundação; base",
    exemplos: [
      {
        kanji: "基礎",
        furigana: "きそ",
        romaji: "kiso",
        traducao: "fundação; base",
      },
      {
        kanji: "礎石",
        furigana: "いしずえ",
        romaji: "ishizue",
        traducao: "pedra fundamental",
      },
      {
        kanji: "礎",
        furigana: "いしずえ",
        romaji: "ishizue",
        traducao: "fundação; base",
      },
    ],
  },
  {
    kanji: "腐",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "くさ.る", romaji: "kusa.ru" }],
    significado: "apodrecer; deteriorar",
    exemplos: [
      {
        kanji: "腐敗",
        furigana: "ふはい",
        romaji: "fuai",
        traducao: "corrupção; decomposição",
      },
      {
        kanji: "腐食",
        furigana: "ふしょく",
        romaji: "fushoku",
        traducao: "corrosão; deterioração",
      },
      {
        kanji: "腐る",
        furigana: "くさる",
        romaji: "kusa.ru",
        traducao: "apodrecer; deteriorar",
      },
    ],
  },
  {
    kanji: "脚",
    onYomi: [{ katakana: "キャク", romaji: "kyaku" }],
    kunYomi: [{ hiragana: "あし", romaji: "ashi" }],
    significado: "perna",
    exemplos: [
      {
        kanji: "前脚",
        furigana: "ぜんきゃく",
        romaji: "zenkyaku",
        traducao: "pata dianteira",
      },
      {
        kanji: "脚本",
        furigana: "きゃくほん",
        romaji: "kyakuhon",
        traducao: "roteiro",
      },
      {
        kanji: "脚",
        furigana: "あし",
        romaji: "ashi",
        traducao: "perna",
      },
    ],
  },
  {
    kanji: "潮",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "しお", romaji: "shio" }],
    significado: "maré; corrente",
    exemplos: [
      {
        kanji: "潮流",
        furigana: "ちょうりゅう",
        romaji: "chouryuu",
        traducao: "fluxo; tendência",
      },
      {
        kanji: "干潮",
        furigana: "ひしお",
        romaji: "hishio",
        traducao: "maré baixa",
      },
      {
        kanji: "潮",
        furigana: "しお",
        romaji: "shio",
        traducao: "maré; corrente",
      },
    ],
  },
  {
    kanji: "梅",
    onYomi: [{ katakana: "バイ", romaji: "bai" }],
    kunYomi: [{ hiragana: "うめ", romaji: "ume" }],
    significado: "ameixa",
    exemplos: [
      {
        kanji: "梅雨",
        furigana: "つゆ",
        romaji: "tsuyu",
        traducao: "estação chuvosa",
      },
      {
        kanji: "梅干し",
        furigana: "うめぼし",
        romaji: "umeboshi",
        traducao: "ameixa em conserva",
      },
      {
        kanji: "梅",
        furigana: "うめ",
        romaji: "ume",
        traducao: "ameixa",
      },
    ],
  },
  {
    kanji: "尽",
    onYomi: [
      { katakana: "ジン", romaji: "jin" },
      { katakana: "サン", romaji: "san" },
    ],
    kunYomi: [
      { hiragana: "つ.くす", romaji: "tsu.kusu" },
      { hiragana: "つ.きる", romaji: "tsu.kiru" },
    ],
    significado: "esgotar; esgotado",
    exemplos: [
      {
        kanji: "尽力",
        furigana: "じんりょく",
        romaji: "jinryoku",
        traducao: "esforço máximo",
      },
      {
        kanji: "尽くす",
        furigana: "つくす",
        romaji: "tsukusu",
        traducao: "dedicar-se; esgotar",
      },
      {
        kanji: "尽きる",
        furigana: "つきる",
        romaji: "tsukiru",
        traducao: "esgotar-se; acabar",
      },
    ],
  },
  {
    kanji: "僕",
    onYomi: [{ katakana: "ボク", romaji: "boku" }],
    kunYomi: [],
    significado: "eu (pronome pessoal)",
    exemplos: [
      {
        kanji: "僕",
        furigana: "ぼく",
        romaji: "boku",
        traducao: "eu (pronome pessoal)",
      },
      {
        kanji: "僕ら",
        furigana: "ぼくら",
        romaji: "bokura",
        traducao: "nós (pronome pessoal)",
      },
      {
        kanji: "僕の",
        furigana: "ぼくの",
        romaji: "boku no",
        traducao: "meu",
      },
    ],
  },
  {
    kanji: "桜",
    onYomi: [{ katakana: "オウ", romaji: "ou" }],
    kunYomi: [{ hiragana: "さくら", romaji: "sakura" }],
    significado: "cerejeira; flor de cerejeira",
    exemplos: [
      {
        kanji: "桜前線",
        furigana: "さくらぜんせん",
        romaji: "sakurazensen",
        traducao: "frente de flores de cerejeira",
      },
      {
        kanji: "桜吹雪",
        furigana: "さくらふぶき",
        romaji: "sakurafubuki",
        traducao: "chuva de pétalas de cerejeira",
      },
      {
        kanji: "桜",
        furigana: "さくら",
        romaji: "sakura",
        traducao: "cerejeira; flor de cerejeira",
      },
    ],
  },
  {
    kanji: "滑",
    onYomi: [{ katakana: "カツ", romaji: "katsu" }],
    kunYomi: [{ hiragana: "すべ.る", romaji: "sube.ru" }],
    significado: "escorregar; deslizar",
    exemplos: [
      {
        kanji: "滑走",
        furigana: "かっそう",
        romaji: "kassou",
        traducao: "corrida; decolagem",
      },
      {
        kanji: "滑降",
        furigana: "かっこう",
        romaji: "kakkou",
        traducao: "esqui downhill",
      },
      {
        kanji: "滑る",
        furigana: "すべる",
        romaji: "suberu",
        traducao: "escorregar; deslizar",
      },
    ],
  },
  {
    kanji: "孤",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "ひと.り", romaji: "hito.ri" }],
    significado: "solitário; isolado",
    exemplos: [
      {
        kanji: "孤独",
        furigana: "こどく",
        romaji: "kodoku",
        traducao: "solidão; isolamento",
      },
      {
        kanji: "孤立",
        furigana: "こりつ",
        romaji: "koritsu",
        traducao: "isolamento; estar sozinho",
      },
      {
        kanji: "孤",
        furigana: "ひとり",
        romaji: "hitori",
        traducao: "solitário; isolado",
      },
    ],
  },
  {
    kanji: "炎",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "ほのお", romaji: "honoo" }],
    significado: "chama; fogo",
    exemplos: [
      {
        kanji: "炎上",
        furigana: "えんじょう",
        romaji: "enjou",
        traducao: "incêndio; ser incendiado",
      },
      {
        kanji: "炎症",
        furigana: "えんしょう",
        romaji: "enshou",
        traducao: "inflamação",
      },
      {
        kanji: "炎",
        furigana: "ほのお",
        romaji: "honoo",
        traducao: "chama; fogo",
      },
    ],
  },
  {
    kanji: "賠",
    onYomi: [{ katakana: "バイ", romaji: "bai" }],
    kunYomi: [{ hiragana: "ばい.する", romaji: "bai.suru" }],
    significado: "indenização; compensação",
    exemplos: [
      {
        kanji: "賠償",
        furigana: "ばいしょう",
        romaji: "baishou",
        traducao: "indenização; compensação",
      },
      {
        kanji: "損害賠償",
        furigana: "そんがいばいしょう",
        romaji: "songaibaishou",
        traducao: "indenização por danos",
      },
      {
        kanji: "賠",
        furigana: "ばい",
        romaji: "bai",
        traducao: "indenização; compensação",
      },
    ],
  },
  {
    kanji: "句",
    onYomi: [{ katakana: "ク", romaji: "ku" }],
    kunYomi: [],
    significado: "frase; verso",
    exemplos: [
      {
        kanji: "俳句",
        furigana: "はいく",
        romaji: "haiku",
        traducao: "haiku (forma de poesia japonesa)",
      },
      {
        kanji: "詩句",
        furigana: "しく",
        romaji: "shiku",
        traducao: "verso",
      },
      {
        kanji: "句",
        furigana: "く",
        romaji: "ku",
        traducao: "frase; verso",
      },
    ],
  },
  {
    kanji: "鋼",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "はがね", romaji: "hagane" }],
    significado: "aço",
    exemplos: [
      {
        kanji: "鉄鋼",
        furigana: "てっこう",
        romaji: "tekko",
        traducao: "ferro e aço",
      },
      {
        kanji: "鋼材",
        furigana: "こうざい",
        romaji: "kouzai",
        traducao: "material de aço",
      },
      {
        kanji: "鋼",
        furigana: "はがね",
        romaji: "hagane",
        traducao: "aço",
      },
    ],
  },
  {
    kanji: "頑",
    onYomi: [{ katakana: "ガン", romaji: "gan" }],
    kunYomi: [{ hiragana: "かたく", romaji: "kataku" }],
    significado: "teimoso; obstinado",
    exemplos: [
      {
        kanji: "頑張る",
        furigana: "がんばる",
        romaji: "ganbaru",
        traducao: "fazer o melhor; esforçar-se",
      },
      {
        kanji: "頑固",
        furigana: "がんこ",
        romaji: "ganko",
        traducao: "teimoso; obstinado",
      },
      {
        kanji: "頑",
        furigana: "かたく",
        romaji: "kataku",
        traducao: "teimoso; obstinado",
      },
    ],
  },
  {
    kanji: "鎖",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [{ hiragana: "くさり", romaji: "kusari" }],
    significado: "corrente; cadeia",
    exemplos: [
      {
        kanji: "連鎖",
        furigana: "れんさ",
        romaji: "rensa",
        traducao: "cadeia; sequência",
      },
      {
        kanji: "鎖国",
        furigana: "さこく",
        romaji: "sakoku",
        traducao: "política de isolamento",
      },
      {
        kanji: "鎖",
        furigana: "くさり",
        romaji: "kusari",
        traducao: "corrente; cadeia",
      },
    ],
  },
  {
    kanji: "彩",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "いろど.る", romaji: "irodo.ru" }],
    significado: "colorir; colorido",
    exemplos: [
      {
        kanji: "彩色",
        furigana: "さいしょく",
        romaji: "saishoku",
        traducao: "coloração",
      },
      {
        kanji: "彩る",
        furigana: "いろどる",
        romaji: "irodoru",
        traducao: "colorir; embelezar",
      },
      {
        kanji: "彩",
        furigana: "いろ",
        romaji: "iro",
        traducao: "cor; colorido",
      },
    ],
  },
  {
    kanji: "摩",
    onYomi: [{ katakana: "マ", romaji: "ma" }],
    kunYomi: [{ hiragana: "さ.する", romaji: "sa.suru" }],
    significado: "esfregar; friccionar",
    exemplos: [
      {
        kanji: "摩擦",
        furigana: "まさつ",
        romaji: "masatsu",
        traducao: "fricção; atrito",
      },
      {
        kanji: "摩耗",
        furigana: "まもう",
        romaji: "mamou",
        traducao: "desgaste; erosão",
      },
      {
        kanji: "摩",
        furigana: "さ",
        romaji: "sa",
        traducao: "esfregar; friccionar",
      },
    ],
  },
  {
    kanji: "励",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [{ hiragana: "はげ.む", romaji: "hage.mu" }],
    significado: "incentivar; encorajar",
    exemplos: [
      {
        kanji: "励行",
        furigana: "れいこう",
        romaji: "reikou",
        traducao: "perseverança; diligência",
      },
      {
        kanji: "励ます",
        furigana: "はげます",
        romaji: "hagemasu",
        traducao: "incentivar; encorajar",
      },
      {
        kanji: "励",
        furigana: "はげ",
        romaji: "hage",
        traducao: "incentivar; encorajar",
      },
    ],
  },
  {
    kanji: "縦",
    onYomi: [{ katakana: "ジュウ", romaji: "juu" }],
    kunYomi: [{ hiragana: "たて", romaji: "tate" }],
    significado: "vertical; altura",
    exemplos: [
      {
        kanji: "縦書き",
        furigana: "たてがき",
        romaji: "tategaki",
        traducao: "escrita vertical",
      },
      {
        kanji: "縦横",
        furigana: "じゅうおう",
        romaji: "juuou",
        traducao: "vertical e horizontal",
      },
      {
        kanji: "縦",
        furigana: "たて",
        romaji: "tate",
        traducao: "vertical; altura",
      },
    ],
  },
  {
    kanji: "輝",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "かがや.く", romaji: "kagaya.ku" }],
    significado: "brilho; radiante",
    exemplos: [
      {
        kanji: "輝度",
        furigana: "きど",
        romaji: "kido",
        traducao: "brilho",
      },
      {
        kanji: "輝く",
        furigana: "かがやく",
        romaji: "kagayaku",
        traducao: "brilhar; reluzir",
      },
      {
        kanji: "輝",
        furigana: "かがや",
        romaji: "kagaya",
        traducao: "brilho; radiante",
      },
    ],
  },
  {
    kanji: "蓄",
    onYomi: [{ katakana: "チク", romaji: "chiku" }],
    kunYomi: [{ hiragana: "たくわ.える", romaji: "takuwa.eru" }],
    significado: "acumular; armazenar",
    exemplos: [
      {
        kanji: "蓄積",
        furigana: "ちくせき",
        romaji: "chikuseki",
        traducao: "acúmulo; acumulação",
      },
      {
        kanji: "蓄電池",
        furigana: "ちくでんち",
        romaji: "chikudenchi",
        traducao: "bateria; acumulador",
      },
      {
        kanji: "蓄",
        furigana: "たくわえ",
        romaji: "takuwae",
        traducao: "acumular; armazenar",
      },
    ],
  },
  {
    kanji: "軸",
    onYomi: [{ katakana: "ジク", romaji: "jiku" }],
    kunYomi: [{ hiragana: "じく", romaji: "jiku" }],
    significado: "eixo; pivô",
    exemplos: [
      {
        kanji: "中心軸",
        furigana: "ちゅうしんじく",
        romaji: "chuushinjiku",
        traducao: "eixo central",
      },
      {
        kanji: "軸",
        furigana: "じく",
        romaji: "jiku",
        traducao: "eixo; pivô",
      },
    ],
  },
  {
    kanji: "巡",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [{ hiragana: "めぐ.る", romaji: "megu.ru" }],
    significado: "patrulhar; circundar",
    exemplos: [
      {
        kanji: "巡回",
        furigana: "じゅんかい",
        romaji: "junkai",
        traducao: "patrulhar; circundar",
      },
      {
        kanji: "巡る",
        furigana: "めぐる",
        romaji: "meguru",
        traducao: "patrulhar; circundar",
      },
      {
        kanji: "巡",
        furigana: "めぐ",
        romaji: "megu",
        traducao: "patrulhar; circundar",
      },
    ],
  },
  {
    kanji: "稼",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "かせ.ぐ", romaji: "kase.gu" }],
    significado: "ganhar dinheiro; lucrar",
    exemplos: [
      {
        kanji: "稼業",
        furigana: "かぎょう",
        romaji: "kagyou",
        traducao: "ocupação; negócio",
      },
      {
        kanji: "稼ぐ",
        furigana: "かせぐ",
        romaji: "kasegu",
        traducao: "ganhar dinheiro; lucrar",
      },
      {
        kanji: "稼",
        furigana: "かせ",
        romaji: "kase",
        traducao: "ganhar dinheiro; lucrar",
      },
    ],
  },
  {
    kanji: "瞬",
    onYomi: [{ katakana: "シュン", romaji: "shun" }],
    kunYomi: [{ hiragana: "またた.く", romaji: "matata.ku" }],
    significado: "instante; piscar",
    exemplos: [
      {
        kanji: "瞬間",
        furigana: "しゅんかん",
        romaji: "shunkan",
        traducao: "momento; instante",
      },
      {
        kanji: "瞬く",
        furigana: "またたく",
        romaji: "matataku",
        traducao: "piscar; brilhar rapidamente",
      },
      {
        kanji: "瞬",
        furigana: "またた",
        romaji: "matata",
        traducao: "instante; piscar",
      },
    ],
  },
  {
    kanji: "砲",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [],
    significado: "canhão; arma de fogo",
    exemplos: [
      {
        kanji: "大砲",
        furigana: "たいほう",
        romaji: "taihou",
        traducao: "canhão",
      },
      {
        kanji: "砲撃",
        furigana: "ほうげき",
        romaji: "hougeki",
        traducao: "bombardeio",
      },
      {
        kanji: "砲",
        furigana: "ほう",
        romaji: "hou",
        traducao: "canhão; arma de fogo",
      },
    ],
  },
  {
    kanji: "噴",
    onYomi: [{ katakana: "フン", romaji: "fun" }],
    kunYomi: [{ hiragana: "ふ.く", romaji: "fu.ku" }],
    significado: "jorrar; espirrar",
    exemplos: [
      {
        kanji: "噴火",
        furigana: "ふんか",
        romaji: "funka",
        traducao: "erupção vulcânica",
      },
      {
        kanji: "噴水",
        furigana: "ふんすい",
        romaji: "funsui",
        traducao: "fonte; jato d'água",
      },
      {
        kanji: "噴く",
        furigana: "ふく",
        romaji: "fuku",
        traducao: "jorrar; espirrar",
      },
    ],
  },
  {
    kanji: "誇",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "ほこ.る", romaji: "hoko.ru" }],
    significado: "orgulho; gloriar-se",
    exemplos: [
      {
        kanji: "誇示",
        furigana: "こじ",
        romaji: "koji",
        traducao: "exibição; demonstração",
      },
      {
        kanji: "自誇",
        furigana: "じこ",
        romaji: "jiko",
        traducao: "orgulho próprio",
      },
      {
        kanji: "誇",
        furigana: "ほこ",
        romaji: "hoko",
        traducao: "orgulho; gloriar-se",
      },
    ],
  },
  {
    kanji: "牲",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [],
    significado: "sacrifício; oferenda",
    exemplos: [
      {
        kanji: "犠牲",
        furigana: "ぎせい",
        romaji: "gisei",
        traducao: "sacrifício",
      },
      {
        kanji: "牲畜",
        furigana: "せいちく",
        romaji: "seichiku",
        traducao: "gado; animais de fazenda",
      },
      {
        kanji: "牲",
        furigana: "しょう",
        romaji: "shou",
        traducao: "sacrifício; oferenda",
      },
    ],
  },
  {
    kanji: "秩",
    onYomi: [{ katakana: "チツ", romaji: "chitsu" }],
    kunYomi: [],
    significado: "ordem; regularidade",
    exemplos: [
      {
        kanji: "秩序",
        furigana: "ちつじょ",
        romaji: "chitsujo",
        traducao: "ordem; arranjo",
      },
      {
        kanji: "規律秩序",
        furigana: "きりつちつじょ",
        romaji: "kiritsuchitsujo",
        traducao: "disciplina",
      },
      {
        kanji: "秩",
        furigana: "ちつ",
        romaji: "chitsu",
        traducao: "ordem; regularidade",
      },
    ],
  },
  {
    kanji: "帝",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "imperador; soberano",
    exemplos: [
      {
        kanji: "皇帝",
        furigana: "こうてい",
        romaji: "koutei",
        traducao: "imperador",
      },
      {
        kanji: "帝国",
        furigana: "ていこく",
        romaji: "teikoku",
        traducao: "império",
      },
      {
        kanji: "帝",
        furigana: "みかど",
        romaji: "mikado",
        traducao: "imperador; soberano",
      },
    ],
  },
  {
    kanji: "宏",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "grande; vasto",
    exemplos: [
      {
        kanji: "宏大",
        furigana: "こうだい",
        romaji: "koudai",
        traducao: "vasto; grande",
      },
      {
        kanji: "広宏",
        furigana: "こうこう",
        romaji: "koukou",
        traducao: "grande; vasto",
      },
      {
        kanji: "宏",
        furigana: "ひろ",
        romaji: "hiro",
        traducao: "grande; vasto",
      },
    ],
  },
  {
    kanji: "唆",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [{ hiragana: "そそのか.す", romaji: "sosonoka.su" }],
    significado: "incitar; instigar",
    exemplos: [
      {
        kanji: "唆す",
        furigana: "そそなす",
        romaji: "sosonasu",
        traducao: "incitar; instigar",
      },
      {
        kanji: "唆人",
        furigana: "さじん",
        romaji: "sajin",
        traducao: "incitador; instigador",
      },
      {
        kanji: "唆",
        furigana: "さそ",
        romaji: "saso",
        traducao: "incitar; instigar",
      },
    ],
  },
  {
    kanji: "阻",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [{ hiragana: "はば.む", romaji: "haba.mu" }],
    significado: "obstruir; impedir",
    exemplos: [
      {
        kanji: "阻止",
        furigana: "そし",
        romaji: "soshi",
        traducao: "impedimento; prevenção",
      },
      {
        kanji: "阻む",
        furigana: "はばむ",
        romaji: "habamu",
        traducao: "obstruir; impedir",
      },
      {
        kanji: "阻",
        furigana: "さまた",
        romaji: "samata",
        traducao: "obstruir; impedir",
      },
    ],
  },
  {
    kanji: "泰",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [],
    significado: "pacificamente; calma",
    exemplos: [
      {
        kanji: "安泰",
        furigana: "あんたい",
        romaji: "antai",
        traducao: "pacificamente; tranquilamente",
      },
      {
        kanji: "泰然",
        furigana: "たいぜん",
        romaji: "taizen",
        traducao: "calmo; imperturbável",
      },
      {
        kanji: "泰",
        furigana: "やす",
        romaji: "yasu",
        traducao: "pacificamente; calma",
      },
    ],
  },
  {
    kanji: "賄",
    onYomi: [{ katakana: "ワイ", romaji: "wai" }],
    kunYomi: [{ hiragana: "まかな.う", romaji: "makanau" }],
    significado: "suborno; propina",
    exemplos: [
      {
        kanji: "賄賂",
        furigana: "わいろ",
        romaji: "wairo",
        traducao: "suborno",
      },
      {
        kanji: "賄う",
        furigana: "まかなう",
        romaji: "makanau",
        traducao: "fornecer; oferecer",
      },
      {
        kanji: "賄",
        furigana: "まかない",
        romaji: "makanai",
        traducao: "suborno; propina",
      },
    ],
  },
  {
    kanji: "撲",
    onYomi: [{ katakana: "ボク", romaji: "boku" }],
    kunYomi: [],
    significado: "atacar; golpear",
    exemplos: [
      {
        kanji: "突撃",
        furigana: "とつげき",
        romaji: "totsugeki",
        traducao: "ataque; carga",
      },
      {
        kanji: "撲殺",
        furigana: "ぼくさつ",
        romaji: "bokusatsu",
        traducao: "assassinato; matar",
      },
      {
        kanji: "撲",
        furigana: "はた",
        romaji: "hata",
        traducao: "atacar; golpear",
      },
    ],
  },
  {
    kanji: "堀",
    onYomi: [],
    kunYomi: [{ hiragana: "ほり", romaji: "hori" }],
    significado: "fosso; vala",
    exemplos: [
      {
        kanji: "堀",
        furigana: "ほり",
        romaji: "hori",
        traducao: "fosso; vala",
      },
      {
        kanji: "堀る",
        furigana: "ほる",
        romaji: "horu",
        traducao: "escavar; cavar",
      },
    ],
  },
  {
    kanji: "菊",
    onYomi: [{ katakana: "キク", romaji: "kiku" }],
    kunYomi: [],
    significado: "crisântemo",
    exemplos: [
      {
        kanji: "菊",
        furigana: "きく",
        romaji: "kiku",
        traducao: "crisântemo",
      },
      {
        kanji: "菊花",
        furigana: "きっか",
        romaji: "kikka",
        traducao: "flor de crisântemo",
      },
    ],
  },
  {
    kanji: "絞",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "し.める", romaji: "shi.meru" }],
    significado: "apertar; estrangular",
    exemplos: [
      {
        kanji: "絞首刑",
        furigana: "こうしゅけい",
        romaji: "koushuukei",
        traducao: "pena de morte por enforcamento",
      },
      {
        kanji: "絞る",
        furigana: "しめる",
        romaji: "shimeru",
        traducao: "apertar; estrangular",
      },
      {
        kanji: "絞",
        furigana: "しぼ",
        romaji: "shibo",
        traducao: "apertar; estrangular",
      },
    ],
  },
  {
    kanji: "縁",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [
      { hiragana: "ふち", romaji: "fuchi" },
      { hiragana: "ゆかり", romaji: "yukari" },
    ],
    significado: "relacionamento; borda",
    exemplos: [
      {
        kanji: "縁結び",
        furigana: "えんむすび",
        romaji: "enmusubi",
        traducao: "casamento; união",
      },
      {
        kanji: "縁",
        furigana: "えん",
        romaji: "en",
        traducao: "relacionamento; borda",
      },
      {
        kanji: "縁",
        furigana: "ふち",
        romaji: "fuchi",
        traducao: "borda; margem",
      },
    ],
  },
  {
    kanji: "唯",
    onYomi: [{ katakana: "ユイ", romaji: "yui" }],
    kunYomi: [{ hiragana: "ただ", romaji: "tada" }],
    significado: "apenas; somente",
    exemplos: [
      {
        kanji: "唯一",
        furigana: "ゆいいつ",
        romaji: "yuiitsu",
        traducao: "único; exclusivo",
      },
      {
        kanji: "ただ唯",
        furigana: "ただただ",
        romaji: "tadatada",
        traducao: "simplesmente; apenas",
      },
      {
        kanji: "唯",
        furigana: "ただ",
        romaji: "tada",
        traducao: "apenas; somente",
      },
    ],
  },
  {
    kanji: "膨",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "ふく.らむ", romaji: "fuku.ramu" }],
    significado: "inchar; expandir",
    exemplos: [
      {
        kanji: "膨大",
        furigana: "ぼうだい",
        romaji: "boudai",
        traducao: "vasto; enorme",
      },
      {
        kanji: "膨らむ",
        furigana: "ふくらむ",
        romaji: "fukuramu",
        traducao: "inchar; expandir",
      },
      {
        kanji: "膨",
        furigana: "ふく",
        romaji: "fuku",
        traducao: "inchar; expandir",
      },
    ],
  },
  {
    kanji: "矢",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "や", romaji: "ya" }],
    significado: "flecha",
    exemplos: [
      {
        kanji: "矢印",
        furigana: "やじるし",
        romaji: "yajirushi",
        traducao: "seta; flecha",
      },
      {
        kanji: "矢",
        furigana: "や",
        romaji: "ya",
        traducao: "flecha",
      },
    ],
  },
  {
    kanji: "耐",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "た.える", romaji: "ta.eru" }],
    significado: "resistir; suportar",
    exemplos: [
      {
        kanji: "耐久",
        furigana: "たいきゅう",
        romaji: "taikyuu",
        traducao: "durabilidade; resistência",
      },
      {
        kanji: "耐える",
        furigana: "たえる",
        romaji: "taeru",
        traducao: "resistir; suportar",
      },
      {
        kanji: "耐",
        furigana: "たい",
        romaji: "tai",
        traducao: "resistir; suportar",
      },
    ],
  },
  {
    kanji: "塾",
    onYomi: [{ katakana: "ジュク", romaji: "juku" }],
    kunYomi: [],
    significado: "cursinho; academia",
    exemplos: [
      {
        kanji: "塾",
        furigana: "じゅく",
        romaji: "juku",
        traducao: "cursinho; academia",
      },
      {
        kanji: "塾生",
        furigana: "じゅくせい",
        romaji: "jukusei",
        traducao: "aluno de cursinho",
      },
    ],
  },
  {
    kanji: "漏",
    onYomi: [{ katakana: "ロウ", romaji: "rou" }],
    kunYomi: [{ hiragana: "も.る", romaji: "mo.ru" }],
    significado: "vazar; escapar",
    exemplos: [
      {
        kanji: "漏水",
        furigana: "ろうすい",
        romaji: "rousui",
        traducao: "vazamento de água",
      },
      {
        kanji: "漏れる",
        furigana: "もれる",
        romaji: "moreru",
        traducao: "vazar; escapar",
      },
      {
        kanji: "漏",
        furigana: "も",
        romaji: "mo",
        traducao: "vazar; escapar",
      },
    ],
  },
  {
    kanji: "慶",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [],
    significado: "felicidade; celebração",
    exemplos: [
      {
        kanji: "慶祝",
        furigana: "けいしゅく",
        romaji: "keishuku",
        traducao: "celebração; comemoração",
      },
      {
        kanji: "慶応",
        furigana: "けいおう",
        romaji: "keiou",
        traducao: "comemoração",
      },
      {
        kanji: "慶",
        furigana: "よろこ",
        romaji: "yoroko",
        traducao: "felicidade; celebração",
      },
    ],
  },
  {
    kanji: "猛",
    onYomi: [{ katakana: "モウ", romaji: "mou" }],
    kunYomi: [],
    significado: "fierce; violent",
    exemplos: [
      {
        kanji: "猛烈",
        furigana: "もうれつ",
        romaji: "mouretsu",
        traducao: "intenso; feroz",
      },
      {
        kanji: "猛獣",
        furigana: "もうじゅう",
        romaji: "moujuu",
        traducao: "fera; animal feroz",
      },
      {
        kanji: "猛",
        furigana: "たけ",
        romaji: "take",
        traducao: "fierce; violent",
      },
    ],
  },
  {
    kanji: "芳",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "かんば.しい", romaji: "kanba.shii" }],
    significado: "fragrance; perfume",
    exemplos: [
      {
        kanji: "芳香",
        furigana: "ほうこう",
        romaji: "houkou",
        traducao: "fragrância; perfume",
      },
      {
        kanji: "芳しい",
        furigana: "かんばしい",
        romaji: "kanbashii",
        traducao: "perfumado; cheiroso",
      },
      {
        kanji: "芳",
        furigana: "よし",
        romaji: "yoshi",
        traducao: "fragrance; perfume",
      },
    ],
  },
  {
    kanji: "懲",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "こ.りる", romaji: "ko.riru" }],
    significado: "punir; corrigir",
    exemplos: [
      {
        kanji: "懲罰",
        furigana: "ちょうばつ",
        romaji: "choubatsu",
        traducao: "punição",
      },
      {
        kanji: "懲りる",
        furigana: "こりる",
        romaji: "koriru",
        traducao: "aprender com a experiência; corrigir-se",
      },
      {
        kanji: "懲",
        furigana: "こ.り",
        romaji: "ko.ri",
        traducao: "punir; corrigir",
      },
    ],
  },
  {
    kanji: "剣",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "つるぎ", romaji: "tsurugi" }],
    significado: "sword; blade",
    exemplos: [
      {
        kanji: "剣道",
        furigana: "けんどう",
        romaji: "kendou",
        traducao: "caminho da espada; kendô",
      },
      {
        kanji: "剣士",
        furigana: "けんし",
        romaji: "kenshi",
        traducao: "espadachim",
      },
      {
        kanji: "剣",
        furigana: "つるぎ",
        romaji: "tsurugi",
        traducao: "sword; blade",
      },
    ],
  },
  {
    kanji: "彰",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "eminent; remarkable",
    exemplos: [
      {
        kanji: "表彰",
        furigana: "ひょうしょう",
        romaji: "hyoushou",
        traducao: "reconhecimento; premiação",
      },
      {
        kanji: "顕彰",
        furigana: "けんしょう",
        romaji: "kenshou",
        traducao: "homenagem; memorial",
      },
      {
        kanji: "彰",
        furigana: "あきら",
        romaji: "akira",
        traducao: "eminent; remarkable",
      },
    ],
  },
  {
    kanji: "棋",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "chess; shogi piece",
    exemplos: [
      {
        kanji: "囲碁",
        furigana: "いご",
        romaji: "igo",
        traducao: "jogo de go",
      },
      {
        kanji: "将棋",
        furigana: "しょうぎ",
        romaji: "shougi",
        traducao: "xadrez japonês",
      },
      {
        kanji: "棋",
        furigana: "き",
        romaji: "ki",
        traducao: "chess; shogi piece",
      },
    ],
  },
  {
    kanji: "丁",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [],
    significado: "small pieces; unit of counting",
    exemplos: [
      {
        kanji: "一丁",
        furigana: "いっちょう",
        romaji: "icchou",
        traducao: "one piece",
      },
      {
        kanji: "仲間一丁",
        furigana: "なかまいっちょう",
        romaji: "nakama icchou",
        traducao: "um grupo de amigos",
      },
      {
        kanji: "丁",
        furigana: "ちょう",
        romaji: "chou",
        traducao: "small pieces; unit of counting",
      },
    ],
  },
  {
    kanji: "恒",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "constant; permanent",
    exemplos: [
      {
        kanji: "恒例",
        furigana: "こうれい",
        romaji: "kourei",
        traducao: "evento regular; costume",
      },
      {
        kanji: "永恒",
        furigana: "えいこう",
        romaji: "eikou",
        traducao: "eterno; perpétuo",
      },
      {
        kanji: "恒",
        furigana: "つね",
        romaji: "tsune",
        traducao: "constant; permanent",
      },
    ],
  },
  {
    kanji: "揚",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [
      { hiragana: "あ.げる", romaji: "a.ge.ru" },
      { hiragana: "あ.がる", romaji: "a.ga.ru" },
    ],
    significado: "hoist; raise",
    exemplos: [
      {
        kanji: "掲揚",
        furigana: "けいよう",
        romaji: "keiyou",
        traducao: "exposição; exibição",
      },
      {
        kanji: "揚げる",
        furigana: "あげる",
        romaji: "ageru",
        traducao: "fritar; elevar",
      },
      {
        kanji: "揚",
        furigana: "あ.げる",
        romaji: "a.ge.ru",
        traducao: "hoist; raise",
      },
    ],
  },
  {
    kanji: "冒",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [
      { hiragana: "おか.す", romaji: "oka.su" },
      { hiragana: "ふ.く", romaji: "fu.ku" },
    ],
    significado: "risk; venture",
    exemplos: [
      {
        kanji: "冒険",
        furigana: "ぼうけん",
        romaji: "bouken",
        traducao: "aventura",
      },
      {
        kanji: "冒す",
        furigana: "おかす",
        romaji: "okasu",
        traducao: "arriscar; desafiar",
      },
      {
        kanji: "冒",
        furigana: "ふく",
        romaji: "fuku",
        traducao: "risk; venture",
      },
    ],
  },
  {
    kanji: "之",
    onYomi: [],
    kunYomi: [{ hiragana: "の", romaji: "no" }],
    significado: "of; possessive particle",
    exemplos: [
      {
        kanji: "それぞれの",
        furigana: "それぞれの",
        romaji: "sorezore no",
        traducao: "cada um; respectivo",
      },
      {
        kanji: "彼の",
        furigana: "かれの",
        romaji: "kare no",
        traducao: "dele",
      },
      {
        kanji: "之",
        furigana: "の",
        romaji: "no",
        traducao: "of; possessive particle",
      },
    ],
  },
  {
    kanji: "倫",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [],
    significado: "ethics; morals",
    exemplos: [
      {
        kanji: "倫理",
        furigana: "りんり",
        romaji: "rinri",
        traducao: "ética",
      },
      {
        kanji: "倫",
        furigana: "りん",
        romaji: "rin",
        traducao: "ethics; morals",
      },
    ],
  },
  {
    kanji: "陳",
    onYomi: [{ katakana: "チン", romaji: "chin" }],
    kunYomi: [],
    significado: "exhibit; display",
    exemplos: [
      {
        kanji: "陳列",
        furigana: "ちんれつ",
        romaji: "chinretsu",
        traducao: "exibição; exposição",
      },
      {
        kanji: "提陳",
        furigana: "ていちん",
        romaji: "teichin",
        traducao: "apresentação; proposta",
      },
      {
        kanji: "陳",
        furigana: "ひ.する",
        romaji: "hi.suru",
        traducao: "exhibit; display",
      },
    ],
  },
  {
    kanji: "憶",
    onYomi: [{ katakana: "オク", romaji: "oku" }],
    kunYomi: [],
    significado: "memória; lembrança",
    exemplos: [
      {
        kanji: "記憶",
        furigana: "きおく",
        romaji: "kioku",
        traducao: "memória; lembrança",
      },
      {
        kanji: "憶える",
        furigana: "おぼえる",
        romaji: "oboeru",
        traducao: "lembrar; memorizar",
      },
      {
        kanji: "憶",
        furigana: "おく",
        romaji: "oku",
        traducao: "memória; lembrança",
      },
    ],
  },
  {
    kanji: "潜",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "もぐ.る", romaji: "mogu.ru" }],
    significado: "mergulhar; esconder-se",
    exemplos: [
      {
        kanji: "潜水",
        furigana: "せんすい",
        romaji: "sensui",
        traducao: "mergulho; submersão",
      },
      {
        kanji: "潜む",
        furigana: "ひそむ",
        romaji: "hisomu",
        traducao: "esconder-se; ocultar-se",
      },
      {
        kanji: "潜",
        furigana: "もぐ",
        romaji: "mogu",
        traducao: "mergulhar; esconder-se",
      },
    ],
  },
  {
    kanji: "梨",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [{ hiragana: "なし", romaji: "nashi" }],
    significado: "pera",
    exemplos: [
      {
        kanji: "梨",
        furigana: "なし",
        romaji: "nashi",
        traducao: "pera",
      },
      {
        kanji: "洋梨",
        furigana: "ようなし",
        romaji: "younashi",
        traducao: "pêra ocidental",
      },
    ],
  },
  {
    kanji: "仁",
    onYomi: [{ katakana: "ジン", romaji: "jin" }],
    kunYomi: [{ hiragana: "じん", romaji: "jin" }],
    significado: "bondade; benevolência",
    exemplos: [
      {
        kanji: "仁愛",
        furigana: "じんあい",
        romaji: "jin'ai",
        traducao: "amor; compaixão",
      },
      {
        kanji: "仁義",
        furigana: "じんぎ",
        romaji: "jingi",
        traducao: "humanidade e justiça",
      },
      {
        kanji: "仁",
        furigana: "じん",
        romaji: "jin",
        traducao: "bondade; benevolência",
      },
    ],
  },
  {
    kanji: "克",
    onYomi: [{ katakana: "コク", romaji: "koku" }],
    kunYomi: [],
    significado: "superar; dominar",
    exemplos: [
      {
        kanji: "克服",
        furigana: "こくふく",
        romaji: "kokufuku",
        traducao: "superar; vencer",
      },
      {
        kanji: "克己",
        furigana: "こっき",
        romaji: "kokki",
        traducao: "autocontrole; autodomínio",
      },
      {
        kanji: "克",
        furigana: "か.つ",
        romaji: "ka.tsu",
        traducao: "superar; dominar",
      },
    ],
  },
  {
    kanji: "岳",
    onYomi: [{ katakana: "ガク", romaji: "gaku" }],
    kunYomi: [{ hiragana: "たけ", romaji: "take" }],
    significado: "montanha; pico",
    exemplos: [
      {
        kanji: "富士岳",
        furigana: "ふじがく",
        romaji: "fujigaku",
        traducao: "Monte Fuji",
      },
      {
        kanji: "岳",
        furigana: "たけ",
        romaji: "take",
        traducao: "montanha; pico",
      },
    ],
  },
  {
    kanji: "概",
    onYomi: [{ katakana: "ガイ", romaji: "gai" }],
    kunYomi: [],
    significado: "geral; resumo",
    exemplos: [
      {
        kanji: "概要",
        furigana: "がいよう",
        romaji: "gaiyou",
        traducao: "resumo; sinopse",
      },
      {
        kanji: "概念",
        furigana: "がいねん",
        romaji: "gainen",
        traducao: "conceito; noção geral",
      },
      {
        kanji: "概",
        furigana: "おおよ.そ",
        romaji: "ooyo.so",
        traducao: "geral; resumo",
      },
    ],
  },
  {
    kanji: "拘",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "かか.わる", romaji: "kaka.waru" }],
    significado: "prender; restringir",
    exemplos: [
      {
        kanji: "拘束",
        furigana: "こうそく",
        romaji: "kousoku",
        traducao: "restrição; confinamento",
      },
      {
        kanji: "拘る",
        furigana: "かかる",
        romaji: "kakaru",
        traducao: "prender; restringir",
      },
      {
        kanji: "拘",
        furigana: "かか.わる",
        romaji: "kaka.waru",
        traducao: "prender; restringir",
      },
    ],
  },
  {
    kanji: "墓",
    onYomi: [{ katakana: "ボ", romaji: "bo" }],
    kunYomi: [{ hiragana: "はか", romaji: "haka" }],
    significado: "túmulo; sepultura",
    exemplos: [
      {
        kanji: "墓地",
        furigana: "ぼち",
        romaji: "bochi",
        traducao: "cemitério",
      },
      {
        kanji: "墓",
        furigana: "はか",
        romaji: "haka",
        traducao: "túmulo; sepultura",
      },
    ],
  },
  {
    kanji: "黙",
    onYomi: [{ katakana: "モク", romaji: "moku" }],
    kunYomi: [{ hiragana: "だま.る", romaji: "dama.ru" }],
    significado: "silêncio; quietude",
    exemplos: [
      {
        kanji: "黙秘",
        furigana: "もくひ",
        romaji: "mokuhi",
        traducao: "segredo; confidência",
      },
      {
        kanji: "黙る",
        furigana: "だまる",
        romaji: "damaru",
        traducao: "ficar em silêncio",
      },
      {
        kanji: "黙",
        furigana: "もだ.す",
        romaji: "moda.su",
        traducao: "silêncio; quietude",
      },
    ],
  },
  {
    kanji: "須",
    onYomi: [{ katakana: "ス", romaji: "su" }],
    kunYomi: [],
    significado: "necessário; dever",
    exemplos: [
      {
        kanji: "必須",
        furigana: "ひっす",
        romaji: "hisso",
        traducao: "obrigatório; essencial",
      },
      {
        kanji: "須",
        furigana: "すべ.から.く",
        romaji: "sube.kara.ku",
        traducao: "necessário; dever",
      },
    ],
  },
  {
    kanji: "偏",
    onYomi: [{ katakana: "ヘン", romaji: "hen" }],
    kunYomi: [{ hiragana: "かたよ.る", romaji: "katayo.ru" }],
    significado: "viés; tendência",
    exemplos: [
      {
        kanji: "偏見",
        furigana: "へんけん",
        romaji: "henken",
        traducao: "preconceito",
      },
      {
        kanji: "偏る",
        furigana: "かたよる",
        romaji: "katayoru",
        traducao: "ser tendencioso; ser parcial",
      },
      {
        kanji: "偏",
        furigana: "かたよ.る",
        romaji: "katayo.ru",
        traducao: "viés; tendência",
      },
    ],
  },
  {
    kanji: "雰",
    onYomi: [{ katakana: "フン", romaji: "fun" }],
    kunYomi: [],
    significado: "atmosfera; clima",
    exemplos: [
      {
        kanji: "雰囲気",
        furigana: "ふんいき",
        romaji: "fun'iki",
        traducao: "atmosfera; clima",
      },
      {
        kanji: "雰",
        furigana: "ふん",
        romaji: "fun",
        traducao: "atmosfera; clima",
      },
    ],
  },
  {
    kanji: "遇",
    onYomi: [{ katakana: "グウ", romaji: "guu" }],
    kunYomi: [{ hiragana: "あ.う", romaji: "a.u" }],
    significado: "encontrar; encontrar-se",
    exemplos: [
      {
        kanji: "遭遇",
        furigana: "そうぐう",
        romaji: "souguu",
        traducao: "encontro; encontro casual",
      },
      {
        kanji: "遇う",
        furigana: "あう",
        romaji: "au",
        traducao: "encontrar; encontrar-se",
      },
      {
        kanji: "遇",
        furigana: "めぐ.る",
        romaji: "megu.ru",
        traducao: "encontrar; encontrar-se",
      },
    ],
  },
  {
    kanji: "卓",
    onYomi: [{ katakana: "タク", romaji: "taku" }],
    kunYomi: [],
    significado: "excelente; mesa",
    exemplos: [
      {
        kanji: "卓越",
        furigana: "たくえつ",
        romaji: "takuetsu",
        traducao: "excelência",
      },
      {
        kanji: "卓球",
        furigana: "たっきゅう",
        romaji: "takkyuu",
        traducao: "tênis de mesa",
      },
      {
        kanji: "卓",
        furigana: "たく",
        romaji: "taku",
        traducao: "excelente; mesa",
      },
    ],
  },
  {
    kanji: "亀",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "かめ", romaji: "kame" }],
    significado: "tartaruga",
    exemplos: [
      {
        kanji: "亀",
        furigana: "かめ",
        romaji: "kame",
        traducao: "tartaruga",
      },
      {
        kanji: "亀裂",
        furigana: "きれつ",
        romaji: "kiretsu",
        traducao: "fissura; rachadura",
      },
    ],
  },
  {
    kanji: "糧",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [{ hiragana: "かて", romaji: "kate" }],
    significado: "alimento; provisão",
    exemplos: [
      {
        kanji: "糧",
        furigana: "かて",
        romaji: "kate",
        traducao: "alimento; provisão",
      },
      {
        kanji: "食糧",
        furigana: "しょくりょう",
        romaji: "shokuryou",
        traducao: "alimento; suprimento",
      },
    ],
  },
  {
    kanji: "簿",
    onYomi: [{ katakana: "ボ", romaji: "bo" }],
    kunYomi: [],
    significado: "registro; livro",
    exemplos: [
      {
        kanji: "名簿",
        furigana: "めいぼ",
        romaji: "meibo",
        traducao: "lista de nomes",
      },
      {
        kanji: "簿記",
        furigana: "ぼき",
        romaji: "boki",
        traducao: "contabilidade",
      },
      {
        kanji: "簿",
        furigana: "ぼ",
        romaji: "bo",
        traducao: "registro; livro",
      },
    ],
  },
  {
    kanji: "炉",
    onYomi: [{ katakana: "ロ", romaji: "ro" }],
    kunYomi: [],
    significado: "forno; lareira",
    exemplos: [
      {
        kanji: "暖炉",
        furigana: "だんろ",
        romaji: "danro",
        traducao: "lareira",
      },
      {
        kanji: "炉辺",
        furigana: "ろへん",
        romaji: "rohen",
        traducao: "lareira",
      },
      {
        kanji: "炉",
        furigana: "ろ",
        romaji: "ro",
        traducao: "forno; lareira",
      },
    ],
  },
  {
    kanji: "牧",
    onYomi: [{ katakana: "ボク", romaji: "boku" }],
    kunYomi: [{ hiragana: "まき", romaji: "maki" }],
    significado: "pasto; fazenda",
    exemplos: [
      {
        kanji: "牧場",
        furigana: "ぼくじょう",
        romaji: "bokujou",
        traducao: "fazenda; pasto",
      },
      {
        kanji: "牧師",
        furigana: "ぼくし",
        romaji: "bokushi",
        traducao: "pastor",
      },
      {
        kanji: "牧",
        furigana: "まき",
        romaji: "maki",
        traducao: "pasto; fazenda",
      },
    ],
  },
  {
    kanji: "殊",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [{ hiragana: "こと", romaji: "koto" }],
    significado: "especial; distinto",
    exemplos: [
      {
        kanji: "特殊",
        furigana: "とくしゅ",
        romaji: "tokushu",
        traducao: "especial; peculiar",
      },
      {
        kanji: "殊勝",
        furigana: "しゅしょう",
        romaji: "shushou",
        traducao: "admirável; louvável",
      },
      {
        kanji: "殊",
        furigana: "こと",
        romaji: "koto",
        traducao: "especial; distinto",
      },
    ],
  },
  {
    kanji: "殖",
    onYomi: [{ katakana: "ショク", romaji: "shoku" }],
    kunYomi: [{ hiragana: "ふ.える", romaji: "fu.eru" }],
    significado: "aumentar; multiplicar",
    exemplos: [
      {
        kanji: "増殖",
        furigana: "ぞうしょく",
        romaji: "zoushoku",
        traducao: "crescimento; multiplicação",
      },
      {
        kanji: "繁殖",
        furigana: "はんしょく",
        romaji: "hanshoku",
        traducao: "reprodução; procriação",
      },
      {
        kanji: "殖",
        furigana: "ふ.える",
        romaji: "fu.eru",
        traducao: "aumentar; multiplicar",
      },
    ],
  },
  {
    kanji: "艦",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "navio de guerra",
    exemplos: [
      {
        kanji: "戦艦",
        furigana: "せんかん",
        romaji: "senkan",
        traducao: "navio de guerra",
      },
      {
        kanji: "艦船",
        furigana: "かんせん",
        romaji: "kansen",
        traducao: "navio",
      },
      {
        kanji: "艦",
        furigana: "かん",
        romaji: "kan",
        traducao: "navio de guerra",
      },
    ],
  },
  {
    kanji: "輩",
    onYomi: [{ katakana: "ハイ", romaji: "hai" }],
    kunYomi: [],
    significado: "companheiro; colega",
    exemplos: [
      {
        kanji: "同輩",
        furigana: "どうはい",
        romaji: "douhai",
        traducao: "colega; companheiro",
      },
      {
        kanji: "先輩",
        furigana: "せんぱい",
        romaji: "senpai",
        traducao: "senior; veterano",
      },
      {
        kanji: "輩",
        furigana: "やから",
        romaji: "yakara",
        traducao: "companheiro; colega",
      },
    ],
  },
  {
    kanji: "穴",
    onYomi: [{ katakana: "ケツ", romaji: "ketsu" }],
    kunYomi: [{ hiragana: "あな", romaji: "ana" }],
    significado: "buraco; cavidade",
    exemplos: [
      {
        kanji: "地下穴",
        furigana: "ちかあな",
        romaji: "chikaana",
        traducao: "buraco subterrâneo",
      },
      {
        kanji: "穴",
        furigana: "あな",
        romaji: "ana",
        traducao: "buraco; cavidade",
      },
    ],
  },
  {
    kanji: "奇",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "く.しき", romaji: "ku.shiki" }],
    significado: "estranho; incomum",
    exemplos: [
      {
        kanji: "奇跡",
        furigana: "きせき",
        romaji: "kiseki",
        traducao: "milagre",
      },
      {
        kanji: "奇妙",
        furigana: "きみょう",
        romaji: "kimyou",
        traducao: "estranho; peculiar",
      },
      {
        kanji: "奇",
        furigana: "く.しき",
        romaji: "ku.shiki",
        traducao: "estranho; incomum",
      },
    ],
  },
  {
    kanji: "慢",
    onYomi: [{ katakana: "マン", romaji: "man" }],
    kunYomi: [],
    significado: "arrogância; negligência",
    exemplos: [
      {
        kanji: "傲慢",
        furigana: "ごうまん",
        romaji: "gouman",
        traducao: "arrogância; arrogante",
      },
      {
        kanji: "慢心",
        furigana: "まんしん",
        romaji: "manshin",
        traducao: "presunção; arrogância",
      },
      {
        kanji: "慢",
        furigana: "おご.る",
        romaji: "ogo.ru",
        traducao: "arrogância; negligência",
      },
    ],
  },
  {
    kanji: "鶴",
    onYomi: [{ katakana: "ツル", romaji: "tsuru" }],
    kunYomi: [],
    significado: "garça",
    exemplos: [
      {
        kanji: "鶴",
        furigana: "つる",
        romaji: "tsuru",
        traducao: "garça",
      },
      {
        kanji: "朱鷺",
        furigana: "とき",
        romaji: "toki",
        traducao: "garça-vermelha",
      },
    ],
  },
  {
    kanji: "謀",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "はか.る", romaji: "haka.ru" }],
    significado: "planejar; conspirar",
    exemplos: [
      {
        kanji: "策謀",
        furigana: "さくぼう",
        romaji: "sakubou",
        traducao: "estratégia; maquinação",
      },
      {
        kanji: "謀る",
        furigana: "はかる",
        romaji: "hakaru",
        traducao: "planejar; conspirar",
      },
      {
        kanji: "謀",
        furigana: "はか.る",
        romaji: "haka.ru",
        traducao: "planejar; conspirar",
      },
    ],
  },
  {
    kanji: "暖",
    onYomi: [{ katakana: "ダン", romaji: "dan" }],
    kunYomi: [{ hiragana: "あたた.かい", romaji: "atata.kai" }],
    significado: "quente; calor",
    exemplos: [
      {
        kanji: "暖房",
        furigana: "だんぼう",
        romaji: "danbou",
        traducao: "aquecimento",
      },
      {
        kanji: "暖かい",
        furigana: "あたたかい",
        romaji: "atatakai",
        traducao: "quente; caloroso",
      },
      {
        kanji: "暖",
        furigana: "あたた.かい",
        romaji: "atata.kai",
        traducao: "quente; calor",
      },
    ],
  },
  {
    kanji: "諮",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "はか.る", romaji: "haka.ru" }],
    significado: "consultar; pedir conselho",
    exemplos: [
      {
        kanji: "諮問",
        furigana: "しもん",
        romaji: "shimon",
        traducao: "consulta",
      },
      {
        kanji: "諮る",
        furigana: "はかる",
        romaji: "hakaru",
        traducao: "consultar; pedir conselho",
      },
      {
        kanji: "諮",
        furigana: "はか.る",
        romaji: "haka.ru",
        traducao: "consultar; pedir conselho",
      },
    ],
  },
  {
    kanji: "狭",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "せま.い", romaji: "sema.i" }],
    significado: "estreito; apertado",
    exemplos: [
      {
        kanji: "狭心症",
        furigana: "きょうしんしょう",
        romaji: "kyoushinshou",
        traducao: "angina",
      },
      {
        kanji: "狭い",
        furigana: "せまい",
        romaji: "semai",
        traducao: "estreito; apertado",
      },
      {
        kanji: "狭",
        furigana: "せま.い",
        romaji: "sema.i",
        traducao: "estreito; apertado",
      },
    ],
  },
  {
    kanji: "昌",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "próspero; florescente",
    exemplos: [
      {
        kanji: "繁昌",
        furigana: "はんじょう",
        romaji: "hanjou",
        traducao: "prosperidade; florescimento",
      },
      {
        kanji: "昌盛",
        furigana: "しょうせい",
        romaji: "shousei",
        traducao: "prosperidade; florescimento",
      },
      {
        kanji: "昌",
        furigana: "まさ",
        romaji: "masa",
        traducao: "próspero; florescente",
      },
    ],
  },
  {
    kanji: "拍",
    onYomi: [{ katakana: "ハク", romaji: "haku" }],
    kunYomi: [],
    significado: "batida; palma",
    exemplos: [
      {
        kanji: "拍手",
        furigana: "はくしゅ",
        romaji: "hakushu",
        traducao: "aplauso",
      },
      {
        kanji: "拍",
        furigana: "はく",
        romaji: "haku",
        traducao: "batida; palma",
      },
    ],
  },
  {
    kanji: "朗",
    onYomi: [{ katakana: "ロウ", romaji: "rou" }],
    kunYomi: [{ hiragana: "ほが.らか", romaji: "hoga.raka" }],
    significado: "claro; brilhante",
    exemplos: [
      {
        kanji: "明朗",
        furigana: "めいろう",
        romaji: "meirou",
        traducao: "claro; brilhante",
      },
      {
        kanji: "朗読",
        furigana: "ろうどく",
        romaji: "roudoku",
        traducao: "leitura em voz alta",
      },
      {
        kanji: "朗",
        furigana: "ほが.らか",
        romaji: "hoga.raka",
        traducao: "claro; brilhante",
      },
    ],
  },
  {
    kanji: "寛",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "くつろ.ぐ", romaji: "kutsuro.gu" }],
    significado: "amplo; generoso",
    exemplos: [
      {
        kanji: "寛容",
        furigana: "かんよう",
        romaji: "kan'you",
        traducao: "tolerância; indulgência",
      },
      {
        kanji: "寛大",
        furigana: "かんだい",
        romaji: "kandai",
        traducao: "generoso; indulgente",
      },
      {
        kanji: "寛",
        furigana: "くつろ.ぐ",
        romaji: "kutsuro.gu",
        traducao: "amplo; generoso",
      },
    ],
  },
  {
    kanji: "覆",
    onYomi: [{ katakana: "フク", romaji: "fuku" }],
    kunYomi: [{ hiragana: "おお.う", romaji: "oo.u" }],
    significado: "cobrir; virar",
    exemplos: [
      {
        kanji: "覆面",
        furigana: "ふくめん",
        romaji: "fukumen",
        traducao: "máscara; disfarce",
      },
      {
        kanji: "覆う",
        furigana: "おおう",
        romaji: "oou",
        traducao: "cobrir; envolver",
      },
      {
        kanji: "覆",
        furigana: "くつがえ.す",
        romaji: "kutsugae.su",
        traducao: "virar; revirar",
      },
    ],
  },
  {
    kanji: "胞",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [],
    significado: "célula; tecido",
    exemplos: [
      {
        kanji: "胞子",
        furigana: "ほうし",
        romaji: "houshi",
        traducao: "esporo",
      },
      {
        kanji: "細胞",
        furigana: "さいぼう",
        romaji: "saibou",
        traducao: "célula",
      },
      {
        kanji: "胞",
        furigana: "ほう",
        romaji: "hou",
        traducao: "célula; tecido",
      },
    ],
  },
  {
    kanji: "泣",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "な.く", romaji: "na.ku" }],
    significado: "chorar",
    exemplos: [
      {
        kanji: "涙泣",
        furigana: "るいきゅう",
        romaji: "ruikyuu",
        traducao: "choro; lágrimas",
      },
      {
        kanji: "泣く",
        furigana: "なく",
        romaji: "naku",
        traducao: "chorar",
      },
      {
        kanji: "泣",
        furigana: "な.く",
        romaji: "na.ku",
        traducao: "chorar",
      },
    ],
  },
  {
    kanji: "隔",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "へだ.てる", romaji: "heda.teru" }],
    significado: "separar; isolar",
    exemplos: [
      {
        kanji: "隔離",
        furigana: "かくり",
        romaji: "kakuri",
        traducao: "isolamento; separação",
      },
      {
        kanji: "隔週",
        furigana: "かくしゅう",
        romaji: "kakushuu",
        traducao: "quinzenal",
      },
      {
        kanji: "隔",
        furigana: "へだ.てる",
        romaji: "heda.teru",
        traducao: "separar; isolar",
      },
    ],
  },
  {
    kanji: "浄",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "きよ.める", romaji: "kiyo.meru" }],
    significado: "limpo; purificar",
    exemplos: [
      {
        kanji: "浄化",
        furigana: "じょうか",
        romaji: "jouka",
        traducao: "purificação",
      },
      {
        kanji: "浄水",
        furigana: "じょうすい",
        romaji: "jousui",
        traducao: "água limpa",
      },
      {
        kanji: "浄",
        furigana: "きよ.める",
        romaji: "kiyo.meru",
        traducao: "limpo; purificar",
      },
    ],
  },
  {
    kanji: "没",
    onYomi: [{ katakana: "ボツ", romaji: "botsu" }],
    kunYomi: [{ hiragana: "ふ.ける", romaji: "fu.keru" }],
    significado: "afundar; desaparecer",
    exemplos: [
      {
        kanji: "没頭",
        furigana: "ぼっとう",
        romaji: "bottou",
        traducao: "imersão; entrega",
      },
      {
        kanji: "没収",
        furigana: "ぼっしゅう",
        romaji: "bosshuu",
        traducao: "confisco",
      },
      {
        kanji: "没",
        furigana: "ふ.ける",
        romaji: "fu.keru",
        traducao: "afundar; desaparecer",
      },
    ],
  },
  {
    kanji: "暇",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "ひま", romaji: "hima" }],
    significado: "tempo livre",
    exemplos: [
      {
        kanji: "暇",
        furigana: "ひま",
        romaji: "hima",
        traducao: "tempo livre",
      },
      {
        kanji: "余暇",
        furigana: "よか",
        romaji: "yoka",
        traducao: "tempo livre",
      },
    ],
  },
  {
    kanji: "肺",
    onYomi: [{ katakana: "ハイ", romaji: "hai" }],
    kunYomi: [{ hiragana: "はい", romaji: "hai" }],
    significado: "pulmão",
    exemplos: [
      {
        kanji: "肺炎",
        furigana: "はいえん",
        romaji: "haien",
        traducao: "pneumonia",
      },
      {
        kanji: "肺",
        furigana: "はい",
        romaji: "hai",
        traducao: "pulmão",
      },
    ],
  },
  {
    kanji: "貞",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "さだ", romaji: "sada" }],
    significado: "virtude; fidelidade",
    exemplos: [
      {
        kanji: "貞節",
        furigana: "ていせつ",
        romaji: "teisetsu",
        traducao: "virtude; fidelidade",
      },
      {
        kanji: "貞心",
        furigana: "ていしん",
        romaji: "teishin",
        traducao: "fidelidade",
      },
      {
        kanji: "貞",
        furigana: "さだ",
        romaji: "sada",
        traducao: "virtude; fidelidade",
      },
    ],
  },
  {
    kanji: "靖",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "やす.んじる", romaji: "yasu.njiru" }],
    significado: "paz; tranquilidade",
    exemplos: [
      {
        kanji: "安靖",
        furigana: "あんせい",
        romaji: "ansei",
        traducao: "paz; tranquilidade",
      },
      {
        kanji: "靖国",
        furigana: "せいこく",
        romaji: "seikoku",
        traducao: "paz nacional",
      },
      {
        kanji: "靖",
        furigana: "やす.んじる",
        romaji: "yasu.njiru",
        traducao: "paz; tranquilidade",
      },
    ],
  },
  {
    kanji: "鑑",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "かんが.みる", romaji: "kan.ga.miru" }],
    significado: "espelho; reflexo",
    exemplos: [
      {
        kanji: "鑑定",
        furigana: "かんてい",
        romaji: "kantei",
        traducao: "avaliação; peritagem",
      },
      {
        kanji: "鑑賞",
        furigana: "かんしょう",
        romaji: "kanshou",
        traducao: "apreciação",
      },
      {
        kanji: "鑑",
        furigana: "かんが.みる",
        romaji: "kan.ga.miru",
        traducao: "espelho; reflexo",
      },
    ],
  },
  {
    kanji: "飼",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "か.う", romaji: "ka.u" }],
    significado: "criar; criar animais",
    exemplos: [
      {
        kanji: "飼育",
        furigana: "しいく",
        romaji: "shiiku",
        traducao: "criação; criação de animais",
      },
      {
        kanji: "飼う",
        furigana: "かう",
        romaji: "kau",
        traducao: "criar; criar animais",
      },
      {
        kanji: "飼",
        furigana: "か.う",
        romaji: "ka.u",
        traducao: "criar; criar animais",
      },
    ],
  },
  {
    kanji: "陰",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [{ hiragana: "かげ", romaji: "kage" }],
    significado: "sombra; escuridão",
    exemplos: [
      {
        kanji: "陰影",
        furigana: "いんえい",
        romaji: "inei",
        traducao: "sombra; penumbra",
      },
      {
        kanji: "陰",
        furigana: "かげ",
        romaji: "kage",
        traducao: "sombra; escuridão",
      },
    ],
  },
  {
    kanji: "銘",
    onYomi: [{ katakana: "メイ", romaji: "mei" }],
    kunYomi: [{ hiragana: "な", romaji: "na" }],
    significado: "inscrição; epígrafe",
    exemplos: [
      {
        kanji: "銘柄",
        furigana: "めいがら",
        romaji: "meigara",
        traducao: "marca; marca registrada",
      },
      {
        kanji: "銘",
        furigana: "な",
        romaji: "na",
        traducao: "inscrição; epígrafe",
      },
    ],
  },
  {
    kanji: "随",
    onYomi: [{ katakana: "ズイ", romaji: "zui" }],
    kunYomi: [{ hiragana: "まにまに", romaji: "manimani" }],
    significado: "acompanhar; seguir",
    exemplos: [
      {
        kanji: "随時",
        furigana: "ずいじ",
        romaji: "zuiji",
        traducao: "a qualquer momento",
      },
      {
        kanji: "随",
        furigana: "まにまに",
        romaji: "manimani",
        traducao: "acompanhar; seguir",
      },
    ],
  },
  {
    kanji: "烈",
    onYomi: [{ katakana: "レツ", romaji: "retsu" }],
    kunYomi: [],
    significado: "intenso; violento",
    exemplos: [
      {
        kanji: "猛烈",
        furigana: "もうれつ",
        romaji: "mouretsu",
        traducao: "intenso; violento",
      },
      {
        kanji: "激烈",
        furigana: "げきれつ",
        romaji: "gekiretsu",
        traducao: "intenso; feroz",
      },
      {
        kanji: "烈",
        furigana: "れつ",
        romaji: "retsu",
        traducao: "intenso; violento",
      },
    ],
  },
  {
    kanji: "尋",
    onYomi: [{ katakana: "ジン", romaji: "jin" }],
    kunYomi: [{ hiragana: "たず.ねる", romaji: "tazu.neru" }],
    significado: "procurar; investigar",
    exemplos: [
      {
        kanji: "尋問",
        furigana: "じんもん",
        romaji: "jinmon",
        traducao: "interrogatório; inquérito",
      },
      {
        kanji: "尋ねる",
        furigana: "たずねる",
        romaji: "tazuneru",
        traducao: "perguntar; inquirir",
      },
      {
        kanji: "尋",
        furigana: "たず.ねる",
        romaji: "tazu.neru",
        traducao: "procurar; investigar",
      },
    ],
  },
  {
    kanji: "稿",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "rascunho; manuscrito",
    exemplos: [
      {
        kanji: "原稿",
        furigana: "げんこう",
        romaji: "genkou",
        traducao: "manuscrito; original",
      },
      {
        kanji: "草稿",
        furigana: "そうこう",
        romaji: "soukou",
        traducao: "rascunho",
      },
      {
        kanji: "稿",
        furigana: "こう",
        romaji: "kou",
        traducao: "rascunho; manuscrito",
      },
    ],
  },
  {
    kanji: "丹",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "に", romaji: "ni" }],
    significado: "vermelho; tinta",
    exemplos: [
      {
        kanji: "丹念",
        furigana: "たんねん",
        romaji: "tannen",
        traducao: "cuidadoso; minucioso",
      },
      {
        kanji: "丹塗り",
        furigana: "にぬり",
        romaji: "ninuri",
        traducao: "vermelho; tinta vermelha",
      },
      {
        kanji: "丹",
        furigana: "に",
        romaji: "ni",
        traducao: "vermelho; tinta",
      },
    ],
  },
  {
    kanji: "啓",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "さと.す", romaji: "sato.su" }],
    significado: "revelar; abrir",
    exemplos: [
      {
        kanji: "啓示",
        furigana: "けいじ",
        romaji: "keiji",
        traducao: "revelação; aviso",
      },
      {
        kanji: "啓発",
        furigana: "けいはつ",
        romaji: "keihatsu",
        traducao: "educação; iluminação",
      },
      {
        kanji: "啓",
        furigana: "さと.す",
        romaji: "sato.su",
        traducao: "revelar; abrir",
      },
    ],
  },
  {
    kanji: "也",
    onYomi: [],
    kunYomi: [],
    significado: "também",
    exemplos: [
      {
        kanji: "全て",
        furigana: "すべて",
        romaji: "subete",
        traducao: "tudo; todos",
      },
      {
        kanji: "誰でも",
        furigana: "だれでも",
        romaji: "dare demo",
        traducao: "qualquer um",
      },
      {
        kanji: "也",
        furigana: "や",
        romaji: "ya",
        traducao: "também",
      },
    ],
  },
  {
    kanji: "丘",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "おか", romaji: "oka" }],
    significado: "colina; morro",
    exemplos: [
      {
        kanji: "丘陵",
        furigana: "きゅうりょう",
        romaji: "kyuuryou",
        traducao: "colina",
      },
      {
        kanji: "高台",
        furigana: "たかだい",
        romaji: "takadai",
        traducao: "colina; monte",
      },
      {
        kanji: "丘",
        furigana: "おか",
        romaji: "oka",
        traducao: "colina; morro",
      },
    ],
  },
  {
    kanji: "棟",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [],
    significado: "telhado; edifício",
    exemplos: [
      {
        kanji: "建物",
        furigana: "たてもの",
        romaji: "tatemono",
        traducao: "edifício",
      },
      {
        kanji: "屋根",
        furigana: "やね",
        romaji: "yane",
        traducao: "telhado",
      },
      {
        kanji: "棟",
        furigana: "むね",
        romaji: "mune",
        traducao: "telhado; edifício",
      },
    ],
  },
  {
    kanji: "壌",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [],
    significado: "terra; solo",
    exemplos: [
      {
        kanji: "土壌",
        furigana: "どじょう",
        romaji: "dojou",
        traducao: "solo; terreno",
      },
      {
        kanji: "土壌汚染",
        furigana: "どじょうおせん",
        romaji: "dojouosen",
        traducao: "contaminação do solo",
      },
      {
        kanji: "壌",
        furigana: "つち",
        romaji: "tsuchi",
        traducao: "terra; solo",
      },
    ],
  },
  {
    kanji: "漫",
    onYomi: [{ katakana: "マン", romaji: "man" }],
    kunYomi: [],
    significado: "vago; sem rumo",
    exemplos: [
      {
        kanji: "漫画",
        furigana: "まんが",
        romaji: "manga",
        traducao: "quadrinhos; mangá",
      },
      {
        kanji: "漫然",
        furigana: "まんぜん",
        romaji: "manzen",
        traducao: "desatento; apático",
      },
      {
        kanji: "漫",
        furigana: "まん",
        romaji: "man",
        traducao: "vago; sem rumo",
      },
    ],
  },
  {
    kanji: "玄",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [],
    significado: "misterioso; profundo",
    exemplos: [
      {
        kanji: "玄関",
        furigana: "げんかん",
        romaji: "genkan",
        traducao: "entrada",
      },
      {
        kanji: "玄武",
        furigana: "げんぶ",
        romaji: "genbu",
        traducao: "tartaruga negra",
      },
      {
        kanji: "玄",
        furigana: "げん",
        romaji: "gen",
        traducao: "misterioso; profundo",
      },
    ],
  },
  {
    kanji: "粘",
    onYomi: [{ katakana: "ネン", romaji: "nen" }],
    kunYomi: [{ hiragana: "ねば.る", romaji: "neba.ru" }],
    significado: "pegajoso; viscoso",
    exemplos: [
      {
        kanji: "粘土",
        furigana: "ねんど",
        romaji: "nendo",
        traducao: "argila",
      },
      {
        kanji: "粘",
        furigana: "ねば.る",
        romaji: "neba.ru",
        traducao: "pegajoso; viscoso",
      },
    ],
  },
  {
    kanji: "悟",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [{ hiragana: "さと.る", romaji: "sato.ru" }],
    significado: "compreender; perceber",
    exemplos: [
      {
        kanji: "悟り",
        furigana: "さとり",
        romaji: "satori",
        traducao: "iluminação; despertar",
      },
      {
        kanji: "悟る",
        furigana: "さとる",
        romaji: "satoru",
        traducao: "compreender; perceber",
      },
      {
        kanji: "悟",
        furigana: "さと.る",
        romaji: "sato.ru",
        traducao: "compreender; perceber",
      },
    ],
  },
  {
    kanji: "舗",
    onYomi: [{ katakana: "ホ", romaji: "ho" }],
    kunYomi: [{ hiragana: "ほ", romaji: "ho" }],
    significado: "loja; pavimento",
    exemplos: [
      {
        kanji: "店舗",
        furigana: "てんぽ",
        romaji: "tenpo",
        traducao: "loja",
      },
      {
        kanji: "舗装",
        furigana: "ほそう",
        romaji: "hosou",
        traducao: "pavimentação",
      },
      {
        kanji: "舗",
        furigana: "ほ",
        romaji: "ho",
        traducao: "loja; pavimento",
      },
    ],
  },
  {
    kanji: "妊",
    onYomi: [{ katakana: "ニン", romaji: "nin" }],
    kunYomi: [],
    significado: "gravidez",
    exemplos: [
      {
        kanji: "妊娠",
        furigana: "にんしん",
        romaji: "ninshin",
        traducao: "gravidez",
      },
      {
        kanji: "妊婦",
        furigana: "にんぷ",
        romaji: "ninfu",
        traducao: "grávida",
      },
      {
        kanji: "妊",
        furigana: "にん",
        romaji: "nin",
        traducao: "gravidez",
      },
    ],
  },
  {
    kanji: "熟",
    onYomi: [{ katakana: "ジュク", romaji: "juku" }],
    kunYomi: [{ hiragana: "う.れる", romaji: "u.reru" }],
    significado: "maduro; experiente",
    exemplos: [
      {
        kanji: "熟睡",
        furigana: "じゅくすい",
        romaji: "jukusui",
        traducao: "sono profundo",
      },
      {
        kanji: "熟成",
        furigana: "じゅくせい",
        romaji: "jukusei",
        traducao: "maturação",
      },
      {
        kanji: "熟",
        furigana: "う.れる",
        romaji: "u.reru",
        traducao: "maduro; experiente",
      },
    ],
  },
  {
    kanji: "旭",
    onYomi: [{ katakana: "キョク", romaji: "kyoku" }],
    kunYomi: [],
    significado: "nascer do sol",
    exemplos: [
      {
        kanji: "旭日",
        furigana: "きょくじつ",
        romaji: "kyokujitsu",
        traducao: "nascer do sol",
      },
      {
        kanji: "旭",
        furigana: "あさひ",
        romaji: "asahi",
        traducao: "nascer do sol",
      },
    ],
  },
  {
    kanji: "恩",
    onYomi: [{ katakana: "オン", romaji: "on" }],
    kunYomi: [{ hiragana: "おん", romaji: "on" }],
    significado: "gratidão",
    exemplos: [
      {
        kanji: "感恩",
        furigana: "かんおん",
        romaji: "kanon",
        traducao: "gratidão",
      },
      {
        kanji: "報恩",
        furigana: "ほうおん",
        romaji: "houn",
        traducao: "retribuição",
      },
      {
        kanji: "恩",
        furigana: "おん",
        romaji: "on",
        traducao: "gratidão",
      },
    ],
  },
  {
    kanji: "騰",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [],
    significado: "aumento; subida",
    exemplos: [
      {
        kanji: "高騰",
        furigana: "こうとう",
        romaji: "koutou",
        traducao: "aumento; alta",
      },
      {
        kanji: "騰貴",
        furigana: "とうき",
        romaji: "touki",
        traducao: "valorização",
      },
      {
        kanji: "騰",
        furigana: "と.げる",
        romaji: "to.geru",
        traducao: "aumento; subida",
      },
    ],
  },
  {
    kanji: "往",
    onYomi: [{ katakana: "オウ", romaji: "ou" }],
    kunYomi: [{ hiragana: "い.く", romaji: "i.ku" }],
    significado: "ir; viajar",
    exemplos: [
      {
        kanji: "往復",
        furigana: "おうふく",
        romaji: "oufuku",
        traducao: "ida e volta",
      },
      {
        kanji: "往路",
        furigana: "おうろ",
        romaji: "ouro",
        traducao: "ida",
      },
      {
        kanji: "往",
        furigana: "い.く",
        romaji: "i.ku",
        traducao: "ir; viajar",
      },
    ],
  },
  {
    kanji: "豆",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "まめ", romaji: "mame" }],
    significado: "feijão",
    exemplos: [
      {
        kanji: "大豆",
        furigana: "だいず",
        romaji: "daizu",
        traducao: "soja",
      },
      {
        kanji: "豆腐",
        furigana: "とうふ",
        romaji: "tofu",
        traducao: "tofu",
      },
      {
        kanji: "豆",
        furigana: "まめ",
        romaji: "mame",
        traducao: "feijão",
      },
    ],
  },
  {
    kanji: "遂",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "と.げる", romaji: "to.geru" }],
    significado: "realizar; concluir",
    exemplos: [
      {
        kanji: "遂行",
        furigana: "すいこう",
        romaji: "suikou",
        traducao: "execução; realização",
      },
      {
        kanji: "遂げる",
        furigana: "とげる",
        romaji: "togeru",
        traducao: "realizar; concluir",
      },
      {
        kanji: "遂",
        furigana: "と.げる",
        romaji: "to.geru",
        traducao: "realizar; concluir",
      },
    ],
  },
  {
    kanji: "狂",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "くる.う", romaji: "kuru.u" }],
    significado: "loucura; insano",
    exemplos: [
      {
        kanji: "狂気",
        furigana: "きょうき",
        romaji: "kyouki",
        traducao: "loucura",
      },
      {
        kanji: "狂う",
        furigana: "くるう",
        romaji: "kuruu",
        traducao: "ficar louco",
      },
      {
        kanji: "狂",
        furigana: "くる.う",
        romaji: "kuru.u",
        traducao: "loucura; insano",
      },
    ],
  },
  {
    kanji: "岐",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "cruzamento; bifurcação",
    exemplos: [
      {
        kanji: "分岐",
        furigana: "ぶんき",
        romaji: "bunki",
        traducao: "ramificação; bifurcação",
      },
      {
        kanji: "岐路",
        furigana: "ぎろ",
        romaji: "giro",
        traducao: "encruzilhada; ponto de decisão",
      },
      {
        kanji: "岐",
        furigana: "き",
        romaji: "ki",
        traducao: "cruzamento; bifurcação",
      },
    ],
  },
  {
    kanji: "陛",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [],
    significado: "trono imperial",
    exemplos: [
      {
        kanji: "陛下",
        furigana: "へいか",
        romaji: "heika",
        traducao: "sua majestade",
      },
      {
        kanji: "陛",
        furigana: "へい",
        romaji: "hei",
        traducao: "trono imperial",
      },
    ],
  },
  {
    kanji: "緯",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [],
    significado: "latitude; fio",
    exemplos: [
      {
        kanji: "経緯度",
        furigana: "けいいど",
        romaji: "keiido",
        traducao: "coordenadas geográficas",
      },
      {
        kanji: "緯度",
        furigana: "いど",
        romaji: "ido",
        traducao: "latitude",
      },
      {
        kanji: "緯",
        furigana: "い",
        romaji: "i",
        traducao: "latitude; fio",
      },
    ],
  },
  {
    kanji: "培",
    onYomi: [{ katakana: "バイ", romaji: "bai" }],
    kunYomi: [{ hiragana: "つちか.う", romaji: "tsuchika.u" }],
    significado: "cultivar; desenvolver",
    exemplos: [
      {
        kanji: "培養",
        furigana: "ばいよう",
        romaji: "baiyou",
        traducao: "cultivo; cultivo de células",
      },
      {
        kanji: "培う",
        furigana: "つちかう",
        romaji: "tsuchikau",
        traducao: "cultivar; desenvolver",
      },
      {
        kanji: "培",
        furigana: "つちか.う",
        romaji: "tsuchika.u",
        traducao: "cultivar; desenvolver",
      },
    ],
  },
  {
    kanji: "衰",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "おとろ.える", romaji: "otoro.eru" }],
    significado: "declínio; enfraquecer",
    exemplos: [
      {
        kanji: "衰弱",
        furigana: "すいじゃく",
        romaji: "suijaku",
        traducao: "fraqueza",
      },
      {
        kanji: "衰える",
        furigana: "おとろえる",
        romaji: "otoro.eru",
        traducao: "declinar; enfraquecer",
      },
      {
        kanji: "衰",
        furigana: "おとろ.える",
        romaji: "otoro.eru",
        traducao: "declínio; enfraquecer",
      },
    ],
  },
  {
    kanji: "艇",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "embarcação",
    exemplos: [
      {
        kanji: "船艇",
        furigana: "せんてい",
        romaji: "sentei",
        traducao: "embarcação",
      },
      {
        kanji: "小艇",
        furigana: "しょうてい",
        romaji: "shoutei",
        traducao: "bote; barco pequeno",
      },
      {
        kanji: "艇",
        furigana: "てい",
        romaji: "tei",
        traducao: "embarcação",
      },
    ],
  },
  {
    kanji: "屈",
    onYomi: [{ katakana: "クツ", romaji: "kutsu" }],
    kunYomi: [{ hiragana: "かが.む", romaji: "kaga.mu" }],
    significado: "dobrar; ceder",
    exemplos: [
      {
        kanji: "屈辱",
        furigana: "くつじょく",
        romaji: "kutsujoku",
        traducao: "humilhação",
      },
      {
        kanji: "屈む",
        furigana: "かがむ",
        romaji: "kagamu",
        traducao: "curvar-se; ceder",
      },
      {
        kanji: "屈",
        furigana: "かが.む",
        romaji: "kaga.mu",
        traducao: "dobrar; ceder",
      },
    ],
  },
  {
    kanji: "径",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "みち", romaji: "michi" }],
    significado: "caminho; diâmetro",
    exemplos: [
      {
        kanji: "直径",
        furigana: "ちょっけい",
        romaji: "chokkei",
        traducao: "diâmetro",
      },
      {
        kanji: "道径",
        furigana: "みちみち",
        romaji: "michimichi",
        traducao: "caminho; trilha",
      },
      {
        kanji: "径",
        furigana: "みち",
        romaji: "michi",
        traducao: "caminho; diâmetro",
      },
    ],
  },
  {
    kanji: "淡",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "あわ.い", romaji: "awa.i" }],
    significado: "pálido; suave",
    exemplos: [
      {
        kanji: "淡水",
        furigana: "たんすい",
        romaji: "tansui",
        traducao: "água doce",
      },
      {
        kanji: "淡い",
        furigana: "あわい",
        romaji: "awa.i",
        traducao: "pálido; suave",
      },
      {
        kanji: "淡",
        furigana: "あわ.い",
        romaji: "awa.i",
        traducao: "pálido; suave",
      },
    ],
  },
  {
    kanji: "抽",
    onYomi: [{ katakana: "チュウ", romaji: "chuu" }],
    kunYomi: [],
    significado: "extrair; tirar",
    exemplos: [
      {
        kanji: "抽選",
        furigana: "ちゅうせん",
        romaji: "chuusen",
        traducao: "sorteio",
      },
      {
        kanji: "抽象",
        furigana: "ちゅうしょう",
        romaji: "chuushou",
        traducao: "abstração",
      },
      {
        kanji: "抽",
        furigana: "ちゅう",
        romaji: "chuu",
        traducao: "extrair; tirar",
      },
    ],
  },
  {
    kanji: "披",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [],
    significado: "jogar por cima; exibir",
    exemplos: [
      {
        kanji: "披露",
        furigana: "ひろう",
        romaji: "hirou",
        traducao: "revelar; apresentar",
      },
      {
        kanji: "披",
        furigana: "ひら.く",
        romaji: "hira.ku",
        traducao: "jogar por cima; exibir",
      },
      {
        kanji: "披",
        furigana: "ヒ",
        romaji: "hi",
        traducao: "jogar por cima; exibir",
      },
    ],
  },
  {
    kanji: "廷",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "corte imperial; tribunal",
    exemplos: [
      {
        kanji: "法廷",
        furigana: "ほうてい",
        romaji: "houtei",
        traducao: "tribunal",
      },
      {
        kanji: "宮廷",
        furigana: "きゅうてい",
        romaji: "kyuutei",
        traducao: "corte imperial",
      },
      {
        kanji: "廷",
        furigana: "てい",
        romaji: "tei",
        traducao: "corte imperial; tribunal",
      },
    ],
  },
  {
    kanji: "錦",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [],
    significado: "brocado; seda",
    exemplos: [
      {
        kanji: "錦鯉",
        furigana: "にしきごい",
        romaji: "nishikigoi",
        traducao: "carpa colorida",
      },
      {
        kanji: "錦",
        furigana: "にしき",
        romaji: "nishiki",
        traducao: "brocado; seda",
      },
      {
        kanji: "錦",
        furigana: "きん",
        romaji: "kin",
        traducao: "brocado; seda",
      },
    ],
  },
  {
    kanji: "准",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [],
    significado: "semi-; quase",
    exemplos: [
      {
        kanji: "准教授",
        furigana: "じゅんきょうじゅ",
        romaji: "junkyouju",
        traducao: "professor assistente",
      },
      {
        kanji: "准",
        furigana: "じゅん",
        romaji: "jun",
        traducao: "semi-; quase",
      },
    ],
  },
  {
    kanji: "暑",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [{ hiragana: "あつ.い", romaji: "atsu.i" }],
    significado: "quente",
    exemplos: [
      {
        kanji: "暑中",
        furigana: "しょちゅう",
        romaji: "shochuu",
        traducao: "meio do verão",
      },
      {
        kanji: "暑い",
        furigana: "あつい",
        romaji: "atsu.i",
        traducao: "quente",
      },
      {
        kanji: "暑",
        furigana: "あつ.い",
        romaji: "atsu.i",
        traducao: "quente",
      },
    ],
  },
  {
    kanji: "磯",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "rochedo; costa",
    exemplos: [
      {
        kanji: "岩磯",
        furigana: "いわいそ",
        romaji: "iwaiso",
        traducao: "costa rochosa",
      },
      {
        kanji: "磯",
        furigana: "いそ",
        romaji: "iso",
        traducao: "rochedo; costa",
      },
      {
        kanji: "磯",
        furigana: "キ",
        romaji: "ki",
        traducao: "rochedo; costa",
      },
    ],
  },
  {
    kanji: "奨",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "encorajar; premiar",
    exemplos: [
      {
        kanji: "奨学金",
        furigana: "しょうがくきん",
        romaji: "shougakukin",
        traducao: "bolsa de estudos",
      },
      {
        kanji: "奨励",
        furigana: "しょうれい",
        romaji: "shourei",
        traducao: "incentivo; encorajamento",
      },
      {
        kanji: "奨",
        furigana: "すす.める",
        romaji: "susu.meru",
        traducao: "encorajar; premiar",
      },
    ],
  },
  {
    kanji: "浸",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "ひた.す", romaji: "hita.su" }],
    significado: "imersão; mergulhar",
    exemplos: [
      {
        kanji: "浸水",
        furigana: "しんすい",
        romaji: "shinsui",
        traducao: "inundação",
      },
      {
        kanji: "浸る",
        furigana: "ひたる",
        romaji: "hitaru",
        traducao: "imerso; envolto",
      },
      {
        kanji: "浸",
        furigana: "ひた.す",
        romaji: "hita.su",
        traducao: "imersão; mergulhar",
      },
    ],
  },
  {
    kanji: "剰",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [],
    significado: "excesso; sobra",
    exemplos: [
      {
        kanji: "余剰",
        furigana: "よじょう",
        romaji: "yojou",
        traducao: "excesso; sobra",
      },
      {
        kanji: "剰余",
        furigana: "じょうよ",
        romaji: "jouyo",
        traducao: "excedente; saldo",
      },
      {
        kanji: "剰",
        furigana: "あま.る",
        romaji: "ama.ru",
        traducao: "excesso; sobra",
      },
    ],
  },
  {
    kanji: "胆",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "きも", romaji: "kimo" }],
    significado: "coragem; audácia",
    exemplos: [
      {
        kanji: "胆力",
        furigana: "たんりょく",
        romaji: "tanryoku",
        traducao: "coragem",
      },
      {
        kanji: "胆",
        furigana: "きも",
        romaji: "kimo",
        traducao: "coragem; audácia",
      },
      {
        kanji: "胆",
        furigana: "はらわた",
        romaji: "harawata",
        traducao: "coragem; audácia",
      },
    ],
  },
  {
    kanji: "繊",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [],
    significado: "fibra; fino",
    exemplos: [
      {
        kanji: "繊維",
        furigana: "せんい",
        romaji: "sen'i",
        traducao: "fibra",
      },
      {
        kanji: "繊細",
        furigana: "せんさい",
        romaji: "sensai",
        traducao: "delicado",
      },
      {
        kanji: "繊",
        furigana: "せん",
        romaji: "sen",
        traducao: "fibra; fino",
      },
    ],
  },
  {
    kanji: "駒",
    onYomi: [{ katakana: "ク", romaji: "ku" }],
    kunYomi: [],
    significado: "peça de xadrez; cavalo",
    exemplos: [
      {
        kanji: "駒",
        furigana: "こま",
        romaji: "koma",
        traducao: "peça de xadrez; cavalo",
      },
      {
        kanji: "駒",
        furigana: "くま",
        romaji: "kuma",
        traducao: "peça de xadrez; cavalo",
      },
      {
        kanji: "駒",
        furigana: "ク",
        romaji: "ku",
        traducao: "peça de xadrez; cavalo",
      },
    ],
  },
  {
    kanji: "虚",
    onYomi: [{ katakana: "キョ", romaji: "kyo" }],
    kunYomi: [{ hiragana: "むな.しい", romaji: "muna.shii" }],
    significado: "vazio; irreal",
    exemplos: [
      {
        kanji: "虚無",
        furigana: "きょむ",
        romaji: "kyomu",
        traducao: "nada; vazio",
      },
      {
        kanji: "虚偽",
        furigana: "きょぎ",
        romaji: "kyogi",
        traducao: "falsidade; mentira",
      },
      {
        kanji: "虚",
        furigana: "むな.しい",
        romaji: "muna.shii",
        traducao: "vazio; irreal",
      },
    ],
  },
  {
    kanji: "霊",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [{ hiragana: "たま", romaji: "tama" }],
    significado: "espírito; alma",
    exemplos: [
      {
        kanji: "霊魂",
        furigana: "れいこん",
        romaji: "reikon",
        traducao: "espírito",
      },
      {
        kanji: "霊感",
        furigana: "れいかん",
        romaji: "reikan",
        traducao: "intuição; inspiração",
      },
      {
        kanji: "霊",
        furigana: "たま",
        romaji: "tama",
        traducao: "espírito; alma",
      },
    ],
  },
  {
    kanji: "帳",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [],
    significado: "livro; tela; cortina",
    exemplos: [
      {
        kanji: "手帳",
        furigana: "てちょう",
        romaji: "techou",
        traducao: "caderno; agenda",
      },
      {
        kanji: "帳面",
        furigana: "ちょうめん",
        romaji: "choumen",
        traducao: "papelada; documentos",
      },
      {
        kanji: "帳",
        furigana: "ちょう",
        romaji: "chou",
        traducao: "livro; tela; cortina",
      },
    ],
  },
  {
    kanji: "悔",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "く.いる", romaji: "ku.iru" }],
    significado: "lamentar; se arrepender",
    exemplos: [
      {
        kanji: "後悔",
        furigana: "こうかい",
        romaji: "koukai",
        traducao: "arrependimento",
      },
      {
        kanji: "悔やむ",
        furigana: "くやむ",
        romaji: "kuyamu",
        traducao: "lamentar; se arrepender",
      },
      {
        kanji: "悔",
        furigana: "く.いる",
        romaji: "ku.iru",
        traducao: "lamentar; se arrepender",
      },
    ],
  },
  {
    kanji: "諭",
    onYomi: [{ katakana: "ユ", romaji: "yu" }],
    kunYomi: [{ hiragana: "さと.す", romaji: "sato.su" }],
    significado: "aconselhar; instruir",
    exemplos: [
      {
        kanji: "諭す",
        furigana: "さとす",
        romaji: "sato.su",
        traducao: "aconselhar; instruir",
      },
      {
        kanji: "諭",
        furigana: "ユ",
        romaji: "yu",
        traducao: "aconselhar; instruir",
      },
    ],
  },
  {
    kanji: "惨",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [{ hiragana: "みじ.め", romaji: "miji.me" }],
    significado: "miserável; terrível",
    exemplos: [
      {
        kanji: "惨事",
        furigana: "さんじ",
        romaji: "sanji",
        traducao: "desastre; tragédia",
      },
      {
        kanji: "惨め",
        furigana: "みじめ",
        romaji: "mijime",
        traducao: "miserável; patético",
      },
      {
        kanji: "惨",
        furigana: "サン",
        romaji: "san",
        traducao: "miserável; terrível",
      },
    ],
  },
  {
    kanji: "虐",
    onYomi: [{ katakana: "ギャク", romaji: "gyaku" }],
    kunYomi: [{ hiragana: "しいた.げる", romaji: "shiita.geru" }],
    significado: "tirânico; abusivo",
    exemplos: [
      {
        kanji: "虐待",
        furigana: "ぎゃくたい",
        romaji: "gyakutai",
        traducao: "abuso; maus tratos",
      },
      {
        kanji: "虐殺",
        furigana: "ぎゃくさつ",
        romaji: "gyakusatsu",
        traducao: "genocídio",
      },
      {
        kanji: "虐",
        furigana: "しいた.げる",
        romaji: "shiita.geru",
        traducao: "tirânico; abusivo",
      },
    ],
  },
  {
    kanji: "翻",
    onYomi: [{ katakana: "ホン", romaji: "hon" }],
    kunYomi: [{ hiragana: "ひるが.える", romaji: "hiruga.eru" }],
    significado: "virar; girar",
    exemplos: [
      {
        kanji: "翻訳",
        furigana: "ほんやく",
        romaji: "honyaku",
        traducao: "tradução",
      },
      {
        kanji: "翻る",
        furigana: "ひるがえる",
        romaji: "hirugaeru",
        traducao: "virar; girar",
      },
      {
        kanji: "翻",
        furigana: "ほん",
        romaji: "hon",
        traducao: "virar; girar",
      },
    ],
  },
  {
    kanji: "墜",
    onYomi: [{ katakana: "ツイ", romaji: "tsui" }],
    kunYomi: [{ hiragana: "お.ちる", romaji: "o.chiru" }],
    significado: "cair; despencar",
    exemplos: [
      {
        kanji: "墜落",
        furigana: "ついらく",
        romaji: "tsuiraku",
        traducao: "queda; acidente",
      },
      {
        kanji: "墜ちる",
        furigana: "おちる",
        romaji: "ochiru",
        traducao: "cair; despencar",
      },
      {
        kanji: "墜",
        furigana: "つ.く",
        romaji: "tsu.ku",
        traducao: "cair; despencar",
      },
    ],
  },
  {
    kanji: "沼",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "ぬま", romaji: "numa" }],
    significado: "pântano; lagoa",
    exemplos: [
      {
        kanji: "沼地",
        furigana: "しょうち",
        romaji: "shouchi",
        traducao: "pântano",
      },
      {
        kanji: "沼",
        furigana: "ぬま",
        romaji: "numa",
        traducao: "pântano; lagoa",
      },
      {
        kanji: "沼",
        furigana: "ショウ",
        romaji: "shou",
        traducao: "pântano; lagoa",
      },
    ],
  },
  {
    kanji: "据",
    onYomi: [{ katakana: "キョ", romaji: "kyo" }],
    kunYomi: [{ hiragana: "す.える", romaji: "su.eru" }],
    significado: "posicionar; estabelecer",
    exemplos: [
      {
        kanji: "基礎を据える",
        furigana: "きそをすえる",
        romaji: "kiso o sueru",
        traducao: "estabelecer as bases",
      },
      {
        kanji: "据える",
        furigana: "すえる",
        romaji: "su.eru",
        traducao: "posicionar; estabelecer",
      },
      {
        kanji: "据",
        furigana: "す.える",
        romaji: "su.eru",
        traducao: "posicionar; estabelecer",
      },
    ],
  },
  {
    kanji: "肥",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "こ.える", romaji: "ko.eru" }],
    significado: "engordar; fertilizar",
    exemplos: [
      {
        kanji: "肥料",
        furigana: "ひりょう",
        romaji: "hiryou",
        traducao: "adubo",
      },
      {
        kanji: "肥える",
        furigana: "こえる",
        romaji: "koeru",
        traducao: "engordar; fertilizar",
      },
      {
        kanji: "肥",
        furigana: "こ.える",
        romaji: "ko.eru",
        traducao: "engordar; fertilizar",
      },
    ],
  },
  {
    kanji: "徐",
    onYomi: [{ katakana: "ジョ", romaji: "jo" }],
    kunYomi: [{ hiragana: "おもむ.ろに", romaji: "omomuro.ni" }],
    significado: "devagar; gradualmente",
    exemplos: [
      {
        kanji: "徐々に",
        furigana: "じょじょに",
        romaji: "jojo ni",
        traducao: "gradualmente",
      },
      {
        kanji: "徐行",
        furigana: "じょこう",
        romaji: "jokou",
        traducao: "sinal de trânsito de velocidade reduzida",
      },
      {
        kanji: "徐",
        furigana: "おもむ.ろに",
        romaji: "omomuro.ni",
        traducao: "devagar; gradualmente",
      },
    ],
  },
  {
    kanji: "糖",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [],
    significado: "açúcar",
    exemplos: [
      {
        kanji: "砂糖",
        furigana: "さとう",
        romaji: "satou",
        traducao: "açúcar",
      },
      {
        kanji: "糖分",
        furigana: "とうぶん",
        romaji: "toubun",
        traducao: "conteúdo de açúcar",
      },
      {
        kanji: "糖",
        furigana: "トウ",
        romaji: "tou",
        traducao: "açúcar",
      },
    ],
  },
  {
    kanji: "搭",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [],
    significado: "embarcar; subir a bordo",
    exemplos: [
      {
        kanji: "乗り損なう",
        furigana: "のりそこなう",
        romaji: "norisokonau",
        traducao: "perder um transporte",
      },
      {
        kanji: "搭乗",
        furigana: "とうじょう",
        romaji: "toujou",
        traducao: "embarque",
      },
      {
        kanji: "搭",
        furigana: "トウ",
        romaji: "tou",
        traducao: "embarcar; subir a bordo",
      },
    ],
  },
  {
    kanji: "盾",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [{ hiragana: "たて", romaji: "tate" }],
    significado: "escudo; proteção",
    exemplos: [
      {
        kanji: "盾",
        furigana: "たて",
        romaji: "tate",
        traducao: "escudo; proteção",
      },
      {
        kanji: "防盾",
        furigana: "ぼうじゅん",
        romaji: "boujun",
        traducao: "escudo de proteção",
      },
      {
        kanji: "盾",
        furigana: "ジュン",
        romaji: "jun",
        traducao: "escudo; proteção",
      },
    ],
  },
  {
    kanji: "脈",
    onYomi: [{ katakana: "ミャク", romaji: "myaku" }],
    kunYomi: [],
    significado: "pulso; veia",
    exemplos: [
      {
        kanji: "脈拍",
        furigana: "みゃくはく",
        romaji: "myakuhaku",
        traducao: "batimento cardíaco",
      },
      {
        kanji: "脈",
        furigana: "みゃく",
        romaji: "myaku",
        traducao: "pulso; veia",
      },
      {
        kanji: "脈",
        furigana: "すじ",
        romaji: "suji",
        traducao: "pulso; veia",
      },
    ],
  },
  {
    kanji: "滝",
    onYomi: [{ katakana: "ロウ", romaji: "rou" }],
    kunYomi: [{ hiragana: "たき", romaji: "taki" }],
    significado: "cachoeira",
    exemplos: [
      {
        kanji: "滝行",
        furigana: "たきぎょう",
        romaji: "takigyou",
        traducao: "purificação espiritual sob uma cachoeira",
      },
      {
        kanji: "滝",
        furigana: "たき",
        romaji: "taki",
        traducao: "cachoeira",
      },
      {
        kanji: "滝",
        furigana: "ロウ",
        romaji: "rou",
        traducao: "cachoeira",
      },
    ],
  },
  {
    kanji: "軌",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "trilho; rota",
    exemplos: [
      {
        kanji: "軌道",
        furigana: "きどう",
        romaji: "kidou",
        traducao: "órbita; trilha",
      },
      {
        kanji: "軌跡",
        furigana: "きせき",
        romaji: "kiseki",
        traducao: "trilha; rastro",
      },
      {
        kanji: "軌",
        furigana: "キ",
        romaji: "ki",
        traducao: "trilho; rota",
      },
    ],
  },
  {
    kanji: "俵",
    onYomi: [{ katakana: "ヒョウ", romaji: "hyou" }],
    kunYomi: [{ hiragana: "たわら", romaji: "tawara" }],
    significado: "saco; fardo",
    exemplos: [
      {
        kanji: "米俵",
        furigana: "こめだわら",
        romaji: "kome tawara",
        traducao: "saco de arroz",
      },
      {
        kanji: "俵",
        furigana: "たわら",
        romaji: "tawara",
        traducao: "saco; fardo",
      },
      {
        kanji: "俵",
        furigana: "ヒョウ",
        romaji: "hyou",
        traducao: "saco; fardo",
      },
    ],
  },
  {
    kanji: "妨",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "さまた.げる", romaji: "samata.geru" }],
    significado: "impedir; atrapalhar",
    exemplos: [
      {
        kanji: "妨害",
        furigana: "ぼうがい",
        romaji: "bougai",
        traducao: "interferência; obstrução",
      },
      {
        kanji: "妨げる",
        furigana: "さまたげる",
        romaji: "samata.geru",
        traducao: "impedir; atrapalhar",
      },
      {
        kanji: "妨",
        furigana: "さまた.げる",
        romaji: "samata.geru",
        traducao: "impedir; atrapalhar",
      },
    ],
  },
  {
    kanji: "擦",
    onYomi: [{ katakana: "サツ", romaji: "satsu" }],
    kunYomi: [{ hiragana: "す.る", romaji: "su.ru" }],
    significado: "esfregar; polir",
    exemplos: [
      {
        kanji: "擦る",
        furigana: "する",
        romaji: "suru",
        traducao: "esfregar; polir",
      },
      {
        kanji: "擦りむく",
        furigana: "すりむく",
        romaji: "surimuku",
        traducao: "raspar; arranhar",
      },
      {
        kanji: "擦",
        furigana: "す.る",
        romaji: "su.ru",
        traducao: "esfregar; polir",
      },
    ],
  },
  {
    kanji: "鯨",
    onYomi: [{ katakana: "ゲイ", romaji: "gei" }],
    kunYomi: [{ hiragana: "くじら", romaji: "kujira" }],
    significado: "baleia",
    exemplos: [
      {
        kanji: "鯨",
        furigana: "くじら",
        romaji: "kujira",
        traducao: "baleia",
      },
      {
        kanji: "鯨油",
        furigana: "げいゆ",
        romaji: "geiyu",
        traducao: "óleo de baleia",
      },
      {
        kanji: "鯨",
        furigana: "ゲイ",
        romaji: "gei",
        traducao: "baleia",
      },
    ],
  },
  {
    kanji: "荘",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [],
    significado: "villa; pousada",
    exemplos: [
      {
        kanji: "別荘",
        furigana: "べっそう",
        romaji: "bessou",
        traducao: "casa de veraneio; villa",
      },
      {
        kanji: "荘厳",
        furigana: "そうごん",
        romaji: "sougon",
        traducao: "magnificência; grandiosidade",
      },
      {
        kanji: "荘",
        furigana: "ソウ",
        romaji: "sou",
        traducao: "villa; pousada",
      },
    ],
  },
  {
    kanji: "諾",
    onYomi: [{ katakana: "ダク", romaji: "daku" }],
    kunYomi: [],
    significado: "consentimento; acordo",
    exemplos: [
      {
        kanji: "承諾",
        furigana: "しょうだく",
        romaji: "shoudaku",
        traducao: "consentimento; acordo",
      },
      {
        kanji: "諾否",
        furigana: "だくひ",
        romaji: "dakuhi",
        traducao: "aprovação ou desaprovação",
      },
      {
        kanji: "諾",
        furigana: "ダク",
        romaji: "daku",
        traducao: "consentimento; acordo",
      },
    ],
  },
  {
    kanji: "雷",
    onYomi: [{ katakana: "ライ", romaji: "rai" }],
    kunYomi: [{ hiragana: "かみなり", romaji: "kaminari" }],
    significado: "raio; trovão",
    exemplos: [
      {
        kanji: "雷雨",
        furigana: "らいう",
        romaji: "raiu",
        traducao: "chuva com trovões",
      },
      {
        kanji: "雷",
        furigana: "かみなり",
        romaji: "kaminari",
        traducao: "raio; trovão",
      },
      {
        kanji: "雷",
        furigana: "ライ",
        romaji: "rai",
        traducao: "raio; trovão",
      },
    ],
  },
  {
    kanji: "漂",
    onYomi: [{ katakana: "ヒョウ", romaji: "hyou" }],
    kunYomi: [{ hiragana: "ただよ.う", romaji: "tadayo.u" }],
    significado: "flutuar; derivar",
    exemplos: [
      {
        kanji: "漂流",
        furigana: "ひょうりゅう",
        romaji: "hyouryuu",
        traducao: "deriva; naufrágio",
      },
      {
        kanji: "漂白",
        furigana: "ひょうはく",
        romaji: "hyouhaku",
        traducao: "clareamento; descoramento",
      },
      {
        kanji: "漂",
        furigana: "ただよ.う",
        romaji: "tadayo.u",
        traducao: "flutuar; derivar",
      },
    ],
  },
  {
    kanji: "懐",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "ふところ", romaji: "futokoro" }],
    significado: "peito; abraço; nostalgia",
    exemplos: [
      {
        kanji: "懐かしい",
        furigana: "なつかしい",
        romaji: "natsukashii",
        traducao: "nostálgico",
      },
      {
        kanji: "懐中電灯",
        furigana: "かいちゅうでんとう",
        romaji: "kaichuudentou",
        traducao: "lanterna",
      },
      {
        kanji: "懐",
        furigana: "ふところ",
        romaji: "futokoro",
        traducao: "peito; abraço; nostalgia",
      },
    ],
  },
  {
    kanji: "勘",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "intuição; pressentimento",
    exemplos: [
      {
        kanji: "勘違い",
        furigana: "かんちがい",
        romaji: "kanchigai",
        traducao: "mal-entendido",
      },
      {
        kanji: "勘定",
        furigana: "かんじょう",
        romaji: "kanjou",
        traducao: "conta; fatura",
      },
      {
        kanji: "勘",
        furigana: "カン",
        romaji: "kan",
        traducao: "intuição; pressentimento",
      },
    ],
  },
  {
    kanji: "栽",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [],
    significado: "cultivar; plantar",
    exemplos: [
      {
        kanji: "栽培",
        furigana: "さいばい",
        romaji: "saibai",
        traducao: "cultivo; plantio",
      },
      {
        kanji: "盆栽",
        furigana: "ぼんさい",
        romaji: "bonsai",
        traducao: "bonsai",
      },
      {
        kanji: "栽",
        furigana: "サイ",
        romaji: "sai",
        traducao: "cultivar; plantar",
      },
    ],
  },
  {
    kanji: "拐",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [],
    significado: "sequestro",
    exemplos: [
      {
        kanji: "誘拐",
        furigana: "ゆうかい",
        romaji: "yuukai",
        traducao: "sequestro",
      },
      {
        kanji: "拐帯",
        furigana: "かいたい",
        romaji: "kaitai",
        traducao: "cinta de segurança",
      },
      {
        kanji: "拐",
        furigana: "カイ",
        romaji: "kai",
        traducao: "sequestro",
      },
    ],
  },
  {
    kanji: "駄",
    onYomi: [{ katakana: "ダ", romaji: "da" }],
    kunYomi: [],
    significado: "carga; inútil",
    exemplos: [
      {
        kanji: "無駄",
        furigana: "むだ",
        romaji: "muda",
        traducao: "desperdício",
      },
      {
        kanji: "駄菓子",
        furigana: "だがし",
        romaji: "dagashi",
        traducao: "doce barato",
      },
      {
        kanji: "駄",
        furigana: "ダ",
        romaji: "da",
        traducao: "carga; inútil",
      },
    ],
  },
  {
    kanji: "添",
    onYomi: [{ katakana: "テン", romaji: "ten" }],
    kunYomi: [{ hiragana: "そ.える", romaji: "so.eru" }],
    significado: "acrescentar; anexar",
    exemplos: [
      {
        kanji: "添削",
        furigana: "てんさく",
        romaji: "tensaku",
        traducao: "revisão; edição",
      },
      {
        kanji: "添付",
        furigana: "てんぷ",
        romaji: "tenpu",
        traducao: "anexo; anexar",
      },
      {
        kanji: "添",
        furigana: "そ.える",
        romaji: "so.eru",
        traducao: "acrescentar; anexar",
      },
    ],
  },
  {
    kanji: "冠",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "かんむり", romaji: "kanmuri" }],
    significado: "coroa; chapéu",
    exemplos: [
      {
        kanji: "冠",
        furigana: "かんむり",
        romaji: "kanmuri",
        traducao: "coroa; chapéu",
      },
      {
        kanji: "冠婚葬祭",
        furigana: "かんこんそうさい",
        romaji: "kankonsousai",
        traducao: "eventos sociais",
      },
      {
        kanji: "冠",
        furigana: "カン",
        romaji: "kan",
        traducao: "coroa; chapéu",
      },
    ],
  },
  {
    kanji: "斜",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "なな.め", romaji: "nana.me" }],
    significado: "inclinado; diagonal",
    exemplos: [
      {
        kanji: "斜面",
        furigana: "しゃめん",
        romaji: "shamen",
        traducao: "encosta; declive",
      },
      {
        kanji: "斜め",
        furigana: "ななめ",
        romaji: "naname",
        traducao: "inclinado; diagonal",
      },
      {
        kanji: "斜",
        furigana: "シャ",
        romaji: "sha",
        traducao: "inclinado; diagonal",
      },
    ],
  },
  {
    kanji: "鏡",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "かがみ", romaji: "kagami" }],
    significado: "espelho",
    exemplos: [
      {
        kanji: "鏡",
        furigana: "かがみ",
        romaji: "kagami",
        traducao: "espelho",
      },
      {
        kanji: "鏡台",
        furigana: "きょうだい",
        romaji: "kyoudai",
        traducao: "penteadeira; toucador",
      },
      {
        kanji: "鏡",
        furigana: "キョウ",
        romaji: "kyou",
        traducao: "espelho",
      },
    ],
  },
  {
    kanji: "聡",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "さとし", romaji: "satoshi" }],
    significado: "inteligente; sábio",
    exemplos: [
      {
        kanji: "聡明",
        furigana: "そうめい",
        romaji: "soumei",
        traducao: "inteligente; sábio",
      },
      {
        kanji: "聡",
        furigana: "さとし",
        romaji: "satoshi",
        traducao: "inteligente; sábio",
      },
      {
        kanji: "聡",
        furigana: "ソウ",
        romaji: "sou",
        traducao: "inteligente; sábio",
      },
    ],
  },
  {
    kanji: "浪",
    onYomi: [{ katakana: "ロウ", romaji: "rou" }],
    kunYomi: [],
    significado: "vagabundo; onda",
    exemplos: [
      {
        kanji: "放浪",
        furigana: "ほうろう",
        romaji: "hourou",
        traducao: "vagabundagem",
      },
      {
        kanji: "浪人",
        furigana: "ろうにん",
        romaji: "rounin",
        traducao: "samurai sem mestre",
      },
      {
        kanji: "浪",
        furigana: "ロウ",
        romaji: "rou",
        traducao: "vagabundo; onda",
      },
    ],
  },
  {
    kanji: "亜",
    onYomi: [
      { katakana: "ア", romaji: "a" },
      { katakana: "アン", romaji: "an" },
    ],
    kunYomi: [],
    significado: "sub-; segundo",
    exemplos: [
      {
        kanji: "亜熱帯",
        furigana: "あねったい",
        romaji: "anettai",
        traducao: "subtrópico",
      },
      {
        kanji: "亜",
        furigana: "ア",
        romaji: "a",
        traducao: "sub-; segundo",
      },
      {
        kanji: "亜",
        furigana: "アン",
        romaji: "an",
        traducao: "sub-; segundo",
      },
    ],
  },
  {
    kanji: "覧",
    onYomi: [{ katakana: "ラン", romaji: "ran" }],
    kunYomi: [],
    significado: "exibição; ver",
    exemplos: [
      {
        kanji: "観覧",
        furigana: "かんらん",
        romaji: "kanran",
        traducao: "visualização",
      },
      {
        kanji: "一覧",
        furigana: "いちらん",
        romaji: "ichiran",
        traducao: "lista; tabela",
      },
      {
        kanji: "覧",
        furigana: "ラン",
        romaji: "ran",
        traducao: "exibição; ver",
      },
    ],
  },
  {
    kanji: "詐",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [],
    significado: "fraude; engano",
    exemplos: [
      {
        kanji: "詐欺",
        furigana: "さぎ",
        romaji: "sagi",
        traducao: "fraude; engano",
      },
      {
        kanji: "詐称",
        furigana: "さしょう",
        romaji: "sasshou",
        traducao: "falsa declaração",
      },
      {
        kanji: "詐",
        furigana: "サ",
        romaji: "sa",
        traducao: "fraude; engano",
      },
    ],
  },
  {
    kanji: "壇",
    onYomi: [{ katakana: "ダン", romaji: "dan" }],
    kunYomi: [],
    significado: "plataforma; pódio",
    exemplos: [
      {
        kanji: "壇上",
        furigana: "だんじょう",
        romaji: "danjou",
        traducao: "palco",
      },
      {
        kanji: "演壇",
        furigana: "えんだん",
        romaji: "endan",
        traducao: "palanque",
      },
      {
        kanji: "壇",
        furigana: "ダン",
        romaji: "dan",
        traducao: "plataforma; pódio",
      },
    ],
  },
  {
    kanji: "勲",
    onYomi: [{ katakana: "クン", romaji: "kun" }],
    kunYomi: [],
    significado: "mérito; honra",
    exemplos: [
      {
        kanji: "勲章",
        furigana: "くんしょう",
        romaji: "kunshou",
        traducao: "medalha de honra",
      },
      {
        kanji: "勲功",
        furigana: "くんこう",
        romaji: "kunkou",
        traducao: "mérito",
      },
      {
        kanji: "勲",
        furigana: "クン",
        romaji: "kun",
        traducao: "mérito; honra",
      },
    ],
  },
  {
    kanji: "魔",
    onYomi: [{ katakana: "マ", romaji: "ma" }],
    kunYomi: [],
    significado: "demônio; mal",
    exemplos: [
      {
        kanji: "魔法",
        furigana: "まほう",
        romaji: "mahou",
        traducao: "magia",
      },
      {
        kanji: "魔力",
        furigana: "まりょく",
        romaji: "maryoku",
        traducao: "poder mágico",
      },
      {
        kanji: "魔",
        furigana: "マ",
        romaji: "ma",
        traducao: "demônio; mal",
      },
    ],
  },
  {
    kanji: "酬",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [],
    significado: "recompensa; gratidão",
    exemplos: [
      {
        kanji: "報酬",
        furigana: "ほうしゅう",
        romaji: "houshuu",
        traducao: "recompensa",
      },
      {
        kanji: "謝酬",
        furigana: "しゃしゅう",
        romaji: "shashuu",
        traducao: "gratidão",
      },
      {
        kanji: "酬",
        furigana: "シュウ",
        romaji: "shuu",
        traducao: "recompensa; gratidão",
      },
    ],
  },
  {
    kanji: "紫",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "むらさき", romaji: "murasaki" }],
    significado: "roxo; violeta",
    exemplos: [
      {
        kanji: "紫外線",
        furigana: "しがいせん",
        romaji: "shigaisen",
        traducao: "raios ultravioleta",
      },
      {
        kanji: "紫",
        furigana: "むらさき",
        romaji: "murasaki",
        traducao: "roxo; violeta",
      },
      {
        kanji: "紫",
        furigana: "シ",
        romaji: "shi",
        traducao: "roxo; violeta",
      },
    ],
  },
  {
    kanji: "曙",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [{ hiragana: "あけぼの", romaji: "akebono" }],
    significado: "amanhecer",
    exemplos: [
      {
        kanji: "曙光",
        furigana: "しょこう",
        romaji: "shokou",
        traducao: "raio de luz",
      },
      {
        kanji: "黎明",
        furigana: "れいめい",
        romaji: "reimei",
        traducao: "amanhecer",
      },
      {
        kanji: "曙",
        furigana: "あけぼの",
        romaji: "akebono",
        traducao: "amanhecer",
      },
    ],
  },
  {
    kanji: "紋",
    onYomi: [{ katakana: "モン", romaji: "mon" }],
    kunYomi: [],
    significado: "brasão; padrão",
    exemplos: [
      {
        kanji: "家紋",
        furigana: "かもん",
        romaji: "kamon",
        traducao: "brasão familiar",
      },
      {
        kanji: "紋章",
        furigana: "もんしょう",
        romaji: "monshou",
        traducao: "emblema",
      },
      {
        kanji: "紋",
        furigana: "モン",
        romaji: "mon",
        traducao: "brasão; padrão",
      },
    ],
  },
  {
    kanji: "卸",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "おろ.す", romaji: "oro.su" }],
    significado: "atacado; descontar",
    exemplos: [
      {
        kanji: "卸売り",
        furigana: "おろしうり",
        romaji: "oroshiuri",
        traducao: "venda por atacado",
      },
      {
        kanji: "値卸し",
        furigana: "ねおろし",
        romaji: "neoroshi",
        traducao: "desconto",
      },
      {
        kanji: "卸",
        furigana: "シャ",
        romaji: "sha",
        traducao: "atacado; descontar",
      },
    ],
  },
  {
    kanji: "奮",
    onYomi: [{ katakana: "フン", romaji: "fun" }],
    kunYomi: [{ hiragana: "ふる.う", romaji: "furu.u" }],
    significado: "excitar; estimular",
    exemplos: [
      {
        kanji: "奮闘",
        furigana: "ふんとう",
        romaji: "funtou",
        traducao: "luta; esforço",
      },
      {
        kanji: "奮起",
        furigana: "ふんき",
        romaji: "funki",
        traducao: "motivação",
      },
      {
        kanji: "奮",
        furigana: "フン",
        romaji: "fun",
        traducao: "excitar; estimular",
      },
    ],
  },
  {
    kanji: "欄",
    onYomi: [{ katakana: "ラン", romaji: "ran" }],
    kunYomi: [],
    significado: "coluna; seção",
    exemplos: [
      {
        kanji: "広告欄",
        furigana: "こうこくらん",
        romaji: "koukokuran",
        traducao: "seção de anúncios",
      },
      {
        kanji: "欄外",
        furigana: "らんがい",
        romaji: "rangai",
        traducao: "fora da margem",
      },
      {
        kanji: "欄",
        furigana: "ラン",
        romaji: "ran",
        traducao: "coluna; seção",
      },
    ],
  },
  {
    kanji: "逸",
    onYomi: [{ katakana: "イツ", romaji: "itsu" }],
    kunYomi: [{ hiragana: "そ.れる", romaji: "so.reru" }],
    significado: "fugir; exceção",
    exemplos: [
      {
        kanji: "逸話",
        furigana: "いつわ",
        romaji: "itsuwa",
        traducao: "anedota",
      },
      {
        kanji: "逸脱",
        furigana: "いつだつ",
        romaji: "itsudatsu",
        traducao: "desvio",
      },
      {
        kanji: "逸",
        furigana: "イツ",
        romaji: "itsu",
        traducao: "fugir; exceção",
      },
    ],
  },
  {
    kanji: "涯",
    onYomi: [{ katakana: "ガイ", romaji: "gai" }],
    kunYomi: [{ hiragana: "はて", romaji: "hate" }],
    significado: "horizonte; limite",
    exemplos: [
      {
        kanji: "人生の涯",
        furigana: "じんせいのはて",
        romaji: "jinsei no hate",
        traducao: "o limite da vida",
      },
      {
        kanji: "地平線",
        furigana: "ちへいせん",
        romaji: "chiheisen",
        traducao: "horizonte",
      },
      {
        kanji: "涯",
        furigana: "ガイ",
        romaji: "gai",
        traducao: "horizonte; limite",
      },
    ],
  },
  {
    kanji: "拓",
    onYomi: [{ katakana: "タク", romaji: "taku" }],
    kunYomi: [],
    significado: "desenvolver; abrir",
    exemplos: [
      {
        kanji: "開拓",
        furigana: "かいたく",
        romaji: "kaitaku",
        traducao: "desenvolvimento",
      },
      {
        kanji: "拓本",
        furigana: "たくほん",
        romaji: "takuhon",
        traducao: "reprodução",
      },
      {
        kanji: "拓",
        furigana: "タク",
        romaji: "taku",
        traducao: "desenvolver; abrir",
      },
    ],
  },
  {
    kanji: "眼",
    onYomi: [{ katakana: "ガン", romaji: "gan" }],
    kunYomi: [{ hiragana: "まなこ", romaji: "manako" }],
    significado: "olho",
    exemplos: [
      {
        kanji: "目眼",
        furigana: "めがん",
        romaji: "megane",
        traducao: "óculos",
      },
      {
        kanji: "眼科",
        furigana: "がんか",
        romaji: "ganka",
        traducao: "oftalmologia",
      },
      {
        kanji: "眼",
        furigana: "ガン",
        romaji: "gan",
        traducao: "olho",
      },
    ],
  },
  {
    kanji: "獄",
    onYomi: [{ katakana: "ゴク", romaji: "goku" }],
    kunYomi: [],
    significado: "prisão",
    exemplos: [
      {
        kanji: "地獄",
        furigana: "じごく",
        romaji: "jigoku",
        traducao: "inferno",
      },
      {
        kanji: "獄中",
        furigana: "ごくちゅう",
        romaji: "gokuchuu",
        traducao: "prisão",
      },
      {
        kanji: "獄",
        furigana: "ゴク",
        romaji: "goku",
        traducao: "prisão",
      },
    ],
  },
  {
    kanji: "尚",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "ainda mais",
    exemplos: [
      {
        kanji: "尚更",
        furigana: "なおさら",
        romaji: "naosara",
        traducao: "ainda mais",
      },
      {
        kanji: "尚",
        furigana: "ショウ",
        romaji: "shou",
        traducao: "ainda mais",
      },
    ],
  },
  {
    kanji: "彫",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "ほ.る", romaji: "ho.ru" }],
    significado: "entalhar; esculpir",
    exemplos: [
      {
        kanji: "彫刻",
        furigana: "ちょうこく",
        romaji: "choukoku",
        traducao: "escultura",
      },
      {
        kanji: "彫",
        furigana: "ほ.る",
        romaji: "ho.ru",
        traducao: "entalhar; esculpir",
      },
      {
        kanji: "彫",
        furigana: "チョウ",
        romaji: "chou",
        traducao: "entalhar; esculpir",
      },
    ],
  },
  {
    kanji: "穏",
    onYomi: [{ katakana: "オン", romaji: "on" }],
    kunYomi: [{ hiragana: "おだ.やか", romaji: "oda.yaka" }],
    significado: "calmo; tranquilo",
    exemplos: [
      {
        kanji: "安穏",
        furigana: "あんのん",
        romaji: "an'non",
        traducao: "paz",
      },
      {
        kanji: "穏やか",
        furigana: "おだやか",
        romaji: "odayaka",
        traducao: "calmo; tranquilo",
      },
      {
        kanji: "穏",
        furigana: "オン",
        romaji: "on",
        traducao: "calmo; tranquilo",
      },
    ],
  },
  {
    kanji: "顕",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "あきらか", romaji: "akiraka" }],
    significado: "evidente; manifesto",
    exemplos: [
      {
        kanji: "顕微鏡",
        furigana: "けんびきょう",
        romaji: "kenbikyou",
        traducao: "microscópio",
      },
      {
        kanji: "顕著",
        furigana: "けんちょ",
        romaji: "kencho",
        traducao: "notável; marcante",
      },
      {
        kanji: "顕",
        furigana: "ケン",
        romaji: "ken",
        traducao: "evidente; manifesto",
      },
    ],
  },
  {
    kanji: "巧",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "たく.み", romaji: "taku.mi" }],
    significado: "habilidade; habilidoso",
    exemplos: [
      {
        kanji: "巧妙",
        furigana: "こうみょう",
        romaji: "koumyou",
        traducao: "hábil",
      },
      {
        kanji: "巧",
        furigana: "たく.み",
        romaji: "taku.mi",
        traducao: "habilidade; habilidoso",
      },
      {
        kanji: "巧",
        furigana: "コウ",
        romaji: "kou",
        traducao: "habilidade; habilidoso",
      },
    ],
  },
  {
    kanji: "矛",
    onYomi: [{ katakana: "ム", romaji: "mu" }],
    kunYomi: [{ hiragana: "ほこ", romaji: "hoko" }],
    significado: "lança; lança longa",
    exemplos: [
      {
        kanji: "矛盾",
        furigana: "むじゅん",
        romaji: "mujuun",
        traducao: "contradição",
      },
      {
        kanji: "矛先",
        furigana: "ほこさき",
        romaji: "hokosaki",
        traducao: "ponta da lança",
      },
      {
        kanji: "矛",
        furigana: "ム",
        romaji: "mu",
        traducao: "lança; lança longa",
      },
    ],
  },
  {
    kanji: "垣",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "かき", romaji: "kaki" }],
    significado: "cerca; muro",
    exemplos: [
      {
        kanji: "垣根",
        furigana: "かきね",
        romaji: "kakine",
        traducao: "cerca; limite",
      },
      {
        kanji: "塀",
        furigana: "へい",
        romaji: "hei",
        traducao: "muro",
      },
      {
        kanji: "垣",
        furigana: "エン",
        romaji: "en",
        traducao: "cerca; muro",
      },
    ],
  },
  {
    kanji: "欺",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [{ hiragana: "あざむ.く", romaji: "azamu.ku" }],
    significado: "enganar; iludir",
    exemplos: [
      {
        kanji: "欺瞞",
        furigana: "ぎまん",
        romaji: "giman",
        traducao: "enganar; iludir",
      },
      {
        kanji: "欺く",
        furigana: "あざむく",
        romaji: "azamuku",
        traducao: "enganar; iludir",
      },
      {
        kanji: "欺",
        furigana: "ギ",
        romaji: "gi",
        traducao: "enganar; iludir",
      },
    ],
  },
  {
    kanji: "釣",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "つ.る", romaji: "tsu.ru" }],
    significado: "pescar; atrair",
    exemplos: [
      {
        kanji: "釣魚",
        furigana: "ちょうぎょ",
        romaji: "chougyo",
        traducao: "pesca",
      },
      {
        kanji: "釣り",
        furigana: "つり",
        romaji: "tsuri",
        traducao: "pesca",
      },
      {
        kanji: "釣",
        furigana: "チョウ",
        romaji: "chou",
        traducao: "pescar; atrair",
      },
    ],
  },
  {
    kanji: "萩",
    onYomi: [],
    kunYomi: [{ hiragana: "はぎ", romaji: "hagi" }],
    significado: "erva",
    exemplos: [
      {
        kanji: "萩の月",
        furigana: "はぎのつき",
        romaji: "hagi no tsuki",
        traducao: "lua cheia",
      },
      {
        kanji: "萩",
        furigana: "はぎ",
        romaji: "hagi",
        traducao: "erva",
      },
    ],
  },
  {
    kanji: "粛",
    onYomi: [{ katakana: "シュク", romaji: "shuku" }],
    kunYomi: [{ hiragana: "つつし.む", romaji: "tsutsushi.mu" }],
    significado: "solene; respeitoso",
    exemplos: [
      {
        kanji: "粛清",
        furigana: "しゅくせい",
        romaji: "shukusei",
        traducao: "purificação",
      },
      {
        kanji: "厳粛",
        furigana: "げんしゅく",
        romaji: "genshuku",
        traducao: "solene; sério",
      },
      {
        kanji: "粛",
        furigana: "シュク",
        romaji: "shuku",
        traducao: "solene; respeitoso",
      },
    ],
  },
  {
    kanji: "栗",
    onYomi: [{ katakana: "リツ", romaji: "ritsu" }],
    kunYomi: [{ hiragana: "くり", romaji: "kuri" }],
    significado: "castanha",
    exemplos: [
      {
        kanji: "栗の木",
        furigana: "くりのき",
        romaji: "kuri no ki",
        traducao: "castanheiro",
      },
      {
        kanji: "栗",
        furigana: "くり",
        romaji: "kuri",
        traducao: "castanha",
      },
      {
        kanji: "栗",
        furigana: "リツ",
        romaji: "ritsu",
        traducao: "castanha",
      },
    ],
  },
  {
    kanji: "愚",
    onYomi: [{ katakana: "グ", romaji: "gu" }],
    kunYomi: [{ hiragana: "おろ.か", romaji: "oro.ka" }],
    significado: "tolice; tolo",
    exemplos: [
      {
        kanji: "愚痴",
        furigana: "ぐち",
        romaji: "guchi",
        traducao: "reclamação",
      },
      {
        kanji: "愚か",
        furigana: "おろか",
        romaji: "oroka",
        traducao: "tolice; tolo",
      },
      {
        kanji: "愚",
        furigana: "グ",
        romaji: "gu",
        traducao: "tolice; tolo",
      },
    ],
  },
  {
    kanji: "嘉",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [],
    significado: "elogio; louvor",
    exemplos: [
      {
        kanji: "嘉納治五郎",
        furigana: "かのうじごろう",
        romaji: "kanoujigorou",
        traducao: "Kanō Jigorō (nome próprio)",
      },
      {
        kanji: "嘉",
        furigana: "カ",
        romaji: "ka",
        traducao: "elogio; louvor",
      },
    ],
  },
  {
    kanji: "遭",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "あ.う", romaji: "a.u" }],
    significado: "encontrar; sofrer",
    exemplos: [
      {
        kanji: "遭遇",
        furigana: "そうぐう",
        romaji: "souguu",
        traducao: "encontro; encontro fortuito",
      },
      {
        kanji: "遭難",
        furigana: "そうなん",
        romaji: "sounan",
        traducao: "desastre; acidente",
      },
      {
        kanji: "遭",
        furigana: "ソウ",
        romaji: "sou",
        traducao: "encontrar; sofrer",
      },
    ],
  },
  {
    kanji: "架",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "か.ける", romaji: "ka.keru" }],
    significado: "construir; montar",
    exemplos: [
      {
        kanji: "架橋",
        furigana: "かきょう",
        romaji: "kakyou",
        traducao: "ponte",
      },
      {
        kanji: "架ける",
        furigana: "かける",
        romaji: "kakeru",
        traducao: "construir; montar",
      },
      {
        kanji: "架",
        furigana: "カ",
        romaji: "ka",
        traducao: "construir; montar",
      },
    ],
  },
  {
    kanji: "鬼",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "demônio; ogro",
    exemplos: [
      {
        kanji: "鬼神",
        furigana: "きじん",
        romaji: "kijin",
        traducao: "espírito maligno",
      },
      {
        kanji: "鬼",
        furigana: "キ",
        romaji: "ki",
        traducao: "demônio; ogro",
      },
    ],
  },
  {
    kanji: "庶",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [],
    significado: "comum; não nobre",
    exemplos: [
      {
        kanji: "庶民",
        furigana: "しょみん",
        romaji: "shomin",
        traducao: "pessoa comum",
      },
      {
        kanji: "庶",
        furigana: "ショ",
        romaji: "sho",
        traducao: "comum; não nobre",
      },
    ],
  },
  {
    kanji: "稚",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [],
    significado: "imaturo; jovem",
    exemplos: [
      {
        kanji: "幼稚",
        furigana: "ようち",
        romaji: "youchi",
        traducao: "infantil",
      },
      {
        kanji: "稚拙",
        furigana: "ちせつ",
        romaji: "chisetsu",
        traducao: "inexperiente",
      },
      {
        kanji: "稚",
        furigana: "チ",
        romaji: "chi",
        traducao: "imaturo; jovem",
      },
    ],
  },
  {
    kanji: "滋",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [],
    significado: "nutrir; aumentar",
    exemplos: [
      {
        kanji: "滋味",
        furigana: "じみ",
        romaji: "jimi",
        traducao: "sabor; gosto",
      },
      {
        kanji: "栄滋",
        furigana: "えいじ",
        romaji: "eiji",
        traducao: "nutrição",
      },
      {
        kanji: "滋",
        furigana: "ジ",
        romaji: "ji",
        traducao: "nutrir; aumentar",
      },
    ],
  },
  {
    kanji: "幻",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [{ hiragana: "まぼろし", romaji: "maboroshi" }],
    significado: "ilusão; fantasma",
    exemplos: [
      {
        kanji: "幻想",
        furigana: "げんそう",
        romaji: "gensou",
        traducao: "ilusão; fantasia",
      },
      {
        kanji: "幻",
        furigana: "まぼろし",
        romaji: "maboroshi",
        traducao: "ilusão; fantasma",
      },
      {
        kanji: "幻",
        furigana: "ゲン",
        romaji: "gen",
        traducao: "ilusão; fantasma",
      },
    ],
  },
  {
    kanji: "煮",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "に.る", romaji: "ni.ru" }],
    significado: "cozinhar",
    exemplos: [
      {
        kanji: "煮物",
        furigana: "にもの",
        romaji: "nimono",
        traducao: "prato cozido",
      },
      {
        kanji: "煮る",
        furigana: "にる",
        romaji: "niru",
        traducao: "cozinhar",
      },
      {
        kanji: "煮",
        furigana: "シャ",
        romaji: "sha",
        traducao: "cozinhar",
      },
    ],
  },
  {
    kanji: "姫",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "princesa",
    exemplos: [
      {
        kanji: "姫君",
        furigana: "ひめぎみ",
        romaji: "himegimi",
        traducao: "princesa",
      },
      {
        kanji: "姫",
        furigana: "キ",
        romaji: "ki",
        traducao: "princesa",
      },
    ],
  },
  {
    kanji: "誓",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "ちか.う", romaji: "chika.u" }],
    significado: "jurar; prometer",
    exemplos: [
      {
        kanji: "誓約",
        furigana: "せいやく",
        romaji: "seiyaku",
        traducao: "promessa; compromisso",
      },
      {
        kanji: "宣誓",
        furigana: "せんせい",
        romaji: "sensei",
        traducao: "juramento",
      },
      {
        kanji: "誓",
        furigana: "セイ",
        romaji: "sei",
        traducao: "jurar; prometer",
      },
    ],
  },
  {
    kanji: "把",
    onYomi: [{ katakana: "ハ", romaji: "ha" }],
    kunYomi: [],
    significado: "segurar; agarrar",
    exemplos: [
      {
        kanji: "把握",
        furigana: "はあく",
        romaji: "haaku",
        traducao: "entendimento",
      },
      {
        kanji: "把持",
        furigana: "はじ",
        romaji: "haji",
        traducao: "segurar; agarrar",
      },
      {
        kanji: "把",
        furigana: "ハ",
        romaji: "ha",
        traducao: "segurar; agarrar",
      },
    ],
  },
  {
    kanji: "践",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "ふ.む", romaji: "fu.mu" }],
    significado: "pisar; praticar",
    exemplos: [
      {
        kanji: "実践",
        furigana: "じっせん",
        romaji: "jissen",
        traducao: "prática",
      },
      {
        kanji: "践行",
        furigana: "せんこう",
        romaji: "senkou",
        traducao: "implementar",
      },
      {
        kanji: "践",
        furigana: "セン",
        romaji: "sen",
        traducao: "pisar; praticar",
      },
    ],
  },
  {
    kanji: "呈",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "apresentar; oferecer",
    exemplos: [
      {
        kanji: "提呈",
        furigana: "ていてい",
        romaji: "teitei",
        traducao: "submissão",
      },
      {
        kanji: "提案",
        furigana: "ていあん",
        romaji: "teian",
        traducao: "proposta",
      },
      {
        kanji: "呈",
        furigana: "テイ",
        romaji: "tei",
        traducao: "apresentar; oferecer",
      },
    ],
  },
  {
    kanji: "疎",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [{ hiragana: "うと.い", romaji: "uto.i" }],
    significado: "descuidado; negligente",
    exemplos: [
      {
        kanji: "疎遠",
        furigana: "そえん",
        romaji: "soen",
        traducao: "distante",
      },
      {
        kanji: "疎開",
        furigana: "そかい",
        romaji: "sokai",
        traducao: "evacuação",
      },
      {
        kanji: "疎",
        furigana: "ソ",
        romaji: "so",
        traducao: "descuidado; negligente",
      },
    ],
  },
  {
    kanji: "仰",
    onYomi: [{ katakana: "ギョウ", romaji: "gyou" }],
    kunYomi: [{ hiragana: "あお.ぐ", romaji: "ao.gu" }],
    significado: "olhar para cima; reverenciar",
    exemplos: [
      {
        kanji: "仰天",
        furigana: "ぎょうてん",
        romaji: "gyouten",
        traducao: "surpresa",
      },
      {
        kanji: "仰る",
        furigana: "おっしゃる",
        romaji: "ossharu",
        traducao: "dizer; mencionar",
      },
      {
        kanji: "仰",
        furigana: "ギョウ",
        romaji: "gyou",
        traducao: "olhar para cima; reverenciar",
      },
    ],
  },
  {
    kanji: "剛",
    onYomi: [{ katakana: "ゴウ", romaji: "gou" }],
    kunYomi: [],
    significado: "forte; robusto",
    exemplos: [
      {
        kanji: "剛力",
        furigana: "ごうりき",
        romaji: "gouriki",
        traducao: "força",
      },
      {
        kanji: "剛毅",
        furigana: "ごうき",
        romaji: "gouki",
        traducao: "resolução",
      },
      {
        kanji: "剛",
        furigana: "ゴウ",
        romaji: "gou",
        traducao: "forte; robusto",
      },
    ],
  },
  {
    kanji: "疾",
    onYomi: [{ katakana: "シツ", romaji: "shitsu" }],
    kunYomi: [{ hiragana: "はや.い", romaji: "haya.i" }],
    significado: "rápido; veloz",
    exemplos: [
      {
        kanji: "疾病",
        furigana: "しっぺい",
        romaji: "shippai",
        traducao: "doença",
      },
      {
        kanji: "疾走",
        furigana: "しっそう",
        romaji: "shissou",
        traducao: "corrida",
      },
      {
        kanji: "疾",
        furigana: "シツ",
        romaji: "shitsu",
        traducao: "rápido; veloz",
      },
    ],
  },
  {
    kanji: "征",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "ゆ.く", romaji: "yu.ku" }],
    significado: "conquistar; subjugar",
    exemplos: [
      {
        kanji: "征服",
        furigana: "せいふく",
        romaji: "seifuku",
        traducao: "conquista",
      },
      {
        kanji: "征矢",
        furigana: "いくさや",
        romaji: "ikusaya",
        traducao: "flecha de guerra",
      },
      {
        kanji: "征",
        furigana: "セイ",
        romaji: "sei",
        traducao: "conquistar; subjugar",
      },
    ],
  },
  {
    kanji: "砕",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "くだ.く", romaji: "kuda.ku" }],
    significado: "quebrar; triturar",
    exemplos: [
      {
        kanji: "破砕",
        furigana: "はさい",
        romaji: "hasai",
        traducao: "quebrar; demolir",
      },
      {
        kanji: "砕氷",
        furigana: "さいひょう",
        romaji: "saihyou",
        traducao: "quebra-gelo",
      },
      {
        kanji: "砕",
        furigana: "サイ",
        romaji: "sai",
        traducao: "quebrar; triturar",
      },
    ],
  },
  {
    kanji: "謡",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [],
    significado: "canto tradicional",
    exemplos: [
      {
        kanji: "民謡",
        furigana: "みんよう",
        romaji: "minyou",
        traducao: "canto tradicional",
      },
      {
        kanji: "謡",
        furigana: "ヨウ",
        romaji: "you",
        traducao: "canto tradicional",
      },
    ],
  },
  {
    kanji: "嫁",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "よめ", romaji: "yome" }],
    significado: "esposa",
    exemplos: [
      {
        kanji: "新婦",
        furigana: "しんぷ",
        romaji: "shinpu",
        traducao: "noiva",
      },
      {
        kanji: "嫁ぐ",
        furigana: "とつぐ",
        romaji: "tsutsugu",
        traducao: "casar-se",
      },
      {
        kanji: "嫁",
        furigana: "カ",
        romaji: "ka",
        traducao: "esposa",
      },
    ],
  },
  {
    kanji: "謙",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [],
    significado: "humildade",
    exemplos: [
      {
        kanji: "謙虚",
        furigana: "けんきょ",
        romaji: "kenkyo",
        traducao: "humildade",
      },
      {
        kanji: "謙遜",
        furigana: "けんそん",
        romaji: "kenson",
        traducao: "modéstia",
      },
      {
        kanji: "謙",
        furigana: "ケン",
        romaji: "ken",
        traducao: "humildade",
      },
    ],
  },
  {
    kanji: "后",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "imperatriz",
    exemplos: [
      {
        kanji: "皇后",
        furigana: "こうごう",
        romaji: "kougou",
        traducao: "imperatriz",
      },
      {
        kanji: "后宮",
        furigana: "こうきゅう",
        romaji: "koukyuu",
        traducao: "harém",
      },
      {
        kanji: "后",
        furigana: "コウ",
        romaji: "kou",
        traducao: "imperatriz",
      },
    ],
  },
  {
    kanji: "嘆",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "なげ.く", romaji: "nage.ku" }],
    significado: "suspiro; lamentação",
    exemplos: [
      {
        kanji: "嘆息",
        furigana: "たんそく",
        romaji: "tansoku",
        traducao: "suspiro",
      },
      {
        kanji: "嘆く",
        furigana: "なげく",
        romaji: "nageku",
        traducao: "lamentar-se",
      },
      {
        kanji: "嘆",
        furigana: "タン",
        romaji: "tan",
        traducao: "suspiro; lamentação",
      },
    ],
  },
  {
    kanji: "菌",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [],
    significado: "bactéria; fungo",
    exemplos: [
      {
        kanji: "細菌",
        furigana: "さいきん",
        romaji: "saikin",
        traducao: "bactéria",
      },
      {
        kanji: "菌",
        furigana: "キン",
        romaji: "kin",
        traducao: "bactéria; fungo",
      },
    ],
  },
  {
    kanji: "鎌",
    onYomi: [{ katakana: "レン", romaji: "ren" }],
    kunYomi: [{ hiragana: "かま", romaji: "kama" }],
    significado: "foice",
    exemplos: [
      {
        kanji: "草鎌",
        furigana: "くさがま",
        romaji: "kusagama",
        traducao: "foice",
      },
      {
        kanji: "鎌倉",
        furigana: "かまくら",
        romaji: "kamakura",
        traducao: "Kamakura (nome de lugar)",
      },
      {
        kanji: "鎌",
        furigana: "レン",
        romaji: "ren",
        traducao: "foice",
      },
    ],
  },
  {
    kanji: "巣",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "す", romaji: "su" }],
    significado: "ninho",
    exemplos: [
      {
        kanji: "巣立ち",
        furigana: "すだち",
        romaji: "sudachi",
        traducao: "deixar o ninho",
      },
      {
        kanji: "巣",
        furigana: "ソウ",
        romaji: "sou",
        traducao: "ninho",
      },
    ],
  },
  {
    kanji: "頻",
    onYomi: [{ katakana: "ヒン", romaji: "hin" }],
    kunYomi: [{ hiragana: "しき.りに", romaji: "shiki.ri ni" }],
    significado: "frequente; repetido",
    exemplos: [
      {
        kanji: "頻繁",
        furigana: "ひんぱん",
        romaji: "hinpan",
        traducao: "frequente",
      },
      {
        kanji: "頻発",
        furigana: "ひんぱつ",
        romaji: "hinpatsu",
        traducao: "ocorrência frequente",
      },
      {
        kanji: "頻",
        furigana: "ヒン",
        romaji: "hin",
        traducao: "frequente; repetido",
      },
    ],
  },
  {
    kanji: "琴",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [{ hiragana: "こと", romaji: "koto" }],
    significado: "koto (instrumento musical)",
    exemplos: [
      {
        kanji: "箏琴",
        furigana: "そうきん",
        romaji: "soukin",
        traducao: "koto (instrumento musical)",
      },
      {
        kanji: "琴",
        furigana: "キン",
        romaji: "kin",
        traducao: "koto (instrumento musical)",
      },
    ],
  },
  {
    kanji: "班",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [],
    significado: "grupo; equipe",
    exemplos: [
      {
        kanji: "班長",
        furigana: "はんちょう",
        romaji: "hanchou",
        traducao: "líder de grupo",
      },
      {
        kanji: "班",
        furigana: "ハン",
        romaji: "han",
        traducao: "grupo; equipe",
      },
    ],
  },
  {
    kanji: "棚",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "たな", romaji: "tana" }],
    significado: "prateleira; estante",
    exemplos: [
      {
        kanji: "本棚",
        furigana: "ほんだな",
        romaji: "hondana",
        traducao: "estante de livros",
      },
      {
        kanji: "棚",
        furigana: "ホウ",
        romaji: "hou",
        traducao: "prateleira; estante",
      },
    ],
  },
  {
    kanji: "潔",
    onYomi: [{ katakana: "ケツ", romaji: "ketsu" }],
    kunYomi: [{ hiragana: "いさぎよ.い", romaji: "isagiyo.i" }],
    significado: "limpo; puro",
    exemplos: [
      {
        kanji: "清潔",
        furigana: "せいけつ",
        romaji: "seiketsu",
        traducao: "limpeza; higiene",
      },
      {
        kanji: "潔白",
        furigana: "けっぱく",
        romaji: "keppaku",
        traducao: "inocência",
      },
      {
        kanji: "潔",
        furigana: "ケツ",
        romaji: "ketsu",
        traducao: "limpo; puro",
      },
    ],
  },
  {
    kanji: "酷",
    onYomi: [{ katakana: "コク", romaji: "koku" }],
    kunYomi: [{ hiragana: "ひど.い", romaji: "hido.i" }],
    significado: "cruel; severo",
    exemplos: [
      {
        kanji: "残酷",
        furigana: "ざんこく",
        romaji: "zankoku",
        traducao: "crueldade",
      },
      {
        kanji: "過酷",
        furigana: "かこく",
        romaji: "kakoku",
        traducao: "severo",
      },
      {
        kanji: "酷",
        furigana: "コク",
        romaji: "koku",
        traducao: "cruel; severo",
      },
    ],
  },
  {
    kanji: "宰",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [],
    significado: "governar; liderar",
    exemplos: [
      {
        kanji: "宰相",
        furigana: "さいしょう",
        romaji: "saishou",
        traducao: "primeiro-ministro",
      },
      {
        kanji: "宰",
        furigana: "サイ",
        romaji: "sai",
        traducao: "governar; liderar",
      },
    ],
  },
  {
    kanji: "廊",
    onYomi: [{ katakana: "ロウ", romaji: "rou" }],
    kunYomi: [],
    significado: "corredor; passagem",
    exemplos: [
      {
        kanji: "回廊",
        furigana: "かいろう",
        romaji: "kairou",
        traducao: "corredor",
      },
      {
        kanji: "廊下",
        furigana: "ろうか",
        romaji: "rouka",
        traducao: "corredor",
      },
      {
        kanji: "廊",
        furigana: "ロウ",
        romaji: "rou",
        traducao: "corredor; passagem",
      },
    ],
  },
  {
    kanji: "寂",
    onYomi: [{ katakana: "ジャク", romaji: "jaku" }],
    kunYomi: [{ hiragana: "さび", romaji: "sabi" }],
    significado: "solidão; silêncio",
    exemplos: [
      {
        kanji: "寂寞",
        furigana: "せきばく",
        romaji: "sekibaku",
        traducao: "solidão",
      },
      {
        kanji: "寂しい",
        furigana: "さびしい",
        romaji: "sabishii",
        traducao: "solitário",
      },
      {
        kanji: "寂",
        furigana: "ジャク",
        romaji: "jaku",
        traducao: "solidão; silêncio",
      },
    ],
  },
  {
    kanji: "辰",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "dragão",
    exemplos: [
      {
        kanji: "辰年",
        furigana: "しんねん",
        romaji: "shinnen",
        traducao: "ano do dragão",
      },
      {
        kanji: "辰",
        furigana: "シン",
        romaji: "shin",
        traducao: "dragão",
      },
    ],
  },
  {
    kanji: "霞",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "かすみ", romaji: "kasumi" }],
    significado: "neblina",
    exemplos: [
      {
        kanji: "霞む",
        furigana: "かすむ",
        romaji: "kasumu",
        traducao: "ficar nebuloso",
      },
      {
        kanji: "霞",
        furigana: "カ",
        romaji: "ka",
        traducao: "neblina",
      },
    ],
  },
  {
    kanji: "伏",
    onYomi: [{ katakana: "フク", romaji: "fuku" }],
    kunYomi: [{ hiragana: "ふ.せる", romaji: "fu.seru" }],
    significado: "prostrar-se; esconder-se",
    exemplos: [
      {
        kanji: "潜伏",
        furigana: "せんぷく",
        romaji: "senpuku",
        traducao: "infiltração",
      },
      {
        kanji: "伏せる",
        furigana: "ふせる",
        romaji: "fuseru",
        traducao: "prostrar-se",
      },
      {
        kanji: "伏",
        furigana: "フク",
        romaji: "fuku",
        traducao: "prostrar-se; esconder-se",
      },
    ],
  },
  {
    kanji: "碁",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [],
    significado: "go (jogo de tabuleiro)",
    exemplos: [
      {
        kanji: "囲碁",
        furigana: "いご",
        romaji: "igo",
        traducao: "go (jogo de tabuleiro)",
      },
      {
        kanji: "碁盤",
        furigana: "ごばん",
        romaji: "goban",
        traducao: "tabuleiro de go",
      },
      {
        kanji: "碁",
        furigana: "ゴ",
        romaji: "go",
        traducao: "go (jogo de tabuleiro)",
      },
    ],
  },
  {
    kanji: "俗",
    onYomi: [{ katakana: "ゾク", romaji: "zoku" }],
    kunYomi: [],
    significado: "vulgar; comum",
    exemplos: [
      {
        kanji: "俗語",
        furigana: "ぞくご",
        romaji: "zokugo",
        traducao: "gíria",
      },
      {
        kanji: "俗",
        furigana: "ゾク",
        romaji: "zoku",
        traducao: "vulgar; comum",
      },
    ],
  },
  {
    kanji: "漠",
    onYomi: [{ katakana: "バク", romaji: "baku" }],
    kunYomi: [],
    significado: "vago; amplo",
    exemplos: [
      {
        kanji: "荒漠",
        furigana: "こうばく",
        romaji: "koubaku",
        traducao: "deserto",
      },
      {
        kanji: "広漠",
        furigana: "こうばく",
        romaji: "koubaku",
        traducao: "extenso",
      },
      {
        kanji: "漠",
        furigana: "バク",
        romaji: "baku",
        traducao: "vago; amplo",
      },
    ],
  },
  {
    kanji: "邪",
    onYomi: [{ katakana: "ジャ", romaji: "ja" }],
    kunYomi: [{ hiragana: "よこしま", romaji: "yokoshima" }],
    significado: "mal; mau",
    exemplos: [
      {
        kanji: "邪悪",
        furigana: "じゃあく",
        romaji: "jaaku",
        traducao: "mal; malvado",
      },
      {
        kanji: "邪心",
        furigana: "じゃしん",
        romaji: "jashin",
        traducao: "más intenções",
      },
      {
        kanji: "邪",
        furigana: "ジャ",
        romaji: "ja",
        traducao: "mal; mau",
      },
    ],
  },
  {
    kanji: "晶",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "cristal; brilhante",
    exemplos: [
      {
        kanji: "結晶",
        furigana: "けっしょう",
        romaji: "kesshou",
        traducao: "cristal",
      },
      {
        kanji: "晶",
        furigana: "ショウ",
        romaji: "shou",
        traducao: "cristal; brilhante",
      },
    ],
  },
  {
    kanji: "墨",
    onYomi: [{ katakana: "ボク", romaji: "boku" }],
    kunYomi: [{ hiragana: "すみ", romaji: "sumi" }],
    significado: "tinta; tinteiro",
    exemplos: [
      {
        kanji: "墨",
        furigana: "すみ",
        romaji: "sumi",
        traducao: "tinta; tinteiro",
      },
      {
        kanji: "墨書",
        furigana: "ぼくしょ",
        romaji: "bokusho",
        traducao: "escrita a tinta",
      },
      {
        kanji: "墨",
        furigana: "ボク",
        romaji: "boku",
        traducao: "tinta; tinteiro",
      },
    ],
  },
  {
    kanji: "鎮",
    onYomi: [{ katakana: "チン", romaji: "chin" }],
    kunYomi: [],
    significado: "pacificar; acalmar",
    exemplos: [
      {
        kanji: "鎮静",
        furigana: "ちんせい",
        romaji: "chinsei",
        traducao: "calma",
      },
      {
        kanji: "鎮める",
        furigana: "しずめる",
        romaji: "shizumeru",
        traducao: "acalmar",
      },
      {
        kanji: "鎮",
        furigana: "チン",
        romaji: "chin",
        traducao: "pacificar; acalmar",
      },
    ],
  },
  {
    kanji: "洞",
    onYomi: [{ katakana: "ドウ", romaji: "dou" }],
    kunYomi: [{ hiragana: "ほら", romaji: "hora" }],
    significado: "caverna; buraco",
    exemplos: [
      {
        kanji: "洞窟",
        furigana: "どうくつ",
        romaji: "doukutsu",
        traducao: "caverna",
      },
      {
        kanji: "突破口",
        furigana: "とっぱぐち",
        romaji: "toppaguchi",
        traducao: "ponto de fuga",
      },
      {
        kanji: "洞",
        furigana: "ドウ",
        romaji: "dou",
        traducao: "caverna; buraco",
      },
    ],
  },
  {
    kanji: "履",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [{ hiragana: "は.く", romaji: "ha.ku" }],
    significado: "calçar; usar",
    exemplos: [
      {
        kanji: "履歴",
        furigana: "りれき",
        romaji: "rirekI",
        traducao: "histórico",
      },
      {
        kanji: "履く",
        furigana: "はく",
        romaji: "haku",
        traducao: "calçar",
      },
      {
        kanji: "履",
        furigana: "リ",
        romaji: "ri",
        traducao: "calçar; usar",
      },
    ],
  },
  {
    kanji: "劣",
    onYomi: [{ katakana: "レツ", romaji: "retsu" }],
    kunYomi: [{ hiragana: "おと.る", romaji: "oto.ru" }],
    significado: "inferior; fraco",
    exemplos: [
      {
        kanji: "劣等感",
        furigana: "れっとうかん",
        romaji: "rettoukan",
        traducao: "complexo de inferioridade",
      },
      {
        kanji: "劣る",
        furigana: "おとる",
        romaji: "otoru",
        traducao: "ser inferior",
      },
      {
        kanji: "劣",
        furigana: "レツ",
        romaji: "retsu",
        traducao: "inferior; fraco",
      },
    ],
  },
  {
    kanji: "那",
    onYomi: [{ katakana: "ナ", romaji: "na" }],
    kunYomi: [],
    significado: "tal; aquele",
    exemplos: [
      {
        kanji: "何那",
        furigana: "なにな",
        romaji: "nani na",
        traducao: "o quê",
      },
      {
        kanji: "那",
        furigana: "ナ",
        romaji: "na",
        traducao: "tal; aquele",
      },
    ],
  },
  {
    kanji: "殴",
    onYomi: [{ katakana: "オウ", romaji: "ou" }],
    kunYomi: [{ hiragana: "なぐ.る", romaji: "nagu.ru" }],
    significado: "agredir; bater",
    exemplos: [
      {
        kanji: "殴打",
        furigana: "おうだ",
        romaji: "ouda",
        traducao: "agressão física",
      },
      {
        kanji: "殴る",
        furigana: "なぐる",
        romaji: "naguru",
        traducao: "bater",
      },
      {
        kanji: "殴",
        furigana: "オウ",
        romaji: "ou",
        traducao: "agredir; bater",
      },
    ],
  },
  {
    kanji: "娠",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "gravidez",
    exemplos: [
      {
        kanji: "妊娠",
        furigana: "にんしん",
        romaji: "ninshin",
        traducao: "gravidez",
      },
      {
        kanji: "娠期",
        furigana: "しんき",
        romaji: "shinki",
        traducao: "período de gravidez",
      },
      {
        kanji: "娠",
        furigana: "シン",
        romaji: "shin",
        traducao: "gravidez",
      },
    ],
  },
  {
    kanji: "朴",
    onYomi: [{ katakana: "ボク", romaji: "boku" }],
    kunYomi: [{ hiragana: "ほう", romaji: "hou" }],
    significado: "simplicidade; honestidade",
    exemplos: [
      {
        kanji: "素朴",
        furigana: "そぼく",
        romaji: "soboku",
        traducao: "simplicidade",
      },
      {
        kanji: "朴訥",
        furigana: "ぼくとつ",
        romaji: "bokutotsu",
        traducao: "simplicidade; franqueza",
      },
      {
        kanji: "朴",
        furigana: "ボク",
        romaji: "boku",
        traducao: "simplicidade; honestidade",
      },
    ],
  },
  {
    kanji: "亭",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "pavilhão; pousada",
    exemplos: [
      {
        kanji: "旅亭",
        furigana: "りょてい",
        romaji: "ryotei",
        traducao: "pousada",
      },
      {
        kanji: "亭主",
        furigana: "ていしゅ",
        romaji: "teishu",
        traducao: "hospedeiro",
      },
      {
        kanji: "亭",
        furigana: "テイ",
        romaji: "tei",
        traducao: "pavilhão; pousada",
      },
    ],
  },
  {
    kanji: "淳",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [],
    significado: "puro; genuíno",
    exemplos: [
      {
        kanji: "純粋",
        furigana: "じゅんすい",
        romaji: "junsui",
        traducao: "puro",
      },
      {
        kanji: "淳",
        furigana: "ジュン",
        romaji: "jun",
        traducao: "puro; genuíno",
      },
    ],
  },
  {
    kanji: "怪",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "あや.しい", romaji: "aya.shii" }],
    significado: "estranho; suspeito",
    exemplos: [
      {
        kanji: "怪我",
        furigana: "けが",
        romaji: "kega",
        traducao: "lesão",
      },
      {
        kanji: "怪物",
        furigana: "かいぶつ",
        romaji: "kaibutsu",
        traducao: "monstro",
      },
      {
        kanji: "怪",
        furigana: "カイ",
        romaji: "kai",
        traducao: "estranho; suspeito",
      },
    ],
  },
  {
    kanji: "鳩",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "はと", romaji: "hato" }],
    significado: "pombo",
    exemplos: [
      {
        kanji: "鳩時計",
        furigana: "はとどけい",
        romaji: "hatodokei",
        traducao: "relógio cuco",
      },
      {
        kanji: "白鳩",
        furigana: "しろはと",
        romaji: "shirohato",
        traducao: "pombo branco",
      },
      {
        kanji: "鳩",
        furigana: "キュウ",
        romaji: "kyuu",
        traducao: "pombo",
      },
    ],
  },
  {
    kanji: "酔",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "よ.う", romaji: "yo.u" }],
    significado: "embriagado; bêbado",
    exemplos: [
      {
        kanji: "酔っ払い",
        furigana: "よっぱらい",
        romaji: "yopparai",
        traducao: "bêbado",
      },
      {
        kanji: "二日酔い",
        furigana: "ふつかよい",
        romaji: "futsukayoi",
        traducao: "ressaca",
      },
      {
        kanji: "酔",
        furigana: "スイ",
        romaji: "sui",
        traducao: "embriagado; bêbado",
      },
    ],
  },
  {
    kanji: "惜",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "お.しい", romaji: "o.shii" }],
    significado: "lamentar; lamentável",
    exemplos: [
      {
        kanji: "惜敗",
        furigana: "せきはい",
        romaji: "sekihai",
        traducao: "derrota lamentável",
      },
      {
        kanji: "惜しむ",
        furigana: "おしむ",
        romaji: "oshimu",
        traducao: "lamentar",
      },
      {
        kanji: "惜",
        furigana: "セキ",
        romaji: "seki",
        traducao: "lamentar; lamentável",
      },
    ],
  },
  {
    kanji: "穫",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [],
    significado: "colheita",
    exemplos: [
      {
        kanji: "収穫",
        furigana: "しゅうかく",
        romaji: "shuukaku",
        traducao: "colheita",
      },
      {
        kanji: "穫れる",
        furigana: "くわれる",
        romaji: "kuwareru",
        traducao: "ser colhido",
      },
      {
        kanji: "穫",
        furigana: "カク",
        romaji: "kaku",
        traducao: "colheita",
      },
    ],
  },
  {
    kanji: "佳",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [],
    significado: "excelente; bonito",
    exemplos: [
      {
        kanji: "佳作",
        furigana: "かさく",
        romaji: "kasaku",
        traducao: "obra-prima",
      },
      {
        kanji: "佳",
        furigana: "カ",
        romaji: "ka",
        traducao: "excelente; bonito",
      },
    ],
  },
  {
    kanji: "潤",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [{ hiragana: "うるお.う", romaji: "uruo.u" }],
    significado: "rico; úmido",
    exemplos: [
      {
        kanji: "潤滑",
        furigana: "じゅんかつ",
        romaji: "junkatsu",
        traducao: "lubrificação",
      },
      {
        kanji: "湿潤",
        furigana: "しつじゅん",
        romaji: "shitsujun",
        traducao: "umedecimento",
      },
      {
        kanji: "潤",
        furigana: "ジュン",
        romaji: "jun",
        traducao: "rico; úmido",
      },
    ],
  },
  {
    kanji: "悼",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "いた.む", romaji: "ita.mu" }],
    significado: "lamentar; chorar",
    exemplos: [
      {
        kanji: "哀悼",
        furigana: "あいとう",
        romaji: "aitou",
        traducao: "luto",
      },
      {
        kanji: "悼む",
        furigana: "いたむ",
        romaji: "itamu",
        traducao: "lamentar",
      },
      {
        kanji: "悼",
        furigana: "トウ",
        romaji: "tou",
        traducao: "lamentar; chorar",
      },
    ],
  },
  {
    kanji: "乏",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "とぼ.しい", romaji: "tobo.shii" }],
    significado: "pobre; escasso",
    exemplos: [
      {
        kanji: "乏しい",
        furigana: "とぼしい",
        romaji: "toboshii",
        traducao: "pobre; escasso",
      },
      {
        kanji: "乏",
        furigana: "ボウ",
        romaji: "bou",
        traducao: "pobre; escasso",
      },
    ],
  },
  {
    kanji: "該",
    onYomi: [{ katakana: "ガイ", romaji: "gai" }],
    kunYomi: [],
    significado: "relacionado; concernente",
    exemplos: [
      {
        kanji: "該当",
        furigana: "がいとう",
        romaji: "gaitou",
        traducao: "pertinente",
      },
      {
        kanji: "該",
        furigana: "ガイ",
        romaji: "gai",
        traducao: "relacionado; concernente",
      },
    ],
  },
  {
    kanji: "赴",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "おもむ.く", romaji: "omo.mu.ku" }],
    significado: "dirigir-se; ir",
    exemplos: [
      {
        kanji: "赴任",
        furigana: "ふにん",
        romaji: "funin",
        traducao: "ir a um novo posto",
      },
      {
        kanji: "赴く",
        furigana: "おもむく",
        romaji: "omomuku",
        traducao: "dirigir-se; ir",
      },
      {
        kanji: "赴",
        furigana: "フ",
        romaji: "fu",
        traducao: "dirigir-se; ir",
      },
    ],
  },
  {
    kanji: "桑",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "くわ", romaji: "kuwa" }],
    significado: "amoreira",
    exemplos: [
      {
        kanji: "桑原",
        furigana: "くわばら",
        romaji: "kuwabara",
        traducao: "campo de amoreiras",
      },
      {
        kanji: "桑",
        furigana: "ソウ",
        romaji: "sou",
        traducao: "amoreira",
      },
    ],
  },
  {
    kanji: "桂",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "かつら", romaji: "katsura" }],
    significado: "canela; árvore de canela",
    exemplos: [
      {
        kanji: "桂",
        furigana: "ケイ",
        romaji: "kei",
        traducao: "canela; árvore de canela",
      },
      {
        kanji: "桂枝",
        furigana: "けいし",
        romaji: "keishi",
        traducao: "galho de canela",
      },
    ],
  },
  {
    kanji: "髄",
    onYomi: [{ katakana: "ズイ", romaji: "zui" }],
    kunYomi: [],
    significado: "medula",
    exemplos: [
      {
        kanji: "脊髄",
        furigana: "せきずい",
        romaji: "sekizui",
        traducao: "medula espinhal",
      },
      {
        kanji: "髄膜",
        furigana: "ずいまく",
        romaji: "zuimaku",
        traducao: "meninges",
      },
      {
        kanji: "髄",
        furigana: "ズイ",
        romaji: "zui",
        traducao: "medula",
      },
    ],
  },
  {
    kanji: "盆",
    onYomi: [{ katakana: "ボン", romaji: "bon" }],
    kunYomi: [],
    significado: "travessa; bandeja",
    exemplos: [
      {
        kanji: "盆踊り",
        furigana: "ぼんおどり",
        romaji: "bonodori",
        traducao: "dança bon",
      },
      {
        kanji: "盆",
        furigana: "ボン",
        romaji: "bon",
        traducao: "travessa; bandeja",
      },
    ],
  },
  {
    kanji: "奉",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "たてまつ.る", romaji: "tatematsu.ru" }],
    significado: "oferecer; dedicar",
    exemplos: [
      {
        kanji: "奉仕",
        furigana: "ほうし",
        romaji: "houshi",
        traducao: "serviço; voluntariado",
      },
      {
        kanji: "奉納",
        furigana: "ほうのう",
        romaji: "hounou",
        traducao: "oferta; dedicação",
      },
      {
        kanji: "奉",
        furigana: "ホウ",
        romaji: "hou",
        traducao: "oferecer; dedicar",
      },
    ],
  },
  {
    kanji: "憂",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [{ hiragana: "うれ.える", romaji: "ure.eru" }],
    significado: "preocupação; melancolia",
    exemplos: [
      {
        kanji: "憂鬱",
        furigana: "ゆううつ",
        romaji: "yuuutsu",
        traducao: "depressão",
      },
      {
        kanji: "憂慮",
        furigana: "ゆうりょ",
        romaji: "yuuryo",
        traducao: "preocupação",
      },
      {
        kanji: "憂",
        furigana: "ユウ",
        romaji: "yuu",
        traducao: "preocupação; melancolia",
      },
    ],
  },
  {
    kanji: "晋",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "avançar; progredir",
    exemplos: [
      {
        kanji: "晋進",
        furigana: "しんしん",
        romaji: "shinshin",
        traducao: "avançar; progredir",
      },
      {
        kanji: "晋",
        furigana: "シン",
        romaji: "shin",
        traducao: "avançar; progredir",
      },
    ],
  },
  {
    kanji: "穂",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "ほ", romaji: "ho" }],
    significado: "espiga; fruto; cacho",
    exemplos: [
      {
        kanji: "稲穂",
        furigana: "いなほ",
        romaji: "inaho",
        traducao: "espigas de arroz",
      },
      {
        kanji: "麦穂",
        furigana: "むぎほ",
        romaji: "mugibo",
        traducao: "espigas de trigo",
      },
      {
        kanji: "穂",
        furigana: "スイ",
        romaji: "sui",
        traducao: "espiga; fruto; cacho",
      },
    ],
  },
  {
    kanji: "壮",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [],
    significado: "robusto; magnífico",
    exemplos: [
      {
        kanji: "壮大",
        furigana: "そうだい",
        romaji: "soudai",
        traducao: "magnífico; grandioso",
      },
      {
        kanji: "壮健",
        furigana: "そうけん",
        romaji: "souken",
        traducao: "robusto; saudável",
      },
      {
        kanji: "壮",
        furigana: "ソウ",
        romaji: "sou",
        traducao: "robusto; magnífico",
      },
    ],
  },
  {
    kanji: "堤",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "つつみ", romaji: "tsutsumi" }],
    significado: "dique; barragem",
    exemplos: [
      {
        kanji: "堤防",
        furigana: "ていぼう",
        romaji: "teibou",
        traducao: "dique; barragem",
      },
      {
        kanji: "水門堤",
        furigana: "すいもんつつみ",
        romaji: "suimontsutsumi",
        traducao: "dique com comportas",
      },
      {
        kanji: "堤",
        furigana: "テイ",
        romaji: "tei",
        traducao: "dique; barragem",
      },
    ],
  },
  {
    kanji: "飢",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "う.える", romaji: "u.eru" }],
    significado: "fome",
    exemplos: [
      {
        kanji: "飢餓",
        furigana: "きが",
        romaji: "kiga",
        traducao: "fome extrema",
      },
      {
        kanji: "飢える",
        furigana: "うえる",
        romaji: "ueru",
        traducao: "ter fome",
      },
      {
        kanji: "飢",
        furigana: "キ",
        romaji: "ki",
        traducao: "fome",
      },
    ],
  },
  {
    kanji: "傍",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "かたわ.ら", romaji: "katawa.ra" }],
    significado: "lado; próximo",
    exemplos: [
      {
        kanji: "傍聴",
        furigana: "ぼうちょう",
        romaji: "bouchou",
        traducao: "audiência; observação",
      },
      {
        kanji: "傍",
        furigana: "ボウ",
        romaji: "bou",
        traducao: "lado; próximo",
      },
    ],
  },
  {
    kanji: "疫",
    onYomi: [{ katakana: "エキ", romaji: "eki" }],
    kunYomi: [],
    significado: "epidemia; peste",
    exemplos: [
      {
        kanji: "疫病",
        furigana: "えきびょう",
        romaji: "ekibyou",
        traducao: "doença epidêmica",
      },
      {
        kanji: "疫",
        furigana: "エキ",
        romaji: "eki",
        traducao: "epidemia; peste",
      },
    ],
  },
  {
    kanji: "累",
    onYomi: [{ katakana: "ルイ", romaji: "rui" }],
    kunYomi: [],
    significado: "acumular; complicado",
    exemplos: [
      {
        kanji: "累積",
        furigana: "るいせき",
        romaji: "ruiseki",
        traducao: "acumulação",
      },
      {
        kanji: "累計",
        furigana: "るいけい",
        romaji: "ruikei",
        traducao: "total; acumulado",
      },
      {
        kanji: "累",
        furigana: "ルイ",
        romaji: "rui",
        traducao: "acumular; complicado",
      },
    ],
  },
  {
    kanji: "痴",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [{ hiragana: "し.れる", romaji: "shi.reru" }],
    significado: "tolo; estúpido",
    exemplos: [
      {
        kanji: "痴呆",
        furigana: "ちほう",
        romaji: "chihou",
        traducao: "demência",
      },
      {
        kanji: "痴情",
        furigana: "ちじょう",
        romaji: "chijou",
        traducao: "sentimento tolo",
      },
      {
        kanji: "痴",
        furigana: "チ",
        romaji: "chi",
        traducao: "tolo; estúpido",
      },
    ],
  },
  {
    kanji: "搬",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [],
    significado: "transportar",
    exemplos: [
      {
        kanji: "搬入",
        furigana: "はんにゅう",
        romaji: "hannyuu",
        traducao: "entrada; transporte",
      },
      {
        kanji: "搬送",
        furigana: "はんそう",
        romaji: "hansou",
        traducao: "transporte",
      },
      {
        kanji: "搬",
        furigana: "ハン",
        romaji: "han",
        traducao: "transportar",
      },
    ],
  },
  {
    kanji: "晃",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "claro; brilhante",
    exemplos: [
      {
        kanji: "明晃",
        furigana: "あきらか",
        romaji: "akiraka",
        traducao: "claro; evidente",
      },
      {
        kanji: "晃",
        furigana: "コウ",
        romaji: "kou",
        traducao: "claro; brilhante",
      },
    ],
  },
  {
    kanji: "癒",
    onYomi: [{ katakana: "ユ", romaji: "yu" }],
    kunYomi: [{ hiragana: "い.える", romaji: "i.eru" }],
    significado: "curar; aliviar",
    exemplos: [
      {
        kanji: "癒着",
        furigana: "ゆちゃく",
        romaji: "yuchaku",
        traducao: "aderência; cicatrização",
      },
      {
        kanji: "癒える",
        furigana: "いえる",
        romaji: "ieru",
        traducao: "curar; cicatrizar",
      },
      {
        kanji: "癒",
        furigana: "ユ",
        romaji: "yu",
        traducao: "curar; aliviar",
      },
    ],
  },
  {
    kanji: "桐",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [],
    significado: "árvore paulownia",
    exemplos: [
      {
        kanji: "桐油",
        furigana: "とうゆ",
        romaji: "touyu",
        traducao: "óleo de paulownia",
      },
      {
        kanji: "桐",
        furigana: "トウ",
        romaji: "tou",
        traducao: "árvore paulownia",
      },
    ],
  },
  {
    kanji: "寸",
    onYomi: [{ katakana: "スン", romaji: "sun" }],
    kunYomi: [],
    significado: "medida",
    exemplos: [
      {
        kanji: "寸法",
        furigana: "すんぽう",
        romaji: "sunpou",
        traducao: "medida",
      },
      {
        kanji: "寸",
        furigana: "スン",
        romaji: "sun",
        traducao: "medida",
      },
    ],
  },
  {
    kanji: "郭",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [],
    significado: "muralha; bairro",
    exemplos: [
      {
        kanji: "城郭",
        furigana: "じょうかく",
        romaji: "joukaku",
        traducao: "castelo e muralhas",
      },
      {
        kanji: "内郭",
        furigana: "ないかく",
        romaji: "naikaku",
        traducao: "muralha interna",
      },
      {
        kanji: "郭",
        furigana: "カク",
        romaji: "kaku",
        traducao: "muralha; bairro",
      },
    ],
  },
  {
    kanji: "尿",
    onYomi: [{ katakana: "ニョウ", romaji: "nyou" }],
    kunYomi: [],
    significado: "urina",
    exemplos: [
      {
        kanji: "尿道",
        furigana: "にょうどう",
        romaji: "nyoudou",
        traducao: "uretra",
      },
      {
        kanji: "尿",
        furigana: "ニョウ",
        romaji: "nyou",
        traducao: "urina",
      },
    ],
  },
  {
    kanji: "凶",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [],
    significado: "mau; infortúnio",
    exemplos: [
      {
        kanji: "凶器",
        furigana: "きょうき",
        romaji: "kyouki",
        traducao: "arma; instrumento perigoso",
      },
      {
        kanji: "凶悪",
        furigana: "きょうあく",
        romaji: "kyouaku",
        traducao: "malvado; cruel",
      },
      {
        kanji: "凶",
        furigana: "キョウ",
        romaji: "kyou",
        traducao: "mau; infortúnio",
      },
    ],
  },
  {
    kanji: "吐",
    onYomi: [{ katakana: "ト", romaji: "to" }],
    kunYomi: [{ hiragana: "は.く", romaji: "ha.ku" }],
    significado: "vomitar; cuspir",
    exemplos: [
      {
        kanji: "吐血",
        furigana: "とけつ",
        romaji: "toke.tsu",
        traducao: "cuspir sangue",
      },
      {
        kanji: "嘔吐",
        furigana: "おうと",
        romaji: "ou.to",
        traducao: "vômito",
      },
      {
        kanji: "吐",
        furigana: "ト",
        romaji: "to",
        traducao: "vomitar; cuspir",
      },
    ],
  },
  {
    kanji: "宴",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "うたげ", romaji: "uta.ge" }],
    significado: "banquete; festa",
    exemplos: [
      {
        kanji: "宴会",
        furigana: "えんかい",
        romaji: "enkai",
        traducao: "banquete; festa",
      },
      {
        kanji: "宴",
        furigana: "エン",
        romaji: "en",
        traducao: "banquete; festa",
      },
    ],
  },
  {
    kanji: "鷹",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [],
    significado: "falcão",
    exemplos: [
      {
        kanji: "鷹狩り",
        furigana: "たかがり",
        romaji: "taka.gari",
        traducao: "caça com falcão",
      },
      {
        kanji: "隼鷹",
        furigana: "はやたか",
        romaji: "haya.taka",
        traducao: "peregrino (espécie de falcão)",
      },
      {
        kanji: "鷹",
        furigana: "ヨウ",
        romaji: "you",
        traducao: "falcão",
      },
    ],
  },
  {
    kanji: "賓",
    onYomi: [{ katakana: "ヒン", romaji: "hin" }],
    kunYomi: [],
    significado: "convidado; hóspede",
    exemplos: [
      {
        kanji: "賓客",
        furigana: "ひんきゃく",
        romaji: "hin.kyaku",
        traducao: "convidados; hóspedes",
      },
      {
        kanji: "賓",
        furigana: "ヒン",
        romaji: "hin",
        traducao: "convidado; hóspede",
      },
    ],
  },
  {
    kanji: "虜",
    onYomi: [{ katakana: "リョ", romaji: "ryo" }],
    kunYomi: [{ hiragana: "とりこ", romaji: "tori.ko" }],
    significado: "prisioneiro; cativo",
    exemplos: [
      {
        kanji: "虜囚",
        furigana: "りょしゅう",
        romaji: "ryo.shuu",
        traducao: "prisioneiro",
      },
      {
        kanji: "捕虜",
        furigana: "ほりょ",
        romaji: "ho.ryo",
        traducao: "prisioneiro de guerra",
      },
      {
        kanji: "虜",
        furigana: "リョ",
        romaji: "ryo",
        traducao: "prisioneiro; cativo",
      },
    ],
  },
  {
    kanji: "陶",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [],
    significado: "cerâmica",
    exemplos: [
      {
        kanji: "陶器",
        furigana: "とうき",
        romaji: "touki",
        traducao: "cerâmica; vasos",
      },
      {
        kanji: "陶酔",
        furigana: "とうすい",
        romaji: "tousui",
        traducao: "êxtase; embriaguez",
      },
      {
        kanji: "陶",
        furigana: "トウ",
        romaji: "tou",
        traducao: "cerâmica",
      },
    ],
  },
  {
    kanji: "鐘",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "かね", romaji: "ka.ne" }],
    significado: "sino",
    exemplos: [
      {
        kanji: "時鐘",
        furigana: "ときがね",
        romaji: "toki.ga.ne",
        traducao: "sino do tempo",
      },
      {
        kanji: "鐘楼",
        furigana: "しょうろう",
        romaji: "shou.rou",
        traducao: "torre do sino",
      },
      {
        kanji: "鐘",
        furigana: "ショウ",
        romaji: "shou",
        traducao: "sino",
      },
    ],
  },
  {
    kanji: "憾",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "lamentável; pesaroso",
    exemplos: [
      {
        kanji: "遺憾",
        furigana: "いかん",
        romaji: "i.kan",
        traducao: "lamentável; deplorável",
      },
      {
        kanji: "憾",
        furigana: "カン",
        romaji: "kan",
        traducao: "lamentável; pesaroso",
      },
    ],
  },
  {
    kanji: "猪",
    onYomi: [{ katakana: "チョ", romaji: "cho" }],
    kunYomi: [{ hiragana: "いのしし", romaji: "i.no.shi.shi" }],
    significado: "javali",
    exemplos: [
      {
        kanji: "猪突猛進",
        furigana: "ちょとつもうしん",
        romaji: "cho.totsu.mou.shin",
        traducao: "avançar ferozmente",
      },
      {
        kanji: "野猪",
        furigana: "やいのしし",
        romaji: "ya.i.no.shi.shi",
        traducao: "javali",
      },
      {
        kanji: "猪",
        furigana: "チョ",
        romaji: "cho",
        traducao: "javali",
      },
    ],
  },
  {
    kanji: "紘",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "fio; linha",
    exemplos: [
      {
        kanji: "紘縮",
        furigana: "こうしゅく",
        romaji: "kou.shuku",
        traducao: "encolhimento",
      },
      {
        kanji: "紘",
        furigana: "コウ",
        romaji: "kou",
        traducao: "fio; linha",
      },
    ],
  },
  {
    kanji: "磁",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [],
    significado: "ímã; magnetismo",
    exemplos: [
      {
        kanji: "磁石",
        furigana: "じしゃく",
        romaji: "ji.shaku",
        traducao: "ímã",
      },
      {
        kanji: "磁気",
        furigana: "じき",
        romaji: "ji.ki",
        traducao: "magnetismo",
      },
      {
        kanji: "磁",
        furigana: "ジ",
        romaji: "ji",
        traducao: "ímã; magnetismo",
      },
    ],
  },
  {
    kanji: "弥",
    onYomi: [{ katakana: "ヤ", romaji: "ya" }],
    kunYomi: [],
    significado: "mais e mais",
    exemplos: [
      {
        kanji: "弥生",
        furigana: "やよい",
        romaji: "ya.yoi",
        traducao: "março (mês); primavera",
      },
      {
        kanji: "弥",
        furigana: "ヤ",
        romaji: "ya",
        traducao: "mais e mais",
      },
    ],
  },
  {
    kanji: "昆",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [],
    significado: "ancestrais; grupo",
    exemplos: [
      {
        kanji: "昆虫",
        furigana: "こんちゅう",
        romaji: "kon.chuu",
        traducao: "inseto",
      },
      {
        kanji: "昆布",
        furigana: "こんぶ",
        romaji: "kon.bu",
        traducao: "kombu (alga marinha)",
      },
      {
        kanji: "昆",
        furigana: "コン",
        romaji: "kon",
        traducao: "ancestrais; grupo",
      },
    ],
  },
  {
    kanji: "粗",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [{ hiragana: "あら.い", romaji: "ara.i" }],
    significado: "grosseiro; áspero",
    exemplos: [
      {
        kanji: "粗筋",
        furigana: "あらすじ",
        romaji: "ara.suji",
        traducao: "esboço; resumo",
      },
      {
        kanji: "粗野",
        furigana: "そや",
        romaji: "so.ya",
        traducao: "grosseiro; rústico",
      },
      {
        kanji: "粗",
        furigana: "ソ",
        romaji: "so",
        traducao: "grosseiro; áspero",
      },
    ],
  },
  {
    kanji: "訂",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "corrigir; revisar",
    exemplos: [
      {
        kanji: "訂正",
        furigana: "ていせい",
        romaji: "tei.sei",
        traducao: "correção; retificação",
      },
      {
        kanji: "訂正箋",
        furigana: "ていせいせん",
        romaji: "tei.sei.sen",
        traducao: "folha de correção",
      },
      {
        kanji: "訂",
        furigana: "テイ",
        romaji: "tei",
        traducao: "corrigir; revisar",
      },
    ],
  },
  {
    kanji: "芽",
    onYomi: [{ katakana: "ガ", romaji: "ga" }],
    kunYomi: [{ hiragana: "め", romaji: "me" }],
    significado: "broto; germinar",
    exemplos: [
      {
        kanji: "芽生える",
        furigana: "めばえる",
        romaji: "me.bae.ru",
        traducao: "germinar; brotar",
      },
      {
        kanji: "新芽",
        furigana: "しんめ",
        romaji: "shin.me",
        traducao: "broto novo",
      },
      {
        kanji: "芽",
        furigana: "ガ",
        romaji: "ga",
        traducao: "broto; germinar",
      },
    ],
  },
  {
    kanji: "庄",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "vilarejo; aldeia",
    exemplos: [
      {
        kanji: "庄屋",
        furigana: "しょうや",
        romaji: "shou.ya",
        traducao: "chefe do vilarejo",
      },
      {
        kanji: "庄",
        furigana: "ショウ",
        romaji: "shou",
        traducao: "vilarejo; aldeia",
      },
    ],
  },
  {
    kanji: "傘",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [{ hiragana: "かさ", romaji: "ka.sa" }],
    significado: "guarda-chuva",
    exemplos: [
      {
        kanji: "傘下",
        furigana: "さんか",
        romaji: "san.ka",
        traducao: "sob a proteção",
      },
      {
        kanji: "傘",
        furigana: "サン",
        romaji: "san",
        traducao: "guarda-chuva",
      },
    ],
  },
  {
    kanji: "敦",
    onYomi: [{ katakana: "トン", romaji: "ton" }],
    kunYomi: [],
    significado: "sincero; honesto",
    exemplos: [
      {
        kanji: "敦賀",
        furigana: "つるが",
        romaji: "tsu.ru.ga",
        traducao: "Tsuruga (cidade no Japão)",
      },
      {
        kanji: "敦",
        furigana: "トン",
        romaji: "ton",
        traducao: "sincero; honesto",
      },
    ],
  },
  {
    kanji: "騎",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "cavaleiro; montar",
    exemplos: [
      {
        kanji: "騎士",
        furigana: "きし",
        romaji: "ki.shi",
        traducao: "cavaleiro",
      },
      {
        kanji: "騎乗",
        furigana: "きじょう",
        romaji: "ki.jou",
        traducao: "montar",
      },
      {
        kanji: "騎",
        furigana: "キ",
        romaji: "ki",
        traducao: "cavaleiro; montar",
      },
    ],
  },
  {
    kanji: "寧",
    onYomi: [{ katakana: "ネイ", romaji: "nei" }],
    kunYomi: [],
    significado: "preferência; tranquilidade",
    exemplos: [
      {
        kanji: "寧可",
        furigana: "ねいか",
        romaji: "nei.ka",
        traducao: "preferencialmente",
      },
      {
        kanji: "寧波",
        furigana: "ねいは",
        romaji: "nei.ha",
        traducao: "Ningbo (cidade na China)",
      },
      {
        kanji: "寧",
        furigana: "ネイ",
        romaji: "nei",
        traducao: "preferência; tranquilidade",
      },
    ],
  },
  {
    kanji: "循",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [],
    significado: "circular; seguir",
    exemplos: [
      {
        kanji: "循環",
        furigana: "じゅんかん",
        romaji: "jun.kan",
        traducao: "circulação; ciclo",
      },
      {
        kanji: "循環器",
        furigana: "じゅんかんき",
        romaji: "jun.kan.ki",
        traducao: "aparelho circulatório",
      },
      {
        kanji: "循",
        furigana: "ジュン",
        romaji: "jun",
        traducao: "circular; seguir",
      },
    ],
  },
  {
    kanji: "忍",
    onYomi: [{ katakana: "ニン", romaji: "nin" }],
    kunYomi: [],
    significado: "perseverança; paciência",
    exemplos: [
      {
        kanji: "忍耐",
        furigana: "にんたい",
        romaji: "nin.tai",
        traducao: "perseverança; paciência",
      },
      {
        kanji: "忍者",
        furigana: "にんじゃ",
        romaji: "nin.ja",
        traducao: "ninja",
      },
      {
        kanji: "忍",
        furigana: "ニン",
        romaji: "nin",
        traducao: "perseverança; paciência",
      },
    ],
  },
  {
    kanji: "怠",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "おこた.る", romaji: "oko.ta.ru" }],
    significado: "negligência; preguiça",
    exemplos: [
      {
        kanji: "怠慢",
        furigana: "たいまん",
        romaji: "tai.man",
        traducao: "negligência; descuido",
      },
      {
        kanji: "怠け者",
        furigana: "なまけもの",
        romaji: "nama.ke.mo.no",
        traducao: "preguiçoso",
      },
      {
        kanji: "怠",
        furigana: "タイ",
        romaji: "tai",
        traducao: "negligência; preguiça",
      },
    ],
  },
  {
    kanji: "如",
    onYomi: [{ katakana: "ジョ", romaji: "jo" }],
    kunYomi: [{ hiragana: "ごと.し", romaji: "goto.shi" }],
    significado: "semelhante; como",
    exemplos: [
      {
        kanji: "如実",
        furigana: "じょじつ",
        romaji: "jo.jitsu",
        traducao: "realidade; verdade",
      },
      {
        kanji: "如何",
        furigana: "いかん",
        romaji: "i.kan",
        traducao: "como; de que forma",
      },
      {
        kanji: "如",
        furigana: "ジョ",
        romaji: "jo",
        traducao: "semelhante; como",
      },
    ],
  },
  {
    kanji: "寮",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [],
    significado: "dormitório; alojamento",
    exemplos: [
      {
        kanji: "学寮",
        furigana: "がくりょう",
        romaji: "gaku.ryou",
        traducao: "dormitório estudantil",
      },
      {
        kanji: "寮母",
        furigana: "りょうぼ",
        romaji: "ryou.bo",
        traducao: "responsável pelo dormitório",
      },
      {
        kanji: "寮",
        furigana: "リョウ",
        romaji: "ryou",
        traducao: "dormitório; alojamento",
      },
    ],
  },
  {
    kanji: "祐",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [],
    significado: "ajuda; auxílio",
    exemplos: [
      {
        kanji: "救援",
        furigana: "きゅうえん",
        romaji: "kyuu.en",
        traducao: "socorro; ajuda",
      },
      {
        kanji: "祐介",
        furigana: "ゆうすけ",
        romaji: "yuu.suke",
        traducao: "Yusuke (nome próprio)",
      },
      {
        kanji: "祐",
        furigana: "ユウ",
        romaji: "yuu",
        traducao: "ajuda; auxílio",
      },
    ],
  },
  {
    kanji: "鵬",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [],
    significado: "fênix",
    exemplos: [
      {
        kanji: "大鵬",
        furigana: "たいほう",
        romaji: "tai.hou",
        traducao: "Ōhō (nome de um lutador de sumô)",
      },
      {
        kanji: "鵬",
        furigana: "ホウ",
        romaji: "hou",
        traducao: "fênix",
      },
    ],
  },
  {
    kanji: "鉛",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [],
    significado: "chumbo",
    exemplos: [
      {
        kanji: "鉛筆",
        furigana: "えんぴつ",
        romaji: "en.pitsu",
        traducao: "lápis",
      },
      {
        kanji: "鉛色",
        furigana: "なまりいろ",
        romaji: "namari.iro",
        traducao: "cinza chumbo",
      },
      {
        kanji: "鉛",
        furigana: "エン",
        romaji: "en",
        traducao: "chumbo",
      },
    ],
  },
  {
    kanji: "珠",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [],
    significado: "pérola; esfera",
    exemplos: [
      {
        kanji: "真珠",
        furigana: "しんじゅ",
        romaji: "shin.ju",
        traducao: "pérola",
      },
      {
        kanji: "火珠",
        furigana: "かじゅ",
        romaji: "ka.ju",
        traducao: "brasa",
      },
      {
        kanji: "珠",
        furigana: "シュ",
        romaji: "shu",
        traducao: "pérola; esfera",
      },
    ],
  },
  {
    kanji: "凝",
    onYomi: [{ katakana: "ギョウ", romaji: "gyou" }],
    kunYomi: [{ hiragana: "こ.る", romaji: "ko.ru" }],
    significado: "congelar; solidificar",
    exemplos: [
      {
        kanji: "凝固",
        furigana: "ぎょうこ",
        romaji: "gyou.ko",
        traducao: "coagulação; solidificação",
      },
      {
        kanji: "凝視",
        furigana: "ぎょうし",
        romaji: "gyou.shi",
        traducao: "olhar fixamente",
      },
      {
        kanji: "凝",
        furigana: "ギョウ",
        romaji: "gyou",
        traducao: "congelar; solidificar",
      },
    ],
  },
  {
    kanji: "苗",
    onYomi: [{ katakana: "ビョウ", romaji: "byou" }],
    kunYomi: [{ hiragana: "なえ", romaji: "nae" }],
    significado: "muda; broto",
    exemplos: [
      {
        kanji: "苗床",
        furigana: "なえどこ",
        romaji: "nae.doko",
        traducao: "canteiro; berçário",
      },
      {
        kanji: "苗木",
        furigana: "なえぎ",
        romaji: "nae.gi",
        traducao: "muda; plântula",
      },
      {
        kanji: "苗",
        furigana: "ビョウ",
        romaji: "byou",
        traducao: "muda; broto",
      },
    ],
  },
  {
    kanji: "獣",
    onYomi: [{ katakana: "ジュウ", romaji: "juu" }],
    kunYomi: [{ hiragana: "けもの", romaji: "kemono" }],
    significado: "animal; besta",
    exemplos: [
      {
        kanji: "獣医",
        furigana: "じゅうい",
        romaji: "juu.i",
        traducao: "veterinário",
      },
      {
        kanji: "動物獣",
        furigana: "どうぶつけもの",
        romaji: "dou.butsu.ke.mo.no",
        traducao: "animal selvagem",
      },
      {
        kanji: "獣",
        furigana: "ジュウ",
        romaji: "juu",
        traducao: "animal; besta",
      },
    ],
  },
  {
    kanji: "哀",
    onYomi: [{ katakana: "アイ", romaji: "ai" }],
    kunYomi: [{ hiragana: "あわ.れむ", romaji: "awa.re.mu" }],
    significado: "tristeza; lamento",
    exemplos: [
      {
        kanji: "哀悼",
        furigana: "あいとう",
        romaji: "ai.tou",
        traducao: "luto; pesar",
      },
      {
        kanji: "哀れ",
        furigana: "あわれ",
        romaji: "aware",
        traducao: "triste; lamentável",
      },
      {
        kanji: "哀",
        furigana: "アイ",
        romaji: "ai",
        traducao: "tristeza; lamento",
      },
    ],
  },
  {
    kanji: "跳",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "は.ねる", romaji: "ha.ne.ru" }],
    significado: "saltar; pular",
    exemplos: [
      {
        kanji: "跳躍",
        furigana: "ちょうやく",
        romaji: "chou.yaku",
        traducao: "salto; pulo",
      },
      {
        kanji: "跳ねる",
        furigana: "はねる",
        romaji: "ha.ne.ru",
        traducao: "saltar; pular",
      },
      {
        kanji: "跳",
        furigana: "チョウ",
        romaji: "chou",
        traducao: "saltar; pular",
      },
    ],
  },
  {
    kanji: "匠",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "artesão; mestre",
    exemplos: [
      {
        kanji: "職人匠",
        furigana: "しょくにんしょう",
        romaji: "shoku.nin.shou",
        traducao: "artesão qualificado",
      },
      {
        kanji: "匠",
        furigana: "ショウ",
        romaji: "shou",
        traducao: "artesão; mestre",
      },
    ],
  },
  {
    kanji: "垂",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "た.れる", romaji: "ta.re.ru" }],
    significado: "pendurar; cair",
    exemplos: [
      {
        kanji: "垂直",
        furigana: "すいちょく",
        romaji: "sui.choku",
        traducao: "vertical",
      },
      {
        kanji: "垂れる",
        furigana: "たれる",
        romaji: "ta.re.ru",
        traducao: "pendurar; cair",
      },
      {
        kanji: "垂",
        furigana: "スイ",
        romaji: "sui",
        traducao: "pendurar; cair",
      },
    ],
  },
  {
    kanji: "蛇",
    onYomi: [{ katakana: "ジャ", romaji: "ja" }],
    kunYomi: [{ hiragana: "へび", romaji: "hebi" }],
    significado: "cobra; serpente",
    exemplos: [
      {
        kanji: "蛇口",
        furigana: "じゃぐち",
        romaji: "ja.gu.chi",
        traducao: "torneira",
      },
      {
        kanji: "蛇",
        furigana: "ジャ",
        romaji: "ja",
        traducao: "cobra; serpente",
      },
    ],
  },
  {
    kanji: "澄",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "す.む", romaji: "su.mu" }],
    significado: "clareza; purificar",
    exemplos: [
      {
        kanji: "澄む",
        furigana: "すむ",
        romaji: "su.mu",
        traducao: "clarear; ficar transparente",
      },
      {
        kanji: "澄みきる",
        furigana: "すみきる",
        romaji: "sumi.ki.ru",
        traducao: "estar limpo e claro",
      },
      {
        kanji: "澄",
        furigana: "チョウ",
        romaji: "chou",
        traducao: "clareza; purificar",
      },
    ],
  },
  {
    kanji: "縫",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "ぬ.う", romaji: "nu.u" }],
    significado: "costurar",
    exemplos: [
      {
        kanji: "縫製",
        furigana: "ほうせい",
        romaji: "hou.sei",
        traducao: "costura; confecção",
      },
      {
        kanji: "縫う",
        furigana: "ぬう",
        romaji: "nu.u",
        traducao: "costurar",
      },
      {
        kanji: "縫",
        furigana: "ホウ",
        romaji: "hou",
        traducao: "costurar",
      },
    ],
  },
  {
    kanji: "僧",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [],
    significado: "monge; sacerdote",
    exemplos: [
      {
        kanji: "僧侶",
        furigana: "そうりょ",
        romaji: "sou.ryou",
        traducao: "monge; sacerdote",
      },
      {
        kanji: "仏僧",
        furigana: "ぶっそう",
        romaji: "butsu.sou",
        traducao: "monge budista",
      },
      {
        kanji: "僧",
        furigana: "ソウ",
        romaji: "sou",
        traducao: "monge; sacerdote",
      },
    ],
  },
  {
    kanji: "眺",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "なが.める", romaji: "naga.me.ru" }],
    significado: "observar; contemplar",
    exemplos: [
      {
        kanji: "眺望",
        furigana: "ちょうぼう",
        romaji: "chou.bou",
        traducao: "vista; panorama",
      },
      {
        kanji: "眺める",
        furigana: "ながめる",
        romaji: "naga.me.ru",
        traducao: "observar; contemplar",
      },
      {
        kanji: "眺",
        furigana: "チョウ",
        romaji: "chou",
        traducao: "observar; contemplar",
      },
    ],
  },
  {
    kanji: "亘",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "わた.る", romaji: "wata.ru" }],
    significado: "atravessar; estender-se",
    exemplos: [
      {
        kanji: "全亘",
        furigana: "ぜんこう",
        romaji: "zen.kou",
        traducao: "completamente; por completo",
      },
      {
        kanji: "亘る",
        furigana: "わたる",
        romaji: "wata.ru",
        traducao: "atravessar; estender-se",
      },
      {
        kanji: "亘",
        furigana: "コウ",
        romaji: "kou",
        traducao: "atravessar; estender-se",
      },
    ],
  },
  {
    kanji: "呉",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [{ hiragana: "く.れる", romaji: "ku.reru" }],
    significado: "dar; presentear",
    exemplos: [
      {
        kanji: "感謝の意を呉る",
        furigana: "かんしゃのいをく.る",
        romaji: "kan.sha.no.i.o.ku.ru",
        traducao: "expressar gratidão",
      },
      {
        kanji: "呉る",
        furigana: "くれる",
        romaji: "ku.reru",
        traducao: "dar; presentear",
      },
      {
        kanji: "呉",
        furigana: "ゴ",
        romaji: "go",
        traducao: "dar; presentear",
      },
    ],
  },
  {
    kanji: "凡",
    onYomi: [{ katakana: "ボン", romaji: "bon" }],
    kunYomi: [{ hiragana: "およ.そ", romaji: "oyo.so" }],
    significado: "comum; ordinário",
    exemplos: [
      {
        kanji: "凡例",
        furigana: "ぼんれい",
        romaji: "bon.rei",
        traducao: "legenda",
      },
      {
        kanji: "凡庸",
        furigana: "ぼんよう",
        romaji: "bon.you",
        traducao: "mediocre; comum",
      },
      {
        kanji: "凡",
        furigana: "ボン",
        romaji: "bon",
        traducao: "comum; ordinário",
      },
    ],
  },
  {
    kanji: "憩",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "いこ.い", romaji: "iko.i" }],
    significado: "descanso; lazer",
    exemplos: [
      {
        kanji: "憩いの場",
        furigana: "いこいのば",
        romaji: "iko.i.no.ba",
        traducao: "local de descanso",
      },
      {
        kanji: "憩う",
        furigana: "いこう",
        romaji: "iko.u",
        traducao: "descansar; relaxar",
      },
      {
        kanji: "憩",
        furigana: "ケイ",
        romaji: "kei",
        traducao: "descanso; lazer",
      },
    ],
  },
  {
    kanji: "媛",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "ひめ", romaji: "hime" }],
    significado: "princesa; mulher",
    exemplos: [
      {
        kanji: "姫媛",
        furigana: "ひめひめ",
        romaji: "hime.hime",
        traducao: "princesa",
      },
      {
        kanji: "媛",
        furigana: "エン",
        romaji: "en",
        traducao: "princesa; mulher",
      },
    ],
  },
  {
    kanji: "溝",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "みぞ", romaji: "mizo" }],
    significado: "vala; trincheira",
    exemplos: [
      {
        kanji: "排水溝",
        furigana: "はいすいこう",
        romaji: "hai.sui.kou",
        traducao: "escoadouro",
      },
      {
        kanji: "溝",
        furigana: "コウ",
        romaji: "kou",
        traducao: "vala; trincheira",
      },
    ],
  },
  {
    kanji: "恭",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "うやうや.しい", romaji: "uyauya.shii" }],
    significado: "respeito; cortesia",
    exemplos: [
      {
        kanji: "恭敬",
        furigana: "きょうけい",
        romaji: "kyou.kei",
        traducao: "respeito; reverência",
      },
      {
        kanji: "恭しい",
        furigana: "うやうやしい",
        romaji: "uyauya.shii",
        traducao: "respeitoso; cortês",
      },
      {
        kanji: "恭",
        furigana: "キョウ",
        romaji: "kyou",
        traducao: "respeito; cortesia",
      },
    ],
  },
  {
    kanji: "刈",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "か.る", romaji: "ka.ru" }],
    significado: "cortar (plantas); colher",
    exemplos: [
      {
        kanji: "収穫刈り",
        furigana: "しゅうかくかり",
        romaji: "shuu.kaku.ka.ri",
        traducao: "colheita",
      },
      {
        kanji: "草刈り",
        furigana: "くさかり",
        romaji: "kusa.ka.ri",
        traducao: "corte de grama",
      },
      {
        kanji: "刈る",
        furigana: "かる",
        romaji: "ka.ru",
        traducao: "cortar (plantas); colher",
      },
    ],
  },
  {
    kanji: "睡",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "ねむ.る", romaji: "ne.mu.ru" }],
    significado: "sono; dormir",
    exemplos: [
      {
        kanji: "睡眠",
        furigana: "すいみん",
        romaji: "sui.min",
        traducao: "sono; repouso",
      },
      {
        kanji: "睡る",
        furigana: "ねむる",
        romaji: "ne.mu.ru",
        traducao: "dormir",
      },
      {
        kanji: "睡",
        furigana: "スイ",
        romaji: "sui",
        traducao: "sono; dormir",
      },
    ],
  },
  {
    kanji: "錯",
    onYomi: [{ katakana: "サク", romaji: "saku" }],
    kunYomi: [],
    significado: "erro; confusão",
    exemplos: [
      {
        kanji: "錯誤",
        furigana: "さくご",
        romaji: "saku.go",
        traducao: "erro; equívoco",
      },
      {
        kanji: "混錯",
        furigana: "こんさく",
        romaji: "kon.saku",
        traducao: "mistura; confusão",
      },
      {
        kanji: "錯",
        furigana: "サク",
        romaji: "saku",
        traducao: "erro; confusão",
      },
    ],
  },
  {
    kanji: "伯",
    onYomi: [{ katakana: "ハク", romaji: "haku" }],
    kunYomi: [],
    significado: "grau; título",
    exemplos: [
      {
        kanji: "伯父",
        furigana: "おじ",
        romaji: "oji",
        traducao: "tio (mais velho)",
      },
      {
        kanji: "伯",
        furigana: "ハク",
        romaji: "haku",
        traducao: "grau; título",
      },
    ],
  },
  {
    kanji: "笹",
    onYomi: [],
    kunYomi: [{ hiragana: "ささ", romaji: "sasa" }],
    significado: "bambu-anão",
    exemplos: [
      {
        kanji: "笹",
        furigana: "ささ",
        romaji: "sasa",
        traducao: "bambu-anão",
      },
    ],
  },
  {
    kanji: "穀",
    onYomi: [{ katakana: "コク", romaji: "koku" }],
    kunYomi: [],
    significado: "cereal; grão",
    exemplos: [
      {
        kanji: "穀物",
        furigana: "こくもつ",
        romaji: "koku.motsu",
        traducao: "cereal",
      },
      {
        kanji: "穀",
        furigana: "コク",
        romaji: "koku",
        traducao: "cereal; grão",
      },
    ],
  },
  {
    kanji: "陵",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [{ hiragana: "みささぎ", romaji: "misasagi" }],
    significado: "túmulo; monte",
    exemplos: [
      {
        kanji: "皇陵",
        furigana: "こうりょう",
        romaji: "kou.ryou",
        traducao: "túmulo imperial",
      },
      {
        kanji: "陵",
        furigana: "リョウ",
        romaji: "ryou",
        traducao: "túmulo; monte",
      },
    ],
  },
  {
    kanji: "霧",
    onYomi: [{ katakana: "ム", romaji: "mu" }],
    kunYomi: [{ hiragana: "きり", romaji: "kiri" }],
    significado: "neblina; névoa",
    exemplos: [
      {
        kanji: "霧",
        furigana: "きり",
        romaji: "kiri",
        traducao: "neblina; névoa",
      },
      {
        kanji: "霧雨",
        furigana: "きりさめ",
        romaji: "kiri.same",
        traducao: "chuva fina",
      },
    ],
  },
  {
    kanji: "魂",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [{ hiragana: "たましい", romaji: "tamashii" }],
    significado: "alma; espírito",
    exemplos: [
      {
        kanji: "魂",
        furigana: "たましい",
        romaji: "tamashii",
        traducao: "alma; espírito",
      },
      {
        kanji: "魂魄",
        furigana: "こんぱく",
        romaji: "konpaku",
        traducao: "alma e corpo",
      },
    ],
  },
  {
    kanji: "弊",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [],
    significado: "fraude; abuso",
    exemplos: [
      {
        kanji: "弊社",
        furigana: "へいしゃ",
        romaji: "hei.sha",
        traducao: "nossa empresa",
      },
      {
        kanji: "弊害",
        furigana: "へいがい",
        romaji: "hei.gai",
        traducao: "danos; malefícios",
      },
    ],
  },
  {
    kanji: "妃",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [],
    significado: "rainha; consorte",
    exemplos: [
      {
        kanji: "皇妃",
        furigana: "こうひ",
        romaji: "kou.hi",
        traducao: "imperatriz",
      },
      {
        kanji: "妃",
        furigana: "ヒ",
        romaji: "hi",
        traducao: "rainha; consorte",
      },
    ],
  },
  {
    kanji: "舶",
    onYomi: [{ katakana: "ハク", romaji: "haku" }],
    kunYomi: [],
    significado: "navio; embarcação",
    exemplos: [
      {
        kanji: "舶来",
        furigana: "はくらい",
        romaji: "haku.rai",
        traducao: "importado; estrangeiro",
      },
      {
        kanji: "舶",
        furigana: "ハク",
        romaji: "haku",
        traducao: "navio; embarcação",
      },
    ],
  },
  {
    kanji: "餓",
    onYomi: [{ katakana: "ガ", romaji: "ga" }],
    kunYomi: [],
    significado: "fome; faminto",
    exemplos: [
      {
        kanji: "餓死",
        furigana: "がし",
        romaji: "ga.shi",
        traducao: "morte por fome",
      },
      {
        kanji: "餓",
        furigana: "ガ",
        romaji: "ga",
        traducao: "fome; faminto",
      },
    ],
  },
  {
    kanji: "窮",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "きわ.める", romaji: "kiwa.meru" }],
    significado: "pobreza; miséria",
    exemplos: [
      {
        kanji: "窮地",
        furigana: "きゅうち",
        romaji: "kyuu.chi",
        traducao: "situação difícil",
      },
      {
        kanji: "窮",
        furigana: "キュウ",
        romaji: "kyuu",
        traducao: "pobreza; miséria",
      },
    ],
  },
  {
    kanji: "掌",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "てのひら", romaji: "tenohira" }],
    significado: "palma da mão",
    exemplos: [
      {
        kanji: "手の掌",
        furigana: "てのてのひら",
        romaji: "te.no.te.no.hira",
        traducao: "palma da mão",
      },
      {
        kanji: "掌握",
        furigana: "しょうあく",
        romaji: "shou.aku",
        traducao: "apreensão; domínio",
      },
    ],
  },
  {
    kanji: "麗",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [{ hiragana: "うるわ.しい", romaji: "uruwa.shii" }],
    significado: "lindo; bonito",
    exemplos: [
      {
        kanji: "美麗",
        furigana: "びれい",
        romaji: "bi.rei",
        traducao: "beleza",
      },
      {
        kanji: "麗しい",
        furigana: "うるわしい",
        romaji: "uruwa.shii",
        traducao: "encantador; bonito",
      },
    ],
  },
  {
    kanji: "綾",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [{ hiragana: "あや", romaji: "aya" }],
    significado: "padrão de seda",
    exemplos: [
      {
        kanji: "綾模様",
        furigana: "あやもよう",
        romaji: "aya.moyou",
        traducao: "padrão de seda",
      },
      {
        kanji: "綾",
        furigana: "リン",
        romaji: "rin",
        traducao: "padrão de seda",
      },
    ],
  },
  {
    kanji: "臭",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [{ hiragana: "くさ.い", romaji: "kusa.i" }],
    significado: "cheiro; odor",
    exemplos: [
      {
        kanji: "臭気",
        furigana: "しゅうき",
        romaji: "shuu.ki",
        traducao: "odor; cheiro",
      },
      {
        kanji: "臭い",
        furigana: "くさい",
        romaji: "kusa.i",
        traducao: "cheiroso; fedorento",
      },
    ],
  },
  {
    kanji: "悦",
    onYomi: [{ katakana: "エツ", romaji: "etsu" }],
    kunYomi: [{ hiragana: "よろこ.ぶ", romaji: "yoroko.bu" }],
    significado: "alegria; prazer",
    exemplos: [
      {
        kanji: "喜悦",
        furigana: "きえつ",
        romaji: "ki.etsu",
        traducao: "alegria; felicidade",
      },
      {
        kanji: "悦ぶ",
        furigana: "よろこぶ",
        romaji: "yoroko.bu",
        traducao: "alegrar-se; regozijar-se",
      },
    ],
  },
  {
    kanji: "刃",
    onYomi: [{ katakana: "ジン", romaji: "jin" }],
    kunYomi: [{ hiragana: "は", romaji: "ha" }],
    significado: "lâmina; gume",
    exemplos: [
      {
        kanji: "刃物",
        furigana: "はもの",
        romaji: "ha.mono",
        traducao: "ferramenta cortante",
      },
      {
        kanji: "刃",
        furigana: "ジン",
        romaji: "jin",
        traducao: "lâmina; gume",
      },
    ],
  },
  {
    kanji: "縛",
    onYomi: [{ katakana: "バク", romaji: "baku" }],
    kunYomi: [{ hiragana: "しば.る", romaji: "shiba.ru" }],
    significado: "amarrar; amordaçar",
    exemplos: [
      {
        kanji: "束縛",
        furigana: "そくばく",
        romaji: "soku.baku",
        traducao: "restrição; aprisionamento",
      },
      {
        kanji: "縛る",
        furigana: "しばる",
        romaji: "shiba.ru",
        traducao: "amarrar; amordaçar",
      },
    ],
  },
  {
    kanji: "暦",
    onYomi: [{ katakana: "レキ", romaji: "reki" }],
    kunYomi: [{ hiragana: "こよみ", romaji: "koyomi" }],
    significado: "calendário",
    exemplos: [
      {
        kanji: "暦",
        furigana: "こよみ",
        romaji: "koyomi",
        traducao: "calendário",
      },
      {
        kanji: "暦年",
        furigana: "れきねん",
        romaji: "reki.nen",
        traducao: "ano civil",
      },
    ],
  },
  {
    kanji: "宜",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [{ hiragana: "よろ.しい", romaji: "yoro.shii" }],
    significado: "adequado; apropriado",
    exemplos: [
      {
        kanji: "宜しい",
        furigana: "よろしい",
        romaji: "yoro.shii",
        traducao: "adequado; apropriado",
      },
      {
        kanji: "宜応",
        furigana: "ぎおう",
        romaji: "gi.ou",
        traducao: "resposta adequada",
      },
    ],
  },
  {
    kanji: "盲",
    onYomi: [{ katakana: "モウ", romaji: "mou" }],
    kunYomi: [{ hiragana: "めくら", romaji: "mekura" }],
    significado: "cego",
    exemplos: [
      {
        kanji: "盲目",
        furigana: "もうもく",
        romaji: "mou.moku",
        traducao: "cego; cegueira",
      },
      {
        kanji: "盲人",
        furigana: "もうじん",
        romaji: "mou.jin",
        traducao: "pessoa cega",
      },
    ],
  },
  {
    kanji: "粋",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "いき", romaji: "iki" }],
    significado: "elegante; refinado",
    exemplos: [
      {
        kanji: "粋な",
        furigana: "いきな",
        romaji: "iki.na",
        traducao: "elegante; refinado",
      },
      {
        kanji: "粋人",
        furigana: "すいじん",
        romaji: "sui.jin",
        traducao: "pessoa elegante",
      },
    ],
  },
  {
    kanji: "辱",
    onYomi: [{ katakana: "ジョク", romaji: "joku" }],
    kunYomi: [{ hiragana: "はずかし", romaji: "hazukashi" }],
    significado: "humilhação; desonra",
    exemplos: [
      {
        kanji: "辱める",
        furigana: "はずかしめる",
        romaji: "hazukashi.meru",
        traducao: "humilhar; envergonhar",
      },
      {
        kanji: "辱",
        furigana: "ジョク",
        romaji: "joku",
        traducao: "humilhação; desonra",
      },
    ],
  },
  {
    kanji: "毅",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "forte; corajoso",
    exemplos: [
      {
        kanji: "毅然",
        furigana: "きぜん",
        romaji: "ki.zen",
        traducao: "determinado; firme",
      },
      {
        kanji: "毅",
        furigana: "キ",
        romaji: "ki",
        traducao: "forte; corajoso",
      },
    ],
  },
  {
    kanji: "轄",
    onYomi: [{ katakana: "カツ", romaji: "katsu" }],
    kunYomi: [],
    significado: "jurisdição; controle",
    exemplos: [
      {
        kanji: "管轄",
        furigana: "かんかつ",
        romaji: "kan.katsu",
        traducao: "jurisdição",
      },
      {
        kanji: "所轄",
        furigana: "しょかつ",
        romaji: "sho.katsu",
        traducao: "jurisdição",
      },
    ],
  },
  {
    kanji: "猿",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "さる", romaji: "saru" }],
    significado: "macaco",
    exemplos: [
      {
        kanji: "猿",
        furigana: "さる",
        romaji: "saru",
        traducao: "macaco",
      },
      {
        kanji: "猿真似",
        furigana: "さるまね",
        romaji: "saru.mane",
        traducao: "imitação; mímica",
      },
    ],
  },
  {
    kanji: "弦",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [{ hiragana: "つる", romaji: "tsuru" }],
    significado: "corda",
    exemplos: [
      {
        kanji: "弦",
        furigana: "つる",
        romaji: "tsuru",
        traducao: "corda",
      },
      {
        kanji: "弦楽器",
        furigana: "げんがっき",
        romaji: "gen.gakki",
        traducao: "instrumento de cordas",
      },
    ],
  },
  {
    kanji: "稔",
    onYomi: [{ katakana: "ニン", romaji: "nin" }],
    kunYomi: [{ hiragana: "みのる", romaji: "minoru" }],
    significado: "colheita",
    exemplos: [
      {
        kanji: "豊稔",
        furigana: "ほうにん",
        romaji: "hou.nin",
        traducao: "boa colheita",
      },
      {
        kanji: "稔る",
        furigana: "みのる",
        romaji: "minoru",
        traducao: "colher",
      },
    ],
  },
  {
    kanji: "窒",
    onYomi: [{ katakana: "チツ", romaji: "chitsu" }],
    kunYomi: [],
    significado: "nitrogênio",
    exemplos: [
      {
        kanji: "窒素",
        furigana: "ちっそ",
        romaji: "chisso",
        traducao: "nitrogênio",
      },
      {
        kanji: "窒",
        furigana: "チツ",
        romaji: "chitsu",
        traducao: "nitrogênio",
      },
    ],
  },
  {
    kanji: "炊",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "た.く", romaji: "ta.ku" }],
    significado: "cozinhar; cozer",
    exemplos: [
      {
        kanji: "炊事",
        furigana: "すいじ",
        romaji: "sui.ji",
        traducao: "cozinhar; culinária",
      },
      {
        kanji: "炊く",
        furigana: "たく",
        romaji: "ta.ku",
        traducao: "cozinhar; cozer",
      },
    ],
  },
  {
    kanji: "洪",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "inundação",
    exemplos: [
      {
        kanji: "洪水",
        furigana: "こうずい",
        romaji: "kou.zui",
        traducao: "inundação",
      },
      {
        kanji: "洪流",
        furigana: "こうりゅう",
        romaji: "kou.ryuu",
        traducao: "correnteza",
      },
    ],
  },
  {
    kanji: "摂",
    onYomi: [{ katakana: "セツ", romaji: "setsu" }],
    kunYomi: [{ hiragana: "おさ.める", romaji: "osa.meru" }],
    significado: "tomar; ingerir",
    exemplos: [
      {
        kanji: "摂取",
        furigana: "せっしゅ",
        romaji: "setsu.shu",
        traducao: "ingestão; consumo",
      },
      {
        kanji: "摂",
        furigana: "おさ",
        romaji: "osa",
        traducao: "tomar; ingerir",
      },
    ],
  },
  {
    kanji: "飽",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "あ.きる", romaji: "a.kiru" }],
    significado: "saciado; farto",
    exemplos: [
      {
        kanji: "飽和",
        furigana: "ほうわ",
        romaji: "hou.wa",
        traducao: "saturação",
      },
      {
        kanji: "飽きる",
        furigana: "あきる",
        romaji: "a.kiru",
        traducao: "ficar entediado",
      },
    ],
  },
  {
    kanji: "冗",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [],
    significado: "supérfluo; desnecessário",
    exemplos: [
      {
        kanji: "冗談",
        furigana: "じょうだん",
        romaji: "jou.dan",
        traducao: "piada; brincadeira",
      },
      {
        kanji: "冗",
        furigana: "ジョウ",
        romaji: "jou",
        traducao: "supérfluo; desnecessário",
      },
    ],
  },
  {
    kanji: "桃",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "もも", romaji: "momo" }],
    significado: "pêssego",
    exemplos: [
      {
        kanji: "桃",
        furigana: "もも",
        romaji: "momo",
        traducao: "pêssego",
      },
      {
        kanji: "桃色",
        furigana: "ももいろ",
        romaji: "momo.iro",
        traducao: "cor de pêssego",
      },
    ],
  },
  {
    kanji: "狩",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [{ hiragana: "か.る", romaji: "ka.ru" }],
    significado: "caçar",
    exemplos: [
      {
        kanji: "狩り",
        furigana: "かり",
        romaji: "kari",
        traducao: "caça",
      },
      {
        kanji: "狩る",
        furigana: "かる",
        romaji: "karu",
        traducao: "caçar",
      },
    ],
  },
  {
    kanji: "朱",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [{ hiragana: "あけ", romaji: "ake" }],
    significado: "vermelho brilhante",
    exemplos: [
      {
        kanji: "朱色",
        furigana: "しゅいろ",
        romaji: "shu.iro",
        traducao: "vermelho brilhante",
      },
      {
        kanji: "朱",
        furigana: "あけ",
        romaji: "ake",
        traducao: "vermelho brilhante",
      },
    ],
  },
  {
    kanji: "渦",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "うず", romaji: "uzu" }],
    significado: "redemoinho",
    exemplos: [
      {
        kanji: "渦巻き",
        furigana: "うずまき",
        romaji: "uzu.maki",
        traducao: "redemoinho",
      },
      {
        kanji: "渦",
        furigana: "うず",
        romaji: "uzu",
        traducao: "redemoinho",
      },
    ],
  },
  {
    kanji: "紳",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "cavalheiro; membro da sociedade",
    exemplos: [
      {
        kanji: "紳士",
        furigana: "しんし",
        romaji: "shin.shi",
        traducao: "cavalheiro; homem de sociedade",
      },
      {
        kanji: "紳",
        furigana: "シン",
        romaji: "shin",
        traducao: "cavalheiro; membro da sociedade",
      },
    ],
  },
  {
    kanji: "枢",
    onYomi: [{ katakana: "スウ", romaji: "suu" }],
    kunYomi: [{ hiragana: "からくり", romaji: "karakuri" }],
    significado: "eixo; centro",
    exemplos: [
      {
        kanji: "枢軸",
        furigana: "すうじく",
        romaji: "suu.jiku",
        traducao: "eixo central",
      },
      {
        kanji: "枢",
        furigana: "からくり",
        romaji: "karakuri",
        traducao: "mecanismo; dispositivo",
      },
    ],
  },
  {
    kanji: "碑",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [],
    significado: "pedra com inscrição",
    exemplos: [
      {
        kanji: "碑文",
        furigana: "ひぶん",
        romaji: "hi.bun",
        traducao: "inscrição em pedra",
      },
      {
        kanji: "碑",
        furigana: "ヒ",
        romaji: "hi",
        traducao: "pedra com inscrição",
      },
    ],
  },
  {
    kanji: "鍛",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "きた.える", romaji: "kita.eru" }],
    significado: "forjar; moldar",
    exemplos: [
      {
        kanji: "鍛錬",
        furigana: "たんれん",
        romaji: "tan.ren",
        traducao: "treinamento; forja",
      },
      {
        kanji: "鍛える",
        furigana: "きたえる",
        romaji: "kita.eru",
        traducao: "forjar; moldar",
      },
    ],
  },
  {
    kanji: "刀",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [],
    significado: "espada",
    exemplos: [
      {
        kanji: "刀剣",
        furigana: "とうけん",
        romaji: "tou.ken",
        traducao: "espada",
      },
      {
        kanji: "刀",
        furigana: "トウ",
        romaji: "tou",
        traducao: "espada",
      },
    ],
  },
  {
    kanji: "鼓",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "つづみ", romaji: "tsuzumi" }],
    significado: "tambor",
    exemplos: [
      {
        kanji: "太鼓",
        furigana: "たいこ",
        romaji: "taiko",
        traducao: "tambor",
      },
      {
        kanji: "鼓",
        furigana: "つづみ",
        romaji: "tsuzumi",
        traducao: "tambor",
      },
    ],
  },
  {
    kanji: "裸",
    onYomi: [{ katakana: "ラ", romaji: "ra" }],
    kunYomi: [{ hiragana: "はだか", romaji: "hadaka" }],
    significado: "nu; desprovido",
    exemplos: [
      {
        kanji: "裸体",
        furigana: "らたい",
        romaji: "ra.tai",
        traducao: "corpo nu",
      },
      {
        kanji: "裸",
        furigana: "はだか",
        romaji: "hadaka",
        traducao: "nu; desprovido",
      },
    ],
  },
  {
    kanji: "猶",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [],
    significado: "ainda; como antes",
    exemplos: [
      {
        kanji: "猶予",
        furigana: "ゆうよ",
        romaji: "yuu.yo",
        traducao: "adiamento; atraso",
      },
      {
        kanji: "猶",
        furigana: "ユウ",
        romaji: "yuu",
        traducao: "ainda; como antes",
      },
    ],
  },
  {
    kanji: "塊",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "かたまり", romaji: "katamari" }],
    significado: "pedaço; aglomerado",
    exemplos: [
      {
        kanji: "塊",
        furigana: "かたまり",
        romaji: "katamari",
        traducao: "pedaço; aglomerado",
      },
      {
        kanji: "大塊",
        furigana: "おおかた",
        romaji: "ookata",
        traducao: "grande pedaço",
      },
    ],
  },
  {
    kanji: "旋",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [],
    significado: "girar; rodar",
    exemplos: [
      {
        kanji: "旋回",
        furigana: "せんかい",
        romaji: "sen.kai",
        traducao: "girar; rodar",
      },
      {
        kanji: "回旋",
        furigana: "かいせん",
        romaji: "kai.sen",
        traducao: "rotação; volta",
      },
    ],
  },
  {
    kanji: "弓",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "ゆみ", romaji: "yumi" }],
    significado: "arco",
    exemplos: [
      {
        kanji: "弓道",
        furigana: "きゅうどう",
        romaji: "kyuu.dou",
        traducao: "tiro com arco",
      },
      {
        kanji: "弓",
        furigana: "ゆみ",
        romaji: "yumi",
        traducao: "arco",
      },
    ],
  },
  {
    kanji: "幣",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [],
    significado: "moeda; oferenda",
    exemplos: [
      {
        kanji: "貨幣",
        furigana: "かへい",
        romaji: "ka.hei",
        traducao: "moeda",
      },
      {
        kanji: "祭幣",
        furigana: "さいへい",
        romaji: "sai.hei",
        traducao: "oferta ritual",
      },
    ],
  },
  {
    kanji: "膜",
    onYomi: [{ katakana: "マク", romaji: "maku" }],
    kunYomi: [],
    significado: "membrana; película",
    exemplos: [
      {
        kanji: "細胞膜",
        furigana: "さいぼうまく",
        romaji: "sai.bou.maku",
        traducao: "membrana celular",
      },
      {
        kanji: "膜",
        furigana: "まく",
        romaji: "maku",
        traducao: "membrana; película",
      },
    ],
  },
  {
    kanji: "扇",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "おうぎ", romaji: "ougi" }],
    significado: "leque",
    exemplos: [
      {
        kanji: "扇風機",
        furigana: "せんぷうき",
        romaji: "sen.puu.ki",
        traducao: "ventilador",
      },
      {
        kanji: "扇",
        furigana: "おうぎ",
        romaji: "ougi",
        traducao: "leque",
      },
    ],
  },
  {
    kanji: "腸",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [],
    significado: "intestino",
    exemplos: [
      {
        kanji: "小腸",
        furigana: "しょうちょう",
        romaji: "shou.chou",
        traducao: "intestino delgado",
      },
      {
        kanji: "大腸",
        furigana: "だいちょう",
        romaji: "dai.chou",
        traducao: "intestino grosso",
      },
    ],
  },
  {
    kanji: "槽",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [],
    significado: "tanque; reservatório",
    exemplos: [
      {
        kanji: "水槽",
        furigana: "すいそう",
        romaji: "sui.sou",
        traducao: "tanque de água",
      },
      {
        kanji: "槽",
        furigana: "ソウ",
        romaji: "sou",
        traducao: "tanque; reservatório",
      },
    ],
  },
  {
    kanji: "慈",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [{ hiragana: "いつく.しむ", romaji: "itsuku.shimu" }],
    significado: "amor; bondade",
    exemplos: [
      {
        kanji: "慈愛",
        furigana: "じあい",
        romaji: "ji.ai",
        traducao: "amor; caridade",
      },
      {
        kanji: "慈",
        furigana: "いつくしむ",
        romaji: "itsukushimu",
        traducao: "amar; ter compaixão",
      },
    ],
  },
  {
    kanji: "楊",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [],
    significado: "salgueiro",
    exemplos: [
      {
        kanji: "楊",
        furigana: "よう",
        romaji: "you",
        traducao: "salgueiro",
      },
      {
        kanji: "楊枝",
        furigana: "ようじ",
        romaji: "you.ji",
        traducao: "palito",
      },
    ],
  },
  {
    kanji: "伐",
    onYomi: [{ katakana: "バツ", romaji: "batsu" }],
    kunYomi: [],
    significado: "derrubar; cortar",
    exemplos: [
      {
        kanji: "伐採",
        furigana: "ばっさい",
        romaji: "batsu.sai",
        traducao: "derrubada de árvores",
      },
      {
        kanji: "伐",
        furigana: "バツ",
        romaji: "batsu",
        traducao: "derrubar; cortar",
      },
    ],
  },
  {
    kanji: "駿",
    onYomi: [{ katakana: "シュン", romaji: "shun" }],
    kunYomi: [],
    significado: "cavalo rápido",
    exemplos: [
      {
        kanji: "駿馬",
        furigana: "しゅんめ",
        romaji: "shun.me",
        traducao: "cavalo veloz",
      },
      {
        kanji: "駿",
        furigana: "シュン",
        romaji: "shun",
        traducao: "cavalo rápido",
      },
    ],
  },
  {
    kanji: "漬",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "つ.ける", romaji: "tsu.keru" }],
    significado: "embeber; marinar",
    exemplos: [
      {
        kanji: "漬物",
        furigana: "つけもの",
        romaji: "tsuke.mono",
        traducao: "conserva; picles",
      },
      {
        kanji: "漬ける",
        furigana: "つける",
        romaji: "tsukeru",
        traducao: "embeber; marinar",
      },
    ],
  },
  {
    kanji: "糾",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [],
    significado: "fio; emaranhado",
    exemplos: [
      {
        kanji: "糾紛",
        furigana: "きゅうふん",
        romaji: "kyuu.fun",
        traducao: "disputa; controvérsia",
      },
      {
        kanji: "糾",
        furigana: "キュウ",
        romaji: "kyuu",
        traducao: "fio; emaranhado",
      },
    ],
  },
  {
    kanji: "亮",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [],
    significado: "iluminado; brilhante",
    exemplos: [
      {
        kanji: "明亮",
        furigana: "めいりょう",
        romaji: "mei.ryou",
        traducao: "brilhante; luminoso",
      },
      {
        kanji: "亮",
        furigana: "リョウ",
        romaji: "ryou",
        traducao: "iluminado; brilhante",
      },
    ],
  },
  {
    kanji: "墳",
    onYomi: [{ katakana: "フン", romaji: "fun" }],
    kunYomi: [],
    significado: "túmulo",
    exemplos: [
      {
        kanji: "墳墓",
        furigana: "ふんぼ",
        romaji: "fun.bo",
        traducao: "túmulo",
      },
      {
        kanji: "墳",
        furigana: "フン",
        romaji: "fun",
        traducao: "túmulo",
      },
    ],
  },
  {
    kanji: "坪",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [],
    significado: "medida de área",
    exemplos: [
      {
        kanji: "坪数",
        furigana: "つぼかず",
        romaji: "tsubo.kazu",
        traducao: "área",
      },
      {
        kanji: "坪",
        furigana: "ヘイ",
        romaji: "hei",
        traducao: "medida de área",
      },
    ],
  },
  {
    kanji: "紺",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [],
    significado: "azul escuro",
    exemplos: [
      {
        kanji: "紺青",
        furigana: "こんじょう",
        romaji: "kon.jou",
        traducao: "azul escuro",
      },
      {
        kanji: "紺",
        furigana: "コン",
        romaji: "kon",
        traducao: "azul escuro",
      },
    ],
  },
  {
    kanji: "娯",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [],
    significado: "entretenimento; lazer",
    exemplos: [
      {
        kanji: "娯楽",
        furigana: "ごらく",
        romaji: "go.raku",
        traducao: "entretenimento",
      },
      {
        kanji: "娯",
        furigana: "ゴ",
        romaji: "go",
        traducao: "entretenimento; lazer",
      },
    ],
  },
  {
    kanji: "椿",
    onYomi: [{ katakana: "チン", romaji: "chin" }],
    kunYomi: [{ hiragana: "つばき", romaji: "tsubaki" }],
    significado: "camélia",
    exemplos: [
      {
        kanji: "椿",
        furigana: "つばき",
        romaji: "tsubaki",
        traducao: "camélia",
      },
      {
        kanji: "椿油",
        furigana: "つばきあぶら",
        romaji: "tsubaki.abura",
        traducao: "óleo de camélia",
      },
    ],
  },
  {
    kanji: "舌",
    onYomi: [{ katakana: "ゼツ", romaji: "zetsu" }],
    kunYomi: [{ hiragana: "した", romaji: "shita" }],
    significado: "língua",
    exemplos: [
      {
        kanji: "舌",
        furigana: "した",
        romaji: "shita",
        traducao: "língua",
      },
      {
        kanji: "舌打ち",
        furigana: "したうち",
        romaji: "shita.uchi",
        traducao: "clicar a língua",
      },
    ],
  },
  {
    kanji: "羅",
    onYomi: [{ katakana: "ラ", romaji: "ra" }],
    kunYomi: [],
    significado: "tecido; rede",
    exemplos: [
      {
        kanji: "羅針盤",
        furigana: "らしんばん",
        romaji: "ra.shin.ban",
        traducao: "bússola",
      },
      {
        kanji: "羅",
        furigana: "ラ",
        romaji: "ra",
        traducao: "tecido; rede",
      },
    ],
  },
  {
    kanji: "峡",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [],
    significado: "desfiladeiro",
    exemplos: [
      {
        kanji: "峡谷",
        furigana: "きょうこく",
        romaji: "kyou.koku",
        traducao: "cânion",
      },
      {
        kanji: "峡",
        furigana: "キョウ",
        romaji: "kyou",
        traducao: "desfiladeiro",
      },
    ],
  },
  {
    kanji: "俸",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [],
    significado: "salário",
    exemplos: [
      {
        kanji: "俸給",
        furigana: "ほうきゅう",
        romaji: "hou.kyuu",
        traducao: "salário",
      },
      {
        kanji: "年俸",
        furigana: "ねんぽう",
        romaji: "nen.pou",
        traducao: "salário anual",
      },
    ],
  },
  {
    kanji: "厘",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [],
    significado: "unidade de medida",
    exemplos: [
      {
        kanji: "厘米",
        furigana: "りんべい",
        romaji: "rin.bei",
        traducao: "centímetro",
      },
      {
        kanji: "厘",
        furigana: "リン",
        romaji: "rin",
        traducao: "unidade de medida",
      },
      {
        kanji: "一厘玉",
        furigana: "いちりんだま",
        romaji: "ichi.rin.da.ma",
        traducao: "bola de 1 centavo",
      },
    ],
  },
  {
    kanji: "峰",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [],
    significado: "pico; cume",
    exemplos: [
      {
        kanji: "山峰",
        furigana: "さんぽう",
        romaji: "san.pou",
        traducao: "pico da montanha",
      },
      {
        kanji: "峰",
        furigana: "ほう",
        romaji: "hou",
        traducao: "pico; cume",
      },
      {
        kanji: "峰下",
        furigana: "みねした",
        romaji: "mine.shita",
        traducao: "base da montanha",
      },
    ],
  },
  {
    kanji: "圭",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [],
    significado: "esquadro",
    exemplos: [
      {
        kanji: "圭",
        furigana: "けい",
        romaji: "kei",
        traducao: "esquadro",
      },
      {
        kanji: "圭形",
        furigana: "けいけい",
        romaji: "kei.kei",
        traducao: "formato de esquadro",
      },
      {
        kanji: "圭瓜",
        furigana: "きゅうり",
        romaji: "kyuu.ri",
        traducao: "pepino",
      },
    ],
  },
  {
    kanji: "醸",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "かも.す", romaji: "kamo.su" }],
    significado: "fermentar; criar",
    exemplos: [
      {
        kanji: "醸造",
        furigana: "じょうぞう",
        romaji: "jou.zou",
        traducao: "fabricação; produção",
      },
      {
        kanji: "醸す",
        furigana: "かもす",
        romaji: "kamo.su",
        traducao: "fermentar; criar",
      },
      {
        kanji: "醸し出す",
        furigana: "かもし.だす",
        romaji: "kamo.shi.dasu",
        traducao: "produzir; criar",
      },
    ],
  },
  {
    kanji: "蓮",
    onYomi: [{ katakana: "レン", romaji: "ren" }],
    kunYomi: [{ hiragana: "はす", romaji: "hasu" }],
    significado: "lótus",
    exemplos: [
      {
        kanji: "蓮華",
        furigana: "れんげ",
        romaji: "ren.ge",
        traducao: "flor de lótus",
      },
      {
        kanji: "蓮",
        furigana: "はす",
        romaji: "hasu",
        traducao: "lótus",
      },
      {
        kanji: "蓮池",
        furigana: "はすいけ",
        romaji: "hasui.ke",
        traducao: "lago de lótus",
      },
    ],
  },
  {
    kanji: "弔",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "とむら.う", romaji: "tomura.u" }],
    significado: "luto; condolências",
    exemplos: [
      {
        kanji: "弔問",
        furigana: "ちょうもん",
        romaji: "chou.mon",
        traducao: "visita de condolências",
      },
      {
        kanji: "弔う",
        furigana: "とむらう",
        romaji: "tomurau",
        traducao: "lamentar; expressar condolências",
      },
      {
        kanji: "弔辞",
        furigana: "ちょうじ",
        romaji: "chou.ji",
        traducao: "discurso fúnebre",
      },
    ],
  },
  {
    kanji: "乙",
    onYomi: [{ katakana: "オツ", romaji: "otsu" }],
    kunYomi: [],
    significado: "segundo; peixe em forma de gancho",
    exemplos: [
      {
        kanji: "乙女",
        furigana: "おとめ",
        romaji: "otome",
        traducao: "garota; donzela",
      },
      {
        kanji: "乙",
        furigana: "オツ",
        romaji: "otsu",
        traducao: "segundo; peixe em forma de gancho",
      },
      {
        kanji: "乙型",
        furigana: "おつがた",
        romaji: "otsu.gata",
        traducao: "tipo B",
      },
    ],
  },
  {
    kanji: "汁",
    onYomi: [{ katakana: "ジュウ", romaji: "juu" }],
    kunYomi: [],
    significado: "sopa; líquido",
    exemplos: [
      {
        kanji: "味噌汁",
        furigana: "みそしる",
        romaji: "miso.shiru",
        traducao: "sopa de missô",
      },
      {
        kanji: "汁",
        furigana: "しる",
        romaji: "shiru",
        traducao: "sopa; líquido",
      },
      {
        kanji: "汁気",
        furigana: "しるけ",
        romaji: "shiru.ke",
        traducao: "umidade; suco",
      },
    ],
  },
  {
    kanji: "尼",
    onYomi: [{ katakana: "ニ", romaji: "ni" }],
    kunYomi: [],
    significado: "freira",
    exemplos: [
      {
        kanji: "修道尼",
        furigana: "しゅうどうに",
        romaji: "shuu.dou.ni",
        traducao: "freira",
      },
      {
        kanji: "尼寺",
        furigana: "にでら",
        romaji: "ni.de.ra",
        traducao: "templo de freiras",
      },
      {
        kanji: "尼僧",
        furigana: "にそう",
        romaji: "ni.sou",
        traducao: "freira budista",
      },
    ],
  },
  {
    kanji: "遍",
    onYomi: [{ katakana: "ヘン", romaji: "hen" }],
    kunYomi: [],
    significado: "por toda parte; todo",
    exemplos: [
      {
        kanji: "一遍",
        furigana: "いっぺん",
        romaji: "ip.pen",
        traducao: "uma vez",
      },
      {
        kanji: "遍歴",
        furigana: "へんれき",
        romaji: "hen.reki",
        traducao: "período de viagem; peregrinação",
      },
      {
        kanji: "普遍",
        furigana: "ふへん",
        romaji: "fu.hen",
        traducao: "universal; geral",
      },
    ],
  },
  {
    kanji: "衡",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "equilíbrio; balança",
    exemplos: [
      {
        kanji: "均衡",
        furigana: "きんこう",
        romaji: "kin.kou",
        traducao: "equilíbrio",
      },
      {
        kanji: "衡量",
        furigana: "こうりょう",
        romaji: "kou.ryou",
        traducao: "medida; avaliação",
      },
      {
        kanji: "鉄道衡",
        furigana: "てつどうこう",
        romaji: "tetsu.dou.kou",
        traducao: "balança ferroviária",
      },
    ],
  },
  {
    kanji: "薫",
    onYomi: [{ katakana: "クン", romaji: "kun" }],
    kunYomi: [{ hiragana: "かお.る", romaji: "kao.ru" }],
    significado: "perfume; aroma",
    exemplos: [
      {
        kanji: "薫風",
        furigana: "くんぷう",
        romaji: "kun.puu",
        traducao: "brisa perfumada",
      },
      {
        kanji: "薫る",
        furigana: "かおる",
        romaji: "kaoru",
        traducao: "exalar perfume; emanar aroma",
      },
      {
        kanji: "薫製",
        furigana: "くんせい",
        romaji: "kun.sei",
        traducao: "defumado",
      },
    ],
  },
  {
    kanji: "猟",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [{ hiragana: "かり", romaji: "kari" }],
    significado: "caça",
    exemplos: [
      {
        kanji: "猟師",
        furigana: "りょうし",
        romaji: "ryou.shi",
        traducao: "caçador",
      },
      {
        kanji: "猟犬",
        furigana: "りょうけん",
        romaji: "ryou.ken",
        traducao: "cão de caça",
      },
      {
        kanji: "狩り",
        furigana: "かり",
        romaji: "kari",
        traducao: "caça",
      },
    ],
  },
  {
    kanji: "羊",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "ひつじ", romaji: "hitsuji" }],
    significado: "carneiro; ovelha",
    exemplos: [
      {
        kanji: "羊毛",
        furigana: "ようもう",
        romaji: "you.mou",
        traducao: "lã",
      },
      {
        kanji: "羊",
        furigana: "ひつじ",
        romaji: "hitsuji",
        traducao: "carneiro; ovelha",
      },
      {
        kanji: "山羊",
        furigana: "やぎ",
        romaji: "yagi",
        traducao: "cabra",
      },
    ],
  },
  {
    kanji: "款",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "item; estilo",
    exemplos: [
      {
        kanji: "款式",
        furigana: "かんしき",
        romaji: "kan.shiki",
        traducao: "estilo",
      },
      {
        kanji: "返品",
        furigana: "へんぴん",
        romaji: "hen.pin",
        traducao: "devolução",
      },
      {
        kanji: "お取り寄せ品",
        furigana: "おとりよせひん",
        romaji: "oto.ri.yo.se.hin",
        traducao: "item sob encomenda",
      },
    ],
  },
  {
    kanji: "閲",
    onYomi: [{ katakana: "エツ", romaji: "etsu" }],
    kunYomi: [{ hiragana: "けみ.する", romaji: "kemi.suru" }],
    significado: "examinar; inspecionar",
    exemplos: [
      {
        kanji: "閲覧",
        furigana: "えつらん",
        romaji: "etsu.ran",
        traducao: "visualização",
      },
      {
        kanji: "閲兵",
        furigana: "えつへい",
        romaji: "etsu.hei",
        traducao: "revisão militar",
      },
      {
        kanji: "閲する",
        furigana: "けみする",
        romaji: "kemi.suru",
        traducao: "examinar; inspecionar",
      },
    ],
  },
  {
    kanji: "偵",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "espionar; investigar",
    exemplos: [
      {
        kanji: "偵察",
        furigana: "ていさつ",
        romaji: "tei.satsu",
        traducao: "reconhecimento; espionagem",
      },
      {
        kanji: "偵",
        furigana: "ちょうさ",
        romaji: "chou.sa",
        traducao: "investigação",
      },
      {
        kanji: "偵察機",
        furigana: "ていさつき",
        romaji: "tei.satsu.ki",
        traducao: "avião de reconhecimento",
      },
    ],
  },
  {
    kanji: "喝",
    onYomi: [{ katakana: "カツ", romaji: "katsu" }],
    kunYomi: [],
    significado: "repreender; dar um grito",
    exemplos: [
      {
        kanji: "忠告の喝",
        furigana: "ちゅうこくのかつ",
        romaji: "chuu.koku.no.katsu",
        traducao: "repreensão de aviso",
      },
      {
        kanji: "喝する",
        furigana: "かつする",
        romaji: "katsu.suru",
        traducao: "repreender; dar um grito",
      },
      {
        kanji: "大喝",
        furigana: "だいかつ",
        romaji: "dai.katsu",
        traducao: "grito alto",
      },
    ],
  },
  {
    kanji: "敢",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "ousadia; coragem",
    exemplos: [
      {
        kanji: "大胆不敵",
        furigana: "だいたんふてき",
        romaji: "dai.tan.fu.teki",
        traducao: "ousado; destemido",
      },
      {
        kanji: "敢然",
        furigana: "かんぜん",
        romaji: "kan.zen",
        traducao: "corajosamente",
      },
      {
        kanji: "敢えて",
        furigana: "あえて",
        romaji: "ae.te",
        traducao: "ousar; atrever-se",
      },
    ],
  },
  {
    kanji: "胎",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [],
    significado: "embrião; feto",
    exemplos: [
      {
        kanji: "母胎",
        furigana: "ぼたい",
        romaji: "bo.tai",
        traducao: "útero; ventre materno",
      },
      {
        kanji: "胎児",
        furigana: "たいじ",
        romaji: "tai.ji",
        traducao: "feto",
      },
      {
        kanji: "生胎",
        furigana: "せいたい",
        romaji: "sei.tai",
        traducao: "gravidez; concepção",
      },
    ],
  },
  {
    kanji: "酵",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "fermento",
    exemplos: [
      {
        kanji: "発酵",
        furigana: "はっこう",
        romaji: "hak.kou",
        traducao: "fermentação",
      },
      {
        kanji: "酵素",
        furigana: "こうそ",
        romaji: "kou.so",
        traducao: "enzima",
      },
      {
        kanji: "酵母",
        furigana: "こうぼ",
        romaji: "kou.bo",
        traducao: "levedura",
      },
    ],
  },
  {
    kanji: "憤",
    onYomi: [{ katakana: "フン", romaji: "fun" }],
    kunYomi: [{ hiragana: "いきどお.る", romaji: "ikidoo.ru" }],
    significado: "indignação; raiva",
    exemplos: [
      {
        kanji: "憤慨",
        furigana: "ふんがい",
        romaji: "fun.gai",
        traducao: "indignação",
      },
      {
        kanji: "憤り",
        furigana: "いきどおり",
        romaji: "ikidoo.ri",
        traducao: "raiva; ressentimento",
      },
      {
        kanji: "憤怒",
        furigana: "ふんど",
        romaji: "fun.do",
        traducao: "raiva; fúria",
      },
    ],
  },
  {
    kanji: "豚",
    onYomi: [{ katakana: "トン", romaji: "ton" }],
    kunYomi: [{ hiragana: "ぶた", romaji: "buta" }],
    significado: "porco",
    exemplos: [
      {
        kanji: "豚肉",
        furigana: "ぶたにく",
        romaji: "bu.ta.ni.ku",
        traducao: "carne de porco",
      },
      {
        kanji: "豚骨",
        furigana: "とんこつ",
        romaji: "ton.ko.tsu",
        traducao: "caldo de porco",
      },
      {
        kanji: "子豚",
        furigana: "こぶた",
        romaji: "ko.bu.ta",
        traducao: "leitão",
      },
    ],
  },
  {
    kanji: "遮",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "さえぎ.る", romaji: "saegi.ru" }],
    significado: "bloquear; interromper",
    exemplos: [
      {
        kanji: "遮断",
        furigana: "しゃだん",
        romaji: "sha.dan",
        traducao: "bloqueio",
      },
      {
        kanji: "遮る",
        furigana: "さえぎる",
        romaji: "saegi.ru",
        traducao: "bloquear; interromper",
      },
      {
        kanji: "遮光",
        furigana: "しゃこう",
        romaji: "sha.kou",
        traducao: "sombreamento",
      },
    ],
  },
  {
    kanji: "扉",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "とびら", romaji: "tobira" }],
    significado: "porta; entrada",
    exemplos: [
      {
        kanji: "前扉",
        furigana: "まえとびら",
        romaji: "mae.to.bi.ra",
        traducao: "porta dianteira",
      },
      {
        kanji: "扉",
        furigana: "とびら",
        romaji: "tobira",
        traducao: "porta; entrada",
      },
      {
        kanji: "内扉",
        furigana: "うちとびら",
        romaji: "uchi.to.bi.ra",
        traducao: "porta interna",
      },
    ],
  },
  {
    kanji: "硫",
    onYomi: [{ katakana: "リュウ", romaji: "ryuu" }],
    kunYomi: [],
    significado: "enxofre",
    exemplos: [
      {
        kanji: "硫黄",
        furigana: "いおう",
        romaji: "i.ou",
        traducao: "enxofre",
      },
      {
        kanji: "硫酸",
        furigana: "りゅうさん",
        romaji: "ryuu.san",
        traducao: "ácido sulfúrico",
      },
      {
        kanji: "硫化",
        furigana: "りゅうか",
        romaji: "ryuu.ka",
        traducao: "sulfuração",
      },
    ],
  },
  {
    kanji: "赦",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "ゆる.す", romaji: "yuru.su" }],
    significado: "perdoar",
    exemplos: [
      {
        kanji: "恩赦",
        furigana: "おんしゃ",
        romaji: "on.sha",
        traducao: "anistia",
      },
      {
        kanji: "赦す",
        furigana: "ゆるす",
        romaji: "yuru.su",
        traducao: "perdoar",
      },
      {
        kanji: "赦免",
        furigana: "しゃめん",
        romaji: "sha.men",
        traducao: "dispensa; perdão",
      },
    ],
  },
  {
    kanji: "窃",
    onYomi: [{ katakana: "セツ", romaji: "setsu" }],
    kunYomi: [{ hiragana: "ぬす.む", romaji: "nusu.mu" }],
    significado: "roubar; furtar",
    exemplos: [
      {
        kanji: "窃盗",
        furigana: "せっとう",
        romaji: "setsu.tou",
        traducao: "roubo",
      },
      {
        kanji: "窃取",
        furigana: "せっしゅ",
        romaji: "setsu.shu",
        traducao: "roubo; apropriação",
      },
      {
        kanji: "窃う",
        furigana: "ぬすう",
        romaji: "nusu.u",
        traducao: "roubar; furtar",
      },
    ],
  },
  {
    kanji: "泡",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "あわ", romaji: "awa" }],
    significado: "bolha; espuma",
    exemplos: [
      {
        kanji: "泡立つ",
        furigana: "あわだつ",
        romaji: "awa.da.tsu",
        traducao: "formar bolhas; espumar",
      },
      {
        kanji: "泡",
        furigana: "あわ",
        romaji: "awa",
        traducao: "bolha; espuma",
      },
      {
        kanji: "泡沫",
        furigana: "うたかた",
        romaji: "uta.ka.ta",
        traducao: "bolha; efêmero",
      },
    ],
  },
  {
    kanji: "瑞",
    onYomi: [{ katakana: "ズイ", romaji: "zui" }],
    kunYomi: [{ hiragana: "みず.う", romaji: "mizu.u" }],
    significado: "auspicioso; felicidade",
    exemplos: [
      {
        kanji: "瑞雨",
        furigana: "ずいう",
        romaji: "zui.u",
        traducao: "chuva benéfica",
      },
      {
        kanji: "瑞々しい",
        furigana: "みずみずしい",
        romaji: "mizu.mizu.shii",
        traducao: "refrescante; exuberante",
      },
      {
        kanji: "神瑞",
        furigana: "しんずい",
        romaji: "shin.zui",
        traducao: "sinais divinos; auspicioso",
      },
    ],
  },
  {
    kanji: "又",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [{ hiragana: "また", romaji: "mata" }],
    significado: "também; além disso",
    exemplos: [
      {
        kanji: "又",
        furigana: "また",
        romaji: "mata",
        traducao: "também; além disso",
      },
      {
        kanji: "又は",
        furigana: "または",
        romaji: "mata.ha",
        traducao: "ou; ou então",
      },
      {
        kanji: "又々",
        furigana: "またまた",
        romaji: "mata.mata",
        traducao: "mais uma vez",
      },
    ],
  },
  {
    kanji: "慨",
    onYomi: [{ katakana: "ガイ", romaji: "gai" }],
    kunYomi: [{ hiragana: "うら.めしい", romaji: "ura.meshii" }],
    significado: "lamentar; indignado",
    exemplos: [
      {
        kanji: "憤慨",
        furigana: "ふんがい",
        romaji: "fun.gai",
        traducao: "indignação",
      },
      {
        kanji: "慨嘆",
        furigana: "がいたん",
        romaji: "gai.tan",
        traducao: "lamentação",
      },
      {
        kanji: "慨て",
        furigana: "うらめしい",
        romaji: "ura.meshii",
        traducao: "lamentar; indignado",
      },
    ],
  },
  {
    kanji: "紡",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "つむ.ぐ", romaji: "tsumu.gu" }],
    significado: "fiar; tecer",
    exemplos: [
      {
        kanji: "紡績",
        furigana: "ぼうせき",
        romaji: "bou.seki",
        traducao: "fiação",
      },
      {
        kanji: "紡ぐ",
        furigana: "つむぐ",
        romaji: "tsumu.gu",
        traducao: "fiar; tecer",
      },
      {
        kanji: "紡錘",
        furigana: "ぼうすい",
        romaji: "bou.sui",
        traducao: "fuso",
      },
    ],
  },
  {
    kanji: "恨",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [{ hiragana: "うら.む", romaji: "ura.mu" }],
    significado: "ressentimento; rancor",
    exemplos: [
      {
        kanji: "恨み",
        furigana: "うらみ",
        romaji: "ura.mi",
        traducao: "ressentimento; rancor",
      },
      {
        kanji: "恨む",
        furigana: "うらむ",
        romaji: "ura.mu",
        traducao: "ressentir-se; ter rancor",
      },
      {
        kanji: "恨み節",
        furigana: "うらみぶし",
        romaji: "ura.mi.bu.shi",
        traducao: "canção de ressentimento",
      },
    ],
  },
  {
    kanji: "肪",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "ぬくもり", romaji: "nukumori" }],
    significado: "gordura",
    exemplos: [
      {
        kanji: "脂肪",
        furigana: "しぼう",
        romaji: "shi.bou",
        traducao: "gordura",
      },
      {
        kanji: "肥肪",
        furigana: "ひぼう",
        romaji: "hi.bou",
        traducao: "gordura; adiposidade",
      },
      {
        kanji: "肪溜め",
        furigana: "ぬくもり",
        romaji: "nukumori",
        traducao: "gordura; adiposidade",
      },
    ],
  },
  {
    kanji: "扶",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "たす.ける", romaji: "tasu.keru" }],
    significado: "ajudar; suportar",
    exemplos: [
      {
        kanji: "扶養",
        furigana: "ふよう",
        romaji: "fu.you",
        traducao: "sustento",
      },
      {
        kanji: "扶助",
        furigana: "ふじょ",
        romaji: "fu.jo",
        traducao: "assistência",
      },
      {
        kanji: "扶ける",
        furigana: "たすける",
        romaji: "tasu.keru",
        traducao: "ajudar; suportar",
      },
    ],
  },
  {
    kanji: "戯",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [{ hiragana: "たわむ.れる", romaji: "tawamu.reru" }],
    significado: "brincar; diversão",
    exemplos: [
      {
        kanji: "戯れ",
        furigana: "たわむれ",
        romaji: "tawamu.re",
        traducao: "brincadeira; diversão",
      },
      {
        kanji: "戯曲",
        furigana: "ぎきょく",
        romaji: "gi.kyoku",
        traducao: "peça teatral",
      },
      {
        kanji: "戯れる",
        furigana: "たわむれる",
        romaji: "tawamu.reru",
        traducao: "brincar; divertir-se",
      },
    ],
  },
  {
    kanji: "伍",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [],
    significado: "cinco; companheiro",
    exemplos: [
      {
        kanji: "五人伍",
        furigana: "ごにんご",
        romaji: "go.nin.go",
        traducao: "grupo de cinco pessoas",
      },
      {
        kanji: "第伍",
        furigana: "だいご",
        romaji: "dai.go",
        traducao: "quinto",
      },
      {
        kanji: "伍する",
        furigana: "ごする",
        romaji: "go.suru",
        traducao: "formar um grupo; juntar-se",
      },
    ],
  },
  {
    kanji: "忌",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "い.む", romaji: "i.mu" }],
    significado: "luto; evitar",
    exemplos: [
      {
        kanji: "忌み嫌う",
        furigana: "いみぎらう",
        romaji: "imi.gira.u",
        traducao: "evitar; repudiar",
      },
      {
        kanji: "忌まわしい",
        furigana: "いまわしい",
        romaji: "imawa.shii",
        traducao: "odioso; detestável",
      },
      {
        kanji: "忌日",
        furigana: "きにち",
        romaji: "ki.nichi",
        traducao: "dia de luto",
      },
    ],
  },
  {
    kanji: "濁",
    onYomi: [{ katakana: "ダク", romaji: "daku" }],
    kunYomi: [{ hiragana: "にご.る", romaji: "nigo.ru" }],
    significado: "turbidez; sujeira",
    exemplos: [
      {
        kanji: "濁る",
        furigana: "にごる",
        romaji: "nigo.ru",
        traducao: "tornar-se turvo; sujar-se",
      },
      {
        kanji: "濁水",
        furigana: "だくすい",
        romaji: "daku.sui",
        traducao: "água turva",
      },
      {
        kanji: "濁音",
        furigana: "だくおん",
        romaji: "daku.on",
        traducao: "som impuro",
      },
    ],
  },
  {
    kanji: "奔",
    onYomi: [{ katakana: "ホン", romaji: "hon" }],
    kunYomi: [],
    significado: "correr; precipitar-se",
    exemplos: [
      {
        kanji: "奔走",
        furigana: "ほんそう",
        romaji: "hon.sou",
        traducao: "correr; trabalhar intensamente",
      },
      {
        kanji: "奔放",
        furigana: "ほんぽう",
        romaji: "hon.pou",
        traducao: "despreocupado; livre",
      },
      {
        kanji: "奔る",
        furigana: "はしる",
        romaji: "hashiru",
        traducao: "correr; precipitar-se",
      },
    ],
  },
  {
    kanji: "斗",
    onYomi: [{ katakana: "ト", romaji: "to" }],
    kunYomi: [],
    significado: "grande cálice; concha",
    exemplos: [
      {
        kanji: "北斗七星",
        furigana: "ほくとしちせい",
        romaji: "hoku.to.shichi.sei",
        traducao: "sete estrelas da Ursa Maior",
      },
      {
        kanji: "北斗の拳",
        furigana: "ほくとのけん",
        romaji: "hoku.to.no.ken",
        traducao: "Punho da Estrela do Norte",
      },
      {
        kanji: "斗食",
        furigana: "としょく",
        romaji: "to.shoku",
        traducao: "garfo",
      },
    ],
  },
  {
    kanji: "蘭",
    onYomi: [{ katakana: "ラン", romaji: "ran" }],
    kunYomi: [],
    significado: "orqueídea; Holanda",
    exemplos: [
      {
        kanji: "蘭語",
        furigana: "らんご",
        romaji: "ran.go",
        traducao: "idioma holandês",
      },
      {
        kanji: "蘭学",
        furigana: "らんがく",
        romaji: "ran.gaku",
        traducao: "estudos neerlandeses",
      },
      {
        kanji: "蘭人",
        furigana: "らんじん",
        romaji: "ran.jin",
        traducao: "holandês",
      },
    ],
  },
  {
    kanji: "迅",
    onYomi: [{ katakana: "ジン", romaji: "jin" }],
    kunYomi: [],
    significado: "rápido; ágil",
    exemplos: [
      {
        kanji: "迅速",
        furigana: "じんそく",
        romaji: "jin.soku",
        traducao: "rápido; ágil",
      },
      {
        kanji: "迅雷",
        furigana: "じんらい",
        romaji: "jin.rai",
        traducao: "relâmpago",
      },
      {
        kanji: "迅猛",
        furigana: "じんもう",
        romaji: "jin.mou",
        traducao: "rápido; feroz",
      },
    ],
  },
  {
    kanji: "肖",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "semelhança",
    exemplos: [
      {
        kanji: "肖像",
        furigana: "しょうぞう",
        romaji: "shou.zou",
        traducao: "retrato",
      },
      {
        kanji: "肖り",
        furigana: "あやかり",
        romaji: "ayaka.ri",
        traducao: "semelhança",
      },
      {
        kanji: "肖ちる",
        furigana: "あやちる",
        romaji: "ayachi.ru",
        traducao: "assemelhar-se",
      },
    ],
  },
  {
    kanji: "鉢",
    onYomi: [{ katakana: "ハチ", romaji: "hachi" }],
    kunYomi: [],
    significado: "tigela; vaso",
    exemplos: [
      {
        kanji: "花鉢",
        furigana: "はなばち",
        romaji: "hana.bachi",
        traducao: "vaso de flores",
      },
      {
        kanji: "鉢植え",
        furigana: "はちうえ",
        romaji: "hachi.ue",
        traducao: "planta em vaso",
      },
      {
        kanji: "植木鉢",
        furigana: "うえきばち",
        romaji: "ueki.bachi",
        traducao: "vaso de plantas",
      },
    ],
  },
  {
    kanji: "朽",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "く.ちる", romaji: "ku.chiru" }],
    significado: "apodrecer; deteriorar-se",
    exemplos: [
      {
        kanji: "朽ちる",
        furigana: "くちる",
        romaji: "ku.chiru",
        traducao: "apodrecer; deteriorar-se",
      },
      {
        kanji: "朽木",
        furigana: "くちき",
        romaji: "ku.chi.ki",
        traducao: "madeira apodrecida",
      },
      {
        kanji: "朽ち果てる",
        furigana: "くちはてる",
        romaji: "ku.chi.ha.teru",
        traducao: "apodrecer completamente",
      },
    ],
  },
  {
    kanji: "殻",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "から", romaji: "kara" }],
    significado: "casca; concha",
    exemplos: [
      {
        kanji: "殻",
        furigana: "から",
        romaji: "kara",
        traducao: "casca; concha",
      },
      {
        kanji: "貝殻",
        furigana: "かいがら",
        romaji: "kai.gara",
        traducao: "concha",
      },
      {
        kanji: "甲殻類",
        furigana: "こうかくるい",
        romaji: "kou.kaku.rui",
        traducao: "crustáceos",
      },
    ],
  },
  {
    kanji: "享",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [],
    significado: "desfrutar; aproveitar",
    exemplos: [
      {
        kanji: "享受",
        furigana: "きょうじゅ",
        romaji: "kyou.ju",
        traducao: "desfrute; aproveitar",
      },
      {
        kanji: "享楽",
        furigana: "きょうらく",
        romaji: "kyou.raku",
        traducao: "prazer; gozo",
      },
      {
        kanji: "享有",
        furigana: "きょうゆう",
        romaji: "kyou.yuu",
        traducao: "possuir; desfrutar",
      },
    ],
  },
  {
    kanji: "秦",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "estado de Qin (antigo estado chinês)",
    exemplos: [
      {
        kanji: "秦の始皇帝",
        furigana: "しんのしこうてい",
        romaji: "shin.no.shi.kou.tei",
        traducao: "Imperador Qin Shi Huang",
      },
      {
        kanji: "秦時代",
        furigana: "しんじだい",
        romaji: "shin.ji.dai",
        traducao: "Era Qin",
      },
      {
        kanji: "秦国",
        furigana: "しんこく",
        romaji: "shin.koku",
        traducao: "Estado de Qin",
      },
    ],
  },
  {
    kanji: "茅",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "かや", romaji: "kaya" }],
    significado: "capim; palha",
    exemplos: [
      {
        kanji: "茅屋",
        furigana: "かや",
        romaji: "kaya",
        traducao: "casa de palha",
      },
      {
        kanji: "茅葺き屋根",
        furigana: "かやぶきやね",
        romaji: "kaya.buki.ya.ne",
        traducao: "telhado de palha",
      },
      {
        kanji: "茅草",
        furigana: "かやくさ",
        romaji: "kaya.kusa",
        traducao: "capim",
      },
    ],
  },
  {
    kanji: "藩",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [],
    significado: "domínio feudal; clã",
    exemplos: [
      {
        kanji: "藩",
        furigana: "はん",
        romaji: "han",
        traducao: "domínio feudal; clã",
      },
      {
        kanji: "藩主",
        furigana: "はんしゅ",
        romaji: "han.shu",
        traducao: "senhor feudal",
      },
      {
        kanji: "藩政",
        furigana: "はんせい",
        romaji: "han.sei",
        traducao: "governo do domínio feudal",
      },
    ],
  },
  {
    kanji: "沙",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [],
    significado: "areia",
    exemplos: [
      {
        kanji: "砂",
        furigana: "すな",
        romaji: "suna",
        traducao: "areia",
      },
      {
        kanji: "砂漠",
        furigana: "さばく",
        romaji: "saba.ku",
        traducao: "deserto",
      },
      {
        kanji: "砂糖",
        furigana: "さとう",
        romaji: "sa.tou",
        traducao: "açúcar",
      },
    ],
  },
  {
    kanji: "輔",
    onYomi: [{ katakana: "ホ", romaji: "ho" }],
    kunYomi: [{ hiragana: "たす.ける", romaji: "tasu.keru" }],
    significado: "ajudar; apoiar",
    exemplos: [
      {
        kanji: "補輔",
        furigana: "ほほ",
        romaji: "ho.ho",
        traducao: "assistente",
      },
      {
        kanji: "輔助",
        furigana: "ほじょ",
        romaji: "ho.jo",
        traducao: "apoio; assistência",
      },
      {
        kanji: "輔導",
        furigana: "ほどう",
        romaji: "ho.dou",
        traducao: "orientação; aconselhamento",
      },
    ],
  },
  {
    kanji: "媒",
    onYomi: [{ katakana: "バイ", romaji: "bai" }],
    kunYomi: [{ hiragana: "なこ", romaji: "nako" }],
    significado: "casamenteiro; intermediário",
    exemplos: [
      {
        kanji: "仲媒",
        furigana: "なかまい",
        romaji: "naka.mai",
        traducao: "casamenteiro",
      },
      {
        kanji: "媒介",
        furigana: "ばいかい",
        romaji: "bai.kai",
        traducao: "intermediário",
      },
      {
        kanji: "媒体",
        furigana: "ばいたい",
        romaji: "bai.tai",
        traducao: "meio; veículo",
      },
    ],
  },
  {
    kanji: "鶏",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "にわとり", romaji: "niwa.tori" }],
    significado: "galinha",
    exemplos: [
      {
        kanji: "鶏肉",
        furigana: "とりにく",
        romaji: "tori.niku",
        traducao: "carne de frango",
      },
      {
        kanji: "鶏卵",
        furigana: "けいらん",
        romaji: "kei.ran",
        traducao: "ovo de galinha",
      },
      {
        kanji: "鶏料理",
        furigana: "けいりょうり",
        romaji: "kei.ryou.ri",
        traducao: "prato de frango",
      },
    ],
  },
  {
    kanji: "禅",
    onYomi: [{ katakana: "ゼン", romaji: "zen" }],
    kunYomi: [],
    significado: "zen",
    exemplos: [
      {
        kanji: "禅寺",
        furigana: "ぜんでら",
        romaji: "zen.de.ra",
        traducao: "templo zen",
      },
      {
        kanji: "禅定",
        furigana: "ぜんじょう",
        romaji: "zen.jou",
        traducao: "meditação zen",
      },
      {
        kanji: "禅宗",
        furigana: "ぜんしゅう",
        romaji: "zen.shuu",
        traducao: "escola zen",
      },
    ],
  },
  {
    kanji: "嘱",
    onYomi: [{ katakana: "ショク", romaji: "shoku" }],
    kunYomi: [{ hiragana: "しょく", romaji: "shoku" }],
    significado: "instruir; confiar",
    exemplos: [
      {
        kanji: "指嘱",
        furigana: "しじょく",
        romaji: "shi.joku",
        traducao: "instruções",
      },
      {
        kanji: "依嘱",
        furigana: "いしょく",
        romaji: "i.shoku",
        traducao: "sob instrução",
      },
      {
        kanji: "委嘱",
        furigana: "いしょく",
        romaji: "i.shoku",
        traducao: "confiança; comissão",
      },
    ],
  },
  {
    kanji: "胴",
    onYomi: [{ katakana: "ドウ", romaji: "dou" }],
    kunYomi: [],
    significado: "tronco; corpo",
    exemplos: [
      {
        kanji: "胴体",
        furigana: "どうたい",
        romaji: "dou.tai",
        traducao: "corpo; tronco",
      },
      {
        kanji: "胴着",
        furigana: "どうぎ",
        romaji: "dou.gi",
        traducao: "roupa; traje",
      },
      {
        kanji: "胴部",
        furigana: "どうぶ",
        romaji: "dou.bu",
        traducao: "parte do corpo; seção",
      },
    ],
  },
  {
    kanji: "迭",
    onYomi: [{ katakana: "テツ", romaji: "tetsu" }],
    kunYomi: [],
    significado: "alternar; substituir",
    exemplos: [
      {
        kanji: "交迭",
        furigana: "こうてつ",
        romaji: "kou.tetsu",
        traducao: "alternar; intercalar",
      },
      {
        kanji: "迭代",
        furigana: "てつだい",
        romaji: "tetsu.dai",
        traducao: "sucessão; substituição",
      },
      {
        kanji: "代迭",
        furigana: "だいてつ",
        romaji: "dai.tetsu",
        traducao: "alternância; rotação",
      },
    ],
  },
  {
    kanji: "挿",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "さ.す", romaji: "sa.su" }],
    significado: "inserir; enfiar",
    exemplos: [
      {
        kanji: "挿入",
        furigana: "そうにゅう",
        romaji: "sou.nyuu",
        traducao: "inserção",
      },
      {
        kanji: "差し挿入",
        furigana: "さしにゅう",
        romaji: "sashi.nyuu",
        traducao: "inserção",
      },
      {
        kanji: "挿絵",
        furigana: "さしえ",
        romaji: "sashi.e",
        traducao: "ilustração; desenho",
      },
    ],
  },
  {
    kanji: "嵐",
    onYomi: [{ katakana: "ラン", romaji: "ran" }],
    kunYomi: [{ hiragana: "あらし", romaji: "arashi" }],
    significado: "tempestade; vendaval",
    exemplos: [
      {
        kanji: "嵐",
        furigana: "あらし",
        romaji: "arashi",
        traducao: "tempestade; vendaval",
      },
      {
        kanji: "台風嵐",
        furigana: "たいふうあらし",
        romaji: "tai.fuu.a.ra.shi",
        traducao: "tempestade de tufão",
      },
      {
        kanji: "雪嵐",
        furigana: "ゆきあらし",
        romaji: "yuki.a.ra.shi",
        traducao: "tempestade de neve",
      },
    ],
  },
  {
    kanji: "椎",
    onYomi: [{ katakana: "ツイ", romaji: "tsui" }],
    kunYomi: [{ hiragana: "しい", romaji: "shii" }],
    significado: "castanha; madeira de castanheiro",
    exemplos: [
      {
        kanji: "椎茸",
        furigana: "しいたけ",
        romaji: "shii.ta.ke",
        traducao: "cogumelo shiitake",
      },
      {
        kanji: "椎名",
        furigana: "しいな",
        romaji: "shii.na",
        traducao: "nome de família",
      },
      {
        kanji: "椎間板ヘルニア",
        furigana: "ついかんばんヘルニア",
        romaji: "tsui.kan.ban.he.ru.ni.a",
        traducao: "hérnia de disco",
      },
    ],
  },
  {
    kanji: "絹",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "きぬ", romaji: "kinu" }],
    significado: "seda",
    exemplos: [
      {
        kanji: "絹",
        furigana: "きぬ",
        romaji: "kinu",
        traducao: "seda",
      },
      {
        kanji: "絹糸",
        furigana: "けんし",
        romaji: "ken.shi",
        traducao: "fio de seda",
      },
      {
        kanji: "絹布",
        furigana: "けんぷ",
        romaji: "ken.pu",
        traducao: "tecido de seda",
      },
    ],
  },
  {
    kanji: "陪",
    onYomi: [{ katakana: "バイ", romaji: "bai" }],
    kunYomi: [],
    significado: "acompanhar; ser companhia",
    exemplos: [
      {
        kanji: "陪審",
        furigana: "ばいしん",
        romaji: "bai.shin",
        traducao: "júri",
      },
      {
        kanji: "陪席",
        furigana: "ばいせき",
        romaji: "bai.seki",
        traducao: "assento de acompanhante",
      },
      {
        kanji: "陪同",
        furigana: "ばいどう",
        romaji: "bai.dou",
        traducao: "acompanhamento",
      },
    ],
  },
  {
    kanji: "剖",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [],
    significado: "dividir; dissecar",
    exemplos: [
      {
        kanji: "解剖",
        furigana: "かいぼう",
        romaji: "kai.bou",
        traducao: "autópsia; dissecação",
      },
      {
        kanji: "剖検",
        furigana: "ぼうけん",
        romaji: "bou.ken",
        traducao: "exame post-mortem",
      },
      {
        kanji: "剖面図",
        furigana: "ぼうめんず",
        romaji: "bou.men.zu",
        traducao: "corte transversal",
      },
    ],
  },
  {
    kanji: "譜",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [],
    significado: "partitura; genealogia",
    exemplos: [
      {
        kanji: "楽譜",
        furigana: "がくふ",
        romaji: "gaku.fu",
        traducao: "partitura",
      },
      {
        kanji: "家系譜",
        furigana: "かけいふ",
        romaji: "ka.kei.fu",
        traducao: "genealogia; árvore genealógica",
      },
      {
        kanji: "譜面",
        furigana: "ふめん",
        romaji: "fu.men",
        traducao: "pauta",
      },
    ],
  },
  {
    kanji: "郁",
    onYomi: [{ katakana: "イク", romaji: "iku" }],
    kunYomi: [],
    significado: "perfume; aroma",
    exemplos: [
      {
        kanji: "華麗",
        furigana: "かれい",
        romaji: "ka.rei",
        traducao: "esplendor; magnificência",
      },
      {
        kanji: "郁郁",
        furigana: "いくいく",
        romaji: "iku.iku",
        traducao: "perfumado; aromático",
      },
      {
        kanji: "郁文",
        furigana: "いくぶん",
        romaji: "iku.bun",
        traducao: "letra ornamental",
      },
    ],
  },
  {
    kanji: "悠",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [],
    significado: "longínquo; tranquilo",
    exemplos: [
      {
        kanji: "悠々",
        furigana: "ゆうゆう",
        romaji: "yuu.yuu",
        traducao: "calmo; tranquilo",
      },
      {
        kanji: "悠然",
        furigana: "ゆうぜん",
        romaji: "yuu.zen",
        traducao: "sereno; plácido",
      },
      {
        kanji: "悠遊",
        furigana: "ゆうゆう",
        romaji: "yuu.yuu",
        traducao: "lazer; ócio",
      },
    ],
  },
  {
    kanji: "淑",
    onYomi: [{ katakana: "シュク", romaji: "shuku" }],
    kunYomi: [{ hiragana: "しと.やか", romaji: "shito.yaka" }],
    significado: "gentil; refinado",
    exemplos: [
      {
        kanji: "淑女",
        furigana: "しゅくじょ",
        romaji: "shuku.jo",
        traducao: "senhora; dama",
      },
      {
        kanji: "淑やか",
        furigana: "しとやか",
        romaji: "shito.yaka",
        traducao: "gentil; elegante",
      },
      {
        kanji: "淑徳",
        furigana: "しゅくとく",
        romaji: "shuku.toku",
        traducao: "virtude; bondade",
      },
    ],
  },
  {
    kanji: "帆",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "ほ", romaji: "ho" }],
    significado: "vela; vela de barco",
    exemplos: [
      {
        kanji: "帆船",
        furigana: "はんせん",
        romaji: "han.sen",
        traducao: "veleiro",
      },
      {
        kanji: "三角帆",
        furigana: "さんかくはん",
        romaji: "san.kaku.han",
        traducao: "vela triangular",
      },
      {
        kanji: "帆布",
        furigana: "はんぷ",
        romaji: "han.pu",
        traducao: "lona; tecido de vela",
      },
    ],
  },
  {
    kanji: "暁",
    onYomi: [{ katakana: "ギョウ", romaji: "gyou" }],
    kunYomi: [{ hiragana: "あかつき", romaji: "akatsuki" }],
    significado: "amanhecer; madrugada",
    exemplos: [
      {
        kanji: "明暁",
        furigana: "めいぎょう",
        romaji: "mei.gyou",
        traducao: "amanhecer",
      },
      {
        kanji: "暁闇",
        furigana: "あかつきやみ",
        romaji: "akatsuki.yami",
        traducao: "madrugada escura",
      },
      {
        kanji: "暁星",
        furigana: "ぎょうせい",
        romaji: "gyou.sei",
        traducao: "estrela da manhã",
      },
    ],
  },
  {
    kanji: "傑",
    onYomi: [{ katakana: "ケツ", romaji: "ketsu" }],
    kunYomi: [],
    significado: "eminentemente talentoso; excepcional",
    exemplos: [
      {
        kanji: "偉傑",
        furigana: "いけつ",
        romaji: "i.ketsu",
        traducao: "grande talento",
      },
      {
        kanji: "人傑",
        furigana: "じんけつ",
        romaji: "jin.ketsu",
        traducao: "pessoa notável",
      },
      {
        kanji: "英傑",
        furigana: "えいけつ",
        romaji: "ei.ketsu",
        traducao: "herói; grande figura",
      },
    ],
  },
  {
    kanji: "楠",
    onYomi: [{ katakana: "ナン", romaji: "nan" }],
    kunYomi: [{ hiragana: "くす", romaji: "kusu" }],
    significado: "árvore de camphor; camphor tree",
    exemplos: [
      {
        kanji: "楠",
        furigana: "くす",
        romaji: "kusu",
        traducao: "árvore de camphor",
      },
      {
        kanji: "楠林",
        furigana: "なんりん",
        romaji: "nan.rin",
        traducao: "floresta de árvores de camphor",
      },
      {
        kanji: "楠の香り",
        furigana: "くすのかおり",
        romaji: "kusu.no.kaori",
        traducao: "fragrância de camphor",
      },
    ],
  },
  {
    kanji: "笛",
    onYomi: [{ katakana: "テキ", romaji: "teki" }],
    kunYomi: [{ hiragana: "ふえ", romaji: "fue" }],
    significado: "flauta; apito",
    exemplos: [
      {
        kanji: "笛吹き",
        furigana: "ふえふき",
        romaji: "fue.fuki",
        traducao: "flautista",
      },
      {
        kanji: "短笛",
        furigana: "たんてき",
        romaji: "tan.teki",
        traducao: "flauta curta",
      },
      {
        kanji: "笛音",
        furigana: "てきおん",
        romaji: "teki.on",
        traducao: "som da flauta",
      },
    ],
  },
  {
    kanji: "玲",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [],
    significado: "som de cristal; límpido",
    exemplos: [
      {
        kanji: "玲瓏",
        furigana: "れいろう",
        romaji: "rei.rou",
        traducao: "claro; límpido",
      },
      {
        kanji: "玲瓏とした",
        furigana: "れいろうとした",
        romaji: "rei.rou.to.shita",
        traducao: "cristalino; límpido",
      },
      {
        kanji: "玲瓏たる",
        furigana: "れいろうたる",
        romaji: "rei.rou.ta.ru",
        traducao: "cristalino; límpido",
      },
    ],
  },
  {
    kanji: "奴",
    onYomi: [{ katakana: "ド", romaji: "do" }],
    kunYomi: [{ hiragana: "やつ", romaji: "yatsu" }],
    significado: "escravo; sujeito; camarada",
    exemplos: [
      {
        kanji: "奴隷",
        furigana: "どれい",
        romaji: "do.rei",
        traducao: "escravo; escravidão",
      },
      {
        kanji: "悪奴",
        furigana: "あくやつ",
        romaji: "aku.yatsu",
        traducao: "vilão",
      },
      {
        kanji: "仲間奴",
        furigana: "なかまやつ",
        romaji: "nakama.yatsu",
        traducao: "camarada",
      },
    ],
  },
  {
    kanji: "錠",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [],
    significado: "cadeado; fechadura",
    exemplos: [
      {
        kanji: "錠前",
        furigana: "じょうまえ",
        romaji: "jou.mae",
        traducao: "cadeado; fechadura",
      },
      {
        kanji: "錠剤",
        furigana: "じょうざい",
        romaji: "jou.zai",
        traducao: "comprimido",
      },
      {
        kanji: "錠前をかける",
        furigana: "じょうまえをかける",
        romaji: "jou.mae.o.kakeru",
        traducao: "trancar com um cadeado",
      },
    ],
  },
  {
    kanji: "拳",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "こぶし", romaji: "kobushi" }],
    significado: "punho",
    exemplos: [
      {
        kanji: "拳銃",
        furigana: "けんじゅう",
        romaji: "ken.juu",
        traducao: "revólver",
      },
      {
        kanji: "石拳",
        furigana: "いしこぶし",
        romaji: "ishi.kobushi",
        traducao: "soco de pedra",
      },
      {
        kanji: "大拳",
        furigana: "おおぶし",
        romaji: "oo.bushi",
        traducao: "soco poderoso",
      },
    ],
  },
  {
    kanji: "翔",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "voar; planar",
    exemplos: [
      {
        kanji: "翔ぶ",
        furigana: "とぶ",
        romaji: "to.bu",
        traducao: "voar",
      },
      {
        kanji: "飛翔",
        furigana: "ひしょう",
        romaji: "hi.shou",
        traducao: "voo",
      },
      {
        kanji: "翔ける",
        furigana: "かける",
        romaji: "ka.keru",
        traducao: "voar; planar",
      },
    ],
  },
  {
    kanji: "遷",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "うつ.る", romaji: "utsu.ru" }],
    significado: "mover-se; mudar",
    exemplos: [
      {
        kanji: "移遷",
        furigana: "いせん",
        romaji: "i.sen",
        traducao: "migração; mudança",
      },
      {
        kanji: "遷都",
        furigana: "せんと",
        romaji: "sen.to",
        traducao: "mudança da capital",
      },
      {
        kanji: "遷喬",
        furigana: "せんきょう",
        romaji: "sen.kyou",
        traducao: "mudar-se para uma casa nova",
      },
    ],
  },
  {
    kanji: "拙",
    onYomi: [{ katakana: "セツ", romaji: "setsu" }],
    kunYomi: [{ hiragana: "つたな.い", romaji: "tsutana.i" }],
    significado: "desajeitado; tosco",
    exemplos: [
      {
        kanji: "拙作",
        furigana: "せっさく",
        romaji: "setsu.saku",
        traducao: "obra amadora",
      },
      {
        kanji: "拙速",
        furigana: "せっそく",
        romaji: "setsu.soku",
        traducao: "pressa; precipitação",
      },
      {
        kanji: "拙者",
        furigana: "せっしゃ",
        romaji: "setsu.sha",
        traducao: "eu; modestamente",
      },
    ],
  },
  {
    kanji: "侍",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [{ hiragana: "さむらい", romaji: "samurai" }],
    significado: "samurai; guerreiro",
    exemplos: [
      {
        kanji: "侍道",
        furigana: "じどう",
        romaji: "ji.dou",
        traducao: "caminho do samurai",
      },
      {
        kanji: "侍従",
        furigana: "じじゅう",
        romaji: "ji.juu",
        traducao: "assistente; acompanhante",
      },
      {
        kanji: "忠侍",
        furigana: "ちゅうじ",
        romaji: "chuu.ji",
        traducao: "samurai leal",
      },
    ],
  },
  {
    kanji: "尺",
    onYomi: [{ katakana: "シャク", romaji: "shaku" }],
    kunYomi: [],
    significado: "shaku (unidade de medida)",
    exemplos: [
      {
        kanji: "尺度",
        furigana: "しゃくど",
        romaji: "shaku.do",
        traducao: "escala; medida",
      },
      {
        kanji: "尺貫法",
        furigana: "しゃっかんほう",
        romaji: "shaku.kan.hou",
        traducao: "sistema de medida shaku-kan",
      },
      {
        kanji: "尺取橋",
        furigana: "しゃくとりばし",
        romaji: "shaku.tori.hashi",
        traducao: "ponte de medição",
      },
    ],
  },
  {
    kanji: "峠",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "とうげ", romaji: "touge" }],
    significado: "passe; montanha",
    exemplos: [
      {
        kanji: "峠道",
        furigana: "とうげみち",
        romaji: "touge.michi",
        traducao: "estrada de montanha",
      },
      {
        kanji: "峠を越える",
        furigana: "とうげをこえる",
        romaji: "touge.o.koeru",
        traducao: "passar por uma montanha",
      },
      {
        kanji: "峠の景色",
        furigana: "とうげのけしき",
        romaji: "touge.no.keshiki",
        traducao: "paisagem de montanha",
      },
    ],
  },
  {
    kanji: "篤",
    onYomi: [{ katakana: "トク", romaji: "toku" }],
    kunYomi: [{ hiragana: "あつ.い", romaji: "atsu.i" }],
    significado: "sincero; dedicado",
    exemplos: [
      {
        kanji: "熱篤",
        furigana: "ねっとく",
        romaji: "netto.ku",
        traducao: "sinceridade",
      },
      {
        kanji: "篤志",
        furigana: "とくし",
        romaji: "toku.shi",
        traducao: "determinação; devoção",
      },
      {
        kanji: "篤農",
        furigana: "とくのう",
        romaji: "toku.nou",
        traducao: "agricultura intensiva",
      },
    ],
  },
  {
    kanji: "肇",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [],
    significado: "iniciar; originar",
    exemplos: [
      {
        kanji: "創肇",
        furigana: "そうちょう",
        romaji: "sou.chou",
        traducao: "fundador; pioneiro",
      },
      {
        kanji: "開肇",
        furigana: "かいちょう",
        romaji: "kai.chou",
        traducao: "início; fundação",
      },
      {
        kanji: "肇禍",
        furigana: "ちょうか",
        romaji: "chou.ka",
        traducao: "causar desordem",
      },
    ],
  },
  {
    kanji: "渇",
    onYomi: [{ katakana: "カツ", romaji: "katsu" }],
    kunYomi: [{ hiragana: "かわ.く", romaji: "kawa.ku" }],
    significado: "sede; desejo",
    exemplos: [
      {
        kanji: "渇望",
        furigana: "かつぼう",
        romaji: "katsu.bou",
        traducao: "ansiedade; ânsia",
      },
      {
        kanji: "渇水",
        furigana: "かっすい",
        romaji: "katsu.sui",
        traducao: "escassez de água",
      },
      {
        kanji: "渇きを癒す",
        furigana: "かわきをいやす",
        romaji: "kawaki.o.iyasu",
        traducao: "saciar a sede",
      },
    ],
  },
  {
    kanji: "叔",
    onYomi: [{ katakana: "シュク", romaji: "shuku" }],
    kunYomi: [],
    significado: "tio",
    exemplos: [
      {
        kanji: "叔母",
        furigana: "おば",
        romaji: "oba",
        traducao: "tia",
      },
      {
        kanji: "叔父",
        furigana: "おじ",
        romaji: "oji",
        traducao: "tio",
      },
      {
        kanji: "叔父さん",
        furigana: "おじさん",
        romaji: "oji.san",
        traducao: "tio",
      },
    ],
  },
  {
    kanji: "雌",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "めす", romaji: "mesu" }],
    significado: "fêmea",
    exemplos: [
      {
        kanji: "雌犬",
        furigana: "めすいぬ",
        romaji: "mesu.inu",
        traducao: "cadela",
      },
      {
        kanji: "雌鳥",
        furigana: "めすどり",
        romaji: "mesu.dori",
        traducao: "fêmea de pássaro",
      },
      {
        kanji: "雌牛",
        furigana: "めすうし",
        romaji: "mesu.ushi",
        traducao: "vaca",
      },
    ],
  },
  {
    kanji: "亨",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "prosperidade; sucesso",
    exemplos: [
      {
        kanji: "亨通",
        furigana: "こうつう",
        romaji: "kou.tsuu",
        traducao: "prosperidade; sucesso",
      },
      {
        kanji: "利亨",
        furigana: "りこう",
        romaji: "ri.kou",
        traducao: "lucrativo; próspero",
      },
      {
        kanji: "亨利",
        furigana: "こうり",
        romaji: "kou.ri",
        traducao: "lucro; benefício",
      },
    ],
  },
  {
    kanji: "堪",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "た.える", romaji: "ta.eru" }],
    significado: "suportar; aguentar",
    exemplos: [
      {
        kanji: "堪忍",
        furigana: "かんにん",
        romaji: "kan.nin",
        traducao: "paciência; tolerância",
      },
      {
        kanji: "堪能",
        furigana: "たんのう",
        romaji: "tan.nou",
        traducao: "proficiente; hábil",
      },
      {
        kanji: "堪える",
        furigana: "たえる",
        romaji: "taeru",
        traducao: "suportar; aguentar",
      },
    ],
  },
  {
    kanji: "叙",
    onYomi: [{ katakana: "ジョ", romaji: "jo" }],
    kunYomi: [{ hiragana: "つい.ずる", romaji: "tsui.zuru" }],
    significado: "narrar; conceder",
    exemplos: [
      {
        kanji: "叙勲",
        furigana: "じょくん",
        romaji: "jo.kun",
        traducao: "condecoração",
      },
      {
        kanji: "叙情詩",
        furigana: "じょじょうし",
        romaji: "jo.jou.shi",
        traducao: "poesia lírica",
      },
      {
        kanji: "叙述",
        furigana: "じょじゅつ",
        romaji: "jo.jutsu",
        traducao: "narrativa; descrição",
      },
    ],
  },
  {
    kanji: "酢",
    onYomi: [{ katakana: "サク", romaji: "saku" }],
    kunYomi: [],
    significado: "vinagre",
    exemplos: [
      {
        kanji: "酢の物",
        furigana: "すのもの",
        romaji: "su.no.mono",
        traducao: "pratos de vinagre",
      },
      {
        kanji: "酢酸",
        furigana: "さくさん",
        romaji: "saku.san",
        traducao: "ácido acético",
      },
      {
        kanji: "酢豚",
        furigana: "すたび",
        romaji: "su.ta.bi",
        traducao: "carne de porco agridoce",
      },
    ],
  },
  {
    kanji: "吟",
    onYomi: [{ katakana: "ギン", romaji: "gin" }],
    kunYomi: [],
    significado: "recitar; entoar",
    exemplos: [
      {
        kanji: "吟味",
        furigana: "ぎんみ",
        romaji: "gin.mi",
        traducao: "exame minucioso; análise",
      },
      {
        kanji: "吟遊詩人",
        furigana: "ぎんゆうしじん",
        romaji: "gin.yuu.shi.jin",
        traducao: "poeta itinerante",
      },
      {
        kanji: "吟唱",
        furigana: "ぎんしょう",
        romaji: "gin.shou",
        traducao: "recitar; entoar",
      },
    ],
  },
  {
    kanji: "逓",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "transmissão; entrega",
    exemplos: [
      {
        kanji: "逓信",
        furigana: "ていしん",
        romaji: "tei.shin",
        traducao: "correios; serviço postal",
      },
      {
        kanji: "逓減",
        furigana: "ていげん",
        romaji: "tei.gen",
        traducao: "diminuição gradativa",
      },
      {
        kanji: "逓流",
        furigana: "ていりゅう",
        romaji: "tei.ryuu",
        traducao: "fluxo; corrente",
      },
    ],
  },

  {
    kanji: "嶺",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [{ hiragana: "みね", romaji: "mine" }],
    significado: "pico; cume",
    exemplos: [
      {
        kanji: "山嶺",
        furigana: "さんれい",
        romaji: "san.rei",
        traducao: "pico da montanha",
      },
      {
        kanji: "水嶺",
        furigana: "すいれい",
        romaji: "sui.rei",
        traducao: "divisor de águas",
      },
      {
        kanji: "嶺南",
        furigana: "れいなん",
        romaji: "rei.nan",
        traducao: "região sul das montanhas",
      },
    ],
  },
  {
    kanji: "甚",
    onYomi: [{ katakana: "ジン", romaji: "jin" }],
    kunYomi: [{ hiragana: "はなは.だ", romaji: "hanaha.da" }],
    significado: "muito; extremamente",
    exemplos: [
      {
        kanji: "甚大",
        furigana: "じんだい",
        romaji: "jin.dai",
        traducao: "enorme; colossal",
      },
      {
        kanji: "甚だしい",
        furigana: "はなはだしい",
        romaji: "hanahada.shii",
        traducao: "muito; extremamente",
      },
      {
        kanji: "甚だ勿体無い",
        furigana: "はなはだもったいない",
        romaji: "hanahada.mottai.nai",
        traducao: "muito desperdício; uma pena",
      },
    ],
  },
  {
    kanji: "喬",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [],
    significado: "alto; nobre",
    exemplos: [
      {
        kanji: "高喬",
        furigana: "たかたか",
        romaji: "taka.taka",
        traducao: "nobre; digno",
      },
      {
        kanji: "雄喬",
        furigana: "おおたか",
        romaji: "oo.taka",
        traducao: "homem nobre",
      },
      {
        kanji: "喬木",
        furigana: "たかき",
        romaji: "taka.ki",
        traducao: "árvore alta",
      },
    ],
  },
  {
    kanji: "崇",
    onYomi: [{ katakana: "スウ", romaji: "sū" }],
    kunYomi: [{ hiragana: "あが.める", romaji: "aga.meru" }],
    significado: "adoração; reverência",
    exemplos: [
      {
        kanji: "崇敬",
        furigana: "すうけい",
        romaji: "sū.kei",
        traducao: "adoração; reverência",
      },
      {
        kanji: "崇高",
        furigana: "すうこう",
        romaji: "sū.kō",
        traducao: "sublime; nobre",
      },
      {
        kanji: "崇拝",
        furigana: "すうはい",
        romaji: "sū.hai",
        traducao: "adoração; culto",
      },
    ],
  },
  {
    kanji: "漆",
    onYomi: [{ katakana: "シツ", romaji: "shitsu" }],
    kunYomi: [{ hiragana: "うるし", romaji: "urushi" }],
    significado: "laca",
    exemplos: [
      {
        kanji: "漆器",
        furigana: "しっき",
        romaji: "shikki",
        traducao: "artigos de laca",
      },
      {
        kanji: "漆黒",
        furigana: "しっこく",
        romaji: "shikku.roku",
        traducao: "preto brilhante",
      },
      {
        kanji: "漆塗り",
        furigana: "うるしぬり",
        romaji: "urushi.nuri",
        traducao: "lacagem",
      },
    ],
  },
  {
    kanji: "岬",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [{ hiragana: "みさき", romaji: "misaki" }],
    significado: "cabo; promontório",
    exemplos: [
      {
        kanji: "岬町",
        furigana: "みさきちょう",
        romaji: "misaki.chou",
        traducao: "cidade de Misaki",
      },
      {
        kanji: "海岬",
        furigana: "かいがん",
        romaji: "kai.gan",
        traducao: "cabo; promontório marinho",
      },
      {
        kanji: "島の岬",
        furigana: "しまのみさき",
        romaji: "shima.no.misaki",
        traducao: "cabo de uma ilha",
      },
    ],
  },
  {
    kanji: "癖",
    onYomi: [{ katakana: "ヘキ", romaji: "heki" }],
    kunYomi: [{ hiragana: "くせ", romaji: "kuse" }],
    significado: "hábito; vício",
    exemplos: [
      {
        kanji: "癖のある",
        furigana: "くせのある",
        romaji: "kuse.no.aru",
        traducao: "peculiar; excêntrico",
      },
      {
        kanji: "癖になる",
        furigana: "くせになる",
        romaji: "kuse.ni.naru",
        traducao: "tornar-se um hábito",
      },
      {
        kanji: "癖っ毛",
        furigana: "くせっけ",
        romaji: "kusekke",
        traducao: "cabelo rebelde",
      },
    ],
  },
  {
    kanji: "愉",
    onYomi: [{ katakana: "ユ", romaji: "yu" }],
    kunYomi: [{ hiragana: "たの.しい", romaji: "tano.shii" }],
    significado: "prazer; alegria",
    exemplos: [
      {
        kanji: "愉快",
        furigana: "ゆかい",
        romaji: "yu.kai",
        traducao: "alegre; divertido",
      },
      {
        kanji: "愉悦",
        furigana: "ゆえつ",
        romaji: "yu.etsu",
        traducao: "satisfação; prazer",
      },
      {
        kanji: "愉快犯",
        furigana: "ゆかいはん",
        romaji: "yu.kai.han",
        traducao: "delinquente divertido",
      },
    ],
  },
  {
    kanji: "寅",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [{ hiragana: "とら", romaji: "tora" }],
    significado: "tigre",
    exemplos: [
      {
        kanji: "寅年",
        furigana: "とらどし",
        romaji: "tora.doshi",
        traducao: "ano do tigre",
      },
      {
        kanji: "寅壱",
        furigana: "とらいち",
        romaji: "tora.ichi",
        traducao: "primeiro filho",
      },
      {
        kanji: "寅月",
        furigana: "とらづき",
        romaji: "tora.zuki",
        traducao: "mês de tigre",
      },
    ],
  },
  {
    kanji: "礁",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [],
    significado: "recife",
    exemplos: [
      {
        kanji: "珊瑚礁",
        furigana: "さんごしょう",
        romaji: "sango.shō",
        traducao: "recife de coral",
      },
      {
        kanji: "岩礁",
        furigana: "がんしょう",
        romaji: "gan.shō",
        traducao: "rocha no mar",
      },
      {
        kanji: "海底礁",
        furigana: "かいていしょう",
        romaji: "kai.tei.shō",
        traducao: "recife submarino",
      },
    ],
  },
  {
    kanji: "乃",
    onYomi: [{ katakana: "ナイ", romaji: "nai" }],
    kunYomi: [{ hiragana: "の", romaji: "no" }],
    significado: "de; pertencente a",
    exemplos: [
      {
        kanji: "之乎者也",
        furigana: "これものものありなり",
        romaji: "kore.mono.mono.ari.nari",
        traducao: "isto é; de fato",
      },
      {
        kanji: "帝乃弟子",
        furigana: "みかどのでし",
        romaji: "mikado.no.de.shi",
        traducao: "discípulo do imperador",
      },
      {
        kanji: "我乃筆者",
        furigana: "われこのふでしゃ",
        romaji: "ware.kono.fu.de.sha",
        traducao: "eu sou o autor",
      },
    ],
  },
  {
    kanji: "洲",
    onYomi: [{ katakana: "シュウ", romaji: "shū" }],
    kunYomi: [{ hiragana: "しま", romaji: "shima" }],
    significado: "ilha",
    exemplos: [
      {
        kanji: "大陸",
        furigana: "たいりく",
        romaji: "tai.riku",
        traducao: "continente",
      },
      {
        kanji: "本洲",
        furigana: "ほんしゅう",
        romaji: "hon.shū",
        traducao: "ilha principal",
      },
      {
        kanji: "孤島",
        furigana: "ことう",
        romaji: "ko.tō",
        traducao: "ilha isolada",
      },
    ],
  },
  {
    kanji: "屯",
    onYomi: [{ katakana: "トン", romaji: "ton" }],
    kunYomi: [],
    significado: "quartel militar",
    exemplos: [
      {
        kanji: "兵屯",
        furigana: "へいとん",
        romaji: "hei.ton",
        traducao: "acampamento militar",
      },
      {
        kanji: "兵士屯",
        furigana: "へいしとん",
        romaji: "hei.shi.ton",
        traducao: "quartel militar",
      },
      {
        kanji: "衛兵屯",
        furigana: "えいへいとん",
        romaji: "ei.hei.ton",
        traducao: "quartel da guarda",
      },
    ],
  },
  {
    kanji: "樺",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "かば", romaji: "kaba" }],
    significado: "bétula",
    exemplos: [
      {
        kanji: "樺太",
        furigana: "からふと",
        romaji: "ka.ra.fu.to",
        traducao: "ilha Sacalina",
      },
      {
        kanji: "樺木",
        furigana: "かばき",
        romaji: "ka.ba.ki",
        traducao: "bétula",
      },
      {
        kanji: "樺湖",
        furigana: "かばこ",
        romaji: "ka.ba.ko",
        traducao: "lago de bétula",
      },
    ],
  },
  {
    kanji: "槙",
    onYomi: [{ katakana: "テン", romaji: "ten" }],
    kunYomi: [{ hiragana: "まき", romaji: "maki" }],
    significado: "árvore de maki",
    exemplos: [
      {
        kanji: "槙峰",
        furigana: "まきみね",
        romaji: "maki.mine",
        traducao: "montanha Maki",
      },
      {
        kanji: "槙原",
        furigana: "まきはら",
        romaji: "maki.hara",
        traducao: "campo de maki",
      },
      {
        kanji: "槙川",
        furigana: "まきかわ",
        romaji: "maki.kawa",
        traducao: "rio Maki",
      },
    ],
  },
  {
    kanji: "槙",
    onYomi: [{ katakana: "テン", romaji: "ten" }],
    kunYomi: [{ hiragana: "まき", romaji: "maki" }],
    significado: "árvore de maki",
    exemplos: [
      {
        kanji: "槙峰",
        furigana: "まきみね",
        romaji: "maki.mine",
        traducao: "montanha Maki",
      },
      {
        kanji: "槙原",
        furigana: "まきはら",
        romaji: "maki.hara",
        traducao: "campo de maki",
      },
      {
        kanji: "槙川",
        furigana: "まきかわ",
        romaji: "maki.kawa",
        traducao: "rio Maki",
      },
    ],
  },
  {
    kanji: "姻",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [],
    significado: "casamento",
    exemplos: [
      {
        kanji: "姻戚",
        furigana: "いんせき",
        romaji: "in.seki",
        traducao: "parentesco por casamento",
      },
      {
        kanji: "姻族",
        furigana: "いんぞく",
        romaji: "in.zoku",
        traducao: "clã por casamento",
      },
      {
        kanji: "姻戚関係",
        furigana: "いんせきかんけい",
        romaji: "in.seki.kan.kei",
        traducao: "relação de parentesco por casamento",
      },
    ],
  },
  {
    kanji: "巌",
    onYomi: [{ katakana: "ガン", romaji: "gan" }],
    kunYomi: [],
    significado: "rocha; pedra",
    exemplos: [
      {
        kanji: "巌窟王",
        furigana: "がんくつおう",
        romaji: "gan.kutsu.ō",
        traducao: "rei da caverna",
      },
      {
        kanji: "巌流島",
        furigana: "がんりゅうじま",
        romaji: "gan.ryū.jima",
        traducao: "ilha Ganryū",
      },
      {
        kanji: "岩巌",
        furigana: "がんがん",
        romaji: "gan.gan",
        traducao: "rochas íngremes",
      },
    ],
  },
  {
    kanji: "擬",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [{ hiragana: "まが.い", romaji: "maga.i" }],
    significado: "imitação",
    exemplos: [
      {
        kanji: "擬音",
        furigana: "ぎおん",
        romaji: "gi.on",
        traducao: "onomatopeia",
      },
      {
        kanji: "擬態",
        furigana: "ぎたい",
        romaji: "gi.tai",
        traducao: "mimetismo",
      },
      {
        kanji: "擬人化",
        furigana: "ぎじんか",
        romaji: "gi.jin.ka",
        traducao: "personificação",
      },
    ],
  },

  {
    kanji: "睦",
    onYomi: [{ katakana: "ボク", romaji: "boku" }],
    kunYomi: [{ hiragana: "むつ", romaji: "mutsu" }],
    significado: "harmonia; amizade",
    exemplos: [
      {
        kanji: "親睦",
        furigana: "しんぼく",
        romaji: "shin.boku",
        traducao: "amizade",
      },
      {
        kanji: "睦月",
        furigana: "むつき",
        romaji: "mutsu.ki",
        traducao: "janeiro (no antigo calendário japonês)",
      },
      {
        kanji: "睦調",
        furigana: "むつちょう",
        romaji: "mutsu.chō",
        traducao: "harmonia",
      },
    ],
  },
  {
    kanji: "閑",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "しず", romaji: "shizu" }],
    significado: "tranquilo; sossegado",
    exemplos: [
      {
        kanji: "閑静",
        furigana: "かんせい",
        romaji: "kan.sei",
        traducao: "calmo; tranquilo",
      },
      {
        kanji: "閑話",
        furigana: "かんわ",
        romaji: "kan.wa",
        traducao: "conversa casual",
      },
      {
        kanji: "閑散",
        furigana: "かんさん",
        romaji: "kan.san",
        traducao: "deserto; vazio",
      },
    ],
  },
  {
    kanji: "胡",
    onYomi: [{ katakana: "ウ", romaji: "u" }],
    kunYomi: [{ hiragana: "えびす", romaji: "ebisu" }],
    significado: "bárbaro",
    exemplos: [
      {
        kanji: "胡散",
        furigana: "うさん",
        romaji: "u.san",
        traducao: "suspeito; duvidoso",
      },
      {
        kanji: "胡椒",
        furigana: "こしょう",
        romaji: "ko.shō",
        traducao: "pimenta",
      },
      {
        kanji: "胡麻",
        furigana: "ごま",
        romaji: "go.ma",
        traducao: "gergelim",
      },
    ],
  },
  {
    kanji: "幽",
    onYomi: [{ katakana: "ユウ", romaji: "yū" }],
    kunYomi: [{ hiragana: "かす", romaji: "kasu" }],
    significado: "tranquilo; sereno",
    exemplos: [
      {
        kanji: "幽霊",
        furigana: "ゆうれい",
        romaji: "yū.rei",
        traducao: "fantasma",
      },
      {
        kanji: "幽玄",
        furigana: "ゆうげん",
        romaji: "yū.gen",
        traducao: "profundo; misterioso",
      },
      {
        kanji: "幽閉",
        furigana: "ゆうへい",
        romaji: "yū.hei",
        traducao: "prisão; confinamento",
      },
    ],
  },
  {
    kanji: "峻",
    onYomi: [{ katakana: "シュン", romaji: "shun" }],
    kunYomi: [],
    significado: "alto; íngreme",
    exemplos: [
      {
        kanji: "峻険",
        furigana: "しゅんけん",
        romaji: "shun.ken",
        traducao: "íngreme; escarpado",
      },
      {
        kanji: "峻石",
        furigana: "しゅんせき",
        romaji: "shun.seki",
        traducao: "rocha íngreme",
      },
      {
        kanji: "峻別",
        furigana: "しゅんべつ",
        romaji: "shun.betsu",
        traducao: "separação severa",
      },
    ],
  },
  {
    kanji: "曹",
    onYomi: [{ katakana: "ソウ", romaji: "sō" }],
    kunYomi: [],
    significado: "subordinado",
    exemplos: [
      {
        kanji: "曹長",
        furigana: "そうちょう",
        romaji: "sō.chō",
        traducao: "sargento",
      },
      {
        kanji: "曹洞宗",
        furigana: "そうとうしゅう",
        romaji: "sō.tō.shū",
        traducao: "Escola Soto Zen",
      },
      {
        kanji: "曹達",
        furigana: "そうたつ",
        romaji: "sō.tatsu",
        traducao: "subordinados",
      },
    ],
  },
  {
    kanji: "詠",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [{ hiragana: "よ.む", romaji: "yo.mu" }],
    significado: "recitar; cantar",
    exemplos: [
      {
        kanji: "詠嘆",
        furigana: "えいたん",
        romaji: "ei.tan",
        traducao: "admiração",
      },
      {
        kanji: "詠唱",
        furigana: "えいしょう",
        romaji: "ei.shō",
        traducao: "recitação",
      },
      {
        kanji: "詠春拳",
        furigana: "えいしゅんけん",
        romaji: "ei.shun.ken",
        traducao: "Wing Chun (arte marcial)",
      },
    ],
  },
  {
    kanji: "塀",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [{ hiragana: "へい", romaji: "hei" }],
    significado: "muro; cerca",
    exemplos: [
      {
        kanji: "塀外",
        furigana: "へいがい",
        romaji: "hei.gai",
        traducao: "fora do muro",
      },
      {
        kanji: "塀の中",
        furigana: "へいのなか",
        romaji: "hei.no.naka",
        traducao: "dentro do muro",
      },
      {
        kanji: "塀の上",
        furigana: "へいのうえ",
        romaji: "hei.no.ue",
        traducao: "em cima do muro",
      },
    ],
  },
  {
    kanji: "唇",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "くちびる", romaji: "kuchibiru" }],
    significado: "lábios",
    exemplos: [
      {
        kanji: "上唇",
        furigana: "じょうしん",
        romaji: "jō.shin",
        traducao: "lábio superior",
      },
      {
        kanji: "下唇",
        furigana: "かしん",
        romaji: "ka.shin",
        traducao: "lábio inferior",
      },
      {
        kanji: "唇紅",
        furigana: "くちべに",
        romaji: "kuchibeni",
        traducao: "batom",
      },
    ],
  },
  {
    kanji: "卑",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "いや.し", romaji: "iya.shii" }],
    significado: "baixo; desprezível",
    exemplos: [
      {
        kanji: "卑怯",
        furigana: "ひきょう",
        romaji: "hi.kyō",
        traducao: "covardia",
      },
      {
        kanji: "卑下",
        furigana: "ひげ",
        romaji: "hi.ge",
        traducao: "humildade",
      },
      {
        kanji: "卑劣",
        furigana: "ひれつ",
        romaji: "hi.retsu",
        traducao: "baixeza",
      },
    ],
  },
  {
    kanji: "侮",
    onYomi: [{ katakana: "ブ", romaji: "bu" }],
    kunYomi: [{ hiragana: "あなど.る", romaji: "anado.ru" }],
    significado: "desprezar; menosprezar",
    exemplos: [
      {
        kanji: "侮辱",
        furigana: "ぶじょく",
        romaji: "bu.joku",
        traducao: "insulto",
      },
      {
        kanji: "侮蔑",
        furigana: "ぶべつ",
        romaji: "bu.betsu",
        traducao: "desprezo",
      },
      {
        kanji: "侮視",
        furigana: "ぶし",
        romaji: "bu.shi",
        traducao: "desprezo",
      },
    ],
  },
  {
    kanji: "鋳",
    onYomi: [{ katakana: "チュウ", romaji: "chū" }],
    kunYomi: [{ hiragana: "い.る", romaji: "i.ru" }],
    significado: "fundir; lançar (um molde)",
    exemplos: [
      {
        kanji: "鋳造",
        furigana: "ちゅうぞう",
        romaji: "chū.zō",
        traducao: "fundição",
      },
      {
        kanji: "鋳鉄",
        furigana: "ちゅうてつ",
        romaji: "chū.tetsu",
        traducao: "ferro fundido",
      },
      {
        kanji: "鋳物",
        furigana: "ちゅうもの",
        romaji: "chū.mo.no",
        traducao: "fundição",
      },
    ],
  },
  {
    kanji: "抹",
    onYomi: [{ katakana: "マツ", romaji: "matsu" }],
    kunYomi: [{ hiragana: "ま.する", romaji: "ma.suru" }],
    significado: "esfregar; apagar",
    exemplos: [
      {
        kanji: "抹消",
        furigana: "まっしょう",
        romaji: "mas.shō",
        traducao: "cancelamento",
      },
      {
        kanji: "抹茶",
        furigana: "まっちゃ",
        romaji: "ma.tcha",
        traducao: "chá verde em pó",
      },
      {
        kanji: "抹殺",
        furigana: "まっさつ",
        romaji: "mas.satsu",
        traducao: "eliminação",
      },
    ],
  },
  {
    kanji: "尉",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [],
    significado: "oficial (militar); sargento",
    exemplos: [
      {
        kanji: "尉官",
        furigana: "いかん",
        romaji: "i.kan",
        traducao: "oficial",
      },
      {
        kanji: "少尉",
        furigana: "しょうい",
        romaji: "shō.i",
        traducao: "tenente",
      },
      {
        kanji: "警察尉",
        furigana: "けいさつい",
        romaji: "kei.satsu.i",
        traducao: "sargento de polícia",
      },
    ],
  },
  {
    kanji: "槻",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "árvore de hackberry",
    exemplos: [
      {
        kanji: "槻の木",
        furigana: "つきのき",
        romaji: "tsuki.no.ki",
        traducao: "árvore de hackberry",
      },
      {
        kanji: "槻の実",
        furigana: "つきのみ",
        romaji: "tsuki.no.mi",
        traducao: "fruto de hackberry",
      },
    ],
  },
  {
    kanji: "隷",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [],
    significado: "escravo; servo",
    exemplos: [
      {
        kanji: "隷属",
        furigana: "れいぞく",
        romaji: "rei.zoku",
        traducao: "subordinação",
      },
      {
        kanji: "奴隷",
        furigana: "どれい",
        romaji: "do.rei",
        traducao: "escravo",
      },
      {
        kanji: "隷従",
        furigana: "れいじゅう",
        romaji: "rei.jū",
        traducao: "servidão",
      },
    ],
  },
  {
    kanji: "禍",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "わざわ.い", romaji: "wazawa.i" }],
    significado: "desastre; calamidade",
    exemplos: [
      {
        kanji: "禍根",
        furigana: "かこん",
        romaji: "ka.kon",
        traducao: "causa de problemas",
      },
      {
        kanji: "災禍",
        furigana: "さいか",
        romaji: "sai.ka",
        traducao: "desastre",
      },
      {
        kanji: "禍々しい",
        furigana: "わざわざしい",
        romaji: "wazawa.zashii",
        traducao: "sinistro",
      },
    ],
  },
  {
    kanji: "蝶",
    onYomi: [{ katakana: "チョウ", romaji: "chō" }],
    kunYomi: [{ hiragana: "ちょう", romaji: "chō" }],
    significado: "borboleta",
    exemplos: [
      {
        kanji: "蝶舞",
        furigana: "ちょうぶ",
        romaji: "chō.bu",
        traducao: "dança da borboleta",
      },
      {
        kanji: "蝶々",
        furigana: "ちょうちょう",
        romaji: "chō.chō",
        traducao: "borboleta",
      },
      {
        kanji: "蝶の羽",
        furigana: "ちょうのは",
        romaji: "chō.no.ha",
        traducao: "asa de borboleta",
      },
    ],
  },
  {
    kanji: "酪",
    onYomi: [{ katakana: "ラク", romaji: "raku" }],
    kunYomi: [],
    significado: "leite; laticínio",
    exemplos: [
      {
        kanji: "酪農",
        furigana: "らくのう",
        romaji: "raku.nō",
        traducao: "produção de laticínios",
      },
      {
        kanji: "酪酸",
        furigana: "らくさん",
        romaji: "raku.san",
        traducao: "ácido láctico",
      },
      {
        kanji: "酪製品",
        furigana: "らくせいひん",
        romaji: "raku.sei.hin",
        traducao: "produtos lácteos",
      },
    ],
  },
  {
    kanji: "茎",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "くき", romaji: "kuki" }],
    significado: "caule; talo",
    exemplos: [
      {
        kanji: "茎葉",
        furigana: "くきば",
        romaji: "kuki.ba",
        traducao: "hastes e folhas",
      },
      {
        kanji: "花茎",
        furigana: "かけい",
        romaji: "ka.kei",
        traducao: "haste floral",
      },
      {
        kanji: "茎の先",
        furigana: "くきのさき",
        romaji: "kuki.no.saki",
        traducao: "ponta do caule",
      },
    ],
  },
  {
    kanji: "帥",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [],
    significado: "líder; comandante",
    exemplos: [
      {
        kanji: "元帥",
        furigana: "げんすい",
        romaji: "gen.sui",
        traducao: "marechal",
      },
      {
        kanji: "帥府",
        furigana: "すいふ",
        romaji: "sui.fu",
        traducao: "quartel-general",
      },
      {
        kanji: "帥印",
        furigana: "すいいん",
        romaji: "sui.in",
        traducao: "selo oficial",
      },
    ],
  },
  {
    kanji: "逝",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "ゆ.く", romaji: "yu.ku" }],
    significado: "partir; falecer",
    exemplos: [
      {
        kanji: "死逝",
        furigana: "しせい",
        romaji: "shi.sei",
        traducao: "passamento",
      },
      {
        kanji: "逝去",
        furigana: "せいきょ",
        romaji: "sei.kyo",
        traducao: "falecimento",
      },
      {
        kanji: "逝去日",
        furigana: "せいきょび",
        romaji: "sei.kyo.bi",
        traducao: "data de falecimento",
      },
    ],
  },
  {
    kanji: "汽",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "vapor; fumaça",
    exemplos: [
      {
        kanji: "汽車",
        furigana: "きしゃ",
        romaji: "ki.sha",
        traducao: "trem a vapor",
      },
      {
        kanji: "汽船",
        furigana: "きせん",
        romaji: "ki.sen",
        traducao: "barco a vapor",
      },
      {
        kanji: "汽笛",
        furigana: "きてき",
        romaji: "ki.teki",
        traducao: "apito a vapor",
      },
    ],
  },
  {
    kanji: "琢",
    onYomi: [{ katakana: "タク", romaji: "taku" }],
    kunYomi: [],
    significado: "lapidação; polimento",
    exemplos: [
      {
        kanji: "磨琢",
        furigana: "またく",
        romaji: "ma.taku",
        traducao: "polimento",
      },
      {
        kanji: "琢磨",
        furigana: "たくま",
        romaji: "taku.ma",
        traducao: "cultivo; refinamento",
      },
      {
        kanji: "進取の琢磨",
        furigana: "しんしゅのたくま",
        romaji: "shin.shu.no.taku.ma",
        traducao: "cultivo de progresso",
      },
    ],
  },
  {
    kanji: "匿",
    onYomi: [{ katakana: "トク", romaji: "toku" }],
    kunYomi: [{ hiragana: "かくま.う", romaji: "kakuma.u" }],
    significado: "esconder; ocultar",
    exemplos: [
      {
        kanji: "匿名",
        furigana: "とくめい",
        romaji: "to.ku.mei",
        traducao: "anônimo",
      },
      {
        kanji: "隠匿",
        furigana: "いんとく",
        romaji: "in.toku",
        traducao: "esconderijo",
      },
      {
        kanji: "逃亡匿",
        furigana: "とうぼうとく",
        romaji: "tō.bō.toku",
        traducao: "fuga e esconderijo",
      },
    ],
  },
  {
    kanji: "襟",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [{ hiragana: "えり", romaji: "eri" }],
    significado: "gola; colarinho",
    exemplos: [
      {
        kanji: "襟巻",
        furigana: "えりまき",
        romaji: "eri.maki",
        traducao: "cachecol",
      },
      {
        kanji: "首襟",
        furigana: "しゅきん",
        romaji: "shu.kin",
        traducao: "gola",
      },
      {
        kanji: "襟元",
        furigana: "えりもと",
        romaji: "eri.moto",
        traducao: "colarinho",
      },
    ],
  },
  {
    kanji: "蛍",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "ほたる", romaji: "hotaru" }],
    significado: "vaga-lume",
    exemplos: [
      {
        kanji: "蛍光灯",
        furigana: "けいこうとう",
        romaji: "kei.kō.tō",
        traducao: "lâmpada fluorescente",
      },
      {
        kanji: "蛍狩り",
        furigana: "ほたるがり",
        romaji: "hotaru.gari",
        traducao: "caça aos vagalumes",
      },
      {
        kanji: "蛍火",
        furigana: "ほたるび",
        romaji: "hotaru.bi",
        traducao: "luz de vaga-lume",
      },
    ],
  },
  {
    kanji: "蕉",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [{ hiragana: "ばな", romaji: "bana" }],
    significado: "bananeira",
    exemplos: [
      {
        kanji: "蕉庭",
        furigana: "しょうてい",
        romaji: "shō.tei",
        traducao: "jardim de bananeiras",
      },
      {
        kanji: "蕉の葉",
        furigana: "しょうのは",
        romaji: "shō.no.ha",
        traducao: "folha de bananeira",
      },
      {
        kanji: "蕉組",
        furigana: "しょうぐみ",
        romaji: "shō.gumi",
        traducao: "grupo de estudantes",
      },
    ],
  },
  {
    kanji: "寡",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [],
    significado: "poucos; pouca frequência",
    exemplos: [
      {
        kanji: "寡婦",
        furigana: "かふ",
        romaji: "ka.fu",
        traducao: "viúva",
      },
      {
        kanji: "寡黙",
        furigana: "かもく",
        romaji: "ka.moku",
        traducao: "poucas palavras",
      },
      {
        kanji: "寡言",
        furigana: "かげん",
        romaji: "ka.gen",
        traducao: "poucas palavras",
      },
    ],
  },
  {
    kanji: "琉",
    onYomi: [{ katakana: "リュウ", romaji: "ryū" }],
    kunYomi: [],
    significado: "lápis-lazúli",
    exemplos: [
      {
        kanji: "琉球",
        furigana: "りゅうきゅう",
        romaji: "ryū.kyū",
        traducao: "Ryukyu",
      },
      {
        kanji: "琉璃",
        furigana: "るり",
        romaji: "ru.ri",
        traducao: "lápis-lazúli",
      },
      {
        kanji: "琉璃玉",
        furigana: "るりだま",
        romaji: "ru.ri.da.ma",
        traducao: "conta de lápis-lazúli",
      },
    ],
  },
  {
    kanji: "痢",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [],
    significado: "disenteria",
    exemplos: [
      {
        kanji: "痢疾",
        furigana: "りしつ",
        romaji: "ri.shitsu",
        traducao: "disenteria",
      },
      {
        kanji: "赤痢",
        furigana: "せきり",
        romaji: "se.ki.ri",
        traducao: "disenteria vermelha",
      },
      {
        kanji: "急性痢疾",
        furigana: "きゅうせいりしつ",
        romaji: "kyū.sei.ri.shitsu",
        traducao: "disenteria aguda",
      },
    ],
  },
  {
    kanji: "庸",
    onYomi: [{ katakana: "ヨウ", romaji: "yō" }],
    kunYomi: [],
    significado: "comum; medíocre",
    exemplos: [
      {
        kanji: "庸人",
        furigana: "ようじん",
        romaji: "yō.jin",
        traducao: "pessoa comum",
      },
      {
        kanji: "庸医",
        furigana: "ようい",
        romaji: "yō.i",
        traducao: "médico medíocre",
      },
      {
        kanji: "庸脳",
        furigana: "ようのう",
        romaji: "yō.nō",
        traducao: "mente medíocre",
      },
    ],
  },
  {
    kanji: "朋",
    onYomi: [{ katakana: "ホウ", romaji: "hō" }],
    kunYomi: [{ hiragana: "とも", romaji: "tomo" }],
    significado: "amigo; companheiro",
    exemplos: [
      {
        kanji: "友朋",
        furigana: "ゆうほう",
        romaji: "yū.hō",
        traducao: "amigos",
      },
      {
        kanji: "朋友",
        furigana: "ほうゆう",
        romaji: "hō.yū",
        traducao: "amigo",
      },
      {
        kanji: "同朋",
        furigana: "どうほう",
        romaji: "dō.hō",
        traducao: "amigo",
      },
    ],
  },
  {
    kanji: "坑",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [],
    significado: "mina; cova",
    exemplos: [
      {
        kanji: "坑道",
        furigana: "こうどう",
        romaji: "kō.dō",
        traducao: "túnel de mina",
      },
      {
        kanji: "炭坑",
        furigana: "たんこう",
        romaji: "tan.kō",
        traducao: "mina de carvão",
      },
      {
        kanji: "坑内",
        furigana: "こうない",
        romaji: "kō.nai",
        traducao: "interior da mina",
      },
    ],
  },
  {
    kanji: "藍",
    onYomi: [{ katakana: "ラン", romaji: "ran" }],
    kunYomi: [],
    significado: "índigo",
    exemplos: [
      {
        kanji: "藍染",
        furigana: "あいぞめ",
        romaji: "ai.zome",
        traducao: "tingimento de índigo",
      },
      {
        kanji: "藍色",
        furigana: "あいいろ",
        romaji: "ai.iro",
        traducao: "cor azul-índigo",
      },
      {
        kanji: "藍染めの着物",
        furigana: "あいぞめのきもの",
        romaji: "ai.zome.no.kimono",
        traducao: "quimono tingido de índigo",
      },
    ],
  },
  {
    kanji: "賊",
    onYomi: [{ katakana: "ゾク", romaji: "zoku" }],
    kunYomi: [],
    significado: "ladrão; criminoso",
    exemplos: [
      {
        kanji: "海賊",
        furigana: "かいぞく",
        romaji: "kai.zoku",
        traducao: "pirata",
      },
      {
        kanji: "盗賊",
        furigana: "とうぞく",
        romaji: "tō.zoku",
        traducao: "ladrão",
      },
      {
        kanji: "賊軍",
        furigana: "ぞくぐん",
        romaji: "zoku.gun",
        traducao: "exército inimigo",
      },
    ],
  },
  {
    kanji: "搾",
    onYomi: [{ katakana: "サク", romaji: "saku" }],
    kunYomi: [{ hiragana: "しぼ.る", romaji: "shibo.ru" }],
    significado: "espremer; extrair",
    exemplos: [
      {
        kanji: "搾取",
        furigana: "さくしゅ",
        romaji: "sa.ku.shu",
        traducao: "exploração",
      },
      {
        kanji: "果汁搾り",
        furigana: "かじゅうしぼり",
        romaji: "ka.jū.shi.bo.ri",
        traducao: "espremedor de suco",
      },
      {
        kanji: "搾りたてのジュース",
        furigana: "しぼりたてのじゅーす",
        romaji: "shi.bo.ri.ta.te.no.jū.su",
        traducao: "suco recém-espremido",
      },
    ],
  },
  {
    kanji: "畔",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "くろ", romaji: "kuro" }],
    significado: "beira; margem",
    exemplos: [
      {
        kanji: "川畔",
        furigana: "かわべ",
        romaji: "kawa.be",
        traducao: "beira do rio",
      },
      {
        kanji: "海畔",
        furigana: "かいはん",
        romaji: "kai.han",
        traducao: "beira-mar",
      },
      {
        kanji: "畔道",
        furigana: "くろみち",
        romaji: "kuro.michi",
        traducao: "caminho ao longo da margem",
      },
    ],
  },
  {
    kanji: "遼",
    onYomi: [{ katakana: "リョウ", romaji: "ryō" }],
    kunYomi: [],
    significado: "distante",
    exemplos: [
      {
        kanji: "遼遠",
        furigana: "りょうえん",
        romaji: "ryō.en",
        traducao: "distante",
      },
      {
        kanji: "遼河",
        furigana: "りょうが",
        romaji: "ryō.ga",
        traducao: "rio Liao",
      },
      {
        kanji: "遼やかな風景",
        furigana: "りょうやかなふうけい",
        romaji: "ryō.ya.ka.na.fū.kei",
        traducao: "paisagem distante",
      },
    ],
  },
  {
    kanji: "唄",
    onYomi: [{ katakana: "バイ", romaji: "bai" }],
    kunYomi: [{ hiragana: "うた.う", romaji: "uta.u" }],
    significado: "canção; música",
    exemplos: [
      {
        kanji: "唄う",
        furigana: "うたう",
        romaji: "uta.u",
        traducao: "cantar",
      },
      {
        kanji: "民謡",
        furigana: "みんよう",
        romaji: "min.yō",
        traducao: "canção folclórica",
      },
      {
        kanji: "唄い手",
        furigana: "うたいて",
        romaji: "uta.i.te",
        traducao: "cantor",
      },
    ],
  },
  {
    kanji: "孔",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [],
    significado: "cavidade; orifício",
    exemplos: [
      {
        kanji: "孔明",
        furigana: "こうめい",
        romaji: "kō.mei",
        traducao: "Kongming",
      },
      {
        kanji: "鼻孔",
        furigana: "びこう",
        romaji: "bi.kō",
        traducao: "narina",
      },
      {
        kanji: "音孔",
        furigana: "おんこう",
        romaji: "on.kō",
        traducao: "orifício sonoro",
      },
    ],
  },
  {
    kanji: "橘",
    onYomi: [{ katakana: "キツ", romaji: "kitsu" }],
    kunYomi: [{ hiragana: "たちばな", romaji: "tachibana" }],
    significado: "árvore de tangerina",
    exemplos: [
      {
        kanji: "橘",
        furigana: "たちばな",
        romaji: "ta.chi.ba.na",
        traducao: "árvore de tangerina",
      },
      {
        kanji: "橘色",
        furigana: "たちばないろ",
        romaji: "ta.chi.ba.na.i.ro",
        traducao: "cor de tangerina",
      },
      {
        kanji: "橘の実",
        furigana: "たちばなのみ",
        romaji: "ta.chi.ba.na.no.mi",
        traducao: "fruto de tangerina",
      },
    ],
  },
  {
    kanji: "漱",
    onYomi: [{ katakana: "ソウ", romaji: "sō" }],
    kunYomi: [{ hiragana: "くちすす.ぐ", romaji: "kuchisusu.gu" }],
    significado: "enxaguar a boca; bochecho",
    exemplos: [
      {
        kanji: "歯を漱ぐ",
        furigana: "はをくちすすぐ",
        romaji: "ha o kuchisusugu",
        traducao: "enxaguar a boca",
      },
      {
        kanji: "漱石",
        furigana: "そうせき",
        romaji: "sō.seki",
        traducao: "enxaguante bucal",
      },
      {
        kanji: "漱ぎ口",
        furigana: "くちすすぎ",
        romaji: "kuchisusugi",
        traducao: "bochecho",
      },
    ],
  },
  {
    kanji: "呂",
    onYomi: [{ katakana: "ロ", romaji: "ro" }],
    kunYomi: [],
    significado: "banho; casa de banho",
    exemplos: [
      {
        kanji: "風呂",
        furigana: "ふろ",
        romaji: "furo",
        traducao: "banho",
      },
      {
        kanji: "温泉",
        furigana: "おんせん",
        romaji: "onsen",
        traducao: "fonte termal",
      },
      {
        kanji: "風呂場",
        furigana: "ふろば",
        romaji: "furoba",
        traducao: "banheiro",
      },
    ],
  },
  {
    kanji: "拷",
    onYomi: [{ katakana: "ゴウ", romaji: "gō" }],
    kunYomi: [],
    significado: "tortura",
    exemplos: [
      {
        kanji: "拷問",
        furigana: "ごうもん",
        romaji: "gō.mon",
        traducao: "tortura",
      },
      {
        kanji: "拷問台",
        furigana: "ごうもんだい",
        romaji: "gō.mon.dai",
        traducao: "instrumento de tortura",
      },
      {
        kanji: "非拷問",
        furigana: "ひごうもん",
        romaji: "hi.gō.mon",
        traducao: "não-tortura",
      },
    ],
  },
  {
    kanji: "嬢",
    onYomi: [{ katakana: "ジョウ", romaji: "jō" }],
    kunYomi: [],
    significado: "moça; jovem mulher",
    exemplos: [
      {
        kanji: "令嬢",
        furigana: "れいじょう",
        romaji: "rei.jō",
        traducao: "jovem dama",
      },
      {
        kanji: "ホステス嬢",
        furigana: "ほすてすじょう",
        romaji: "hosutesu.jō",
        traducao: "anfitriã",
      },
      {
        kanji: "嬢学生",
        furigana: "じょうがくせい",
        romaji: "jō.gakusei",
        traducao: "estudante universitária",
      },
    ],
  },
  {
    kanji: "苑",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [],
    significado: "jardim; parque",
    exemplos: [
      {
        kanji: "植物園",
        furigana: "しょくぶつえん",
        romaji: "shoku.butsu.en",
        traducao: "jardim botânico",
      },
      {
        kanji: "動物園",
        furigana: "どうぶつえん",
        romaji: "dō.butsu.en",
        traducao: "zoológico",
      },
      {
        kanji: "苑前駅",
        furigana: "えんぜんえき",
        romaji: "enzen.eki",
        traducao: "estação Enzen",
      },
    ],
  },
  {
    kanji: "巽",
    onYomi: [{ katakana: "ソン", romaji: "son" }],
    kunYomi: [],
    significado: "leste; sudeste; vento sul",
    exemplos: [
      {
        kanji: "南巽",
        furigana: "なんそん",
        romaji: "nan.son",
        traducao: "sul",
      },
      {
        kanji: "巽風",
        furigana: "そんぷう",
        romaji: "son.fū",
        traducao: "vento sul",
      },
      {
        kanji: "巽宮",
        furigana: "そんきゅう",
        romaji: "son.kyū",
        traducao: "sudeste",
      },
    ],
  },
  {
    kanji: "杜",
    onYomi: [{ katakana: "ト", romaji: "to" }],
    kunYomi: [{ hiragana: "もり", romaji: "mori" }],
    significado: "floresta; bosque",
    exemplos: [
      {
        kanji: "杜の都",
        furigana: "もりのみやこ",
        romaji: "mori.no.miya.ko",
        traducao: "Tóquio",
      },
      {
        kanji: "杜撰",
        furigana: "ずさん",
        romaji: "zu.san",
        traducao: "descuidado",
      },
      {
        kanji: "杜宇",
        furigana: "とう",
        romaji: "tō",
        traducao: "andorinha",
      },
    ],
  },
  {
    kanji: "渓",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "たに", romaji: "tani" }],
    significado: "vale; desfiladeiro",
    exemplos: [
      {
        kanji: "渓谷",
        furigana: "けいこく",
        romaji: "kei.koku",
        traducao: "vale",
      },
      {
        kanji: "渓流",
        furigana: "けいりゅう",
        romaji: "kei.ryū",
        traducao: "corrente de rio",
      },
      {
        kanji: "渓魚",
        furigana: "けいぎょ",
        romaji: "kei.gyo",
        traducao: "peixe de rio",
      },
    ],
  },
  {
    kanji: "翁",
    onYomi: [{ katakana: "オウ", romaji: "ō" }],
    kunYomi: [],
    significado: "homem mais velho; ancião",
    exemplos: [
      {
        kanji: "老翁",
        furigana: "ろうおう",
        romaji: "rō.ō",
        traducao: "homem idoso",
      },
      {
        kanji: "翁長",
        furigana: "おきなが",
        romaji: "oki.naga",
        traducao: "Okinaga",
      },
      {
        kanji: "翁鳥",
        furigana: "おきなどり",
        romaji: "oki.na.do.ri",
        traducao: "pássaro idoso",
      },
    ],
  },
  {
    kanji: "廉",
    onYomi: [{ katakana: "レン", romaji: "ren" }],
    kunYomi: [],
    significado: "retidão; honestidade; pureza",
    exemplos: [
      {
        kanji: "廉価",
        furigana: "れんか",
        romaji: "ren.ka",
        traducao: "preço baixo",
      },
      {
        kanji: "廉洁",
        furigana: "れんけつ",
        romaji: "ren.ketsu",
        traducao: "integridade",
      },
      {
        kanji: "清廉",
        furigana: "せいれん",
        romaji: "sei.ren",
        traducao: "pureza",
      },
    ],
  },
  {
    kanji: "謹",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [],
    significado: "respeito; cautela",
    exemplos: [
      {
        kanji: "謹賀新年",
        furigana: "きんがしんねん",
        romaji: "kin.ga.shin.nen",
        traducao: "Feliz Ano Novo",
      },
      {
        kanji: "謹慎",
        furigana: "きんしん",
        romaji: "kin.shin",
        traducao: "prudência",
      },
      {
        kanji: "謹呈",
        furigana: "きんてい",
        romaji: "kin.tei",
        traducao: "apresentação respeitosa",
      },
    ],
  },
  {
    kanji: "瞳",
    onYomi: [{ katakana: "ドウ", romaji: "dō" }],
    kunYomi: [{ hiragana: "ひとみ", romaji: "hitomi" }],
    significado: "pupila; olho",
    exemplos: [
      {
        kanji: "瞳孔",
        furigana: "どうこう",
        romaji: "dō.kō",
        traducao: "pupila",
      },
      {
        kanji: "瞳色",
        furigana: "ひとみいろ",
        romaji: "hitomi.iro",
        traducao: "cor dos olhos",
      },
      {
        kanji: "瞳の奥",
        furigana: "ひとみのおく",
        romaji: "hitomi.no.oku",
        traducao: "profundidade dos olhos",
      },
    ],
  },
  {
    kanji: "湧",
    onYomi: [{ katakana: "ユウ", romaji: "yū" }],
    kunYomi: [{ hiragana: "わ.く", romaji: "wa.ku" }],
    significado: "brotar; surgir",
    exemplos: [
      {
        kanji: "湧水",
        furigana: "ゆうすい",
        romaji: "yū.sui",
        traducao: "água brotando",
      },
      {
        kanji: "湧出",
        furigana: "ゆうしゅつ",
        romaji: "yū.shutsu",
        traducao: "surgimento",
      },
      {
        kanji: "湧き上がる",
        furigana: "わきあがる",
        romaji: "waki.agaru",
        traducao: "brotar",
      },
    ],
  },
  {
    kanji: "欣",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [],
    significado: "alegria; satisfação",
    exemplos: [
      {
        kanji: "歓欣",
        furigana: "かんきん",
        romaji: "kan.kin",
        traducao: "alegria",
      },
      {
        kanji: "喜欣",
        furigana: "ききん",
        romaji: "ki.kin",
        traducao: "jubiloso",
      },
      {
        kanji: "愉欣",
        furigana: "ゆきん",
        romaji: "yu.kin",
        traducao: "prazeroso",
      },
    ],
  },
  {
    kanji: "窯",
    onYomi: [{ katakana: "ヨウ", romaji: "yō" }],
    kunYomi: [{ hiragana: "かま", romaji: "kama" }],
    significado: "forno; fornalha",
    exemplos: [
      {
        kanji: "窯業",
        furigana: "ようぎょう",
        romaji: "yō.gyō",
        traducao: "indústria de cerâmica",
      },
      {
        kanji: "焼き物窯",
        furigana: "やきものがま",
        romaji: "yaki.monogama",
        traducao: "forno de cerâmica",
      },
      {
        kanji: "登り窯",
        furigana: "のぼりがま",
        romaji: "nobori.gama",
        traducao: "forno de subida",
      },
    ],
  },
  {
    kanji: "褒",
    onYomi: [{ katakana: "ホウ", romaji: "hō" }],
    kunYomi: [{ hiragana: "ほ.める", romaji: "ho.meru" }],
    significado: "elogiar; louvar",
    exemplos: [
      {
        kanji: "称賛する",
        furigana: "しょうさんする",
        romaji: "shō.san.suru",
        traducao: "elogiar",
      },
      {
        kanji: "褒章",
        furigana: "ほうしょう",
        romaji: "hō.shō",
        traducao: "honra",
      },
      {
        kanji: "褒美",
        furigana: "ほうび",
        romaji: "hō.bi",
        traducao: "recompensa",
      },
    ],
  },
  {
    kanji: "醜",
    onYomi: [{ katakana: "シュウ", romaji: "shū" }],
    kunYomi: [{ hiragana: "みにく.い", romaji: "miniku.i" }],
    significado: "feio; grotesco",
    exemplos: [
      {
        kanji: "醜悪",
        furigana: "しゅうあく",
        romaji: "shū.aku",
        traducao: "feio",
      },
      {
        kanji: "醜聞",
        furigana: "しゅうぶん",
        romaji: "shū.bun",
        traducao: "escândalo",
      },
      {
        kanji: "醜評",
        furigana: "しゅうひょう",
        romaji: "shū.hyō",
        traducao: "crítica negativa",
      },
    ],
  },
  {
    kanji: "升",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [{ hiragana: "ます", romaji: "masu" }],
    significado: "medida de volume; tigela",
    exemplos: [
      {
        kanji: "一升",
        furigana: "いっしょう",
        romaji: "isshō",
        traducao: "um shō",
      },
      {
        kanji: "升目",
        furigana: "ますめ",
        romaji: "masu.me",
        traducao: "grade",
      },
      {
        kanji: "升座",
        furigana: "ますざ",
        romaji: "masu.za",
        traducao: "assento elevado",
      },
    ],
  },
  {
    kanji: "殉",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [{ hiragana: "じゅんじる", romaji: "junjiru" }],
    significado: "morrer por; falecer",
    exemplos: [
      {
        kanji: "殉職",
        furigana: "じゅんしょく",
        romaji: "jun.shoku",
        traducao: "morte em serviço",
      },
      {
        kanji: "殉教",
        furigana: "じゅんきょう",
        romaji: "jun.kyō",
        traducao: "martírio",
      },
      {
        kanji: "殉情",
        furigana: "じゅんじょう",
        romaji: "jun.jō",
        traducao: "morreu de amor",
      },
    ],
  },
  {
    kanji: "煩",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "わずら.う", romaji: "wazura.u" }],
    significado: "perturbação; ansiedade",
    exemplos: [
      {
        kanji: "煩悩",
        furigana: "ぼんのう",
        romaji: "bon.nō",
        traducao: "desejo terreno",
      },
      {
        kanji: "苦煩",
        furigana: "くはん",
        romaji: "ku.han",
        traducao: "aflição",
      },
      {
        kanji: "騒煩",
        furigana: "そうぼう",
        romaji: "sō.bō",
        traducao: "inquietação",
      },
    ],
  },
  {
    kanji: "巴",
    onYomi: [{ katakana: "ト", romaji: "to" }],
    kunYomi: [{ hiragana: "ともえ", romaji: "tomoe" }],
    significado: "padrão em forma de vírgula; virgular",
    exemplos: [
      {
        kanji: "巴紋",
        furigana: "ともえもん",
        romaji: "tomoe.mon",
        traducao: "padrão em forma de vírgula",
      },
      {
        kanji: "巴の旗",
        furigana: "ともえのはた",
        romaji: "tomoe.no.hata",
        traducao: "bandeira com padrão em forma de vírgula",
      },
      {
        kanji: "巴結び",
        furigana: "ともえむすび",
        romaji: "tomoe.musubi",
        traducao: "nó em forma de vírgula",
      },
    ],
  },
  {
    kanji: "禎",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [],
    significado: "sorte; bênção",
    exemplos: [
      {
        kanji: "幸福",
        furigana: "こうふく",
        romaji: "kō.fuku",
        traducao: "felicidade",
      },
      {
        kanji: "福禄寿",
        furigana: "ふくろくじゅ",
        romaji: "fuku.roku.ju",
        traducao: "Fukurokuju (deus da sorte)",
      },
      {
        kanji: "福音",
        furigana: "ふくいん",
        romaji: "fuku.in",
        traducao: "boas novas",
      },
    ],
  },
  {
    kanji: "劾",
    onYomi: [{ katakana: "ガイ", romaji: "gai" }],
    kunYomi: [],
    significado: "acusar; impeachment",
    exemplos: [
      {
        kanji: "弾劾",
        furigana: "だんがい",
        romaji: "dan.gai",
        traducao: "acusação",
      },
      {
        kanji: "弾劾訴訟",
        furigana: "だんがいそしょう",
        romaji: "dan.gai.so.shō",
        traducao: "processo de impeachment",
      },
      {
        kanji: "劾罪",
        furigana: "がいざい",
        romaji: "gai.zai",
        traducao: "acusação criminal",
      },
    ],
  },
  {
    kanji: "堕",
    onYomi: [{ katakana: "ダ", romaji: "da" }],
    kunYomi: [{ hiragana: "お.ちる", romaji: "o.chiru" }],
    significado: "cair; decair",
    exemplos: [
      {
        kanji: "堕天",
        furigana: "だてん",
        romaji: "da.ten",
        traducao: "queda do céu",
      },
      {
        kanji: "堕落",
        furigana: "だらく",
        romaji: "da.raku",
        traducao: "degradação",
      },
      {
        kanji: "堕胎",
        furigana: "だたい",
        romaji: "da.tai",
        traducao: "aborto",
      },
    ],
  },
  {
    kanji: "租",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [],
    significado: "aluguel; taxa",
    exemplos: [
      {
        kanji: "賃租",
        furigana: "ちんそ",
        romaji: "chin.so",
        traducao: "aluguel",
      },
      {
        kanji: "租税",
        furigana: "そぜい",
        romaji: "so.zei",
        traducao: "imposto",
      },
      {
        kanji: "使用料",
        furigana: "しようりょう",
        romaji: "shi.yō.ryō",
        traducao: "taxa de uso",
      },
    ],
  },
  {
    kanji: "稜",
    onYomi: [{ katakana: "リョウ", romaji: "ryō" }],
    kunYomi: [],
    significado: "aresta; ângulo",
    exemplos: [
      {
        kanji: "稜線",
        furigana: "りょうせん",
        romaji: "ryō.sen",
        traducao: "linha de cume",
      },
      {
        kanji: "稜角",
        furigana: "りょうかく",
        romaji: "ryō.kaku",
        traducao: "ângulo",
      },
      {
        kanji: "稜柱",
        furigana: "りょうちゅう",
        romaji: "ryō.chū",
        traducao: "pilar angular",
      },
    ],
  },
  {
    kanji: "桟",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [],
    significado: "estrado; plataforma",
    exemplos: [
      {
        kanji: "桟橋",
        furigana: "さんばし",
        romaji: "san.bashi",
        traducao: "cais; píer",
      },
      {
        kanji: "桟道",
        furigana: "さんどう",
        romaji: "san.dō",
        traducao: "passarela",
      },
      {
        kanji: "桟敷",
        furigana: "さんじき",
        romaji: "san.jiki",
        traducao: "assentos de frente para o palco",
      },
    ],
  },
  {
    kanji: "倭",
    onYomi: [{ katakana: "ワ", romaji: "wa" }],
    kunYomi: [],
    significado: "Japão (antigo nome); japoneses",
    exemplos: [
      {
        kanji: "倭国",
        furigana: "わこく",
        romaji: "wa.koku",
        traducao: "país Yamato (antigo nome do Japão)",
      },
      {
        kanji: "倭人",
        furigana: "わじん",
        romaji: "wa.jin",
        traducao: "japonês (pessoa)",
      },
      {
        kanji: "倭建",
        furigana: "わだて",
        romaji: "wa.date",
        traducao: "arquitetura japonesa",
      },
    ],
  },
  {
    kanji: "婿",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "むこ", romaji: "muko" }],
    significado: "genro",
    exemplos: [
      {
        kanji: "婿養子",
        furigana: "むこようし",
        romaji: "muko.yōshi",
        traducao: "genro adotivo",
      },
      {
        kanji: "婿入り",
        furigana: "むこいり",
        romaji: "muko.iri",
        traducao: "casamento",
      },
      {
        kanji: "婿取り",
        furigana: "むこどり",
        romaji: "muko.dori",
        traducao: "casamento",
      },
    ],
  },
  {
    kanji: "慕",
    onYomi: [{ katakana: "ボ", romaji: "bo" }],
    kunYomi: [{ hiragana: "した.う", romaji: "shita.u" }],
    significado: "admirar; ansiar",
    exemplos: [
      {
        kanji: "憧憬",
        furigana: "どうけい",
        romaji: "dō.kei",
        traducao: "ardente desejo",
      },
      {
        kanji: "慕情",
        furigana: "ぼじょう",
        romaji: "bo.jō",
        traducao: "amor; afeto",
      },
      {
        kanji: "慕名",
        furigana: "ぼめい",
        romaji: "bo.mei",
        traducao: "notoriedade",
      },
    ],
  },
  {
    kanji: "斐",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [],
    significado: "elegante; gracioso",
    exemplos: [
      {
        kanji: "斐川",
        furigana: "ひかわ",
        romaji: "hi.kawa",
        traducao: "rio Fikawa",
      },
      {
        kanji: "斐洲",
        furigana: "いす",
        romaji: "i.su",
        traducao: "ilha Phi",
      },
      {
        kanji: "斐紗",
        furigana: "いとわ",
        romaji: "i.towa",
        traducao: "vestido elegante",
      },
    ],
  },
  {
    kanji: "罷",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "まか.り-", romaji: "maka.ri-" }],
    significado: "encerrar; parar; terminar",
    exemplos: [
      {
        kanji: "罷免",
        furigana: "ひめん",
        romaji: "hi.men",
        traducao: "demissão; destituição",
      },
      {
        kanji: "罷業",
        furigana: "ひぎょう",
        romaji: "hi.gyō",
        traducao: "greve",
      },
      {
        kanji: "罷黜",
        furigana: "ひちゅつ",
        romaji: "hi.chutsu",
        traducao: "expulsão",
      },
    ],
  },
  {
    kanji: "矯",
    onYomi: [{ katakana: "キョウ", romaji: "kyō" }],
    kunYomi: [{ hiragana: "た.める", romaji: "ta.meru" }],
    significado: "corrigir; retificar",
    exemplos: [
      {
        kanji: "矯正",
        furigana: "きょうせい",
        romaji: "kyō.sei",
        traducao: "correção",
      },
      {
        kanji: "矯歪",
        furigana: "きょうわい",
        romaji: "kyō.wai",
        traducao: "distorção",
      },
      {
        kanji: "矯飾",
        furigana: "きょうしょく",
        romaji: "kyō.shoku",
        traducao: "exagero",
      },
    ],
  },
  {
    kanji: "某",
    onYomi: [{ katakana: "ボウ", romaji: "bō" }],
    kunYomi: [{ hiragana: "それがし", romaji: "sore.gashi" }],
    significado: "fulano; certo; um certo",
    exemplos: [
      {
        kanji: "某所",
        furigana: "ぼうしょ",
        romaji: "bō.sho",
        traducao: "em algum lugar",
      },
      {
        kanji: "某氏",
        furigana: "ぼうし",
        romaji: "bō.shi",
        traducao: "Fulano",
      },
      {
        kanji: "某国",
        furigana: "ぼうこく",
        romaji: "bō.koku",
        traducao: "certo país",
      },
    ],
  },
  {
    kanji: "囚",
    onYomi: [{ katakana: "シュウ", romaji: "shū" }],
    kunYomi: [{ hiragana: "とら.われる", romaji: "tora.wareru" }],
    significado: "prisioneiro; aprisionado",
    exemplos: [
      {
        kanji: "囚人",
        furigana: "しゅうじん",
        romaji: "shū.jin",
        traducao: "prisioneiro",
      },
      {
        kanji: "囚獄",
        furigana: "しゅうごく",
        romaji: "shū.goku",
        traducao: "prisão",
      },
      {
        kanji: "囚室",
        furigana: "しゅうしつ",
        romaji: "shū.shitsu",
        traducao: "cela",
      },
    ],
  },
  {
    kanji: "魁",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [],
    significado: "líder; pioneiro",
    exemplos: [
      {
        kanji: "魁星",
        furigana: "かいせい",
        romaji: "kai.sei",
        traducao: "estrela pioneira",
      },
      {
        kanji: "魁軍",
        furigana: "かいぐん",
        romaji: "kai.gun",
        traducao: "equipe líder",
      },
      {
        kanji: "魁力",
        furigana: "かいりょく",
        romaji: "kai.ryoku",
        traducao: "força líder",
      },
    ],
  },
  {
    kanji: "虹",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [{ hiragana: "にじ", romaji: "niji" }],
    significado: "arco-íris",
    exemplos: [
      {
        kanji: "虹色",
        furigana: "にじいろ",
        romaji: "niji.iro",
        traducao: "cores do arco-íris",
      },
      {
        kanji: "虹彩",
        furigana: "こうさい",
        romaji: "kō.sai",
        traducao: "íris (parte do olho)",
      },
      {
        kanji: "虹霓",
        furigana: "こうげい",
        romaji: "kō.gei",
        traducao: "arco-íris",
      },
    ],
  },
  {
    kanji: "鴻",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [],
    significado: "ganso selvagem",
    exemplos: [
      {
        kanji: "大鴻",
        furigana: "だいこう",
        romaji: "dai.kō",
        traducao: "grande ganso selvagem",
      },
      {
        kanji: "鴻巣",
        furigana: "こうのす",
        romaji: "kō.no.su",
        traducao: "ninho de gansos",
      },
      {
        kanji: "鴻門",
        furigana: "こうもん",
        romaji: "kō.mon",
        traducao: "Portão Hongmen",
      },
    ],
  },
  {
    kanji: "泌",
    onYomi: [{ katakana: "ヒツ", romaji: "hitsu" }],
    kunYomi: [{ hiragana: "の.れる", romaji: "no.reru" }],
    significado: "secretar; liberar",
    exemplos: [
      {
        kanji: "分泌",
        furigana: "ぶんぴつ",
        romaji: "bunpitsu",
        traducao: "secreção",
      },
      {
        kanji: "尿泌",
        furigana: "にょうひつ",
        romaji: "nyōhitsu",
        traducao: "excreção urinária",
      },
      {
        kanji: "汗泌",
        furigana: "あせひつ",
        romaji: "asehitsu",
        traducao: "secreção de suor",
      },
    ],
  },
  {
    kanji: "於",
    onYomi: [{ katakana: "オ", romaji: "o" }],
    kunYomi: [],
    significado: "em; no; a; para",
    exemplos: [
      {
        kanji: "於外",
        furigana: "おがい",
        romaji: "ogai",
        traducao: "fora; além",
      },
      {
        kanji: "於海",
        furigana: "おうみ",
        romaji: "ōmi",
        traducao: "no mar",
      },
      {
        kanji: "於本",
        furigana: "おほん",
        romaji: "ohon",
        traducao: "no livro",
      },
    ],
  },
  {
    kanji: "赳",
    onYomi: [{ katakana: "キュウ", romaji: "kyū" }],
    kunYomi: [],
    significado: "valente; corajoso",
    exemplos: [
      {
        kanji: "赳赳",
        furigana: "きゅうきゅう",
        romaji: "kyūkyū",
        traducao: "valente",
      },
      {
        kanji: "赳赳武官",
        furigana: "きゅうきゅうぶかん",
        romaji: "kyūkyū bukan",
        traducao: "oficial militar valente",
      },
      {
        kanji: "赳赳老将",
        furigana: "きゅうきゅうろうしょう",
        romaji: "kyūkyū rōshō",
        traducao: "velho general valente",
      },
    ],
  },
  {
    kanji: "漸",
    onYomi: [{ katakana: "ゼン", romaji: "zen" }],
    kunYomi: [{ hiragana: "ようや.く", romaji: "yōya.ku" }],
    significado: "gradualmente; aos poucos",
    exemplos: [
      {
        kanji: "漸次",
        furigana: "ぜんじ",
        romaji: "zenji",
        traducao: "gradualmente",
      },
      {
        kanji: "漸近",
        furigana: "ぜんきん",
        romaji: "zenkin",
        traducao: "aproximação",
      },
      {
        kanji: "漸増",
        furigana: "ぜんぞう",
        romaji: "zenzō",
        traducao: "aumento gradual",
      },
    ],
  },
  {
    kanji: "蚊",
    onYomi: [{ katakana: "ブン", romaji: "bun" }],
    kunYomi: [{ hiragana: "か", romaji: "ka" }],
    significado: "mosquito",
    exemplos: [
      {
        kanji: "蚊帳",
        furigana: "かや",
        romaji: "kaya",
        traducao: "mosquiteiro",
      },
      {
        kanji: "蚊取り線香",
        furigana: "かとりせんこう",
        romaji: "katori senkō",
        traducao: "espiral repelente de mosquitos",
      },
      {
        kanji: "蚊の声",
        furigana: "かのこえ",
        romaji: "ka no koe",
        traducao: "zumbido de mosquitos",
      },
    ],
  },
  {
    kanji: "葵",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "aoi (planta)",
    exemplos: [
      {
        kanji: "葵祭",
        furigana: "あおいまつり",
        romaji: "aoi matsuri",
        traducao: "Festival Aoi",
      },
      {
        kanji: "葵七段",
        furigana: "あおいしちだん",
        romaji: "aoi shichi dan",
        traducao: "Aoi Shichidan (rank de sumô)",
      },
      {
        kanji: "葵紋章",
        furigana: "あおいもんしょう",
        romaji: "aoi monshō",
        traducao: "brasão de aoi",
      },
    ],
  },
  {
    kanji: "厄",
    onYomi: [{ katakana: "ヤク", romaji: "yaku" }],
    kunYomi: [],
    significado: "azar; infortúnio",
    exemplos: [
      {
        kanji: "厄年",
        furigana: "やくどし",
        romaji: "yakudoshi",
        traducao: "idade do azar",
      },
      {
        kanji: "厄介",
        furigana: "やっかい",
        romaji: "yakkai",
        traducao: "problema; aborrecimento",
      },
      {
        kanji: "厄除け",
        furigana: "やくよけ",
        romaji: "yakuyoke",
        traducao: "afastar o azar",
      },
    ],
  },
  {
    kanji: "藻",
    onYomi: [{ katakana: "ソウ", romaji: "sō" }],
    kunYomi: [],
    significado: "alga",
    exemplos: [
      {
        kanji: "海藻",
        furigana: "かいそう",
        romaji: "kaisō",
        traducao: "alga marinha",
      },
      {
        kanji: "水藻",
        furigana: "すいそう",
        romaji: "suisō",
        traducao: "alga de água doce",
      },
      {
        kanji: "藻場",
        furigana: "もば",
        romaji: "moba",
        traducao: "banco de algas",
      },
    ],
  },
  {
    kanji: "禄",
    onYomi: [{ katakana: "ロク", romaji: "roku" }],
    kunYomi: [],
    significado: "salário; renda; benefício",
    exemplos: [
      {
        kanji: "神禄",
        furigana: "しんろく",
        romaji: "shinroku",
        traducao: "salário divino",
      },
      {
        kanji: "神職禄",
        furigana: "しんしょくろく",
        romaji: "shinshoku roku",
        traducao: "salário de um funcionário de um santuário",
      },
      {
        kanji: "朝散歩禄",
        furigana: "あささんぽろく",
        romaji: "asa sanpo roku",
        traducao: "benefício da caminhada matinal",
      },
    ],
  },
  {
    kanji: "孟",
    onYomi: [{ katakana: "モウ", romaji: "mō" }],
    kunYomi: [],
    significado: "o mais velho; primogênito",
    exemplos: [
      {
        kanji: "孟母",
        furigana: "もうぼ",
        romaji: "mōbo",
        traducao: "Mãe de Mencius",
      },
      {
        kanji: "孟宗",
        furigana: "もうそう",
        romaji: "mōsō",
        traducao: "primeiro ancestral",
      },
      {
        kanji: "孟女",
        furigana: "もうじょ",
        romaji: "mōjo",
        traducao: "irmã mais velha",
      },
    ],
  },
  {
    kanji: "嫡",
    onYomi: [{ katakana: "チャク", romaji: "chaku" }],
    kunYomi: [],
    significado: "herdeiro; legítimo",
    exemplos: [
      {
        kanji: "嫡子",
        furigana: "ちゃくし",
        romaji: "chakushi",
        traducao: "herdeiro legítimo",
      },
      {
        kanji: "嫡流",
        furigana: "ちゃくりゅう",
        romaji: "chakuryū",
        traducao: "linhagem legítima",
      },
      {
        kanji: "嫡孫",
        furigana: "ちゃくそん",
        romaji: "chakuson",
        traducao: "neto legítimo",
      },
    ],
  },
  {
    kanji: "尭",
    onYomi: [{ katakana: "ギョウ", romaji: "gyō" }],
    kunYomi: [],
    significado: "elevado; alto",
    exemplos: [
      {
        kanji: "尭度",
        furigana: "ぎょうど",
        romaji: "gyōdo",
        traducao: "altitude",
      },
      {
        kanji: "尊尭",
        furigana: "そんぎょう",
        romaji: "songyō",
        traducao: "respeito elevado",
      },
      {
        kanji: "尭然",
        furigana: "ぎょうぜん",
        romaji: "gyōzen",
        traducao: "elevado; distinto",
      },
    ],
  },
  {
    kanji: "嚇",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [],
    significado: "amedrontar; intimidar",
    exemplos: [
      {
        kanji: "威嚇",
        furigana: "いかく",
        romaji: "ikaku",
        traducao: "ameaça; intimidação",
      },
      {
        kanji: "嚇叱",
        furigana: "かくしっ",
        romaji: "kaku.shiatsu",
        traducao: "repreensão severa",
      },
      {
        kanji: "威嚇行為",
        furigana: "いかくこうい",
        romaji: "ikaku kōi",
        traducao: "ato de intimidação",
      },
    ],
  },
  {
    kanji: "巳",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "signo do zodíaco (serpente)",
    exemplos: [
      {
        kanji: "巳年",
        furigana: "みどし",
        romaji: "midoshi",
        traducao: "ano do signo da serpente",
      },
      {
        kanji: "巳の刻",
        furigana: "みのとき",
        romaji: "mi.no.toki",
        traducao: "hora da serpente (9h-11h)",
      },
      {
        kanji: "巳の方位",
        furigana: "みのほうい",
        romaji: "mi no hōi",
        traducao: "direção da serpente (sudeste)",
      },
    ],
  },
  {
    kanji: "凸",
    onYomi: [{ katakana: "トツ", romaji: "totsu" }],
    kunYomi: [],
    significado: "saliente; convexo",
    exemplos: [
      {
        kanji: "凸面",
        furigana: "とつめん",
        romaji: "totsu.men",
        traducao: "superfície convexa",
      },
      {
        kanji: "凸レンズ",
        furigana: "とつれんず",
        romaji: "totsu renzu",
        traducao: "lente convexa",
      },
      {
        kanji: "凸起",
        furigana: "とっき",
        romaji: "tokki",
        traducao: "saliente",
      },
    ],
  },
  {
    kanji: "暢",
    onYomi: [{ katakana: "チョウ", romaji: "chō" }],
    kunYomi: [],
    significado: "fluente; livre; desimpedido",
    exemplos: [
      {
        kanji: "暢気",
        furigana: "のんき",
        romaji: "nonki",
        traducao: "despreocupado; descontraído",
      },
      {
        kanji: "順暢",
        furigana: "じゅんちょう",
        romaji: "junchō",
        traducao: "fluidez",
      },
      {
        kanji: "舌暢",
        furigana: "ぜつちょう",
        romaji: "zetsuchō",
        traducao: "fluência verbal",
      },
    ],
  },
  {
    kanji: "韻",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [],
    significado: "rima; entoação",
    exemplos: [
      {
        kanji: "押韻",
        furigana: "おういん",
        romaji: "ōin",
        traducao: "rima",
      },
      {
        kanji: "頭韻",
        furigana: "とういん",
        romaji: "tōin",
        traducao: "aliteração",
      },
      {
        kanji: "韻律",
        furigana: "いんりつ",
        romaji: "inritsu",
        traducao: "prosódia; ritmo",
      },
    ],
  },
  {
    kanji: "霜",
    onYomi: [{ katakana: "ソウ", romaji: "sō" }],
    kunYomi: [],
    significado: "geada",
    exemplos: [
      {
        kanji: "霜降り",
        furigana: "しもふり",
        romaji: "shimo.furi",
        traducao: "carne marmorizada",
      },
      {
        kanji: "霜月",
        furigana: "しもつき",
        romaji: "shimotsuki",
        traducao: "outubro",
      },
      {
        kanji: "霜柱",
        furigana: "しもばしら",
        romaji: "shimo bashira",
        traducao: "pilar de gelo",
      },
    ],
  },
  {
    kanji: "硝",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [],
    significado: "nitrato",
    exemplos: [
      {
        kanji: "硝子",
        furigana: "ガラス",
        romaji: "garasu",
        traducao: "vidro",
      },
      {
        kanji: "硝酸",
        furigana: "しょうさん",
        romaji: "shōsan",
        traducao: "ácido nítrico",
      },
      {
        kanji: "硝煙",
        furigana: "しょうえん",
        romaji: "shōen",
        traducao: "fumaça de pólvora",
      },
    ],
  },
  {
    kanji: "勅",
    onYomi: [{ katakana: "チョク", romaji: "choku" }],
    kunYomi: [],
    significado: "imperial; decreto",
    exemplos: [
      {
        kanji: "勅語",
        furigana: "ちょくご",
        romaji: "chokugo",
        traducao: "mensagem imperial",
      },
      {
        kanji: "勅使",
        furigana: "ちょくし",
        romaji: "chokushi",
        traducao: "enviado imperial",
      },
      {
        kanji: "勅許",
        furigana: "ちょっきょ",
        romaji: "chokkyo",
        traducao: "permissão imperial",
      },
    ],
  },
  {
    kanji: "芹",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [],
    significado: "salsão",
    exemplos: [
      {
        kanji: "芹菜",
        furigana: "せりな",
        romaji: "serina",
        traducao: "salsão",
      },
      {
        kanji: "芹川",
        furigana: "せりかわ",
        romaji: "serikawa",
        traducao: "rio Seri",
      },
      {
        kanji: "芹瀬",
        furigana: "せりせ",
        romaji: "serise",
        traducao: "ribeirão Seri",
      },
    ],
  },
  {
    kanji: "杏",
    onYomi: [{ katakana: "キョウ", romaji: "kyō" }],
    kunYomi: [],
    significado: "damasco",
    exemplos: [
      {
        kanji: "杏仁豆腐",
        furigana: "あんにんとうふ",
        romaji: "annin.tōfu",
        traducao: "pudim de amêndoa",
      },
      {
        kanji: "杏子",
        furigana: "あんず",
        romaji: "anzu",
        traducao: "damasco",
      },
      {
        kanji: "杏田",
        furigana: "あんだ",
        romaji: "anda",
        traducao: "arrozal de damascos",
      },
    ],
  },
  {
    kanji: "棺",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "caixão",
    exemplos: [
      {
        kanji: "棺桶",
        furigana: "かんおけ",
        romaji: "kanoke",
        traducao: "caixão",
      },
      {
        kanji: "木棺",
        furigana: "きかん",
        romaji: "kikan",
        traducao: "caixão de madeira",
      },
      {
        kanji: "石棺",
        furigana: "せっかん",
        romaji: "sekkan",
        traducao: "caixão de pedra",
      },
    ],
  },
  {
    kanji: "儒",
    onYomi: [{ katakana: "ジュ", romaji: "ju" }],
    kunYomi: [],
    significado: "confucionismo; erudito confucionista",
    exemplos: [
      {
        kanji: "儒学",
        furigana: "じゅがく",
        romaji: "jugaku",
        traducao: "estudo confucionista",
      },
      {
        kanji: "儒教",
        furigana: "じゅきょう",
        romaji: "jukyō",
        traducao: "confucionismo",
      },
      {
        kanji: "儒者",
        furigana: "じゅしゃ",
        romaji: "jusha",
        traducao: "erudito confucionista",
      },
    ],
  },
  {
    kanji: "鳳",
    onYomi: [{ katakana: "ホウ", romaji: "hō" }],
    kunYomi: [],
    significado: "fênix",
    exemplos: [
      {
        kanji: "鳳凰",
        furigana: "ほうおう",
        romaji: "hōō",
        traducao: "fênix",
      },
      {
        kanji: "白鳳",
        furigana: "はくほう",
        romaji: "haku hō",
        traducao: "Período Hakuhō",
      },
      {
        kanji: "鳳翔",
        furigana: "ほうしょう",
        romaji: "hōshō",
        traducao: "voo da fênix",
      },
    ],
  },
  {
    kanji: "馨",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [],
    significado: "perfume; fragrância",
    exemplos: [
      {
        kanji: "芳馨",
        furigana: "ほうけい",
        romaji: "hōkei",
        traducao: "perfume; fragrância",
      },
      {
        kanji: "香馨",
        furigana: "こうけい",
        romaji: "kōkei",
        traducao: "aroma; fragrância",
      },
      {
        kanji: "松馨",
        furigana: "まつかおり",
        romaji: "matsu kāori",
        traducao: "perfume de pinheiro",
      },
    ],
  },
  {
    kanji: "慧",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [],
    significado: "inteligente; sábio",
    exemplos: [
      {
        kanji: "智慧",
        furigana: "ちえ",
        romaji: "chie",
        traducao: "sabedoria; inteligência",
      },
      {
        kanji: "悟慧",
        furigana: "ごけい",
        romaji: "gokei",
        traducao: "sabedoria; discernimento",
      },
      {
        kanji: "覚悟",
        furigana: "かくご",
        romaji: "kakugo",
        traducao: "determinação; preparação",
      },
    ],
  },
  {
    kanji: "愁",
    onYomi: [{ katakana: "シュウ", romaji: "shū" }],
    kunYomi: [],
    significado: "melancolia; tristeza",
    exemplos: [
      {
        kanji: "憂愁",
        furigana: "ゆうしゅう",
        romaji: "yūshū",
        traducao: "melancolia; tristeza",
      },
      {
        kanji: "哀愁",
        furigana: "あいしゅう",
        romaji: "aishū",
        traducao: "tristeza; melancolia",
      },
      {
        kanji: "寂愁",
        furigana: "せきしゅう",
        romaji: "sekishū",
        traducao: "solidão; tristeza",
      },
    ],
  },
  {
    kanji: "楼",
    onYomi: [{ katakana: "ロウ", romaji: "rō" }],
    kunYomi: [],
    significado: "edifício alto; torre",
    exemplos: [
      {
        kanji: "高楼",
        furigana: "こうろう",
        romaji: "kōrō",
        traducao: "edifício alto",
      },
      {
        kanji: "楼閣",
        furigana: "ろうかく",
        romaji: "rōkaku",
        traducao: "pavilhão; edifício de vários andares",
      },
      {
        kanji: "五重楼",
        furigana: "ごじゅうろう",
        romaji: "gojūrō",
        traducao: "torre de cinco andares",
      },
    ],
  },
  {
    kanji: "彬",
    onYomi: [{ katakana: "ビン", romaji: "bin" }],
    kunYomi: [],
    significado: "inteligente; talentoso",
    exemplos: [
      {
        kanji: "彬彬",
        furigana: "ひんぴん",
        romaji: "hinpin",
        traducao: "gracioso; distinto",
      },
      {
        kanji: "清彬",
        furigana: "きよあき",
        romaji: "kiyoaki",
        traducao: "Nome próprio masculino",
      },
      {
        kanji: "彬子",
        furigana: "あきこ",
        romaji: "akiko",
        traducao: "Nome próprio feminino",
      },
    ],
  },
  {
    kanji: "匡",
    onYomi: [{ katakana: "キョウ", romaji: "kyō" }],
    kunYomi: [],
    significado: "ajustar; corrigir",
    exemplos: [
      {
        kanji: "匡正",
        furigana: "きょうせい",
        romaji: "kyōsei",
        traducao: "correção; ajuste",
      },
      {
        kanji: "匡匡",
        furigana: "きょうきょう",
        romaji: "kyōkyō",
        traducao: "equilibrado; corrigido",
      },
      {
        kanji: "匡拵",
        furigana: "きょうごん",
        romaji: "kyōgon",
        traducao: "corrigido; ajustado",
      },
    ],
  },
  {
    kanji: "眉",
    onYomi: [{ katakana: "ビ", romaji: "bi" }],
    kunYomi: [],
    significado: "sobrancelha",
    exemplos: [
      {
        kanji: "眉毛",
        furigana: "まゆげ",
        romaji: "mayuge",
        traducao: "sobrancelha",
      },
      {
        kanji: "一本眉",
        furigana: "いっぽんまゆ",
        romaji: "ipponmayu",
        traducao: "sobrancelha unida",
      },
      {
        kanji: "細眉",
        furigana: "ほそまゆ",
        romaji: "hosomayu",
        traducao: "sobrancelhas finas",
      },
    ],
  },
  {
    kanji: "欽",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [],
    significado: "respeitar; reverenciar",
    exemplos: [
      {
        kanji: "敬欽",
        furigana: "けいきん",
        romaji: "keikin",
        traducao: "respeito; veneração",
      },
      {
        kanji: "欽戴",
        furigana: "きんたい",
        romaji: "kintai",
        traducao: "reverência; devoção",
      },
      {
        kanji: "欽敬",
        furigana: "きんけい",
        romaji: "kinkei",
        traducao: "respeito; veneração",
      },
    ],
  },
  {
    kanji: "薪",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "lenha",
    exemplos: [
      {
        kanji: "薪木",
        furigana: "たきぎ",
        romaji: "takigi",
        traducao: "lenha",
      },
      {
        kanji: "薪炭",
        furigana: "たきずみ",
        romaji: "takizumi",
        traducao: "carvão de lenha",
      },
      {
        kanji: "薪割り",
        furigana: "たきわり",
        romaji: "takiwari",
        traducao: "corte de lenha",
      },
    ],
  },
  {
    kanji: "褐",
    onYomi: [{ katakana: "カツ", romaji: "katsu" }],
    kunYomi: [],
    significado: "marrom; castanho",
    exemplos: [
      {
        kanji: "茶褐色",
        furigana: "ちゃかっしょく",
        romaji: "chakasshoku",
        traducao: "marrom; cor castanho",
      },
      {
        kanji: "褐色",
        furigana: "かっしょく",
        romaji: "kasshoku",
        traducao: "marrom; castanho",
      },
      {
        kanji: "褐石",
        furigana: "かっせき",
        romaji: "kasseki",
        traducao: "rocha marrom",
      },
    ],
  },
  {
    kanji: "賜",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "たまわ", romaji: "tamawa" }],
    significado: "conceder; presentear",
    exemplos: [
      {
        kanji: "賜物",
        furigana: "たまもの",
        romaji: "tamamono",
        traducao: "presente; presenteado",
      },
      {
        kanji: "恩賜",
        furigana: "おんし",
        romaji: "onshi",
        traducao: "presente; favor",
      },
      {
        kanji: "祝賜",
        furigana: "しゅくし",
        romaji: "shukushi",
        traducao: "presente de felicitações",
      },
    ],
  },
  {
    kanji: "嵯",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [],
    significado: "acidentado; escarpado",
    exemplos: [
      {
        kanji: "嵯峨",
        furigana: "さが",
        romaji: "saga",
        traducao: "Monte Saga; região de Kyoto",
      },
      {
        kanji: "嵯峨野",
        furigana: "さがの",
        romaji: "sagano",
        traducao: "área de Sagano em Kyoto",
      },
      {
        kanji: "嵯峨岳",
        furigana: "さがのだけ",
        romaji: "saganodake",
        traducao: "Monte Sagano",
      },
    ],
  },
  {
    kanji: "綜",
    onYomi: [{ katakana: "ソウ", romaji: "sō" }],
    kunYomi: [],
    significado: "compreender; sintetizar",
    exemplos: [
      {
        kanji: "綜合",
        furigana: "そうごう",
        romaji: "sōgō",
        traducao: "síntese; integração",
      },
      {
        kanji: "綜覧",
        furigana: "そうらん",
        romaji: "sōran",
        traducao: "compilação; resumo",
      },
      {
        kanji: "綜合病院",
        furigana: "そうごうびょういん",
        romaji: "sōgō byōin",
        traducao: "hospital geral",
      },
    ],
  },
  {
    kanji: "繕",
    onYomi: [{ katakana: "ゼン", romaji: "zen" }],
    kunYomi: [{ hiragana: "つくろ", romaji: "tsukuro" }],
    significado: "reparar; consertar",
    exemplos: [
      {
        kanji: "修繕",
        furigana: "しゅうぜん",
        romaji: "shūzen",
        traducao: "reparo; conserto",
      },
      {
        kanji: "手繕い",
        furigana: "てづくろい",
        romaji: "tedzukuroi",
        traducao: "costura; reparo",
      },
      {
        kanji: "縫繕",
        furigana: "ほうぜん",
        romaji: "hōzen",
        traducao: "costura; reparo",
      },
    ],
  },
  {
    kanji: "栓",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [],
    significado: "rolha; tampa",
    exemplos: [
      {
        kanji: "栓抜き",
        furigana: "せんぬき",
        romaji: "sennuki",
        traducao: "abridor de garrafa",
      },
      {
        kanji: "コルク栓",
        furigana: "コルクせん",
        romaji: "koruku sen",
        traducao: "rolha de cortiça",
      },
      {
        kanji: "栓",
        furigana: "せん",
        romaji: "sen",
        traducao: "tampa; rolha",
      },
    ],
  },
  {
    kanji: "翠",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [],
    significado: "verde-esmeralda",
    exemplos: [
      {
        kanji: "翠玉",
        furigana: "すいぎょく",
        romaji: "suigyoku",
        traducao: "jade verde",
      },
      {
        kanji: "翡翠",
        furigana: "ひすい",
        romaji: "hisui",
        traducao: "jade verde",
      },
      {
        kanji: "翠緑",
        furigana: "すいりょく",
        romaji: "suiryoku",
        traducao: "verde-esmeralda",
      },
    ],
  },
  {
    kanji: "鮎",
    onYomi: [{ katakana: "デン", romaji: "den" }],
    kunYomi: [],
    significado: "truta",
    exemplos: [
      {
        kanji: "鮎",
        furigana: "あゆ",
        romaji: "ayu",
        traducao: "truta-do-mar",
      },
      {
        kanji: "鮎川",
        furigana: "あゆかわ",
        romaji: "ayukawa",
        traducao: "rio Ayukawa",
      },
      {
        kanji: "鮎料理",
        furigana: "あゆりょうり",
        romaji: "ayu ryōri",
        traducao: "culinária de truta",
      },
    ],
  },
  {
    kanji: "榛",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "avelã",
    exemplos: [
      {
        kanji: "榛の実",
        furigana: "はしばみのみ",
        romaji: "hashibami no mi",
        traducao: "avelã",
      },
      {
        kanji: "榛の木",
        furigana: "はしばみのき",
        romaji: "hashibami no ki",
        traducao: "avelazeiro",
      },
      {
        kanji: "榛の森",
        furigana: "はしばみのもり",
        romaji: "hashibami no mori",
        traducao: "floresta de avelãs",
      },
    ],
  },
  {
    kanji: "凹",
    onYomi: [{ katakana: "オウ", romaji: "ō" }],
    kunYomi: [],
    significado: "côncavo",
    exemplos: [
      {
        kanji: "凹凸",
        furigana: "おうとつ",
        romaji: "ōtotsu",
        traducao: "irregularidade; rugosidade",
      },
      {
        kanji: "凹面",
        furigana: "おうめん",
        romaji: "ōmen",
        traducao: "superfície côncava",
      },
      {
        kanji: "凹地",
        furigana: "おうち",
        romaji: "ōchi",
        traducao: "área côncava; depressão",
      },
    ],
  },
  {
    kanji: "艶",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "つや", romaji: "tsuya" }],
    significado: "encanto; brilho",
    exemplos: [
      {
        kanji: "艶やか",
        furigana: "つややか",
        romaji: "tsuyayaka",
        traducao: "encantador; deslumbrante",
      },
      {
        kanji: "艶麗",
        furigana: "えんれい",
        romaji: "enrei",
        traducao: "elegante; encantador",
      },
      {
        kanji: "艶めかしい",
        furigana: "つやめかしい",
        romaji: "tsuyamekashii",
        traducao: "atraente; cativante",
      },
    ],
  },
  {
    kanji: "惣",
    onYomi: [{ katakana: "ソウ", romaji: "sō" }],
    kunYomi: [],
    significado: "geralmente; tudo; todos",
    exemplos: [
      {
        kanji: "惣菜",
        furigana: "そうざい",
        romaji: "sōzai",
        traducao: "comida pronta; pratos preparados",
      },
      {
        kanji: "惣流",
        furigana: "そうりゅう",
        romaji: "sōryū",
        traducao: "corrente principal; geralmente aceito",
      },
      {
        kanji: "惣倉",
        furigana: "そうくら",
        romaji: "sōkura",
        traducao: "depósito geral; armazém",
      },
    ],
  },
  {
    kanji: "蔦",
    onYomi: [{ katakana: "チョウ", romaji: "chō" }],
    kunYomi: [{ hiragana: "つた", romaji: "tsuta" }],
    significado: "hera",
    exemplos: [
      {
        kanji: "蔦の絡まり",
        furigana: "つたのからまり",
        romaji: "tsuta no karamari",
        traducao: "emaranhado de hera",
      },
      {
        kanji: "蔦属",
        furigana: "つたぞく",
        romaji: "tsuta zoku",
        traducao: "gênero de hera",
      },
      {
        kanji: "蔦のつる",
        furigana: "つたのつる",
        romaji: "tsuta no tsuru",
        traducao: "cipo de hera",
      },
    ],
  },
  {
    kanji: "錬",
    onYomi: [{ katakana: "レン", romaji: "ren" }],
    kunYomi: [{ hiragana: "ね", romaji: "ne" }],
    significado: "treinamento; refinação",
    exemplos: [
      {
        kanji: "錬金術",
        furigana: "れんきんじゅつ",
        romaji: "renkinjutsu",
        traducao: "alquimia",
      },
      {
        kanji: "鍛錬",
        furigana: "たんれん",
        romaji: "tanren",
        traducao: "treinamento; prática",
      },
      {
        kanji: "修錬",
        furigana: "しゅうれん",
        romaji: "shūren",
        traducao: "cultivo; aperfeiçoamento",
      },
    ],
  },
  {
    kanji: "隼",
    onYomi: [{ katakana: "シュン", romaji: "shun" }],
    kunYomi: [{ hiragana: "はやぶさ", romaji: "hayabusa" }],
    significado: "falcão-peregrino",
    exemplos: [
      {
        kanji: "隼人",
        furigana: "はやと",
        romaji: "hayato",
        traducao: "povo Hayato",
      },
      {
        kanji: "隼鷹",
        furigana: "じゅんよう",
        romaji: "junyō",
        traducao: "navio de guerra Junyō",
      },
      {
        kanji: "隼砲",
        furigana: "はやぼう",
        romaji: "hayabō",
        traducao: "canhão falcão",
      },
    ],
  },
  {
    kanji: "渚",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [{ hiragana: "なぎさ", romaji: "nagisa" }],
    significado: "praia; beira-mar",
    exemplos: [
      {
        kanji: "渚のシンドバッド",
        furigana: "なぎさのシンドバッド",
        romaji: "nagisa no shindobaddo",
        traducao: "Sinbad na praia",
      },
      {
        kanji: "渚の恋",
        furigana: "なぎさのこい",
        romaji: "nagisa no koi",
        traducao: "amor à beira-mar",
      },
      {
        kanji: "渚にて",
        furigana: "なぎさにて",
        romaji: "nagisa nite",
        traducao: "à beira-mar",
      },
    ],
  },
  {
    kanji: "衷",
    onYomi: [{ katakana: "チュウ", romaji: "chū" }],
    kunYomi: [],
    significado: "sinceridade; interior",
    exemplos: [
      {
        kanji: "衷心",
        furigana: "ちゅうしん",
        romaji: "chūshin",
        traducao: "sinceridade; coração verdadeiro",
      },
      {
        kanji: "衷情",
        furigana: "ちゅうじょう",
        romaji: "chūjō",
        traducao: "sentimento profundo",
      },
      {
        kanji: "衷心に",
        furigana: "ちゅうしんに",
        romaji: "chūshin ni",
        traducao: "de coração sincero",
      },
    ],
  },
  {
    kanji: "逐",
    onYomi: [{ katakana: "チク", romaji: "chiku" }],
    kunYomi: [],
    significado: "perseguir; expulsar",
    exemplos: [
      {
        kanji: "逐一",
        furigana: "ちくいち",
        romaji: "chikuichi",
        traducao: "um por um; detalhadamente",
      },
      {
        kanji: "逐次",
        furigana: "ちくじ",
        romaji: "chikuji",
        traducao: "sucessivamente; passo a passo",
      },
      {
        kanji: "逐次的に",
        furigana: "ちくじてきに",
        romaji: "chikuji teki ni",
        traducao: "gradualmente; progressivamente",
      },
    ],
  },
  {
    kanji: "斥",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "しりぞ.ける", romaji: "shirizo.keru" }],
    significado: "rejeitar; repelir",
    exemplos: [
      {
        kanji: "斥候",
        furigana: "せっこう",
        romaji: "sekko",
        traducao: "batedor; explorador",
      },
      {
        kanji: "斥力",
        furigana: "せきりょく",
        romaji: "sekiryoku",
        traducao: "força repulsiva",
      },
      {
        kanji: "斥与",
        furigana: "せきよ",
        romaji: "sekiyo",
        traducao: "revogação; retirada",
      },
    ],
  },
  {
    kanji: "稀",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "まれ", romaji: "mare" }],
    significado: "raro; incomum",
    exemplos: [
      {
        kanji: "稀有",
        furigana: "けう",
        romaji: "keu",
        traducao: "raro; singular",
      },
      {
        kanji: "稀代",
        furigana: "けいだい",
        romaji: "keidai",
        traducao: "excepcional; incomparável",
      },
      {
        kanji: "稀有な",
        furigana: "けうな",
        romaji: "keu na",
        traducao: "raro; excepcional",
      },
    ],
  },
  {
    kanji: "芙",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [],
    significado: "lótus",
    exemplos: [
      {
        kanji: "芙蓉",
        furigana: "ふよう",
        romaji: "fuyō",
        traducao: "lótus",
      },
      {
        kanji: "芙蓉の花",
        furigana: "ふようのはな",
        romaji: "fuyō no hana",
        traducao: "flor de lótus",
      },
      {
        kanji: "芙蓉神",
        furigana: "ふようしん",
        romaji: "fuyōshin",
        traducao: "deusa da flor de lótus",
      },
    ],
  },
  {
    kanji: "詔",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [{ hiragana: "みことのり", romaji: "mikotonori" }],
    significado: "decreto; édito",
    exemplos: [
      {
        kanji: "詔勅",
        furigana: "しょうちょく",
        romaji: "shōchoku",
        traducao: "decreto imperial",
      },
      {
        kanji: "詔書",
        furigana: "しょうしょ",
        romaji: "shōsho",
        traducao: "edito; proclamação",
      },
      {
        kanji: "詔文",
        furigana: "しょうぶん",
        romaji: "shōbun",
        traducao: "texto do decreto",
      },
    ],
  },
  {
    kanji: "皐",
    onYomi: [],
    kunYomi: [{ hiragana: "さ", romaji: "sa" }],
    significado: "lago",
    exemplos: [
      {
        kanji: "皐月",
        furigana: "さつき",
        romaji: "satsuki",
        traducao: "maio (mês lunar)",
      },
      {
        kanji: "皐浜",
        furigana: "さつはま",
        romaji: "satsuhama",
        traducao: "praia de Satsu",
      },
      {
        kanji: "皐月の花",
        furigana: "さつきのはな",
        romaji: "satsuki no hana",
        traducao: "flor de maio",
      },
    ],
  },
  {
    kanji: "雛",
    onYomi: [{ katakana: "スウ", romaji: "sū" }],
    kunYomi: [{ hiragana: "ひな", romaji: "hina" }],
    significado: "boneca do festival das meninas; filhote",
    exemplos: [
      {
        kanji: "雛祭り",
        furigana: "ひなまつり",
        romaji: "hinamatsuri",
        traducao: "Festival das Bonecas",
      },
      {
        kanji: "雛人形",
        furigana: "ひなにんぎょう",
        romaji: "hina ningyō",
        traducao: "bonecas do Festival das Bonecas",
      },
      {
        kanji: "雛鳥",
        furigana: "ひなどり",
        romaji: "hinadori",
        traducao: "pintainho; filhote de pássaro",
      },
    ],
  },
  {
    kanji: "惟",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "おもんぱく.る", romaji: "omonpaku.ru" }],
    significado: "apenas; somente",
    exemplos: [
      {
        kanji: "惟一",
        furigana: "ゆいいつ",
        romaji: "yuitsu",
        traducao: "único; singular",
      },
      {
        kanji: "惟命",
        furigana: "いのち",
        romaji: "inochi",
        traducao: "vida",
      },
      {
        kanji: "惟神",
        furigana: "いしん",
        romaji: "ishin",
        traducao: "apenas os deuses; divindade",
      },
    ],
  },
  {
    kanji: "佑",
    onYomi: [{ katakana: "ユウ", romaji: "yū" }],
    kunYomi: [],
    significado: "ajudar; auxiliar",
    exemplos: [
      {
        kanji: "保佑",
        furigana: "ほゆう",
        romaji: "hoyū",
        traducao: "proteção; bênção",
      },
      {
        kanji: "奉佑",
        furigana: "ほうゆう",
        romaji: "hōyū",
        traducao: "assistência; apoio",
      },
      {
        kanji: "佑命",
        furigana: "たすけいのち",
        romaji: "tasuke inochi",
        traducao: "salvar vidas",
      },
    ],
  },
  {
    kanji: "耀",
    onYomi: [{ katakana: "ヨウ", romaji: "yō" }],
    kunYomi: [{ hiragana: "かがや.く", romaji: "kagaya.ku" }],
    significado: "brilhar; resplandecer",
    exemplos: [
      {
        kanji: "輝耀",
        furigana: "きよう",
        romaji: "kiyō",
        traducao: "esplendor; brilho",
      },
      {
        kanji: "耀眼",
        furigana: "ぎょうがん",
        romaji: "gyōgan",
        traducao: "brilhante; deslumbrante",
      },
      {
        kanji: "煌耀",
        furigana: "こうよう",
        romaji: "kōyō",
        traducao: "brilho; radiância",
      },
    ],
  },
  {
    kanji: "黛",
    onYomi: [],
    kunYomi: [{ hiragana: "まゆずみ", romaji: "mayuzumi" }],
    significado: "tinta para sobrancelha; escuro",
    exemplos: [
      {
        kanji: "黛色",
        furigana: "まゆずみいろ",
        romaji: "mayuzumi iro",
        traducao: "cor escura; azul-marinho",
      },
      {
        kanji: "黛のような",
        furigana: "まゆずみのような",
        romaji: "mayuzumi no yō na",
        traducao: "como uma sobrancelha escura",
      },
      {
        kanji: "黛の顔",
        furigana: "まゆずみのかお",
        romaji: "mayuzumi no kao",
        traducao: "rosto sombrio",
      },
    ],
  },
  {
    kanji: "渥",
    onYomi: [{ katakana: "アク", romaji: "aku" }],
    kunYomi: [{ hiragana: "あつ.い", romaji: "atsu.i" }],
    significado: "úmido; encharcado",
    exemplos: [
      {
        kanji: "渥美",
        furigana: "あつみ",
        romaji: "atsumi",
        traducao: "Atsumi (nome próprio)",
      },
      {
        kanji: "渥沢",
        furigana: "あっさわ",
        romaji: "assawa",
        traducao: "Atsuzawa (nome próprio)",
      },
      {
        kanji: "渥な地",
        furigana: "あつなち",
        romaji: "atsu na chi",
        traducao: "terreno úmido",
      },
    ],
  },
  {
    kanji: "憧",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [{ hiragana: "あこが.れる", romaji: "akoga.reru" }],
    significado: "ansiar; desejar",
    exemplos: [
      {
        kanji: "憧憬",
        furigana: "どうけい",
        romaji: "dōkei",
        traducao: "aspiração; anseio",
      },
      {
        kanji: "憧れの人",
        furigana: "あこがれのひと",
        romaji: "akogare no hito",
        traducao: "pessoa admirada; pessoa desejada",
      },
      {
        kanji: "憧れの場所",
        furigana: "あこがれのばしょ",
        romaji: "akogare no basho",
        traducao: "lugar desejado; lugar dos sonhos",
      },
    ],
  },
  {
    kanji: "宵",
    onYomi: [{ katakana: "ヨウ", romaji: "yō" }],
    kunYomi: [{ hiragana: "よい", romaji: "yoi" }],
    significado: "anoitecer; crepúsculo",
    exemplos: [
      {
        kanji: "宵待ち",
        furigana: "よいまち",
        romaji: "yoimachi",
        traducao: "espera pelo anoitecer",
      },
      {
        kanji: "宵闇",
        furigana: "よいやみ",
        romaji: "yoiyami",
        traducao: "crepúsculo; escuridão da noite",
      },
      {
        kanji: "宵の口",
        furigana: "よいのくち",
        romaji: "yoi no kuchi",
        traducao: "crepúsculo; início da noite",
      },
    ],
  },
  {
    kanji: "妄",
    onYomi: [{ katakana: "モウ", romaji: "mō" }],
    kunYomi: [{ hiragana: "みだ.りに", romaji: "mida.ri ni" }],
    significado: "fantasia; ilusão",
    exemplos: [
      {
        kanji: "妄信",
        furigana: "もうしん",
        romaji: "mōshin",
        traducao: "crença irracional; fanatismo",
      },
      {
        kanji: "妄想",
        furigana: "もうそう",
        romaji: "mōsō",
        traducao: "delírio; devaneio",
      },
      {
        kanji: "妄動",
        furigana: "もうどう",
        romaji: "mōdō",
        traducao: "ação irracional; comportamento descontrolado",
      },
    ],
  },
  {
    kanji: "惇",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [],
    significado: "altruísmo",
    exemplos: [
      {
        kanji: "勤労惇敬",
        furigana: "きんろうじゅんけい",
        romaji: "kinrōjunkei",
        traducao: "trabalho árduo e respeito mútuo",
      },
      {
        kanji: "忠勤惇敬",
        furigana: "ちゅうきんじゅんけい",
        romaji: "chūkinjunkei",
        traducao: "dedicação e respeito",
      },
      {
        kanji: "忠節惇忠",
        furigana: "ちゅうせつじゅんちゅう",
        romaji: "chūsetsujunchū",
        traducao: "lealdade e dedicação sincera",
      },
    ],
  },
  {
    kanji: "脩",
    onYomi: [{ katakana: "シュウ", romaji: "shū" }],
    kunYomi: [{ hiragana: "おさ.める", romaji: "osa.meru" }],
    significado: "cultivar; disciplinar",
    exemplos: [
      {
        kanji: "修脩",
        furigana: "しゅうしゅう",
        romaji: "shūshū",
        traducao: "cultivo; disciplina",
      },
      {
        kanji: "研脩",
        furigana: "けんしゅう",
        romaji: "kenshū",
        traducao: "treinamento; aperfeiçoamento",
      },
      {
        kanji: "道脩",
        furigana: "どうしゅう",
        romaji: "dōshū",
        traducao: "cultivo do caminho; treinamento moral",
      },
    ],
  },
  {
    kanji: "甫",
    onYomi: [{ katakana: "ホ", romaji: "ho" }],
    kunYomi: [],
    significado: "primeiro",
    exemplos: [
      {
        kanji: "甫に",
        furigana: "はじめに",
        romaji: "hajime ni",
        traducao: "primeiramente; em primeiro lugar",
      },
      {
        kanji: "甫の",
        furigana: "はじめの",
        romaji: "hajime no",
        traducao: "o primeiro; o inicial",
      },
      {
        kanji: "甫に読む",
        furigana: "はじめによむ",
        romaji: "hajime ni yomu",
        traducao: "ler em primeiro lugar",
      },
    ],
  },
  {
    kanji: "酌",
    onYomi: [{ katakana: "シャク", romaji: "shaku" }],
    kunYomi: [{ hiragana: "く.む", romaji: "ku.mu" }],
    significado: "servir bebida; beber",
    exemplos: [
      {
        kanji: "酌量",
        furigana: "しゃくりょう",
        romaji: "shakuryō",
        traducao: "discricionariedade; senso de proporção",
      },
      {
        kanji: "酌み交わす",
        furigana: "くみかわす",
        romaji: "kumikawasu",
        traducao: "brindar; beber juntos",
      },
      {
        kanji: "酌み酒",
        furigana: "くみざけ",
        romaji: "kumizake",
        traducao: "bebida alcoólica; saquê",
      },
    ],
  },
  {
    kanji: "蚕",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [{ hiragana: "かいこ", romaji: "kaiko" }],
    significado: "bicho-da-seda",
    exemplos: [
      {
        kanji: "蚕糸",
        furigana: "さんし",
        romaji: "sanshi",
        traducao: "fio de seda",
      },
      {
        kanji: "蚕食",
        furigana: "さんしょく",
        romaji: "sanshoku",
        traducao: "devorar; conquistar",
      },
      {
        kanji: "蚕を飼う",
        furigana: "かいこをかう",
        romaji: "kaiko o kau",
        traducao: "criar bicho-da-seda",
      },
    ],
  },
  {
    kanji: "嬉",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "うれ.しい", romaji: "ure.shii" }],
    significado: "alegre; feliz",
    exemplos: [
      {
        kanji: "嬉し涙",
        furigana: "うれしいなみだ",
        romaji: "ureshii namida",
        traducao: "lágrimas de alegria",
      },
      {
        kanji: "嬉しい驚き",
        furigana: "うれしいおどろき",
        romaji: "ureshii odoroki",
        traducao: "surpresa agradável",
      },
      {
        kanji: "嬉しいニュース",
        furigana: "うれしいニュース",
        romaji: "ureshii nyūsu",
        traducao: "notícia feliz",
      },
    ],
  },
  {
    kanji: "蒼",
    onYomi: [{ katakana: "ソウ", romaji: "sō" }],
    kunYomi: [{ hiragana: "あお.い", romaji: "ao.i" }],
    significado: "azul; pálido",
    exemplos: [
      {
        kanji: "蒼天",
        furigana: "そうてん",
        romaji: "sōten",
        traducao: "céu azul",
      },
      {
        kanji: "蒼白",
        furigana: "そうはく",
        romaji: "sōhaku",
        traducao: "pálido; lívido",
      },
      {
        kanji: "蒼年",
        furigana: "そうねん",
        romaji: "sōnen",
        traducao: "idade avançada; velhice",
      },
    ],
  },
  {
    kanji: "暉",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "かがや.く", romaji: "kagaya.ku" }],
    significado: "brilho; resplandecer",
    exemplos: [
      {
        kanji: "朝暉",
        furigana: "あさひ",
        romaji: "asahi",
        traducao: "luz da manhã",
      },
      {
        kanji: "夕暉",
        furigana: "ゆうひ",
        romaji: "yūhi",
        traducao: "pôr do sol; luz da tarde",
      },
      {
        kanji: "春暉",
        furigana: "はるひ",
        romaji: "haruhi",
        traducao: "brilho da primavera",
      },
    ],
  },
  {
    kanji: "頒",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [],
    significado: "distribuir; divulgar",
    exemplos: [
      {
        kanji: "頒布",
        furigana: "はんぷ",
        romaji: "hanpu",
        traducao: "distribuição; divulgação",
      },
      {
        kanji: "頒賞",
        furigana: "はんしょう",
        romaji: "hanshō",
        traducao: "premiação; concessão de prêmio",
      },
      {
        kanji: "頒布会",
        furigana: "はんぷかい",
        romaji: "hanpukai",
        traducao: "evento de distribuição; feira",
      },
    ],
  },
  {
    kanji: "只",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "ただ", romaji: "tada" }],
    significado: "apenas; somente",
    exemplos: [
      {
        kanji: "只今",
        furigana: "ただいま",
        romaji: "tadaima",
        traducao: "agora mesmo; estou de volta",
      },
      {
        kanji: "只管",
        furigana: "ただかん",
        romaji: "tadakan",
        traducao: "apenas; somente",
      },
      {
        kanji: "只中",
        furigana: "ただなか",
        romaji: "tadanaka",
        traducao: "exatamente no meio",
      },
    ],
  },
  {
    kanji: "肢",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "membro; extremidade",
    exemplos: [
      {
        kanji: "四肢",
        furigana: "しし",
        romaji: "shi.shi",
        traducao: "membros; extremidades",
      },
      {
        kanji: "上肢",
        furigana: "じょうし",
        romaji: "jōshi",
        traducao: "membros superiores; braços",
      },
      {
        kanji: "下肢",
        furigana: "かし",
        romaji: "kashi",
        traducao: "membros inferiores; pernas",
      },
    ],
  },
  {
    kanji: "檀",
    onYomi: [{ katakana: "ダン", romaji: "dan" }],
    kunYomi: [{ hiragana: "まゆみ", romaji: "mayumi" }],
    significado: "madeira de sândalo",
    exemplos: [
      {
        kanji: "檀木",
        furigana: "だんぼく",
        romaji: "danboku",
        traducao: "madeira de sândalo",
      },
      {
        kanji: "檀家",
        furigana: "だんか",
        romaji: "danka",
        traducao: "família budista; família que apoia um templo",
      },
      {
        kanji: "檀信徒",
        furigana: "だんしんと",
        romaji: "danshinto",
        traducao: "seguidor do budismo; fiel budista",
      },
    ],
  },
  {
    kanji: "凱",
    onYomi: [{ katakana: "ガイ", romaji: "gai" }],
    kunYomi: [],
    significado: "vitória; triunfo",
    exemplos: [
      {
        kanji: "凱旋",
        furigana: "がいせん",
        romaji: "gaisen",
        traducao: "triunfo; retorno triunfal",
      },
      {
        kanji: "凱歌",
        furigana: "がいか",
        romaji: "gaika",
        traducao: "canção de triunfo",
      },
      {
        kanji: "凱風",
        furigana: "がいふう",
        romaji: "gaifū",
        traducao: "vento da vitória",
      },
    ],
  },
  {
    kanji: "彗",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [],
    significado: "cometa",
    exemplos: [
      {
        kanji: "彗星",
        furigana: "すいせい",
        romaji: "suisei",
        traducao: "cometa",
      },
      {
        kanji: "彗軌",
        furigana: "すいき",
        romaji: "suiki",
        traducao: "órbita de cometa",
      },
      {
        kanji: "彗影",
        furigana: "すいえい",
        romaji: "suiei",
        traducao: "rastro de cometa",
      },
    ],
  },
  {
    kanji: "謄",
    onYomi: [{ katakana: "トウ", romaji: "tō" }],
    kunYomi: [],
    significado: "cópia; reprodução",
    exemplos: [
      {
        kanji: "謄写",
        furigana: "とうしゃ",
        romaji: "tōsha",
        traducao: "cópia; reprodução",
      },
      {
        kanji: "謄本",
        furigana: "とうほん",
        romaji: "tōhon",
        traducao: "cópia autenticada",
      },
      {
        kanji: "謄写機",
        furigana: "とうしゃき",
        romaji: "tōshaki",
        traducao: "máquina de cópia",
      },
    ],
  },
  {
    kanji: "梓",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "madeira paulownia",
    exemplos: [
      {
        kanji: "梓材",
        furigana: "しざい",
        romaji: "shizai",
        traducao: "madeira de paulownia",
      },
      {
        kanji: "梓の木",
        furigana: "あずさのき",
        romaji: "azusa no ki",
        traducao: "árvore de paulownia",
      },
      {
        kanji: "梓川",
        furigana: "あずさがわ",
        romaji: "azusa gawa",
        traducao: "rio Azusa",
      },
    ],
  },
  {
    kanji: "丑",
    onYomi: [{ katakana: "チュウ", romaji: "chū" }],
    kunYomi: [],
    significado: "boi (segundo signo do zodíaco chinês)",
    exemplos: [
      {
        kanji: "丑年",
        furigana: "うしとし",
        romaji: "ushi toshi",
        traducao: "ano do boi",
      },
      {
        kanji: "丑三つ時",
        furigana: "うしむつどき",
        romaji: "ushi mutsu toki",
        traducao: "hora do boi (meia-noite)",
      },
      {
        kanji: "丑の刻参り",
        furigana: "うしのこくまいり",
        romaji: "ushi no koku mairi",
        traducao: "visita a um templo à meia-noite",
      },
    ],
  },
  {
    kanji: "嗣",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "herdeiro; sucessor",
    exemplos: [
      {
        kanji: "嗣子",
        furigana: "しこ",
        romaji: "shiko",
        traducao: "herdeiro; sucessor",
      },
      {
        kanji: "嗣継ぐ",
        furigana: "つぐこう",
        romaji: "tsugu kō",
        traducao: "sucessão; herdar",
      },
      {
        kanji: "家督を嗣ぐ",
        furigana: "かとくをつぐ",
        romaji: "katoku o tsugu",
        traducao: "herdar o comando da família",
      },
    ],
  },
  {
    kanji: "叶",
    onYomi: [{ katakana: "キョウ", romaji: "kyō" }],
    kunYomi: [
      { hiragana: "かな", romaji: "kana" },
      { hiragana: "かなえる", romaji: "kanaeru" },
    ],
    significado: "realizar; conceder",
    exemplos: [
      {
        kanji: "叶う",
        furigana: "かなう",
        romaji: "kanau",
        traducao: "realizar-se; conceder-se",
      },
      {
        kanji: "願いが叶う",
        furigana: "ねがいがかなう",
        romaji: "negai ga kanau",
        traducao: "um desejo se realiza",
      },
      {
        kanji: "夢が叶う",
        furigana: "ゆめがかなう",
        romaji: "yume ga kanau",
        traducao: "um sonho se realiza",
      },
    ],
  },
  {
    kanji: "汐",
    onYomi: [],
    kunYomi: [{ hiragana: "しお", romaji: "shio" }],
    significado: "maré; refluxo",
    exemplos: [
      {
        kanji: "干潮",
        furigana: "ひしお",
        romaji: "hishio",
        traducao: "maré baixa",
      },
      {
        kanji: "満潮",
        furigana: "みしお",
        romaji: "mishio",
        traducao: "maré alta",
      },
      {
        kanji: "汐風",
        furigana: "しおかぜ",
        romaji: "shiokaze",
        traducao: "vento marítimo",
      },
    ],
  },
  {
    kanji: "絢",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [],
    significado: "brilhante; colorido",
    exemplos: [
      {
        kanji: "絢爛",
        furigana: "けんらん",
        romaji: "kenran",
        traducao: "esplendor; brilho",
      },
      {
        kanji: "絢爛豪華",
        furigana: "けんらんごうか",
        romaji: "kenran gōka",
        traducao: "luxo esplêndido",
      },
      {
        kanji: "絢爛たる",
        furigana: "けんらんたる",
        romaji: "kenran taru",
        traducao: "brilhante; esplêndido",
      },
    ],
  },
  {
    kanji: "朔",
    onYomi: [{ katakana: "サク", romaji: "saku" }],
    kunYomi: [],
    significado: "primeiro dia do mês lunar",
    exemplos: [
      {
        kanji: "元朔",
        furigana: "がんさく",
        romaji: "gansaku",
        traducao: "primeiro dia do mês lunar",
      },
      {
        kanji: "朔日",
        furigana: "さくじつ",
        romaji: "sakujitsu",
        traducao: "primeiro dia do mês",
      },
      {
        kanji: "朔風",
        furigana: "さくふう",
        romaji: "sakufū",
        traducao: "vento do início do mês",
      },
    ],
  },
  {
    kanji: "伽",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [],
    significado: "cantor budista; acompanhante; associação",
    exemplos: [
      {
        kanji: "伽藍",
        furigana: "がらん",
        romaji: "garan",
        traducao: "complexo de templos budistas",
      },
      {
        kanji: "伽羅",
        furigana: "きゃら",
        romaji: "kyara",
        traducao: "incenso; perfume",
      },
      {
        kanji: "伽倻",
        furigana: "カヤ",
        romaji: "kaya",
        traducao: "associação; companhia",
      },
    ],
  },
  {
    kanji: "畝",
    onYomi: [{ katakana: "ボウ", romaji: "bō" }],
    kunYomi: [{ hiragana: "うね", romaji: "une" }],
    significado: "leito; sulco",
    exemplos: [
      {
        kanji: "畝作り",
        furigana: "うねづくり",
        romaji: "unezukuri",
        traducao: "preparação do solo; fazer sulcos",
      },
      {
        kanji: "畝間",
        furigana: "うねま",
        romaji: "unema",
        traducao: "espaço entre os sulcos",
      },
      {
        kanji: "畝筋",
        furigana: "うねすじ",
        romaji: "une suji",
        traducao: "linha do sulco",
      },
    ],
  },
  {
    kanji: "抄",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [],
    significado: "extrato; resumo",
    exemplos: [
      {
        kanji: "抄録",
        furigana: "しょうろく",
        romaji: "shōroku",
        traducao: "resumo; abstract",
      },
      {
        kanji: "抄訳",
        furigana: "しょうやく",
        romaji: "shōyaku",
        traducao: "tradução abreviada; resumo",
      },
      {
        kanji: "要約抄",
        furigana: "ようやくしょう",
        romaji: "yōyaku shō",
        traducao: "resumo; sumário",
      },
    ],
  },
  {
    kanji: "爽",
    onYomi: [{ katakana: "ソウ", romaji: "sō" }],
    kunYomi: [{ hiragana: "さわ", romaji: "sawa" }],
    significado: "refrescante; agradável",
    exemplos: [
      {
        kanji: "爽快",
        furigana: "そうかい",
        romaji: "sōkai",
        traducao: "revigorante; refrescante",
      },
      {
        kanji: "爽やか",
        furigana: "さわやか",
        romaji: "sawayaka",
        traducao: "refrescante; agradável",
      },
      {
        kanji: "爽明",
        furigana: "そうめい",
        romaji: "sōmei",
        traducao: "clareza; brilho",
      },
    ],
  },
  {
    kanji: "黎",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [],
    significado: "negro; escuro",
    exemplos: [
      {
        kanji: "夜黎",
        furigana: "やれい",
        romaji: "yarei",
        traducao: "escuridão da noite",
      },
      {
        kanji: "陰黎",
        furigana: "いんれい",
        romaji: "inrei",
        traducao: "escuridão; trevas",
      },
      {
        kanji: "黎明",
        furigana: "れいめい",
        romaji: "reimei",
        traducao: "amanhecer; alvorecer",
      },
    ],
  },
  {
    kanji: "惰",
    onYomi: [{ katakana: "ダ", romaji: "da" }],
    kunYomi: [],
    significado: "preguiçoso; ocioso",
    exemplos: [
      {
        kanji: "怠惰",
        furigana: "たいだ",
        romaji: "taida",
        traducao: "preguiça; indolência",
      },
      {
        kanji: "惰性",
        furigana: "だせい",
        romaji: "dasei",
        traducao: "inércia",
      },
      {
        kanji: "物惰り",
        furigana: "ものだずらり",
        romaji: "mono dazurari",
        traducao: "ociosidade",
      },
    ],
  },
  {
    kanji: "蛮",
    onYomi: [{ katakana: "バン", romaji: "ban" }],
    kunYomi: [],
    significado: "bárbaro; selvagem",
    exemplos: [
      {
        kanji: "蛮行",
        furigana: "ばんこう",
        romaji: "bankō",
        traducao: "comportamento selvagem",
      },
      {
        kanji: "蛮族",
        furigana: "ばんぞく",
        romaji: "banzoku",
        traducao: "tribo bárbara",
      },
      {
        kanji: "蛮勇",
        furigana: "ばんゆう",
        romaji: "ban'yū",
        traducao: "valentia; bravura",
      },
    ],
  },
  {
    kanji: "冴",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [{ hiragana: "さえ", romaji: "sae" }],
    significado: "sereno; claro",
    exemplos: [
      {
        kanji: "冴える",
        furigana: "さえる",
        romaji: "saeru",
        traducao: "sereno; claro",
      },
      {
        kanji: "冴えている",
        furigana: "さえている",
        romaji: "saete iru",
        traducao: "estar sereno; estar claro",
      },
      {
        kanji: "冴えた空気",
        furigana: "さえたくうき",
        romaji: "saeta kūki",
        traducao: "ar fresco e claro",
      },
    ],
  },
  {
    kanji: "旺",
    onYomi: [{ katakana: "オウ", romaji: "ō" }],
    kunYomi: [],
    significado: "prosperidade; florescente",
    exemplos: [
      {
        kanji: "旺盛",
        furigana: "おうせい",
        romaji: "ōsei",
        traducao: "prosperidade; florescente",
      },
      {
        kanji: "旺時",
        furigana: "おうじ",
        romaji: "ōji",
        traducao: "tempo de prosperidade",
      },
      {
        kanji: "盛旺",
        furigana: "せいおう",
        romaji: "seiō",
        traducao: "prosperidade; auge",
      },
    ],
  },
  {
    kanji: "萌",
    onYomi: [{ katakana: "ホウ", romaji: "hō" }],
    kunYomi: [{ hiragana: "もえ", romaji: "moe" }],
    significado: "germinar; brotar",
    exemplos: [
      {
        kanji: "萌芽",
        furigana: "もうが",
        romaji: "mōga",
        traducao: "germinação; broto",
      },
      {
        kanji: "萌黄",
        furigana: "ほうおう",
        romaji: "hōō",
        traducao: "verde claro; broto verde",
      },
      {
        kanji: "芽が萌える",
        furigana: "めがもえる",
        romaji: "me ga moeru",
        traducao: "brotar; germinar",
      },
    ],
  },
  {
    kanji: "偲",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "しの", romaji: "shino" }],
    significado: "recordar; lembrar",
    exemplos: [
      {
        kanji: "偲ぶ",
        furigana: "しのぶ",
        romaji: "shinobu",
        traducao: "recordar; lembrar",
      },
      {
        kanji: "偲び合う",
        furigana: "しのびあう",
        romaji: "shinobi au",
        traducao: "recordar juntos",
      },
      {
        kanji: "偲ぶ場所",
        furigana: "しのぶばしょ",
        romaji: "shinobu basho",
        traducao: "local para recordar",
      },
    ],
  },
  {
    kanji: "侯",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [],
    significado: "marquês; chefe",
    exemplos: [
      {
        kanji: "侯爵",
        furigana: "こうしゃく",
        romaji: "kōshaku",
        traducao: "marquês",
      },
      {
        kanji: "侯国",
        furigana: "こうこく",
        romaji: "kōkoku",
        traducao: "estado feudal",
      },
      {
        kanji: "侯爵位",
        furigana: "こうしゃくい",
        romaji: "kōshaku i",
        traducao: "título de marquês",
      },
    ],
  },
  {
    kanji: "蒔",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "ま", romaji: "ma" }],
    significado: "semear; plantar",
    exemplos: [
      {
        kanji: "蒔く",
        furigana: "まく",
        romaji: "maku",
        traducao: "semear; plantar",
      },
      {
        kanji: "種蒔く",
        furigana: "たねまく",
        romaji: "tane maku",
        traducao: "semear sementes",
      },
      {
        kanji: "野菜を蒔く",
        furigana: "やさいをまく",
        romaji: "yasai o maku",
        traducao: "plantar vegetais",
      },
    ],
  },
  {
    kanji: "鯉",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [],
    significado: "carpa",
    exemplos: [
      {
        kanji: "鯉のぼり",
        furigana: "こいのぼり",
        romaji: "koi nobori",
        traducao: "bandeiras de carpa",
      },
      {
        kanji: "鯉の池",
        furigana: "こいのいけ",
        romaji: "koi no ike",
        traducao: "lagoa de carpas",
      },
      {
        kanji: "鯉料理",
        furigana: "こいりょうり",
        romaji: "koi ryōri",
        traducao: "culinária de carpa",
      },
    ],
  },
  {
    kanji: "弧",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [],
    significado: "arco",
    exemplos: [
      {
        kanji: "円弧",
        furigana: "えんこ",
        romaji: "enko",
        traducao: "arco; curva circular",
      },
      {
        kanji: "弧状",
        furigana: "こじょう",
        romaji: "kojō",
        traducao: "formato de arco",
      },
      {
        kanji: "弧を描く",
        furigana: "こをえがく",
        romaji: "ko o egaku",
        traducao: "desenhar um arco",
      },
    ],
  },
  {
    kanji: "遥",
    onYomi: [{ katakana: "ヨウ", romaji: "yō" }],
    kunYomi: [{ hiragana: "はるか", romaji: "haruka" }],
    significado: "distante; remoto",
    exemplos: [
      {
        kanji: "遥か",
        furigana: "はるか",
        romaji: "haruka",
        traducao: "distante; remoto",
      },
      {
        kanji: "遥か彼方",
        furigana: "はるかかなた",
        romaji: "haruka kanata",
        traducao: "muito além",
      },
      {
        kanji: "遥か遠い",
        furigana: "はるかとおい",
        romaji: "haruka tōi",
        traducao: "muito distante",
      },
    ],
  },
  {
    kanji: "舜",
    onYomi: [{ katakana: "シュン", romaji: "shun" }],
    kunYomi: [],
    significado: "Shun (nome próprio)",
    exemplos: [
      {
        kanji: "舜王",
        furigana: "しゅんおう",
        romaji: "shun'ō",
        traducao: "Rei Shun",
      },
      {
        kanji: "舜の時代",
        furigana: "しゅんのじだい",
        romaji: "shun no jidai",
        traducao: "Era de Shun",
      },
      {
        kanji: "舜帝",
        furigana: "しゅんてい",
        romaji: "shuntei",
        traducao: "Imperador Shun",
      },
    ],
  },
  {
    kanji: "瑛",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [],
    significado: "cristal",
    exemplos: [
      {
        kanji: "瑛子",
        furigana: "えいこ",
        romaji: "Eiko",
        traducao: "nome próprio feminino",
      },
      {
        kanji: "瑛瑞",
        furigana: "えいずい",
        romaji: "eizui",
        traducao: "cristal e jade",
      },
      {
        kanji: "瑛太",
        furigana: "えいた",
        romaji: "Eita",
        traducao: "nome próprio masculino",
      },
    ],
  },
  {
    kanji: "附",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "つ", romaji: "tsu" }],
    significado: "anexo; anexar",
    exemplos: [
      {
        kanji: "付ける",
        furigana: "つける",
        romaji: "tsukeru",
        traducao: "anexar; adicionar",
      },
      {
        kanji: "附属",
        furigana: "ふぞく",
        romaji: "fuzoku",
        traducao: "anexo; filiado",
      },
      {
        kanji: "附録",
        furigana: "ふろく",
        romaji: "furoku",
        traducao: "apêndice; anexo",
      },
    ],
  },
  {
    kanji: "彪",
    onYomi: [{ katakana: "ヒョウ", romaji: "hyō" }],
    kunYomi: [],
    significado: "leopardo",
    exemplos: [
      {
        kanji: "彪形",
        furigana: "ひょうけい",
        romaji: "hyōkei",
        traducao: "figura de leopardo; figura heróica",
      },
      {
        kanji: "虎彪",
        furigana: "こひょう",
        romaji: "ko hyō",
        traducao: "tigre e leopardo",
      },
      {
        kanji: "彪悍",
        furigana: "ひょうかん",
        romaji: "hyōkan",
        traducao: "heróico; valente",
      },
    ],
  },
  {
    kanji: "卯",
    onYomi: [{ katakana: "ボウ", romaji: "bō" }],
    kunYomi: [{ hiragana: "う", romaji: "u" }],
    significado: "signo do zodíaco chinês: coelho",
    exemplos: [
      {
        kanji: "卯年",
        furigana: "ぼうねん",
        romaji: "bōnen",
        traducao: "ano do coelho",
      },
      {
        kanji: "卯の花",
        furigana: "うのはな",
        romaji: "u no hana",
        traducao: "flores de primavera",
      },
      {
        kanji: "卯月",
        furigana: "うづき",
        romaji: "udzuki",
        traducao: "mês de abril",
      },
    ],
  },
  {
    kanji: "但",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "ただ", romaji: "tada" }],
    significado: "mas; porém; no entanto",
    exemplos: [
      {
        kanji: "しかし",
        furigana: "",
        romaji: "shikashi",
        traducao: "porém; contudo",
      },
      {
        kanji: "但し書き",
        furigana: "ただしがき",
        romaji: "tadashi gaki",
        traducao: "reserva; cláusula",
      },
      {
        kanji: "但し案",
        furigana: "ただしあん",
        romaji: "tadashi an",
        traducao: "proposta alternativa",
      },
    ],
  },
  {
    kanji: "綺",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "lindo; belo",
    exemplos: [
      {
        kanji: "綺麗",
        furigana: "きれい",
        romaji: "kirei",
        traducao: "bonito; lindo",
      },
      {
        kanji: "綺羅",
        furigana: "きら",
        romaji: "kira",
        traducao: "vestido de festa",
      },
      {
        kanji: "綺麗事",
        furigana: "きれいごと",
        romaji: "kirei goto",
        traducao: "palavras bonitas",
      },
    ],
  },
  {
    kanji: "芋",
    onYomi: [{ katakana: "ウ", romaji: "u" }],
    kunYomi: [],
    significado: "batata; inhame",
    exemplos: [
      {
        kanji: "芋煮",
        furigana: "いもに",
        romaji: "imo ni",
        traducao: "sopa de batata",
      },
      {
        kanji: "芋焼酎",
        furigana: "いもしょうちゅう",
        romaji: "imo shōchū",
        traducao: "shochu de batata",
      },
      {
        kanji: "サツマイモ",
        furigana: "",
        romaji: "satumaimo",
        traducao: "batata-doce",
      },
    ],
  },
  {
    kanji: "茜",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [],
    significado: "a cor do vermelho-escuro",
    exemplos: [
      {
        kanji: "茜色",
        furigana: "あかねいろ",
        romaji: "akaneiro",
        traducao: "cor vermelho-escuro",
      },
      {
        kanji: "茜染め",
        furigana: "あかねぞめ",
        romaji: "akanezome",
        traducao: "tingimento de cor vermelho-escuro",
      },
      {
        kanji: "茜香る",
        furigana: "あかねかおる",
        romaji: "akane kaoru",
        traducao: "cheirar a cor vermelho-escuro",
      },
    ],
  },
  {
    kanji: "凌",
    onYomi: [{ katakana: "リョウ", romaji: "ryō" }],
    kunYomi: [],
    significado: "superar; atravessar",
    exemplos: [
      {
        kanji: "凌ぐ",
        furigana: "しのぐ",
        romaji: "shinogu",
        traducao: "superar; suportar",
      },
      {
        kanji: "凌駕",
        furigana: "りょうが",
        romaji: "ryōga",
        traducao: "dominar; superar",
      },
      {
        kanji: "凌辱",
        furigana: "りょうじょく",
        romaji: "ryōjoku",
        traducao: "humilhação; ultraje",
      },
    ],
  },
  {
    kanji: "皓",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [],
    significado: "brilhante; claro",
    exemplos: [
      {
        kanji: "皓々",
        furigana: "こうこう",
        romaji: "kōkō",
        traducao: "brilhante; radiante",
      },
      {
        kanji: "皓月",
        furigana: "こうげつ",
        romaji: "kōgetsu",
        traducao: "lua brilhante",
      },
      {
        kanji: "皓白",
        furigana: "こうはく",
        romaji: "kōhaku",
        traducao: "branco brilhante",
      },
    ],
  },
  {
    kanji: "柚",
    onYomi: [{ katakana: "ユ", romaji: "yu" }],
    kunYomi: [],
    significado: "toranja",
    exemplos: [
      {
        kanji: "柚子",
        furigana: "ゆず",
        romaji: "yuzu",
        traducao: "toranja japonesa",
      },
      {
        kanji: "柚木",
        furigana: "ゆずき",
        romaji: "yuzuki",
        traducao: "madeira de toranja",
      },
      {
        kanji: "柚葉",
        furigana: "ゆのは",
        romaji: "yunoha",
        traducao: "folha de toranja",
      },
    ],
  },
  {
    kanji: "繭",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "まゆ", romaji: "mayu" }],
    significado: "casulo",
    exemplos: [
      {
        kanji: "繭糸",
        furigana: "まゆいと",
        romaji: "mayuito",
        traducao: "fio de casulo",
      },
      {
        kanji: "繭室",
        furigana: "まゆむろ",
        romaji: "mayumuro",
        traducao: "casulo",
      },
      {
        kanji: "繭化",
        furigana: "まゆばけ",
        romaji: "mayubake",
        traducao: "emergência do casulo",
      },
    ],
  },
  {
    kanji: "亦",
    onYomi: [{ katakana: "エキ", romaji: "eki" }],
    kunYomi: [{ hiragana: "また", romaji: "mata" }],
    significado: "também",
    exemplos: [
      {
        kanji: "亦",
        furigana: "また",
        romaji: "mata",
        traducao: "também",
      },
      {
        kanji: "亦々",
        furigana: "またまた",
        romaji: "matamata",
        traducao: "também; novamente",
      },
      {
        kanji: "亦人",
        furigana: "またびと",
        romaji: "matabito",
        traducao: "outro; também",
      },
    ],
  },
  {
    kanji: "詢",
    onYomi: [],
    kunYomi: [{ hiragana: "くま", romaji: "kuma" }],
    significado: "consulta",
    exemplos: [
      {
        kanji: "詢問",
        furigana: "じゅんもん",
        romaji: "junmon",
        traducao: "consulta; questionamento",
      },
      {
        kanji: "詢奪",
        furigana: "くまだつ",
        romaji: "kumadatsu",
        traducao: "tomar uma decisão após consulta",
      },
      {
        kanji: "詢視",
        furigana: "じゅんし",
        romaji: "junshi",
        traducao: "observação atenta",
      },
    ],
  },
  {
    kanji: "采",
    onYomi: [],
    kunYomi: [{ hiragana: "さい", romaji: "sai" }],
    significado: "amostra",
    exemplos: [
      {
        kanji: "采集",
        furigana: "さいしゅう",
        romaji: "saishū",
        traducao: "coleta; amostragem",
      },
      {
        kanji: "採采",
        furigana: "さいさい",
        romaji: "saisai",
        traducao: "colher; coletar",
      },
      {
        kanji: "采取",
        furigana: "さいしゅ",
        romaji: "saishu",
        traducao: "adotar; implementar",
      },
    ],
  },
  {
    kanji: "紗",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [],
    significado: "gaze; musselina",
    exemplos: [
      {
        kanji: "紗幕",
        furigana: "さまく",
        romaji: "samaku",
        traducao: "cortina de gaze",
      },
      {
        kanji: "紗綾",
        furigana: "しゃあ",
        romaji: "shā",
        traducao: "cetim de gaze",
      },
      {
        kanji: "紗衣",
        furigana: "しゃい",
        romaji: "shai",
        traducao: "roupa de gaze",
      },
    ],
  },
  {
    kanji: "賦",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [],
    significado: "tributo; poema",
    exemplos: [
      {
        kanji: "賦与",
        furigana: "ふよ",
        romaji: "fuyo",
        traducao: "concessão; doação",
      },
      {
        kanji: "賦税",
        furigana: "ふぜい",
        romaji: "fuzai",
        traducao: "imposto; tributo",
      },
      {
        kanji: "賦役",
        furigana: "ふえき",
        romaji: "fueki",
        traducao: "tributo; serviço público",
      },
    ],
  },
  {
    kanji: "眸",
    onYomi: [{ katakana: "ボウ", romaji: "bō" }],
    kunYomi: [{ hiragana: "ひとみ", romaji: "hitomi" }],
    significado: "olhos",
    exemplos: [
      {
        kanji: "眸子",
        furigana: "ひとみご",
        romaji: "hitomigo",
        traducao: "pupila",
      },
      {
        kanji: "眸中",
        furigana: "ひとみちゅう",
        romaji: "hitomichū",
        traducao: "olhos",
      },
      {
        kanji: "眸角",
        furigana: "ひとみかど",
        romaji: "hitomikado",
        traducao: "canto do olho",
      },
    ],
  },
  {
    kanji: "玖",
    onYomi: [{ katakana: "キュウ", romaji: "kyū" }],
    kunYomi: [],
    significado: "nove",
    exemplos: [
      {
        kanji: "玖千",
        furigana: "きゅうせん",
        romaji: "kyūsen",
        traducao: "nove mil",
      },
      {
        kanji: "玖万",
        furigana: "きゅうまん",
        romaji: "kyūman",
        traducao: "noventa mil",
      },
      {
        kanji: "玖拾",
        furigana: "きゅうじゅう",
        romaji: "kyūjū",
        traducao: "noventa",
      },
    ],
  },
  {
    kanji: "弐",
    onYomi: [],
    kunYomi: [],
    significado: "dois",
    exemplos: [
      {
        kanji: "弐号",
        furigana: "にごう",
        romaji: "ni-gō",
        traducao: "número dois",
      },
      {
        kanji: "弐倍",
        furigana: "にばい",
        romaji: "ni-bai",
        traducao: "duplo",
      },
      {
        kanji: "弐拾",
        furigana: "にじゅう",
        romaji: "ni-jū",
        traducao: "vinte",
      },
    ],
  },
  {
    kanji: "錘",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [],
    significado: "peso",
    exemplos: [
      {
        kanji: "錘叩",
        furigana: "すいたた",
        romaji: "suitataki",
        traducao: "martelo de peso",
      },
      {
        kanji: "錘打",
        furigana: "すいう",
        romaji: "suiu",
        traducao: "bater com um peso",
      },
      {
        kanji: "錘石",
        furigana: "すいせき",
        romaji: "suiseki",
        traducao: "pedra de peso",
      },
    ],
  },
  {
    kanji: "諄",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [],
    significado: "repetir",
    exemplos: [
      {
        kanji: "諄々",
        furigana: "じゅんじゅん",
        romaji: "junjun",
        traducao: "muito; muito repetidamente",
      },
      {
        kanji: "諄声",
        furigana: "じゅんせい",
        romaji: "junsei",
        traducao: "tom de repetição",
      },
      {
        kanji: "諄々しく",
        furigana: "じゅんじゅんしく",
        romaji: "junjunshiku",
        traducao: "repetidamente; enfaticamente",
      },
    ],
  },
  {
    kanji: "倖",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [],
    significado: "sorte; felicidade",
    exemplos: [
      {
        kanji: "幸福",
        furigana: "こうふく",
        romaji: "kōfuku",
        traducao: "felicidade; bem-estar",
      },
      {
        kanji: "倖せ",
        furigana: "しあわせ",
        romaji: "shiawase",
        traducao: "felicidade; sorte",
      },
      {
        kanji: "倖存",
        furigana: "こうぞん",
        romaji: "kōzon",
        traducao: "sobrevivência; existência",
      },
    ],
  },
  {
    kanji: "痘",
    onYomi: [{ katakana: "トウ", romaji: "tō" }],
    kunYomi: [],
    significado: "varíola",
    exemplos: [
      {
        kanji: "牛痘",
        furigana: "ぎゅうとう",
        romaji: "gyūtō",
        traducao: "vacina contra a varíola",
      },
      {
        kanji: "天然痘",
        furigana: "てんねんとう",
        romaji: "tennentō",
        traducao: "varíola natural",
      },
      {
        kanji: "種痘",
        furigana: "しゅとう",
        romaji: "shutō",
        traducao: "vacinação; inoculação",
      },
    ],
  },
  {
    kanji: "笙",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [],
    significado: "shō (instrumento musical)",
    exemplos: [
      {
        kanji: "笙奏",
        furigana: "しょうそう",
        romaji: "shōsō",
        traducao: "tocar o shō",
      },
      {
        kanji: "笙音",
        furigana: "しょうおん",
        romaji: "shōon",
        traducao: "som do shō",
      },
      {
        kanji: "笙曲",
        furigana: "しょうきょく",
        romaji: "shōkyoku",
        traducao: "música para shō",
      },
    ],
  },
  {
    kanji: "侃",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "assertivo",
    exemplos: [
      {
        kanji: "侃々諤々",
        furigana: "かんかんがくがく",
        romaji: "kankangakugaku",
        traducao: "com franqueza; com convicção",
      },
      {
        kanji: "侃侃",
        furigana: "かんかん",
        romaji: "kankan",
        traducao: "franco; assertivo",
      },
      {
        kanji: "侃々",
        furigana: "かんかん",
        romaji: "kankan",
        traducao: "franco; assertivo",
      },
    ],
  },
  {
    kanji: "裟",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [],
    significado: "sutra",
    exemplos: [
      {
        kanji: "袈裟",
        furigana: "けさ",
        romaji: "kesa",
        traducao: "sutra",
      },
      {
        kanji: "袈裟懐",
        furigana: "けさば",
        romaji: "kesaba",
        traducao: "dobrando o sutra",
      },
      {
        kanji: "袈裟斬り",
        furigana: "けさぎり",
        romaji: "kesagiri",
        traducao: "cortar a sutra",
      },
    ],
  },
  {
    kanji: "洵",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [],
    significado: "verdadeiro",
    exemplos: [
      {
        kanji: "洵々",
        furigana: "じゅんじゅん",
        romaji: "junjun",
        traducao: "verdadeiramente",
      },
      {
        kanji: "洵敏",
        furigana: "じゅんびん",
        romaji: "junbin",
        traducao: "verdadeiramente perspicaz",
      },
      {
        kanji: "洵實",
        furigana: "じゅんじつ",
        romaji: "junjitsu",
        traducao: "verdadeiro; genuíno",
      },
    ],
  },
  {
    kanji: "爾",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [],
    significado: "você",
    exemplos: [
      {
        kanji: "爾後",
        furigana: "いご",
        romaji: "igo",
        traducao: "a partir de agora",
      },
      {
        kanji: "爾来",
        furigana: "にしょらい",
        romaji: "nishorai",
        traducao: "desde então",
      },
      {
        kanji: "我爾",
        furigana: "がじ",
        romaji: "gaji",
        traducao: "você e eu",
      },
    ],
  },
  {
    kanji: "耗",
    onYomi: [{ katakana: "モウ", romaji: "mō" }],
    kunYomi: [],
    significado: "consumir",
    exemplos: [
      {
        kanji: "耗尽",
        furigana: "こうじん",
        romaji: "kōjin",
        traducao: "exaustão; esgotamento",
      },
      {
        kanji: "消耗",
        furigana: "しょうもう",
        romaji: "shōmō",
        traducao: "desgaste; consumo",
      },
      {
        kanji: "疲耗",
        furigana: "ひほう",
        romaji: "hihō",
        traducao: "cansaço; exaustão",
      },
    ],
  },
  {
    kanji: "昴",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [],
    significado: "plêiades",
    exemplos: [
      {
        kanji: "昴星",
        furigana: "こうせい",
        romaji: "kōsei",
        traducao: "estrelas plêiades",
      },
      {
        kanji: "昴座",
        furigana: "こうざ",
        romaji: "kōza",
        traducao: "constelação de plêiades",
      },
      {
        kanji: "昴光",
        furigana: "こうこう",
        romaji: "kōkō",
        traducao: "luz de plêiades",
      },
    ],
  },
  {
    kanji: "銑",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [],
    significado: "língote de metal",
    exemplos: [
      {
        kanji: "銑鉄",
        furigana: "せんてつ",
        romaji: "sentetsu",
        traducao: "ferro em lingote",
      },
      {
        kanji: "銑片",
        furigana: "せんぺん",
        romaji: "senpen",
        traducao: "pequeno lingote de metal",
      },
      {
        kanji: "銑山",
        furigana: "せんざん",
        romaji: "senzan",
        traducao: "montanha de minério",
      },
    ],
  },
  {
    kanji: "莞",
    onYomi: [],
    kunYomi: [{ hiragana: "かん", romaji: "kan" }],
    significado: "um tipo de planta",
    exemplos: [
      {
        kanji: "莞草",
        furigana: "かんそう",
        romaji: "kansō",
        traducao: "capim-dos-pampas",
      },
      {
        kanji: "莞爾",
        furigana: "かんじ",
        romaji: "kanji",
        traducao: "com uma expressão suave",
      },
      {
        kanji: "莞爾一笑",
        furigana: "かんじいっしょう",
        romaji: "kanjiisshō",
        traducao: "um sorriso gentil",
      },
    ],
  },
  {
    kanji: "伶",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [],
    significado: "artista",
    exemplos: [
      {
        kanji: "伶楽",
        furigana: "れいがく",
        romaji: "reigaku",
        traducao: "entretenimento; arte",
      },
      {
        kanji: "伶俐",
        furigana: "れいり",
        romaji: "reiri",
        traducao: "esperto; inteligente",
      },
      {
        kanji: "伶人",
        furigana: "れいじん",
        romaji: "reijin",
        traducao: "artista; ator",
      },
    ],
  },
  {
    kanji: "碩",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [],
    significado: "notável",
    exemplos: [
      {
        kanji: "碩学",
        furigana: "せきがく",
        romaji: "sekigaku",
        traducao: "grande conhecimento; erudição",
      },
      {
        kanji: "碩人",
        furigana: "せきじん",
        romaji: "sekijin",
        traducao: "grande pessoa; notável",
      },
      {
        kanji: "碩勲",
        furigana: "せきくん",
        romaji: "sekikun",
        traducao: "grande feito; distinção",
      },
    ],
  },
  {
    kanji: "宥",
    onYomi: [{ katakana: "ユウ", romaji: "yū" }],
    kunYomi: [],
    significado: "conciliar",
    exemplos: [
      {
        kanji: "宥和",
        furigana: "ゆうわ",
        romaji: "yūwa",
        traducao: "conciliação; harmonia",
      },
      {
        kanji: "宥譲",
        furigana: "ゆうじょう",
        romaji: "yūjō",
        traducao: "clemência; tolerância",
      },
      {
        kanji: "宥恕",
        furigana: "ゆうじょ",
        romaji: "yūjo",
        traducao: "misericórdia; indulgência",
      },
    ],
  },
  {
    kanji: "滉",
    onYomi: [],
    kunYomi: [{ hiragana: "こう", romaji: "kō" }],
    significado: "vasto; amplo",
    exemplos: [
      {
        kanji: "滉々",
        furigana: "こうこう",
        romaji: "kōkō",
        traducao: "vastamente; amplamente",
      },
      {
        kanji: "滉沢",
        furigana: "こうたく",
        romaji: "kōtaku",
        traducao: "rio amplo",
      },
      {
        kanji: "滉海",
        furigana: "こうかい",
        romaji: "kōkai",
        traducao: "oceano vasto",
      },
    ],
  },
  {
    kanji: "晏",
    onYomi: [],
    kunYomi: [{ hiragana: "あん", romaji: "an" }],
    significado: "paz; sereno",
    exemplos: [
      {
        kanji: "晏然",
        furigana: "あんぜん",
        romaji: "anzenn",
        traducao: "tranquilo; sereno",
      },
      {
        kanji: "晏晏",
        furigana: "あんあん",
        romaji: "anan",
        traducao: "serenamente",
      },
      {
        kanji: "晏紅",
        furigana: "あんこう",
        romaji: "ankō",
        traducao: "vermelho sereno",
      },
    ],
  },
  {
    kanji: "伎",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "habilidade",
    exemplos: [
      {
        kanji: "伎芸",
        furigana: "ぎげい",
        romaji: "gigei",
        traducao: "arte performática",
      },
      {
        kanji: "伎倆",
        furigana: "きりょう",
        romaji: "kiryō",
        traducao: "habilidade",
      },
      {
        kanji: "創伎",
        furigana: "そうぎ",
        romaji: "sōgi",
        traducao: "arte performática",
      },
    ],
  },
  {
    kanji: "朕",
    onYomi: [{ katakana: "チン", romaji: "chin" }],
    kunYomi: [],
    significado: "eu (uso real)",
    exemplos: [
      {
        kanji: "朕儀",
        furigana: "ちんぎ",
        romaji: "chingi",
        traducao: "nós; nosso",
      },
      {
        kanji: "朕親",
        furigana: "ちんしん",
        romaji: "chinshin",
        traducao: "nossos pais",
      },
      {
        kanji: "朕命",
        furigana: "ちんめい",
        romaji: "chinmei",
        traducao: "minha vida",
      },
    ],
  },
  {
    kanji: "迪",
    onYomi: [],
    kunYomi: [{ hiragana: "みち", romaji: "michi" }],
    significado: "guiar; liderar",
    exemplos: [
      {
        kanji: "迪学",
        furigana: "みちがく",
        romaji: "michigaku",
        traducao: "caminho do estudo; aprendizado",
      },
      {
        kanji: "迪元",
        furigana: "みちもと",
        romaji: "michimoto",
        traducao: "guia; líder",
      },
      {
        kanji: "迪令",
        furigana: "みちれい",
        romaji: "michirei",
        traducao: "comando; ordem",
      },
    ],
  },
  {
    kanji: "綸",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [],
    significado: "fio; linha",
    exemplos: [
      {
        kanji: "綸旨",
        furigana: "りんし",
        romaji: "rinshi",
        traducao: "sentido do fio; propósito",
      },
      {
        kanji: "綸巾",
        furigana: "りんきん",
        romaji: "rinkin",
        traducao: "faixa; faixa de cabeça",
      },
      {
        kanji: "綸言",
        furigana: "りんげん",
        romaji: "ringen",
        traducao: "discurso; fala",
      },
    ],
  },
  {
    kanji: "且",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [
      { hiragana: "か", romaji: "ka" },
      { hiragana: "かつ", romaji: "katsu" },
    ],
    significado: "além disso; ainda",
    exemplos: [
      {
        kanji: "且つ",
        furigana: "かつ",
        romaji: "katsu",
        traducao: "além disso; e",
      },
      {
        kanji: "且つして",
        furigana: "かつして",
        romaji: "katsushite",
        traducao: "e também",
      },
      {
        kanji: "且つ言える",
        furigana: "かついえる",
        romaji: "katsuieru",
        traducao: "além disso, pode-se dizer",
      },
    ],
  },
  {
    kanji: "竣",
    onYomi: [{ katakana: "シュン", romaji: "shun" }],
    kunYomi: [],
    significado: "concluir",
    exemplos: [
      {
        kanji: "竣工",
        furigana: "しゅんこう",
        romaji: "shunkō",
        traducao: "conclusão da construção",
      },
      {
        kanji: "竣校",
        furigana: "しゅんこう",
        romaji: "shunkō",
        traducao: "formatura; conclusão dos estudos",
      },
      {
        kanji: "竣工式",
        furigana: "しゅんこうしき",
        romaji: "shunkōshiki",
        traducao: "cerimônia de conclusão da construção",
      },
    ],
  },
  {
    kanji: "晨",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "manhã",
    exemplos: [
      {
        kanji: "晨光",
        furigana: "しんこう",
        romaji: "shinkō",
        traducao: "luz da manhã",
      },
      {
        kanji: "晨起",
        furigana: "しんき",
        romaji: "shinki",
        traducao: "acordar de manhã",
      },
      {
        kanji: "晨食",
        furigana: "しんしょく",
        romaji: "shinshoku",
        traducao: "café da manhã",
      },
    ],
  },
  {
    kanji: "吏",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [],
    significado: "funcionário público",
    exemplos: [
      {
        kanji: "吏員",
        furigana: "りいん",
        romaji: "riin",
        traducao: "funcionário público",
      },
      {
        kanji: "吏部",
        furigana: "りぶ",
        romaji: "ribu",
        traducao: "departamento administrativo",
      },
      {
        kanji: "官吏",
        furigana: "かんり",
        romaji: "kanri",
        traducao: "funcionário do governo",
      },
    ],
  },
  {
    kanji: "燦",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [],
    significado: "brilhante; radiante",
    exemplos: [
      {
        kanji: "燦爛",
        furigana: "さんらん",
        romaji: "sanran",
        traducao: "brilhante; radiante",
      },
      {
        kanji: "燦然",
        furigana: "さんぜん",
        romaji: "sanzen",
        traducao: "brilhante; radiante",
      },
      {
        kanji: "燦燦",
        furigana: "さんさん",
        romaji: "sansan",
        traducao: "brilhante; radiante",
      },
    ],
  },
  {
    kanji: "麿",
    onYomi: [],
    kunYomi: [{ hiragana: "まろ", romaji: "maro" }],
    significado: "eu (antigo uso)",
    exemplos: [
      {
        kanji: "麿子",
        furigana: "まろこ",
        romaji: "maroko",
        traducao: "filho de alguém",
      },
      {
        kanji: "麿日",
        furigana: "まろび",
        romaji: "marobi",
        traducao: "a meu ver; na minha opinião",
      },
      {
        kanji: "麿相",
        furigana: "まろあい",
        romaji: "maroai",
        traducao: "minha aparência",
      },
    ],
  },
  {
    kanji: "頌",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [],
    significado: "elogio",
    exemplos: [
      {
        kanji: "頌美",
        furigana: "しょうび",
        romaji: "shōbi",
        traducao: "beleza; esplendor",
      },
      {
        kanji: "頌詞",
        furigana: "しょうし",
        romaji: "shōshi",
        traducao: "elogio; enaltecimento",
      },
      {
        kanji: "頌徳",
        furigana: "しょうとく",
        romaji: "shōtoku",
        traducao: "elogio; virtude",
      },
    ],
  },
  {
    kanji: "箇",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [],
    significado: "contador para itens",
    exemplos: [
      {
        kanji: "一箇",
        furigana: "いっか",
        romaji: "ikka",
        traducao: "um item",
      },
      {
        kanji: "三箇月",
        furigana: "さんかげつ",
        romaji: "sankagetsu",
        traducao: "três meses",
      },
      {
        kanji: "二箇所",
        furigana: "にかしょ",
        romaji: "nikasho",
        traducao: "dois lugares",
      },
    ],
  },
  {
    kanji: "楓",
    onYomi: [{ katakana: "フウ", romaji: "fū" }],
    kunYomi: [{ hiragana: "かえで", romaji: "kaede" }],
    significado: "plátano; bordo",
    exemplos: [
      {
        kanji: "楓木",
        furigana: "ふうぼく",
        romaji: "fūboku",
        traducao: "árvore de bordo",
      },
      {
        kanji: "楓葉",
        furigana: "かえでば",
        romaji: "kaedeba",
        traducao: "folha de bordo",
      },
      {
        kanji: "紅楓",
        furigana: "べにかえで",
        romaji: "benikaede",
        traducao: "bordo vermelho",
      },
    ],
  },
  {
    kanji: "琳",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [],
    significado: "jade",
    exemplos: [
      {
        kanji: "琳派",
        furigana: "りんぱ",
        romaji: "rinpa",
        traducao: "Escola Rinpa (escola de pintura japonesa)",
      },
      {
        kanji: "琳琅",
        furigana: "りんろう",
        romaji: "rinrō",
        traducao: "variado; belo",
      },
      {
        kanji: "琳麗",
        furigana: "りんれい",
        romaji: "rinrei",
        traducao: "elegante; esplêndido",
      },
    ],
  },
  {
    kanji: "梧",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [],
    significado: "árvore de parasol",
    exemplos: [
      {
        kanji: "梧桐",
        furigana: "ごとう",
        romaji: "gotō",
        traducao: "árvore de parasol",
      },
      {
        kanji: "梧葉",
        furigana: "ごよう",
        romaji: "goyō",
        traducao: "folha de árvore de parasol",
      },
      {
        kanji: "梧洲",
        furigana: "ごしゅう",
        romaji: "goshū",
        traducao: "ilha de parasol",
      },
    ],
  },
  {
    kanji: "哉",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [],
    significado: "interjeição de exclamação",
    exemplos: [
      {
        kanji: "何哉",
        furigana: "なにや",
        romaji: "naniya",
        traducao: "o que!",
      },
      {
        kanji: "哉学",
        furigana: "さいがく",
        romaji: "saigaku",
        traducao: "oh, estudo!",
      },
      {
        kanji: "哉乱",
        furigana: "さいらん",
        romaji: "sairan",
        traducao: "oh, caos!",
      },
    ],
  },
  {
    kanji: "澪",
    onYomi: [],
    kunYomi: [{ hiragana: "みお", romaji: "mio" }],
    significado: "água clara; canal",
    exemplos: [
      {
        kanji: "澪水",
        furigana: "みおみず",
        romaji: "miomizu",
        traducao: "água clara",
      },
      {
        kanji: "運澪",
        furigana: "うんれい",
        romaji: "unrei",
        traducao: "canal de transporte",
      },
      {
        kanji: "澪標",
        furigana: "みおしるべ",
        romaji: "mioshirube",
        traducao: "marco de navegação",
      },
    ],
  },
  {
    kanji: "匁",
    onYomi: [],
    kunYomi: [{ hiragana: "もんめ", romaji: "monme" }],
    significado: "antiga unidade de peso",
    exemplos: [
      {
        kanji: "銀匁",
        furigana: "ぎんもんめ",
        romaji: "ginmonme",
        traducao: "monme de prata",
      },
      {
        kanji: "一匁",
        furigana: "いちもんめ",
        romaji: "ichimonme",
        traducao: "uma unidade de peso (antiga)",
      },
      {
        kanji: "半匁",
        furigana: "はんもんめ",
        romaji: "hanmonme",
        traducao: "meia unidade de peso (antiga)",
      },
    ],
  },
  {
    kanji: "晟",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [],
    significado: "inteligente; sábio",
    exemplos: [
      {
        kanji: "明晟",
        furigana: "あきはる",
        romaji: "akiharu",
        traducao: "brilho; esplendor",
      },
      {
        kanji: "晟明",
        furigana: "あきあきらか",
        romaji: "akiakekuraka",
        traducao: "brilho; claridade",
      },
      {
        kanji: "晟瑞",
        furigana: "あきみず",
        romaji: "akimizu",
        traducao: "brilho; sorte",
      },
    ],
  },
  {
    kanji: "衿",
    onYomi: [],
    kunYomi: [{ hiragana: "えり", romaji: "eri" }],
    significado: "gola; colarinho",
    exemplos: [
      {
        kanji: "衿袖",
        furigana: "えりそで",
        romaji: "erisode",
        traducao: "gola e mangas",
      },
      {
        kanji: "衿頭",
        furigana: "えりがしら",
        romaji: "erigashira",
        traducao: "colarinho",
      },
      {
        kanji: "胸衿",
        furigana: "むなえり",
        romaji: "muneri",
        traducao: "colarinho",
      },
    ],
  },
  {
    kanji: "凪",
    onYomi: [],
    kunYomi: [{ hiragana: "なぎ", romaji: "nagi" }],
    significado: "mar calmo; calmaria",
    exemplos: [
      {
        kanji: "凪止",
        furigana: "なぎとめ",
        romaji: "nagitome",
        traducao: "cessar o vento",
      },
      {
        kanji: "凪寧",
        furigana: "なぎやす",
        romaji: "nagiyasu",
        traducao: "calmo e pacífico",
      },
      {
        kanji: "凪水",
        furigana: "なぎみず",
        romaji: "nagimizu",
        traducao: "água calma",
      },
    ],
  },
  {
    kanji: "梢",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [{ hiragana: "こずえ", romaji: "kozue" }],
    significado: "ponta da árvore; galho superior",
    exemplos: [
      {
        kanji: "樹梢",
        furigana: "じゅしょう",
        romaji: "jushō",
        traducao: "ponta da árvore",
      },
      {
        kanji: "紅梢",
        furigana: "べにこずえ",
        romaji: "benikozue",
        traducao: "ponta vermelha",
      },
      {
        kanji: "松梢",
        furigana: "まつこずえ",
        romaji: "matsukozue",
        traducao: "ponta do pinheiro",
      },
    ],
  },
  {
    kanji: "丙",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [],
    significado: "terceiro; classe C",
    exemplos: [
      {
        kanji: "丙類",
        furigana: "へいるい",
        romaji: "heirui",
        traducao: "classe C",
      },
      {
        kanji: "丙烷",
        furigana: "へいたん",
        romaji: "heitan",
        traducao: "propano",
      },
      {
        kanji: "丙種",
        furigana: "へいしゅ",
        romaji: "heishu",
        traducao: "terceiro grau",
      },
    ],
  },
  {
    kanji: "颯",
    onYomi: [{ katakana: "サツ", romaji: "satsu" }],
    kunYomi: [],
    significado: "rápido; vento soprando",
    exemplos: [
      {
        kanji: "颯爽",
        furigana: "さっそう",
        romaji: "sassō",
        traducao: "elegante; animado",
      },
      {
        kanji: "颯然",
        furigana: "さっぱん",
        romaji: "sappan",
        traducao: "imperturbável",
      },
      {
        kanji: "颯麗",
        furigana: "さつれい",
        romaji: "satsurei",
        traducao: "elegante",
      },
    ],
  },
  {
    kanji: "茄",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [],
    significado: "berinjela",
    exemplos: [
      {
        kanji: "茄子",
        furigana: "なす",
        romaji: "nasu",
        traducao: "berinjela",
      },
      {
        kanji: "茄漢",
        furigana: "かかん",
        romaji: "kakan",
        traducao: "homem sábio",
      },
      {
        kanji: "茄神",
        furigana: "かじん",
        romaji: "kajin",
        traducao: "espírito sábio",
      },
    ],
  },
  {
    kanji: "勺",
    onYomi: [],
    kunYomi: [{ hiragana: "しゃく", romaji: "shaku" }],
    significado: "colher",
    exemplos: [
      {
        kanji: "木勺",
        furigana: "きしゃく",
        romaji: "kishaku",
        traducao: "colher de madeira",
      },
      {
        kanji: "勺匙",
        furigana: "しゃくし",
        romaji: "shakushi",
        traducao: "colher",
      },
      {
        kanji: "勺葛",
        furigana: "しゃくず",
        romaji: "shakuzu",
        traducao: "caçarola",
      },
    ],
  },
  {
    kanji: "恕",
    onYomi: [],
    kunYomi: [{ hiragana: "じょ", romaji: "jo" }],
    significado: "perdão; tolerância",
    exemplos: [
      {
        kanji: "恕心",
        furigana: "じょしん",
        romaji: "joshin",
        traducao: "coração compassivo",
      },
      {
        kanji: "寛恕",
        furigana: "かんじょ",
        romaji: "kanjo",
        traducao: "tolerância",
      },
      {
        kanji: "恕情",
        furigana: "じょじょう",
        romaji: "jojō",
        traducao: "sentimento de perdão",
      },
    ],
  },
  {
    kanji: "蕗",
    onYomi: [{ katakana: "ロ", romaji: "ro" }],
    kunYomi: [{ hiragana: "ふき", romaji: "fuki" }],
    significado: "brotos de bardana",
    exemplos: [
      {
        kanji: "蕗の薹",
        furigana: "ふきのとう",
        romaji: "fukinotō",
        traducao: "broto de bardana",
      },
      {
        kanji: "蕗田",
        furigana: "ふきだ",
        romaji: "fukida",
        traducao: "campo de bardana",
      },
      {
        kanji: "蕗人",
        furigana: "ふきびと",
        romaji: "fukibito",
        traducao: "colheitador de bardana",
      },
    ],
  },
  {
    kanji: "瑚",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [],
    significado: "coral",
    exemplos: [
      {
        kanji: "珊瑚",
        furigana: "さんご",
        romaji: "sango",
        traducao: "coral",
      },
      {
        kanji: "瑚璃",
        furigana: "ごり",
        romaji: "gori",
        traducao: "cristal; vidro colorido",
      },
      {
        kanji: "瑚瑰",
        furigana: "こかい",
        romaji: "kokai",
        traducao: "precioso; lindo",
      },
    ],
  },
  {
    kanji: "遵",
    onYomi: [],
    kunYomi: [{ hiragana: "したが", romaji: "shitaga" }],
    significado: "obedecer; seguir",
    exemplos: [
      {
        kanji: "遵命",
        furigana: "じゅんめい",
        romaji: "junmei",
        traducao: "obediência",
      },
      {
        kanji: "遵旨",
        furigana: "じゅんし",
        romaji: "junshi",
        traducao: "ordem; instrução",
      },
      {
        kanji: "遵守",
        furigana: "じゅんしゅ",
        romaji: "junshu",
        traducao: "observância",
      },
    ],
  },
  {
    kanji: "瞭",
    onYomi: [{ katakana: "リョウ", romaji: "ryō" }],
    kunYomi: [],
    significado: "claro; distinto",
    exemplos: [
      {
        kanji: "明瞭",
        furigana: "めいりょう",
        romaji: "meiryō",
        traducao: "claro; evidente",
      },
      {
        kanji: "瞭然",
        furigana: "りょうぜん",
        romaji: "ryōzen",
        traducao: "óbvio; claro",
      },
      {
        kanji: "瞭解",
        furigana: "りょうかい",
        romaji: "ryōkai",
        traducao: "compreensão",
      },
    ],
  },
  {
    kanji: "燎",
    onYomi: [{ katakana: "リョウ", romaji: "ryō" }],
    kunYomi: [],
    significado: "chama",
    exemplos: [
      {
        kanji: "燎原",
        furigana: "りょうげん",
        romaji: "ryōgen",
        traducao: "fogo intenso",
      },
      {
        kanji: "燎石",
        furigana: "りょうせき",
        romaji: "ryōseki",
        traducao: "pedra brilhante",
      },
      {
        kanji: "燎燭",
        furigana: "りょうそく",
        romaji: "ryōsoku",
        traducao: "vela",
      },
    ],
  },
  {
    kanji: "虞",
    onYomi: [],
    kunYomi: [{ hiragana: "おそれ", romaji: "osore" }],
    significado: "preocupação; apreensão",
    exemplos: [
      {
        kanji: "戦虞",
        furigana: "せんぐ",
        romaji: "sengu",
        traducao: "preocupação com a guerra",
      },
      {
        kanji: "虞状",
        furigana: "ぐじょう",
        romaji: "gujō",
        traducao: "aviso; advertência",
      },
      {
        kanji: "警虞",
        furigana: "けいぐ",
        romaji: "keigu",
        traducao: "preocupação com a segurança",
      },
    ],
  },
  {
    kanji: "柊",
    onYomi: [],
    kunYomi: [{ hiragana: "ひいらぎ", romaji: "hiiragi" }],
    significado: "azevinho",
    exemplos: [
      {
        kanji: "柊草",
        furigana: "ひいらぎくさ",
        romaji: "hiiragikusa",
        traducao: "azevinho",
      },
      {
        kanji: "柊木",
        furigana: "ひいらぎき",
        romaji: "hiiragiki",
        traducao: "azevinho",
      },
      {
        kanji: "柊神",
        furigana: "ひいらぎがみ",
        romaji: "hiiragigami",
        traducao: "espírito do azevinho",
      },
    ],
  },
  {
    kanji: "侑",
    onYomi: [{ katakana: "ユウ", romaji: "yū" }],
    kunYomi: [],
    significado: "incentivar; oferecer",
    exemplos: [
      {
        kanji: "侑子",
        furigana: "ゆうこ",
        romaji: "yūko",
        traducao: "Yuko (nome próprio)",
      },
      {
        kanji: "侑斗",
        furigana: "ゆうと",
        romaji: "yūto",
        traducao: "Yuto (nome próprio)",
      },
      {
        kanji: "侑香",
        furigana: "ゆうか",
        romaji: "yūka",
        traducao: "Yuka (nome próprio)",
      },
    ],
  },
  {
    kanji: "謁",
    onYomi: [],
    kunYomi: [{ hiragana: "けつ", romaji: "ketsu" }],
    significado: "audência; saudação",
    exemplos: [
      {
        kanji: "謁見",
        furigana: "えっけん",
        romaji: "ekken",
        traducao: "audiência",
      },
      {
        kanji: "謁者",
        furigana: "けっしゃ",
        romaji: "kessha",
        traducao: "visitante; cliente",
      },
      {
        kanji: "謁訪",
        furigana: "えっぽう",
        romaji: "ekkō",
        traducao: "visita oficial",
      },
    ],
  },
  {
    kanji: "斤",
    onYomi: [],
    kunYomi: [{ hiragana: "おの", romaji: "ono" }],
    significado: "machado",
    exemplos: [
      {
        kanji: "斧斤",
        furigana: "おのきん",
        romaji: "onokin",
        traducao: "machado",
      },
      {
        kanji: "斧頭",
        furigana: "おのがしら",
        romaji: "onogashira",
        traducao: "cabeça de machado",
      },
      {
        kanji: "斧形",
        furigana: "おのがた",
        romaji: "onogata",
        traducao: "formato de machado",
      },
    ],
  },
  {
    kanji: "嵩",
    onYomi: [],
    kunYomi: [{ hiragana: "かさ", romaji: "kasa" }],
    significado: "elevado; alto",
    exemplos: [
      {
        kanji: "嵩高",
        furigana: "かさだか",
        romaji: "kasadaka",
        traducao: "altura; elevação",
      },
      {
        kanji: "嵩山",
        furigana: "かさやま",
        romaji: "kasayama",
        traducao: "monte Kasayama",
      },
      {
        kanji: "嵩上げ",
        furigana: "かさあげ",
        romaji: "kasaage",
        traducao: "aumento; elevação",
      },
    ],
  },
  {
    kanji: "捺",
    onYomi: [],
    kunYomi: [{ hiragana: "なつ", romaji: "natsu" }],
    significado: "carimbar; pressionar",
    exemplos: [
      {
        kanji: "捺印",
        furigana: "なついん",
        romaji: "natsuin",
        traducao: "carimbo",
      },
      {
        kanji: "捺染",
        furigana: "なっせん",
        romaji: "nassen",
        traducao: "impressão",
      },
      {
        kanji: "捺号",
        furigana: "なつごう",
        romaji: "natsugō",
        traducao: "carimbo; selo",
      },
    ],
  },
  {
    kanji: "蓉",
    onYomi: [],
    kunYomi: [{ hiragana: "よう", romaji: "yō" }],
    significado: "lótus",
    exemplos: [
      {
        kanji: "蓉華",
        furigana: "ようか",
        romaji: "yōka",
        traducao: "flor de lótus",
      },
      {
        kanji: "蓉鬱",
        furigana: "よううつ",
        romaji: "yōutsu",
        traducao: "desejo intenso",
      },
      {
        kanji: "蓉白",
        furigana: "ようはく",
        romaji: "yōhaku",
        traducao: "branco lótus",
      },
    ],
  },
  {
    kanji: "茉",
    onYomi: [],
    kunYomi: [{ hiragana: "ま", romaji: "ma" }],
    significado: "jasmine",
    exemplos: [
      {
        kanji: "茉莉花",
        furigana: "まりか",
        romaji: "marika",
        traducao: "flor de jasmim",
      },
      {
        kanji: "茉莉香",
        furigana: "まりか",
        romaji: "marika",
        traducao: "fragrância de jasmim",
      },
      {
        kanji: "茉莉緒",
        furigana: "まりお",
        romaji: "mario",
        traducao: "Mario (nome próprio)",
      },
    ],
  },
  {
    kanji: "袈",
    onYomi: [],
    kunYomi: [{ hiragana: "け", romaji: "ke" }],
    significado: "manto; peça de roupa",
    exemplos: [
      {
        kanji: "袈裟",
        furigana: "けさ",
        romaji: "kesa",
        traducao: "manto budista",
      },
      {
        kanji: "袈裟襟",
        furigana: "けさえり",
        romaji: "kesaeri",
        traducao: "gola estilo manto",
      },
      {
        kanji: "袈裟懸け",
        furigana: "けさかけ",
        romaji: "kesakake",
        traducao: "pendurar o manto",
      },
    ],
  },
  {
    kanji: "燿",
    onYomi: [{ katakana: "ヨウ", romaji: "yō" }],
    kunYomi: [],
    significado: "brilho; esplendor",
    exemplos: [
      {
        kanji: "燿然",
        furigana: "ようぜん",
        romaji: "yōzen",
        traducao: "brilhante",
      },
      {
        kanji: "燿燿",
        furigana: "ようよう",
        romaji: "yōyō",
        traducao: "brilhante",
      },
      {
        kanji: "燿度",
        furigana: "ようど",
        romaji: "yōdo",
        traducao: "brilho; intensidade",
      },
    ],
  },
  {
    kanji: "勁",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [],
    significado: "vigor; força",
    exemplos: [
      {
        kanji: "剛勁",
        furigana: "ごうけい",
        romaji: "gōkei",
        traducao: "força robusta",
      },
      {
        kanji: "勁風",
        furigana: "けいふう",
        romaji: "keifū",
        traducao: "vento forte",
      },
      {
        kanji: "勁敵",
        furigana: "けいてき",
        romaji: "keiteki",
        traducao: "adversário poderoso",
      },
    ],
  },
  {
    kanji: "菖",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [],
    significado: "íris; lírio-d'água",
    exemplos: [
      {
        kanji: "菖蒲",
        furigana: "しょうぶ",
        romaji: "shōbu",
        traducao: "junco",
      },
      {
        kanji: "菖蒲湯",
        furigana: "しょうぶゆ",
        romaji: "shōbuyu",
        traducao: "banho com junco",
      },
      {
        kanji: "菖蒲の節句",
        furigana: "しょうぶのせっく",
        romaji: "shōbu no sekku",
        traducao: "Festival do Junco (Dia dos Meninos)",
      },
    ],
  },
  {
    kanji: "旦",
    onYomi: [
      { katakana: "タン", romaji: "tan" },
      { katakana: "ダン", romaji: "dan" },
    ],
    kunYomi: [{ hiragana: "あきら", romaji: "akira" }],
    significado: "amanhecer; ato",
    exemplos: [
      {
        kanji: "旦那",
        furigana: "だんな",
        romaji: "danna",
        traducao: "marido",
      },
      {
        kanji: "旦過",
        furigana: "たんか",
        romaji: "tanka",
        traducao: "passado; antigamente",
      },
      {
        kanji: "旦旦",
        furigana: "たんたん",
        romaji: "tantan",
        traducao: "muito; muito mesmo",
      },
    ],
  },
  {
    kanji: "椋",
    onYomi: [{ katakana: "リョウ", romaji: "ryō" }],
    kunYomi: [{ hiragana: "むく", romaji: "muku" }],
    significado: "árvore de cedro",
    exemplos: [
      {
        kanji: "椋鳥",
        furigana: "むくどり",
        romaji: "mukudori",
        traducao: "canto do melro",
      },
      {
        kanji: "椋校",
        furigana: "むくこう",
        romaji: "mukukō",
        traducao: "cedro",
      },
      {
        kanji: "椋杜",
        furigana: "むくもり",
        romaji: "mukumori",
        traducao: "árvore de cedro",
      },
    ],
  },
  {
    kanji: "叡",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [],
    significado: "inteligência; sabedoria",
    exemplos: [
      {
        kanji: "叡智",
        furigana: "えいち",
        romaji: "eichi",
        traducao: "sabedoria",
      },
      {
        kanji: "叡王",
        furigana: "えいおう",
        romaji: "eiō",
        traducao: "rei sábio",
      },
      {
        kanji: "叡覧",
        furigana: "えいらん",
        romaji: "eiran",
        traducao: "exame de sabedoria",
      },
    ],
  },
  {
    kanji: "紬",
    onYomi: [{ katakana: "チョウ", romaji: "chō" }],
    kunYomi: [{ hiragana: "つむぎ", romaji: "tsumugi" }],
    significado: "seda tecida à mão",
    exemplos: [
      {
        kanji: "紬糸",
        furigana: "つむぎいと",
        romaji: "tsumugiito",
        traducao: "fio de seda",
      },
      {
        kanji: "紬衣",
        furigana: "つむぎぎぬ",
        romaji: "tsumugiginu",
        traducao: "vestimenta de seda",
      },
      {
        kanji: "紬織",
        furigana: "つむぎおり",
        romaji: "tsumugiori",
        traducao: "tecelagem de seda",
      },
    ],
  },
  {
    kanji: "胤",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [],
    significado: "herdeiro; sucessor",
    exemplos: [
      {
        kanji: "嫡胤",
        furigana: "ちゃくいん",
        romaji: "chakuin",
        traducao: "herdeiro legítimo",
      },
      {
        kanji: "皇胤",
        furigana: "こういん",
        romaji: "kōin",
        traducao: "herdeiro imperial",
      },
      {
        kanji: "宗胤",
        furigana: "そういん",
        romaji: "sōin",
        traducao: "herdeiro religioso",
      },
    ],
  },
  {
    kanji: "凜",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [],
    significado: "respeitoso; digno",
    exemplos: [
      {
        kanji: "凜然",
        furigana: "りんぜん",
        romaji: "rinzen",
        traducao: "digno; imponente",
      },
      {
        kanji: "凜冽",
        furigana: "りんれつ",
        romaji: "rinretsu",
        traducao: "frio intenso",
      },
      {
        kanji: "凜然とした",
        furigana: "りんぜんとした",
        romaji: "rinzen to shita",
        traducao: "imponente; digno",
      },
    ],
  },
  {
    kanji: "亥",
    onYomi: [{ katakana: "ガイ", romaji: "gai" }],
    kunYomi: [],
    significado: "signo do zodíaco; javali",
    exemplos: [
      {
        kanji: "甲亥",
        furigana: "こうがい",
        romaji: "kōgai",
        traducao: "ano do javali",
      },
      {
        kanji: "亥年",
        furigana: "いのとし",
        romaji: "ino-toshi",
        traducao: "ano do javali",
      },
      {
        kanji: "亥時",
        furigana: "いのとき",
        romaji: "ino-toki",
        traducao: "horas do javali",
      },
    ],
  },
  {
    kanji: "爵",
    onYomi: [{ katakana: "シャク", romaji: "shaku" }],
    kunYomi: [],
    significado: "nobreza; título nobre",
    exemplos: [
      {
        kanji: "公爵",
        furigana: "こうしゃく",
        romaji: "kōshaku",
        traducao: "duque",
      },
      {
        kanji: "侯爵",
        furigana: "こうしゃく",
        romaji: "kōshaku",
        traducao: "marquês",
      },
      {
        kanji: "男爵",
        furigana: "だんしゃく",
        romaji: "danshaku",
        traducao: "barão",
      },
    ],
  },
  {
    kanji: "脹",
    onYomi: [{ katakana: "チョウ", romaji: "chō" }],
    kunYomi: [{ hiragana: "は.れる", romaji: "hare-ru" }],
    significado: "inchaço; inchar",
    exemplos: [
      {
        kanji: "脹脛",
        furigana: "はれはぎ",
        romaji: "harehagi",
        traducao: "inchaço na panturrilha",
      },
      {
        kanji: "脹脾",
        furigana: "はればら",
        romaji: "harebara",
        traducao: "inchaço no baço",
      },
      {
        kanji: "脹満",
        furigana: "ちょうまん",
        romaji: "chōman",
        traducao: "sensação de inchaço",
      },
    ],
  },
  {
    kanji: "麟",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [],
    significado: "fênix; lenda chinesa",
    exemplos: [
      {
        kanji: "麟麟",
        furigana: "りんりん",
        romaji: "rinrin",
        traducao: "majestoso",
      },
      {
        kanji: "麟角",
        furigana: "りんかく",
        romaji: "rinkaku",
        traducao: "chifre de fênix",
      },
      {
        kanji: "麟趾",
        furigana: "りんし",
        romaji: "rinshi",
        traducao: "pegadas de fênix",
      },
    ],
  },
  {
    kanji: "莉",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [],
    significado: "jasmine",
    exemplos: [
      {
        kanji: "莉色",
        furigana: "りいろ",
        romaji: "riiro",
        traducao: "cor jasmin",
      },
      {
        kanji: "莉香",
        furigana: "りか",
        romaji: "rika",
        traducao: "perfume de jasmim",
      },
      {
        kanji: "莉玉",
        furigana: "りぎょく",
        romaji: "rigyoku",
        traducao: "jade jasmin",
      },
    ],
  },
  {
    kanji: "汰",
    onYomi: [{ katakana: "タ", romaji: "ta" }],
    kunYomi: [],
    significado: "escolher; selecionar",
    exemplos: [
      {
        kanji: "淘汰",
        furigana: "とうた",
        romaji: "tōta",
        traducao: "seleção; eliminação",
      },
      {
        kanji: "汰流",
        furigana: "たりゅう",
        romaji: "taryū",
        traducao: "correnteza",
      },
      {
        kanji: "汰糠",
        furigana: "たぬか",
        romaji: "tanuka",
        traducao: "farelo flutuante",
      },
    ],
  },
  {
    kanji: "瑶",
    onYomi: [{ katakana: "ヨウ", romaji: "yō" }],
    kunYomi: [],
    significado: "jade; pedra preciosa",
    exemplos: [
      {
        kanji: "瑶池",
        furigana: "ようち",
        romaji: "yōchi",
        traducao: "lago de jade",
      },
      {
        kanji: "瑶山",
        furigana: "ようざん",
        romaji: "yōzan",
        traducao: "montanha de jade",
      },
      {
        kanji: "瑶姫",
        furigana: "ようひめ",
        romaji: "yōhime",
        traducao: "princesa de jade",
      },
    ],
  },
  {
    kanji: "誼",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [],
    significado: "amizade; camaradagem",
    exemplos: [
      {
        kanji: "友誼",
        furigana: "ゆうぎ",
        romaji: "yūgi",
        traducao: "amizade",
      },
      {
        kanji: "親誼",
        furigana: "しんぎ",
        romaji: "shingi",
        traducao: "relação próxima",
      },
      {
        kanji: "誼を通じる",
        furigana: "ぎをつうじる",
        romaji: "gi o tsūjiru",
        traducao: "cultivar uma amizade",
      },
    ],
  },
  {
    kanji: "冶",
    onYomi: [{ katakana: "ヤ", romaji: "ya" }],
    kunYomi: [],
    significado: "forjar; fundir",
    exemplos: [
      {
        kanji: "冶金",
        furigana: "やきん",
        romaji: "yakin",
        traducao: "metalurgia",
      },
      {
        kanji: "冶具",
        furigana: "やぐ",
        romaji: "yagu",
        traducao: "ferramentas de fundição",
      },
      {
        kanji: "冶炼",
        furigana: "やれん",
        romaji: "yaren",
        traducao: "fundição",
      },
    ],
  },
  {
    kanji: "栞",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "marcador de página",
    exemplos: [
      {
        kanji: "栞を挟む",
        furigana: "しおりをはさむ",
        romaji: "shiori o hasamu",
        traducao: "marcar uma página",
      },
      {
        kanji: "栞付け",
        furigana: "しおりづけ",
        romaji: "shiori-zuke",
        traducao: "marcador de página",
      },
      {
        kanji: "栞の色",
        furigana: "しおりのいろ",
        romaji: "shiori no iro",
        traducao: "cor do marcador de página",
      },
    ],
  },
  {
    kanji: "墾",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [],
    significado: "desenvolvimento; colonização",
    exemplos: [
      {
        kanji: "開墾",
        furigana: "かいこん",
        romaji: "kaikon",
        traducao: "desbravamento",
      },
      {
        kanji: "墾田",
        furigana: "こんでん",
        romaji: "konden",
        traducao: "terra desbravada",
      },
      {
        kanji: "農地墾設",
        furigana: "のうちこんせつ",
        romaji: "nōchi konsetsu",
        traducao: "desenvolvimento de terras agrícolas",
      },
    ],
  },
  {
    kanji: "瑳",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [],
    significado: "jade",
    exemplos: [
      {
        kanji: "瑳瑳",
        furigana: "ささ",
        romaji: "sasa",
        traducao: "brilho do jade",
      },
      {
        kanji: "瑳瑳とした",
        furigana: "ささとした",
        romaji: "sasa to shita",
        traducao: "resplandecente",
      },
      {
        kanji: "瑳瑳と輝く",
        furigana: "ささとかがやく",
        romaji: "sasa to kagayaku",
        traducao: "brilhar intensamente",
      },
    ],
  },
  {
    kanji: "耶",
    onYomi: [{ katakana: "ヤ", romaji: "ya" }],
    kunYomi: [],
    significado: "partícula interrogativa; final de interjeição",
    exemplos: [
      {
        kanji: "耶蘇",
        furigana: "ヤス",
        romaji: "Yasu",
        traducao: "Jesus",
      },
      {
        kanji: "耶悪",
        furigana: "ヤアク",
        romaji: "Yaaku",
        traducao: "mal",
      },
      {
        kanji: "耶と語る",
        furigana: "ヤとかたる",
        romaji: "Ya to kataru",
        traducao: "falar com entusiasmo",
      },
    ],
  },
  {
    kanji: "椰",
    onYomi: [{ katakana: "ヤ", romaji: "ya" }],
    kunYomi: [],
    significado: "coqueiro",
    exemplos: [
      {
        kanji: "椰子",
        furigana: "やし",
        romaji: "yashi",
        traducao: "coco",
      },
      {
        kanji: "椰子の実",
        furigana: "やしのみ",
        romaji: "yashi no mi",
        traducao: "polpa de coco",
      },
      {
        kanji: "椰子の木",
        furigana: "やしのき",
        romaji: "yashi no ki",
        traducao: "coqueiro",
      },
    ],
  },
  {
    kanji: "絃",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [],
    significado: "corda (de um instrumento musical)",
    exemplos: [
      {
        kanji: "琴絃",
        furigana: "ことげん",
        romaji: "kotogen",
        traducao: "corda do koto",
      },
      {
        kanji: "絃楽器",
        furigana: "げんがっき",
        romaji: "gengakki",
        traducao: "instrumento de cordas",
      },
      {
        kanji: "弦楽器",
        furigana: "げんがっき",
        romaji: "gengakki",
        traducao: "instrumento de cordas",
      },
    ],
  },
  {
    kanji: "丞",
    onYomi: [{ katakana: "ジョウ", romaji: "jō" }],
    kunYomi: [],
    significado: "ajudante; assistente",
    exemplos: [
      {
        kanji: "大丞",
        furigana: "だいじょう",
        romaji: "daijō",
        traducao: "assistente principal",
      },
      {
        kanji: "丞相",
        furigana: "じょうしょう",
        romaji: "jōshō",
        traducao: "chanceler",
      },
      {
        kanji: "内丞",
        furigana: "ないじょう",
        romaji: "naijō",
        traducao: "assistente interno",
      },
    ],
  },
  {
    kanji: "璃",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [],
    significado: "vidro; cristal",
    exemplos: [
      {
        kanji: "琉璃",
        furigana: "るり",
        romaji: "ruri",
        traducao: "vidro azul",
      },
      {
        kanji: "琉璃工芸",
        furigana: "るりこうげい",
        romaji: "ruri kōgei",
        traducao: "artesanato em vidro",
      },
      {
        kanji: "琉璃器",
        furigana: "るりき",
        romaji: "ruri ki",
        traducao: "utensílio de vidro",
      },
    ],
  },
  {
    kanji: "奎",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "constelação; asterismo",
    exemplos: [
      {
        kanji: "奎星",
        furigana: "きせい",
        romaji: "kisei",
        traducao: "estrela na constelação de Aquarius",
      },
      {
        kanji: "奎宿",
        furigana: "きゅうしゅく",
        romaji: "kyūshuku",
        traducao: "mansão lunar em Aquarius",
      },
      {
        kanji: "奎座",
        furigana: "きゅうざ",
        romaji: "kyūza",
        traducao: "constelação de Aquarius",
      },
    ],
  },
  {
    kanji: "塑",
    onYomi: [{ katakana: "ソ", romaji: "so" }],
    kunYomi: [{ katakana: "でる", romaji: "deru" }],
    significado: "modelar; esculpir",
    exemplos: [
      {
        kanji: "塑像",
        furigana: "そぞう",
        romaji: "sozō",
        traducao: "estátua",
      },
      {
        kanji: "塑造",
        furigana: "そぞう",
        romaji: "sozō",
        traducao: "escultura",
      },
      {
        kanji: "土塑",
        furigana: "どそ",
        romaji: "dosu",
        traducao: "escultura em barro",
      },
    ],
  },
  {
    kanji: "昂",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [],
    significado: "elevado; elevação",
    exemplos: [
      {
        kanji: "昂揚",
        furigana: "こうよう",
        romaji: "kōyō",
        traducao: "exaltação; elevação",
      },
      {
        kanji: "昂奮",
        furigana: "こうふん",
        romaji: "kōfun",
        traducao: "entusiasmo; exaltação",
      },
      {
        kanji: "昂然",
        furigana: "こうぜん",
        romaji: "kōzen",
        traducao: "com confiança",
      },
    ],
  },
  {
    kanji: "柾",
    onYomi: [],
    kunYomi: [{ katakana: "まさ", romaji: "masa" }],
    significado: "truncar; retalhar",
    exemplos: [
      {
        kanji: "柾木",
        furigana: "まさき",
        romaji: "masaki",
        traducao: "madeira cortada",
      },
      {
        kanji: "柾目",
        furigana: "まさめ",
        romaji: "masame",
        traducao: "direção do grão da madeira",
      },
      {
        kanji: "柾線",
        furigana: "まさせん",
        romaji: "masasen",
        traducao: "grão da madeira",
      },
    ],
  },
  {
    kanji: "熙",
    onYomi: [],
    kunYomi: [{ katakana: "あき", romaji: "aki" }],
    significado: "brilhante; esplêndido",
    exemplos: [
      {
        kanji: "熙熙",
        furigana: "きき",
        romaji: "kiki",
        traducao: "esplendoroso",
      },
      {
        kanji: "熙熙とした",
        furigana: "ききとした",
        romaji: "kiki to shita",
        traducao: "radiante",
      },
      {
        kanji: "熙熙たる",
        furigana: "ききたる",
        romaji: "kiki taru",
        traducao: "radiante",
      },
    ],
  },
  {
    kanji: "菫",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [{ katakana: "すみれ", romaji: "sumire" }],
    significado: "violeta; flor violeta",
    exemplos: [
      {
        kanji: "菫色",
        furigana: "すみれいろ",
        romaji: "sumireiro",
        traducao: "cor violeta",
      },
      {
        kanji: "菫草",
        furigana: "すみれぐさ",
        romaji: "sumiregusa",
        traducao: "flor violeta",
      },
      {
        kanji: "菫青",
        furigana: "すみれあお",
        romaji: "sumireao",
        traducao: "violeta claro",
      },
    ],
  },
  {
    kanji: "諒",
    onYomi: [{ katakana: "リョウ", romaji: "ryō" }],
    kunYomi: [{ katakana: "あきら", romaji: "akira" }],
    significado: "entendimento; compreensão",
    exemplos: [
      {
        kanji: "了諒",
        furigana: "りょうりょう",
        romaji: "ryōryō",
        traducao: "compreensão mútua",
      },
      {
        kanji: "諒解",
        furigana: "りょうかい",
        romaji: "ryōkai",
        traducao: "compreensão",
      },
      {
        kanji: "諒察",
        furigana: "りょうさつ",
        romaji: "ryōsatsu",
        traducao: "discernimento",
      },
    ],
  },
  {
    kanji: "鞠",
    onYomi: [{ katakana: "キク", romaji: "kiku" }],
    kunYomi: [],
    significado: "bola; esfera",
    exemplos: [
      {
        kanji: "鞠踏み",
        furigana: "まりふみ",
        romaji: "marifumi",
        traducao: "dança com bola",
      },
      {
        kanji: "鞠打ち",
        furigana: "まりうち",
        romaji: "mariuchi",
        traducao: "brincar com bola",
      },
      {
        kanji: "鞠",
        furigana: "まり",
        romaji: "mari",
        traducao: "bola; esfera",
      },
    ],
  },
  {
    kanji: "崚",
    onYomi: [],
    kunYomi: [],
    significado: "pico de montanha",
    exemplos: [
      {
        kanji: "崚峦",
        furigana: "れいれん",
        romaji: "reiren",
        traducao: "cadeia de montanhas",
      },
      {
        kanji: "崚々",
        furigana: "りょうりょう",
        romaji: "ryōryō",
        traducao: "imponente",
      },
      {
        kanji: "崚嶺",
        furigana: "れいれい",
        romaji: "reirei",
        traducao: "cadeia de montanhas",
      },
    ],
  },
  {
    kanji: "濫",
    onYomi: [],
    kunYomi: [{ katakana: "みだ", romaji: "mida" }],
    significado: "excesso; indiscriminado",
    exemplos: [
      {
        kanji: "濫用",
        furigana: "らんよう",
        romaji: "ran'yō",
        traducao: "uso excessivo",
      },
      {
        kanji: "濫発",
        furigana: "らんぱつ",
        romaji: "ranpatsu",
        traducao: "disparo indiscriminado",
      },
      {
        kanji: "濫墾",
        furigana: "らんこん",
        romaji: "rankon",
        traducao: "desmatamento indiscriminado",
      },
    ],
  },
  {
    kanji: "捷",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [],
    significado: "rápido; ágil",
    exemplos: [
      {
        kanji: "捷報",
        furigana: "しょうほう",
        romaji: "shōhō",
        traducao: "notícia rápida",
      },
      {
        kanji: "捷速",
        furigana: "しょうそく",
        romaji: "shōsoku",
        traducao: "alta velocidade",
      },
      {
        kanji: "捷径",
        furigana: "しょうけい",
        romaji: "shōkei",
        traducao: "atalho; rota rápida",
      },
    ],
  },
];

export default n1KanjiList;
