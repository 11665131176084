const n5KanjiList = [
  {
    kanji: "一",
    onYomi: [{ katakana: "イチ", romaji: "ichi" }],
    kunYomi: [
      { hiragana: "ひと", romaji: "hito" },
      { hiragana: "ひとつ", romaji: "hitotsu" },
    ],
    significado: "um",
    exemplos: [
      {
        kanji: "一人",
        furigana: "ひとり",
        romaji: "hitori",
        traducao: "uma pessoa",
      },
      {
        kanji: "一つ",
        furigana: "ひとつ",
        romaji: "hitotsu",
        traducao: "um (coisa)",
      },
      {
        kanji: "一度",
        furigana: "いちど",
        romaji: "ichido",
        traducao: "uma vez",
      },
    ],
  },
  {
    kanji: "人",
    onYomi: [{ katakana: "ジン", romaji: "jin" }],
    kunYomi: [{ hiragana: "ひと", romaji: "hito" }],
    significado: "pessoa",
    exemplos: [
      {
        kanji: "日本人",
        furigana: "にほんじん",
        romaji: "nihonjin",
        traducao: "pessoa japonesa",
      },
      {
        kanji: "学生",
        furigana: "がくせい",
        romaji: "gakusei",
        traducao: "estudante",
      },
      {
        kanji: "友達",
        furigana: "ともだち",
        romaji: "tomodachi",
        traducao: "amigo",
      },
    ],
  },
  {
    kanji: "十",
    onYomi: [{ katakana: "ジュウ", romaji: "jū" }],
    kunYomi: [{ hiragana: "とお", romaji: "tō" }],
    significado: "dez",
    exemplos: [
      {
        kanji: "十分",
        furigana: "じゅうぶん",
        romaji: "jūbun",
        traducao: "suficiente",
      },
      {
        kanji: "十日",
        furigana: "とおか",
        romaji: "tōka",
        traducao: "dez dias",
      },
      {
        kanji: "十時",
        furigana: "じゅうじ",
        romaji: "jūji",
        traducao: "dez horas",
      },
    ],
  },
  {
    kanji: "二",
    onYomi: [{ katakana: "ニ", romaji: "ni" }],
    kunYomi: [{ hiragana: "ふた", romaji: "futa" }],
    significado: "dois",
    exemplos: [
      {
        kanji: "二人",
        furigana: "ふたり",
        romaji: "futari",
        traducao: "duas pessoas",
      },
      {
        kanji: "二つ",
        furigana: "ふたつ",
        romaji: "futatsu",
        traducao: "dois (coisas)",
      },
      {
        kanji: "二番目",
        furigana: "にばんめ",
        romaji: "nibanme",
        traducao: "segundo",
      },
    ],
  },
  {
    kanji: "九",
    onYomi: [{ katakana: "キュウ", romaji: "kyū" }],
    kunYomi: [{ hiragana: "ここの", romaji: "kokono" }],
    significado: "nove",
    exemplos: [
      {
        kanji: "九つ",
        furigana: "ここのつ",
        romaji: "kokonotsu",
        traducao: "nove (coisas)",
      },
      {
        kanji: "九月",
        furigana: "くがつ",
        romaji: "kugatsu",
        traducao: "setembro",
      },
      {
        kanji: "九人",
        furigana: "きゅうにん",
        romaji: "kyūnin",
        traducao: "nove pessoas",
      },
    ],
  },
  {
    kanji: "入",
    onYomi: [{ katakana: "ニュウ", romaji: "nyū" }],
    kunYomi: [
      { hiragana: "い", romaji: "i" },
      { hiragana: "はい", romaji: "hai" },
    ],
    significado: "entrar",
    exemplos: [
      {
        kanji: "入口",
        furigana: "いりぐち",
        romaji: "iriguchi",
        traducao: "entrada",
      },
      {
        kanji: "入学",
        furigana: "にゅうがく",
        romaji: "nyūgaku",
        traducao: "matrícula na escola",
      },
      {
        kanji: "入れる",
        furigana: "いれる",
        romaji: "ireru",
        traducao: "colocar dentro",
      },
    ],
  },
  {
    kanji: "八",
    onYomi: [{ katakana: "ハチ", romaji: "hachi" }],
    kunYomi: [{ hiragana: "や", romaji: "ya" }],
    significado: "oito",
    exemplos: [
      {
        kanji: "八つ",
        furigana: "やっつ",
        romaji: "yattsu",
        traducao: "oito (coisas)",
      },
      {
        kanji: "八時",
        furigana: "はちじ",
        romaji: "hachiji",
        traducao: "oito horas",
      },
      {
        kanji: "八百屋",
        furigana: "やおや",
        romaji: "yaoya",
        traducao: "mercearia",
      },
    ],
  },
  {
    kanji: "七",
    onYomi: [{ katakana: "シチ", romaji: "shichi" }],
    kunYomi: [{ hiragana: "なな", romaji: "nana" }],
    significado: "sete",
    exemplos: [
      {
        kanji: "七つ",
        furigana: "ななつ",
        romaji: "nanatsu",
        traducao: "sete (coisas)",
      },
      {
        kanji: "七月",
        furigana: "しちがつ",
        romaji: "shichigatsu",
        traducao: "julho",
      },
      {
        kanji: "七人",
        furigana: "しちにん",
        romaji: "shichinin",
        traducao: "sete pessoas",
      },
    ],
  },
  {
    kanji: "大",
    onYomi: [{ katakana: "ダイ", romaji: "dai" }],
    kunYomi: [{ hiragana: "おお", romaji: "ō" }],
    significado: "grande",
    exemplos: [
      {
        kanji: "大きい",
        furigana: "おおきい",
        romaji: "ōkii",
        traducao: "grande",
      },
      {
        kanji: "大学",
        furigana: "だいがく",
        romaji: "daigaku",
        traducao: "universidade",
      },
      {
        kanji: "大人",
        furigana: "おとな",
        romaji: "otona",
        traducao: "adulto",
      },
    ],
  },
  {
    kanji: "三",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [{ hiragana: "み", romaji: "mi" }],
    significado: "três",
    exemplos: [
      {
        kanji: "三つ",
        furigana: "みっつ",
        romaji: "mittsu",
        traducao: "três (coisas)",
      },
      {
        kanji: "三月",
        furigana: "さんがつ",
        romaji: "sangatsu",
        traducao: "março",
      },
      {
        kanji: "三人",
        furigana: "さんにん",
        romaji: "sannin",
        traducao: "três pessoas",
      },
    ],
  },
  {
    kanji: "上",
    onYomi: [{ katakana: "ジョウ", romaji: "jō" }],
    kunYomi: [{ hiragana: "うえ", romaji: "ue" }],
    significado: "acima",
    exemplos: [
      {
        kanji: "上手",
        furigana: "じょうず",
        romaji: "jōzu",
        traducao: "habilidoso",
      },
      {
        kanji: "上着",
        furigana: "うわぎ",
        romaji: "uwagi",
        traducao: "casaco",
      },
      {
        kanji: "上る",
        furigana: "のぼる",
        romaji: "noboru",
        traducao: "subir",
      },
    ],
  },
  {
    kanji: "入",
    onYomi: [{ katakana: "ニュウ", romaji: "nyū" }],
    kunYomi: [
      { hiragana: "い", romaji: "i" },
      { hiragana: "はい", romaji: "hai" },
    ],
    significado: "entrar",
    exemplos: [
      {
        kanji: "入口",
        furigana: "いりぐち",
        romaji: "iriguchi",
        traducao: "entrada",
      },
      {
        kanji: "入学",
        furigana: "にゅうがく",
        romaji: "nyūgaku",
        traducao: "matrícula na escola",
      },
      {
        kanji: "入れる",
        furigana: "いれる",
        romaji: "ireru",
        traducao: "colocar dentro",
      },
    ],
  },
  {
    kanji: "子",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "こ", romaji: "ko" }],
    significado: "criança",
    exemplos: [
      {
        kanji: "子供",
        furigana: "こども",
        romaji: "kodomo",
        traducao: "criança",
      },
      {
        kanji: "女の子",
        furigana: "おんなのこ",
        romaji: "onna no ko",
        traducao: "menina",
      },
      {
        kanji: "子犬",
        furigana: "こいぬ",
        romaji: "koinu",
        traducao: "filhote de cachorro",
      },
    ],
  },
  {
    kanji: "下",
    onYomi: [
      { katakana: "カ", romaji: "ka" },
      { katakana: "ゲ", romaji: "ge" },
    ],
    kunYomi: [
      { hiragana: "した", romaji: "shita" },
      { hiragana: "もと", romaji: "moto" },
    ],
    significado: "abaixo",
    exemplos: [
      {
        kanji: "下手",
        furigana: "へた",
        romaji: "heta",
        traducao: "inexperiente",
      },
      {
        kanji: "下着",
        furigana: "したぎ",
        romaji: "shitagi",
        traducao: "roupa íntima",
      },
      {
        kanji: "下りる",
        furigana: "おりる",
        romaji: "oriru",
        traducao: "descer",
      },
    ],
  },
  {
    kanji: "小",
    onYomi: [{ katakana: "ショウ", romaji: "shō" }],
    kunYomi: [
      { hiragana: "ちい", romaji: "chii" },
      { hiragana: "こ", romaji: "ko" },
    ],
    significado: "pequeno",
    exemplos: [
      {
        kanji: "小さい",
        furigana: "ちいさい",
        romaji: "chīsai",
        traducao: "pequeno",
      },
      {
        kanji: "小説",
        furigana: "しょうせつ",
        romaji: "shōsetsu",
        traducao: "romance",
      },
      {
        kanji: "小鳥",
        furigana: "ことり",
        romaji: "kotori",
        traducao: "pássaro",
      },
    ],
  },
  {
    kanji: "山",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [{ hiragana: "やま", romaji: "yama" }],
    significado: "montanha",
    exemplos: [
      { kanji: "山", furigana: "やま", romaji: "yama", traducao: "montanha" },
      {
        kanji: "山田",
        furigana: "やまだ",
        romaji: "Yamada",
        traducao: "sobrenome Yamada",
      },
      {
        kanji: "山口",
        furigana: "やまぐち",
        romaji: "Yamaguchi",
        traducao: "sobrenome Yamaguchi",
      },
    ],
  },
  {
    kanji: "女",
    onYomi: [
      { katakana: "ジョ", romaji: "jo" },
      { katakana: "ニョ", romaji: "nyo" },
    ],
    kunYomi: [{ hiragana: "おんな", romaji: "onna" }],
    significado: "mulher",
    exemplos: [
      {
        kanji: "女性",
        furigana: "じょせい",
        romaji: "josei",
        traducao: "mulher",
      },
      {
        kanji: "女の人",
        furigana: "おんなのひと",
        romaji: "onna no hito",
        traducao: "mulher",
      },
      {
        kanji: "女子",
        furigana: "じょし",
        romaji: "joshi",
        traducao: "menina, garota",
      },
    ],
  },
  {
    kanji: "川",
    onYomi: [
      { katakana: "セン", romaji: "sen" },
      { katakana: "ガワ", romaji: "gawa" },
    ],
    kunYomi: [{ hiragana: "かわ", romaji: "kawa" }],
    significado: "rio",
    exemplos: [
      { kanji: "川", furigana: "かわ", romaji: "kawa", traducao: "rio" },
      {
        kanji: "川辺",
        furigana: "かわべ",
        romaji: "kawabe",
        traducao: "beira do rio",
      },
      {
        kanji: "川岸",
        furigana: "かわぎし",
        romaji: "kawagishi",
        traducao: "margem do rio",
      },
    ],
  },
  {
    kanji: "千",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "ち", romaji: "chi" }],
    significado: "mil",
    exemplos: [
      {
        kanji: "千円",
        furigana: "せんえん",
        romaji: "sen'en",
        traducao: "mil ienes",
      },
      {
        kanji: "千人",
        furigana: "せんにん",
        romaji: "sennin",
        traducao: "mil pessoas",
      },
      {
        kanji: "千足虫",
        furigana: "むかでむし",
        romaji: "mukade mushi",
        traducao: "milípede",
      },
    ],
  },
  {
    kanji: "土",
    onYomi: [{ katakana: "ド", romaji: "do" }],
    kunYomi: [{ hiragana: "つち", romaji: "tsuchi" }],
    significado: "terra",
    exemplos: [
      { kanji: "土地", furigana: "とち", romaji: "tochi", traducao: "terreno" },
      {
        kanji: "土曜日",
        furigana: "どようび",
        romaji: "doyōbi",
        traducao: "sábado",
      },
      { kanji: "土壌", furigana: "どじょう", romaji: "dojō", traducao: "solo" },
    ],
  },
  {
    kanji: "万",
    onYomi: [{ katakana: "マン", romaji: "man" }],
    kunYomi: [{ hiragana: "よろず", romaji: "yorozu" }],
    significado: "dez mil",
    exemplos: [
      {
        kanji: "万歳",
        furigana: "ばんざい",
        romaji: "banzai",
        traducao: "vivas!",
      },
      {
        kanji: "万年筆",
        furigana: "まんねんひつ",
        romaji: "mannenhitsu",
        traducao: "caneta-tinteiro",
      },
      {
        kanji: "万華鏡",
        furigana: "まんげきょう",
        romaji: "mangekyō",
        traducao: "caleidoscópio",
      },
    ],
  },
  {
    kanji: "日",
    onYomi: [
      { katakana: "ニチ", romaji: "nichi" },
      { katakana: "ジツ", romaji: "jitsu" },
    ],
    kunYomi: [{ hiragana: "ひ", romaji: "hi" }],
    significado: "dia",
    exemplos: [
      { kanji: "日本", furigana: "にほん", romaji: "Nihon", traducao: "Japão" },
      {
        kanji: "日曜日",
        furigana: "にちようび",
        romaji: "nichiyōbi",
        traducao: "domingo",
      },
      {
        kanji: "日記",
        furigana: "にっき",
        romaji: "nikki",
        traducao: "diário",
      },
    ],
  },
  {
    kanji: "中",
    onYomi: [{ katakana: "チュウ", romaji: "chū" }],
    kunYomi: [{ hiragana: "なか", romaji: "naka" }],
    significado: "meio",
    exemplos: [
      {
        kanji: "中国",
        furigana: "ちゅうごく",
        romaji: "Chūgoku",
        traducao: "China",
      },
      {
        kanji: "中学校",
        furigana: "ちゅうがっこう",
        romaji: "chūgakkō",
        traducao: "escola secundária",
      },
      {
        kanji: "中央",
        furigana: "ちゅうおう",
        romaji: "chūō",
        traducao: "central",
      },
    ],
  },
  {
    kanji: "月",
    onYomi: [{ katakana: "ゲツ", romaji: "getsu" }],
    kunYomi: [{ hiragana: "つき", romaji: "tsuki" }],
    significado: "mês",
    exemplos: [
      {
        kanji: "月曜日",
        furigana: "げつようび",
        romaji: "getsuyōbi",
        traducao: "segunda-feira",
      },
      {
        kanji: "月光",
        furigana: "げっこう",
        romaji: "gekkō",
        traducao: "luz da lua",
      },
      {
        kanji: "月刊",
        furigana: "げっかん",
        romaji: "gekkan",
        traducao: "mensal",
      },
    ],
  },
  {
    kanji: "五",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [{ hiragana: "いつ", romaji: "itsu" }],
    significado: "cinco",
    exemplos: [
      {
        kanji: "五つ",
        furigana: "いつつ",
        romaji: "itsutsu",
        traducao: "cinco",
      },
      {
        kanji: "五月",
        furigana: "ごがつ",
        romaji: "gogatsu",
        traducao: "maio",
      },
      {
        kanji: "五日",
        furigana: "いつか",
        romaji: "itsuka",
        traducao: "dia cinco",
      },
    ],
  },
  {
    kanji: "円",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [
      { hiragana: "まる", romaji: "maru" },
      { hiragana: "えん", romaji: "en" },
    ],
    significado: "iene",
    exemplos: [
      { kanji: "円", furigana: "えん", romaji: "en", traducao: "iene" },
      {
        kanji: "円形",
        furigana: "えんけい",
        romaji: "enkei",
        traducao: "formato circular",
      },
      {
        kanji: "円高",
        furigana: "えんだか",
        romaji: "endaka",
        traducao: "valorização do iene",
      },
    ],
  },
  {
    kanji: "六",
    onYomi: [{ katakana: "ロク", romaji: "roku" }],
    kunYomi: [{ hiragana: "む", romaji: "mu" }],
    significado: "seis",
    exemplos: [
      { kanji: "六つ", furigana: "むっつ", romaji: "muttsu", traducao: "seis" },
      {
        kanji: "六月",
        furigana: "ろくがつ",
        romaji: "rokugatsu",
        traducao: "junho",
      },
      {
        kanji: "六本木",
        furigana: "ろっぽんぎ",
        romaji: "roppongi",
        traducao: "Roppongi (bairro em Tóquio)",
      },
    ],
  },
  {
    kanji: "今",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [{ hiragana: "いま", romaji: "ima" }],
    significado: "agora",
    exemplos: [
      { kanji: "今日", furigana: "きょう", romaji: "kyō", traducao: "hoje" },
      {
        kanji: "今晩",
        furigana: "こんばん",
        romaji: "konban",
        traducao: "esta noite",
      },
      {
        kanji: "今週",
        furigana: "こんしゅう",
        romaji: "konshū",
        traducao: "esta semana",
      },
    ],
  },
  {
    kanji: "水",
    onYomi: [{ katakana: "スイ", romaji: "sui" }],
    kunYomi: [{ hiragana: "みず", romaji: "mizu" }],
    significado: "água",
    exemplos: [
      { kanji: "水", furigana: "みず", romaji: "mizu", traducao: "água" },
      {
        kanji: "水曜日",
        furigana: "すいようび",
        romaji: "suiyōbi",
        traducao: "quarta-feira",
      },
      {
        kanji: "水族館",
        furigana: "すいぞくかん",
        romaji: "suizokukan",
        traducao: "aquário",
      },
    ],
  },
  {
    kanji: "午",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [{ hiragana: "うま", romaji: "uma" }],
    significado: "meio-dia",
    exemplos: [
      { kanji: "午後", furigana: "ごご", romaji: "gogo", traducao: "tarde" },
      { kanji: "午前", furigana: "ごぜん", romaji: "gozen", traducao: "manhã" },
      {
        kanji: "正午",
        furigana: "しょうご",
        romaji: "shōgo",
        traducao: "meio-dia",
      },
    ],
  },
  {
    kanji: "木",
    onYomi: [{ katakana: "ボク", romaji: "boku" }],
    kunYomi: [{ hiragana: "き", romaji: "ki" }],
    significado: "árvore",
    exemplos: [
      { kanji: "木", furigana: "き", romaji: "ki", traducao: "árvore" },
      {
        kanji: "木曜日",
        furigana: "もくようび",
        romaji: "mokuyōbi",
        traducao: "quinta-feira",
      },
      {
        kanji: "木漏れ日",
        furigana: "こもれび",
        romaji: "komorebi",
        traducao: "luz do sol filtrada pelas árvores",
      },
    ],
  },
  {
    kanji: "天",
    onYomi: [{ katakana: "テン", romaji: "ten" }],
    kunYomi: [
      { hiragana: "あま", romaji: "ama" },
      { hiragana: "てん", romaji: "ten" },
    ],
    significado: "céu",
    exemplos: [
      { kanji: "天", furigana: "てん", romaji: "ten", traducao: "céu" },
      {
        kanji: "天気",
        furigana: "てんき",
        romaji: "tenki",
        traducao: "tempo (clima)",
      },
      {
        kanji: "天才",
        furigana: "てんさい",
        romaji: "tensai",
        traducao: "gênio",
      },
    ],
  },
  {
    kanji: "火",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "ひ", romaji: "hi" }],
    significado: "fogo",
    exemplos: [
      { kanji: "火", furigana: "ひ", romaji: "hi", traducao: "fogo" },
      {
        kanji: "火曜日",
        furigana: "かようび",
        romaji: "kayōbi",
        traducao: "terça-feira",
      },
      { kanji: "火事", furigana: "かじ", romaji: "kaji", traducao: "incêndio" },
    ],
  },
  {
    kanji: "友",
    onYomi: [{ katakana: "ユウ", romaji: "yū" }],
    kunYomi: [{ hiragana: "とも", romaji: "tomo" }],
    significado: "amigo",
    exemplos: [
      {
        kanji: "友達",
        furigana: "ともだち",
        romaji: "tomodachi",
        traducao: "amigo",
      },
      {
        kanji: "友情",
        furigana: "ゆうじょう",
        romaji: "yūjō",
        traducao: "amizade",
      },
      {
        kanji: "友人",
        furigana: "ゆうじん",
        romaji: "yūjin",
        traducao: "amigo (pessoa)",
      },
    ],
  },
  {
    kanji: "父",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "ちち", romaji: "chichi" }],
    significado: "pai",
    exemplos: [
      { kanji: "父", furigana: "ちち", romaji: "chichi", traducao: "pai" },
      {
        kanji: "父親",
        furigana: "ちちおや",
        romaji: "chichioya",
        traducao: "pai (pai biológico)",
      },
      {
        kanji: "父母",
        furigana: "ふぼ",
        romaji: "fubo",
        traducao: "pais (pai e mãe)",
      },
    ],
  },
  {
    kanji: "本",
    onYomi: [{ katakana: "ホン", romaji: "hon" }],
    kunYomi: [
      { hiragana: "もと", romaji: "moto" },
      { hiragana: "ほん", romaji: "hon" },
    ],
    significado: "livro",
    exemplos: [
      { kanji: "本", furigana: "もと", romaji: "moto", traducao: "origem" },
      { kanji: "本", furigana: "ほん", romaji: "hon", traducao: "livro" },
      {
        kanji: "本棚",
        furigana: "ほんだな",
        romaji: "hondana",
        traducao: "estante de livros",
      },
    ],
  },
  {
    kanji: "出",
    onYomi: [{ katakana: "シュツ", romaji: "shutsu" }],
    kunYomi: [{ hiragana: "で", romaji: "de" }],
    significado: "sair",
    exemplos: [
      { kanji: "出る", furigana: "でる", romaji: "deru", traducao: "sair" },
      {
        kanji: "出口",
        furigana: "でぐち",
        romaji: "deguchi",
        traducao: "saída",
      },
      {
        kanji: "出席",
        furigana: "しゅっせき",
        romaji: "shusseki",
        traducao: "comparecimento",
      },
    ],
  },
  {
    kanji: "生",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [
      { hiragana: "いきる", romaji: "ikiru" },
      { hiragana: "なま", romaji: "nama" },
    ],
    significado: "vida",
    exemplos: [
      {
        kanji: "生きる",
        furigana: "いきる",
        romaji: "ikiru",
        traducao: "viver",
      },
      { kanji: "生徒", furigana: "せいと", romaji: "seito", traducao: "aluno" },
      {
        kanji: "生活",
        furigana: "せいかつ",
        romaji: "seikatsu",
        traducao: "vida cotidiana",
      },
    ],
  },
  {
    kanji: "四",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "よん", romaji: "yon" }],
    significado: "quatro",
    exemplos: [
      {
        kanji: "四つ",
        furigana: "よっつ",
        romaji: "yottsu",
        traducao: "quatro",
      },
      {
        kanji: "四月",
        furigana: "しがつ",
        romaji: "shigatsu",
        traducao: "abril",
      },
      {
        kanji: "四角",
        furigana: "しかく",
        romaji: "shikaku",
        traducao: "quadrado",
      },
    ],
  },
  {
    kanji: "外",
    onYomi: [{ katakana: "ガイ", romaji: "gai" }],
    kunYomi: [{ hiragana: "そと", romaji: "soto" }],
    significado: "fora",
    exemplos: [
      { kanji: "外", furigana: "そと", romaji: "soto", traducao: "fora" },
      {
        kanji: "外国",
        furigana: "がいこく",
        romaji: "gaikoku",
        traducao: "país estrangeiro",
      },
      {
        kanji: "外食",
        furigana: "がいしょく",
        romaji: "gaishoku",
        traducao: "comer fora",
      },
    ],
  },
  {
    kanji: "北",
    onYomi: [{ katakana: "ホク", romaji: "hoku" }],
    kunYomi: [{ hiragana: "きた", romaji: "kita" }],
    significado: "norte",
    exemplos: [
      { kanji: "北", furigana: "きた", romaji: "kita", traducao: "norte" },
      {
        kanji: "北海道",
        furigana: "ほっかいどう",
        romaji: "Hokkaidō",
        traducao: "Hokkaido",
      },
      {
        kanji: "北側",
        furigana: "きたがわ",
        romaji: "kitagawa",
        traducao: "lado norte",
      },
    ],
  },
  {
    kanji: "半",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "なか", romaji: "naka" }],
    significado: "metade",
    exemplos: [
      {
        kanji: "半分",
        furigana: "はんぶん",
        romaji: "hanbun",
        traducao: "metade",
      },
      {
        kanji: "午後半",
        furigana: "ごごはん",
        romaji: "gogohan",
        traducao: "meio-dia",
      },
      {
        kanji: "半年",
        furigana: "はんとし",
        romaji: "hantoshi",
        traducao: "meio ano",
      },
    ],
  },
  {
    kanji: "白",
    onYomi: [{ katakana: "ハク", romaji: "haku" }],
    kunYomi: [{ hiragana: "しろ", romaji: "shiro" }],
    significado: "branco",
    exemplos: [
      {
        kanji: "白い",
        furigana: "しろい",
        romaji: "shiroi",
        traducao: "branco",
      },
      {
        kanji: "白紙",
        furigana: "はくし",
        romaji: "hakushi",
        traducao: "papel em branco",
      },
      {
        kanji: "白鳥",
        furigana: "はくちょう",
        romaji: "hakuchō",
        traducao: "cisne",
      },
    ],
  },
  {
    kanji: "右",
    onYomi: [{ katakana: "ウ", romaji: "u" }],
    kunYomi: [{ hiragana: "みぎ", romaji: "migi" }],
    significado: "direita",
    exemplos: [
      { kanji: "右", furigana: "みぎ", romaji: "migi", traducao: "direita" },
      {
        kanji: "右手",
        furigana: "みぎて",
        romaji: "migite",
        traducao: "mão direita",
      },
      {
        kanji: "右折",
        furigana: "うせつ",
        romaji: "usetsu",
        traducao: "virar à direita",
      },
    ],
  },
  {
    kanji: "左",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [{ hiragana: "ひだり", romaji: "hidari" }],
    significado: "esquerda",
    exemplos: [
      {
        kanji: "左",
        furigana: "ひだり",
        romaji: "hidari",
        traducao: "esquerda",
      },
      {
        kanji: "左手",
        furigana: "ひだりて",
        romaji: "hidarite",
        traducao: "mão esquerda",
      },
      {
        kanji: "左折",
        furigana: "させつ",
        romaji: "sasetsu",
        traducao: "virar à esquerda",
      },
    ],
  },
  {
    kanji: "母",
    onYomi: [{ katakana: "ボ", romaji: "bo" }],
    kunYomi: [{ hiragana: "はは", romaji: "haha" }],
    significado: "mãe",
    exemplos: [
      { kanji: "母", furigana: "はは", romaji: "haha", traducao: "mãe" },
      {
        kanji: "母国",
        furigana: "ぼこく",
        romaji: "bokoku",
        traducao: "país natal",
      },
      {
        kanji: "母校",
        furigana: "ぼこう",
        romaji: "bokō",
        traducao: "escola onde se formou",
      },
    ],
  },
  {
    kanji: "年",
    onYomi: [{ katakana: "ネン", romaji: "nen" }],
    kunYomi: [{ hiragana: "とし", romaji: "toshi" }],
    significado: "ano",
    exemplos: [
      { kanji: "年", furigana: "とし", romaji: "toshi", traducao: "ano" },
      {
        kanji: "今年",
        furigana: "ことし",
        romaji: "kotoshi",
        traducao: "este ano",
      },
      {
        kanji: "来年",
        furigana: "らいねん",
        romaji: "rainen",
        traducao: "próximo ano",
      },
    ],
  },
  {
    kanji: "行",
    onYomi: [
      { katakana: "コウ", romaji: "kō" },
      { katakana: "ギョウ", romaji: "gyō" },
    ],
    kunYomi: [
      { hiragana: "いく", romaji: "iku" },
      { hiragana: "おこなう", romaji: "okonau" },
    ],
    significado: "ir",
    exemplos: [
      { kanji: "行く", furigana: "いく", romaji: "iku", traducao: "ir" },
      {
        kanji: "旅行",
        furigana: "りょこう",
        romaji: "ryokō",
        traducao: "viagem",
      },
      {
        kanji: "行動",
        furigana: "こうどう",
        romaji: "kōdō",
        traducao: "comportamento",
      },
    ],
  },
  {
    kanji: "気",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "き", romaji: "ki" }],
    significado: "espírito",
    exemplos: [
      {
        kanji: "元気",
        furigana: "げんき",
        romaji: "genki",
        traducao: "energia",
      },
      {
        kanji: "気持ち",
        furigana: "きもち",
        romaji: "kimochi",
        traducao: "sentimento",
      },
      { kanji: "気分", furigana: "きぶん", romaji: "kibun", traducao: "humor" },
    ],
  },
  {
    kanji: "百",
    onYomi: [{ katakana: "ヒャク", romaji: "hyaku" }],
    kunYomi: [{ hiragana: "もも", romaji: "mom" }],
    significado: "cem",
    exemplos: [
      { kanji: "百", furigana: "ひゃく", romaji: "hyaku", traducao: "cem" },
      {
        kanji: "百円",
        furigana: "ひゃくえん",
        romaji: "hyaku-en",
        traducao: "cem ienes",
      },
      {
        kanji: "百科事典",
        furigana: "ひゃっかじてん",
        romaji: "hyakka-jiten",
        traducao: "enciclopédia",
      },
    ],
  },
  {
    kanji: "先",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "さき", romaji: "saki" }],
    significado: "antes",
    exemplos: [
      { kanji: "先", furigana: "さき", romaji: "saki", traducao: "antes" },
      {
        kanji: "先週",
        furigana: "せんしゅう",
        romaji: "senshū",
        traducao: "semana passada",
      },
      {
        kanji: "先生",
        furigana: "せんせい",
        romaji: "sensei",
        traducao: "professor",
      },
    ],
  },
  {
    kanji: "名",
    onYomi: [{ katakana: "メイ", romaji: "mei" }],
    kunYomi: [{ hiragana: "な", romaji: "na" }],
    significado: "nome",
    exemplos: [
      { kanji: "名前", furigana: "なまえ", romaji: "namae", traducao: "nome" },
      {
        kanji: "有名",
        furigana: "ゆうめい",
        romaji: "yūmei",
        traducao: "famoso",
      },
      {
        kanji: "名刺",
        furigana: "めいし",
        romaji: "meishi",
        traducao: "cartão de visita",
      },
    ],
  },
  {
    kanji: "西",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "にし", romaji: "nishi" }],
    significado: "oeste",
    exemplos: [
      { kanji: "西", furigana: "にし", romaji: "nishi", traducao: "oeste" },
      {
        kanji: "西洋",
        furigana: "せいよう",
        romaji: "seiyo",
        traducao: "ocidente",
      },
      {
        kanji: "西日",
        furigana: "にしび",
        romaji: "nishibi",
        traducao: "sol poente",
      },
    ],
  },
  {
    kanji: "休",
    onYomi: [{ katakana: "キュウ", romaji: "kyū" }],
    kunYomi: [{ hiragana: "やす", romaji: "yasu" }],
    significado: "descansar",
    exemplos: [
      {
        kanji: "休み",
        furigana: "やすみ",
        romaji: "yasumi",
        traducao: "descanso",
      },
      {
        kanji: "休日",
        furigana: "きゅうじつ",
        romaji: "kyūjitsu",
        traducao: "dia de folga",
      },
      {
        kanji: "休憩",
        furigana: "きゅうけい",
        romaji: "kyūkei",
        traducao: "pausa",
      },
    ],
  },
  {
    kanji: "気",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "き", romaji: "ki" }],
    significado: "espírito",
    exemplos: [
      {
        kanji: "元気",
        furigana: "げんき",
        romaji: "genki",
        traducao: "energia",
      },
      {
        kanji: "気持ち",
        furigana: "きもち",
        romaji: "kimochi",
        traducao: "sentimento",
      },
      { kanji: "気分", furigana: "きぶん", romaji: "kibun", traducao: "humor" },
    ],
  },
  {
    kanji: "百",
    onYomi: [{ katakana: "ヒャク", romaji: "hyaku" }],
    kunYomi: [{ hiragana: "もも", romaji: "mom" }],
    significado: "cem",
    exemplos: [
      { kanji: "百", furigana: "ひゃく", romaji: "hyaku", traducao: "cem" },
      {
        kanji: "百円",
        furigana: "ひゃくえん",
        romaji: "hyaku-en",
        traducao: "cem ienes",
      },
      {
        kanji: "百科事典",
        furigana: "ひゃっかじてん",
        romaji: "hyakka-jiten",
        traducao: "enciclopédia",
      },
    ],
  },
  {
    kanji: "先",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "さき", romaji: "saki" }],
    significado: "antes",
    exemplos: [
      { kanji: "先", furigana: "さき", romaji: "saki", traducao: "antes" },
      {
        kanji: "先週",
        furigana: "せんしゅう",
        romaji: "senshū",
        traducao: "semana passada",
      },
      {
        kanji: "先生",
        furigana: "せんせい",
        romaji: "sensei",
        traducao: "professor",
      },
    ],
  },
  {
    kanji: "名",
    onYomi: [{ katakana: "メイ", romaji: "mei" }],
    kunYomi: [{ hiragana: "な", romaji: "na" }],
    significado: "nome",
    exemplos: [
      { kanji: "名前", furigana: "なまえ", romaji: "namae", traducao: "nome" },
      {
        kanji: "有名",
        furigana: "ゆうめい",
        romaji: "yūmei",
        traducao: "famoso",
      },
      {
        kanji: "名刺",
        furigana: "めいし",
        romaji: "meishi",
        traducao: "cartão de visita",
      },
    ],
  },
  {
    kanji: "西",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "にし", romaji: "nishi" }],
    significado: "oeste",
    exemplos: [
      { kanji: "西", furigana: "にし", romaji: "nishi", traducao: "oeste" },
      {
        kanji: "西洋",
        furigana: "せいよう",
        romaji: "seiyo",
        traducao: "ocidente",
      },
      {
        kanji: "西日",
        furigana: "にしび",
        romaji: "nishibi",
        traducao: "sol poente",
      },
    ],
  },
  {
    kanji: "休",
    onYomi: [{ katakana: "キュウ", romaji: "kyū" }],
    kunYomi: [{ hiragana: "やす", romaji: "yasu" }],
    significado: "descansar",
    exemplos: [
      {
        kanji: "休み",
        furigana: "やすみ",
        romaji: "yasumi",
        traducao: "descanso",
      },
      {
        kanji: "休日",
        furigana: "きゅうじつ",
        romaji: "kyūjitsu",
        traducao: "dia de folga",
      },
      {
        kanji: "休憩",
        furigana: "きゅうけい",
        romaji: "kyūkei",
        traducao: "pausa",
      },
    ],
  },
  {
    kanji: "毎",
    onYomi: [{ katakana: "マイ", romaji: "mai" }],
    kunYomi: [{ hiragana: "ごと", romaji: "goto" }],
    significado: "todo",
    exemplos: [
      {
        kanji: "毎日",
        furigana: "まいにち",
        romaji: "mainichi",
        traducao: "todos os dias",
      },
      {
        kanji: "毎週",
        furigana: "まいしゅう",
        romaji: "maishū",
        traducao: "todas as semanas",
      },
      {
        kanji: "毎月",
        furigana: "まいつき",
        romaji: "maitsuki",
        traducao: "todos os meses",
      },
    ],
  },
  {
    kanji: "見",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "み", romaji: "mi" }],
    significado: "ver",
    exemplos: [
      { kanji: "見る", furigana: "みる", romaji: "miru", traducao: "ver" },
      {
        kanji: "見せる",
        furigana: "みせる",
        romaji: "miseru",
        traducao: "mostrar",
      },
      {
        kanji: "見学",
        furigana: "けんがく",
        romaji: "kengaku",
        traducao: "visita de estudo",
      },
    ],
  },
  {
    kanji: "来",
    onYomi: [{ katakana: "ライ", romaji: "rai" }],
    kunYomi: [{ hiragana: "く", romaji: "ku" }],
    significado: "vir",
    exemplos: [
      { kanji: "来る", furigana: "くる", romaji: "kuru", traducao: "vir" },
      {
        kanji: "来週",
        furigana: "らいしゅう",
        romaji: "raishū",
        traducao: "próxima semana",
      },
      {
        kanji: "来月",
        furigana: "らいげつ",
        romaji: "raigetsu",
        traducao: "próximo mês",
      },
    ],
  },
  {
    kanji: "男",
    onYomi: [{ katakana: "ダン", romaji: "dan" }],
    kunYomi: [{ hiragana: "おとこ", romaji: "otoko" }],
    significado: "homem",
    exemplos: [
      { kanji: "男", furigana: "おとこ", romaji: "otoko", traducao: "homem" },
      {
        kanji: "男性",
        furigana: "だんせい",
        romaji: "dansei",
        traducao: "sexo masculino",
      },
      {
        kanji: "男子",
        furigana: "だんし",
        romaji: "danshi",
        traducao: "garoto",
      },
    ],
  },
  {
    kanji: "車",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "くるま", romaji: "kuruma" }],
    significado: "carro",
    exemplos: [
      { kanji: "車", furigana: "くるま", romaji: "kuruma", traducao: "carro" },
      {
        kanji: "自転車",
        furigana: "じてんしゃ",
        romaji: "jitensha",
        traducao: "bicicleta",
      },
      {
        kanji: "電車",
        furigana: "でんしゃ",
        romaji: "densha",
        traducao: "trem",
      },
    ],
  },
  {
    kanji: "何",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "なに", romaji: "nani" }],
    significado: "o quê",
    exemplos: [
      { kanji: "何", furigana: "なに", romaji: "nani", traducao: "o quê" },
      {
        kanji: "何歳",
        furigana: "なんさい",
        romaji: "nan-sai",
        traducao: "quantos anos",
      },
      {
        kanji: "何時",
        furigana: "なんじ",
        romaji: "nan-ji",
        traducao: "que horas",
      },
    ],
  },
  {
    kanji: "国",
    onYomi: [{ katakana: "コク", romaji: "koku" }],
    kunYomi: [{ hiragana: "くに", romaji: "kuni" }],
    significado: "país",
    exemplos: [
      { kanji: "日本", furigana: "にほん", romaji: "Nihon", traducao: "Japão" },
      {
        kanji: "外国",
        furigana: "がいこく",
        romaji: "gaikoku",
        traducao: "país estrangeiro",
      },
      {
        kanji: "国語",
        furigana: "こくご",
        romaji: "kokugo",
        traducao: "língua nacional",
      },
    ],
  },
  {
    kanji: "長",
    onYomi: [{ katakana: "チョウ", romaji: "chō" }],
    kunYomi: [{ hiragana: "なが", romaji: "naga" }],
    significado: "longo",
    exemplos: [
      { kanji: "長い", furigana: "ながい", romaji: "nagai", traducao: "longo" },
      {
        kanji: "長さ",
        furigana: "ながさ",
        romaji: "nagasa",
        traducao: "comprimento",
      },
      {
        kanji: "長期",
        furigana: "ちょうき",
        romaji: "chōki",
        traducao: "longo prazo",
      },
    ],
  },
  {
    kanji: "金",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [{ hiragana: "かね", romaji: "kane" }],
    significado: "ouro/dinheiro",
    exemplos: [
      { kanji: "金", furigana: "かね", romaji: "kane", traducao: "dinheiro" },
      {
        kanji: "金曜日",
        furigana: "きんようび",
        romaji: "kin'yōbi",
        traducao: "sexta-feira",
      },
      {
        kanji: "金魚",
        furigana: "きんぎょ",
        romaji: "kingyo",
        traducao: "peixe dourado",
      },
    ],
  },
  {
    kanji: "学",
    onYomi: [{ katakana: "ガク", romaji: "gaku" }],
    kunYomi: [{ hiragana: "まな", romaji: "mana" }],
    significado: "estudo",
    exemplos: [
      {
        kanji: "学校",
        furigana: "がっこう",
        romaji: "gakkō",
        traducao: "escola",
      },
      {
        kanji: "学生",
        furigana: "がくせい",
        romaji: "gakusei",
        traducao: "estudante",
      },
      {
        kanji: "学ぶ",
        furigana: "まなぶ",
        romaji: "manabu",
        traducao: "estudar/aprender",
      },
    ],
  },
  {
    kanji: "東",
    onYomi: [{ katakana: "トウ", romaji: "tō" }],
    kunYomi: [{ hiragana: "ひがし", romaji: "higashi" }],
    significado: "leste",
    exemplos: [
      {
        kanji: "東京",
        furigana: "とうきょう",
        romaji: "Tōkyō",
        traducao: "Tóquio",
      },
      {
        kanji: "東部",
        furigana: "とうぶ",
        romaji: "tōbu",
        traducao: "parte leste",
      },
      {
        kanji: "東方",
        furigana: "とうほう",
        romaji: "tōhō",
        traducao: "leste",
      },
    ],
  },
  {
    kanji: "雨",
    onYomi: [{ katakana: "ウ", romaji: "u" }],
    kunYomi: [{ hiragana: "あめ", romaji: "ame" }],
    significado: "chuva",
    exemplos: [
      { kanji: "雨", furigana: "あめ", romaji: "ame", traducao: "chuva" },
      {
        kanji: "雨天",
        furigana: "うてん",
        romaji: "uten",
        traducao: "tempo chuvoso",
      },
      {
        kanji: "梅雨",
        furigana: "つゆ",
        romaji: "tsuyu",
        traducao: "estação chuvosa",
      },
    ],
  },
  {
    kanji: "後",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [{ hiragana: "あと", romaji: "ato" }],
    significado: "depois",
    exemplos: [
      { kanji: "後", furigana: "あと", romaji: "ato", traducao: "depois" },
      {
        kanji: "後ろ",
        furigana: "うしろ",
        romaji: "ushiro",
        traducao: "atrás",
      },
      {
        kanji: "後日",
        furigana: "ごじつ",
        romaji: "gojitsu",
        traducao: "em outra ocasião",
      },
    ],
  },
  {
    kanji: "前",
    onYomi: [{ katakana: "ゼン", romaji: "zen" }],
    kunYomi: [{ hiragana: "まえ", romaji: "mae" }],
    significado: "antes",
    exemplos: [
      { kanji: "前", furigana: "まえ", romaji: "mae", traducao: "antes" },
      {
        kanji: "前回",
        furigana: "ぜんかい",
        romaji: "zenkai",
        traducao: "vez anterior",
      },
      {
        kanji: "前進",
        furigana: "ぜんしん",
        romaji: "zenshin",
        traducao: "avançar",
      },
    ],
  },
  {
    kanji: "食",
    onYomi: [{ katakana: "ショク", romaji: "shoku" }],
    kunYomi: [
      { hiragana: "た", romaji: "ta" },
      { hiragana: "く", romaji: "ku" },
    ],
    significado: "comer",
    exemplos: [
      {
        kanji: "食べる",
        furigana: "たべる",
        romaji: "taberu",
        traducao: "comer",
      },
      {
        kanji: "食事",
        furigana: "しょくじ",
        romaji: "shokuji",
        traducao: "refeição",
      },
      {
        kanji: "食堂",
        furigana: "しょくどう",
        romaji: "shokudō",
        traducao: "refeitório",
      },
    ],
  },
  {
    kanji: "南",
    onYomi: [{ katakana: "ナン", romaji: "nan" }],
    kunYomi: [{ hiragana: "みなみ", romaji: "minami" }],
    significado: "sul",
    exemplos: [
      { kanji: "南", furigana: "みなみ", romaji: "minami", traducao: "sul" },
      {
        kanji: "南口",
        furigana: "みなみぐち",
        romaji: "minamiguchi",
        traducao: "saída sul",
      },
      {
        kanji: "南部",
        furigana: "なんぶ",
        romaji: "nanbu",
        traducao: "região sul",
      },
    ],
  },
  {
    kanji: "時",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [{ hiragana: "とき", romaji: "toki" }],
    significado: "hora/tempo",
    exemplos: [
      { kanji: "時間", furigana: "じかん", romaji: "jikan", traducao: "tempo" },
      {
        kanji: "時計",
        furigana: "とけい",
        romaji: "tokei",
        traducao: "relógio",
      },
      { kanji: "時代", furigana: "じだい", romaji: "jidai", traducao: "época" },
    ],
  },
  {
    kanji: "高",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [{ hiragana: "たか", romaji: "taka" }],
    significado: "alto",
    exemplos: [
      { kanji: "高い", furigana: "たかい", romaji: "takai", traducao: "alto" },
      {
        kanji: "高校",
        furigana: "こうこう",
        romaji: "kōkō",
        traducao: "escola secundária",
      },
      {
        kanji: "高速",
        furigana: "こうそく",
        romaji: "kōsoku",
        traducao: "alta velocidade",
      },
    ],
  },
  {
    kanji: "校",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [{ hiragana: "か", romaji: "ka" }],
    significado: "escola",
    exemplos: [
      {
        kanji: "学校",
        furigana: "がっこう",
        romaji: "gakkō",
        traducao: "escola",
      },
      {
        kanji: "中学校",
        furigana: "ちゅうがっこう",
        romaji: "chūgakkō",
        traducao: "escola de ensino fundamental",
      },
      {
        kanji: "高校生",
        furigana: "こうこうせい",
        romaji: "kōkōsei",
        traducao: "estudante do ensino médio",
      },
    ],
  },
  {
    kanji: "書",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [{ hiragana: "か", romaji: "ka" }],
    significado: "escrever",
    exemplos: [
      { kanji: "書く", furigana: "かく", romaji: "kaku", traducao: "escrever" },
      {
        kanji: "本を書く",
        furigana: "ほんをかく",
        romaji: "hon o kaku",
        traducao: "escrever um livro",
      },
      {
        kanji: "手紙を書く",
        furigana: "てがみをかく",
        romaji: "tegami o kaku",
        traducao: "escrever uma carta",
      },
    ],
  },
  {
    kanji: "間",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "あいだ", romaji: "aida" }],
    significado: "espaço/tempo",
    exemplos: [
      { kanji: "時間", furigana: "じかん", romaji: "jikan", traducao: "tempo" },
      {
        kanji: "間違い",
        furigana: "まちがい",
        romaji: "machigai",
        traducao: "erro",
      },
      {
        kanji: "間に合う",
        furigana: "まにあう",
        romaji: "maniau",
        traducao: "estar a tempo",
      },
    ],
  },
  {
    kanji: "話",
    onYomi: [{ katakana: "ワ", romaji: "wa" }],
    kunYomi: [{ hiragana: "はなし", romaji: "hanashi" }],
    significado: "conversa",
    exemplos: [
      {
        kanji: "話す",
        furigana: "はなす",
        romaji: "hanasu",
        traducao: "conversar",
      },
      {
        kanji: "話題",
        furigana: "わだい",
        romaji: "wadai",
        traducao: "tópico de conversa",
      },
      {
        kanji: "話し合う",
        furigana: "はなしあう",
        romaji: "hanashiau",
        traducao: "discutir",
      },
    ],
  },
  {
    kanji: "電",
    onYomi: [{ katakana: "デン", romaji: "den" }],
    kunYomi: [],
    significado: "eletricidade",
    exemplos: [
      {
        kanji: "電車",
        furigana: "でんしゃ",
        romaji: "densha",
        traducao: "trem",
      },
      {
        kanji: "電話",
        furigana: "でんわ",
        romaji: "denwa",
        traducao: "telefone",
      },
      {
        kanji: "電気",
        furigana: "でんき",
        romaji: "denki",
        traducao: "eletricidade",
      },
    ],
  },
  {
    kanji: "語",
    onYomi: [{ katakana: "ゴ", romaji: "go" }],
    kunYomi: [],
    significado: "idioma",
    exemplos: [
      {
        kanji: "日本語",
        furigana: "にほんご",
        romaji: "nihongo",
        traducao: "japonês",
      },
      { kanji: "英語", furigana: "えいご", romaji: "eigo", traducao: "inglês" },
      {
        kanji: "言語",
        furigana: "げんご",
        romaji: "gengo",
        traducao: "idioma",
      },
    ],
  },
  {
    kanji: "聞",
    onYomi: [
      { katakana: "ブン", romaji: "bun" },
      { katakana: "モン", romaji: "mon" },
    ],
    kunYomi: [{ hiragana: "き", romaji: "ki" }],
    significado: "ouvir",
    exemplos: [
      { kanji: "聞く", furigana: "きく", romaji: "kiku", traducao: "ouvir" },
      {
        kanji: "聞こえる",
        furigana: "きこえる",
        romaji: "kikoeru",
        traducao: "ser ouvido",
      },
      {
        kanji: "聞くこと",
        furigana: "きくこと",
        romaji: "kiku koto",
        traducao: "escutar",
      },
    ],
  },
  {
    kanji: "読",
    onYomi: [{ katakana: "ドク", romaji: "doku" }],
    kunYomi: [{ hiragana: "よ", romaji: "yo" }],
    significado: "ler",
    exemplos: [
      { kanji: "読む", furigana: "よむ", romaji: "yomu", traducao: "ler" },
      {
        kanji: "本を読む",
        furigana: "ほんをよむ",
        romaji: "hon o yomu",
        traducao: "ler um livro",
      },
      {
        kanji: "新聞を読む",
        furigana: "しんぶんをよむ",
        romaji: "shinbun o yomu",
        traducao: "ler um jornal",
      },
    ],
  },
];

export default n5KanjiList;