const n2KanjiList = [
  {
    kanji: "党",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "なかま", romaji: "nakama" }],
    significado: "partido político",
    exemplos: [
      {
        kanji: "政党",
        furigana: "せいとう",
        romaji: "seitou",
        traducao: "partido político",
      },
      {
        kanji: "党員",
        furigana: "とういん",
        romaji: "touin",
        traducao: "membro do partido",
      },
      {
        kanji: "政党制",
        furigana: "せいとうせい",
        romaji: "seitousei",
        traducao: "sistema partidário",
      },
    ],
  },
  {
    kanji: "協",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "とも", romaji: "tomo" }],
    significado: "cooperação",
    exemplos: [
      {
        kanji: "協力",
        furigana: "きょうりょく",
        romaji: "kyouryoku",
        traducao: "cooperação",
      },
      {
        kanji: "協会",
        furigana: "きょうかい",
        romaji: "kyoukai",
        traducao: "associação",
      },
      {
        kanji: "協定",
        furigana: "きょうてい",
        romaji: "kyoutei",
        traducao: "acordo",
      },
    ],
  },
  {
    kanji: "総",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "すべ", romaji: "sube" }],
    significado: "total",
    exemplos: [
      {
        kanji: "総合",
        furigana: "そうごう",
        romaji: "sougo",
        traducao: "total; abrangente",
      },
      {
        kanji: "総数",
        furigana: "そうすう",
        romaji: "sousuu",
        traducao: "número total",
      },
      {
        kanji: "総理大臣",
        furigana: "そうりだいじん",
        romaji: "souridaijin",
        traducao: "Primeiro-Ministro",
      },
    ],
  },
  {
    kanji: "区",
    onYomi: [{ katakana: "ク", romaji: "ku" }],
    kunYomi: [{ hiragana: "くに", romaji: "kuni" }],
    significado: "distrito",
    exemplos: [
      {
        kanji: "地区",
        furigana: "ちく",
        romaji: "chiku",
        traducao: "distrito",
      },
      {
        kanji: "区域",
        furigana: "くいき",
        romaji: "kuiki",
        traducao: "área",
      },
      {
        kanji: "区画",
        furigana: "くかく",
        romaji: "kukaku",
        traducao: "parcela; lote",
      },
    ],
  },
  {
    kanji: "領",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [{ hiragana: "えり", romaji: "eri" }],
    significado: "território",
    exemplos: [
      {
        kanji: "領土",
        furigana: "りょうど",
        romaji: "ryoudo",
        traducao: "território",
      },
      {
        kanji: "領域",
        furigana: "りょういき",
        romaji: "ryouiki",
        traducao: "área",
      },
      {
        kanji: "領事",
        furigana: "りょうじ",
        romaji: "ryouji",
        traducao: "consulado",
      },
    ],
  },
  {
    kanji: "県",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "か", romaji: "ka" }],
    significado: "prefeitura",
    exemplos: [
      {
        kanji: "都県",
        furigana: "とけん",
        romaji: "to-ken",
        traducao: "prefeitura metropolitana",
      },
      {
        kanji: "県庁",
        furigana: "けんちょう",
        romaji: "kenchou",
        traducao: "governo da prefeitura",
      },
      {
        kanji: "県立",
        furigana: "けんりつ",
        romaji: "kenritsu",
        traducao: "administrado pela prefeitura",
      },
    ],
  },
  {
    kanji: "設",
    onYomi: [{ katakana: "セツ", romaji: "setsu" }],
    kunYomi: [{ hiragana: "もう", romaji: "mou" }],
    significado: "estabelecimento",
    exemplos: [
      {
        kanji: "設立",
        furigana: "せつりつ",
        romaji: "setsuritsu",
        traducao: "estabelecimento; fundação",
      },
      {
        kanji: "設計",
        furigana: "せっけい",
        romaji: "sekkei",
        traducao: "projeto; design",
      },
      {
        kanji: "設備",
        furigana: "せつび",
        romaji: "setsubi",
        traducao: "instalação; equipamento",
      },
    ],
  },
  {
    kanji: "保",
    onYomi: [{ katakana: "ホ", romaji: "ho" }],
    kunYomi: [{ hiragana: "たもつ", romaji: "tamotsu" }],
    significado: "proteção; manutenção",
    exemplos: [
      {
        kanji: "保護",
        furigana: "ほご",
        romaji: "hogo",
        traducao: "proteção",
      },
      {
        kanji: "保険",
        furigana: "ほけん",
        romaji: "hoken",
        traducao: "seguro",
      },
      {
        kanji: "保証",
        furigana: "ほしょう",
        romaji: "hoshou",
        traducao: "garantia",
      },
    ],
  },
  {
    kanji: "改",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "あらた", romaji: "arata" }],
    significado: "mudança; reforma",
    exemplos: [
      {
        kanji: "改善",
        furigana: "かいぜん",
        romaji: "kaizen",
        traducao: "melhoria",
      },
      {
        kanji: "改革",
        furigana: "かいかく",
        romaji: "kaikaku",
        traducao: "reforma",
      },
      {
        kanji: "改正",
        furigana: "かいせい",
        romaji: "kaisei",
        traducao: "emenda; revisão",
      },
    ],
  },
  {
    kanji: "第",
    onYomi: [{ katakana: "ダイ", romaji: "dai" }],
    kunYomi: [],
    significado: "ordinal; número",
    exemplos: [
      {
        kanji: "第一",
        furigana: "だいいち",
        romaji: "daiichi",
        traducao: "primeiro",
      },
      {
        kanji: "第二",
        furigana: "だいに",
        romaji: "daini",
        traducao: "segundo",
      },
      {
        kanji: "第三",
        furigana: "だいさん",
        romaji: "daisan",
        traducao: "terceiro",
      },
    ],
  },
  {
    kanji: "結",
    onYomi: [{ katakana: "ケツ", romaji: "ketsu" }],
    kunYomi: [{ hiragana: "むす", romaji: "musu" }],
    significado: "união; conclusão",
    exemplos: [
      {
        kanji: "結婚",
        furigana: "けっこん",
        romaji: "kekkon",
        traducao: "casamento",
      },
      {
        kanji: "結果",
        furigana: "けっか",
        romaji: "kekka",
        traducao: "resultado",
      },
      {
        kanji: "結束",
        furigana: "けっそく",
        romaji: "kessoku",
        traducao: "união",
      },
    ],
  },
  {
    kanji: "派",
    onYomi: [{ katakana: "ハ", romaji: "ha" }],
    kunYomi: [],
    significado: "facção; grupo",
    exemplos: [
      {
        kanji: "派閥",
        furigana: "はばつ",
        romaji: "habatsu",
        traducao: "clique; facção",
      },
      {
        kanji: "派遣",
        furigana: "はけん",
        romaji: "haken",
        traducao: "envio; despacho",
      },
      {
        kanji: "政治派",
        furigana: "せいじは",
        romaji: "seiji-ha",
        traducao: "facção política",
      },
    ],
  },
  {
    kanji: "府",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [],
    significado: "governo local",
    exemplos: [
      {
        kanji: "都府県",
        furigana: "とふけん",
        romaji: "to-fu-ken",
        traducao: "prefeitura",
      },
      {
        kanji: "府庁",
        furigana: "ふちょう",
        romaji: "fu-chou",
        traducao: "governo da cidade",
      },
      {
        kanji: "府立",
        furigana: "ふりつ",
        romaji: "fu-ritsu",
        traducao: "administrado pela cidade",
      },
    ],
  },
  {
    kanji: "査",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [],
    significado: "inspeção; investigação",
    exemplos: [
      {
        kanji: "調査",
        furigana: "ちょうさ",
        romaji: "chousa",
        traducao: "investigação",
      },
      {
        kanji: "審査",
        furigana: "しんさ",
        romaji: "shinsa",
        traducao: "exame; avaliação",
      },
      {
        kanji: "検査",
        furigana: "けんさ",
        romaji: "kensa",
        traducao: "inspeção",
      },
    ],
  },
  {
    kanji: "委",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [],
    significado: "comissão",
    exemplos: [
      {
        kanji: "委員",
        furigana: "いいん",
        romaji: "iin",
        traducao: "membro da comissão",
      },
      {
        kanji: "委託",
        furigana: "いたく",
        romaji: "itaku",
        traducao: "delegação; encargo",
      },
      {
        kanji: "任委",
        furigana: "にんい",
        romaji: "nin'i",
        traducao: "comissão de nomeação",
      },
    ],
  },
  {
    kanji: "軍",
    onYomi: [{ katakana: "グン", romaji: "gun" }],
    kunYomi: [],
    significado: "exército",
    exemplos: [
      {
        kanji: "陸軍",
        furigana: "りくぐん",
        romaji: "riku-gun",
        traducao: "exército terrestre",
      },
      {
        kanji: "海軍",
        furigana: "かいぐん",
        romaji: "kai-gun",
        traducao: "marinha",
      },
      {
        kanji: "軍事",
        furigana: "ぐんじ",
        romaji: "gunji",
        traducao: "assuntos militares",
      },
    ],
  },
  {
    kanji: "案",
    onYomi: [{ katakana: "アン", romaji: "an" }],
    kunYomi: [{ hiragana: "つくえ", romaji: "tsukue" }],
    significado: "ideia; plano",
    exemplos: [
      {
        kanji: "計画案",
        furigana: "けいかくあん",
        romaji: "keikaku-an",
        traducao: "projeto; plano",
      },
      {
        kanji: "提案",
        furigana: "ていあん",
        romaji: "teian",
        traducao: "proposta",
      },
      {
        kanji: "案内",
        furigana: "あんない",
        romaji: "annai",
        traducao: "guia; instrução",
      },
    ],
  },
  {
    kanji: "策",
    onYomi: [{ katakana: "サク", romaji: "saku" }],
    kunYomi: [],
    significado: "estratégia; plano",
    exemplos: [
      {
        kanji: "解決策",
        furigana: "かいけつさく",
        romaji: "kaiketsu-saku",
        traducao: "solução; abordagem",
      },
      {
        kanji: "対策",
        furigana: "たいさく",
        romaji: "taisaku",
        traducao: "medida; contramedida",
      },
      {
        kanji: "政策",
        furigana: "せいさく",
        romaji: "seisaku",
        traducao: "política (medidas)",
      },
    ],
  },
  {
    kanji: "団",
    onYomi: [{ katakana: "ダン", romaji: "dan" }],
    kunYomi: [],
    significado: "grupo; associação",
    exemplos: [
      {
        kanji: "団体",
        furigana: "だんたい",
        romaji: "dantai",
        traducao: "organização; grupo",
      },
      {
        kanji: "団員",
        furigana: "だんいん",
        romaji: "danin",
        traducao: "membro do grupo",
      },
      {
        kanji: "団結",
        furigana: "だんけつ",
        romaji: "danketsu",
        traducao: "união; solidariedade",
      },
    ],
  },
  {
    kanji: "各",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "おのおの", romaji: "onoono" }],
    significado: "cada; respectivo",
    exemplos: [
      {
        kanji: "各国",
        furigana: "かっこく",
        romaji: "kak-koku",
        traducao: "cada país",
      },
      {
        kanji: "各地",
        furigana: "かくち",
        romaji: "kaku-chi",
        traducao: "cada local; várias regiões",
      },
      {
        kanji: "各自",
        furigana: "かくじ",
        romaji: "kakuji",
        traducao: "cada um; individualmente",
      },
    ],
  },
  {
    kanji: "島",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "しま", romaji: "shima" }],
    significado: "ilha",
    exemplos: [
      {
        kanji: "本島",
        furigana: "ほんとう",
        romaji: "hontou",
        traducao: "ilha principal",
      },
      {
        kanji: "離島",
        furigana: "りとう",
        romaji: "ritou",
        traducao: "ilha remota",
      },
      {
        kanji: "島国",
        furigana: "しまぐに",
        romaji: "shimaguni",
        traducao: "arquipélago",
      },
    ],
  },
  {
    kanji: "革",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "かわ", romaji: "kawa" }],
    significado: "couro; reforma",
    exemplos: [
      {
        kanji: "革新",
        furigana: "かくしん",
        romaji: "kakushin",
        traducao: "reforma; renovação",
      },
      {
        kanji: "革命",
        furigana: "かくめい",
        romaji: "kakumei",
        traducao: "revolução",
      },
      {
        kanji: "革製品",
        furigana: "かくせいひん",
        romaji: "kakuseihin",
        traducao: "produto de couro",
      },
    ],
  },
  {
    kanji: "村",
    onYomi: [{ katakana: "ソン", romaji: "son" }],
    kunYomi: [{ hiragana: "むら", romaji: "mura" }],
    significado: "vila",
    exemplos: [
      {
        kanji: "村民",
        furigana: "そんみん",
        romaji: "sonmin",
        traducao: "morador da vila",
      },
      {
        kanji: "村長",
        furigana: "そんちょう",
        romaji: "sonchou",
        traducao: "prefeito da vila",
      },
      {
        kanji: "村落",
        furigana: "そんらく",
        romaji: "sonraku",
        traducao: "aldeia",
      },
    ],
  },
  {
    kanji: "勢",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [],
    significado: "poder; influência",
    exemplos: [
      {
        kanji: "勢力",
        furigana: "せいりょく",
        romaji: "seiryoku",
        traducao: "influência; poder",
      },
      {
        kanji: "経済勢",
        furigana: "けいざいせい",
        romaji: "keizai-sei",
        traducao: "força econômica",
      },
      {
        kanji: "攻勢",
        furigana: "こうせい",
        romaji: "kousei",
        traducao: "ofensiva",
      },
    ],
  },
  {
    kanji: "減",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [
      { hiragana: "へ", romaji: "he" },
      { hiragana: "へら", romaji: "hera" },
    ],
    significado: "diminuição",
    exemplos: [
      {
        kanji: "減少",
        furigana: "げんしょう",
        romaji: "genshou",
        traducao: "diminuição",
      },
      {
        kanji: "減少率",
        furigana: "げんしょうりつ",
        romaji: "genshouritsu",
        traducao: "taxa de diminuição",
      },
      {
        kanji: "削減",
        furigana: "さくげん",
        romaji: "sakugen",
        traducao: "redução",
      },
    ],
  },
  {
    kanji: "再",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "ふたた", romaji: "futata" }],
    significado: "repetição; novamente",
    exemplos: [
      {
        kanji: "再生",
        furigana: "さいせい",
        romaji: "saisei",
        traducao: "regeneração; reprodução",
      },
      {
        kanji: "再利用",
        furigana: "さいりよう",
        romaji: "sai-riyou",
        traducao: "reutilização",
      },
      {
        kanji: "再開",
        furigana: "さいかい",
        romaji: "saikai",
        traducao: "reabertura",
      },
    ],
  },
  {
    kanji: "税",
    onYomi: [{ katakana: "ゼイ", romaji: "zei" }],
    kunYomi: [],
    significado: "imposto",
    exemplos: [
      {
        kanji: "消費税",
        furigana: "しょうひぜい",
        romaji: "shouhi-zei",
        traducao: "imposto de consumo",
      },
      {
        kanji: "所得税",
        furigana: "しょとくぜい",
        romaji: "shotoku-zei",
        traducao: "imposto de renda",
      },
      {
        kanji: "税金",
        furigana: "ぜいきん",
        romaji: "zeikin",
        traducao: "tributo; imposto",
      },
    ],
  },
  {
    kanji: "営",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [{ hiragana: "いとな", romaji: "itona" }],
    significado: "gerenciamento; operação",
    exemplos: [
      {
        kanji: "営業",
        furigana: "えいぎょう",
        romaji: "eigyou",
        traducao: "negócios",
      },
      {
        kanji: "営利",
        furigana: "えいり",
        romaji: "eiri",
        traducao: "lucrativo",
      },
      {
        kanji: "営業時間",
        furigana: "えいぎょうじかん",
        romaji: "eigyou-jikan",
        traducao: "horário comercial",
      },
    ],
  },
  {
    kanji: "比",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "くらべ", romaji: "kurabe" }],
    significado: "comparação",
    exemplos: [
      {
        kanji: "比較",
        furigana: "ひかく",
        romaji: "hikaku",
        traducao: "comparação",
      },
      {
        kanji: "比例",
        furigana: "ひれい",
        romaji: "hirei",
        traducao: "proporção",
      },
      {
        kanji: "対比",
        furigana: "たいひ",
        romaji: "taihi",
        traducao: "contraste; comparação",
      },
    ],
  },
  {
    kanji: "防",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "ふせ", romaji: "fuse" }],
    significado: "prevenção; proteção",
    exemplos: [
      {
        kanji: "防止",
        furigana: "ぼうし",
        romaji: "boushi",
        traducao: "prevenção",
      },
      {
        kanji: "防災",
        furigana: "ぼうさい",
        romaji: "bousai",
        traducao: "prevenção de desastres",
      },
      {
        kanji: "防衛",
        furigana: "ぼうえい",
        romaji: "bouei",
        traducao: "defesa",
      },
    ],
  },
  {
    kanji: "補",
    onYomi: [{ katakana: "ホ", romaji: "ho" }],
    kunYomi: [{ hiragana: "おぎな", romaji: "ogina" }],
    significado: "suplemento; complemento",
    exemplos: [
      {
        kanji: "補完",
        furigana: "ほかん",
        romaji: "hokan",
        traducao: "complementação",
      },
      {
        kanji: "補充",
        furigana: "ほじゅう",
        romaji: "hojuu",
        traducao: "reabastecimento",
      },
      {
        kanji: "補助",
        furigana: "ほじょ",
        romaji: "hojo",
        traducao: "assistência",
      },
    ],
  },
  {
    kanji: "境",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "さかい", romaji: "sakai" }],
    significado: "fronteira; limite",
    exemplos: [
      {
        kanji: "国境",
        furigana: "こっきょう",
        romaji: "kokkyou",
        traducao: "fronteira",
      },
      {
        kanji: "境界",
        furigana: "きょうかい",
        romaji: "kyoukai",
        traducao: "limite; fronteira",
      },
      {
        kanji: "境地",
        furigana: "きょうち",
        romaji: "kyouchi",
        traducao: "estado; condição",
      },
    ],
  },
  {
    kanji: "導",
    onYomi: [{ katakana: "ドウ", romaji: "dou" }],
    kunYomi: [{ hiragana: "みちび", romaji: "michibi" }],
    significado: "guiar; liderar",
    exemplos: [
      {
        kanji: "導入",
        furigana: "どうにゅう",
        romaji: "dounyuu",
        traducao: "introdução",
      },
      {
        kanji: "案内",
        furigana: "あんない",
        romaji: "annai",
        traducao: "guia; instrução",
      },
      {
        kanji: "導出",
        furigana: "どうしゅつ",
        romaji: "doushutsu",
        traducao: "dedução",
      },
    ],
  },
  {
    kanji: "副",
    onYomi: [{ katakana: "フク", romaji: "fuku" }],
    kunYomi: [],
    significado: "vice-; auxiliar",
    exemplos: [
      {
        kanji: "副社長",
        furigana: "ふくしゃちょう",
        romaji: "fuku-shachou",
        traducao: "vice-presidente",
      },
      {
        kanji: "副作用",
        furigana: "ふくさよう",
        romaji: "fuku-sayou",
        traducao: "efeito colateral",
      },
      {
        kanji: "副業",
        furigana: "ふくぎょう",
        romaji: "fuku-gyou",
        traducao: "trabalho secundário",
      },
    ],
  },
  {
    kanji: "算",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [],
    significado: "cálculo; contar",
    exemplos: [
      {
        kanji: "計算",
        furigana: "けいさん",
        romaji: "keisan",
        traducao: "cálculo",
      },
      {
        kanji: "算数",
        furigana: "さんすう",
        romaji: "sansuu",
        traducao: "matemática",
      },
      {
        kanji: "予算",
        furigana: "よさん",
        romaji: "yosan",
        traducao: "orçamento",
      },
    ],
  },
  {
    kanji: "輸",
    onYomi: [{ katakana: "ユ", romaji: "yu" }],
    kunYomi: [],
    significado: "exportação; importação",
    exemplos: [
      {
        kanji: "輸出",
        furigana: "ゆしゅつ",
        romaji: "yushutsu",
        traducao: "exportação",
      },
      {
        kanji: "輸入",
        furigana: "ゆにゅう",
        romaji: "yunyuu",
        traducao: "importação",
      },
      {
        kanji: "輸送",
        furigana: "ゆそう",
        romaji: "yusou",
        traducao: "transporte",
      },
    ],
  },
  {
    kanji: "述",
    onYomi: [{ katakana: "ジュツ", romaji: "jutsu" }],
    kunYomi: [{ hiragana: "のべ", romaji: "nobe" }],
    significado: "descrever; relatar",
    exemplos: [
      {
        kanji: "述べる",
        furigana: "のべる",
        romaji: "noberu",
        traducao: "descrever; declarar",
      },
      {
        kanji: "陳述",
        furigana: "ちんじゅつ",
        romaji: "chinjutsu",
        traducao: "declaração; depoimento",
      },
      {
        kanji: "説明",
        furigana: "せつめい",
        romaji: "setsumei",
        traducao: "explicação",
      },
    ],
  },
  {
    kanji: "線",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [],
    significado: "linha",
    exemplos: [
      {
        kanji: "直線",
        furigana: "ちょくせん",
        romaji: "chokusen",
        traducao: "linha reta",
      },
      {
        kanji: "曲線",
        furigana: "きょくせん",
        romaji: "kyokusen",
        traducao: "curva",
      },
      {
        kanji: "鉄道線",
        furigana: "てつどうせん",
        romaji: "tetsudou-sen",
        traducao: "linha ferroviária",
      },
    ],
  },
  {
    kanji: "農",
    onYomi: [{ katakana: "ノウ", romaji: "nou" }],
    kunYomi: [],
    significado: "agricultura",
    exemplos: [
      {
        kanji: "農業",
        furigana: "のうぎょう",
        romaji: "nougyou",
        traducao: "agricultura",
      },
      {
        kanji: "農民",
        furigana: "のうみん",
        romaji: "noumin",
        traducao: "agricultor",
      },
      {
        kanji: "農作物",
        furigana: "のうさくぶつ",
        romaji: "nousakubutsu",
        traducao: "cultivo agrícola",
      },
    ],
  },
  {
    kanji: "州",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [],
    significado: "estado; província",
    exemplos: [
      {
        kanji: "米国",
        furigana: "べいこく",
        romaji: "beikoku",
        traducao: "Estados Unidos",
      },
      {
        kanji: "州都",
        furigana: "しゅうと",
        romaji: "shuuto",
        traducao: "capital do estado",
      },
      {
        kanji: "地方自治州",
        furigana: "ちほうじちしゅう",
        romaji: "chihou-jichishuu",
        traducao: "região autônoma",
      },
    ],
  },
  {
    kanji: "武",
    onYomi: [{ katakana: "ブ", romaji: "bu" }],
    kunYomi: [{ hiragana: "たけ", romaji: "take" }],
    significado: "guerreiro; militar",
    exemplos: [
      {
        kanji: "武道",
        furigana: "ぶどう",
        romaji: "budou",
        traducao: "artes marciais",
      },
      {
        kanji: "武器",
        furigana: "ぶき",
        romaji: "buki",
        traducao: "arma",
      },
      {
        kanji: "武勇",
        furigana: "ぶゆう",
        romaji: "buyuu",
        traducao: "coragem; bravura",
      },
    ],
  },
  {
    kanji: "象",
    onYomi: [{ katakana: "ゾウ", romaji: "zou" }],
    kunYomi: [{ hiragana: "かたち", romaji: "katachi" }],
    significado: "elefante; semelhante a",
    exemplos: [
      {
        kanji: "象徴",
        furigana: "しょうちょう",
        romaji: "shouchou",
        traducao: "símbolo",
      },
      {
        kanji: "動物",
        furigana: "どうぶつ",
        romaji: "doubutsu",
        traducao: "animal",
      },
      {
        kanji: "大象",
        furigana: "だいぞう",
        romaji: "daizou",
        traducao: "elefante",
      },
    ],
  },
  {
    kanji: "域",
    onYomi: [{ katakana: "イキ", romaji: "iki" }],
    kunYomi: [],
    significado: "região; área",
    exemplos: [
      {
        kanji: "地域",
        furigana: "ちいき",
        romaji: "chiiki",
        traducao: "região",
      },
      {
        kanji: "都市圏",
        furigana: "としかい",
        romaji: "toshikai",
        traducao: "área metropolitana",
      },
      {
        kanji: "管轄域",
        furigana: "かんかついき",
        romaji: "kankatsu-iki",
        traducao: "área de jurisdição",
      },
    ],
  },
  {
    kanji: "額",
    onYomi: [{ katakana: "ガク", romaji: "gaku" }],
    kunYomi: [{ hiragana: "ひたい", romaji: "hitai" }],
    significado: "testa; montante",
    exemplos: [
      {
        kanji: "金額",
        furigana: "きんがく",
        romaji: "kingaku",
        traducao: "quantia em dinheiro",
      },
      {
        kanji: "総額",
        furigana: "そうがく",
        romaji: "sougaku",
        traducao: "total",
      },
      {
        kanji: "予算額",
        furigana: "よさんがく",
        romaji: "yosan-gaku",
        traducao: "orçamento",
      },
    ],
  },
  {
    kanji: "欧",
    onYomi: [{ katakana: "オウ", romaji: "ou" }],
    kunYomi: [],
    significado: "Europa",
    exemplos: [
      {
        kanji: "欧州",
        furigana: "おうしゅう",
        romaji: "oushuu",
        traducao: "Europa",
      },
      {
        kanji: "欧米",
        furigana: "おうべい",
        romaji: "oubei",
        traducao: "Europa e América",
      },
      {
        kanji: "西欧",
        furigana: "せいおう",
        romaji: "seiou",
        traducao: "Europa Ocidental",
      },
    ],
  },
  {
    kanji: "担",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [],
    significado: "responsabilidade; suportar",
    exemplos: [
      {
        kanji: "担当",
        furigana: "たんとう",
        romaji: "tantou",
        traducao: "responsável; encarregado",
      },
      {
        kanji: "責任を担う",
        furigana: "せきにんをになう",
        romaji: "sekinin o ninau",
        traducao: "assumir a responsabilidade",
      },
      {
        kanji: "役割を担う",
        furigana: "やくわりをになう",
        romaji: "yakuwari o ninau",
        traducao: "desempenhar um papel",
      },
    ],
  },
  {
    kanji: "準",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [],
    significado: "padrão; quase",
    exemplos: [
      {
        kanji: "準備",
        furigana: "じゅんび",
        romaji: "junbi",
        traducao: "preparação",
      },
      {
        kanji: "準備する",
        furigana: "じゅんびする",
        romaji: "junbi suru",
        traducao: "preparar",
      },
      {
        kanji: "準決勝",
        furigana: "じゅんけっしょう",
        romaji: "jun-kesshou",
        traducao: "semifinal",
      },
    ],
  },
  {
    kanji: "賞",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "prêmio",
    exemplos: [
      {
        kanji: "賞金",
        furigana: "しょうきん",
        romaji: "shoukin",
        traducao: "premiação em dinheiro",
      },
      {
        kanji: "受賞",
        furigana: "じゅしょう",
        romaji: "jushou",
        traducao: "receber um prêmio",
      },
      {
        kanji: "賞品",
        furigana: "しょうひん",
        romaji: "shouhin",
        traducao: "prêmio; troféu",
      },
    ],
  },
  {
    kanji: "辺",
    onYomi: [{ katakana: "ヘン", romaji: "hen" }],
    kunYomi: [{ hiragana: "あたり", romaji: "atari" }],
    significado: "ao redor; vizinhança",
    exemplos: [
      {
        kanji: "周辺",
        furigana: "しゅうへん",
        romaji: "shuuhen",
        traducao: "arredores",
      },
      {
        kanji: "近辺",
        furigana: "きんぺん",
        romaji: "kinpen",
        traducao: "região próxima",
      },
      {
        kanji: "辺り",
        furigana: "あたり",
        romaji: "atari",
        traducao: "ao redor; nas proximidades",
      },
    ],
  },
  {
    kanji: "造",
    onYomi: [{ katakana: "ゾウ", romaji: "zou" }],
    kunYomi: [],
    significado: "construir; fabricar",
    exemplos: [
      {
        kanji: "建造",
        furigana: "けんぞう",
        romaji: "kenzou",
        traducao: "construção",
      },
      {
        kanji: "造船",
        furigana: "ぞうせん",
        romaji: "zousen",
        traducao: "construção naval",
      },
      {
        kanji: "造形",
        furigana: "ぞうけい",
        romaji: "zoukei",
        traducao: "modelagem; escultura",
      },
    ],
  },
  {
    kanji: "被",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "こうむ", romaji: "koumu" }],
    significado: "ser afetado; cobrir",
    exemplos: [
      {
        kanji: "被害",
        furigana: "ひがい",
        romaji: "higai",
        traducao: "danos; prejuízo",
      },
      {
        kanji: "被験者",
        furigana: "ひけんしゃ",
        romaji: "hikensha",
        traducao: "participante de teste",
      },
      {
        kanji: "被る",
        furigana: "かぶる",
        romaji: "kaburu",
        traducao: "usar; cobrir a cabeça",
      },
    ],
  },
  {
    kanji: "技",
    onYomi: [{ katakana: "ギ", romaji: "gi" }],
    kunYomi: [{ hiragana: "わざ", romaji: "waza" }],
    significado: "técnica; habilidade",
    exemplos: [
      {
        kanji: "技術",
        furigana: "ぎじゅつ",
        romaji: "gijutsu",
        traducao: "tecnologia; habilidade técnica",
      },
      {
        kanji: "技能",
        furigana: "ぎのう",
        romaji: "ginou",
        traducao: "habilidade; destreza",
      },
      {
        kanji: "特技",
        furigana: "とくぎ",
        romaji: "tokugi",
        traducao: "habilidade especial; talento",
      },
    ],
  },
  {
    kanji: "低",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "ひくい", romaji: "hikui" }],
    significado: "baixo",
    exemplos: [
      {
        kanji: "低下",
        furigana: "ていか",
        romaji: "teika",
        traducao: "declínio; queda",
      },
      {
        kanji: "低音",
        furigana: "ていおん",
        romaji: "teion",
        traducao: "nota grave; tom baixo",
      },
      {
        kanji: "低温",
        furigana: "ていおん",
        romaji: "teion",
        traducao: "baixa temperatura",
      },
    ],
  },
  {
    kanji: "復",
    onYomi: [{ katakana: "フク", romaji: "fuku" }],
    kunYomi: [{ hiragana: "また", romaji: "mata" }],
    significado: "repetição; retorno",
    exemplos: [
      {
        kanji: "復習",
        furigana: "ふくしゅう",
        romaji: "fukushuu",
        traducao: "revisão",
      },
      {
        kanji: "復元",
        furigana: "ふくげん",
        romaji: "fukugen",
        traducao: "restauração; recuperação",
      },
      {
        kanji: "復活",
        furigana: "ふっかつ",
        romaji: "fukkatsu",
        traducao: "ressurreição; retorno",
      },
    ],
  },
  {
    kanji: "移",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "うつ", romaji: "utsu" }],
    significado: "mover; transferir",
    exemplos: [
      {
        kanji: "移動",
        furigana: "いどう",
        romaji: "idou",
        traducao: "movimento",
      },
      {
        kanji: "移住",
        furigana: "いじゅう",
        romaji: "ijuu",
        traducao: "migração; imigração",
      },
      {
        kanji: "移転",
        furigana: "いてん",
        romaji: "iten",
        traducao: "transferência; mudança",
      },
    ],
  },
  {
    kanji: "個",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [],
    significado: "unidade; indivíduo",
    exemplos: [
      {
        kanji: "個人",
        furigana: "こじん",
        romaji: "kojin",
        traducao: "indivíduo; pessoal",
      },
      {
        kanji: "個体",
        furigana: "こたい",
        romaji: "kotai",
        traducao: "organismo; indivíduo",
      },
      {
        kanji: "個数",
        furigana: "こすう",
        romaji: "kosuu",
        traducao: "quantidade; número de unidades",
      },
    ],
  },
  {
    kanji: "門",
    onYomi: [{ katakana: "モン", romaji: "mon" }],
    kunYomi: [{ hiragana: "かど", romaji: "kado" }],
    significado: "portão; portal",
    exemplos: [
      {
        kanji: "入口",
        furigana: "いりぐち",
        romaji: "iriguchi",
        traducao: "entrada",
      },
      {
        kanji: "出口",
        furigana: "でぐち",
        romaji: "deguchi",
        traducao: "saída",
      },
      {
        kanji: "門前",
        furigana: "もんぜん",
        romaji: "monzen",
        traducao: "diante do portão",
      },
    ],
  },
  {
    kanji: "課",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [],
    significado: "lição; seção",
    exemplos: [
      {
        kanji: "課題",
        furigana: "かだい",
        romaji: "kadai",
        traducao: "tarefa; problema",
      },
      {
        kanji: "授業",
        furigana: "じゅぎょう",
        romaji: "jugyou",
        traducao: "aula; lição",
      },
      {
        kanji: "課程",
        furigana: "かてい",
        romaji: "katei",
        traducao: "currículo; programa",
      },
    ],
  },
  {
    kanji: "脳",
    onYomi: [{ katakana: "ノウ", romaji: "nou" }],
    kunYomi: [],
    significado: "cérebro",
    exemplos: [
      {
        kanji: "脳科学",
        furigana: "のうかがく",
        romaji: "noukagaku",
        traducao: "neurociência",
      },
      {
        kanji: "脳機能",
        furigana: "のうきのう",
        romaji: "noukinou",
        traducao: "função cerebral",
      },
      {
        kanji: "脳内",
        furigana: "のうない",
        romaji: "nounai",
        traducao: "dentro do cérebro",
      },
    ],
  },
  {
    kanji: "極",
    onYomi: [{ katakana: "キョク", romaji: "kyoku" }],
    kunYomi: [{ hiragana: "きわ", romaji: "kiwa" }],
    significado: "extremo; pico",
    exemplos: [
      {
        kanji: "極限",
        furigana: "きょくげん",
        romaji: "kyokugen",
        traducao: "limite; extremidade",
      },
      {
        kanji: "極寒",
        furigana: "ごっかん",
        romaji: "gokkan",
        traducao: "extremamente frio",
      },
      {
        kanji: "極上",
        furigana: "ごくじょう",
        romaji: "gokujou",
        traducao: "excelente; de primeira qualidade",
      },
    ],
  },
  {
    kanji: "含",
    onYomi: [{ katakana: "ガン", romaji: "gan" }],
    kunYomi: [{ hiragana: "ふく", romaji: "fuku" }],
    significado: "incluir; conter",
    exemplos: [
      {
        kanji: "含意",
        furigana: "がんい",
        romaji: "gan'i",
        traducao: "significado implícito",
      },
      {
        kanji: "包含",
        furigana: "ほうがん",
        romaji: "hougan",
        traducao: "inclusão; abranger",
      },
      {
        kanji: "含む",
        furigana: "ふくむ",
        romaji: "fukumu",
        traducao: "incluir; conter",
      },
    ],
  },
  {
    kanji: "蔵",
    onYomi: [{ katakana: "ゾウ", romaji: "zou" }],
    kunYomi: [{ hiragana: "くら", romaji: "kura" }],
    significado: "armazenar; depósito",
    exemplos: [
      {
        kanji: "図書館",
        furigana: "としょかん",
        romaji: "toshokan",
        traducao: "biblioteca",
      },
      {
        kanji: "倉庫",
        furigana: "そうこ",
        romaji: "souko",
        traducao: "armazém; depósito",
      },
      {
        kanji: "蔵書",
        furigana: "ぞうしょ",
        romaji: "zousho",
        traducao: "coleção de livros",
      },
    ],
  },
  {
    kanji: "量",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [],
    significado: "quantidade",
    exemplos: [
      {
        kanji: "数量",
        furigana: "すうりょう",
        romaji: "suryou",
        traducao: "quantidade",
      },
      {
        kanji: "容量",
        furigana: "ようりょう",
        romaji: "youryou",
        traducao: "capacidade; volume",
      },
      {
        kanji: "量る",
        furigana: "はかる",
        romaji: "hakaru",
        traducao: "medir; pesar",
      },
    ],
  },
  {
    kanji: "型",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "かた", romaji: "kata" }],
    significado: "tipo; modelo",
    exemplos: [
      {
        kanji: "形式",
        furigana: "けいしき",
        romaji: "keishiki",
        traducao: "formato; forma",
      },
      {
        kanji: "原型",
        furigana: "げんけい",
        romaji: "genkei",
        traducao: "forma original; protótipo",
      },
      {
        kanji: "型",
        furigana: "かた",
        romaji: "kata",
        traducao: "tipo; modelo",
      },
    ],
  },
  {
    kanji: "況",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "まし", romaji: "mashi" }],
    significado: "condição; situação",
    exemplos: [
      {
        kanji: "状況",
        furigana: "じょうきょう",
        romaji: "joukyou",
        traducao: "situação; circunstância",
      },
      {
        kanji: "事情",
        furigana: "じじょう",
        romaji: "jijou",
        traducao: "circunstância; condição",
      },
      {
        kanji: "状態",
        furigana: "じょうたい",
        romaji: "joutai",
        traducao: "estado; condição",
      },
    ],
  },
  {
    kanji: "針",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "はり", romaji: "hari" }],
    significado: "agulha",
    exemplos: [
      {
        kanji: "時計",
        furigana: "とけい",
        romaji: "tokei",
        traducao: "relógio",
      },
      {
        kanji: "針金",
        furigana: "はりがね",
        romaji: "harigane",
        traducao: "arame",
      },
      {
        kanji: "指針",
        furigana: "ししん",
        romaji: "shishin",
        traducao: "diretriz; orientação",
      },
    ],
  },
  {
    kanji: "専",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "もっぱ", romaji: "moppa" }],
    significado: "especialização",
    exemplos: [
      {
        kanji: "専門",
        furigana: "せんもん",
        romaji: "senmon",
        traducao: "especialidade; profissional",
      },
      {
        kanji: "専用",
        furigana: "せんよう",
        romaji: "senyou",
        traducao: "exclusivo; dedicado",
      },
      {
        kanji: "専念",
        furigana: "せんねん",
        romaji: "sennen",
        traducao: "dedicação; concentração",
      },
    ],
  },
  {
    kanji: "谷",
    onYomi: [{ katakana: "コク", romaji: "koku" }],
    kunYomi: [{ hiragana: "たに", romaji: "tani" }],
    significado: "vale",
    exemplos: [
      {
        kanji: "山谷",
        furigana: "さんこく",
        romaji: "sankoku",
        traducao: "montanha e vale",
      },
      {
        kanji: "渓谷",
        furigana: "けいこく",
        romaji: "keikoku",
        traducao: "vale estreito",
      },
      {
        kanji: "谷間",
        furigana: "たにま",
        romaji: "tanimama",
        traducao: "vale; depressão",
      },
    ],
  },
  {
    kanji: "史",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "história",
    exemplos: [
      {
        kanji: "歴史",
        furigana: "れきし",
        romaji: "rekishi",
        traducao: "história",
      },
      {
        kanji: "史学",
        furigana: "しがく",
        romaji: "shigaku",
        traducao: "história (campo de estudo)",
      },
      {
        kanji: "史料",
        furigana: "しりょう",
        romaji: "shiryou",
        traducao: "fonte histórica",
      },
    ],
  },
  {
    kanji: "階",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [],
    significado: "andar; pavimento",
    exemplos: [
      {
        kanji: "階段",
        furigana: "かいだん",
        romaji: "kaidan",
        traducao: "escada",
      },
      {
        kanji: "階層",
        furigana: "かいそう",
        romaji: "kaisou",
        traducao: "hierarquia; camada",
      },
      {
        kanji: "高層階",
        furigana: "こうそうかい",
        romaji: "kousoukai",
        traducao: "andares superiores",
      },
    ],
  },
  {
    kanji: "管",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "くだ", romaji: "kuda" }],
    significado: "cano; gerenciar",
    exemplos: [
      {
        kanji: "管理",
        furigana: "かんり",
        romaji: "kanri",
        traducao: "gestão; administração",
      },
      {
        kanji: "管轄",
        furigana: "かんかつ",
        romaji: "kankatsu",
        traducao: "jurisdição; controle",
      },
      {
        kanji: "管弦楽団",
        furigana: "かんげんがくだん",
        romaji: "kangen gakudan",
        traducao: "orquestra",
      },
    ],
  },
  {
    kanji: "兵",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [{ hiragana: "つわもの", romaji: "tsuwamono" }],
    significado: "soldado; forças armadas",
    exemplos: [
      {
        kanji: "兵士",
        furigana: "へいし",
        romaji: "heishi",
        traducao: "soldado; tropa",
      },
      {
        kanji: "兵器",
        furigana: "へいき",
        romaji: "heiki",
        traducao: "arma; armamento",
      },
      {
        kanji: "兵隊",
        furigana: "へいたい",
        romaji: "heitai",
        traducao: "soldado; exército",
      },
    ],
  },
  {
    kanji: "接",
    onYomi: [{ katakana: "セツ", romaji: "setsu" }],
    kunYomi: [{ hiragana: "つ.ぐ", romaji: "tsu.gu" }],
    significado: "conectar; contato",
    exemplos: [
      {
        kanji: "接する",
        furigana: "せっする",
        romaji: "sessuru",
        traducao: "entrar em contato; interagir",
      },
      {
        kanji: "接客",
        furigana: "せっきゃく",
        romaji: "sekkaku",
        traducao: "atendimento ao cliente",
      },
      {
        kanji: "接続詞",
        furigana: "せつぞくし",
        romaji: "setsuzokushi",
        traducao: "conjunção",
      },
    ],
  },
  {
    kanji: "細",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "ほそ.い", romaji: "hoso.i" }],
    significado: "fino; detalhado",
    exemplos: [
      {
        kanji: "細部",
        furigana: "さいぶ",
        romaji: "saibu",
        traducao: "detalhes",
      },
      {
        kanji: "細工",
        furigana: "さいく",
        romaji: "saiku",
        traducao: "artesanato",
      },
      {
        kanji: "細かい",
        furigana: "こまかい",
        romaji: "komakai",
        traducao: "detalhado; minucioso",
      },
    ],
  },
  {
    kanji: "効",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "efeito; eficácia",
    exemplos: [
      {
        kanji: "効果",
        furigana: "こうか",
        romaji: "kouka",
        traducao: "efeito; resultado",
      },
      {
        kanji: "有効",
        furigana: "ゆうこう",
        romaji: "yuukou",
        traducao: "eficaz; válido",
      },
      {
        kanji: "効く",
        furigana: "きく",
        romaji: "kiku",
        traducao: "ser eficaz",
      },
    ],
  },
  {
    kanji: "丸",
    onYomi: [{ katakana: "ガン", romaji: "gan" }],
    kunYomi: [{ hiragana: "まる", romaji: "maru" }],
    significado: "círculo; redondo",
    exemplos: [
      {
        kanji: "丸い",
        furigana: "まるい",
        romaji: "marui",
        traducao: "redondo",
      },
      {
        kanji: "丸ごと",
        furigana: "まるごと",
        romaji: "marugoto",
        traducao: "por completo",
      },
      {
        kanji: "丸める",
        furigana: "まるめる",
        romaji: "marumeru",
        traducao: "arredondar; enrolar",
      },
    ],
  },
  {
    kanji: "湾",
    onYomi: [{ katakana: "ワン", romaji: "wan" }],
    kunYomi: [{ hiragana: "わん", romaji: "wan" }],
    significado: "baía; golfo",
    exemplos: [
      {
        kanji: "湾内",
        furigana: "わんない",
        romaji: "wannai",
        traducao: "dentro da baía; no golfo",
      },
      {
        kanji: "湾岸",
        furigana: "わんがん",
        romaji: "wanggan",
        traducao: "costa da baía; litoral",
      },
      {
        kanji: "湾曲",
        furigana: "わんきょく",
        romaji: "wankyoku",
        traducao: "curvatura; curva",
      },
    ],
  },
  {
    kanji: "録",
    onYomi: [{ katakana: "ロク", romaji: "roku" }],
    kunYomi: [],
    significado: "gravar; registro",
    exemplos: [
      {
        kanji: "録音",
        furigana: "ろくおん",
        romaji: "rokuon",
        traducao: "gravação de som",
      },
      {
        kanji: "録画",
        furigana: "ろくが",
        romaji: "rokuga",
        traducao: "gravação de vídeo",
      },
      {
        kanji: "記録",
        furigana: "きろく",
        romaji: "kiroku",
        traducao: "registro; anotação",
      },
    ],
  },
  {
    kanji: "省",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "かえり.みる", romaji: "kaeri.miru" }],
    significado: "ministério; economizar",
    exemplos: [
      {
        kanji: "省庁",
        furigana: "しょうちょう",
        romaji: "shouchou",
        traducao: "órgãos governamentais; ministérios",
      },
      {
        kanji: "節約",
        furigana: "せつやく",
        romaji: "setsuyaku",
        traducao: "economia; poupança",
      },
      {
        kanji: "省エネ",
        furigana: "しょうエネ",
        romaji: "shouene",
        traducao: "eficiência energética",
      },
    ],
  },
  {
    kanji: "旧",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "ふる.い", romaji: "furu.i" }],
    significado: "antigo; velho",
    exemplos: [
      {
        kanji: "旧友",
        furigana: "きゅうゆう",
        romaji: "kyuuyuu",
        traducao: "velho amigo",
      },
      {
        kanji: "旧市街",
        furigana: "きゅうしがい",
        romaji: "kyuushigai",
        traducao: "cidade antiga; centro histórico",
      },
      {
        kanji: "旧式",
        furigana: "きゅうしき",
        romaji: "kyuushiki",
        traducao: "estilo antigo; tradicional",
      },
    ],
  },
  {
    kanji: "橋",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "はし", romaji: "hashi" }],
    significado: "ponte",
    exemplos: [
      {
        kanji: "鉄橋",
        furigana: "てっきょう",
        romaji: "tekkyou",
        traducao: "ponte de ferro",
      },
      {
        kanji: "吊り橋",
        furigana: "つりばし",
        romaji: "tsuribashi",
        traducao: "ponte suspensa",
      },
      {
        kanji: "橋脚",
        furigana: "きょうきゃく",
        romaji: "kyoukyaku",
        traducao: "pilares de ponte",
      },
    ],
  },
  {
    kanji: "岸",
    onYomi: [{ katakana: "ガン", romaji: "gan" }],
    kunYomi: [{ hiragana: "きし", romaji: "kishi" }],
    significado: "costa; margem",
    exemplos: [
      {
        kanji: "海岸",
        furigana: "かいがん",
        romaji: "kaigan",
        traducao: "costa; litoral",
      },
      {
        kanji: "川岸",
        furigana: "かわぎし",
        romaji: "kawagishi",
        traducao: "margem do rio",
      },
      {
        kanji: "湖岸",
        furigana: "こがん",
        romaji: "kogan",
        traducao: "margem do lago",
      },
    ],
  },
  {
    kanji: "周",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [{ hiragana: "まわ.り", romaji: "mawa.ri" }],
    significado: "circunferência; volta",
    exemplos: [
      {
        kanji: "周囲",
        furigana: "しゅうい",
        romaji: "shuui",
        traducao: "circunferência; arredores",
      },
      {
        kanji: "一周",
        furigana: "いっしゅう",
        romaji: "isshuu",
        traducao: "uma volta; circuito",
      },
      {
        kanji: "週末",
        furigana: "しゅうまつ",
        romaji: "shuumatsu",
        traducao: "final de semana",
      },
    ],
  },
  {
    kanji: "材",
    onYomi: [{ katakana: "ザイ", romaji: "zai" }],
    kunYomi: [],
    significado: "material; substância",
    exemplos: [
      {
        kanji: "材料",
        furigana: "ざいりょう",
        romaji: "zairyou",
        traducao: "material; ingredientes",
      },
      {
        kanji: "原材料",
        furigana: "げんざいりょう",
        romaji: "genzairyou",
        traducao: "matéria-prima",
      },
      {
        kanji: "建材",
        furigana: "けんざい",
        romaji: "kenzai",
        traducao: "materiais de construção",
      },
    ],
  },
  {
    kanji: "戸",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "と", romaji: "to" }],
    significado: "porta; casa",
    exemplos: [
      {
        kanji: "戸口",
        furigana: "とぐち",
        romaji: "toguchi",
        traducao: "entrada; portal",
      },
      {
        kanji: "戸籍",
        furigana: "こせき",
        romaji: "koseki",
        traducao: "registro familiar",
      },
      {
        kanji: "戸数",
        furigana: "こすう",
        romaji: "kosuu",
        traducao: "número de casas",
      },
    ],
  },
  {
    kanji: "央",
    onYomi: [{ katakana: "オウ", romaji: "ou" }],
    kunYomi: [],
    significado: "centro",
    exemplos: [
      {
        kanji: "中央",
        furigana: "ちゅうおう",
        romaji: "chuou",
        traducao: "centro; meio",
      },
      {
        kanji: "首都",
        furigana: "しゅと",
        romaji: "shuto",
        traducao: "capital",
      },
      {
        kanji: "中央線",
        furigana: "ちゅうおうせん",
        romaji: "chuou sen",
        traducao: "Linha Central",
      },
    ],
  },
  {
    kanji: "券",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [],
    significado: "bilhete; ingresso",
    exemplos: [
      {
        kanji: "入場券",
        furigana: "にゅうじょうけん",
        romaji: "nyuujouken",
        traducao: "bilhete de entrada",
      },
      {
        kanji: "航空券",
        furigana: "こうくうけん",
        romaji: "koukuuken",
        traducao: "passagem aérea",
      },
      {
        kanji: "切符",
        furigana: "きっぷ",
        romaji: "kippu",
        traducao: "bilhete",
      },
    ],
  },
  {
    kanji: "編",
    onYomi: [{ katakana: "ヘン", romaji: "hen" }],
    kunYomi: [{ hiragana: "あ.む", romaji: "a.mu" }],
    significado: "editar; compilar",
    exemplos: [
      {
        kanji: "編集",
        furigana: "へんしゅう",
        romaji: "henshuu",
        traducao: "edição",
      },
      {
        kanji: "編成",
        furigana: "へんせい",
        romaji: "hensei",
        traducao: "composição; formação",
      },
      {
        kanji: "編物",
        furigana: "あみもの",
        romaji: "amimono",
        traducao: "trabalho de tricô; tecido",
      },
    ],
  },
  {
    kanji: "捜",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "さが.す", romaji: "saga.su" }],
    significado: "procurar; pesquisar",
    exemplos: [
      {
        kanji: "捜査",
        furigana: "そうさ",
        romaji: "sousa",
        traducao: "investigação",
      },
      {
        kanji: "捜索",
        furigana: "そうさく",
        romaji: "sousaku",
        traducao: "busca; pesquisa",
      },
      {
        kanji: "捜し物",
        furigana: "さがしもの",
        romaji: "sagashi mono",
        traducao: "procurar algo",
      },
    ],
  },
  {
    kanji: "竹",
    onYomi: [{ katakana: "チク", romaji: "chiku" }],
    kunYomi: [{ hiragana: "たけ", romaji: "take" }],
    significado: "bambu",
    exemplos: [
      {
        kanji: "竹林",
        furigana: "ちくりん",
        romaji: "chikurin",
        traducao: "bosque de bambu",
      },
      {
        kanji: "竹馬",
        furigana: "たけうま",
        romaji: "takeuma",
        traducao: "cavalo de pau",
      },
      {
        kanji: "竹製",
        furigana: "ちくせい",
        romaji: "chikusei",
        traducao: "feito de bambu",
      },
    ],
  },
  {
    kanji: "超",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [],
    significado: "super; ultra",
    exemplos: [
      {
        kanji: "超過",
        furigana: "ちょうか",
        romaji: "chouka",
        traducao: "excesso; ultrapassar",
      },
      {
        kanji: "超能力",
        furigana: "ちょうのうりょく",
        romaji: "chounouryoku",
        traducao: "poderes sobrenaturais",
      },
      {
        kanji: "超える",
        furigana: "こえる",
        romaji: "koeru",
        traducao: "ultrapassar; exceder",
      },
    ],
  },
  {
    kanji: "並",
    onYomi: [{ katakana: "ヘイ", romaji: "hei" }],
    kunYomi: [
      { hiragana: "なみ", romaji: "nami" },
      { hiragana: "なら.べる", romaji: "nara.beru" },
    ],
    significado: "alinhado; igual",
    exemplos: [
      {
        kanji: "並行",
        furigana: "へいこう",
        romaji: "heikou",
        traducao: "paralelo",
      },
      {
        kanji: "並ぶ",
        furigana: "ならぶ",
        romaji: "narabu",
        traducao: "ficar em fila; estar alinhado",
      },
      {
        kanji: "等しい",
        furigana: "ひとしい",
        romaji: "hitoshii",
        traducao: "igual",
      },
    ],
  },
  {
    kanji: "療",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [],
    significado: "tratamento; terapia",
    exemplos: [
      {
        kanji: "療法",
        furigana: "りょうほう",
        romaji: "ryouhou",
        traducao: "terapia; tratamento",
      },
      {
        kanji: "治療",
        furigana: "ちりょう",
        romaji: "chiryou",
        traducao: "tratamento; terapia",
      },
      {
        kanji: "療養",
        furigana: "りょうよう",
        romaji: "ryouyou",
        traducao: "recuperação; convalescença",
      },
    ],
  },
  {
    kanji: "採",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "と.る", romaji: "to.ru" }],
    significado: "colher; adotar",
    exemplos: [
      {
        kanji: "採用",
        furigana: "さいよう",
        romaji: "saiyou",
        traducao: "contratação; admissão",
      },
      {
        kanji: "採取",
        furigana: "さいしゅ",
        romaji: "saishu",
        traducao: "coleta; colheita",
      },
      {
        kanji: "採掘",
        furigana: "さいくつ",
        romaji: "saikutsu",
        traducao: "extração; mineração",
      },
    ],
  },
  {
    kanji: "森",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "もり", romaji: "mori" }],
    significado: "floresta",
    exemplos: [
      {
        kanji: "森林",
        furigana: "しんりん",
        romaji: "shinrin",
        traducao: "floresta",
      },
      {
        kanji: "森の中",
        furigana: "もりのなか",
        romaji: "mori no naka",
        traducao: "no meio da floresta",
      },
      {
        kanji: "森に迷う",
        furigana: "もりにまよう",
        romaji: "mori ni mayou",
        traducao: "se perder na floresta",
      },
    ],
  },
  {
    kanji: "競",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "きそ.う", romaji: "kiso.u" }],
    significado: "competir; corrida",
    exemplos: [
      {
        kanji: "競争",
        furigana: "きょうそう",
        romaji: "kyousou",
        traducao: "competição; corrida",
      },
      {
        kanji: "競馬",
        furigana: "けいば",
        romaji: "keiba",
        traducao: "corrida de cavalos",
      },
      {
        kanji: "競技",
        furigana: "きょうぎ",
        romaji: "kyougi",
        traducao: "esporte; competição",
      },
    ],
  },
  {
    kanji: "介",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [],
    significado: "intermediar; auxiliar",
    exemplos: [
      {
        kanji: "介護",
        furigana: "かいご",
        romaji: "kaigo",
        traducao: "cuidados de enfermagem; assistência",
      },
      {
        kanji: "紹介",
        furigana: "しょうかい",
        romaji: "shoukai",
        traducao: "introdução; apresentação",
      },
      {
        kanji: "介入",
        furigana: "かいにゅう",
        romaji: "kainyuu",
        traducao: "intervenção; interferência",
      },
    ],
  },
  {
    kanji: "根",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [{ hiragana: "ね", romaji: "ne" }],
    significado: "raiz; origem",
    exemplos: [
      {
        kanji: "根本",
        furigana: "こんぽん",
        romaji: "konpon",
        traducao: "raiz; fundamento",
      },
      {
        kanji: "起源",
        furigana: "きげん",
        romaji: "kigen",
        traducao: "origem; fonte",
      },
      {
        kanji: "根気",
        furigana: "こんき",
        romaji: "konki",
        traducao: "paciência; perseverança",
      },
    ],
  },
  {
    kanji: "販",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [],
    significado: "venda; comercialização",
    exemplos: [
      {
        kanji: "販売",
        furigana: "はんばい",
        romaji: "hanbai",
        traducao: "venda; comercialização",
      },
      {
        kanji: "販路",
        furigana: "はんろ",
        romaji: "hanro",
        traducao: "canal de vendas",
      },
      {
        kanji: "自販機",
        furigana: "じはんき",
        romaji: "jihanki",
        traducao: "máquina de venda automática",
      },
    ],
  },
  {
    kanji: "歴",
    onYomi: [{ katakana: "レキ", romaji: "reki" }],
    kunYomi: [],
    significado: "história; experiência",
    exemplos: [
      {
        kanji: "歴史",
        furigana: "れきし",
        romaji: "rekishi",
        traducao: "história",
      },
      {
        kanji: "経歴",
        furigana: "けいれき",
        romaji: "keireki",
        traducao: "experiência; histórico",
      },
      {
        kanji: "履歴書",
        furigana: "りれきしょ",
        romaji: "rirekisho",
        traducao: "currículo",
      },
    ],
  },
  {
    kanji: "将",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "general; líder",
    exemplos: [
      {
        kanji: "将来",
        furigana: "しょうらい",
        romaji: "shourai",
        traducao: "futuro; perspectivas",
      },
      {
        kanji: "将棋",
        furigana: "しょうぎ",
        romaji: "shougi",
        traducao: "xadrez japonês",
      },
      {
        kanji: "将軍",
        furigana: "しょうぐん",
        romaji: "shougun",
        traducao: "general; comandante",
      },
    ],
  },
  {
    kanji: "幅",
    onYomi: [{ katakana: "フク", romaji: "fuku" }],
    kunYomi: [{ hiragana: "はば", romaji: "haba" }],
    significado: "largura; extensão",
    exemplos: [
      {
        kanji: "幅広い",
        furigana: "はばひろい",
        romaji: "habahiroi",
        traducao: "amplo; vasto",
      },
      {
        kanji: "幅員",
        furigana: "はばいん",
        romaji: "habain",
        traducao: "largura (de uma estrada)",
      },
      {
        kanji: "幅狭い",
        furigana: "はばせまい",
        romaji: "habasemai",
        traducao: "estreito",
      },
    ],
  },
  {
    kanji: "般",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [],
    significado: "geral; comum",
    exemplos: [
      {
        kanji: "一般",
        furigana: "いっぱん",
        romaji: "ippan",
        traducao: "geral; comum",
      },
      {
        kanji: "全般",
        furigana: "ぜんぱん",
        romaji: "zenpan",
        traducao: "em geral; geralmente",
      },
      {
        kanji: "一般的",
        furigana: "いっぱんてき",
        romaji: "ippan teki",
        traducao: "geral; comum",
      },
    ],
  },
  {
    kanji: "貿",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [],
    significado: "comércio; negócio",
    exemplos: [
      {
        kanji: "貿易",
        furigana: "ぼうえき",
        romaji: "boueki",
        traducao: "comércio exterior",
      },
      {
        kanji: "輸出入",
        furigana: "ゆしゅつにゅう",
        romaji: "yushutsunyuu",
        traducao: "exportação e importação",
      },
      {
        kanji: "貿易業",
        furigana: "ぼうえきぎょう",
        romaji: "bouekigyou",
        traducao: "indústria do comércio exterior",
      },
    ],
  },
  {
    kanji: "講",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "palestra; curso",
    exemplos: [
      {
        kanji: "講義",
        furigana: "こうぎ",
        romaji: "kougi",
        traducao: "palestra; aula",
      },
      {
        kanji: "講演",
        furigana: "こうえん",
        romaji: "kouen",
        traducao: "palestra; conferência",
      },
      {
        kanji: "講師",
        furigana: "こうし",
        romaji: "koushi",
        traducao: "instrutor; professor",
      },
    ],
  },
  {
    kanji: "林",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [{ hiragana: "はやし", romaji: "hayashi" }],
    significado: "floresta; bosque",
    exemplos: [
      {
        kanji: "森林",
        furigana: "しんりん",
        romaji: "shinrin",
        traducao: "floresta",
      },
      {
        kanji: "人工林",
        furigana: "じんこうりん",
        romaji: "jinkourin",
        traducao: "floresta plantada",
      },
      {
        kanji: "広葉樹林",
        furigana: "こうようじゅりん",
        romaji: "kouyoujurin",
        traducao: "floresta de folhas largas",
      },
    ],
  },
  {
    kanji: "装",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "よそお.う", romaji: "yosoo.u" }],
    significado: "vestir; equipar",
    exemplos: [
      {
        kanji: "装置",
        furigana: "そうち",
        romaji: "souchi",
        traducao: "dispositivo; equipamento",
      },
      {
        kanji: "装備",
        furigana: "そうび",
        romaji: "soubi",
        traducao: "equipamento; armamento",
      },
      {
        kanji: "装飾",
        furigana: "そうしょく",
        romaji: "soushoku",
        traducao: "decoração; ornamento",
      },
    ],
  },
  {
    kanji: "諸",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [],
    significado: "vários; muitos",
    exemplos: [
      {
        kanji: "諸国",
        furigana: "しょこく",
        romaji: "shokoku",
        traducao: "vários países",
      },
      {
        kanji: "諸条件",
        furigana: "しょじょうけん",
        romaji: "shojouken",
        traducao: "diversas condições",
      },
      {
        kanji: "諸問題",
        furigana: "しょもんだい",
        romaji: "shomondai",
        traducao: "vários problemas",
      },
    ],
  },
  {
    kanji: "劇",
    onYomi: [{ katakana: "ゲキ", romaji: "geki" }],
    kunYomi: [],
    significado: "drama; peça teatral",
    exemplos: [
      {
        kanji: "劇場",
        furigana: "げきじょう",
        romaji: "gekijou",
        traducao: "teatro",
      },
      {
        kanji: "演劇",
        furigana: "えんげき",
        romaji: "engeki",
        traducao: "teatro; drama",
      },
      {
        kanji: "劇団",
        furigana: "げきだん",
        romaji: "gekidan",
        traducao: "companhia teatral",
      },
    ],
  },
  {
    kanji: "河",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "かわ", romaji: "kawa" }],
    significado: "rio",
    exemplos: [
      {
        kanji: "川河",
        furigana: "かわかわ",
        romaji: "kawakawa",
        traducao: "rios e riachos",
      },
      {
        kanji: "河口",
        furigana: "かこう",
        romaji: "kakou",
        traducao: "foz de um rio",
      },
      {
        kanji: "河川",
        furigana: "かせん",
        romaji: "kasen",
        traducao: "rio; curso de água",
      },
    ],
  },
  {
    kanji: "航",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "navegar; viagem",
    exemplos: [
      {
        kanji: "航空",
        furigana: "こうくう",
        romaji: "koukuu",
        traducao: "aviação",
      },
      {
        kanji: "航海",
        furigana: "こうかい",
        romaji: "koukai",
        traducao: "navegação",
      },
      {
        kanji: "航空会社",
        furigana: "こうくうがいしゃ",
        romaji: "koukuu gaisha",
        traducao: "companhia aérea",
      },
    ],
  },
  {
    kanji: "鉄",
    onYomi: [{ katakana: "テツ", romaji: "tetsu" }],
    kunYomi: [],
    significado: "ferro",
    exemplos: [
      {
        kanji: "鉄道",
        furigana: "てつどう",
        romaji: "tetsudou",
        traducao: "ferrovia",
      },
      {
        kanji: "鉄橋",
        furigana: "てっきょう",
        romaji: "tekkyou",
        traducao: "ponte de ferro",
      },
      {
        kanji: "鉄砲",
        furigana: "てっぽう",
        romaji: "teppou",
        traducao: "arma de fogo",
      },
    ],
  },
  {
    kanji: "児",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [
      { hiragana: "こ", romaji: "ko" },
      { hiragana: "ちご", romaji: "chigo" },
    ],
    significado: "criança",
    exemplos: [
      {
        kanji: "子児",
        furigana: "しじ",
        romaji: "shiji",
        traducao: "bebê; criança",
      },
      {
        kanji: "幼児",
        furigana: "ようじ",
        romaji: "youji",
        traducao: "criança pequena; bebê",
      },
      {
        kanji: "児童",
        furigana: "じどう",
        romaji: "jidou",
        traducao: "criança; menores",
      },
    ],
  },
  {
    kanji: "禁",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [],
    significado: "proibido; proibição",
    exemplos: [
      {
        kanji: "禁止",
        furigana: "きんし",
        romaji: "kinshi",
        traducao: "proibição; proibir",
      },
      {
        kanji: "禁煙",
        furigana: "きんえん",
        romaji: "kin'en",
        traducao: "proibido fumar",
      },
      {
        kanji: "禁忌",
        furigana: "きんき",
        romaji: "kinki",
        traducao: "tabu; proibido",
      },
    ],
  },
  {
    kanji: "印",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [{ hiragana: "しるし", romaji: "shirushi" }],
    significado: "selo; marca",
    exemplos: [
      {
        kanji: "印鑑",
        furigana: "いんかん",
        romaji: "inkan",
        traducao: "carimbo; selo",
      },
      {
        kanji: "印刷",
        furigana: "いんさつ",
        romaji: "insatsu",
        traducao: "impressão",
      },
      {
        kanji: "印象",
        furigana: "いんしょう",
        romaji: "inshou",
        traducao: "impressão",
      },
    ],
  },
  {
    kanji: "逆",
    onYomi: [{ katakana: "ギャク", romaji: "gyaku" }],
    kunYomi: [
      { hiragana: "さか", romaji: "saka" },
      { hiragana: "ぎゃく", romaji: "gyaku" },
    ],
    significado: "contrário; inverso",
    exemplos: [
      {
        kanji: "逆さ",
        furigana: "さかさ",
        romaji: "sakasa",
        traducao: "de cabeça para baixo; invertido",
      },
      {
        kanji: "逆境",
        furigana: "ぎゃっきょう",
        romaji: "gyakkyou",
        traducao: "situação adversa",
      },
      {
        kanji: "逆転",
        furigana: "ぎゃくてん",
        romaji: "gyakuten",
        traducao: "reviravolta; inversão",
      },
    ],
  },
  {
    kanji: "換",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "か.える", romaji: "ka.eru" }],
    significado: "trocar; substituir",
    exemplos: [
      {
        kanji: "交換",
        furigana: "こうかん",
        romaji: "koukan",
        traducao: "troca; substituição",
      },
      {
        kanji: "変換",
        furigana: "へんかん",
        romaji: "henkan",
        traducao: "conversão",
      },
      {
        kanji: "換気",
        furigana: "かんき",
        romaji: "kanki",
        traducao: "ventilação",
      },
    ],
  },
  {
    kanji: "久",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "ひさ.しい", romaji: "hisa.shii" }],
    significado: "longo tempo; há muito tempo",
    exemplos: [
      {
        kanji: "長久",
        furigana: "ちょうきゅう",
        romaji: "choukyuu",
        traducao: "longa duração",
      },
      {
        kanji: "久しぶり",
        furigana: "ひさしぶり",
        romaji: "hisashiburi",
        traducao: "há muito tempo; depois de muito tempo",
      },
      {
        kanji: "久保",
        furigana: "くぼ",
        romaji: "kubo",
        traducao: "sobrenome",
      },
    ],
  },
  {
    kanji: "短",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "みじか.い", romaji: "mijika.i" }],
    significado: "curto; breve",
    exemplos: [
      {
        kanji: "短期",
        furigana: "たんき",
        romaji: "tanki",
        traducao: "curto prazo",
      },
      {
        kanji: "短編",
        furigana: "たんぺん",
        romaji: "tanpen",
        traducao: "conto; história curta",
      },
      {
        kanji: "短気",
        furigana: "たんき",
        romaji: "tanki",
        traducao: "impaciente",
      },
    ],
  },
  {
    kanji: "油",
    onYomi: [{ katakana: "ユ", romaji: "yu" }],
    kunYomi: [{ hiragana: "あぶら", romaji: "abura" }],
    significado: "óleo; gordura",
    exemplos: [
      {
        kanji: "食用油",
        furigana: "しょくようあぶら",
        romaji: "shokuyouabura",
        traducao: "óleo comestível",
      },
      {
        kanji: "機械油",
        furigana: "きかいあぶら",
        romaji: "kikaiaabura",
        traducao: "óleo lubrificante",
      },
      {
        kanji: "油絵",
        furigana: "あぶらえ",
        romaji: "aburae",
        traducao: "pintura a óleo",
      },
    ],
  },
  {
    kanji: "暴",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [{ hiragana: "あば.く", romaji: "aba.ku" }],
    significado: "violento; feroz",
    exemplos: [
      {
        kanji: "暴力",
        furigana: "ぼうりょく",
        romaji: "bouryoku",
        traducao: "violência",
      },
      {
        kanji: "暴風雨",
        furigana: "ぼうふうう",
        romaji: "boufuuu",
        traducao: "tempestade",
      },
      {
        kanji: "暴露",
        furigana: "ばくろ",
        romaji: "bakuro",
        traducao: "revelação; exposição",
      },
    ],
  },
  {
    kanji: "輪",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [{ hiragana: "わ", romaji: "wa" }],
    significado: "roda; aro",
    exemplos: [
      {
        kanji: "輪ゴム",
        furigana: "わゴム",
        romaji: "wagomu",
        traducao: "elástico",
      },
      {
        kanji: "車輪",
        furigana: "しゃりん",
        romaji: "sharin",
        traducao: "roda",
      },
      {
        kanji: "連結輪",
        furigana: "れんけつりん",
        romaji: "renketsurin",
        traducao: "acoplador",
      },
    ],
  },
  {
    kanji: "占",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "し.める", romaji: "shi.meru" }],
    significado: "adivinhar; ocupar",
    exemplos: [
      {
        kanji: "占星術",
        furigana: "せんせいじゅつ",
        romaji: "senseijutsu",
        traducao: "astrologia",
      },
      {
        kanji: "占有",
        furigana: "せんゆう",
        romaji: "senyuu",
        traducao: "ocupação; posse",
      },
      {
        kanji: "占める",
        furigana: "しめる",
        romaji: "shimeru",
        traducao: "ocupar; tomar",
      },
    ],
  },
  {
    kanji: "植",
    onYomi: [{ katakana: "ショク", romaji: "shoku" }],
    kunYomi: [{ hiragana: "う.える", romaji: "u.eru" }],
    significado: "plantar",
    exemplos: [
      {
        kanji: "植物",
        furigana: "しょくぶつ",
        romaji: "shokubutsu",
        traducao: "planta",
      },
      {
        kanji: "栽植",
        furigana: "さいしょく",
        romaji: "saishoku",
        traducao: "cultivo; plantio",
      },
      {
        kanji: "植林",
        furigana: "しょくりん",
        romaji: "shokurin",
        traducao: "reflorestamento",
      },
    ],
  },
  {
    kanji: "清",
    onYomi: [
      { katakana: "セイ", romaji: "sei" },
      { katakana: "ショウ", romaji: "shou" },
    ],
    kunYomi: [{ hiragana: "きよ.い", romaji: "kiyo.i" }],
    significado: "limpo; puro",
    exemplos: [
      {
        kanji: "清潔",
        furigana: "せいけつ",
        romaji: "seiketsu",
        traducao: "limpeza; higiene",
      },
      {
        kanji: "清書",
        furigana: "せいしょ",
        romaji: "seisho",
        traducao: "cópia limpa",
      },
      {
        kanji: "清澄",
        furigana: "せいちょう",
        romaji: "seichou",
        traducao: "clareza; limpidez",
      },
    ],
  },
  {
    kanji: "倍",
    onYomi: [{ katakana: "バイ", romaji: "bai" }],
    kunYomi: [],
    significado: "dobro; múltiplo",
    exemplos: [
      {
        kanji: "倍増",
        furigana: "ばいぞう",
        romaji: "baizou",
        traducao: "aumento duplo",
      },
      {
        kanji: "倍率",
        furigana: "ばいりつ",
        romaji: "bairitsu",
        traducao: "taxa; ampliação",
      },
      {
        kanji: "二倍",
        furigana: "にばい",
        romaji: "nibai",
        traducao: "o dobro",
      },
    ],
  },
  {
    kanji: "均",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [],
    significado: "igual; equilíbrio",
    exemplos: [
      {
        kanji: "均等",
        furigana: "きんとう",
        romaji: "kintou",
        traducao: "igualdade; equilíbrio",
      },
      {
        kanji: "平均",
        furigana: "へいきん",
        romaji: "heikin",
        traducao: "média; médio",
      },
      {
        kanji: "均衡",
        furigana: "きんこう",
        romaji: "kinkou",
        traducao: "equilíbrio; estabilidade",
      },
    ],
  },
  {
    kanji: "億",
    onYomi: [{ katakana: "オク", romaji: "oku" }],
    kunYomi: [],
    significado: "cem milhões",
    exemplos: [
      {
        kanji: "億万",
        furigana: "おくまん",
        romaji: "okuman",
        traducao: "centenas de milhões",
      },
      {
        kanji: "億劫",
        furigana: "おっくう",
        romaji: "okkuu",
        traducao: "aborrecido; incômodo",
      },
      {
        kanji: "数億",
        furigana: "すうおく",
        romaji: "suuoku",
        traducao: "vários bilhões",
      },
    ],
  },
  {
    kanji: "圧",
    onYomi: [{ katakana: "アツ", romaji: "atsu" }],
    kunYomi: [{ hiragana: "お.す", romaji: "o.su" }],
    significado: "pressão; força",
    exemplos: [
      {
        kanji: "圧力",
        furigana: "あつりょく",
        romaji: "atsuryoku",
        traducao: "pressão",
      },
      {
        kanji: "圧倒",
        furigana: "あっとう",
        romaji: "attou",
        traducao: "dominação; esmagador",
      },
      {
        kanji: "圧迫",
        furigana: "あっぱく",
        romaji: "appaku",
        traducao: "pressão; opressão",
      },
    ],
  },
  {
    kanji: "芸",
    onYomi: [{ katakana: "ゲイ", romaji: "gei" }],
    kunYomi: [{ hiragana: "う.える", romaji: "u.eru" }],
    significado: "arte; talento",
    exemplos: [
      {
        kanji: "芸術",
        furigana: "げいじゅつ",
        romaji: "geijutsu",
        traducao: "arte",
      },
      {
        kanji: "芸能",
        furigana: "げいのう",
        romaji: "geinou",
        traducao: "entretenimento; habilidades artísticas",
      },
      {
        kanji: "書芸",
        furigana: "しょげい",
        romaji: "shogei",
        traducao: "artes literárias",
      },
    ],
  },
  {
    kanji: "署",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [],
    significado: "delegacia de polícia",
    exemplos: [
      {
        kanji: "警察署",
        furigana: "けいさつしょ",
        romaji: "keisatsusho",
        traducao: "delegacia de polícia",
      },
      {
        kanji: "消防署",
        furigana: "しょうぼうしょ",
        romaji: "shoubousho",
        traducao: "corpo de bombeiros",
      },
      {
        kanji: "署名",
        furigana: "しょめい",
        romaji: "shomei",
        traducao: "assinatura",
      },
    ],
  },
  {
    kanji: "伸",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "の.びる", romaji: "no.biru" }],
    significado: "estender; crescer",
    exemplos: [
      {
        kanji: "伸長",
        furigana: "しんちょう",
        romaji: "shinchou",
        traducao: "crescimento; expansão",
      },
      {
        kanji: "延伸",
        furigana: "えんしん",
        romaji: "enshin",
        traducao: "extensão; expansão",
      },
      {
        kanji: "伸ばす",
        furigana: "のばす",
        romaji: "nobasu",
        traducao: "estender; alongar",
      },
    ],
  },
  {
    kanji: "停",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "と.める", romaji: "to.meru" }],
    significado: "parar; suspender",
    exemplos: [
      {
        kanji: "停止",
        furigana: "ていし",
        romaji: "teishi",
        traducao: "parada; suspensão",
      },
      {
        kanji: "停車",
        furigana: "ていしゃ",
        romaji: "teisha",
        traducao: "parada (de um veículo)",
      },
      {
        kanji: "停電",
        furigana: "ていでん",
        romaji: "teiden",
        traducao: "apagão; falta de energia",
      },
    ],
  },
  {
    kanji: "爆",
    onYomi: [{ katakana: "バク", romaji: "baku" }],
    kunYomi: [],
    significado: "explosão",
    exemplos: [
      {
        kanji: "爆発",
        furigana: "ばくはつ",
        romaji: "bakuhatsu",
        traducao: "explosão",
      },
      {
        kanji: "爆笑",
        furigana: "ばくしょう",
        romaji: "bakushou",
        traducao: "risada estrondosa",
      },
      {
        kanji: "爆弾",
        furigana: "ばくだん",
        romaji: "bakudan",
        traducao: "bomba",
      },
    ],
  },
  {
    kanji: "陸",
    onYomi: [{ katakana: "リク", romaji: "riku" }],
    kunYomi: [],
    significado: "terra; continente",
    exemplos: [
      {
        kanji: "陸上",
        furigana: "りくじょう",
        romaji: "rikujou",
        traducao: "atletismo",
      },
      {
        kanji: "陸地",
        furigana: "りくち",
        romaji: "rikuchi",
        traducao: "terra firme",
      },
      {
        kanji: "海陸",
        furigana: "かいりく",
        romaji: "kairiku",
        traducao: "mar e terra",
      },
    ],
  },
  {
    kanji: "玉",
    onYomi: [{ katakana: "ギョク", romaji: "gyoku" }],
    kunYomi: [{ hiragana: "たま", romaji: "tama" }],
    significado: "esfera; jóia",
    exemplos: [
      {
        kanji: "玉石",
        furigana: "ぎょくせき",
        romaji: "gyokuseki",
        traducao: "jade e pedra",
      },
      {
        kanji: "玉座",
        furigana: "ぎょくざ",
        romaji: "gyokuza",
        traducao: "trono",
      },
      {
        kanji: "玉手箱",
        furigana: "たまてばこ",
        romaji: "tamatebako",
        traducao: "caixa de tesouros",
      },
    ],
  },
  {
    kanji: "波",
    onYomi: [{ katakana: "ハ", romaji: "ha" }],
    kunYomi: [{ hiragana: "なみ", romaji: "nami" }],
    significado: "onda",
    exemplos: [
      {
        kanji: "波浪",
        furigana: "はろう",
        romaji: "harou",
        traducao: "mar agitado; ondas",
      },
      {
        kanji: "波乱",
        furigana: "はらん",
        romaji: "haran",
        traducao: "distúrbio; agitação",
      },
      {
        kanji: "波紋",
        furigana: "はもん",
        romaji: "hamon",
        traducao: "ondulação; anéis concêntricos",
      },
    ],
  },
  {
    kanji: "帯",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "お.びる", romaji: "o.biru" }],
    significado: "faixa; cinto",
    exemplos: [
      {
        kanji: "帯広",
        furigana: "おびひろ",
        romaji: "obihiro",
        traducao: "nome de uma cidade",
      },
      {
        kanji: "帯状",
        furigana: "たいじょう",
        romaji: "taijou",
        traducao: "em forma de faixa",
      },
      {
        kanji: "書籍帯",
        furigana: "しょせきおび",
        romaji: "shosekiobi",
        traducao: "faixa promocional de livro",
      },
    ],
  },
  {
    kanji: "延",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "の.びる", romaji: "no.biru" }],
    significado: "estender; prolongar",
    exemplos: [
      {
        kanji: "延長",
        furigana: "えんちょう",
        romaji: "encho",
        traducao: "prolongamento; extensão",
      },
      {
        kanji: "延期",
        furigana: "えんき",
        romaji: "enki",
        traducao: "adiamento; postergação",
      },
      {
        kanji: "延びる",
        furigana: "のびる",
        romaji: "nobiru",
        traducao: "estender-se; prolongar-se",
      },
    ],
  },
  {
    kanji: "羽",
    onYomi: [{ katakana: "ウ", romaji: "u" }],
    kunYomi: [{ hiragana: "はね", romaji: "hane" }],
    significado: "pena; asa",
    exemplos: [
      {
        kanji: "羽根",
        furigana: "はね",
        romaji: "hane",
        traducao: "pena; asa",
      },
      {
        kanji: "羽毛",
        furigana: "うもう",
        romaji: "umou",
        traducao: "penugem; plumagem",
      },
      {
        kanji: "羽ばたく",
        furigana: "はばたく",
        romaji: "habataku",
        traducao: "bater as asas; voar",
      },
    ],
  },
  {
    kanji: "固",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "かた.める", romaji: "kata.meru" }],
    significado: "firme; sólido",
    exemplos: [
      {
        kanji: "固定",
        furigana: "こてい",
        romaji: "kotei",
        traducao: "fixo; estático",
      },
      {
        kanji: "固体",
        furigana: "こたい",
        romaji: "kotai",
        traducao: "sólido",
      },
      {
        kanji: "固める",
        furigana: "かためる",
        romaji: "katameru",
        traducao: "tornar firme; solidificar",
      },
    ],
  },
  {
    kanji: "則",
    onYomi: [{ katakana: "ソク", romaji: "soku" }],
    kunYomi: [],
    significado: "regra; princípio",
    exemplos: [
      {
        kanji: "法則",
        furigana: "ほうそく",
        romaji: "housoku",
        traducao: "lei; princípio",
      },
      {
        kanji: "規則",
        furigana: "きそく",
        romaji: "kisoku",
        traducao: "regra; regulamento",
      },
      {
        kanji: "原則",
        furigana: "げんそく",
        romaji: "gensoku",
        traducao: "princípio; regra básica",
      },
    ],
  },
  {
    kanji: "乱",
    onYomi: [{ katakana: "ラン", romaji: "ran" }],
    kunYomi: [{ hiragana: "みだ.れる", romaji: "mi.da.reru" }],
    significado: "desordem; tumulto",
    exemplos: [
      {
        kanji: "混乱",
        furigana: "こんらん",
        romaji: "konran",
        traducao: "confusão; caos",
      },
      {
        kanji: "乱暴",
        furigana: "らんぼう",
        romaji: "ranbou",
        traducao: "violento; brutal",
      },
      {
        kanji: "乱れる",
        furigana: "みだれる",
        romaji: "midareru",
        traducao: "ficar desordenado; bagunçar",
      },
    ],
  },
  {
    kanji: "普",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [],
    significado: "normal; comum",
    exemplos: [
      {
        kanji: "普通",
        furigana: "ふつう",
        romaji: "futsuu",
        traducao: "normal; comum",
      },
      {
        kanji: "普及",
        furigana: "ふきゅう",
        romaji: "fukyuu",
        traducao: "popularização; disseminação",
      },
      {
        kanji: "普通運転",
        furigana: "ふつううんてん",
        romaji: "futsuuunten",
        traducao: "dirigir normalmente",
      },
    ],
  },
  {
    kanji: "測",
    onYomi: [{ katakana: "ソク", romaji: "soku" }],
    kunYomi: [{ hiragana: "はか.る", romaji: "haka.ru" }],
    significado: "medir; calcular",
    exemplos: [
      {
        kanji: "測定",
        furigana: "そくてい",
        romaji: "sokutei",
        traducao: "medição",
      },
      {
        kanji: "測量",
        furigana: "そくりょう",
        romaji: "sokuryou",
        traducao: "medição de terreno",
      },
      {
        kanji: "予測",
        furigana: "よそく",
        romaji: "yosoku",
        traducao: "previsão; estimativa",
      },
    ],
  },
  {
    kanji: "豊",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "ゆた.か", romaji: "yuta.ka" }],
    significado: "abundante; rico",
    exemplos: [
      {
        kanji: "豊富",
        furigana: "ほうふ",
        romaji: "houfu",
        traducao: "abundante; rico",
      },
      {
        kanji: "豊作",
        furigana: "ほうさく",
        romaji: "housaku",
        traducao: "colheita abundante",
      },
      {
        kanji: "豊かな",
        furigana: "ゆたかな",
        romaji: "yutakana",
        traducao: "rico; abundante",
      },
    ],
  },
  {
    kanji: "厚",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "あつ.い", romaji: "atsu.i" }],
    significado: "espesso; generoso",
    exemplos: [
      {
        kanji: "厚意",
        furigana: "こうい",
        romaji: "koui",
        traducao: "gentileza; generosidade",
      },
      {
        kanji: "厚切り",
        furigana: "あつぎり",
        romaji: "atsugiri",
        traducao: "fatia grossa",
      },
      {
        kanji: "厚かましい",
        furigana: "あつかましい",
        romaji: "atsukamashii",
        traducao: "desavergonhado; sem vergonha",
      },
    ],
  },
  {
    kanji: "齢",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [{ hiragana: "よわい", romaji: "yowai" }],
    significado: "idade",
    exemplos: [
      {
        kanji: "年齢",
        furigana: "ねんれい",
        romaji: "nenrei",
        traducao: "idade",
      },
      {
        kanji: "高齢",
        furigana: "こうれい",
        romaji: "kourei",
        traducao: "idade avançada",
      },
      {
        kanji: "長齢",
        furigana: "ちょうれい",
        romaji: "chourei",
        traducao: "longevidade",
      },
    ],
  },
  {
    kanji: "囲",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "かこ.む", romaji: "kako.mu" }],
    significado: "cercar; envolver",
    exemplos: [
      {
        kanji: "囲む",
        furigana: "かこむ",
        romaji: "kakomu",
        traducao: "cercar; envolver",
      },
      {
        kanji: "包囲",
        furigana: "ほうい",
        romaji: "houi",
        traducao: "cerco; cerco",
      },
      {
        kanji: "取り囲む",
        furigana: "とりかこむ",
        romaji: "torikakomu",
        traducao: "cercar; rodear",
      },
    ],
  },
  {
    kanji: "卒",
    onYomi: [{ katakana: "ソツ", romaji: "sotsu" }],
    kunYomi: [],
    significado: "graduar; terminar",
    exemplos: [
      {
        kanji: "卒業",
        furigana: "そつぎょう",
        romaji: "sotsugyou",
        traducao: "formatura; graduação",
      },
      {
        kanji: "新卒",
        furigana: "しんそつ",
        romaji: "shinsotsu",
        traducao: "recém-formado",
      },
      {
        kanji: "卒業式",
        furigana: "そつぎょうしき",
        romaji: "sotsugyoushiki",
        traducao: "cerimônia de formatura",
      },
    ],
  },
  {
    kanji: "略",
    onYomi: [{ katakana: "リャク", romaji: "ryaku" }],
    kunYomi: [],
    significado: "resumo; abreviação",
    exemplos: [
      {
        kanji: "略語",
        furigana: "りゃくご",
        romaji: "ryakugo",
        traducao: "abreviação",
      },
      {
        kanji: "大略",
        furigana: "たいりゃく",
        romaji: "tairyaku",
        traducao: "em resumo; essencialmente",
      },
      {
        kanji: "略奪",
        furigana: "りゃくだつ",
        romaji: "ryakudatsu",
        traducao: "saque; pilhagem",
      },
    ],
  },
  {
    kanji: "承",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "うけたまわ.る", romaji: "uketamawa.ru" }],
    significado: "aceitar; consentir",
    exemplos: [
      {
        kanji: "承知",
        furigana: "しょうち",
        romaji: "shouchi",
        traducao: "conhecimento; consentimento",
      },
      {
        kanji: "承諾",
        furigana: "しょうだく",
        romaji: "shoudaku",
        traducao: "aceitação; consentimento",
      },
      {
        kanji: "了承",
        furigana: "りょうしょう",
        romaji: "ryoushou",
        traducao: "aceitação; entendimento",
      },
    ],
  },
  {
    kanji: "順",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [],
    significado: "ordem; sequência",
    exemplos: [
      {
        kanji: "順番",
        furigana: "じゅんばん",
        romaji: "junban",
        traducao: "ordem; sequência",
      },
      {
        kanji: "順調",
        furigana: "じゅんちょう",
        romaji: "junchou",
        traducao: "bom progresso; fluente",
      },
      {
        kanji: "順序",
        furigana: "じゅんじょ",
        romaji: "junjo",
        traducao: "ordem; sequência",
      },
    ],
  },
  {
    kanji: "岩",
    onYomi: [{ katakana: "ガン", romaji: "gan" }],
    kunYomi: [{ hiragana: "いわ", romaji: "iwa" }],
    significado: "rocha; pedra",
    exemplos: [
      {
        kanji: "岩山",
        furigana: "いわやま",
        romaji: "iwayama",
        traducao: "montanha de rocha",
      },
      {
        kanji: "岩石",
        furigana: "がんせき",
        romaji: "ganseki",
        traducao: "rocha; pedra",
      },
      {
        kanji: "岩礁",
        furigana: "がんしょう",
        romaji: "ganshou",
        traducao: "recife; rochedo",
      },
    ],
  },
  {
    kanji: "練",
    onYomi: [{ katakana: "レン", romaji: "ren" }],
    kunYomi: [{ hiragana: "ね.る", romaji: "ne.ru" }],
    significado: "treinar; praticar",
    exemplos: [
      {
        kanji: "練習",
        furigana: "れんしゅう",
        romaji: "renshuu",
        traducao: "treinamento; prática",
      },
      {
        kanji: "修練",
        furigana: "しゅうれん",
        romaji: "shuuren",
        traducao: "aperfeiçoamento; treinamento",
      },
      {
        kanji: "鍛練",
        furigana: "たんれん",
        romaji: "tanren",
        traducao: "treinamento; disciplina",
      },
    ],
  },
  {
    kanji: "軽",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "かる.い", romaji: "karu.i" }],
    significado: "leve; fácil",
    exemplos: [
      {
        kanji: "軽量",
        furigana: "けいりょう",
        romaji: "keiryou",
        traducao: "leveza; peso leve",
      },
      {
        kanji: "軽快",
        furigana: "けいかい",
        romaji: "keikai",
        traducao: "ágil; ligeiro",
      },
      {
        kanji: "軽い",
        furigana: "かるい",
        romaji: "karui",
        traducao: "leve; fácil",
      },
    ],
  },
  {
    kanji: "了",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [{ hiragana: "お.わる", romaji: "o.waru" }],
    significado: "concluir; acabar",
    exemplos: [
      {
        kanji: "了解",
        furigana: "りょうかい",
        romaji: "ryoukai",
        traducao: "compreensão; entendimento",
      },
      {
        kanji: "完了",
        furigana: "かんりょう",
        romaji: "kanryou",
        traducao: "conclusão; finalização",
      },
      {
        kanji: "終了",
        furigana: "しゅうりょう",
        romaji: "shuuryou",
        traducao: "término; finalização",
      },
    ],
  },
  {
    kanji: "庁",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [],
    significado: "agência governamental; escritório",
    exemplos: [
      {
        kanji: "庁舎",
        furigana: "ちょうしゃ",
        romaji: "chousha",
        traducao: "prédio do governo; prefeitura",
      },
      {
        kanji: "庁内",
        furigana: "ちょうない",
        romaji: "chounai",
        traducao: "dentro do escritório",
      },
      {
        kanji: "庁長",
        furigana: "ちょうちょう",
        romaji: "chouchou",
        traducao: "chefe de escritório",
      },
    ],
  },
  {
    kanji: "城",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "しろ", romaji: "shiro" }],
    significado: "castelo; fortaleza",
    exemplos: [
      {
        kanji: "城壁",
        furigana: "じょうへき",
        romaji: "jouheki",
        traducao: "muralha do castelo",
      },
      {
        kanji: "城下町",
        furigana: "じょうかまち",
        romaji: "joukamachi",
        traducao: "cidade do castelo",
      },
      {
        kanji: "城址",
        furigana: "じょうし",
        romaji: "joushi",
        traducao: "ruínas do castelo",
      },
    ],
  },
  {
    kanji: "患",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "わずら.う", romaji: "wazura.u" }],
    significado: "doença; afligir",
    exemplos: [
      {
        kanji: "患者",
        furigana: "かんじゃ",
        romaji: "kanja",
        traducao: "paciente",
      },
      {
        kanji: "患部",
        furigana: "かんぶ",
        romaji: "kanbu",
        traducao: "parte afetada; área doente",
      },
      {
        kanji: "被患",
        furigana: "ひかん",
        romaji: "hikan",
        traducao: "ser afetado por uma doença",
      },
    ],
  },
  {
    kanji: "層",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [],
    significado: "camada; estrato",
    exemplos: [
      {
        kanji: "社会層",
        furigana: "しゃかいそう",
        romaji: "shakaisou",
        traducao: "camada social",
      },
      {
        kanji: "階層",
        furigana: "かいそう",
        romaji: "kaisou",
        traducao: "estrato; hierarquia",
      },
      {
        kanji: "層別",
        furigana: "そうべつ",
        romaji: "soubetsu",
        traducao: "classificação por camadas",
      },
    ],
  },
  {
    kanji: "版",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [],
    significado: "edição; versão",
    exemplos: [
      {
        kanji: "新版",
        furigana: "しんぱん",
        romaji: "shinpan",
        traducao: "nova edição",
      },
      {
        kanji: "初版",
        furigana: "しょはん",
        romaji: "shohan",
        traducao: "primeira edição",
      },
      {
        kanji: "復刻版",
        furigana: "ふっこくばん",
        romaji: "fukkokuban",
        traducao: "edição republicada",
      },
    ],
  },
  {
    kanji: "令",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [],
    significado: "ordem; comando",
    exemplos: [
      {
        kanji: "法令",
        furigana: "ほうれい",
        romaji: "hourei",
        traducao: "lei; regulamento",
      },
      {
        kanji: "勅令",
        furigana: "ちょくれい",
        romaji: "chokurei",
        traducao: "ordem imperial",
      },
      {
        kanji: "命令",
        furigana: "めいれい",
        romaji: "meirei",
        traducao: "ordem; comando",
      },
    ],
  },
  {
    kanji: "角",
    onYomi: [{ katakana: "カク", romaji: "kaku" }],
    kunYomi: [{ hiragana: "かど", romaji: "kado" }],
    significado: "canto; ângulo",
    exemplos: [
      {
        kanji: "直角",
        furigana: "ちょっかく",
        romaji: "chokkaku",
        traducao: "ângulo reto",
      },
      {
        kanji: "角度",
        furigana: "かくど",
        romaji: "kakudo",
        traducao: "ângulo",
      },
      {
        kanji: "四角",
        furigana: "しかく",
        romaji: "shikaku",
        traducao: "quadrado",
      },
    ],
  },
  {
    kanji: "絡",
    onYomi: [{ katakana: "ラク", romaji: "raku" }],
    kunYomi: [{ hiragana: "から.む", romaji: "kara.mu" }],
    significado: "entrelaçar; envolver",
    exemplos: [
      {
        kanji: "関絡",
        furigana: "かんらく",
        romaji: "kanraku",
        traducao: "relacionamento; conexão",
      },
      {
        kanji: "絡む",
        furigana: "からむ",
        romaji: "karamu",
        traducao: "envolver-se; enredar",
      },
      {
        kanji: "絡み合う",
        furigana: "からみあう",
        romaji: "karamiau",
        traducao: "entrelaçar-se; envolver-se",
      },
    ],
  },
  {
    kanji: "損",
    onYomi: [{ katakana: "ソン", romaji: "son" }],
    kunYomi: [{ hiragana: "そこ.なう", romaji: "soko.nau" }],
    significado: "prejuízo; danificar",
    exemplos: [
      {
        kanji: "損害",
        furigana: "そんがい",
        romaji: "songai",
        traducao: "prejuízo; dano",
      },
      {
        kanji: "損失",
        furigana: "そんしつ",
        romaji: "sonshitsu",
        traducao: "perda; prejuízo",
      },
      {
        kanji: "損傷",
        furigana: "そんしょう",
        romaji: "sonshou",
        traducao: "dano; lesão",
      },
    ],
  },
  {
    kanji: "募",
    onYomi: [{ katakana: "ボ", romaji: "bo" }],
    kunYomi: [{ hiragana: "つの.る", romaji: "tsuno.ru" }],
    significado: "recrutar; solicitar",
    exemplos: [
      {
        kanji: "募集",
        furigana: "ぼしゅう",
        romaji: "boshuu",
        traducao: "recrutamento; convocação",
      },
      {
        kanji: "募金",
        furigana: "ぼきん",
        romaji: "bokin",
        traducao: "doação; arrecadação",
      },
      {
        kanji: "募る",
        furigana: "つのる",
        romaji: "tsunoru",
        traducao: "recrutar; solicitar",
      },
    ],
  },
  {
    kanji: "裏",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [{ hiragana: "うら", romaji: "ura" }],
    significado: "parte de trás; reverso",
    exemplos: [
      {
        kanji: "裏面",
        furigana: "りめん",
        romaji: "rimen",
        traducao: "parte de trás; verso",
      },
      {
        kanji: "裏切る",
        furigana: "うらぎる",
        romaji: "uragiru",
        traducao: "trair; enganar",
      },
      {
        kanji: "表裏",
        furigana: "ひょうり",
        romaji: "hyouri",
        traducao: "anverso e reverso",
      },
    ],
  },
  {
    kanji: "仏",
    onYomi: [{ katakana: "ブツ", romaji: "butsu" }],
    kunYomi: [{ hiragana: "ほとけ", romaji: "hotoke" }],
    significado: "Buda; estátua de Buda",
    exemplos: [
      {
        kanji: "仏教",
        furigana: "ぶっきょう",
        romaji: "bukkyou",
        traducao: "Budismo",
      },
      {
        kanji: "仏像",
        furigana: "ぶつぞう",
        romaji: "butsuzou",
        traducao: "estátua de Buda",
      },
      {
        kanji: "釈迦",
        furigana: "しゃか",
        romaji: "shaka",
        traducao: "Buda; Sidarta Gautama",
      },
    ],
  },
  {
    kanji: "績",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [],
    significado: "realização; conquista",
    exemplos: [
      {
        kanji: "成績",
        furigana: "せいせき",
        romaji: "seiseki",
        traducao: "desempenho acadêmico; resultado",
      },
      {
        kanji: "実績",
        furigana: "じっせき",
        romaji: "jisshiki",
        traducao: "realização; conquista",
      },
      {
        kanji: "業績",
        furigana: "ぎょうせき",
        romaji: "gyouseki",
        traducao: "realização; desempenho",
      },
    ],
  },
  {
    kanji: "築",
    onYomi: [{ katakana: "チク", romaji: "chiku" }],
    kunYomi: [{ hiragana: "きず.く", romaji: "kizu.ku" }],
    significado: "construir; erguer",
    exemplos: [
      {
        kanji: "建築",
        furigana: "けんちく",
        romaji: "kenchiku",
        traducao: "arquitetura; construção",
      },
      {
        kanji: "築く",
        furigana: "きずく",
        romaji: "kizuku",
        traducao: "construir; erguer",
      },
      {
        kanji: "築年数",
        furigana: "ちくねんすう",
        romaji: "chikunensuu",
        traducao: "idade de construção",
      },
    ],
  },
  {
    kanji: "貨",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [],
    significado: "mercadoria; bem",
    exemplos: [
      {
        kanji: "貨物",
        furigana: "かもつ",
        romaji: "kamotsu",
        traducao: "carga; mercadoria",
      },
      {
        kanji: "通貨",
        furigana: "つうか",
        romaji: "tsuuka",
        traducao: "moeda; dinheiro",
      },
      {
        kanji: "貨幣",
        furigana: "かへい",
        romaji: "kahei",
        traducao: "moeda; cédula",
      },
    ],
  },
  {
    kanji: "混",
    onYomi: [{ katakana: "コン", romaji: "kon" }],
    kunYomi: [{ hiragana: "ま.じる", romaji: "ma.jiru" }],
    significado: "misturar; mesclar",
    exemplos: [
      {
        kanji: "混乱",
        furigana: "こんらん",
        romaji: "konran",
        traducao: "confusão; tumulto",
      },
      {
        kanji: "混雑",
        furigana: "こんざつ",
        romaji: "konzatsu",
        traducao: "congestionamento; lotado",
      },
      {
        kanji: "混合",
        furigana: "こんごう",
        romaji: "kongou",
        traducao: "misturar; combinar",
      },
    ],
  },
  {
    kanji: "昇",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "のぼ.る", romaji: "nobo.ru" }],
    significado: "subir; ascender",
    exemplos: [
      {
        kanji: "昇進",
        furigana: "しょうしん",
        romaji: "shoushin",
        traducao: "promoção",
      },
      {
        kanji: "昇格",
        furigana: "しょうかく",
        romaji: "shoukaku",
        traducao: "elevação de categoria",
      },
      {
        kanji: "出昇",
        furigana: "しゅっしょう",
        romaji: "shusshou",
        traducao: "nascer do sol",
      },
    ],
  },
  {
    kanji: "池",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [],
    significado: "lago; lagoa",
    exemplos: [
      {
        kanji: "魚池",
        furigana: "ぎょいけ",
        romaji: "gyoike",
        traducao: "lago de peixes",
      },
      {
        kanji: "人工池",
        furigana: "じんこういけ",
        romaji: "jinkouike",
        traducao: "lago artificial",
      },
      {
        kanji: "池泉",
        furigana: "いけいずみ",
        romaji: "ikeizumi",
        traducao: "lago e fonte",
      },
    ],
  },
  {
    kanji: "血",
    onYomi: [{ katakana: "ケツ", romaji: "ketsu" }],
    kunYomi: [{ hiragana: "ち", romaji: "chi" }],
    significado: "sangue",
    exemplos: [
      {
        kanji: "血液",
        furigana: "けつえき",
        romaji: "ketsueki",
        traducao: "sangue",
      },
      {
        kanji: "血圧",
        furigana: "けつあつ",
        romaji: "ketsuatsu",
        traducao: "pressão arterial",
      },
      {
        kanji: "鮮血",
        furigana: "せんけつ",
        romaji: "senketsu",
        traducao: "sangue fresco",
      },
    ],
  },
  {
    kanji: "温",
    onYomi: [{ katakana: "オン", romaji: "on" }],
    kunYomi: [{ hiragana: "あたた.か", romaji: "atata.ka" }],
    significado: "quente; morno",
    exemplos: [
      {
        kanji: "温度",
        furigana: "おんど",
        romaji: "ondo",
        traducao: "temperatura",
      },
      {
        kanji: "温泉",
        furigana: "おんせん",
        romaji: "onsen",
        traducao: "fonte termal; águas termais",
      },
      {
        kanji: "温暖",
        furigana: "おんだん",
        romaji: "ondan",
        traducao: "clima ameno; quente",
      },
    ],
  },
  {
    kanji: "季",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [],
    significado: "estação",
    exemplos: [
      {
        kanji: "四季",
        furigana: "しき",
        romaji: "shiki",
        traducao: "as quatro estações",
      },
      {
        kanji: "季節",
        furigana: "きせつ",
        romaji: "kisetsu",
        traducao: "estação; temporada",
      },
      {
        kanji: "季刊",
        furigana: "きかん",
        romaji: "kikan",
        traducao: "trimestral",
      },
    ],
  },
  {
    kanji: "星",
    onYomi: [
      { katakana: "セイ", romaji: "sei" },
      { katakana: "ショウ", romaji: "shou" },
    ],
    kunYomi: [{ hiragana: "ほし", romaji: "hoshi" }],
    significado: "estrela",
    exemplos: [
      {
        kanji: "星空",
        furigana: "ほしぞら",
        romaji: "hoshizora",
        traducao: "céu estrelado",
      },
      {
        kanji: "星座",
        furigana: "せいざ",
        romaji: "seiza",
        traducao: "constelação",
      },
      {
        kanji: "流星",
        furigana: "りゅうせい",
        romaji: "ryuusei",
        traducao: "estrela cadente",
      },
    ],
  },
  {
    kanji: "永",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [],
    significado: "eterno; perpétuo",
    exemplos: [
      {
        kanji: "永遠",
        furigana: "えいえん",
        romaji: "eien",
        traducao: "eternidade",
      },
      {
        kanji: "永久",
        furigana: "えいきゅう",
        romaji: "eikyuu",
        traducao: "permanente; duradouro",
      },
      {
        kanji: "永年",
        furigana: "えいねん",
        romaji: "einen",
        traducao: "ao longo dos anos",
      },
    ],
  },
  {
    kanji: "著",
    onYomi: [{ katakana: "チョ", romaji: "cho" }],
    kunYomi: [{ hiragana: "あらわ.す", romaji: "arawa.su" }],
    significado: "notável; destacado",
    exemplos: [
      {
        kanji: "著名",
        furigana: "ちょめい",
        romaji: "chomei",
        traducao: "famoso; renomado",
      },
      {
        kanji: "著書",
        furigana: "ちょしょ",
        romaji: "chosho",
        traducao: "obra; livro publicado",
      },
      {
        kanji: "著者",
        furigana: "ちょしゃ",
        romaji: "chosha",
        traducao: "autor; escritor",
      },
    ],
  },
  {
    kanji: "誌",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "revista; publicação",
    exemplos: [
      {
        kanji: "雑誌",
        furigana: "ざっし",
        romaji: "zasshi",
        traducao: "revista",
      },
      {
        kanji: "週刊誌",
        furigana: "しゅうかんし",
        romaji: "shuukanshi",
        traducao: "revista semanal",
      },
      {
        kanji: "専門誌",
        furigana: "せんもんし",
        romaji: "senmonshi",
        traducao: "revista especializada",
      },
    ],
  },
  {
    kanji: "庫",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "くら", romaji: "kura" }],
    significado: "depósito; armazém",
    exemplos: [
      {
        kanji: "図書館",
        furigana: "としょかん",
        romaji: "toshokan",
        traducao: "biblioteca",
      },
      {
        kanji: "倉庫",
        furigana: "そうこ",
        romaji: "souko",
        traducao: "armazém; depósito",
      },
      {
        kanji: "貯蔵庫",
        furigana: "ちょぞうこ",
        romaji: "chozouko",
        traducao: "depósito; armazenamento",
      },
    ],
  },
  {
    kanji: "刊",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "publicar; impresso",
    exemplos: [
      {
        kanji: "新刊",
        furigana: "しんかん",
        romaji: "shinkan",
        traducao: "nova edição; novo lançamento",
      },
      {
        kanji: "刊行",
        furigana: "かんこう",
        romaji: "kankou",
        traducao: "publicação; lançamento",
      },
      {
        kanji: "月刊",
        furigana: "げっかん",
        romaji: "gekkan",
        traducao: "mensalmente",
      },
    ],
  },
  {
    kanji: "像",
    onYomi: [{ katakana: "ゾウ", romaji: "zou" }],
    kunYomi: [],
    significado: "estátua; imagem",
    exemplos: [
      {
        kanji: "彫像",
        furigana: "ちょうぞう",
        romaji: "chouzou",
        traducao: "escultura",
      },
      {
        kanji: "動物像",
        furigana: "どうぶつぞう",
        romaji: "doubutsuzou",
        traducao: "estátua de animal",
      },
      {
        kanji: "像",
        furigana: "ぞう",
        romaji: "zou",
        traducao: "imagem; representação",
      },
    ],
  },
  {
    kanji: "香",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "かお.り", romaji: "kao.ri" }],
    significado: "fragrância; cheiro",
    exemplos: [
      {
        kanji: "香り",
        furigana: "かおり",
        romaji: "kaori",
        traducao: "cheiro; aroma",
      },
      {
        kanji: "香水",
        furigana: "こうすい",
        romaji: "kousui",
        traducao: "perfume",
      },
      {
        kanji: "香り立つ",
        furigana: "かおりたつ",
        romaji: "kaoritatsu",
        traducao: "perfumar; exalar cheiro",
      },
    ],
  },
  {
    kanji: "坂",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "さか", romaji: "saka" }],
    significado: "ladeira; colina",
    exemplos: [
      {
        kanji: "坂道",
        furigana: "さかみち",
        romaji: "sakamichi",
        traducao: "ladeira; rua íngreme",
      },
      {
        kanji: "坂",
        furigana: "さか",
        romaji: "saka",
        traducao: "colina; declive",
      },
      {
        kanji: "上り坂",
        furigana: "のぼりざか",
        romaji: "noborizaka",
        traducao: "subida; ladeira íngreme",
      },
    ],
  },
  {
    kanji: "底",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "そこ", romaji: "soko" }],
    significado: "fundo; base",
    exemplos: [
      {
        kanji: "底",
        furigana: "そこ",
        romaji: "soko",
        traducao: "fundo; base",
      },
      {
        kanji: "底辺",
        furigana: "ていへん",
        romaji: "teihen",
        traducao: "base; lado inferior",
      },
      {
        kanji: "水底",
        furigana: "みずぞこ",
        romaji: "mizuzoko",
        traducao: "fundo do mar; leito de rio",
      },
    ],
  },
  {
    kanji: "布",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "ぬの", romaji: "nuno" }],
    significado: "tecido; pano",
    exemplos: [
      {
        kanji: "布団",
        furigana: "ふとん",
        romaji: "futon",
        traducao: "colchão; futon",
      },
      {
        kanji: "布",
        furigana: "ぬの",
        romaji: "nuno",
        traducao: "tecido; pano",
      },
      {
        kanji: "絹布",
        furigana: "けんぷ",
        romaji: "kenpu",
        traducao: "tecido de seda",
      },
    ],
  },
  {
    kanji: "寺",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [],
    significado: "templo",
    exemplos: [
      {
        kanji: "寺院",
        furigana: "じいん",
        romaji: "jiin",
        traducao: "templo",
      },
      {
        kanji: "寺社",
        furigana: "じしゃ",
        romaji: "jisha",
        traducao: "templo e santuário",
      },
      {
        kanji: "大寺",
        furigana: "だいじ",
        romaji: "daiji",
        traducao: "templo grande",
      },
    ],
  },
  {
    kanji: "宇",
    onYomi: [{ katakana: "ウ", romaji: "u" }],
    kunYomi: [],
    significado: "espaço; universo",
    exemplos: [
      {
        kanji: "宇宙",
        furigana: "うちゅう",
        romaji: "uchuu",
        traducao: "espaço; universo",
      },
      {
        kanji: "宇宙飛行士",
        furigana: "うちゅうひこうし",
        romaji: "uchuu hikoushi",
        traducao: "astronauta",
      },
      {
        kanji: "宇宙船",
        furigana: "うちゅうせん",
        romaji: "uchuu sen",
        traducao: "nave espacial",
      },
    ],
  },
  {
    kanji: "巨",
    onYomi: [{ katakana: "キョ", romaji: "kyo" }],
    kunYomi: [{ hiragana: "おお", romaji: "oo" }],
    significado: "enorme; gigante",
    exemplos: [
      {
        kanji: "巨大",
        furigana: "きょだい",
        romaji: "kyodai",
        traducao: "enorme; gigante",
      },
      {
        kanji: "巨人",
        furigana: "きょじん",
        romaji: "kyojin",
        traducao: "gigante",
      },
      {
        kanji: "巨額",
        furigana: "きょがく",
        romaji: "kyogaku",
        traducao: "quantia enorme",
      },
    ],
  },
  {
    kanji: "震",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "ふる.う", romaji: "furu.u" }],
    significado: "tremor; abalar",
    exemplos: [
      {
        kanji: "地震",
        furigana: "じしん",
        romaji: "jishin",
        traducao: "terremoto",
      },
      {
        kanji: "震度",
        furigana: "しんど",
        romaji: "shindo",
        traducao: "escala sísmica",
      },
      {
        kanji: "揺れる",
        furigana: "ゆれる",
        romaji: "yureru",
        traducao: "tremer; balançar",
      },
    ],
  },
  {
    kanji: "希",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "まれ", romaji: "mare" }],
    significado: "raro; escasso",
    exemplos: [
      {
        kanji: "希望",
        furigana: "きぼう",
        romaji: "kibou",
        traducao: "esperança; desejo",
      },
      {
        kanji: "希少",
        furigana: "きしょう",
        romaji: "kishou",
        traducao: "raro; escasso",
      },
      {
        kanji: "希な",
        furigana: "まれな",
        romaji: "marena",
        traducao: "raro",
      },
    ],
  },
  {
    kanji: "触",
    onYomi: [{ katakana: "ショク", romaji: "shoku" }],
    kunYomi: [{ hiragana: "さわ.る", romaji: "sawa.ru" }],
    significado: "toque; contato",
    exemplos: [
      {
        kanji: "触れる",
        furigana: "ふれる",
        romaji: "fureru",
        traducao: "tocar; entrar em contato",
      },
      {
        kanji: "接触",
        furigana: "せっしょく",
        romaji: "sesshoku",
        traducao: "contato; comunicação",
      },
      {
        kanji: "感触",
        furigana: "かんしょく",
        romaji: "kanshoku",
        traducao: "sensação de toque",
      },
    ],
  },
  {
    kanji: "依",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "よ.る", romaji: "yo.ru" }],
    significado: "dependência; confiar",
    exemplos: [
      {
        kanji: "依頼",
        furigana: "いらい",
        romaji: "irai",
        traducao: "pedido; solicitação",
      },
      {
        kanji: "依存",
        furigana: "いぞん",
        romaji: "izon",
        traducao: "dependência",
      },
      {
        kanji: "依然",
        furigana: "いぜん",
        romaji: "izen",
        traducao: "ainda; permanecer",
      },
    ],
  },
  {
    kanji: "籍",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [],
    significado: "registro; inscrição",
    exemplos: [
      {
        kanji: "国籍",
        furigana: "こくせき",
        romaji: "kokuseki",
        traducao: "nacionalidade",
      },
      {
        kanji: "学籍",
        furigana: "がくせき",
        romaji: "gakuseki",
        traducao: "registro acadêmico",
      },
      {
        kanji: "入籍",
        furigana: "にゅうせき",
        romaji: "nyuuseki",
        traducao: "registro de casamento",
      },
    ],
  },
  {
    kanji: "汚",
    onYomi: [{ katakana: "オ", romaji: "o" }],
    kunYomi: [{ hiragana: "けが.す", romaji: "kega.su" }],
    significado: "sujo; poluído",
    exemplos: [
      {
        kanji: "汚染",
        furigana: "おせん",
        romaji: "osen",
        traducao: "poluição; contaminação",
      },
      {
        kanji: "汚水",
        furigana: "おすい",
        romaji: "osui",
        traducao: "águas residuais",
      },
      {
        kanji: "汚れる",
        furigana: "よごれる",
        romaji: "yogoreru",
        traducao: "ficar sujo; se sujar",
      },
    ],
  },
  {
    kanji: "枚",
    onYomi: [{ katakana: "マイ", romaji: "mai" }],
    kunYomi: [],
    significado: "contador de objetos planos",
    exemplos: [
      {
        kanji: "写真",
        furigana: "しゃしん",
        romaji: "shashin",
        traducao: "fotografia",
      },
      {
        kanji: "切手",
        furigana: "きって",
        romaji: "kitte",
        traducao: "selo postal",
      },
      {
        kanji: "一枚",
        furigana: "いちまい",
        romaji: "ichimai",
        traducao: "uma folha",
      },
    ],
  },
  {
    kanji: "複",
    onYomi: [{ katakana: "フク", romaji: "fuku" }],
    kunYomi: [],
    significado: "múltiplo; complexo",
    exemplos: [
      {
        kanji: "複数",
        furigana: "ふくすう",
        romaji: "fukusuu",
        traducao: "plural; múltiplo",
      },
      {
        kanji: "複雑",
        furigana: "ふくざつ",
        romaji: "fukuzatsu",
        traducao: "complexo; complicado",
      },
      {
        kanji: "複写",
        furigana: "ふくしゃ",
        romaji: "fukusha",
        traducao: "cópia",
      },
    ],
  },
  {
    kanji: "郵",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [],
    significado: "correio",
    exemplos: [
      {
        kanji: "郵便",
        furigana: "ゆうびん",
        romaji: "yuubin",
        traducao: "correio",
      },
      {
        kanji: "郵送",
        furigana: "ゆうそう",
        romaji: "yuusou",
        traducao: "envio postal",
      },
      {
        kanji: "郵便局",
        furigana: "ゆうびんきょく",
        romaji: "yuubinkyoku",
        traducao: "agência postal",
      },
    ],
  },
  {
    kanji: "仲",
    onYomi: [{ katakana: "チュウ", romaji: "chuu" }],
    kunYomi: [{ hiragana: "なか", romaji: "naka" }],
    significado: "relacionamento; meio",
    exemplos: [
      {
        kanji: "仲間",
        furigana: "なかま",
        romaji: "nakama",
        traducao: "companheiro; colega",
      },
      {
        kanji: "仲良し",
        furigana: "なかよし",
        romaji: "nakayoshi",
        traducao: "amigo; próximo",
      },
      {
        kanji: "仲介",
        furigana: "ちゅうかい",
        romaji: "chukai",
        traducao: "mediação; intermediário",
      },
    ],
  },
  {
    kanji: "栄",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [{ hiragana: "さか.える", romaji: "saka.eru" }],
    significado: "glória; prosperidade",
    exemplos: [
      {
        kanji: "栄光",
        furigana: "えいこう",
        romaji: "eikou",
        traducao: "glória; esplendor",
      },
      {
        kanji: "繁栄",
        furigana: "はんえい",
        romaji: "han'ei",
        traducao: "prosperidade",
      },
      {
        kanji: "栄養",
        furigana: "えいよう",
        romaji: "eiyō",
        traducao: "nutrição",
      },
    ],
  },
  {
    kanji: "札",
    onYomi: [{ katakana: "サツ", romaji: "satsu" }],
    kunYomi: [{ hiragana: "ふだ", romaji: "fuda" }],
    significado: "nota; etiqueta",
    exemplos: [
      {
        kanji: "札",
        furigana: "ふだ",
        romaji: "fuda",
        traducao: "nota; etiqueta",
      },
      {
        kanji: "札束",
        furigana: "さつたば",
        romaji: "satsutaba",
        traducao: "maço de notas",
      },
      {
        kanji: "入札",
        furigana: "にゅうさつ",
        romaji: "nyuusatsu",
        traducao: "licitação",
      },
    ],
  },
  {
    kanji: "板",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "いた", romaji: "ita" }],
    significado: "tábua; prancha",
    exemplos: [
      {
        kanji: "木材",
        furigana: "もくざい",
        romaji: "mokuzai",
        traducao: "madeira; material lenhoso",
      },
      {
        kanji: "板",
        furigana: "いた",
        romaji: "ita",
        traducao: "tábua; placa",
      },
      {
        kanji: "掲示板",
        furigana: "けいじばん",
        romaji: "keijiban",
        traducao: "quadro de avisos",
      },
    ],
  },
  {
    kanji: "骨",
    onYomi: [{ katakana: "コツ", romaji: "kotsu" }],
    kunYomi: [{ hiragana: "ほね", romaji: "hone" }],
    significado: "osso",
    exemplos: [
      {
        kanji: "骨折",
        furigana: "こっせつ",
        romaji: "kosssetsu",
        traducao: "fratura; quebra de osso",
      },
      {
        kanji: "骨格",
        furigana: "こっかく",
        romaji: "kokkaku",
        traducao: "esqueleto; estrutura óssea",
      },
      {
        kanji: "骨盤",
        furigana: "こつばん",
        romaji: "kotsuban",
        traducao: "bacia; pelve",
      },
    ],
  },
  {
    kanji: "傾",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "かたむ.く", romaji: "katamu.ku" }],
    significado: "inclinado; inclinar",
    exemplos: [
      {
        kanji: "傾向",
        furigana: "けいこう",
        romaji: "keikou",
        traducao: "tendência; inclinação",
      },
      {
        kanji: "傾斜",
        furigana: "けいしゃ",
        romaji: "keisha",
        traducao: "inclinado; declive",
      },
      {
        kanji: "傾く",
        furigana: "かたむく",
        romaji: "katamuku",
        traducao: "incluir; inclinar",
      },
    ],
  },
  {
    kanji: "届",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "とど.ける", romaji: "todo.keru" }],
    significado: "entrega; notificação",
    exemplos: [
      {
        kanji: "届ける",
        furigana: "とどける",
        romaji: "todokeru",
        traducao: "entregar; notificar",
      },
      {
        kanji: "申し出",
        furigana: "もうしで",
        romaji: "moushide",
        traducao: "solicitação; proposta",
      },
      {
        kanji: "届出",
        furigana: "とどけで",
        romaji: "todokeide",
        traducao: "notificação; declaração",
      },
    ],
  },
  {
    kanji: "巻",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "ま.く", romaji: "ma.ku" }],
    significado: "rolo; enrolar",
    exemplos: [
      {
        kanji: "巻物",
        furigana: "かんぶつ",
        romaji: "kanbutsu",
        traducao: "rolo; pergaminho",
      },
      {
        kanji: "巻き毛",
        furigana: "まきげ",
        romaji: "makige",
        traducao: "cabelo enrolado",
      },
      {
        kanji: "巻く",
        furigana: "まく",
        romaji: "maku",
        traducao: "enrolar; dar voltas",
      },
    ],
  },
  {
    kanji: "燃",
    onYomi: [{ katakana: "ネン", romaji: "nen" }],
    kunYomi: [{ hiragana: "も.える", romaji: "mo.eru" }],
    significado: "queimar; combustão",
    exemplos: [
      {
        kanji: "燃料",
        furigana: "ねんりょう",
        romaji: "nenryou",
        traducao: "combustível",
      },
      {
        kanji: "発燃",
        furigana: "はつねん",
        romaji: "hatsunen",
        traducao: "ignição; inflamação",
      },
      {
        kanji: "燃える",
        furigana: "もえる",
        romaji: "moeru",
        traducao: "queimar; arder",
      },
    ],
  },
  {
    kanji: "跡",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "あと", romaji: "ato" }],
    significado: "rastro; marca",
    exemplos: [
      {
        kanji: "足跡",
        furigana: "あしあと",
        romaji: "ashiato",
        traducao: "pegadas",
      },
      {
        kanji: "歴史の跡",
        furigana: "れきしのあと",
        romaji: "rekishi no ato",
        traducao: "vestígios históricos",
      },
      {
        kanji: "消しゴムの跡",
        furigana: "けしごむのあと",
        romaji: "keshigomu no ato",
        traducao: "marca de borracha",
      },
    ],
  },
  {
    kanji: "包",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "つつ.む", romaji: "tsutsu.mu" }],
    significado: "envolver; embrulhar",
    exemplos: [
      {
        kanji: "包む",
        furigana: "つつむ",
        romaji: "tsutsumu",
        traducao: "envolver; embrulhar",
      },
      {
        kanji: "包装",
        furigana: "ほうそう",
        romaji: "housou",
        traducao: "embalagem",
      },
      {
        kanji: "包み紙",
        furigana: "つつみがみ",
        romaji: "tsutsumigami",
        traducao: "papel de embrulho",
      },
    ],
  },
  {
    kanji: "駐",
    onYomi: [{ katakana: "チュウ", romaji: "chuu" }],
    kunYomi: [],
    significado: "estar estacionado",
    exemplos: [
      {
        kanji: "駐車",
        furigana: "ちゅうしゃ",
        romaji: "chuusha",
        traducao: "estacionamento",
      },
      {
        kanji: "駐在",
        furigana: "ちゅうざい",
        romaji: "chuuzai",
        traducao: "estacionado; destacado",
      },
      {
        kanji: "駐留",
        furigana: "ちゅうりゅう",
        romaji: "chuuryuu",
        traducao: "estacionado; guarnecido",
      },
    ],
  },
  {
    kanji: "弱",
    onYomi: [{ katakana: "ジャク", romaji: "jaku" }],
    kunYomi: [{ hiragana: "よわ.い", romaji: "yowa.i" }],
    significado: "fraco; frágil",
    exemplos: [
      {
        kanji: "弱点",
        furigana: "じゃくてん",
        romaji: "jakuten",
        traducao: "ponto fraco",
      },
      {
        kanji: "弱音",
        furigana: "よわね",
        romaji: "yowane",
        traducao: "reclamação; lamento",
      },
      {
        kanji: "弱虫",
        furigana: "よわむし",
        romaji: "yowamushi",
        traducao: "covarde; fraco",
      },
    ],
  },
  {
    kanji: "紹",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "introduzir; apresentar",
    exemplos: [
      {
        kanji: "紹介",
        furigana: "しょうかい",
        romaji: "shoukai",
        traducao: "introdução; apresentação",
      },
      {
        kanji: "紹介状",
        furigana: "しょうかいじょう",
        romaji: "shoukaijou",
        traducao: "carta de apresentação",
      },
      {
        kanji: "紹介する",
        furigana: "しょうかいする",
        romaji: "shoukai suru",
        traducao: "introduzir; apresentar",
      },
    ],
  },
  {
    kanji: "雇",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "やと.う", romaji: "yato.u" }],
    significado: "contratar; empregar",
    exemplos: [
      {
        kanji: "雇用",
        furigana: "こよう",
        romaji: "koyou",
        traducao: "emprego; contratação",
      },
      {
        kanji: "雇われる",
        furigana: "やとわれる",
        romaji: "yatawareru",
        traducao: "ser contratado",
      },
      {
        kanji: "解雇",
        furigana: "かいこ",
        romaji: "kaiko",
        traducao: "demissão; dispensa",
      },
    ],
  },
  {
    kanji: "替",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "か.える", romaji: "ka.eru" }],
    significado: "trocar; substituir",
    exemplos: [
      {
        kanji: "代替",
        furigana: "だいたい",
        romaji: "daitai",
        traducao: "substituição; substituir",
      },
      {
        kanji: "交替",
        furigana: "こうたい",
        romaji: "koutai",
        traducao: "alternância; alternar",
      },
      {
        kanji: "替える",
        furigana: "かえる",
        romaji: "kaeru",
        traducao: "trocar; substituir",
      },
    ],
  },
  {
    kanji: "預",
    onYomi: [{ katakana: "ヨ", romaji: "yo" }],
    kunYomi: [{ hiragana: "あず.ける", romaji: "azu.keru" }],
    significado: "depositar; confiar",
    exemplos: [
      {
        kanji: "預金",
        furigana: "よきん",
        romaji: "yokin",
        traducao: "depósito bancário",
      },
      {
        kanji: "預ける",
        furigana: "あずける",
        romaji: "azukeru",
        traducao: "depositar; confiar",
      },
      {
        kanji: "保預",
        furigana: "ほあず",
        romaji: "hoazu",
        traducao: "guarda e depósito",
      },
    ],
  },
  {
    kanji: "焼",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "や.く", romaji: "ya.ku" }],
    significado: "assar; queimar",
    exemplos: [
      {
        kanji: "焼肉",
        furigana: "やきにく",
        romaji: "yakiniku",
        traducao: "churrasco japonês",
      },
      {
        kanji: "焼く",
        furigana: "やく",
        romaji: "yaku",
        traducao: "assar; cozinhar",
      },
      {
        kanji: "燃焼",
        furigana: "ねんしょう",
        romaji: "nenshou",
        traducao: "combustão",
      },
    ],
  },
  {
    kanji: "簡",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "simples; fácil",
    exemplos: [
      {
        kanji: "簡単",
        furigana: "かんたん",
        romaji: "kantan",
        traducao: "simples; fácil",
      },
      {
        kanji: "簡易",
        furigana: "かんい",
        romaji: "kan'i",
        traducao: "simplificado",
      },
      {
        kanji: "簡潔",
        furigana: "かんけつ",
        romaji: "kanketsu",
        traducao: "conciso; sucinto",
      },
    ],
  },
  {
    kanji: "章",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [],
    significado: "capítulo; emblema",
    exemplos: [
      {
        kanji: "章",
        furigana: "しょう",
        romaji: "shou",
        traducao: "capítulo; emblema",
      },
      {
        kanji: "章委員",
        furigana: "しょういいん",
        romaji: "shou iin",
        traducao: "membro do comitê",
      },
      {
        kanji: "章法",
        furigana: "しょうほう",
        romaji: "shouhou",
        traducao: "método; técnica",
      },
    ],
  },
  {
    kanji: "臓",
    onYomi: [{ katakana: "ゾウ", romaji: "zou" }],
    kunYomi: [],
    significado: "órgão interno",
    exemplos: [
      {
        kanji: "内臓",
        furigana: "ないぞう",
        romaji: "naizou",
        traducao: "órgão interno",
      },
      {
        kanji: "肺臓",
        furigana: "はいぞう",
        romaji: "haizou",
        traducao: "pulmão",
      },
      {
        kanji: "臓器",
        furigana: "ぞうき",
        romaji: "zouki",
        traducao: "órgão",
      },
    ],
  },
  {
    kanji: "奥",
    onYomi: [{ katakana: "オウ", romaji: "ou" }],
    kunYomi: [{ hiragana: "おく", romaji: "oku" }],
    significado: "interior; fundo",
    exemplos: [
      {
        kanji: "奥底",
        furigana: "おくそこ",
        romaji: "okusoko",
        traducao: "fundo; profundidade",
      },
      {
        kanji: "奥行き",
        furigana: "おくゆき",
        romaji: "okuyuki",
        traducao: "profundidade",
      },
      {
        kanji: "奥",
        furigana: "おく",
        romaji: "oku",
        traducao: "interior; fundo",
      },
    ],
  },
  {
    kanji: "詰",
    onYomi: [{ katakana: "キツ", romaji: "kitsu" }],
    kunYomi: [{ hiragana: "つ.める", romaji: "tsu.meru" }],
    significado: "apertado; encher",
    exemplos: [
      {
        kanji: "詰まる",
        furigana: "つまる",
        romaji: "tsumaru",
        traducao: "encher; ficar congestionado",
      },
      {
        kanji: "詰める",
        furigana: "つめる",
        romaji: "tsumeru",
        traducao: "encher; embalar",
      },
      {
        kanji: "詰め込み",
        furigana: "つめこみ",
        romaji: "tsumekomi",
        traducao: "enchimento",
      },
    ],
  },
  {
    kanji: "双",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "ふた", romaji: "futa" }],
    significado: "par; duplo",
    exemplos: [
      {
        kanji: "双子",
        furigana: "ふたご",
        romaji: "futago",
        traducao: "gêmeos",
      },
      {
        kanji: "双方",
        furigana: "そうほう",
        romaji: "souhou",
        traducao: "ambos os lados",
      },
      {
        kanji: "双眼鏡",
        furigana: "そうがんきょう",
        romaji: "sougan-kyou",
        traducao: "binóculos",
      },
    ],
  },
  {
    kanji: "刺",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "さ.す", romaji: "sa.su" }],
    significado: "espetar; perfurar",
    exemplos: [
      {
        kanji: "刺激",
        furigana: "しげき",
        romaji: "shigeki",
        traducao: "estímulo; excitação",
      },
      {
        kanji: "刺す",
        furigana: "さす",
        romaji: "sasu",
        traducao: "espetar; perfurar",
      },
      {
        kanji: "刺身",
        furigana: "さしみ",
        romaji: "sashimi",
        traducao: "sashimi (peixe cru)",
      },
    ],
  },
  {
    kanji: "純",
    onYomi: [{ katakana: "ジュン", romaji: "jun" }],
    kunYomi: [],
    significado: "puro; honesto",
    exemplos: [
      {
        kanji: "純粋",
        furigana: "じゅんすい",
        romaji: "junsui",
        traducao: "puro; genuíno",
      },
      {
        kanji: "純金",
        furigana: "じゅんきん",
        romaji: "junkin",
        traducao: "ouro puro",
      },
      {
        kanji: "純情",
        furigana: "じゅんじょう",
        romaji: "junjou",
        traducao: "romance inocente",
      },
    ],
  },
  {
    kanji: "翌",
    onYomi: [{ katakana: "ヨク", romaji: "yoku" }],
    kunYomi: [],
    significado: "próximo; seguinte",
    exemplos: [
      {
        kanji: "翌日",
        furigana: "よくじつ",
        romaji: "yokujitsu",
        traducao: "dia seguinte",
      },
      {
        kanji: "翌月",
        furigana: "よくげつ",
        romaji: "yokugatsu",
        traducao: "mês seguinte",
      },
      {
        kanji: "翌年",
        furigana: "よくねん",
        romaji: "yokunen",
        traducao: "ano seguinte",
      },
    ],
  },
  {
    kanji: "快",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "こころよ.い", romaji: "kokoroyo.i" }],
    significado: "agradável; confortável",
    exemplos: [
      {
        kanji: "快適",
        furigana: "かいてき",
        romaji: "kaiteki",
        traducao: "confortável; agradável",
      },
      {
        kanji: "快楽",
        furigana: "かいらく",
        romaji: "kairaku",
        traducao: "prazer; deleite",
      },
      {
        kanji: "快感",
        furigana: "かいかん",
        romaji: "kaikan",
        traducao: "sensação agradável",
      },
    ],
  },
  {
    kanji: "片",
    onYomi: [{ katakana: "ヘン", romaji: "hen" }],
    kunYomi: [{ hiragana: "かた", romaji: "kata" }],
    significado: "parte; pedaço",
    exemplos: [
      {
        kanji: "片付け",
        furigana: "かたづけ",
        romaji: "kataduke",
        traducao: "arrumação; organização",
      },
      {
        kanji: "片思い",
        furigana: "かたおもい",
        romaji: "kataomoi",
        traducao: "amor não correspondido",
      },
      {
        kanji: "片手",
        furigana: "かたて",
        romaji: "katate",
        traducao: "uma mão",
      },
    ],
  },
  {
    kanji: "敬",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "うやま.う", romaji: "uyama.u" }],
    significado: "respeitar; reverenciar",
    exemplos: [
      {
        kanji: "敬語",
        furigana: "けいご",
        romaji: "keigo",
        traducao: "linguagem honorífica",
      },
      {
        kanji: "敬意",
        furigana: "けいい",
        romaji: "keii",
        traducao: "respeito; deferência",
      },
      {
        kanji: "敬う",
        furigana: "うやまう",
        romaji: "uyamau",
        traducao: "respeitar; venerar",
      },
    ],
  },
  {
    kanji: "悩",
    onYomi: [{ katakana: "ノウ", romaji: "nou" }],
    kunYomi: [{ hiragana: "なや.む", romaji: "naya.mu" }],
    significado: "preocupar-se; angustiar-se",
    exemplos: [
      {
        kanji: "悩み",
        furigana: "なやみ",
        romaji: "nayami",
        traducao: "preocupação; problema",
      },
      {
        kanji: "悩む",
        furigana: "なやむ",
        romaji: "nayamu",
        traducao: "preocupar-se; angustiar-se",
      },
      {
        kanji: "悩み事",
        furigana: "なやみごと",
        romaji: "nayamigoto",
        traducao: "preocupações",
      },
    ],
  },
  {
    kanji: "泉",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "いずみ", romaji: "izumi" }],
    significado: "fonte; nascente",
    exemplos: [
      {
        kanji: "温泉",
        furigana: "おんせん",
        romaji: "onsen",
        traducao: "fonte termal; águas termais",
      },
      {
        kanji: "泉水",
        furigana: "いずみみず",
        romaji: "izumi mizu",
        traducao: "água de nascente",
      },
      {
        kanji: "泉",
        furigana: "いずみ",
        romaji: "izumi",
        traducao: "fonte; nascente",
      },
    ],
  },
  {
    kanji: "皮",
    onYomi: [{ katakana: "ヒ", romaji: "hi" }],
    kunYomi: [{ hiragana: "かわ", romaji: "kawa" }],
    significado: "pele; couro",
    exemplos: [
      {
        kanji: "皮膚",
        furigana: "ひふ",
        romaji: "hifu",
        traducao: "pele",
      },
      {
        kanji: "革皮",
        furigana: "かわかわ",
        romaji: "kawakawa",
        traducao: "couro",
      },
      {
        kanji: "皮肉",
        furigana: "ひにく",
        romaji: "hiniku",
        traducao: "ironia",
      },
    ],
  },
  {
    kanji: "漁",
    onYomi: [{ katakana: "ギョ", romaji: "gyo" }],
    kunYomi: [{ hiragana: "あさ.る", romaji: "asa.ru" }],
    significado: "pesca; pescaria",
    exemplos: [
      {
        kanji: "漁業",
        furigana: "ぎょぎょう",
        romaji: "gyogyou",
        traducao: "pesca; indústria pesqueira",
      },
      {
        kanji: "漁村",
        furigana: "ぎょそん",
        romaji: "gyoson",
        traducao: "vila de pescadores",
      },
      {
        kanji: "漁師",
        furigana: "りょうし",
        romaji: "ryoushi",
        traducao: "pescador",
      },
    ],
  },
  {
    kanji: "荒",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "あら.い", romaji: "ara.i" }],
    significado: "selvagem; áspero",
    exemplos: [
      {
        kanji: "荒野",
        furigana: "こうや",
        romaji: "kouya",
        traducao: "terreno selvagem; deserto",
      },
      {
        kanji: "荒れる",
        furigana: "あれる",
        romaji: "areru",
        traducao: "tornar-se selvagem; ficar áspero",
      },
      {
        kanji: "荒々しい",
        furigana: "あらあらしい",
        romaji: "araarashii",
        traducao: "rude; selvagem",
      },
    ],
  },
  {
    kanji: "貯",
    onYomi: [{ katakana: "チョ", romaji: "cho" }],
    kunYomi: [{ hiragana: "た.める", romaji: "ta.meru" }],
    significado: "armazenar; poupar",
    exemplos: [
      {
        kanji: "貯金",
        furigana: "ちょきん",
        romaji: "chokin",
        traducao: "poupança; economias",
      },
      {
        kanji: "貯蔵",
        furigana: "ちょぞう",
        romaji: "chozou",
        traducao: "armazenamento; reserva",
      },
      {
        kanji: "貯える",
        furigana: "たくわえる",
        romaji: "takuwaeru",
        traducao: "armazenar; poupar",
      },
    ],
  },
  {
    kanji: "硬",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "かた.い", romaji: "kata.i" }],
    significado: "duro; rígido",
    exemplos: [
      {
        kanji: "硬貨",
        furigana: "こうか",
        romaji: "kouka",
        traducao: "moeda",
      },
      {
        kanji: "硬直",
        furigana: "こうちょく",
        romaji: "kouchoku",
        traducao: "rigidez",
      },
      {
        kanji: "硬い",
        furigana: "かたい",
        romaji: "katai",
        traducao: "duro; rígido",
      },
    ],
  },
  {
    kanji: "埋",
    onYomi: [{ katakana: "マイ", romaji: "mai" }],
    kunYomi: [{ hiragana: "う.める", romaji: "u.meru" }],
    significado: "enterrar; ocultar",
    exemplos: [
      {
        kanji: "埋蔵",
        furigana: "まいぞう",
        romaji: "maizou",
        traducao: "enterrado; oculto",
      },
      {
        kanji: "埋める",
        furigana: "うめる",
        romaji: "umeru",
        traducao: "enterrar; cobrir",
      },
      {
        kanji: "埋没",
        furigana: "まいぼつ",
        romaji: "maibotsu",
        traducao: "afundamento; ocultação",
      },
    ],
  },
  {
    kanji: "柱",
    onYomi: [{ katakana: "チュウ", romaji: "chuu" }],
    kunYomi: [{ hiragana: "はしら", romaji: "hashira" }],
    significado: "coluna; pilar",
    exemplos: [
      {
        kanji: "柱状",
        furigana: "ちゅうじょう",
        romaji: "chuujo",
        traducao: "em forma de coluna",
      },
      {
        kanji: "主柱",
        furigana: "しゅちゅう",
        romaji: "shuchuu",
        traducao: "pilar principal",
      },
      {
        kanji: "柱",
        furigana: "はしら",
        romaji: "hashira",
        traducao: "coluna; pilar",
      },
    ],
  },
  {
    kanji: "祭",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [{ hiragana: "まつ.る", romaji: "matsu.ru" }],
    significado: "festival",
    exemplos: [
      {
        kanji: "祭り",
        furigana: "まつり",
        romaji: "matsuri",
        traducao: "festival",
      },
      {
        kanji: "祭典",
        furigana: "さいてん",
        romaji: "saiten",
        traducao: "celebração",
      },
      {
        kanji: "祭る",
        furigana: "まつる",
        romaji: "matsuru",
        traducao: "celebrar; adorar",
      },
    ],
  },
  {
    kanji: "袋",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "ふくろ", romaji: "fukuro" }],
    significado: "saco; bolsa",
    exemplos: [
      {
        kanji: "手袋",
        furigana: "てぶくろ",
        romaji: "tebukuro",
        traducao: "luvas",
      },
      {
        kanji: "袋",
        furigana: "ふくろ",
        romaji: "fukuro",
        traducao: "saco; bolsa",
      },
      {
        kanji: "入れ物",
        furigana: "いれもの",
        romaji: "iremono",
        traducao: "recipiente",
      },
    ],
  },
  {
    kanji: "筆",
    onYomi: [{ katakana: "ヒツ", romaji: "hitsu" }],
    kunYomi: [{ hiragana: "ふで", romaji: "fude" }],
    significado: "pincel; caneta",
    exemplos: [
      {
        kanji: "筆記",
        furigana: "ひっき",
        romaji: "hikki",
        traducao: "escrita; anotação",
      },
      {
        kanji: "筆",
        furigana: "ふで",
        romaji: "fude",
        traducao: "pincel; caneta",
      },
      {
        kanji: "筆者",
        furigana: "ひっしゃ",
        romaji: "hissha",
        traducao: "autor; escritor",
      },
    ],
  },
  {
    kanji: "訓",
    onYomi: [{ katakana: "クン", romaji: "kun" }],
    kunYomi: [],
    significado: "leitura japonesa",
    exemplos: [
      {
        kanji: "訓読み",
        furigana: "くんよみ",
        romaji: "kunyomi",
        traducao: "leitura kun'yomi",
      },
      {
        kanji: "訓練",
        furigana: "くんれん",
        romaji: "kunren",
        traducao: "treinamento",
      },
      {
        kanji: "訓点",
        furigana: "くんてん",
        romaji: "kunten",
        traducao: "ponto de leitura",
      },
    ],
  },
  {
    kanji: "浴",
    onYomi: [{ katakana: "ヨク", romaji: "yoku" }],
    kunYomi: [{ hiragana: "あ.びる", romaji: "a.biru" }],
    significado: "banhar-se",
    exemplos: [
      {
        kanji: "入浴",
        furigana: "にゅうよく",
        romaji: "nyuuyoku",
        traducao: "tomar banho",
      },
      {
        kanji: "浴室",
        furigana: "よくしつ",
        romaji: "yokushitsu",
        traducao: "banheiro",
      },
      {
        kanji: "入浴剤",
        furigana: "にゅうよくざい",
        romaji: "nyuuyokuzai",
        traducao: "produto de banho",
      },
    ],
  },
  {
    kanji: "童",
    onYomi: [{ katakana: "ドウ", romaji: "dou" }],
    kunYomi: [{ hiragana: "わらべ", romaji: "warabe" }],
    significado: "criança",
    exemplos: [
      {
        kanji: "童話",
        furigana: "どうわ",
        romaji: "douwa",
        traducao: "conto de fadas",
      },
      {
        kanji: "童心",
        furigana: "どうしん",
        romaji: "doushin",
        traducao: "inocência infantil",
      },
      {
        kanji: "子童",
        furigana: "こわらべ",
        romaji: "kowarabe",
        traducao: "criança",
      },
    ],
  },
  {
    kanji: "宝",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "たから", romaji: "takara" }],
    significado: "tesouro",
    exemplos: [
      {
        kanji: "宝物",
        furigana: "たからもの",
        romaji: "takaramono",
        traducao: "tesouro",
      },
      {
        kanji: "宝石",
        furigana: "ほうせき",
        romaji: "houseki",
        traducao: "pedra preciosa",
      },
      {
        kanji: "宝庫",
        furigana: "ほうこ",
        romaji: "houko",
        traducao: "tesouro; depósito",
      },
    ],
  },
  {
    kanji: "封",
    onYomi: [{ katakana: "フウ", romaji: "fuu" }],
    kunYomi: [{ hiragana: "ふう.じる", romaji: "fuu.jiru" }],
    significado: "selar; lacrar",
    exemplos: [
      {
        kanji: "封筒",
        furigana: "ふうとう",
        romaji: "futou",
        traducao: "envelope",
      },
      {
        kanji: "封印",
        furigana: "ふういん",
        romaji: "fuuin",
        traducao: "selo; lacrar",
      },
      {
        kanji: "密封",
        furigana: "みっぷう",
        romaji: "mippuu",
        traducao: "selar; lacrar",
      },
    ],
  },
  {
    kanji: "胸",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "むね", romaji: "mune" }],
    significado: "peito; coração",
    exemplos: [
      {
        kanji: "胸中",
        furigana: "きょうちゅう",
        romaji: "kyouchuu",
        traducao: "coração; mente",
      },
      {
        kanji: "胸",
        furigana: "むね",
        romaji: "mune",
        traducao: "peito; coração",
      },
      {
        kanji: "心胸",
        furigana: "しんきょう",
        romaji: "shinkyou",
        traducao: "mente; coração",
      },
    ],
  },
  {
    kanji: "砂",
    onYomi: [{ katakana: "サ", romaji: "sa" }],
    kunYomi: [{ hiragana: "すな", romaji: "suna" }],
    significado: "areia",
    exemplos: [
      {
        kanji: "砂漠",
        furigana: "さばく",
        romaji: "sabaku",
        traducao: "deserto",
      },
      {
        kanji: "砂糖",
        furigana: "さとう",
        romaji: "satou",
        traducao: "açúcar",
      },
      {
        kanji: "砂",
        furigana: "すな",
        romaji: "suna",
        traducao: "areia",
      },
    ],
  },
  {
    kanji: "塩",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "しお", romaji: "shio" }],
    significado: "sal",
    exemplos: [
      {
        kanji: "塩辛い",
        furigana: "しおからい",
        romaji: "shiokarai",
        traducao: "salgado",
      },
      {
        kanji: "塩",
        furigana: "しお",
        romaji: "shio",
        traducao: "sal",
      },
      {
        kanji: "岩塩",
        furigana: "いわしお",
        romaji: "iwashio",
        traducao: "sal marinho",
      },
    ],
  },
  {
    kanji: "賢",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "かしこ.い", romaji: "kashiko.i" }],
    significado: "sábio; inteligente",
    exemplos: [
      {
        kanji: "賢者",
        furigana: "けんじゃ",
        romaji: "kenja",
        traducao: "sábio",
      },
      {
        kanji: "賢い",
        furigana: "かしこい",
        romaji: "kashikoi",
        traducao: "inteligente",
      },
      {
        kanji: "賢明",
        furigana: "けんめい",
        romaji: "kenmei",
        traducao: "sensato; sábio",
      },
    ],
  },
  {
    kanji: "腕",
    onYomi: [{ katakana: "ワン", romaji: "wan" }],
    kunYomi: [{ hiragana: "うで", romaji: "ude" }],
    significado: "braço; habilidade",
    exemplos: [
      {
        kanji: "腕力",
        furigana: "わんりょく",
        romaji: "wanryoku",
        traducao: "força física",
      },
      {
        kanji: "達人",
        furigana: "たつじん",
        romaji: "tatsujin",
        traducao: "especialista; mestre",
      },
      {
        kanji: "腕",
        furigana: "うで",
        romaji: "ude",
        traducao: "braço; habilidade",
      },
    ],
  },
  {
    kanji: "兆",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "きざ.す", romaji: "kiza.su" }],
    significado: "sinal; presságio",
    exemplos: [
      {
        kanji: "兆候",
        furigana: "ちょうこう",
        romaji: "choukou",
        traducao: "sintoma; sinal",
      },
      {
        kanji: "予兆",
        furigana: "よちょう",
        romaji: "yochou",
        traducao: "presságio; prenúncio",
      },
      {
        kanji: "兆し",
        furigana: "きざし",
        romaji: "kizashi",
        traducao: "sinal; indício",
      },
    ],
  },
  {
    kanji: "床",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "とこ", romaji: "toko" }],
    significado: "chão; piso",
    exemplos: [
      {
        kanji: "床",
        furigana: "ゆか",
        romaji: "yuka",
        traducao: "chão; piso",
      },
      {
        kanji: "床屋",
        furigana: "とこや",
        romaji: "tokoya",
        traducao: "barbeiro",
      },
      {
        kanji: "寝床",
        furigana: "ねどこ",
        romaji: "nedoko",
        traducao: "cama",
      },
    ],
  },
  {
    kanji: "毛",
    onYomi: [{ katakana: "モウ", romaji: "mou" }],
    kunYomi: [{ hiragana: "け", romaji: "ke" }],
    significado: "cabelo; pelo",
    exemplos: [
      {
        kanji: "髪の毛",
        furigana: "かみのけ",
        romaji: "kaminoke",
        traducao: "cabelo",
      },
      {
        kanji: "毛皮",
        furigana: "けがわ",
        romaji: "kegawa",
        traducao: "pele; pele de animal",
      },
      {
        kanji: "毛",
        furigana: "け",
        romaji: "ke",
        traducao: "cabelo; pelo",
      },
    ],
  },
  {
    kanji: "緑",
    onYomi: [{ katakana: "リョク", romaji: "ryoku" }],
    kunYomi: [{ hiragana: "みどり", romaji: "midori" }],
    significado: "verde",
    exemplos: [
      {
        kanji: "緑色",
        furigana: "みどりいろ",
        romaji: "midori iro",
        traducao: "cor verde",
      },
      {
        kanji: "緑化",
        furigana: "りょっか",
        romaji: "ryokka",
        traducao: "verdejamento",
      },
      {
        kanji: "緑",
        furigana: "みどり",
        romaji: "midori",
        traducao: "verde",
      },
    ],
  },
  {
    kanji: "尊",
    onYomi: [{ katakana: "ソン", romaji: "son" }],
    kunYomi: [{ hiragana: "たっと.い", romaji: "tatto.i" }],
    significado: "respeito; reverência",
    exemplos: [
      {
        kanji: "尊敬",
        furigana: "そんけい",
        romaji: "sonkei",
        traducao: "respeito; reverência",
      },
      {
        kanji: "尊重",
        furigana: "そんちょう",
        romaji: "sonchou",
        traducao: "respeito; consideração",
      },
      {
        kanji: "尊い",
        furigana: "とうとい",
        romaji: "toutoi",
        traducao: "precioso; respeitável",
      },
    ],
  },
  {
    kanji: "祝",
    onYomi: [{ katakana: "シュク", romaji: "shuku" }],
    kunYomi: [{ hiragana: "いわ.う", romaji: "iwa.u" }],
    significado: "celebrar; congratular",
    exemplos: [
      {
        kanji: "祝福",
        furigana: "しゅくふく",
        romaji: "shukufuku",
        traducao: "bênção; felicitação",
      },
      {
        kanji: "祝日",
        furigana: "しゅくじつ",
        romaji: "shukujitsu",
        traducao: "feriado",
      },
      {
        kanji: "祝う",
        furigana: "いわう",
        romaji: "iwau",
        traducao: "celebrar; congratular",
      },
    ],
  },
  {
    kanji: "柔",
    onYomi: [{ katakana: "ジュウ", romaji: "juu" }],
    kunYomi: [{ hiragana: "やわ.らか", romaji: "yawa.raka" }],
    significado: "suave; flexível",
    exemplos: [
      {
        kanji: "柔軟",
        furigana: "じゅうなん",
        romaji: "juunan",
        traducao: "flexível; elástico",
      },
      {
        kanji: "柔和",
        furigana: "にゅうわ",
        romaji: "nyuuwa",
        traducao: "suave; amigável",
      },
      {
        kanji: "柔らかい",
        furigana: "やわらかい",
        romaji: "yawarakai",
        traducao: "suave; macio",
      },
    ],
  },
  {
    kanji: "殿",
    onYomi: [{ katakana: "デン", romaji: "den" }],
    kunYomi: [{ hiragana: "との", romaji: "tono" }],
    significado: "senhor; palácio",
    exemplos: [
      {
        kanji: "殿様",
        furigana: "とのさま",
        romaji: "tonosama",
        traducao: "senhor; nobre",
      },
      {
        kanji: "御殿",
        furigana: "ごてん",
        romaji: "goten",
        traducao: "palácio",
      },
      {
        kanji: "殿",
        furigana: "との",
        romaji: "tono",
        traducao: "senhor; palácio",
      },
    ],
  },
  {
    kanji: "濃",
    onYomi: [{ katakana: "ノウ", romaji: "nou" }],
    kunYomi: [{ hiragana: "こ.い", romaji: "ko.i" }],
    significado: "denso; concentrado",
    exemplos: [
      {
        kanji: "濃厚",
        furigana: "のうこう",
        romaji: "noukou",
        traducao: "rico; denso",
      },
      {
        kanji: "濃度",
        furigana: "のうど",
        romaji: "noudo",
        traducao: "concentração",
      },
      {
        kanji: "濃い",
        furigana: "こい",
        romaji: "koi",
        traducao: "denso; concentrado",
      },
    ],
  },
  {
    kanji: "液",
    onYomi: [{ katakana: "エキ", romaji: "eki" }],
    kunYomi: [],
    significado: "líquido",
    exemplos: [
      {
        kanji: "液体",
        furigana: "えきたい",
        romaji: "ekitai",
        traducao: "líquido",
      },
      {
        kanji: "液状",
        furigana: "えきじょう",
        romaji: "ekijou",
        traducao: "em estado líquido",
      },
      {
        kanji: "液",
        furigana: "えき",
        romaji: "eki",
        traducao: "líquido",
      },
    ],
  },
  {
    kanji: "衣",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "ころも", romaji: "koromo" }],
    significado: "roupa",
    exemplos: [
      {
        kanji: "衣類",
        furigana: "いるい",
        romaji: "irui",
        traducao: "roupas",
      },
      {
        kanji: "和服",
        furigana: "わふく",
        romaji: "wafuku",
        traducao: "roupa tradicional japonesa",
      },
      {
        kanji: "衣",
        furigana: "ころも",
        romaji: "koromo",
        traducao: "roupa",
      },
    ],
  },
  {
    kanji: "肩",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "かた", romaji: "kata" }],
    significado: "ombro",
    exemplos: [
      {
        kanji: "肩書き",
        furigana: "かたがき",
        romaji: "katagaki",
        traducao: "título",
      },
      {
        kanji: "肩",
        furigana: "かた",
        romaji: "kata",
        traducao: "ombro",
      },
      {
        kanji: "肩こり",
        furigana: "かたこり",
        romaji: "katakori",
        traducao: "rigidez nos ombros",
      },
    ],
  },
  {
    kanji: "零",
    onYomi: [{ katakana: "レイ", romaji: "rei" }],
    kunYomi: [{ hiragana: "ぜろ", romaji: "zero" }],
    significado: "zero",
    exemplos: [
      {
        kanji: "零下",
        furigana: "れいか",
        romaji: "reika",
        traducao: "temperatura abaixo de zero",
      },
      {
        kanji: "零点",
        furigana: "れいてん",
        romaji: "reiten",
        traducao: "zero; ponto zero",
      },
      {
        kanji: "零",
        furigana: "ぜろ",
        romaji: "zero",
        traducao: "zero",
      },
    ],
  },
  {
    kanji: "幼",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "おさな.い", romaji: "osana.i" }],
    significado: "jovem; criança",
    exemplos: [
      {
        kanji: "幼稚",
        furigana: "ようち",
        romaji: "youchi",
        traducao: "infantil",
      },
      {
        kanji: "幼児",
        furigana: "ようじ",
        romaji: "youji",
        traducao: "criança; criança pequena",
      },
      {
        kanji: "幼い",
        furigana: "おさない",
        romaji: "osanai",
        traducao: "jovem; criança",
      },
    ],
  },
  {
    kanji: "荷",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "に", romaji: "ni" }],
    significado: "carga; bagagem",
    exemplos: [
      {
        kanji: "荷物",
        furigana: "にもつ",
        romaji: "nimotsu",
        traducao: "bagagem",
      },
      {
        kanji: "負荷",
        furigana: "ふか",
        romaji: "fuka",
        traducao: "carga",
      },
      {
        kanji: "荷",
        furigana: "に",
        romaji: "ni",
        traducao: "carga; bagagem",
      },
    ],
  },
  {
    kanji: "泊",
    onYomi: [{ katakana: "ハク", romaji: "haku" }],
    kunYomi: [{ hiragana: "と.まる", romaji: "to.maru" }],
    significado: "pernoitar; estadia",
    exemplos: [
      {
        kanji: "宿泊",
        furigana: "しゅくはく",
        romaji: "shukuhaku",
        traducao: "hospedagem; pernoite",
      },
      {
        kanji: "泊まる",
        furigana: "とまる",
        romaji: "tomaru",
        traducao: "pernoitar; ficar hospedado",
      },
      {
        kanji: "一泊",
        furigana: "いっぱく",
        romaji: "ippaku",
        traducao: "uma noite; estadia de uma noite",
      },
    ],
  },
  {
    kanji: "黄",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "き", romaji: "ki" }],
    significado: "amarelo",
    exemplos: [
      {
        kanji: "黄色",
        furigana: "きいろ",
        romaji: "kiiro",
        traducao: "cor amarela",
      },
      {
        kanji: "黄金",
        furigana: "おうごん",
        romaji: "ougon",
        traducao: "ouro",
      },
      {
        kanji: "黄",
        furigana: "き",
        romaji: "ki",
        traducao: "amarelo",
      },
    ],
  },
  {
    kanji: "甘",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "あま.い", romaji: "ama.i" }],
    significado: "doce",
    exemplos: [
      {
        kanji: "甘い",
        furigana: "あまい",
        romaji: "amai",
        traducao: "doce",
      },
      {
        kanji: "甘党",
        furigana: "あまとう",
        romaji: "amatou",
        traducao: "amante de doces",
      },
      {
        kanji: "甘味",
        furigana: "あまみ",
        romaji: "amami",
        traducao: "doçura",
      },
    ],
  },
  {
    kanji: "臣",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [],
    significado: "servo",
    exemplos: [
      {
        kanji: "臣下",
        furigana: "しんか",
        romaji: "shinka",
        traducao: "servo; vassalo",
      },
      {
        kanji: "忠臣",
        furigana: "ちゅうしん",
        romaji: "chuushin",
        traducao: "servo leal",
      },
      {
        kanji: "公臣",
        furigana: "くぶん",
        romaji: "kubun",
        traducao: "funcionário público",
      },
    ],
  },
  {
    kanji: "浅",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "あさ.い", romaji: "asa.i" }],
    significado: "raso; superficial",
    exemplos: [
      {
        kanji: "浅い",
        furigana: "あさい",
        romaji: "asai",
        traducao: "raso; superficial",
      },
      {
        kanji: "浅学",
        furigana: "せんがく",
        romaji: "sengaku",
        traducao: "conhecimento superficial",
      },
      {
        kanji: "浅瀬",
        furigana: "あさせ",
        romaji: "asase",
        traducao: "águas rasas",
      },
    ],
  },
  {
    kanji: "掃",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "は.く", romaji: "ha.ku" }],
    significado: "varrer; limpar",
    exemplos: [
      {
        kanji: "掃除",
        furigana: "そうじ",
        romaji: "souji",
        traducao: "limpeza",
      },
      {
        kanji: "掃く",
        furigana: "はく",
        romaji: "haku",
        traducao: "varrer",
      },
      {
        kanji: "掃討",
        furigana: "そうとう",
        romaji: "soutou",
        traducao: "limpeza; varredura",
      },
    ],
  },
  {
    kanji: "雲",
    onYomi: [{ katakana: "ウン", romaji: "un" }],
    kunYomi: [{ hiragana: "くも", romaji: "kumo" }],
    significado: "nuvem",
    exemplos: [
      {
        kanji: "雲",
        furigana: "くも",
        romaji: "kumo",
        traducao: "nuvem",
      },
      {
        kanji: "雲行き",
        furigana: "くもゆき",
        romaji: "kumoyuki",
        traducao: "aparência; desenvolvimento",
      },
      {
        kanji: "雲海",
        furigana: "うんかい",
        romaji: "unkai",
        traducao: "mar de nuvens",
      },
    ],
  },
  {
    kanji: "掘",
    onYomi: [{ katakana: "クツ", romaji: "kutsu" }],
    kunYomi: [{ hiragana: "ほ.る", romaji: "ho.ru" }],
    significado: "escavar; cavar",
    exemplos: [
      {
        kanji: "掘削",
        furigana: "くっさく",
        romaji: "kussaku",
        traducao: "escavação",
      },
      {
        kanji: "掘る",
        furigana: "ほる",
        romaji: "horu",
        traducao: "cavar; escavar",
      },
      {
        kanji: "掘り出し物",
        furigana: "ほりだしもの",
        romaji: "horidashimono",
        traducao: "achado; pechincha",
      },
    ],
  },
  {
    kanji: "捨",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "す.てる", romaji: "su.teru" }],
    significado: "jogar fora; descartar",
    exemplos: [
      {
        kanji: "捨てる",
        furigana: "すてる",
        romaji: "suteru",
        traducao: "jogar fora; descartar",
      },
      {
        kanji: "捨て身",
        furigana: "すてみ",
        romaji: "su.temi",
        traducao: "desprezo pelo perigo",
      },
      {
        kanji: "放り捨てる",
        furigana: "ほうりすてる",
        romaji: "hourisuteru",
        traducao: "jogar fora; abandonar",
      },
    ],
  },
  {
    kanji: "軟",
    onYomi: [{ katakana: "ナン", romaji: "nan" }],
    kunYomi: [{ hiragana: "やわ.らか", romaji: "yawa.raka" }],
    significado: "macio; suave",
    exemplos: [
      {
        kanji: "軟弱",
        furigana: "なんじゃく",
        romaji: "nanjaku",
        traducao: "fraco; sem força",
      },
      {
        kanji: "軟化",
        furigana: "なんか",
        romaji: "nanka",
        traducao: "suavização; abrandamento",
      },
      {
        kanji: "軟",
        furigana: "やわらか",
        romaji: "yawaraka",
        traducao: "macio; suave",
      },
    ],
  },
  {
    kanji: "沈",
    onYomi: [{ katakana: "チン", romaji: "chin" }],
    kunYomi: [{ hiragana: "しず.む", romaji: "shizu.mu" }],
    significado: "afundar; submergir",
    exemplos: [
      {
        kanji: "沈没",
        furigana: "ちんぼつ",
        romaji: "chinbotsu",
        traducao: "afundamento; naufrágio",
      },
      {
        kanji: "沈黙",
        furigana: "ちんもく",
        romaji: "chinmoku",
        traducao: "silêncio; quietude",
      },
      {
        kanji: "沈む",
        furigana: "しずむ",
        romaji: "shizumu",
        traducao: "afundar; submergir",
      },
    ],
  },
  {
    kanji: "凍",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "こお.る", romaji: "koo.ru" }],
    significado: "congelar",
    exemplos: [
      {
        kanji: "凍結",
        furigana: "とうけつ",
        romaji: "touketsu",
        traducao: "congelamento",
      },
      {
        kanji: "凍る",
        furigana: "こおる",
        romaji: "kooru",
        traducao: "congelar",
      },
      {
        kanji: "氷結",
        furigana: "ひょうけつ",
        romaji: "hyouketsu",
        traducao: "congelamento; gelo",
      },
    ],
  },
  {
    kanji: "乳",
    onYomi: [{ katakana: "ニュウ", romaji: "nyuu" }],
    kunYomi: [{ hiragana: "ちち", romaji: "chichi" }],
    significado: "leite",
    exemplos: [
      {
        kanji: "牛乳",
        furigana: "ぎゅうにゅう",
        romaji: "gyuunyuu",
        traducao: "leite de vaca",
      },
      {
        kanji: "母乳",
        furigana: "ぼにゅう",
        romaji: "bonyuu",
        traducao: "leite materno",
      },
      {
        kanji: "乳",
        furigana: "ちち",
        romaji: "chichi",
        traducao: "leite",
      },
    ],
  },
  {
    kanji: "恋",
    onYomi: [{ katakana: "レン", romaji: "ren" }],
    kunYomi: [{ hiragana: "こい", romaji: "koi" }],
    significado: "amor; romance",
    exemplos: [
      {
        kanji: "恋愛",
        furigana: "れんあい",
        romaji: "ren'ai",
        traducao: "amor; romance",
      },
      {
        kanji: "片思い",
        furigana: "かたおもい",
        romaji: "kataomoi",
        traducao: "amor não correspondido",
      },
      {
        kanji: "恋",
        furigana: "こい",
        romaji: "koi",
        traducao: "amor; romance",
      },
    ],
  },
  {
    kanji: "紅",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "べに", romaji: "beni" }],
    significado: "vermelho",
    exemplos: [
      {
        kanji: "紅茶",
        furigana: "こうちゃ",
        romaji: "koucha",
        traducao: "chá preto",
      },
      {
        kanji: "紅葉",
        furigana: "こうよう",
        romaji: "kouyou",
        traducao: "folhagem vermelha",
      },
      {
        kanji: "紅",
        furigana: "べに",
        romaji: "beni",
        traducao: "vermelho",
      },
    ],
  },
  {
    kanji: "郊",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "subúrbio",
    exemplos: [
      {
        kanji: "郊外",
        furigana: "こうがい",
        romaji: "kougai",
        traducao: "subúrbio",
      },
      {
        kanji: "郊遊",
        furigana: "こうゆう",
        romaji: "kouyuu",
        traducao: "passeio no subúrbio",
      },
      {
        kanji: "郊",
        furigana: "こう",
        romaji: "kou",
        traducao: "subúrbio",
      },
    ],
  },
  {
    kanji: "腰",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "こし", romaji: "koshi" }],
    significado: "cintura; quadril",
    exemplos: [
      {
        kanji: "腰痛",
        furigana: "ようつう",
        romaji: "youtsuu",
        traducao: "dor na cintura",
      },
      {
        kanji: "腰掛け",
        furigana: "こしかけ",
        romaji: "koshikake",
        traducao: "assento",
      },
      {
        kanji: "腰",
        furigana: "こし",
        romaji: "koshi",
        traducao: "cintura; quadril",
      },
    ],
  },
  {
    kanji: "炭",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "すみ", romaji: "sumi" }],
    significado: "carvão",
    exemplos: [
      {
        kanji: "炭酸",
        furigana: "たんさん",
        romaji: "tansan",
        traducao: "gás carbônico",
      },
      {
        kanji: "炭火",
        furigana: "すみび",
        romaji: "sumibi",
        traducao: "carvão; churrasco",
      },
      {
        kanji: "炭",
        furigana: "すみ",
        romaji: "sumi",
        traducao: "carvão",
      },
    ],
  },
  {
    kanji: "踊",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "おど.る", romaji: "odo.ru" }],
    significado: "dançar",
    exemplos: [
      {
        kanji: "踊り",
        furigana: "おどり",
        romaji: "odori",
        traducao: "dança",
      },
      {
        kanji: "踊る",
        furigana: "おどる",
        romaji: "odoru",
        traducao: "dançar",
      },
      {
        kanji: "踊り場",
        furigana: "おどりば",
        romaji: "odoriba",
        traducao: "pista de dança",
      },
    ],
  },
  {
    kanji: "冊",
    onYomi: [{ katakana: "サツ", romaji: "satsu" }],
    kunYomi: [],
    significado: "livro (contador)",
    exemplos: [
      {
        kanji: "本冊",
        furigana: "ほんさつ",
        romaji: "honsatsu",
        traducao: "volume de livro",
      },
      {
        kanji: "冊子",
        furigana: "さっし",
        romaji: "sasshi",
        traducao: "folheto; brochura",
      },
      {
        kanji: "一冊",
        furigana: "いっさつ",
        romaji: "issatsu",
        traducao: "um volume",
      },
    ],
  },
  {
    kanji: "勇",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [{ hiragana: "いさ.む", romaji: "isa.mu" }],
    significado: "coragem; bravura",
    exemplos: [
      {
        kanji: "勇気",
        furigana: "ゆうき",
        romaji: "yuuki",
        traducao: "coragem",
      },
      {
        kanji: "勇ましい",
        furigana: "いさましい",
        romaji: "isamashii",
        traducao: "bravo; corajoso",
      },
      {
        kanji: "勇",
        furigana: "ゆう",
        romaji: "yuu",
        traducao: "coragem; bravura",
      },
    ],
  },
  {
    kanji: "械",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [],
    significado: "máquina; aparelho",
    exemplos: [
      {
        kanji: "機械",
        furigana: "きかい",
        romaji: "kikai",
        traducao: "máquina",
      },
      {
        kanji: "装置",
        furigana: "そうち",
        romaji: "souchi",
        traducao: "dispositivo; aparelho",
      },
      {
        kanji: "械",
        furigana: "かい",
        romaji: "kai",
        traducao: "máquina; aparelho",
      },
    ],
  },
  {
    kanji: "菜",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [],
    significado: "vegetal; verdura",
    exemplos: [
      {
        kanji: "野菜",
        furigana: "やさい",
        romaji: "yasai",
        traducao: "vegetal; verdura",
      },
      {
        kanji: "菜食",
        furigana: "さいしょく",
        romaji: "saishoku",
        traducao: "dieta vegetariana",
      },
      {
        kanji: "菜",
        furigana: "な",
        romaji: "na",
        traducao: "vegetal; verdura",
      },
    ],
  },
  {
    kanji: "珍",
    onYomi: [{ katakana: "チン", romaji: "chin" }],
    kunYomi: [{ hiragana: "めずら.しい", romaji: "mezura.shii" }],
    significado: "raro; incomum",
    exemplos: [
      {
        kanji: "珍品",
        furigana: "ちんぴん",
        romaji: "chinpin",
        traducao: "artigo raro",
      },
      {
        kanji: "珍味",
        furigana: "ちんみ",
        romaji: "chinmi",
        traducao: "iguaria; delícia",
      },
      {
        kanji: "珍",
        furigana: "めずら",
        romaji: "mezura",
        traducao: "raro; incomum",
      },
    ],
  },
  {
    kanji: "卵",
    onYomi: [{ katakana: "ラン", romaji: "ran" }],
    kunYomi: [{ hiragana: "たまご", romaji: "tamago" }],
    significado: "ovo",
    exemplos: [
      {
        kanji: "卵料理",
        furigana: "らんりょうり",
        romaji: "ranryouri",
        traducao: "prato com ovos",
      },
      {
        kanji: "卵",
        furigana: "たまご",
        romaji: "tamago",
        traducao: "ovo",
      },
      {
        kanji: "卵黄",
        furigana: "らんおう",
        romaji: "ran'ou",
        traducao: "gema do ovo",
      },
    ],
  },
  {
    kanji: "湖",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "みずうみ", romaji: "mizuumi" }],
    significado: "lago",
    exemplos: [
      {
        kanji: "湖畔",
        furigana: "こはん",
        romaji: "kohan",
        traducao: "margem do lago",
      },
      {
        kanji: "湖",
        furigana: "みずうみ",
        romaji: "mizuumi",
        traducao: "lago",
      },
      {
        kanji: "湖水",
        furigana: "こすい",
        romaji: "kosui",
        traducao: "água do lago",
      },
    ],
  },
  {
    kanji: "喫",
    onYomi: [{ katakana: "キツ", romaji: "kitsu" }],
    kunYomi: [],
    significado: "consumir; ingerir",
    exemplos: [
      {
        kanji: "喫茶店",
        furigana: "きっさてん",
        romaji: "kissaten",
        traducao: "cafeteria",
      },
      {
        kanji: "喫煙",
        furigana: "きつえん",
        romaji: "kitsuen",
        traducao: "fumar",
      },
      {
        kanji: "喫",
        furigana: "く.う",
        romaji: "ku.u",
        traducao: "consumir; ingerir",
      },
    ],
  },
  {
    kanji: "干",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [
      { hiragana: "ほ.す", romaji: "ho.su" },
      { hiragana: "ひ.る", romaji: "hi.ru" },
    ],
    significado: "secar; pendurar",
    exemplos: [
      {
        kanji: "干渉",
        furigana: "かんしょう",
        romaji: "kanshou",
        traducao: "interferência",
      },
      {
        kanji: "干ばつ",
        furigana: "かんばつ",
        romaji: "kanbatsu",
        traducao: "seca",
      },
      {
        kanji: "干",
        furigana: "ほす",
        romaji: "hosu",
        traducao: "secar; pendurar",
      },
    ],
  },
  {
    kanji: "虫",
    onYomi: [{ katakana: "チュウ", romaji: "chuu" }],
    kunYomi: [{ hiragana: "むし", romaji: "mushi" }],
    significado: "inseto",
    exemplos: [
      {
        kanji: "昆虫",
        furigana: "こんちゅう",
        romaji: "konchuu",
        traducao: "inseto",
      },
      {
        kanji: "虫歯",
        furigana: "むしば",
        romaji: "mushiba",
        traducao: "cárie",
      },
      {
        kanji: "虫",
        furigana: "むし",
        romaji: "mushi",
        traducao: "inseto",
      },
    ],
  },
  {
    kanji: "刷",
    onYomi: [{ katakana: "サツ", romaji: "satsu" }],
    kunYomi: [{ hiragana: "す.る", romaji: "su.ru" }],
    significado: "imprimir",
    exemplos: [
      {
        kanji: "印刷",
        furigana: "いんさつ",
        romaji: "insatsu",
        traducao: "impressão",
      },
      {
        kanji: "刷る",
        furigana: "する",
        romaji: "suru",
        traducao: "imprimir",
      },
      {
        kanji: "刷",
        furigana: "す",
        romaji: "su",
        traducao: "imprimir",
      },
    ],
  },
  {
    kanji: "湯",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "ゆ", romaji: "yu" }],
    significado: "água quente",
    exemplos: [
      {
        kanji: "お湯",
        furigana: "おゆ",
        romaji: "oyu",
        traducao: "água quente",
      },
      {
        kanji: "温泉",
        furigana: "おんせん",
        romaji: "onsen",
        traducao: "fonte termal",
      },
      {
        kanji: "湯",
        furigana: "ゆ",
        romaji: "yu",
        traducao: "água quente",
      },
    ],
  },
  {
    kanji: "溶",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [{ hiragana: "と.ける", romaji: "to.keru" }],
    significado: "derreter; dissolver",
    exemplos: [
      {
        kanji: "溶岩",
        furigana: "ようがん",
        romaji: "yougan",
        traducao: "lava",
      },
      {
        kanji: "溶解",
        furigana: "ようかい",
        romaji: "youkai",
        traducao: "dissolução",
      },
      {
        kanji: "溶ける",
        furigana: "とける",
        romaji: "tokeru",
        traducao: "derreter; dissolver",
      },
    ],
  },
  {
    kanji: "鉱",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "minério",
    exemplos: [
      {
        kanji: "鉱物",
        furigana: "こうぶつ",
        romaji: "koubutsu",
        traducao: "mineral",
      },
      {
        kanji: "鉱業",
        furigana: "こうぎょう",
        romaji: "kougyou",
        traducao: "indústria mineral",
      },
      {
        kanji: "鉱",
        furigana: "こう",
        romaji: "kou",
        traducao: "minério",
      },
    ],
  },
  {
    kanji: "涙",
    onYomi: [{ katakana: "ルイ", romaji: "rui" }],
    kunYomi: [{ hiragana: "なみだ", romaji: "namida" }],
    significado: "lágrima",
    exemplos: [
      {
        kanji: "涙",
        furigana: "なみだ",
        romaji: "namida",
        traducao: "lágrima",
      },
      {
        kanji: "涙声",
        furigana: "なみだごえ",
        romaji: "namidagoe",
        traducao: "voz chorosa",
      },
      {
        kanji: "涙流",
        furigana: "るいりゅう",
        romaji: "ruiuryuu",
        traducao: "chorar em lágrimas",
      },
    ],
  },
  {
    kanji: "匹",
    onYomi: [{ katakana: "ヒツ", romaji: "hitsu" }],
    kunYomi: [],
    significado: "contagem de animais pequenos",
    exemplos: [
      {
        kanji: "猫匹",
        furigana: "ねこひつ",
        romaji: "nekohitsu",
        traducao: "gato",
      },
      {
        kanji: "一匹",
        furigana: "いっぴき",
        romaji: "ippiki",
        traducao: "um (animal pequeno)",
      },
      {
        kanji: "匹",
        furigana: "ひき",
        romaji: "hiki",
        traducao: "contagem de animais pequenos",
      },
    ],
  },
  {
    kanji: "孫",
    onYomi: [{ katakana: "ソン", romaji: "son" }],
    kunYomi: [{ hiragana: "まご", romaji: "mago" }],
    significado: "neto(a)",
    exemplos: [
      {
        kanji: "孫",
        furigana: "まご",
        romaji: "mago",
        traducao: "neto(a)",
      },
      {
        kanji: "曾孫",
        furigana: "ひまご",
        romaji: "himago",
        traducao: "bisneto(a)",
      },
      {
        kanji: "孫娘",
        furigana: "まごむすめ",
        romaji: "magomusume",
        traducao: "neta",
      },
    ],
  },
  {
    kanji: "鋭",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [{ hiragana: "するど.い", romaji: "surudo.i" }],
    significado: "afiado; afiado",
    exemplos: [
      {
        kanji: "鋭利",
        furigana: "えいり",
        romaji: "eiri",
        traducao: "afiado",
      },
      {
        kanji: "鋭い",
        furigana: "するどい",
        romaji: "surudoi",
        traducao: "afiado",
      },
      {
        kanji: "鋭",
        furigana: "えい",
        romaji: "ei",
        traducao: "afiado",
      },
    ],
  },
  {
    kanji: "枝",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "えだ", romaji: "eda" }],
    significado: "galho; ramo",
    exemplos: [
      {
        kanji: "枝",
        furigana: "えだ",
        romaji: "eda",
        traducao: "galho; ramo",
      },
      {
        kanji: "木の枝",
        furigana: "きのえだ",
        romaji: "kinoda",
        traducao: "galho de árvore",
      },
      {
        kanji: "枝分かれ",
        furigana: "えだわかれ",
        romaji: "edawakare",
        traducao: "bifurcação",
      },
    ],
  },
  {
    kanji: "塗",
    onYomi: [{ katakana: "ト", romaji: "to" }],
    kunYomi: [{ hiragana: "ぬ.る", romaji: "nu.ru" }],
    significado: "pintar; envernizar",
    exemplos: [
      {
        kanji: "塗る",
        furigana: "ぬる",
        romaji: "nuru",
        traducao: "pintar; envernizar",
      },
      {
        kanji: "塗料",
        furigana: "とりょう",
        romaji: "toryou",
        traducao: "tinta; verniz",
      },
      {
        kanji: "塗",
        furigana: "ぬ",
        romaji: "nu",
        traducao: "pintar; envernizar",
      },
    ],
  },
  {
    kanji: "軒",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [],
    significado: "unidade de casas; contador de casas",
    exemplos: [
      {
        kanji: "一軒",
        furigana: "いっけん",
        romaji: "ikken",
        traducao: "uma casa",
      },
      {
        kanji: "軒数",
        furigana: "けんすう",
        romaji: "kensuu",
        traducao: "número de casas",
      },
      {
        kanji: "軒",
        furigana: "けん",
        romaji: "ken",
        traducao: "unidade de casas; contador de casas",
      },
    ],
  },
  {
    kanji: "毒",
    onYomi: [{ katakana: "ドク", romaji: "doku" }],
    kunYomi: [],
    significado: "veneno",
    exemplos: [
      {
        kanji: "毒",
        furigana: "どく",
        romaji: "doku",
        traducao: "veneno",
      },
      {
        kanji: "中毒",
        furigana: "ちゅうどく",
        romaji: "chuudoku",
        traducao: "envenenamento; intoxicação",
      },
      {
        kanji: "毒物",
        furigana: "どくぶつ",
        romaji: "dokubutsu",
        traducao: "substância venenosa",
      },
    ],
  },
  {
    kanji: "叫",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "さけ.ぶ", romaji: "sake.bu" }],
    significado: "gritar",
    exemplos: [
      {
        kanji: "叫ぶ",
        furigana: "さけぶ",
        romaji: "sakebu",
        traducao: "gritar",
      },
      {
        kanji: "叫声",
        furigana: "きょうせい",
        romaji: "kyousei",
        traducao: "grito",
      },
      {
        kanji: "叫",
        furigana: "さけ",
        romaji: "sake",
        traducao: "gritar",
      },
    ],
  },
  {
    kanji: "拝",
    onYomi: [{ katakana: "ハイ", romaji: "hai" }],
    kunYomi: [{ hiragana: "おが.む", romaji: "oga.mu" }],
    significado: "adorar; reverenciar",
    exemplos: [
      {
        kanji: "拝見",
        furigana: "はいけん",
        romaji: "haiken",
        traducao: "ver; observar",
      },
      {
        kanji: "拝啓",
        furigana: "はいけい",
        romaji: "haikei",
        traducao: "caro (em uma carta)",
      },
      {
        kanji: "拝",
        furigana: "おが",
        romaji: "oga",
        traducao: "adorar; reverenciar",
      },
    ],
  },
  {
    kanji: "氷",
    onYomi: [{ katakana: "ヒョウ", romaji: "hyou" }],
    kunYomi: [{ hiragana: "こおり", romaji: "koori" }],
    significado: "gelo",
    exemplos: [
      {
        kanji: "氷",
        furigana: "こおり",
        romaji: "koori",
        traducao: "gelo",
      },
      {
        kanji: "氷河",
        furigana: "ひょうが",
        romaji: "hyouga",
        traducao: "glaciar",
      },
      {
        kanji: "氷点下",
        furigana: "ひょうてんか",
        romaji: "hyoutenka",
        traducao: "abaixo de zero",
      },
    ],
  },
  {
    kanji: "乾",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "かわ.く", romaji: "kawa.ku" }],
    significado: "seco",
    exemplos: [
      {
        kanji: "乾燥",
        furigana: "かんそう",
        romaji: "kansou",
        traducao: "seco",
      },
      {
        kanji: "乾く",
        furigana: "かわく",
        romaji: "kawaku",
        traducao: "secar",
      },
      {
        kanji: "乾",
        furigana: "かわ",
        romaji: "kawa",
        traducao: "seco",
      },
    ],
  },
  {
    kanji: "棒",
    onYomi: [{ katakana: "ボウ", romaji: "bou" }],
    kunYomi: [],
    significado: "bastão",
    exemplos: [
      {
        kanji: "棒",
        furigana: "ぼう",
        romaji: "bou",
        traducao: "bastão",
      },
      {
        kanji: "鉄棒",
        furigana: "てつぼう",
        romaji: "tetsubou",
        traducao: "barra fixa",
      },
      {
        kanji: "棒状",
        furigana: "ぼうじょう",
        romaji: "boujou",
        traducao: "em forma de bastão",
      },
    ],
  },
  {
    kanji: "祈",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "いの.る", romaji: "ino.ru" }],
    significado: "rezar",
    exemplos: [
      {
        kanji: "祈る",
        furigana: "いのる",
        romaji: "inoru",
        traducao: "rezar",
      },
      {
        kanji: "祈願",
        furigana: "きがん",
        romaji: "kigan",
        traducao: "oração; súplica",
      },
      {
        kanji: "祈",
        furigana: "いの",
        romaji: "ino",
        traducao: "rezar",
      },
    ],
  },
  {
    kanji: "拾",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [{ hiragana: "ひろ.う", romaji: "hiro.u" }],
    significado: "pegar; recolher",
    exemplos: [
      {
        kanji: "拾う",
        furigana: "ひろう",
        romaji: "hirou",
        traducao: "pegar; recolher",
      },
      {
        kanji: "拾得",
        furigana: "しゅうとく",
        romaji: "shuutoku",
        traducao: "achado",
      },
      {
        kanji: "拾",
        furigana: "ひろ",
        romaji: "hiro",
        traducao: "pegar; recolher",
      },
    ],
  },
  {
    kanji: "粉",
    onYomi: [{ katakana: "フン", romaji: "fun" }],
    kunYomi: [{ hiragana: "こな", romaji: "kona" }],
    significado: "farinha; pó",
    exemplos: [
      {
        kanji: "粉",
        furigana: "こな",
        romaji: "kona",
        traducao: "farinha; pó",
      },
      {
        kanji: "小麦粉",
        furigana: "こむぎこ",
        romaji: "komugiko",
        traducao: "farinha de trigo",
      },
      {
        kanji: "粉末",
        furigana: "ふんまつ",
        romaji: "funmatsu",
        traducao: "pó",
      },
    ],
  },
  {
    kanji: "糸",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "いと", romaji: "ito" }],
    significado: "linha",
    exemplos: [
      {
        kanji: "糸",
        furigana: "いと",
        romaji: "ito",
        traducao: "linha",
      },
      {
        kanji: "糸電話",
        furigana: "いとでんわ",
        romaji: "itodenwa",
        traducao: "telefone com fio",
      },
      {
        kanji: "糸口",
        furigana: "いとぐち",
        romaji: "itoguchi",
        traducao: "ponta do fio",
      },
    ],
  },
  {
    kanji: "綿",
    onYomi: [{ katakana: "メン", romaji: "men" }],
    kunYomi: [{ hiragana: "わた", romaji: "wata" }],
    significado: "algodão",
    exemplos: [
      {
        kanji: "綿",
        furigana: "わた",
        romaji: "wata",
        traducao: "algodão",
      },
      {
        kanji: "綿花",
        furigana: "めんか",
        romaji: "menka",
        traducao: "flor de algodão",
      },
      {
        kanji: "綿布",
        furigana: "めんぷ",
        romaji: "menpu",
        traducao: "tecido de algodão",
      },
    ],
  },
  {
    kanji: "汗",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "あせ", romaji: "ase" }],
    significado: "suor",
    exemplos: [
      {
        kanji: "汗",
        furigana: "あせ",
        romaji: "ase",
        traducao: "suor",
      },
      {
        kanji: "発汗",
        furigana: "はっかん",
        romaji: "hakkan",
        traducao: "transpiração",
      },
      {
        kanji: "汗をかく",
        furigana: "あせをかく",
        romaji: "ase o kaku",
        traducao: "suar",
      },
    ],
  },
  {
    kanji: "銅",
    onYomi: [{ katakana: "ドウ", romaji: "dou" }],
    kunYomi: [],
    significado: "cobre",
    exemplos: [
      {
        kanji: "銅",
        furigana: "どう",
        romaji: "dou",
        traducao: "cobre",
      },
      {
        kanji: "銅像",
        furigana: "どうぞう",
        romaji: "douzou",
        traducao: "estátua de cobre",
      },
      {
        kanji: "銅器",
        furigana: "どうき",
        romaji: "douki",
        traducao: "utensílios de cobre",
      },
    ],
  },
  {
    kanji: "湿",
    onYomi: [{ katakana: "シツ", romaji: "shitsu" }],
    kunYomi: [{ hiragana: "しめ.る", romaji: "shime.ru" }],
    significado: "úmido",
    exemplos: [
      {
        kanji: "湿る",
        furigana: "しめる",
        romaji: "shimeru",
        traducao: "ficar úmido",
      },
      {
        kanji: "湿気",
        furigana: "しっけ",
        romaji: "shikke",
        traducao: "umidade",
      },
      {
        kanji: "湿",
        furigana: "しめ",
        romaji: "shime",
        traducao: "úmido",
      },
    ],
  },
  {
    kanji: "瓶",
    onYomi: [{ katakana: "ビン", romaji: "bin" }],
    kunYomi: [],
    significado: "garrafa",
    exemplos: [
      {
        kanji: "瓶",
        furigana: "びん",
        romaji: "bin",
        traducao: "garrafa",
      },
      {
        kanji: "瓶詰",
        furigana: "びんづめ",
        romaji: "binzume",
        traducao: "engarrafado",
      },
      {
        kanji: "ガラス瓶",
        furigana: "がらすびん",
        romaji: "garasubin",
        traducao: "garrafa de vidro",
      },
    ],
  },
  {
    kanji: "咲",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "さ.く", romaji: "sa.ku" }],
    significado: "florescer",
    exemplos: [
      {
        kanji: "咲く",
        furigana: "さく",
        romaji: "saku",
        traducao: "florescer",
      },
      {
        kanji: "咲き誇る",
        furigana: "さきほこる",
        romaji: "sakihokoru",
        traducao: "florescer em esplendor",
      },
      {
        kanji: "花咲く",
        furigana: "はなさく",
        romaji: "hanasaku",
        traducao: "florescer",
      },
    ],
  },
  {
    kanji: "召",
    onYomi: [{ katakana: "ショウ", romaji: "shou" }],
    kunYomi: [{ hiragana: "め.す", romaji: "me.su" }],
    significado: "chamar; convocar",
    exemplos: [
      {
        kanji: "召す",
        furigana: "めす",
        romaji: "mesu",
        traducao: "chamar; convocar",
      },
      {
        kanji: "召喚",
        furigana: "しょうかん",
        romaji: "shoukan",
        traducao: "convocar; invocar",
      },
      {
        kanji: "招待",
        furigana: "しょうたい",
        romaji: "shoutai",
        traducao: "convidar",
      },
    ],
  },
  {
    kanji: "缶",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "lata",
    exemplos: [
      {
        kanji: "缶",
        furigana: "かん",
        romaji: "kan",
        traducao: "lata",
      },
      {
        kanji: "空き缶",
        furigana: "あきかん",
        romaji: "akikan",
        traducao: "lata vazia",
      },
      {
        kanji: "缶詰",
        furigana: "かんづめ",
        romaji: "kandzume",
        traducao: "enlatado",
      },
    ],
  },
  {
    kanji: "隻",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [],
    significado: "unidade de navio",
    exemplos: [
      {
        kanji: "一隻",
        furigana: "いっせき",
        romaji: "isseki",
        traducao: "um navio",
      },
      {
        kanji: "艦隻",
        furigana: "かんせき",
        romaji: "kanseki",
        traducao: "navio; embarcação",
      },
      {
        kanji: "隻",
        furigana: "せき",
        romaji: "seki",
        traducao: "unidade de navio",
      },
    ],
  },
  {
    kanji: "脂",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "あぶら", romaji: "abura" }],
    significado: "gordura",
    exemplos: [
      {
        kanji: "脂",
        furigana: "あぶら",
        romaji: "abura",
        traducao: "gordura",
      },
      {
        kanji: "動物脂",
        furigana: "どうぶつあぶら",
        romaji: "doubutsu abura",
        traducao: "gordura animal",
      },
      {
        kanji: "脂肪",
        furigana: "しぼう",
        romaji: "shibou",
        traducao: "gordura corporal",
      },
    ],
  },
  {
    kanji: "蒸",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "む.す", romaji: "mu.su" }],
    significado: "cozinhar a vapor",
    exemplos: [
      {
        kanji: "蒸す",
        furigana: "むす",
        romaji: "musu",
        traducao: "cozinhar a vapor",
      },
      {
        kanji: "蒸気",
        furigana: "じょうき",
        romaji: "jouki",
        traducao: "vapor",
      },
      {
        kanji: "蒸",
        furigana: "む",
        romaji: "mu",
        traducao: "cozinhar a vapor",
      },
    ],
  },
  {
    kanji: "肌",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "はだ", romaji: "hada" }],
    significado: "pele",
    exemplos: [
      {
        kanji: "肌",
        furigana: "はだ",
        romaji: "hada",
        traducao: "pele",
      },
      {
        kanji: "乾燥肌",
        furigana: "かんそうはだ",
        romaji: "kansou hada",
        traducao: "pele seca",
      },
      {
        kanji: "美肌",
        furigana: "びはだ",
        romaji: "bihada",
        traducao: "pele bonita",
      },
    ],
  },
  {
    kanji: "耕",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "たがや.す", romaji: "tagaya.su" }],
    significado: "cultivar",
    exemplos: [
      {
        kanji: "耕す",
        furigana: "たがやす",
        romaji: "tagayasu",
        traducao: "cultivar",
      },
      {
        kanji: "農耕",
        furigana: "のうこう",
        romaji: "noukou",
        traducao: "agricultura",
      },
      {
        kanji: "耕地",
        furigana: "こうち",
        romaji: "kouchi",
        traducao: "terra cultivada",
      },
    ],
  },
  {
    kanji: "鈍",
    onYomi: [{ katakana: "ドン", romaji: "don" }],
    kunYomi: [{ hiragana: "にぶ.い", romaji: "nibu.i" }],
    significado: "embotado",
    exemplos: [
      {
        kanji: "鈍い",
        furigana: "にぶい",
        romaji: "nibui",
        traducao: "embotado",
      },
      {
        kanji: "鈍器",
        furigana: "どんき",
        romaji: "donki",
        traducao: "objeto embotado",
      },
      {
        kanji: "鈍",
        furigana: "どん",
        romaji: "don",
        traducao: "embotado",
      },
    ],
  },
  {
    kanji: "泥",
    onYomi: [{ katakana: "デイ", romaji: "dei" }],
    kunYomi: [{ hiragana: "どろ", romaji: "doro" }],
    significado: "lama",
    exemplos: [
      {
        kanji: "泥",
        furigana: "どろ",
        romaji: "doro",
        traducao: "lama",
      },
      {
        kanji: "泥棒",
        furigana: "どろぼう",
        romaji: "dorobou",
        traducao: "ladrão",
      },
      {
        kanji: "泥だらけ",
        furigana: "どろだらけ",
        romaji: "dorodarake",
        traducao: "cheio de lama",
      },
    ],
  },
  {
    kanji: "隅",
    onYomi: [{ katakana: "グウ", romaji: "gū" }],
    kunYomi: [{ hiragana: "すみ", romaji: "sumi" }],
    significado: "canto",
    exemplos: [
      {
        kanji: "隅",
        furigana: "すみ",
        romaji: "sumi",
        traducao: "canto",
      },
      {
        kanji: "隅々",
        furigana: "すみずみ",
        romaji: "sumizumi",
        traducao: "em todos os cantos",
      },
      {
        kanji: "角隅",
        furigana: "かどすみ",
        romaji: "kadosumi",
        traducao: "canto; recanto",
      },
    ],
  },
  {
    kanji: "灯",
    onYomi: [{ katakana: "トウ", romaji: "tō" }],
    kunYomi: [{ hiragana: "ともしび", romaji: "tomoshibi" }],
    significado: "luz",
    exemplos: [
      {
        kanji: "灯",
        furigana: "ともし",
        romaji: "tomoshi",
        traducao: "luz",
      },
      {
        kanji: "電灯",
        furigana: "でんとう",
        romaji: "dentō",
        traducao: "lâmpada; iluminação elétrica",
      },
      {
        kanji: "灯台",
        furigana: "とうだい",
        romaji: "tōdai",
        traducao: "farol",
      },
    ],
  },
  {
    kanji: "辛",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "から.い", romaji: "kara.i" }],
    significado: "picante; apimentado",
    exemplos: [
      {
        kanji: "辛い",
        furigana: "からい",
        romaji: "karai",
        traducao: "picante; apimentado",
      },
      {
        kanji: "辛口",
        furigana: "からくち",
        romaji: "karakuchi",
        traducao: "picante",
      },
      {
        kanji: "辛",
        furigana: "しん",
        romaji: "shin",
        traducao: "picante; apimentado",
      },
    ],
  },
  {
    kanji: "磨",
    onYomi: [{ katakana: "マ", romaji: "ma" }],
    kunYomi: [{ hiragana: "みが.く", romaji: "miga.ku" }],
    significado: "polir",
    exemplos: [
      {
        kanji: "磨く",
        furigana: "みがく",
        romaji: "migaku",
        traducao: "polir",
      },
      {
        kanji: "歯磨き",
        furigana: "はみがき",
        romaji: "hamigaki",
        traducao: "escovação dos dentes",
      },
      {
        kanji: "研磨",
        furigana: "けんま",
        romaji: "kenma",
        traducao: "polimento",
      },
    ],
  },
  {
    kanji: "麦",
    onYomi: [{ katakana: "バク", romaji: "baku" }],
    kunYomi: [{ hiragana: "むぎ", romaji: "mugi" }],
    significado: "trigo",
    exemplos: [
      {
        kanji: "麦",
        furigana: "むぎ",
        romaji: "mugi",
        traducao: "trigo",
      },
      {
        kanji: "小麦",
        furigana: "こむぎ",
        romaji: "komugi",
        traducao: "trigo",
      },
      {
        kanji: "麦茶",
        furigana: "むぎちゃ",
        romaji: "mugicha",
        traducao: "chá de trigo",
      },
    ],
  },
  {
    kanji: "姓",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [],
    significado: "sobrenome",
    exemplos: [
      {
        kanji: "姓",
        furigana: "せい",
        romaji: "sei",
        traducao: "sobrenome",
      },
      {
        kanji: "名字",
        furigana: "みょうじ",
        romaji: "myōji",
        traducao: "sobrenome",
      },
      {
        kanji: "姓氏",
        furigana: "せいし",
        romaji: "seishi",
        traducao: "sobrenome",
      },
    ],
  },
  {
    kanji: "筒",
    onYomi: [{ katakana: "トウ", romaji: "tō" }],
    kunYomi: [],
    significado: "tubo",
    exemplos: [
      {
        kanji: "筒",
        furigana: "つつ",
        romaji: "tsutsu",
        traducao: "tubo",
      },
      {
        kanji: "花筒",
        furigana: "はなづつ",
        romaji: "hanazutsu",
        traducao: "vaso de flores",
      },
      {
        kanji: "筒抜け",
        furigana: "つつぬけ",
        romaji: "tsutsunuke",
        traducao: "furado",
      },
    ],
  },
  {
    kanji: "鼻",
    onYomi: [{ katakana: "ビ", romaji: "bi" }],
    kunYomi: [{ hiragana: "はな", romaji: "hana" }],
    significado: "nariz",
    exemplos: [
      {
        kanji: "鼻",
        furigana: "はな",
        romaji: "hana",
        traducao: "nariz",
      },
      {
        kanji: "鼻水",
        furigana: "はなみず",
        romaji: "hanamizu",
        traducao: "coriza",
      },
      {
        kanji: "鼻歌",
        furigana: "はなうた",
        romaji: "hanauta",
        traducao: "cantarolar",
      },
    ],
  },
  {
    kanji: "粒",
    onYomi: [{ katakana: "リュウ", romaji: "ryū" }],
    kunYomi: [{ hiragana: "つぶ", romaji: "tsubu" }],
    significado: "grão",
    exemplos: [
      {
        kanji: "粒",
        furigana: "つぶ",
        romaji: "tsubu",
        traducao: "grão",
      },
      {
        kanji: "雨粒",
        furigana: "あまつぶ",
        romaji: "amatsubu",
        traducao: "gota de chuva",
      },
      {
        kanji: "粒子",
        furigana: "りゅうし",
        romaji: "ryūshi",
        traducao: "partícula",
      },
    ],
  },
  {
    kanji: "詞",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [],
    significado: "palavra",
    exemplos: [
      {
        kanji: "詞",
        furigana: "し",
        romaji: "shi",
        traducao: "palavra",
      },
      {
        kanji: "歌詞",
        furigana: "かし",
        romaji: "kashi",
        traducao: "letra da música",
      },
      {
        kanji: "文詞",
        furigana: "ぶんし",
        romaji: "bunshi",
        traducao: "prosa",
      },
    ],
  },
  {
    kanji: "胃",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [],
    significado: "estômago",
    exemplos: [
      {
        kanji: "胃",
        furigana: "い",
        romaji: "i",
        traducao: "estômago",
      },
      {
        kanji: "胃袋",
        furigana: "いぶくろ",
        romaji: "ibukuro",
        traducao: "bolsa do estômago",
      },
      {
        kanji: "胃痛",
        furigana: "いつう",
        romaji: "itsuu",
        traducao: "dor de estômago",
      },
    ],
  },
  {
    kanji: "畳",
    onYomi: [{ katakana: "ジョウ", romaji: "jō" }],
    kunYomi: [{ hiragana: "たたみ", romaji: "tatami" }],
    significado: "esteira; tapete",
    exemplos: [
      {
        kanji: "畳",
        furigana: "たたみ",
        romaji: "tatami",
        traducao: "esteira; tapete",
      },
      {
        kanji: "畳む",
        furigana: "たたむ",
        romaji: "tatamu",
        traducao: "dobrar",
      },
      {
        kanji: "畳替え",
        furigana: "たたみがえ",
        romaji: "tatamigae",
        traducao: "substituição do tatami",
      },
    ],
  },
  {
    kanji: "机",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "つくえ", romaji: "tsukue" }],
    significado: "mesa",
    exemplos: [
      {
        kanji: "机",
        furigana: "つくえ",
        romaji: "tsukue",
        traducao: "mesa",
      },
      {
        kanji: "机上",
        furigana: "きじょう",
        romaji: "kijō",
        traducao: "sobre a mesa",
      },
      {
        kanji: "机上の空論",
        furigana: "きじょうのくうろん",
        romaji: "kijō no kūron",
        traducao: "teoria vazia",
      },
    ],
  },
  {
    kanji: "膚",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "はだ", romaji: "hada" }],
    significado: "pele",
    exemplos: [
      {
        kanji: "皮膚",
        furigana: "ひふ",
        romaji: "hifu",
        traducao: "pele",
      },
      {
        kanji: "膚色",
        furigana: "ふしょく",
        romaji: "fushoku",
        traducao: "cor da pele",
      },
      {
        kanji: "脂肪膜",
        furigana: "しぼうまく",
        romaji: "shibōmaku",
        traducao: "membrana de gordura",
      },
    ],
  },
  {
    kanji: "濯",
    onYomi: [{ katakana: "タク", romaji: "taku" }],
    kunYomi: [{ hiragana: "すす.ぐ", romaji: "susu.gu" }],
    significado: "lavar",
    exemplos: [
      {
        kanji: "洗濯",
        furigana: "せんたく",
        romaji: "sentaku",
        traducao: "lavar",
      },
      {
        kanji: "洗濯機",
        furigana: "せんたくき",
        romaji: "sentakuki",
        traducao: "máquina de lavar",
      },
      {
        kanji: "洗濯物",
        furigana: "せんたくもの",
        romaji: "sentaku mono",
        traducao: "roupa lavada",
      },
    ],
  },
  {
    kanji: "塔",
    onYomi: [{ katakana: "トウ", romaji: "tō" }],
    kunYomi: [],
    significado: "torre",
    exemplos: [
      {
        kanji: "塔",
        furigana: "とう",
        romaji: "tō",
        traducao: "torre",
      },
      {
        kanji: "塔上",
        furigana: "とうじょう",
        romaji: "tōjō",
        traducao: "no topo da torre",
      },
      {
        kanji: "塔婆",
        furigana: "とうば",
        romaji: "tōba",
        traducao: "estupa",
      },
    ],
  },
  {
    kanji: "沸",
    onYomi: [{ katakana: "フツ", romaji: "futsu" }],
    kunYomi: [{ hiragana: "わ.く", romaji: "wa.ku" }],
    significado: "ferver",
    exemplos: [
      {
        kanji: "沸く",
        furigana: "わく",
        romaji: "waku",
        traducao: "ferver",
      },
      {
        kanji: "沸騰",
        furigana: "ふっとう",
        romaji: "futtō",
        traducao: "ebulição",
      },
      {
        kanji: "沸点",
        furigana: "ふってん",
        romaji: "futten",
        traducao: "ponto de ebulição",
      },
    ],
  },
  {
    kanji: "灰",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "はい", romaji: "hai" }],
    significado: "cinza",
    exemplos: [
      {
        kanji: "灰",
        furigana: "はい",
        romaji: "hai",
        traducao: "cinza",
      },
      {
        kanji: "灰色",
        furigana: "はいいろ",
        romaji: "haiiro",
        traducao: "cor cinza",
      },
      {
        kanji: "灰皿",
        furigana: "はいざら",
        romaji: "haizara",
        traducao: "cinzeiro",
      },
    ],
  },
  {
    kanji: "菓",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [],
    significado: "doce; sobremesa",
    exemplos: [
      {
        kanji: "菓子",
        furigana: "かし",
        romaji: "kashi",
        traducao: "doce; sobremesa",
      },
      {
        kanji: "和菓子",
        furigana: "わがし",
        romaji: "wagashi",
        traducao: "doce japonês",
      },
      {
        kanji: "洋菓子",
        furigana: "ようがし",
        romaji: "yōgashi",
        traducao: "doce ocidental",
      },
    ],
  },
  {
    kanji: "帽",
    onYomi: [{ katakana: "ボウ", romaji: "bō" }],
    kunYomi: [],
    significado: "chapéu",
    exemplos: [
      {
        kanji: "帽子",
        furigana: "ぼうし",
        romaji: "bōshi",
        traducao: "chapéu",
      },
      {
        kanji: "頭帽",
        furigana: "とうぼう",
        romaji: "tōbō",
        traducao: "touca",
      },
      {
        kanji: "制帽",
        furigana: "せいぼう",
        romaji: "seibō",
        traducao: "quepe",
      },
    ],
  },
  {
    kanji: "枯",
    onYomi: [{ katakana: "コ", romaji: "ko" }],
    kunYomi: [{ hiragana: "か.れる", romaji: "ka.reru" }],
    significado: "seco; murcha",
    exemplos: [
      {
        kanji: "枯れる",
        furigana: "かれる",
        romaji: "kareru",
        traducao: "secar; murchar",
      },
      {
        kanji: "枯れ葉",
        furigana: "かれは",
        romaji: "kareha",
        traducao: "folha seca",
      },
      {
        kanji: "枯渇",
        furigana: "こかつ",
        romaji: "kokatsu",
        traducao: "esgotamento",
      },
    ],
  },
  {
    kanji: "涼",
    onYomi: [{ katakana: "リョウ", romaji: "ryō" }],
    kunYomi: [{ hiragana: "すず.しい", romaji: "suzu.shii" }],
    significado: "fresco",
    exemplos: [
      {
        kanji: "涼しい",
        furigana: "すずしい",
        romaji: "suzushii",
        traducao: "fresco",
      },
      {
        kanji: "涼風",
        furigana: "りょうふう",
        romaji: "ryōfū",
        traducao: "brisa fresca",
      },
      {
        kanji: "涼感",
        furigana: "りょうかん",
        romaji: "ryōkan",
        traducao: "sensação de frescor",
      },
    ],
  },
  {
    kanji: "舟",
    onYomi: [{ katakana: "シュウ", romaji: "shū" }],
    kunYomi: [{ hiragana: "ふね", romaji: "fune" }],
    significado: "barco",
    exemplos: [
      {
        kanji: "舟",
        furigana: "ふね",
        romaji: "fune",
        traducao: "barco",
      },
      {
        kanji: "舟歌",
        furigana: "ふなうた",
        romaji: "funauta",
        traducao: "canção de barco",
      },
      {
        kanji: "汽船",
        furigana: "きせん",
        romaji: "kisen",
        traducao: "barco a vapor",
      },
    ],
  },
  {
    kanji: "貝",
    onYomi: [{ katakana: "バイ", romaji: "bai" }],
    kunYomi: [{ hiragana: "かい", romaji: "kai" }],
    significado: "concha",
    exemplos: [
      {
        kanji: "貝",
        furigana: "かい",
        romaji: "kai",
        traducao: "concha",
      },
      {
        kanji: "貝類",
        furigana: "かいるい",
        romaji: "kairui",
        traducao: "moluscos",
      },
      {
        kanji: "貝殻",
        furigana: "かいがら",
        romaji: "kaigara",
        traducao: "concha",
      },
    ],
  },
  {
    kanji: "符",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [],
    significado: "sinal; símbolo",
    exemplos: [
      {
        kanji: "符号",
        furigana: "ふごう",
        romaji: "fugō",
        traducao: "sinal; símbolo",
      },
      {
        kanji: "音符",
        furigana: "おんぷ",
        romaji: "onpu",
        traducao: "nota musical",
      },
      {
        kanji: "符丁",
        furigana: "ふちょう",
        romaji: "fuchō",
        traducao: "número do capítulo",
      },
    ],
  },
  {
    kanji: "憎",
    onYomi: [{ katakana: "ゾウ", romaji: "zō" }],
    kunYomi: [{ hiragana: "にく.む", romaji: "niku.mu" }],
    significado: "odiar",
    exemplos: [
      {
        kanji: "憎む",
        furigana: "にくむ",
        romaji: "nikumu",
        traducao: "odiar",
      },
      {
        kanji: "憎しみ",
        furigana: "にくしみ",
        romaji: "nikushimi",
        traducao: "ódio",
      },
      {
        kanji: "憎悪",
        furigana: "ぞうお",
        romaji: "zōo",
        traducao: "aversão",
      },
    ],
  },
  {
    kanji: "皿",
    onYomi: [{ katakana: "ベイ", romaji: "bei" }],
    kunYomi: [{ hiragana: "さら", romaji: "sara" }],
    significado: "prato",
    exemplos: [
      {
        kanji: "皿",
        furigana: "さら",
        romaji: "sara",
        traducao: "prato",
      },
      {
        kanji: "お皿",
        furigana: "おさら",
        romaji: "osara",
        traducao: "prato",
      },
      {
        kanji: "食器皿",
        furigana: "しょっきざら",
        romaji: "shokkizara",
        traducao: "prato",
      },
    ],
  },
  {
    kanji: "肯",
    onYomi: [{ katakana: "コウ", romaji: "kō" }],
    kunYomi: [{ hiragana: "がえんじ.る", romaji: "gaenji.ru" }],
    significado: "consentir; concordar",
    exemplos: [
      {
        kanji: "肯る",
        furigana: "がえる",
        romaji: "gaeru",
        traducao: "consentir; concordar",
      },
      {
        kanji: "肯定",
        furigana: "こうてい",
        romaji: "kōtei",
        traducao: "afirmativo; positivo",
      },
      {
        kanji: "肯定的",
        furigana: "こうていてき",
        romaji: "kōteiteki",
        traducao: "positivo",
      },
    ],
  },
  {
    kanji: "燥",
    onYomi: [{ katakana: "ソウ", romaji: "sō" }],
    kunYomi: [{ hiragana: "はしゃ.ぐ", romaji: "hasha.gu" }],
    significado: "seco; irritado",
    exemplos: [
      {
        kanji: "燥ぐ",
        furigana: "はしゃぐ",
        romaji: "hashagu",
        traducao: "seco; irritado",
      },
      {
        kanji: "乾燥",
        furigana: "かんそう",
        romaji: "kansō",
        traducao: "seco",
      },
      {
        kanji: "燥気",
        furigana: "そうき",
        romaji: "sōki",
        traducao: "aridez",
      },
    ],
  },
  {
    kanji: "畜",
    onYomi: [{ katakana: "チク", romaji: "chiku" }],
    kunYomi: [],
    significado: "criação; gado",
    exemplos: [
      {
        kanji: "畜産",
        furigana: "ちくさん",
        romaji: "chikusan",
        traducao: "criação de animais",
      },
      {
        kanji: "家畜",
        furigana: "かちく",
        romaji: "kachiku",
        traducao: "gado",
      },
      {
        kanji: "畜生",
        furigana: "ちくしょう",
        romaji: "chikushō",
        traducao: "animal",
      },
    ],
  },
  {
    kanji: "坊",
    onYomi: [{ katakana: "ボウ", romaji: "bō" }],
    kunYomi: [{ hiragana: "ぼう", romaji: "bō" }],
    significado: "rapaz; monge",
    exemplos: [
      {
        kanji: "坊主",
        furigana: "ぼうず",
        romaji: "bōzu",
        traducao: "monge",
      },
      {
        kanji: "坊や",
        furigana: "ぼうや",
        romaji: "bōya",
        traducao: "rapaz",
      },
      {
        kanji: "寺坊",
        furigana: "じぼう",
        romaji: "jibō",
        traducao: "monge",
      },
    ],
  },
  {
    kanji: "挟",
    onYomi: [{ katakana: "キョウ", romaji: "kyō" }],
    kunYomi: [{ hiragana: "はさ.む", romaji: "hasa.mu" }],
    significado: "prender entre; encaixar",
    exemplos: [
      {
        kanji: "挟む",
        furigana: "はさむ",
        romaji: "hasamu",
        traducao: "prender entre; encaixar",
      },
      {
        kanji: "挟撃",
        furigana: "きょうげき",
        romaji: "kyōgeki",
        traducao: "ataque pinçado",
      },
      {
        kanji: "挟間",
        furigana: "きょうかん",
        romaji: "kyōkan",
        traducao: "espaço estreito",
      },
    ],
  },
  {
    kanji: "曇",
    onYomi: [{ katakana: "ドン", romaji: "don" }],
    kunYomi: [{ hiragana: "くも.る", romaji: "kumo.ru" }],
    significado: "nublado",
    exemplos: [
      {
        kanji: "曇る",
        furigana: "くもる",
        romaji: "kumoru",
        traducao: "nublado",
      },
      {
        kanji: "曇天",
        furigana: "どんてん",
        romaji: "donten",
        traducao: "dia nublado",
      },
      {
        kanji: "曇空",
        furigana: "くもぞら",
        romaji: "kumozora",
        traducao: "céu nublado",
      },
    ],
  },
  {
    kanji: "滴",
    onYomi: [{ katakana: "テキ", romaji: "teki" }],
    kunYomi: [{ hiragana: "したた.る", romaji: "shitata.ru" }],
    significado: "gotejar",
    exemplos: [
      {
        kanji: "滴る",
        furigana: "したたる",
        romaji: "shitataru",
        traducao: "gotejar",
      },
      {
        kanji: "水滴",
        furigana: "すいてき",
        romaji: "suiteki",
        traducao: "gota de água",
      },
      {
        kanji: "涙滴",
        furigana: "るいてき",
        romaji: "ruiteki",
        traducao: "lágrima",
      },
    ],
  },
  {
    kanji: "伺",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "うかが.う", romaji: "ukaga.u" }],
    significado: "visitar; perguntar",
    exemplos: [
      {
        kanji: "伺う",
        furigana: "うかがう",
        romaji: "ukagau",
        traducao: "visitar; perguntar",
      },
      {
        kanji: "伺候",
        furigana: "しごう",
        romaji: "shigō",
        traducao: "atender",
      },
      {
        kanji: "拝伺",
        furigana: "はいうかがい",
        romaji: "haiukagai",
        traducao: "visitar",
      },
    ],
  },
];

export default n2KanjiList;