const n4KanjiList = [
  {
    kanji: "不",
    onYomi: [{ katakana: "フ", romaji: "fu" }],
    kunYomi: [{ hiragana: "ふ", romaji: "fu" }],
    significado: "não",
    exemplos: [
      {
        kanji: "不思議",
        furigana: "ふしぎ",
        romaji: "fushigi",
        traducao: "misterioso",
      },
      {
        kanji: "不便",
        furigana: "ふべん",
        romaji: "fuben",
        traducao: "inconveniente",
      },
      {
        kanji: "不安",
        furigana: "ふあん",
        romaji: "fuan",
        traducao: "inseguro",
      },
    ],
  },
  {
    kanji: "世",
    onYomi: [
      { katakana: "セイ", romaji: "sei" },
      { katakana: "セ", romaji: "se" },
    ],
    kunYomi: [{ hiragana: "よ", romaji: "yo" }],
    significado: "mundo, geração",
    exemplos: [
      {
        kanji: "世界",
        furigana: "せかい",
        romaji: "sekai",
        traducao: "mundo",
      },
      {
        kanji: "現世",
        furigana: "うつしよ",
        romaji: "utsushiyo",
        traducao: "vida atual",
      },
      {
        kanji: "世代",
        furigana: "せだい",
        romaji: "sedai",
        traducao: "geração",
      },
    ],
  },
  {
    kanji: "主",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [
      { hiragana: "ぬし", romaji: "nushi" },
      { hiragana: "おも", romaji: "omo" },
    ],
    significado: "mestre, principal",
    exemplos: [
      {
        kanji: "主人",
        furigana: "しゅじん",
        romaji: "shujin",
        traducao: "marido",
      },
      {
        kanji: "主張",
        furigana: "しゅちょう",
        romaji: "shuchou",
        traducao: "afirmar",
      },
      {
        kanji: "主婦",
        furigana: "しゅふ",
        romaji: "shufu",
        traducao: "dona de casa",
      },
    ],
  },
  {
    kanji: "乗",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [
      { hiragana: "の", romaji: "no" },
      { hiragana: "の", romaji: "no" },
    ],
    significado: "montar, pegar (transporte)",
    exemplos: [
      {
        kanji: "乗車",
        furigana: "じょうしゃ",
        romaji: "jousha",
        traducao: "embarcar",
      },
      {
        kanji: "乗客",
        furigana: "じょうきゃく",
        romaji: "joukyaku",
        traducao: "passageiro",
      },
      {
        kanji: "乗馬",
        furigana: "じょうば",
        romaji: "jouba",
        traducao: "andar de cavalo",
      },
    ],
  },
  {
    kanji: "事",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [{ hiragana: "こと", romaji: "koto" }],
    significado: "coisa, assunto",
    exemplos: [
      {
        kanji: "仕事",
        furigana: "しごと",
        romaji: "shigoto",
        traducao: "trabalho",
      },
      {
        kanji: "出来事",
        furigana: "できごと",
        romaji: "dekigoto",
        traducao: "acontecimento",
      },
      {
        kanji: "重要な事",
        furigana: "じゅうようなこと",
        romaji: "juuyouna koto",
        traducao: "coisa importante",
      },
    ],
  },
  {
    kanji: "京",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "みやこ", romaji: "miyako" }],
    significado: "capital",
    exemplos: [
      {
        kanji: "東京",
        furigana: "とうきょう",
        romaji: "Tōkyō",
        traducao: "Tóquio",
      },
      {
        kanji: "京都",
        furigana: "きょうと",
        romaji: "Kyōto",
        traducao: "Quioto",
      },
      {
        kanji: "京大",
        furigana: "きょうだい",
        romaji: "Kyōdai",
        traducao: "Universidade de Quioto",
      },
    ],
  },
  {
    kanji: "仕",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [
      { hiragana: "つか", romaji: "tsuka" },
      { hiragana: "し", romaji: "shi" },
    ],
    significado: "fazer",
    exemplos: [
      {
        kanji: "仕事",
        furigana: "しごと",
        romaji: "shigoto",
        traducao: "trabalho",
      },
      {
        kanji: "仕方",
        furigana: "しかた",
        romaji: "shikata",
        traducao: "maneira",
      },
      {
        kanji: "仕上げる",
        furigana: "しあげる",
        romaji: "shiageru",
        traducao: "terminar, finalizar",
      },
    ],
  },
  {
    kanji: "代",
    onYomi: [{ katakana: "ダイ", romaji: "dai" }],
    kunYomi: [
      { hiragana: "かわ", romaji: "kawa" },
      { hiragana: "よ", romaji: "yo" },
    ],
    significado: "substituir, geração",
    exemplos: [
      {
        kanji: "代表",
        furigana: "だいひょう",
        romaji: "daihyou",
        traducao: "representante",
      },
      {
        kanji: "時代",
        furigana: "じだい",
        romaji: "jidai",
        traducao: "época",
      },
      {
        kanji: "交代",
        furigana: "こうたい",
        romaji: "koutai",
        traducao: "alternância",
      },
    ],
  },
  {
    kanji: "以",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "もっ", romaji: "motto" }],
    significado: "com base em, desde",
    exemplos: [
      {
        kanji: "以上",
        furigana: "いじょう",
        romaji: "ijou",
        traducao: "acima de",
      },
      {
        kanji: "以下",
        furigana: "いか",
        romaji: "ika",
        traducao: "abaixo de",
      },
      {
        kanji: "以内",
        furigana: "いない",
        romaji: "inai",
        traducao: "dentro de",
      },
    ],
  },
  {
    kanji: "低",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "ひく", romaji: "hiku" }],
    significado: "baixo",
    exemplos: [
      {
        kanji: "低音",
        furigana: "ていおん",
        romaji: "teion",
        traducao: "tom baixo",
      },
      {
        kanji: "低温",
        furigana: "ていおん",
        romaji: "teion",
        traducao: "baixa temperatura",
      },
      {
        kanji: "低下",
        furigana: "ていか",
        romaji: "teika",
        traducao: "queda",
      },
    ],
  },
  {
    kanji: "住",
    onYomi: [{ katakana: "ジュウ", romaji: "juu" }],
    kunYomi: [{ hiragana: "す", romaji: "su" }],
    significado: "morar",
    exemplos: [
      {
        kanji: "住所",
        furigana: "じゅうしょ",
        romaji: "juusho",
        traducao: "endereço",
      },
      {
        kanji: "住宅",
        furigana: "じゅうたく",
        romaji: "juutaku",
        traducao: "casa",
      },
      {
        kanji: "居住",
        furigana: "きょじゅう",
        romaji: "kyojuu",
        traducao: "residência",
      },
    ],
  },
  {
    kanji: "体",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "からだ", romaji: "karada" }],
    significado: "corpo",
    exemplos: [
      {
        kanji: "体力",
        furigana: "たいりょく",
        romaji: "tairyoku",
        traducao: "energia física",
      },
      {
        kanji: "体重",
        furigana: "たいじゅう",
        romaji: "taijuu",
        traducao: "peso corporal",
      },
      {
        kanji: "健康な体",
        furigana: "けんこうなからだ",
        romaji: "kenkou na karada",
        traducao: "corpo saudável",
      },
    ],
  },
  {
    kanji: "作",
    onYomi: [{ katakana: "サク", romaji: "saku" }],
    kunYomi: [{ hiragana: "つく", romaji: "tsuku" }],
    significado: "criar, fazer",
    exemplos: [
      {
        kanji: "作品",
        furigana: "さくひん",
        romaji: "sakuhin",
        traducao: "obra",
      },
      {
        kanji: "作曲",
        furigana: "さっきょく",
        romaji: "sakkyoku",
        traducao: "composição musical",
      },
      {
        kanji: "作成",
        furigana: "さくせい",
        romaji: "sakusei",
        traducao: "criação",
      },
    ],
  },
  {
    kanji: "使",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "つか", romaji: "tsuka" }],
    significado: "usar",
    exemplos: [
      {
        kanji: "使用",
        furigana: "しよう",
        romaji: "shiyou",
        traducao: "uso",
      },
      {
        kanji: "使い方",
        furigana: "つかいかた",
        romaji: "tsukaikata",
        traducao: "forma de uso",
      },
      {
        kanji: "使命",
        furigana: "しめい",
        romaji: "shimei",
        traducao: "missão",
      },
    ],
  },
  {
    kanji: "便",
    onYomi: [{ katakana: "ベン", romaji: "ben" }],
    kunYomi: [{ hiragana: "たよ", romaji: "tayo" }],
    significado: "conveniência",
    exemplos: [
      {
        kanji: "便利",
        furigana: "べんり",
        romaji: "benri",
        traducao: "conveniente",
      },
      {
        kanji: "便箋",
        furigana: "びんせん",
        romaji: "binsen",
        traducao: "papel de carta",
      },
      {
        kanji: "便所",
        furigana: "べんじょ",
        romaji: "benjo",
        traducao: "banheiro",
      },
    ],
  },
  {
    kanji: "借",
    onYomi: [{ katakana: "シャク", romaji: "shaku" }],
    kunYomi: [{ hiragana: "かり", romaji: "kari" }],
    significado: "emprestar",
    exemplos: [
      {
        kanji: "借りる",
        furigana: "かりる",
        romaji: "kariru",
        traducao: "emprestar",
      },
      {
        kanji: "借金",
        furigana: "しゃっきん",
        romaji: "shakkin",
        traducao: "dívida",
      },
      {
        kanji: "借り物",
        furigana: "かりもの",
        romaji: "karimono",
        traducao: "emprestado",
      },
    ],
  },
  {
    kanji: "働",
    onYomi: [{ katakana: "ドウ", romaji: "dou" }],
    kunYomi: [{ hiragana: "はたら", romaji: "hataraka" }],
    significado: "trabalhar",
    exemplos: [
      {
        kanji: "働く",
        furigana: "はたらく",
        romaji: "hataraku",
        traducao: "trabalhar",
      },
      {
        kanji: "労働",
        furigana: "ろうどう",
        romaji: "roudou",
        traducao: "trabalho",
      },
      {
        kanji: "働き方",
        furigana: "はたらきかた",
        romaji: "hatarakikata",
        traducao: "forma de trabalhar",
      },
    ],
  },
  {
    kanji: "元",
    onYomi: [{ katakana: "ゲン", romaji: "gen" }],
    kunYomi: [{ hiragana: "もと", romaji: "moto" }],
    significado: "origem, anterior",
    exemplos: [
      {
        kanji: "元気",
        furigana: "げんき",
        romaji: "genki",
        traducao: "energia",
      },
      {
        kanji: "起源",
        furigana: "きげん",
        romaji: "kigen",
        traducao: "origem",
      },
      {
        kanji: "元旦",
        furigana: "がんたん",
        romaji: "gantan",
        traducao: "ano novo",
      },
    ],
  },
  {
    kanji: "兄",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "あに", romaji: "ani" }],
    significado: "irmão mais velho",
    exemplos: [
      {
        kanji: "兄弟",
        furigana: "きょうだい",
        romaji: "kyoudai",
        traducao: "irmãos",
      },
      {
        kanji: "兄妹",
        furigana: "きょうだい",
        romaji: "kyoudai",
        traducao: "irmãos e irmãs",
      },
      {
        kanji: "兄貴",
        furigana: "あにき",
        romaji: "aniki",
        traducao: "irmão mais velho (termo familiar)",
      },
    ],
  },
  {
    kanji: "光",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "ひかり", romaji: "hikari" }],
    significado: "luz",
    exemplos: [
      {
        kanji: "太陽光",
        furigana: "たいようこう",
        romaji: "taiyoukou",
        traducao: "luz solar",
      },
      {
        kanji: "光線",
        furigana: "こうせん",
        romaji: "kousen",
        traducao: "raio de luz",
      },
      {
        kanji: "光る",
        furigana: "ひかる",
        romaji: "hikaru",
        traducao: "brilhar",
      },
    ],
  },
  {
    kanji: "写",
    onYomi: [{ katakana: "シャ", romaji: "sha" }],
    kunYomi: [{ hiragana: "うつ", romaji: "utsu" }],
    significado: "fotografar, copiar",
    exemplos: [
      {
        kanji: "写真",
        furigana: "しゃしん",
        romaji: "shashin",
        traducao: "fotografia",
      },
      {
        kanji: "写生",
        furigana: "しゃせい",
        romaji: "shasei",
        traducao: "desenho de observação",
      },
      {
        kanji: "写る",
        furigana: "うつる",
        romaji: "utsuru",
        traducao: "ser fotografado",
      },
    ],
  },
  {
    kanji: "冬",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "ふゆ", romaji: "fuyu" }],
    significado: "inverno",
    exemplos: [
      {
        kanji: "冬季",
        furigana: "とうき",
        romaji: "touki",
        traducao: "inverno",
      },
      {
        kanji: "冬休み",
        furigana: "ふゆやすみ",
        romaji: "fuyuyasumi",
        traducao: "férias de inverno",
      },
      {
        kanji: "冬至",
        furigana: "とうじ",
        romaji: "touji",
        traducao: "solstício de inverno",
      },
    ],
  },
  {
    kanji: "切",
    onYomi: [
      { katakana: "セツ", romaji: "setsu" },
      { katakana: "サイ", romaji: "sai" },
    ],
    kunYomi: [
      { hiragana: "き", romaji: "ki" },
      { hiragana: "き.る", romaji: "ki.ru" },
    ],
    significado: "cortar, desligar",
    exemplos: [
      {
        kanji: "切手",
        furigana: "きって",
        romaji: "kitte",
        traducao: "selo",
      },
      {
        kanji: "切符",
        furigana: "きっぷ",
        romaji: "kippu",
        traducao: "bilhete",
      },
      {
        kanji: "切る",
        furigana: "きる",
        romaji: "kiru",
        traducao: "cortar",
      },
    ],
  },
  {
    kanji: "別",
    onYomi: [{ katakana: "ベツ", romaji: "betsu" }],
    kunYomi: [{ hiragana: "わか", romaji: "waka" }],
    significado: "diferente, separado",
    exemplos: [
      {
        kanji: "別れる",
        furigana: "わかれる",
        romaji: "wakareru",
        traducao: "separar-se",
      },
      {
        kanji: "別々",
        furigana: "べつべつ",
        romaji: "betsubetsu",
        traducao: "separadamente",
      },
      {
        kanji: "別名",
        furigana: "べつめい",
        romaji: "betsumei",
        traducao: "pseudônimo",
      },
    ],
  },
  {
    kanji: "力",
    onYomi: [{ katakana: "リョク", romaji: "ryoku" }],
    kunYomi: [{ hiragana: "ちから", romaji: "chikara" }],
    significado: "força, poder",
    exemplos: [
      {
        kanji: "力量",
        furigana: "りょくりょう",
        romaji: "ryokuryou",
        traducao: "capacidade",
      },
      {
        kanji: "力士",
        furigana: "りきし",
        romaji: "rikishi",
        traducao: "lutador de sumô",
      },
      {
        kanji: "努力",
        furigana: "どりょく",
        romaji: "doryoku",
        traducao: "esforço",
      },
    ],
  },
  {
    kanji: "勉",
    onYomi: [{ katakana: "ベン", romaji: "ben" }],
    kunYomi: [{ hiragana: "つと.める", romaji: "tsuto.meru" }],
    significado: "empenhar-se",
    exemplos: [
      {
        kanji: "勉学",
        furigana: "べんがく",
        romaji: "bengaku",
        traducao: "estudo",
      },
      {
        kanji: "勉強",
        furigana: "べんきょう",
        romaji: "benkyou",
        traducao: "estudar",
      },
      {
        kanji: "勉強する",
        furigana: "べんきょうする",
        romaji: "benkyou suru",
        traducao: "estudar",
      },
    ],
  },
  {
    kanji: "動",
    onYomi: [{ katakana: "ドウ", romaji: "dou" }],
    kunYomi: [{ hiragana: "うご.く", romaji: "ugo.ku" }],
    significado: "mover, agir",
    exemplos: [
      {
        kanji: "動物",
        furigana: "どうぶつ",
        romaji: "doubutsu",
        traducao: "animal",
      },
      {
        kanji: "運動",
        furigana: "うんどう",
        romaji: "undou",
        traducao: "exercício físico",
      },
      {
        kanji: "動く",
        furigana: "うごく",
        romaji: "ugoku",
        traducao: "mover-se",
      },
    ],
  },
  {
    kanji: "区",
    onYomi: [{ katakana: "ク", romaji: "ku" }],
    kunYomi: [{ hiragana: "く", romaji: "ku" }],
    significado: "distrito",
    exemplos: [
      {
        kanji: "地区",
        furigana: "ちく",
        romaji: "chiku",
        traducao: "área",
      },
      {
        kanji: "区切る",
        furigana: "くぎる",
        romaji: "kugiru",
        traducao: "separar",
      },
      {
        kanji: "区画",
        furigana: "くかく",
        romaji: "kukaku",
        traducao: "lote",
      },
    ],
  },
  {
    kanji: "医",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [{ hiragana: "い", romaji: "i" }],
    significado: "médico, medicina",
    exemplos: [
      {
        kanji: "医者",
        furigana: "いしゃ",
        romaji: "isha",
        traducao: "médico",
      },
      {
        kanji: "医療",
        furigana: "いりょう",
        romaji: "iryō",
        traducao: "tratamento médico",
      },
      {
        kanji: "医学",
        furigana: "いがく",
        romaji: "igaku",
        traducao: "medicina",
      },
    ],
  },
  {
    kanji: "去",
    onYomi: [{ katakana: "キョ", romaji: "kyo" }],
    kunYomi: [{ hiragana: "さ.る", romaji: "sa.ru" }],
    significado: "ir embora, passado",
    exemplos: [
      {
        kanji: "去年",
        furigana: "きょねん",
        romaji: "kyonen",
        traducao: "ano passado",
      },
      {
        kanji: "出発する",
        furigana: "しゅっぱつする",
        romaji: "shuppatsu suru",
        traducao: "partir",
      },
      {
        kanji: "去る",
        furigana: "さる",
        romaji: "saru",
        traducao: "partir",
      },
    ],
  },
  {
    kanji: "台",
    onYomi: [
      { katakana: "ダイ", romaji: "dai" },
      { katakana: "タイ", romaji: "tai" },
    ],
    kunYomi: [{ hiragana: "うてな", romaji: "utena" }],
    significado: "plataforma, contador",
    exemplos: [
      {
        kanji: "台風",
        furigana: "たいふう",
        romaji: "taifuu",
        traducao: "tufão",
      },
      {
        kanji: "台所",
        furigana: "だいどころ",
        romaji: "daidokoro",
        traducao: "cozinha",
      },
      {
        kanji: "一台",
        furigana: "いちだい",
        romaji: "ichidai",
        traducao: "um (contador de máquinas, carros, etc.)",
      },
    ],
  },
  {
    kanji: "合",
    onYomi: [
      { katakana: "ゴウ", romaji: "gou" },
      { katakana: "ガッ", romaji: "gatsu" },
    ],
    kunYomi: [{ hiragana: "あ.う", romaji: "a.u" }],
    significado: "juntar, combinar",
    exemplos: [
      {
        kanji: "合格",
        furigana: "ごうかく",
        romaji: "goukaku",
        traducao: "aprovação",
      },
      {
        kanji: "合図",
        furigana: "あいず",
        romaji: "aizu",
        traducao: "sinal",
      },
      {
        kanji: "合わせる",
        furigana: "あわせる",
        romaji: "awaseru",
        traducao: "juntar",
      },
    ],
  },
  {
    kanji: "同",
    onYomi: [{ katakana: "ドウ", romaji: "dou" }],
    kunYomi: [{ hiragana: "おな.じ", romaji: "ona.ji" }],
    significado: "igual, mesmo",
    exemplos: [
      {
        kanji: "同じ",
        furigana: "おなじ",
        romaji: "onaji",
        traducao: "igual",
      },
      {
        kanji: "同時",
        furigana: "どうじ",
        romaji: "douji",
        traducao: "ao mesmo tempo",
      },
      {
        kanji: "一緒",
        furigana: "いっしょ",
        romaji: "issho",
        traducao: "junto",
      },
    ],
  },
  {
    kanji: "味",
    onYomi: [{ katakana: "ミ", romaji: "mi" }],
    kunYomi: [{ hiragana: "あじ", romaji: "aji" }],
    significado: "sabor, gosto",
    exemplos: [
      {
        kanji: "味噌",
        furigana: "みそ",
        romaji: "miso",
        traducao: "missô",
      },
      {
        kanji: "味覚",
        furigana: "みかく",
        romaji: "mikaku",
        traducao: "paladar",
      },
      {
        kanji: "美味しい",
        furigana: "おいしい",
        romaji: "oishii",
        traducao: "delicioso",
      },
    ],
  },
  {
    kanji: "品",
    onYomi: [{ katakana: "ヒン", romaji: "hin" }],
    kunYomi: [{ hiragana: "しな", romaji: "shina" }],
    significado: "produto, artigo",
    exemplos: [
      {
        kanji: "商品",
        furigana: "しょうひん",
        romaji: "shouhin",
        traducao: "produto",
      },
      {
        kanji: "品質",
        furigana: "ひんしつ",
        romaji: "hinshitsu",
        traducao: "qualidade",
      },
      {
        kanji: "品物",
        furigana: "しなもの",
        romaji: "shinamono",
        traducao: "item",
      },
    ],
  },
  {
    kanji: "員",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [],
    significado: "membro, funcionário",
    exemplos: [
      {
        kanji: "社員",
        furigana: "しゃいん",
        romaji: "shain",
        traducao: "funcionário",
      },
      {
        kanji: "学生員",
        furigana: "がくせいいん",
        romaji: "gakuseiin",
        traducao: "membro estudante",
      },
      {
        kanji: "チーム員",
        furigana: "チームいん",
        romaji: "chiimuin",
        traducao: "membro da equipe",
      },
    ],
  },
  {
    kanji: "問",
    onYomi: [{ katakana: "モン", romaji: "mon" }],
    kunYomi: [{ hiragana: "と", romaji: "to" }],
    significado: "pergunta, problema",
    exemplos: [
      {
        kanji: "質問",
        furigana: "しつもん",
        romaji: "shitsumon",
        traducao: "pergunta",
      },
      {
        kanji: "問題",
        furigana: "もんだい",
        romaji: "mondai",
        traducao: "problema",
      },
      {
        kanji: "問う",
        furigana: "とう",
        romaji: "tou",
        traducao: "perguntar",
      },
    ],
  },
  {
    kanji: "回",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "まわ.る", romaji: "mawa.ru" }],
    significado: "voltar, vezes",
    exemplos: [
      {
        kanji: "回答",
        furigana: "かいとう",
        romaji: "kaitou",
        traducao: "resposta",
      },
      {
        kanji: "回数",
        furigana: "かいすう",
        romaji: "kaisuu",
        traducao: "número de vezes",
      },
      {
        kanji: "回る",
        furigana: "まわる",
        romaji: "mawaru",
        traducao: "girar",
      },
    ],
  },
  {
    kanji: "図",
    onYomi: [{ katakana: "ズ", romaji: "zu" }],
    kunYomi: [{ hiragana: "え", romaji: "e" }],
    significado: "figura, plano",
    exemplos: [
      {
        kanji: "地図",
        furigana: "ちず",
        romaji: "chizu",
        traducao: "mapa",
      },
      {
        kanji: "図書館",
        furigana: "としょかん",
        romaji: "toshokan",
        traducao: "biblioteca",
      },
      {
        kanji: "図る",
        furigana: "はかる",
        romaji: "hakaru",
        traducao: "planejar",
      },
    ],
  },
  {
    kanji: "地",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [
      { hiragana: "じ", romaji: "ji" },
      { hiragana: "ち", romaji: "chi" },
    ],
    significado: "terra, lugar",
    exemplos: [
      {
        kanji: "地下",
        furigana: "ちか",
        romaji: "chika",
        traducao: "subterrâneo",
      },
      {
        kanji: "地域",
        furigana: "ちいき",
        romaji: "chiiki",
        traducao: "região",
      },
      {
        kanji: "地図",
        furigana: "ちず",
        romaji: "chizu",
        traducao: "mapa",
      },
    ],
  },
  {
    kanji: "堂",
    onYomi: [{ katakana: "ドウ", romaji: "dou" }],
    kunYomi: [],
    significado: "salão, templo",
    exemplos: [
      {
        kanji: "教堂",
        furigana: "きょうどう",
        romaji: "kyoudou",
        traducao: "igreja",
      },
      {
        kanji: "礼堂",
        furigana: "れいどう",
        romaji: "reidou",
        traducao: "sala de cerimônia",
      },
      {
        kanji: "講堂",
        furigana: "こうどう",
        romaji: "koudou",
        traducao: "auditório",
      },
    ],
  },
  {
    kanji: "場",
    onYomi: [{ katakana: "ジョウ", romaji: "jou" }],
    kunYomi: [{ hiragana: "ば", romaji: "ba" }],
    significado: "local, lugar",
    exemplos: [
      {
        kanji: "場所",
        furigana: "ばしょ",
        romaji: "basho",
        traducao: "local",
      },
      {
        kanji: "公園",
        furigana: "こうえん",
        romaji: "kouen",
        traducao: "parque",
      },
      {
        kanji: "場面",
        furigana: "ばめん",
        romaji: "bamen",
        traducao: "cena",
      },
    ],
  },
  {
    kanji: "声",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "こえ", romaji: "koe" }],
    significado: "voz",
    exemplos: [
      {
        kanji: "大声",
        furigana: "おおごえ",
        romaji: "oogoe",
        traducao: "voz alta",
      },
      {
        kanji: "声優",
        furigana: "せいゆう",
        romaji: "seiyuu",
        traducao: "dublador",
      },
      {
        kanji: "声",
        furigana: "こえ",
        romaji: "koe",
        traducao: "voz",
      },
    ],
  },
  {
    kanji: "売",
    onYomi: [{ katakana: "バイ", romaji: "bai" }],
    kunYomi: [{ hiragana: "う.る", romaji: "u.ru" }],
    significado: "vender",
    exemplos: [
      {
        kanji: "販売",
        furigana: "はんばい",
        romaji: "hanbai",
        traducao: "venda",
      },
      {
        kanji: "売店",
        furigana: "ばいてん",
        romaji: "baiten",
        traducao: "banca de venda",
      },
      {
        kanji: "売る",
        furigana: "うる",
        romaji: "uru",
        traducao: "vender",
      },
    ],
  },
  {
    kanji: "夏",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "なつ", romaji: "natsu" }],
    significado: "verão",
    exemplos: [
      {
        kanji: "夏休み",
        furigana: "なつやすみ",
        romaji: "natsuyasumi",
        traducao: "férias de verão",
      },
      {
        kanji: "夏季",
        furigana: "かき",
        romaji: "kaki",
        traducao: "temporada de verão",
      },
      {
        kanji: "夏服",
        furigana: "なつふく",
        romaji: "natsufuku",
        traducao: "roupa de verão",
      },
    ],
  },
  {
    kanji: "夕",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "ゆう", romaji: "yuu" }],
    significado: "tarde",
    exemplos: [
      {
        kanji: "夕方",
        furigana: "ゆうがた",
        romaji: "yuugata",
        traducao: "tarde",
      },
      {
        kanji: "夕食",
        furigana: "ゆうしょく",
        romaji: "yuushoku",
        traducao: "jantar",
      },
      {
        kanji: "夕日",
        furigana: "ゆうひ",
        romaji: "yuubi",
        traducao: "pôr do sol",
      },
    ],
  },
  {
    kanji: "夜",
    onYomi: [{ katakana: "ヤ", romaji: "ya" }],
    kunYomi: [{ hiragana: "よる", romaji: "yoru" }],
    significado: "noite",
    exemplos: [
      {
        kanji: "夜中",
        furigana: "よなか",
        romaji: "yonaka",
        traducao: "meia-noite",
      },
      {
        kanji: "夜景",
        furigana: "やけい",
        romaji: "yakei",
        traducao: "vista noturna",
      },
      {
        kanji: "夜行バス",
        furigana: "やこうバス",
        romaji: "yakou basu",
        traducao: "ônibus noturno",
      },
    ],
  },
  {
    kanji: "太",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "ふと.い", romaji: "futo.i" }],
    significado: "gordo, grosso",
    exemplos: [
      {
        kanji: "太陽",
        furigana: "たいよう",
        romaji: "taiyou",
        traducao: "sol",
      },
      {
        kanji: "太字",
        furigana: "ふとじ",
        romaji: "futoji",
        traducao: "negrito",
      },
      {
        kanji: "太る",
        furigana: "ふとる",
        romaji: "futoru",
        traducao: "engordar",
      },
    ],
  },
  {
    kanji: "好",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [
      { hiragana: "す.く", romaji: "su.ku" },
      { hiragana: "この.む", romaji: "ko.no.mu" },
    ],
    significado: "gostar",
    exemplos: [
      {
        kanji: "好き",
        furigana: "すき",
        romaji: "suki",
        traducao: "gostar",
      },
      {
        kanji: "好意",
        furigana: "こうい",
        romaji: "koui",
        traducao: "boa vontade",
      },
      {
        kanji: "好む",
        furigana: "このむ",
        romaji: "konomu",
        traducao: "preferir",
      },
    ],
  },
  {
    kanji: "妹",
    onYomi: [{ katakana: "マイ", romaji: "mai" }],
    kunYomi: [{ hiragana: "いもうと", romaji: "imouto" }],
    significado: "irmã mais nova",
    exemplos: [
      {
        kanji: "姉妹",
        furigana: "しまい",
        romaji: "shimai",
        traducao: "irmãs",
      },
      {
        kanji: "妹さん",
        furigana: "いもうとさん",
        romaji: "imouto-san",
        traducao: "irmã mais nova (formal)",
      },
      {
        kanji: "妹",
        furigana: "いもうと",
        romaji: "imouto",
        traducao: "irmã mais nova",
      },
    ],
  },
  {
    kanji: "姉",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "あね", romaji: "ane" }],
    significado: "irmã mais velha",
    exemplos: [
      {
        kanji: "姉妹",
        furigana: "しまい",
        romaji: "shimai",
        traducao: "irmãs",
      },
      {
        kanji: "姉さん",
        furigana: "ねえさん",
        romaji: "neesan",
        traducao: "irmã mais velha (informal)",
      },
      {
        kanji: "姉",
        furigana: "あね",
        romaji: "ane",
        traducao: "irmã mais velha",
      },
    ],
  },
  {
    kanji: "始",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [
      { hiragana: "はじ.める", romaji: "ha.ji.meru" },
      { hiragana: "はじ.まる", romaji: "ha.ji.maru" },
    ],
    significado: "começar",
    exemplos: [
      {
        kanji: "始める",
        furigana: "はじめる",
        romaji: "hajimeru",
        traducao: "começar",
      },
      {
        kanji: "開始",
        furigana: "かいし",
        romaji: "kaishi",
        traducao: "início",
      },
      {
        kanji: "始まる",
        furigana: "はじまる",
        romaji: "hajimaru",
        traducao: "começar",
      },
    ],
  },
  {
    kanji: "字",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [],
    significado: "caractere, letra",
    exemplos: [
      {
        kanji: "漢字",
        furigana: "かんじ",
        romaji: "kanji",
        traducao: "caractere chinês",
      },
      {
        kanji: "字幕",
        furigana: "じまく",
        romaji: "jimaku",
        traducao: "legendas",
      },
      {
        kanji: "手紙",
        furigana: "てがみ",
        romaji: "tegami",
        traducao: "carta",
      },
    ],
  },
  {
    kanji: "室",
    onYomi: [{ katakana: "シツ", romaji: "shitsu" }],
    kunYomi: [{ hiragana: "むろ", romaji: "muro" }],
    significado: "quarto, sala",
    exemplos: [
      {
        kanji: "教室",
        furigana: "きょうしつ",
        romaji: "kyoushitsu",
        traducao: "sala de aula",
      },
      {
        kanji: "待合室",
        furigana: "まちあいしつ",
        romaji: "machiaishitsu",
        traducao: "sala de espera",
      },
      {
        kanji: "寝室",
        furigana: "しんしつ",
        romaji: "shinshitsu",
        traducao: "quarto",
      },
    ],
  },
  {
    kanji: "家",
    onYomi: [
      { katakana: "カ", romaji: "ka" },
      { katakana: "ケ", romaji: "ke" },
    ],
    kunYomi: [
      { hiragana: "いえ", romaji: "ie" },
      { hiragana: "や", romaji: "ya" },
    ],
    significado: "casa, família",
    exemplos: [
      {
        kanji: "家族",
        furigana: "かぞく",
        romaji: "kazoku",
        traducao: "família",
      },
      {
        kanji: "家庭",
        furigana: "かてい",
        romaji: "katei",
        traducao: "lar, família",
      },
      {
        kanji: "家",
        furigana: "いえ",
        romaji: "ie",
        traducao: "casa",
      },
    ],
  },
  {
    kanji: "寒",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "さむ.い", romaji: "samu.i" }],
    significado: "frio",
    exemplos: [
      {
        kanji: "寒冷",
        furigana: "かんれい",
        romaji: "kanrei",
        traducao: "frio intenso",
      },
      {
        kanji: "寒い",
        furigana: "さむい",
        romaji: "samui",
        traducao: "frio",
      },
      {
        kanji: "寒さ",
        furigana: "さむさ",
        romaji: "samusa",
        traducao: "frio",
      },
    ],
  },
  {
    kanji: "屋",
    onYomi: [
      { katakana: "オク", romaji: "oku" },
      { katakana: "ヤ", romaji: "ya" },
    ],
    kunYomi: [],
    significado: "casa, loja",
    exemplos: [
      {
        kanji: "家屋",
        furigana: "かおく",
        romaji: "kaoku",
        traducao: "casa",
      },
      {
        kanji: "店舗",
        furigana: "てんぽ",
        romaji: "tenpo",
        traducao: "loja",
      },
      {
        kanji: "屋上",
        furigana: "おくじょう",
        romaji: "okujou",
        traducao: "telhado",
      },
    ],
  },
  {
    kanji: "工",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [],
    significado: "trabalho, indústria",
    exemplos: [
      {
        kanji: "工場",
        furigana: "こうじょう",
        romaji: "koujou",
        traducao: "fábrica",
      },
      {
        kanji: "工事",
        furigana: "こうじ",
        romaji: "kouji",
        traducao: "construção",
      },
      {
        kanji: "工業",
        furigana: "こうぎょう",
        romaji: "kougyou",
        traducao: "indústria",
      },
    ],
  },
  {
    kanji: "市",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "いち", romaji: "ichi" }],
    significado: "cidade, mercado",
    exemplos: [
      {
        kanji: "市民",
        furigana: "しみん",
        romaji: "shimin",
        traducao: "cidadão",
      },
      {
        kanji: "市場",
        furigana: "いちば",
        romaji: "ichiba",
        traducao: "mercado",
      },
      {
        kanji: "市内",
        furigana: "しない",
        romaji: "shinai",
        traducao: "dentro da cidade",
      },
    ],
  },
  {
    kanji: "帰",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "かえ.る", romaji: "kae.ru" }],
    significado: "voltar, retornar",
    exemplos: [
      {
        kanji: "帰国",
        furigana: "きこく",
        romaji: "kikoku",
        traducao: "retorno ao país",
      },
      {
        kanji: "帰宅",
        furigana: "きたく",
        romaji: "kitaku",
        traducao: "voltar para casa",
      },
      {
        kanji: "帰る",
        furigana: "かえる",
        romaji: "kaeru",
        traducao: "voltar",
      },
    ],
  },
  {
    kanji: "広",
    onYomi: [{ katakana: "コウ", romaji: "kou" }],
    kunYomi: [{ hiragana: "ひろ.い", romaji: "hiro.i" }],
    significado: "amplo, espaçoso",
    exemplos: [
      {
        kanji: "広い",
        furigana: "ひろい",
        romaji: "hiroi",
        traducao: "amplo",
      },
      {
        kanji: "広場",
        furigana: "ひろば",
        romaji: "hiroba",
        traducao: "praça",
      },
      {
        kanji: "広告",
        furigana: "こうこく",
        romaji: "koukoku",
        traducao: "anúncio",
      },
    ],
  },
  {
    kanji: "度",
    onYomi: [{ katakana: "ド", romaji: "do" }],
    kunYomi: [{ hiragana: "たび", romaji: "tabi" }],
    significado: "grau, vez",
    exemplos: [
      {
        kanji: "温度",
        furigana: "おんど",
        romaji: "ondo",
        traducao: "temperatura",
      },
      {
        kanji: "度合い",
        furigana: "どあい",
        romaji: "doai",
        traducao: "intensidade",
      },
      {
        kanji: "度",
        furigana: "たび",
        romaji: "tabi",
        traducao: "vez",
      },
    ],
  },
  {
    kanji: "建",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "た.てる", romaji: "ta.teru" }],
    significado: "construir",
    exemplos: [
      {
        kanji: "建物",
        furigana: "たてもの",
        romaji: "tatemono",
        traducao: "edifício",
      },
      {
        kanji: "建築",
        furigana: "けんちく",
        romaji: "kenchiku",
        traducao: "arquitetura",
      },
      {
        kanji: "建てる",
        furigana: "たてる",
        romaji: "tateru",
        traducao: "construir",
      },
    ],
  },
  {
    kanji: "引",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [{ hiragana: "ひ.く", romaji: "hi.ku" }],
    significado: "puxar",
    exemplos: [
      {
        kanji: "引っ越し",
        furigana: "ひっこし",
        romaji: "hikkoshi",
        traducao: "mudança",
      },
      {
        kanji: "引用",
        furigana: "いんよう",
        romaji: "inyou",
        traducao: "citação",
      },
      {
        kanji: "引く",
        furigana: "ひく",
        romaji: "hiku",
        traducao: "puxar",
      },
    ],
  },
  {
    kanji: "弟",
    onYomi: [{ katakana: "テイ", romaji: "tei" }],
    kunYomi: [{ hiragana: "おとうと", romaji: "otouto" }],
    significado: "irmão mais novo",
    exemplos: [
      {
        kanji: "兄弟",
        furigana: "きょうだい",
        romaji: "kyoudai",
        traducao: "irmãos",
      },
      {
        kanji: "弟さん",
        furigana: "おとうとさん",
        romaji: "otouto-san",
        traducao: "irmão mais novo (formal)",
      },
      {
        kanji: "弟",
        furigana: "おとうと",
        romaji: "otouto",
        traducao: "irmão mais novo",
      },
    ],
  },
  {
    kanji: "弱",
    onYomi: [{ katakana: "ジャク", romaji: "jaku" }],
    kunYomi: [{ hiragana: "よわ.い", romaji: "yowa.i" }],
    significado: "fraco",
    exemplos: [
      {
        kanji: "弱点",
        furigana: "じゃくてん",
        romaji: "jakuten",
        traducao: "ponto fraco",
      },
      {
        kanji: "弱音",
        furigana: "よわね",
        romaji: "yowane",
        traducao: "lamento, reclamação",
      },
      {
        kanji: "弱い",
        furigana: "よわい",
        romaji: "yowai",
        traducao: "fraco",
      },
    ],
  },
  {
    kanji: "強",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "つよ.い", romaji: "tsuyo.i" }],
    significado: "forte",
    exemplos: [
      {
        kanji: "強化",
        furigana: "きょうか",
        romaji: "kyouka",
        traducao: "fortalecimento",
      },
      {
        kanji: "強制",
        furigana: "きょうせい",
        romaji: "kyousei",
        traducao: "coerção, forçado",
      },
      {
        kanji: "強い",
        furigana: "つよい",
        romaji: "tsuyoi",
        traducao: "forte",
      },
    ],
  },
  {
    kanji: "待",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "ま.つ", romaji: "ma.tsu" }],
    significado: "esperar",
    exemplos: [
      {
        kanji: "待ち合わせ",
        furigana: "まちあわせ",
        romaji: "machiawase",
        traducao: "encontro",
      },
      {
        kanji: "待機",
        furigana: "たいき",
        romaji: "taiki",
        traducao: "espera, standby",
      },
      {
        kanji: "待つ",
        furigana: "まつ",
        romaji: "matsu",
        traducao: "esperar",
      },
    ],
  },
  {
    kanji: "心",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "こころ", romaji: "kokoro" }],
    significado: "coração, mente",
    exemplos: [
      {
        kanji: "心配",
        furigana: "しんぱい",
        romaji: "shinpai",
        traducao: "preocupação",
      },
      {
        kanji: "心理",
        furigana: "しんり",
        romaji: "shinri",
        traducao: "psicologia",
      },
      {
        kanji: "心",
        furigana: "こころ",
        romaji: "kokoro",
        traducao: "coração",
      },
    ],
  },
  {
    kanji: "思",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "おも.う", romaji: "omo.u" }],
    significado: "pensar, achar",
    exemplos: [
      {
        kanji: "思考",
        furigana: "しこう",
        romaji: "shikou",
        traducao: "pensamento, reflexão",
      },
      {
        kanji: "思い出",
        furigana: "おもいで",
        romaji: "omoide",
        traducao: "lembrança",
      },
      {
        kanji: "思う",
        furigana: "おもう",
        romaji: "omou",
        traducao: "pensar",
      },
    ],
  },
  {
    kanji: "急",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "いそ.ぐ", romaji: "iso.gu" }],
    significado: "rápido, urgente",
    exemplos: [
      {
        kanji: "急速",
        furigana: "きゅうそく",
        romaji: "kyuusoku",
        traducao: "rápido, veloz",
      },
      {
        kanji: "急行",
        furigana: "きゅうこう",
        romaji: "kyuukou",
        traducao: "trem expresso",
      },
      {
        kanji: "急ぐ",
        furigana: "いそぐ",
        romaji: "isogu",
        traducao: "apressar-se",
      },
    ],
  },
  {
    kanji: "悪",
    onYomi: [{ katakana: "アク", romaji: "aku" }],
    kunYomi: [{ hiragana: "わる.い", romaji: "waru.i" }],
    significado: "ruim, mau",
    exemplos: [
      {
        kanji: "悪化",
        furigana: "あっか",
        romaji: "akka",
        traducao: "deterioração",
      },
      {
        kanji: "悪夢",
        furigana: "あくむ",
        romaji: "akumu",
        traducao: "pesadelo",
      },
      {
        kanji: "悪い",
        furigana: "わるい",
        romaji: "warui",
        traducao: "ruim",
      },
    ],
  },
  {
    kanji: "意",
    onYomi: [{ katakana: "イ", romaji: "i" }],
    kunYomi: [],
    significado: "intenção, significado",
    exemplos: [
      {
        kanji: "意味",
        furigana: "いみ",
        romaji: "imi",
        traducao: "significado",
      },
      {
        kanji: "意見",
        furigana: "いけん",
        romaji: "iken",
        traducao: "opinião",
      },
      {
        kanji: "意識",
        furigana: "いしき",
        romaji: "ishiki",
        traducao: "consciência",
      },
    ],
  },
  {
    kanji: "所",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [{ hiragana: "ところ", romaji: "tokoro" }],
    significado: "lugar",
    exemplos: [
      {
        kanji: "場所",
        furigana: "ばしょ",
        romaji: "basho",
        traducao: "local",
      },
      {
        kanji: "事務所",
        furigana: "じむしょ",
        romaji: "jimusho",
        traducao: "escritório",
      },
      {
        kanji: "住所",
        furigana: "じゅうしょ",
        romaji: "juusho",
        traducao: "endereço",
      },
    ],
  },
  {
    kanji: "持",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [{ hiragana: "も.つ", romaji: "mo.tsu" }],
    significado: "ter, segurar",
    exemplos: [
      {
        kanji: "持参",
        furigana: "じさん",
        romaji: "jisan",
        traducao: "trazer consigo",
      },
      {
        kanji: "所有",
        furigana: "しょゆう",
        romaji: "shoyuu",
        traducao: "posse",
      },
      {
        kanji: "持つ",
        furigana: "もつ",
        romaji: "motsu",
        traducao: "ter, segurar",
      },
    ],
  },
  {
    kanji: "教",
    onYomi: [{ katakana: "キョウ", romaji: "kyou" }],
    kunYomi: [{ hiragana: "おし.える", romaji: "oshi.eru" }],
    significado: "ensinar",
    exemplos: [
      {
        kanji: "教育",
        furigana: "きょういく",
        romaji: "kyouiku",
        traducao: "educação",
      },
      {
        kanji: "宗教",
        furigana: "しゅうきょう",
        romaji: "shuukyou",
        traducao: "religião",
      },
      {
        kanji: "教える",
        furigana: "おしえる",
        romaji: "oshieru",
        traducao: "ensinar",
      },
    ],
  },
  {
    kanji: "文",
    onYomi: [{ katakana: "ブン", romaji: "bun" }],
    kunYomi: [{ hiragana: "ふみ", romaji: "fumi" }],
    significado: "texto, escrita",
    exemplos: [
      {
        kanji: "文化",
        furigana: "ぶんか",
        romaji: "bunka",
        traducao: "cultura",
      },
      {
        kanji: "文章",
        furigana: "ぶんしょう",
        romaji: "bunshou",
        traducao: "texto, redação",
      },
      {
        kanji: "文",
        furigana: "ふみ",
        romaji: "fumi",
        traducao: "escrita",
      },
    ],
  },
  {
    kanji: "料",
    onYomi: [{ katakana: "リョウ", romaji: "ryou" }],
    kunYomi: [],
    significado: "taxa, custo",
    exemplos: [
      {
        kanji: "料金",
        furigana: "りょうきん",
        romaji: "ryoukin",
        traducao: "tarifa, taxa",
      },
      {
        kanji: "料理",
        furigana: "りょうり",
        romaji: "ryouri",
        traducao: "culinária",
      },
      {
        kanji: "料金表",
        furigana: "りょうきんひょう",
        romaji: "ryoukinhyou",
        traducao: "tabela de preços",
      },
    ],
  },
  {
    kanji: "方",
    onYomi: [{ katakana: "ホウ", romaji: "hou" }],
    kunYomi: [{ hiragana: "かた", romaji: "kata" }],
    significado: "lado, maneira",
    exemplos: [
      {
        kanji: "方法",
        furigana: "ほうほう",
        romaji: "houhou",
        traducao: "método, maneira",
      },
      {
        kanji: "方向",
        furigana: "ほうこう",
        romaji: "houkou",
        traducao: "direção",
      },
      {
        kanji: "方",
        furigana: "かた",
        romaji: "kata",
        traducao: "lado, maneira",
      },
    ],
  },
  {
    kanji: "旅",
    onYomi: [{ katakana: "リョ", romaji: "ryo" }],
    kunYomi: [{ hiragana: "たび", romaji: "tabi" }],
    significado: "viagem",
    exemplos: [
      {
        kanji: "旅行",
        furigana: "りょこう",
        romaji: "ryokou",
        traducao: "viagem",
      },
      {
        kanji: "旅館",
        furigana: "りょかん",
        romaji: "ryokan",
        traducao: "pousada tradicional",
      },
      {
        kanji: "旅",
        furigana: "たび",
        romaji: "tabi",
        traducao: "viagem",
      },
    ],
  },
  {
    kanji: "族",
    onYomi: [{ katakana: "ゾク", romaji: "zoku" }],
    kunYomi: [],
    significado: "tribo, família",
    exemplos: [
      {
        kanji: "家族",
        furigana: "かぞく",
        romaji: "kazoku",
        traducao: "família",
      },
      {
        kanji: "部族",
        furigana: "ぶぞく",
        romaji: "buzoku",
        traducao: "tribo",
      },
      {
        kanji: "族",
        furigana: "ぞく",
        romaji: "zoku",
        traducao: "grupo étnico",
      },
    ],
  },
  {
    kanji: "早",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "はや.い", romaji: "haya.i" }],
    significado: "cedo, rápido",
    exemplos: [
      {
        kanji: "早朝",
        furigana: "そうちょう",
        romaji: "souchou",
        traducao: "manhã cedo",
      },
      {
        kanji: "早速",
        furigana: "さっそく",
        romaji: "sassoku",
        traducao: "imediatamente",
      },
      {
        kanji: "早い",
        furigana: "はやい",
        romaji: "hayai",
        traducao: "cedo, rápido",
      },
    ],
  },
  {
    kanji: "明",
    onYomi: [
      { katakana: "メイ", romaji: "mei" },
      { katakana: "ミョウ", romaji: "myou" },
    ],
    kunYomi: [
      { hiragana: "あ.かり", romaji: "a.kari" },
      { hiragana: "あか.るい", romaji: "aka.rui" },
    ],
    significado: "claro, brilhante",
    exemplos: [
      {
        kanji: "明日",
        furigana: "あした",
        romaji: "ashita",
        traducao: "amanhã",
      },
      {
        kanji: "明るい",
        furigana: "あかるい",
        romaji: "akarui",
        traducao: "brilhante",
      },
      {
        kanji: "明確",
        furigana: "めいかく",
        romaji: "meikaku",
        traducao: "claro, definido",
      },
    ],
  },
  {
    kanji: "映",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [{ hiragana: "うつ.る", romaji: "utsu.ru" }],
    significado: "refletir, projetar",
    exemplos: [
      {
        kanji: "映画",
        furigana: "えいが",
        romaji: "eiga",
        traducao: "filme",
      },
      {
        kanji: "映像",
        furigana: "えいぞう",
        romaji: "eizou",
        traducao: "imagem",
      },
      {
        kanji: "映る",
        furigana: "うつる",
        romaji: "utsuru",
        traducao: "refletir",
      },
    ],
  },
  {
    kanji: "春",
    onYomi: [{ katakana: "シュン", romaji: "shun" }],
    kunYomi: [{ hiragana: "はる", romaji: "haru" }],
    significado: "primavera",
    exemplos: [
      {
        kanji: "春休み",
        furigana: "はるやすみ",
        romaji: "haruyasumi",
        traducao: "férias de primavera",
      },
      {
        kanji: "春風",
        furigana: "はるかぜ",
        romaji: "harukaze",
        traducao: "brisa de primavera",
      },
      {
        kanji: "春",
        furigana: "はる",
        romaji: "haru",
        traducao: "primavera",
      },
    ],
  },
  {
    kanji: "昼",
    onYomi: [{ katakana: "チュウ", romaji: "chuu" }],
    kunYomi: [{ hiragana: "ひる", romaji: "hiru" }],
    significado: "meio-dia, dia",
    exemplos: [
      {
        kanji: "昼食",
        furigana: "ちゅうしょく",
        romaji: "chuumeshi",
        traducao: "almoço",
      },
      {
        kanji: "昼間",
        furigana: "ひるま",
        romaji: "hiruma",
        traducao: "durante o dia",
      },
      {
        kanji: "昼",
        furigana: "ひる",
        romaji: "hiru",
        traducao: "meio-dia, dia",
      },
    ],
  },
  {
    kanji: "暑",
    onYomi: [{ katakana: "ショ", romaji: "sho" }],
    kunYomi: [{ hiragana: "あつ.い", romaji: "atsu.i" }],
    significado: "quente",
    exemplos: [
      {
        kanji: "暑中",
        furigana: "しょちゅう",
        romaji: "shochuu",
        traducao: "durante o verão",
      },
      {
        kanji: "暑さ",
        furigana: "あつさ",
        romaji: "atsusa",
        traducao: "calor",
      },
      {
        kanji: "暑い",
        furigana: "あつい",
        romaji: "atsui",
        traducao: "quente",
      },
    ],
  },
  {
    kanji: "暗",
    onYomi: [{ katakana: "アン", romaji: "an" }],
    kunYomi: [{ hiragana: "くら.い", romaji: "kura.i" }],
    significado: "escuro",
    exemplos: [
      {
        kanji: "暗記",
        furigana: "あんき",
        romaji: "anki",
        traducao: "memorização",
      },
      {
        kanji: "暗闇",
        furigana: "くらやみ",
        romaji: "kurayami",
        traducao: "escuridão",
      },
      {
        kanji: "暗い",
        furigana: "くらい",
        romaji: "kurai",
        traducao: "escuro",
      },
    ],
  },
  {
    kanji: "曜",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [],
    significado: "dia da semana",
    exemplos: [
      {
        kanji: "月曜日",
        furigana: "げつようび",
        romaji: "getsuyoubi",
        traducao: "segunda-feira",
      },
      {
        kanji: "曜日",
        furigana: "ようび",
        romaji: "youbi",
        traducao: "dia da semana",
      },
      {
        kanji: "週末",
        furigana: "しゅうまつ",
        romaji: "shuumatsu",
        traducao: "fim de semana",
      },
    ],
  },
  {
    kanji: "有",
    onYomi: [{ katakana: "ユウ", romaji: "yuu" }],
    kunYomi: [{ hiragana: "あ.る", romaji: "a.ru" }],
    significado: "ter, existir",
    exemplos: [
      {
        kanji: "有名",
        furigana: "ゆうめい",
        romaji: "yuumei",
        traducao: "famoso",
      },
      {
        kanji: "所有",
        furigana: "しょゆう",
        romaji: "shoyuu",
        traducao: "posse",
      },
      {
        kanji: "持っている",
        furigana: "もっている",
        romaji: "motteiru",
        traducao: "ter",
      },
    ],
  },
  {
    kanji: "服",
    onYomi: [{ katakana: "フク", romaji: "fuku" }],
    kunYomi: [],
    significado: "roupa",
    exemplos: [
      {
        kanji: "服装",
        furigana: "ふくそう",
        romaji: "fukusou",
        traducao: "vestimenta",
      },
      {
        kanji: "制服",
        furigana: "せいふく",
        romaji: "seifuku",
        traducao: "uniforme",
      },
      {
        kanji: "洋服",
        furigana: "ようふく",
        romaji: "youfuku",
        traducao: "roupa ocidental",
      },
    ],
  },
  {
    kanji: "朝",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "あさ", romaji: "asa" }],
    significado: "manhã",
    exemplos: [
      {
        kanji: "朝食",
        furigana: "ちょうしょく",
        romaji: "choushoku",
        traducao: "café da manhã",
      },
      {
        kanji: "朝日",
        furigana: "あさひ",
        romaji: "asahi",
        traducao: "sol da manhã",
      },
      {
        kanji: "朝",
        furigana: "あさ",
        romaji: "asa",
        traducao: "manhã",
      },
    ],
  },
  {
    kanji: "村",
    onYomi: [{ katakana: "ソン", romaji: "son" }],
    kunYomi: [{ hiragana: "むら", romaji: "mura" }],
    significado: "vilarejo",
    exemplos: [
      {
        kanji: "田村",
        furigana: "たむら",
        romaji: "tamura",
        traducao: "nome de família",
      },
      {
        kanji: "村人",
        furigana: "むらびと",
        romaji: "murabito",
        traducao: "morador do vilarejo",
      },
      {
        kanji: "村",
        furigana: "むら",
        romaji: "mura",
        traducao: "vilarejo",
      },
    ],
  },
  {
    kanji: "林",
    onYomi: [{ katakana: "リン", romaji: "rin" }],
    kunYomi: [{ hiragana: "はやし", romaji: "hayashi" }],
    significado: "floresta",
    exemplos: [
      {
        kanji: "竹林",
        furigana: "ちくりん",
        romaji: "chikurin",
        traducao: "bosque de bambu",
      },
      {
        kanji: "林間",
        furigana: "りんかん",
        romaji: "rinkan",
        traducao: "em meio à floresta",
      },
      {
        kanji: "森林",
        furigana: "しんりん",
        romaji: "shinrin",
        traducao: "floresta",
      },
    ],
  },
  {
    kanji: "森",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "もり", romaji: "mori" }],
    significado: "floresta",
    exemplos: [
      {
        kanji: "森林",
        furigana: "しんりん",
        romaji: "shinrin",
        traducao: "floresta",
      },
      {
        kanji: "森口",
        furigana: "もりぐち",
        romaji: "moriguchi",
        traducao: "nome de família",
      },
      {
        kanji: "森",
        furigana: "もり",
        romaji: "mori",
        traducao: "floresta",
      },
    ],
  },
  {
    kanji: "業",
    onYomi: [{ katakana: "ギョウ", romaji: "gyou" }],
    kunYomi: [{ hiragana: "わざ", romaji: "waza" }],
    significado: "trabalho, indústria",
    exemplos: [
      {
        kanji: "仕業",
        furigana: "しわざ",
        romaji: "shi-waza",
        traducao: "ação, feito",
      },
      {
        kanji: "業界",
        furigana: "ぎょうかい",
        romaji: "gyoukai",
        traducao: "setor, indústria",
      },
      {
        kanji: "業",
        furigana: "わざ",
        romaji: "waza",
        traducao: "trabalho, ofício",
      },
    ],
  },
  {
    kanji: "楽",
    onYomi: [
      { katakana: "ガク", romaji: "gaku" },
      { katakana: "ラク", romaji: "raku" },
    ],
    kunYomi: [
      { hiragana: "たの.しい", romaji: "tano.shii" },
      { hiragana: "らく", romaji: "raku" },
    ],
    significado: "diversão, música",
    exemplos: [
      {
        kanji: "音楽",
        furigana: "おんがく",
        romaji: "ongaku",
        traducao: "música",
      },
      {
        kanji: "楽しい",
        furigana: "たのしい",
        romaji: "tanoshii",
        traducao: "divertido",
      },
      {
        kanji: "楽器",
        furigana: "がっき",
        romaji: "gakki",
        traducao: "instrumento musical",
      },
    ],
  },
  {
    kanji: "歌",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "うた", romaji: "uta" }],
    significado: "canção",
    exemplos: [
      {
        kanji: "歌手",
        furigana: "かしゅ",
        romaji: "kashu",
        traducao: "cantor",
      },
      {
        kanji: "歌詞",
        furigana: "かし",
        romaji: "kashi",
        traducao: "letra de música",
      },
      {
        kanji: "歌う",
        furigana: "うたう",
        romaji: "utau",
        traducao: "cantar",
      },
    ],
  },
  {
    kanji: "止",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [
      { hiragana: "と.まる", romaji: "to.maru" },
      { hiragana: "や.める", romaji: "ya.meru" },
    ],
    significado: "parar",
    exemplos: [
      {
        kanji: "停止",
        furigana: "ていし",
        romaji: "teishi",
        traducao: "parada, interrupção",
      },
      {
        kanji: "止める",
        furigana: "とめる",
        romaji: "tomeru",
        traducao: "parar",
      },
      {
        kanji: "止まる",
        furigana: "とまる",
        romaji: "tomaru",
        traducao: "parar",
      },
    ],
  },
  {
    kanji: "正",
    onYomi: [
      { katakana: "セイ", romaji: "sei" },
      { katakana: "ショウ", romaji: "shou" },
    ],
    kunYomi: [
      { hiragana: "ただ.しい", romaji: "tada.shii" },
      { hiragana: "まさ", romaji: "masa" },
    ],
    significado: "correto, justo",
    exemplos: [
      {
        kanji: "正確",
        furigana: "せいかく",
        romaji: "seikaku",
        traducao: "precisão, exatidão",
      },
      {
        kanji: "正直",
        furigana: "しょうじき",
        romaji: "shoujiki",
        traducao: "honestidade",
      },
      {
        kanji: "正しい",
        furigana: "ただしい",
        romaji: "tadashii",
        traducao: "correto",
      },
    ],
  },
  {
    kanji: "楽",
    onYomi: [
      { katakana: "ガク", romaji: "gaku" },
      { katakana: "ラク", romaji: "raku" },
    ],
    kunYomi: [
      { hiragana: "たの.しい", romaji: "tano.shii" },
      { hiragana: "らく", romaji: "raku" },
    ],
    significado: "diversão, música",
    exemplos: [
      {
        kanji: "音楽",
        furigana: "おんがく",
        romaji: "ongaku",
        traducao: "música",
      },
      {
        kanji: "楽しい",
        furigana: "たのしい",
        romaji: "tanoshii",
        traducao: "divertido",
      },
      {
        kanji: "楽器",
        furigana: "がっき",
        romaji: "gakki",
        traducao: "instrumento musical",
      },
    ],
  },
  {
    kanji: "歌",
    onYomi: [{ katakana: "カ", romaji: "ka" }],
    kunYomi: [{ hiragana: "うた", romaji: "uta" }],
    significado: "canção",
    exemplos: [
      {
        kanji: "歌手",
        furigana: "かしゅ",
        romaji: "kashu",
        traducao: "cantor",
      },
      {
        kanji: "歌詞",
        furigana: "かし",
        romaji: "kashi",
        traducao: "letra de música",
      },
      {
        kanji: "歌う",
        furigana: "うたう",
        romaji: "utau",
        traducao: "cantar",
      },
    ],
  },
  {
    kanji: "止",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [
      { hiragana: "と.まる", romaji: "to.maru" },
      { hiragana: "や.める", romaji: "ya.meru" },
    ],
    significado: "parar",
    exemplos: [
      {
        kanji: "停止",
        furigana: "ていし",
        romaji: "teishi",
        traducao: "parada, interrupção",
      },
      {
        kanji: "止める",
        furigana: "とめる",
        romaji: "tomeru",
        traducao: "parar",
      },
      {
        kanji: "止まる",
        furigana: "とまる",
        romaji: "tomaru",
        traducao: "parar",
      },
    ],
  },
  {
    kanji: "正",
    onYomi: [
      { katakana: "セイ", romaji: "sei" },
      { katakana: "ショウ", romaji: "shou" },
    ],
    kunYomi: [
      { hiragana: "ただ.しい", romaji: "tada.shii" },
      { hiragana: "まさ", romaji: "masa" },
    ],
    significado: "correto, justo",
    exemplos: [
      {
        kanji: "正確",
        furigana: "せいかく",
        romaji: "seikaku",
        traducao: "precisão, exatidão",
      },
      {
        kanji: "正直",
        furigana: "しょうじき",
        romaji: "shoujiki",
        traducao: "honestidade",
      },
      {
        kanji: "正しい",
        furigana: "ただしい",
        romaji: "tadashii",
        traducao: "correto",
      },
    ],
  },
  {
    kanji: "歩",
    onYomi: [{ katakana: "ホ", romaji: "ho" }],
    kunYomi: [{ hiragana: "ある.く", romaji: "aru.ku" }],
    significado: "andar",
    exemplos: [
      {
        kanji: "散歩",
        furigana: "さんぽ",
        romaji: "sanpo",
        traducao: "caminhada",
      },
      {
        kanji: "歩道",
        furigana: "ほどう",
        romaji: "hodou",
        traducao: "calçada",
      },
      {
        kanji: "歩く",
        furigana: "あるく",
        romaji: "aruku",
        traducao: "andar",
      },
    ],
  },
  {
    kanji: "死",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "し.ぬ", romaji: "shi.nu" }],
    significado: "morrer",
    exemplos: [
      {
        kanji: "死亡",
        furigana: "しぼう",
        romaji: "shibou",
        traducao: "morte",
      },
      {
        kanji: "死者",
        furigana: "ししゃ",
        romaji: "shisha",
        traducao: "falecido",
      },
      {
        kanji: "死ぬ",
        furigana: "しぬ",
        romaji: "shinu",
        traducao: "morrer",
      },
    ],
  },
  {
    kanji: "民",
    onYomi: [{ katakana: "ミン", romaji: "min" }],
    kunYomi: [{ hiragana: "たみ", romaji: "tami" }],
    significado: "povo",
    exemplos: [
      {
        kanji: "国民",
        furigana: "こくみん",
        romaji: "kokumin",
        traducao: "cidadão",
      },
      {
        kanji: "民族",
        furigana: "みんぞく",
        romaji: "minzoku",
        traducao: "etnia",
      },
      {
        kanji: "市民",
        furigana: "しみん",
        romaji: "shimin",
        traducao: "cidadão",
      },
    ],
  },
  {
    kanji: "池",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [{ hiragana: "いけ", romaji: "ike" }],
    significado: "lago",
    exemplos: [
      {
        kanji: "池の水",
        furigana: "いけのみず",
        romaji: "ike no mizu",
        traducao: "água do lago",
      },
      {
        kanji: "池",
        furigana: "いけ",
        romaji: "ike",
        traducao: "lago",
      },
      {
        kanji: "池釣り",
        furigana: "いけつり",
        romaji: "ike-tsuri",
        traducao: "pesca em lago",
      },
    ],
  },
  {
    kanji: "注",
    onYomi: [{ katakana: "チュウ", romaji: "chuu" }],
    kunYomi: [{ hiragana: "そそ.ぐ", romaji: "soso.gu" }],
    significado: "injetar, comentário",
    exemplos: [
      {
        kanji: "注目",
        furigana: "ちゅうもく",
        romaji: "chuumoku",
        traducao: "atenção, destaque",
      },
      {
        kanji: "注射",
        furigana: "ちゅうしゃ",
        romaji: "chuusha",
        traducao: "injeção",
      },
      {
        kanji: "注ぐ",
        furigana: "そそぐ",
        romaji: "sosogu",
        traducao: "despejar, injetar",
      },
    ],
  },
  {
    kanji: "洋",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [],
    significado: "oceano, estilo ocidental",
    exemplos: [
      {
        kanji: "洋服",
        furigana: "ようふく",
        romaji: "youfuku",
        traducao: "roupa ocidental",
      },
      {
        kanji: "洋食",
        furigana: "ようしょく",
        romaji: "youshoku",
        traducao: "comida ocidental",
      },
      {
        kanji: "西洋",
        furigana: "せいよう",
        romaji: "seiyou",
        traducao: "Ocidente",
      },
    ],
  },
  {
    kanji: "洗",
    onYomi: [{ katakana: "セン", romaji: "sen" }],
    kunYomi: [{ hiragana: "あら.う", romaji: "ara.u" }],
    significado: "lavar",
    exemplos: [
      {
        kanji: "洗濯",
        furigana: "せんたく",
        romaji: "sentaku",
        traducao: "lavagem",
      },
      {
        kanji: "洗面",
        furigana: "せんめん",
        romaji: "senmen",
        traducao: "lavatório",
      },
      {
        kanji: "洗う",
        furigana: "あらう",
        romaji: "arau",
        traducao: "lavar",
      },
    ],
  },
  {
    kanji: "海",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "うみ", romaji: "umi" }],
    significado: "mar",
    exemplos: [
      {
        kanji: "海外",
        furigana: "かいがい",
        romaji: "kaigai",
        traducao: "no exterior",
      },
      {
        kanji: "海水",
        furigana: "かいすい",
        romaji: "kaisui",
        traducao: "água do mar",
      },
      {
        kanji: "海",
        furigana: "うみ",
        romaji: "umi",
        traducao: "mar",
      },
    ],
  },
  {
    kanji: "漢",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [],
    significado: "chinês",
    exemplos: [
      {
        kanji: "漢字",
        furigana: "かんじ",
        romaji: "kanji",
        traducao: "caractere chinês",
      },
      {
        kanji: "漢語",
        furigana: "かんご",
        romaji: "kango",
        traducao: "palavra chinesa",
      },
      {
        kanji: "漢文",
        furigana: "かんぶん",
        romaji: "kanbun",
        traducao: "escrita em chinês clássico",
      },
    ],
  },
  {
    kanji: "牛",
    onYomi: [{ katakana: "ギュウ", romaji: "gyuu" }],
    kunYomi: [{ hiragana: "うし", romaji: "ushi" }],
    significado: "boi, vaca",
    exemplos: [
      {
        kanji: "牛肉",
        furigana: "ぎゅうにく",
        romaji: "gyuuniku",
        traducao: "carne de boi",
      },
      {
        kanji: "牛乳",
        furigana: "ぎゅうにゅう",
        romaji: "gyuunyuu",
        traducao: "leite de vaca",
      },
      {
        kanji: "牛",
        furigana: "うし",
        romaji: "ushi",
        traducao: "boi, vaca",
      },
    ],
  },
  {
    kanji: "物",
    onYomi: [{ katakana: "ブツ", romaji: "butsu" }],
    kunYomi: [{ hiragana: "もの", romaji: "mono" }],
    significado: "coisa, objeto",
    exemplos: [
      {
        kanji: "物語",
        furigana: "ものがたり",
        romaji: "monogatari",
        traducao: "história, conto",
      },
      {
        kanji: "物価",
        furigana: "ぶっか",
        romaji: "bukka",
        traducao: "preço, custo de vida",
      },
      {
        kanji: "物",
        furigana: "もの",
        romaji: "mono",
        traducao: "coisa, objeto",
      },
    ],
  },
  {
    kanji: "特",
    onYomi: [{ katakana: "トク", romaji: "toku" }],
    kunYomi: [],
    significado: "especial",
    exemplos: [
      {
        kanji: "特別",
        furigana: "とくべつ",
        romaji: "tokubetsu",
        traducao: "especial",
      },
      {
        kanji: "特技",
        furigana: "とくぎ",
        romaji: "tokugi",
        traducao: "habilidade especial",
      },
      {
        kanji: "特に",
        furigana: "とくに",
        romaji: "tokuni",
        traducao: "especialmente",
      },
    ],
  },
  {
    kanji: "犬",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "いぬ", romaji: "inu" }],
    significado: "cachorro",
    exemplos: [
      {
        kanji: "犬種",
        furigana: "けんしゅ",
        romaji: "kenshu",
        traducao: "raça de cachorro",
      },
      {
        kanji: "犬舎",
        furigana: "けんしゃ",
        romaji: "kensha",
        traducao: "canil",
      },
      {
        kanji: "犬",
        furigana: "いぬ",
        romaji: "inu",
        traducao: "cachorro",
      },
    ],
  },
  {
    kanji: "理",
    onYomi: [{ katakana: "リ", romaji: "ri" }],
    kunYomi: [{ hiragana: "ことわり", romaji: "kotowari" }],
    significado: "razão, lógica",
    exemplos: [
      {
        kanji: "理解",
        furigana: "りかい",
        romaji: "rikai",
        traducao: "compreensão",
      },
      {
        kanji: "理由",
        furigana: "りゆう",
        romaji: "riyuu",
        traducao: "motivo, razão",
      },
      {
        kanji: "理",
        furigana: "ことわり",
        romaji: "kotowari",
        traducao: "razão, lógica",
      },
    ],
  },
  {
    kanji: "産",
    onYomi: [{ katakana: "サン", romaji: "san" }],
    kunYomi: [{ hiragana: "う.む", romaji: "u.mu" }],
    significado: "produzir, dar à luz",
    exemplos: [
      {
        kanji: "生産",
        furigana: "せいさん",
        romaji: "seisan",
        traducao: "produção",
      },
      {
        kanji: "出産",
        furigana: "しゅっさん",
        romaji: "shussan",
        traducao: "parto",
      },
      {
        kanji: "産む",
        furigana: "うむ",
        romaji: "umu",
        traducao: "dar à luz",
      },
    ],
  },
  {
    kanji: "用",
    onYomi: [{ katakana: "ヨウ", romaji: "you" }],
    kunYomi: [],
    significado: "uso, utilidade",
    exemplos: [
      {
        kanji: "使用",
        furigana: "しよう",
        romaji: "shiyou",
        traducao: "uso",
      },
      {
        kanji: "用事",
        furigana: "ようじ",
        romaji: "youji",
        traducao: "assunto, tarefa",
      },
      {
        kanji: "用",
        furigana: "もち.いる",
        romaji: "mochi.iru",
        traducao: "usar, empregar",
      },
    ],
  },
  {
    kanji: "田",
    onYomi: [{ katakana: "デン", romaji: "den" }],
    kunYomi: [{ hiragana: "た", romaji: "ta" }],
    significado: "arrozal, campo",
    exemplos: [
      {
        kanji: "田園",
        furigana: "でんえん",
        romaji: "den'en",
        traducao: "campo, zona rural",
      },
      {
        kanji: "田舎",
        furigana: "いなか",
        romaji: "inaka",
        traducao: "campo, interior",
      },
      {
        kanji: "田",
        furigana: "た",
        romaji: "ta",
        traducao: "arrozal, campo",
      },
    ],
  },
  {
    kanji: "町",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "まち", romaji: "machi" }],
    significado: "cidade, bairro",
    exemplos: [
      {
        kanji: "町中",
        furigana: "ちょうちゅう",
        romaji: "chouchuu",
        traducao: "em toda a cidade",
      },
      {
        kanji: "町役場",
        furigana: "ちょうやくば",
        romaji: "chouyakuba",
        traducao: "prefeitura",
      },
      {
        kanji: "町",
        furigana: "まち",
        romaji: "machi",
        traducao: "cidade, bairro",
      },
    ],
  },
  {
    kanji: "画",
    onYomi: [
      { katakana: "ガ", romaji: "ga" },
      { katakana: "カク", romaji: "kaku" },
    ],
    kunYomi: [],
    significado: "desenho, pintura",
    exemplos: [
      {
        kanji: "絵画",
        furigana: "かいが",
        romaji: "kaiga",
        traducao: "pintura, quadro",
      },
      {
        kanji: "映画",
        furigana: "えいが",
        romaji: "eiga",
        traducao: "filme",
      },
      {
        kanji: "画家",
        furigana: "がか",
        romaji: "gaka",
        traducao: "artista, pintor",
      },
    ],
  },
  {
    kanji: "界",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [],
    significado: "mundo, campo",
    exemplos: [
      {
        kanji: "世界",
        furigana: "せかい",
        romaji: "sekai",
        traducao: "mundo",
      },
      {
        kanji: "界面",
        furigana: "かいめん",
        romaji: "kaimen",
        traducao: "interface",
      },
      {
        kanji: "業界",
        furigana: "ぎょうかい",
        romaji: "gyoukai",
        traducao: "indústria, ramo",
      },
    ],
  },
  {
    kanji: "病",
    onYomi: [{ katakana: "ビョウ", romaji: "byou" }],
    kunYomi: [{ hiragana: "や.む", romaji: "ya.mu" }],
    significado: "doença",
    exemplos: [
      {
        kanji: "病院",
        furigana: "びょういん",
        romaji: "byouin",
        traducao: "hospital",
      },
      {
        kanji: "病気",
        furigana: "びょうき",
        romaji: "byouki",
        traducao: "doença",
      },
      {
        kanji: "病む",
        furigana: "やむ",
        romaji: "yamu",
        traducao: "estar doente",
      },
    ],
  },
  {
    kanji: "発",
    onYomi: [
      { katakana: "ハツ", romaji: "hatsu" },
      { katakana: "ホツ", romaji: "hotsu" },
    ],
    kunYomi: [],
    significado: "emitir, partida",
    exemplos: [
      {
        kanji: "発表",
        furigana: "はっぴょう",
        romaji: "happyou",
        traducao: "apresentação, divulgação",
      },
      {
        kanji: "発生",
        furigana: "はっせい",
        romaji: "hassei",
        traducao: "ocorrência, surgimento",
      },
      {
        kanji: "発する",
        furigana: "はっする",
        romaji: "hassuru",
        traducao: "emitir, proferir",
      },
    ],
  },
  {
    kanji: "県",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [],
    significado: "prefeitura",
    exemplos: [
      {
        kanji: "県庁",
        furigana: "けんちょう",
        romaji: "kenchou",
        traducao: "prefeitura",
      },
      {
        kanji: "都道府県",
        furigana: "とどうふけん",
        romaji: "todoufukenn",
        traducao: "prefeituras do Japão",
      },
      {
        kanji: "県",
        furigana: "けん",
        romaji: "ken",
        traducao: "prefeitura",
      },
    ],
  },
  {
    kanji: "真",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "ま", romaji: "ma" }],
    significado: "verdade, real",
    exemplos: [
      {
        kanji: "真実",
        furigana: "しんじつ",
        romaji: "shinjitsu",
        traducao: "verdade",
      },
      {
        kanji: "真面目",
        furigana: "まじめ",
        romaji: "majime",
        traducao: "sério, dedicado",
      },
      {
        kanji: "真っ直ぐ",
        furigana: "まっすぐ",
        romaji: "massugu",
        traducao: "direto, reto",
      },
    ],
  },
  {
    kanji: "着",
    onYomi: [{ katakana: "チャク", romaji: "chaku" }],
    kunYomi: [{ hiragana: "き.る", romaji: "ki.ru" }],
    significado: "vestir, chegar",
    exemplos: [
      {
        kanji: "着物",
        furigana: "きもの",
        romaji: "kimono",
        traducao: "quimono",
      },
      {
        kanji: "到着",
        furigana: "とうちゃく",
        romaji: "touchaku",
        traducao: "chegada",
      },
      {
        kanji: "着る",
        furigana: "きる",
        romaji: "kiru",
        traducao: "vestir",
      },
    ],
  },
  {
    kanji: "知",
    onYomi: [{ katakana: "チ", romaji: "chi" }],
    kunYomi: [{ hiragana: "し.る", romaji: "shi.ru" }],
    significado: "saber, conhecer",
    exemplos: [
      {
        kanji: "知識",
        furigana: "ちしき",
        romaji: "chishiki",
        traducao: "conhecimento",
      },
      {
        kanji: "知人",
        furigana: "ちじん",
        romaji: "chijin",
        traducao: "conhecido",
      },
      {
        kanji: "知る",
        furigana: "しる",
        romaji: "shiru",
        traducao: "saber, conhecer",
      },
    ],
  },
  {
    kanji: "短",
    onYomi: [{ katakana: "タン", romaji: "tan" }],
    kunYomi: [{ hiragana: "みじか.い", romaji: "mijika.i" }],
    significado: "curto",
    exemplos: [
      {
        kanji: "短期",
        furigana: "たんき",
        romaji: "tan'ki",
        traducao: "curto prazo",
      },
      {
        kanji: "短い",
        furigana: "みじかい",
        romaji: "mijikai",
        traducao: "curto",
      },
      {
        kanji: "短距離",
        furigana: "たんきょり",
        romaji: "tankyori",
        traducao: "curta distância",
      },
    ],
  },
  {
    kanji: "研",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "と.ぐ", romaji: "to.gu" }],
    significado: "estudar, polir",
    exemplos: [
      {
        kanji: "研究",
        furigana: "けんきゅう",
        romaji: "kenkyuu",
        traducao: "pesquisa, estudo",
      },
      {
        kanji: "研修",
        furigana: "けんしゅう",
        romaji: "kenshuu",
        traducao: "treinamento",
      },
      {
        kanji: "研ぐ",
        furigana: "とぐ",
        romaji: "togyu",
        traducao: "afiar, polir",
      },
    ],
  },
  {
    kanji: "私",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "わたし", romaji: "watashi" }],
    significado: "eu, meu",
    exemplos: [
      {
        kanji: "私立",
        furigana: "しりつ",
        romaji: "shiritsu",
        traducao: "particular, privado",
      },
      {
        kanji: "私生活",
        furigana: "しせいかつ",
        romaji: "shiseikatsu",
        traducao: "vida pessoal",
      },
      {
        kanji: "私",
        furigana: "わたし",
        romaji: "watashi",
        traducao: "eu, meu",
      },
    ],
  },
  {
    kanji: "秋",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [{ hiragana: "あき", romaji: "aki" }],
    significado: "outono",
    exemplos: [
      {
        kanji: "秋季",
        furigana: "しゅうき",
        romaji: "shuuki",
        traducao: "outono",
      },
      {
        kanji: "秋風",
        furigana: "あきかぜ",
        romaji: "akikaze",
        traducao: "vento de outono",
      },
      {
        kanji: "秋",
        furigana: "あき",
        romaji: "aki",
        traducao: "outono",
      },
    ],
  },
  {
    kanji: "究",
    onYomi: [{ katakana: "キュウ", romaji: "kyuu" }],
    kunYomi: [{ hiragana: "きわ.める", romaji: "kiwa.meru" }],
    significado: "pesquisar, investigar",
    exemplos: [
      {
        kanji: "研究",
        furigana: "けんきゅう",
        romaji: "kenkyuu",
        traducao: "pesquisa, estudo",
      },
      {
        kanji: "究明",
        furigana: "きゅうめい",
        romaji: "kyuumei",
        traducao: "investigação",
      },
      {
        kanji: "究める",
        furigana: "きわめる",
        romaji: "kiwameru",
        traducao: "levar ao limite, aprofundar",
      },
    ],
  },
  {
    kanji: "答",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "こた.える", romaji: "kota.eru" }],
    significado: "resposta",
    exemplos: [
      {
        kanji: "回答",
        furigana: "かいとう",
        romaji: "kaitou",
        traducao: "resposta",
      },
      {
        kanji: "答え",
        furigana: "こたえ",
        romaji: "kotae",
        traducao: "resposta",
      },
      {
        kanji: "答える",
        furigana: "こたえる",
        romaji: "kotaeru",
        traducao: "responder",
      },
    ],
  },
  {
    kanji: "者",
    onYomi: [
      { katakana: "シャ", romaji: "sha" },
      { katakana: "モノ", romaji: "mono" },
    ],
    kunYomi: [],
    significado: "pessoa",
    exemplos: [
      {
        kanji: "学生",
        furigana: "がくせい",
        romaji: "gakusei",
        traducao: "estudante",
      },
      {
        kanji: "職業",
        furigana: "しょくぎょう",
        romaji: "shokugyou",
        traducao: "profissão",
      },
      {
        kanji: "人物",
        furigana: "じんぶつ",
        romaji: "jinbutsu",
        traducao: "personagem, figura",
      },
    ],
  },
  {
    kanji: "肉",
    onYomi: [{ katakana: "ニク", romaji: "niku" }],
    kunYomi: [],
    significado: "carne",
    exemplos: [
      {
        kanji: "牛肉",
        furigana: "ぎゅうにく",
        romaji: "gyuuniku",
        traducao: "carne de boi",
      },
      {
        kanji: "鶏肉",
        furigana: "とりにく",
        romaji: "toriniku",
        traducao: "carne de frango",
      },
      {
        kanji: "肉",
        furigana: "にく",
        romaji: "niku",
        traducao: "carne",
      },
    ],
  },
  {
    kanji: "自",
    onYomi: [{ katakana: "ジ", romaji: "ji" }],
    kunYomi: [{ hiragana: "みずか.ら", romaji: "mizuka.ra" }],
    significado: "próprio, si mesmo",
    exemplos: [
      {
        kanji: "自然",
        furigana: "しぜん",
        romaji: "shizen",
        traducao: "natureza",
      },
      {
        kanji: "自動",
        furigana: "じどう",
        romaji: "jidou",
        traducao: "automático",
      },
      {
        kanji: "自分",
        furigana: "じぶん",
        romaji: "jibun",
        traducao: "si mesmo",
      },
    ],
  },
  {
    kanji: "色",
    onYomi: [{ katakana: "ショク", romaji: "shoku" }],
    kunYomi: [{ hiragana: "いろ", romaji: "iro" }],
    significado: "cor",
    exemplos: [
      {
        kanji: "黒色",
        furigana: "くろいろ",
        romaji: "kuroiro",
        traducao: "cor preta",
      },
      {
        kanji: "青色",
        furigana: "あおいろ",
        romaji: "aoiro",
        traducao: "cor azul",
      },
      {
        kanji: "色",
        furigana: "いろ",
        romaji: "iro",
        traducao: "cor",
      },
    ],
  },
  {
    kanji: "英",
    onYomi: [{ katakana: "エイ", romaji: "ei" }],
    kunYomi: [],
    significado: "inglês, excelente",
    exemplos: [
      {
        kanji: "英語",
        furigana: "えいご",
        romaji: "eigo",
        traducao: "inglês",
      },
      {
        kanji: "英国",
        furigana: "えいこく",
        romaji: "eikoku",
        traducao: "Reino Unido",
      },
      {
        kanji: "英雄",
        furigana: "えいゆう",
        romaji: "eiyuu",
        traducao: "herói",
      },
    ],
  },
  {
    kanji: "茶",
    onYomi: [{ katakana: "チャ", romaji: "cha" }],
    kunYomi: [],
    significado: "chá",
    exemplos: [
      {
        kanji: "紅茶",
        furigana: "こうちゃ",
        romaji: "koucha",
        traducao: "chá preto",
      },
      {
        kanji: "緑茶",
        furigana: "りょくちゃ",
        romaji: "ryokucha",
        traducao: "chá verde",
      },
      {
        kanji: "茶",
        furigana: "ちゃ",
        romaji: "cha",
        traducao: "chá",
      },
    ],
  },
  {
    kanji: "菜",
    onYomi: [{ katakana: "サイ", romaji: "sai" }],
    kunYomi: [],
    significado: "legume, verdura",
    exemplos: [
      {
        kanji: "野菜",
        furigana: "やさい",
        romaji: "yasai",
        traducao: "legumes, verduras",
      },
      {
        kanji: "菜園",
        furigana: "さいえん",
        romaji: "saien",
        traducao: "horta",
      },
      {
        kanji: "菜",
        furigana: "な",
        romaji: "na",
        traducao: "verdura",
      },
    ],
  },
  {
    kanji: "薬",
    onYomi: [{ katakana: "ヤク", romaji: "yaku" }],
    kunYomi: [{ hiragana: "くすり", romaji: "kusuri" }],
    significado: "remédio",
    exemplos: [
      {
        kanji: "薬局",
        furigana: "やっきょく",
        romaji: "yakkyoku",
        traducao: "farmácia",
      },
      {
        kanji: "薬品",
        furigana: "やくひん",
        romaji: "yakuhin",
        traducao: "medicamento",
      },
      {
        kanji: "薬",
        furigana: "くすり",
        romaji: "kusuri",
        traducao: "remédio",
      },
    ],
  },
  {
    kanji: "親",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "おや", romaji: "oya" }],
    significado: "pai, mãe, próximo",
    exemplos: [
      {
        kanji: "親子",
        furigana: "おやこ",
        romaji: "oyako",
        traducao: "pai e filho",
      },
      {
        kanji: "親友",
        furigana: "しんゆう",
        romaji: "shinyuu",
        traducao: "amigo próximo",
      },
      {
        kanji: "親",
        furigana: "おや",
        romaji: "oya",
        traducao: "pai, mãe, próximo",
      },
    ],
  },
  {
    kanji: "計",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "はか.る", romaji: "haka.ru" }],
    significado: "medir, planejar",
    exemplos: [
      {
        kanji: "計画",
        furigana: "けいかく",
        romaji: "keikaku",
        traducao: "plano",
      },
      {
        kanji: "計算",
        furigana: "けいさん",
        romaji: "keisan",
        traducao: "cálculo",
      },
      {
        kanji: "計る",
        furigana: "はかる",
        romaji: "hakaru",
        traducao: "medir",
      },
    ],
  },
  {
    kanji: "試",
    onYomi: [{ katakana: "シ", romaji: "shi" }],
    kunYomi: [{ hiragana: "こころ.みる", romaji: "kokoro.miru" }],
    significado: "testar, experimentar",
    exemplos: [
      {
        kanji: "試験",
        furigana: "しけん",
        romaji: "shiken",
        traducao: "exame",
      },
      {
        kanji: "試合",
        furigana: "しあい",
        romaji: "shiai",
        traducao: "jogo, partida",
      },
      {
        kanji: "試みる",
        furigana: "こころみる",
        romaji: "kokoromiru",
        traducao: "tentar, experimentar",
      },
    ],
  },
  {
    kanji: "説",
    onYomi: [{ katakana: "セツ", romaji: "setsu" }],
    kunYomi: [{ hiragana: "と.く", romaji: "to.ku" }],
    significado: "explicar",
    exemplos: [
      {
        kanji: "説明",
        furigana: "せつめい",
        romaji: "setsumei",
        traducao: "explicação",
      },
      {
        kanji: "解説",
        furigana: "かいせつ",
        romaji: "kaisetsu",
        traducao: "comentário, explicação",
      },
      {
        kanji: "説く",
        furigana: "とく",
        romaji: "toku",
        traducao: "explicar, argumentar",
      },
    ],
  },
  {
    kanji: "貸",
    onYomi: [{ katakana: "タイ", romaji: "tai" }],
    kunYomi: [{ hiragana: "か.す", romaji: "ka.su" }],
    significado: "emprestar",
    exemplos: [
      {
        kanji: "貸し出し",
        furigana: "かしだし",
        romaji: "kashidashi",
        traducao: "empréstimo",
      },
      {
        kanji: "貸す",
        furigana: "かす",
        romaji: "kasu",
        traducao: "emprestar",
      },
      {
        kanji: "貸し",
        furigana: "かし",
        romaji: "kashi",
        traducao: "aluguel",
      },
    ],
  },
  {
    kanji: "質",
    onYomi: [{ katakana: "シツ", romaji: "shitsu" }],
    kunYomi: [],
    significado: "qualidade, garantia",
    exemplos: [
      {
        kanji: "品質",
        furigana: "ひんしつ",
        romaji: "hinshitsu",
        traducao: "qualidade",
      },
      {
        kanji: "質問",
        furigana: "しつもん",
        romaji: "shitsumon",
        traducao: "pergunta",
      },
      {
        kanji: "信質",
        furigana: "しんしつ",
        romaji: "shinshitsu",
        traducao: "integridade",
      },
    ],
  },
  {
    kanji: "赤",
    onYomi: [{ katakana: "セキ", romaji: "seki" }],
    kunYomi: [{ hiragana: "あか", romaji: "aka" }],
    significado: "vermelho",
    exemplos: [
      {
        kanji: "赤色",
        furigana: "あかいろ",
        romaji: "akairo",
        traducao: "cor vermelha",
      },
      {
        kanji: "赤ちゃん",
        furigana: "あかちゃん",
        romaji: "akachan",
        traducao: "bebê",
      },
      {
        kanji: "赤",
        furigana: "あか",
        romaji: "aka",
        traducao: "vermelho",
      },
    ],
  },
  {
    kanji: "走",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "はし.る", romaji: "hashi.ru" }],
    significado: "correr",
    exemplos: [
      {
        kanji: "走行",
        furigana: "そうこう",
        romaji: "soukou",
        traducao: "corrida, condução",
      },
      {
        kanji: "走る",
        furigana: "はしる",
        romaji: "hashiru",
        traducao: "correr",
      },
      {
        kanji: "走者",
        furigana: "そうしゃ",
        romaji: "sousha",
        traducao: "corredor",
      },
    ],
  },
  {
    kanji: "起",
    onYomi: [{ katakana: "キ", romaji: "ki" }],
    kunYomi: [{ hiragana: "お.きる", romaji: "o.kiru" }],
    significado: "levantar, acordar",
    exemplos: [
      {
        kanji: "起床",
        furigana: "きしょう",
        romaji: "kishou",
        traducao: "acordar, levantar",
      },
      {
        kanji: "起きる",
        furigana: "おきる",
        romaji: "okiru",
        traducao: "levantar, acordar",
      },
      {
        kanji: "起こす",
        furigana: "おこす",
        romaji: "okosu",
        traducao: "acordar, despertar",
      },
    ],
  },
  {
    kanji: "転",
    onYomi: [{ katakana: "テン", romaji: "ten" }],
    kunYomi: [{ hiragana: "ころ.がる", romaji: "koro.garu" }],
    significado: "virar, mudar",
    exemplos: [
      {
        kanji: "転職",
        furigana: "てんしょく",
        romaji: "tenshoku",
        traducao: "mudança de emprego",
      },
      {
        kanji: "転倒",
        furigana: "てんとう",
        romaji: "tentou",
        traducao: "queda, tombamento",
      },
      {
        kanji: "転がる",
        furigana: "ころがる",
        romaji: "korogaru",
        traducao: "rolar, virar",
      },
    ],
  },
  {
    kanji: "軽",
    onYomi: [{ katakana: "ケイ", romaji: "kei" }],
    kunYomi: [{ hiragana: "かる.い", romaji: "karu.i" }],
    significado: "leve",
    exemplos: [
      {
        kanji: "軽量",
        furigana: "けいりょう",
        romaji: "keiryou",
        traducao: "leveza, peso leve",
      },
      {
        kanji: "軽い",
        furigana: "かるい",
        romaji: "karui",
        traducao: "leve",
      },
      {
        kanji: "軽快",
        furigana: "けいかい",
        romaji: "keikai",
        traducao: "rápido, ágil",
      },
    ],
  },
  {
    kanji: "近",
    onYomi: [{ katakana: "キン", romaji: "kin" }],
    kunYomi: [{ hiragana: "ちか.い", romaji: "chika.i" }],
    significado: "perto",
    exemplos: [
      {
        kanji: "近所",
        furigana: "きんじょ",
        romaji: "kinjo",
        traducao: "vizinhança",
      },
      {
        kanji: "近く",
        furigana: "ちかく",
        romaji: "chikaku",
        traducao: "perto",
      },
      {
        kanji: "近い",
        furigana: "ちかい",
        romaji: "chikai",
        traducao: "perto",
      },
    ],
  },
  {
    kanji: "送",
    onYomi: [{ katakana: "ソウ", romaji: "sou" }],
    kunYomi: [{ hiragana: "おく.る", romaji: "o.kuru" }],
    significado: "enviar",
    exemplos: [
      {
        kanji: "送信",
        furigana: "そうしん",
        romaji: "soushin",
        traducao: "enviar, transmitir",
      },
      {
        kanji: "送る",
        furigana: "おくる",
        romaji: "okuru",
        traducao: "enviar",
      },
      {
        kanji: "送り",
        furigana: "おくり",
        romaji: "okuri",
        traducao: "acompanhamento, envio",
      },
    ],
  },
  {
    kanji: "通",
    onYomi: [{ katakana: "ツウ", romaji: "tsuu" }],
    kunYomi: [{ hiragana: "とお.る", romaji: "too.ru" }],
    significado: "passar, comunicar",
    exemplos: [
      {
        kanji: "通学",
        furigana: "つうがく",
        romaji: "tsuugaku",
        traducao: "ir à escola",
      },
      {
        kanji: "通信",
        furigana: "つうしん",
        romaji: "tsuushin",
        traducao: "comunicação",
      },
      {
        kanji: "通り",
        furigana: "とおり",
        romaji: "toori",
        traducao: "rua, passagem",
      },
    ],
  },
  {
    kanji: "進",
    onYomi: [{ katakana: "シン", romaji: "shin" }],
    kunYomi: [{ hiragana: "すす.む", romaji: "susumu" }],
    significado: "avançar",
    exemplos: [
      {
        kanji: "進化",
        furigana: "しんか",
        romaji: "shinka",
        traducao: "evolução",
      },
      {
        kanji: "進学",
        furigana: "しんがく",
        romaji: "shingaku",
        traducao: "avançar para a faculdade",
      },
      {
        kanji: "進む",
        furigana: "すすむ",
        romaji: "susumu",
        traducao: "avançar",
      },
    ],
  },
  {
    kanji: "運",
    onYomi: [{ katakana: "ウン", romaji: "un" }],
    kunYomi: [{ hiragana: "はこ.ぶ", romaji: "hako.bu" }],
    significado: "transportar, sorte",
    exemplos: [
      {
        kanji: "運動",
        furigana: "うんどう",
        romaji: "undou",
        traducao: "exercício físico",
      },
      {
        kanji: "運転",
        furigana: "うんてん",
        romaji: "unten",
        traducao: "condução",
      },
      {
        kanji: "運ぶ",
        furigana: "はこぶ",
        romaji: "hakobu",
        traducao: "transportar",
      },
    ],
  },
  {
    kanji: "遠",
    onYomi: [{ katakana: "エン", romaji: "en" }],
    kunYomi: [{ hiragana: "とお.い", romaji: "too.i" }],
    significado: "distante",
    exemplos: [
      {
        kanji: "遠足",
        furigana: "えんそく",
        romaji: "ensoku",
        traducao: "passeio, excursão",
      },
      {
        kanji: "遠い",
        furigana: "とおい",
        romaji: "tooi",
        traducao: "distante",
      },
      {
        kanji: "遠く",
        furigana: "とおく",
        romaji: "tooku",
        traducao: "longe",
      },
    ],
  },
  {
    kanji: "都",
    onYomi: [{ katakana: "ト", romaji: "to" }],
    kunYomi: [{ hiragana: "みやこ", romaji: "miyako" }],
    significado: "capital",
    exemplos: [
      {
        kanji: "首都",
        furigana: "しゅと",
        romaji: "shuto",
        traducao: "capital",
      },
      {
        kanji: "都市",
        furigana: "とし",
        romaji: "toshi",
        traducao: "cidade",
      },
      {
        kanji: "都心",
        furigana: "としん",
        romaji: "toshin",
        traducao: "centro da cidade",
      },
    ],
  },
  {
    kanji: "重",
    onYomi: [
      { katakana: "ジュウ", romaji: "juu" },
      { katakana: "チョウ", romaji: "chou" },
    ],
    kunYomi: [
      { hiragana: "おも.い", romaji: "omo.i" },
      { hiragana: "かさ.ねる", romaji: "kasa.neru" },
    ],
    significado: "pesado, importante",
    exemplos: [
      {
        kanji: "重要",
        furigana: "じゅうよう",
        romaji: "juuyou",
        traducao: "importante",
      },
      {
        kanji: "重量",
        furigana: "じゅうりょう",
        romaji: "juuryou",
        traducao: "peso",
      },
      {
        kanji: "重い",
        furigana: "おもい",
        romaji: "omoi",
        traducao: "pesado",
      },
    ],
  },
  {
    kanji: "野",
    onYomi: [{ katakana: "ヤ", romaji: "ya" }],
    kunYomi: [{ hiragana: "の", romaji: "no" }],
    significado: "campo, selvagem",
    exemplos: [
      {
        kanji: "野菜",
        furigana: "やさい",
        romaji: "yasai",
        traducao: "legumes, verduras",
      },
      {
        kanji: "草野球",
        furigana: "くさやきゅう",
        romaji: "kusa yakyuu",
        traducao: "beisebol amador",
      },
      {
        kanji: "野生",
        furigana: "やせい",
        romaji: "yasei",
        traducao: "selvagem",
      },
    ],
  },
  {
    kanji: "銀",
    onYomi: [{ katakana: "ギン", romaji: "gin" }],
    kunYomi: [],
    significado: "prata",
    exemplos: [
      {
        kanji: "銀行",
        furigana: "ぎんこう",
        romaji: "ginkou",
        traducao: "banco",
      },
      {
        kanji: "銀色",
        furigana: "ぎんいろ",
        romaji: "gin'iro",
        traducao: "cor prata",
      },
      {
        kanji: "銀",
        furigana: "ぎん",
        romaji: "gin",
        traducao: "prata",
      },
    ],
  },
  {
    kanji: "門",
    onYomi: [{ katakana: "モン", romaji: "mon" }],
    kunYomi: [{ hiragana: "かど", romaji: "kado" }],
    significado: "porta",
    exemplos: [
      {
        kanji: "入り口",
        furigana: "いりぐち",
        romaji: "iriguchi",
        traducao: "entrada",
      },
      {
        kanji: "門",
        furigana: "もん",
        romaji: "mon",
        traducao: "porta",
      },
      {
        kanji: "門前",
        furigana: "もんぜん",
        romaji: "monzen",
        traducao: "em frente ao portão",
      },
    ],
  },
  {
    kanji: "開",
    onYomi: [{ katakana: "カイ", romaji: "kai" }],
    kunYomi: [{ hiragana: "ひら.く", romaji: "hira.ku" }],
    significado: "abrir",
    exemplos: [
      {
        kanji: "開始",
        furigana: "かいし",
        romaji: "kaishi",
        traducao: "início",
      },
      {
        kanji: "開放",
        furigana: "かいほう",
        romaji: "kaihou",
        traducao: "abrir, liberar",
      },
      {
        kanji: "開く",
        furigana: "ひらく",
        romaji: "hiraku",
        traducao: "abrir",
      },
    ],
  },
  {
    kanji: "院",
    onYomi: [{ katakana: "イン", romaji: "in" }],
    kunYomi: [],
    significado: "instituição",
    exemplos: [
      {
        kanji: "大学院",
        furigana: "だいがくいん",
        romaji: "daigakuin",
        traducao: "pós-graduação",
      },
      {
        kanji: "病院",
        furigana: "びょういん",
        romaji: "byouin",
        traducao: "hospital",
      },
      {
        kanji: "美容院",
        furigana: "びよういん",
        romaji: "biyouin",
        traducao: "salão de beleza",
      },
    ],
  },
  {
    kanji: "集",
    onYomi: [{ katakana: "シュウ", romaji: "shuu" }],
    kunYomi: [{ hiragana: "あつ.まる", romaji: "atsu.maru" }],
    significado: "coletar, reunir",
    exemplos: [
      {
        kanji: "集中",
        furigana: "しゅうちゅう",
        romaji: "shuuchuu",
        traducao: "concentração",
      },
      {
        kanji: "集まる",
        furigana: "あつまる",
        romaji: "atsumaru",
        traducao: "reunir-se, juntar-se",
      },
      {
        kanji: "集める",
        furigana: "あつめる",
        romaji: "atsumeru",
        traducao: "coletar, reunir",
      },
    ],
  },
  {
    kanji: "青",
    onYomi: [{ katakana: "セイ", romaji: "sei" }],
    kunYomi: [{ hiragana: "あお", romaji: "ao" }],
    significado: "azul, verde, jovem",
    exemplos: [
      {
        kanji: "青年",
        furigana: "せいねん",
        romaji: "seinen",
        traducao: "jovem, juventude",
      },
      {
        kanji: "青空",
        furigana: "あおぞら",
        romaji: "aozora",
        traducao: "céu azul",
      },
      {
        kanji: "青い",
        furigana: "あおい",
        romaji: "aoi",
        traducao: "azul, verde",
      },
    ],
  },
  {
    kanji: "音",
    onYomi: [{ katakana: "オン", romaji: "on" }],
    kunYomi: [{ hiragana: "おと", romaji: "oto" }],
    significado: "som",
    exemplos: [
      {
        kanji: "音楽",
        furigana: "おんがく",
        romaji: "ongaku",
        traducao: "música",
      },
      {
        kanji: "音声",
        furigana: "おんせい",
        romaji: "onsei",
        traducao: "voz, áudio",
      },
      {
        kanji: "音",
        furigana: "おと",
        romaji: "oto",
        traducao: "som",
      },
    ],
  },
  {
    kanji: "頭",
    onYomi: [{ katakana: "トウ", romaji: "tou" }],
    kunYomi: [{ hiragana: "あたま", romaji: "atama" }],
    significado: "cabeça",
    exemplos: [
      {
        kanji: "頭痛",
        furigana: "ずつう",
        romaji: "zutsuu",
        traducao: "dor de cabeça",
      },
      {
        kanji: "頭脳",
        furigana: "ずのう",
        romaji: "zunou",
        traducao: "inteligência",
      },
      {
        kanji: "頭",
        furigana: "あたま",
        romaji: "atama",
        traducao: "cabeça",
      },
    ],
  },
  {
    kanji: "題",
    onYomi: [{ katakana: "ダイ", romaji: "dai" }],
    kunYomi: [],
    significado: "título, assunto",
    exemplos: [
      {
        kanji: "問題",
        furigana: "もんだい",
        romaji: "mondai",
        traducao: "problema",
      },
      {
        kanji: "題名",
        furigana: "だいめい",
        romaji: "daimei",
        traducao: "título",
      },
      {
        kanji: "題",
        furigana: "だい",
        romaji: "dai",
        traducao: "assunto, tópico",
      },
    ],
  },
  {
    kanji: "顔",
    onYomi: [{ katakana: "ガン", romaji: "gan" }],
    kunYomi: [{ hiragana: "かお", romaji: "kao" }],
    significado: "rosto",
    exemplos: [
      {
        kanji: "笑顔",
        furigana: "えがお",
        romaji: "egao",
        traducao: "sorriso",
      },
      {
        kanji: "顔色",
        furigana: "かおいろ",
        romaji: "kaoiro",
        traducao: "cor da pele, aparência",
      },
      {
        kanji: "顔",
        furigana: "かお",
        romaji: "kao",
        traducao: "rosto",
      },
    ],
  },
  {
    kanji: "風",
    onYomi: [{ katakana: "フウ", romaji: "fuu" }],
    kunYomi: [{ hiragana: "かぜ", romaji: "kaze" }],
    significado: "vento, estilo",
    exemplos: [
      {
        kanji: "風景",
        furigana: "ふうけい",
        romaji: "fuukei",
        traducao: "paisagem",
      },
      {
        kanji: "風味",
        furigana: "ふうみ",
        romaji: "fuumi",
        traducao: "sabor, aroma",
      },
      {
        kanji: "風",
        furigana: "かぜ",
        romaji: "kaze",
        traducao: "vento",
      },
    ],
  },
  {
    kanji: "飯",
    onYomi: [{ katakana: "ハン", romaji: "han" }],
    kunYomi: [{ hiragana: "めし", romaji: "meshi" }],
    significado: "refeição",
    exemplos: [
      {
        kanji: "朝飯",
        furigana: "あさめし",
        romaji: "asameshi",
        traducao: "café da manhã",
      },
      {
        kanji: "飯",
        furigana: "めし",
        romaji: "meshi",
        traducao: "refeição",
      },
      {
        kanji: "弁当",
        furigana: "べんとう",
        romaji: "bentou",
        traducao: "marmita",
      },
    ],
  },
  {
    kanji: "館",
    onYomi: [{ katakana: "カン", romaji: "kan" }],
    kunYomi: [{ hiragana: "たて", romaji: "tate" }],
    significado: "edifício, casa",
    exemplos: [
      {
        kanji: "美術館",
        furigana: "びじゅつかん",
        romaji: "bijutsukan",
        traducao: "museu de arte",
      },
      {
        kanji: "図書館",
        furigana: "としょかん",
        romaji: "toshokan",
        traducao: "biblioteca",
      },
      {
        kanji: "館",
        furigana: "たて",
        romaji: "tate",
        traducao: "edifício, casa",
      },
    ],
  },
  {
    kanji: "首",
    onYomi: [{ katakana: "シュ", romaji: "shu" }],
    kunYomi: [{ hiragana: "くび", romaji: "kubi" }],
    significado: "pescoço",
    exemplos: [
      {
        kanji: "首都",
        furigana: "しゅと",
        romaji: "shuto",
        traducao: "capital",
      },
      {
        kanji: "首相",
        furigana: "しゅしょう",
        romaji: "shushou",
        traducao: "primeiro-ministro",
      },
      {
        kanji: "首",
        furigana: "くび",
        romaji: "kubi",
        traducao: "pescoço",
      },
    ],
  },
  {
    kanji: "験",
    onYomi: [{ katakana: "ケン", romaji: "ken" }],
    kunYomi: [{ hiragana: "あかし", romaji: "akashi" }],
    significado: "teste, experiência",
    exemplos: [
      {
        kanji: "試験",
        furigana: "しけん",
        romaji: "shiken",
        traducao: "exame",
      },
      {
        kanji: "経験",
        furigana: "けいけん",
        romaji: "keiken",
        traducao: "experiência",
      },
      {
        kanji: "験",
        furigana: "あかし",
        romaji: "akashi",
        traducao: "prova, evidência",
      },
    ],
  },
  {
    kanji: "鳥",
    onYomi: [{ katakana: "チョウ", romaji: "chou" }],
    kunYomi: [{ hiragana: "とり", romaji: "tori" }],
    significado: "pássaro",
    exemplos: [
      {
        kanji: "鳥居",
        furigana: "とりい",
        romaji: "torii",
        traducao: "portal xintoísta",
      },
      {
        kanji: "鳥",
        furigana: "とり",
        romaji: "tori",
        traducao: "pássaro",
      },
      {
        kanji: "小鳥",
        furigana: "ことり",
        romaji: "kotori",
        traducao: "passarinho",
      },
    ],
  },
  {
    kanji: "黒",
    onYomi: [{ katakana: "コク", romaji: "koku" }],
    kunYomi: [{ hiragana: "くろ", romaji: "kuro" }],
    significado: "preto",
    exemplos: [
      {
        kanji: "黒板",
        furigana: "こくばん",
        romaji: "kokuban",
        traducao: "lousa",
      },
      {
        kanji: "黒い",
        furigana: "くろい",
        romaji: "kuroi",
        traducao: "preto",
      },
      {
        kanji: "黒",
        furigana: "くろ",
        romaji: "kuro",
        traducao: "preto",
      },
    ],
  },
];

export default n4KanjiList;
